export class ApprovalViewModel{

    constructor(
        public idApproval:number,
        public description: string,
        public level: number,
        public idOperation: number,
        public hierarchyConfigurationId: number,
        public hierarchyApprovalLevelId: number,
        public operatorStart: string,
        public rankStart: number,
        public operatorEnd: string,
        public rankEnd: number,
        public orderlevel: number,
        public edit?:boolean,
    ) {}
  }