<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'">
            <i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/solicitude'">
            {{ "SolicitudComponent.Title" | translate }}
          </a>
        </li>
        <li *ngIf="isType == solicitudeTypeEnum.Withdrawal" 
            class="breadcrumb-item active" aria-current="page">
          {{ "SolicitudComponent.Withdrawal.Title" | translate }} 
        </li>
        <li *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential" 
            class="breadcrumb-item active" aria-current="page">
          {{ "SolicitudComponent.WithdrawalDiferential.Title" | translate }} 
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential">
      <div class="col-md-6">
        <h1 class="pstntitle">
          {{ "SolicitudComponent.WithdrawalDiferential.Title" | translate }}
        </h1>
        <br>
        <h6>{{ "SolicitudComponent.Reason" | translate }} : {{reason}}</h6>
      </div>
    </div>

    <div *ngIf="isType == solicitudeTypeEnum.Withdrawal">
      <div class="row">
        <div class="col-md-6">
          <h1 class="pstntitle">
            {{ "SolicitudComponent.Withdrawal.Title" | translate }}
          </h1>
        </div>
        <div class="col-md-6 text-right" style=" padding: 0;">
          <div class="form-group">
            <label>{{ "SolicitudComponent.TypeSolicitude" | translate }}</label>
            <div class="row estadosol">
              <p class="creacion" style="opacity: 0.5;">
                <i class="uil uil-okta" aria-hidden="true"></i>
                {{ "SolicitudComponent.Create.SolicitudeCreation" | translate }}
              </p>
              <p class="retiro" style="opacity: 99;">
                <i class="uil uil-okta" aria-hidden="true"></i>
                {{ "SolicitudComponent.Withdrawal.TypeSolicitude" | translate }}
              </p>
              <p class="modificacion" [ngStyle]="{'opacity': solicitudeStatus === solicitudeStatusEnum.Modification
                                      || solicitudeStatus === solicitudeStatusEnum.Rejected ? '99': '0.5'}">
                <i class="uil uil-okta" aria-hidden="true"></i>
                {{ "SolicitudComponent.TypeSolicitudeModification" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p-accordion>
        <p-accordionTab header="{{ 'SolicitudComponent.Create.ProductFilter' | translate }}" [selected]="activeAccordion">
  
          <app-hierarchy-advanced-filters [isMultiFilter]="true" (eventClickFilters)="eventClickFilters($event)">
          </app-hierarchy-advanced-filters>
  
        </p-accordionTab>
      </p-accordion>
      <div class="row mt-5"></div>
      <app-upload-excel
        [codeCreation]="codeCreationSession"
        [idSolicitude]="solicitudeService.getSolicitudeId()"
        [processType]="solicitudeTypeEnum.Withdrawal"
        [typeOfLoad]="typeOfLoadFileExcel.Solicitude"
        (eventEmitterId)="refreshDetailByFile($event)"
      ></app-upload-excel>
    </div>
    <div class="mt-5" *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential">
      <p-accordion>
        <p-accordionTab
          header="{{
            'SolicitudComponent.Create.PriceCertificate' | translate
          }}"
          [selected]="true"
        >
          <div class="row mt-5">
            <div class="col-md-10">
              <p-message
                severity="info"
                text="{{
                  'SolicitudComponent.Create.FileUploadMsgValidate' | translate
                }}"
                styleClass="p-mr-5"
              ></p-message>
            </div>

            <p-fileUpload
              #fileUpload
              customUpload="true"
              class="col-md-12"
              (uploadHandler)="onUploadFile($event)"
              (onRemove)="onRemoveFileImage()"
              [auto]="true"
              maxFileSize="4194304"
              chooseLabel="{{
                'SolicitudComponent.Create.UploadFile' | translate
              }}"
              invalidFileSizeMessageSummary="{0}: {{
                'SolicitudComponent.Create.InvalidSize' | translate
              }}"
              invalidFileSizeMessageDetail="{{
                'SolicitudComponent.Create.FileUpload' | translate
              }} {0}."
              accept=".pdf"
            >
            <ng-template pTemplate="content">
              <div *ngIf="!uploadedFiles.length" class="col-md-12">
                {{ "SolicitudComponent.Create.NoFile" | translate }}
              </div>
            </ng-template>
          </p-fileUpload>
          <div *ngIf="downloadFile" class="row">
              <div class="row mt-3 pb-3 d-flex justify-content-end">
                {{
                  "SolicitudComponent.Create.FilePriceCertificate" | translate
                }}
                &nbsp;&nbsp; <strong>{{ nameFile }}</strong
                >&nbsp;&nbsp;
              </div>

              <div class="col-lg-4 md-3 mt-3">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-download"
                  label="{{ 'Download' | translate }}"
                  class="p-button-outlined p-button-rounded"
                  (click)="fileDownloadPDF()"
                ></button>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <h2 class="pstn_subtitle">
          {{ "SolicitudComponent.Create.PriceUpdate" | translate }}
        </h2>
      </div>
      <div class="col-md-12">
        <h6 class="pstn_subtitle">
          {{ "SolicitudComponent.Create.CurrentPriceNewPrice" | translate }}
        </h6>
      </div>
      <div class="pstn_container container-fluid">
        <div class="row justify-content-between">
          <div class="align-self-center">
            <h6>
              {{ "SolicitudComponent.Withdrawal.DeleteBtn" | translate }}
              <button id="btnDelete" type="button" class="delete btn btn-secundary-delete" (click)="delete()"
              *ngIf="rolePermission.delete | rolePermission">
                <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
              </button>
            </h6>
          </div>
        </div>

        <!-- PrimeNg Table -->
        <p-table #dt [value]="objectList" dataKey="id" styleClass="p-datatable-customers" [paginator]="true" [rows]="10"
          paginatorPosition="bottom" currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
          [rowsPerPageOptions]="[10, 25, 50, 100]" editMode="row" [(selection)]="selectSolicitude"
          [scrollable]="true" [globalFilterFields]="['materialCode', 'product']" scrollHeight="800px">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width:150px" *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential">
                {{ "Actions" | translate }}
              </th>
              <th role="columnheader" style="width: 20px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th style="width:150px" pSortableColumn="materialCode">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.Code" | translate }}
                  <p-sortIcon field="materialCode"></p-sortIcon>
                </div>
              </th>
              <th style="width:150px" pSortableColumn="product">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.Product" | translate }}
                  <p-sortIcon field="product"></p-sortIcon>
                </div>
              </th>
              <th style="width:150px">
                <div  class="p-d-flex p-jc-between p-ai-center">
                  {{
                  "SolicitudComponent.Create.ProductType" | translate
                  }}
                </div>
              </th>
              <th style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{
                  "SolicitudComponent.Create.CurrentWithoutTax" | translate
                  }}
                </div>
              </th>
              <th style="width:150px" *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{
                  "SolicitudComponent.WithdrawalDiferential.Differential" | translate
                  }}
                </div>
              </th>
              <th style="width:150px">
                <div  class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.CurrentPSP" | translate }}
                </div>
              </th>
              <th style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.InitialDate" | translate }}
                </div>
              </th>
              <th style="width:180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.FinalDate" | translate }}
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-object let-editing="editing" let-ri="rowIndex">
            <tr>
              <td style="width:150px" *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential">
                <i id="btnUsers"
                      class="uil uil-users-alt enviar m-1"
                      pTooltip="{{object.tooltipClient}}"
                      (click)="users(object.id, object.materialCode)"
                      aria-hidden="true"
                      *ngIf="(rolePermission.create | rolePermission)">
                    </i>
              </td>
              <td style="width: 20px">
                <p-tableCheckbox [value]="object" (click)="selectRow(e.checked,object)" #e></p-tableCheckbox>
              </td>
              <td style="width:150px">{{materialCodeZeroLess(object.materialCode) }}</td>
              <td style="width:150px">{{ object.product }}</td>
              <td style="width:150px">{{ object.zofeDescription }}</td>
              <td style="width:150px">
                <div *ngIf="object.isVisibleCurrentListPrice">
                  {{object.currentListPrice | currency: 'USD'}}
                </div>
                <div *ngIf="!object.isVisibleCurrentListPrice">
                  {{ "SolicitudComponent.Priceless" | translate }}
                </div>
              </td>
              <td style="width:150px" *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential">
                {{ object.differential }}
              </td>
              <td style="width:150px">{{ object.currentPsp | currency: "USD" }}</td>
              <td style="width:150px" *ngIf="isType == solicitudeTypeEnum.WithdrawalDiferential">{{ object.effectiveDateStart | date:'dd/MM/yyyy'}}</td>
              <td style="width:180px" *ngIf="isType != solicitudeTypeEnum.WithdrawalDiferential">
                <p-calendar
                  #cal3
                  [(ngModel)]="object.effectiveDateStart"
                  (ngModelChange)="onChangeDateStart(object)"
                  dateFormat="dd/mm/yy" [style]="{'width':'150px'}"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [showButtonBar]="true"
                  [showOtherMonths]="false"
                  [yearRange]="getYearRange()"
                  [required]="true"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [touchUI]="true"
                  [defaultDate]="minDate"
                  (onShow)="onShowDate(object)"
                  [readonlyInput]="true"
                  [showButtonBar]="true"
                  (onTodayClick)="onTodayClick(object)"
                  (onClearClick)="onClearClick(object)">
                  <p-header class="p-2">
                    <i
                    id="btnClose"
                    style="border:none"
                     class="uil uil-times enviar"
                     (click)="cal3.hideOverlay()"
                     aria-hidden="true"></i>
                  </p-header>
                </p-calendar>
              </td>
              <td style="width:150px">{{ object.effectiveDateEnd | date:'dd/MM/yyyy'}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- PrimeNg Table -->

        <!-- Buttons -->
        <div class="row mt-3 pb-3">
          <!-- <div class="col-5">
          </div> -->

          <div class="col-lg-4 col-md-4 m-1">
            <button id="btnCancel" pButton pRipple type="button"
              label="{{ 'SolicitudComponent.Withdrawal.DifferencialsAffectBtn' | translate }}"
              class="p-button-rounded pstn_secundario w-100" (click)="getSolicitudeMaterialsAffectByWitdrawal(true)"
              *ngIf="objectList && objectList.length > 0 && isType != solicitudeTypeEnum.WithdrawalDiferential"></button>
          </div>
          <div class="col-lg-1 col-md-0"></div>

          <div class="col-lg-3 col-md-4 m-1">
            <button id="btnCancel" 
              pButton 
              pRipple 
              type="button"
              label="{{ 'SolicitudComponent.Withdrawal.SendSolicitudeBtn' | translate }}"
              class="p-button-rounded pstn_secundario w-100" 
              [disabled]="objectList && objectList.length === 0"
              (click)="sendSolicitude()" *ngIf="isSend"></button>
          </div>
          <div class="col-lg-3 col-md-4 m-1">
            <button id="btnCreate" 
              pButton 
              pRipple 
              type="button"
              label="{{ 'SolicitudComponent.Withdrawal.SaveSolicitude' | translate }}"
              class="p-button-rounded pstn_primario w-100"
              [disabled]="objectList && objectList.length === 0"
              (click)="save()"
              *ngIf="rolePermission.create | rolePermission"></button>
          </div>
        </div>
      </div>
    </div>

    
    <!-- Affect by Withdrawal -->
    <div class="row mt-5 mb-5" *ngIf="objectList && objectList.length > 0 && isType != solicitudeTypeEnum.WithdrawalDiferential">
      <div class="pstn_container container-fluid">
        <p-accordion>
          <p-accordionTab
          header="{{ 'SolicitudComponent.Withdrawal.SpreadsAffectedWithdrawal' | translate }}"
          [selected]="true">
            <p-table #dt [value]="objectListSolicitudeDifferential"
            dataKey="id"
            styleClass="p-datatable-customers"
            [paginator]="true"
            [rows]="10"
            [scrollable]="true"
            paginatorPosition="bottom"
            currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
            [rowsPerPageOptions]="[10,25,50,100]"
            [globalFilterFields]="['id', 'sku', 'materialDescription', 'valueDifferential', 'accessSequence', 'dateStart', 'dateEnd']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true" ></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
          </ng-template>
    
          <ng-template pTemplate="header">
            <tr>
              <th style="width:150px">
                {{ "Actions" | translate }}
              </th>
              <th style="width:180px" pSortableColumn="id">
                <div class="p-d-flex p-jc-between p-ai-center">
                {{ "SolicitudComponent.idDifferential" | translate }}
                <p-sortIcon field="id"></p-sortIcon>
                </div>
              </th>
              
              <th style="width:180px" pSortableColumn="sku">
                <div class="p-d-flex p-jc-between p-ai-center">
                {{ "SolicitudComponent.CodigoSku" | translate }}
                <p-sortIcon field="sku"></p-sortIcon>
                </div>
              </th>
    
              <th style="width:200px" pSortableColumn="materialDescription">
                <div class="p-d-flex p-jc-between p-ai-center">
                {{"SolicitudComponent.SkuDescription" | translate }}
                <p-sortIcon field="materialDescription"></p-sortIcon>
              </div>
              </th>
    
              <th style="width:180px" pSortableColumn="valueDifferential">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.Value" | translate }}
                <p-sortIcon field="valueDifferential"></p-sortIcon>
                </div>
              </th>
    
              <th style="width:210px" pSortableColumn="accessSequence">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.AccessSequence" | translate }}
                <p-sortIcon field="accessSequence"></p-sortIcon>
              </div>
              </th>
    
              <th style="width:220px" pSortableColumn="dateStart">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.InitialDate" | translate }}
                <p-sortIcon field="dateStart"></p-sortIcon>
              </div>
              </th>
    
              <th style="width:200px" pSortableColumn="dateEnd">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.FinalDate" | translate }}
                <p-sortIcon field="dateEnd"></p-sortIcon>
              </div>
              </th>
    
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex"let-object >
            <tr>
              <td style="width:150px">
              
                <i id="btnUsers"
                      class="uil uil-users-alt enviar m-1"
                      pTooltip="{{object.tooltipClient}}"
                      (click)="usersSolicitude(object)"
                      aria-hidden="true"
                      *ngIf="(rolePermission.create | rolePermission)">
                    </i>
              </td>
              <td style="width:180px">{{object.id }}</td>
              <td style="width:180px">{{ materialCodeZeroLess(object.sku) }} </td>
              <td style="width:200px">{{ object.materialDescription }} </td>
              <td style="width:180px">{{ object.valueDifferential}}</td>
              <td style="width:210px">{{object.accessSequence }}</td>
              <td style="width:220px">{{object.dateStart | date: 'dd/MM/yyyy'}}</td>
              <td style="width:200px">{{object.dateEnd | date: 'dd/MM/yyyy'}}</td>
    
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td *ngIf="hasDifferentialData" colspan="6">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>

  </div>
</section>

  <!-- Modal Asignacion de Clientes -->
  <p-dialog [(visible)]="modalUser" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}">

    <ng-template pTemplate="header">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: left;">
        <h5><strong>{{ 'EliminateDifferentialComponent.AssignedClients' | translate}}</strong></h5>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div *ngIf="objectListClient.length > 0">
        <p-table #dt 
        name="gridAdvancedClientFilters" 
        [value]="objectListClient" 
        styleClass="p-datatable-customers">
        <ng-template pTemplate="body" let-object>
          <tr>
            <td>{{object.typeDescription }}</td>
            <td>{{object.contentDescription}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="3">{{ 'ElementsNotFound' | translate }}</td></tr>
        </ng-template>
      </p-table>
      </div>

      <div *ngIf="CSVFile.length > 0" class="row">         
        <div class="col-md-6">
          {{ "AssingClient.TotalRecords" | translate }} &nbsp;
          <strong style="font-size: 22px">{{ totalRecordsDownload }}</strong>
        </div>

        <div class="col-md-4">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            label="{{ 'Download' | translate }}"
            class="p-button-outlined p-button-rounded"
            (click)="fileDownloadCSV()"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-8 mt-1"></div>
        <div class="col-md-4 mt-1"> 
          <button pButton 
                  pRipple
                  type="button"
                  label="{{ 'CloseBtn' | translate}}"
                  class="pstn_primario w-100"
                  (click)="cancelUser()">
          </button>
        </div> 
      </div>
    </ng-template>
  </p-dialog>
<p-toast></p-toast>
