import { IRedeemableFilterMaterials } from '../interfaces/redeemable-filter-materials.interface';

export class RedeemableFilterMaterialsModel implements IRedeemableFilterMaterials{
    constructor(
        public hierarchyFilter: string,
        public materialCode? : string,
        public id?: string,
        public group?: string,
        public dataHierarchy?: string,
        public type?: boolean
      ) {}
}