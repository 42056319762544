import { ISubGroupItem } from '../../interfaces/sub-group-item.interface';

export class SubDiscountFinancial implements ISubGroupItem {
    constructor(
        public descSku?: string,
        public id?: string,
        public sku?: string,
        public unitMeasure?: string,
        public percentageDiscountFinancial?: string,
        public check?: boolean
    ) { }
}
