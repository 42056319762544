import { environment } from '../../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataRangeModel } from '@app/@components/commercial-activity-footer/models/data-range.model';
import { IHierarchyFiltersMaterials } from '@app/@components/hierarchy-advanced-filters/interfaces/hierarchy-filters-materials.interface';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { ManagementExclusionEnum } from '@app/@shared/enums/management-exclusion.enum';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { PaginationByIdModel } from '@app/@shared/model/pagination-by-id.model';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { DomainService } from '@app/@shared/services/domain.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { SweetAlertResult } from 'sweetalert2';
import { ManagePromotionalActivitiesService } from '../../manage-promotional-activities/manage-promotional-activities.service';
import { ManagePromotionalActivitiesMessage } from '../../manage-promotional-activities/messages/manage-promotional-activities-messages.message';
import { ProductGroupListModel } from '../../manage-promotional-activities/promotional-activities-create/models/product-group-list.model';
import { ExclusionsService } from '../exclusions.service';
import { IExclusionChannel, IExclusionDetailActivity, IExclusionMaterial } from '../interfaces/exclusions.interface';
import { IProductList } from '../interfaces/product-list.interface';
import { IProductGroupList } from '../interfaces/products-groups-list.interface';
import { ExclusionInformationModel } from '../models/exclusionInformation.model';
import { MeasurementUnit } from '@app/@shared/enums/measurement-unit.enum';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';

@Component({
  selector: 'app-exclusions-create',
  providers: [MessageService],
  templateUrl: './exclusions-create.component.html',
  styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
    .optionsForm{
      display:flex
    }

    .without-border{
      padding-left: 0px;
    }

    .container-table.header{
      padding-left: 29px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #009CDD;
      font-weight: 700;
      font-size: 19px;
      margin-bottom:50px;
    }
    .container-table.options{
      padding-left: 29px;
      font-weight: 400;
      font-size: 14px;
    }
    .container_size {
      width : 82%;
    }
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
  ],
})
export class ExclusionsCreateComponent implements OnInit {
  private readonly fb: FormBuilder;
  private readonly domainService: DomainService;
  private readonly coreService: CoreMasterService;
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly router: Router;
  private readonly route: ActivatedRoute;
  private readonly exclusionService: ExclusionsService;

  // #region Define Variables
  public isProcess: string;

  public get comercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  public jsonFilter: string;
  public jsonFilterClients: string;
  public formExclusions: FormGroup;
  public objectChannel: ICombo[] = [];
  public objectPromotionalActivity: ICombo[] = [];
  public isMultiFilter = true;
  public editFiltersObjectList: IHierarchyFiltersMaterials[] = [];

  public isPromotion = false;
  public isDiscountKind = false;

  public advancedFiltersObjectList: IHierarchyFiltersMaterials[] = [];
  public isPurchaseBase = false;

  public isExclusionClients = false;
  public hiddenBiggestPromoBuy = false;

  public invalidDates: Date[] = [];
  public today: Date = new Date();
  public currentYear = 0;
  public endYear = 0;
  public rangeDateData: DataRangeModel = new DataRangeModel;
  public oldRangeDateData: DataRangeModel = new DataRangeModel;

  // #endregion


  // Products in group table
  public productGroupFilterList: IProductGroupList[] = [];
  public selectProductHeadGroupList: IProductGroupList[] = [];
  public selectProductList: IProductList[] = [];
  // Promotional Products
  public productGroupFilterListPromotion: IProductGroupList[] = [];
  public selectProductHeadGroupListPromotion: IProductGroupList[] = [];
  public selectProductListPromotion: IProductList[] = [];
  public productFilterListPromotion: IProductList[] = [];
  public IdExclusion: number;

  public filterClientJson: string;
  public rolePermission: any = RolePermissionEnum;

  constructor(
    fb: FormBuilder,
    domainService: DomainService,
    coreService: CoreMasterService,
    spinner: NgxSpinnerService,
    messageService: MessageService,
    managePromotionActService: ManagePromotionalActivitiesService,
    router: Router,
    route: ActivatedRoute,
    exclusionService: ExclusionsService,
  ) { 
      this.fb = fb;
      this.domainService = domainService;
      this.coreService = coreService;
      this.spinner = spinner;
      this.messageService = messageService;
      this.managePromotionActService = managePromotionActService;
      this.router = router;
      this.route = route;
      this.exclusionService = exclusionService;
  }

  ngOnInit(): void {

    this.initForm();
    this.isProcess = this.route.snapshot.paramMap.get('isProcess');
    this.IdExclusion = parseInt(this.route.snapshot.paramMap.get('objectDetail'), 10);
    this.loadInfomartion(this.isProcess, this.IdExclusion);
  }


  /**
    * @description this method execute async requests.
    * @param isProcess
    * @param processObject
    * @author Santiago Martinez
    * @sprint 11
    */
  public loadInfomartion(isProcess: any, processObject: any) {
    this.spinner.show();

    const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
    const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);

    forkJoin([
      channelResponse,
      processResponse
    ])
      .subscribe(
        (result) => {

          const objChannelResponse: IResponseService<ICoreMaster[]> = result[0];
          const objProcessResponse: IResponseService<IDomain[]> = result[1];

          this.objectChannel = objChannelResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });

          this.objectPromotionalActivity = objProcessResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          if (isProcess === ManagementExclusionEnum.detail || isProcess === ManagementExclusionEnum.edit) {
            this.loaderFormDetails(processObject);
          }
        }, () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
  }


  /**
   * @description this method prepares the loading of information for the form
   *
   * @param id
   * @author Santiago Martinez
   * @sprint 11
   *
   */
  public loaderFormDetails(id: any) {
    this.exclusionService.getExclusionWithDetailById(id).subscribe(
      (result: any) => {
        if (Object.keys(result.data).length !== 0 && result.data.constructor === Object) {
          this.ReasonExclusion.setValue(result.data.exclusionReason);
          this.TypeComercialActivity.setValue(this.mapExclusionDetailActivity(result.data.exclusionDetailActivity));
          this.Channel.setValue(this.mapExclusionChannel(result.data.exclusionChannel));
          this.changeTypeOfPrice();
          this.jsonFilter = result.data.jsonFilter;
          this.jsonFilterClients = result.data.jsonFilterClients;
          this.rangeDateData = this.getProcessEnum() === this.comercialActivityEnum.update || this.getProcessEnum() === this.comercialActivityEnum.view ? result.data.rangeDateData : new DataRangeModel();
          this.oldRangeDateData = this.getProcessEnum() === this.comercialActivityEnum.update || this.getProcessEnum() === this.comercialActivityEnum.view ? result.data.rangeDateData : new DataRangeModel();
          this.setValueDataRange();
          this.editFiltersObjectList = JSON.parse(this.jsonFilter);
          const paginationById = new PaginationByIdModel('Id', true, 1, 1000, id);
          this.exclusionService.getExclusionMaterial(paginationById).subscribe(
            (materialResponse) => {
              if (materialResponse && materialResponse.data.length) {
                this.productFilterListPromotion = materialResponse.data.map((material) => {
                  return {
                    product: material.description,
                    exclusionId: material.exclusionId,
                    id: material.id,
                    materialCode: material.materialCode,
                    zofeCode: material.zofeCode,
                    zofeDescription: material.zofeDescription
                  };
                });
              }
            });
        }
      }
    );

  }


  /**
   * @description this method maps the loaded channels of an exclusion
   *
   * @param exclusionsChannel
   * @author Santiago Martinez
   * @sprint 11
   *
   */

  public mapExclusionChannel(exclusionsChannel: any[]) {
    return exclusionsChannel.map((channel) => {
      return `${channel.channelCode}`;
    });
  }

  /**
   * @description this method maps the activity details loaded from an exclusion
   *
   * @param exclusionsDetailActivity
   * @author Santiago Martinez
   * @sprint 11
   *
   */

  public mapExclusionDetailActivity(exclusionsDetailActivity: any[]) {
    return exclusionsDetailActivity.map((activity) => {
      if (activity.typeCommercialActivityDomainId === 12) {
        this.Purchase.setValue(activity.indPurchase);
        this.Promotion.setValue(activity.indPromos);
        this.PromoClient.setValue(activity.promoByClient);
        this.BiggestPromoBuy.setValue(activity.biggestPromotionPurchase);
      } else {
        if (activity.typeCommercialActivityDomainId === 13) {
          this.CapDiscount.setValue(activity.discountPercentageCap);
        }
      }
      return activity.typeCommercialActivityDomainId;
    });
  }


  /**
   * @description this method is to list channel
   * @author Leonardo Andres Herrera Benavides
   * @sprint 11
   */
  getDataChannel(): void {
    this.spinner.show();
    this.coreService.getAll(TypeCoreMaster.Channel).subscribe((result) => {
      this.spinner.hide();
      const objChannelResponse: IResponseService<ICoreMaster[]> = result;
      objChannelResponse.data.forEach((elemet) => {
        this.objectChannel.push({
          label: elemet.description,
          value: elemet.code
        });
      });

    });
  }

  /**
   * @description this method is to get all the society.
   * @author sgonzalezj
   * @sprint 8
   */
  getPromotionalActivity(): void {
    this.spinner.show();
    this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess).subscribe(
      (response) => {
        this.spinner.hide();
        this.objectPromotionalActivity = response.data.filter((f) => f.code === 'AP').map((item) => {
          return {
            label: item.description,
            value: item.id
          };
        });
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
   * @description This method is to verify if needed
   * multiFilter or not
   *
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  onSelectTypePromotion(): void {
    this.isMultiFilter = this.TypeComercialActivity.value !== 43;
  }

  /**
   * @description This method for event for filter.
   *
   * @author Leonardo Andres Herrera
   * @sprint 11
   */
  eventClickFilters($event: any): void {
    if (this.isPurchaseBase && this.productGroupFilterList.length > 0) {
      let dataZero = 0;
      let dataNoZero = 0;
      this.productGroupFilterList.forEach((elm) => {
        let sum = 0;

        if (sum === 0) {
          dataNoZero = 2;
        }

      });
      if (dataZero === 1) {
        this.alertSelectedProductsBasedOnPurchaseWithoutParameterization();
        return;
      } else {
        if (dataNoZero === 2) {
          this.alertProductsAlreadyParameterizedBasedOnPurchase();
          return;
        }
      }
    }
    if (!this.isPurchaseBase && this.productGroupFilterListPromotion.length > 0) {
      let dataZero = 0;
      let dataNoZero = 0;
      this.productGroupFilterListPromotion.forEach((elm) => {
        let sum = 0;
        elm.products.forEach((itm) => {
          if (itm.purchaseUnit === 0) {
            sum += 1;
          }
        });
        if (sum === 0) {
          dataNoZero = 2;
        }
        if (sum > 0 && sum !== elm.products.length) {
          dataZero = 1;
        }
      });
      if (dataZero === 1) {
        this.alertSelectedProductsInPromotionWithoutParameterizing();
        return;
      } else {
        if (dataNoZero === 2) {
          this.alertProductsAlreadyParameterizedPromotionProduct();
          return;
        }
      }
    }

    if ($event.advancedFiltersObjectList.length > 0) {
      this.spinner.show();
      this.advancedFiltersObjectList = $event.advancedFiltersObjectList;
      this.jsonFilter = JSON.stringify($event.advancedFiltersObjectList);
      const newFilterModel = new FilterDataModel(
        JSON.stringify($event.advancedFiltersObjectList),
        environment.conditionTable,
        environment.conditionClassCode,
        false
      );
      this.getMaterialsByFilters(newFilterModel);
    } else {
      this.alertAddRequiredType();
    }
  }

  private alertAddRequiredType() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS : ManagePromotionalActivitiesMessage.NOT_FILTERS_TRANSLATE);
  }

  private alertSelectedProductsBasedOnPurchaseWithoutParameterization() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? SweetAlert.messageFilterEvents : SweetAlert.messageFilterEventsTranslate);
  }

  private alertSelectedProductsInPromotionWithoutParameterizing() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? 'Existen productos seleccionados en promoción sin parametrizar unidades compra, verifique y eliminelos si desea aplicar otro filtro.'
        : 'There are selected products in promotion without parameterizing purchase units, check and delete them if you want to apply another filter.');
  }

  private alertProductsAlreadyParameterizedPromotionProduct() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? 'Ya se han parametrizado productos en promoción, verifique y eliminelos si desea aplicar otro filtro.'
        : 'Promotional products have already been parameterized, check and delete them if you want to apply another filter.');
  }

  private alertProductsAlreadyParameterizedBasedOnPurchase() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? 'Ya se han parametrizado productos en base compra, verifique y eliminelos si desea aplicar otro filtro.'
        : 'Products have already been parameterized on a purchase basis, check and delete them if you want to apply another filter.');
  }

  /**
   * @description This method for get the filters.
   *
   * @author Leonardo Andres Herrera
   * @sprint 11
   * @param filters
   */
  private getMaterialsByFilters(filters: FilterDataModel): void {
    this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
      (response) => {
        if (response.data.length > 0) {
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`
            : `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`);

          const listProducts: IProductList[] = response.data.map((item: any) => {
            return {
              check: false,
              product: item.product,
              hierarchy: item.hierarchy,
              purchaseBaseId: 0,
              materialCode: item.materialCode,
              measurementUnitCode: '',
              purchaseUnit: 0,
              porcent: 0,
              discountValue: 0,
              id: this.comercialActivityEnum.create ? item.id : 0,
              currentPsp: item.currentPsp,
              zofeCode: item.zofeCode,
              zofeDescription : item.zofeDescription
            };
          });
          this.productGroupFilterList = this.groupProductsToShow(listProducts);
          //this.productGroupFilterListPromotion = this.groupProductsToShow(listProducts);;
          this.productFilterListPromotion = listProducts;
        } else {
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE);
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  /**
	 * @description This method is to group by hierarchy the purchase base
	 * and promotional products list
	 *
	 * @param listProducts
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private groupProductsToShow(listProducts: IProductList[]): IProductGroupList[] {
		const groups: IProductGroupList[] = [];
		if (listProducts.length > 0) {
			listProducts.forEach((product: IProductList) => {
					const filterFound = this.advancedFiltersObjectList.find((fFilter) => fFilter.hierarchyFilter === product.hierarchy);
					let newGroup: ProductGroupListModel = null;
					if (this.isPurchaseBase) {
						newGroup = new ProductGroupListModel(
							product.hierarchy, // groupName
							0, // commercialActivityId
							0, // purchaseUnit
							MeasurementUnit.PIECE, // measurementUnitCode
							true, // isGroup,
							JSON.stringify(filterFound), // groupByJson
							0, // id
							[product], // purchaseBaseDetails
							[] // purchaseBasePromotionDetails
						);
					} else {
						newGroup = new ProductGroupListModel(
							product.hierarchy, // groupName
							0, // commercialActivityId
							0, // purchaseUnit
							MeasurementUnit.PIECE, // measurementUnitCode
							true, // isGroup,
							JSON.stringify(filterFound), // groupByJson
							0, // id
							[], // purchaseBaseDetails
							[product] // purchaseBasePromotionDetails
						);
					}
					groups.push(newGroup);
			});
		}
		return groups;
	}

  /**
     * @description this method filters the group of elements to eliminate
     *
     * @param element
     * @author Leonardo Andres Herrera
     * @sprint 11
     *
     */
  public deleteGroupAction(element: IProductList) {

    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        if (element) {
          this.productFilterListPromotion = this.productFilterListPromotion.filter((x) => x.id !== element.id);
        } else {
          this.productFilterListPromotion = this.productFilterListPromotion.filter((x) => x.check === false);
        }
        this.spinner.hide();
      }
    });
  }


  public showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  changeTypeOfPrice() {
    if (this.TypeComercialActivity.value.includes(12)) {
      this.isPromotion = true;
      this.hiddenBiggestPromoBuy = false;
    } else {
      this.isPromotion = false;
      this.hiddenBiggestPromoBuy = false;
    }

    if (this.TypeComercialActivity.value.includes(13)) {
      this.isDiscountKind = true;
    } else {
      this.isDiscountKind = false;
    }
  }

  /**
     * @description this method prepares and sends the information to store exclusion
     *
     * @param
     * @author Leonardo Andres Herrera
     * @sprint 11
     *
  */
  eventClickGetDataSave($event: any) {
    this.spinner.show();
    if (this.formExclusions.valid) {
      const exclusionDetailActivity = this.formatDataForm();
      const exclusionMaterial = this.formatDataMaterial();
      const eclusionChannel = this.formatDataChannel();
      this.filterClientJson = $event.jsonFilterClient;
      const model: ExclusionInformationModel = new ExclusionInformationModel();
      model.ExclusionReason = this.ReasonExclusion.value;
      model.JsonFilter = this.jsonFilter;
      model.exclusionDetailActivity = exclusionDetailActivity;
      model.exclusionChannel = eclusionChannel;
      model.exclusionMaterial = exclusionMaterial;
      model.jsonFilterClients = this.filterClientJson;
      model.exclusionCustomer = $event.listClient == null ? null : $event.listClient;
      model.rangeDateData = this.rangeDateData === undefined ? {} : this.rangeDateData ;

      this.exclusionService.postExclusion(model).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.status) {
            this.formExclusions.reset();
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconSuccess,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
            this.viewDetails();
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            this.formExclusions.reset();
          }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          this.formExclusions.reset();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }
  }
  /**
     * @description this method prepares and sends the information for exclusion editing.
     *
     * @param
     * @author Leonardo Andres Herrera
     * @sprint 11
     *
     */
  eventClickUpdateBtn($event: any) {
    this.spinner.show();
    if (this.formExclusions.valid) {
      const exclusionDetailActivity = this.formatDataForm();
      const exclusionMaterial = this.formatDataMaterial();
      const eclusionChannel = this.formatDataChannel();
      const model: ExclusionInformationModel = new ExclusionInformationModel();
      model.id = this.IdExclusion;
      model.ExclusionReason = this.ReasonExclusion.value;
      model.JsonFilter = this.jsonFilter;
      model.exclusionDetailActivity = exclusionDetailActivity;
      model.exclusionChannel = eclusionChannel;
      model.exclusionMaterial = exclusionMaterial;
      model.jsonFilterClients = $event.jsonFilterClient;
      model.exclusionCustomer = $event.listClient;
      model.rangeDateData = this.rangeDateData;

      this.exclusionService.putExclusion(model).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.status) {
            this.formExclusions.reset();
            const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
            this.viewDetails();
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }
  }

  public viewDetails() {
    this.router.navigate(['/auth/exclusion']);
  }

  /**
  * @description this method is to initialize the form.
  * @author Leonardo Andres Herrera Benavides
  * @sprint 11
  */

  formatDataForm(): IExclusionDetailActivity[] {
    const exclusionDetailActivity: IExclusionDetailActivity[] = [];
    if (this.TypeComercialActivity.value) {
      this.TypeComercialActivity.value.forEach((element: any) => {
        exclusionDetailActivity.push({
          TypeCommercialActivityDomainId: element,
          IndPurchase: element === 12 ? this.Purchase.value : false,
          IndPromos: element === 12 ? this.Promotion.value : false,
          BiggestPromotionPurchase: element === 12 ? this.BiggestPromoBuy.value : false,
          PromoByClient: element === 12 ? this.PromoClient.value : 0,
          DiscountPercentageCap: element === 13 ? this.CapDiscount.value : 0,
        });
      });
    }
    return exclusionDetailActivity;
  }

  /**
  * @description this method is to initialize data materials to the form.
  * @author Leonardo Andres Herrera Benavides
  * @sprint 11
  */

  formatDataMaterial(): IExclusionMaterial[] {
    const exclusionMaterial: IExclusionMaterial[] = [];
    this.productFilterListPromotion.forEach((element: any) => {
      exclusionMaterial.push({
        MaterialCode: element.materialCode
      });
    });

    return exclusionMaterial;
  }

  /**
  * @description this method is to initialize data channel to the form.
  * @author Leonardo Andres Herrera Benavides
  */
  formatDataChannel(): IExclusionChannel[] {
    const exclusionChannel: IExclusionChannel[] = [];
    if (this.Channel.value) {
      this.Channel.value.forEach((element: any) => {
        exclusionChannel.push({
          ChannelCode: element
        });
      });
    }
    return exclusionChannel;
  }

  validResult() {
    return this.ReasonExclusion.valid &&
      this.TypeComercialActivity.valid &&
      this.Channel.valid &&
      this.productFilterListPromotion.length > 0 &&
      this.jsonFilter ? false : true;
  }


  keyUpCapDiscount(event: any) {
    if (event.target.value <= 100) {
      this.CapDiscount.setValue(event.target.value);
    } else {
      const num = event.target.value.substr(0, event.target.value.length - 1);
      this.CapDiscount.setValue(num);
    }
  }


  keyupReasonExclusion(event: any) {
    const value = Util.keyupLetterWithNumbersValidation(event);
    this.ReasonExclusion.setValue(value);
  }

  getProcessEnum(): number {
    switch (this.isProcess) {
      case 'create':
        return ComercialActivityEnum.create;
      case 'edit':
        return ComercialActivityEnum.update;
      case 'detail':
        return ComercialActivityEnum.view;
      default: break;
    }
  }

  next() {
    if (this.formExclusions.valid) {
      this.isExclusionClients = true;
    }
  }

  back() {
    this.isExclusionClients = false;
  }


  /**
   * @description this method set year value date picker
   *
   * @param exclusionsChannel
   * @author Jhon Pavon
   * @sprint 15
   *
   */
  public getYearRange() {
    this.currentYear = this.today.getFullYear();
    this.endYear = this.currentYear + 10;
    return `${this.currentYear}:${this.endYear}`;
  }


  /**
   * @description this method add value data range model
   *
   * @param exclusionsChannel
   * @author Jhon Pavon
   * @sprint 15
   *
   */
  public addRangeDate(event: any) {
    if (this.rangeDateControl.value == null || this.rangeDateControl.value.length === 0) {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.info(
        language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
        language === 'es-ES' ? SweetAlert.messageRankDate : SweetAlert.messageRankDateTranslate);
    } else {
      const startDate = new Date(this.rangeDateControl.value[0]);
      const endDate = new Date(this.rangeDateControl.value[1]);
      const days = parseInt(`${(this.getDateDiff(startDate, endDate) + 1)}`, 10);

      this.rangeDateData = new DataRangeModel(
        startDate,
        endDate,
        days,
        this.getProcessEnum() === ComercialActivityEnum.update ? this.oldRangeDateData.id : 0,
      );

    }
  }

  /**
   * @description this method set value  date range model
   *
   * @param exclusionsChannel
   * @author Andres Herrera
   * @sprint 15
   *
   */
  public setValueDataRange() {
    const dateStart = new Date(this.rangeDateData.dateStart);
    const dateEnd = new Date(this.rangeDateData.dateEnd);
    const arrDates = [dateStart, dateEnd];
    this.rangeDateControl.setValue(arrDates);
  }

  /**
  * @description this method clear contro data range model
  *
  * @param exclusionsChannel
  * @author Jhon Pavon
  * @sprint 15
  *
  */
  clear() {
    this.rangeDateControl.reset();
    this.rangeDateData = new DataRangeModel();
  }

  /**
  * @description This method is to get days between two dates
  *
  * @param start
  * @param end
  * @author Diego Mauricio Cortés
  * @sprint 8
  */
  getDateDiff(start: Date, end: Date) {
    return Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  }

  /**
  * @description this method is to initialize the form.
  * @author Leonardo Andres Herrera Benavides
  * @sprint 11
  */
  initForm(): void {
    this.formExclusions = this.fb.group({
      ReasonExclusion: [{ value: '', disabled: false }, [Validators.required]],
      Channel: [{ value: '', disabled: false }, [Validators.required]],
      TypeComercialActivity: [{ value: '', disabled: false }, [Validators.required]],
      rangeDateControl: [{ value: '', disabled: false }],
      Purchase: [{ value: false, disabled: false }],
      Promotion: [{ value: false, disabled: false }],
      BiggestPromoBuy: [{ value: false, disabled: false }],
      PromoClient: [{ value: 0, disabled: false }],
      CapDiscount: [{ value: 0, disabled: false }],
    });
  }


  get ReasonExclusion(): AbstractControl { return this.formExclusions.get('ReasonExclusion'); }
  get TypeComercialActivity(): AbstractControl { return this.formExclusions.get('TypeComercialActivity'); }
  get Channel(): AbstractControl { return this.formExclusions.get('Channel'); }
  get rangeDateControl(): AbstractControl { return this.formExclusions.get('rangeDateControl'); }
  get Purchase(): AbstractControl { return this.formExclusions.get('Purchase'); }
  get Promotion(): AbstractControl { return this.formExclusions.get('Promotion'); }
  get BiggestPromoBuy(): AbstractControl { return this.formExclusions.get('BiggestPromoBuy'); }
  get PromoClient(): AbstractControl { return this.formExclusions.get('PromoClient'); }
  get CapDiscount(): AbstractControl { return this.formExclusions.get('CapDiscount'); }

}
