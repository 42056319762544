import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { PaginationByIdModel } from '@app/@shared/model/pagination-by-id.model';
import { ApprovalRankService } from './approval-rank.service';
import { IApprovalRankJson } from './interfaces/approval-rank-json.interface';
import { Util } from '@app/@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ApplicationConstants } from '@app/app.constants';
import { ApprovalRankStatusModel } from './models/approval-rank-status.model';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';

@Component({
  selector: 'app-approval-ranks',
  templateUrl: './approval-rank.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService],
})
export class ApprovalRankComponent implements OnInit {
  private readonly approvalRankService: ApprovalRankService;
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly router: Router;

  public objectList: IApprovalRankJson[];
  public rolePermission: any = RolePermissionEnum;

  constructor(
    approvalRankService: ApprovalRankService,
    spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router
  ) {
      this.approvalRankService = approvalRankService;
      this.spinner = spinner;
      this.messageService = messageService;
      this.router = router;
  }

  /**
   * @description this method is private.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  ngOnInit(): void {
    this.approvalRankService.setApprovalRankId(0);
    this.getAll();
  }

  /**
   * @description this method is to get all the Approval Rank.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  getAll(): void {
    this.spinner.show();

    const paginationById = new PaginationByIdModel('Id', true, 1, 1000, 0);

    this.approvalRankService.getListApprovalRank(paginationById).subscribe(
      (response) => {
        this.objectList = response.data;
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  /**
   * @description this method is to see record.
   * @author Priscy Antonio Reales
   * @sprint 7
   */
   onSeeRecord(object: IApprovalRankJson): void {
    Util.confirmSee().then((result: SweetAlertResult) => {
      if (result.value) {
        this.approvalRankService.setApprovalRankId(object.id);
        this.approvalRankService.setIsJustLook(true);
        this.router.navigate(['auth/approval-rank/create-edit']);
      }
    });
  }

  /**
   * @description this method is to edit.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  onEdit(object: IApprovalRankJson): void {
    Util.confirmEdit().then((result: SweetAlertResult) => {
      if (result.value) {
        this.approvalRankService.setApprovalRankId(object.id);
        this.approvalRankService.setIsJustLook(false);
        this.router.navigate(['auth/approval-rank/create-edit']);
      }
    });
  }

  /**
   * @description this method is to delete.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  onDelete(object: IApprovalRankJson): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.approvalRankService.deleteLogic(object.id).subscribe(
          (response) => {
            this.spinner.hide();
            if (response.status) {
              this.getAll();
              const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
            } else {
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });
  }

  /**
   * @description this method change state of domain.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  changeState($event: any, object: IApprovalRankJson): void {
    Util.confirmEditStatus().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();

        const statusModel: ApprovalRankStatusModel =
          new ApprovalRankStatusModel(object.id, $event.checked);

        this.approvalRankService
          .approvalRankUpdateState(object.id, statusModel)
          .subscribe(
            (respose) => {
              if (respose.status){
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconWarningToast,
                  language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate);
              }
              else {SweetAlertMessageHelpers.showMessage(TypeMessage.Error);}
            },
            (error) => {
              SweetAlertMessageHelpers.exception(error);
            },
            () => {
              this.spinner.hide();
            }
          );
      } else {
        object.status = !$event.checked;
      }
    });
  }

  /**
   * @description This method shows the toast control.
   * @author Priscy Antonio Reales
   * @sprint 6
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }
}