import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INotificationUser } from '../interfaces/notification-user.interface';
import { DomainTypeSecurity } from '@app/@shared/enums/domain-type-security.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SolicitudeTypeDescription } from '@app/@shared/enums/solicitude-type-description.enums';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { LanguageCalendarHelper } from '@app/@shared/helpers/language-calendar.helper';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { PaginationNotificationUserModel } from '@app/@shared/model/paginationNotificationUser.model';
import { DomainService } from '@app/@shared/services/domain.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { ISolicitudeNotificationUser } from '../interfaces/solicitude-notification-user.interface';
import { ISolicitudeNotification } from '../interfaces/solicitude-notification.interface';
import { SolicitudeCreationModel } from '../models/solicitude-creation.model';
import { SolicitudeMaterialRequestModel } from '../models/solicitude-material-request.model';
import { SolicitudeMaterialModel } from '../models/solicitude-material.model';
import { SolicitudeNotificationRequestModel } from '../models/solicitude-notification-request.model';
import { SolicitudeModel } from '../models/solicitude.model';
import { SolicitudeService } from '../solicitude.service';
import { Router } from '@angular/router';
import { SolicitudeMessage } from '../messages/solicitude.message';
import { IAdvancedFiltersMaterial } from '@app/@shared/interfaces/advanced-filters-material.interface';
import { AdvancedFiltersMaterialModel } from '@app/@shared/model/advanced-filters-material.model';
import { environment } from '@env/environment';
import { IAdvancedFiltersMaterialsResult } from '@app/@shared/interfaces/advanced-filters-material-result.interface';
import { parse } from 'date-fns';
import { ManagePromotionalActivitiesMessage } from '@app/@modules/promotionalactivity/manage-promotional-activities/messages/manage-promotional-activities-messages.message';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RankNumber } from '@app/@shared/enums/rank-numbers.enum';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { AssingClientEnums } from '@app/@shared/enums/assing-client.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { TypeMaterial } from '@app/@shared/enums/type-material.enums';
import { SolicitudeFileModel } from '../models/solicitude-file.model';
import { ISolicitudeJson } from '../interfaces/solicitude-json.interface';
import { FileBaseHelper } from '@app/@shared/helpers/file-base.helper';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { TypeOfLoadExcelEnum } from '@app/@components/upload-excel/enums/type-of-load-excel.enum';
import { HelperService } from '@app/@shared/services/helper.service';
import { ISolicitudeDetailDifferentialWithDrawal } from '../interfaces/solicitude-differential-withdrawal.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';

@Component({
    selector: 'app-withdrawal-solicitude',
    templateUrl: './withdrawal-solicitude.component.html',
    styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
    styles: [
        `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
    ],
    providers: [MessageService, DatePipe],
})
export class WithdrawalSolicitudeComponent implements OnInit, OnDestroy {
    @ViewChild('fileUpload') fileUpload: any;

  private readonly spinner: NgxSpinnerService;
  private readonly fb: FormBuilder;
  private readonly messageService: MessageService;
  private readonly domainService: DomainService;
  readonly solicitudeService: SolicitudeService;
  private readonly router: Router;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly manageParametersService: ManageParametersService;
  private readonly miDatePipe: DatePipe;
  private readonly fileBaseHelper: FileBaseHelper;
  private readonly helperService: HelperService;

  public filterResult: IAdvancedFiltersMaterialsResult;
  public es: any;
  public solicitudeType: SolicitudeType;
  public solicitudAll: ISolicitudeJson;
  public solicitudeTypeEnum = SolicitudeType;
  public solicitudeStatus: SolicitudeStatus;
  public solicitudeId: number;
  public filterTypeName = 'PriceListWithdrawal';
  public typeFiltersObjectList: ICombo[];
  public objectList: SolicitudeCreationModel[];
  public selectSolicitude: SolicitudeCreationModel[];
  public formSolicitude: FormGroup;
  public activeAccordion = true;
  public isSend: boolean;
  public objectListNotificationUser: INotificationUser[];
  public solicitudeCode: number;
  public solicitudeStatusEnum = SolicitudeStatus;
  public stop$ = new Subject<void>();
//   public isVisibleCurrentListPrice = true;
  public defaultDateSAP = new Date('9999-12-31');
  public isProcess = 0;
  public isType: number;
  public assingClientEnums = AssingClientEnums;
  public tomorrow: Date = new Date();
  public rolePermission : any = RolePermissionEnum;
  public today: Date = new Date();
  public setMaterialType : string[] = [TypeMaterial.fert];
  public uploadedFiles: any[] = [];
  public downloadFile: SolicitudeFileModel;
  public nameFile = '';
  public modalUser: boolean = false;
  public objectListClient : any = [];
  public CSVFile : string[] = [];
  public totalRecordsDownload = 0;
  public attachedFilePdf: string = undefined;
  public codeCreationSession : string;
  public isChangeActa: boolean = false;
  public reason: string = '';
  public filterClientJson : string = '';
  public typeOfLoadFileExcel : any = TypeOfLoadExcelEnum;
  public minDate : Date = new Date();
  public maxDate : Date = new Date();
  public objectListSolicitudeDifferential: ISolicitudeDetailDifferentialWithDrawal[];
  public hasDifferentialData: boolean = false;


  constructor(
    spinner: NgxSpinnerService,
    fb: FormBuilder,
    messageService: MessageService, 
    domainService: DomainService,
    solicitudeService: SolicitudeService,
    router: Router,
    managePromotionActService: ManagePromotionalActivitiesService,
    manageParametersService: ManageParametersService,
    miDatePipe: DatePipe,
    fileBaseHelper: FileBaseHelper,
    helperService: HelperService
  ) { 
    this.spinner = spinner;
    this.fb = fb;
    this.messageService = messageService;
    this.domainService = domainService;
    this.solicitudeService = solicitudeService;
    this.router = router;
    this.managePromotionActService = managePromotionActService;
    this.manageParametersService = manageParametersService;
    this.miDatePipe = miDatePipe;
    this.fileBaseHelper = fileBaseHelper;
    this.helperService = helperService;
  }

  ngOnInit(): void {
    
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.isProcess = this.solicitudeService.getIsCreate();
    if(this.isProcess == AssingClientEnums.create){
        this.codeCreationSession = sessionStorage.getItem(CommonConfiguration.SolicitudeCodeCreation);
    }
    this.manageParametersService.setIntervalAutosave();
    this.es = LanguageCalendarHelper.spanish();
    this.isType = this.solicitudeService.getSolicitudType();
    this.solicitudeType = SolicitudeType.Withdrawal;
    this.solicitudeStatus = SolicitudeStatus.Temporary;
    this.spinner.show();
    this.getSolicitudeById();
    this.getTypeFiltersCreation();
    this.getSolicitudeMaterialRulesAffectByWithdrawal(false);
    this.initForm();
  }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  stopInterval(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  /**
   * @description This method is for validate date control.
   *
   * @author Priscy Reales
   * @sprint 15
   */

getYearRange() {
    return `${this.getActuallyYear(0)}:${this.getActuallyYear(10)}`;
  }

  getActuallyYear(years : number) : number{
    const thisDate = new Date();
    return thisDate.getFullYear() + years;
  }

 /**
 * @description Date change event to move the selected ones.
 * @author Daniel Londoño
 * @sprint 5
 */
  onChangeDateStart(object: SolicitudeCreationModel) {
    if(object.effectiveDateStart === null || object.effectiveDateStart === undefined){
          object.effectiveDateStart = new Date(object.temporalDateEnd);
        }

    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === object.id);
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
          if(object.effectiveDateStart <= x.effectiveDateEnd){
            x.effectiveDateStart = object.effectiveDateStart;
          }
        });
      }
    }
  }

  onShowDate(object: SolicitudeCreationModel){
    this.maxDate = object.effectiveDateEnd;  

    let today = this.miDatePipe.transform(new Date(),'yyyy-MM-dd');
    let effectiveDateStartOrigin = this.miDatePipe.transform(object.effectiveDateStartOrigin,'yyyy-MM-dd');

    if(effectiveDateStartOrigin > today){
      this.minDate = new Date(object.effectiveDateStartOrigin);
    }
    else{
      this.minDate = new Date();
    }
  }

  onTodayClick(object: SolicitudeCreationModel){
    let today = this.miDatePipe.transform(new Date(),'yyyy-MM-dd');
    let effectiveDateStartOriginString = this.miDatePipe.transform(object.effectiveDateStartOrigin,'yyyy-MM-dd');

    if(effectiveDateStartOriginString > today){
      object.effectiveDateStart = object.effectiveDateStartOrigin;
    }
    else{
      object.effectiveDateStart = new Date();
    }
  }

  onClearClick(object: SolicitudeCreationModel){
    object.effectiveDateStart = new Date(object.temporalDateEnd);
  }

  /**
  * @description Verify change end date to prices, and adjust from SAP
  * @author Diego Mauricio Cortés
  * @sprint 16
  */
  validateDefaultDate(solicitud: SolicitudeCreationModel) {
    if (solicitud.effectiveDateEnd && solicitud.effectiveDateEnd.getFullYear() === 9999) {
        solicitud.effectiveDateEnd.setFullYear(this.tomorrow.getFullYear());
        if(solicitud.effectiveDateEnd.getMonth() === this.tomorrow.getMonth() &&
        solicitud.effectiveDateEnd.getDay() < this.tomorrow.getDay()){
          solicitud.effectiveDateEnd.setDate(this.tomorrow.getDate());
        }
      }
      if(!solicitud.effectiveDateEnd){
        solicitud.effectiveDateEnd = new Date(9999,11,31);
      }
  }

  /**
   * @description This method for event for filter.
   * @author Priscy Antonio Reales
   * @sprint 4
   */
  eventClickFilters($event: any) {
    if ($event.advancedFiltersObjectList.length > 0) {
      this.spinner.show();
      const newFilterModel = new FilterDataModel(
        JSON.stringify($event.advancedFiltersObjectList),
        environment.conditionTable,
        environment.conditionClassCode,
        false
      );
      this.getMaterialsByFilters(newFilterModel);
    } else {
        const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS : SolicitudeMessage.NOT_FILTERS_TRANSLATE);
    }
    this.spinner.hide();
  }

    /**
     * @description This method for get the filters.
     * @author Elkin Vasquez Isenia
     * @sprint 7
     * @param filters
     */
    public advancedFiltersMaterials(filters: string): void {
        const objFilters: IAdvancedFiltersMaterial =
            new AdvancedFiltersMaterialModel(
                filters,
                environment.conditionTable,
                environment.conditionClassCode,
                'Id',
                true,
                1,
                1000
            );

        this.solicitudeService.postAdvancedFiltersMaterials(objFilters)
            .subscribe(
                (response) => {

                    this.filterResult = response.data;

                    if (this.filterResult.totalRecords > 0) {

                        const language: string = sessionStorage.getItem('language');
                        this.showToast(SweetAlert.iconSuccess,
                            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                            language === 'es-ES' 
                            ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${this.filterResult.totalRecords}`: `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${this.filterResult.totalRecords}`);

                        const listFilters = this.filterResult.results.map((item) => {
                            return {
                                id: item.id,
                                solicitudeId: 1,
                                materialCode: item.materialCode,
                                product: item.product,
                                currentListPrice: parseFloat(item.currentPsp),
                                currentPsp: 0,
                                newPriceWithOutTax: 0,
                                priceAdjustment: 0,
                                newPsp: 0,
                                effectiveDateStart: parse(item.effectiveDateStart, 'dd/MM/yyyy', new Date()),
                                effectiveDateEnd: parse(item.effectiveDateEnd, 'dd/MM/yyyy', new Date()),
                                zofeCode: item.zofeCode,
                                zofeDescription: item.zofeDescription
                            };
                        });

                        this.objectList = [...listFilters];
                        this.objectList.forEach((x: SolicitudeCreationModel) => {
                            if (x.currentListPrice === RankNumber.Zero) {
                                x.currentListPrice = RankNumber.One;
                                x.isVisibleCurrentListPrice = false;
                            }else {
                                x.isVisibleCurrentListPrice = true;
                            }
                        });
                        const model: SolicitudeMaterialRequestModel =
                            new SolicitudeMaterialRequestModel(
                                this.getSolicitudeModel(),
                                this.getListSolicitudeMaterialModel()
                            );

                        this.solicitudeService
                            .postSolicitudeWhitSolicitudeMaterial(model)
                            .subscribe(
                                (response) => {
                                    this.spinner.hide();
                                    if(response.status){
                                        sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
                                        this.solicitudeService.setSolicitudeId(response.data);
                                        this.getSolicitudeById();
                                    }else{
                                        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
                                    }
                                },
                                (error: HttpErrorResponse) => {
                                    this.spinner.hide();
                                    SweetAlertMessageHelpers.exception(error);
                                }
                            );

                    } else {
                        const language: string = sessionStorage.getItem('language');
                        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                            language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS_RESULT : SolicitudeMessage.NOT_FILTERS_RESULT_TRANSLATE);
                    }

                },
                (error: HttpErrorResponse) => {
                    this.spinner.hide();
                    SweetAlertMessageHelpers.exception(error);
                },
                () => {
                    this.spinner.hide();
                }
            );
    }

    /**
     * @description this method is to get Solicitude with SolicitudeMaterial By Id.
     * @author Priscy Antonio Reales
     * @sprint 1
     */
    getSolicitudeById() {
        this.spinner.show();
        const id = this.solicitudeService.getSolicitudeId();
        if (!id) {
            this.spinner.hide();
            return;
        }
        let tooltip = '';
        this.solicitudeService.getSolicitudeWithDetailById(id).subscribe(
            (response) => {
                if (response.status) {
                    this.solicitudAll = response.data;
                    this.activeAccordion = false;
                    this.solicitudeStatus = response.data.status;
                    this.solicitudeType = response.data.type;
                    this.solicitudeCode = response.data.code;
                    this.reason = response.data.message;
                    this.codeCreationSession = response.data.codeCreation;
                    this.filterClientJson = response.data.filterClientJson;

                    this.objectList = [];
                      if(response.data.solicitudeDetailJson){
                        this.objectList = response.data.solicitudeDetailJson.map((item) => {
                            return {
                                id: item.detailId,
                                solicitudeId: response.data.id,
                                materialCode: item.sku,
                                product: item.materialDescription,
                                currentListPrice: item.currentListPrice,
                                currentPsp: item.currentPsp,
                                newPriceWithOutTax: item.newPriceWithOutTax,
                                priceAdjustment: item.priceAdjustment,
                                newPsp: item.newPsp,
                                effectiveDateStart: new Date(item.effectiveDateStart),
                                effectiveDateEnd: new Date(item.effectiveDateEnd),
                                guid: item.guid,
                                zofeCode: item.zofeCode,
                                zofeDescription: item.zofeDescription,
                                temporalDateEnd: new Date(item.effectiveDateStart),
                                differential: item.differential,
                                csvClients : item.csvClients,
                                filterClient: item.filterClient,
                                solicitudeIdOrigin : item.solicitudeIdOrigin,
                                statusDomainId: item.statusDomainId,
                                solicitudeMaterialIdOrigin : item.solicitudeMaterialIdOrigin,
                                effectiveDateStartOrigin: new Date(item.effectiveDateStartOrigin)
                            };
                        });
                    }
                    this.tooltipClient();
                    this.objectList.forEach((x: SolicitudeCreationModel) => {
                        if (x.currentListPrice === RankNumber.Zero) {
                            x.currentListPrice = RankNumber.One;
                            x.isVisibleCurrentListPrice = false;
                        }else {
                            x.isVisibleCurrentListPrice = true;
                        }
                    });

                    if(this.solicitudAll.solicitudeFileJson){
                        this.downloadFile = new SolicitudeFileModel(
                            this.solicitudAll.solicitudeFileJson[0].id,
                            this.solicitudAll.solicitudeFileJson[0].solicitudeId,
                            this.solicitudAll.solicitudeFileJson[0].route,
                            this.solicitudAll.solicitudeFileJson[0].file,
                            this.solicitudAll.solicitudeFileJson[0].hash,
                        );
                        this.nameFile = this.downloadFile.route;
                    }

                    this.validateData();
                } else {
                    this.spinner.hide();
                    SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
                }
            },
            (error) => {
                this.spinner.hide();
                SweetAlertMessageHelpers.exception(error);
            },
            () => {
                this.spinner.hide();
                this.autoSave();
            }
        );
    }

    tooltipClient(){
        this.objectList.forEach((x) => {
            if(x.filterClient){
                let description = JSON.parse(x.filterClient);
                if(description.length > 1){
                    x.tooltipClient = description[0].contentDescription + '/' + description[1].contentDescription;
                }
                if(description.length == 1){
                    x.tooltipClient = description[0].contentDescription;
                }
            }
            if(x.csvClients){
                x.tooltipClient  = 'Archivo CSV';
            }
        });
    }

    /**
     * @description This method return model Solicitude.
     * @author Priscy Antonio Reales
     * @sprint 5
     */
    getSolicitudeModel(): SolicitudeModel {
        return new SolicitudeModel(
            this.solicitudeService.getSolicitudeId(),
            this.solicitudeType,
            this.solicitudeStatus,
            0,
            this.codeCreationSession,
            this.filterClientJson,
            this.reason
        );
    }

    getSolicitudFile(): SolicitudeFileModel{
        if (this.uploadedFiles[0]) {
            return new SolicitudeFileModel(
              null,
              0,
              this.uploadedFiles[0].name,
              this.attachedFilePdf,
              ''
            );
          }
    }

    /**
     * @description This method return model SolicitudeMaterial List.
     * @author Priscy Antonio Reales
     * @sprint 5
     */
    getListSolicitudeMaterialModel(): SolicitudeMaterialModel[] {

        if (this.objectList) {
            const modelDetail: SolicitudeMaterialModel[] = this.objectList.map(
                (item) => {
                    return {
                        id: 0,
                        solicitudeId: this.solicitudeService.getSolicitudeId(),
                        materialCode: item.materialCode,
                        currentListPrice: item.currentListPrice,
                        currentPsp: item.currentPsp,
                        newPriceWithOutTax: item.newPriceWithOutTax,
                        priceAdjustment: item.priceAdjustment,
                        newPsp: item.newPsp,
                        effectiveDateStart: new Date(item.effectiveDateStart),
                        effectiveDateEnd: new Date(item.effectiveDateEnd),
                        guid: item.guid,
                        solicitudeIdOrigin: item.solicitudeIdOrigin,
                        solicitudeMaterialIdOrigin : item.solicitudeMaterialIdOrigin,
                        statusDomainId: item.statusDomainId,
                        effectiveDateStartOrigin: new Date(item.effectiveDateStartOrigin)
                    };
                }
            );
            return modelDetail;
        }
        return [];

    }

    /**
     * @description This method for get type filters creation.
     * @author Priscy Antonio Reales
     * @sprint 4
     */
    public getTypeFiltersCreation(): void {
        this.domainService
            .getAllByDomainType(Microservice.Security, DomainTypeSecurity.TypeFilterCreation)
            .subscribe(
                (response) => {
                    this.typeFiltersObjectList = response.data.map((item) => {
                        return {
                            label: item.description,
                            value: item.code,
                        };
                    });
                },
                (error) => {
                    this.spinner.hide();
                    SweetAlertMessageHelpers.exception(error);
                },
                () => {
                    this.spinner.hide();
                }
            );
    }

    /**
     * @description This method for delete material.
     * @author Priscy Antonio Reales
     * @sprint 5
     */
    public delete(): void {
        if(!this.selectSolicitude || this.selectSolicitude.length === 0 ){
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
                language === 'es-ES' ? `${SolicitudeMessage.NOT_SELECTED_ITEM}`
                : `${SolicitudeMessage.NOT_SELECTED_ITEM_TRANSLATE}`);
            return;
        }
        Util.confirmDelete().then((result: SweetAlertResult) => {
            if (result.value) {
                this.spinner.show();
                const Ids = this.selectSolicitude.map((item: SolicitudeCreationModel) => { return item.id; }).join();
                if (!Ids) {
                    this.spinner.hide();
                    return;
                }

                const auxProductsSelect = [...this.selectSolicitude];
                auxProductsSelect.forEach((element, index) => {
                const indexSolicitude = this.objectList.findIndex((prd) => prd.id === element.id);
                const indexSolicitudeSelect = this.selectSolicitude.findIndex((prd) => prd.id === element.id);
                if (indexSolicitude > -1) {
                    this.objectList.splice(indexSolicitude, 1);
                    this.selectSolicitude.splice(indexSolicitudeSelect, 1);
                }
                });

                this.solicitudeService.deleteSolicitudeMaterialByIds(Ids).subscribe(
                    (response) => {
                        if (response.status) {
                            this.getSolicitudeById();
                            const language: string = sessionStorage.getItem('language');
                            this.showToast(SweetAlert.iconInfo,
                            language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                            language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
                            this.spinner.hide();
                        } else {
                            this.spinner.hide();
                            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.spinner.hide();
                        SweetAlertMessageHelpers.exception(error);
                    }
                );
            }
        });
    }

    /**
     * @description This method for save solicitude and materials.
     * @author Priscy Antonio Reales
     * @sprint 5
     */
    public async save() {
      Util.confirmSave().then(async (result: SweetAlertResult) => {
        if (result.value) {
          await this.saveWithValidations().then(
            async (response) => {
              if(response.status){
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconSuccess,
                    language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                    language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
                this.getSolicitudeById();
                this.router.navigate(['auth/solicitude']);
              }else{
                  SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
              }
              this.spinner.hide();
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
        }
      });
    }

    /**
     * @description Save with validations
     * @author WArboleda
     * @sprint 63
     */
    private async saveWithValidations() : Promise<IResponseService<number>>{
      this.spinner.show();

      if (this.solicitudeStatus === SolicitudeStatus.Temporary) {
        this.solicitudeStatus = SolicitudeStatus.Registered;
      } else {
        if (this.solicitudeStatus === SolicitudeStatus.Rejected) {
            this.solicitudeStatus = SolicitudeStatus.Modification;
        }
      }

      const model: SolicitudeMaterialRequestModel =
        new SolicitudeMaterialRequestModel(
            this.getSolicitudeModel(),
            this.getListSolicitudeMaterialModel(),
            this.getSolicitudFile()
        );

      if (!model.solicitudeMaterial || model.solicitudeMaterial.length === 0) {
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.info(SweetAlert.titleAlertError,
            language === 'es-ES' ? SolicitudeMessage.NO_EXIST_MATERIALS
            : SolicitudeMessage.NO_EXIST_MATERIALS_TRANSLATE);
        this.spinner.hide();
        return;
      }

      if(((this.isChangeActa && this.uploadedFiles[0] == undefined) || (!this.isChangeActa && this.downloadFile == null)) && this.isType == SolicitudeType.WithdrawalDiferential){
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.info(SweetAlert.titleAlertError,
            language === 'es-ES' ? SolicitudeMessage.NO_PDF
            : SolicitudeMessage.NO_PDF_TRANSLATE);
        this.spinner.hide();
        return;
      }

      return await this.solicitudeService.putSolicitudeWhitSolicitudeMaterial(this.solicitudeService.getSolicitudeId(), model).toPromise();
    }

    /**
     * @description This method for autosave solicitude and materials.
     * @author Priscy Antonio Reales
     * @sprint 10
     */
    public autoSave() {
        if(this.solicitudeService.getSolicitudeId() === 0)
            return;
        const intervalSecond = this.manageParametersService.getIntervalAutosave();
        if (intervalSecond && intervalSecond > 0) {
            const subcribeInterval = interval(intervalSecond * 1000);
            subcribeInterval
                .pipe(takeUntil(this.stop$))
                .subscribe(() => {

                    const model: SolicitudeMaterialRequestModel =
                        new SolicitudeMaterialRequestModel(
                            this.getSolicitudeModel(),
                            this.getListSolicitudeMaterialModel()
                        );

                    if (model.solicitudeMaterial.length > 0) {
                        this.validationSolicitusStatus(model);
                    }
                });
        }
    }

    /**
     * @description this method is to send Solicitude.
     * @author Priscy Antonio Reales
     * @sprint 7
     */
    sendSolicitude(): void {
        Util.confirmSendSolicitude().then((result: SweetAlertResult) => {
            if (result.value) {
                this.spinner.show();

                
                if(((this.isChangeActa && this.uploadedFiles[0] == undefined) || (!this.isChangeActa && this.downloadFile == null)) && this.isType == SolicitudeType.WithdrawalDiferential){
                    const language: string = sessionStorage.getItem('language');
                    SweetAlertMessageHelpers.info(SweetAlert.titleAlertError,
                        language === 'es-ES' ? SolicitudeMessage.NO_PDF
                        : SolicitudeMessage.NO_PDF_TRANSLATE);
                    this.spinner.hide();
                    return;
                }
                let typeSolicitude = `${SolicitudeType.Withdrawal}-${SolicitudeTypeDescription.Withdrawal}`;
                if(this.isType == SolicitudeType.WithdrawalDiferential)
                    typeSolicitude = `${SolicitudeType.WithdrawalDiferential}-${SolicitudeTypeDescription.WithdrawalDiferential}`;

                const paginationNotificationUser = new PaginationNotificationUserModel(
                    'id',
                    true,
                    1,
                    100,
                    typeSolicitude
                );

                this.solicitudeService
                    .getListNotificationUser(paginationNotificationUser)
                    .subscribe(
                        (response) => {
                            if (response.status) {

                                if (response.data == null || response.data.length === 0) {
                                    this.spinner.hide();
                                    const language: string = sessionStorage.getItem('language');
                                    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                                        language === 'es-ES' ? SolicitudeMessage.NO_EXIST_USERS
                                        : SolicitudeMessage.NO_EXIST_USERS_TRANSLATE);
                                    return;
                                }

                                this.objectListNotificationUser = response.data;

                                const objSolicitudeNotification = {} as ISolicitudeNotification;

                                objSolicitudeNotification.solicitudeId = this.solicitudeService.getSolicitudeId();
                                objSolicitudeNotification.notificationId = this.objectListNotificationUser[0].id;
                                objSolicitudeNotification.notificationTypeId = this.objectListNotificationUser[0].notificationTypeId;
                                objSolicitudeNotification.notificationTypeCode = this.objectListNotificationUser[0].notificationTypeCode;
                                objSolicitudeNotification.notificationTypeDescription = this.objectListNotificationUser[0].notificationTypeDescription;
                                objSolicitudeNotification.notificationDescription = this.objectListNotificationUser[0].notificationDescription;
                                objSolicitudeNotification.moduleId = this.objectListNotificationUser[0].moduleId;
                                objSolicitudeNotification.moduleCode = this.objectListNotificationUser[0].moduleCode;
                                objSolicitudeNotification.moduleDescription = this.objectListNotificationUser[0].moduleDescription;
                                objSolicitudeNotification.typeSolicitude = this.objectListNotificationUser[0].typeSolicitude;
                                objSolicitudeNotification.isSendEmail = this.objectListNotificationUser[0].isSendEmail;
                                objSolicitudeNotification.enableCommentary = this.objectListNotificationUser[0].enableCommentary;
                                objSolicitudeNotification.color = this.objectListNotificationUser[0].color;
                                objSolicitudeNotification.subject = `${this.objectListNotificationUser[0].subject} ${this.solicitudeService.getSolicitudeId()}`;
                                objSolicitudeNotification.bodyMail = this.objectListNotificationUser[0].bodyMail
                                    .replace('#[NoficationType]#', objSolicitudeNotification.notificationTypeDescription.toString())
                                    .replace('#[Solicitude]#', this.solicitudeService.getSolicitudeId().toString())
                                    .replace('#[Module]#', objSolicitudeNotification.moduleDescription.toString());


                                const objSolicitudeNotificationUser: ISolicitudeNotificationUser[] =
                                    this.objectListNotificationUser[0].userInformation.map((item) => {
                                        return {
                                            user: item.user,
                                            userEmail: item.userEmail,
                                        };
                                    });

                                const model: SolicitudeNotificationRequestModel =
                                    new SolicitudeNotificationRequestModel(
                                        objSolicitudeNotification,
                                        objSolicitudeNotificationUser,
                                        this.solicitudeService.getSolicitudeId()
                                    );

                                this.solicitudeService.postSolicitudeNotification(model).subscribe(
                                    (responsePost) => {
                                        const language: string = sessionStorage.getItem('language');
                                        this.showToast(SweetAlert.iconSuccess,
                                            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                                            language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
                                        this.router.navigate(['auth/solicitude']);
                                    },
                                    (error: HttpErrorResponse) => {
                                        this.spinner.hide();
                                        SweetAlertMessageHelpers.exception(error);
                                    },
                                    () => {
                                        this.spinner.hide();
                                    }
                                );

                                this.spinner.hide();
                            } else {
                                this.spinner.hide();
                                const language: string = sessionStorage.getItem('language');
                                SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                                    language === 'es-ES' ? SolicitudeMessage.NOT_NOTIFICATION_CONFIG
                                    : SolicitudeMessage.NOT_NOTIFICATION_CONFIG_TRANSLATE);
                            }
                        },
                        (error: HttpErrorResponse) => {
                            SweetAlertMessageHelpers.exception(error);
                        }
                    );
            }
        });
    }

    /**
     * @description This method is to validate data.
     * @author Priscy Antonio Reales
     * @sprint 7
     * @returns none
     */
    validateData(): void {
        this.isSend = false;
        if (this.solicitudeStatus === SolicitudeStatus.Registered || this.solicitudeStatus === SolicitudeStatus.Modification) {
            this.isSend = true;
        }
    }

    /**
     * @description This method shows the toast control.
     * @author Priscy Antonio Reales
     * @sprint 5
     * @param severity
     * @param message
     * @param detail
     * @returns none
     */
    showToast(severity: string, message: string, detail: string) {
        this.messageService.clear();
        this.messageService.add({
            severity: severity,
            summary: message,
            detail: detail,
            life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
        });
    }

    /**
     * @description This method for get the filters.
     * @author Priscy Antonio Reales
     * @sprint 10
     * @param filters
     */
     private getMaterialsByFilters(filters: FilterDataModel): void {
        this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
            (response) => {
                if (response.data.length > 0) {
                    const language: string = sessionStorage.getItem('language');
                    this.showToast(SweetAlert.iconSuccess,
                        language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                        language === 'es-ES' 
                        ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`: `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`);
                    const listFilters = response.data.map((item: any) => {
                        return {
                            id: item.id,
                            solicitudeId: 0,
                            materialCode: item.materialCode,
                            product: item.product,
                            currentListPrice: parseFloat(item.currentPsp),
                            currentPsp: 0,
                            newPriceWithOutTax: 0,
                            priceAdjustment: 0,
                            newPsp: 0,
                            effectiveDateStart: this.validateDateStart(parse(item.effectiveDateStart,'dd/MM/yyyy', new Date())),
                            effectiveDateEnd: parse(item.effectiveDateEnd, 'dd/MM/yyyy', new Date()),
                            guid: String(Util.newGuid()),
                            zofeCode: item.zofeCode,
                            zofeDescription: item.zofeDescription,
                            effectiveDateStartOrigin: parse(item.effectiveDateStart, 'dd/MM/yyyy', new Date())
                        };
                    });

                    this.objectList = [...listFilters];

                    this.objectList.forEach((x: SolicitudeCreationModel) => {
                        if (x.currentListPrice === RankNumber.Zero) {
                            x.currentListPrice = RankNumber.One;
                            x.isVisibleCurrentListPrice = false;
                        }else {
                            x.isVisibleCurrentListPrice = true;
                        }
                    });

                    const model: SolicitudeMaterialRequestModel =
                        new SolicitudeMaterialRequestModel(
                            this.getSolicitudeModel(),
                            this.getListSolicitudeMaterialModel()
                        );

                    this.solicitudeService
                        .postSolicitudeWhitSolicitudeMaterial(model)
                        .subscribe(
                            (response) => {
                                this.spinner.hide();
                                if(response.status){
                                    this.solicitudeService.setSolicitudeId(response.data);
                                    sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
                                    this.getSolicitudeById();
                                }else{
                                    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
                                }
                            },
                            (error: HttpErrorResponse) => {
                                this.spinner.hide();
                                SweetAlertMessageHelpers.exception(error);
                            }
                        );
                } else {
                    const language: string = sessionStorage.getItem('language');
                    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                        language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE);
                }
                this.spinner.hide();
            }, (error) => {
                this.spinner.hide();
                console.error(error);
            }
        );
    }

    private validationSolicitusStatus(model: SolicitudeMaterialRequestModel) {
        if (this.solicitudeStatus === SolicitudeStatus.Temporary) {
            this.solicitudeService
                .postSolicitudeWhitSolicitudeMaterial(model)
                .subscribe(
                    (response) => {
                        if (response.status) {
                            sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
                            this.messageAutoSave();
                        }else{
                            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message);
                        }
                    },
                    (error: HttpErrorResponse) => {
                        SweetAlertMessageHelpers.exception(error);
                    }
                );
        } else {
            this.validationDifferentState(model);
        }
    }

    private validationDifferentState (model: SolicitudeMaterialRequestModel) {
        if (this.solicitudeStatus === SolicitudeStatus.Registered
            || this.solicitudeStatus === SolicitudeStatus.Modification
            || this.solicitudeStatus === SolicitudeStatus.Rejected) {
            this.solicitudeService.putSolicitudeWhitSolicitudeMaterial(this.solicitudeService.getSolicitudeId(), model)
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.messageAutoSave();
                        }else{
                            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
                        }
                    },
                    (error: HttpErrorResponse) => {
                        SweetAlertMessageHelpers.exception(error);
                    }
                );
        }
    }

    private messageAutoSave() {
        const language: string = sessionStorage.getItem('language');
        this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' ? SolicitudeMessage.AUTO_SAVE : SolicitudeMessage.AUTO_SAVE_TRANSLATE);
    }

    users(id: number, material: string){
        this.modalUser = true;
        this.objectListClient = [];
        this.CSVFile = [];
        let data = this.objectList.find((x) => x.id == id && x.materialCode == material);

        if(data.filterClient){
            this.objectListClient = JSON.parse(data.filterClient);
        }

        if(data.csvClients){
        this.CSVFile = data.csvClients;
        this.totalRecordsDownload = this.CSVFile.length;
        }
    }


    usersSolicitude(solicitude : ISolicitudeDetailDifferentialWithDrawal){
        this.modalUser = true;
        this.objectListClient = [];
        this.CSVFile = [];

        if(solicitude.filterClient){
            this.objectListClient = JSON.parse(solicitude.filterClient);
        }

        if(solicitude.csvClients){
        this.CSVFile = solicitude.csvClients;
        this.totalRecordsDownload = this.CSVFile.length;
        }
    }

    cancelUser(){
        this.modalUser = false;
        this.objectListClient = [];
        this.CSVFile = [];
      }


    async onUploadFile(event: any) {
        if (this.downloadFile) {
          Util.confirmEditFile().then(async (result: SweetAlertResult) => {
            if (result.value) {
                this.isChangeActa = true;
              this.downloadFile = null;
              this.uploadFile(event);
            }
            else {
              this.fileUpload.clear();
              this.isChangeActa = false;
            }
          });
        }
        else {
          this.uploadFile(event);
        }
    }

    async uploadFile(event: any) {
        this.uploadedFiles = [];
        for (const file of event.files) {
          this.uploadedFiles.push(file);
        }
        this.attachedFilePdf = await this.fileBaseHelper.getFileBase(this.uploadedFiles[0]);
      }

    onRemoveFileImage(): void {
    this.uploadedFiles = [];
    }

    fileDownloadPDF() {
        const byteArray = new Uint8Array(atob(this.downloadFile.file).split('').map((char) => char.charCodeAt(0)));
    
        const a = document.createElement('a');
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.nameFile;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }

      fileDownloadCSV() {
        const csv = typeof this.CSVFile[0] === 'string' ? this.CSVFile : this.CSVFile.map((row: any) => JSON.stringify(row.clientCode));
        const csvArray = csv.join('\r\n');
        const language: string = sessionStorage.getItem('language');
    
        const a = document.createElement('a');
        const blob = new Blob([csvArray], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
    
        a.href = url;
        a.download = language === 'es-ES' 
                                    ? ManagePromotionalActivitiesMessage.CSV_CLIENTS_SOLICITUDE_ASSIGNED 
                                    : ManagePromotionalActivitiesMessage.CSV_CLIENTS_SOLICITUDE_ASSIGNED_TRANSLATE;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }

    refreshDetailByFile(id : number){
        this.solicitudeService.setSolicitudeId(id);
        this.getSolicitudeById();
    }

    materialCodeZeroLess(value: string): string{
        return this.helperService.materialCodeZeroLess(value);
    }

    selectRow(value: any, itemSelect : SolicitudeCreationModel){
        if(this.isType == SolicitudeType.WithdrawalDiferential)
        {
            const duplicateItem = this.objectList.filter((x) => x.materialCode == itemSelect.materialCode && x.solicitudeIdOrigin == itemSelect.solicitudeIdOrigin);
            if(!value){
                duplicateItem.forEach((i) => {
                    var indexOfObject  = this.selectSolicitude.findIndex((item) => { return item.solicitudeId == i.solicitudeId && item.materialCode == i.materialCode});
                    if (indexOfObject  !== -1) {
                        this.selectSolicitude.splice(indexOfObject, 1);
                    }
                    this.selectSolicitude = [...this.selectSolicitude];
                });
            }else{
                this.selectSolicitude = [...this.selectSolicitude, ...duplicateItem];
                this.selectSolicitude = [...new Map(this.selectSolicitude.map((item) => [item, item])).values()];
            }
        }
    }
    
    /**
     * @description this method is to initialize the form.
     * @author Priscy Antonio Reales
     * @sprint 1
     */
    initForm(): void {
        this.formSolicitude = this.fb.group({
            solicitudeDetail: this.fb.array([]),
            effectiveDateEnd: [{ value: '', disabled: false }, [Validators.required]],
        });
    }

    get solicitudeDetail(): FormArray { return this.formSolicitude.get('solicitudeDetail') as FormArray; }


    /**
     * @description  Get Solicitude Material RulesAffected By Withdrawal
     * @author WArboleda
     * @sprint 63
     */
    async getSolicitudeMaterialsAffectByWitdrawal(isOnClick : boolean = false) : Promise<void>{
        await this.saveWithValidations();
        this.getSolicitudeMaterialRulesAffectByWithdrawal(isOnClick);
    }

    /**
     * @description Call service to get Solicitude Material RulesAffected By Withdrawal
     * @author WArboleda
     * @sprint 63
     */
    private getSolicitudeMaterialRulesAffectByWithdrawal(isOnClick: boolean) : void {
      this.spinner.show();
      const solicitudeId = this.solicitudeService.getSolicitudeId();
      if (!solicitudeId) {
        this.spinner.hide();
        return;
      }

      this.solicitudeService.getSolicitudeMaterialRulesAffectByWithdrawal(solicitudeId).subscribe(
        (response) => {
            if (response.status) {
                this.spinner.hide();
                if(response.data && response.data.length > 0){
                    this.objectListSolicitudeDifferential = response.data;

                    this.objectListSolicitudeDifferential.forEach((x) => {
                      if(x.filterClient){
                        let description = JSON.parse(x.filterClient);
                        if(description.length > 1){
                          x.tooltipClient = description[0].contentDescription + '/' + description[1].contentDescription;
                        }else if(description.length === 1){
                          x.tooltipClient = description[0].contentDescription;
                        }
                      }
                      if(x.csvClients){
                        x.tooltipClient = 'Archivo CSV';
                      }
                    });
                  }
                  else{
                    this.objectListSolicitudeDifferential = [];
                    if (isOnClick){
                      const language: string = sessionStorage.getItem('language');
                      this.showToast(SweetAlert.iconInfo,
                        language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate,
                        language === 'es-ES' ? SweetAlert.messageSpreadsAffect
                                              : SweetAlert.messageSpreadsAffectTranslate);
                    }
                  }
                  this.validateElementsDifferencialNotFound();

            } else {
                this.spinner.hide();
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
        },
        (error) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
        },
        () => {
            this.spinner.hide();
        }
      );
    }

    /**
     * @description Validate Elements no Found
     * @author WArboleda
     * @sprint 63
     */
    private validateElementsDifferencialNotFound() : void{
      if(this.objectListSolicitudeDifferential === undefined || this.objectListSolicitudeDifferential.length <= 0){
        this.hasDifferentialData = true;
      }else{
        this.hasDifferentialData = false;
      }
    }

    /**
     * @description Validate date start
     * @author WArboleda
     * @sprint 63
     */
    private validateDateStart(currentDate: Date) : Date{
        let today = new Date();

        return currentDate > today ? currentDate : today;
    }
}


