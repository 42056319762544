import { IDataRange } from '@components/commercial-activity-footer/interfaces/data-range.interface';
import { ICommercialActivityCustomerJson } from '../../interfaces/commercial-activity-custommer-json.interface';
import { ICommercialActivitySector } from '../../interfaces/commercial-activity-sector.interface';
import { ICustomer } from '../../promotional-activities-create/interfaces/customer-list.interface';
import { IChannel } from '../interfaces/channel-interface';
import { ICommercialActivityZeroValueProduct } from '../interfaces/commercial-activity-zero-value-product.interface';
import { IProductGroupList } from '../interfaces/product-group-list.interface';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';


export class CommercialActivityZeroValueProductModel implements ICommercialActivityZeroValueProduct {

	constructor(
		public typeCommercialActivityDomainId?: number,
		public typePromotionalActivityDomainId?: number,
		public statusDomainId?: number,
		public description?: string,
		public startTime?: string,
		public endTime?: string,
		public orderLimit?: number,
		public periodLimit?: number,
		public numberDays?: number,
		public id?: number,
		public purchaseBases?: IProductGroupList[],
		public purchaseBasePromotions?: IProductGroupList[],
		public commercialActivityDateRanges?: IDataRange[],
		public commercialActivityChannels?: IChannel[],
		public isCalculate?: boolean,
		public commercialActivitySector?: ICommercialActivitySector[],
		public totalClientsFilter?: number,
		public uidCalculateProcess?: string,
		public filterClientJson?: string,
		public commercialActivityCustomers?: ICustomer[],
		public commercialActivityCustomerJson?: ICommercialActivityCustomerJson[],
		public commercialActivityObservation?: ICommercialActivityDocument[]
	) { }

}
