import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IManageParameters } from './interfaces/manage-parameters.interface';
import { ManageParametersModel } from './models/manage-parameters.model';

const endpointController = '/api/v1/ccvp_apwb_secu/manage_parameters/';

@Injectable({
  providedIn: 'root'
})
export class ManageParametersService {
  private readonly httpService: HttpService;
  private intervalAutosave: number;

  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  /**
   * @description This method is to return interval for second autosave storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 10
   */
   getIntervalAutosave(): number {
    return this.intervalAutosave;
  }

  /**
   * @description This method is to set interval for second autosave storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 10
   */
   setIntervalAutosave(): void {
    this.getById(ManagementParameterEnum.intervalAutoSave)
    .subscribe(
      (response)=>{
        if(response.status){
          this.intervalAutosave = parseInt(response.data.value, 10);
        }
      }
    );
  }

  /**
  * @description This method is to get one element by id the ManageParameter.
  * @author smartinezr
  * @param none
  * @returns Observable<IResponseService<IAccessManagement[]>>
  * @sprint 1
  */
  public getById(id: any): Observable<IResponseService<IManageParameters>> {
    const response$: Observable<IResponseService<IManageParameters>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}${endpointController}${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IManageParameters>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to get all the Manage Parameters.
   * @author djgil
   * @param none
   * @returns Observable<IResponseService<IManageParameters[]>>
   * @sprint 6
   */
  public getAll(): Observable<IResponseService<IManageParameters[]>> {
    const response$: Observable<IResponseService<IManageParameters[]>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}${endpointController}1/100`)
      .pipe(
        map((response: HttpResponse<IResponseService<IManageParameters[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to create Manage Parameters.
 * @author djgil
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 6
 */
  public post(body: ManageParametersModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
    this.httpService
      .post(
        `${environment.apiUrlSecurity}${endpointController}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to update Manage Parameters.
 * @author djgil
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 6
 */
  public put(id: number, body: ManageParametersModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .put(
        `${environment.apiUrlSecurity}${endpointController}${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to delete a GracePeriod.
 * @author djgil
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 6
 */
  public deleteLogic(id: number): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService.delete(`${environment.apiUrlSecurity}${endpointController}logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
