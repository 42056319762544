import { IDataRange } from '@app/@components/commercial-activity-footer/interfaces/data-range.interface';
import { IChannel } from '../../commercial-activity-loyalty/interfaces/channel-interface';
import { IProductGroupList } from '../../commercial-activity-loyalty/interfaces/product-group-list.interface';
import { ICommercialActivitySector } from '../../interfaces/commercial-activity-sector.interface';
import { ICommercialActivityRedemption } from '../interfaces/commercial-activity-redemption.interface';
import { IRedeemableCommercialActivity } from '../interfaces/redeemable-commercial-activity.interface';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';

export class CommercialActivityRedemptionModel implements ICommercialActivityRedemption {

	constructor(
		public typeCommercialActivityDomainId?: number,
		public typePromotionalActivityDomainId?: number,
		public statusDomainId?: number,
		public description?: string,
		public startTime?: string,
		public endTime?: string,
		public orderLimit?: number,
		public periodLimit?: number,
		public numberDays?: number,
		public id?: number,
		public consideration?: number,
		public periodSettlement?: number,
		public typeMeasure?: number,
		public accumulatedValue?: number,
		public purchaseBases?: IProductGroupList[],
		public purchaseBasePromotions?: IProductGroupList[],
		public commercialActivityDateRanges?: IDataRange[],
		public commercialActivityChannels?: IChannel[],
		public commercialActivityRedeemable?: IRedeemableCommercialActivity[],
		public isCalculate?: boolean,
		public commercialActivitySector?: ICommercialActivitySector[],
		public commercialActivityObservation?: ICommercialActivityDocument[]
	) { }

}
