import { Component, OnInit } from '@angular/core';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { environment } from '@env/environment';
import { UserSession } from '@app/@shared/model/user-session.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ApplicationConstants } from '@app/app.constants';
import { LoginService } from './login.service';
import { Logger } from '../logger.service';
import { UserNameModel } from '../../@shared/model/user-name.model';
import { MsalService } from '@azure/msal-angular';
import { MonitoringService } from '../monitoring.service';

const log = new Logger('LoginComponent');
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  public loggedIn = false;
  public environmentName = '';
  public version = '';

  private readonly router: Router;
  private readonly spinnerService: NgxSpinnerService;
  private readonly authService: MsalService;
  private readonly messageService: MessageService;
  private readonly monitoringService: MonitoringService;
  private readonly loginService : LoginService;

  constructor(router: Router,
    spinnerService: NgxSpinnerService,
    authService: MsalService,
    messageService: MessageService,
    monitoringService: MonitoringService,
    loginService : LoginService)
  {
    this.router = router;
    this.spinnerService = spinnerService;
    this.authService = authService;
    this.messageService = messageService;
    this.monitoringService = monitoringService;
    this.loginService = loginService;
  }

  /**
   * @description This method is privated.
   * @author Elkin Vasquez Isenia
   * @sprint 1
   */
  ngOnInit() {
    SessionStorageHelper.removeAll();
    sessionStorage.setItem(CommonConfiguration.FirstLoad, 'false');
    SessionStorageHelper.save(CommonConfiguration.Authenticated, 'false');
    this.validateSession();
    sessionStorage.setItem(ApplicationConstants.CONSTANTS.languageKey, CommonConfiguration.LanguageKey);

    this.version = environment.version;
    this.environmentName = environment.environmentName;
  }

  /**
   * @description This method is to check the account of the authenticated user.
   * @author Elkin Vasquez Isenia
   * @sprint 1
   */
  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
    if(!this.loggedIn){
      log.debug('Not authenticated, redirecting and adding redirect url...');
      this.monitoringService.logEvent(
        'Not authenticated, redirecting and adding redirect url...'
      );
    }
  }

  /**
   * @description This method is to start user authentication.
   * @author Elkin Vasquez Isenia
   * @sprint 1
   */
  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (!isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  /**
   * @description This method is to validate the state of the current session
   * @author Elkin Vasquez Isenia
   * @sprint 1
   */
  async validateSession() {
    this.checkAccount();
    const isAuth = SessionStorageHelper.get(CommonConfiguration.Authenticated);

    if (this.loggedIn && isAuth === 'false') {
      this.spinnerService.show();

      const userAccount = this.authService.getAccount();

      const userSession = new UserSession(
        userAccount.userName,
        userAccount.name,
        userAccount.userName,
        undefined,
        undefined
      );

      sessionStorage.removeItem(CommonConfiguration.FirstLoad);
      sessionStorage.setItem(CommonConfiguration.FirstLoad, 'true');
      SessionStorageHelper.save(CommonConfiguration.Authenticated, 'true');
      SessionStorageHelper.save(CommonConfiguration.UserSession, JSON.stringify(userSession));
      SessionStorageHelper.save(CommonConfiguration.Email, userAccount.userName);
      await this.getPhoto();
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };

      this.router.navigate(['auth', 'select-profile-module']);
    }
  }

  async getPhoto (){
    const user = SessionStorageHelper.get(CommonConfiguration.Email);
    const userName = new UserNameModel(`${user}`);
    try{
      const result = await this.loginService.getUserPhoto(userName).toPromise();
      if(result.status){
        sessionStorage.removeItem(CommonConfiguration.Photo);
        SessionStorageHelper.save(CommonConfiguration.Photo, result.data);
      }
    }
    catch(err){}
  }

  /**
 * @description This method shows the toast control.
 * @author Elkin Vasquez Isenia
 * @sprint 1
 * @param severity
 * @param message
 * @param detail
 * @returns none
 */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

}
