import { IDataImpactAnalysisJson } from '../interfaces/data-impact-analysis-json.interfaces';
import { IImpactAnalysisJsonDetail } from '../interfaces/impact-analysis-json-detail.interface';

export class DataImpactAnalysisJson implements IDataImpactAnalysisJson {
  constructor(
    public id: string,
    public slug: string,
    public SMaterialCode: string,
    public JClientsFilters: IImpactAnalysisJsonDetail[]
  ){}

}
