<p-accordion>
    <p-accordionTab header="{{ 'AdvancedUserFilterComponent.Title' | translate }}">
        <div class="pstn_container container-fluid">
            <div>
                <p-tabView>
                    <p-tabPanel header="{{ 'AdvancedUserFilterComponent.Generals' | translate }}">
                        <form id="formUserFilter" [formGroup]="formUserFilter">
                            <div class="col-md-12 row">
                                <!-- User -->
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>{{ "AccessManagementComponent.Id" | translate }}</label>
                                    <input #user
                                        formControlName="user"
                                        class="form-control"
                                        pInputText/>
                                    </div>
                                </div>

                                <!-- Email -->
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>{{ "AccessManagementComponent.Email" | translate }}</label>
                                    <input #email
                                        formControlName="email"
                                        class="form-control"
                                        pInputText/>
                                    </div>
                                </div>

                                <!-- Name -->
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>{{ "AccessManagementComponent.Create.Name" | translate }}</label>
                                    <input #name
                                        formControlName="name"
                                        class="form-control"
                                        onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) 
                                            || (event.charCode >= 97 && event.charCode <= 122) 
                                            || event.charCode == 32 || event.charCode == 225
                                            || event.charCode == 233 || event.charCode == 237
                                            || event.charCode == 243 || event.charCode == 250)"
                                        pInputText/>
                                    </div>
                                </div>

                                <!-- Last name -->
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>{{ "AccessManagementComponent.Create.LastName" | translate }}</label>
                                    <input #lastName
                                        formControlName="lastName"
                                        class="form-control"
                                        onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) 
                                            || (event.charCode >= 97 && event.charCode <= 122) 
                                            || event.charCode == 32 || event.charCode == 225
                                            || event.charCode == 233 || event.charCode == 237
                                            || event.charCode == 243 || event.charCode == 250)"
                                        pInputText/>
                                    </div>
                                </div>

                                <!-- Cargo -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AccessManagementComponent.Create.Post" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="cargo" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="cargo"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectPost"
                                                placeholder="{{'AccessManagementComponent.Create.Post'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Role -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AccessManagementComponent.Create.Role" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="role" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="role"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectRole"
                                                placeholder="{{'AccessManagementComponent.Create.Role'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Country -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AccessManagementComponent.Country" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="country" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="country"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectCountry"
                                                placeholder="{{'AccessManagementComponent.Country'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip"
                                                (onChange)="changeSelectCountry()">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Sector -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AccessManagementComponent.Sector" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="sector" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="sector"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectSector"
                                                placeholder="{{'AccessManagementComponent.Sector'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Society -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AccessManagementComponent.Society" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="society" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                [group]="true"
                                                formControlName="society"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectSociety"
                                                placeholder="{{'AccessManagementComponent.Society'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip"
                                                (onChange)="changeSelectSociety()">
                                            </p-multiSelect>
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                  <i class="pi pi-minus"
                                                      aria-hidden="true" ></i>
                                                    <span>{{group.label}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <!-- Region -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AccessManagementComponent.Region" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="region" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="region"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectRegion"
                                                placeholder="{{'AccessManagementComponent.Region'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip"
                                                (onChange)="changeSelectRegion()">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Cedi -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.Cedi" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="cedi" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                [group]="true"
                                                formControlName="cedi"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectCedi"
                                                placeholder="{{'AdvancedClientFiltersComponent.Cedi'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                  <i class="pi pi-minus"
                                                      aria-hidden="true" ></i>
                                                    <span>{{group.label}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <!-- Channel -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AccessManagementComponent.Channel" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="channel" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="channel"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectChannel"
                                                placeholder="{{'AccessManagementComponent.Channel'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- SalesForce -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.SalesForceType" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="saleForce" 
                                                class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="saleForce"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectSalesForceType"
                                                placeholder="{{'AdvancedClientFiltersComponent.SalesForceType'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip"
                                                (onChange)="changeSelectSalesForce()">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- SalesBoss -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.SalesBoss" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="salesBoss" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                [group]="true"
                                                formControlName="salesBoss"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectSalesBoss"
                                                placeholder="{{'AdvancedClientFiltersComponent.SalesBoss'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip"
                                                (onChange)="changeSelectSalesBoss()">
                                            </p-multiSelect>
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                  <i class="pi pi-minus"
                                                      aria-hidden="true" ></i>
                                                    <span>{{group.label}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <!-- Supervisor -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.Supervisor" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="supervisor" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                [group]="true"
                                                formControlName="supervisor"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectSupervisor"
                                                placeholder="{{'AdvancedClientFiltersComponent.Supervisor'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip"
                                                (onChange)="changeSelectSupervisor()">
                                            </p-multiSelect>
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                  <i class="pi pi-minus"
                                                      aria-hidden="true" ></i>
                                                    <span>{{group.label}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <!-- Representative -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.Representative" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="representative" 
                                                class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                [group]="true"
                                                formControlName="representative"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectRepresentative"
                                                placeholder="{{'AdvancedClientFiltersComponent.Representative'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                  <i class="pi pi-minus"
                                                      aria-hidden="true" ></i>
                                                    <span>{{group.label}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <!-- Macrosegment -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.Macrosegment" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="macrosegment" 
                                                class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="macrosegment"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectMacrosegment"
                                                placeholder="{{'AdvancedClientFiltersComponent.Macrosegment'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Subtypology -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.Subtypology" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="subtypology" 
                                                class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="subtypology"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectSubtypology"
                                                placeholder="{{'AdvancedClientFiltersComponent.Subtypology'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Typology -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{ "AdvancedClientFiltersComponent.Typology" | translate }}</label>
                                        <div class="form-group">
                                            <p-multiSelect id="typology" class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="typology"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectTypology"
                                                placeholder="{{'AdvancedClientFiltersComponent.Typology'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- PurchaseMission -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>
                                            {{ "AdvancedClientFiltersComponent.PurchaseMission" | translate }}
                                        </label>
                                        <div class="form-group">
                                            <p-multiSelect id="purchaseMission" 
                                                class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="purchaseMission"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectPurchaseMission"
                                                placeholder="{{'AdvancedClientFiltersComponent.PurchaseMission'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Hierarchy Customer I -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>
                                            {{ "AdvancedClientFiltersComponent.CustomerHierarchy" | translate }}
                                        </label>
                                        <div class="form-group">
                                            <p-multiSelect id="hierarchyCustomerI" 
                                                class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                formControlName="hierarchyCustomerI"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectCustomerHierarchyI"
                                                placeholder="{{'AdvancedClientFiltersComponent.CustomerHierarchy'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip"
                                                (onChange)="changeSelectHerarchyOne()">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <!-- Hierarchy Customer II -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>
                                            {{ "AdvancedClientFiltersComponent.CustomerHierarchy2" | translate }}
                                        </label>
                                        <div class="form-group">
                                            <p-multiSelect id="hierarchyCustomerII" 
                                                class="multiselect-custom-virtual-scroll"
                                                [filter]="true"
                                                [group]="true"
                                                formControlName="hierarchyCustomerII"
                                                optionLabel="label" 
                                                optionValue="value"
                                                [options]="objectCustomerHierarchyII"
                                                placeholder="{{'AdvancedClientFiltersComponent.CustomerHierarchy2'| translate}}"
                                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                                [style]="{ width: '100%' }"
                                                display="chip">
                                            </p-multiSelect>
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                  <i class="pi pi-minus"
                                                      aria-hidden="true" ></i>
                                                    <span>{{group.label}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
        <!-- Botones -->
        <div class="text-right mt-3">
            <button *ngIf="(rolePermission.read | rolePermission)" 
                id="btnRunFilters" 
                class="p-button-rounded pstn_primario"
                pButton pRipple 
                type="button"
                label="{{ 'AdvancedClientFiltersComponent.ExecuteFilter' | translate }}"
                (click)="eventClickFilters()">
            </button>
            <button *ngIf="(rolePermission.read | rolePermission)" 
                id="btnClearFilters" 
                class="p-button-rounded pstn_secundario"
                pButton pRipple 
                type="button"
                label="{{ 'AdvancedClientFiltersComponent.ClearFilter' | translate }}"
                (click)="eventClearFilters()">
            </button>
        </div>
    </p-accordionTab>
</p-accordion>