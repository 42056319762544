<p-accordion>
	<p-accordionTab header="{{ 'ManagePromotionalActivitiesComponent.Liberation.Header' | translate }}"
		[selected]="false">
		<div class="row">
			<div class="col-md-12">
				<p style=" font-weight: 600; color : #009cdd ">
					{{ 'ManagePromotionalActivitiesComponent.Liberation.TitleUserCreation' | translate }}</p>
				<p class="p-0 m-0">
					<label style=" color : #009cdd ">
						{{ 'ManagePromotionalActivitiesComponent.Liberation.User' | translate }} :</label> {{userCreation.nameUser}} </p>
				<p class="p-0 m-0">
					<label style=" color : #009cdd ">
					{{ 'ManagePromotionalActivitiesComponent.Liberation.UserEmail' | translate }} :
					</label> {{userCreation.emailUser}} 
				</p>
				<p class="p-0 m-0"><label style=" color : #009cdd ">
					{{ 'ManagePromotionalActivitiesComponent.Liberation.DateTime' | translate }} :</label>
					{{userCreation.creationDate | date: 'dd/MM/yyyy hh:mm a'}} </p>
			</div>
			<div class="col-md-12">
				<div>
					<p-table #dttea name="gridAdvancedFilters" [value]="objectList" dataKey="approvalSelected" [paginator]="true"
						[rows]="10" paginatorPosition="bottom" [(selection)]="selectObject" styleClass="p-datatable-customers"
						[rowsPerPageOptions]="[10,25,50,100]"
						currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
						[globalFilterFields]="['Id']">

						<ng-template pTemplate="header">
							<tr>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.Sector' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.Level' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.ApprovalLevel' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.State' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.ModificationDate' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.StateDetail' | translate}}</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
							<tr>
								<td>{{object.sectorDescription}}</td>
								<td>{{object.approvalProfile}}</td>
								<td>{{object.approvalUser}}</td>
								<td >
									<span *ngIf=" emailSession !== object.accessManagementId && commercialActStatus === domainStatusEnum.Pending 
										&& object.statusDomainId === approvalStatus.WithoutChecking">
										{{'ManagePromotionalActivitiesComponent.Liberation.WithoutChecking' | translate}}
									</span>	
									<span 	*ngIf="object.showButton === true 
											&& emailSession === object.accessManagementId 
											&& commercialActStatus === domainStatusEnum.Pending 
											&& object.statusDomainId === approvalStatus.WithoutChecking">										
										<p-button label="{{'ManagePromotionalActivitiesComponent.Liberation.WithoutChecking' | translate}}"
														styleClass="p-button-link"
														(click)="showApprovalModal(object)"></p-button>
									</span>
									<span *ngIf="(commercialActStatus !== domainStatusEnum.Pending 
										&& object.statusDomainId === approvalStatus.WithoutChecking)">
										N/A
									</span>
									<span *ngIf="object.statusDomainId === approvalStatus.Rejection">
											{{'ManagePromotionalActivitiesComponent.Liberation.Rejection' | translate}}
									</span>
									<span *ngIf="object.statusDomainId === approvalStatus.OK">
											{{'ManagePromotionalActivitiesComponent.Liberation.OK' | translate}}
									</span>
									<span
										[ngClass]="object.statusDomainId === approvalStatus.OK ? 'color-green' 
										: object.statusDomainId === approvalStatus.Rejection ? 'color-red' : 'color-gray'"></span>
								</td>
								<td>{{object.modificationDate | date: 'dd/MM/yyyy hh:mm a'}}</td>
								<td>
									{{ object.approvalDetail | slice:0:20 }}
									<a href="javascript:void(0);" (click)="showApprovalModalDetail(object)"
										*ngIf="object.approvalDetail !== ''">
										{{'ManagePromotionalActivitiesComponent.Liberation.ShoMore' | translate}}
									</a>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="5">{{ messageData }}
									<i class="pi pi-spin pi-spinner" 
											style="font-size: 1rem; color: #009cdd;"
											pTooltip="Cargando registros"
                      						aria-hidden="true" 
											*ngIf="loading"></i>
								</td>
							</tr>
						</ng-template>
					</p-table>

				</div>
			</div>
		</div>
	</p-accordionTab>
</p-accordion>

<p-dialog header="{{ 'ManagePromotionalActivitiesComponent.Liberation.TitleApprovalRejectionModal' | translate}}"
  [(visible)]="showModalApproval" [modal]="true" [style]="{ width: '40vw' }" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="content">

		<p style="text-align: center;">
			{{ 'ManagePromotionalActivitiesComponent.Liberation.ApprovalRejection' | translate}}
		</p>

		<div class="row">
			<div class="col-md-6" style="text-align: center;">
				<button pButton pRipple
								type="button"
								icon="pi pi-check"
								label="{{ 'ManagePromotionalActivitiesComponent.Liberation.Approve' | translate}}"
								class="p-button-outlined p-button-rounded p-button-success"
								(click)="updateStatusApprovalSelected(approvalStatus.OK)"></button>
			</div>
			<div class="col-md-6" style="text-align: center;">
				<button pButton pRipple
								type="button"
								icon="pi pi-times"
								label="{{ 'ManagePromotionalActivitiesComponent.Liberation.Reject' | translate}}"
								class="p-button-outlined p-button-rounded p-button-danger"
								(click)="updateStatusApprovalSelected(approvalStatus.Rejection)"></button>
			</div>
		</div>

  </ng-template>
</p-dialog>

<p-dialog header="{{ 'ManagePromotionalActivitiesComponent.Liberation.TitleApprovalRejectionModal' | translate}}"
  [(visible)]="showModalApprovalDetail" 
  [modal]="true" [style]="{ width: '40vw' }" 
  [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="content">

		<div class="row">
			<div class="col-md-12" style="text-align: center;">
				<p>
					{{ 'ManagePromotionalActivitiesComponent.Liberation.ApprovalRejectionDetail' | translate}}
				</p>

				<textarea rows="4" cols="45" pInputTextarea [(ngModel)]="approvalRejectionDetail"></textarea>
				<br/>
				<span>
					{{ 'ManagePromotionalActivitiesComponent.Liberation.Limit300Characters' | translate}}
				</span>
				<span>
					{{ 'ManagePromotionalActivitiesComponent.Liberation.CantCharacters' | translate}} 
					{{ approvalRejectionDetail.length }}
				</span>
			</div>
		</div>

  </ng-template>
	<ng-template pTemplate="footer">
    <button pButton pRipple
						label="{{ 'SendBtn' | translate}}"
						icon="pi pi-check"
						class="p-button-info"
						[disabled]="approvalRejectionDetail.length < 10 || approvalRejectionDetail.length > 300"
      			(click)="updateStatusLiberation()"></button>
  </ng-template>

</p-dialog>

<p-dialog header="{{ 'ManagePromotionalActivitiesComponent.Liberation.TitleApprovalDetailModal' | translate}}"
  [(visible)]="showModalApprovalDetailText" 
  [modal]="true" [style]="{ width: '40vw' }" 
  [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="content">

		<div class="row">
			<div class="col-md-12" style="text-align: center;">
				<p>
					{{ selectObject.approvalDetail }}
				</p>
			</div>
		</div>

  </ng-template>
	<ng-template pTemplate="footer">
    <button pButton pRipple
						label="{{ 'CloseBtn' | translate}}"
						icon="pi pi-times"
						class="p-button-primary"
						(click)="showModalApprovalDetailText=false;"></button>
  </ng-template>

</p-dialog>
