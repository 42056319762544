import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SolicitudeMessage {

  public static readonly TITLE = 'Estados de la solicitud';
  public static readonly TOTAL_FILTERS = 'Total de materiales: ';
  public static readonly TOTAL_FILTERS_TRANSLATE = 'Total Materials: ';
  public static readonly NOT_FILTERS = 'Debe agregar un tipo de filtro a consultar es requerido';
  public static readonly NOT_FILTERS_TRANSLATE = 'You must add a type of filter to query is required';
  public static readonly NOT_FILTERS_RESULT = 'No existe materiales para este tipo de filtro';
  public static readonly NOT_FILTERS_RESULT_TRANSLATE = 'There are no materials for this type of filter';
  public static readonly NOT_FILTERS_RESULT_CLIENTS = 'No existe clientes para este tipo de filtro';
  public static readonly NOT_FILTERS_RESULT_CLIENTS_TRANSLATE = 'There are no clients for this type of filter';
  public static readonly TOTAL_FILTERS_CLIENTS = 'Total de clientes: ';
  public static readonly TOTAL_FILTERS_CLIENTS_TRANSLATE = 'Total clients: ';
  public static readonly NOT_NOTIFICATION_CONFIG = 'No existe una notificacion configurada para esta solicitud';
  public static readonly NOT_NOTIFICATION_CONFIG_TRANSLATE = 'There is no notification configured for this request';
  public static readonly AUTO_SAVE = 'Datos guardados automaticamente';
  public static readonly AUTO_SAVE_TRANSLATE = 'Automatically saved data';
  public static readonly NO_EXIST_USERS = 'No existen usuarios en la lista de distribución';
  public static readonly NO_EXIST_USERS_TRANSLATE = 'There are no users in the distribution list';
  public static readonly NOT_SKU = 'Esta solicitud no tiene material asignado';
  public static readonly NOT_SKU_TRANSLATE = 'This request has no material assigned';
  public static readonly NOT_STATUS_PENDING = 'Esta solicitud no esta habilitada para su gestión';
  public static readonly NOT_STATUS_PENDING_TRANSLATE = 'This request is not enabled for management';
  public static readonly PENDING_STATUS_MATERIALS = 'Uno o más materiales tienen solicitudes pendientes por gestión. Elimine los materiales con conflicto de la lista o intente con un nuevo filtro de productos.';
  public static readonly PENDING_STATUS_MATERIALS_TRANSLATE = 'One or more materials have pending requests for management. Please remove the conflicting materials from the list or try a new product filter.';
  public static readonly NO_EXIST_MATERIALS = 'No existen materiales agregados en la solicitud';
  public static readonly NO_EXIST_MATERIALS_TRANSLATE = 'There are no added materials in the request';
  public static readonly NO_PDF = 'No existe acta de precio';
  public static readonly NO_PDF_TRANSLATE = 'There is no price record';
  public static readonly NEW_TAXES_ZERO = 'Precio sin impuesto nuevo no puede ser 0';
  public static readonly NEW_TAXES_ZERO_TRANSLATE = 'Price without new tax cannot be 0';
  public static readonly EXIST_ANALYSIS = 'Este Sku ya tiene un proceso de análisis de impacto';
  public static readonly EXIST_ANALYSIS_TRANSLATE = 'This Sku already has an impact analysis process';
  public static readonly NOT_FILTERS_CLIENT = 'Debe agregar clientes para asignar a la solicitud';
  public static readonly NOT_FILTERS_CLIENT_TRANSLATE = 'You must add customers to assign to the request';
  public static readonly BAD_SKU_DATE_RANGE = 'La fecha de inicio debe ser menor a la de fin en los siguientes SKU';
  public static readonly BAD_SKU_DATE_RANGE_TRANSLATE = 'The start date must be less than the end date in the following SKUs';
  public static readonly BAD_SKU_END_DATE = 'Las fechas fin son requeridas.';
  public static readonly BAD_SKU_END_DATE_TRANSLATE = 'End dates are required.';
  public static readonly DATE_OUT_OF_RANGE = 'La solicitud contiene fechas de vigencia menores o iguales a la fecha actual. ¿Desea actualizar la(s) fecha(s) para el día de mañana y continuar con la aprobación?';
  public static readonly DATE_OUT_OF_RANGE_TRANSLATE = 'The request contains effective dates less than or equal to the current date. Do you want to update the date(s) for tomorrow and continue with the approval?';
  public static readonly APPROVAL_SOLICITUDE = '¿Estás seguro de aprobar la solicitud?';
  public static readonly APPROVAL_SOLICITUDE_TRANSLATE = 'Are you sure you approve the request?';
  public static readonly REJECT_SOLICITUDE = '¿Estás seguro de rechazar la solicitud?';
  public static readonly REJECT_SOLICITUDE_TRANSLATE = 'Are you sure to decline the request?';
  public static readonly NOT_SELECTED_ITEM = 'No se ha seleccionado ningun item';
  public static readonly NOT_SELECTED_ITEM_TRANSLATE = 'No item has been selected';
  public static readonly MAX_PERCENTAGE = 'El porcentaje de ajuste sobrepasa el permitido';
  public static readonly MAX_PERCENTAGE_TRANSLATE = 'The adjustment percentage exceeds the allowed';
  public static readonly PSPNEW = 'El campo PSP nuevo es obligatorio';
  public static readonly PSPNEW_TRANSLATE = 'The field PSP new is mandatory';
  public static readonly PRICE_LIST_LABEL = 'Precio de lista';
  public static readonly PRICE_ADJUSMENT = 'Ajuste en precio';
  public static readonly SOLICITUDE_NOT_HAVE_MATERIALS = 'La solicitud no puede ser enviada porque no tiene materiales asociados';
  public static readonly SOLICITUDE_NOT_HAVE_MATERIALS_TRANSLATE = 'Request cannot be submitted because it does not have associated materials';

  constructor() { }
}
