export enum CommonConfiguration {
  AuthenticationScheme = 'Bearer',
  Authenticated = 'Authenticated',
  UserName = 'UserName',
  Name = 'Name',
  Email = 'Email',
  msalToken= 'msal.idtoken',
  UserSession = 'UserSession',
  Token = 'TokenId',
  LanguageKey = 'es-ES',
  Profile = 'Profile',
  FirstLoad = 'FirstLoad',
  Photo = 'Photo',
  TransformImage = 'data:image/jpeg;base64,',
  ProfileId = 'ProfileId',
  ModuleId = 'ModuleId',
  ConnectionRetries = 'ConnectionRetries',
  TimeConnectionRetries = 'TimeConnectionRetries',
  None = 'Ninguno',
  InProgress = 'In Progress',
  ConsiderationCode = 'NCP',
  Channel = 'Channel',
  Sector = 'Sector',
  SolicitudeCodeCreation = 'SolicitudeCodeCreation'
}
