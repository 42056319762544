import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommercialActivityHandlerPeriodModel } from './models/handler-period.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { SwitchService } from '../switch/switch.service';

@Component({
  selector: 'app-calendar-period',
  templateUrl: './calendar-period.component.html',
  styleUrls: ['./calendar-period.component.css']
})
export class CalendarPeriodComponent implements OnInit {

  @Input('commercialActivityHandlerPeriod') commercialActivityHandlerPeriod : CommercialActivityHandlerPeriodModel[];
  @Input() isProcess : number;
  @Output('calendarValue') calendarValue: EventEmitter<any> = new EventEmitter();
  

  public formPeriod: FormGroup;
  public objectPeriodList: ICombo[];
  public periodToolTip: string[] = [];
  public commercialActivityEnum: any;

  //Observable
	public isCurrentMonth = false;

  private readonly fb: FormBuilder;
  private readonly manageParametersService: ManageParametersService;
  private readonly spinner: NgxSpinnerService;


  constructor(
    fb: FormBuilder,
    manageParametersService: ManageParametersService,
    spinner: NgxSpinnerService,
    private switchService : SwitchService
  ) {
    this.fb = fb;
    this.manageParametersService = manageParametersService;
    this.spinner = spinner;
    this.switchService.data$.subscribe((data) => {
			this.isCurrentMonth = data;
      this.getPeriods();
		  });
  }

  async ngOnInit() {
    this.commercialActivityEnum = this.getCommercialActivityEnum();
    this.initForm();
    await this.getPeriods();
    this.setValuePeriod();
  }

  async ngOnChanges(changes: { [property: string]: SimpleChange }): Promise<void> {
    const changeFilterObjectList: SimpleChange = changes['commercialActivityHandlerPeriod'];
    if (changeFilterObjectList && changeFilterObjectList.currentValue && changeFilterObjectList.currentValue.length > 0 && (this.isProcess == ComercialActivityEnum.update || this.isProcess == ComercialActivityEnum.view)) {
      // this.initForm();
      if(this.objectPeriodList == undefined || this.objectPeriodList.length === 0) await this.getPeriods();
      this.setValuePeriod();
    }
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  async getPeriods() {
    this.objectPeriodList = [];
    let date = new Date();
    let month = date.getMonth();
    let yearCurrent = date.getFullYear();
    let auxMonth = 11;
    let yearParameter = 0;

    try {
      const response = await this.manageParametersService
        .getById(ManagementParameterEnum.intervalYears)
        .toPromise();
      if (response.status) {
        this.objectPeriodList = [];
        yearParameter = Number(response.data.value);
        let yearFind = yearCurrent - yearParameter;

        for (let year = yearFind; year <= date.getFullYear(); year++) {
          if (year === yearCurrent) auxMonth = this.isCurrentMonth ? month - 1 : month;

          for (let i = 0; i <= auxMonth; i++) {
            date.setUTCMonth(i);
            this.objectPeriodList.push({
              label: date.toLocaleString('es', { month: 'long' }) + ' ' + year,
              value: year + (i + 1).toString().padStart(2, '0'),
            });
          }
        }
      } else {
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        };
      }
    } catch (error) {
      SweetAlertMessageHelpers.exception(error);
    }
  }

  setValuePeriod() {
    if(this.periodFilter && this.commercialActivityHandlerPeriod && this.commercialActivityHandlerPeriod.length > 0){
      this.periodFilter.setValue(this.commercialActivityHandlerPeriod.map(prd => {return prd.period}));
      this.setTooltip();
    }

  }

  getValuePeriod() : CommercialActivityHandlerPeriodModel[] {
    if(this.periodFilter && this.periodFilter.value.length > 0){
      const periods = this.periodFilter.value.map((prdo: number) => {
        return {
          commercialActivityId: 0,
          period: prdo
        };
      });
  
      return periods;
    }
  }

  onChangePeriod(){
    this.setTooltip();
    if(this.periodFilter && this.periodFilter.value.length > 0){
        // let valuePeriod = this.getValuePeriod();
        let date = new Date();
        let currentDate = date.getFullYear().toString() + ((date.getMonth() + 1).toString().padStart(2,'0'));
        if(this.getValuePeriod().find(item => item.period == currentDate)){
          this.calendarValue.emit(true);
        }else if(!this.getValuePeriod().find(item => item.period == currentDate))
        {
          this.calendarValue.emit(false);
        }
       
    }
  }

  setTooltip(){
    if(this.periodFilter && this.periodFilter.value.length > 0){
      this.periodToolTip = [];
      this.periodFilter.value.forEach((elm : any) => {
        this.objectPeriodList.forEach(list => {
          if(elm === list.value){
            this.periodToolTip.push(list.label);
          }
        });
        
      });
    }else {
      this.periodToolTip = [];
    }
  }

  initForm() {
    this.formPeriod = this.fb.group({
      periodFilter: [{ value: '', disabled: false }, [Validators.required]],
    });
  }


  get periodFilter(): AbstractControl { return this.formPeriod.get('periodFilter'); }

}
