import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import {
  BotDirective,
  ComService,
  IPayload,
  DEFAULT_OPTIONS,
} from 'ngx-microsoft-bot-framework';

@Component({
  selector: 'app-filter-bot-clients',
  templateUrl: './filter-bot-clients.component.html',
  styleUrls: ['./filter-bot-clients.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBotClientsComponent implements OnInit {
  @ViewChild('botWindow', { static: false }) botWindowElement: ElementRef;
  passViewChild: ViewChild;

  private readonly comService: ComService;
  private readonly bot: BotDirective;

  constructor(comService: ComService, 
    bot: BotDirective) 
    {
      this.comService = comService;
      this.bot = bot;
    }

  sw = 0;

  ngOnInit(): void {
    this.obtainStylePayload();
    this.obtainLocalToken();
  }

  public ngAfterViewInit(): void {
    this.setBotDirective();
  }

  payload: IPayload = {
    secret: '39ffc6d4-3091-4c4e-9696-2096badeef4f',
    url: 'https://ccvpwbotsbox.azurewebsites.net/api/messages',
    secretSetting: true,
    userId: '22b0eb29-b95f-4465-a6a3-fb90ed67fdb9',
    webSocket: true,
  };

  stylesetPayload: DEFAULT_OPTIONS = {
    rootHeight: '100%',
    botAvatarInitials: 'BF',
    userAvatarInitials: 'CH',
    backgroundColor: '#131313',

    root: {
      /* width */
      ' ::-webkit-scrollbar': {
        width: '3px',
      },
    },
    // text_content: {
    //   fontFamily: "'Comic Sans MS', 'Arial', sans-serif",
    //   fontWeight: 'bold',
    // },
  };
  styleOptionsPayload: DEFAULT_OPTIONS = {
    rootHeight: '100%',
    botAvatarInitials: 'BF',
    userAvatarInitials: 'CH',
    backgroundColor: 'black',
  };

  setBotDirective(): void { //VISTA DEL BOT
    if (this.botWindowElement) {
      this.passViewChild = this.botWindowElement.nativeElement;
      this.bot.botDirective(this.passViewChild);
    }
  }

  obtainLocalToken() {
    this.comService.obtainToken(this.payload);
  }

  obtainStylePayload() {
    this.comService.obtainStylePayload(
      this.stylesetPayload,
      this.styleOptionsPayload
    );
  }

  openBot(number: number){
    this.sw = number;
  }
}
