import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { Observable } from 'rxjs';
import { IApprovalRankJson } from './interfaces/approval-rank-json.interface';
import { environment } from '@env/environment';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationByIdModel } from '@app/@shared/model/pagination-by-id.model';
import { ApprovalRankRequestModel } from './models/approval-rank-request.model';
import { ApprovalRankStatusModel } from './models/approval-rank-status.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalRankService {
  private readonly httpService: HttpService;
  private approvalRankId: number;
  private isJustLook: boolean;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  /**
   * @description This method is to storage id.
   * @author Priscy Antonio Reales
   * @param number
   * @returns none
   * @sprint 6
   */
  setApprovalRankId(id: number) {
    this.approvalRankId = id;
  }

  /**
   * @description This method is to return id storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 6
   */
  getApprovalRankId(): number {
    return this.approvalRankId;
  }

  /**
   * @description This method is to storage isJustLook.
   * @author Priscy Antonio Reales
   * @param number
   * @returns none
   * @sprint 7
   */
   setIsJustLook(isJustLook: boolean) : void {
    this.isJustLook = isJustLook;
  }

  /**
   * @description This method is to return isJustLook storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 7
   */
  getIsJustLook(): boolean {
    return this.isJustLook;
  }

  /**
   * @description This method is to get all the ApprovalRankJson.
   * @author Priscy Antonio Reales
   * @param paginationById : PaginationByIdModel
   * @returns Observable<IResponseService<IApprovalRankJson[]>>
   * @sprint 6
   */
  public getListApprovalRank(
    paginationById: PaginationByIdModel
  ): Observable<IResponseService<IApprovalRankJson[]>> {
    const response$: Observable<IResponseService<IApprovalRankJson[]>> =
     this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_approval_rank`,
        paginationById
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IApprovalRankJson[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to get a ApprovalRank With Detail By Id.
   * @author Priscy Antonio Reales
   * @param none
   * @returns Observable<IResponseService<IApprovalRankJson>>
   * @sprint 6
   */
  public getApprovalRankById(
    id: number
  ): Observable<IResponseService<IApprovalRankJson>> {
    const response$: Observable<IResponseService<IApprovalRankJson>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/approval_rank_by_id/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IApprovalRankJson>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to delete ApprovalRank.
   * @author Priscy Antonio Reales
   * @param id : number
   * @returns Observable<ResponseService>
   * @sprint 6
   */
  public deleteLogic(id: number): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/approval_ranks/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to delete ApprovalRankLevel.
   * @author Priscy Antonio Reales
   * @param id : number
   * @returns Observable<ResponseService>
   * @sprint 6
   */
  public deleteDetailLogic(id: number): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/approval_rank_levels/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to create Approval Rank with detail.
   * @author Priscy Antonio Reales
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 6
   */
  public post(
    body: ApprovalRankRequestModel
  ): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/approval_ranks/associate_approval_rank_with_level`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to update Approval Rank with detail.
   * @author Priscy Antonio Reales
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 6
   */
  public put(
    id: number,
    body: ApprovalRankRequestModel
  ): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/approval_ranks/associate_approval_rank_with_level/${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to update Approval Rank with detail.
   * @author Priscy Antonio Reales
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 6
   */
  public approvalRankUpdateState(
    id: number,
    body: ApprovalRankStatusModel
  ): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/approval_ranks/update_state_approval_rank/${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
