import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { MaterialModel } from '@app/@shared/model/material.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ManagePromotionalActivitiesMessage } from '../../messages/manage-promotional-activities-messages.message';
import { RedeeableCommercialActivityModel } from '../models/redeemable-commercial-activity.model';
import { RedemptionFilterService } from './redemption-filter.service';



@Component({
  selector: 'app-redeemable-filter',
  templateUrl: './redemption-filter.component.html',
  styleUrls: ['./../../../../../@shared/scss/responsive.table.scss'],
  styles: [`
  @media screen and (max-width: 960px) {
      :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
          display: flex;
      }
  }
`],
  providers: [MessageService]
})

export class RedemptionFilterComponent implements OnInit {

  @Input('isProcess') isProcess: number;
  @Input('listProductSelectedEdit') listProductSelectedEdit : RedeeableCommercialActivityModel[] = [];
  @Output('eventClickFilterRedemtion') eventButton: EventEmitter<any> = new EventEmitter();
  @Output('eventClickClearFilter') eventDeleteButton: EventEmitter<any> = new EventEmitter();

  private readonly redeemableService : RedemptionFilterService;
  private readonly spinner: NgxSpinnerService;

  public isVisible = false;
  public advancedFiltersObjectList: any[];
  public codesFilterList: ICombo[] = [];
  public codesMultiSelected: string[] = [];
  public processEnum: any = ComercialActivityEnum;

  constructor(
    redeemableService : RedemptionFilterService,
    spinner: NgxSpinnerService
  ){
    this.redeemableService = redeemableService;
    this.spinner = spinner;
  }

  ngOnInit(): void {
    if(this.listProductSelectedEdit.length > 0){
      this.listProductSelectedEdit.forEach((item : RedeeableCommercialActivityModel)  => {
        this.codesMultiSelected.push(item.materialCode.toString());
      });
    }
  }

  getMaterials(){
    const materialType = 'DIEN';
    const materialGroup = '531';
    this.redeemableService.redeemableFiltersElements(materialType, materialGroup).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.status) {
          this.codesFilterList = response.data.map((item: MaterialModel) => {
            return {
              label: `${item.code}-${item.description}`,
              value: item.code
            };
          });
        } else {
          this.isVisible = false;
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? `${ManagePromotionalActivitiesMessage.NOT_MATERIAL_REDEEMABLE}`:`${ManagePromotionalActivitiesMessage.NOT_MATERIAL_REDEEMABLE_TRANSLATE}`);
        }
      });
  }


  getDataTypeProcess(){
    if(this.isProcess === this.processEnum.update){
      this.listProductSelectedEdit.forEach((item : RedeeableCommercialActivityModel)  => {
        this.codesMultiSelected.push(item.materialCode.toString());
      });
    }
  }

  public deleteFilters(){}

  /*public openAdvancedFilters(){
    this.toEditFilter = false;
    this.filterToEdit = null;
    this.clearFilters();
    this.getFiltersByLevelHierarchy(0);
    this.getFiltersElementsCode();
    this.isVisible = true;
  }*/

  newRecordRedeemable() {
    this.spinner.show();
    if(this.listProductSelectedEdit.length > 0){
      this.codesMultiSelected = [];
      this.listProductSelectedEdit.forEach((item : RedeeableCommercialActivityModel)  => {
        this.codesMultiSelected.push(item.materialCode.toString());
      });
    }
    this.isVisible = true;
    this.getMaterials();
  }


  public closeAdvancedFilters(){
    this.isVisible = false;
  }

  public clearFilterSelected(){
    this.codesMultiSelected = [];
    this.eventDeleteButton.emit(true);
  }

  public addFilter(){
    const listRedentionProduct : RedeeableCommercialActivityModel[] = [];

    if(this.codesMultiSelected.length === 0){
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'Debe seleccionar un filtro.' : 'You must select a filter.');
      this.isVisible = false;
      return;
    }

    this.codesMultiSelected.forEach((x) => {
      const codeSelected = this.codesFilterList.find((y) => y.value === x);
      codeSelected.label = codeSelected.label.split('-')[1];
      const existElement: RedeeableCommercialActivityModel = this.listProductSelectedEdit.find((x) => x.materialCode === codeSelected.value);

      const redentionProduct = new RedeeableCommercialActivityModel();

      redentionProduct.id = existElement !== undefined ? existElement.id.valueOf() :0;
      redentionProduct.materialCode = codeSelected.value;
      redentionProduct.descriptionRedeemable = codeSelected.label;
      redentionProduct.amount = existElement !== undefined ? existElement.amount.valueOf() : 0;
      redentionProduct.individualPesoValue = existElement !== undefined ? existElement.individualPesoValue.valueOf() : 0;

      listRedentionProduct.push(redentionProduct);
    });

    this.clearFilterSelected();

    this.isVisible = false;
    this.eventButton.emit(listRedentionProduct);

  }
}