import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18nModule } from '../i18n/i18n.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from './modules/prime-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RolePermissionPipe } from './pipeline/role/role-permission.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    PrimeNgModule,
    I18nModule,
  ],
  declarations: [
    RolePermissionPipe
   ],
  exports: [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RolePermissionPipe
  ],
})
export class SharedModule { }
