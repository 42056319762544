// Components
import { Util } from '@app/@shared/util';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ProcessHierarchyReleaseEnum } from '@app/@shared/enums/hierarchy-release.enum';
import { AssociateEnum } from '@app/@shared/enums/class-style.enum';
import { OptionUserEnum } from './enums/option-user.enum';
import { AssociateMessage } from './messages/associate.message';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';

// Services
import { ProfileService } from '../../../administration/profile/profile.service';
import { AssociateService } from './associate.service';

// Models
import { HierarchyReleaseModel } from './../create/models/hierarchy-release.model';
import { PaginationForId } from '@app/@shared/model/pagination-for-id.model';
import { IUsersInfoAccess } from './interfaces/users-info-access.interface';
import { IProfile } from '../../../administration/profile/interfaces/profile.interface';
import { IAssociateProfile } from './interfaces/associate-profile.interface';
import { AssociateUserModel } from './models/associate-user.model';
import { AssociateProfileModel } from './models/associate-profile.model';
import { UsersInfoAccessModel } from './models/users-info-access.model';
import { CreateService } from '../create/create.service';
import { HierarchyReleaseAssociateModel } from '../create/models/hierarchy-release-associate.model';
import { IAssociateUser } from './interfaces/associate-user.interface';
import { AssociateUserUpdateModel } from './models/associate-user-update.model';



@Component({
  selector: 'app-associate',
  templateUrl: './associate.component.html',
  styleUrls: ['./associate.component.css'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
    providers: [MessageService]
})
export class AssociateComponent implements OnInit {
  private readonly associateService: AssociateService;
  private readonly createHierarchyService: CreateService;
  private readonly profileService: ProfileService;
  private readonly messageService: MessageService;
  private readonly spinner: NgxSpinnerService;
  private readonly router: Router;

  public profileId = 0;
  public profileList : IProfile[] = [];
  public profileListSelected :  IProfile[] = [];
  public profileSelected : IProfile;
  public idBtnBefore = '';
  public titleTable = '';
  public usersInfo: IUsersInfoAccess[] = [];
  public usersInfoSelected : IUsersInfoAccess[] = [];
  public checkAssociateAll = false;
  public associateInfoListModel: AssociateProfileModel[] = [];
  public associateInfoModel: AssociateProfileModel;
  public associateUserListModel: AssociateUserModel[] = [];
  public associateUserModel: AssociateUserModel;
  public hierarchyReleaseAssociateModel: HierarchyReleaseAssociateModel;
  public hierarchyReleaseModel: HierarchyReleaseModel;
  public processHierarchyEnum: any = ProcessHierarchyReleaseEnum;
  public processHierarchy = 0;
  public hierarchyIdUpdate = 0;



  constructor(
    associateService: AssociateService,
    createHierarchyService: CreateService,
    profileService: ProfileService,
    messageService: MessageService,
    spinner: NgxSpinnerService,
    router: Router
  ) { 
    this.associateService = associateService;
    this.createHierarchyService = createHierarchyService;
    this.profileService = profileService;
    this.messageService = messageService;
    this.spinner = spinner;
    this.router = router;
  }

  /**
  * @description this method is private.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  ngOnInit(): void {
    this.processHierarchy = this.createHierarchyService.getProcessHierarchyRealese();

    if(this.processHierarchy === undefined){
      this.router.navigate(['auth/hierarchy-release/create']);
    }

    this.hierarchyIdUpdate = this.createHierarchyService.getHierarchyIdEdit();
    this.getAllProfile();
  }


  // #region  Create

   /**
   * @description this method is to get all the profile.
   * @author Jhon Stiven Pavón Bedoya
   * @sprint 6
   */
     getAllProfile(): void {
      this.spinner.show();
      this.profileService.getAllProfileApprovalLevel().subscribe(
        (response) => {
          this.spinner.hide();
          this.profileList = response.data;
          this.validateEditAssociate();
        },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }

    /**
    * @description this method is to get all the profile.
    * @author Jhon Stiven Pavón Bedoya
    * @sprint 6
    */
    getUsersbyProfileId(profileId: number){
      this.spinner.show();
      const paginationModel = new PaginationForId(profileId,'name', true, 1, 100);
      this.associateService.getListUsersAccessManagement(paginationModel).subscribe(
        (response) => {
          this.spinner.hide();
          this.usersInfo = response.data;
          this.setInfoUsersByProfileId();
        },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }

    /**
    * @description this method compares IUserInfoAccess with AssociateUserModel and Set values in  IUserInfoAccess.
    * @author Jhon Stiven Pavón Bedoya
    * @sprint 6
    */
    setInfoUsersByProfileId(){
      const existProfile = this.associateInfoListModel.find((u) => u.id === this.profileId);

      if (existProfile) {
        this.usersInfo.forEach((v) => {
          const existUser = existProfile.associateUsers.find((y) => y.accessManagementId === v.accessManagementId);

          if (existUser) {
            v.mandatory = existUser.mandatory;
            v.authorizer = existUser.authorizer;
            v.approval = existUser.approval;
          }
        });
      }
    }

  /**
  * @description this method init the drop.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  dragStart($event: any, object: any) {
      this.profileSelected = object;
  }

  /**
  * @description this method jump the drop.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  drop($event: any) {
    if (this.profileSelected) {
      this.profileListSelected = [...this.profileListSelected, this.profileSelected];
      this.profileList = this.profileList.filter((x) => x.id !== this.profileSelected.id );
      this.profileSelected = null;
    }
  }

  /**
  * @description this method finalized the drag.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
   dragEnd($event: any) {
  }
   /**
  * @description this method deleted IProfile Selected and add this IProfile to profileList and associateInfoListModel.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  deleteProfileSelected(profile: IProfile){
    this.profileListSelected = this.profileListSelected.filter((x) => x.id !== profile.id);
    this.profileList.push(profile);
    this.usersInfo =  [];
    if(this.associateInfoListModel && this.associateInfoListModel.length > 0){
      const listdata = this.associateInfoListModel.filter((x) => x.id !== profile.id);
      this.associateInfoListModel = [];
      this.associateInfoListModel = listdata;
    }

  }

  /**
  * @description this method change Style of IProfile Selected when user to click.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  changeStyleProfileSelected($event: any, profile: IProfile){
    this.profileId = profile.id;
    let idCurrent = `${AssociateEnum.btn}-${profile.id}`;
    const btnBefore = this.idBtnBefore !== null ? document.getElementById(this.idBtnBefore) : null;
    const btn = document.getElementById(idCurrent);
    if($event.type ===  'click' && (idCurrent !== this.idBtnBefore || idCurrent === '')){
        this.titleTable = profile.description;
        this.idBtnBefore = idCurrent;
        btn.className = AssociateEnum.btnPrimaryClass;
        idCurrent = '';
        this.getUsersbyProfileId(profile.id);
        btnBefore.className = AssociateEnum.btnSecondClass;
    }else{
      btn.className = AssociateEnum.btnSecondClass;
      this.profileId = 0;
      this.titleTable = '';
      idCurrent = '';
      this.idBtnBefore = '';
      this.usersInfo = [];
    }
  }

  /**
  * @description this method change add True/False to Mandatory of User.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  callMandatory($event: any, userInfo: IUsersInfoAccess){
    this.changeValueBoolean($event, userInfo, OptionUserEnum.mandatory);
  }

  /**
  * @description this method change add True/False to Authorizer of User.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  callAuthorizer($event: any, userInfo: IUsersInfoAccess){
    this.changeValueBoolean($event, userInfo, OptionUserEnum.authorizer);
  }

  /**
  * @description this method change add True/False to Approval of User.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  callApproval($event: any, userInfo: IUsersInfoAccess){
    this.changeValueBoolean($event, userInfo, OptionUserEnum.approval);
  }

  /**
  * @description this method change add True/False to User.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  changeValueBoolean($event: any, userInfo: IUsersInfoAccess, origin: number){
    this.usersInfo.forEach((e) => {
      if (e.accessManagementId === userInfo.accessManagementId) {
        switch (origin) {
          case OptionUserEnum.mandatory:
              e.mandatory = $event.checked;
              this.setPropertiesAssociateInfo($event, e, OptionUserEnum.mandatory);
            break;
          case OptionUserEnum.authorizer:
              e.authorizer = $event.checked;
              this.setPropertiesAssociateInfo($event, e, OptionUserEnum.authorizer);
            break;
          case OptionUserEnum.approval:
              e.approval = $event.checked;
              this.setPropertiesAssociateInfo($event, e, OptionUserEnum.approval);
              break;
          default:
            break;
        }
      }
    });
  }

  /**
  * @description this method change add True/False all property Appoval for profile selected.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  approvalAll($event: any){
    if(this.usersInfo.length > 0){
      this.usersInfo.forEach((e) => {
        e.approval = $event.checked;
        this.setPropertiesAssociateInfo($event,e, OptionUserEnum.approval);
      });
    }
  }

  /**
  * @description this method add, edit and delete User to associateInfoListModel.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  setPropertiesAssociateInfo($event: any, userInfo: IUsersInfoAccess, origin: number){
    const existAssociate = this.associateInfoListModel.find((x) => x.id === this.profileId);

    if ($event.checked) {

      if(this.associateInfoListModel.length > 0 && existAssociate){

        const associate : AssociateProfileModel = existAssociate;
        const associateList = associate.associateUsers;

        const existUser = associateList.find((x) => x.accessManagementId === userInfo.accessManagementId);
        if(existUser){
          this.changePropertyAssociateInfo(associate, userInfo, origin);
        }else{
          associateList.push(this.addAssuciateUser(userInfo));

          associate.associateUsers = [];
          associate.associateUsers = associateList;

          this.associateInfoListModel = this.associateInfoListModel.filter((x) => x.id !== this.profileId);

          this.associateInfoListModel.push(associate);
        }
      }else{

        const listAssociateUsers : AssociateUserModel[] = [];
        listAssociateUsers.push(this.addAssuciateUser(userInfo));

        const associateInfoModel : AssociateProfileModel = new AssociateProfileModel(
          this.profileId,
          this.titleTable,
          listAssociateUsers
        );

        this.associateInfoListModel.push(associateInfoModel);
      }
    } else {

      const associate : IAssociateProfile = this.associateInfoListModel.find((x) => x.id === this.profileId);


      const associateUpdate = this.changePropertyAssociateInfo(associate, userInfo, origin);

      const listAssociateModel = this.associateInfoListModel.filter((z) => z.id !== this.profileId);

      this.associateInfoListModel = listAssociateModel;
      this.associateInfoListModel.push(associateUpdate);
    }
  }

  /**
  * @description this method set properties or delete User to associateInfoListModel.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  changePropertyAssociateInfo(associate:  AssociateProfileModel, userInfo: IUsersInfoAccess, origin: number) : AssociateProfileModel{
    const userModel = associate.associateUsers.find((x) => x.accessManagementId === userInfo.accessManagementId);

    if(userInfo.mandatory &&  userInfo.authorizer &&  userInfo.approval ||
       userInfo.mandatory &&  userInfo.authorizer && !userInfo.approval ||
       userInfo.mandatory && !userInfo.authorizer &&  userInfo.approval ||
       userInfo.mandatory && !userInfo.authorizer && !userInfo.approval ||
      !userInfo.mandatory &&  userInfo.authorizer &&  userInfo.approval ||
      !userInfo.mandatory && !userInfo.authorizer &&  userInfo.approval ||
      !userInfo.mandatory &&  userInfo.authorizer && !userInfo.approval){

        userModel.mandatory = userInfo.mandatory;
        userModel.authorizer = userInfo.authorizer;
        userModel.approval = userInfo.approval;

        const listUsers = associate.associateUsers.filter((y) => y.accessManagementId !== userInfo.accessManagementId);
        listUsers.push(userModel);

        associate.associateUsers = [];
        associate.associateUsers = listUsers;

        return associate;
    }else{
      const listUsers = associate.associateUsers.filter((y) => y.accessManagementId !== userInfo.accessManagementId);
      associate.associateUsers = [];
      associate.associateUsers = listUsers;

      return associate;
    }
  }

  /**
  * @description this method set User to associateUserModel.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  addAssuciateUser(userInfo: IUsersInfoAccess) : AssociateUserModel{

    const associateModel : AssociateUserModel = new AssociateUserModel(
      userInfo.name,
      userInfo.lastName,
      userInfo.email,
      userInfo.accessManagementId,
      this.profileId,
      userInfo.mandatory,
      userInfo.authorizer,
      userInfo.approval
    );

    return associateModel;
  }

    /**
    * @description this method validates only one Mandatory.
    * @author Jhon Stiven Pavón Bedoya
    * @sprint 6
    */
    validateMandatory(): boolean{

      let response = false;

      if(this.associateInfoListModel && this.associateInfoListModel.length > 0){
        this.associateInfoListModel.forEach((v) => {
          const countMandatary = v.associateUsers.filter((x) => x.mandatory === true);

          if(countMandatary.length === 0 || countMandatary.length >= 2){
            const msn = `${AssociateMessage.NO_MANDATORY} - ${v.description}`;
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, msn );
            response = true;
          }
        });
      }else{
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
          language === 'es-ES' ? AssociateMessage.NO_ASSOCIATE : AssociateMessage.NO_ASSOCIATE_TRANSLATE);
        response = true;
      }
      return response;
    }

    /**
    * @description this method validates only one Authorizer.
    * @author Jhon Stiven Pavón Bedoya
    * @sprint 6
    */
    validateAuthorizer(): boolean{
      let response = false;
     if(this.associateInfoListModel && this.associateInfoListModel.length > 0){
        this.associateInfoListModel.forEach((v) => {
          const countMandatary = v.associateUsers.filter((x) => x.authorizer === true);

          if(countMandatary.length >= 2){
            const language: string = sessionStorage.getItem('language');
            let msn = '';
            if(language === 'es-ES'){
               msn = `${AssociateMessage.NO_AUTHORIZER} - ${v.description}`;
            }else{
               msn = `${AssociateMessage.NO_AUTHORIZER_TRANSLATE} - ${v.description}`;
            }
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, msn );
            response = true;
          }

        });
      }else{
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
          language === 'es-ES' ? AssociateMessage.NO_ASSOCIATE
          : AssociateMessage.NO_ASSOCIATE_TRANSLATE);
        response = true;
      }
      return response;
    }

  /**
  * @description this method set listAssociateInfoModel to storage and return create.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  save(){
    const validateMandatory = this.validateMandatory();
    const validateAuthorizer = this.validateAuthorizer();

    if(!validateMandatory && !validateAuthorizer){
      Util.confirmSave().then((result: SweetAlertResult) => {
        if (this.associateInfoListModel.length > 0 && result.value) {

          if(this.processHierarchy === ProcessHierarchyReleaseEnum.create || this.processHierarchy === ProcessHierarchyReleaseEnum.update){
            this.createHierarchyService.setProcessHierarchyRealese(this.processHierarchy);
            this.createHierarchyService.setHierarchyIdEdit(this.hierarchyIdUpdate);
            const hierarchyReleaseAssociate = this.convertAssociateToHierarchyCreateModel();
            this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel = hierarchyReleaseAssociate;
            this.createHierarchyService.setInfoHierarchyModel(this.hierarchyReleaseModel);
            this.router.navigate(['auth/hierarchy-release/create']);
          }
        }
        else{
          Util.showMessage(2);
        }
      });
    }
  }

  /**
  * @description This method convert hierarchy release  associate model to hierarchy create model.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  convertAssociateToHierarchyCreateModel() : HierarchyReleaseAssociateModel[] {


    const hierarchyReleaseAssociate: HierarchyReleaseAssociateModel[] = [];

    this.associateInfoListModel.forEach((v) =>{

      const HierarchyCreateModel = new  HierarchyReleaseAssociateModel(
        0,
        v.description,
        0,
        '',
        0,
        '',
        0,
        0,
        '',
        this.getAssociateInfoToHierarchyModel(v.associateUsers)
      );

      hierarchyReleaseAssociate.push(HierarchyCreateModel);
    });

    return hierarchyReleaseAssociate;
  }

  /**
  * @description This method get associate info to hierarchy model.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getAssociateInfoToHierarchyModel(listUsers: IAssociateUser[]): AssociateUserUpdateModel[]{

    const listAssociateModel: AssociateUserUpdateModel[] = [];

    listUsers.forEach((v) => {

      const associateModel = new AssociateUserUpdateModel(
        0,
        v.name,
        v.lastName,
        v.email,
        v.accessManagementId,
        v.profileId,
        v.mandatory,
        v.authorizer,
        v.approval
      );

      listAssociateModel.push(associateModel);
    });

    return listAssociateModel;
  }

  /**
  * @description this method cancel operation and return create.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 6
  */
  cancel(){
    Util.confirmCancel().then((result: SweetAlertResult) => {
      this.router.navigate(['auth/hierarchy-release/create']);
    });
  }



  // #endregion


 // #region Edit

  /**
  * @description This method validate info de associate.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  validateEditAssociate(){

    if(this.processHierarchy === ProcessHierarchyReleaseEnum.create){
      this.hierarchyReleaseModel = this.createHierarchyService.getInfoHierarchyModel();
      if(this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel.length > 0){

        this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel.forEach((v) => {
          const listInfoUser: UsersInfoAccessModel[] = [];
          const idProfesion = v.associateUsers[0].profileId !== undefined ? v.associateUsers[0].profileId : 0;

          v.associateUsers.forEach((u) => {
            const infoUser = new UsersInfoAccessModel(
                u.name,
                u.lastName,
                u.email,
                u.accessManagementId,
                u.profileId,
                u.mandatory,
                u.authorizer,
                u.approval
            );
            listInfoUser.push(infoUser);
          });

          const profileInfo = this.profileList.find((x) => x.id === idProfesion);
          const associateProfileModel = new AssociateProfileModel(profileInfo.id,
                                                                profileInfo.description,
                                                                listInfoUser);

          this.associateInfoListModel.push(associateProfileModel);
        });
      }
    }else {
      if(this.processHierarchy === ProcessHierarchyReleaseEnum.update){

        const profileDescription = this.createHierarchyService.getProfileDescriptionEdit();
        this.hierarchyReleaseModel = this.createHierarchyService.getInfoHierarchyModel();

        if(profileDescription === undefined || this.hierarchyIdUpdate === undefined) {
          this.createHierarchyService.setProcessHierarchyRealese(this.processHierarchy);
          this.createHierarchyService.setInfoHierarchyModel(this.hierarchyReleaseModel);
          this.router.navigate(['auth/hierarchy-release/create']);
        }

        const profileInfo = this.profileList.find((x) => x.description === profileDescription);

        this.profileId = profileInfo.id;
        this.titleTable = profileInfo.description;
        let listAssociateUserUpdateModel : AssociateUserUpdateModel[] = [];

        this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel.forEach((v) => {
          listAssociateUserUpdateModel = v.associateUsers.filter((x) => x.profileId === profileInfo.id);
        });

        this.convertInfoAssociateUsersToAssociateInfoAccess(listAssociateUserUpdateModel);
      }
    }
  }


  /**
  * @description This method convert Info Associate Users To Associate Info Access.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  convertInfoAssociateUsersToAssociateInfoAccess(associateUsers: AssociateUserUpdateModel[]){
    associateUsers.forEach((x) => {

        const user: UsersInfoAccessModel = new UsersInfoAccessModel(
          x.name,
          x.lastName,
          x.email,
          x.accessManagementId,
          x.profileId,
          x.mandatory,
          x.authorizer,
          x.approval
        );
        this.usersInfo.push(user);
        this.usersInfoSelected.push(user);
    });

    const associate : AssociateProfileModel = new AssociateProfileModel(
      this.profileId,
      this.titleTable,
      associateUsers
    );

    this.associateInfoListModel.push(associate);

  }

  /**
  * @description This method update info in associate user update model.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  changeInfoHierarchyUpdate(newInfo: IAssociateUser[], oldInfo: AssociateUserUpdateModel[]): AssociateUserUpdateModel[]{

    const arrayAccesManagementId: number[] = [];
    const infoFinal: AssociateUserUpdateModel[] = [];
    let i = 0;
    oldInfo.forEach((v) => {
      const info = newInfo.find((x) => x.accessManagementId === v.accessManagementId);

      if (info !== undefined) {
        v.mandatory = info.mandatory;
        v.authorizer = info.authorizer;
        v.approval = info.approval;
      } else {
        arrayAccesManagementId.push(i);
      }
      i++;

    });

    arrayAccesManagementId.forEach((pos) => {
      oldInfo.splice(pos);
    });
    return oldInfo;
  }
 // #endregion


}
