<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'">
            <i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/approval-rank'">
            {{ "ApprovalRankComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "ApprovalRankComponent.Create.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <h1 class="pstntitle">
        {{ "ApprovalRankComponent.Create.Title" | translate }}
      </h1>
    </div>

    <form id="formApprovalRank" [formGroup]="formApprovalRank">
      <div class="pstn_container ">
        <div class="row ">
          <!-- description -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Description" | translate }}</label>
              <input #user id="description"
                formControlName="description"
                autocomplete="off"
                class="form-control"
                [ngClass]="{ 'ng-invalid ng-dirty': description.invalid && description.touched }" pInputText 
                required (keyup)="keyupApprovalRank($event)" />
              <small id="description" *ngIf="
                         formApprovalRank.controls['description'].invalid &&
                      (formApprovalRank.controls['description'].dirty ||
                      formApprovalRank.controls['description'].touched)
                    " class="p-error">{{"ApprovalRankComponent.Create.DescriptionMsgValidate" | translate}}</small>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Country -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Country" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="country"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': country.invalid && country.touched}"
                  formControlName="country"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.CountryPlaceHolderCombo'| translate}}"
                  [options]="objectCountryList"
                  [style]="{ width: '100%' }"
                  (onChange)="getSocietiesByCountry()">
                </p-dropdown>
                <small id="countryValidator" *ngIf="
                    formApprovalRank.controls['country'].invalid &&
                    (formApprovalRank.controls['country'].dirty ||
                      formApprovalRank.controls['country'].touched)
                  " class="p-error">{{"ApprovalRankComponent.Create.CountryMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>

          <!-- Society -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Society" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="society"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': society.invalid && society.touched}"
                  formControlName="society"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.SocietyPlaceHolderCombo'| translate}}"
                  [options]="objectSocietyList"
                  [style]="{ width: '100%' }"
                  (onChange)="getCediChannelRegionBySocietyId()">
                </p-dropdown>
                <small id="societyValidator" *ngIf="
                    formApprovalRank.controls['society'].invalid &&
                    (formApprovalRank.controls['society'].dirty ||
                      formApprovalRank.controls['society'].touched)
                  " class="p-error">{{"ApprovalRankComponent.Create.SocietyMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>
          <!-- Channel -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Channel" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="channel"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': channel.invalid && channel.touched}"
                  formControlName="channel"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.ChannelPlaceHolderCombo'| translate}}"
                  [options]="objectChannelList"
                  [style]="{ width: '100%' }"
                  >
                </p-dropdown>
                <small id="channel" *ngIf="
                    formApprovalRank.controls['channel'].invalid &&
                    (formApprovalRank.controls['channel'].dirty ||
                      formApprovalRank.controls['channel'].touched)
                  " class="p-error">{{"ApprovalRankComponent.Create.ChannelMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>
          <!-- Region -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Region" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="region"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': region.invalid && region.touched}"
                  formControlName="region"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.RegionPlaceHolderCombo'| translate}}"
                  [options]="objectRegionList"
                  [style]="{ width: '100%' }" >
                </p-dropdown>
                <small id="regionValidator" *ngIf="
                    formApprovalRank.controls['region'].invalid &&
                    (formApprovalRank.controls['region'].dirty ||
                      formApprovalRank.controls['region'].touched)
                  " class="p-error">{{"ApprovalRankComponent.Create.RegionMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>
          <!-- Cedi -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Cedi" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="cedi"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': cedi.invalid && cedi.touched}"
                  formControlName="cedi"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.CediPlaceHolderCombo'| translate}}"
                  [options]="objectCediList" [style]="{ width: '100%' }" >
                </p-dropdown>
                <small id="cediValidator" *ngIf="
                    formApprovalRank.controls['cedi'].invalid &&
                    (formApprovalRank.controls['cedi'].dirty ||
                      formApprovalRank.controls['cedi'].touched)
                  " class="p-error">{{"ApprovalRankComponent.Create.CediMsgValidate" | translate}}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Process -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Process" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="process"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': process.invalid && process.touched}"
                  formControlName="process"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.ProcessPlaceHolderCombo'| translate}}"
                  [options]="objectProcessList"
                  (click)="forkJoinData()"
                  [style]="{ width: '100%' }"
                  (onChange)="getSubprocessByCodeProcess()">
                </p-dropdown>
                <small id="processValidator" *ngIf="
                    formApprovalRank.controls['process'].invalid &&
                    (formApprovalRank.controls['process'].dirty ||
                      formApprovalRank.controls['process'].touched)
                  " class="p-error">{{"ApprovalRankComponent.Create.ProcessMsgValidate" | translate}}</small>
              </div>
            </div>
          </div>

          <!-- Subprocess -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.Subprocess" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="subprocess"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': subprocess.invalid && subprocess.touched}"
                  formControlName="subprocess"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.SubprocessPlaceHolderCombo'| translate}}"
                  [options]="objectSubprocessList"
                  (click)="getSubprocessByCodeProcess()"
                  [style]="{ width: '100%' }" >
                </p-dropdown>
                <small id="subprocessValidator" *ngIf="
                        formApprovalRank.controls['subprocess'].invalid &&
                        (formApprovalRank.controls['subprocess'].dirty ||
                          formApprovalRank.controls['subprocess'].touched)
                      " class="p-error">{{"ApprovalRankComponent.Create.SubprocessMsgValidate" | translate}}</small>
              </div>
            </div>
          </div>

          <!-- UnitMeasurement -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ApprovalRankComponent.UnitMeasurement" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="unitMeasurement"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': unitMeasurement.invalid && unitMeasurement.touched}"
                  formControlName="unitMeasurement"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="{{'ApprovalRankComponent.Create.UnitMeasurementPlaceHolderCombo'| translate}}"
                  [options]="objectUnitMeasurementList"
                  [style]="{ width: '100%' }" >
                </p-dropdown>
                <small id="unitMeasurementValidator" *ngIf="
                        formApprovalRank.controls['unitMeasurement'].invalid &&
                        (formApprovalRank.controls['unitMeasurement'].dirty ||
                          formApprovalRank.controls['unitMeasurement'].touched)
                      " class="p-error">{{"ApprovalRankComponent.Create.UnitMeasurementMsgValidate" |
                  translate}}</small>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
      <div class="row mt-5">
        <div class="pstn_container container-fluid">
          <div class="row justify-content-between">
            <div class=" align-self-center" *ngIf="!this.isJustLook">
              <button id="btnAddNew" pButton pRipple type="button"
                label="{{ 'ApprovalRankComponent.Create.AddRecord' | translate }}"
                class="p-button-rounded pstn_primario w-100" (click)="addRecordApprovalRankLevel()"></button>
            </div>
          </div>
          <div class="mt-2"></div>
          <p-table #dt
            editMode="row"
            name="gridApprovalRank"
            [paginator]="true"
            [rows]="10"
            paginatorPosition="bottom"
            [value]="objectApprovalRankList"
            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
            [rowsPerPageOptions]="[10,25,50,100]"
            styleClass="p-datatable-customers">
            <ng-template pTemplate="header" style="width: 100%;">
              <tr>
                <th style="width: 30%;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ 'ApprovalRankComponent.ApprovalLevel' | translate }}
                  </div>
                </th>

                <th style="width: 30%;">
                  <div class="p-d-flex p-jc-between p-ai-center">{{ 'ApprovalRankComponent.RankStart' | translate }}
                  </div>
                </th>
                <th style="width: 30%;">
                  <div class="p-d-flex p-jc-between p-ai-center">{{ 'ApprovalRankComponent.RankEnd' | translate }}
                  </div>
                </th>
                <th style="width: 10%;">
                  {{ "Actions" | translate }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rank>
              <tr>
                <td>
                  <p-dropdown id="profile"
                        optionLabel="label"
                        optionValue="value"
                        [(ngModel)]="rank.approvalLevel"
                        [filter]="true"
                        [showClear]="true"
                        [options]="objectProfileList"
                        [style]="{ width: '100%' }"
                        [disabled]="this.isJustLook"
                        placeholder="{{'ApprovalRankComponent.Create.ApprovalLevelPlaceHolderCombo'| translate}}">
                </p-dropdown>
                </td>
                <td>
                  <p-dropdown id="operatorStart"
                        optionLabel="label"
                        optionValue="value"
                        [showClear]="true"
                        [(ngModel)]="rank.operatorStart"
                        [options]="objectOperatorListStart"
                        [style]="{ width: '40%' }"
                        [disabled]="this.isJustLook"
                        placeholder="{{'ApprovalRankComponent.Create.Select'| translate}}">
                  </p-dropdown>
                  <p-inputNumber
                        [style]="{ width: '50%' }"
                        [inputStyle]="{'width':'50%'}"
                        [min]="0"
                        [max]="999999999999999"
                        [(ngModel)]="rank.rankStart"
                        (ngModelChange)="calculateRanges(rank)"
                        [disabled]="this.isJustLook"></p-inputNumber>
                </td>
                <td>
                  <p-dropdown id="operatorEnd"
                        optionLabel="label"
                        optionValue="value"
                        [showClear]="true"
                        [(ngModel)]="rank.operatorEnd"
                        [options]="objectOperatorListEnd"
                        [style]="{ width: '40%'}"
                        [disabled]="this.isJustLook"
                        placeholder="{{'ApprovalRankComponent.Create.Select'| translate}}">
                  </p-dropdown>
                  <p-inputNumber
                        [style]="{ width: '50%' }"
                        [inputStyle]="{'width':'50%'}"
                        [min]="0"
                        [max]="999999999999999"
                        [(ngModel)]="rank.rankEnd"
                        (ngModelChange)="calculateRanges(rank)"
                        [disabled]="this.isJustLook"></p-inputNumber>
                </td>
                <td>
                  <i
                  aria-hidden="true"
                  id="btnDelete"
                  class="uil uil-trash-alt eliminar"
                  (click)="deleteRecordApprovalRankLevel(rank)"
                  *ngIf="!this.isJustLook && (rolePermission.delete | rolePermission)"></i>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4">{{ 'ElementsNotFound' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <!-- Buttons -->
      <div class="row justify-content-end">
        <div class="col-lg-4 md-3 mt-3">
          <button id="btnCancel"
                  pButton
                  pRipple
                  type="button"
                  label="{{ 'CancelBtn' | translate }}"
                  class="p-button-rounded pstn_secundario w-100"
                  (click)="onCancel()"></button>
        </div>
        <div class="col-lg-4 md-3 mt-3 align-items-end" *ngIf="!this.isJustLook">
          <button id="btnCreate"
                  pButton
                  pRipple
                  type="button"
                  label="{{ 'SaveBtn' | translate }}"
                  class="p-button-rounded pstn_primario w-100"
                  (click)="onAdd()"
                  *ngIf="this.isCreate && (rolePermission.create | rolePermission)"></button>
          <button id="btnUpdate"
                  pButton
                  pRipple
                  type="button"
                  label="{{ 'UpdateBtn' | translate }}"
                  class="p-button-rounded pstn_primario w-100"
                  (click)="onUpdate()"
                  *ngIf="!this.isCreate && (rolePermission.update | rolePermission)"></button>
        </div>
      </div>
      <div class="row mt-3"></div>
  </div>
</section>
<p-toast></p-toast>
