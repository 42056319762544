import { IFieldsClientFilter } from "../interfaces/show-fields-client-filter.interface";

export class FieldsClientFilterModel implements IFieldsClientFilter {
  constructor(
    public enabledBtn: boolean,
    public enabledSummary: boolean,
    public enabledExecuteFilters: boolean,
    public hiddenSectorandSocietyWithHierarchyLevelOne?: boolean,
    public enabledFilterAdvanced?: boolean,
    public enabledExecuteFiltersShared?: boolean
  ){}
  }
