import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { PaginationForId } from '@app/@shared/model/pagination-for-id.model';
import { IUsersInfoAccess } from './interfaces/users-info-access.interface';
import { AssociateProfileModel } from './models/associate-profile.model';
import { AssociateUserUpdateModel } from './models/associate-user-update.model';

@Injectable({
    providedIn: 'root'
})

export class AssociateService {
    private readonly httpService: HttpService;

    public associateInfoListModel: AssociateProfileModel[] = [];
    public associate: AssociateProfileModel;
    public associateUpdateComparer: AssociateUserUpdateModel[];

    constructor(httpService: HttpService) {
      this.httpService = httpService;
     }


   /**
   * @description This method is to get all the UsersInfoAccessModel.
   * @author Jhon Steven Pavón Bedoya
   * @param none
   * @returns Observable<IResponseService<UsersInfoAccessModel[]>>
   * @sprint 6
   */
  public getListUsersAccessManagement(paginationModel: PaginationForId): Observable<IResponseService<IUsersInfoAccess[]>> {
    let response$: Observable<IResponseService<IUsersInfoAccess[]>>;

    response$ = this.httpService
      .patch(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/users_info_access_management`, paginationModel)
      .pipe(
        map((response: HttpResponse<IResponseService<IUsersInfoAccess[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

    /**
   * @description This method is to return AssociateProfileModel[] storage.
   * @author Jhon Stiven Pavón Bedoya
   * @param none
   * @returns AssociateProfileModel[]
   * @sprint 6
   */
     getAssociateProfile(): AssociateProfileModel[] {
      return this.associateInfoListModel;
    }

    /**
     * @description This method is to storage AssociateProfileModel[].
     * @author Jhon Stiven Pavón Bedoya
     * @param number
     * @returns none
     * @sprint 6
     */
    setAssociateProfile(listAssociateProfileModel: AssociateProfileModel[]) {
      this.associateInfoListModel = listAssociateProfileModel;
    }

    getAssocieateEdit(): AssociateProfileModel {
      return this.associate;
    }

    setAssocieateEdit(associate: AssociateProfileModel){
      this.associate = associate;
    }


}
