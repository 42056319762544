export enum DomainTypeCore {
  SolicitudeType = 1,
  SolicitudeStatus = 2,
  Process = 3,
  Subprocess = 4,
  Operator = 5,
  UnitMeasurement = 6,
  TypePromotionalActivity = 7,
  CommercialActivityStatus = 8,
  SettlementPeriod=16,
  TypeMeasure=17,
  Consideration=18,
  TypeRedeemable=19,
  SubprocessRappel = 25,
}
