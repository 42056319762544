import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }

        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService],
})
export class NotificationModalComponent implements OnInit {

  @Input('showDialog') showDialog = false;
  @Output('eventHidden') eventHidden: EventEmitter<any> = new EventEmitter();

  @Input('title') title = '';
  @Input('id') id: number;
  @Input('notificationText') notificationText = '';
  @Input('notificationColor') notificationColor = '';
  @Input('moreInfo') moreInfo = false;
  @Input('commentary') commentary = false;
  @Input('snooze') snooze = false;
  @Input('reject') reject = false;
  @Input('accept') accept = false;
  @Input('moduleId') moduleId: number;
  @Input('type') type: number;

  @Output('eventClickSnooze') eventClickSnooze: EventEmitter<any> = new EventEmitter();
  @Output('eventClickReject') eventClickReject: EventEmitter<any> = new EventEmitter();
  @Output('eventClickAccept') eventClickAccept: EventEmitter<any> = new EventEmitter();
  @Output('eventClickSeeDocument') eventClickSeeDocument: EventEmitter<any> = new EventEmitter();

  public commentaryText : string;

  ngOnInit(): void {
    this.commentaryText = '';
  }

  /**
   * @description this method is to emit event the father.
   * @author Priscy Antonio Reales
   */
   onHidden(): void {
    this.eventHidden.emit(!this.showDialog);
  }

  /**
   * @description this method is to emit event the father.
   * @author Priscy Antonio Reales
   */
   onClickSnooze($event: any): void {
    this.eventClickSnooze.emit(this.id);
  }

  /**
   * @description this method is to emit event the father.
   * @author Priscy Antonio Reales
   */
   onClickReject($event: any): void {
    const objectEvent = this.getObjectEvent();
    this.eventClickReject.emit(objectEvent);
  }

  /**
   * @description this method is to emit event the father.
   * @author Priscy Antonio Reales
   */
   onClickAccept($event: any): void {
    const objectEvent = this.getObjectEvent();
    this.eventClickAccept.emit(objectEvent);
  }

  /**
   * @description this method is to emit event the father.
   * @author Priscy Antonio Reales
   */
  onClickSeeDocument($event : any) : void {
    const objectEvent = this.getObjectEvent();
    this.eventClickSeeDocument.emit(objectEvent);
  }

  /**
   * @description this method is to get object for event.
   * @author Priscy Antonio Reales
   */
  getObjectEvent():any{
    const objectEvent = {
      id : this.id,
      commentary : this.commentaryText,
      moduleId : this.moduleId,
      type : this.type
    };
    this.commentaryText = '';
    this.onHidden();
    return objectEvent;
  }
}