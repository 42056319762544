<form id="formAccessManagement" [formGroup]="formAccessManagement">
  <div class="pstn_container">
    <div class="row" *ngIf="showFieldsClient.clientsCode">
      <!-- Clients Code -->
      <div class="form-check col-md-4">
        <div class="form-group">
          <label>{{
            "AdvancedFiltersComponent.GeneralComponent.ClientCode" | translate
          }}</label>
        </div>
      </div>
      <div class="form-check col-md-4">
        <div class="form-group">
          <input
            id="clientsCode"
            formControlName="clientsCode"
            autocomplete="off"
            placeholder="123344,3212333,4553333"
            class="form-control"
            pInputText
          />
          <small id="activityDescMsgValidate" *ngIf="formAccessManagement.controls['clientsCode'].invalid && 
                                              (formAccessManagement.controls['clientsCode'].dirty || 
                                               formAccessManagement.controls['clientsCode'].touched)"
             class="p-error">{{ 'AdvancedFiltersComponent.GeneralComponent.ClientCodeMsgValidate' | translate }}</small>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="row" *ngIf="showFieldsClient.assignClients">
      <app-assing-clients
        [rolePermissionBtn]="rolePermissionBtn" 
        [showFields]="showFields"
        [validateFilter]="validateFilter"
        [requiredFields]="false"
        [filtersEdit]="filtersEdit"
        [clientsEdit]="clientsEdit"
        [isProcess]="isProcess"
        [btnShowEdit]="true">
      </app-assing-clients>
    </div>
  </div>
</form>
