import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILiberationHierarchy } from './interfaces/liberation-hierarchy.interface';
import { LiberationHierarchyModel } from './models/liberation-hierarchy.model';

@Injectable({
    providedIn: 'root',
  })
  export class LiberationHierarchyService {
    private readonly httpService: HttpService;
    public IdHierarchy : number;

    constructor(httpService: HttpService) {
      this.httpService = httpService;
    }
    
    public getAll(nameMaster : TypeCoreMaster): Observable<IResponseService<ICoreMaster[]>> {
        const response$: Observable<IResponseService<ICoreMaster[]>> = this.httpService
          .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/${nameMaster}/1/100`)
          .pipe(
            map((response: HttpResponse<IResponseService<ICoreMaster[]>>) => {
              return response.body;
            })
          );
        return response$;
      }

    public getAllType(typeEnviroment: Microservice, id : number): Observable<IResponseService<IDomain[]>> {
      const response$: Observable<IResponseService<IDomain[]>> = this.httpService
        .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/domains/domainTypes/${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<IDomain[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

    public getAllHierarchy(): Observable<IResponseService<ILiberationHierarchy[]>>{
      const response$: Observable<IResponseService<ILiberationHierarchy[]>> = this.httpService
        .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/1/10000`)
        .pipe(
          map((response: HttpResponse<IResponseService<ILiberationHierarchy[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

    public postHierarchy(body: LiberationHierarchyModel): Observable<IResponseService<any>>{
      const response$: Observable<IResponseService<any>> =
      this.httpService
     .post(
         `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration`, body)
     .pipe(
         map((response: HttpResponse<IResponseService<any>>) => {
         return response.body;
         })
     );
     return response$;
    }


    public postHierarchyCopy(body: LiberationHierarchyModel, id: number): Observable<IResponseService<any>>{
      const response$: Observable<IResponseService<any>> =
      this.httpService
     .post(
         `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/copy/${id}`, body)
     .pipe(
         map((response: HttpResponse<IResponseService<any>>) => {
         return response.body;
         })
     );
     return response$;
    }


    public putHierarchy(id: number, body: LiberationHierarchyModel): Observable<IResponseService<any>> {
      const response$: Observable<IResponseService<any>> =
       this.httpService
        .put(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/${id}`, body)
        .pipe(
          map((response: HttpResponse<IResponseService<any>>) => {
            return response.body;
          })
        );
      return response$;

    }

    public deleteHierarchyLogic(id: number): Observable<IResponseService<boolean>> {
      const response$: Observable<IResponseService<boolean>> =
       this.httpService
       .delete(
         `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/logic/${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<boolean>>) => {
            return response.body;
          })
        );
      return response$;
    }


    public getHierarchyId() : number {
      return this.IdHierarchy;
    }


    public setHierarchyId(id : number) {
      this.IdHierarchy = id;
    }

  }