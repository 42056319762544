import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CommercialActivityDiscountMessage {


  public static readonly NO_UNITMEASURE = 'El campo unidad de medida es obligatoria';
  public static readonly NO_UNITMEASURE_TRANSLATE = 'The unit of measure field is required';
  public static readonly NO_DISCOUNT_PRICE_ADJUSTMENT = 'Es obligatorio llenar el campo porcentaje o valor ajuste de precio';
  public static readonly NO_DISCOUNT_PRICE_ADJUSTMENT_TRANSLATE = 'It is mandatory to fill in the percentage field or price adjustment value';
  public static readonly NO_DISCOUNT_FINANCIAL = 'Es obligatorio el campo porcentaje descuento financiero';
  public static readonly NO_DISCOUNT_FINANCIAL_TRANSLATE = 'The financial discount percentage field is mandatory';
  public static readonly NO_DISCOUNT_KIND = 'Es obligatorio el campo porcentaje descuento en especie';
  public static readonly NO_DISCOUNT_KIND_TRANSLATE = 'The percentage discount in kind field is mandatory';
  public static readonly NO_DISCOUNT_VALUE = 'Es obligatorio el campo porcentaje descuento en valor';
  public static readonly NO_DISCOUNT_VALUE_TRANSLATE = 'The discount percentage field in value is mandatory';
  public static readonly NO_CHANNEL_SECTOR = 'Son obligatorios los campos sector y canal';
  public static readonly NO_CHANNEL_SECTOR_TRANSLATE = 'The sector and channel fields are mandatory';
  constructor() { }
}
