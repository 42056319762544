import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { NotificationInformativeModel } from '@app/@shared/model/notification-informative.model';
import { PaginationDateModel } from '@app/@shared/model/paginationDate.model';
import { PaginationHistoryModel } from '@app/@shared/model/paginationHistory.model';
import { PaginationNotificationUserModel } from '@app/@shared/model/paginationNotificationUser.model';
import { PaginationUserModuleModel } from '@app/@shared/model/paginationUserModule.model';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAdvancedFiltersMaterialsResult } from '../../../@shared/interfaces/advanced-filters-material-result.interface';
import { IAdvancedFiltersMaterial } from '../../../@shared/interfaces/advanced-filters-material.interface';
import { INotificationUser } from './interfaces/notification-user.interface';
import { ISolicitudeHistory } from './interfaces/solicitude-history.interface';
import { ISolicitudeJson } from './interfaces/solicitude-json.interface';
import { ISolicitudeNotificationJson } from './interfaces/solicitude-notification-json.interface';
import { SolicitudeCreationModel } from './models/solicitude-creation.model';
import { SolicitudeMaterialRequestModel } from './models/solicitude-material-request.model';
import { SolicitudeMaterialModel } from './models/solicitude-material.model';
import { SolicitudeNotificationRequestModel } from './models/solicitude-notification-request.model';
import { SolicitudeNotificationUserStatusModel } from './models/solicitude-notification-user-status.model';
import { SolicitudeStatusModel } from './models/solicitude-status.model';
import { SolicitudeMaterialApprovalModel } from './models/solicitude-material-approval.model';
import { IDataImpactAnalysisJson } from './interfaces/data-impact-analysis-json.interfaces';
import { SolicitudeCopyModel } from './models/copy-solicitude.model';
import { IAdvancedFilter } from '@app/@components/shared-advanced-filter/interfaces/general-advanced-filter.interface';
import { ISolicitudeFileResponse } from '@app/@components/upload-excel/interfaces/solicitude-file-response.interface';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { ISolicitudeDetailDifferentialWithDrawal } from './interfaces/solicitude-differential-withdrawal.interface';

@Injectable({
  providedIn: 'root',
})

export class SolicitudeService {
  private readonly httpService: HttpService;
  private readonly HttpClient: HttpClient;

  private solicitudeId: number;
  private isProcess: number;
  private solicitudType: number;

  constructor(
    httpService: HttpService,
    HttpClient: HttpClient
  ) { 
      this.httpService = httpService;
      this.HttpClient = HttpClient;
  }


  /**
   * @description This method is to get all the ISolicitudeJson.
   * @author djgil
   * @param none
   * @returns Observable<IResponseService<ISolicitudeJson[]>>
   * @sprint 4
   */
  public getListSolicitude(
    paginationDateModel: PaginationDateModel
  ): Observable<IResponseService<ISolicitudeJson[]>> {
    let response$: Observable<IResponseService<ISolicitudeJson[]>>;
    response$ = this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_solicitude`,
        paginationDateModel
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ISolicitudeJson[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to get a Solicitude With Detail.
   * @author Priscy Antonio Reales
   * @param none
   * @returns Observable<IResponseService<ISolicitudeJson>>
   * @sprint 5
   */
  public getSolicitudeWithDetailById(id: number): Observable<IResponseService<ISolicitudeJson>> {
    const response$: Observable<IResponseService<ISolicitudeJson>> =
       this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/solicitude_with_detail/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ISolicitudeJson>>) => {
          return response.body;
        })
      );
    return response$;
  }

    /**
   * @description This method is to return id storage.
   * @author Daniel Londono
   * @returns boolean
   * @sprint 12
   */
     getIsCreate(): number {
      return this.isProcess;
    }

    /**
     * @description This method is to storage id.
     * @author Daniel Londono
     * @param number
     * @returns none
     * @sprint 12
     */
    setIsCreate(IdProcess: number) {
      this.isProcess = IdProcess;
    }

    getSolicitudType(): number{
      return this.solicitudType;
    }

    setSolicitudType(SolicitudType : number){
      this.solicitudType = SolicitudType;
    }

  /**
   * @description This method is to storage id.
   * @author djgil
   * @param number
   * @returns none
   * @sprint 4
   */
  setSolicitudeId(id: number) {
    this.solicitudeId = id;

  }

  /**
   * @description This method is to return id storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 5
   */
  getSolicitudeId(): number {
    return this.solicitudeId;
  }

  /**
   * @description This method is to delete a AccessManagement.
   * @author djgil
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 1
   */
  public deleteSolicitude(id: string): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
    this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

    /**
  * @description This method is to get List of string with material codes with solicitude pending information
  * @author Daniel Londono
  * @returns Observable<ResponseService>
  * @sprint 5
  */
  getPendingSolicitudeByMaterialCode(
    body: SolicitudeMaterialModel[]
  ) {
    const response$: Observable<IResponseService<string[]>> =
      this.httpService
        .post(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/validate_pendings_solicitude`, body)
        .pipe(
          map((response: HttpResponse<IResponseService<string[]>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
   * @description This method is to create Solicitude and SolicitudeMaterial.
   * @author Priscy Antonio Reales
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 5
   */
  public postSolicitudeWhitSolicitudeMaterial(
    body: SolicitudeMaterialRequestModel
  ): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
    this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/associate_solicitude_with_solicitude_material`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to update Solicitude and SolicitudeMaterial.
   * @author Priscy Antonio Reales
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 5
   */
  public putSolicitudeWhitSolicitudeMaterial(
    id: number,
    body: SolicitudeMaterialRequestModel
  ): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/associate_solicitude_with_solicitude_material/${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to delete a SolicitudeMaterial by Ids.
  * @author Priscy Antonio Reales
  * @param params
  * @returns Observable<ResponseService>
  * @sprint 5
  */
  public deleteSolicitudeMaterialByIds(ids: string): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
    this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/logic_by_ids/${ids}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to get dummy information
  * @author Daniel Londono
  * @returns Observable<ResponseService>
  * @sprint 5
  */
  getProducts() {
    return this.HttpClient.get<any>('assets/products.json')
      .toPromise()
      .then((res) => <SolicitudeCreationModel[]>res.solicitudeMaterial)
      .then((data) => {
        return data;
      });
  }

  /**
* @description This method is to get all the solicitude history.
* @author David Cardona Cardenas
* @param none
* @returns Observable<IResponseService<ISolicitudeHistory[]>>
* @sprint 4
*/
  public getListSolicitudeHistory(
    paginationHistoryModel: PaginationHistoryModel
  ): Observable<IResponseService<ISolicitudeHistory[]>> {
    const response$: Observable<IResponseService<ISolicitudeHistory[]>> =
    this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_solicitude_history`,
        paginationHistoryModel
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ISolicitudeHistory[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to get all the ISolicitudeNotification.
 * @author David Cardona Cardenas
 * @param none
 * @returns Observable<IResponseService<ISolicitudeNotification[]>>
 * @sprint 6
 */
  public getListSolicitudeNotification(
    paginationUserModuleModel: PaginationUserModuleModel
  ): Observable<IResponseService<ISolicitudeNotificationJson[]>> {
    const response$: Observable<IResponseService<ISolicitudeNotificationJson[]>> =
    this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_solicitude_notification`,
        paginationUserModuleModel
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ISolicitudeNotificationJson[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
* @description This method is to get all the INotificationUser.
* @author David Cardona Cardenas
* @param none
* @returns Observable<IResponseService<INotificationUser[]>>
* @sprint 6
*/
  public getListNotificationUser(
    paginationUserModuleModel: PaginationNotificationUserModel
  ): Observable<IResponseService<INotificationUser[]>> {
    const response$: Observable<IResponseService<INotificationUser[]>> =
     this.httpService
      .patch(
        `${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/list_notification_user`,
        paginationUserModuleModel
      )
      .pipe(
        map((response: HttpResponse<IResponseService<INotificationUser[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to create SolicitudeNotification and SolicitudeNotificationUser.
   * @author David Sneider Cardona Cardenas
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 6
   */
  public postSolicitudeNotification(body: SolicitudeNotificationRequestModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
      this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/notification_associate_with_user`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to update status solicitude.
   * @author David Sneider Cardona Cardenas
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 6
   */
  public putSolicitudeStatus(id: number, body: SolicitudeStatusModel): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
    this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/update_solicitude_status/${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to update status solicitude notification user.
   * @author Priscy Antonio Reales Mozo
   * @param id
   * @param body
   * @returns Observable<ResponseService>
   * @sprint 6
   */
   public putStatusSolicitudeNotificationUser(id: number, body: SolicitudeNotificationUserStatusModel): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_notification_users/update_status/${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to filters.
   * @author Elkin Vasquez Isenia
   * @param filters
   * @returns Observable<IResponseService<IAdvancedFiltersMaterialsResult>>
   * @sprint 7
   */
  public postAdvancedFiltersMaterials(filters: IAdvancedFiltersMaterial): Observable<IResponseService<IAdvancedFiltersMaterialsResult>> {
    const response$: Observable<IResponseService<IAdvancedFiltersMaterialsResult>> =
     this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/advanced_filters_materials`,
        filters
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IAdvancedFiltersMaterialsResult>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public getListImpactAnalysis(): Observable<any[]> {
    const response$: Observable<IResponseService<any[]>> =
    <any>this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/1/1000`)
      .pipe(
        map((response: HttpResponse<any[]>) => {
          return response.body;
        })
      );
    return <any>response$;
  }


    /**
   * @description This method put set data imapct analysis.
   * @author Daniel Londoño
   */
    public postSetDataImpactAnalysis(body: IDataImpactAnalysisJson): Observable<any> {
      let response$: Observable<any>;
      response$ = this.httpService
        .post(`${environment.apiAnalytics}/api/v1/ccvp_apwb_pric/impact`,body)
        .pipe(
          map((response: HttpResponse<any>) => {
            return response.body;
          })
        );
      return response$;
    }

     /**
   * @description This method put set data imapct analysis.
   * @author Daniel Londoño
   */
     public postDataImpact(body: IDataImpactAnalysisJson[]): Observable<any> {
      let response$: Observable<any>;
      response$ = this.httpService
        .post(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/impact`,body)
        .pipe(
          map((response: HttpResponse<any>) => {
            return response.body;
          })
        );
      return response$;
    }

  /**
   * @description This method get imapct analysis.
   * @author smartinezr
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 1
   */
  public getImpactAnalysis(id: string, slug : string): Observable<any> {
    let response$: Observable<any>;
    response$ = this.httpService
      .get(`${environment.apiAnalytics}/api/v1/ccvp_apwb_pric/impact/${id}/${slug}`)
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
    return response$;
  }



  /**
   * @description This method is to set notification informative for solicitude.
   * @author Priscy Antonio Reales Mozo
   * @param body
   * @returns Observable<IResponseService<boolean>>
   * @sprint 11
   */
  public putSolicitudeNotificationInformative(body: NotificationInformativeModel): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/set_solicitude_notification_informative/${body.solicitudeId}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

    /**
   * @description This method is to change status approval solicitude material
   * @author Daniel Londono
   * @param body
   * @returns Observable<IResponseService<boolean>>
   * @sprint 11
   */
    public putSolicitudeMaterialApproval(body: SolicitudeMaterialApprovalModel): Observable<IResponseService<boolean>> {
      const response$: Observable<IResponseService<boolean>> =
       this.httpService
        .put(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/approval_material/${body.id}`,
          body
        )
        .pipe(
          map((response: HttpResponse<IResponseService<boolean>>) => {
            return response.body;
          })
        );
      return response$;
    }

    public postCreateUpdatePricing(idSolcitude: number){
      const response$: Observable<IResponseService<string>> =
       this.httpService
        .post(
          `${environment.apiUrlIntegration}/api/v1/ccvp_apwb_inte/integration_sap/create_update_pricing/${idSolcitude}`
        )
        .pipe(
          map((response: HttpResponse<IResponseService<string>>) => {
            return response.body;
          })
        );
      return response$;
    }

      /**
   * @description This method get imapct analysis.
   * @author daniel londono
   * @sprint 15
   */
  public getStatusImpactAnalysis(id: string): Observable<any> {
    const response$: Observable<any> =
     this.httpService
      .get(`${environment.apiAnalytics}/api/v1/ccvp_apwb_stat/${id}/`)
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
    return response$;
  }

  public copy(activity: SolicitudeCopyModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .post(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/copy`,
      activity)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public putApprovalSolicitudeTemp(id: number, status : number): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/approval_material_temp/${id}/${status}` 
        )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public putApprovalSolicitude(id: number, body: SolicitudeStatusModel): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/approval_solicitude/${id}`, body 
        )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Jaramillo Munera
   * @param none
   * @returns number
   * @sprint 8
   */
  public getSolicitudeFilter(filters: IAdvancedFilter): Observable<IResponseService<ISolicitudeJson[]>> {
    var url = `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/list_solicitude_filter_async`;
    const response$: Observable<IResponseService<ISolicitudeJson[]>> =
      this.httpService
        .post(url, filters)
        .pipe(
          map((response: HttpResponse<IResponseService<ISolicitudeJson[]>>) => {
            return response.body;
          })
        );
    return response$;
  }  


  public postUploadFileSolicutdeMaterial(
    formData: FormData,
    idSolcitude : number,
    codeCreation : string,
    solicitudeType : SolicitudeType
    ): Observable<IResponseService<ISolicitudeFileResponse>> {      

    const response$: Observable<IResponseService<ISolicitudeFileResponse>> =
     this.httpService
    .postFile(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/upload_file_solicitude/${idSolcitude}/${codeCreation}/${solicitudeType}`,formData)
    .pipe(
      map((response: HttpResponse<IResponseService<ISolicitudeFileResponse>>) => {
          return response.body;
      })
    );
    return response$;
  }  

  public putSolicitudeMaterial(body : SolicitudeCreationModel): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/${body.id}`, body
        )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description Delete SolicitudeMaterial by Approver.
  * @author WArboleda
  * @param params
  * @returns Observable<ResponseService>
  * @sprint 57
  */
  public deleteSolicitudeMaterialIdByApprover(id: string): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
    this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/logic_by_approver/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description Get Solicitude Material Deleted by Approver
   * @author WArboleda
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 57
   */
  public getSolicitudeMaterialDeletedByApprover(solicitudeId: number): Observable<any> {
    let response$: Observable<any>;
    response$ = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/solicitude_materials_deleted_by_approver/${solicitudeId}`)
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description Get solicitude material rules affect by withdrawal
   * @author WArboleda
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 63
   */
  public getSolicitudeMaterialRulesAffectByWithdrawal(solicitudeId: number): Observable<IResponseService<ISolicitudeDetailDifferentialWithDrawal[]>> {
    let response$: Observable<IResponseService<ISolicitudeDetailDifferentialWithDrawal[]>>;
    response$ = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/differencial_solicitude_material_rules_affect_by_withdrawal/${solicitudeId}`)
      .pipe(
        map((response: HttpResponse<IResponseService<ISolicitudeDetailDifferentialWithDrawal[]>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
