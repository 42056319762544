import { IMaximumPeriodValidityCreate } from './../interfaces/maximum-period-validity-create.interface';


export class MaximumPeriodValidityCreateModel implements IMaximumPeriodValidityCreate{
  /**
   * @author kmora
   * @param subProcessDomainId
   * @param numberDay
   * @sprint 9
   */
  constructor(
    public subProcessDomainId: string,
    public numberDay: number,

  ) {}
}
