import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListOperationByRoleModel } from '@app/@modules/administration/select-profile-module/models/list-operation-by-role.model';
import { SelectProfileModuleService } from '@app/@modules/administration/select-profile-module/select-profile-module.service';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectedActivityModuleService } from './selected-activity.service';

@Component({
  selector: 'app-selected-activity',
  templateUrl: './selected-activity.component.html',
  styleUrls: ['./selected-activity.component.css']
})
export class SelectedActivityComponent implements OnInit {

  public listOperation: ListOperationByRoleModel[];
  private readonly spinner: NgxSpinnerService;
  private readonly selectsServices: SelectedActivityModuleService;
  private readonly router: Router;
  public profile: string;

  constructor(
    selectProfileModuleService: SelectProfileModuleService,
    spinner: NgxSpinnerService,
    selectsServices: SelectedActivityModuleService,
    router: Router,
  ) {
    this.router = router;
    this.listOperation = [];
    this.spinner = spinner;
    this.selectsServices = selectsServices;
  }

  ngOnInit(): void {
    this.profile = SessionStorageHelper.get(CommonConfiguration.Profile);
    const moduleId = Number(SessionStorageHelper.get(CommonConfiguration.ModuleId));
    const profileId = Number(SessionStorageHelper.get(CommonConfiguration.ProfileId));
    this.getListOperationByRole(profileId, moduleId);
  }



  getListOperationByRole(profileId: number, moduleId: number): void {
    this.spinner.show();

    this.selectsServices.getListOperationUser(moduleId)
    .subscribe((response) => {
      if(response.status){
        sessionStorage.removeItem(ApplicationConstants.CONSTANTS.listOperation);
          this.listOperation = [];

          this.spinner.hide();
          this.listOperation = response.data;
          sessionStorage.setItem(ApplicationConstants.CONSTANTS.listOperation, JSON.stringify(this.listOperation));
      }
    },
    (error: HttpErrorResponse) => {
      this.spinner.hide();
      SweetAlertMessageHelpers.exception(error);
    });
  }

  sendRoute(route: string){
    this.router.navigate([route]);
    SessionStorageHelper.remove('filters')
  }

  returnShow(){
    this.router.navigate(['auth/select-profile-module']);
  }

}
