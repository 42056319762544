import { UUID } from 'angular2-uuid';

import { Component, OnInit, Input, SimpleChange, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';

import { ISkuCalculateList } from './interfaces/sku-calculate-list.interface';

import { CommercialActivityCalculateMessages } from './messages/commercial-activity-calculate-messages';

import { IdCalculateImpact } from './models/id-calculate-impact.model';
import { ICommercialActivityCalculateImpact } from './interfaces/commercial-activity-calculate-impact.interface';
import { CommercialActivitySkuCalculateImpact } from './models/commercial-activity-sku-calculate-impact.model';
import { CommercialActivityClientCalculateImpact } from './models/commercial-activity-client-calculate-impact.model';
import { CommercialActivityCalculateImpact } from './models/commercial-activity-calculate-impact.model';

import { NgxSpinnerService } from 'ngx-spinner';
import { CommercialActivityCalculateService } from './commercial-activity-calculate.service';
import { CommercialActivitySubprocess, CommercialActivityCalculateSlugEnum } from '@shared/enums/comercial-activity-subprocess.enums';
import { CommercialActivityStatus } from '@shared/enums/commercial-activity-status.enum';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { ISectorConsolidated } from './interfaces/sector-consolidated.interface';
import { SectorConsolidatedModel } from './models/sector-consolidated.model';
import { Util } from '@app/@shared/util';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';

@Component({
	selector: 'app-commercial-activity-calculate',
	templateUrl: './commercial-activity-calculate.component.html',
	styleUrls: ['./commercial-activity-calculate.component.css', '...../../../../@shared/scss/responsive.table.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommercialActivityCalculateComponent implements OnInit {

	public productsToCalculate: any = [];

	public totalSumCostIncome  = 0;
	public totalSumEbitdaPercentage = 0;
	public totalSumEbitda = 0;
	public totalSumPromotionValue = 0;
	public totalSumWeightedValue = 0;
	public totalSUmKpiLiberationSku = 0;

	public totalSumCostIncomeProm = 0;
	public totalSumEbitdaPercentageProm = 0;
	public totalSumEbitdaProm = 0;
	public totalSumPromotionValueProm = 0;
	public totalSumWeightedValueProm = 0;
	public totalSUmKpiLiberationSkuProm = 0;
	public language: string;
	public activityUid: string = "";
	public QtyStateExecuted = 0;

	//Consolidado
	public totalClient = 0;
	public totalPromotionValue = 0;
	public totalInvestmentEbitda = 0;
	public totalEbitdaConsolidated = 0;

	public objectAlcoholic: ISectorConsolidated[] = [];
	public objectNoAlcoholic: ISectorConsolidated[] = [];
	public objectFood: ISectorConsolidated[] = [];


	// #region calculate variables
	@Input('commercialACtivityId') commercialACtivityId = 0;
	@Input('statusDomainId') statusDomainId = 0;
	@Input('commercialActivityType') commercialActivityType = 0;
	@Input('filterClientJson') filterClientJson = '';
	@Input('purchaseBaseToCalculate') purchaseBaseToCalculate: ISkuCalculateList[] = [];
	@Input('purchasePromotionToCalculate') purchasePromotionToCalculate: ISkuCalculateList[] = [];
	@Input('deltaDays') deltaDays = 0;
	@Input('maxPromotion') maxPromotion = 0;
	@Input('maxPeriod') maxPeriod = 0;
	@Input('nClients') nClients = 0;
	@Input('isCalculate') isCalculate = false;
	@Input('nConsideration') nConsideration = 0;

	@Input('totalDepositValue') totalDepositValue = 0.0;
	@Input('totalEbitdaPercentage') totalEbitdaPercentage = 0.0;
	@Input('totalEbitda') totalEbitda = 0.0;

	@Input('totalDepositValue') totalPromotionDepositValue = 0.0;
	@Input('totalDepositValue') totalPromotionEbitdaPercentage = 0.0;
	@Input('totalDepositValue') totalPromotionEbitda = 0.0;

	@Output('eventSendToCalculate') eventSendToCalculate: EventEmitter<any> = new EventEmitter();

	public commercialActivityTypeEnum: any;
	public commercialActivityStateEnum: any;
	private readonly spinner: NgxSpinnerService;
	private readonly commActivityCalculateService: CommercialActivityCalculateService;

	constructor(spinner: NgxSpinnerService,
		commActivityCalculateService: CommercialActivityCalculateService) {
		this.spinner = spinner;
		this.commActivityCalculateService = commActivityCalculateService;
	}


	ngOnInit(): void {
		this.QtyStateExecuted = 0;
		this.activityUid = SessionStorageHelper.get(`Calculo indicadores:${this.commercialACtivityId.toString()}`);
		this.commercialActivityTypeEnum = this.getCommercialActivityTypeEnum();
		this.commercialActivityStateEnum = this.getCommercialActivityStatusEnum();
		this.calculateTotals();
		this.getGrupoSector();

	}

	ngOnChanges(changes: { [property: string]: SimpleChange }) {
		this.language = sessionStorage.getItem('language');
		const purchaseBaseToCalculateChange: SimpleChange = changes['purchaseBaseToCalculate'];
		const purchasePromotionToCalculateChange: SimpleChange = changes['purchasePromotionToCalculate'];
		this.calculateTotals();
	}


	getGrupoSector(){
		let alcoholicSector = false;
		let noAlcoholicSector = false;
		let foodSector = false;

		let baseCostZ1 = 0;
		let baseEbitdaMilZ1 = 0;
		let baseEbitdaPercZ1 = 0;

		let baseCostZ2 = 0;
		let baseEbitdaMilZ2 = 0;
		let baseEbitdaPercZ2 = 0;

		let baseCostZ3 = 0;
		let baseEbitdaMilZ3 = 0;
		let baseEbitdaPercZ3 = 0;

		this.commActivityCalculateService.getPromotionalActivityById(this.commercialACtivityId)
		.subscribe(
			(response: any) => {
				if (response.status) {
					this.totalClient = response.data.totalClientsFilter;
					if(response.data.purchaseBasePromotions != null){
						response.data.purchaseBasePromotions.forEach((x: any) => {
							x.purchaseBasePromotionDetails.forEach((d: any) => {
								if(d.sectorCode === 'Z1'){
									//No Alcoholicas
									noAlcoholicSector = true;
									baseCostZ1 += d.costIncome;
									baseEbitdaMilZ1 += d.ebitda;
									baseEbitdaPercZ1 += d.ebitdaPercentage;

								}else if(d.sectorCode === 'Z2'){
									//Alcoholicas
									alcoholicSector = true;
									baseCostZ2 += d.costIncome;
									baseEbitdaMilZ2 += d.ebitda;
									baseEbitdaPercZ2 += d.ebitdaPercentage;

								}else if(d.sectorCode === 'Z3'){
									//Alimentos
									foodSector = true;
									baseCostZ3 += d.costIncome;
									baseEbitdaMilZ3 += d.ebitda;
									baseEbitdaPercZ3 += d.ebitdaPercentage;
								}
							});
						});
					}

					if(response.data.purchaseBases != null){
						response.data.purchaseBases.forEach((x: any) => {
							x.purchaseBaseDetails.forEach((d:any) => {
								if(d.sectorCode === 'Z1'){
									//No Alcoholicas
									noAlcoholicSector = true;
									baseCostZ1 += d.costIncome;
									baseEbitdaMilZ1 += d.ebitda;
									baseEbitdaPercZ1 += d.ebitdaPercentage;

								}else if(d.sectorCode === 'Z2'){
									//Alcoholicas
									alcoholicSector = true;
									baseCostZ2 += d.costIncome;
									baseEbitdaMilZ2 += d.ebitda;
									baseEbitdaPercZ2 += d.ebitdaPercentage;

								}else if(d.sectorCode === 'Z3'){
									//Alimentos
									foodSector = true;
									baseCostZ3 += d.costIncome;
									baseEbitdaMilZ3 += d.ebitda;
									baseEbitdaPercZ3 += d.ebitdaPercentage;
								}
							});

						});
					}

					if(noAlcoholicSector === true){
						const auxCost: SectorConsolidatedModel = new SectorConsolidatedModel('Ingreso bruto', baseCostZ1);
						const auxEbitdaMil: SectorConsolidatedModel = new SectorConsolidatedModel('Ebitda en miles', baseEbitdaMilZ1);
						const auxEbitdaPer: SectorConsolidatedModel = new SectorConsolidatedModel('% ebitda', baseEbitdaPercZ1);

						this.objectNoAlcoholic.push(auxCost);
						this.objectNoAlcoholic.push(auxEbitdaMil);
						this.objectNoAlcoholic.push(auxEbitdaPer);

					}

					if(alcoholicSector === true){
						const auxCost: SectorConsolidatedModel = new SectorConsolidatedModel('Ingreso bruto', baseCostZ2);
						const auxEbitdaMil: SectorConsolidatedModel = new SectorConsolidatedModel('Ebitda en miles', baseEbitdaMilZ2);
						const auxEbitdaPer: SectorConsolidatedModel = new SectorConsolidatedModel('% ebitda', baseEbitdaPercZ2);

						this.objectAlcoholic.push(auxCost);
						this.objectAlcoholic.push(auxEbitdaMil);
						this.objectAlcoholic.push(auxEbitdaPer);

					}

					if(foodSector === true){
						const auxCost: SectorConsolidatedModel = new SectorConsolidatedModel('Ingreso bruto', baseCostZ3);
						const auxEbitdaMil: SectorConsolidatedModel = new SectorConsolidatedModel('Ebitda en miles', baseEbitdaMilZ3);
						const auxEbitdaPer: SectorConsolidatedModel = new SectorConsolidatedModel('% ebitda', baseEbitdaPercZ3);

						this.objectFood.push(auxCost);
						this.objectFood.push(auxEbitdaMil);
						this.objectFood.push(auxEbitdaPer);
					}
				}
			}
		);
	}


	calculateTotals(): void {
		this.restartTotals();
		this.ValidatePurchaseBaseToCalculateLengthGreatZero();
		this.ValidatePurchasePromotionToCalculateGreatZero();
		//Agregar suma base+promoción
		this.AddSumBasePromotion();
	}

	restartTotals() {
		this.totalSumCostIncome = 0;
		this.totalSumEbitdaPercentage = 0;
		this.totalSumEbitda = 0;
		this.totalSumPromotionValue = 0;
		this.totalSumWeightedValue = 0;
		this.totalSUmKpiLiberationSku = 0;
		this.totalSumCostIncomeProm = 0;
		this.totalSumEbitdaPercentageProm = 0;
		this.totalSumEbitdaProm = 0;
		this.totalSumPromotionValueProm = 0;
		this.totalSumWeightedValueProm = 0;
		this.totalSUmKpiLiberationSkuProm = 0;
	}

	public getCommercialActivityTypeEnum(): typeof CommercialActivitySubprocess {
		return CommercialActivitySubprocess;
	}

	public getCommercialActivityStatusEnum(): typeof CommercialActivityStatus {
		return CommercialActivityStatus;
	}

	/**
	 * @description This method is to send to calculate data from commercial activity type
	 * @sprint 18
	 * @author Luis Estrella
	 */
	sendToCalculate(): void {
		if(this.nClients === 0){
			SweetAlertMessageHelpers.warning(SweetAlert.titleWarning,
				(this.language === CommonConfiguration.LanguageKey)
					? CommercialActivityCalculateMessages.CUSTOMER_ZERO_ES
					: CommercialActivityCalculateMessages.CUSTOMER_ZERO_EN);
			return;
		}
		const zeroPsp = this.purchaseBaseToCalculate.filter((mat) => (mat.currentPsp < 1 || mat.currentPsp === null));
		if (this.commercialActivityType === CommercialActivitySubprocess.Promotions) {
			const zeroPsp2 = this.purchasePromotionToCalculate.filter((mat) => (mat.currentPsp < 1 || mat.currentPsp === null));
			if (zeroPsp2.length > 0) {
				let msj = '';
				zeroPsp2.forEach((mat) => {
					msj += `${mat.materialCode} - ${mat.materialDescription} \n`;
				});
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.warning(
					language === 'es-ES' ? CommercialActivityCalculateMessages.titleErrorSKUWithoutPsp : CommercialActivityCalculateMessages.titleErrorSKUWithoutPspTranslate,
					language === 'es-ES' ? `${CommercialActivityCalculateMessages.messageErrorSKUWithoutPsp} \n\n ${msj}`
					: `${CommercialActivityCalculateMessages.messageErrorSKUWithoutPspTranslate} \n\n ${msj}`
				);
				return;
			}
		}

		if (zeroPsp.length > 0) {
			let msj = '';
			zeroPsp.forEach((mat) => {
				msj += `${mat.materialCode} - ${mat.materialDescription} \n`;
			});
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? CommercialActivityCalculateMessages.titleErrorSKUWithoutPsp : CommercialActivityCalculateMessages.titleErrorSKUWithoutPspTranslate,
				language === 'es-ES' ? `${CommercialActivityCalculateMessages.messageErrorSKUWithoutPsp} \n\n ${msj}`
				: `${CommercialActivityCalculateMessages.messageErrorSKUWithoutPspTranslate} \n\n ${msj}`
			);
			return;
		}

		this.spinner.show();

		const language: string = sessionStorage.getItem('language');
		SweetAlertMessageHelpers.info(
            language === 'es-ES' ? 'Información' : 'Information',
            language === 'es-ES' ? 'Cálculo de indicadores en proceso' :
			'Calculation of indicators in process');


			const slug = this.prepareSlugToCalculate(this.commercialActivityType);
			const commercialActivity: CommercialActivityCalculateImpact = this.getCommercialActivityCalculateImpactByType(this.commercialActivityType, slug);
			this.activityUid = commercialActivity.id.toString();
			SessionStorageHelper.save(`Calculo indicadores:${commercialActivity.scommercialactivity.toString()}`, this.activityUid);
	
			let activityUUID = this.activityUid ?? String(Util.newGuid());
			this.commActivityCalculateService.getPromotionalActivityCalcute(activityUUID, this.commercialACtivityId, slug).subscribe(
				(response: any) => {
				this.updateCalculatedData(response);
				this.calculateTotals();
				const rsp = {
					'purchaseBaseCalculate': this.purchaseBaseToCalculate,
					'purchasePromotionCalculate': this.purchasePromotionToCalculate,
				};
				this.eventSendToCalculate.emit(rsp);
		  }, (error: any) => {
			if(error){
				this.commActivityCalculateService.postPromotionalActivityCalculate(commercialActivity).subscribe(
					(response: any) => {
						  this.updateCalculatedData(response);
						  this.calculateTotals();
						  const rsp = {
							  'purchaseBaseCalculate': this.purchaseBaseToCalculate,
							  'purchasePromotionCalculate': this.purchasePromotionToCalculate,
						  };
						  this.eventSendToCalculate.emit(rsp);
					}, (error: any) => {
					  console.error(' 🔥 🐛 ERROR', error);
					}
				  );
			}
		    console.error(' 🔥 🐛 ERROR', error);
		  }
		);

	}

	/**
	 * @description This method is to prepare CommercialActivityCalculateImpact data to send to anal by commercialActivityType
	 * dr
	 * @param commercialActivityType type of commercial activity to prepare data
	 * @param slug string by commercial activity type to prepare data to send to anal
	 * @returns CommercialActivityCalculateImpact object
	 * @sprint 18
	 * @author Diego Cortes
	 */
	private getCommercialActivityCalculateImpactByType(commercialActivityType: number, slug: string,): CommercialActivityCalculateImpact {
		let commercialActivity: ICommercialActivityCalculateImpact;

		let skuCalculateImpact: CommercialActivitySkuCalculateImpact[] = this.prepareJsonByCommercialActivityType(this.commercialActivityType, slug);
		let clientsCalculate: CommercialActivityClientCalculateImpact[] = JSON.parse(this.filterClientJson);

		if (commercialActivityType === CommercialActivitySubprocess.Promotions) {
			commercialActivity = {
				id: UUID.UUID(),
				scommercialactivity: this.commercialACtivityId,
				slug: slug,
				delta_days: this.deltaDays,
				max_promotion: this.maxPromotion,
				max_period: this.maxPeriod,
				nclients: this.nClients,
				commercilactivity: skuCalculateImpact,
				JClientsFilters: clientsCalculate
			};
		}

		if (commercialActivityType === CommercialActivitySubprocess.Loyalty ||
			commercialActivityType === CommercialActivitySubprocess.Events ||
			commercialActivityType === CommercialActivitySubprocess.ZeroValueProduct) {
			commercialActivity = {
				id: UUID.UUID(),
				scommercialactivity: this.commercialACtivityId,
				slug: slug,
				delta_days: this.deltaDays,
				max_promotion: this.maxPromotion,
				max_period: this.maxPeriod,
				nclients: this.nClients,
				commercilactivity: skuCalculateImpact,
				JClientsFilters: clientsCalculate
			};
		}

		if (commercialActivityType === CommercialActivitySubprocess.DiscountKind ||
			commercialActivityType === CommercialActivitySubprocess.DiscountValue ||
			commercialActivityType === CommercialActivitySubprocess.PriceDiscount ||
			commercialActivityType === CommercialActivitySubprocess.FinancialDiscount) {

			commercialActivity = {
				id: UUID.UUID(),
				scommercialactivity: this.commercialACtivityId,
				slug: slug,
				delta_days: this.deltaDays,
				nclients: this.nClients,
				commercilactivity: skuCalculateImpact,
				JClientsFilters: clientsCalculate
			};

		}

		if (commercialActivityType === CommercialActivitySubprocess.Rappel) {
			if (this.nConsideration === 104) {
				commercialActivity = {
					id: UUID.UUID(),
					scommercialactivity: this.commercialACtivityId,
					slug: slug,
					delta_days: this.deltaDays,
					max_promotion: this.maxPromotion,
					max_period: this.maxPeriod,
					nclients: this.nClients,
					commercilactivity: skuCalculateImpact,
					JClientsFilters: clientsCalculate
				};
			}
			if (this.nConsideration === 102 || this.nConsideration === 103) {
				commercialActivity = {
					id: UUID.UUID(),
					scommercialactivity: this.commercialACtivityId,
					slug: slug,
					delta_days: this.deltaDays,
					nclients: this.nClients,
					commercilactivity: skuCalculateImpact,
					JClientsFilters: clientsCalculate
				};
			}
		}

		return commercialActivity;
	}

	private updateCalculatedData(responseAnal: any) {
		this.purchaseBaseToCalculateForeach(responseAnal);
		this.PurchasePromotionToCalculateForeach(responseAnal);
	}

	/**
	 * @description This method is to prepare the purchases to send to anal
	 * @param commercialactivityType number of commercial activity type
	 * @param slug slug by commercial activity type
	 * @param id id generated
	 * @author Luis Estrella
	 * @sprint 18
	 * @returns CommercialActivitySkuCalculateImpact object with info
	 */
	private prepareJsonByCommercialActivityType(commercialactivityType: number, slug: string): CommercialActivitySkuCalculateImpact[] {
		let dt: CommercialActivitySkuCalculateImpact[] = [];

		if (commercialactivityType === CommercialActivitySubprocess.Promotions) {

			dt = this.purchaseBaseToCalculate.map((dtl) => {
				const id: IdCalculateImpact = new IdCalculateImpact(`${dtl.id}`);
				return {
					'id': id,
					'slug': slug,
					'SMaterial': dtl.materialCode,
					'uniqueprice': dtl.currentPsp,
					'pricefactor': dtl.factor,
					'units_purchase': dtl.purchaseUnit,
					'units_promotion': 0,
				};
			});

      const dtx = this.purchasePromotionToCalculate.map((dtl) => {
				const id: IdCalculateImpact = new IdCalculateImpact(`${dtl.id}`);
				return {
					'id': id,
					'slug': slug,
					'SMaterial': dtl.materialCode,
					'uniqueprice': dtl.currentPsp,
					'pricefactor': dtl.factor,
					'units_purchase': 0,
					'units_promotion': dtl.purchaseUnit,
				};
			});
      dt = dt.concat(dtx);
		}

		if (commercialactivityType === CommercialActivitySubprocess.Loyalty ||
			commercialactivityType === CommercialActivitySubprocess.Events ||
			commercialactivityType === CommercialActivitySubprocess.ZeroValueProduct) {
			dt = this.purchaseBaseToCalculate.map((dtl) => {
				const id: IdCalculateImpact = new IdCalculateImpact(`${dtl.id}`);
 				return {
					'id': id,
					'slug': slug,
					'SMaterial': dtl.materialCode,
					'uniqueprice': dtl.currentPsp,
					'pricefactor': dtl.factor,
					'units_replacement': dtl.purchaseUnit
				};
			});
		}

		if (commercialactivityType === CommercialActivitySubprocess.DiscountKind ||
			commercialactivityType === CommercialActivitySubprocess.DiscountValue ||
			commercialactivityType === CommercialActivitySubprocess.PriceDiscount ||
			commercialactivityType === CommercialActivitySubprocess.FinancialDiscount) {
			dt = this.purchaseBaseToCalculate.map((dtl) => {
				const id: IdCalculateImpact = new IdCalculateImpact(`${dtl.id}`);
				return {
					'id': id,
					'slug': slug,
					'SMaterial': dtl.materialCode,
					'uniqueprice': dtl.currentPsp,
					'pricefactor': dtl.factor,
					'per_descount': dtl.porcent
				};
			});
		}

		if (commercialactivityType === CommercialActivitySubprocess.Rappel) {
			if (this.nConsideration === 104) {
				dt = this.purchaseBaseToCalculate.map((dtl) => {
					const id: IdCalculateImpact = new IdCalculateImpact(`${dtl.id}`);
					 return {
						'id': id,
						'slug': slug,
						'SMaterial': dtl.materialCode,
						'uniqueprice': dtl.currentPsp,
						'pricefactor': dtl.factor,
						'units_replacement': dtl.purchaseUnit
					};
				});
			}
			if (this.nConsideration === 102 || this.nConsideration === 103) {
				dt = this.purchaseBaseToCalculate.map((dtl) => {
					const id: IdCalculateImpact = new IdCalculateImpact(`${dtl.id}`);
					return {
						'id': id,
						'slug': slug,
						'SMaterial': dtl.materialCode,
						'uniqueprice': dtl.currentPsp,
						'pricefactor': dtl.factor,
						'per_descount': dtl.porcent
					};
				});
			}
		}

		return dt;
	}

	/**
	 * @description This method is to prepare slug description to sen to calculate by type of commercial activity
	 * @sprint 18
	 * @author Diego Cortes
	 */
	private prepareSlugToCalculate(commercialActivityType: number): string {
		switch (commercialActivityType) {
			case CommercialActivitySubprocess.Promotions:
				return CommercialActivityCalculateSlugEnum.Promotions;
			case CommercialActivitySubprocess.DiscountKind:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
			case CommercialActivitySubprocess.DiscountValue:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
			case CommercialActivitySubprocess.FinancialDiscount:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
			case CommercialActivitySubprocess.Events:
				return CommercialActivityCalculateSlugEnum.Loyalty;
			case CommercialActivitySubprocess.ZeroValueProduct:
				return CommercialActivityCalculateSlugEnum.Loyalty;
			case CommercialActivitySubprocess.Loyalty:
				return CommercialActivityCalculateSlugEnum.Loyalty;
			case CommercialActivitySubprocess.Redemption:
				return CommercialActivityCalculateSlugEnum.Rappel;
			case CommercialActivitySubprocess.PriceDiscount:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
			case CommercialActivitySubprocess.Rappel:
				if (this.nConsideration === 104) {
					return CommercialActivityCalculateSlugEnum.Loyalty;
				}
				if (this.nConsideration === 102 || this.nConsideration === 103) {
					return CommercialActivityCalculateSlugEnum.DiscountKind;
				}
			default: break;
		}
		return;
	}

	private ValidatePurchaseBaseToCalculateLengthGreatZero() {
		if (this.purchaseBaseToCalculate.length > 0) {
			this.purchaseBaseToCalculate.forEach((prd) => {
				this.totalSumCostIncome += prd.costIncome ? prd.costIncome : 0;
				this.totalSumEbitda += prd.ebitda ? prd.ebitda : 0;
				this.totalSumPromotionValue += prd.promotionValue ? prd.promotionValue : 0;
				this.totalSumWeightedValue += prd.weightedValue ? prd.weightedValue : 0;
				this.totalSUmKpiLiberationSku += prd.kpiLiberationSku ? prd.kpiLiberationSku : 0;
			});
			this.totalSumEbitdaPercentage = this.totalSumCostIncome === 0 ? 0 : (this.totalSumEbitda / this.totalSumCostIncome) * 100;
		}
	}

	private ValidatePurchasePromotionToCalculateGreatZero() {
		if (this.purchasePromotionToCalculate.length > 0) {
			this.purchasePromotionToCalculate.forEach((prd) => {
				this.totalSumCostIncomeProm += prd.costIncome ? prd.costIncome : 0;
				this.totalSumEbitdaProm += prd.ebitda ? prd.ebitda : 0;
				this.totalSumPromotionValueProm += prd.promotionValue ? prd.promotionValue : 0;
				this.totalSumWeightedValueProm += prd.weightedValue ? prd.weightedValue : 0;
				this.totalSUmKpiLiberationSkuProm += prd.kpiLiberationSku ? prd.kpiLiberationSku : 0;
			});
			this.totalSumEbitdaPercentageProm = this.totalSumCostIncomeProm === 0 ? 0 : (this.totalSumEbitdaProm / this.totalSumCostIncomeProm) * 100;
		}
	}

	private AddSumBasePromotion() {
		if (this.purchaseBaseToCalculate.length > 0 && this.purchasePromotionToCalculate.length > 0) {
			this.totalPromotionValue = this.totalSumPromotionValue + this.totalSumPromotionValueProm;
			this.totalInvestmentEbitda = this.totalSumEbitda + this.totalSumEbitdaProm;
			this.totalEbitdaConsolidated = this.totalSumEbitdaPercentage + this.totalSumEbitdaPercentageProm;
		} else {
			this.totalPromotionValue = this.totalSumPromotionValue;
			this.totalInvestmentEbitda = this.totalSumEbitda;
			this.totalEbitdaConsolidated = this.totalSumEbitdaPercentage;
		}
	}

	private purchaseBaseToCalculateForeach(responseAnal: any) {
		this.purchaseBaseToCalculate.forEach((prch) => {
			const elm = responseAnal.promotionimpact.filter((elem: any) => elem.SMaterial === prch.materialCode && Number(elem.id.value) === prch.id);
			if (elm.length > 0) {
				prch.perDescount = elm[0].per_descount !== null ? elm[0].per_descount : 0;
				prch.ebitda = elm[0].ctspimpact !== null ? elm[0].ctspimpact.ebitda.value : 0;
				prch.ebitdaPercentage = elm[0].ctspimpact !== null ? elm[0].ctspimpact.ebitda.percentage : 0;
				prch.costIncome = elm[0].ctspimpact !== null ? elm[0].ctspimpact.income : 0;
				prch.promotionValue = elm[0].result.promotion_value !== null ? elm[0].result.promotion_value : 0;
				prch.weightedValue = elm[0].result.weighted_value !== null ? elm[0].result.weighted_value : 0;
				prch.kpiLiberationSku = elm[0].result.kpi_liberation_sku !== null ? elm[0].result.kpi_liberation_sku : 0;
			}
		});
	}

	private PurchasePromotionToCalculateForeach(responseAnal: any) {
		if (this.commercialActivityType === CommercialActivitySubprocess.Promotions) {
			this.purchasePromotionToCalculate.forEach((prm) => {
				const elm = responseAnal.promotionimpact.filter((elem: any) => elem.SMaterial === prm.materialCode && elem.id.value === prm.id);
				if (elm.length > 0) {
					prm.perDescount = elm[0].per_descount !== null ? elm[0].per_descount : 0;
					prm.ebitda = elm[0].ctspimpact !== null ? elm[0].ctspimpact.ebitda.value : 0;
					prm.ebitdaPercentage = elm[0].ctspimpact !== null ? elm[0].ctspimpact.ebitda.percentage : 0;
					prm.costIncome = elm[0].ctspimpact !== null ? elm[0].ctspimpact.income : 0;
					prm.promotionValue = elm[0].result.promotion_value !== null ? elm[0].result.promotion_value : 0;
					prm.weightedValue = elm[0].result.weighted_value !== null ? elm[0].result.weighted_value : 0;
					prm.kpiLiberationSku = elm[0].result.kpi_liberation_sku !== null ? elm[0].result.kpi_liberation_sku : 0;
				}
			});
		}
	}


	async validateState() {
		this.activityUid = SessionStorageHelper.get(`Calculo indicadores:${this.commercialACtivityId.toString()}`);
		  
		if (this.activityUid){
		  this.spinner.show('PleaseWait2');
		  await this.commActivityCalculateService.getPromotionalActivityStateById(this.activityUid)
		  .subscribe(
			  (response) => {
				  this.spinner.hide('PleaseWait2');
				  if (response.state.content.status == "In Progress") {
					  const language: string = sessionStorage.getItem('language');
					  SweetAlertMessageHelpers.info(
						  language === 'es-ES' ? 'Información' : 'Information',
						  language === 'es-ES' ? 'Cálculo de indicadores se encuentra en ejecución' :
						  'Calculation of indicators is in execution');					
				  }
				  else if (response.state.content.status == "OK")
				  {
					  this.getCalculatedIndicators(this.activityUid);
				  }
				  else{
					  const language: string = sessionStorage.getItem('language');
					  SweetAlertMessageHelpers.error(
						  language === 'es-ES' ? 'Error' : 'Error',
						  language === 'es-ES' ? 'Error al consultar el cálculo de indicadores, intentelo mas tarde' :
						  'Error in calculation of indicators, try later');					
				  }
			  },
			  (error) => {
				  this.spinner.hide('PleaseWait2');
				  this.QtyStateExecuted++;
				  if (this.QtyStateExecuted < 3)
				  {
					  this.validateState();
				  }
				  else
				  {
					  const language: string = sessionStorage.getItem('language');
					  SweetAlertMessageHelpers.error(
						  language === 'es-ES' ? 'Error' : 'Error',
						  language === 'es-ES' ? 'Error al consultar el cálculo de indicadores, intentelo mas tarde' :
						  'Error in calculation of indicators, try later');					
				  }
			  });			
	  }
	  else
	  {
		  this.spinner.hide('PleaseWait2');
		  const language: string = sessionStorage.getItem('language');
		  SweetAlertMessageHelpers.error(
			  language === 'es-ES' ? 'Error' : 'Error',
			  language === 'es-ES' ? 'No existe cálculo de indicadores en ejecución' :
			  'No calculation of indicators in execution');				
	  }
   }


	getCalculatedIndicators(id:string){
		const slug = this.prepareSlugToCalculate(this.commercialActivityType);
		const commercialActivity: CommercialActivityCalculateImpact = this.getCommercialActivityCalculateImpactByType(this.commercialActivityType, slug);
		commercialActivity.id = id;

		this.commActivityCalculateService.getPromotionalActivityCalcute(id, this.commercialACtivityId, slug).subscribe(
			(response: any) => {
				this.updateCalculatedData(response);
				this.calculateTotals();
				const rsp = {
					'purchaseBaseCalculate': this.purchaseBaseToCalculate,
					'purchasePromotionCalculate': this.purchasePromotionToCalculate,
				};
				this.eventSendToCalculate.emit(rsp);
		  }, (error: any) => {
			const language: string = sessionStorage.getItem('language');
		  	SweetAlertMessageHelpers.error(
			  language === 'es-ES' ? 'Error' : 'Error',
			  language === 'es-ES' ? 'Error para obtener indicador calculado' :
			  'Error get calculated indicator');
		});
		SessionStorageHelper.remove(`Calculo indicadores:${this.commercialACtivityId.toString()}`);
	}
}
