import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from '@app/@shared/enums/module.enums';
import { NotificationType } from '@app/@shared/enums/notification-type.enum';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NotificationInformativeModel } from '@app/@shared/model/notification-informative.model';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { SolicitudeMessage } from '../../messages/solicitude.message';
import { SolicitudeCreationModel } from '../../models/solicitude-creation.model';
import { SolicitudeStatusModel } from '../../models/solicitude-status.model';
import { SolicitudeService } from '../../solicitude.service';
import { HelperService } from '@app/@shared/services/helper.service';

@Component({
  selector: 'app-pending-withdrawal',
  templateUrl: './pending-withdrawal.component.html',
  styleUrls: ['../../../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
  ],
  providers: [MessageService, DatePipe],
})
export class PendingWithdrawalComponent implements OnInit {
  private readonly spinner: NgxSpinnerService;
  private readonly solicitudeService: SolicitudeService;
  private readonly router: Router;
  private readonly messageService: MessageService;
  private readonly helperService: HelperService;

  public rolePermission: any = RolePermissionEnum;
  public idSolicitude = 0;
  public solicitudeType: SolicitudeType;
  public solicitudeStatus: SolicitudeStatus;
  public objectListSolicitude: SolicitudeCreationModel[];
  public retry: number;

  constructor(
    spinner: NgxSpinnerService,
    solicitudeService: SolicitudeService,
    router: Router,
    messageService: MessageService,
    public datepipe: DatePipe,
    helperService: HelperService
  ) {
      this.spinner = spinner;
      this.solicitudeService = solicitudeService;
      this.router = router;
      this.messageService = messageService;
      this.helperService = helperService;
  }

  ngOnInit(): void {
    this.getSolicitudeById();
  }

  /**
   * @description event to navigate back
   * @author Daniel Londoño
   * @sprint 5
   */
  back(): void {
    this.router.navigate(['auth/pending-solicitude']);
  }

  /**
   * @description This method accept status solicitude.
   * @author Daniel Londoño
   * @sprint 6
   */
  accept(): void {
    this.solicitudeStatus = SolicitudeStatus.InRecord;
    Util.confirmApprove().then((result: SweetAlertResult) => {
      if (result.value) {
        this.save();
      }
    });
  }

  /**
   * @description This method reject status solicitude.
   * @author Daniel Londoño
   * @sprint 6
   */
  reject(): void {
    this.solicitudeStatus = SolicitudeStatus.Rejected;
    Util.confirmRejection().then((result: SweetAlertResult) => {
      if (result.value) {
        this.save();
      }
    });
  }

  sendRetry(): void {
    Util.confirmSendSolicitude().then((result: SweetAlertResult) => {
      if (result.value && this.validateRetry()) {
        this.spinner.show();
        this.solicitudeService.postCreateUpdatePricing(this.idSolicitude).subscribe(
          (response) => {
            if (response.status) {
              const modelNotification : NotificationInformativeModel = new NotificationInformativeModel(
                Module.Price,
                NotificationType.Informative,
                this.solicitudeService.getSolicitudeId()
              );
              this.solicitudeService.putSolicitudeNotificationInformative(modelNotification)
              .subscribe(
                (response)=>{
                  this.spinner.hide();
                  this.back();
                },
                (error)=>{
                  this.spinner.hide();
                  this.back();
                }
              );

              const language: string = sessionStorage.getItem('language');
              if(language === 'es-ES'){
                this.showToast(SweetAlert.iconSuccess,SweetAlert.titleAlert,this.solicitudeStatus === SolicitudeStatus.Pending 
                  ? SweetAlert.messageAlertRejectSolicitude : SweetAlert.messageAlertAcceptSolicitude);
              }else{
                this.showToast(SweetAlert.iconSuccess,SweetAlert.titleAlertTranslate, this.solicitudeStatus === SolicitudeStatus.Pending 
                  ? SweetAlert.messageAlertRejectSolicitudeTranslate : SweetAlert.messageAlertAcceptSolicitudeTranslate);
              }

            }else{
              this.spinner.hide();
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,response.message);
            }
          },
          (error) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });
  }

  /**
   * @description This method for change status solicitude.
   * @author Daniel Londoño
   * @sprint 6
   */
  public save(): void {
    this.spinner.show();
      const statusModel: SolicitudeStatusModel = new SolicitudeStatusModel(
        this.solicitudeService.getSolicitudeId(),
        this.solicitudeStatus,
        ''
      );        
      this.solicitudeService.putSolicitudeStatus(this.solicitudeService.getSolicitudeId(), statusModel)
      .subscribe(
        (response) => {
          if (response.status) {

            const modelNotification : NotificationInformativeModel = new NotificationInformativeModel(
              Module.Price,
              this.solicitudeStatus === SolicitudeStatus.Rejected  ? NotificationType.System : NotificationType.Informative,
              this.solicitudeService.getSolicitudeId()
            );
            this.solicitudeService.putSolicitudeNotificationInformative(modelNotification)
            .subscribe(
              (response)=>{
                this.spinner.hide();
                this.back();
              },
              (error)=>{
                this.spinner.hide();
                this.back();
              }
            );
            const language: string = sessionStorage.getItem('language');
            if(language === 'es-ES'){
              this.showToast(SweetAlert.iconSuccess,SweetAlert.titleAlert,this.solicitudeStatus === SolicitudeStatus.InRecord 
                ? SweetAlert.messageAlertAcceptSolicitude : SweetAlert.messageAlertRejectSolicitude);
            }else{
              this.showToast(SweetAlert.iconSuccess,SweetAlert.titleAlertTranslate,this.solicitudeStatus === SolicitudeStatus.InRecord 
                ? SweetAlert.messageAlertAcceptSolicitudeTranslate : SweetAlert.messageAlertRejectSolicitudeTranslate);
            }
          } else {
            this.spinner.hide();
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,response.message);
          }
        },
        (error) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }

  /**
   * @description this method is to get Solicitude with SolicitudeMaterial By Id.
   * @author Daniel Londoño
   * @sprint 5
   */
  getSolicitudeById(): void {
    this.spinner.show();

    this.idSolicitude = this.solicitudeService.getSolicitudeId();

    if (!this.idSolicitude) {
      this.spinner.hide();
      this.back();
      return;
    }
    this.solicitudeService.getSolicitudeWithDetailById(this.idSolicitude).subscribe(
      (response) => {
        if (response.status) {
          if(response.data.status === SolicitudeStatus.InProgress){
            if(!response.data.solicitudeDetailJson){
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                language === 'es-ES' ? SolicitudeMessage.NOT_SKU : SolicitudeMessage.NOT_SKU_TRANSLATE);
              this.router.navigate(['auth/pending-solicitude']);
            }
            this.retry = response.data.retry;

            this.objectListSolicitude = response.data.solicitudeDetailJson.map(
              (item) => {
                return {
                  id: item.detailId,
                  solicitudeId: response.data.id,
                  materialCode: item.sku,
                  product: item.materialDescription,
                  currentListPrice: item.currentListPrice,
                  currentPsp: item.currentPsp,
                  newPriceWithOutTax: item.newPriceWithOutTax,
                  priceAdjustment: item.priceAdjustment,
                  newPsp: item.newPsp,
                  effectiveDateStart: new Date(item.effectiveDateStart),
                  effectiveDateEnd: new Date(item.effectiveDateEnd),
                  zofeCode: item.zofeCode,
                  zofeDescription: item.zofeDescription
                };
              }
            );
          }else{
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? SolicitudeMessage.NOT_STATUS_PENDING
              : SolicitudeMessage.NOT_STATUS_PENDING_TRANSLATE);
            this.router.navigate(['auth/pending-solicitude/']);
          }
        } else {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  validateRetry(): boolean{
    const result = true;
    if(this.retry >= 3){
      const language: string = sessionStorage.getItem('language');
      const msn = language === 'es-ES' 
      ? `Lo sentimos, ha superado el numero máximo de intentos permitidos, por favor realice una nueva solicitud` : `Sorry, you have exceeded the maximum number of attempts allowed, please make a new request`;
      SweetAlertMessageHelpers.info('', msn);
      return false;
    }
    return result;
  }

  /**
   * @description This method shows the toast control.
   * @author Daniel Londoño
   * @sprint 6
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  materialCodeZeroLess(value: string): string{
    return this.helperService.materialCodeZeroLess(value);
   }
}
