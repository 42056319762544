import { IUpdateStatus } from '@app/@shared/interfaces/update-status.interface';

export class ApprovalRankStatusModel implements IUpdateStatus<number,boolean> {
    /**
   * @author Priscy Antonio Reales
   * @sprint 6
   * @description Model for update status
   */
  constructor(
    public id : number,
    public status: boolean,
  ) {}
}