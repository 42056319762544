import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IAnalysisChartsProps,
  ICardEmmiterInformation,
  IImpactAnalysis,
  IWightedWeight,
} from '../../../interfaces/impact-analysis.interfaces';
import { SolicitudeService } from '../../../solicitude.service';
import { Subject } from 'rxjs';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { AnalysisImpactMessage } from '../../../messages/analysis-Impact.message';
import { Util } from '@app/@shared/util';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagePersonalizedModel } from '@app/@shared/model/message-personalized.model';
import { SolicitudeMaterialApprovalModel } from '../../../models/solicitude-material-approval.model';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { AnalyticModule } from '@app/@shared/enums/analytic-module.enum';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { SweetAlertResult } from 'sweetalert2';
import { IRulesImpacted } from './interfaces/rules-impacted.interfaces';
import { ImpactAnalysisServices } from './impact-analysis.services';
import { HttpErrorResponse } from '@angular/common/http';
import { RulesImpactedEditModel } from './models/rules-impacted-edit.models';
import { NgxSpinnerService } from 'ngx-spinner';
import { RulesImpactedDeleteModel } from './models/rules-impacted-delete.models';
import { ApplicationConstants } from '@app/app.constants';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { PriceCertificateComponent } from '@app/@components/price-certificate/price-certificate.component';
import { SolicitudeFileModel } from '../../../models/solicitude-file.model';

@Component({
  selector: 'app-impact-analyses',
  templateUrl: './impact-analysis.component.html',
  styleUrls: ['../../../../../../@shared/scss/responsive.table.scss'],
  providers: [MessageService],
})
export class ImpactAnalysisComponent implements OnInit {
  @ViewChild('priceCertificate') priceCertificate: PriceCertificateComponent;
  
  private readonly spinner: NgxSpinnerService;
  private readonly solicitudeService: SolicitudeService;
  private readonly impactAnalysisServices: ImpactAnalysisServices;
  private readonly manageParametersService: ManageParametersService;
  private readonly activatedRoute: ActivatedRoute;
  private readonly messageService: MessageService;
  private readonly router: Router;

  public rolePermission: any = RolePermissionEnum;
  public toSendCalculatedValue = new Subject<string>();
  public toSendCalculatedValue2 = new Subject<string>();
  public isSuggestion = '1';
  public isViewRulesDelete = true;
  public isRecalculation :boolean;
  public isPriceList: boolean;
  public isSetting = false;
  public information = '';
  public selectedDate: Date[] = [];
  public selectedSetting: string;
  public deleteRules: RulesImpactedDeleteModel[] = [];
  public editRules: RulesImpactedEditModel[] = [];
  public today: Date = new Date();
  public isDateEdit = false;
  public isettingEdit = false;
  public tomorrow: Date = new Date();
  public rulesImpacted: IRulesImpacted[];
  public rulesSuggested : IRulesImpacted[];
  public aux1RulesImpacted: IRulesImpacted[];
  public auxRulesImpacted: any[];
  public buttonView = false;
  public auxId = 0;
  public listIdDateRed: number[] = [];
  public isEdit = false;
  public isView = true;
  public toSendCalculatedValue$ = this.toSendCalculatedValue.asObservable();
  public toSendCalculatedValue2$ = this.toSendCalculatedValue2.asObservable();
  public listMsn: MessagePersonalizedModel[] = [];
  public isTypeDialog = false;
  public titleTypeDialog = 'Motivo del Rechazo';
  public titleRulesImpacted = 'Reglas Impactadas';
  public infoModal = false;
  public commentary = '';
  public analysisCharts: IImpactAnalysis[] = [];
  public analysisCharts2: IImpactAnalysis[] = [];
  public weightedWeightIds: Array<number> = [
    ManagementParameterEnum.weightedClient,
    ManagementParameterEnum.weightedVolume,
    ManagementParameterEnum.weightedIncome,
    ManagementParameterEnum.weightedEbitdad,
    ManagementParameterEnum.threshold,
  ];

  public idSolicitude = 0;
  public idGuid: string;
  public code = '000000000000005';
  public name = 'Gaseosa pepsi';
  public price = 908765211;
  public dateStartProduct: Date;
  public dateEndProduct: Date;
  public solicitudId: number;
  public newPriceWithOutTax: number;
  public calculateWeightedWeight: any;
  public statusApproval: number;
  public status = false;
  public auxDelete: RulesImpactedDeleteModel[] = [];
  public messageTable = '';
  public language: string = sessionStorage.getItem('language');
  public analysisChartsProps: IAnalysisChartsProps<IImpactAnalysis> = {
    clients: {
      icon: 'uil-users-alt',
      description: this.language === 'es-ES' ? AnalysisImpactMessage.CLIENTS_DESCRIPTION : AnalysisImpactMessage.CLIENTS_DESCRIPTION_TRANSLATE,
      type: 'doughnutChart',
      dependent: false,
      weightedWeight: 0,
      idType: 'clients',
      chartStyle: {
        backgroundColor: ['#662D91', '#f7f7f7'],
      },
      typeNumber: 'value',
    },
    volume: {
      icon: 'uil-cube',
      description: this.language === 'es-ES' ? AnalysisImpactMessage.VOLUME_DESCRIPTION : AnalysisImpactMessage.VOLUME_DESCRIPTION_TRANSALTE,
      type: 'pieChart',
      dependent: true,
      weightedWeight: 0,
      chartProps: {
        filters: [
          { label: this.language === 'es-ES' ? 'Unidad volumen por kilos' : 'Unit volume per kilogram', attr: '' },
          { label: this.language === 'es-ES' ? 'Unidad volumen por hectolitros' : 'Unit volume per hectoliter', attr: '' },
          { label: this.language === 'es-ES' ? 'Unidad volumen por cajas físicas' : 'Volume unit per physical box', attr: '' },
          { label: this.language === 'es-ES' ? 'Unidad volumen por cajas unitarias': 'Unit volume per unit boxes', attr: '' },
        ],
      },
      idType: 'volume',
      chartStyle: {
        backgroundColor: ['#ED088C', '#f7f7f7'],
      },
      typeNumber: 'number',
    },
    income: {
      icon: 'uil-bill',
      description: this.language === 'es-ES' ? AnalysisImpactMessage.INCOME_DESCRIPTION : AnalysisImpactMessage.INCOME_DESCRIPTION_TRANSLATE,
      type: 'doughnutChart',
      dependent: false,
      weightedWeight: 0,
      idType: 'income',
      chartStyle: {
        backgroundColor: ['#0070BA', '#f7f7f7'],
      },
      typeNumber: 'number',
    },
    ebita: {
      icon: 'uil-bill',
      description: this.language === 'es-ES' ? AnalysisImpactMessage.EBITDA_DESCRIPTION : AnalysisImpactMessage.EBITDA_DESCRIPTION_TRANSLATE,
      subdescription: 'EBITDA',
      type: 'doughnutChart',
      dependent: false,
      weightedWeight: 0,
      idType: 'debita',
      chartStyle: {
        backgroundColor: ['#009CDD', '#f7f7f7'],
      },
      typeNumber: 'number',
    },
  };

  public analysisChartImpactPorcentage = {
    icon: 'uil-chart-pie-alt',
    description: this.language === 'es-ES' ? AnalysisImpactMessage.IMPACTPORCENTAGE_DESCRIPTION : AnalysisImpactMessage.IMPACTPORCENTAGE_DESCRIPTION_TRANSLATE,
    weightedWeight: '',
    umbral: 0,
    amount: '',
    type: 'pieChart',
    chartProps: {
      percentage: '',
    },
    dependent: false,
    idType: '',
    chartStyle: {
      backgroundColor: ['#FEBE11', '#f7f7f7'],
    },
    toUpdate: true,
  };
  //Estados de accion
  public isDeleteAction : boolean;
  public isUpdate = false;
  public disabledButton = false;

  //Modal 
  public displayModalDetails = false;
  public rulesImpactedShow : IRulesImpacted[];
  

  //assing Client
  public showFieldClientFilter : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false);
  public isProcess : number ;
  public filtersEdit : string = '';
  public clientsEdit : any;
  public isCreateFilter = false;
  

  constructor(
    solicitudeService: SolicitudeService,
    spinner: NgxSpinnerService,
    impactAnalysisServices: ImpactAnalysisServices,
    manageParametersService: ManageParametersService,
    activatedRoute: ActivatedRoute,
    messageService: MessageService,
    router: Router
  ) {
    this.solicitudeService = solicitudeService;
    this.spinner = spinner;
    this.impactAnalysisServices = impactAnalysisServices;
    this.manageParametersService = manageParametersService;
    this.activatedRoute = activatedRoute;
    this.messageService = messageService;
    this.router = router;
  }

  ngOnInit(): void {
     const language: string = sessionStorage.getItem('language');
     this.messageTable = language === 'es-ES' ? 'Buscando reglas impactadas': 'Looking for Shocked Rules';
     this.getAll();
     this.statusBotton();
     this.solicitudId = this.solicitudeService.getSolicitudeId();
  }

  statusBotton(){
    if(Number(this.statusApproval) === 2 ){
      this.status = true;
     }else if(Number(this.statusApproval) === 0){
      this.status = true;
     }else if(Number(this.statusApproval) === 1){
      this.status = false;
     }
  }

  detailProductURL(){
    //this.spinner.show();
    this.solicitudeService.setSolicitudeId(this.solicitudId);
    //this.router.navigate(['auth/pending-solicitude/creation/']);
    const url = this.router.serializeUrl(this.router.createUrlTree(
    ['auth/pending-solicitude/creation/'], {
      queryParams: {
        id: this.solicitudId,
      }
    }));
     window.open(url, '_blank');
  }

  async refreshCheck(){
    this.rulesImpacted = [];
    this.editRules = [];
    this.deleteRules = [];
    if(!this.solicitudId){
      this.router.navigate(['auth/pending-solicitude/']);
      return;
    } 
    if(this.isSuggestion === '1'){
      await this.getAllListPrice(this.solicitudId);
    }else if(this.isSuggestion === '2'){
      await this.getAllAdjustmentPrice(this.solicitudId);
    }
  }

  getAll() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const id = params['id'];
      const sku = params['sku'];
      const newPriceWithOutTax = params['newPriceWithOutTax'];
      const guid = params['codeId'];
      const nameProduct = params['name'];
      const priceProduct = params['price'];
      this.dateStartProduct = params['dateStart'];
      this.dateEndProduct = params['dateEnd'];
      this.statusApproval = params['status'];
      if (sku && nameProduct && priceProduct && id) {
        this.idSolicitude = id;
        this.code = sku;
        this.newPriceWithOutTax = newPriceWithOutTax;
        this.name = nameProduct;
        this.price = priceProduct;
        this.idGuid = guid;
      }
      if (id && sku) {
        this.getWeightedWeightInformation().then(
          (getWeightedWeightData: any) => {
            if (getWeightedWeightData.isInvalid) {
              this.listMsn.sort();
              this.infoModal = true;
            }
            this.getListImpactAnlasysis(this.idGuid, sku);
          }
        );
      } else {
        this.router.navigate(['auth/pending-solicitude']);
      }
    });
  }

  excluir509(data: IRulesImpacted[]): IRulesImpacted[]{
    let rules : IRulesImpacted[] = [];
    if(!data || data.length == 0 ) return rules;
    data.forEach((x) => {
      if(x.hierarchyCode != '509'){
        rules.push(x);
      }
    });
    return rules;
  }


  async getAllListPrice(id: number) {
    try {
      this.spinner.show('PleaseWait2');
      const response = await this.impactAnalysisServices.getListPrice(this.code, id).toPromise();
      if(response){
        if (response.status){
          this.rulesImpacted = response.data;
          this.checkSolicitude();
          this.actionFieldForPriceList();
          this.aux1RulesImpacted = this.rulesImpacted;
          this.viewRules(true,false);
          this.viewRules(false,false);
          this.spinner.hide('PleaseWait2');
        }
        else
        {
          const language: string = sessionStorage.getItem('language');
          this.messageTable = language === 'es-ES' ? 'Por favor validar si hay reglas sugeridas para eliminar': 'Please check if there are suggested rules to remove';
          this.spinner.hide('PleaseWait2');
          this.buttonView = true;
        }  
      }
      (error: HttpErrorResponse) => {
        this.messageTable = '';
        this.spinner.hide('PleaseWait2');
        SweetAlertMessageHelpers.exception(error);
      }
    } catch (error) {
      this.messageTable = '';
      this.spinner.hide('PleaseWait2');
      SweetAlertMessageHelpers.exception(error);
    }

}

async getAllAdjustmentPrice(id: number) {
  try {
    this.spinner.show('PleaseWait2');
    const response = await this.impactAnalysisServices.getAdjustmentPrice(this.code, id).toPromise();

    if(response.status){
      this.rulesImpacted = this.excluir509(response.data);
      this.checkSolicitude();
      this.actionFieldForPriceList();
      this.addColor();
      this.aux1RulesImpacted = this.rulesImpacted;
      this.skip(this.aux1RulesImpacted);
      this.viewRules(true,false);
      this.viewRules(false,false);
      this.spinner.hide('PleaseWait2');
    }
    else
    {
      const language: string = sessionStorage.getItem('language');
      this.messageTable = language === 'es-ES' ? 'Por favor validar si hay reglas sugeridas para eliminar': 'Please check if there are suggested rules to remove';
      this.spinner.hide('PleaseWait2');
      this.buttonView = true;
    }  
    (error: HttpErrorResponse) => {
      this.spinner.hide('PleaseWait2');
      SweetAlertMessageHelpers.exception(error);
    }
  } catch (error) {
    this.spinner.hide('PleaseWait2');
    SweetAlertMessageHelpers.exception(error);
  }
}

  skip(rulesImpacted : IRulesImpacted[]){
    if(rulesImpacted != null){
    rulesImpacted.forEach((x) => {
      if(x.hierarchyCode === '509'){
        const deleteModel: RulesImpactedDeleteModel = new RulesImpactedDeleteModel(
          x.solicitudeId
        );
        this.auxDelete.push(deleteModel);
        const index = rulesImpacted.indexOf(x);
        rulesImpacted.splice(index, 1);
      }
    });
  }
  }

  async selectRadioButton() {
    if(this.calculateWeightedWeight && (this.calculateWeightedWeight*100 > this.analysisChartImpactPorcentage?.umbral*100)){
      //Lista de Precio
      this.isSuggestion = '1';
      await this.getAllListPrice(this.solicitudId);
    }else{
      //Ajuste de Precio
      this.isSuggestion = '2';
      await this.getAllAdjustmentPrice(this.solicitudId);
    }
  }

  checkSolicitude() {
    if (this.rulesImpacted == null) {
      const language: string = sessionStorage.getItem('language');
      this.messageTable = language === 'es-ES' ? 'Por favor validar si hay reglas sugeridas para eliminar': 'Please check if there are suggested rules to remove';
      // SweetAlertMessageHelpers.Warning(
      //   this.titleRulesImpacted,
      //   'No hay reglas impactadas'
      // );
      this.buttonView = true;
    } else {
      this.rulesImpacted.sort(
        (a, b) => parseInt(a.hierarchyCode, 10) - parseInt(b.hierarchyCode, 10)
      );
      this.validateDateRule();
      this.clientsImpacted();
      this.checkStatus();
      this.tooltip();
    }
  }

  actionFieldForPriceList(){
    if(this.rulesImpacted != null){
      this.rulesImpacted.forEach((x) => {
        const value: string[] = x.numberImpactedClients.split('/');
        if(x.action === 0 && Number(value[0]) !== 0){
          //Eliminar
          const deleteActionModel : RulesImpactedDeleteModel = new RulesImpactedDeleteModel(
            x.solicitudeId,
          );
          this.deleteRules.push(deleteActionModel);
        }
        this.checkStatus();
      });
      this.isDeleteAction = true;

    }
  }

  validateDateRule() {
    const currentSolicitude = {} as IRulesImpacted;
    currentSolicitude.effectiveDateStart = this.dateStartProduct;
    currentSolicitude.effectiveDateEnd = this.dateEndProduct;
    currentSolicitude.solicitudeId = 0;

    this.rulesImpacted.push(currentSolicitude);

    for (let i = 0; i < this.rulesImpacted.length; i++) {
      if (i + 1 > this.rulesImpacted.length) {
        continue;
      }
      const current = this.rulesImpacted[i];
      const startRule = this.timestamp(String(current.effectiveDateStart));
      const endRule = this.timestamp(String(current.effectiveDateEnd));

      for (let j = i + 1; j < this.rulesImpacted.length; j++) {
        const aux = this.rulesImpacted[i + 1];
        const auxStartDate = this.timestamp(String(aux.effectiveDateStart));
        const auxEndDate = this.timestamp(String(aux.effectiveDateEnd));

        if (
          (auxStartDate >= startRule && auxStartDate <= endRule) ||
          (auxEndDate >= startRule && auxEndDate <= endRule)
        ) {
          if (current.isOverlapDate !== true && current.solicitudeId !== 0) {
            this.textRedDate(current.solicitudeId);
          }

          if (aux.isOverlapDate !== true && aux.solicitudeId !== 0) {
            this.textRedDate(aux.solicitudeId);
          }
        }
      }
    }

    let index = this.rulesImpacted
      .map((x) => {
        return x.solicitudeId;
      })
      .indexOf(0);

    this.rulesImpacted.splice(index, 1);
    this.checkStatus();
  }

  timestamp(strDate: string) {
    let datum = Date.parse(strDate);
    return datum / 1000;
  }

  validityStatus() {
    this.rulesImpacted.forEach((x) => {
      if (x.statusClients === true || x.statusDate === true) {
        x.status = true;
        this.checkStatus();
      } else {
        x.status = false;
      }
    });
  }

  textRedDate(id: number) {
    this.rulesImpacted.forEach((x) => {
      if (id === x.solicitudeId) {
        x.isOverlapDate = true;
        x.statusDate = true;
      }
    });
  }

  textBlackDate(id: number) {
    this.rulesImpacted.forEach((x) => {
      if (id === x.solicitudeId) {
        x.isOverlapDate = false;
        x.statusDate = false;
      }
    });
  }


  clientsImpacted() {
    this.rulesImpacted.forEach((x) => {
      if(x.numberImpactedClients != null && x.numberImpactedClients !== undefined && x.numberImpactedClients !== ''){
        const value: string[] = x.numberImpactedClients.split('/');
        if (Number(value[0]) === 0) {
          x.isClientsImpacted = false;
          x.statusClients = false;
        } else {
          x.isClientsImpacted = true;
          x.statusClients = true;
        }
      }
    });
    this.validityStatus();
  }

  public getWeightedWeightInformation() {
    return new Promise((resolve, _) => {
      let informationGet = 0;
      const informationInvalid = {
        description: '',
        isInvalid: false,
        error: {},
      };

      return this.weightedWeightIds.forEach((weightedWeightId, _, array) => {
        this.manageParametersService.getById(weightedWeightId).subscribe(
          (response: IResponseService<IWightedWeight>) => {
            if (parseInt(response.data.value, 10) === 0) {
              const msnError = new MessagePersonalizedModel(
                'Precaución',
                `La configuracion ${response.data.description} esta en 0%`,
                'Warning'
              );
              this.listMsn.push(msnError);
              if (!informationInvalid.isInvalid) {
                informationInvalid.isInvalid = true;
              }
            }
            this.setWeightedWeight(response.data.id, response.data.value);
          },
          (error) => {
            informationInvalid.error = error;
          },
          () => {
            informationGet = informationGet + 1;
            if (informationGet === array.length) resolve(informationInvalid);
          }
        );
      });
    });
  }

  public setWeightedWeight(id: number, value: string) {
    const weightedValue = parseInt(value, 10) / 100;
    switch (id) {
      case 1:
        this.analysisChartsProps.clients.weightedWeight = weightedValue;
        break;
      case 2:
        this.analysisChartsProps.volume.weightedWeight = weightedValue;
        break;
      case 3:
        this.analysisChartsProps.income.weightedWeight = weightedValue;
        break;
      case 4:
        this.analysisChartsProps.ebita.weightedWeight = weightedValue;
        break;
      case 5:
        this.analysisChartImpactPorcentage.umbral = weightedValue;
        break;
      default:
        break;
    }
  }

  public getListImpactAnlasysis(id: any, sku: any): void {
    if (this.analysisCharts.length === 0) {
      this.solicitudeService
        .getImpactAnalysis(id, AnalyticModule.Price)
        .subscribe(
          (response) => {
            if (response.impacts && Object.keys(response.impacts).length > 0) {
              const dataRetrive = response;
              this.analysisCharts = this.analysisChartsDto(dataRetrive);
            } else {
              Util.showWarning(
                '',
                `${AnalysisImpactMessage.MESSAGE_WARNING_LACK_ANALYSIS_INFORMATION}`
              );
            }
          },
          () => {
            Util.showWarning(
              '',
              `${AnalysisImpactMessage.MESSAGE_WARNING_LACK_ANALYSIS_INFORMATION}`
            );
          }
        );
    }
  }

  public updateCalculatedValueSubject(newStringVar: any) {
    this.toSendCalculatedValue.next(newStringVar);
  }

  public updateCalculatedValueSubject2(newStringVar: any) {
    this.toSendCalculatedValue2.next(newStringVar);
  }

  public getEventEmitterCard(event: ICardEmmiterInformation) {
    if (this.analysisCharts.length > 0) {
      const valueCalculate = this.calculatePercentageImpact(
        this.analysisCharts,
        event
      );
      this.updateCalculatedValueSubject(valueCalculate);
      this.analysisChartImpactPorcentage.chartProps.percentage = valueCalculate;
      this.calculateWeightedWeight = valueCalculate;
      this.selectRadioButton();
    }
  }

  public getEventEmitterCard2(event: ICardEmmiterInformation) {
    if (this.analysisCharts2.length > 0) {
      const valueCalculate = this.calculatePercentageImpact(
        this.analysisCharts2,
        event
      );
      this.updateCalculatedValueSubject2(valueCalculate);
      this.analysisChartImpactPorcentage.chartProps.percentage = valueCalculate;
      this.calculateWeightedWeight = valueCalculate;
    }
  }

  public analysisChartsDto(data: any): Array<IImpactAnalysis> {
    const newAnalysis = [];
    const analysisImpact = data?.impacts;
    for (const key in analysisImpact) {
      let itemAnalysis = {};
      if (key !== 'volume') {
        itemAnalysis = {
          ...this.analysisChartsProps[key],
          amount: analysisImpact[key].amount,
          chartProps: {
            ...this.analysisChartsProps[key]?.chartProps,
            percentage: analysisImpact[key].percentage,
          },
        };
      } else {
        if (key === 'volume') {
          itemAnalysis = {
            ...this.analysisChartsProps[key],
            chartProps: {
              ...this.analysisChartsProps[key].chartProps,
              filters: this.generateFiltersAttr(
                this.analysisChartsProps[key].chartProps.filters,
                analysisImpact[key]
              ),
              values: {
                ...analysisImpact[key],
              },
            },
          };
        }
      }
      newAnalysis.push(itemAnalysis);
    }
    newAnalysis.push(this.analysisChartImpactPorcentage);
    return <any>newAnalysis;
  }

  public generateFiltersAttr(filters: any, attributes: any): Array<any> {
    const newFilters = [...filters];
    let counter = 0;
    for (const key in attributes) {
      if (newFilters[counter] && newFilters[counter]?.attr === '') {
        newFilters[counter]['attr'] = key;
      }
      counter = counter + 1;
    }
    return newFilters;
  }

  public calculatePercentageImpact(
    amountCharts: Array<IImpactAnalysis>,
    amountObjectFilter: ICardEmmiterInformation
  ) {
    let multiplication: any = 0;
    let summation: any = 0;
    amountCharts.forEach((amountChart) => {
      if (amountChart.idType) {
        if (amountChart.idType !== 'volume') {
          multiplication =
            <any>amountChart.chartProps.percentage *
            <any>amountChart.weightedWeight;
        } else {
          if (amountChart.idType === 'volume') {
            multiplication =
              amountObjectFilter.percentage * <any>amountChart.weightedWeight;
          }
        }
        summation = summation + multiplication;
        multiplication = 0;
      }
    });
    return summation;
  }

  acept() {
    this.listMsn = [];
    this.infoModal = false;
  }

  public showValueCard(value: any) {
    return this.numberWithCommas(this.getFirstTwoDecimal(value));
  }

  public numberWithCommas(x: string) {
    if (x !== undefined) {
      let stringPart = [];
      const newValue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      stringPart = newValue.split('');
      stringPart[newValue.lastIndexOf('.')] = ',';
      return stringPart.join('');
    }
    return x;
  }

  public getFirstTwoDecimal(num: any, decimal: any = 2) {
    if (num) {
      return (Math.round(num * 100) / 100).toFixed(decimal);
    }
  }

  reject() {
    if(this.isSetting === true || (this.selectedDate == null || this.selectedDate.length === 1)){
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.warning(
        language === 'es-ES' ? 'Reglas Impactadas': 'Impacted Rules',
        language === 'es-ES' ? 'Complete los campos obligatorios': 'Complete the required fields'
      );
    }else{
      this.isTypeDialog = true;
    }
  }

  onChangeDate(rulesImpacted: IRulesImpacted) {
    if (this.selectedDate && this.selectedDate.length === 2) {
      const startDate = this.selectedDate[0];
      const endDate = this.selectedDate[1];
      if (startDate && endDate && (startDate !== rulesImpacted.effectiveDateStart || endDate !== rulesImpacted.effectiveDateEnd)) {
        this.isUpdate = true;
        rulesImpacted.effectiveDateStart = startDate;
        rulesImpacted.effectiveDateEnd = endDate;
        rulesImpacted.isColor = true;
        rulesImpacted.isEdit = true;
      }
    }
  }

  onChangeSetting(rulesImpacted: IRulesImpacted) {
    if (this.selectedSetting && this.selectedSetting !== rulesImpacted.priceAdjustment) {
      this.isSetting = false;
      const setting = this.selectedSetting;
      rulesImpacted.priceAdjustment = setting;
      this.isUpdate = true;
      rulesImpacted.isColor = true;
      rulesImpacted.isEdit = true;
    }
    // else{
    //   this.isSetting = true;
    // }
  }

  getYearRange() {
    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();
    return `${thisYear - 10}:${thisYear + 10}`;
  }

  checkStatus() {
    let size = this.rulesImpacted.length;
    const sizeRemove = this.deleteRules.length;
    let sumRed = 0;
    let sumGreen = 0;
    this.rulesImpacted.forEach((x) => {
      if (x.status === false) {
        sumGreen += 1;
      } else {
        sumRed += 1;
      }
    });

    if (sizeRemove >= 1) {
      size = size - sizeRemove;
      sumGreen = sumGreen - sizeRemove;
    }
    if(sumRed>0){
      this.buttonView = false;
    }

    if (size === sumGreen || this.rulesImpacted.length === 0) {
      this.buttonView = true;
    }
  }

  tooltip() {
    this.rulesImpacted.forEach((x) => {
      if (Number(x.hierarchyCode) === 5) {
        x.information = 'Cliente/Material';
      } else if (Number(x.hierarchyCode) === 10) {
        x.information = 'JerarquiaCliente_2/Material';
      } else if (Number(x.hierarchyCode) === 15) {
        x.information = 'JerarquiaCliente_1/Material';
      } else if (Number(x.hierarchyCode) === 20) {
        x.information = 'Ruta/Material';
      } else if (Number(x.hierarchyCode) === 25) {
        x.information = 'GrVendedor/Gr.client./Material';
      } else if (Number(x.hierarchyCode) === 30) {
        x.information = 'GrVendedor/Gr.client3/Material';
      } else if (Number(x.hierarchyCode) === 35) {
        x.information = 'GrVendedor/Gr.client5/Material';
      } else if (Number(x.hierarchyCode) === 40) {
        x.information = 'GrVendedor/Gr.client2/Material';
      } else if (Number(x.hierarchyCode) === 45) {
        x.information = 'Zona vta./Gr.client/Material';
      } else if (Number(x.hierarchyCode) === 50) {
        x.information = 'Zona vta./Gr.client3/Material';
      } else if (Number(x.hierarchyCode) === 55) {
        x.information = 'Zona vta./Gr.client5/Material';
      } else if (Number(x.hierarchyCode) === 60) {
        x.information = 'Zona vta./Gr.client2/Material';
      } else if (Number(x.hierarchyCode) === 65) {
        x.information = 'Gr.client/Material';
      } else if (Number(x.hierarchyCode) === 70) {
        x.information = 'Gr.client3/Material';
      } else if (Number(x.hierarchyCode) === 75) {
        x.information = 'Gr.client5/Material';
      } else if (Number(x.hierarchyCode) === 80) {
        x.information = 'Gr.client2/Material';
      } else if (Number(x.hierarchyCode) === 85) {
        x.information = 'GrVendedor/Material';
      } else if (Number(x.hierarchyCode) === 90) {
        x.information = 'Zona vta./Material';
      } else if (Number(x.hierarchyCode) === 95) {
        x.information = 'Cl.pedido/Material';
      }else if(Number(x.hierarchyCode) === 509){
        x.information = 'ZPLP Material';
      }
    });
  }

  addColor(){
    if(this.rulesImpacted != null){
      this.rulesImpacted.forEach((x) => {
        x.isColor = false;
      });
    }
  }

  edit(id: number, dateStart: Date, dateEnd: Date, setting: number) {
    this.selectedDate = [];
    this.selectedDate.push(new Date(dateStart), new Date(dateEnd));
    this.selectedSetting = '';
    this.selectedSetting = String(setting);
    this.isEdit = true;
    this.isRecalculation = true;
    this.auxId = id;
    // this.rulesImpacted.forEach((x) => {
    //   if (x.solicitudeId === id) {
    //     x.isEdit = true;
    //     this.deleteRuleArrayDelete(id);
    //   }
    // }); 
  }

  deleteRuleArrayDelete(id: number){
    const ix = this.deleteRules.findIndex((fn) => fn.solicitudeId === id);
    this.deleteRules.splice(ix, 1);
  }

  recalculateDate(rulesImpacted: IRulesImpacted) {
    if (this.isEdit === true) {
      this.isEdit = false;
    }
    this.isRecalculation = false;
    if(this.isUpdate === true){
      rulesImpacted.isColor = true;
    }
   this.isUpdate = false;
    this.rulesImpacted.forEach((x) => {
      x.isOverlapDate = false;
      x.statusDate = false;
    });
    this.isUpdate = true;
    rulesImpacted.action = 1;
    this.validateDateRule();
    this.validityStatus();
  }

  activeRule(objDelete: IRulesImpacted){
      for(let i = this.rulesSuggested.length - 1; i >= 0; i--){
        if(this.rulesSuggested[i].solicitudeId == objDelete.solicitudeId){
          this.rulesSuggested[i].action = 1;
          const index1 = this.rulesSuggested.findIndex((x) => x.solicitudeId === objDelete.solicitudeId && x.action == 1);
          const index2 = this.deleteRules.findIndex((x) => x.solicitudeId === objDelete.solicitudeId);
          this.rulesSuggested.splice(index1, 1);
          this.deleteRules.splice(index2, 1);
        }
      }

    this.validateDateRule();
    this.viewRules(false,false);
    this.buttonView = false;
    // this.checkStatus();
  }

  confirmRule(id: any) {
    Util.confirmRule().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.rulesImpacted.forEach((x) => {
          if (x.solicitudeId === id) {
            x.status = false;
              // if(this.isSuggestion == '1'){
              //   x.isEdit = true;
              // }
            x.isEdit = true;
            this.checkStatus();
          }
        });
        this.spinner.hide();
      }
    });
  }

  viewRules(check: boolean, changeMessage:boolean = true){
    this.isViewRulesDelete = false;
    this.rulesImpacted = !check ? [] : this.rulesImpacted;
    this.rulesSuggested = check ? [] : this.rulesSuggested ;
    
    if(this.aux1RulesImpacted != null){
      if(check === false){
        this.buttonView = true;
        this.aux1RulesImpacted.forEach((x) => {
          
          if(x.action === 1){
            this.rulesImpacted.push(x);
          }

        });
        
        this.validateDateRule();
        this.checkStatus();      
        if(this.rulesImpacted.length <= 0){
          const language: string = sessionStorage.getItem('language');
          this.messageTable = language === 'es-ES' ? 'Por favor validar si hay reglas sugeridas para eliminar': 'Please check if there are suggested rules to remove';
        }else {
          this.rulesImpacted.sort(
            (a, b) => a.solicitudeId - b.solicitudeId
          );
        }

        this.isViewRulesDelete = true;
      }
      else{
        this.aux1RulesImpacted.forEach((x) => {
          //Reglas para eliminar
          const value: string[] = x.numberImpactedClients.split('/');
          if(x.action === 0 && Number(value[0]) !== 0 ){
            this.rulesSuggested.push(x);
          }
        });

        if(this.rulesSuggested.length === 0){
          const language: string = sessionStorage.getItem('language');
          this.messageTable = language === 'es-ES' ? 'No hay reglas impactadas': 'No rules impacted';
        }else {
          this.rulesSuggested.sort(
            (a, b) => a.solicitudeId - b.solicitudeId
          );
        }
        this.buttonView = false;
        this.isViewRulesDelete = false;
      }
    }else{
      if(check === false){
        this.isViewRulesDelete = true;
      }else{
        this.isViewRulesDelete = false;
      }
      if(changeMessage){
        const language: string = sessionStorage.getItem('language');
          this.messageTable = language === 'es-ES' ? 'No hay reglas impactadas': 'No rules impacted';
      }
    }

  }

  deleteLog(id: number) {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        for(let i = this.rulesImpacted.length - 1; i >= 0; i--){
          const value: string[] = this.rulesImpacted[i].numberImpactedClients.split('/');
          if (this.rulesImpacted[i].solicitudeId == id && Number(value[0]) !== 0) {
            const deleteModel: RulesImpactedDeleteModel = new RulesImpactedDeleteModel(
              this.rulesImpacted[i].solicitudeId,
            );
            // const index = this.rulesImpacted.indexOf(this.rulesImpacted[i]);
            this.rulesImpacted[i].action = 0;
            this.rulesImpacted[i].status = true;
            this.rulesImpacted[i].isColor = false;
            this.rulesImpacted[i].isEdit = false;
            const index = this.rulesImpacted.findIndex((x) => x.solicitudeId === id && x.action == 0);
            this.rulesImpacted.splice(index, 1);
            this.deleteRules.push(deleteModel);
            //Agrgarla a la lista de eliminados
            //this.aux1RulesImpacted.push(x);
            this.viewRules(true,false);
            this.checkStatus();
          }
        }
        this.spinner.hide();
      }
    });
  }

  skipRuleDelete(){
    this.deleteRules.forEach((rule) => {
      this.auxDelete.forEach((aux) => {
        if(rule.solicitudeId === aux.solicitudeId){
          const index = this.deleteRules.indexOf(aux);
          this.deleteRules.splice(index, 1);
        }
      });
    });
  }

  /**
   * @description this method is to approval or reject sku for solicitude material
   * @author Daniel Londono
   * @sprint 11
   */
  approvalReject(isApproval: boolean): void {

    if(this.isSuggestion === '1'){
      this.isPriceList = true;
    }else if(this.isSuggestion === '2'){
      this.isPriceList = false;
      this.skipRuleDelete();
    }

    if(this.rulesImpacted != null){
      this.rulesImpacted.forEach((x) => {
        if (x.isEdit) {
          const modifyModel: RulesImpactedEditModel = new RulesImpactedEditModel(
            x.solicitudeId,
            x.priceAdjustment,
            x.effectiveDateStart,
            x.effectiveDateEnd
          );
          this.editRules.push(modifyModel);
        }
      });
    }else{
      this.editRules = [];
      this.deleteRules = [];
    }

    

    this.editRules = this.editRules.filter((item, i, arr) => arr.findIndex((t) => t.solicitudeId=== item.solicitudeId) === i);
    this.deleteRules = this.deleteRules.filter((item, i, arr) => arr.findIndex((t) => t.solicitudeId=== item.solicitudeId) === i);

    let validateDuplicate = this.validateDuplicateRules();
    if(!validateDuplicate){
      return;
    }

    const approvalModel: SolicitudeMaterialApprovalModel =
      new SolicitudeMaterialApprovalModel(
        this.idSolicitude,
        isApproval,
        this.commentary,
        this.code,
        this.isPriceList,
        this.editRules,
        this.deleteRules
      );

      if(this.isSuggestion === '1' && isApproval === true){
        Util.confirmSendApproval().then((result: SweetAlertResult) => {
          if (result.value) {
          this.spinner.show();
          this.disabledButton = true;
          this.solicitudeService
          .putSolicitudeMaterialApproval(approvalModel)
          .subscribe(
            (response) => {
              if (response.status) {
                this.router.navigate(['auth/pending-solicitude/creation']);
              } else {
                SweetAlertMessageHelpers.warning('Error','hubo un error al aprobar el material ' + `${this.code}` + ' por favor vuelva a ejecutar la acción');
                this.disabledButton = false;
                this.editRules = [];
                this.deleteRules = [];
              }
            },
            (error) => {
              SweetAlertMessageHelpers.exception(error);
            },
            () => {
              this.spinner.hide();
             }
          );
          }
        });
      }else{
        this.spinner.show();
        this.disabledButton = true;
        this.solicitudeService
        .putSolicitudeMaterialApproval(approvalModel)
        .subscribe(
          (response) => {
            if (response.status) {
              this.router.navigate(['auth/pending-solicitude/creation']);
            } else {
              SweetAlertMessageHelpers.warning('Error','hubo un error al aprobar el material ' + `${this.code}` + ' por favor vuelva a ejecutar la acción');
              this.disabledButton = false;
              this.editRules = [];
              this.deleteRules = [];
             
            }
          },
          (error) => {
            SweetAlertMessageHelpers.exception(error);
          },
          () => {
            this.spinner.hide();
          }
        );
      }

    }

    validateDuplicateRules() : boolean{
      let intersection = [];
      if(this.editRules.length > 0 && this.deleteRules.length > 0){
        this.editRules.forEach(edit => {
          this.deleteRules.forEach(delet => {
            if(edit.solicitudeId === delet.solicitudeId){
              intersection.push(edit.solicitudeId);
            }
          });
        });     
      }
      
      if(intersection.length > 0){
        SweetAlertMessageHelpers.warning('Error','hubo un error al aprobar el material ' + `${this.code}` + ' por favor vuelva a ejecutar la acción');
        this.editRules = [];
        this.deleteRules = [];
        return false;
      }

      return true;
    }

    seeMore(ruleImpact : IRulesImpacted, flagRuleImpact : boolean){
      this.displayModalDetails = true;
      this.filtersEdit = '';
      this.clientsEdit = [];
      this.analysisCharts2 = [];
      this.rulesImpactedShow = [];
      this.rulesImpactedShow.push(ruleImpact);
      let typeRule = flagRuleImpact ? this.rulesImpacted : this.rulesSuggested;   
      typeRule.forEach(x => {
        if(x.solicitudeId === ruleImpact.solicitudeId){
          if (x.filterClientJson) {
            this.filtersEdit = x.filterClientJson;
          } else if (x.solicitudeCustomerJson) {
            this.clientsEdit = x.solicitudeCustomerJson;
          }

          if (x.solicitudeFileJson) {
            this.priceCertificate.downloadFile = new SolicitudeFileModel(
              x.solicitudeFileJson[0].id,
              x.solicitudeFileJson[0].solicitudeId,
              x.solicitudeFileJson[0].route,
              x.solicitudeFileJson[0].file,
              x.solicitudeFileJson[0].hash,
              
            );
            this.priceCertificate.nameFile = this.priceCertificate.downloadFile.route;
            this.priceCertificate.solicitudeId = x.solicitudeId;
          }
        }
          
      });
      
      this.solicitudeService.getImpactAnalysis(String(ruleImpact.guid), AnalyticModule.Price)
      .subscribe(
        (response) => {
          if (response.impacts && Object.keys(response.impacts).length > 0){
            const dataRetrive = response;
            this.analysisCharts2 = this.analysisChartsDto(dataRetrive);
          }
        }
      );
    }

    hide(condition : number = 0) { 
     this.displayModalDetails = false;
     this.isCreateFilter = false;
     this.filtersEdit = '';
     this.clientsEdit = [];
    }

    showToast(severity: string, message: string, detail: string) {
      this.messageService.clear();
      this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
    }

}
