<section>
    <div class="container">
        <nav aria-label="breadcrumb">
        <ol class="pstn breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="'/auth'">
                <i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
              </li>
            <li class="breadcrumb-item">
              <a [routerLink]="'/auth/hierarchy-release'">
                {{ "HierarchyReleaseComponent.Title" | translate }}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "HierarchyReleaseComponent.Create.Title" | translate }}
            </li>
        </ol>
        </nav>
    </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <h1 class="pstntitle">
        {{ "HierarchyReleaseComponent.Create.Title" | translate }}
      </h1>
    </div>

    <form id="formHierarchy" [formGroup]="formHierarchy">
      <div class="pstn_container ">
        <div class="row ">
          <!-- description -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.HierarchyName" | translate }}</label>
              <input #user id="description"
                formControlName="description"

                autocomplete="off"
                placeholder="{{'HierarchyReleaseComponent.Create.NamePlaceHolderInpu' | translate}}"
                class="form-control"
                [ngClass]="{ 'ng-invalid ng-dirty': description.invalid && description.touched }" pInputText
                 required (keyup)="keyupHierarchyRelase($event)"/>
              <small id="description" *ngIf="
                  formHierarchy.controls['description'].invalid &&
                  (formHierarchy.controls['description'].dirty ||
                  formHierarchy.controls['description'].touched)"
                  class="p-error">{{"HierarchyReleaseComponent.Create.NameMsgValidate" | translate}}</small>
            </div>
          </div>

          <!-- Country -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.Country" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="country"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': country.invalid && country.touched}"
                  formControlName="country"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.CountryPlaceHolderCombo' | translate}}"
                  [options]="objectCountryList"
                  [style]="{ width: '100%' }"
                  (onChange)="getSocietiesByCountry()">
                </p-dropdown>
                <small id="countryValidator" *ngIf="
                    formHierarchy.controls['country'].invalid &&
                    (formHierarchy.controls['country'].dirty ||
                    formHierarchy.controls['country'].touched)"
                    class="p-error">{{"HierarchyReleaseComponent.Create.CountryMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>

          <!-- Society -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.Society" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="society"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': society.invalid && society.touched}"
                  formControlName="society"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.SocietyPlaceHolderCombo'| translate}}"
                  [options]="objectSocietyList"
                  [style]="{ width: '100%' }"
                  (onChange)="getCediChannelRegionBySocietyId()">
                </p-dropdown>
                <small id="societyValidator" *ngIf="
                    formHierarchy.controls['society'].invalid &&
                    (formHierarchy.controls['society'].dirty ||
                    formHierarchy.controls['society'].touched)"
                    class="p-error">{{"HierarchyReleaseComponent.Create.SocietyMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>

          <!-- Channel -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.Channel" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="channel"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': channel.invalid && channel.touched}"
                  formControlName="channel"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.ChannelPlaceHolderCombo'| translate}}"
                  [options]="objectChannelList"
                  [style]="{ width: '100%' }"
                  >
                </p-dropdown>
                <small id="channel" *ngIf="
                    formHierarchy.controls['channel'].invalid &&
                    (formHierarchy.controls['channel'].dirty ||
                    formHierarchy.controls['channel'].touched)"
                    class="p-error">{{"HierarchyReleaseComponent.Create.ChannelMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>

          <!-- Region -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.Region" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="region"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': region.invalid && region.touched}"
                  formControlName="region"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.RegionPlaceHolderCombo'| translate}}"
                  [options]="objectRegionList"
                  [style]="{ width: '100%' }" >
                </p-dropdown>
                <small id="regionValidator" *ngIf="
                    formHierarchy.controls['region'].invalid &&
                    (formHierarchy.controls['region'].dirty ||
                    formHierarchy.controls['region'].touched)"
                    class="p-error">{{"HierarchyReleaseComponent.Create.RegionMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>

          <!-- Cedi -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.Cedi" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="cedi"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': cedi.invalid && cedi.touched}"
                  formControlName="cedi"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.CediPlaceHolderCombo'| translate}}"
                  [options]="objectCediList" [style]="{ width: '100%' }" >
                </p-dropdown>
                <small id="cediValidator" *ngIf="
                    formHierarchy.controls['cedi'].invalid &&
                    (formHierarchy.controls['cedi'].dirty ||
                    formHierarchy.controls['cedi'].touched)"
                    class="p-error">{{"HierarchyReleaseComponent.Create.CediMsgValidate" | translate}}</small>
              </div>
            </div>
          </div>

          <!-- Process -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.Process" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="process"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': process.invalid && process.touched}"
                  formControlName="process"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.ProcessPlaceHolderCombo'| translate}}"
                  [options]="objectProcessList"
                  (click)="forkJoinData()"
                  [style]="{ width: '100%' }"
                  (onChange)="getSubprocessByCodeProcess()">
                </p-dropdown>
                <small id="processValidator" *ngIf="
                    formHierarchy.controls['process'].invalid &&
                   (formHierarchy.controls['process'].dirty ||
                    formHierarchy.controls['process'].touched)"
                    class="p-error">{{"HierarchyReleaseComponent.Create.ProcessMsgValidate" | translate}}</small>
              </div>
            </div>
          </div>

          <!-- Subprocess -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.Subprocess" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="subprocess"
                  [filter]="true"
                  [showClear]="true"
                  [ngClass]="{'ng-invalid ng-dirty': subprocess.invalid && subprocess.touched}"
                  formControlName="subprocess"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.SubprocessPlaceHolderCombo'| translate}}"
                  [options]="objectSubprocessList"
                  (click)="getSubprocessByCodeProcess()"
                  [style]="{ width: '100%' }" >
                </p-dropdown>
                <small id="subprocessValidator"
                *ngIf=" formHierarchy.controls['subprocess'].invalid &&
                       (formHierarchy.controls['subprocess'].dirty ||
                        formHierarchy.controls['subprocess'].touched)"
                        class="p-error">{{"HierarchyReleaseComponent.Create.SubprocessMsgValidate" | translate}}</small>
              </div>
            </div>
          </div>

          <!-- MacroSegment -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.MacroSegment" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="macrosegmentCode"
                  [filter]="true"
                  [showClear]="true"
                  formControlName="macrosegmentCode"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.MacroSegmentPlaceHolderCombo'| translate}}"
                  [options]="objectMacrosegmentsList"
                  [style]="{ width: '100%' }" >
                </p-dropdown>
              </div>
            </div>
          </div>

          <!-- BusinessTypologies -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "HierarchyReleaseComponent.Create.BusinessTypology" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="businessTypologyCode"
                  [filter]="true"
                  [showClear]="true"
                  formControlName="businessTypologyCode"
                  optionLabel="label"
                  optionValue="value"

                  placeholder="{{'HierarchyReleaseComponent.Create.BusinessTypologyPlaceHolderCombo'| translate}}"
                  [options]="objectBusinessTypologiesList"
                  [style]="{ width: '100%' }" >
                </p-dropdown>
              </div>
            </div>
          </div>

          <!-- Button -->
          <div class="form-check col-md-4 mt-4" *ngIf="processHierarchy != processHierarchyEnum.view">
            <div class=" align-self-center">
              <button id="btnAddAssociate"
                label="{{ 'HierarchyReleaseComponent.Create.BtnAssociate' | translate }}"
                class="p-button-rounded pstn_primario w-100"
                pButton
                pRipple
                (click)="getInfoAssociateHierarchy()"
                type="button">
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row mt-5">

      <div class="pstn_container container-fluid">
        <p-table #dt
                [value]="hierarchyRealeaseAssociate"
                dataKey="id"
                styleClass="p-datatable-customers"
                [paginator]="true"
                [rows]="10"
                paginatorPosition="bottom"
                currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
                [rowsPerPageOptions]="[10,25,50,100]"
                [globalFilterFields]="['description']">

          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input pInputText
                        type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem"></th>
              <th pSortableColumn="description">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'HierarchyReleaseComponent.Description' | translate }}<p-sortIcon field="description"></p-sortIcon>
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'HierarchyReleaseComponent.InitialRange' | translate }}
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'HierarchyReleaseComponent.FinalRange' | translate }}
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'HierarchyReleaseComponent.UnitMeasurement' | translate }}
                </div>
              </th>
              <th *ngIf="processHierarchy == processHierarchyEnum.view">

              </th>
              <th *ngIf="processHierarchy != processHierarchyEnum.view">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'Actions' | translate }}
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body"
                      let-object
                      let-expanded="expanded">
            <tr [pSelectableRow]="object.associateUsers">
              <td>
                <button type="button"
                        pButton
                        pRipple
                        [pRowToggler]="object"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                </button>
              </td>
              <td>{{object.description}}</td>
              <td>{{object.OperatorStart}} {{object.RankStart}}</td>
              <td>{{object.OperatorEnd}}{{object.RankEnd}}</td>
              <td>{{object.UnitMeasurementDescription}}</td>
              <td *ngIf="processHierarchy == processHierarchyEnum.view">

              </td>
              <td *ngIf="processHierarchy != processHierarchyEnum.view">
                <button id="btnEdit"
                        type="button"
                        class="edit btn btn-secundary-editar"
                        (click)="editAssociateHierarchyRelease(object)"
                        *ngIf="(rolePermission.update | rolePermission)">
                <i class="uil uil-edit Editar" aria-hidden="true"></i>
                </button>
                <button id="btnAssociateRank"
                        type="button"
                        class="edit btn btn-secundary-editar"
                        (click)="assignAssociateRank(object)">
                  <i class="pi pi-plus-circle enviar" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-object>
            <tr>
              <td colspan="6">
                <div class="">
                  <p-table [value]="object.associateUsers"
                          [scrollable]="true"
                          scrollHeight="400px"
                          dataKey="id">

                      <ng-template pTemplate="header">
                          <tr>
                            <th style="width: 3rem"></th>
                            <th pSortableColumn="names">
                              <div class="p-d-flex p-jc-between p-ai-center">
                                {{ 'HierarchyReleaseComponent.names' | translate }}
                                <p-sortIcon field="names"></p-sortIcon>
                              </div>
                            </th>
                            <th pSortableColumn="user">
                              <div class="p-d-flex p-jc-between p-ai-center">
                                {{ 'HierarchyReleaseComponent.User' | translate }}
                                <p-sortIcon field="user"></p-sortIcon>
                              </div>
                            </th>
                            <th>
                              <div class="p-d-flex p-jc-between p-ai-center">
                                {{ 'HierarchyReleaseComponent.Associate.Mandatory' | translate }}
                              </div>
                            </th>
                            <th>
                              <div class="p-d-flex p-jc-between p-ai-center">
                                {{ 'HierarchyReleaseComponent.DirectAutorization' | translate }}
                              </div>
                            </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-associateUser>
                        <tr>
                          <td style="width: 3rem"></td>
                          <td>{{associateUser.name}} {{associateUser.lastName}}</td>
                          <td>{{associateUser.accessManagementId}}</td>
                          <td>
                            <p-inputSwitch id="switch-one-{{associateUser.id}}"
                                           name="switch-{{associateUser.id}}"
                                           readonly="true"
                                           [(ngModel)]="associateUser.mandatory">
                            </p-inputSwitch>
                          </td>
                          <td>
                              <p-inputSwitch id="switch-{{associateUser.id}}"
                                            name="switch-{{associateUser.id}}"
                                            readonly="true"
                                            [(ngModel)]="associateUser.authorizer">
                              </p-inputSwitch>
                          </td>
                        </tr>
                      </ng-template>

                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>


        </p-table>
      </div>
    </div>

    <div class="row mt-3"></div>
    <div class="row justify-content-end">
      <div class="col-lg-4 md-3 mt-3">
        <button type="button"
              class="p-button-rounded pstn_primario w-100"
              style="background-color: darkgrey; border-color: darkgrey;"
              (click)="cancel()">
          {{'CancelBtn' | translate}}
        </button>
      </div>

      <div class="col-lg-4 md-3 mt-3" *ngIf="processHierarchy == processHierarchyEnum.create">
        <button type="button"
                class="p-button-rounded pstn_primario w-100"
                style="float: right;"
                (click)="create()"
                *ngIf="(rolePermission.create | rolePermission)">
          {{'SaveBtn' | translate}}
        </button>
      </div>

      <div class="col-md-6" *ngIf="processHierarchy == processHierarchyEnum.update">
        <button type="button"
                class="p-button-rounded pstn_primario w-100"
                style="float: right;"
                (click)="update()"
                *ngIf="(rolePermission.update | rolePermission)">
          {{'UpdateBtn' | translate}}
        </button>
      </div>
    </div>
    <div class="row mt-3"></div>

  </div>
</section>
