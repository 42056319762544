import { IUsersInfoAccess } from '../interfaces/users-info-access.interface';

export class UsersInfoAccessModel implements IUsersInfoAccess {
    /**
    * @author Jhon Steven Pavón Bedoya
    * @param name
    * @param lastName
    * @param email
    * @param accessManagementId
    * @param codeDomain
    * @param profileId
    * @sprint 6
    */
    constructor(
        public name: string,
        public lastName: string,
        public email: string,
        public accessManagementId: string,
        public profileId: number,
        public mandatory: boolean = false,
        public authorizer: boolean = false,
        public approval: boolean = false
    ) {}

  }