import { IFieldsComponentGeneralFilter } from "../interfaces/fields-component-general-filter.interface";

export class FieldsComponentGeneralFilter implements IFieldsComponentGeneralFilter {
    constructor(
        public activityCode: boolean,
        public activityDescription: boolean,
        public rangeDateControl: boolean,
        public statusDifferential: boolean,
        public typeSolicitude: boolean,
        public statusSolicitude: boolean,
        public sector: boolean,
        public channel: boolean,
        public activityType: boolean,
        public activityStatus: boolean,
        public activityProm: boolean,
        public rangeCurrentDateControl: boolean,
        public userCreator: boolean,
        public rangeCreationDateControl: boolean,
        public rangeValidityDateControl: boolean)
    {}
}