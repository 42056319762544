import { Injectable } from '@angular/core';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PriceCertificateService {

  private readonly httpService: HttpService;

  constructor(
    httpService: HttpService,
  ) {
      this.httpService = httpService;
  }

  public getFile(solicitudId : number, fileName : string){
    let type : string = 'application/pdf';
    return this.httpService.get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_file/download_file_by_solicitude/${solicitudId}`, undefined, true )
    .pipe(
      tap(content => {
        const blob = new Blob([content.body], {type});
        saveAs(blob,fileName);
      }),
      map(() => true)
    );
  }
}
