<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"
            ><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "ValidityPeriodComponent.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>


<section class="pstngestion">
  <div class="container">
    <div class="row">
      <h1 class="pstntitle">{{ 'ValidityPeriodComponent.Title' | translate }}</h1>
    </div>

    <form id="formValidityPeriod" [formGroup]="formValidityPeriod">
      <div class="row mt-3">
        <div class="col-md-4">
          <label>{{ 'ValidityPeriodComponent.ComercialActivityName' | translate }}</label>
          <div class="form-group">
            <p-dropdown id="comercialActivity"
                        [filter]="true"
                        [showClear]="true"
                        [ngClass]="{'ng-invalid ng-dirty':comercialActivity.invalid && comercialActivity.touched}"
                        formControlName="comercialActivity"
                        optionLabel="label"
                        optionValue="value"
                        placeholder= "{{'ValidityPeriodComponent.ComercialActivityPlaceHolderCombo' | translate }}"
                        [options]="objectPromotionalActivity"
                        (click)="getAllComercialActivity()"
                        [style]="{ width: '100%' }"
                        [disabled]="disabledDropdown">
            </p-dropdown>

            <small id="comercialActivityCodeValidator"
                    *ngIf="formValidityPeriod.controls['comercialActivity'].invalid &&
                    (formValidityPeriod.controls['comercialActivity'].dirty ||
                    formValidityPeriod.controls['comercialActivity'].touched)"
                    class="p-error">{{ 'ValidityPeriodComponent.ComercialActivityNameMsgValidate' | translate }}</small>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label for="days">{{ 'ValidityPeriodComponent.NumberDays' | translate }}</label>
            <p-inputNumber id="days"
													formControlName="days"
													inputId="integeronly"
													[min]="1"
                          [max]="999"
													(keydown)="checkTNumber($event)"
													required></p-inputNumber>
            <small id="daysMsgValidate"
                   *ngIf="formValidityPeriod.controls['days'].invalid &&
                   (formValidityPeriod.controls['days'].dirty ||
                   formValidityPeriod.controls['days'].touched)"
                   class="p-error">{{ 'ValidityPeriodComponent.NumberDaysNameMsgValidate' | translate }}</small>
          </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3 mt-4 align-items-end">
          <button
            id="btnCreate"
            pButton
            pRipple
            type="button"
            label="{{ 'ValidityPeriodComponent.CreateBtn' | translate }}"
            class="p-button-rounded pstn_primario w-100"
            (click)="add()"
            *ngIf="this.isCreate && (rolePermission.create | rolePermission)"></button>
          <button
            id="btnUpdate"
            pButton
            pRipple
            type="button"
            label="{{ 'UpdateBtn' | translate }}"
            class="p-button-rounded pstn_primario w-100"
            (click)="updateData()"
            *ngIf="!this.isCreate && (rolePermission.update | rolePermission)"></button>
        </div>
      </div>
    </form>

    <div class="row mt-4">
      <div class="pstn_container container-fluid">
        <p-table #dt
                 name="gridValidityPeriod"
                 [value]="listValidityPeriod"
                 dataKey="id"
                 [paginator]="true"
                 [rows]="10"
                 currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
                 [rowsPerPageOptions]="[10,25,50,100]"
                 paginatorPosition="bottom"
                 styleClass="p-datatable-customers"
                 [globalFilterFields]="['subProcessDescription', 'numberDay']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex ">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input pInputText
                       type="text"
                       (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="subProcessDescription">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'ValidityPeriodComponent.ComercialActivityName' | translate }}
                  <p-sortIcon field="comercialActivity"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="numberDay">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'ValidityPeriodComponent.NumberDays' | translate }}
                  <p-sortIcon field="days"></p-sortIcon>
                </div>
              </th>

              <th>{{ 'Actions' | translate }}</th>
            </tr>
            <tr>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-object>
            <tr>
              <td>{{ 'Domain.Domain-'+object.subProcessDomainId | translate}}
                <!-- {{object.subProcessDescription}} -->
              </td>
              <td>{{object.numberDay}}</td>
              <td>
                <button id="btnEdit"
                        type="button"
                        ngbTooltip="Editar"
                        class="edit btn btn-secundary-editar"
                        (click)="edit(object)"
                        pTooltip="{{'Edit' | translate}}"
                        *ngIf="(rolePermission.update| rolePermission)">
                  <i class="uil uil-edit Editar" aria-hidden="true"></i>
                </button>
                <button id="btnDelete"
                        ngbTooltip="Eliminar"
                        type="button"
                        class="delete btn btn-secundary-delete"
                        (click)="delete(object)"
                        pTooltip="{{'Delete' | translate}}"
                        *ngIf="(rolePermission.delete | rolePermission)">
                  <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">{{ 'ElementsNotFound' | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</section>

<p-toast></p-toast>
