<div *ngIf="objectList.length > 0">
<p-accordion>
    <p-accordionTab header="{{ 'ManagePromotionalActivitiesComponent.Historical.Header' | translate }}" 
	[selected]="false">
        <div class="row">
            <div class="col-md-12">
                <div>
                    <p-table #dttea name="gridAdvancedFilters" 
						[value]="objectList" dataKey="approvalSelected" [paginator]="true"
						[rows]="10" paginatorPosition="bottom" [(selection)]="selectObject" styleClass="p-datatable-customers"
						[rowsPerPageOptions]="[10,25,50,100]"
						currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
						[globalFilterFields]="['Id']">

						<ng-template pTemplate="header">
							<tr>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.Sector' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.Level' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.ApprovalLevel' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.State' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.ModificationDate' | translate}}</th>
								<th>{{'ManagePromotionalActivitiesComponent.Liberation.StateDetail' | translate}}</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
							<tr>
								<td>{{object.sectorDescription}}</td>
								<td>{{object.approvalProfile}}</td>
								<td>{{object.approvalUser}}</td>
								<td>
									<span *ngIf="object.statusDomainId === approvalStatus.WithoutChecking">
										N/A
									</span>
									<span *ngIf="object.statusDomainId === approvalStatus.Rejection">
											{{'ManagePromotionalActivitiesComponent.Liberation.Rejection' | translate}}
									</span>
									<span *ngIf="object.statusDomainId === approvalStatus.OK">
											{{'ManagePromotionalActivitiesComponent.Liberation.OK' | translate}}
									</span>
									<span
										[ngClass]="object.statusDomainId === approvalStatus.OK ? 'color-green' : 
										object.statusDomainId === approvalStatus.Rejection ? 'color-red' : 'color-gray'"></span>
								</td>
								<td>{{object.modificationDate | date: 'dd/MM/yyyy hh:mm a'}}</td>
								<td>
									{{ object.approvalDetail | slice:0:20 }}
									<a href="javascript:void(0);" (click)="showApprovalModalDetail(object)"
										*ngIf="object.approvalDetail !== ''">
										{{'ManagePromotionalActivitiesComponent.Liberation.ShoMore' | translate}}
									</a>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="5">{{ 'ElementsNotFound' | translate }}</td>
							</tr>
						</ng-template>
					</p-table>
                </div>
            </div>
        </div>
    </p-accordionTab>
</p-accordion>
</div>
<p-dialog header="{{ 'ManagePromotionalActivitiesComponent.Liberation.TitleApprovalDetailModal' | translate}}"
  [(visible)]="showModalApprovalDetailText" [modal]="true" 
  [style]="{ width: '40vw' }" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="content">

		<div class="row">
			<div class="col-md-12" style="text-align: center;">
				<p>
					{{ selectObject.approvalDetail }}
				</p>
			</div>
		</div>

  </ng-template>
	<ng-template pTemplate="footer">
    <button pButton pRipple
						label="{{ 'CloseBtn' | translate}}"
						icon="pi pi-times"
						class="p-button-primary"
						(click)="showModalApprovalDetailText=false;"></button>
  </ng-template>

</p-dialog>