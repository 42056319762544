import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ICommercialActivityFooter } from '@app/@components/commercial-activity-footer/interfaces/commercial-activity-footer.interface';
import { CommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/commercial-activity-footer.model';
import { FieldsCommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/fields-commercial-activity-footer.model';
import { IHierarchyFiltersMaterials } from '@app/@components/hierarchy-advanced-filters/interfaces/hierarchy-filters-materials.interface';
import { IHeaderTableGroupItem } from '@app/@components/tables-with-groups/interfaces/header-table-group-item.interface';
import { ISubGroupItem } from '@app/@components/tables-with-groups/interfaces/sub-group-item.interface';
import { ISubHeaderTableGroupItem } from '@app/@components/tables-with-groups/interfaces/subheader-table-group-item.interface';
import { ITableGroupItem } from '@app/@components/tables-with-groups/interfaces/table-group-item.interface';

import { SubDiscountFinancial } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-financial.model';
import { SubDiscountKind } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-kind.model';
import { SubDiscountPriceAdjustment } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-price-adjustment.model';
import { SubDiscountValue } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-value.model';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { ManagePromotionalActivitiesService } from '../manage-promotional-activities.service';
import { ManagePromotionalActivitiesMessage } from '../messages/manage-promotional-activities-messages.message';

import { CommercialActivityInvalidationDataDetail, CommercialActivityInvalidationDataDetailShow } from './../interfaces/commercial-activity-invalidation-data-detail';
import { IProductGroupList } from './interfaces/product-group-list.interface';
import { IProductList } from './interfaces/product-list.interface';
import { CommercialActivityLoyaltyModel } from './models/commercial-activity-loyalty.model';
import { ProductGroupListModel } from './models/product-group-list.model';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { DomainService } from '@app/@shared/services/domain.service';
import { Router } from '@angular/router';
import { IChannel } from './interfaces/channel-interface';
import { CommercialActivityLoyaltyService } from './commercial-activity-loyalty.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommercialActivityLoyaltyMessage } from './messages/commercial-activity-loyalty.message';
import { IDataRange } from '@app/@components/commercial-activity-footer/interfaces/data-range.interface';
import { ICommercialActivityLoyalty } from './interfaces/commercial-activity-loyalty.interface';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { Loyalty } from '@app/@components/tables-with-groups/models/group/loyalty.model';
import { SubLoyalty } from '@app/@components/tables-with-groups/models/subGroup/loyalty.model';
import { MeasurementUnit } from '@app/@shared/enums/measurement-unit.enum';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { CommercialACtivityQueueValidationModel } from '../models/commercial-activity-queue-validation.model';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { Module } from '@app/@shared/enums/module.enums';
import { ICustomer } from '../promotional-activities-create/interfaces/customer-list.interface';
import { ICommercialActivitySector } from '../interfaces/commercial-activity-sector.interface';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { PromotionalActivitiesCreateService } from '../promotional-activities-create/promotional-activities-create.service';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { CommercialActivityStatusModel } from '../models/commercial-activity-status.model';
import { IUserCreation } from '@app/@components/commercial-activity-approval-selected/interface/user-creation.interface';
import { UserCreationModel } from '@app/@components/commercial-activity-approval-selected/models/user-creation.model';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';
import { CommercialActivityDocumentComponent } from '@app/@components/commercial-activity-document/commercial-activity-document.component';
import { CommercialActivityDocumentRequiredModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document-required.model';
import { CommercialActivityDocumentModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document.model';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';

@Component({
  selector: 'app-commercial-activity-loyalty',
  templateUrl: './commercial-activity-loyalty.component.html',
  styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
    .container-table.header{
      padding-left: 29px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #009CDD;
      font-weight: 700;
      font-size: 19px;
      margin-bottom:50px;
    }
    .container-table.options{
      padding-left: 29px;
      font-weight: 400;
      font-size: 14px;
    }
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
  ],
  providers: [MessageService, DatePipe],
})
export class CommercialActivityLoyaltyComponent implements OnInit {

	@ViewChild('documentSupport') documentSupport: CommercialActivityDocumentComponent;

  private readonly spinner: NgxSpinnerService;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly messageService: MessageService;
  private readonly coreService: CoreMasterService;
  private readonly domainService: DomainService;
  private readonly router: Router;
  private readonly loyaltyService: CommercialActivityLoyaltyService;
  private readonly parameterService: ManageParametersService;
  private readonly promotionalActivityService: PromotionalActivitiesCreateService;

  // #region Define Variables
  public commActivityStatusRegisteredId: number = CommercialActivityStatus.Registered;
  public routeCancelCreatePromotion = '/auth/manage-promotional-activities';
  public showFieldsCreatePromotion: FieldsCommercialActivityFooterModel;
  public showButtonsCreatePromotion = true;
  public promotionEdit: CommercialActivityFooterModel;
  public promotionEditFooter: CommercialActivityFooterModel;
  public commercialActStatVal: number;
  public filterClientJsonToCalculate = '';
  public filterClientJson = '';
  public commercialActivityCustomers: ICustomer[];

	public documentInfo: CommercialActivityDocumentModel[];
	public flagDocumentObservation: boolean = true;
	public documentFieldsRequired: CommercialActivityDocumentRequiredModel;
	public observationEdit: CommercialActivityDocumentModel[];

  public description = '';
  public typeCommercialActivityId = 0;
  public commercialActivityId = 0;
  public channel: any[] = [];
  public objectChannelList: ICombo[];

  public objectProcessList: ICombo[];
  public objectTypeCommercialActivity: any[] = [];
  public discountActivityEdit: CommercialActivityLoyaltyModel;

  public showTable = true;
  public showTableFilter = true;
  public isMultiFilter = true;
  public editFiltersObjectList: IHierarchyFiltersMaterials[] = [];
  public advancedFiltersObjectList: IHierarchyFiltersMaterials[] = [];
  public isPurchaseBase = false;
  public isProcess: number;
  public typeCommercialActivity = '';
  public typeDescription = '';
  public purchaseBaseToCalculate: IProductList[] = [];
  public purchasePromotionToCalculate: IProductList[] = [];
  public totalCostSale = 0;
  public totalCostIncome = 0;
  public nextAssignClients = false;
  public isInitialError: boolean = true;
  public documentValid: boolean = true;

  public objectSectorList: ICombo[];
  public objectSectorSelect: ICombo[] = [];
  public sectorCommercialActivityParam = false;
  public sector: string[] = [];
  public commercialActivityEnum = ComercialActivityEnum;
  public displayValidationsDetails = false;
  public invalidationDataDetail: CommercialActivityInvalidationDataDetailShow[] = [];
  // #endregion

  public channelTooltip: string[];

  public sectorTooltip: string[];
  // Custom variables tableGroups
  public subHeaderTableConst: ISubHeaderTableGroupItem[] = [
    {
      name: 'ManagePromotionalActivitiesComponent.Discount.subHeaderTable.codSku',
      attr: 'sku'
    },
    {
      name: 'ManagePromotionalActivitiesComponent.Discount.subHeaderTable.desSku',
      attr: 'descSku'
    }, {
      name: 'ManagePromotionalActivitiesComponent.Discount.subHeaderTable.productType',
      attr: 'prodType',
    }
  ];

  public headersTableConst: IHeaderTableGroupItem[] = [
    {
      name: 'ManagePromotionalActivitiesComponent.Discount.headersTable.discountedProduct',
      attr: 'descGroup'
    }
  ];

  public valueTable: ITableGroupItem[] = [];
  public subHeaderTable: ISubHeaderTableGroupItem[] = [];
  public headersTable: IHeaderTableGroupItem[] = [];
  public commercialActivityStateEnum: any;
  // #endregion


  // Products in group table
  public productGroupFilterList: IProductGroupList[] = [];
  public selectProductHeadGroupList: IProductGroupList[] = [];
  public selectProductList: IProductList[] = [];
  // Promotional Products
  public productGroupFilterListPromotion: IProductGroupList[] = [];
  public selectProductHeadGroupListPromotion: IProductGroupList[] = [];
  public selectProductListPromotion: IProductList[] = [];
  // #endregion

  public arrayDeleteSelect: any[] = [];
  public arrayDeleteSelectGroup: any[] = [];

  public get comercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }
  public rolePermission: any = RolePermissionEnum;

	public userCreation : IUserCreation;
  public initialDate : Date;
  public showFields : IFieldsClientFilter = new FieldsClientFilterModel(true, true, true, false, true, true);

  public isCalculate : boolean = false;
  constructor(
    public translate: TranslateService,
    spinner: NgxSpinnerService,
    managePromotionActService: ManagePromotionalActivitiesService,
    messageService: MessageService,
    coreService: CoreMasterService,
    domainService: DomainService,
    router: Router,
    loyaltyService: CommercialActivityLoyaltyService,
    parameterService: ManageParametersService,
    promotionalActivityService: PromotionalActivitiesCreateService
  ) { 
    this.spinner = spinner;
    this.managePromotionActService = managePromotionActService;
    this.messageService = messageService;
    this.coreService = coreService;
    this.domainService = domainService;
    this.router = router;
    this.loyaltyService = loyaltyService;
    this.parameterService = parameterService;
    this.promotionalActivityService = promotionalActivityService;
  }

  ngOnInit(): void {
    this.commercialActivityStateEnum = this.getCommercialActivityStatusEnum();
    this.showFieldsCreatePromotion = new FieldsCommercialActivityFooterModel(true, true, true, true, true, true, true, true);
    this.isProcess = this.managePromotionActService.getIsCreate();
    this.forkJoinInitialData();
  }

  public getCommercialActivityStatusEnum(): typeof CommercialActivityStatus {
    return CommercialActivityStatus;
  }

  /**
    * @description this method execute async requests.
    *
    * @author Diego Mauricio Cortés
    * @sprint 11
    */
  forkJoinInitialData(): void {
    this.spinner.show();

    const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
    const measurementUnitResponse = this.coreService.getAll(TypeCoreMaster.MeasurementUnit);
    const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);
    const sectorResponse = this.coreService.getAll(TypeCoreMaster.Sector);
    const parametersResponse = this.parameterService.getAll();

    forkJoin([
      channelResponse,
      measurementUnitResponse,
      processResponse,
      sectorResponse,
      parametersResponse
    ])
      .subscribe(
        (result)=> {

          const objChannelResponse: IResponseService<ICoreMaster[]> = result[0];
          const objMeasurementUnitResponse: IResponseService<ICoreMaster[]> = result[1];
          const objProcessResponse: IResponseService<IDomain[]> = result[2];
          const objSectorResponse: IResponseService<ICoreMaster[]> = result[3];
          const objParametersResponse: IResponseService<any[]> = result[4];

          this.objectChannelList = objChannelResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });

          this.objectProcessList = objProcessResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          this.objectSectorList = objSectorResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim(),
              crossActive: item.crossActive
            };
          });

          const param = objParametersResponse.data.filter((prm) => prm.id === ManagementParameterEnum.sectorCommercialActivity);
          if (param) {
            this.sectorCommercialActivityParam = param[0].status;
          }

          this.objectTypeCommercialActivity = [...objProcessResponse.data];

          if (this.isProcess === this.commercialActivityEnum.update || this.isProcess === this.commercialActivityEnum.view) {
            this.initEditLoyalty();
          } else {
            this.initCreateDiscount();
          }

          if (this.objectSectorList && this.objectChannelList) {
            const userFilters = this.managePromotionActService.setUserFilters(this.objectChannelList, this.objectSectorList);
            this.objectChannelList = userFilters.channels;
            this.objectSectorList = userFilters.sectors;
          }

        }, () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
  }



  /**
   * @description This method is to prepare commercial activity loyalty data to create
   *
   * @param footerData
   * @author Santiago Martinez
   * @sprint 11
   */
  private prepareCommercialActivityLoyaltyToCreate(footerData: ICommercialActivityFooter): CommercialActivityLoyaltyModel {

    const promotionalActivityModel: CommercialActivityLoyaltyModel = new CommercialActivityLoyaltyModel(
      this.typeCommercialActivityId,
      null,
      footerData.statusDomainId,
      this.description,
      footerData.startTime,
      footerData.endTime,
      footerData.orderLimit,
      footerData.periodLimit,
      footerData.numberDays,
      '',
      0,
      this.transformDataCommercialProductLoyalty(this.valueTable),
      [],
      footerData.dataRanges,
      this.prepareChannelsToSave()
    );
    return promotionalActivityModel;
  }

  private transformDataCommercialProductLoyalty(products: Array<ITableGroupItem>) {

    let newProduct: IProductGroupList[] = [];
    newProduct = products.map((product) => {
      return {
        'commercialActivityId': 0,
        'purchaseUnit': product.purcheseUnit,
        'isGroup': true,
        'groupByJson': product.groupByJson,
        'id': parseInt(product.id, 10),
        'purchaseBaseDetails': this.transformDataPurchaseBase(product.subOptions, product)
      };
    });

    return newProduct;
  }

  private transformDataPurchaseBase(subOptions: Array<ISubGroupItem>, product: ITableGroupItem): Array<IProductList> {
    let newSubOptions: IProductList[] = [];

    newSubOptions = subOptions.map((subOption) => {
      const extraOptionValue = this.getExtraOptionsValue(subOption);
      return {
        'purchaseBaseId': parseInt(product.id, 10),
        'materialCode': subOption.sku,
        'materialDescription': subOption.descSku,
        'zofeDescription': subOption.prodType,
        'measurementUnitCode': MeasurementUnit.PIECE,
        'purchaseUnit': extraOptionValue.purcheseUnit,
        'porcent': 0,
        'discountValue': extraOptionValue.discountValue,
        'id': parseInt(subOption.id, 10)
      };
    });

    return newSubOptions;
  }

  private getExtraOptionsValue(subOption: ISubGroupItem): { purcheseUnit: number, discountValue: number } {
    const extraOptionValue = {
      purcheseUnit: 0,
      discountValue: 0
    };

    if (subOption instanceof SubLoyalty) {
      extraOptionValue.purcheseUnit = subOption.purcheseUnit != null ? subOption.purcheseUnit : 0;
    }
    return extraOptionValue;
  }

  public toggleTable() {
    this.showTable = !this.showTable;
  }

  public toggleTableFilter() {
    this.showTableFilter = !this.showTableFilter;
  }


  public showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }
  public getSelectList(object: any) {
    if (object.check === true || object.check.checked === true) {
      this.arrayDeleteSelect.push(object);
    } else {
      const index = this.arrayDeleteSelect.findIndex((elm) => elm.sku === object.sku);
      if (index !== -1) {
        this.arrayDeleteSelect.splice(index, 1);
      }
    }
  }
  public getSelectGroup(object: any) {
    if (object.check === true) {
      this.arrayDeleteSelectGroup.push(object);
    } else {
      const index = this.arrayDeleteSelectGroup.findIndex((elm) => elm.descGroup === object.descGroup);
      if (index !== -1) {
        this.arrayDeleteSelectGroup.splice(index, 1);
      }
    }
  }
  public deleteAllAction() {
    Util.confirmDelete().then(async (result: SweetAlertResult) => {
      if (result.value) {
        const skuIds: number[] = [];
        this.valueTable.forEach((object) => {
          this.arrayDeleteSelect.forEach((objectselect) => {
            const index = object.subOptions.findIndex((elm) => elm.sku === objectselect.sku);
            if (index !== -1) {
              const idSKU = object.subOptions.find((f) => f.sku === objectselect.sku).id;
              if (parseInt(idSKU) > 0) {
                skuIds.push(parseInt(idSKU, 10));
                this.productGroupFilterListPromotion.forEach((item) => {
                  const products = item.products.filter((i) => i.id !== parseInt(idSKU));
                  item.products = [...products];
                  return { ...item };
                }
                );
              }
              object.subOptions.splice(index, 1);
            }
          });
          object.purcheseUnit = this.sumPurchaseUnit(object.subOptions);
        });
        this.deleteMaterialDB(skuIds);

        if (this.arrayDeleteSelectGroup.length > 0) {
          this.arrayDeleteSelectGroup.forEach((objectselect) => {
            const indexGroup = this.valueTable.findIndex((elm) => elm.descGroup === objectselect.descGroup);
            if (indexGroup !== -1) {
              this.valueTable.splice(indexGroup, 1);
            }
          });
        }

        let groupIdToDelete = this.productGroupFilterListPromotion.filter((item) => item.products.length !== 0).map((i) => i.id);
        if (groupIdToDelete.length > 0) {
          this.managePromotionActService.deleteProductGroupsList(groupIdToDelete.join(',')).toPromise();
          this.arrayDeleteSelectGroup.forEach((x) => {
            this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion.filter((item) => item.id !== Number(x.id))];
          });
        }

        this.valueTable.forEach((elm) => {
          if (elm.subOptions.length === 0) {
            let index = this.valueTable.indexOf(elm);
            this.valueTable.splice(index, 1);
          }
        });
      }
      this.arrayDeleteSelectGroup = [];
      this.arrayDeleteSelect = [];
    });
  }

  public deleteMaterialDB(skuIds: number[]) {
    if (skuIds.length > 0) {
      this.managePromotionActService.deleteProductList(skuIds.join(',')).toPromise();
      let groupIdToDelete = this.productGroupFilterListPromotion.filter((item) => item.products.length === 0).map((i) => i.id);
      if (groupIdToDelete.length > 0) {
        this.managePromotionActService.deleteProductGroupsList(groupIdToDelete.join(',')).toPromise();
        this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion.filter((item) => item.products.length !== 0)];
      }
    }
  }

  public deleteGroupAction(groupToDelete: any) {
    Util.confirmDelete().then(async (result: SweetAlertResult) => {
      if (result.value) {
        const groupIndexFount = this.valueTable.findIndex((gr: any) => gr.contentGroup === groupToDelete.contentGroup);
        this.valueTable.splice(groupIndexFount, 1);
        await this.managePromotionActService
          .deleteProductGroupsList(`${groupToDelete.id}`)
          .toPromise();
          this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion.filter((item) => item.id !== Number(groupToDelete.id))];
      }
      this.arrayDeleteSelect = [];
    });

  }

  /**
   * @description This method for event for filter.
   *
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  eventClickFilters($event: any): void {
    if (this.isPurchaseBase && this.valueTable.length > 0) {
      let productArray = [];
			let productCero = [];
			this.valueTable.forEach((elm) => {
				elm.subOptions.forEach((itm) => {
					this.valueTable.forEach((x) => {
						productArray = x.subOptions.filter((y) => y.sku === itm.sku);
					});
				});
			});

			this.valueTable.forEach((elm) => {
				elm.subOptions.forEach((itm) => {
					if (itm.percentDiscountValue  === '0') {
						productCero.push(itm);
					}

				});
			});

			if(productArray.length > 0){
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
					language === 'es-ES' ? SweetAlert.mesageDiscountfilter: SweetAlert.mesageDiscountfilterTranslate);
				return;
			}

			if(productCero.length > 0){
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
					language === 'es-ES' ? SweetAlert.mesageFilterDiscount : SweetAlert.mesageFilterDiscountTranslate);
				return;
			}
    }

    if ($event.advancedFiltersObjectList.length > 0) {
      this.spinner.show();
      this.advancedFiltersObjectList = $event.advancedFiltersObjectList;
      const newFilterModel = new FilterDataModel(
        JSON.stringify($event.advancedFiltersObjectList),
        environment.conditionTable,
        environment.conditionClassCode,
        false
      );
      this.getMaterialsByFilters(newFilterModel);
    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ?  ManagePromotionalActivitiesMessage.NOT_FILTERS : ManagePromotionalActivitiesMessage.NOT_FILTERS_TRANSLATE);
    }
  }

  /**
   * @description This method is to group by hierarchy the purchase base
   * and promotional products list
   *
   * @param listProducts
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private groupProductsToShow(listProducts: IProductList[]): IProductGroupList[] {
    const groups: IProductGroupList[] = [];
    if (listProducts.length > 0) {
      listProducts.forEach((product: IProductList) => {
        const groupFound = groups.find((fGroup) => fGroup.groupName === product.hierarchy);
        if (groupFound) {
          groupFound.products.push(product);
        } else {
          const filterFound = this.advancedFiltersObjectList.find((fFilter) => fFilter.hierarchyFilter === product.hierarchy);
          let newGroup: ProductGroupListModel = null;
          newGroup = new ProductGroupListModel(
            product.hierarchy, // groupName
            0, // comercialActivityId
            0, // purchaseUnit
            MeasurementUnit.PERCENT, // measurementUnitCode
            true, // isGroup,
            JSON.stringify(filterFound), // groupByJson
            0, // id
            [product] // producst
          );
          groups.push(newGroup);
        }
      });
    }

    return groups;
  }

  /**
  * @description This method for get the filters.
  *
  * @author Diego Mauricio Cortés
  * @sprint 8
  * @param filters
  */
  private getMaterialsByFilters(filters: FilterDataModel): void {
    this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
      async (response) => {
        if (response.data.length > 0) {
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' 
              ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`: `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`);

          const listProducts: IProductList[] = response.data.map((item: any) => {
            return {
              product: item.product,
              hierarchy: item.hierarchy,
              purchaseBaseId: 0,
              materialCode: item.materialCode,
              measurementUnitCode: MeasurementUnit.PERCENT,
              purchaseUnit: 0,
              porcent: 0,
              discountValue: 0,
              id: 0,
              currentPsp: item.currentPsp,
              zofeCode: item.zofeCode,
              zofeDescription: item.zofeDescription
            };
          });

          const temporalProducts = listProducts;
          const finalListProducts: IProductList[] = [];

          this.advancedFiltersObjectList.forEach((x) => {
            const product = temporalProducts.filter((y) => y.hierarchy === x.hierarchyFilter);

            if (product !== undefined) {
              product.forEach((z) => { finalListProducts.push(z); });
            }

          });

          const newGroups = this.groupProductsToShow(finalListProducts);
          let newProductGroupList: IProductGroupList[] = [];

          this.productGroupFilterListPromotion.forEach(async (x) => {
            newGroups.filter(async (g) => {
              if (x.groupByJson === g.groupByJson) {
                x.products = [...await this.managePromotionActService.getUnionProducts(x.products, g.products)];
              }
            });
          });

          newProductGroupList = await this.managePromotionActService.getUnionProductGroupList(this.productGroupFilterListPromotion, newGroups);

          const valueConfigurationTable = this.schematicResultsToTable(newProductGroupList);
          this.valueTable = valueConfigurationTable.valueTableConvert;
          this.subHeaderTable = valueConfigurationTable.valueSubHeaderTable;
          this.headersTable = valueConfigurationTable.valueHeadersTable;

        } else {
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE);
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  public factoryExtraOptions(type: any) {
    switch (type) {
      case 'Fidelización':
        return {
          headersTable: [
            {
              name: 'Unidad',
              attr: 'purcheseUnit',
              input: false,
              props: { max: 999999999999999, min: 0, type: 'number' },
              disable: false,
              readonly: true

            }
          ] as any[],
          subHeaderTable: [{
            name: 'Unidad',
            attr: 'purcheseUnit',
            input: true,
            props: { max: 9999999999999, min: 1, type: 'number' },
            onChange: (valueOne: any, currentObject: any, depend: number, attr: string) => {
              this.valueTable.forEach((m) => {
                m.purcheseUnit = this.sumPurchaseUnit(m.subOptions);
              });
            }
          }],
          Interface: Loyalty,
          SubInterface: SubLoyalty
        };
      default:
        break;
    }
  }

  public sumPurchaseUnit(childs: ISubGroupItem[]): number {
    let result = 0;

    childs.forEach((x) => {
      result += Number(x.purcheseUnit);
    });

    return result;
  }


  public schematicResultsToTable(groups: IProductGroupList[]) {

    const valueTableConvert: ITableGroupItem[] = [];
    let valueHeadersTable: IHeaderTableGroupItem[] = [];
    let valueSubHeaderTable: ISubHeaderTableGroupItem[] = [];


    const extraOptions = this.factoryExtraOptions(this.typeCommercialActivity);
    const InterfaceOption = extraOptions.Interface;
    const SubInterfaceOption = extraOptions.SubInterface;
    valueHeadersTable = [...this.headersTableConst, ...extraOptions.headersTable];
    valueSubHeaderTable = [...this.subHeaderTableConst, ...extraOptions.subHeaderTable];
    groups.forEach((group, index) => {
      let newGroup: ITableGroupItem = new InterfaceOption();

      newGroup = new InterfaceOption(
        group.id.toString(),
        `Grupo ${index + 1}`,
        group.measurementUnitCode ? group.measurementUnitCode : MeasurementUnit.PERCENT,
        group.groupByJson,
        group.groupName,
        this.schematicSubGroupToTable(group.products, SubInterfaceOption),
        group.purchaseUnit,
        false
      );


      valueTableConvert.push(newGroup);
    });

    return {
      valueTableConvert,
      valueHeadersTable,
      valueSubHeaderTable
    };
  }

  public schematicSubGroupToTable(subGroups: IProductList[], SubInterfaceOption: any) {
    return subGroups.map((subGroup) => {
      const newSubGroup: ISubGroupItem = new SubInterfaceOption();
      newSubGroup.descSku = subGroup.product;
      newSubGroup.id = subGroup.id.toString();
      newSubGroup.sku = subGroup.materialCode;
      newSubGroup.unitMeasure = '';
      newSubGroup.check = false;
      newSubGroup.currentPsp = subGroup.currentPsp;
      newSubGroup.prodType = subGroup.zofeDescription;

      if (newSubGroup instanceof SubLoyalty) {
        newSubGroup.purcheseUnit = subGroup.purchaseUnit;
      }

      return newSubGroup;
    });
  }

  /**
   *
   * @param $event
   */
  eventClickSaveBtn($event: ICommercialActivityFooter): void {

		this.documentValid = this.validateDocuments();

    if (this.validateForm() && this.validationsForPurchaseBaseProductList() && this.validationSkus()  && this.documentValid) {
      this.spinner.show();
      this.discountActivityEdit = this.prepareCommercialActivityLoyaltyToCreate($event);
      const isNotValid: boolean = this.validateTableInformation(this.valueTable, this.discountActivityEdit);
      this.prepareFiltersToEditProductsPromotionalActivity(this.discountActivityEdit);
      if (!isNotValid) {
        this.nextAssignClients = true;
      }
    }
  }

  validationsForPurchaseBaseProductList() : boolean{
		const GroupFilterListZero: boolean = this.validationsUnitZero();
		if(GroupFilterListZero){
      const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
        language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
        language === 'es-ES' ? SweetAlert.messageNoProductZero : SweetAlert.messageNoProductZeroTranslate);
			return false;
		}
		return true;
	}

  validationSkus() : boolean{
		if(this.valueTable.length === 0){
      const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
        language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate,
        language === 'es-ES' ? SweetAlert.messageSelectSKU : SweetAlert.messageSelectSKUTranslate);
			return false;
		}
		return true;
	}

  validationsUnitZero(): boolean {
    let cont = 0;
    this.valueTable.forEach((elm) => {
      elm.subOptions.forEach((x) => {
        const auxConvert: string = String(x.purcheseUnit);
        if (x.purcheseUnit === 0 || auxConvert === '0' || auxConvert === '') {
          cont += 1;
        }
      });
    });
    if (cont > 0) {
      return true;
    } else {
      return false;
    }
  }

  validateForm() {
		if (this.sectorCommercialActivityParam) {
			if ((this.sector.length > 0 && this.channel.length > 0)) {
				return true;
			}
      const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
        language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
        language === 'es-ES' ? `${CommercialActivityLoyaltyMessage.NO_CHANNEL_SECTOR}` : `${CommercialActivityLoyaltyMessage.NO_CHANNEL_SECTOR_TRANSLATE}`
			);
			return false;
		}
		return true;
  }

  validateTableInformation(valuTable: ITableGroupItem[], valueTableModel: CommercialActivityLoyaltyModel): boolean {
    let isNotValid = false;
    valuTable.forEach((value) => {
      const { unitMeasure, subOptions, descGroup } = value;

      if (!isNotValid) {
        subOptions.forEach((subOption) => {
          if (
            subOption instanceof SubDiscountPriceAdjustment &&
            (subOption.percentDiscountPriceAdjustment === '' &&
              subOption.valueDiscountPriceAdjustment === '')
          ) {
            isNotValid = true;
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_PRICE_ADJUSTMENT} por favor revisar el ${descGroup}`
              : `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_PRICE_ADJUSTMENT_TRANSLATE} please check the ${descGroup}`);
            this.spinner.hide();
            return;

          } else if (
            subOption instanceof SubDiscountFinancial &&
            subOption.percentageDiscountFinancial === ''
          ) {
            isNotValid = true;
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_FINANCIAL} por favor revisar el ${descGroup}`
              : `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_FINANCIAL_TRANSLATE} please check the ${descGroup}`);
            this.spinner.hide();
            return;

          } else if (
            subOption instanceof SubDiscountKind &&
            subOption.percentDiscountKind === ''
          ) {
            isNotValid = true;
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_KIND} por favor revisar el ${descGroup}`
              : `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_KIND_TRANSLATE} please check the ${descGroup}`);
            this.spinner.hide();
            return;

          } else {
            if (
              subOption instanceof SubDiscountValue &&
              subOption.percentDiscountValue === ''
            ) {
              isNotValid = true;
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                language === 'es-ES' ? `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_VALUE} por favor revisar el ${descGroup}`
                : `${CommercialActivityLoyaltyMessage.NO_DISCOUNT_VALUE_TRANSLATE} please check the ${descGroup}`);
              this.spinner.hide();
              return;
            }
          }
        });
      }
    });
    return isNotValid;
  }

  // #region Edit
  eventClickEditBtn($event: ICommercialActivityFooter): void {

    this.documentValid = this.validateDocuments();

    if (this.validateForm() && this.validationsForPurchaseBaseProductList() && this.validationSkus() && this.validationTope($event.orderLimit, $event.periodLimit)  && this.documentValid) {
      this.discountActivityEdit = this.prepareCommercialActivityLoyaltyToEdit($event);
      this.discountActivityEdit.commercialActivityChannels = this.prepareChannelsToSave();
      this.discountActivityEdit.commercialActivitySector = this.prepareSectorsToSave();
      this.discountActivityEdit.commercialActivityObservation = this.documentInfo;
      const isNotValid: boolean = this.validateTableInformation(this.valueTable, this.discountActivityEdit);
      this.prepareFiltersToEditProductsPromotionalActivity(this.discountActivityEdit);
      if (!isNotValid) {
        this.nextAssignClients = true;
      }
    }
  }

  validationTope(order: number, period: number): boolean{
		if(order == 0 || period == 0){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate ,
				language === 'es-ES' ? SweetAlert.messageTope : SweetAlert.mesageTopeTranslate);
			return false;
		}
		return true;
	}

  private prepareCommercialActivityLoyaltyToEdit(footerData: ICommercialActivityFooter): CommercialActivityLoyaltyModel {
    const promotionalActivityModel: CommercialActivityLoyaltyModel = new CommercialActivityLoyaltyModel(
      this.discountActivityEdit.typeCommercialActivityDomainId,
      null,
      footerData.statusDomainId,
      this.description,
      footerData.startTime,
      footerData.endTime,
      footerData.orderLimit,
      footerData.periodLimit,
      footerData.numberDays,
      this.filterClientJson,
      this.discountActivityEdit.id,
      this.transformDataCommercialProductLoyaltyEdit(this.valueTable),
      [],
      footerData.dataRanges,
      this.prepareChannelsToSave()
    );
    return promotionalActivityModel;
  }

  private transformDataCommercialProductLoyaltyEdit(groups: Array<ITableGroupItem>) {
    let newProduct: IProductGroupList[] = [];
    newProduct = groups.map((group) => {
      return {
        'commercialActivityId': this.discountActivityEdit.id,
        'purchaseUnit': group.purcheseUnit,
        'isGroup': true,
        'groupByJson': group.groupByJson,
        'id': parseInt(group.id, 10),
        'purchaseBaseDetails': this.transformDataPurchaseBase(group.subOptions, group)
      };
    });

    return newProduct;
  }

  /**
   * @description This method is to init the edit process
   *
   * @author Diego Mauricio Cortés
   * @sprint 10
   */
  private initEditLoyalty() {
    this.commercialActivityId = this.managePromotionActService.getCommercialActivityId();
    this.getLoyaltyToEdit();
  }

  /**
   * @description This method is to get the Loyalty data for edit
   *
   * @author Diego Mauricio Cortés
   * @sprint 10
   */
  private async getLoyaltyToEdit(): Promise<void> {
    this.spinner.show();
    this.loyaltyService.getPromotionalActivityById(this.commercialActivityId).subscribe(
      async (response: any) => {
        if (response.status) {
          this.commercialActStatVal = response.data.statusDomainId;
          let commercialActivityChannelsLocal: IChannel[] = response.data.commercialActivityChannels;
					let CommercialActivitySectorLocal: ICommercialActivitySector[] = response.data.commercialActivitySector;

					if(this.discountActivityEdit && this.discountActivityEdit.commercialActivityChannels&& this.discountActivityEdit.commercialActivitySector){
						commercialActivityChannelsLocal = this.discountActivityEdit.commercialActivityChannels;
						CommercialActivitySectorLocal = this.discountActivityEdit.commercialActivitySector;
					}

          this.discountActivityEdit = new CommercialActivityLoyaltyModel(
            response.data.typeCommercialActivityDomainId,
            response.data.typePromotionalActivityDomainId,
            response.data.statusDomainId,
            response.data.description,
            response.data.startTime,
            response.data.endTime,
            response.data.orderLimit,
            response.data.periodLimit,
            response.data.numberDays,
            response.data.filterClientJson,
            response.data.id,
            this.valueTable.length > 0 ? this.transformDataCommercialProductLoyalty(this.valueTable) : response.data.purchaseBases,
            [],
            this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges),
            commercialActivityChannelsLocal,
            response.data.isCalculate,
            response.data.commercialActivityCustomers,
            response.data.commercialActivityCustomerJson,
            CommercialActivitySectorLocal,
            response.data.totalClientsFilter,
            response.data.uidCalculateProcess,
            response.data.commercialActivityObservation
          );

          this.userCreation = new UserCreationModel(
            response.data.nameUser,
            response.data.user,
            response.data.emailUser,
            response.data.creationDate
          );

          this.channelTooltip = response.data.commercialActivityChannels;

          this.promotionEdit = new CommercialActivityFooterModel(
            this.discountActivityEdit.numberDays,
            this.discountActivityEdit.startTime,
            this.discountActivityEdit.endTime,
            this.discountActivityEdit.orderLimit,
            this.discountActivityEdit.periodLimit,
            this.discountActivityEdit.statusDomainId,
            this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges)
          );
          if (CommercialActivitySectorLocal) {
            this.sectorTooltip = response.data.commercialActivitySector;
            const sct = CommercialActivitySectorLocal.map((sctr: ICommercialActivitySector) => {
              return sctr.sectorCode;
            });
            this.sector = sct;
          }
          this.filterClientJsonToCalculate = response.data.commercialActivityCustomers !== null 
            ? JSON.stringify(response.data.commercialActivityCustomerJson) : response.data.filterClientJson;
          this.filterClientJson = response.data.filterClientJson;
          this.prepareDataEditPromotionalActivity(this.discountActivityEdit);
          this.preparePurchaseToCalculate();
          this.commercialActivityCustomers = response.data.commercialActivityCustomers;

          if (response.data.commercialActivityObservation && this.flagDocumentObservation)
          {
            this.observationEdit = response.data.commercialActivityObservation;
          }

          let typeCommercialActivityId = this.discountActivityEdit.typeCommercialActivityDomainId;
          if (typeCommercialActivityId)
          {
            await this.documentSupport.setRequiredDocumentInformation(typeCommercialActivityId);
            this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
          }

          this.verifyInvalidateData(response.data.statusDomainId, response.data.validationResult);

          if(this.isProcess == this.comercialActivityEnum.view 
            && (!this.filterClientJson || this.filterClientJson == '') 
            && (!this.discountActivityEdit.commercialActivityCustomerJson || this.discountActivityEdit.commercialActivityCustomerJson[0].content == null))
            {
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                language === 'es-ES' ? 'Por favor asignar clientes.'
                : 'Please assign clients.');
              this.showFieldsCreatePromotion.commercialActivitySendValidate = false;
          }
          this.isCalculate = this.discountActivityEdit.isCalculate;          
          this.spinner.hide();
        }
      }, (error: any) => {
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, `${error}`);
      });
  }

  /**
 * @description This method is to validate if the commercial activity is invalid
 *
 * @param statusDomainId nunmber of status domaind id from the commercial activity
 * @param validationResult Json string with the validation result
 * @sprint 21
 * @author Diego Mauricio Cortés
 */
  verifyInvalidateData(statusDomainId: number, validationResult: string): void {
    if (CommercialActivityStatus.Invalid === statusDomainId && validationResult) {
			try
			{
      const validation: any = JSON.parse(validationResult);
      const invalidation: CommercialActivityInvalidationDataDetail = { ...validation };
      this.invalidationDataDetail = [];
      invalidation.result.forEach((rsp) => {
        let skus = '';
        let clients = 0;
        if (!rsp.clients.status) {
          clients = rsp.clients.repeated;
        }
        if (!rsp.material.status) {
          skus = rsp.material.materialCodesrepeated.join();
        }
        if (clients > 0 || skus !== '') {
          this.invalidationDataDetail.push({
            id: rsp.id,
            clients: clients,
            skus: skus
          });
        }
      });
      this.displayValidationsDetails = true;
    }
    catch(error)
    {
      if (this.isInitialError)
      {
        const language: string = sessionStorage.getItem('language');
        const msg = language === 'es-ES' ? SweetAlert.messageErrorValidationActivity
                      : SweetAlert.messageErrorValidationActivityTranslate;

        Util.optionalMessage(msg).then((result : SweetAlertResult) =>{
          if(result.value){
            this.eventClickSendValidateBtn();
          }else{
            this.displayValidationsDetails = false;
            this.isInitialError = false;
          }
        })
      }
     }
   }
  }

		/**
		 * @description This method is to verify if the user wants update the commercial activity to status registered or deleted
		 * @param opc boolean with the user select
		 * @sprint 21
		 * @author Diego Mauricio Cortes
		 */
		registryInvalidateCommercialACtivity(opc: boolean): void {
			this.displayValidationsDetails = false;
			this.spinner.show();
			if (opc) {
				this.discountActivityEdit.statusDomainId = CommercialActivityStatus.Registered;
				this.loyaltyService.putPromotionalActivity(
					this.discountActivityEdit,
					this.commercialActivityId
				).subscribe(
					(response) => {
						if (response.status) {
							SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
							this.router.navigate(['auth/manage-promotional-activities']);
						} else {
							SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
						}
						this.spinner.hide();
					}, (error: HttpErrorResponse) => {
						this.spinner.hide();
						SweetAlertMessageHelpers.exception(error);
					});
			} else {
				this.managePromotionActService.delete(`${this.commercialActivityId}`)
				.subscribe(
					(response) => {
						this.spinner.hide();
						if (response.status) {
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconInfo,
                language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
							this.router.navigate(['auth/manage-promotional-activities']);
						} else {
							SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
						}
					},
					(error: HttpErrorResponse) => {
						this.spinner.hide();
						SweetAlertMessageHelpers.exception(error);
					}
				);
			}

  }

  /**
   * @description This method is to prepare sku's to send to calculate
   * @sprint 18
   * @author Diego Cortes
    */
  private preparePurchaseToCalculate() {
    if (this.discountActivityEdit.isCalculate) {
      this.isProcess = this.commercialActivityEnum.view;
    }
    if (this.discountActivityEdit.purchaseBases) {
      this.discountActivityEdit.purchaseBases.forEach((object: any) => {
        if (object.purchaseBaseDetails) {
          const dt = object.purchaseBaseDetails.map((obj: any) => {
            this.totalCostSale += obj.costSale;
            this.totalCostIncome += obj.costIncome;
            return {
              id: obj.id,
              purchaseBaseId: obj.purchaseBaseId,
              materialCode: obj.materialCode,
              materialDescription: obj.materialDescription,
              measurementUnitCode: obj.measurementUnitCode,
              measurementUnitDescription: obj.measurementUnitDescription,
              purchaseUnit: obj.purchaseUnit,
              porcent: obj.porcent,
              discountValue: obj.discountValue,
              product: obj.product,
              hierarchy: obj.hierarchy,
              currentPsp: obj.currentPsp,
              depositValue: obj.depositValue,
              ebitdaPercentage: obj.ebitdaPercentage,
              ebitda: obj.ebitda,
              weightedDiscountPercentage: obj.weightedDiscountPercentage,
              costSale: obj.costSale,
              costIncome: obj.costIncome,
              factor: 1.235, // object.purchaseUnit,
              perDescount: obj.perDescount || 0,
              promotionValue: obj.promotionValue || 0,
              weightedValue: obj.weightedValue || 0,
              kpiLiberationSku: obj.kpiLiberationSku || 0,
            };
          });
          this.purchaseBaseToCalculate = this.purchaseBaseToCalculate.concat(dt);
        }
      });
    }
  }

  /**
   * @description This method is to prepare discount data to show edit
   *
   * @param discount
   *
   */
  private prepareDataEditPromotionalActivity(discount: CommercialActivityLoyaltyModel): void {
    this.description = discount.description;
    this.typeCommercialActivity = this.findTypeCommercialActivityName(discount.typeCommercialActivityDomainId);
    this.prepareFiltersToEditProductsPromotionalActivity(discount);
    this.channel = this.prepareChannelsToShowEditPromotionalActivity(discount.commercialActivityChannels);
    this.preparePurchaseBaseProductsToEditPromotionalActivity(discount);
  }

  private prepareFiltersToEditProductsPromotionalActivity(promotion: CommercialActivityLoyaltyModel): void {
    if (promotion.purchaseBases) {
      const filters: IHierarchyFiltersMaterials[] = promotion.purchaseBases.map((element) => {
        return JSON.parse(element.groupByJson);
      });
      this.editFiltersObjectList = [...filters];
    }
  }

  private prepareDataRangeCommercialActivityToEdit(dataRange: ICommercialActivityLoyalty[]): IDataRange[] {
    let dataRangeRet: IDataRange[] = [];
    if (dataRange) {
      if (dataRange.length > 0) {
        dataRangeRet = dataRange.map((data: any) => {
          const start = new Date(data.dateStart);
          const end = new Date(data.dateEnd);
          return {
            dateStart: data.dateStart,
            dateEnd: data.dateEnd,
            cantDays: Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)),
            id: data.id,
            commercialActivityId: data.commercialActivityId
          };
        });
        this.initialDate = dataRangeRet[0].dateStart;
      }
    }
    return dataRangeRet;
  }

  /**
   * @description This method is to prepare selected channels
   *
   * @param channels
   * @author Diego Mauricio Cortés
   * @sprint 11
   */
  private prepareChannelsToShowEditPromotionalActivity(channels: IChannel[]): string[] {
    if (channels) {
      return channels.map((ch) => {
        return `${ch.channelCode}`;
      });
    }
    return [];
  }

  /**
   * @description This method is to prepare group products to edit promotion activity
   *
   * @param promotion
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private preparePurchaseBaseProductsToEditPromotionalActivity(discount: CommercialActivityLoyaltyModel): void {
    if (discount.purchaseBases) {
      this.productGroupFilterListPromotion = discount.purchaseBases.map((group: any, index: number) => {
        return {
          groupName: `Grupo ${(index + 1)}`,
          cgroupName: `Grupo ${(index + 1)}`,
          commercialActivityId: group.commercialActivityId,
          purchaseUnit: group.purchaseUnit,
          measurementUnitCode: group.purchaseBaseDetails ? group.purchaseBaseDetails[0].measurementUnitCode : '',
          isGroup: true,
          groupByJson: group.groupByJson,
          id: group.id,
          products: this.prepareProductsToEditPromotionalActivity(group)
        };
      });

      const valueConfigurationTable = this.schematicResultsToTable(this.productGroupFilterListPromotion);
      this.valueTable = valueConfigurationTable.valueTableConvert;
      this.subHeaderTable = valueConfigurationTable.valueSubHeaderTable;
      this.headersTable = valueConfigurationTable.valueHeadersTable;


    }
  }

  /**
   * @description This method is to prepare purchase base products
   * list to edit promotional activity
   *
   * @param group
   * @author Diego Mauricio Cortés
   * @sprint 11
   */
  private prepareProductsToEditPromotionalActivity(group: any): IProductList[] {
    let products: IProductList[] = [];
    if (group.purchaseBaseDetails) {
      products = group.purchaseBaseDetails.map((product: any) => {
        const productRet = { ...product };
        productRet.product = product.materialDescription;
        productRet.hierarchy = JSON.parse(group.groupByJson).hierarchyFilter;
        return productRet;
      });
    }
    return products;
  }
  // #endregion

  /**
   * @description This method is to find the name of commercial activity selected
   *
   * @param id
   * @author Diego Mauricio Cortés
   * @sprint 11
   */
  private findTypeCommercialActivityName(id: number): string {
    const typeCommercialActivity = this.objectTypeCommercialActivity.find((type) => type.id === id);
    return typeCommercialActivity.description;
  }

  /**
   * @description This method is to initialize the create a new discount
   *
   * @author Diego Mauricio Cortés
   * @sprint 11
   */
  private async initCreateDiscount() {
    const descriptionIn = this.managePromotionActService.getDescription();
    if (descriptionIn !== undefined && descriptionIn !== null && descriptionIn !== '') {
      this.description = descriptionIn;
      this.typeCommercialActivityId = this.managePromotionActService.getTypeCommercialActivity();
      this.typeCommercialActivity = this.findTypeCommercialActivityName(this.typeCommercialActivityId);

			if (this.typeCommercialActivityId)
			{
				await this.documentSupport.setRequiredDocumentInformation(this.typeCommercialActivityId);
				this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
			}

    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'La descripción es requerida. Porfavor intentelo de nuevo.'
        : 'Description is required. Please try again.');
      this.router.navigate(['auth/manage-promotional-activities']);
    }
  }

  /**
   * @description This method is to prepare selected channels to save promotional activity
   *
   * @author Diego Mauricio Cortés
   * @sprint 11
   */
  private prepareChannelsToSave(): IChannel[] {
    const channels = this.channel.map((chl: number) => {
      return {
        commercialActivityId: 0,
        channelCode: chl
      };
    });
    return channels;
  }

	private prepareSectorsToSave(): ICommercialActivitySector[] {
		if (this.sector) {
			const sectors: ICommercialActivitySector[] = this.sector.map((sct: string) => {
				return {
					id: 0,
					commercialActivityId: this.commercialActivityId || 0,
					sectorCode: sct
				};
			});
			return sectors;
		}
	}

  /**
   * @description This method is to catch the event to send promotional activity to validate.
   *
   * @param $event
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  eventClickSendValidateBtn(): void {
    if (!this.validateForm()) {
      return;
    }
    this.spinner.show();
    const sendQueue = new CommercialACtivityQueueValidationModel(
      this.commercialActivityId,
      CommercialActivityStatus.InValidation,
      null,
      Module.PromotionalActivity,
      this.commercialActivityId,
      this.typeCommercialActivity,
      moment(new Date()).format('YYYY-MM-DD')
    );
    this.managePromotionActService.postCommercialActivityQueueValidated(sendQueue).subscribe(
      (response) => {
        if (response.status) {
          this.router.navigate(['auth/manage-promotional-activities']);
        } else {
          const listError = [];
          listError.push(response.message);
          SweetAlertMessageHelpers.listWarningMessageShow(SweetAlert.titleAlertError, listError);
        }
        this.spinner.hide();
      }, (error) => {
        console.error('ERROR', error);
        this.spinner.hide();
      }
    );
  }

  eventSendToCalculate($event: string) {
    this.updateCalculatedData($event);
    this.spinner.show();
    this.discountActivityEdit.isCalculate = true;
    this.discountActivityEdit.statusDomainId = CommercialActivityStatus.Pending;
    this.discountActivityEdit.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate();
    const updateModel: CommercialActivityStatusModel = new CommercialActivityStatusModel(this.discountActivityEdit.id, CommercialActivityStatus.Pending, '');
    this.loyaltyService.putPromotionalActivity(this.discountActivityEdit, this.discountActivityEdit.id).subscribe(
      (response) => {
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.success(ManagePromotionalActivitiesMessage.OK,
          language === 'es-ES' ? ManagePromotionalActivitiesMessage.CALCULATE_PROCESS_STARTED : ManagePromotionalActivitiesMessage.CALCULATE_PROCESS_STARTED_TRANSLATE);
        this.managePromotionActService.postCommercialActivityUpdateStatus(updateModel).toPromise();
        this.router.navigate(['/auth/manage-promotional-activities']);
        this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      });
  }

  /**
   * @description: This method is to update calculated data in skus
   *
   * @param responseAnal: Aanalitics calculate process response
   * @sprint 20
   */
  private updateCalculatedData(responseAnal: any) {
    this.discountActivityEdit.purchaseBases.forEach((prch: any) => {
      prch.purchaseBaseDetails.forEach((mat: any) => {
        const elm = responseAnal.purchaseBaseCalculate.filter((elem: any) => elem.materialCode === mat.materialCode);
        if (elm.length > 0) {
          mat.perDescount = elm[0].perDescount;
          mat.ebitda = elm[0].ebitda;
          mat.ebitdaPercentage = elm[0].ebitdaPercentage;
          mat.costIncome = elm[0].costIncome;
          mat.promotionValue = elm[0].promotionValue;
          mat.weightedValue = elm[0].weightedValue;
          mat.kpiLiberationSku = elm[0].kpiLiberationSku;
        }
      });
    });
  }

  /**
   * @description This method is to save promotional activity whit customers data
   *
   * @author Diego Mauricio Cortés
   * @sprint 11
   */
	 eventClickSendDataClients($event: any): void {
		if ($event.jsonFilterClient || $event.listClient) {
			this.spinner.show();
			this.discountActivityEdit.filterClientJson = $event.jsonFilterClient;
			this.discountActivityEdit.commercialActivityCustomers = this.prepareCommercialActivityCustomerCreate($event.listClient);
			this.discountActivityEdit.isCalculate = false;
			this.discountActivityEdit.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate();
			this.discountActivityEdit.totalClientsFilter = $event.totalClients;
			this.discountActivityEdit.commercialActivityObservation= this.documentInfo;
			this.prepareCommercialActivitiesDocumentsToCreate();

			this.loyaltyService.postPromotionalActivity(this.discountActivityEdit).subscribe(
				(response) => {
					if (response.status) {
						SweetAlertMessageHelpers.showMessage(this.isProcess === this.commercialActivityEnum.update ? TypeMessage.Update : TypeMessage.Success);
						this.router.navigate(['auth/manage-promotional-activities']);
					} else {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
					}
					this.spinner.hide();
				}, (error: HttpErrorResponse) => {
					this.spinner.hide();
					SweetAlertMessageHelpers.exception(error);
				});
		} else {
      const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'El filtro de clientes es obligatorio'
        : 'The customer filter is mandatory');
		}
  }

	prepareCommercialActivitiesDocumentsToCreate() {
		this.documentInfo[0].commercialActivityId = this.commercialActivityId;
	}

  /**
   * @description This method is to prepare commercial activity sectors to create
   * @sprint 15
   * @author Diego Mauricio Cortes
   * @returns ICommercialActivitySector object list
   */
  private prepareCommercialActivitiesSectorsToCreate(): ICommercialActivitySector[] {
    let rsp: ICommercialActivitySector[] = [];
    rsp = this.sector.map((sct: string) => {
      return {
        id: 0,
        commercialActivityId: this.discountActivityEdit.id || 0,
        sectorCode: sct
      };
    });
    return rsp;
  }

  /**
   * @description This method is to validate the selected sector combinations
   *
   * @sprint 17
   * @author Diego Mauricio Cortés
   */
  selectSectorValidate(): void {
		if (this.sector.length > 1)
		{
			this.sector.forEach((x:string) => {
				if (this.objectSectorList.find((y) => y.value === x && !y.crossActive))
				{
					const inx = this.sector.findIndex((z:string) => z === x)
					this.sector.splice(inx,1);
				}
			});
		}    
  }

  /**
   * @description This method is to prepare customers data to create promotional activity
   *
   * @param listClient
   * @author Diego Mauricio Cortés
   * @sprint 11
   */
  private prepareCommercialActivityCustomerCreate(listClient: any[]): ICustomer[] {
    let customers: ICustomer[] = [];

    if (listClient !== null) {
      customers = listClient.map((client) => {

        return {
          commercialActivityId: client.commercialActivityId === undefined ? 0 : client.commercialActivityId,
          clientCode: client.clientCode === undefined ? client : client.clientCode,
          id: 0,
        };
      });
    }
    return customers;
  }

  eventClickSendDataEditClients($event: any): void {
    this.spinner.show();
    this.discountActivityEdit.filterClientJson = $event.jsonFilterClient;
    this.discountActivityEdit.commercialActivityCustomers = this.prepareCommercialActivityCustomerCreate($event.listClient);
    this.discountActivityEdit.isCalculate = false;
    this.discountActivityEdit.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate();
    this.discountActivityEdit.totalClientsFilter = $event.listClient == null ? $event.totalClients : $event.listClient.length;
		this.prepareCommercialActivitiesDocumentsToCreate();
		this.discountActivityEdit.commercialActivityObservation = this.documentInfo;
    this.spinner.show();
    this.loyaltyService.putPromotionalActivity(this.discountActivityEdit, this.discountActivityEdit.id).subscribe(
      (response) => {
        if (response.status) {
          SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
          this.router.navigate(['auth/manage-promotional-activities']);
        } else {
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
        this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      });
  }

  eventClickBackClients(): void {
    this.observationEdit = this.documentInfo;
    if (this.isProcess === this.commercialActivityEnum.update) {
      this.nextAssignClients = false;
      this.editFiltersObjectList = [...this.editFiltersObjectList];
      this.flagDocumentObservation = false;
      this.promotionEdit = { ...this.promotionEditFooter };
      this.getLoyaltyToEdit();
    } else if (this.isProcess === this.commercialActivityEnum.view) {
      this.getLoyaltyToEdit();
      this.nextAssignClients = false;
    }
    else {
      this.nextAssignClients = false;
      this.editFiltersObjectList = [...this.editFiltersObjectList];
      this.promotionEdit = { ...this.promotionEditFooter };
    }
  }

  public prepareFooterForEvent($event: ICommercialActivityFooter) {
    this.promotionEditFooter = new CommercialActivityFooterModel(
      $event.numberDays,
      $event.startTime,
      $event.endTime,
      $event.orderLimit,
      $event.periodLimit,
      $event.statusDomainId,
      $event.dataRanges
    );
  }

	validateDocuments():boolean {
		let validDocument:boolean = true;
		this.documentInfo = this.documentSupport.getDocumentInformation();
		const language: string = sessionStorage.getItem('language');
	
    let fileName = this.documentInfo[0].file ?? this.documentSupport.getNameFile();

    if((this.documentFieldsRequired?.document && !fileName) && 
		(this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim()))
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE_TRANSLATE);
				validDocument = false;
			
			return;
		}
    
		if (this.documentFieldsRequired?.document && !fileName)
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE_TRANSLATE);
				validDocument = false;
		}
	
		if (this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim())
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_TRANSLATE);
				validDocument = false;
		}	
		return validDocument;
	}	

}
