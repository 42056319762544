import { Injectable } from '@angular/core';
import { HttpService } from '@app/@shared/services/http.service';
import { CountrySocietyRegionRequestModel } from './models/country-society-region-request.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ICountrySocietyRegion } from './interfaces/country-society-region.interface';

@Injectable({
    providedIn: 'root'
  })
  export class RegionService {
    private readonly httpService: HttpService;
    constructor(httpService: HttpService) {
      this.httpService = httpService;
     }

    /**
     * @description This method is to get all the profiles.
     * @author djgil
     * @param none
     * @returns Observable<IResponseService<IProfile[]>>
     * @sprint 1
     */
    public getAllCountrySocietyRegion(): Observable<IResponseService<ICountrySocietyRegion[]>> {
      const response$: Observable<IResponseService<ICountrySocietyRegion[]>> =
       this.httpService
        .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_country_society_region`)
        .pipe(
          map((response: HttpResponse<IResponseService<ICountrySocietyRegion[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

    /**
     * @description This method is to create CountrySocietyRegion.
     * @author djgil
     * @param params
     * @returns Observable<ResponseService>
     * @sprint 1
     */
    public postCountrySocietyRegion(body: CountrySocietyRegionRequestModel): Observable<IResponseService<boolean>> {
        const response$: Observable<IResponseService<boolean>> =
         this.httpService
        .post(
            `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/country_society_region`,
            body
        )
        .pipe(
            map((response: HttpResponse<IResponseService<boolean>>) => {
            return response.body;
            })
        );
        return response$;
    }

    /**
   * @description This method is to update CountrySocietyRegion.
   * @author djgil
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 1
   */
     public putCountrySocietyRegion(id: number, body: CountrySocietyRegionRequestModel): Observable<IResponseService<number>> {
      const response$: Observable<IResponseService<number>> =
      this.httpService
        .put(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/country_society_region/${id}`,
          body
        )
        .pipe(
          map((response: HttpResponse<IResponseService<number>>) => {
            return response.body;
          })
        );
      return response$;
    }

    /**
     * @description This method is to delete a CountrySocietyRegion.
     * @author djgil
     * @param params
     * @returns Observable<ResponseService>
     * @sprint 1
    */
    public deleteLogicCountrySocietyRegion(id: number): Observable<IResponseService<boolean>> {
      const response$: Observable<IResponseService<boolean>> =
       this.httpService.delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/country_society_region/logic/${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<boolean>>) => {
            return response.body;
          })
        );
      return response$;
    }

     /**
     * @description This method is to get all the Region by Society code.
     * @author Priscy Antonio Reales
     * @param code
     * @returns Observable<IResponseService<ICountrySocietyRegion[]>>
     * @sprint 4
     */
      public getAllRegionBySociety(code : string): Observable<IResponseService<ICountrySocietyRegion[]>> {
        const response$: Observable<IResponseService<ICountrySocietyRegion[]>> =
         this.httpService
          .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/country_society_region_by_society_code/${code}`)
          .pipe(
            map((response: HttpResponse<IResponseService<ICountrySocietyRegion[]>>) => {
              return response.body;
            })
          );
        return response$;
      }
  }