<div class="row h-100 pstn_inicio_roles align-middle">
  <div class="container align-self-center">


    <div class="selectedProfile" *ngIf="selectedProfileShow === true">
      <div class="imgProfile">
        <img src="assets/images/admi.png" alt="" style="object-fit: cover;" >
      </div>
      <div class="selectedName">
        <h6>{{profile}}</h6>
      </div>
    </div>   

    <!-- Modulos -->
    <div *ngIf="isModule == true;">
      <div class="row actividad mt-3">
        <div *ngFor="let item of listModule"
             class="col mb-3 itmactividad" (click)="onModule(item.id)">
          <i [class]="item.icon" aria-hidden="true"></i>
          <h3><strong>{{'Menu.MenuMain.menu-'+item.id | translate}}</strong></h3>
          <!-- <div *ngIf="item.code!=='AD' && item.total>0"
               class="notificaciones">
            <p>{{ 'Notification' | translate }}</p>
            <p class="cont">{{item.total}}+</p>
          </div> -->
        </div>
      </div>
    </div>    
  </div>
</div>

<div class="logoinicio"><img src="assets/images/logoinicio@2x.png" alt=""></div>