import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';

import { DomainService } from '@app/@shared/services/domain.service';
import { ProfileService } from '../../../administration/profile/profile.service';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { RegionService } from '@app/@modules/administration/region/region.service';
import { ChannelService } from '@app/@modules/administration/channel/channel.service';
import { CreateService } from '../create/create.service';

import { SweetAlertMessageHelpers } from '../../../../@shared/helpers/sweet-alert-message.helper';

import { AssociateService } from '../associate/associate.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegexCommon } from '@app/@shared/enums/regex.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { Operator } from '../../../../@shared/enums/operator.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { MessageService } from 'primeng/api';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { Router } from '@angular/router';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { IProfile } from '@app/@modules/administration/profile/interfaces/profile.interface';
import { ICountrySocietyRegion } from '@app/@modules/administration/region/interfaces/country-society-region.interface';
import { HierarchyReleaseModel } from './models/hierarchy-release.model';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ApplicationConstants } from '@app/app.constants';
import { HierarchyReleaseService } from '../hierarchy-release.service';
import { HierarchyReleaseAssociateModel } from './models/hierarchy-release-associate.model';
import { DomainTypeModel } from '@app/@modules/administration/typology/models/domain-type.model';
import { HierarchyReleaseCreateModel } from './models/hierarchy-release-create.model';
import { HierarchyReleaseAssociateCreateModel } from './models/hierarchy-release-associate-create.model';
import { HierarchyRealeseUpdateModel } from './models/hierarchy-realese-update.model';
import { HierarchyRealeseAssociateUpdateModel } from './models/hierarchy-release-associate-update.model';
import { SweetAlertResult } from 'sweetalert2';
import { Util } from '@app/@shared/util';
import { ProcessHierarchyReleaseEnum } from '@app/@shared/enums/hierarchy-release.enum';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
  styles: [`
      @media screen and (max-width: 960px) {
          :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
              display: flex;
          }
      }

    `],
  providers: [MessageService]
})

export class CreateComponent implements OnInit {
  private readonly router: Router;
  private readonly fb: FormBuilder;
  private readonly spinner: NgxSpinnerService;
  private readonly coreService: CoreMasterService;
  private readonly domainService: DomainService;
  private readonly profileService: ProfileService;
  private readonly regionService: RegionService;
  private readonly channelService: ChannelService;
  private readonly hierarchyReleaseService: HierarchyReleaseService;
  private readonly createServices: CreateService;
  private readonly associateService: AssociateService;
  private readonly messageService: MessageService;
  private readonly translate: TranslateService;
  private readonly i18nService: I18nService;

  public formHierarchy: FormGroup;
  public objectCountryList: ICombo[];
  public objectSocietyList: ICombo[];
  public objectProcessList: ICombo[];
  public objectSubprocess: IDomain[];
  public objectUnitMeasurementList: ICombo[];
  public objectOperatorListStart: ICombo[];
  public objectProfileList: ICombo[];
  public objectOperatorListEnd: ICombo[];
  public objectRegionList: ICombo[];
  public objectChannelList: ICombo[];
  public objectCediList: ICombo[];
  public objectSubprocessList: ICombo[];
  public objectMacrosegmentsList: ICombo[];
  public objectBusinessTypologiesList: ICombo[];
  public hierarchyRelease: HierarchyReleaseModel;
  public hierarchyRealeaseAssociate: HierarchyReleaseAssociateModel[] = [];
  public isJustLook: boolean;
  public profileInfo: DomainTypeModel;
  public processHierarchyEnum: any = ProcessHierarchyReleaseEnum;
  public processHierarchy = 0;
  public hierarchyIdUpdate = 0;
  public rolePermission: any = RolePermissionEnum;
  public language: string = sessionStorage.getItem('language');

  constructor(
    router: Router,
    fb: FormBuilder,
    spinner: NgxSpinnerService,
    coreService: CoreMasterService,
    domainService: DomainService,
    profileService: ProfileService,
    regionService: RegionService,
    channelService: ChannelService,
    hierarchyReleaseService: HierarchyReleaseService,
    createServices: CreateService,
    associateService: AssociateService,
    messageService: MessageService,
    translate: TranslateService,
    i18nService: I18nService
  ) {
    this.router = router;
    this.fb = fb;
    this.spinner = spinner;
    this.coreService = coreService;
    this.domainService = domainService;
    this.profileService = profileService;
    this.regionService = regionService;
    this.channelService = channelService;
    this.hierarchyReleaseService = hierarchyReleaseService;
    this.createServices = createServices;
    this.associateService = associateService;
    this.messageService = messageService;
    this.translate = translate;
    this.i18nService = i18nService;
   }

  ngOnInit(): void {
    this.getAll();
  }

  /**
  * @description This method get all info the init form.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getAll(){
    this.processHierarchy = this.createServices.getProcessHierarchyRealese();
    this.hierarchyIdUpdate = this.createServices.getHierarchyIdEdit();
    this.initForm();
    this.forkJoinData(true);
  }

  /**
  * @description This method execute methods async.
  * @author Diego Mauricio Cortés
  * @sprint 7
  */
  forkJoinData(isFirst: boolean = false): void {
    if(this.language === this.translate.currentLang && !isFirst){
      return;
    }else{
      this.translate.use(this.i18nService.language);
      this.language = this.i18nService.language;
    }

    this.spinner.show();
    this.hierarchyRelease = undefined;

    const countryResponse = this.coreService.getAll(TypeCoreMaster.Country);
    const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Process);
    const subprocessResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);
    const unitResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.UnitMeasurement);
    const operatorResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Operator);
    const profileResponse = this.profileService.getAllProfileApprovalLevel();

    forkJoin([
      countryResponse,
      processResponse,
      subprocessResponse,
      unitResponse,
      operatorResponse,
      profileResponse
    ])
      .subscribe(
        (result) => {

          const objCountryResponse: IResponseService<ICoreMaster[]> = result[0];
          const objProcessResponse: IResponseService<IDomain[]> = result[1];
          const objSubprocessResponse: IResponseService<IDomain[]> = result[2];
          const objUnitResponse: IResponseService<IDomain[]> = result[3];
          const objOperatorResponse: IResponseService<IDomain[]> = result[4];
          const objProfileResponse: IResponseService<IProfile[]> = result[5];

          this.objectCountryList = objCountryResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });

          this.objectProcessList = objProcessResponse.data.map((item) => {
            return {
              label: this.translate.instant('Domain.Domain-'+item.id),
              value: `${item.id}-${item.code}`
            };
          });

          this.objectSubprocess = objSubprocessResponse.data;

          this.objectUnitMeasurementList = objUnitResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          if (objProfileResponse.data.length > 0) {
            this.objectOperatorListStart = objOperatorResponse.data.map((item) => {
              return {
                label: item.description,
                value: item.code
              };
            });
          }
          else {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            return;
          }
          this.objectProfileList = objProfileResponse.data.map((item) => {
            return {
              label: item.description,
              value: `${item.id}-${item.description}`
            };
          });

          this.objectOperatorListEnd = this.objectOperatorListStart.filter((x) => x.value === Operator.LessThan || x.value === Operator.LessThanEqual);
          this.objectOperatorListStart = this.objectOperatorListStart.filter((x) => x.value !== Operator.LessThan && x.value !== Operator.LessThanEqual);



          const Macrosegments = this.coreService.getAll(TypeCoreMaster.Macrosegments);
          const BusinessTypologies = this.coreService.getAll(TypeCoreMaster.BusinessTypologies);

          forkJoin([
            Macrosegments,
            BusinessTypologies
          ])
            .subscribe(
              (result) => {
                const objMacrosegmentsResponse: IResponseService<ICoreMaster[]> = result[0];
                const objBusinessTypologiesResponse: IResponseService<ICoreMaster[]> = result[1];


                this.objectMacrosegmentsList = objMacrosegmentsResponse.data.map((item) => {
                  return {
                    label: item.description,
                    value: item.code.trim()
                  };
                });

                this.objectBusinessTypologiesList = objBusinessTypologiesResponse.data.map((item) => {
                  return {
                    label: item.description,
                    value: item.code.trim()
                  };
                });

                this.getInfoInitComponent();
              },
              () => {
                this.spinner.hide();
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            );

        },
        () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
  }

  /**
  * @description This method is to get Societies By Country.
  * @author Diego Mauricio Cortés
  * @sprint 7
  */
  getSocietiesByCountry(): void {
    const id = this.country.value;
    if (id !== '') {
      this.spinner.show();
      this.coreService.getAllSocietiesByCountry(id).subscribe(
        (response) => {
          this.objectSocietyList = response.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });
          this.society.enable();
          this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }
  }

  /**
  * @description This method is to get Cedi, Channel By Society.
  * @author Diego Mauricio Cortés
  * @sprint 7
  */
  getCediChannelRegionBySocietyId(): void {
    const id = this.society.value;
    if (id !== '') {

      const regionResponse = this.regionService.getAllRegionBySociety(id);
      const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
      const cediResponse = this.coreService.getAllCediBySociety(id);

      forkJoin([
        regionResponse,
        channelResponse,
        cediResponse
      ])
        .subscribe(
          (result) => {
            const objRegionResponse: IResponseService<ICountrySocietyRegion[]> = result[0];
            const objChannelResponse: IResponseService<ICoreMaster[]> = result[1];
            const objCediResponse: IResponseService<ICoreMaster[]> = result[2];

            this.objectRegionList = objRegionResponse.data.map((item) => {
              return {
                label: item.regionDescription,
                value: item.regionCode.trim()
              };
            });
            this.region.enable();
            this.objectChannelList = objChannelResponse.data.map((item) => {
              return {
                label: item.description,
                value: item.code.trim()
              };
            });
            this.channel.enable();
            this.objectCediList = objCediResponse.data.map((item) => {
              return {
                label: item.description,
                value: item.code.trim()
              };
            });
            this.cedi.enable();
            this.spinner.hide();
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
    }
  }

  /**
  * @description This method is to filter Subrocess By ProcessCode.
  * @author Diego Mauricio Cortés
  * @sprint 7
  */
  getSubprocessByCodeProcess(): void {

    const code = this.process.value.split('-')[1];
    if (code !== '') {
      this.subprocess.enable();
      this.objectSubprocessList = this.objectSubprocess.filter((x) => x.code === code).map((item)=> {
        return {
          label: this.translate.instant('Domain.Domain-'+item.id),
          value: item.id
        };
      });
    } else {
      this.subprocess.disable();
    }
  }

  /**
  * @description This method get info of memory the form.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getInfoInitComponent(){
    if (this.processHierarchy === ProcessHierarchyReleaseEnum.create ||
      this.processHierarchy === ProcessHierarchyReleaseEnum.update ||
      this.processHierarchy === ProcessHierarchyReleaseEnum.view) {

      const memoryData = this.createServices.getInfoHierarchyModel();

      if (memoryData !== undefined) {
        this.hierarchyRelease = memoryData;
        this.getInfoCombo();
        this.hierarchyRealeaseAssociate = this.hierarchyRelease.hierarchyReleaseAssociateCreateModel;
      }

      this.isJustLook = this.processHierarchy === ProcessHierarchyReleaseEnum.view ? true : false;
      this.spinner.hide();

    } else {
      this.redirectTo('/auth/hierarchy-release/');
    }

  }

  /**
  * @description This method get form info and return model of creation
  * @author Diego Mauricio Cortés
  * @sprint 7
  */
  getInfoHierarchyRelease(): HierarchyReleaseModel {

    const infoAssociateHierarchy = this.hierarchyRealeaseAssociate;

    const hierarchyReleaseCreate = new HierarchyReleaseModel(
      0,
      this.description.value,
      this.country.value,
      this.society.value,
      this.channel.value,
      this.region.value,
      this.cedi.value,
      this.macrosegmentCode.value,
      this.businessTypologyCode.value,
      this.process.value !== undefined ? this.process.value.split('-')[0] : 0,
      this.process.value,
      this.subprocess.value,
      infoAssociateHierarchy
    );

    return hierarchyReleaseCreate;
  }

  /**
  * @description This method get info of memory from create service.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getInfoAssociateHierarchy(){
    if(this.formHierarchy.valid){
       this.createServices.setProcessHierarchyRealese(this.processHierarchy);
       this.createServices.setInfoHierarchyModel(this.getInfoHierarchyRelease());
       this.createServices.setHierarchyIdEdit(this.hierarchyIdUpdate);
       this.redirectTo('/auth/hierarchy-release/associate-level');

    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'Debe completar los datos requeridos':'You must fill in the required information');
    }
  }

  /**
  * @description This method set info edit for create hierarchy.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  editAssociateHierarchyRelease(associate: HierarchyReleaseAssociateModel){
    this.createServices.setHierarchyIdEdit(this.hierarchyIdUpdate);

    this.createServices.setProcessHierarchyRealese(this.processHierarchy);
    this.createServices.setInfoHierarchyModel(this.getInfoHierarchyRelease());
    this.createServices.setProfileDescriptionEdit(associate.description);
    this.createServices.setHierarchyIdEdit(this.hierarchyIdUpdate);
    this.redirectTo('/auth/hierarchy-release/associate-level');
  }

  /**
  * @description This method assign associate rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  assignAssociateRank(associate: HierarchyReleaseAssociateModel){
    this.createServices.setProcessHierarchyRealese(this.processHierarchy);
    this.createServices.setInfoHierarchyModel(this.getInfoHierarchyRelease());
    this.createServices.setProfileDescriptionEdit(associate.description);
    this.createServices.setHierarchyIdEdit(this.hierarchyIdUpdate);
    this.redirectTo('/auth/hierarchy-release/associate-rank');
  }

    /**
  * @description This method validate associate rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns boolean
  * @sprint 8
  */
  validateApprovalRank(hierarchyAssociate: HierarchyReleaseAssociateModel[]): boolean{
    let result: boolean;
    hierarchyAssociate.forEach((x) => {
      if (x.ApprovalRankLevelId === 0 || x.RankStart === 0) {
        result = false;
      }else{
        result = true;
      }
    });
    return result;
  }


  /**
  * @description This method create the hierarchy.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  create(){
    Util.confirmSave().then((result: SweetAlertResult) => {
      this.spinner.show();

      this.hierarchyRelease = this.getInfoHierarchyRelease();


      const validation = this.validateApprovalRank(this.hierarchyRelease.hierarchyReleaseAssociateCreateModel);

      if(validation){
        const createModel = new HierarchyReleaseCreateModel(
          this.hierarchyRelease.description,
          this.hierarchyRelease.countryCode,
          this.hierarchyRelease.societyCode,
          this.hierarchyRelease.channelCode,
          this.hierarchyRelease.regionCode,
          this.hierarchyRelease.cediCode,
          this.hierarchyRelease.macrosegmentCode,
          this.hierarchyRelease.businessTypologyCode,
          this.hierarchyRelease.processDomainId,
          this.hierarchyRelease.subprocessDomainId,
          this.getHierarchyAssociateCreateModel(this.hierarchyRelease.hierarchyReleaseAssociateCreateModel)
        );


        this.createServices.postHierarchyReleaseWithAssociate(createModel)
          .subscribe((response) => {
            if (response.status) {
              this.spinner.hide();
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertCopy :SweetAlert.messageAlertCopyTranslate);

              this.formHierarchy.reset();
              this.redirectTo('/auth/hierarchy-release');

          } else {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        });
      }else{
        this.spinner.hide();
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
          language === 'es-ES' ? 'Debe seleccionar un rango de aprobación.': 'You must select an approval range.');
      }

    });
  }

  /**
  * @description This method create hierarchy model.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getHierarchyAssociateCreateModel(hierarchyModel: HierarchyReleaseAssociateModel[]): HierarchyReleaseAssociateCreateModel[]{
    const listAssociate: HierarchyReleaseAssociateCreateModel[] = [];

    hierarchyModel.forEach((v) => {
      v.associateUsers.forEach((u) => {
        const associate = new HierarchyReleaseAssociateCreateModel(
          0,
          `${u.profileId}-${v.description}`,
          u.accessManagementId,
          v.ApprovalRankLevelId,
          u.name,
          u.lastName,
          u.email,
          u.mandatory,
          u.authorizer,
          u.approval
        );
        listAssociate.push(associate);
      });
    });

    return listAssociate;
  }


  /**
  * @description This method edit info hierarchy release.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  update(){

    Util.confirmEdit().then((result: SweetAlertResult) => {
      this.spinner.show();

      const hierarchyIdUpdate = this.createServices.getHierarchyIdEdit();
      this.hierarchyRelease = this.getInfoHierarchyRelease();

      const update = new HierarchyRealeseUpdateModel(
        hierarchyIdUpdate,
        this.hierarchyRelease.description,
        this.hierarchyRelease.countryCode,
        this.hierarchyRelease.societyCode,
        this.hierarchyRelease.channelCode,
        this.hierarchyRelease.regionCode,
        this.hierarchyRelease.cediCode,
        this.hierarchyRelease.macrosegmentCode,
        this.hierarchyRelease.businessTypologyCode,
        this.hierarchyRelease.processDomainId,
        this.hierarchyRelease.processDomainIdView,
        this.hierarchyRelease.subprocessDomainId,
        this.getHierarchyReleaseUpdateModel(hierarchyIdUpdate, this.hierarchyRelease.hierarchyReleaseAssociateCreateModel)
      );


      this.createServices.putHierarchyReleaseWithAssociate(hierarchyIdUpdate, update)
        .subscribe((response) => {
          if (response.status) {
            this.spinner.hide();
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' ? SweetAlert.messageAlertCopy :SweetAlert.messageAlertCopyTranslate);
            this.formHierarchy.reset();
            this.redirectTo('/auth/hierarchy-release');
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            this.spinner.hide();
          }
        },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          });
    });




  }

  /**
  * @description This method set info to hierarchy release associate update.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getHierarchyReleaseUpdateModel(hierarchyId: number, hierarchyModel: HierarchyReleaseAssociateModel[]): HierarchyRealeseAssociateUpdateModel[] {
    const listAssociateUpdateModel: HierarchyRealeseAssociateUpdateModel[] = [];

    hierarchyModel.forEach((v) => {
      v.associateUsers.forEach((u) => {
        const associateUpdateModel = new HierarchyRealeseAssociateUpdateModel(
          u.id,
          hierarchyId,
          `${u.profileId}-${v.description}`,
          u.accessManagementId,
          v.ApprovalRankLevelId,
          u.name,
          u.lastName,
          u.email,
          u.mandatory,
          u.authorizer,
          u.approval,
        );

        listAssociateUpdateModel.push(associateUpdateModel);
      });
    });

    return listAssociateUpdateModel;
  }

  /**
  * @description This method cancel to word of form.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  cancel(): void {
    Util.confirmCancel().then((result: SweetAlertResult) => {
      this.formHierarchy.reset();
      this.clearCreateHierarchyRelease();
      this.clearControls();
      this.redirectTo('auth/hierarchy-release');
    });
  }

  /**
  * @description This method redirect to url.
  * @author Jhon Steven Pavón Bedoya
  * @param to
  * @returns
  * @sprint 8
  */
  redirectTo(to: string): void {
    this.router.navigate([to]);
  }


  /**
  * @description This method shows the toast control.
  * @author Jhon Stiven Pavón Bedoya
  * @sprint 7
  * @param severity
  * @param message
  * @param detail
  * @returns none
  */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }


  /**
  * @description This method get info combobox.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getInfoCombo(){
    this.description.setValue(this.hierarchyRelease.description);
    this.country.setValue(this.hierarchyRelease.countryCode);
    this.getSocietiesByCountry();
    this.society.setValue(this.hierarchyRelease.societyCode);
    this.getCediChannelRegionBySocietyId();
    this.channel.setValue(this.hierarchyRelease.channelCode);
    this.region.setValue(this.hierarchyRelease.regionCode);
    this.cedi.setValue(this.hierarchyRelease.cediCode);
    this.process.setValue(this.hierarchyRelease.processDomainIdView);
    this.getSubprocessByCodeProcess();
    this.subprocess.setValue(this.hierarchyRelease.subprocessDomainId);
    this.macrosegmentCode.setValue(this.hierarchyRelease.macrosegmentCode);
    this.businessTypologyCode.setValue(this.hierarchyRelease.businessTypologyCode);

    this.spinner.hide();
  }


  /**
  * @description This method clear create info the form.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  clearCreateHierarchyRelease(){
    this.hierarchyRelease = undefined;
    this.processHierarchy = 0;
  }

  /**
  * @description This method clear info the form.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  clearControls() {
    this.description.setValue('');
    this.country.setValue('');
    this.society.setValue('');
    this.channel.setValue('');
    this.region.setValue('');
    this.cedi.setValue('');
    this.process.setValue('');
    this.subprocess.setValue('');
    this.macrosegmentCode.setValue('');
    this.businessTypologyCode.setValue('');
  }

  /**
  * @description This method split description approval level.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  splitAprovalLevel(aprovalLevel: string, position: number): string {
    const splitted = aprovalLevel.split('-');
    return splitted[position];
  }

  /**
   * @description This method is to initialize the form.
   * @author Diego Mauricio Cortés
   * @sprint 7
   */
  initForm(): void {
    this.formHierarchy = this.fb.group({
      description: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(100), Validators.pattern(RegexCommon.ExpRegOnlyCaracterNumber100)]],
      country: [{ value: '', disabled: false }, [Validators.required]],
      society: [{ value: '', disabled: true }, [Validators.required]],
      channel: [{ value: '', disabled: true }, [Validators.required]],
      region: [{ value: '', disabled: true }, [Validators.required]],
      cedi: [{ value: '', disabled: true }, [Validators.required]],
      process: [{ value: '', disabled: false }, [Validators.required]],
      subprocess: [{ value: '', disabled: true }, [Validators.required]],
      macrosegmentCode: [{ value: '', disabled: false }, []],
      businessTypologyCode: [{ value: '', disabled: false }, []],
    });
  }

  get description(): AbstractControl { return this.formHierarchy.get('description'); }
  get country(): AbstractControl { return this.formHierarchy.get('country'); }
  get society(): AbstractControl { return this.formHierarchy.get('society'); }
  get channel(): AbstractControl { return this.formHierarchy.get('channel'); }
  get region(): AbstractControl { return this.formHierarchy.get('region'); }
  get cedi(): AbstractControl { return this.formHierarchy.get('cedi'); }
  get process(): AbstractControl { return this.formHierarchy.get('process'); }
  get subprocess(): AbstractControl { return this.formHierarchy.get('subprocess'); }
  get macrosegmentCode(): AbstractControl { return this.formHierarchy.get('macrosegmentCode'); }
  get businessTypologyCode(): AbstractControl { return this.formHierarchy.get('businessTypologyCode'); }

  keyupHierarchyRelase(event: any) {
    const value = Util.keyupLetterWithNumbersValidation(event);
    this.description.setValue(value);
  }
}
