import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { PaginationDateModel } from '@app/@shared/model/paginationDate.model';
import { PaginationHistoryModel } from '@app/@shared/model/paginationHistory.model';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { SweetAlert } from '../../../@shared/enums/sweetalert.enum';
import { ISolicitudeDetailJson } from './interfaces/solicitude-detail-json.interface';
import { ISolicitudeHistory, ISolicitudeHistoryDetails } from './interfaces/solicitude-history.interface';
import { INotificationUser } from './interfaces/notification-user.interface';
import { PaginationNotificationUserModel } from '@app/@shared/model/paginationNotificationUser.model';
import { ISolicitudeNotification } from './interfaces/solicitude-notification.interface';
import { ISolicitudeNotificationUser } from './interfaces/solicitude-notification-user.interface';
import { SolicitudeNotificationRequestModel } from './models/solicitude-notification-request.model';
import { ISolicitudeJson } from './interfaces/solicitude-json.interface';
import { ISolicitude } from './interfaces/solicitude.interface';
import { SolicitudeMessage } from './messages/solicitude.message';
import { SolicitudeService } from './solicitude.service';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { AssingClientEnums } from '../../../@shared/enums/assing-client.enums';
import { DataImpactAnalysisJson } from './models/data-impact-analysis-json.model';
import { ImpactAnalysisJsonDetail } from './models/impact-analysis-json-detail.model';
import { ISolicitudeCustomer } from './interfaces/solicitude-customer.interface';
import { FilterClient } from '@app/@shared/enums/filter-client.enums';
import { AnalyticModule } from '@app/@shared/enums/analytic-module.enum';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { I18nService } from '@app/i18n';
import { CommercialActivityCalculateService } from '@app/@components/commercial-activity-calculate/commercial-activity-calculate.service';
import { SolicitudeCopyModel } from './models/copy-solicitude.model';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { IFieldsComponentGeneralFilter } from '@app/@shared/interfaces/fields-component-general-filter.interface';
import { FieldsComponentGeneralFilter } from '@app/@shared/model/fields-component-general-filter.model';
import { IFieldsComponentClientFilter } from '@app/@shared/interfaces/fields-component-client-filter.interface';
import { FieldsComponentClientFilter } from '@app/@shared/model/fields-component-client-filter.model';
import { IAdvancedFilter, IGeneralAdvancedFilter } from '@app/@components/shared-advanced-filter/interfaces/general-advanced-filter.interface';import { PaginatorFilterModel } from '@app/@shared/model/paginator-filter.models';
import { PaginatorFilterService } from '@app/@shared/services/paginator-filter.service';
import { SharedAdvancedFilterComponent } from '@app/@components/shared-advanced-filter/shared-advanced-filter.component';


@Component({
  selector: 'app-solicitude',
  templateUrl: './solicitude.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
  styles: [`
      @media screen and (max-width: 960px) {
          :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
              display: flex;
          }

          :host ::ng-deep .p-dialog {
            min-width: 6rem;
      }
      }
  `]
})
export class SolicitudeComponent implements OnInit {

  private readonly solicitudeService: SolicitudeService;
  private readonly commActivityCalculateService: CommercialActivityCalculateService;
  private readonly spinner: NgxSpinnerService;
  private readonly fb: FormBuilder;
  private readonly messageService: MessageService;
  private readonly router: Router;
  private readonly gTranslate : I18nService;
  private readonly paginatorService: PaginatorFilterService;

  public pageTitle = SolicitudeMessage.TITLE;
  public objectList: ISolicitudeJson[];
  public objectListNotificationUser: INotificationUser[];
  public objectSolicitudeNotification: ISolicitudeNotification;
  public objectListSolicitudeNotificationUser: ISolicitudeNotificationUser[] = [];
  public formSolicitude: FormGroup;
  public solicitude: ISolicitudeJson;
  public showDialog: boolean;
  public actionsDetail: ISolicitudeDetailJson;
  public objectListSolicitudeHistory: ISolicitudeHistory[];
  public displayModal: boolean;
  public displayPosition: boolean;
  public position: string;
  public solicitudeStatusEnum = SolicitudeStatus;
  public solicitudeTypeEnum = SolicitudeType;
  public jsonFilterClient: ImpactAnalysisJsonDetail[] = [];
  public rolePermission: any = RolePermissionEnum;
  public solicitudId: number;
  public validity: boolean;
  public today: Date = new Date();
  public minYear: number;
  public minDay: number;
  public minMonth:number;
  public page = 1;
  public pageAux = 1;
  public rows = 10;
  public pageLinkSize = 5;
  public paginator: PaginatorFilterModel;
  public paginatorAuxFlag = 0;
  public first = 0;
  public isSearchAux = false;
  public flagBack = false;

  public showModalCopy = false;
  public idCopy: number;
  public objectSku : any[];
  public sku = 0;
  public objectCopy: ISolicitudeJson;
  public auxSave: ISolicitudeJson[] = [];
  public filterPrice : string;
  public advanceFilter = {} as IAdvancedFilter;

  public displayModalDetails : boolean;
  public objectListSolicitudeDetails : ISolicitudeHistoryDetails[];
  public readonly moduleName = 'solicitude';
  public readonly showFieldsGeneral : IFieldsComponentGeneralFilter = new FieldsComponentGeneralFilter(true,  false, false, false, true, 
                                                                                                       true,  false, false, false, false, 
                                                                                                       false, false, false, true, true);
  public readonly showFieldsClient : IFieldsComponentClientFilter = new FieldsComponentClientFilter(false, true);

  constructor(
    solicitudeService: SolicitudeService,
    commActivityCalculateService: CommercialActivityCalculateService,
    spinner: NgxSpinnerService,
    fb: FormBuilder,
    messageService: MessageService,
    router: Router,
    private primengConfig: PrimeNGConfig,
    gTranslate : I18nService,
    paginatorService: PaginatorFilterService
  ) { 
    this.solicitudeService = solicitudeService;
    this.commActivityCalculateService = commActivityCalculateService;
    this.spinner = spinner;
    this.fb = fb;
    this.messageService = messageService;
    this.router = router;
    this.gTranslate = gTranslate;
    this.paginatorService = paginatorService;
  }

  /**
  * @description this method is private.
  * @author djgil
  * @sprint 4
  */
  async ngOnInit(): Promise<void> {
    this.initializeAdvanceFilter();
    this.solicitude = undefined;
    this.auxSave = [];
    this.initForm();
    this.getPaginatorValues();

    this.rows = this.paginator?.limitRecords  ?? 10;
    this.page = this.paginator?.pageNumber ?? 1;
    this.pageAux =  this.page / this.rows;
    this.flagBack =  this.paginator?.flagBack ?? false;
    this.setPaginator();

    this.solicitudeService.setSolicitudeId(0);
    this.primengConfig.ripple = true;
    const auxToday= new Date();
    this.minYear = auxToday.getFullYear() - 2;
    this.today.setFullYear(this.minYear);
    this.paginatorService.resetPaginatorValues();
    await this.getAll();
  }

  validateElementsNotFound(){
    if(this.objectList.length <= 0){
      this.validity = true;
    }else{
      this.validity = false;
    }
  }

    /**
   * @description this method is for validate date control.
   * @author dcardona
   * @sprint 4
   */
  validateDate(): void{
    if(!this.formSolicitude.valid)
    {
      this.formSolicitude.markAllAsTouched();
    }
  }

      /**
   * @description this method is for validate date control.
   * @author dcardona
   * @sprint 4
   */
       getYearRange() {
        const thisDate = new Date();
        const thisYear = thisDate.getFullYear();
        return `${thisYear-2}:${thisYear}`;
      }

  /**
   * @description this method is to get all the solicitude.
   * @author djgil
   * @sprint 4
   */
  async getAll(isSearch = false, isFilterAdvanceExec = false): Promise<void> {
    this.spinner.show();
    this.advanceFilter.filterCombined = this.searchFilter?.value ?? "";
    this.validateNumberPage(isSearch);
    this.isSearchAux = isSearch;
    this.pageAux = isFilterAdvanceExec == true ?  this.advanceFilter.search.pageNumber : this.pageAux;
    this.rows = isFilterAdvanceExec == true ?  this.advanceFilter.search.resultsPerPage : this.rows;
    this.advanceFilter.search.pageNumber = this.pageAux;
    this.advanceFilter.search.resultsPerPage = this.rows;

    const response = await this.solicitudeService.getSolicitudeFilter(this.advanceFilter).toPromise();
    if (response.status) {
        this.spinner.hide();
        this.page = (this.pageAux - 1) * this.rows;
        this.flagBack = false;
        this.objectList = response.data;
        this.objectList.forEach(item => {
          if(item.isManagedWithdrawal) item.status = 139;
        });
       this.auxSave = response.data;
       this.objectSku = response.data;
       this.pageLinkSize = response.resultCount;
       this.validateElementsNotFound();
      }
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
  }

  validateNumberPage(isSearch = false){
    if(this.pageAux >= 1 && this.pageAux < this.page){
      this.pageAux = this.pageAux + 1;
    }else {
      this.pageAux = this.page;
    }
    
    if(isSearch){
      if(this.flagBack){
        this.flagBack = false;
      }else{
        this.page = 0;
      }
      this.pageAux = this.searchFilter.value != '' ? 1 : this.pageAux;
    }else{
      this.pageAux = this.pageAux;
    }
     
     if(this.pageAux == 0) this.pageAux = 1;
  }
  

  paginate(event: any){
    let pageAux = event.first/event.rows;
    this.page = pageAux + 1;
    this.rows = event.rows;
    this.getAll();
  }

  loadCustomers($event: any){
    this.filterPrice = "";
    if($event.globalFilter){
      this.filterPrice = $event.globalFilter;
      let pageAux = $event.first/$event.rows;
      if(this.page !== pageAux+1){
        this.paginate($event)
      }

    }else{
      this.paginate($event);
    }
  }

  loadPaginators(event: any){
    if(!this.flagBack){
      let pageAux = event.first/event.rows;
      this.page = pageAux + 1;
      this.pageAux = this.page;
      this.rows = event.rows;
      this.getAll();
    }
    this.flagBack = false;
  }
  
  loadCustomers2(){
    if(this.searchFilter.value == ''){
      this.advanceFilter.search.pageNumber = 1;
      this.advanceFilter.search.resultsPerPage = 10;
      this.getAll(true, true);
    }
  }

  setPaginator(){
    if((this.paginator?.searchFilter) && this.paginatorAuxFlag == 0){
      this.searchFilter.setValue(this.paginator?.searchFilter ?? '');
      this.paginatorAuxFlag = 1;
    }
  }  

  onClearClickDate(){
    this.rangeDateControl.reset();
    this.page = 0;
    this.getAll();
  }

    /**
  * @description this method is to edit Solicitude.
  * @author Daniel Londoño
  * @sprint 5
  */
    detailSolicitude(ObjecDetail: ISolicitudeJson): void {
    this.spinner.show();
    this.solicitudeService.setSolicitudeId(ObjecDetail.id);
    this.paginatorService.setPaginatorValues(new PaginatorFilterModel(this.page, this.rows,this.searchFilter.value, this.rangeDateControl.value, true, this.advanceFilter)); 
    if (ObjecDetail.type === SolicitudeType.Creation) {
      setTimeout(() => {
        this.router.navigate(['auth/solicitude/detail-solicitude/detail-creation']);
      }, 1000);
    } else {
      if (ObjecDetail.type === SolicitudeType.Withdrawal) {
        setTimeout(() => {
          this.router.navigate(['auth/solicitude/detail-solicitude/detail-withdrawal']);
        }, 1000);
      }else if(ObjecDetail.type === SolicitudeType.WithdrawalDiferential){
        setTimeout(() => {
          this.router.navigate(['auth/solicitude/detail-solicitude/detail-differential']);
        }, 1000);
      }
    }
    this.spinner.hide();
  }

  /**
    * @description this method is to addWithdrawal Solicitude.
    * @author djgil
    * @sprint 4
    */
   addWithdrawal(): void {
    this.solicitudeService.setIsCreate(AssingClientEnums.create);
    sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
    sessionStorage.setItem(CommonConfiguration.SolicitudeCodeCreation, Util.newGuid());
    this.solicitudeService.setSolicitudType(SolicitudeType.Withdrawal);
    this.router.navigate(['auth/solicitude/withdrawal']);
  }

  addDifferential(): void {
    this.router.navigate(['auth/solicitude/remove-differential']);

  }

  /**
    * @description this method is to addCreation Solicitude.
    * @author djgil
    * @sprint 4
    */
   addCreation(): void {
    this.solicitudeService.setIsCreate(AssingClientEnums.create);
    sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
    sessionStorage.setItem(CommonConfiguration.SolicitudeCodeCreation, Util.newGuid());
    this.router.navigate(['auth/solicitude/creation']);
  }

  copySolicitude(ObjecDetail: ISolicitudeJson){
    this.spinner.show();
    this.idCopy = ObjecDetail.id;
    this.objectCopy = ObjecDetail;
    this.numberSku(this.idCopy);
    this.showModalCopy = true;
  }
  clearCopy(){
    this.idCopy = 0;
    this.sku = 0;
  }

  createCopy(){
    //Servicio
    const _guid = Util.newGuid()
    const id :SolicitudeCopyModel = new SolicitudeCopyModel(
      this.idCopy,
      _guid.value
      );
      this.solicitudeService.copy(id)
      .subscribe(
        async (response) => {
          this.spinner.hide();
          if (response.status) {
            this.showModalCopy = false;
            this.clearCopy();
            await this.getAll();
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconSuccess,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertCopy : SweetAlert.messageAlertCopyTranslate);
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );

  }

  cancelCopy(){
    this.showModalCopy = false;
    this.clearCopy();
  }

  numberSku(id: number){
    this.objectSku.forEach((x) => {
      if(id === x.id){
        const aux = x.sku.split(',');
        this.sku = aux.length;
      }
    });
    this.spinner.hide();
  }


  /**
    * @description this method is to edit Solicitude.
    * @author djgil
    * @sprint 4
    */
   edit(ObjecDetail: ISolicitudeJson): void {
    this.solicitudeService.setSolicitudeId(ObjecDetail.id);
    this.solicitudeService.setIsCreate(AssingClientEnums.update);
    this.paginatorService.setPaginatorValues(new PaginatorFilterModel(this.page, this.rows,this.searchFilter.value, this.rangeDateControl.value, true, this.advanceFilter)); 
    if(ObjecDetail.type === SolicitudeType.Creation){
      this.router.navigate(['auth/solicitude/creation/']);
    }else {
      if(ObjecDetail.type === SolicitudeType.Withdrawal){
        this.solicitudeService.setSolicitudType(SolicitudeType.Withdrawal);
        this.router.navigate(['auth/solicitude/withdrawal/']);
      }else if(ObjecDetail.type === SolicitudeType.WithdrawalDiferential){
        this.solicitudeService.setSolicitudType(SolicitudeType.WithdrawalDiferential);
        this.router.navigate(['auth/solicitude/withdrawal/']);
      }
    }
  }

    /**
   * @description this method is for show modal dialog.
   * @author dcardona
   * @sprint 4
   */
  showModalDialog() {
    this.displayModal = true;
  }

/**
 * @description this method is for show modal dialog.
 * @author dcardona
 * @sprint 4
 * @param position
 */
  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }

      /**
   * @description this method is for hide modal dialog.
   * @author djgil
   * @sprint 4
   */

    hide() { 
      this.displayModalDetails = false; 
      this.objectListSolicitudeDetails = [];
    }
    
    hide2() { 
      this.displayModal = false;
      this.objectListSolicitudeHistory = [];
    }
  /**
    * @description this method is to history Solicitude.
    * @author djgil
    * @sprint 4
    * @param ObjectDetail.id
  */
   history(solicitudeId : number): void {
    this.spinner.show();
    this.displayModal = true;
    const paginationHistoryModel = new PaginationHistoryModel('id', true, 1, 100, solicitudeId);
    this.solicitudeService.getListSolicitudeHistory(paginationHistoryModel).subscribe(
      (response) => {
        if(response.status){
          this.spinner.hide();
          this.objectListSolicitudeHistory = response.data;
          this.objectListSolicitudeHistory.sort((a, b) => 
          (a.creationDate > b.creationDate) ? 1 : -1);
        }
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  seeMore(solicitude : ISolicitudeHistory){
    this.displayModalDetails = true;
     this.objectListSolicitudeDetails = solicitude.details;
  }

  /**
    * @description this method is to history Solicitude.
    * @author djgil
    * @sprint 4
    * @param ObjecDetail
    */
   message(ObjecDetail: ISolicitude): void {
    this.router.navigate(['auth/']);
  }

  /**
   * @description this method is to delete Solicitude.
   * @author djgil
   * @sprint 4
   */
   delete(id: string): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.solicitudeService.deleteSolicitude(id)
          .subscribe(
            async (response) => {
              this.spinner.hide();
              if (response.status) {
                const language: string = sessionStorage.getItem('language');
                await this.getAll();
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
    });
  }

  /**
   * @description this method is to delete Solicitude.
   * @author David  Sneider Cardona Cardenas
   * @sprint 6
   * @param objectSolicitude
   */
   sendSolicitude(objectSolicitude: ISolicitudeJson): void {
    if(objectSolicitude.solicitudeDetailJson === null || objectSolicitude.solicitudeDetailJson.length === 0 ){
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? SolicitudeMessage.SOLICITUDE_NOT_HAVE_MATERIALS 
                             : SolicitudeMessage.SOLICITUDE_NOT_HAVE_MATERIALS_TRANSLATE);
      return;
    }
    
    let messageCont = 0;
    Util.confirmSendSolicitude().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        messageCont += 1;
        if(objectSolicitude.type === SolicitudeType.Creation){
          this.sendDataForAnalizyImpact(objectSolicitude);
        }
        const typeSolicitude = `${objectSolicitude.type}-${objectSolicitude.typeCode}`;

      const paginationNotificationUser = modelPaginationNotificationUser(typeSolicitude);
      this.solicitudeService
        .getListNotificationUser(paginationNotificationUser)
        .subscribe(
          (response) => {
            if(response.status) {

							if(response.data == null || response.data.length === 0) {
								this.spinner.hide();
                this.messageThereIsNoNotificationConfigured();
								return;
							}

              this.objectListNotificationUser = response.data;

							if(this.objectListNotificationUser[0].userInformation == null || this.objectListNotificationUser[0].userInformation.length === 0){
								this.spinner.hide();
                this.messageNoUserDistributionList();
								return;
							}

              const objSolicitudeNotification = {} as ISolicitudeNotification;
              objSolicitudeNotification.solicitudeId = objectSolicitude.id;
              objSolicitudeNotification.notificationId = this.objectListNotificationUser[0].id;
              objSolicitudeNotification.notificationTypeId = this.objectListNotificationUser[0].notificationTypeId;
              objSolicitudeNotification.notificationTypeCode = this.objectListNotificationUser[0].notificationTypeCode;
              objSolicitudeNotification.notificationTypeDescription = this.objectListNotificationUser[0].notificationTypeDescription;
              objSolicitudeNotification.notificationDescription = this.objectListNotificationUser[0].notificationDescription;
              objSolicitudeNotification.moduleId = this.objectListNotificationUser[0].moduleId;
              objSolicitudeNotification.moduleCode = this.objectListNotificationUser[0].moduleCode;
              objSolicitudeNotification.moduleDescription = this.objectListNotificationUser[0].moduleDescription;
              objSolicitudeNotification.typeSolicitude = this.objectListNotificationUser[0].typeSolicitude;
              objSolicitudeNotification.isSendEmail = this.objectListNotificationUser[0].isSendEmail;
              objSolicitudeNotification.enableCommentary = this.objectListNotificationUser[0].enableCommentary;
              objSolicitudeNotification.color = this.objectListNotificationUser[0].color;
              objSolicitudeNotification.subject = `${this.objectListNotificationUser[0].subject} N°${objectSolicitude.id}`;
              objSolicitudeNotification.bodyMail = this.objectListNotificationUser[0].bodyMail
                .replace('#[NoficationType]#',objSolicitudeNotification.notificationTypeDescription.toString())
                .replace('#[Solicitude]#', objectSolicitude.id.toString())
                .replace('#[Module]#',objSolicitudeNotification.moduleDescription.toString());

              const objSolicitudeNotificationUser: ISolicitudeNotificationUser[] =
                this.objectListNotificationUser[0].userInformation.map((item) => {
                  return {
                    user: item.user,
                    userEmail: item.userEmail,
                  };
                });

              const model: SolicitudeNotificationRequestModel =
                this.modelSolicitudeNotificationRequest(objSolicitudeNotification, objSolicitudeNotificationUser, objectSolicitude);

              this.solicitudeService.postSolicitudeNotification(model).subscribe(
                async (responsePost) => {
                  await this.getAll();
                  if(messageCont === 1){
                    this.messageSuccessNotification();
                    messageCont += 1;
                  }

                },
                (error: HttpErrorResponse) => {
                  this.spinner.hide();
                  SweetAlertMessageHelpers.exception(error);
                },
                () => {
                  this.spinner.hide();
                }
              );
            }else{
              this.spinner.hide();
              this.messageThereIsNoNotificationConfigured();
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });

     function modelPaginationNotificationUser(typeSolicitude: string) {
       return new PaginationNotificationUserModel(
         'id',
         true,
         1,
         100,
         typeSolicitude
       );
     }
  }

  sendDataForAnalizyImpact(objectSolicitude: ISolicitudeJson) {

    if (objectSolicitude.filterClientJson) {
      this.jsonFilterClient = <ImpactAnalysisJsonDetail[]>(
        JSON.parse(objectSolicitude.filterClientJson)
      );
    } else {
      if (objectSolicitude.solicitudeCustomerJson) {
        this.jsonFilterClient.push(
          this.mapFilterCustomerJson(objectSolicitude.solicitudeCustomerJson)
        );
      }
    }

    let modelDataImpact : DataImpactAnalysisJson[] = [];

    objectSolicitude.solicitudeDetailJson.forEach(async (element) => {
        const modelSetDataImpact: DataImpactAnalysisJson =
          new DataImpactAnalysisJson(
            element.guid,
            AnalyticModule.Price,
            element.sku,
            this.jsonFilterClient
          );        
          modelDataImpact.push(modelSetDataImpact);
      }
    );
    this.solicitudeService.postDataImpact(modelDataImpact).subscribe();
  }

  mapFilterCustomerJson(
    solicitudeCustomerJson: ISolicitudeCustomer[]
  ): ImpactAnalysisJsonDetail {
    return new ImpactAnalysisJsonDetail(
      Util.newGuid(),
      FilterClient.ClientCode,
      'Cliente',
      this.returnClientCode(solicitudeCustomerJson),
      '',
      '='
    );
  }

  returnClientCode(solicitudeCustomerJson: ISolicitudeCustomer[]): string {
    let concatClientCode = '';
    solicitudeCustomerJson.forEach((customer) => {
      concatClientCode += customer.clientCode + ';';
    });
    return concatClientCode.substring(0, concatClientCode.length - 1);
  }

  /**
  * @description This method shows the toast control.
  * @author djgil
  * @sprint 2
  * @param severity
  * @param message
  * @param detail
  * @returns none
  */
   showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  private modelSolicitudeNotificationRequest(objSolicitudeNotification: ISolicitudeNotification, 
                                            objSolicitudeNotificationUser: ISolicitudeNotificationUser[], 
                                            objectSolicitude: ISolicitudeJson): 
  SolicitudeNotificationRequestModel {
    return new SolicitudeNotificationRequestModel(
      objSolicitudeNotification,
      objSolicitudeNotificationUser,
      objectSolicitude.id
    );
  }

  private messageSuccessNotification() {
    const language: string = sessionStorage.getItem('language');
    this.showToast(
      SweetAlert.iconInfo,
      language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
      language === 'es-ES' ? SweetAlert.messageAlertSend : SweetAlert.messageAlertSendTranslate
    );
  }

  private messageNoUserDistributionList() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? SolicitudeMessage.NO_EXIST_USERS : SolicitudeMessage.NO_EXIST_USERS_TRANSLATE);
  }

  private messageThereIsNoNotificationConfigured() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? SolicitudeMessage.NOT_NOTIFICATION_CONFIG : SolicitudeMessage.NOT_NOTIFICATION_CONFIG_TRANSLATE);
  }

  /**
  * @description this method is to initialize the form.
  * @author David Cardona Cardenas
  * @sprint 1
  */
  initForm(): void {
    this.formSolicitude = this.fb.group({
      rangeDateControl: [{ value: '', disabled: false }],
      searchFilter: [{ value: '', disabled: false }],
    });
  }

  getDataListAll($event: any) {
    this.initializeAdvanceFilter();
    this.advanceFilter = $event;
    this.getAll(true, true);
  }


  initializeAdvanceFilter() {
    this.advanceFilter.search = {ascendent:true, id:0, orderBy:'Id', pageNumber:1, resultsPerPage:this.rows}
    this.advanceFilter.general = {} as IGeneralAdvancedFilter;
    this.advanceFilter.general.activityCode = 0;
    this.advanceFilter.general.activityDescription = "";
    this.advanceFilter.general.activityChannels = [];
    this.advanceFilter.general.activityStatus = [];
    this.advanceFilter.general.activitySectors = [];
    this.advanceFilter.general.activityTypes = [];
    this.advanceFilter.general.activityProms = [];
    this.advanceFilter.client = [];
    this.advanceFilter.clientFilter = "";
    this.advanceFilter.materialBase = [];
    this.advanceFilter.materialBasePromotion = [];
    this.advanceFilter.filterCombined = "";
  }
  
  getPaginatorValues() {
    this.paginator = this.paginatorService.getPaginatorValues();

    if (this.paginator?.advanceFilter)
    {
      this.advanceFilter = this.paginator.advanceFilter;
    }
  }  

  get rangeDateControl(): AbstractControl { return this.formSolicitude.get('rangeDateControl'); }
  get searchFilter(): AbstractControl { return this.formSolicitude.get('searchFilter'); }

}
