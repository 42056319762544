
export class ExlusionOverlapModel {
  constructor(
    public typeBusinessActivity : string,
    public channel : string,
    public groupNameSku: string,
    public codeSku: string,
    public clientFilter : string,
    public validityPeriod : string,
  ){}
}
