<section>
  <div class="impact-analysis-breadcrumb">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/pending-solicitude'">
            {{ "PendingSolicitudeComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a [routerLink]="'/auth/pending-solicitude/creation'">
            {{ 'PendingSolicitudeComponent.TitleAnalyze' | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ 'PendingSolicitudeComponent.ImpactAnalysis.Title' | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="impact-analysis-title">
    <div class="row">
      <div class="col-12">
        <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.ImpactAnalysis.Title' | translate }}</h1>
        <span class="pstntitle">{{ 'PendingSolicitudeComponent.ImpactAnalysis.SubTitle' | translate }}</span>
      </div>
        <div class="col-12 mt-3">
          <h6 class="pstntitle">{{'Request' | translate}} : {{ solicitudId }}</h6>
        </div>
    </div>
  </div>
  
</section>

<section class="pstngestion">

  <div class="impact-analysis">
    <div class="container-fluid">
      <div class="basic-information" style="width: 50%;">
        <div class="item">
          <span class="title">{{ 'PendingSolicitudeComponent.ImpactAnalysis.codeProduct' | translate }}:</span>
          <span class="value">{{code}}</span>
        </div>
        <div class="item">
          <span class="title">{{ 'PendingSolicitudeComponent.ImpactAnalysis.nameProduct' | translate }}:</span>
          <span class="value">{{name}}</span>
        </div>
        <div class="item">
          <span class="title">{{ 'PendingSolicitudeComponent.ImpactAnalysis.priceProduct' | translate }}:</span>
          <span class="value">{{newPriceWithOutTax | currency}}</span>
        </div>
      </div>
    </div>

    <div class="container-fluid card-container">
      <div *ngFor="let analysisChart of analysisCharts">
        <app-impact-analyses-card [item]="analysisChart" (eventEmitterCard)="getEventEmitterCard($event)"
          [toSendCalculatedValue$]="toSendCalculatedValue$"></app-impact-analyses-card>
      </div>
      <div class="card-container-sugerencia">
        <p-card class="card-container-sugerencia-pcard">
          <span class="title">{{ 'PendingSolicitudeComponent.ImpactAnalysis.suggestion' | translate }}:</span>
          <span class="description"
            *ngIf="calculateWeightedWeight &&
            (calculateWeightedWeight*100 > analysisChartImpactPorcentage?.umbral*100)">
            {{ 'PendingSolicitudeComponent.ImpactAnalysis.Pricechange' | translate }}
          </span>
          <span class="description"
            *ngIf="calculateWeightedWeight &&
            (calculateWeightedWeight*100 < analysisChartImpactPorcentage?.umbral*100)">
            {{ 'PendingSolicitudeComponent.ImpactAnalysis.PriceAdjustments' | translate }}
          </span>
          <span class="description" *ngIf="calculateWeightedWeight==null"> </span>
        </p-card>
      </div>
    </div>
    <!--Reglas impactadas-->
    <div class="container-fluid">
      <div class="basic-information">
        <div class="item">
          <span class="title">{{ 'PendingSolicitudeComponent.RulesImpacted.ChooseAnOption' | translate }}</span>
        </div>
        <div class="justify-content-around" style="width: 90%;">
          <div>
            <p-radioButton name="typeFilter" 
              (onClick)="refreshCheck()" 
              value="1" 
              label='{{"PendingSolicitudeComponent.ImpactAnalysis.Pricelist" | translate }}' 
              [(ngModel)]="isSuggestion">
            </p-radioButton>
          </div>
          <div>
            <p-radioButton name="typeFilter" 
              (onClick)="refreshCheck()" 
              value="2" 
              label='{{"PendingSolicitudeComponent.ImpactAnalysis.PriceAdjustment" | translate }}' 
              [(ngModel)]="isSuggestion">
          </p-radioButton>
          </div>
        </div>
        </div>
    </div>

      <div class="col-md-6">
        <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.RulesImpacted.Title' | translate }}</h1>
      </div>

      <div class="pstn_container container-fluid">
        <p-table #dt
          name="gridpendingSolicitude"
          dataKey="pendingSolicitude"
          [paginator]="true"
          [scrollable]="true"
          [value]="rulesImpacted"
          [rows]="10"
          paginatorPosition="bottom"
          styleClass="p-datatable-customers"
          [rowsPerPageOptions]="[10,25,50,100]"
          currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
          [globalFilterFields]="['solicitudeId','typeSolicitude','currentListPrice','priceAdjustment','hierarchyCode','numberImpactedClients']">

          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input pInputText type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'RoleComponent.SearchBy' | translate }}"/>
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="solicitudeId" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.RequestId' | translate }}
                </div>
              </th>

              <th pSortableColumn="typeSolicitude" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.TypeOfRequest' | translate }}
                </div>
              </th>

              <th pSortableColumn="currentListPrice" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.CurrentListWithoutTax' | translate }}
                </div>
              </th>

              <th pSortableColumn="priceAdjustment" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.SettingIn' | translate }}
                </div>
              </th>

              <th style="width:250px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.EffectiveDate' | translate }}
                </div>
              </th>

              <th pSortableColumn="hierarchyCode" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.HierarchyCode' | translate }}
                </div>
              </th>

              <th pSortableColumn="numberImpactedClients" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.ImpactedCustomers' | translate }}
                </div>
              </th>

              <th style="width:80px">
                <div class="p-d-flex p-jc-between p-ai-center">
                 {{ 'PendingSolicitudeComponent.RulesImpacted.State' | translate }}
                </div>
              </th>
              <th style="width:220px">{{ 'Actions' | translate }}</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
            <tr>
              <td style="width:150px; cursor: pointer; color: rgb(98, 98, 202);" 
                  [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'"
                  (click)="seeMore(object, true)"> 
               {{object.solicitudeId}}
              </td>
              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">{{object.typeSolicitude}}</td>
              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">$ {{object.currentListPrice}}</td>

              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                <div *ngIf="(object.solicitudeId != auxId || isEdit == false) 
                || isSuggestion == '1'">$ {{object.priceAdjustment}}
                </div>
               
                <div *ngIf="isEdit && object.solicitudeId == auxId && isSuggestion == '2'">
                <p-inputNumber [inputStyle]="{'width':'130px'}" mode="currency" currency="USD" locale="en-US"
                [(ngModel)]="selectedSetting" (ngModelChange)="onChangeSetting(object)"></p-inputNumber>
                <small *ngIf="isSetting == true"
                    class="p-error">{{ 'PendingSolicitudeComponent.RulesImpacted.MsgValidate' | translate }}</small>
                </div>

              </td>

              <td style="width:250px" [ngClass]="object.action === 0  ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                
                <div *ngIf="(object.solicitudeId != auxId || isEdit == false) || isSuggestion == '1'" 
                  [ngClass]="object.isOverlapDate === true ? 'color-text-2' : object.isOverlapDate ? 'color-text-1' : 'Ninguno'">
                  {{object.effectiveDateStart | date:'dd-MM-YYYY'}} - {{object.effectiveDateEnd | date:'dd-MM-YYYY'}}
                </div>

                <div *ngIf="isEdit && object.solicitudeId == auxId && isSuggestion == '2'">
                <p-calendar #cal2
                [(ngModel)]="selectedDate"
                (onSelect)="onChangeDate(object)"

                [touchUI]="true"
                selectionMode="range"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [showOtherMonths]="false"
                [yearRange]="getYearRange()"
                [showButtonBar]="true"
                inputId="icon"
                dateFormat="dd/mm/yy">
                <p-header class="p-2">
                  <i id="btnClose"
                  style="border:none"
                  class="uil uil-times enviar"
                  (click)="cal2.hideOverlay()" aria-hidden="true" ></i>
                </p-header>
                </p-calendar>
                <small *ngIf=" selectedDate == null || selectedDate.length == 1"
                    class="p-error">{{ 'PendingSolicitudeComponent.RulesImpacted.MsgValidate' | translate }}</small>
                </div>
              </td>

              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">{{object.hierarchyCode}}
                <i class="uil uil-exclamation-circle Exclamation ruleTooltip" aria-hidden="true"></i>
                <small class="TooltipRule">{{object.information}}</small>
              </td>

              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
              <div [ngClass]="object.isClientsImpacted === true ? 'color-text-2' : object.isClientsImpacted ? 'color-text-1' : 'Ninguno'">{{object.numberImpactedClients}}</div></td>
             
              <td style="width:80px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                <span [ngClass]="object.status === false ? 'color-notificacion-7' : object.status ? 'color-notificacion-5' : 'Ninguno'"></span>
              </td>

              <td style="width:220px;" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                <div *ngIf="object.action !=0">
                  
                  <button id="btnEdit"
                  *ngIf="isSuggestion == '2' && object.isColor == true "
                  type="button" pTooltip="{{'Edit' | translate}}"
                  style="width: 40px;"               
                  class="edit btn btn-secundary-editar"
                  (click)="edit(object.solicitudeId, object.effectiveDateStart, object.effectiveDateEnd, object.priceAdjustment)">
                  <i  class="uil uil-edit eliminar recalculatee" aria-hidden="true" style="color:#76f322fa;"></i>
                  </button>

                  <button id="btnEdit"
                  *ngIf="isSuggestion == '2' && object.isColor == false"
                  type="button" pTooltip="{{'Edit' | translate}}"
                  style="width: 40px;"               
                  class="edit btn btn-secundary-editar"
                  (click)="edit(object.solicitudeId, object.effectiveDateStart, object.effectiveDateEnd, object.priceAdjustment)">
                  <i class="uil uil-edit Editar" aria-hidden="true"></i>
                  </button>

                <button id="btnSync"
                  *ngIf="isSuggestion == '2' && (isRecalculation && object.solicitudeId == auxId)"
                  pTooltip="{{'Recalculate' | translate}}" type="button"
                  style="width: 40px;"
                  class="delete btn btn-secundary-delete"
                  (click)="recalculateDate(object)">
                  <i class="uil uil-sync eliminar recalculatee" aria-hidden="true" style="color:#76f322fa;" ></i>
                </button>
                <button id="btnUser"
                pTooltip="{{'ConfirmRule' | translate}}" type="button"
                  style="width: 40px;"
                  class="delete btn btn-secundary-delete"
                  (click)="confirmRule(object.solicitudeId)">
                  <i class="uil uil-user-check eliminar rule" aria-hidden="true" style="color:#662d91"></i>
                </button>
                <button id="btnDelete" *ngIf="isSuggestion == '1' || object.hierarchyCode !== 509 "
                  pTooltip="{{'Delete' | translate}}" type="button"
                  style="width: 40px;"
                  class="delete btn btn-secundary-delete"
                  (click)="deleteLog(object.solicitudeId)">
                  <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
                </button>
                </div>
                
                <div *ngIf="object.action == 0">
                  <button id="btnActive"
                  pTooltip="{{'Activate' | translate}}" type="button"
                  style="width: 40px;"
                  class="delete btn btn-secundary-delete"
                  (click)="activeRule(object)">
                  <i class="uil uil-plus Editar" aria-hidden="true"></i>
                </button>
                </div>
              </td>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">{{ messageTable }}</td>
            </tr>
          </ng-template>
        </p-table>

        <div class="mt-5"></div>

        <div class="col-md-6">
          <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.RulesImpacted.TitleSuggested' | translate }}</h1>
        </div>
        
          <p-table #dt
          name="gridpendingSolicitude"
          dataKey="pendingSolicitude"
          [paginator]="true"
          [scrollable]="true"
          [value]="rulesSuggested"
          [rows]="10"
          paginatorPosition="bottom"
          styleClass="p-datatable-customers"
          [rowsPerPageOptions]="[10,25,50,100]"
          currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
          [globalFilterFields]="['RequestId','TypeOfRequest','CurrentListWithoutTax','SettingIn','HierarchyCode','ImpactedCustomers']">

          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input pInputText type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'RoleComponent.SearchBy' | translate }}"/>
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="solicitudeId" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.RequestId' | translate }}
                </div>
              </th>

              <th pSortableColumn="typeSolicitude" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.TypeOfRequest' | translate }}
                </div>
              </th>

              <th pSortableColumn="currentListPrice" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.CurrentListWithoutTax' | translate }}
                </div>
              </th>

              <th pSortableColumn="priceAdjustment" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.SettingIn' | translate }}
                </div>
              </th>

              <th style="width:250px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.EffectiveDate' | translate }}
                </div>
              </th>

              <th pSortableColumn="hierarchyCode" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.HierarchyCode' | translate }}
                </div>
              </th>

              <th pSortableColumn="numberImpactedClients" style="width:150px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'PendingSolicitudeComponent.RulesImpacted.ImpactedCustomers' | translate }}
                </div>
              </th>

              <th style="width:80px">
                <div class="p-d-flex p-jc-between p-ai-center">
                 {{ 'PendingSolicitudeComponent.RulesImpacted.State' | translate }}
                </div>
              </th>
              <th style="width:220px">{{ 'Actions' | translate }}</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
            <tr>
              <td style="width:150px; cursor: pointer; color: rgb(98, 98, 202);" 
                  [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'"
                  (click)="seeMore(object, false)"> 
               {{object.solicitudeId}}
              </td>
              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">{{object.typeSolicitude}}</td>
              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">$ {{object.currentListPrice}}</td>

              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                <div *ngIf="(object.solicitudeId != auxId || isEdit == false) 
                || isSuggestion == '1'">$ {{object.priceAdjustment}}
                </div>
               
                <div *ngIf="isEdit && object.solicitudeId == auxId && isSuggestion == '2'">
                <p-inputNumber [inputStyle]="{'width':'130px'}" mode="currency" currency="USD" locale="en-US"
                [(ngModel)]="selectedSetting" (ngModelChange)="onChangeSetting(object)"></p-inputNumber>
                <small *ngIf="isSetting == true"
                    class="p-error">{{ 'PendingSolicitudeComponent.RulesImpacted.MsgValidate' | translate }}</small>
                </div>

              </td>

              <td style="width:250px" [ngClass]="object.action === 0  ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                
                <div *ngIf="(object.solicitudeId != auxId || isEdit == false) || isSuggestion == '1'" 
                  [ngClass]="object.isOverlapDate === true ? 'color-text-2' : object.isOverlapDate ? 'color-text-1' : 'Ninguno'">
                  {{object.effectiveDateStart | date:'dd-MM-YYYY'}} - {{object.effectiveDateEnd | date:'dd-MM-YYYY'}}
                </div>

                <div *ngIf="isEdit && object.solicitudeId == auxId && isSuggestion == '2'">
                <p-calendar #cal2
                [(ngModel)]="selectedDate"
                (onSelect)="onChangeDate(object)"

                [touchUI]="true"
                selectionMode="range"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [showOtherMonths]="false"
                [yearRange]="getYearRange()"
                [showButtonBar]="true"
                inputId="icon"
                dateFormat="dd/mm/yy">
                <p-header class="p-2">
                  <i id="btnClose"
                  style="border:none"
                  class="uil uil-times enviar"
                  (click)="cal2.hideOverlay()" aria-hidden="true" ></i>
                </p-header>
                </p-calendar>
                <small *ngIf=" selectedDate == null || selectedDate.length == 1"
                    class="p-error">{{ 'PendingSolicitudeComponent.RulesImpacted.MsgValidate' | translate }}</small>
                </div>
              </td>

              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">{{object.hierarchyCode}}
                <i class="uil uil-exclamation-circle Exclamation ruleTooltip" aria-hidden="true"></i>
                <small class="TooltipRule">{{object.information}}</small>
              </td>

              <td style="width:150px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
              <div [ngClass]="object.isClientsImpacted === true ? 'color-text-2' : object.isClientsImpacted ? 'color-text-1' : 'Ninguno'">{{object.numberImpactedClients}}</div></td>
             
              <td style="width:80px" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                <span [ngClass]="object.status === false ? 'color-notificacion-7' : object.status ? 'color-notificacion-5' : 'Ninguno'"></span>
              </td>

              <td style="width:220px;" [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
                <div *ngIf="object.action == 0">
                  <button id="btnActive"
                  pTooltip="{{'Activate' | translate}}" type="button"
                  style="width: 40px;"
                  class="delete btn btn-secundary-delete"
                  (click)="activeRule(object)">
                  <i class="uil uil-plus Editar" aria-hidden="true"></i>
                </button>
                </div>
              </td>
          </ng-template>

          <!-- <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">{{ messageTable }}</td>
            </tr>
          </ng-template> -->
          </p-table>
        
        

        


        <div class="row">
          <div class="col-md-8"></div>
          <div class="col-md-4" *ngIf="status === true">
            <button (click)="reject()" class="pstn_rechazar float-right" *ngIf="rolePermission.create | rolePermission">
              <i class="uil uil-times-circle" aria-hidden="true"></i>
              {{ 'PendingSolicitudeComponent.Reject' | translate }}
            </button>
            <button *ngIf="buttonView && rolePermission.create | rolePermission" (click)="approvalReject(true)" [disabled]="disabledButton" class="pstn_aceptar float-right">
              <i class="uil uil-check-circle" aria-hidden="true"></i>
              {{ 'PendingSolicitudeComponent.Approve' | translate }}
            </button>
          </div>
        </div>

      </div>
    </div>
</section>

<p-dialog [(visible)]="infoModal" header="{{ 'ChannelComponent.TitleModal' | translate }}" [modal]="true"
  [closable]="false" styleClass="p-fluid" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">

  <ng-template pTemplate="content">

    <div class="col-md-auto">
      <div *ngFor="let msn of listMsn">
        <div *ngIf="msn.type =='Success'" [ngStyle]="{color:'#4caf50'}">
          <i class="pi pi-check" aria-hidden="true"></i>
          <label class="p-d-flex p-jc-between p-ai-center"
            [ngStyle]="{color:'#4caf50'}"><strong>{{msn.title}}</strong></label>
          <label class="p-d-flex p-jc-between p-ai-center" [ngStyle]="{color:'#4caf50'}">{{msn.text}}</label>
        </div>

        <div *ngIf="msn.type =='Warning'">
          *
          <label class="p-d-flex p-jc-between p-ai-center"
            [ngStyle]="{color:'rgb(236 214 107)'}"><strong>{{msn.title}}</strong></label>
          <label class="p-d-flex p-jc-between p-ai-center" [ngStyle]="{color:'rgb(236 214 107)'}">{{msn.text}}</label>
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template pTemplate="footer">
    <button id="btnCreateDomain" pButton pRipple type="button" label="{{ 'CloseBtn' | translate }}"
      class="pstn_primario" (click)="acept()">
    </button>
  </ng-template>

</p-dialog>

<p-dialog [(visible)]="isTypeDialog"
          header="{{ 'PendingSolicitudeComponent.ImpactAnalysis.RejectionReasonTitle' | translate }}"
          [modal]="true"
          styleClass="p-fluid"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '35vw'}">

  <ng-template pTemplate="content">
     <div class="col-md-auto">
      <h6>{{ 'PendingSolicitudeComponent.ImpactAnalysis.RejectionReason' | translate }}</h6>
      <textarea [rows]="5"
                [cols]="30"
                pInputTextarea
                [(ngModel)]="commentary"
                autoResize="autoResize"
                autocomplete="off"
                class="form-control p-d-flex p-jc-between p-ai-center"
                required>
              </textarea>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button id="btnEnviar"
            pButton
            pRipple
            type="button"
            label="{{ 'SendBtn' | translate }}"
            class="pstn_primario"
            (click)="approvalReject(false)">
    </button>
  </ng-template>

</p-dialog>

<p-dialog 
header="{{ 'Detail' | translate}}"
[(visible)]="displayModalDetails"
[modal]="true"
[style]="{ width: '65vw' }"
[baseZIndex]="10000"
[draggable]="false"
[resizable]="false"
[closeIcon]="false">



<div class="mt-5"></div>

<app-assing-clients 
[showFields]="showFieldClientFilter"
[validateFilter]="true"
[requiredFields]="false"
[filtersEdit]="filtersEdit"
[isProcess]="isProcess"
[clientsEdit]="clientsEdit"
[isCreate]="false"
[visibleHideFilterClient]="false"
>
</app-assing-clients>

<div class="mt-5"></div>

<div class="container">
  <app-price-certificate #priceCertificate
  [isCreate]="false"
  >
  </app-price-certificate>
</div>

<div class="mt-5"></div>

<div>
  <p-accordion>
    <p-accordionTab  header="Regla Impactada"
    [selected]="true">
    <div class="container-fluid card-container" style="display: flex; flex-direction: row; flex-wrap: wrap;">
      <div *ngFor="let analysisChart of analysisCharts2" >
        <app-impact-analyses-card [item]="analysisChart" (eventEmitterCard)="getEventEmitterCard2($event)"
          [toSendCalculatedValue$]="toSendCalculatedValue2$"></app-impact-analyses-card>
      </div>
      <div class="card-container-sugerencia">
        <p-card class="card-container-sugerencia-pcard">
          <span class="title">{{ 'PendingSolicitudeComponent.ImpactAnalysis.suggestion' | translate }}:</span>
          <span class="description"
            *ngIf="calculateWeightedWeight &&
            (calculateWeightedWeight*100 > analysisChartImpactPorcentage?.umbral*100)">
            {{ 'PendingSolicitudeComponent.ImpactAnalysis.Pricechange' | translate }}
          </span>
          <span class="description"
            *ngIf="calculateWeightedWeight &&
            (calculateWeightedWeight*100 < analysisChartImpactPorcentage?.umbral*100)">
            {{ 'PendingSolicitudeComponent.ImpactAnalysis.PriceAdjustments' | translate }}
          </span>
          <span class="description" *ngIf="calculateWeightedWeight==null"> </span>
        </p-card>
      </div>
    </div>
    </p-accordionTab>
  </p-accordion>
</div>

<div class="mt-5"></div>

<div class="container">

  <p-table #dt
  name="gridpendingSolicitude"
  dataKey="pendingSolicitude"
  [paginator]="false"
  [scrollable]="true"
  [value]="rulesImpactedShow"
  [rows]="10"
  paginatorPosition="bottom"
  styleClass="p-datatable-customers"
  [rowsPerPageOptions]="[10,25,50,100]"
  currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
  >
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="RequestId" style="width:200px">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'PendingSolicitudeComponent.RulesImpacted.RequestId' | translate }}
        </div>
      </th>
  
      <th pSortableColumn="TypeOfRequest" style="width:200px">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'PendingSolicitudeComponent.RulesImpacted.TypeOfRequest' | translate }}
        </div>
      </th>
  
      <th pSortableColumn="CurrentListWithoutTax" style="width:200px">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'PendingSolicitudeComponent.RulesImpacted.CurrentListWithoutTax' | translate }}
        </div>
      </th>
  
      <th pSortableColumn="SettingIn" style="width:200px">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'PendingSolicitudeComponent.RulesImpacted.SettingIn' | translate }}
        </div>
      </th>
  
      <th style="width:200px">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'PendingSolicitudeComponent.RulesImpacted.EffectiveDate' | translate }}
        </div>
      </th>
  
      <th pSortableColumn="HierarchyCode" style="width:150px">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'PendingSolicitudeComponent.RulesImpacted.HierarchyCode' | translate }}
        </div>
      </th>
      
    </tr>
  </ng-template>
  
  <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
    <tr>
      <td style="width:200px;">
        {{object.solicitudeId}}
      </td>
      <td style="width:200px">
        {{object.typeSolicitude}}
      </td>
      <td style="width:200px">
        $ {{object.currentListPrice}}
      </td>
  
      <td style="width:200px">
        <div>
          $ {{object.priceAdjustment}}
        </div>
      </td>
  
      <td style="width:200px">
        
        <div>
          {{object.effectiveDateStart | date:'dd-MM-YYYY'}} - {{object.effectiveDateEnd | date:'dd-MM-YYYY'}}
        </div>
      </td>
  
      <td style="width:150px" 
        [ngClass]="object.action === 0 ? 'color-row-disabled' : object.action ? 'color-row-enabled' : 'Ninguno'">
        {{object.hierarchyCode}}
      </td>
  </ng-template>
  
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3">{{ messageTable }}</td>
    </tr>
  </ng-template>
  </p-table>
</div>
<ng-template pTemplate="footer">
  <button pButton
          pRipple
          label="{{ 'CloseBtn' | translate}}"
          icon="pi pi-times"
          class="p-button-text"
          (click)="hide()"></button>
</ng-template>
  
</p-dialog>

<p-toast></p-toast>


