import { DataRangeModel } from './data-range.model';

export class PromotionModel {
  constructor(
    public numberDays: number,
    public startTime: string,
    public endTime: string,
    public orderLimit: number,
    public periodLimit: number,
    public statusDomainId: number,
    public dataRanges: DataRangeModel[]
  ) {}
}
