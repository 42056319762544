import { Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationConstants } from '@app/app.constants';
import { ListOperationByRoleModel } from '@app/@modules/administration/select-profile-module/models/list-operation-by-role.model';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';

@Pipe({name: 'rolePermission'})

export class RolePermissionPipe implements PipeTransform {
  private readonly router: Router;
  public operations: ListOperationByRoleModel[];

  constructor(router: Router
  ){
    this.router = router;
  }

  transform(action: number): boolean {

    const rutaPadre = this.router.url.split('/')[2];
    this.operations = JSON.parse(sessionStorage.getItem(ApplicationConstants.CONSTANTS.listOperation));
    const element = this.operations.find((x) => x.route.split('/')[2] === rutaPadre);

    if(element !== undefined && element != null && element){
      switch (action) {
        case RolePermissionEnum.create:
          return element.iCreate;
        case RolePermissionEnum.update:
          return element.iUpdate;
        case RolePermissionEnum.read:
          return element.iRead;
        case RolePermissionEnum.delete:
          return element.iDelete;
        default:
          return false;
      }
    }
  }

}
