import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@core/logger.service';
import { MonitoringService } from '@core/monitoring.service';
import { Subscription } from 'rxjs';
import { ApplicationConstants } from '@app/app.constants';
import { ListOperationByRoleModel } from '@app/@modules/administration/select-profile-module/models/list-operation-by-role.model';
const log = new Logger('RouteGuard');

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {

  subscription: Subscription;
  operations: ListOperationByRoleModel[];
  constructor(
    private readonly router: Router,
    private readonly monitoringService: MonitoringService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    this.operations = JSON.parse(sessionStorage.getItem(ApplicationConstants.CONSTANTS.listOperation));
    const rutaPadre = state.url.split('/')[2];
    const element = this.operations.find((x) => x.route.split('/')[2] === rutaPadre);
    if (element) {
      return true;
    }

    log.debug('Not permissions, redirecting and adding redirect url...');
    this.monitoringService.logEvent(
      'Not permissions, redirecting and adding redirect url...'
    );
    this.router.navigate(['/auth'], {
      replaceUrl: true,
    });

    return false;
  }
}
