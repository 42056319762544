import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { ComercialActivityEnum } from '../../@shared/enums/comercial-activity.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-tables-with-groups',
  templateUrl: './tables-with-groups.component.html',
  styleUrls: ['./../../../app/@shared/scss/responsive.table.scss'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
})
export class TablesWithGroupsComponent implements OnInit {

  public options: Array<any> = [];
  public selectedRows: [];
  public selectedSubOptions: [];
  public processEnum: any;
  public rolePermission: any = RolePermissionEnum;

  private readonly spinner: NgxSpinnerService;
  private readonly coreService: CoreMasterService;

  @Input() headersTable: Array<any>;
  @Input() subHeaderTable: Array<any>;
  @Input() valueTable: Array<any>;
  @Input('isProcess') isProcess: number;
  @Output() deleteGroupEvent = new EventEmitter<any>();
  @Output() deleteSelectedEvent: any = new EventEmitter<any>();
  @Output() deleteSelectedGroupEvent: any = new EventEmitter<any>();

  readonly priceMask = createNumberMask({
    allowDecimal: true,
    decimalSymbol: '.',
    integerLimit: 5,
    prefix: '',
    thousandsSeparatorSymbol: ',',
    allowNegative  : false
  });

  constructor(spinner: NgxSpinnerService,
  coreService: CoreMasterService)
  {
    this.spinner = spinner;
    this.coreService = coreService;
  }

  ngOnInit(): void {
    this.processEnum = this.getCommercialActivityEnum();
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }


  public checkRange = function (event: any, value: any, max: any = 100) {
    const keyCod = event.keyCode;
    const key = event.key;
    let numbersAsString;
    if (Number(key) || Number(key) === 0) {
      if (value == null) {
        value = '';
      }
      numbersAsString = `${value}${key}`;
      if (parseInt(numbersAsString, 10) > parseInt(max, 10)) {
        event.preventDefault();
        event.stopPropagation();
      }
    }

    if (
      ![45, 8, 39, 37, 190].includes(keyCod) &&
      isNaN(Number(key))
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  public checkRange2 = function (event: any, value: any, max: any = 100, object : any, object2: any) {
    const keyCod = event.keyCode;
    const key = event.key;
    let numbersAsString;
    if (Number(key) || Number(key) === 0) {
      if (value == null) {
        value = '';
      }
      numbersAsString = `${value}${key}`;
      if (parseInt(numbersAsString, 10) > parseInt(max, 10)) {
        event.preventDefault();
        event.stopPropagation();
      }
    }

    if (
      ![45, 8, 39, 37, 190].includes(keyCod) &&
      isNaN(Number(key))
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  public changeValue(event: any,object: any){

    if(event.target.value){
      const auxValue = Number(event.target.value.replace(/,/g, '.'));
      event.target.value = auxValue.toFixed(2);
    }

    if(event.target.value > 100){
      event.target.value = 100.00;
    }

    this.valueTable.forEach((elm) => {
      elm.subOptions.forEach((x: any) => {
        if(x.sku === object.sku){
          if(x.percentDiscountValue  || x.percentDiscountValue >= 0){
            x.percentDiscountValue = Number(event.target.value);
          }else if (x.percentDiscountKind || x.percentDiscountKind >= 0){
            x.percentDiscountKind = Number(event.target.value);
          }else if (x.percentageDiscountFinancial || x.percentageDiscountFinancial >= 0){
            x.percentageDiscountFinancial = Number(event.target.value);
          }
        }
      });
    });
  }

  public selectRow(event: any, object: any) {

    if (this.valueTable) {
        this.valueTable.forEach((group) => {
          group.subOptions.forEach((option: any) => {
            if (object.sku === option.sku) {
              option.check = event;
              this.deleteSelectedEvent.emit(object);
            }
          });
        });
    } else {
      object.check = event;
    }

  }

  public selectGroup(event: any, object:any){
    this.deleteSelectedGroupEvent.emit(object);
  }

  public selectAll(event : any, object : any) {
    if(event.checked === true) {
      this.valueTable.forEach((group) => {
        if(object.descGroup === group.descGroup){
          group.subOptions.forEach((options: any) => {
            options.check = event.checked;
            this.deleteSelectedEvent.emit(options);
          });
        }
      });
    } else{
      this.valueTable.forEach((group) => {
        if(object.descGroup === group.descGroup){
          group.subOptions.forEach((options: any) => {
            options.check = event.checked;
            this.deleteSelectedEvent.emit(options);
          });
        }
      });
    }
  }

  public deleteGroupAction(value: any) {
    this.deleteGroupEvent.emit(value);
  }

  public onSearchLead(event: any, options: any) {

  }

  public checkTNumber = (event: KeyboardEvent) => {
    const keyCod = event.keyCode;
    const key = event.key;
    if (![45, 8, 190].includes(keyCod) && isNaN(Number(key))) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  public keyupMaxNumber = (event: any, object : any) => {

    let auxSumPurchase = 0;

    if(Number(event.target.value) > 9999){
      event.target.value = 9999;
    }

    this.valueTable.forEach((group) => {

      if(group.descGroup === object.descGroup){
        group.subOptions.forEach((options: any) => {
          if(Number(options.purcheseUnit) > 9999){
            options.purcheseUnit = 9999;
          }
          auxSumPurchase += Number(options.purcheseUnit);
        });
        group.purcheseUnit = auxSumPurchase;
      }
    });
  };

}
