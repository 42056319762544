<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ 'Negotiations.Create.Title' | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
      <section class="generalData">

          <div class="row title">
              <div class="col-md-6">
                  <h1 class="pstntitle">
                      {{ 'Negotiations.Create.Title' | translate }}
                  </h1>
              </div>
          </div>

          <form id="formNegotiation" [formGroup]="formNegotiation">

            <div class="col-sm-4 mt-4">
              <div class=" form-group">
                <label>{{ 'Negotiations.Create.TypeNegotiation' | translate }}</label>
                <div class="form-group">
                  <p-multiSelect id="listTypeNegotiation" class="multiselect-custom-virtual-scroll"
                    [virtualScroll]="true" [options]="objectChannel" formControlName="TypeNegotiation" [filter]="true"
                    placeholder="{{'Negotiations.Create.TypeNegotiation' | translate}}" optionLabel="label"
                    optionValue="value" display="chip" [style]="{width: '100%'}">
                  </p-multiSelect>
                  <small id="typeNegotiation" *ngIf="
                    formNegotiation.controls['TypeNegotiation'].invalid &&
                    (formNegotiation.controls['TypeNegotiation'].dirty ||
                    formNegotiation.controls['TypeNegotiation'].touched)" class="p-error">
                    {{"Negotiations.Create.TypeNegotiationMsgValidate" | translate}}
                  </small>
                </div>
              </div>
            </div>

          </form>

      </section>
  </div>
</section>
