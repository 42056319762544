<form id="formRole"
      [formGroup]="formAdvancedFilters">
  <div class="row">
    <div class="col-md-5">
      <label for="exampleInputEmail1">{{ 'AdvancedFiltersComponent.TypeFilter' | translate }}</label>
      <div class="form-group">
        <p-dropdown id="type"
                    [filter]="true"
                    [ngClass]="{'ng-invalid ng-dirty':type.invalid && type.touched}"
                    formControlName="type"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="{{ 'AdvancedFiltersComponent.TypeFilterPlaceholder' | translate }}"
                    [options]="typeFiltersObjectList"
                    [style]="{ width: '100%' }">
        </p-dropdown>
        <small id="profileIdValidator"
               *ngIf="formAdvancedFilters.controls['type'].invalid && 
               (formAdvancedFilters.controls['type'].dirty || 
               formAdvancedFilters.controls['type'].touched)"
               class="p-error">{{ 'AdvancedFiltersComponent.TypeFilterMsgValidate' | translate }}</small>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">{{ 'AdvancedFiltersComponent.ContentSearch' | translate }}</label>
        <input id="content"
               formControlName="content"
               autocomplete="off"
               class="form-control"
               placeholder="agua;mili;30;%bote%;%25%"
               [ngClass]="{'ng-invalid ng-dirty':content.invalid && content.touched}"
               pInputText
               required />
        <small id="roleNameDetail"
               *ngIf="formAdvancedFilters.controls['content'].invalid && 
               (formAdvancedFilters.controls['content'].dirty || 
               formAdvancedFilters.controls['content'].touched)"
               class="p-error">{{ 'AdvancedFiltersComponent.ContentSearchMsgValidate' | translate }}</small>
      </div>
    </div>

    <div class="col-md-3 mt-4 align-items-end">
      <button id="btnAddFilter"
              (click)="add()"
              pButton
              pRipple
              style="width: 100%;"
              type="button"
              label="{{ 'AdvancedFiltersComponent.AddFilterBtn' | translate }}"
              class="p-button-rounded"
              *ngIf="this.isAdd"></button>

      <button id="btnUpdateFilter"
              (click)="update()"
              pButton
              pRipple
              style="width: 100%;"
              type="button"
              label="{{ 'AdvancedFiltersComponent.UpdateFilterBtn' | translate }}"
              class="p-button-rounded"
              *ngIf="!this.isAdd"></button>
    </div>
  </div>

</form>


<div class="col-md-12">
  <h2 class="pstn_subtitle">{{ 'AdvancedFiltersComponent.SelectedFilters' | translate }}</h2>  
  {{ 'AdvancedFiltersComponent.TitleClearFilter' | translate }}
  <button id="btnDelete"
          type="button"
          class="delete btn btn-secundary-delete"
          (click)="deleteFilters()">
    <i class="uil uil-trash-alt eliminar" aria-hidden="true" ></i>
  </button>
</div>
<!-- PrimeNg Table -->
<p-table #dt
         name="gridadvancedFilters"
         [value]="advancedFiltersObjectList"
         dataKey="id"
         [paginator]="true"
         [rows]="10"
         paginatorPosition="bottom"
         styleClass="p-datatable-customers"
         [globalFilterFields]="['type' ,'content']">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="type">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'AdvancedFiltersComponent.TypeFilter' | translate }}
          <p-sortIcon field="type"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="content">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ 'AdvancedFiltersComponent.ContentSearch' | translate }}
          <p-sortIcon field="content"></p-sortIcon>
        </div>
      </th>
      <th>{{ 'Actions' | translate }}</th>
    </tr>
    <tr>
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
               let-rowIndex="rowIndex"
               let-object>
    <tr>
      <td>
        {{object.typeDescription}}
      </td>
      <td>
        {{object.content}}
      </td>
      <td>
        <button id="btnEdit"
                type="button"
                ngbTooltip="Editar"
                class="edit btn btn-secundary-editar"
                (click)="edit(rowIndex, object)">
          <i class="uil uil-edit Editar" aria-hidden="true" ></i>
        </button>
        <button id="btnDelete"
                ngbTooltip="Eliminar"
                type="button"
                class="delete btn btn-secundary-delete"
                (click)="delete(object)">
          <i class="uil uil-trash-alt eliminar" aria-hidden="true" ></i>
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
<!-- PrimeNg Table -->


<div class="row">
  <div class="col-md-5"></div>
  <div class="col-md-4"></div>
  <div class="col-md-3 mt-2 align-items-end">
    <button id="btnRunFilters"
            pButton
            pRipple
            type="button"
            label="{{ 'AdvancedFiltersComponent.ExecuteFilter' | translate }}"
            class="p-button-rounded"
            style="width: 100%;"
            (click)="eventClickFilters($event)"></button>
  </div>


</div>
