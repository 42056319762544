import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Util } from '@app/@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ApplicationConstants } from '@app/app.constants';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { IHierarchyReleaseJson } from './interface/hierarchy-release-json.interface';
import { PaginationByIdModel } from '@app/@shared/model/pagination-by-id.model';
import { HierarchyReleaseService } from './hierarchy-release.service';
import { HierarchyReleaseStatusModel } from './models/hierarchy-release-status.model';
import { CreateService } from './create/create.service';
import { ProcessHierarchyReleaseEnum } from '@app/@shared/enums/hierarchy-release.enum';
import { HierarchyReleaseModel } from './create/models/hierarchy-release.model';
import { IHierarchyReleaseAssociateJson } from './interface/hierarchy-release-associate-json.interface';
import { HierarchyReleaseAssociateModel } from './create/models/hierarchy-release-associate.model';
import { AssociateUserUpdateModel } from './associate/models/associate-user-update.model';
import { IHierarchyReleaseAssociateUserJson } from './interface/hierarchy-release-associate-user-json.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy-release.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService],
})
export class HierarchyReleaseComponent implements OnInit {
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly router: Router;
  private readonly hierarchyReleaseService: HierarchyReleaseService;
  private readonly createHierarchyService: CreateService;

  public objectList: IHierarchyReleaseJson[];
  public selectObject: IHierarchyReleaseJson[];
  public rolePermission: any = RolePermissionEnum;

  constructor(
    spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router,
    hierarchyReleaseService: HierarchyReleaseService,
    createHierarchyService: CreateService
  ) {
    this.spinner = spinner;
    this.messageService = messageService;
    this.router = router;
    this.hierarchyReleaseService = hierarchyReleaseService;
    this.createHierarchyService = createHierarchyService;
  }

  /**
   * @description this method is private.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  ngOnInit(): void {
    this.hierarchyReleaseService.setHierarchyReleaseId(0);
    this.getAll();
  }

  /**
   * @description this method is to get all the Approval Rank.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  getAll(): void {
    this.spinner.show();
    const paginationById = new PaginationByIdModel('Id', true, 1, 1000, 0);
    this.hierarchyReleaseService
      .getListHierarchyRelease(paginationById)
      .subscribe(
        (response) => {
          this.objectList = response.data;
        },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
  * @description This method redirect to create.
  * @author Jhon Steven Pavón Bedoya
  * @sprint 8
  * @returns none
  */
  onCreate(){
    this.createHierarchyService.setProcessHierarchyRealese(ProcessHierarchyReleaseEnum.create);
    this.createHierarchyService.setInfoHierarchyModel(null);
    this.router.navigate(['auth/hierarchy-release/create']);
  }

  /**
   * @description this method is to edit.
   * @author Priscy Antonio Reales
   * @sprint 7
   */
  onEdit(object: IHierarchyReleaseJson): void {
    Util.confirmEdit().then((result: SweetAlertResult) => {
      if (result.value) {
        this.createHierarchyService.setHierarchyIdEdit(object.id);
        this.createHierarchyService.setProcessHierarchyRealese(ProcessHierarchyReleaseEnum.update);
        const hierarchy = this.setHierarchyRelease(object);
        this.createHierarchyService.setInfoHierarchyModel(hierarchy);
        this.router.navigate(['auth/hierarchy-release/create']);
      }
    });
  }

  /**
   * @description this method is to see record.
   * @author Priscy Antonio Reales
   * @sprint 7
   */
   onSeeRecord(object: IHierarchyReleaseJson): void {
    Util.confirmSee().then((result: SweetAlertResult) => {
      if (result.value) {
        this.createHierarchyService.setProcessHierarchyRealese(ProcessHierarchyReleaseEnum.view);
        this.createHierarchyService.setInfoHierarchyModel(this.setHierarchyRelease(object));
        this.router.navigate(['auth/hierarchy-release/create']);
      }
    });
  }

  setHierarchyRelease(object: IHierarchyReleaseJson): HierarchyReleaseModel{
    const hierarchy = new HierarchyReleaseModel(
      object.id,
      object.description,
      object.countryCode,
      object.societyCode,
      object.channelCode,
      object.regionCode,
      object.cediCode,
      object.macrosegmentCode,
      object.businessTypologyCode,
      object.processId,
      `${object.processId}-${object.processCode}`,
      object.subprocessId,
      this.setHierarchyAssociateRank(object.hierarchyReleaseAssociateJson)
      );

      return hierarchy;
  }

  setHierarchyAssociateRank(associateRankJson: IHierarchyReleaseAssociateJson[]): HierarchyReleaseAssociateModel[] {
    const listAssociateRank : HierarchyReleaseAssociateModel[] = [];

    if(associateRankJson !== undefined && associateRankJson.length > 0){
      associateRankJson.forEach((v) => {
        const associate = new HierarchyReleaseAssociateModel(
          0,
          v.approvalLevel.split('-')[1],
          v.approvalRankLevelId,
          v.operatorStart,
          v.rankStart,
          v.operatorEnd,
          v.rankEnd,
          v.unitMeasurementId,
          v.unitMeasurementDescription,
          this.setHierarchyAssociateRelease(v.approvalLevel.split('-')[0], v.hierarchyReleaseAssociateUserJson)
        );
        listAssociateRank.push(associate);
      });
    }


    return listAssociateRank;
  }

  setHierarchyAssociateRelease(profileId: string, associateRelease: IHierarchyReleaseAssociateUserJson[]): AssociateUserUpdateModel[]{
    const lisAssociateRelease :AssociateUserUpdateModel [] = [];
    if(associateRelease !== undefined && associateRelease.length > 0){
      associateRelease.forEach((v) => {
        const associateRelease = new AssociateUserUpdateModel(
          v.hierarchyReleaseAssociateId,
          v.userName,
          v.userLastName,
          v.email,
          v.user,
          +profileId,
          v.isMandatory,
          v.isAuthorizer,
          v.isApproval
        );
        lisAssociateRelease.push(associateRelease);
      });
    }
    return lisAssociateRelease;
  }



  /**
   * @description this method is to delete.
   * @author Priscy Antonio Reales
   * @sprint 7
   */
  onDelete(object: IHierarchyReleaseJson): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.hierarchyReleaseService
          .deleteLogic(object.id.toString())
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.getAll();
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
    });
  }

  /**
   * @description this method is to copy .
   * @author Priscy Antonio Reales
   * @sprint 7
   * @param objectValue
   */
  onCopy(objectValue: IHierarchyReleaseJson): void {
    Util.confirmCopy().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();

        const model: HierarchyReleaseStatusModel =
          new HierarchyReleaseStatusModel(
            objectValue.id,
            'Copia',
            objectValue.description
          );

        this.hierarchyReleaseService.copy(model).subscribe(
          (response) => {
            if (response.status) {
              this.spinner.hide();
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertCopy :SweetAlert.messageAlertCopyTranslate);
              this.getAll();
            } else {
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });
  }

  /**
   * @description This method for delete in batch.
   * @author Priscy Antonio Reales
   * @sprint 7
   */

  public onDeleteBatch(): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        const Ids = this.selectObject
          .map((item: IHierarchyReleaseJson) => {
            return item.id;
          })
          .join();
        if (!Ids) {
          this.spinner.hide();
          return;
        }
        this.hierarchyReleaseService.deleteLogic(Ids).subscribe(
          (response) => {
            if (response.status) {
              this.getAll();
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconInfo,
                language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              this.spinner.hide();
            } else {
              this.spinner.hide();
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });
  }

  /**
   * @description This method shows the toast control.
   * @author Priscy Antonio Reales
   * @sprint 7
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }
}
