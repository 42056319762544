import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { IAdvancedFiltersClient } from '@app/@shared/interfaces/advanced-filters-client.interface';
import { AdvancedFiltersClientModel } from '@app/@shared/model/advanced-filters-client.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssingClientService } from '../assing-clients.service';
import { IDetailFilterClientsResult } from '../interfaces/detail-filter-client-result.interface';
import { IDetailFilterClientJson } from '../interfaces/details-filter-client-json.interface';
import { DetailFilterClientResultModel } from '../models/detail-filter-client-result.model';
import { DetailFilterClientModel } from '../models/details-filter-client-json.model';
import { SummaryClientModel } from '../models/summary-client.model';
import { CustomerSummaryService } from './customer-summary.service';
import { CustomerService } from '../services/customer.service';

@Component({
  selector: 'app-customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer-summary.component.css']
})
export class CustomerSummaryComponent implements OnInit{

  @Input('isProcess') isProcess: number;
  @Input('summaryClients') summaryClients: SummaryClientModel[];
  @Input('totalClients') totalClients: number;
  @Input('activeAccordionSummary') activeAccordionSummary: boolean;
  @Input('commercialActivityId') commercialActivityId: number;
  @Input('isHanlder') isHanlder: boolean = false;
  @Input('clientsFilter') clientsFilter: string;
  @Input('downloadFileClients') downloadFileClients: boolean = true;
  @Input('jsonFilterClient') jsonFilterClient: string;

  @Output('evenBtnShowEdit') evenBtnShowEdit: EventEmitter<any> = new EventEmitter();

  public detailsClientsResult: DetailFilterClientResultModel[];
  public detailsResult: IDetailFilterClientsResult[];
  public processEnum = ComercialActivityEnum;
  public rolePermission: any = RolePermissionEnum;
  public displayModal = false;
  public nameFile: string = "";
  public clientSummaryFilters: string = "";

  private readonly assingClientService: AssingClientService;
  private readonly summaryService: CustomerSummaryService;
  private readonly spinner: NgxSpinnerService;
  private readonly customerSummaryService: CustomerSummaryService;

  constructor(
    spinner: NgxSpinnerService,
    assingClientService: AssingClientService,
    customerSummaryService: CustomerSummaryService,
  ) {
    this.spinner = spinner;
    this.assingClientService = assingClientService;
    this.customerSummaryService = customerSummaryService;
   }

   ngOnInit(): void {
    this.processEnum = this.getCommercialActivityEnum();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const changeFiltersEdit: SimpleChange = changes['summaryClients'];
    if (changeFiltersEdit && changeFiltersEdit.currentValue && changeFiltersEdit.currentValue.length > 0) {
      this.evenBtnShowEdit.emit(true)
    }
  }

  public showModal(object: SummaryClientModel): void {
    const filterClientDetails: IDetailFilterClientJson = new DetailFilterClientModel(
      object.typeFilter,
      object.groupClients,
      object.codeType
    );

    const objFilters: IAdvancedFiltersClient =
      new AdvancedFiltersClientModel(
        JSON.stringify(filterClientDetails),
        true,
        1,
        100
      );
    this.assingClientService.postAdvancedFiltersClientsDetails(objFilters)
      .subscribe(
        (response) => {
          if (response.status) {
            this.detailsResult = response.data;
            const listDetails = this.detailsResult.map((item) => {
              return {
                type: item.type,
                sClientCode: item.sClientCode,
                sCustomerName: item.sCustomerName
              };
            });
            this.detailsClientsResult = [...listDetails];
          } else {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }

        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );

    this.displayModal = true;
  }

  public hideModal(): void {
    this.displayModal = false;
  }

  downloadFileCsv() {
    this.spinner.show();
    this.customerSummaryService.getFileCustomers(Number(this.commercialActivityId))
      .subscribe();
      this.spinner.hide();
  }


  downloadListClients() {
    let filterClientes = this.jsonFilterClient;
    const objFilters: IAdvancedFiltersClient = new AdvancedFiltersClientModel(
      filterClientes,
      true,
      1,
      100
    );
    this.customerSummaryService.getFile(objFilters).subscribe();
  }
  
  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }  

}
