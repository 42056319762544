<section>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="pstn breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth'"
              ><i class="uil uil-estate" aria-hidden="true"></i
              >{{ "Home" | translate }}</a
            >
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth/solicitude'">
              {{ "SolicitudComponent.Title" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "EliminateDifferentialComponent.Title" | translate }}
          </li>
        </ol>
      </nav>
    </div>
  </section>

  <section class="pstngestion">
    <div class="container">
      <div class="row">
        <h1 class="pstntitle">{{ "EliminateDifferentialComponent.Title" | translate }}</h1>
      </div>
      <div class="mt-3">
        <app-shared-advanced-filter [showFieldsComponent]="false"
                                    [validateFilter]="false"
                                    [showFieldsGeneral]="showFieldsGeneral"
                                    [showFieldsClient]="showFieldsClient"                                    
                                    (removeListAdvancedFilter)="getDataListAll($event)"
                                    (clearListAdvancedFilter)="getDataListAll($event)">

        </app-shared-advanced-filter>
      </div>
      <div class="row mt-5">
        
        <div class="pstn_container container-fluid">
            

            <p-table #dt
                     name="gridValidityPeriod"
                     [value]="objEliminateDifferential"
                     dataKey="id"
                     [paginator]="true"
                     [rows]="10"
                     [scrollable]="true"
                     currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
                     [rowsPerPageOptions]="[10,25,50,100]"
                     [(first)]="first"
                     paginatorPosition="bottom"
                     styleClass="p-datatable-customers"
                     [globalFilterFields]="['solicitudeId', 
                                            'materialCode', 
                                            'materialDesc', 
                                            'diferential', 
                                            'accessSequence', 
                                            'startDate', 
                                            'endDate', 
                                            'statusDesc']">
              <ng-template pTemplate="caption">
                <div class="p-d-flex row">
                    <div class="col-md-6 row">
                        <button id="btnDelete"
                                type="button"
                                class="delete btn btn-secundary-delete"
                                (click)="deleteAllSku()">
                            <i class="uil uil-trash-alt eliminar" aria-hidden="true" ></i>
                        </button>
                        <h6 style="color:black">{{ 'EliminateDifferentialComponent.SelectedSku' | translate }}</h6>
                    </div>
                    <div class="col-md-6">
                    <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search" aria-hidden="true"></i>
                        <input pInputText
                               type="text"
                               (input)="dt.filterGlobal($event.target.value, 'contains')"
                               placeholder="{{ 'SearchBy' | translate }}" />
                      </span>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                    <th style="width: 50px">
                        <p-checkbox (click)="selectAll(e.checked)" 
                            [(ngModel)]="checkDelete" binary="true" #e></p-checkbox>
                    </th>
                    <th style="width:100px">{{ 'Actions' | translate }}</th>
                  <th style="width:150px" pSortableColumn="solicitudeId">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.RequestId' | translate }}
                      <p-sortIcon field="solicitudeId"></p-sortIcon>
                    </div>
                  </th>
                  <th style="width:230px" pSortableColumn="materialCode">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.MaterialCode' | translate }}
                      <p-sortIcon field="materialCode"></p-sortIcon>
                    </div>
                  </th>

                  <th style="width:250px" pSortableColumn="materialDesc">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.MaterialDescription' | translate }}
                      <p-sortIcon field="materialDesc"></p-sortIcon>
                    </div>
                  </th>

                  <th style="width:180px" pSortableColumn="diferential">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.Differential$' | translate }}
                      <p-sortIcon field="diferential"></p-sortIcon>
                    </div>
                  </th>

                  <th style="width:230px" pSortableColumn="accessSequence">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.AccessSequence' | translate }}
                      <p-sortIcon field="accessSequence"></p-sortIcon>
                    </div>
                  </th>

                  <th style="width:180px" pSortableColumn="startDate">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.StartDate' | translate }}
                      <p-sortIcon field="startDate"></p-sortIcon>
                    </div>
                  </th>

                  <th style="width:150px" pSortableColumn="endDate">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.EndDate' | translate }}
                      <p-sortIcon field="endDate"></p-sortIcon>
                    </div>
                  </th>

                  <th style="width:120px" pSortableColumn="statusDesc">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.State' | translate }}
                      <p-sortIcon field="statusDesc"></p-sortIcon>
                    </div>
                  </th>
                </tr>
                <tr>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-object>
                <tr>
                  <td style="width:50px">
                    <div *ngIf="object.statusDesc != solicitudeDescriptionEnum.RemovalProcess">
                      <p-checkbox (click)="selectRow(e.checked,object)" [(ngModel)]="object.check"
                      binary="true" #e></p-checkbox>
                    </div>
                  </td>
                  <td style="width:100px">
                    <div *ngIf="object.statusDesc != solicitudeDescriptionEnum.RemovalProcess">
                      <i id="btnUsers"
                      class="uil uil-users-alt enviar m-1"
                      pTooltip="{{object.tooltipClient}}"
                      (click)="users(object)"
                      aria-hidden="true"
                      *ngIf="(rolePermission.create | rolePermission)">
                    </i>                    
                    </div>
                  </td>
                  <td style="width:150px">{{ object.solicitudeId }}</td>
                  <td style="width:230px">{{ materialCodeZeroLess(object.materialCode) }}</td>
                  <td style="width:250px">{{ object.materialDesc }}</td>
                  <td style="width:180px">{{ object.diferential }}</td>
                  <td style="width:230px">{{ object.accessSequence }}</td>
                  <td style="width:180px">{{ object.startDate | date : 'yyyy-MM-dd'}}</td>
                  <td style="width:150px">{{ object.endDate | date : 'yyyy-MM-dd'}}</td>
                  <td style="width:120px">{{ object.statusDesc }}</td>                  
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="4">{{ 'ElementsNotFound' | translate }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
      </div>
    </div>
  </section>

  <!-- Modal Asignacion de Clientes -->
  <p-dialog [(visible)]="modalUser" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}">

    <ng-template pTemplate="header">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: left;">
        <h5><strong>{{ 'EliminateDifferentialComponent.AssignedClients' | translate}}</strong></h5>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div *ngIf="objectListClient.length > 0">
        <p-table #dt 
        name="gridAdvancedClientFilters" 
        [value]="objectListClient" 
        styleClass="p-datatable-customers">
        <ng-template pTemplate="body" let-object>
          <tr>
            <td>{{object.typeDescription }}</td>
            <td>{{object.contentDescription}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="3">{{ 'ElementsNotFound' | translate }}</td></tr>
        </ng-template>
      </p-table>
      </div>

      <div *ngIf="CSVFile.length > 0" class="row">         
        <div class="col-md-6">
          {{ "AssingClient.TotalRecords" | translate }} &nbsp;
          <strong style="font-size: 22px">{{ totalRecordsDownload }}</strong>
        </div>

        <div class="col-md-4">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            label="{{ 'Download' | translate }}"
            class="p-button-outlined p-button-rounded"
            (click)="fileDownload()"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-8 mt-1"></div>
        <div class="col-md-4 mt-1"> 
          <button pButton 
                  pRipple
                  type="button"
                  label="{{ 'CloseBtn' | translate}}"
                  class="pstn_primario w-100"
                  (click)="cancelUser()">
          </button>
        </div> 
      </div>
    </ng-template>
  </p-dialog>

  <!-- Modal Formulario eliminar -->
  <p-dialog [(visible)]="modalDelete" [modal]="true" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">

  <ng-template pTemplate="header">
    <div style="width: 100%; display: flex; flex-direction: row; justify-content: left;">
      <h5><strong>{{ 'EliminateDifferentialComponent.EliminatePriceDifferential' | translate}}</strong></h5>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <form id="formDifferential" [formGroup]="formDifferential">
      <div class="col-md-12">
        <label>{{ 'EliminateDifferentialComponent.Date' | translate }}</label>
        <br>
        <p-calendar
          #cal2
          id="rangeDateControl"
          formControlName="rangeDateControl"
          dateFormat="dd/mm/yy"
          [style]="{ width: '400px' }"
          [showIcon]="true" 
          inputId="icon"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [showButtonBar]="true"
          [showOtherMonths]="false"
          [yearRange]="getYearRange()"
          [required]="true"
          [touchUI]="true"
          [readonlyInput]="true"
          [minDate]="today">
          <p-header class="p-2">
            <i
            id="btnClose"
            style="border:none"
              class="uil uil-times enviar"
              (click)="cal2.hideOverlay()"
              aria-hidden="true"></i>
          </p-header>
        </p-calendar>
        <br>
        <small id="rangeDateMsgValidate"
        *ngIf="
          formDifferential.controls['rangeDateControl'].invalid &&
          formDifferential.controls['rangeDateControl'].touched"
        class="p-error">{{ "EliminateDifferentialComponent.DateMsgValidation" | translate }}</small>
      </div>
      <div class="col-md-12">
        <label>{{ 'EliminateDifferentialComponent.Reason' | translate }}</label>
      <input     
          id="id"
          formControlName="reason"
          autocomplete="off"
          class="form-control"
          [ngClass]="{ 'ng-invalid ng-dirty': reason.invalid && reason.touched }"
          pInputText
          required/>
          <small id="reasonMsgValidate"
        *ngIf="
          formDifferential.controls['reason'].invalid &&
          formDifferential.controls['reason'].touched"
        class="p-error">{{ "EliminateDifferentialComponent.reasonDetail" | translate }}</small>
      </div>
    </form>
      <div class="col-md-12">
        <label>{{ 'EliminateDifferentialComponent.Acta' | translate }}</label>
        <div class="row">
          <div class="col-md-10">
            <p-message
              severity="info"
              text="{{
                'SolicitudComponent.Create.FileUploadMsgValidate' | translate
              }}"
              styleClass="p-mr-5"
            ></p-message>
          </div>

          <p-fileUpload
            id="fileUpload"
            #fileUpload
            customUpload="true"
            class="col-md-12"
            (uploadHandler)="onUploadFile($event)"
            (onRemove)="onRemoveFileImage()"
            [auto]="true"
            maxFileSize="4194304"
            chooseLabel="{{
              'SolicitudComponent.Create.UploadFile' | translate
            }}"
            invalidFileSizeMessageSummary="{0}: {{
              'SolicitudComponent.Create.InvalidSize' | translate
            }}"
            invalidFileSizeMessageDetail="{{
              'SolicitudComponent.Create.FileUpload' | translate
            }} {0}."
            accept=".pdf"
          >
          <ng-template pTemplate="content">
            <div *ngIf="!uploadedFiles.length" class="col-md-12">
              {{ "SolicitudComponent.Create.NoFile" | translate }}
            </div>
          </ng-template>
        </p-fileUpload>
        <div *ngIf="PDFFile" class="row">
            <div class="row mt-3 pb-3 d-flex justify-content-end">
              {{
                "SolicitudComponent.Create.FilePriceCertificate" | translate
              }}
              &nbsp;&nbsp; <strong>{{ nameFile }}</strong
              >&nbsp;&nbsp;
            </div>
          </div>
          <small id="ValidityMsgfileUpload"
          *ngIf="actaMsgValidity"
          class="p-error">{{ "EliminateDifferentialComponent.ActPriceMsgValidity" | translate }}</small>
        </div>
      </div>
    
  </ng-template>
	<ng-template pTemplate="footer">
    <div class="row">
    <div class="col-md-4 mt-1"></div>
    <div class="col-md-4 mt-1">
      <button pButton 
            pRipple
            type="button"
            label="{{ 'SaveBtn' | translate}}"
            class="pstn_primario w-100"
            (click)="saveDelete()">
    </button>
    </div>
    <div class="col-md-4 mt-1"> 
      <button pButton 
              pRipple
              type="button"
              label="{{ 'CloseBtn' | translate}}"
              class="pstn_primario w-100"
              (click)="cancelDelete()">
      </button>
    </div> 
  </div>
  </ng-template>
</p-dialog>

<p-toast></p-toast>