<div style="display: flex; align-items: center;">
  {{ 'ManagePromotionalActivitiesComponent.Create.RemoveSelected' | translate }}
  <button
    *ngIf="isProcess !== commercialActivityEnum.view"
    id="btnDelete"
    pTooltip="{{ 'Deletefilter' | translate }}"
    type="button"
    class="delete btn btn-secundary-delete"
    (click)="deleteSelected()"
  >
    <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
  </button>
  
  <div class="form-check col-md-8 mt-3" style="display: flex; align-items: center; justify-content: space-between;" *ngIf="isHandlerShow && !isCurrentMonth">						
    <app-switch style="display: contents;"
      #appSwitch
      [nameSwitch]="'HandlingProductFilterComponent.GroupMaterials'"
      [isDisabledInput]="((isProcess === commercialActivityEnum.view) || isDisabledInputSwitch)"
      [isValueSwitch]="commercialActivityHandlerisGroup"
      [isValueSwitchSubjet]="isValueSwitchSubjet.asObservable()"
      >
    </app-switch>

    <div class="row mt-3"></div>

    <app-switch style="display: contents;"
      #appSwitch2
      [nameSwitch]="'HandlingProductFilterComponent.CustomersWithoutSale'"
      [isDisabledInput]="isProcess === commercialActivityEnum.view"
      [isValueSwitch]="commercialActivityHandlerisClientWithoutSales"
      (switchValue)="switchValue($event)"
      >
    </app-switch>

    <div class="row mt-3"></div>

    <app-switch style="display: contents;"
      #appSwitch3
      [nameSwitch]="'HandlingProductFilterComponent.CurrentMonth'"
      [isDisabledInput]="isProcess === commercialActivityEnum.view"
      [isValueSwitch]="commercialActivityHandlerisClientWithoutSales"
      [switchHiddenButtons]="switchHiddenButtons"
      >
    </app-switch>
  </div>
</div>

<p-table
  #dt
  [value]="objectListMaterial"
  dataKey="materialCode"
  styleClass="p-datatable-customers"
  [paginator]="true"
  [rows]="10"
  [scrollable]="true"
  paginatorPosition="bottom"
  currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [(selection)]="selectMaterials"
  [globalFilterFields]="['materialCode', 'materialDescription', 'zofeDescription']"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search" aria-hidden="true"></i>
        <input
          pInputText
          type="text"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="{{ 'SearchBy' | translate }}"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th role="columnheader" style="width: 50px">
        <p-tableHeaderCheckbox [disabled]="isProcess === commercialActivityEnum.view"></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="materialCode" style="width: 150px;">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ "SolicitudComponent.Create.Code" | translate }}
          <p-sortIcon field="materialCode"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="product" style="width: 150px;">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ "SolicitudComponent.Create.Product" | translate }}
          <p-sortIcon field="product"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="zofeDescription" style="width: 150px;">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ "SolicitudComponent.Create.ProductType" | translate }}
        </div>
      </th>
      <th style="width: 200px;" *ngIf="isHandlerShow && !isCurrentMonth">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ "HandlingProductFilterComponent.LowerRange" | translate }}
        </div>
      </th>
      <th style="width: 200px;" *ngIf="isHandlerShow && !isCurrentMonth">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ "HandlingProductFilterComponent.TopRange" | translate }}
        </div>
      </th>
      <th style="width: 150px;">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{ "Actions" | translate }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-object>
    <tr>
      <td style="width: 50px;">
        <p-tableCheckbox [value]="object" [disabled]="isProcess === commercialActivityEnum.view"></p-tableCheckbox>
      </td>
      <td style="width: 150px;">{{ object.materialCode }}</td>
      <td style="width: 150px;">{{ object.materialDescription }}</td>
      <td style="width: 150px;">{{ object.zofeDescription }}</td>
      <td style="width: 200px;" *ngIf="isHandlerShow && !isCurrentMonth">
        <input pInputText
          style="width: 80%;"
            type="number"
            id="inputBtnsNone" 
            autocomplete="off"
            inputId="activityCode"
            [(ngModel)]="object.rankStart"
            [disabled]="(isProcess === commercialActivityEnum.view || isDisabledInput)"
            (change)="changeRankStart($event,object)"
            onkeypress="return ((event.charCode >= 48 && event.charCode <= 57))" />
      </td>
      <td style="width: 200px;" *ngIf="isHandlerShow && !isCurrentMonth">
        <input pInputText
            style="width: 80%;"
            type="number"
            id="inputBtnsNone" 
            autocomplete="off"
            inputId="activityCode"
            [(ngModel)]="object.rankEnd"
            [disabled]="(isProcess === commercialActivityEnum.view || isDisabledInput)"
            (change)="changeRankEnd($event,object)"
            onkeypress="return ((event.charCode >= 48 && event.charCode <= 57))" />
      </td>
      <td style="width: 150px;">
        <i
          *ngIf="isProcess !== commercialActivityEnum.view"
          class="uil uil-trash-alt eliminar"
          pTooltip="{{ 'Delete' | translate }}"
          (click)="deleteSelectOnly(object)"
          aria-hidden="true"
        ></i>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4">{{ "ElementsNotFound" | translate }}</td>
    </tr>
  </ng-template>
</p-table>
<p-toast></p-toast>