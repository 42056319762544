import { IHierarchyRealeseAssociateUpdate } from '../interfaces/hierarchy-release-associate-update.interface';

export class HierarchyRealeseAssociateUpdateModel implements IHierarchyRealeseAssociateUpdate {

  /**
   * @author Jhon Steven Pavón Bedoya
   * @param id
   * @param hierarchyReleaseId
   * @param approvalLevel
   * @param accessManagementId
   * @param approvalRankLevelId
   * @param name
   * @param lastName
   * @param email
   * @param mandatory
   * @param authorizer
   * @param approval
   * @sprint 7
   */
  constructor(
    public id: number,
    public hierarchyReleaseId: number,
    public approvalLevel: string,
    public accessManagementId: string,
    public approvalRankLevelId: number,
    public name: string,
    public lastName: string,
    public email: string,
    public mandatory: boolean,
    public authorizer: boolean,
    public approval: boolean
  ){}
}
