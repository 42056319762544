import { ISolicitudeMaterialRequest } from '../interfaces/solicitude-material-request.interface';
import { SolicitudeMaterialModel } from './solicitude-material.model';
import { SolicitudeModel } from './solicitude.model';
import { SolicitudeFileModel } from './solicitude-file.model';
import { SolicitudeCustomerModel } from './solicitude-customer.model';
import { DeleteEliminateDifferentialModel } from '../remove-differential/models/delete-eliminate-differential.model';

export class SolicitudeMaterialRequestModel implements ISolicitudeMaterialRequest {
    /**
     * @author Priscy Antonio Reales
     * @sprint 5
     */
    constructor(
        public solicitude: SolicitudeModel,
        public solicitudeMaterial?: SolicitudeMaterialModel[],
        public solicitudeFile?: SolicitudeFileModel,
        public solicitudeCustomer?: SolicitudeCustomerModel[],
        public solicitudeExtDetail?: DeleteEliminateDifferentialModel,
    ) {}
}
