<section class="pstngestion">
  <div class="pstn_container container-fluid p">
    <div class="bodyContent" >
      <div id="orgChart" style="width: 100%;">
        <div id="tree"></div>
      </div>
      <div id="modalNode" *ngIf="isAddNode" >
        <div id="img">
          <img src="/assets/images/Enmascarar grupo 12.jpg" style="width: 100%;"/> 
          
          <button
          (click)="closeModalNode()">
            <i class="uil uil-arrow-right" aria-hidden="true" ></i>
          </button>
        </div>
            <div class="col-md-auto" id="bodycontent">
              <form id="formNodeAddEdit" [formGroup]="formNodeAddEdit">                
              
                <div class="row mt-3">
                <div class="col-m-12" style="width: 100%; display: flex;justify-content: center;">
                  <div *ngIf="!isEdit"><h5>Agregar nodo</h5></div>
                  <div *ngIf="isEdit"><h5>Editar nodo</h5></div>
                </div>

                <div class="col-md-12">
                  <label>{{ 'OrganizationChartComponent.Name' | translate }}</label>
                  <div class="form-group">
                    <p-dropdown id="emailNode"
                      appendTo="body"
                      optionLabel="label"
                      optionValue="value"
                      [filter]="true"
                      [options]="objectUsers"
                      formControlName="emailNode"
                      [ngClass]="{'ng-invalid ng-dirty': emailNode.invalid && emailNode.touched}"
                      placeholder="{{'OrganizationChartComponent.Name'| translate}}"
                      [style]="{ width: '100%' }"
                      display="chip"
                      (onChange)="completeEmail(emailNode)"
                      [disabled]="!(rolePermission.create | rolePermission)"
                      required>
                    </p-dropdown>
        
                    <small id="emailNodeCodeValidator"
                      *ngIf="formNodeAddEdit.controls['emailNode'].invalid 
                      && (formNodeAddEdit.controls['emailNode'].dirty ||
                      formNodeAddEdit.controls['emailNode'].touched)"
                      class="p-error">{{ 'OrganizationChartComponent.NameValidator' | translate }}</small>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label>{{'OrganizationChartComponent.Email'| translate}}</label>
                    <input id="cargo"
                           autocomplete="off"
                           class="form-control"
                           formControlName="nameNode"
                           placeholder="{{'OrganizationChartComponent.EmailExample'| translate}}"
                           pInputText disabled
                          />
                  </div>
                </div>
        
                <div class="col-md-12 mt-2">
                  <label>{{ 'OrganizationChartComponent.Job' | translate }}</label>
                  <div class="form-group">
                    <p-dropdown id="jobNode"
                      appendTo="body"
                      optionLabel="label"
                      optionValue="value"
                      [filter]="true"
                      [options]="objectJobCopy"
                      formControlName="jobNode"
                      [ngClass]="{'ng-invalid ng-dirty': jobNode.invalid && jobNode.touched}"
                      placeholder="{{'OrganizationChartComponent.Job'| translate}}"
                      [style]="{ width: '100%' }"
                      display="chip"
                      [disabled]="!(rolePermission.create | rolePermission)"
                      required>
                    </p-dropdown>
                    <small id="jobNodeCodeValidator"
                    *ngIf="formNodeAddEdit.controls['jobNode'].invalid && (formNodeAddEdit.controls['jobNode'].dirty ||
                    formNodeAddEdit.controls['jobNode'].touched)"
                    class="p-error">{{ 'OrganizationChartComponent.JobValidator' | translate }}</small>
                  </div>
                </div>
                <!-- <div class="mt-5"></div> -->
                           
                  <div class="col-md-12 mt-3">
                    <button id="btnCancel"
                      pButton
                      pRipple
                      type="button"
                      (click)="closeModalNode()"
                      label="{{ 'CancelBtn' | translate }}"
                      class="pstn_primario w-100"
                      style="background-color: darkgrey; border-color: darkgrey;">
                    </button>
                  </div>

                  <div class="col-md-12 mt-3">
                    <button
                      id="btnCreate"
                      pButton
                      pRipple
                      type="button"
                      (click)="saveAddNode()"
                      label="{{ 'AcceptBtn' | translate }}"
                      class="pstn_primario w-100"
                      [disabled]="!(rolePermission.create | rolePermission)">
                    </button> 
                  </div>
                </div>
            </form>
            </div>
      </div>
    </div>
  </div>
</section> 
<p-toast></p-toast>




