import { IHierarchyRealeseUpdate } from '../interfaces/hierarchy-realese-update.interface';
import { HierarchyRealeseAssociateUpdateModel } from './hierarchy-release-associate-update.model';

export class HierarchyRealeseUpdateModel implements IHierarchyRealeseUpdate {

  constructor(
    public id: number,
    public description: string,
    public countryCode: string,
    public societyCode: string,
    public channelCode: string,
    public regionCode: string,
    public cediCode: string,
    public macrosegmentCode: string,
    public businessTypologyCode: string,
    public processDomainId: number,
    public processDomainIdView: string,
    public subprocessDomainId: number,
    public hierarchyReleaseAssociateCreateModel : HierarchyRealeseAssociateUpdateModel[]
  ){}
}
