import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Module } from '@app/@shared/enums/module.enums';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moments from 'moment';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styles: [
  ],
})
export class NotificationCardComponent implements OnInit, DoCheck {

  @Input('id') id: number;
  @Input('snooze') snooze = false;
  @Input('reject') reject = false;
  @Input('accept') accept = false;
  @Input('moreInfo') moreInfo = false;
  @Input('notificationColor') notificationColor = '';
  @Input('title') title = '';
  @Input('notificationText') notificationText = '';
  @Input('moduleId') moduleId: number;
  @Input('type') type: number;
  @Input('creationDate') creationDate : Date = new Date();
  @Output('eventClickSnooze') eventClickSnooze: EventEmitter<any> = new EventEmitter();
  @Output('eventClickReject') eventClickReject: EventEmitter<any> = new EventEmitter();
  @Output('eventClickAccept') eventClickAccept: EventEmitter<any> = new EventEmitter();
  @Output('eventClickMoreInfo') eventClickMoreInfo: EventEmitter<any> = new EventEmitter();
  @Output('eventClickSeeDocument') eventClickSeeDocument: EventEmitter<any> = new EventEmitter();

  private readonly spinner: NgxSpinnerService;
  public commentaryText : string;
  public approvalName = 'Visto';
  public seeDocument : boolean;
  ngOnInit(): void {
    this.commentaryText = '';
    this.changeName();
    if(this.moduleId !== Module.Price){
      this.seeDocument = true;
      this.moreInfo = false;
    }else {
      this.seeDocument = false;
      this.moreInfo = true;
    }
  }

  constructor(spinner: NgxSpinnerService,){
    this.spinner = spinner;
  }

  ngDoCheck(): void {
    const language: string = sessionStorage.getItem('language');
    if(language === 'es-ES'){
      moments.locale('es');
    }else{
      moments.locale('en');
    }
  }

  /**
   * @description this method is to emit event the father.
   * @author Elkin Vasquez Isenia
   */
  onClickSnooze($event: any): void {
    this.eventClickSnooze.emit(this.id);
  }

  /**
   * @description this method is to emit event the father.
   * @author Elkin Vasquez Isenia
   */
   onClickReject($event: any): void {
    const objectEvent = this.getObjectEvent();
    this.eventClickReject.emit(objectEvent);
  }

  /**
   * @description this method is to emit event the father.
   * @author Elkin Vasquez Isenia
   */
   onClickAccept($event: any): void {
    const objectEvent = this.getObjectEvent();
    this.eventClickAccept.emit(objectEvent);
  }

  /**
   * @description this method is to emit event the father.
   * @author Prisc Antonio Reales
   */
  onClickMoreInfo($event: any): void {
    this.eventClickMoreInfo.emit(this.id);
  }

  /**
   * @description this method is to emit event the father.
   * @author Prisc Antonio Reales
   */
  onClickSeeDocument($event : any): void{
    const objectEvent = this.getObjectEvent();
    this.eventClickSeeDocument.emit(objectEvent);
  }

  /**
   * @description this method is to get object for event.
   * @author Priscy Antonio Reales
   */
   getObjectEvent():any{
    const objectEvent = {
      id : this.id,
      commentary : this.commentaryText,
      moduleId : this.moduleId,
      type : this.type
    };
    this.commentaryText = '';
    return objectEvent;
  }

  changeName(){
    if(this.notificationColor === 'color-notificacion-2' || this.notificationColor === 'color-notificacion-1') {
     this.approvalName = 'Visto';
   }
  }
}
