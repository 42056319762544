<div class="card p-0">
  <div class="card-header pstn_container p-2" id="headingFive">
    <h2 class="mb-0">
      <button class="btn btn-link btn-block text-left btn-title-collapse collapsed" type="button" data-toggle="collapse"
        data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"> 
        <i
          class="uil uil-arrow-circle-down"
          aria-hidden="true" 
          ></i> {{ title }}
      </button>
    </h2>
  </div>
  <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionCreatePromotion">
    <div class="card-body">
      <!-- CreatePromotion -->
      <form id="formPromotion" [formGroup]="formPromotion">
        <div class="row">
          <div class="col-md-12">
            <h2 class="pstn_subtitle">
              {{ title }}
            </h2>
          </div>

          <!-- RangeDateControl -->
          <div class="col-md-4 bg-light text-dark" *ngIf="showFields.rangeDate">
            <!-- RangeDateControl -->
            <div class="form-group">
              <label>{{ "RangeDate" | translate }}</label>
              <p-calendar #cal2 id="rangeDateControl" [minDate]="today" formControlName="rangeDateControl"
                [showIcon]="true" selectionMode="range" [monthNavigator]="true" [yearNavigator]="true"
                [yearRange]="getYearRange()" [showButtonBar]="true" inputId="icon"- [showOtherMonths]="false"
                placeholder="{{ 'RangeDate' | translate }}" dateFormat="dd/mm/yy" [disabledDates]="invalidDates"
                [disabled]="isProcess === comercialActivityEnum.view">
                <p-header class="p-2">
                  <i 
                    id="btnClose" 
                    style="border:none" 
                    class="uil uil-times enviar" 
                    (click)="cal2.hideOverlay()"
                    aria-hidden="true" 
                  ></i>
                </p-header>
              </p-calendar>
              <p-button icon="pi pi-plus" [disabled]="isProcess === comercialActivityEnum.view"
                (click)="addRangeDate()"></p-button>
            </div>
            <div class="form-group" *ngIf="rangeDateSelectList.length > 0">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.RangeDate" | translate }}</label>
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center"
                  *ngFor="let date of rangeDateSelectList; let i = index">
                  {{ date.dateStart | date: 'dd-MM-yyyy' }} - {{ date.dateEnd | date: 'dd-MM-yyyy' }} &nbsp;&nbsp;
                  <i
                    aria-hidden="true" 
                    class="uil uil-trash-alt eliminar-check" 
                    (click)="deleteRangeDateSelected(i)"
                    *ngIf="isProcess !== comercialActivityEnum.view"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <!-- NumberDays -->
              <div class="col-md-6" *ngIf="showFields.numberDays">
                <div class="form-group">
                  <label for="days">{{ 'ManagePromotionalActivitiesComponent.Create.NumberDays' | translate
                    }}</label><br />
                  <p-inputNumber id="days" formControlName="days" autocomplete="off" [min]="1" [max]="20"
                    buttonLayout="horizontal" spinnerMode="horizontal" inputId="days"
                    decrementButtonClass="p-button-primary" incrementButtonClass="p-button-primary"
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" required>
                  </p-inputNumber>
                  <small id="daysMsgValidate" *ngIf="
                              formPromotion.controls['days'].invalid && 
                              (formPromotion.controls['days'].dirty || formPromotion.controls['days'].touched)"
                    class="p-error">{{ 'ManagePromotionalActivitiesComponent.Create.NumberDaysNameMsgValidate' |
                    translate }}</small>
                </div>
              </div>

              <!-- StartTime -->
              <div class="col-md-6" *ngIf="showFields.startTime">
                <div class="form-group">
                  <label for="startTime">{{ 'ManagePromotionalActivitiesComponent.Create.StartTime' | translate
                    }}</label><br />
                  <p-calendar class="filing-time-input" id="startTime" formControlName="startTime" dateFormat="mm/dd/yy"
                    timeFormat="HH:mm:ss" [timeOnly]="true" 
                    [showOtherMonths]="false" [disabled]="isProcess === comercialActivityEnum.view"
                    required></p-calendar>
                </div>
              </div>

              <!-- EndTime -->
              <div class="col-md-6" *ngIf="showFields.endTime">
                <div class="form-group">
                  <label for="endTime">{{ 'ManagePromotionalActivitiesComponent.Create.EndTime' | translate
                    }}</label><br />
                  <p-calendar class="filing-time-input" id="endTime" formControlName="endTime" dateFormat="mm/dd/yy"
                    timeFormat="HH:mm:ss" [timeOnly]="true" 
                    [showOtherMonths]="false" [disabled]="isProcess === comercialActivityEnum.view"
                    required></p-calendar>
                </div>
              </div>

              <!-- OrderLimit -->
              <div class="col-md-6" *ngIf="showFields.orderLimit">
                <div class="form-group">
                  <label for="orderLimit">{{ 'ManagePromotionalActivitiesComponent.Create.OrderLimit' | translate
                    }}</label><br />
                  <p-inputNumber id="orderLimit" formControlName="orderLimit" autocomplete="off" [min]="1" [max]="20"
                    buttonLayout="horizontal" spinnerMode="horizontal" inputId="orderLimit"
                    decrementButtonClass="p-button-primary" incrementButtonClass="p-button-primary"
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                    [disabled]="isProcess === comercialActivityEnum.view" required>
                  </p-inputNumber>
                  <small id="orderLimitMsgValidate"
                    *ngIf="
                              formPromotion.controls['orderLimit'].invalid && 
                              (formPromotion.controls['orderLimit'].dirty || 
                              formPromotion.controls['orderLimit'].touched)" class="p-error">{{
                    'ManagePromotionalActivitiesComponent.Create.OrderLimitMsgValidate' | translate }}</small>
                </div>
              </div>

              <!-- PeriodLimit -->
              <div class="col-md-6" *ngIf="showFields.periodLimit">
                <div class="form-group">
                  <label for="preiodLimit">{{ 'ManagePromotionalActivitiesComponent.Create.PeriodLimit' | translate
                    }}</label><br />
                  <p-inputNumber id="periodLimit" formControlName="periodLimit" autocomplete="off" [min]="1" [max]="20"
                    buttonLayout="horizontal" spinnerMode="horizontal" inputId="periodLimit"
                    decrementButtonClass="p-button-primary" incrementButtonClass="p-button-primary"
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                    [disabled]="isProcess === comercialActivityEnum.view" required>
                  </p-inputNumber>
                  <small id="periodLimitMsgValidate"
                    *ngIf="
                              formPromotion.controls['periodLimit'].invalid && 
                              (formPromotion.controls['periodLimit'].dirty || 
                              formPromotion.controls['periodLimit'].touched)" class="p-error">{{
                    'ManagePromotionalActivitiesComponent.Create.PeriodLimitMsgValidate' | translate }}</small>
                </div>
              </div>

              <!-- CommercialActivityState -->
              <div class="col-md-6"
                *ngIf="showFields.commercialActivityState && isProcess === comercialActivityEnum.update">
                <div class="form-group">
                  <label for="commercialActivityState">{{
                    "ManagePromotionalActivitiesComponent.Create.CommercialActivityState" | translate }}</label><br />
                  <p-dropdown id="commercialActivityState"
                    [ngClass]="{'ng-invalid ng-dirty': commercialActivityState.invalid && 
                    commercialActivityState.touched}"
                    formControlName="commercialActivityState" optionLabel="label" optionValue="value"
                    placeholder="
                    {{'ManagePromotionalActivitiesComponent.Create.CommercialActivityStatePlaceHolderInput' 
                    | translate}}"
                    [options]="objectCommercialActivityStatusList" [style]="{ width: '100%' }" readonly="true">
                  </p-dropdown>
                  <small id="commercialActivityState" *ngIf="
                    formPromotion.controls['commercialActivityState'].invalid &&
                    (formPromotion.controls['commercialActivityState'].dirty ||
                    formPromotion.controls['commercialActivityState'].touched)"
                    class="p-error">{{"ManagePromotionalActivitiesComponent.Create.CommercialActivityStateMsgValidate" |
                    translate}}</small>
                </div>
                <div class="form-group" *ngIf="isProcess === comercialActivityEnum.update">
                  <label for="commercialActivityState">{{
                    "ManagePromotionalActivitiesComponent.Create.CommercialActivityState" | translate }}</label><br />
                  <input #user type="text" pInputText id="commercialActivityState"
                    formControlName="commercialActivityState" autocomplete="off" class="form-control" required />
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="row mt-5"></div>
<div class="row" *ngIf="showButtons">
  <div class="col-md-6">
    <button type="button" class="pstn_primario" style="background-color: darkgrey; border-color: darkgrey;"
      [routerLink]="routeCancel">
      {{'CancelBtn' | translate}}
    </button>
  </div>

  <div class="col-md-6" *ngIf="isProcess === comercialActivityEnum.create">
    <button pButton pRipple type="button" class="pstn_primario" label="{{'CreateBtn' | translate}}"
      style="float: right;" [disabled]="!formPromotion.valid" (click)="eventClickSaveBtn($event)">

    </button>
  </div>

  <div class="col-md-6" *ngIf="isProcess === comercialActivityEnum.update">
    <button type="button" class="pstn_primario" style="float: right;" [disabled]="!formPromotion.valid"
      (click)="eventClickEditBtn($event)">
      {{'UpdateBtn' | translate}}
    </button>
  </div>
</div>
<div class="row mt-5"></div>