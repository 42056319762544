import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ICommercialActivity } from '@app/@modules/promotionalactivity/manage-promotional-activities/interfaces/commercial-activity.interface';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ClientsAdvancedFilterComponent } from './clients-advanced-filter/clients-advanced-filter.component';
import { GeneralAdvancedFilterComponent } from './general-advanced-filter/general-advanced-filter.component';
import { IAdvancedFilter, IGeneralAdvancedFilter } from './interfaces/general-advanced-filter.interface';
import { SharedAdvancedFilterService } from './shared-advanced-filter.service';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { IConfigurationUserAccessManagement } from '@app/@components/advanced-client-filter/interfaces/configuration-user-access-management.interface';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { FilterMaterialDetailComponent } from '../hierarchy-advanced-filters/filter-material-detail/filter-material-detail.component';
import { HierarchyAdvancedFiltersComponent } from '../hierarchy-advanced-filters/hierarchy-advanced-filters.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { IFieldsComponentGeneralFilter } from '@app/@shared/interfaces/fields-component-general-filter.interface';
import { FieldsComponentGeneralFilter } from '@app/@shared/model/fields-component-general-filter.model';
import { FieldsComponentClientFilter } from '@app/@shared/model/fields-component-client-filter.model';
import { IFieldsComponentClientFilter } from '@app/@shared/interfaces/fields-component-client-filter.interface';
import { IHierarchyFiltersMaterials } from '../hierarchy-advanced-filters/interfaces/hierarchy-filters-materials.interface';
import { IFieldsClientFilter } from '../assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '../assing-clients/models/show-fields-client-filter.model';

@Component({
  selector: 'app-shared-advanced-filter',
  templateUrl: './shared-advanced-filter.component.html',
  styleUrls: ['./shared-advanced-filter.component.css'],
})
export class SharedAdvancedFilterComponent implements OnInit {

  @Input('showFieldsComponent') showFieldsComponent = true;
  @Input('validateFilter') validateFilter = true;
  @Input('showFieldsGeneral') showFieldsGeneral : IFieldsComponentGeneralFilter = new FieldsComponentGeneralFilter(false, false, false, false, false, 
                                                                                                                   false, false, false, false, false,  
                                                                                                                   false, false, false, false, false);
  @Input('showFieldsClient') showFieldsClient : IFieldsComponentClientFilter = new FieldsComponentClientFilter(false,false);
  @Input('advancedSearchFilter') advancedSearchFilter = {} as IAdvancedFilter;

  @Output() activitiesListAdvancedFilter: EventEmitter<ICommercialActivity[]> = new EventEmitter();
  @Output() countListAdvancedFilter: EventEmitter<number> = new EventEmitter();

  @Output() advancedFilter : EventEmitter<IAdvancedFilter> = new EventEmitter();
  @Input('rolePermissionBtn') rolePermissionBtn: boolean;
  @Input('totalRow') totalRow: number;
  @Output() removeListAdvancedFilter: EventEmitter<IAdvancedFilter> = new EventEmitter();
  @Output() clearListAdvancedFilter: EventEmitter<IAdvancedFilter> = new EventEmitter();

  @ViewChild(GeneralAdvancedFilterComponent) general: GeneralAdvancedFilterComponent;
  @ViewChild('advancedFilterClient') filterClient: ClientsAdvancedFilterComponent;
  @ViewChild ('advancedFilterBase') filterBase : HierarchyAdvancedFiltersComponent;
  @ViewChild ('advancedFilterBasePromotion') filterPromotion : HierarchyAdvancedFiltersComponent;
  @ViewChild ('detailBase') filterDetailBase : FilterMaterialDetailComponent;
  @ViewChild ('detailBasePromotion') filterDetailPromotion : FilterMaterialDetailComponent;


  public configurationUserAccessManagement : IConfigurationUserAccessManagement;
  public rolePermission: any = RolePermissionEnum;
  public totalRows = 10;
  public nameFilterMaterial = '';
  public filtersEdit = '';
  public clientsEdit : string[] = [];
  public editFiltersObjectList : IHierarchyFiltersMaterials[] = [];
  public showFields : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false, false, true, false);

  private readonly sharedAdvancedFilterService: SharedAdvancedFilterService;
  private activitiesList: ICommercialActivity[] = [];
  private readonly managePromotionalActivitiesService: ManagePromotionalActivitiesService;
  eventMaterialBase : any;
  eventMaterialBasePromotion : any;
  private readonly spinner: NgxSpinnerService;
  private filters = {} as IAdvancedFilter;

  constructor(
    sharedAdvancedFilterService: SharedAdvancedFilterService,
    spinner: NgxSpinnerService
    ) {
    this.sharedAdvancedFilterService = sharedAdvancedFilterService;
    this.spinner = spinner;

  }

  ngOnInit(): void {

    this.getUserConfig();

    this.nameFilterMaterial = this.showFieldsComponent ? 'AdvancedFiltersComponent.BaseProducts'
    : 'EliminateDifferentialComponent.Materials';

    this.setFormValues()
  }

  ngOnChanges(rows: { [property: string]: SimpleChange }) {
    const changeRows: SimpleChange = rows['totalRow'];
    if (changeRows && changeRows.currentValue) {
      this.totalRows = changeRows.currentValue;
    }
  }

  eventClickFilters(page = 1, rows: number = this.totalRows){
  //Obtener JSON de componentes hijosv
  this.filters = {} as IAdvancedFilter;
  this.filters.search = {ascendent:true, id:0, orderBy:'Id', pageNumber:page, resultsPerPage:rows};
  this.filters.general = {} as IGeneralAdvancedFilter;
  this.filters.general = this.general.getAdvancedFilters();
  const clientInfo = this.filterClient.getAdvancedFilters();
  let activityDescriptionlength = this.filters.general.activityDescription.length > 0 ? this.filters.general.activityDescription.length : 0;
  this.filters.client = clientInfo.listClient;
  this.filters.clientFilter = clientInfo.jsonFilterClient;
  this.filters.materialBase = this.filterDetailBase.getMaterialList();
  this.filters.materialBasePromotion = this.showFieldsComponent ? this.filterDetailPromotion.getMaterialList() : [];
  this.filters.advancedFiltersObjectList = this.eventMaterialBase;

  SessionStorageHelper.save('filters', JSON.stringify(this.filters));
  if(this.showFieldsComponent)
  {
     this.spinner.show();
     if(activityDescriptionlength >=3 || activityDescriptionlength === 0){
       this.getCommercialActivity(this.filters);
       this.advancedFilter.emit(this.filters);
     } else {
       this.spinner.hide();
     }
    } else {
      this.removeListAdvancedFilter.emit(this.filters);
    }

  }



  getCommercialActivity(filters: IAdvancedFilter) {
    this.sharedAdvancedFilterService.getCommercialActivity(filters).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.status) {
          this.activitiesList = response.data;
          this.activitiesListAdvancedFilter.emit(this.activitiesList);
          this.countListAdvancedFilter.emit(response.resultCount);
        } else {
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  eventClearFilters(){
    this.general.initializeForm();

    /*Clientes*/
    this.filterClient.initializeForm();

    /*Materiales Base*/
    this.filterBase.initializeForm();
    this.filterDetailBase.initializeForm();

    /*Materiales Promoción*/
    if(this.showFieldsComponent){
      this.filterPromotion.initializeForm();
      this.filterDetailPromotion.initializeForm();
    }
    /*Realizar consulta inicial*/
    this.filters = {} as IAdvancedFilter;
    this.filters.search = {ascendent:true, id:0, orderBy:'Id', pageNumber:1, resultsPerPage: this.totalRows}

    this.filters.general = {} as IGeneralAdvancedFilter;
    this.filters.general.activityCode = 0;
    this.filters.general.activityDescription ="";
    this.filters.general.activityChannels = [];
    this.filters.general.activityStatus = [];
    this.filters.general.activityTypes = [];
    this.filters.general.activityProms = [];
    this.filters.general.statusDifferential = 0;
    this.filters.general.activitySectors = [];

    this.filters.client = [];
    this.filters.clientFilter = "";
    this.filters.materialBase = [];
    this.filters.materialBasePromotion = [];

    this.eventMaterialBase = [];
    this.eventMaterialBasePromotion = [];

    if (this.showFieldsComponent)
    {
      this.getCommercialActivity(this.filters);
    }
    
    this.advancedFilter.emit(this.filters);
    SessionStorageHelper.remove('filters')
    if(!this.showFieldsComponent) this.clearListAdvancedFilter.emit(this.filters);
  }

  async getUserConfig() {
    this.spinner.show();
    const userAccount = SessionStorageHelper.get(CommonConfiguration.Email);
    this.sharedAdvancedFilterService.getConfigurationUserAcessManagement(userAccount)
    .subscribe((response) =>{
      this.spinner.hide();
      if(response.status){
        this.configurationUserAccessManagement = response.data;
        SessionStorageHelper.save(CommonConfiguration.Sector,  JSON.stringify(this.configurationUserAccessManagement.sector));
        SessionStorageHelper.save(CommonConfiguration.Channel, JSON.stringify(this.configurationUserAccessManagement.channel));
      }
    },
    (error: HttpErrorResponse) => {
      this.spinner.hide();
      SweetAlertMessageHelpers.exception(error);
    });
  }

  eventClickFilterMaterialBase($event: any){
    this.eventMaterialBase = $event;
  }

  eventClickFilterMaterialPromotion($event: any){
    this.eventMaterialBasePromotion = $event;
  }

  setFormValues(){
    let filter : IAdvancedFilter = SessionStorageHelper.get('filters') ?  JSON.parse(SessionStorageHelper.get('filters')) : null;
    if(filter){
      if(filter.clientFilter) this.filtersEdit = filter.clientFilter;
      if(filter.client) this.clientsEdit = filter.client;
      if(filter.materialBase.length > 0){
        this.editFiltersObjectList = filter.advancedFiltersObjectList['advancedFiltersObjectList'];
        this.eventMaterialBase = filter.advancedFiltersObjectList;
      }
    }
  }

}