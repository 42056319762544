import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { Observable } from 'rxjs';
import { SwitchService } from './switch.service';
import { ISwitchHiddenButtons } from './interfaces/switch.interface';
import { SwitchHiddenButtonsModel } from './models/switch.model';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css']
})
export class SwitchComponent implements OnInit {

  @Input('nameSwitch') nameSwitch = '';
  // @Input('nameNgModel') nameNgModel = '';
  @Input() isValueSwitch : boolean;
  @Input() isProcess : number;
  @Input() isDisabledInput : boolean = false;
  @Input() switchHiddenButtons : ISwitchHiddenButtons = new SwitchHiddenButtonsModel(false, true);
  @Input() isValueSwitchSubjet? : Observable<boolean>;

  @Output('switchValue') switchValue: EventEmitter<any> = new EventEmitter();

  public onInitExecuted : boolean = false;
  public formSwitch: FormGroup;
  public commercialActivityEnum: any;
  public nameNgModel: any;
  //Observable
	public isCurrentMonth = false;

  private readonly fb: FormBuilder;
  
  constructor(
    fb: FormBuilder,
    private switchService : SwitchService,
  ) { 
    this.fb = fb;
    this.switchService.data$.subscribe((data) => {
			this.nameNgModel = data;
		  });
  }

  ngOnInit(): void {
    this.startLisening();
  }

  startLisening(){
    this.commercialActivityEnum = this.getCommercialActivityEnum();
    if(!this.onInitExecuted) this.initForm();
    this.isValueSwitchSubjet?.subscribe((apiRequest) => {
      if(this.isValueSwitchSubjet != undefined) this.switchValueForm.setValue(apiRequest);
    });
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }): void {
    const changeSwitchValue: SimpleChange = changes['isValueSwitch'];
    if (changeSwitchValue != undefined && changeSwitchValue.currentValue != undefined) {
      if(!this.onInitExecuted){
        this.initForm();
        this.onInitExecuted=true;
      } 
      this.setValue();
    }
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  onChangeSwitch(event : any){
    this.switchValue.emit(event.checked);
  }

  onChangeNgModel(){
    this.switchService.sendData(this.nameNgModel)
  }

  // Validar este metodo luego ya que eexiste el onchange
  getValue() : boolean {
    if(this.switchValueForm) return this.switchValueForm.value;
  }

  setValue(){
    if(this.isValueSwitch != undefined) this.switchValueForm.setValue(this.isValueSwitch);
  }

  initForm() {
    this.formSwitch = this.fb.group({
      switchValueForm: [{ value: false, disabled: false }],
    });
  }

  get switchValueForm(): AbstractControl { return this.formSwitch.get('switchValueForm'); }

}
