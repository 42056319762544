import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  materialCodeZeroLess(value: string)
  {
    let materialCodeNumber = Number(value);
    return materialCodeNumber.toString();
  }
  
}
