import { IMaximumPeriodValidity } from '../interfaces/maximum-period-validity.inteface';

export class MaximumPeriodValidityModel implements  IMaximumPeriodValidity{
  /**
   * @author kmora
   * @param id
   * @param subProcessDomainId
   * @param numberDay
   * @sprint 9
   */
  constructor(
    public id: number,
    public subProcessDomainId: string,
    public numberDay: number,
  ) {}
}
