import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SelectProfileModuleMessage {

  public static readonly ERROR_PROFILE_MODULE = 'Este usuario no tiene ningún perfil ni modulo asignado. Por favor contacte al administrador';
  public static readonly ERROR_PROFILE_MODULE_TRANSLATE = 'This user does not have any profile or module assigned. Please contact the administrator';

  constructor() { }
}
