import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListOperationByRoleModel } from '@app/@modules/administration/select-profile-module/models/list-operation-by-role.model';
import { SelectProfileModuleService } from '@app/@modules/administration/select-profile-module/select-profile-module.service';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ApplicationConstants } from '@app/app.constants';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertResult } from 'sweetalert2';
import { CommonConfiguration } from '../../../@shared/enums/common.enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isVisibleNotification = false;
  public menuHidden = true;
  public isMenu = false;
  public userName: string;
  public profile: string;
  public operation = '';
  public moduleId: number;
  public photo: any;
  public imageFileBase?: any[];
  public listOperation: ListOperationByRoleModel[];
  public environmentName = '';

  private readonly router: Router;
  private readonly authService: MsalService;
  private readonly selectProfileModuleService: SelectProfileModuleService;
  private readonly spinner: NgxSpinnerService;

  constructor(
    router: Router,
    authService: MsalService,
    selectProfileModuleService: SelectProfileModuleService,
    spinner: NgxSpinnerService
  ) {
    this.router = router;
    this.authService = authService;
    this.selectProfileModuleService = selectProfileModuleService;
    this.spinner = spinner;
    this.listOperation = [];
    this.isMenu = false;
  }

  ngOnInit() {
    this.isMenu = false;
    this.environmentName = environment.environmentName;

    const userInfo = JSON.parse(SessionStorageHelper.get(CommonConfiguration.UserSession));
    this.userName = userInfo.fullName;
    this.profile = SessionStorageHelper.get(CommonConfiguration.Profile);
    this.photo = SessionStorageHelper.get(CommonConfiguration.Photo);
    if (this.photo === 'undefined' || this.photo === undefined || this.photo === null || this.photo === '') {
      this.photo = '/assets/images/user-ccv.png';
    } else {
      this.photo = `${CommonConfiguration.TransformImage}${this.photo}`;
    }

    const moduleId = Number(SessionStorageHelper.get(CommonConfiguration.ModuleId));
    const profileId = SessionStorageHelper.get(CommonConfiguration.ProfileId);


    if (profileId.length > 0 && moduleId > 0) {
      this.getAllModule(profileId, moduleId);
      this.getListOperationByRole(moduleId);
    }

  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  closeNav() {
    document.getElementById('coeSidenav').style.width = '0';
    document.getElementById('main').style.marginLeft = '0';
  }

  logout() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.confirm(
      language === 'es-ES' ? 'Cerrar Sesión' : 'Sign off',
      language === 'es-ES' ? 'Está seguro de finalizar la sesión actual en la plataforma...?'
      : 'Are you sure you want to end the current session on the platform...?')
      .then((result: SweetAlertResult) => {
        if (result.value) {
          SessionStorageHelper.removeAll();
          SessionStorageHelper.remove(CommonConfiguration.Token);
          SessionStorageHelper.remove(CommonConfiguration.ProfileId);
          SessionStorageHelper.remove(CommonConfiguration.ModuleId);
          SessionStorageHelper.remove(CommonConfiguration.FirstLoad);
          SessionStorageHelper.remove(CommonConfiguration.Authenticated);
          SessionStorageHelper.remove(CommonConfiguration.UserSession);
          this.authService.logout();
        }
      });
  }

  toggleNav() {
    const sideNav = document.getElementById('coeSidenav').style;
    const main = document.getElementById('main').style;
    if (!sideNav.width || sideNav.width === '0px') {
      sideNav.width = '250px';
      main.marginLeft = '250px';
    } else {
      sideNav.width = '0';
      main.marginLeft = '0';
    }
  }

  onProfileChange() {
    SessionStorageHelper.remove(CommonConfiguration.FirstLoad);
    SessionStorageHelper.save(CommonConfiguration.FirstLoad, 'true');
    this.isMenu = false;
    this.listOperation = [];
    this.router.navigate(['auth/select-profile-module']);
  }

  getAllModule(rolId: string, moduleId: number){
    this.spinner.show();
    this.selectProfileModuleService.getListModuleByRole(rolId)
    .subscribe(
      (response) => {
        this.spinner.hide();
        this.moduleId = moduleId;
        this.operation = response.data.find((x) => x.id === moduleId).description;
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
   * @description this method is to get all the profile of users.
   * @author Elkin Vasquez Isenia
   * @sprint 2
   */
   getListOperationByRole(moduleId: number): void {
    this.spinner.show();
    this.isMenu = true;

    this.selectProfileModuleService.getListOperationUser(moduleId)
    .subscribe((response) => {
      if(response.status){
        sessionStorage.removeItem(ApplicationConstants.CONSTANTS.listOperation);
          this.listOperation = [];

          this.spinner.hide();
          this.listOperation = response.data;
          sessionStorage.setItem(ApplicationConstants.CONSTANTS.listOperation, JSON.stringify(this.listOperation));
      }
    },
    (error: HttpErrorResponse) => {
      this.spinner.hide();
      SweetAlertMessageHelpers.exception(error);
    });
  }

  sendRoute(route: string){
    this.router.navigate([route]);
  }

  eventHiddenNotificacion($even: any): void {
    this.isVisibleNotification = $even;
  }
}