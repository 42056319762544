export class NotificationInformativeModel {
    /**
   * @author Priscy Antonio Reales Mozo
   * @sprint 11
   * @description Model for set notification informative
   */
  constructor(
    public moduleId : number,
    public notificationTypeId: number,
    public solicitudeId: number,
  ) {}
}