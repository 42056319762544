<div class="impact-analysis-card" >
    <p-card class="impact-analysis-pcard">

        <div class="impact-analysis-header">
            <div class="icon">
                <i class="uil {{item?.icon}}" aria-hidden="true"></i>                
            </div>            
        </div>

        <div class="impact-analysis-body">
            <div class="impact-analysis-body left">
                <span class="amount">{{showValueCard(item)}}</span>
                <span class="description">
                    {{item.description}}
                    <span class="subdescription">{{item?.subdescription}}</span>
                </span>
            </div>
            <div class="impact-analysis-body right">
                <div class="graph" impactAnalysisCharts>                                   
                </div>                
            </div>
        </div>

        <div class="impact-analysis-footer">
            <span class="weightedWeight">
                <span *ngIf="item?.weightedWeight">
                    {{ 'PendingSolicitudeComponent.ImpactAnalysis.Weightedweight' | translate }}: 
                    <span class="weightedWeightValue">{{this.formatNumberPercentage(item.weightedWeight)}} %</span>
                </span>
            </span>
            <span class="umbral" *ngIf="item?.umbral">{{ 'PendingSolicitudeComponent.ImpactAnalysis.Threshold' | translate }}: 
                <span class="umbralValue">{{this.formatNumberPercentage(item?.umbral)}} %</span></span>
            <div class="filters" *ngIf="item?.chartProps?.filters">
                <p-button 
                [label]="selectedFilter?.label!='' ? selectedFilter?.label : 'Seleccione filtro'"                 
                (click)="op.toggle($event)"                 
                ></p-button>

                <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}" [dismissable]="true" >
                    <ng-template pTemplate>
                        <p-table [value]="item?.chartProps?.filters" 
                                 selectionMode="single" 
                                 (onRowSelect)="this.onRowSelect($event,op)">
                            
                            <ng-template pTemplate="body" let-rowData let-product>
                                <tr [pSelectableRow]="rowData">
                                    <td>{{product.label}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </ng-template>
                </p-overlayPanel> 
            </div>
        </div>
    </p-card>


</div>