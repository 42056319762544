<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/solicitude/'">
            {{ "SolicitudComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ 'PendingSolicitudeComponent.TitleDetail' | translate
          }}</li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.TitleDetail' | translate }}</h1>
      </div>
    </div>

    <div class="col-md-12">
      <h6 class="pstntitle">{{'Request' | translate}} : {{ solicitudeId }}</h6>
    </div>

    <app-assing-clients 
          [showFields]="showFieldClientFilter"
          [validateFilter]="true"
          [requiredFields]="false"
          [filtersEdit]="filtersEdit"
          [isProcess]="isProcess"
          [clientsEdit]="clientsEdit"
          [isCreate]="false"
          >
        </app-assing-clients>
  
        <div class="mt-5"></div>
  
        <div class="container">
          <app-price-certificate #priceCertificate
          [isCreate]="false"
          >
          </app-price-certificate>
        </div>




    <div class="row mt-5">
      <div class="pstn_container container-fluid">

        <p-table #dt [value]="objectListSolicitude"
              dataKey="id"
              styleClass="p-datatable-customers"
              [paginator]="true"
              [rows]="10"
              [scrollable]="true"
              paginatorPosition="bottom"
              currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
              [rowsPerPageOptions]="[10,25,50,100]"
              [globalFilterFields]="['materialCode', 'product', 'zofeDescription','currentListPrice', 'newPriceWithOutTax', 'currentPsp', 'newPsp', 'effectiveDateEnd', 'effectiveDateStart', 'isPriceList']"
              scrollHeight="800px">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search" aria-hidden="true" ></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{ 'SearchBy' | translate }}" />
                </span>
              </div>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <th style="width:100px">{{ "Actions" | translate }}</th>

                <th style="width:180px" pSortableColumn="materialCode">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Sku" | translate }}
                  <p-sortIcon field="materialCode"></p-sortIcon>
                  </div>
                </th>

                <th style="width:230px" pSortableColumn="product">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.MaterialDescription"| translate  }}
                  <p-sortIcon field="product"></p-sortIcon>
                  </div>
                </th>
                <th style="width:180px" pSortableColumn="isPriceList">
                  <div class="p-d-flex p-jc-between p-ai-center">{{ "SolicitudComponent.Create.LabelApprovedBy" | translate }}
                  </div>
                </th>
                <th style="width:180px" pSortableColumn="zofeDescription">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.ProductType" | translate }}
                  </div>
                </th>

                <th style="width:180px" pSortableColumn="currentListPrice">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.CurrentListPrice" | translate }}
                  <p-sortIcon field="currentListPrice"></p-sortIcon>
                </div>
                </th>

                <th style="width:180px" pSortableColumn="priceProposed">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.PriceProposed" | translate }}
                  <p-sortIcon field="priceProposed"></p-sortIcon>
                 </div>
                </th>

                <th style="width:180px" pSortableColumn="currentPsp">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.CurrentPsp" | translate }}
                  <p-sortIcon field="currentPsp"></p-sortIcon>
                 </div>
                </th>

                <th style="width:180px" pSortableColumn="proposedPsp">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.ProposedPsp" | translate }}
                  <p-sortIcon field="proposedPsp"></p-sortIcon>
                </div>
                </th>

                <th style="width:180px" pSortableColumn="validity">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Validity" | translate }}
                  <p-sortIcon field="validity"></p-sortIcon>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex"let-object >
              <tr>
                <td style="width:100px">
                  <button id="btnDelete"
                      pTooltip="Detalle"
                      type="button"
                      class="delete btn btn-secundary-delete"
                      (click)="detailAutogenerated(object)"
                      *ngIf="object.isAutogenerated">
                      <i class="uil uil-info-circle Editar" aria-hidden="true"></i>
                  </button>
                </td>
                <td style="width:180px" >{{materialCodeZeroLess(object.materialCode) }}</td>
                <td style="width:230px">{{object.product }}</td>
                <td style="width:180px">
                  {{ object.isPriceList }}
                </td>
                <td style="width:180px"> {{ object.zofeDescription }} </td>
                <td style="width:180px">
                  <div *ngIf="object.currentListPrice >= 1">
                    {{object.currentListPrice | currency: 'USD'}}
                  </div>
                  <div *ngIf="object.currentListPrice === 0">
                    {{ "SolicitudComponent.Priceless" | translate }}
                  </div>
                </td>
                <td style="width:180px">{{object.newPriceWithOutTax | currency: "USD" || ""}}</td>
                <td style="width:180px">{{object.currentPsp  | currency: "USD" || ""}}</td>
                <td style="width:180px">{{object.newPsp | currency: "USD" || ""}}</td>
                <td style="width:180px">
                  {{object.effectiveDateStart | date: "dd/MM/yyyy"}}- {{object.effectiveDateEnd | date: "dd/MM/yyyy"}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td *ngIf="validity" colspan="6">{{ "ElementsNotFound" | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
        <!-- Buttons -->
          <div class="row justify-content-between mt-4">
            <div class="mr-3">
              <button id="btnBack" pButton pRipple type="button"
                label="{{ 'PendingSolicitudeComponent.Back' | translate }}"
                class="p-button-rounded pstn_secundario w-100"
                (click)="back()"></button>
            </div>
          </div>
      </div>
    </div>

    <!-- SKUs Deleted By Approver-->
      <div *ngIf="showSkusDeletedByApprover" class="pstn_container container-fluid mt-5">
        <p-accordion>
          <p-accordionTab header="{{ 'PendingSolicitudeComponent.DeletedByApprover.TitleTableSkusDeleted' | translate }}" 
            [disabled]="!showAccordionSolicitudesDeletedByApprover">
                <p-table #dt
                  dataKey="id" 
                  name="gridSkusDeletedByApprover2" 
                  [value]="objectListSolicitudesDeletedByApprover"
                  [paginator]="true" 
                  [rows]="rows" 
                  [(first)]="page"
                  (onPage)="loadPaginators($event)"
                  [scrollable]="true"  
                  paginatorPosition="bottom"
                  currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
                  [rowsPerPageOptions]="[10,25,50,100]" styleClass="p-datatable-customers"
                  [globalFilterFields]="['materialCode', 'materialDescription', 'zofeDescription','currentListPrice', 'newPriceWithOutTax', 'priceAdjustment', 'newPsp', 'userApproverDeleted', 'effectiveDateEnd', 'effectiveDateStart', 'isPriceList']">
                  <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search" aria-hidden="true"></i>
                        <input pInputText type="text" 
                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{ 'SearchBy' | translate }}" />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 20px;"></th>
                      <th style="width:180px" pSortableColumn="materialCode">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'SolicitudComponent.Create.Code' | translate }}
                        </div>
                      </th>
                      <th style="width:180px" pSortableColumn="materialDescription">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.Product' | translate }}
                        </div>
                      </th>
                      <th style="width:180px"  pSortableColumn="isPriceList">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ "SolicitudComponent.Create.LabelApprovedBy" | translate }}
                        </div>
                      </th>
                      <th style="width:180px" pSortableColumn="zofeDescription">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ "SolicitudComponent.Create.ProductType" | translate }}
                        </div>
                      </th>
                      <th style="width:180px">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.CurrentWithoutTax' |
                          translate }}
                        </div>
                      </th>
                      <th style="width:180px" pSortableColumn="currentListPrice">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.CurrentPSP' | translate }}
                        </div>
                      </th>
                      <th style="width:180px" pSortableColumn="newPriceWithOutTax">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.NoNewTax' |
                          translate }}
                        </div>
                      </th>
                      <th style="width:180px" pSortableColumn="priceAdjustment">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.AdjustmentMoney' |
                          translate }}
                        </div>
                      </th>

                      <th style="width:180px" pSortableColumn="newPsp">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.NewPSP' | translate }}
                        </div>
                      </th>
                      <th style="width:180px" pSortableColumn="effectiveDateStart">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.InitialDate' | translate }}
                        </div>
                      </th>
                      <th style="width:180px" pSortableColumn="effectiveDateEnd">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.FinalDate' | translate }}
                        </div>
                      </th>
                      <!-- {{ "Actions" | translate }} -->
                      <th style="width:180px" pSortableColumn="userApproverDeleted">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'PendingSolicitudeComponent.DeletedByApprover.ApproverUserDeleted' | translate }}</div>
                      </th>
                      <th style="width:180px" pSortableColumn="dateApproverDeleted">
                        <div class="p-d-flex p-jc-between p-ai-center">{{ 'PendingSolicitudeComponent.DeletedByApprover.DeletionDate' | translate }}</div>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-solicitud let-editing="editing" let-ri="rowIndex">
                    <tr>
                      <td style="width:20px"></td>
                      <td style="width:180px">
                        {{solicitud.materialCode}}
                      </td>
                      <td style="width:180px">
                        {{solicitud.materialDescription}}
                      </td>
                      <td style="width:180px">
                        {{ solicitud.isPriceList}}
                      </td>
                      <td style="width:180px">
                        {{ solicitud.zofeDescription }}
                      </td>
                      <td style="width:180px">
                        <div *ngIf="solicitud.currentListPrice >= 1">
                          {{solicitud.currentListPrice | currency: 'USD'}}
                        </div>
                        <div *ngIf="solicitud.currentListPrice === 0">
                          {{ "SolicitudComponent.Priceless" | translate }}
                        </div>
                      </td>
                      <td style="width:180px">
                        {{solicitud.currentPsp | currency: 'USD'}}
                      </td>
                      <td style="width:180px">
                        {{solicitud.newPriceWithOutTax | currency: 'USD'}}
                      </td>
                      <td style="width:180px">
                        {{solicitud.priceAdjustment | currency: 'USD'}}
                      </td>

                      <td style="width:180px">
                        {{solicitud.newPsp | currency: 'USD'}}
                      </td>
                      <td style="width:180px">
                        {{solicitud.effectiveDateStart | date:'dd/MM/yyyy'}}
                      </td>
                      <td style="width:180px">
                        {{solicitud.effectiveDateEnd | date:'dd/MM/yyyy'}}
                      </td>
                      <td style="width:180px">
                        {{solicitud.userApproverDeleted}}
                      </td>
                      <td style="width:180px">
                        {{solicitud.dateApproverDeleted | date:'dd/MM/yyyy HH:mm:ss'}}
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="5">{{ 'ElementsNotFound' | translate }}</td>
                    </tr>
                  </ng-template>
                </p-table>
          </p-accordionTab>
        </p-accordion>
      </div>

  </div>




  <div class="mt-5"></div>

  <div class="container" *ngIf="objectListSolicitudeDifferential">
    <p-accordion>
      <p-accordionTab
      header="{{ 'SolicitudComponent.DifferentialPriceRequests' | translate }}"
      [selected]="true">
        <p-table #dt [value]="objectListSolicitudeDifferential"
        dataKey="id"
        styleClass="p-datatable-customers"
        [paginator]="true"
        [rows]="10"
        [scrollable]="true"
        paginatorPosition="bottom"
        currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
        [rowsPerPageOptions]="[10,25,50,100]"
        [globalFilterFields]="['id', 'statusId', 'sku','materialDescription', 'reason', 'approvalUser', 'approvalDate']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search" aria-hidden="true" ></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="{{ 'SearchBy' | translate }}" />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th style="width:180px" pSortableColumn="id">
            <div class="p-d-flex p-jc-between p-ai-center">
            {{ "SolicitudComponent.idDifferential" | translate }}
            <p-sortIcon field="id"></p-sortIcon>
            </div>
          </th>

          <th style="width:230px" pSortableColumn="statusId">
            <div class="p-d-flex p-jc-between p-ai-center">
            {{ "SolicitudComponent.Status"| translate  }}
            <p-sortIcon field="statusId"></p-sortIcon>
            </div>
          </th>

          <th style="width:180px" pSortableColumn="sku">
            <div class="p-d-flex p-jc-between p-ai-center">
            {{ "SolicitudComponent.CodigoSku" | translate }}
            <p-sortIcon field="sku"></p-sortIcon>
            </div>
          </th>

          <th style="width:200px" pSortableColumn="materialDescription">
            <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.SkuDescription" | translate }}
            <p-sortIcon field="materialDescription"></p-sortIcon>
          </div>
          </th>

          <th style="width:210px" pSortableColumn="reason">
            <div class="p-d-flex p-jc-between p-ai-center">
            {{ "SolicitudComponent.ReasonRemoval" | translate }}
            <p-sortIcon field="reason"></p-sortIcon>
          </div>
          </th>

          <th style="width:220px" pSortableColumn="approvalUser">
            <div class="p-d-flex p-jc-between p-ai-center">
            {{ "SolicitudComponent.UserApproval" | translate }}
            <p-sortIcon field="approvalUser"></p-sortIcon>
          </div>
          </th>

          <th style="width:200px" pSortableColumn="approvalDate">
            <div class="p-d-flex p-jc-between p-ai-center">
            {{ "SolicitudComponent.ApprovalDate" | translate }}
            <p-sortIcon field="approvalDate"></p-sortIcon>
          </div>
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex"let-object >
        <tr>
          <td style="width:180px" >{{object.id }}</td>
          <td style="width:230px">{{ 'DomainPrices.Domain-'+object.statusId | translate}}</td>
          
          <td style="width:180px"> {{ materialCodeZeroLess(object.sku) }} </td>
          <td style="width:200px"> {{ object.materialDescription }} </td>

          <td style="width:210px">{{object.reason }}</td>
          <td style="width:220px">{{object.approvalUser }}</td>
          <td style="width:200px">
            <div *ngIf="object.approvalUser">
              {{object.approvalDate | date: 'dd/MM/yyyy HH:mm'}}
            </div>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td *ngIf="validity" colspan="6">{{ "ElementsNotFound" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
      </p-accordionTab>
    </p-accordion>
  </div>
  <br>
  <br>
  <div class="mb-5"></div>
</section>

