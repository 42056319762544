import { ISolicitudeNotificationRequest } from '../interfaces/solicitude-notification-request.interface';
import { ISolicitudeNotificationUser } from '../interfaces/solicitude-notification-user.interface';
import { ISolicitudeNotification } from '../interfaces/solicitude-notification.interface';


export class SolicitudeNotificationRequestModel implements ISolicitudeNotificationRequest {
    /**
     * @author David Sneider Cardona Cardenas
     * @sprint 6
     */
    constructor(
        public solicitudeNotification: ISolicitudeNotification,
        public solicitudeNotificationUser: ISolicitudeNotificationUser[],
        public solicitudeId: number,
    ) {}
}