import { Injectable } from '@angular/core';
import { HttpService } from '@app/@shared/services/http.service';
import { CountrySocietyChannelRequestModel } from './models/country-society-channel-request.model';
import { CountrySocietyChanneleditRequestModel } from './models/country-society-channel-edit-request.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ICountrySocietyChannel } from './interfaces/country-society-channel.interface';

@Injectable({
    providedIn: 'root'
  })
  export class ChannelService {
    private readonly httpService: HttpService;

    constructor(httpService: HttpService) { 
      this.httpService = httpService;
    }

    /**
     * @description This method is to get all the channel.
     * @author sgonzalezj
     * @param none
     * @returns Observable<IResponseService<IChannel[]>>
     * @sprint 3
     */
    public getAllCountrySocietyChannel(id : string): Observable<IResponseService<ICountrySocietyChannel[]>> {
      const response$: Observable<IResponseService<ICountrySocietyChannel[]>> =
      this.httpService
        .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/societychannel?id=${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<ICountrySocietyChannel[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

    /**
     * @description This method is to create CountrySocietyChannel.
     * @author sgonzalezj
     * @param params
     * @returns Observable<ResponseService>
     * @sprint 1
     */
    public postCountrySocietyChannel(body: CountrySocietyChannelRequestModel): Observable<IResponseService<boolean>> {
        const response$: Observable<IResponseService<boolean>> =
         this.httpService
        .post(
            `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/country_society_channel`,
            body
        )
        .pipe(
            map((response: HttpResponse<IResponseService<boolean>>) => {
            return response.body;
            })
        );
        return response$;
    }

    /**
   * @description This method is to update CountrySocietyChannel.
   * @author sgonzalezj
   * @param params
   * @returns Observable<ResponseService>
   * @sprint 1
   */
     public putCountrySocietyChannel(id: number, body: CountrySocietyChanneleditRequestModel): Observable<IResponseService<number>> {
      const response$: Observable<IResponseService<number>> =
       this.httpService
        .put(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/country_society_channel/${id}`,
          body
        )
        .pipe(
          map((response: HttpResponse<IResponseService<number>>) => {
            return response.body;
          })
        );
      return response$;
    }

    /**
     * @description This method is to delete a CountrySocietyChannel.
     * @author sgonzalezj
     * @param params
     * @returns Observable<ResponseService>
     * @sprint 3
    */
    public deleteLogicCountrySocietyChannel(id: number): Observable<IResponseService<boolean>> {
      const response$: Observable<IResponseService<boolean>> =
      this.httpService.delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/country_society_channel/logic/${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<boolean>>) => {
            return response.body;
          })
        );
      return response$;
    }

    /**
     * @description This method is to get all the Channel by Society code.
     * @author Priscy Antonio Reales
     * @param code
     * @returns Observable<IResponseService<ICountrySocietyChannel[]>>
     * @sprint 4
     */
     public getAllChannelBySociety(code : string): Observable<IResponseService<ICountrySocietyChannel[]>> {
      const response$: Observable<IResponseService<ICountrySocietyChannel[]>> =
       this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/country_society_channel_by_society_code/${code}`)
        .pipe(
          map((response: HttpResponse<IResponseService<ICountrySocietyChannel[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

  }
