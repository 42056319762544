import { IHierarchyReleaseAssociateCreate } from '../interfaces/hierarchy-release-associate-create.interface';

export class HierarchyReleaseAssociateCreateModel implements IHierarchyReleaseAssociateCreate{

  /**
   * @author Jhon Steven Pavón Bedoya
   * @param HierarchyReleaseId
   * @param ApprovalLevel
   * @param AccessManagementId
   * @param ApprovalRankLevelId
   * @param Name
   * @param LastName
   * @param Email
   * @param Mandatory
   * @param Authorizer
   * @param Approval
   * @sprint 7
   */
    constructor(
      public hierarchyReleaseId: number,
      public approvalLevel: string,
      public accessManagementId: string,
      public approvalRankLevelId: number,
      public name: string,
      public lastName: string,
      public email: string,
      public mandatory: boolean,
      public authorizer: boolean,
      public approval: boolean
  ) {}

}
