import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponseService } from '../../../@shared/interfaces/response-service.interface';
import { HttpService } from '../../../@shared/services/http.service';

@Injectable({
    providedIn: 'root'
})

export class CustomerService {
    private readonly httpService: HttpService;
    public readonly routeUrl : string = '/api/v1/ccvp_apwb_core/customer';
    
    constructor(httpService: HttpService) {
      this.httpService = httpService;
     }


    /**
     * @description This method is to get customer availables.
     * @author Priscy Antonio Reales
     * @param customers
     * @returns Observable<IResponseService<>>
     * @sprint 24
     */
     public postValidationCustomers(customer : string[]): Observable<IResponseService<any>> {
        const response$: Observable<IResponseService<any>> = this.httpService
          .post(`${environment.apiUrlCore}${this.routeUrl}/availables`,customer)
          .pipe(
            map((response: HttpResponse<IResponseService<any>>) => {
              return response.body;
            })
          );
        return response$;
      }
}