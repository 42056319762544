<section>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="pstn breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth'"
              ><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "SolicitudComponent.Novelty.Title" | translate }}
          </li>
        </ol>
      </nav>
    </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">
          {{ "SolicitudComponent.Novelty.Title" | translate }}
        </h1>
      </div>
    </div>

    <div class="row mt-5">
      <div class="pstn_container container-fluid">
        <!-- PrimeNg Table -->
        <p-table
          #dt
          dataKey="id"
          [value]="objectVarkeysNovelty"
          [paginator]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10,25,50,100]"
          paginatorPosition="bottom"
          styleClass="p-datatable-customers"
          [globalFilterFields]="['id', 'type', 'status']"
        >
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="p-fluid p-grid p-formgrid col-md-6 row">
                <div class="col-md-8 align-items-start"></div>
                <div class="col-md-2"></div>
              </div>
              <div class="col-md-6 p-0">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search" aria-hidden="true"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{ 'SearchBy' | translate }}"
                  />
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem"></th>
              <th pSortableColumn="id">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Id" | translate }}
                  <p-sortIcon field="id"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="type">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.SolicitudeType" | translate }}
                  <p-sortIcon field="type"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="status">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Status" | translate }}
                  <p-sortIcon field="status"></p-sortIcon>
                </div>
              </th>
              <th>{{ "Actions" | translate }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-object let-expanded="expanded">
            <tr [pSelectableRow]="object">
              <td></td>
             <!-- <td>
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="object"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                ></button>
              </td>-->
              <td>
                {{ object.id }}
              </td>
              <td>
                {{ 'Domain.Domain-'+object.typeId | translate}}
                <!-- {{ object.type }} -->
              </td>
              <td>
                {{ 'Domain.Domain-'+object.statusId | translate}}
                <!-- {{ object.status }} -->
              </td>
              <td>
								<i class="uil uil-eye ver" pTooltip="{{'Detail' | translate}}"  aria-hidden="true"
								*ngIf="rolePermission.read | rolePermission" (click)="showModal(object)"></i>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
      </div>
    </div>
  </div>
</section>

<p-dialog header="{{ 'SolicitudComponent.Novelty.SeeDetail' | translate }}" [(visible)]="displayModal" [modal]="true" [style]="{ width: '60vw' }"
   [draggable]="false" [resizable]="false">
  <ng-template pTemplate="content">


  </ng-template>


  <!-- PrimeNg Table -->
  <p-table #dt name="Hola" [paginator]="false" [rows]="10" [value]="varkeysList"
    currentPageReportTemplate="{{ 'CurrentPage' | translate }}" [rowsPerPageOptions]="[10, 25, 50, 100]"
    paginatorPosition="bottom" styleClass="p-datatable-customers">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ 'SolicitudComponent.Novelty.Varkey' | translate }}
            <!-- <p-sortIcon field="statusDescription"></p-sortIcon> -->
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ 'SolicitudComponent.Novelty.ErrorMessage' | translate }}
            <!-- <p-sortIcon field="creationUser"></p-sortIcon> -->
          </div>
        </th>
				<th>{{ "Actions" | translate }}</th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body" let-object>
      <tr>
        <td>{{ object.varkeyCode }}</td>
        <td>{{ object.error }}</td>
				<td>
					 <i class="uil uil-trash-alt eliminar"  aria-hidden="true" *ngIf="rolePermission.delete | rolePermission"
           (click)="deleteItem(object)" pTooltip="{{'Delete' | translate}}"></i>
					
					<i
						 aria-hidden="true"
						 id="btnMessage"
						 class="uil uil-message enviar"
             pTooltip="{{'SendRequest' | translate}}"
						 *ngIf="(rolePermission.create | rolePermission)"
             (click)="sendVarkey(object);"
					 ></i>
				</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- PrimeNg Table -->


  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'CloseBtn' | translate}}" icon="pi pi-times" class="p-button-text"
      (click)="hideModal()"></button>
  </ng-template>
</p-dialog>
<p-toast></p-toast>