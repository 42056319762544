import { ComponentModule } from './../../@components/component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApprovalRankCreateEditComponent } from './approval-ranks/approval-rank-create-edit/approval-rank-create-edit.component';
import { PrimeNgModule } from '@app/@shared/modules/prime-ng.module';
import { SharedModule } from '@app/@shared';
import { ApprovalRankComponent } from './approval-ranks/approval-rank.component';
import { HierarchyReleaseComponent } from './hierarchy-release/hierarchy-release.component';
import { CreateComponent } from './hierarchy-release/create/create.component';
import { AssociateComponent } from './hierarchy-release/associate/associate.component';
import { AssociateRankComponent } from './hierarchy-release/associate-rank/associate-rank.component';
import { ManagePromotionalActivitiesComponent } from './manage-promotional-activities/manage-promotional-activities.component';
import { PromotionalActivitiesCreateComponent } from './manage-promotional-activities/promotional-activities-create/promotional-activities-create.component';
import { ValidityPeriodComponent } from './validity-period/validity-period.component';
import { CommercialActivityDiscountComponent } from './manage-promotional-activities/commercial-activity-discount/commercial-activity-discount.component';
import { ExclusionsComponent } from './exclusions/exclusions.component';
import { CommercialActivityLoyaltyComponent } from './manage-promotional-activities/commercial-activity-loyalty/commercial-activity-loyalty.component';
import { CommercialActivityEventsComponent } from './manage-promotional-activities/commercial-activity-events/commercial-activity-events.component';
import { CommercialActivityZeroValueProductComponent } from './manage-promotional-activities/commercial-activity-zero-value-product/commercial-activity-zero-value-product.component';
import { ExclusionsManageComponent } from './exclusions/exclusions-manage/exclusions-manage.component';
import { ExclusionsTableFilterComponent } from './exclusions/exclusions-table-filter/exclusions-table-filter.component';
import { ExclusionsCreateComponent } from './exclusions/exclusions-create/exclusions-create.component';
import { CommercialActivityRappelComponent } from './manage-promotional-activities/commercial-activity-rappel/commercial-activity-rappel.component';
import { CommercialActivityRedemptionComponent } from './manage-promotional-activities/commercial-activity-redemption/commercial-activity-redemption.component';
import { RedemptionFilterComponent } from './manage-promotional-activities/commercial-activity-redemption/redemption-filter/redemption-filter.component';
import { LiberationHierarchyComponent } from './liberation-hierarchy/liberation-hierarchy.component';
import { ApprovalRanksComponent } from './liberation-hierarchy/hierarchy-map/approval-ranks/approval-ranks.component';
import { OrganizationChartComponent } from './liberation-hierarchy/organization-chart/organization-chart.component';
import { CommercialActivityNegociationExclusionComponent } from './commercial-activity-negociation-exclusion/commercial-activity-negociation-exclusion.component';
import { ExclusionsBySkuComponent } from './exclusions-by-sku/exclusions-by-sku.componente';
import { ExclusionsBySkuCreateEditComponent } from './exclusions-by-sku/exclusions-by-sku-create-edit/exclusions-by-sku-create-edit.component';
import { TextMaskModule } from 'angular2-text-mask';
import { PromotionalactivityRoutingModule } from './promotionalactivity-routing.module';

@NgModule({
  declarations: [
    ApprovalRankCreateEditComponent,
    ApprovalRankComponent,
    HierarchyReleaseComponent,
    CreateComponent,
    AssociateComponent,
    AssociateRankComponent,
    ManagePromotionalActivitiesComponent,
    PromotionalActivitiesCreateComponent,
    ValidityPeriodComponent,
    CommercialActivityDiscountComponent,
    CommercialActivityLoyaltyComponent,
    CommercialActivityEventsComponent,
    CommercialActivityZeroValueProductComponent,
    ExclusionsManageComponent,
    ExclusionsTableFilterComponent,
    ExclusionsComponent,
    ExclusionsTableFilterComponent,
    ExclusionsCreateComponent,
    CommercialActivityRappelComponent,
    CommercialActivityRedemptionComponent,
    RedemptionFilterComponent,
    CommercialActivityNegociationExclusionComponent,
    ExclusionsBySkuComponent,
    ExclusionsBySkuCreateEditComponent,
    LiberationHierarchyComponent,
    ApprovalRanksComponent,
    OrganizationChartComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    SharedModule,
    PrimeNgModule,
    ComponentModule,
    TextMaskModule,
    PromotionalactivityRoutingModule
  ]
})
export class PromotionalactivityModule { }
