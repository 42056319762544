import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { DomainService } from '@app/@shared/services/domain.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { ExclusionsBySkuService } from './exclusions-by-sku.service';
import { ExlusionBySkuModel } from './models/exclusions-by-sku.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { exclusionState, exclusionStateString } from '@app/@shared/enums/exclusions.enum';


@Component({
  selector: 'app-exclusions-manage',
  templateUrl: './exclusions-by-sku.componente.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
    .generalData .options{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService]
})
export class ExclusionsBySkuComponent implements OnInit {
  private readonly router: Router;
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly domainService: DomainService;
  private readonly coreService: CoreMasterService;
  private readonly exclusionsBySkuService: ExclusionsBySkuService;

  public exclusionsList: ExlusionBySkuModel[] = [];
  public selectedRows: number[] = [];
  public rolePermission: any = RolePermissionEnum;
  public objectChannel: any[] = [];
  public objectPromotionalActivity: any[] = [];

  constructor(
    router: Router,
    spinner: NgxSpinnerService,
    messageService: MessageService,
    domainService: DomainService,
    coreService: CoreMasterService,
    exclusionsBySkuService: ExclusionsBySkuService,
  ){
      this.router = router;
      this.spinner = spinner;
      this.messageService = messageService;
      this.domainService = domainService;
      this.coreService = coreService;
      this.exclusionsBySkuService = exclusionsBySkuService;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loadInformation();
  }

  public loadInformation(){
    const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
    const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);


    forkJoin([
      channelResponse,
      processResponse
    ])
      .subscribe(
        (result) => {

          const objChannelResponse: IResponseService<ICoreMaster[]> = result[0];
          const objProcessResponse: IResponseService<IDomain[]> = result[1];

          this.objectChannel = objChannelResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });

          this.objectPromotionalActivity = objProcessResponse.data.filter((y) => y.code === 'AP').map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          this.getAll();

        }, () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
  }

  /**
  * @description this method obtains the information for the table.
  * @author Santiago Martinez
  */

  public getAll(): void {
    this.exclusionsList = [];
    this.spinner.show();
    this.exclusionsBySkuService.getListExclusions().subscribe(
      (response) => {
        if (response.status) {
          this.exclusionsList = response.data;
          this.exclusionsList.sort((b,a) => a.id - b.id);

          this.exclusionsList.forEach((exclusion) => {
            const canales: string[] = [];
            const tipoActividades: string[] = [];
            const idActivity: number[] = [];
            exclusion.canal.forEach((x) => { this.objectChannel.find((y) => {
              if(y.value === x) canales.push(y.label);
            });

            exclusion.canal = [];
            exclusion.idActivity = [];
            exclusion.canal.push(canales.join(', '));

            exclusion.typeBusinessActivity.forEach((x) => {this.objectPromotionalActivity.find((y) => {
                  if(y.value === x) {
                    tipoActividades.push(y.label);
                  }
                });
              });
              exclusion.typeBusinessActivity = [];
              exclusion.typeBusinessActivity = tipoActividades;
            });
          });
        }
        this.spinner.hide();

      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  getState(state: number): string {
    switch (state) {
      case exclusionState.registered:
        return exclusionStateString.registered;
      case exclusionState.valid:
        return exclusionStateString.valid;
      case exclusionState.finalized:
        return exclusionStateString.finalized;
      default: break;
    }
  }

  createAction(){
    this.exclusionsBySkuService.setProcessExclusion(ComercialActivityEnum.create);
    this.router.navigate(['auth/exclusion-by-sku/create-edit']);
  }

  selectAll($event: any){
    this.exclusionsList.forEach((x) => {
      x['check'] = $event;
    });
  }

  selectRow($event: any, object: ExlusionBySkuModel){
    this.exclusionsList.forEach((x) => {
      if(x.id === object.id) x['check'] = $event;
    });
  }

  editObjectAction(object: any){
    this.exclusionsBySkuService.setProcessExclusion(ComercialActivityEnum.update);
    this.exclusionsBySkuService.setExclusionId(object.id);
    this.router.navigate(['auth/exclusion-by-sku/create-edit']);
  }

  deleteObjectAction(value:  ExlusionBySkuModel){

    Util.confirmDelete().then((result: SweetAlertResult) => {
      if(result.isConfirmed){
        const listIds: number[] = [];
        listIds.push(value.id);
        this.exclusionsBySkuService.deleteExclusionSelected(listIds).subscribe(
          (response: any) => {

            if (response.status) {

              this.getAll();
              const language: string = sessionStorage.getItem('language');

              this.showToast(
                SweetAlert.iconInfo,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate
              );
              this.spinner.hide();
            } else {
              this.spinner.hide();
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          });
      }
    });

  }

  deleteAllObjectAction(){
    const exclusionSelected = this.exclusionsList.filter((x) => x['check'] === true);
    if(exclusionSelected === undefined || exclusionSelected.length === 0) return;

    Util.confirmDelete().then((result: SweetAlertResult) => {
      if(result.isConfirmed){
        const listIds: number[] = [];
        exclusionSelected.map((y) => {
          listIds.push(y.id);
        });

        this.exclusionsBySkuService.deleteExclusionSelected(listIds).subscribe(
          (response: any) => {
            if (response.status) {
              this.getAll();
              const language: string = sessionStorage.getItem('language');
              this.showToast(
                SweetAlert.iconInfo,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate
              );
              this.spinner.hide();
            } else {
              this.spinner.hide();
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          });
      }
    });
  }

  viewDetailsObjectAction(object: ExlusionBySkuModel){
    this.exclusionsBySkuService.setProcessExclusion(ComercialActivityEnum.view);
    this.exclusionsBySkuService.setExclusionId(object.id);
    this.router.navigate(['auth/exclusion-by-sku/create-edit']);
  }


  public showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

}
