import { ILiberationHierarchy } from '../interfaces/liberation-hierarchy.interface';
import { HierarchyConfigurationModel } from './hierarchy-configuration.model';

export class LiberationHierarchyModel implements ILiberationHierarchy{

  constructor(
    public hierarchyConfiguration: HierarchyConfigurationModel,
    public sectorCode: string[],
    public typeCommercialActivityDomainId: string[],
    public channelCode: string[],
    public macrosegmentCode: string[],
    public typologyCode:string[],
    public cediCode:string[]
  ) {}
}