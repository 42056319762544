import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommercialActivityInvalidationDataDetailShow } from '@app/@modules/promotionalactivity/manage-promotional-activities/interfaces/commercial-activity-invalidation-data-detail';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { ManagePromotionalActivitiesMessage } from '@app/@modules/promotionalactivity/manage-promotional-activities/messages/manage-promotional-activities-messages.message';
import { CommercialActivityInterruptBatchModel } from '@app/@modules/promotionalactivity/manage-promotional-activities/models/commercial-activity-interrupt.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { IAdvancedFiltersClient } from '@app/@shared/interfaces/advanced-filters-client.interface';
import { AdvancedFiltersClientModel } from '@app/@shared/model/advanced-filters-client.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CustomerSummaryService } from '../assing-clients/customer-summary/customer-summary.service';

@Component({
  selector: 'app-commercial-activity-invalid',
  templateUrl: './commercial-activity-invalid.component.html',
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }

        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommercialActivityInvalidComponent implements OnInit {
  @Input('isVisible') isVisible = false;
  @Input('dateInterruptionActivity') dateInterruptionActivity : Date;
  @Input('invalidationDataDetail') invalidationDataDetail: CommercialActivityInvalidationDataDetailShow[] = [];
  @Input('isProcess') isProcess = false;
  @Input('activityId') activityId = 0;
  @Input('filterClientJson') filterClientJson: string;
  @Output('eventClickInvalidate') eventClickInvalidate: EventEmitter<any> = new EventEmitter();

  private readonly spinner: NgxSpinnerService;
  private readonly fbInterruption: FormBuilder;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly summaryService: CustomerSummaryService;

  public selectedRadioButton = 2;
  public formCommercialActivityInterrupt: FormGroup;
  public commercialActivityStateEnum = CommercialActivityStatus;
  public downloadFileClients: boolean = true;
  public processEnum: any;

  constructor(
    spinner: NgxSpinnerService,
    fbInterruption: FormBuilder,
    managePromotionActService: ManagePromotionalActivitiesService,
    summaryService: CustomerSummaryService
  ) {
    this.spinner = spinner;
    this.fbInterruption = fbInterruption;
    this.managePromotionActService = managePromotionActService;
    this.summaryService = summaryService;
  }

  ngOnInit(): void {
    this.processEnum = this.getCommercialActivityEnum();
    if (this.isProcess == this.processEnum.create)
    {
      this.downloadFileClients = true;
    }
    else
    {
      this.downloadFileClients = false;
    }
	  this.initForm();
	  this.dateInterruption.setValue(this.dateInterruptionActivity);
  }

  hideModalInterrupt(): void {
    this.isVisible = false;
    this.resetModalInterrupt();
  }

  resetModalInterrupt(): void {
    this.formCommercialActivityInterrupt.reset();
  }

  saveInterruption(): void {
    if (this.formCommercialActivityInterrupt.valid) {
      this.spinner.show();
      const interruptModel = new CommercialActivityInterruptBatchModel(
        this.invalidationDataDetail.map((item) => item.id),
        this.commercialActivityStateEnum.Interrupt,
        this.descriptionInterruption.value,
        this.dateInterruption.value
      );

      this.managePromotionActService
        .postCommercialActivityInterruptBatch(interruptModel)
        .subscribe(
          (response) => {
            this.spinner.hide();
            if (response.status) {
              this.hideModalInterrupt();
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.success(
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? ManagePromotionalActivitiesMessage.INTERRUPT_OK : ManagePromotionalActivitiesMessage.INTERRUPT_OK_TRANSLATE
              );
              this.eventClickInvalidateCommercialActivity(true);
            } else {
              SweetAlertMessageHelpers.listWarningMessageShow(
                response.messages[0],
                response.messages.splice(1),
                1,
                '800px'
              );
            }
          },
          (error) => {
            this.spinner.hide();
			SweetAlertMessageHelpers.error(
				SweetAlert.titleAlertError,
				`${error.error.Message}`
			  );
          }
        );
    } else {
      this.formCommercialActivityInterrupt.markAllAsTouched();
    }
  }

  eventClickInvalidateCommercialActivity(opc:boolean):void{
	this.eventClickInvalidate.emit(opc);
  }

  /**
   * @description This method is to initialize the form.
   * @author Diego Mauricio Cortés
   * @sprint 7
   */
  initForm(): void {
    this.formCommercialActivityInterrupt = this.fbInterruption.group({
      descriptionInterruption: [
        { value: '', disabled: false },
        [Validators.required],
      ],
      dateInterruption: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }  


  downloadListClients(id: any) {
    const ids = this.activityId + ',' + id;
    let filterClientes = this.filterClientJson;
    const objFilters: IAdvancedFiltersClient = new AdvancedFiltersClientModel(
      filterClientes,
      true,
      1,
      100,
      ids
    );
    this.summaryService.getFile(objFilters).subscribe();
  }


  get descriptionInterruption(): AbstractControl {
    return this.formCommercialActivityInterrupt.get('descriptionInterruption');
  }
  get dateInterruption(): AbstractControl {
    return this.formCommercialActivityInterrupt.get('dateInterruption');
  }
}
