<p-accordion >
  <p-accordionTab header="{{ nameComponent | translate }}" [selected]="showManejantes" [disabled]="!showManejantes">
    
    <app-hierarchy-advanced-filters
      #advancedFilterBase
      [isMultiFilter]="true"
      [rolePermissionBtn]="rolePermissionBtn"
      [editFiltersObjectList]="editFiltersObjectList"
      [isProcess]="isProcess"
      [sector]="sector"
      [requireSector]="true"
      [typeCoreMaster]="typeCoreMaster"
      (eventClickFilters)="eventClickFilterMaterial($event)"
    ></app-hierarchy-advanced-filters>

    <div class="row mt-5"></div>

    <app-filter-material-detail
              #detailBase
              [nameComponent]="nameComponent"
              [isProcess]="isProcess"
              [isHandlerShow]="true"
              [filterObjectHandlerList]="commercialActivityHandlerDetails"
              [commercialActivityHandlerisGroup]="commercialActivityHandlerisGroup"
              [commercialActivityHandlerisClientWithoutSales]="commercialActivityHandlerisClientWithoutSales"
              [filterObjectList]="advancedFiltersObjectList"
              [valueCalendar]="valueCalendar.asObservable()"
              [isCurrentMonth]="IsCurrentMonth"
              [typeCoreMaster]="typeCoreMaster"
    ></app-filter-material-detail>
  
    <div class="row mt-5"></div>
    <div *ngIf="!IsCurrentMonth">
      <app-calendar-period 
          #detailPeriod
          [isProcess]="isProcess"
          [commercialActivityHandlerPeriod]="commercialActivityHandlerPeriod"
          (calendarValue)="calendarValue($event)"
          >
      </app-calendar-period>
    </div>
  </p-accordionTab>
</p-accordion>
