<p-accordion>
    <p-accordionTab header="{{ 'HierarchyAdvancedFiltersComponent.AdvancedFilters' | translate }}">
      <div class="pstn_container container-fluid">
        <div>
          <p-tabView>
            <p-tabPanel header="{{ 'AdvancedFiltersComponent.Generals' | translate }}">
              <app-general-advanced-filter [showFieldsComponent]="showFieldsComponent" 
                                           [showFieldsGeneral]="showFieldsGeneral"
                                           [advancedSearchFilter]="advancedSearchFilter">

              </app-general-advanced-filter>
           </p-tabPanel>
           <p-tabPanel header="{{ 'AdvancedFiltersComponent.Customers' | translate }}">
            <app-clients-advanced-filter  [showFieldsComponent]="showFieldsComponent"
                                          [validateFilter]="validateFilter"
                                          [showFieldsClient]="showFieldsClient"
                                          [rolePermissionBtn]="rolePermissionBtn"
                                          [filtersEdit]="filtersEdit"
                                          [clientsEdit]="clientsEdit"
                                          [showFields]="showFields"
                                          [isProcess]="2"
                                          #advancedFilterClient>
            </app-clients-advanced-filter>
          </p-tabPanel>
           <p-tabPanel header="{{ nameFilterMaterial | translate }}">
           
            <app-hierarchy-advanced-filters 
                #advancedFilterBase
                [isMultiFilter]="true"
                [rolePermissionBtn]="rolePermissionBtn"
                [editFiltersObjectList]="editFiltersObjectList"
                (eventClickFilters)="eventClickFilterMaterialBase($event)"
            ></app-hierarchy-advanced-filters>
            
            <app-filter-material-detail
              #detailBase
              [filterObjectList]="eventMaterialBase"
            ></app-filter-material-detail>

           </p-tabPanel>
           <p-tabPanel 
              header="{{ 'AdvancedFiltersComponent.ProductsPromotion' | translate }}" 
              *ngIf="showFieldsComponent">
            
            <app-hierarchy-advanced-filters 
                #advancedFilterBasePromotion
                [isMultiFilter]="true" 
                [rolePermissionBtn]="rolePermissionBtn"
                (eventClickFilters)="eventClickFilterMaterialPromotion($event)"
            ></app-hierarchy-advanced-filters>

            <app-filter-material-detail
              #detailBasePromotion
              [filterObjectList]="eventMaterialBasePromotion"
            ></app-filter-material-detail>
            
           </p-tabPanel>
        </p-tabView>
        </div>
        <div class="text-right">
          <button 
            *ngIf="(rolePermission.read | rolePermission)" 
            id="btnRunFilters" 
            class="p-button-rounded pstn_primario"
            pButton pRipple 
            type="button"
            label="{{ 'AdvancedClientFiltersComponent.ExecuteFilter' | translate }}"
            (click)="eventClickFilters()">
          </button>
          <button 
            *ngIf="(rolePermission.read | rolePermission)" 
            id="btnClearFilters" 
            class="p-button-rounded pstn_secundario"
            pButton pRipple 
            type="button"
            label="{{ 'AdvancedClientFiltersComponent.ClearFilter' | translate }}"
            (click)="eventClearFilters()">
          </button>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>