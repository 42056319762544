import { ICommercialActivityCopy } from '../interfaces/commercial-activity-Copy.interface';

export class CommercialActivityCopyModel implements ICommercialActivityCopy {
  /**
   * @author Elkin Vasquez Isenia
   * @param id
   * @sprint 4
   */
  constructor(
    public id: number,
  ) {}
}
