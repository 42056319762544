import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { PrimeNgModule } from '../@shared/modules/prime-ng.module';
import { NotificationComponent } from './layouts/siderbar/notification/notification.component';
import { NotificationCardComponent } from './layouts/siderbar/notification-card/notification-card.component';
import { NotificationModalComponent } from './layouts/siderbar/notification-modal/notification-modal.component';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';

import { RetryInterceptor } from '@core/interceptors/retry.interceptor';

import 'moment/locale/es';

import { MessageService } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    PrimeNgModule,
    RouterModule,
    FormsModule,
    MomentModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    MessageService
  ],
  exports: [
    NotificationComponent,
    NotificationCardComponent,
    NotificationModalComponent,
  ],
  declarations: [
    NotificationComponent,
    NotificationCardComponent,
    NotificationModalComponent
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`
      );
    }
  }
}
