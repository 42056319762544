import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly http: HttpClient;
  private httpOptions: any;

  constructor(http: HttpClient, private readonly router: Router) {
    this.http = http;
    this.getHeaders();
  }

  private getHeaders(){
    const profile = SessionStorageHelper.get(CommonConfiguration.ProfileId);
    const firstLoad = sessionStorage.getItem(CommonConfiguration.FirstLoad);

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Profile' :  profile,
        'Route' : this.getRouteFather(),
        'FirstLoad' : firstLoad,
        'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey
      }),
    };

    this.httpOptions.observe = 'response';
  }

  private getHeadersFile(){
    const profile = SessionStorageHelper.get(CommonConfiguration.ProfileId);
    const firstLoad = sessionStorage.getItem(CommonConfiguration.FirstLoad);

    this.httpOptions = {
      headers: new HttpHeaders({
        'Profile' :  profile,
        'Route' : this.getRouteFather(),
        'FirstLoad' : firstLoad,
        'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey
      }),
    };

    this.httpOptions.observe = 'response';
  }

  /**
   * @description This method returns the values ​​associated with the request.
   * @author Elkin Vasquez Isenia
   * @param URL
   * @param parameters
   * @returns Observable<any>
   * @sprint 1
   */
  public get(URL: string, parameters?: any, httpOptionBlob? : boolean ): Observable<any> {
    this.httpOptions.params = null;

    if (parameters !== undefined) {
      let httpParams = new HttpParams();
      Object.keys(parameters).forEach((element) => {
        httpParams = httpParams.append(element, parameters[element]);
      });
      this.httpOptions.params = httpParams;
    }
    
    this.getHeaders();
    if(httpOptionBlob)
      this.httpOptions.responseType = 'blob';
      
    return this.http.get<any>(URL, this.httpOptions);
  }

  /**
   * @description This method creates a record associated with the request.
   * @author Elkin Vasquez Isenia
   * @param URL
   * @param parameters
   * @returns Observable<any>
   * @sprint 1
   */
  public post(URL: string, parameters?: any, httpOptionBlob? : boolean): Observable<any> {
    this.httpOptions.params = null;
    this.getHeaders();

    if(httpOptionBlob){
      this.httpOptions.responseType = 'blob';
    }

    return this.http.post(URL, parameters, this.httpOptions);
  }

  public postFile(URL: string, parameters?: any): Observable<any> {
    this.httpOptions.params = null;
    this.getHeadersFile()
    return this.http.post(URL, parameters, this.httpOptions);
  }

  /**
   * @description This method updates a record associated with the request.
   * @author Elkin Vasquez Isenia
   * @param URL
   * @param parameters
   * @returns Observable<any>
   * @sprint 1
   */
  public put(URL: string, parameters: any): Observable<any> {
    this.httpOptions.params = null;
    this.getHeaders();

    return this.http.put(URL, parameters, this.httpOptions);
  }

  /**
   * @description This method removes a record associated with the request.
   * @author Elkin Vasquez Isenia
   * @param URL
   * @param parameters
   * @returns Observable<any>
   * @sprint 1
   */
  public delete(URL: string, parameters?: any): Observable<any> {
    this.httpOptions.params = null;

    if (parameters !== undefined) {
      let httpParams = new HttpParams();
      Object.keys(parameters).forEach((element) => {
        httpParams = httpParams.append(element, parameters[element]);
      });
      this.httpOptions.params = httpParams;
    }

    this.getHeaders();

    return this.http
      .delete<any>(URL, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  public patch(URL: string, parameters: any): Observable<any> {
    this.httpOptions.params = null;
    this.getHeaders();
    return this.http.patch(URL, parameters, this.httpOptions);
  }


  private getRouteFather() : string {
    const rutaPadre = this.router.url.split('/')[2] !== undefined ? this.router.url.split('/')[2] : '';
    return rutaPadre;
  }

  /**
   * @description This method checks for the error and performs the defined action of displaying a message..
   * @author Elkin Vasquez Isenia
   * @param error
   * @returns none
   * @sprint 1
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Cuando Un error del lado del cliente o un error de red occurrio.
      console.error('An error occurred:', error.error.message);
    } else {
      // el backend ha retornado un codigo de respuesta no exitoso.
      // el body de la respuesta puede contener pista de que fue mal,
      console.error(
        `El servidor retorno un  codigo ${error.status}, ` +
        `el body fue: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(
      'Algo inesperado sucedio; Por favor, inténtelo de nuevo más tarde.'
    );
  }
}
