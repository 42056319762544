import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Util } from '../../../../@shared/util';
import { SweetAlertResult } from 'sweetalert2';

import { ApplicationConstants } from '@app/app.constants';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { RegexCommon } from '@app/@shared/enums/regex.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { DomainTypeSecurity } from '@app/@shared/enums/domain-type-security.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ManagePromotionalActivitiesMessage } from '../messages/manage-promotional-activities-messages.message';
import { DomainService } from '@app/@shared/services/domain.service';
import { ManagePromotionalActivitiesService } from '../manage-promotional-activities.service';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { PromotionalActivitiesCreateService } from './promotional-activities-create.service';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';

import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { IProductList } from './interfaces/product-list.interface';
import { ICommercialActivityFooter } from '@components/commercial-activity-footer/interfaces/commercial-activity-footer.interface';
import { IProductGroupList } from './interfaces/product-group-list.interface';
import { IChannel } from './interfaces/channel-interface';
import { ICustomer } from './interfaces/customer-list.interface';
import { CommercialActivityInvalidationDataDetail, CommercialActivityInvalidationDataDetailShow } from './../interfaces/commercial-activity-invalidation-data-detail';

import { PromotionalActivityModel } from './models/promotional-activity.model';
import { ProductGroupListModel } from './models/product-group-list.model';
import { IHierarchyFiltersMaterials } from '@app/@components/hierarchy-advanced-filters/interfaces/hierarchy-filters-materials.interface';
import { IPromotionalActivity } from './interfaces/promotional-activity.interface';
import { IDataRange } from '@app/@components/commercial-activity-footer/interfaces/data-range.interface';
import { CommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/commercial-activity-footer.model';

import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { MeasurementUnit } from '@app/@shared/enums/measurement-unit.enum';
import { FieldsCommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/fields-commercial-activity-footer.model';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { IFieldsCommercialActivityFooter } from '@app/@components/commercial-activity-footer/interfaces/fields-commercial-activity-footer.interface';
import { CommercialACtivityQueueValidationModel } from '../models/commercial-activity-queue-validation.model';
import { Module } from '@app/@shared/enums/module.enums';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { ICommercialActivitySector } from '../interfaces/commercial-activity-sector.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { CommercialActivityStatusModel } from '../models/commercial-activity-status.model';
import { IUserCreation } from '@app/@components/commercial-activity-approval-selected/interface/user-creation.interface';
import { UserCreationModel } from '@app/@components/commercial-activity-approval-selected/models/user-creation.model';
import { CommercialActivityEventsMessage } from '../commercial-activity-events/messages/commercial-activity-events.message';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';
import { HandlingProductFilterComponent } from '@app/@components/handling-product-filter/handling-product-filter.component';
import { ICommercialActivityHandlerPeriod } from '@app/@components/elements/calendar-period/interfaces/handler-period.interface';
import { ICommercialActivityHandler } from '@app/@components/handling-product-filter/interfaces/commercial-activity-handler.interface';
import { HandlingProductFilterService } from '@app/@components/handling-product-filter/handling-product-filter.service';
import { CommercialActivityHandlerModel } from '@app/@components/handling-product-filter/models/commercial-activity-handler.model';
import { ActivityHandlerModel } from '@app/@components/handling-product-filter/models/activity-handler.model';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';
import { CommercialActivityDocumentComponent } from '@app/@components/commercial-activity-document/commercial-activity-document.component';
import { CommercialActivityDocumentRequiredModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document-required.model';
import { CommercialActivityDocumentModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document.model';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { SwitchService } from '@app/@components/elements/switch/switch.service';

@Component({
	selector: 'app-promotional-activities-create',
	templateUrl: './promotional-activities-create.component.html',
	styleUrls: ['./../../../../@shared/scss/responsive.table.scss', './promotional-activities-create.component.css'],
	styles: [
		`
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
	],
	providers: [MessageService, DatePipe, SwitchService],
})
export class PromotionalActivitiesCreateComponent implements OnInit {

	@ViewChild('activityHandler') activityHandler : HandlingProductFilterComponent;
	@ViewChild('ActivityHandlerCurrentMonth') ActivityHandlerCurrentMonth : HandlingProductFilterComponent;
	@ViewChild('documentSupport') documentSupport: CommercialActivityDocumentComponent;

	private readonly domainService: DomainService;
	private readonly spinner: NgxSpinnerService;
	private readonly fb: FormBuilder;
	private readonly managePromotionActService: ManagePromotionalActivitiesService;
	private readonly messageService: MessageService;
	private readonly coreService: CoreMasterService;
	private readonly promotionalActivityService: PromotionalActivitiesCreateService;
	private readonly promotionalActivityHandlerService: HandlingProductFilterService;
	private readonly router: Router;
	private readonly changeDetector: ChangeDetectorRef;
	private readonly parameterService: ManageParametersService;
	private readonly translate: TranslateService;
	private readonly i18nService: I18nService;


	// #region Define Variables
	public rolePermission: any = RolePermissionEnum;
	public isProcess: number;

	public commActivityStatusRegisteredId: number = CommercialActivityStatus.Registered;
	public commActivityStatusValidatedId: number = CommercialActivityStatus.Validated;
	public commercialActivityId: number;
	public isDeleteProducts = false;
	public isDeleteProductsPromotion = false;
	public requiredGroup = true;
	public formPromotionalActivity: FormGroup;
	public filterTypeName = 'PromotionalActivitiesTraditionalCreate';
	public typeFiltersObjectList: ICombo[];
	public objectPromotionTypeList: ICombo[];
	public objectChannelList: ICombo[];
	public objectProcessList: ICombo[];
	public objectTypeCommercialActivity: any[] = [];
	public objectTypePromotionActivity: any = [];
	public isMultiFilter = false;
	public isPurchaseBase = true;
	public advancedFiltersObjectList: IHierarchyFiltersMaterials[] = [];
	public editFiltersObjectList: IHierarchyFiltersMaterials[] = [];
	public editFiltersPromotionObjectList: IHierarchyFiltersMaterials[] = [];
	public promotionEdit: CommercialActivityFooterModel;
	public promotionEditFooter: CommercialActivityFooterModel;
	public promotionActivityEdit: IPromotionalActivity;
	public typeCommercialActivityId: number;
	public nextAssignClients = false;
	public displayValidationsDetails = false;
	public promotionalActivityModel: PromotionalActivityModel;
	public filterClientJson: string;
	public filterClientJsonToCalculate: string;
	public commercialActivityCustomers: ICustomer[];
	public commercialActStatVal: number;
	public invalidationDataDetail: CommercialActivityInvalidationDataDetailShow[] = [];
	public documentInfo: CommercialActivityDocumentModel[];
	public flagDocumentObservation: boolean = true;

	public routeCancelCreatePromotion = '/auth/manage-promotional-activities';
	public showFieldsCreatePromotion: IFieldsCommercialActivityFooter;
	public showButtonsCreatePromotion = true;
	public dataToShow: any[] = [];
	public products: any[] = [];
	public commercialActivityEnum: any;
	public commercialActivityStateEnum: any;
	public totalDepositValue = 0;
	public totalEbitdaPercentage = 0;
	public totalEbitda = 0;
	public totalPromotionDepositValue = 0;
	public totalPromotionEbitdaPercentage = 0;
	public totalPromotionEbitda = 0;
	public objectSectorList: ICombo[];
	public objectSectorSelect: ICombo[] = [];
	public sectorCommercialActivityParam = false;
	public selectedRadioButton = 2;
	public filterGroupOptions: any[] = [];
	public tomorrow: Date = new Date();
	public channelTooltip: string[];
	public sectorTooltip: string[];
	public isInitialError: boolean = true;
	public documentValid: boolean = true;
	public documentFieldsRequired: CommercialActivityDocumentRequiredModel;
	public observationEdit: CommercialActivityDocumentModel[];
	public showFields : IFieldsClientFilter = new FieldsClientFilterModel(true, true, true, false, true, true);

	// #region Products in group table
	public productGroupFilterList: IProductGroupList[] = [];
	public selectProductHeadGroupList: IProductGroupList[] = [];
	public selectProductList: IProductList[] = [];
	// #endregion

	// #region Promotional Products
	public productGroupFilterListPromotion: IProductGroupList[] = [];
	public selectProductHeadGroupListPromotion: IProductGroupList[] = [];
	public selectProductListPromotion: IProductList[] = [];
	// #endregion


	// #region calculate variables
	public purchaseBaseToCalculate: IProductList[] = [];
	public purchasePromotionToCalculate: IProductList[] = [];
	// #endregion

	public userCreation : IUserCreation;
	public initialDate : Date;
	public language: string = sessionStorage.getItem('language');

	// manejantes
	public showManejantes = false;
	public editFiltersObjectHandlerList: IHierarchyFiltersMaterials[] = [];
	public editFiltersObjectHandlerList2: IHierarchyFiltersMaterials[] = [];
	public commercialActivityHandlerDetails: any[] = [];
	public commercialActivityHandlerDetails2: any[] = [];
	// public commercialActivityHandlerDetailsAux: any[] = [];
	public commercialActivityHandlerDetailsAux2: any[] = [];
	public commercialActivityHandlerPeriod : ICommercialActivityHandlerPeriod[] = [];
	public commercialActivityHandlerPeriod2 : ICommercialActivityHandlerPeriod[] = [];
	public commercialActivityHandler?: ICommercialActivityHandler;
	public commercialActivityHandler2?: ICommercialActivityHandler;
	public commercialActivityHandlerisGroup?: boolean;
	public commercialActivityHandlerisGroup2?: boolean;
	public commercialActivityHandlerisClientWithoutSales?: boolean;
	public commercialActivityHandlerisClientWithoutSales2?: boolean;
	public flagHanlder? = false;

	//HandlerCurrentMonth
	public commercialActivityHandlerCurrentMonth?: ICommercialActivityHandler;
	public commercialActivityHandlerCurrentMonth2?: ICommercialActivityHandler;
	public editFiltersObjectHandlerCurrentMonthList: IHierarchyFiltersMaterials[] = [];
	public editFiltersObjectHandlerCurrentMonthList2: IHierarchyFiltersMaterials[] = [];
	public commercialActivityHandlerCurrentMonthDetails: any[] = []; // faltan 2 
	public commercialActivityHandlerCurrentMonthDetails2: any[] = [];
	public commercialActivityCurrentMonthDetailsAux2: any[] = []; //Faltan 2 por si validaciones
	
	//Observable
	public isCurrentMonth = false;

	public typeCoreMaster : any = TypeCoreMaster;

	public isCalculate : boolean = false;
	
	constructor(
		domainService: DomainService,
		spinner: NgxSpinnerService,
		fb: FormBuilder,
		managePromotionActService: ManagePromotionalActivitiesService,
		messageService: MessageService,
		coreService: CoreMasterService,
		promotionalActivityService: PromotionalActivitiesCreateService,
		promotionalActivityHandlerService: HandlingProductFilterService,
		router: Router,
		changeDetector: ChangeDetectorRef,
		parameterService: ManageParametersService,
		translate: TranslateService,
    	i18nService: I18nService,
		private switchService : SwitchService
	) { 
		this.domainService = domainService;
		this.spinner = spinner;
		this.fb = fb;
		this.managePromotionActService = managePromotionActService;
		this.messageService = messageService;
		this.coreService = coreService;
		this.promotionalActivityService = promotionalActivityService;
		this.promotionalActivityHandlerService = promotionalActivityHandlerService;
		this.router = router;
		this.changeDetector = changeDetector;
		this.parameterService = parameterService;
		this.translate = translate;
    	this.i18nService = i18nService;
		this.switchService.data$.subscribe((data) => {
			this.isCurrentMonth = data;
		  });
	}

	ngOnInit(): void {
		this.tomorrow.setDate(this.tomorrow.getDate() + 1);
		this.commercialActivityEnum = this.getCommercialActivityEnum();
		this.commercialActivityStateEnum = this.getCommercialActivityStatusEnum();
		this.showFieldsCreatePromotion = new FieldsCommercialActivityFooterModel(true, true, true, true, true, true, true, true);
		this.isProcess = this.managePromotionActService.getIsCreate();
		this.isInitialError = true;
		this.initForm();
		this.forkJoinInitialData(true);
		this.getTypeFiltersCreation();
	}

	public getCommercialActivityEnum(): typeof ComercialActivityEnum {
		return ComercialActivityEnum;
	}

	public getCommercialActivityStatusEnum(): typeof CommercialActivityStatus {
		return CommercialActivityStatus;
	}

	/**
	* @description this method execute async requests.
	*
	* @author Diego Mauricio Cortés
	* @sprint 8
	*/
	forkJoinInitialData(isFirst: boolean = false): void {
		if(this.language === this.translate.currentLang && !isFirst){
			return;
		  }else{
			this.translate.use(this.i18nService.language);
			this.language = this.i18nService.language;
		  }
		this.spinner.show();

		const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);
		const promotionalTypeResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.TypePromotionalActivity);
		const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
		const sectorResponse = this.coreService.getAll(TypeCoreMaster.Sector);
		const parametersResponse = this.parameterService.getAll();

		forkJoin([
			processResponse,
			promotionalTypeResponse,
			channelResponse,
			sectorResponse,
			parametersResponse,
		])
			.subscribe(
				(result) => {

					const objProcessResponse: IResponseService<IDomain[]> = result[0];
					const objPromotionalTypeResponseResponse: IResponseService<IDomain[]> = result[1];
					const objChannelResponse: IResponseService<ICoreMaster[]> = result[2];
					const objSectorResponse: IResponseService<ICoreMaster[]> = result[3];
					const objParametersResponse: IResponseService<any[]> = result[4];

					this.objectPromotionTypeList = objPromotionalTypeResponseResponse.data.map((item) => {
						return {
							label: this.translate.instant('Domain.Domain-'+item.id),
							value: item.id
						};
					});

					this.objectChannelList = objChannelResponse.data.map((item) => {
						return {
							label: item.description,
							value: item.code.trim()
						};
					});

					this.objectProcessList = objProcessResponse.data.map((item) => {
						return {
							label: item.description,
							value: item.id
						};
					});

					this.objectSectorList = objSectorResponse.data.map((item) => {
						return {
							label: item.description,
							value: item.code.trim(),
							crossActive: item.crossActive
						};
					});

					const param = objParametersResponse.data.filter((prm) => prm.id === ManagementParameterEnum.sectorCommercialActivity);
					if (param) {
						this.sectorCommercialActivityParam = param[0].status;
						if (this.sectorCommercialActivityParam) {
							this.sector.setValidators([Validators.required]);
						}
					}

					this.objectTypePromotionActivity = [...objPromotionalTypeResponseResponse.data];
					this.objectTypeCommercialActivity = [...objProcessResponse.data];

					if (this.isProcess === this.commercialActivityEnum.update || this.isProcess === this.commercialActivityEnum.view) {
						this.initEditPromotionActivity();
					} else {
						this.initCreatePromotionActivity();
					}

					if (this.objectSectorList && this.objectChannelList)
					{
						const userFilters = this.managePromotionActService.setUserFilters(this.objectChannelList, this.objectSectorList);
						this.objectChannelList = userFilters.channels;
						this.objectSectorList = userFilters.sectors;
					}
					this.spinner.hide();

				}, () => {
					this.spinner.hide();
					SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
				}
			);

	}


	/**
	 * @description This method is to init configuration to create a
	 * new promotional activity
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private async initCreatePromotionActivity(): Promise<void> {
		const descriptionIn = await this.managePromotionActService.getDescription();

		if (descriptionIn !== undefined && descriptionIn !== null && descriptionIn !== '') {
			this.description.setValue(this.managePromotionActService.getDescription());
			this.typeCommercialActivityId = this.managePromotionActService.getTypeCommercialActivity();
			const typeCommercialActivityId = this.typeCommercialActivityId;
			this.typeCommercialActivity.setValue(this.findTypeCommercialActivityName(this.managePromotionActService.getTypeCommercialActivity()));

			if (typeCommercialActivityId)
			{
				await this.documentSupport.setRequiredDocumentInformation(typeCommercialActivityId);
				this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
			}
		} else {
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'La descripción es requerida. Por favor intentelo de nuevo.'
				: 'Description is required. Please try again.');
			this.router.navigate(['auth/manage-promotional-activities']);
		}
	}

	/**
	 * @description This method is to init configuration to edit
	 * promotional activity
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private initEditPromotionActivity(): void {
		this.productGroupFilterList = [];
		this.productGroupFilterListPromotion = [];
		this.channel.setValue([]);
		this.commercialActivityId = this.managePromotionActService.getCommercialActivityId();
		if (this.commercialActivityId) {
			this.getPromotionalActivityToEdit();
		} else {
			this.router.navigate(['auth/manage-promotional-activities']);
		}
	}

	/**
	 *
	 * @param id
	 */
	private findTypeCommercialActivityName(id: number): string {
		const typeCommercialActivity = this.objectTypeCommercialActivity.find((type) => type.id === id);
		return typeCommercialActivity.description;
	}

	/**
	 * @description This method is to verify if needed
	 * multiFilter or not
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	onSelectTypePromotion(): void {
		this.isMultiFilter = this.promotionType.value !== 43;
	}

	/**
	 * @description This method for get type filters creation.
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 8
	 */
	getTypeFiltersCreation(): void {
		this.domainService
			.getAllByDomainType(Microservice.Security, DomainTypeSecurity.TypeFilterCreation)
			.subscribe(
				(response) => {
					this.typeFiltersObjectList = response.data.map((item) => {
						return {
							label: item.description,
							value: item.code,
						};
					});
				},
				(error) => {
					this.spinner.hide();
					SweetAlertMessageHelpers.exception(error);
				},
				() => {
					this.spinner.hide();
				}
			);
	}

	/**
	 * @description This method verify if the tab select is
	 * purchase base or promotion
	 *
	 * @param event
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	productsPurchasePromotionChange(event: any): void {
		this.isPurchaseBase = event.index === 0;
	}


	/**
	 * @description This method for event for filter.
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 8
	 */
	// let dataZeroBool : Boolean = false; 
	eventClickFilters($event: any): void {
		if (this.isPurchaseBase && this.productGroupFilterList.length > 0) {
			let dataZero = 0;
			let dataNoZero = 0;
			({ dataNoZero, dataZero } = this.dataNoZeroValidation(dataNoZero, dataZero));
			const language: string = sessionStorage.getItem('language');
			
			if (dataZero === 1) {
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
					language === 'es-ES' ? 'Existen productos seleccionados en base compra sin parametrizar unidades compra, verifique y eliminelos si desea aplicar otro filtro.'
					: 'There are products selected on a purchase basis without parameterizing purchase units, check and delete them if you want to apply another filter.');
				return;
			} else {
				if (dataNoZero === 2) {
					SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
						language === 'es-ES' ? 'Ya se han parametrizado productos en base compra, verifique y eliminelos si desea aplicar otro filtro.'
						: 'Products have already been parameterized on a purchase basis, check and delete them if you want to apply another filter.');
					return;
				}
			}
		}

		if (!this.isPurchaseBase && this.productGroupFilterListPromotion.length > 0) {
			let dataZero = 0;
			let dataNoZero = 0;
			({ dataNoZero, dataZero } = this.eventClickFiltersI(dataNoZero, dataZero));
			const language: string = sessionStorage.getItem('language');
			
			if (dataZero === 1) {
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
					language === 'es-ES' ? 'Existen productos seleccionados en promoción sin parametrizar unidades compra, verifique y eliminelos si desea aplicar otro filtro.'
					: 'There are selected products in promotion without parameterizing purchase units, check and delete them if you want to apply another filter.');
				return;
			} 
			else {
				if (dataNoZero === 2) {
					SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
						language === 'es-ES' ? 'Ya se han parametrizado productos en promoción, verifique y eliminelos si desea aplicar otro filtro.'
						: 'Promotional products have already been parameterized, check and delete them if you want to apply another filter.');
					return;
				}
			}
		}

		this.eventClickFiltersII($event);
	}

	private eventClickFiltersI(dataNoZero: number, dataZero: number) {
		this.productGroupFilterListPromotion.forEach((elm) => {
			let sum = 0;
			elm.purchaseBasePromotionDetails.forEach((itm) => {
				if (itm.purchaseUnit === 0) {
					sum += 1;
				}
			});
			if (sum === 0) {
				dataNoZero = 2;
			}
			if (sum > 0 && sum !== elm.purchaseBasePromotionDetails.length) {
				dataZero = 1;
			}
		});
		return { dataNoZero, dataZero };
	}

	/**
	 * @description This method is to send to get materials in advanced filter
	 * @author Diego Mauricio Cortés
	 * @sprint 15
	 */
	private eventClickFiltersII($event: any) {
		if ($event.advancedFiltersObjectList.length > 0) {
			this.spinner.show();
			const x = $event.advancedFiltersObjectList.map((fil: any) => {
				return {
					...fil,
					'sectors': this.sector.value
				};
			});
			this.advancedFiltersObjectList = $event.advancedFiltersObjectList;
			const newFilterModel = new FilterDataModel(
				JSON.stringify(x),
				environment.conditionTable,
				environment.conditionClassCode,
				false
			);
			this.getMaterialsByFilters(newFilterModel);
		} else {
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ?  ManagePromotionalActivitiesMessage.NOT_FILTERS : ManagePromotionalActivitiesMessage.NOT_FILTERS_TRANSLATE);
		}
	}

	/**
	 * @description This method for get the filters.
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 8
	 * @param filters
	 */
	private getMaterialsByFilters(filters: FilterDataModel): void {
		this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
			(response) => {
				if (response.data.length > 0) {
					const language: string = sessionStorage.getItem('language');
					this.showToast(SweetAlert.iconSuccess,
					  language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
					  language === 'es-ES' 
					  	? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`: `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`);
					const listProducts: IProductList[] = response.data.map((item: any) => {
						return {
							product: item.product,
							hierarchy: item.hierarchy,
							purchaseBaseId: 0,
							materialCode: item.materialCode,
							measurementUnitCode: MeasurementUnit.PIECE,
							purchaseUnit: 0,
							porcent: 0,
							discountValue: 0,
							id: this.isProcess === this.commercialActivityEnum.create ? item.id : 0,
              zofeCode: item.zofeCode,
              zofeDescription: item.zofeDescription
						};
					});
					if (this.isPurchaseBase) {
						this.productGroupFilterList = this.groupProductsToShow(listProducts);
						this.selectProductList = [];
						this.selectProductHeadGroupList = [];
					} else {
						this.productGroupFilterListPromotion = this.groupProductsToShow(listProducts);
						this.selectProductListPromotion = [];
						this.selectProductHeadGroupListPromotion = [];
					}

				} else {
					const language: string = sessionStorage.getItem('language');
					SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
						language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE);
				}
				this.spinner.hide();
			}, (error) => {
				this.spinner.hide();
				console.error(error);
			}
		);
	}

	/**
	 * @description This method is to group by hierarchy the purchase base
	 * and promotional products list
	 *
	 * @param listProducts
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private groupProductsToShow(listProducts: IProductList[]): IProductGroupList[] {
		const groups: IProductGroupList[] = [];
		if (listProducts.length > 0) {
			listProducts.forEach((product: IProductList) => {
				const groupFound = groups.find((fGroup) => fGroup.groupName === product.hierarchy);
				if (groupFound) {
					if (this.isPurchaseBase) {
						groupFound.purchaseBaseDetails.push(product);
					} else {
						groupFound.purchaseBasePromotionDetails.push(product);
					}
				} else {
					const filterFound = this.advancedFiltersObjectList.find((fFilter) => fFilter.hierarchyFilter === product.hierarchy);
					let newGroup: ProductGroupListModel = null;
					if (this.isPurchaseBase) {
						newGroup = new ProductGroupListModel(
							product.hierarchy, // groupName
							0, // commercialActivityId
							0, // purchaseUnit
							MeasurementUnit.PIECE, // measurementUnitCode
							true, // isGroup,
							JSON.stringify(filterFound), // groupByJson
							0, // id
							[product], // purchaseBaseDetails
							[] // purchaseBasePromotionDetails
						);
					} else {
						newGroup = new ProductGroupListModel(
							product.hierarchy, // groupName
							0, // commercialActivityId
							0, // purchaseUnit
							MeasurementUnit.PIECE, // measurementUnitCode
							true, // isGroup,
							JSON.stringify(filterFound), // groupByJson
							0, // id
							[], // purchaseBaseDetails
							[product] // purchaseBasePromotionDetails
						);
					}
					groups.push(newGroup);
				}
			});
		}
		return groups;
	}

	/**
	 * @description This method is to save promotional activity
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	async eventClickSaveBtn($event: ICommercialActivityFooter){

		this.documentValid = this.validateDocuments();
		let validateManejantes = true;

		if(this.showManejantes){ 
			this.prepareActivityHandler();
			this.isCurrentMonth ? this.prepareActivityHandlerCurrentMonth() : this.commercialActivityHandlerCurrentMonth2 = null;
			validateManejantes = this.isProcess === this.commercialActivityEnum.view ? true : await this.validateHandler();
		}else{
			this.commercialActivityHandlerPeriod2 = [];
			
			this.commercialActivityHandler2 = {
				commercialActivityId : 0,
				id: 0,
				groupByJson : '',
				isGroup: false,
				commercialActivityHandlerDetails : [],
				isClientWithoutSales : false,
				isCurrentMonth : false
			};
		} 
	
		if (this. validateForm() && this.validationsForPurchaseBaseProductList() && this.validationsForPromotionalProductList() 
				&& this.formPromotionalActivity.valid && validateManejantes && this.documentValid) {
			this.promotionalActivityModel = this.preparePromotionActivityToCreate($event);
			this.nextAssignClients = true;
			this.prepareFiltersToEditProductsPromotionalActivity(this.promotionalActivityModel);
			this.prepareFiltersToEditProductsPromotionPromotionalActivity(this.promotionalActivityModel);
		}
	}

	validateForm() {
		if (this.sectorCommercialActivityParam) {
			if (this.sector.value === '' && this.channel.value === '') {
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.warning(
					language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
					language === 'es-ES' ? `${CommercialActivityEventsMessage.NO_CHANNEL_SECTOR}`
					: `${CommercialActivityEventsMessage.NO_CHANNEL_SECTOR_TRANSLATE}`
				);
				this.formPromotionalActivity.markAllAsTouched();
				return false;
			}
		}
		return true;
	}

	validationsPurchaseUnit(object :IProductGroupList[], indicador: number): boolean{
		let acum = 0;
		let result = 0;
		//1 Base Compra
		if(indicador === 1){
			object.forEach((elm) => {
				elm.purchaseBaseDetails.forEach((x: any) => {
					acum = acum + x.purchaseUnit;
				});
				if(elm.purchaseUnit === acum){
					result = 1;
				}
			});
			//2 Productos Promocion
		}else if(indicador === 2){
			object.forEach((elm) => {
				elm.purchaseBasePromotionDetails.forEach((x: any) => {
					acum = acum + x.purchaseUnit;
				});
				if(elm.purchaseUnit === acum){
					result = 1;
				}
			});
		}

		if(result === 0){
			return false;
		}else{
			return true;
		}
	}

	validationsUnitZero(object :IProductGroupList[], indicador: number): boolean{
		let cont = 0;
		if(indicador === 1){
			object.forEach((elm) => {
				elm.purchaseBaseDetails.forEach((x) => {
					if(x.purchaseUnit === 0){
						 cont += 1;
					}
				});
			});
			if(cont > 0){
				return true;
			}else{
				return false;
			}
		}
		if(indicador === 2){
			object.forEach((elm) => {
				elm.purchaseBasePromotionDetails.forEach((x) => {
					if(x.purchaseUnit === 0){
						 cont += 1;
					}
				});
			});
			if(cont > 0){
				return true;
			}else{
				return false;
			}
		}
	}

	/**
	 * @description This method is to save promotional activity whit customers data
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 11
	 */
	eventClickSendDataClients($event: any): void {
		if ($event.jsonFilterClient || $event.listClient) {
			this.spinner.show();
			this.promotionalActivityModel.filterClientJson = $event.jsonFilterClient;
			this.promotionalActivityModel.commercialActivityCustomers = this.prepareCommercialActivityCustomerCreate($event.listClient);
			this.promotionalActivityModel.isCalculate = false;
			this.promotionalActivityModel.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate();
			this.promotionalActivityModel.totalClientsFilter = $event.totalClients;
			this.promotionalActivityModel.uidCalculateProcess = '';
			this.promotionalActivityModel.commercialActivityObservation= this.documentInfo;

			this.prepareCommercialActivitiesDocumentsToCreate();
			this.promotionalActivityModel.commercialActivityObservation = this.documentInfo;
			this.promotionalActivityService.postPromotionalActivity(this.promotionalActivityModel).subscribe(
				(response) => {
					if (response.status) {
						SweetAlertMessageHelpers.showMessage(this.isProcess === this.commercialActivityEnum.update ? TypeMessage.Update : TypeMessage.Success);
						this.router.navigate(['auth/manage-promotional-activities']);
					} else {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
					}
					this.spinner.hide();
				}, (error: HttpErrorResponse) => {
					this.spinner.hide();
					SweetAlertMessageHelpers.exception(error);
				});
		} else {
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'El filtro de clientes es obligatorio'
				: 'The customer filter is mandatory');
		}
	}

	/**
	 * @description This method is to prepare commercial activity sectors to create
	 * @sprint 15
	 * @author Diego Mauricio Cortes
	 * @returns ICommercialActivitySector object list
	 */
	private prepareCommercialActivitiesSectorsToCreate(): ICommercialActivitySector[] {
		let rsp: ICommercialActivitySector[] = [];
		if (this.sectorCommercialActivityParam && this.sector.value) {
			rsp = this.sector.value.map((sct: string) => {
				return {
					id: 0,
					commercialActivityId: this.commercialActivityId || 0,
					sectorCode: sct
				};
			});
		}
		return rsp;
	}

	/**
	 * @description This method is to validate the information for
	 * Purchase Base Product List
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	validationsForPurchaseBaseProductList(): boolean {
		const language: string = sessionStorage.getItem('language');
		if (!this.channel.value) {
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'Debe configurar canales.' : 'You must configure channels.');
			return false;
		}

		if (!this.promotionType.value) {
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'Debe configurar tipo de promoción.' : 'You must configure type of promotion.');
			return false;
		}

		if (this.productGroupFilterList.length === 0) {
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'Debe configurar por lo menos un producto base compra para guardar la promocion.'
				: 'You must set up at least one base product purchase to save the promotion.');
			return false;
		}
		const purchaseGroupValidate = this.validateGroupsPurchaseUnits(true);
		if (purchaseGroupValidate) {
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? `Error en la cantidad de unidades de productos en el grupo: ${purchaseGroupValidate.groupName}, en productos base compra`
				: `Error in the quantity of product units in the group: ${purchaseGroupValidate.groupName}, in purchase-based products`);
			return false;
		}
		const GroupFilterList: boolean = this.validationsPurchaseUnit(this.productGroupFilterList, 1);
		if(!GroupFilterList){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
				language === 'es-ES' ? SweetAlert.mesageProductSame : SweetAlert.mesageProductSameTranslate);
			return false;
		}
		const GroupFilterListZero: boolean = this.validationsUnitZero(this.productGroupFilterList, 1);
		if(GroupFilterListZero){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
				language === 'es-ES' ? SweetAlert.mesageProductBasisPurchase : SweetAlert.mesageProductBasisPurchaseTranslate);
			return false;
		}

		return true;
	}

	/**
	 * @description This method is to validate the information for
	 * Promotional Product List
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	validationsForPromotionalProductList(): boolean {
		const language: string = sessionStorage.getItem('language');
		if (this.productGroupFilterListPromotion.length === 0) {
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'Debe configurar por lo menos un producto promocion para guardar la promocion.'
				: 'You must set up at least one promotional product to save the promotion.');
			return false;
		}

		const purchaseGroupValidatePromotion = this.validateGroupsPurchaseUnits(false);
		if (purchaseGroupValidatePromotion) {
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? `Error en la cantidad de unidades de productos en el grupo: ${purchaseGroupValidatePromotion.groupName}, en productos promocion`
				: `Error in the quantity of product units in the group: ${purchaseGroupValidatePromotion.groupName}, in promotional products`);
			return false;
		}
		const productGroupFilterListPromotion: boolean = this.validationsPurchaseUnit(this.productGroupFilterListPromotion, 2);
		if(!productGroupFilterListPromotion){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ?  SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
				language === 'es-ES' ?  SweetAlert.mesagemesageProductSamePromotion : SweetAlert.mesagemesageProductSamePromotionTranslate);
			return false;
		}
		const GroupFilterListZero: boolean = this.validationsUnitZero(this.productGroupFilterListPromotion, 2);
		if(GroupFilterListZero){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
				language === 'es-ES' ? SweetAlert.messageNoProductPromoZero: SweetAlert.messageNoProductPromoZeroTranslate );
			return false;
		}
		return true;
	}

	/**
	 * @description This method is to validate correctly
	 * configuration for purchase units in groups and products
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private validateGroupsPurchaseUnits(isPurchaseBase: boolean): IProductGroupList {
		if (isPurchaseBase) {
			let groupRet: IProductGroupList = null;
			this.productGroupFilterList.forEach((group, indexGrp) => {
				if (group.purchaseUnit === 0) {
					groupRet = group;
				}
				let unitsProducts = 0;
				group.purchaseBaseDetails.forEach((product, indexPrd) => {
					unitsProducts += product.purchaseUnit;
				});
				if (unitsProducts > group.purchaseUnit) {
					groupRet = group;
				}
			});
			return groupRet;
		} else {
			let groupRet: IProductGroupList = null;
			this.productGroupFilterListPromotion.forEach((group, indexGrp) => {
				if (group.purchaseUnit === 0) {
					groupRet = group;
				}
				let unitsProducts = 0;
				group.purchaseBasePromotionDetails.forEach((product, indexPrd) => {
					unitsProducts += product.purchaseUnit;
				});
				if (unitsProducts > group.purchaseUnit) {
					groupRet = group;
				}
			});
			return groupRet;
		}
	}

	/**
	 * @description This method is to prepare data for create promotional activity
	 *
	 * @param promotionData
	 * @author Diego Mauricio Cortés
	 * @sprint 8
	 */
	private preparePromotionActivityToCreate(promotionData: ICommercialActivityFooter): PromotionalActivityModel {
		const promotionalActivityModel: PromotionalActivityModel = new PromotionalActivityModel(
			this.typeCommercialActivityId,
			this.promotionType.value,
			promotionData.statusDomainId,
			this.description.value,
			promotionData.startTime,
			promotionData.endTime,
			promotionData.orderLimit,
			promotionData.periodLimit,
			promotionData.numberDays,
			'',
			0,
			this.productGroupFilterList,
			this.productGroupFilterListPromotion,
			promotionData.dataRanges,
			this.prepareChannelsToSave(),
			[],
			null,
			null,
			null,
			null,
			null,
			this.showManejantes,
			this.commercialActivityHandler2,
			this.commercialActivityHandlerCurrentMonth2,
			this.commercialActivityHandlerPeriod2
		);
		return promotionalActivityModel;
	}

	private prepareActivityHandler() {
		let FiltersObjectHandlerListAux = this.activityHandler.getGroupByJsonHandler();
		let HandlerDetailsAux = this.activityHandler.getCommercialActivityHandlerDetails();
		let handlerPeriodAux = this.activityHandler.getCommercialActivityHandlerPeriodModel();
		let isGroup = this.activityHandler.getIsGroup();
		let isSwitchCustomer = this.activityHandler.getIsSwitchCustomer();

		this.editFiltersObjectHandlerList2 = FiltersObjectHandlerListAux ? JSON.parse(FiltersObjectHandlerListAux) : [];
		this.commercialActivityHandlerisGroup2 = isGroup;
		this.commercialActivityHandlerisClientWithoutSales2 = isSwitchCustomer;

		this.commercialActivityHandlerDetails2 = HandlerDetailsAux;
		

		this.commercialActivityHandlerDetailsAux2 = HandlerDetailsAux.map(item => {
			return {
				commercialActivityHandlerId : item.commercialActivityHandlerId,
				materialCode : item.materialCode,
				rankStart : item.rankStart,
				rankEnd : item.rankEnd,
				id: item.id,
			}
		});
		

		this.commercialActivityHandlerPeriod2 = handlerPeriodAux;

		this.commercialActivityHandler2 = {
			commercialActivityId: this.commercialActivityId != undefined ? this.commercialActivityId : 0,
			id: this.isProcess == 1 || !this.commercialActivityHandler ? 0 : this.commercialActivityHandler.id,
			isGroup: this.commercialActivityHandlerisGroup2,
			groupByJson : FiltersObjectHandlerListAux,
			commercialActivityHandlerDetails: this.commercialActivityHandlerDetailsAux2,
			isClientWithoutSales : this.commercialActivityHandlerisClientWithoutSales2,
			isCurrentMonth : this.isCurrentMonth
		};

	}

	private prepareActivityHandlerCurrentMonth() {
		let date = new Date();
        let currentDate = date.getFullYear().toString() + ((date.getMonth() + 1).toString().padStart(2,'0'));
		let FiltersObjectHandlerListAux = this.ActivityHandlerCurrentMonth.getGroupByJsonHandler();
		
		this.editFiltersObjectHandlerCurrentMonthList2 =  FiltersObjectHandlerListAux ? JSON.parse(FiltersObjectHandlerListAux) : [];
		this.commercialActivityHandlerCurrentMonthDetails2 = this.ActivityHandlerCurrentMonth.getCommercialActivityHandlerDetails();

		
		this.commercialActivityCurrentMonthDetailsAux2 = this.commercialActivityHandlerCurrentMonthDetails2.map(item => {
			return {
				id: item.id,
				commercialActivityHandlerCurrentMonthId : item.commercialActivityHandlerCurrentMonthId,
				materialCode : item.materialCode,
				materialDescription : item.materialDescription,
				zofeCode : item.zofeCode,
				zofeDescription : item.zofeDescription
			}
		});
		

		this.commercialActivityHandlerCurrentMonth2 = {
			commercialActivityId: this.commercialActivityId != undefined ? this.commercialActivityId : 0,
			id: this.isProcess == 1 || !this.commercialActivityHandlerCurrentMonth ? 0 : this.commercialActivityHandlerCurrentMonth.id,
			groupByJson : FiltersObjectHandlerListAux,
			commercialActivityHandlerCurrentMonthDetails: this.commercialActivityCurrentMonthDetailsAux2,
			isClientWithoutSales : undefined,
			isCurrentMonth : undefined,
			isGroup : undefined,
			period: currentDate,
		};

	}

	async validateHandler() : Promise<boolean>{
		const language: string = sessionStorage.getItem('language');
		if(this.commercialActivityHandlerDetailsAux2.length > 0 &&  (this.commercialActivityHandlerPeriod2 && this.commercialActivityHandlerPeriod2.length) > 0){
			
			let findRangeEmpty = this.commercialActivityHandlerDetailsAux2.find((item : any) => item.rankStart === '' || item.rankStart === undefined);

			if(findRangeEmpty != undefined){
				const language: string = sessionStorage.getItem('language');
					this.showToast(SweetAlert.iconError,
					language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
					language === 'es-ES' ? SweetAlert.messageValidateRank : SweetAlert.messageValidateRankTranslate);
				return false;
			}

			if(this.isCurrentMonth && this.commercialActivityHandlerCurrentMonthDetails2.length == 0){
				const language: string = sessionStorage.getItem('language');
					this.showToast(SweetAlert.iconError,
					language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
					language === 'es-ES' ? SweetAlert.messageValidateConfigurationHandlerCurrenMonth : SweetAlert.messageValidateConfigurationHandlerCurrenMonthTranslate);
				return false;
			}

			const activityHandlerModel : ActivityHandlerModel = new ActivityHandlerModel (
				this.commercialActivityHandler2,
				this.isCurrentMonth ? this.commercialActivityHandlerCurrentMonth2 : null,
				this.commercialActivityHandlerPeriod2
			)
			try {
				this.spinner.show();
				const response = await this.promotionalActivityHandlerService.postActivityHandler(activityHandlerModel).toPromise();
					if(response){
						if (response.status) {
							this.spinner.hide();
							return true;
						} else {
							// Mensaje de error de no hay clientes
							this.spinner.hide();
							SweetAlertMessageHelpers.error(
								language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
								language === 'es-ES' ? SweetAlert.messageEmptyConfiguration : SweetAlert.messageEmptyConfigurationTranslate
							);
							return false;
						}
					}
			} catch (error) {
				this.spinner.hide();
				SweetAlertMessageHelpers.exception(error);
			}
			
		}else{
			// Mensaje de Error de validacion 
			SweetAlertMessageHelpers.error(
				language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
				language === 'es-ES' ? SweetAlert.messageValidateConfiguration : SweetAlert.messageValidateConfigurationTranslate
			);
			return false;
		}
	}
	/**
	 * @description This method is to prepare customers data to create promotional activity
	 *
	 * @param listClient
	 * @author Diego Mauricio Cortés
	 * @sprint 11
	 */
   private prepareCommercialActivityCustomerCreate(listClient: any[]): ICustomer[] {
		let customers: ICustomer[] = [];

		if (listClient !== null) {
			customers = listClient.map((client) => {

				return {
					commercialActivityId: client.commercialActivityId === undefined ? 0 : client.commercialActivityId,
					clientCode: client.clientCode === undefined ? client : client.clientCode,
					id: 0,
				};
			});
		}
		return customers;
	}

	/**
	 * @description This method is to prepare selected channels to save promotional activity
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private prepareChannelsToSave(): IChannel[] {
		const channels = this.channel.value.map((chl: number) => {
			return {
				commercialActivityId: 0,
				channelCode: chl
			};
		});
		return channels;
	}

	private prepareSectorsToSave(): ICommercialActivitySector[] { 
		if (this.sector.value) {
			const sectors: ICommercialActivitySector[] = this.sector.value.map((sct: string) => {
				return {
					id: 0,
					commercialActivityId: this.commercialActivityId || 0,
					sectorCode: sct
				};
			});
			return sectors;
		}
	}

	/**
	 * @description This method is to prepare selected channels to edit promotional activity
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private prepareChannelsToEdit(commercialActivityId: number): IChannel[] {
		const channels = this.channel.value.map((chl: number) => {
			return {
				commercialActivityId: commercialActivityId,
				channelCode: chl
			};
		});
		return channels;
	}

	/**
	 * @description This method shows the toast control.
	 *
	 * @param severity
	 * @param message
	 * @param detail
	 * @author Diego Mauricio Cortés
	 * @sprint 8
	 */
	showToast(severity: string, message: string, detail: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: severity,
			summary: message,
			detail: detail,
			life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
		});
	}

	/**
	 * @description This method is to delete the selected
	 * groups or products
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	async deleteSelectedPurchaseBase() {
		Util.confirmDelete().then(async (result: SweetAlertResult) => {
			if (result.value) {
				if (this.isPurchaseBase) {
					await this.deleteSelectedPurchaseBaseI();

					if (this.selectProductList.length > 0) {
						const auxProducts = [...this.selectProductList];
						await this.deleteSelectedPurchaseBaseIII(auxProducts);
						this.deleteSelectedProductBasePurchase(auxProducts);
					}
					 this.isDeleteProducts = this.productGroupFilterList.length === 0;

				} else {
					await this.deleteSelectedPurchaseBasePromoI();

					if (this.selectProductHeadGroupListPromotion.length > 0) {
						const auxGroupList = [...this.selectProductHeadGroupListPromotion];
						if (this.isProcess === this.commercialActivityEnum.update) {
							const ids = auxGroupList.map((grp) => {
								return grp.id;
							});
							const responseDelete = await this.promotionalActivityService.deleteProductPromotionGroupsList(ids.join(',')).toPromise();
						}
						auxGroupList.forEach((element, index) => {
							const indexFound = this.selectProductHeadGroupListPromotion.findIndex((prd) => prd.groupName === element.groupName);
							if (indexFound > -1) {
								this.productGroupFilterListPromotion.splice(indexFound, 1);
								this.selectProductHeadGroupListPromotion.splice(index, 1);
							}
						});
					}

					if (this.selectProductListPromotion.length > 0) {
						const auxProducts = [...this.selectProductListPromotion];
						await this.deleteSelectedPurchaseBaseII(auxProducts);
						this.deletePromotionSelectedProduct(auxProducts);
					}

					this.isDeleteProductsPromotion = this.productGroupFilterListPromotion.length === 0;
					this.productGroupFilterListPromotion = this.productGroupFilterListPromotion.length > 0 ? this.productGroupFilterListPromotion: [];

				}

				this.selectProductList = [];
				this.selectProductListPromotion = [];
				this.selectProductHeadGroupList = [];
				this.selectProductHeadGroupListPromotion = [];
				this.changeDetector.detectChanges();
				this.productGroupFilterList = this.productGroupFilterList.length > 0 ? this.productGroupFilterList: [];

			}
		});
	}


	selectProductPromotionAll(event: any, object: any){
		if(event.checked === true){
			this.productGroupFilterListPromotion.forEach((group) => {
				if(object.groupName === group.groupName){
					group.purchaseBasePromotionDetails.forEach((options: any) => {
					options.check = event.checked;
					});
				}
				});
		}else if(event.checked === false){
			this.productGroupFilterListPromotion.forEach((group) => {
				if(object.groupName === group.groupName){
				  group.purchaseBasePromotionDetails.forEach((options: any) => {
					options.check = event.checked;
				  });
				}
			  });
		}
		this.getSelectListPromotion(this.productGroupFilterListPromotion);
	}

	public getSelectListPromotion(object: IProductGroupList[]) {
		object.forEach((group) => {
			group.purchaseBasePromotionDetails.forEach((options: IProductList) => {
				if(options.check === true){
					const productAdd = this.selectProductListPromotion.find((x) => x.materialCode === options.materialCode);
					if(!productAdd){
						this.selectProductListPromotion.push(options);
						this.selectProductListPromotion = [...this.selectProductListPromotion];
					}
				}else if(options.check === false){
					const productPop = this.selectProductListPromotion.find((x) => x.materialCode === options.materialCode);
					if(productPop){
						const index = this.selectProductListPromotion.findIndex((elm) => elm.materialCode === productPop.materialCode);
						if (index !== -1) {
							this.selectProductListPromotion.splice(index, 1);
						}
					}
				}
			  });
		});
	}


	selectProductAll(event: any, object: any){
		if(event.checked === true){
			this.productGroupFilterList.forEach((group) => {
				if(object.groupName === group.groupName){
					group.purchaseBaseDetails.forEach((options: any) => {
					options.check = event.checked;
					});
				}
				});
		}else if(event.checked === false){
			this.productGroupFilterList.forEach((group) => {
				if(object.groupName === group.groupName){
				  group.purchaseBaseDetails.forEach((options: any) => {
					options.check = event.checked;
				  });
				}
			  });
		}
		this.getSelectList(this.productGroupFilterList);
	}

	public getSelectList(object: IProductGroupList[]) {
		object.forEach((group) => {
			group.purchaseBaseDetails.forEach((options: IProductList) => {
				if(options.check === true){
					const productAdd = this.selectProductList.find((x) => x.materialCode === options.materialCode);
					if(!productAdd){
						this.selectProductList.push(options);
						this.selectProductList = [...this.selectProductList];
					}
				}else if(options.check === false){
					const productPop = this.selectProductList.find((x) => x.materialCode === options.materialCode);
					if(productPop){
						const index = this.selectProductList.findIndex((elm) => elm.materialCode === productPop.materialCode);
						if (index !== -1) {
							this.selectProductList.splice(index, 1);
						}
					}
				}
			  });
		});
	}

	public selectProductPromotionRow(event: any, object: IProductList) {
		if (this.productGroupFilterListPromotion) {
			this.productGroupFilterListPromotion.forEach((group) => {
			  group.purchaseBasePromotionDetails.forEach((option: any) => {
				if (object.materialCode === option.materialCode) {
				  option.check = event;
				  this.getSelectListPromotionRow(option);
				}
			  });
			});
		} else {
		  object.check = event;
		}

	  }

	public selectProductRow(event: any, object: IProductList) {
		if (this.productGroupFilterList) {
			this.productGroupFilterList.forEach((group) => {
			  group.purchaseBaseDetails.forEach((option: any) => {
				if (object.materialCode === option.materialCode) {
				  option.check = event;
				  this.getSelectListRow(option);
				}
			  });
			});
		} else {
		  object.check = event;
		}

	  }

	  public getSelectListRow(object: IProductList) {
		if (object.check === true) {
			this.selectProductList.push(object);
		} else {
			const index = this.selectProductList.findIndex((elm) => elm.materialCode === object.materialCode);
			if (index !== -1) {
				this.selectProductList.splice(index, 1);
			}
		}
	}

	public getSelectListPromotionRow(object: IProductList) {
		if (object.check === true) {
			this.selectProductListPromotion.push(object);
		} else {
			const index = this.selectProductListPromotion.findIndex((elm) => elm.materialCode === object.materialCode);
			if (index !== -1) {
				this.selectProductListPromotion.splice(index, 1);
			}
		}
	}

	private async deleteSelectedPurchaseBaseIII(auxProducts: IProductList[]) {
		if (this.isProcess === this.commercialActivityEnum.update) {
			const ids = auxProducts.map((prd) => {
				return prd.id;
			});
			const responseDelete = await this.promotionalActivityService.deleteProductList(ids.join(',')).toPromise();
		}
	}

	private async deleteSelectedPurchaseBaseII(auxProducts: IProductList[]) {
		if (this.isProcess === this.commercialActivityEnum.update) {
			const ids = auxProducts.map((prd) => {
				return prd.id;
			});
			const responseDelete = await this.promotionalActivityService.deleteProductPromotionList(ids.join(',')).toPromise();
		}
	}

	private async deleteSelectedPurchaseBaseI() {
		if (this.selectProductHeadGroupList.length > 0) {
			const auxGroupList = [...this.selectProductHeadGroupList];
			if (this.isProcess === this.commercialActivityEnum.update) {
				const ids = auxGroupList.map((grp) => {
					return grp.id;
				});
				const responseDelete = await this.promotionalActivityService.deleteProductGroupsList(ids.join(',')).toPromise();
			}

			const countGroupList = auxGroupList.length;
			let countList = 0;

			auxGroupList.forEach((selected) => {
				const productSelected = this.productGroupFilterList.filter((origin) => origin.groupName !== selected.groupName);
				this.productGroupFilterList = productSelected;
				countList += 1;

				if(countGroupList === countList){
					this.selectProductHeadGroupList = [];
				}
			});

			/*auxGroupList.forEach((element, index) => {
				const indexFound = this.productGroupFilterList.findIndex(prd => prd.groupName === element.groupName);

				if (indexFound === 0) {
					this.productGroupFilterList = [];
					this.selectProductHeadGroupList = [];
				}
				if (indexFound >= 1) {
					this.productGroupFilterList.splice(indexFound, 1);
					this.selectProductHeadGroupList.splice(index, 1);
				}
			});*/
		}
	}
	private async deleteSelectedPurchaseBasePromoI() {
		if (this.selectProductHeadGroupListPromotion.length > 0) {
			const auxGroupList = [...this.selectProductHeadGroupListPromotion];
			if (this.isProcess === this.commercialActivityEnum.update) {
				const ids = auxGroupList.map((grp) => {
					return grp.id;
				});
				const responseDelete = await this.promotionalActivityService.deleteProductPromotionGroupsList(ids.join(',')).toPromise();
			}

			const countGroupListPromo = auxGroupList.length;
			let countList = 0;

			auxGroupList.forEach((selected) => {
				const productSelected = this.productGroupFilterListPromotion.filter((origin) => origin.groupName !== selected.groupName);
				this.productGroupFilterListPromotion = productSelected;
				countList += 1;
				if(countGroupListPromo === countList){
					this.selectProductHeadGroupListPromotion = [];
				}
			});

			// auxGroupList.forEach((element, index) => {
			// 	const indexFound = this.productGroupFilterListPromotion.findIndex(prd => prd.groupName === element.groupName);
			// 	if (indexFound > -1) {
			// 		this.productGroupFilterListPromotion.splice(indexFound, 1);
			// 		this.selectProductHeadGroupListPromotion.splice(index, 1);
			// 	}
			// });
		}
	}

	async deleteSelectOnly(object: IProductGroupList, opcion : number){
		Util.confirmDelete().then((result: SweetAlertResult) => {
			if (result.value) {
				this.spinner.show();
				if(opcion === 1){
					 this.promotionalActivityService.deleteProductGroupsList(object.id.toString()).toPromise();
					const index = this.productGroupFilterList.findIndex((elm) => elm.groupName === object.groupName);
					if(index !== -1){
						this.productGroupFilterList.splice(index, 1);
					}
				} else if (opcion === 2){
					 this.promotionalActivityService.deleteProductPromotionGroupsList(object.id.toString()).toPromise();
					const index = this.productGroupFilterListPromotion.findIndex((elm) => elm.groupName === object.groupName);
					if(index !== -1){
						this.productGroupFilterListPromotion.splice(index, 1);
					}
				}

			}
			this.spinner.hide();
		  });
	}

	/**
	 * @description This method is to get the Promotional activity information to edit
	 *
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	async getPromotionalActivityToEdit(): Promise<void> {
		this.spinner.show();
		this.promotionalActivityService.getPromotionalActivityById(this.commercialActivityId).subscribe(
			async (response) => {
				if (response.status) {
					this.commercialActStatVal = response.data.statusDomainId;
					let commercialActivityChannelsLocal: IChannel[] = response.data.commercialActivityChannels;
					let CommercialActivitySectorLocal: ICommercialActivitySector[] = response.data.commercialActivitySector;
					//this.observationEdit = response.data.commercialActivityObservation;

					if(this.promotionActivityEdit && this.promotionActivityEdit.commercialActivityChannels && this.promotionActivityEdit.commercialACtivitySector){
						commercialActivityChannelsLocal = this.promotionActivityEdit.commercialActivityChannels;
						CommercialActivitySectorLocal = this.promotionActivityEdit.commercialACtivitySector;
					}

					this.promotionActivityEdit = new PromotionalActivityModel(
						response.data.typeCommercialActivityDomainId,
						response.data.typePromotionalActivityDomainId,
						response.data.statusDomainId,
						response.data.description,
						response.data.startTime,
						response.data.endTime,
						response.data.orderLimit,
						response.data.periodLimit,
						response.data.numberDays,
						response.data.filterClientJson,
						response.data.id,
						response.data.purchaseBases,
						response.data.purchaseBasePromotions,
						this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges),
						commercialActivityChannelsLocal,
						response.data.commercialActivityCustomers,
						response.data.commercialActivityCustomerJson,
						response.data.isCalculate,
						CommercialActivitySectorLocal,
						response.data.totalClientsFilter,
						response.data.uidCalculateProcess,
						response.data.isHandler,
						response.data.commercialActivityHandler,
						response.data.commercialActivityHandlerCurrentMonth,
						response.data.commercialActivityHandlerPeriod,
						response.data.commercialActivityObservation
					);
					this.userCreation = new UserCreationModel(
						response.data.nameUser,
						response.data.user,
						response.data.emailUser,
						response.data.creationDate
					 );

					this.channelTooltip = response.data.commercialActivityChannels;

					this.promotionEdit = new CommercialActivityFooterModel(
						this.promotionActivityEdit.numberDays,
						this.promotionActivityEdit.startTime,
						this.promotionActivityEdit.endTime,
						this.promotionActivityEdit.orderLimit,
						this.promotionActivityEdit.periodLimit,
						this.promotionActivityEdit.statusDomainId,
						this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges)
					);
					if (CommercialActivitySectorLocal) {
						this.sectorTooltip = response.data.commercialActivitySector;
						const sct = CommercialActivitySectorLocal.map((sctr: ICommercialActivitySector) => {
							return sctr.sectorCode;
						});
						this.sector.setValue(sct);
					}

					if(this.promotionActivityEdit.isHandler && !this.flagHanlder){
						
						this.showManejantes = this.promotionActivityEdit.isHandler;
						this.commercialActivityHandler = this.promotionActivityEdit.commercialActivityHandler;
						this.commercialActivityHandlerisGroup = this.commercialActivityHandler.isGroup;
						this.commercialActivityHandlerisClientWithoutSales = this.commercialActivityHandler.isClientWithoutSales;
						this.commercialActivityHandlerDetails = this.commercialActivityHandler.commercialActivityHandlerDetails;
						this.editFiltersObjectHandlerList = JSON.parse(this.commercialActivityHandler.groupByJson);
						this.commercialActivityHandlerPeriod = this.promotionActivityEdit.commercialActivityHandlerPeriod;

						// CurrentMonthDetails
						this.isCurrentMonth = this.promotionActivityEdit.commercialActivityHandler.isCurrentMonth;
						if(this.isCurrentMonth){
							this.commercialActivityHandlerCurrentMonth = this.promotionActivityEdit.commercialActivityHandlerCurrentMonth;
							this.editFiltersObjectHandlerCurrentMonthList = JSON.parse(this.promotionActivityEdit.commercialActivityHandlerCurrentMonth.groupByJson);
							this.commercialActivityHandlerCurrentMonthDetails = this.promotionActivityEdit. commercialActivityHandlerCurrentMonth.commercialActivityHandlerCurrentMonthDetails;
							this.switchService.sendData(this.isCurrentMonth);
						}
					}

					this.prepareDataEditPromotionalActivity(this.promotionActivityEdit);
					this.filterClientJsonToCalculate = response.data.commercialActivityCustomers !== null 
						? JSON.stringify(response.data.commercialActivityCustomerJson) : response.data.filterClientJson;
					this.filterClientJson = response.data.filterClientJson;
					this.commercialActivityCustomers = response.data.commercialActivityCustomers;
					this.preparePurchaseToCalculate();
					this.verifyInvalidateData(response.data.statusDomainId, response.data.validationResult);

					if (response.data.commercialActivityObservation && this.flagDocumentObservation)
					{
						this.observationEdit = response.data.commercialActivityObservation;
					}

					let typeCommercialActivityId = this.promotionActivityEdit.typeCommercialActivityDomainId;
					if (typeCommercialActivityId)
					{
						await this.documentSupport.setRequiredDocumentInformation(typeCommercialActivityId);
						this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
					}

					if(this.isProcess == this.commercialActivityEnum.view 
						&& (!this.filterClientJson || this.filterClientJson == '') 
						&& (!this.promotionActivityEdit.commercialActivityCustomerJson || this.promotionActivityEdit.commercialActivityCustomerJson[0].content == null))
						{
							const language: string = sessionStorage.getItem('language');
								SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
									language === 'es-ES' ? 'Por favor asignar clientes.'
									: 'Please assign clients.');
							this.showFieldsCreatePromotion.commercialActivitySendValidate = false;
					}
					this.isCalculate = this.promotionActivityEdit.isCalculate;					
					this.spinner.hide();
				}
			}, (error) => {
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, `${error}`);
			}
		);
	}

	/**
	 * @description This method is to validate if the commercial activity is invalid
	 *
	 * @param statusDomainId nunmber of status domaind id from the commercial activity
	 * @param validationResult Json string with the validation result
	 * @sprint 21
	 * @author Diego Mauricio Cortés
	 */
	verifyInvalidateData(statusDomainId: number, validationResult: string): void {
		if (CommercialActivityStatus.Invalid === statusDomainId && validationResult) {
  		  try
		  {
			const validation: any = JSON.parse(validationResult);
			const invalidation: CommercialActivityInvalidationDataDetail = { ...validation };
			this.invalidationDataDetail = [];
			invalidation.result.forEach((rsp) => {
				let skus = '';
				let clients = 0;
				if (!rsp.clients.status) {
					clients = rsp.clients.repeated;
				}
				if (!rsp.material.status) {
					skus = rsp.material.materialCodesrepeated.join();
				}
				if (clients > 0 || skus !== '') {
					///buscar por id
					this.invalidationDataDetail.push({
						id: rsp.id,
						clients: clients,
						skus: skus
					});
				}
			});
			this.displayValidationsDetails = true;
		}
		catch(error)
		{
			if (this.isInitialError)
			{
				const language: string = sessionStorage.getItem('language');
				const msg = language === 'es-ES' ? SweetAlert.messageErrorValidationActivity
											: SweetAlert.messageErrorValidationActivityTranslate;

				Util.optionalMessage(msg).then((result : SweetAlertResult) =>{
					if(result.value){
						this.eventClickSendValidateBtn();
					}else{
						this.displayValidationsDetails = false;
						this.isInitialError = false;
					}
				})
			}
		}
	  }
	}

	/**
	 * @description This method is to verify if the user wants update the commercial activity to status registered or deleted
	 * @param opc boolean with the user select
	 * @sprint 21
	 * @author Diego Mauricio Cortes
	 */
	registryInvalidateCommercialACtivity(opc: boolean): void {
		this.displayValidationsDetails = false;
		this.spinner.show();
		if (opc) {
			this.promotionActivityEdit.commercialActivityHandler = this.commercialActivityHandler;
			this.promotionActivityEdit.statusDomainId = CommercialActivityStatus.Registered;
			this.promotionalActivityService.putPromotionalActivity(
				this.promotionActivityEdit,
				this.commercialActivityId
			).subscribe(
				(response) => {
					if (response.status) {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
						this.router.navigate(['auth/manage-promotional-activities']);
					} else {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
					}
					this.spinner.hide();
				}, (error: HttpErrorResponse) => {
					this.spinner.hide();
					SweetAlertMessageHelpers.exception(error);
				});
		} else {
			this.managePromotionActService.delete(`${this.commercialActivityId}`)
				.subscribe(
					(response) => {
						this.spinner.hide();
						if (response.status) {
							const language: string = sessionStorage.getItem('language');
							this.showToast(SweetAlert.iconInfo,
							language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
							language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
							this.router.navigate(['auth/manage-promotional-activities']);
						} else {
							SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
						}
					},
					(error: HttpErrorResponse) => {
						this.spinner.hide();
						SweetAlertMessageHelpers.exception(error);
					}
				);
		}

	}

	/**
	 * @description This method is to prepare data to send to calculate
	 * @sprint 18
	 * @author Diego Cortes
	 */
	private preparePurchaseToCalculate() {
		if (this.promotionActivityEdit.isCalculate) {
			this.isProcess = this.commercialActivityEnum.view;
		}
		if (this.promotionActivityEdit.purchaseBases) {
			this.promotionActivityEdit.purchaseBases.forEach((object) => {
				const dt = object.purchaseBaseDetails.map((obj) => {
					this.totalDepositValue += obj.depositValue;
					this.totalEbitdaPercentage += obj.ebitdaPercentage;
					this.totalEbitda += obj.ebitda;
					return {
						id: obj.id,
						purchaseBaseId: obj.purchaseBaseId,
						materialCode: obj.materialCode,
						materialDescription: obj.materialDescription,
						measurementUnitCode: obj.measurementUnitCode,
						measurementUnitDescription: obj.measurementUnitDescription,
						purchaseUnit: obj.purchaseUnit,
						porcent: obj.porcent,
						discountValue: obj.discountValue,
						product: obj.product,
						hierarchy: obj.hierarchy,
						currentPsp: obj.currentPsp,
						depositValue: obj.depositValue,
						ebitdaPercentage: obj.ebitdaPercentage,
						ebitda: obj.ebitda,
						weightedDiscountPercentage: obj.weightedDiscountPercentage,
						costSale: obj.costSale,
						costIncome: obj.costIncome,
						factor: obj.factor || 1.056646837,
						perDescount: obj.perDescount || 0,
						promotionValue: obj.promotionValue || 0,
						weightedValue: obj.weightedValue || 0,
						kpiLiberationSku: obj.kpiLiberationSku || 0,
					};
				});
				this.purchaseBaseToCalculate = this.purchaseBaseToCalculate.concat(dt);
			});
		}

		if (this.promotionActivityEdit.purchaseBasePromotions) {
			this.promotionActivityEdit.purchaseBasePromotions.forEach((object) => {
				if (object.purchaseBasePromotionDetails) {
					const dt = object.purchaseBasePromotionDetails.map((obj) => {
						this.totalPromotionDepositValue += obj.depositValue;
						this.totalPromotionEbitdaPercentage += obj.ebitdaPercentage;
						this.totalPromotionEbitda += obj.ebitda;
						this.totalDepositValue += obj.depositValue;
						this.totalEbitdaPercentage += obj.ebitdaPercentage;
						this.totalEbitda += obj.ebitda;
						return {
							id: obj.id,
							purchaseBaseId: obj.purchaseBaseId,
							materialCode: obj.materialCode,
							materialDescription: obj.materialDescription,
							measurementUnitCode: obj.measurementUnitCode,
							measurementUnitDescription: obj.measurementUnitDescription,
							purchaseUnit: obj.purchaseUnit,
							porcent: obj.porcent,
							discountValue: obj.discountValue,
							product: obj.product,
							hierarchy: obj.hierarchy,
							currentPsp: obj.currentPsp,
							depositValue: obj.depositValue,
							ebitdaPercentage: obj.ebitdaPercentage,
							ebitda: obj.ebitda,
							weightedDiscountPercentage: obj.weightedDiscountPercentage,
							costSale: obj.costSale,
							costIncome: obj.costIncome,
							factor: obj.factor || 1.056646837,
							perDescount: obj.perDescount || 0,
							promotionValue: obj.promotionValue || 0,
							weightedValue: obj.weightedValue || 0,
							kpiLiberationSku: obj.kpiLiberationSku || 0,
						};
					});
					this.purchasePromotionToCalculate = this.purchasePromotionToCalculate.concat(dt);
				}
			});
		}

	}

	/**
	 * @description This method is to prepare the range data to edit.
	 *
	 * @param dataRange
	 * @author Diego Mauricio Cortés
	 * @sprint9
	 */
	private prepareDataRangeCommercialActivityToEdit(dataRange: any[]): IDataRange[] {
		let dataRangeRet: IDataRange[] = [];
		if (dataRange ) {
			if(dataRange.length > 0){
				dataRangeRet = dataRange.map((data: any) => {
					const start = new Date(data.dateStart);
					const end = new Date(data.dateEnd);
					return {
						dateStart: data.dateStart,
						dateEnd: data.dateEnd,
						cantDays: Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)),
						id: data.id,
						commercialActivityId: data.commercialActivityId
					};
				});
				this.initialDate = dataRangeRet[0].dateStart;
			}
		}
		return dataRangeRet;
	}

	/**
	 * @description This method is to prepare data in promotion
	 * @param promotion
	 */
	private prepareDataEditPromotionalActivity(promotion: IPromotionalActivity): void {
		this.description.setValue(promotion.description);
		this.typeCommercialActivity.setValue(this.findTypeCommercialActivityName(promotion.typeCommercialActivityDomainId));
		this.promotionType.setValue(this.findPromotionTypeName(promotion.typePromotionalActivityDomainId));
		this.onSelectTypePromotion();
		this.prepareFiltersToEditProductsPromotionalActivity(promotion);
		this.prepareFiltersToEditProductsPromotionPromotionalActivity(promotion);
		this.channel.setValue(this.prepareChannelsToShowEditPromotionalActivity(promotion.commercialActivityChannels));
		this.prepareCommercialActivitiesSectorsToCreate();
		this.preparePurchaseBaseProductsToEditPromotionalActivity(promotion);
	}

	/**
	 * @description This method is to prepare selected channels
	 *
	 * @param channels
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private prepareChannelsToShowEditPromotionalActivity(channels: IChannel[]): string[] {
		if (channels) {
			return channels.map((ch) => {
				return ch.channelCode;
			});
		}
	}

	/**
	 * @description This method is to find the name description to promotion type
	 *
	 * @param id
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private findPromotionTypeName(id: number): string {
		const promotionType = this.objectTypePromotionActivity.find((obj: any) => obj.id === id);
		return promotionType.description;
	}

	/**
	 * @description This method is to prepare selected filters to show in edit promotional activity
	 *
	 * @param promotion
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private prepareFiltersToEditProductsPromotionalActivity(promotion: IPromotionalActivity): void {
		if (promotion.purchaseBases) {
			const filters: IHierarchyFiltersMaterials[] = promotion.purchaseBases.map((element) => {
				return JSON.parse(element.groupByJson);
			});
			this.editFiltersObjectList = [...filters];
		}
	}

	/**
	 * @description This method is to prepare promotion selected filters to show in edit promotional activity
	 *
	 * @param promotion
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private prepareFiltersToEditProductsPromotionPromotionalActivity(promotion: IPromotionalActivity): void {
		if (promotion.purchaseBasePromotions) {
			const filters: IHierarchyFiltersMaterials[] = promotion.purchaseBasePromotions.map((element) => {
				return JSON.parse(element.groupByJson);
			});
			this.editFiltersPromotionObjectList = [...filters];
		}
	}

	/**
	 * @description This method is to prepare group products to edit promotion activity
	 *
	 * @param promotion
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private preparePurchaseBaseProductsToEditPromotionalActivity(promotion: IPromotionalActivity): void {
		if (promotion.purchaseBases) {
			this.productGroupFilterList = promotion.purchaseBases.map((group, index) => {
				return {
					groupName: JSON.parse(group.groupByJson).hierarchyFilter,
					commercialActivityId: group.commercialActivityId,
					purchaseUnit: group.purchaseUnit,
					measurementUnitCode: group.purchaseBaseDetails ? group.purchaseBaseDetails[0].measurementUnitCode : MeasurementUnit.PIECE,
					isGroup: true,
					groupByJson: group.groupByJson,
					id: group.id,
					purchaseBaseDetails: this.prepareProductsToEditPromotionalActivity(group),
					purchaseBasePromotionDetails: []
				};
			});
		}

		if (promotion.purchaseBasePromotions) {
			this.productGroupFilterListPromotion = promotion.purchaseBasePromotions.map((group, index) => {
				return {
					groupName: JSON.parse(group.groupByJson).hierarchyFilter,
					commercialActivityId: group.commercialActivityId,
					purchaseUnit: group.purchaseUnit,
					measurementUnitCode: group.purchaseBasePromotionDetails ? group.purchaseBasePromotionDetails[0].measurementUnitCode : MeasurementUnit.PIECE,
					isGroup: true,
					groupByJson: group.groupByJson,
					id: group.id,
					purchaseBaseDetails: [],
					purchaseBasePromotionDetails: this.prepareProductsPromotionToEditPromotionalActivity(group)
				};
			});
		}
	}

	/**
	 * @description This method is to prepare purchase base products
	 * list to edit promotional activity
	 *
	 * @param group
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private prepareProductsToEditPromotionalActivity(group: IProductGroupList): IProductList[] {
		let products: IProductList[] = [];
		if (group.purchaseBaseDetails) {
			products = group.purchaseBaseDetails.map((product) => {
				const productRet = { ...product };
				productRet.product = product.materialDescription;
				productRet.hierarchy = JSON.parse(group.groupByJson).hierarchyFilter;
				return productRet;
			});
		}
		return products;
	}

	/**
	 * @description This method is to prepare promotion products list to
	 * edit promotional activity
	 *
	 * @param group
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	private prepareProductsPromotionToEditPromotionalActivity(group: IProductGroupList): IProductList[] {
		let products: IProductList[] = [];
		if (group.purchaseBasePromotionDetails) {
			products = group.purchaseBasePromotionDetails.map((product) => {
				const productRet = { ...product };
				productRet.product = product.materialDescription;
				productRet.hierarchy = JSON.parse(group.groupByJson).hierarchyFilter;
				return productRet;
			});
		}
		return products;
	}

	/**
	 * @description This method is to catch the event to edit promotional activity information.
	 *
	 * @param $event
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	async eventClickEditBtn($event: ICommercialActivityFooter) {

		this.documentValid = this.validateDocuments();
		let validateManejantes = true;

		if(this.showManejantes){ 
			this.prepareActivityHandler();
			this.isCurrentMonth ? this.prepareActivityHandlerCurrentMonth() : this.commercialActivityHandlerCurrentMonth2 = null;
			validateManejantes = this.isProcess === this.commercialActivityEnum.view ? true : await this.validateHandler();
		}else {
			this.commercialActivityHandlerPeriod2 = [];
			
			this.commercialActivityHandler2 = {
				commercialActivityId : 0,
				id: 0,
				groupByJson : '',
				isGroup: false,
				commercialActivityHandlerDetails : [],
				isClientWithoutSales : false,
				isCurrentMonth : false
			};
		}

		if (this.validateForm() && this.validationsForPurchaseBaseProductList() && this.validationsForPromotionalProductList() && this.formPromotionalActivity.valid
		&& this.validationTope($event.orderLimit, $event.periodLimit) && validateManejantes && this.documentValid) {
			this.nextAssignClients = true;
			this.promotionalActivityModel = this.preparePromotionActivityToEdit($event);
			this.promotionActivityEdit.commercialActivityChannels = this.prepareChannelsToSave();
			this.promotionActivityEdit.commercialACtivitySector = this.prepareSectorsToSave();
			this.promotionActivityEdit.commercialActivityObservation = this.documentInfo;
		}
	}

	validationTope(order: number, period: number): boolean{
		if(order == 0 || period == 0){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate ,
				language === 'es-ES' ? SweetAlert.messageTope : SweetAlert.mesageTopeTranslate);
			return false;
		}
		return true;
	}

	/**
	 * @description This method is to catch the event to send promotional activity to validate.
	 *
	 * @param $event
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	eventClickSendValidateBtn(): void {
		if(!this.validateForm()){
			return;
		}
		this.spinner.show();
		const sendQueue = new CommercialACtivityQueueValidationModel(
			this.commercialActivityId,
			CommercialActivityStatus.InValidation,
			null,
			Module.PromotionalActivity,
			this.commercialActivityId,
			this.typeCommercialActivity.value,
			moment(new Date()).format('YYYY-MM-DD')
		);
		this.managePromotionActService.postCommercialActivityQueueValidated(sendQueue).subscribe(
			(response) => {
				if (response.status) {
					this.router.navigate(['auth/manage-promotional-activities']);
				}else{
					const listError = [];
					listError.push(response.message);
					SweetAlertMessageHelpers.listWarningMessageShow(SweetAlert.titleAlertError, listError);
					}

				this.spinner.hide();
			}, (error) => {
				console.error('ERROR', error);
				this.spinner.hide();
			}
		);
	}

	eventClickSendDataEditClients($event: any): void {
		console.table($event);
		this.spinner.show();
		this.promotionalActivityModel.filterClientJson = $event.jsonFilterClient;
		this.promotionalActivityModel.commercialActivityCustomers = this.prepareCommercialActivityCustomerCreate($event.listClient);
		this.promotionalActivityModel.isCalculate = false;
		this.promotionalActivityModel.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate();
		this.promotionalActivityModel.totalClientsFilter = $event.listClient == null ? $event.totalClients : $event.listClient.length;
		this.promotionalActivityModel.commercialActivityObservation = this.documentInfo;

		this.prepareCommercialActivitiesDocumentsToCreate();
		this.promotionalActivityModel.commercialActivityObservation = this.documentInfo;

		this.promotionalActivityService.putPromotionalActivity(this.promotionalActivityModel, this.promotionalActivityModel.id).subscribe(
			(response) => {
				if (response.status) {
					SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
					this.router.navigate(['auth/manage-promotional-activities']);
				} else {
					SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
				}
				this.spinner.hide();
			}, (error: HttpErrorResponse) => {
				this.spinner.hide();
				SweetAlertMessageHelpers.exception(error);
			});
	}
	prepareCommercialActivitiesDocumentsToCreate() {
		this.documentInfo[0].commercialActivityId = this.commercialActivityId;
	}

	/**
	 * @description This method is to prepare promotion activity model for sent to edit
	 *
	 * @param promotion
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	preparePromotionActivityToEdit(promotion: ICommercialActivityFooter): IPromotionalActivity {
		const promotionalActivityModel: PromotionalActivityModel = new PromotionalActivityModel(
			this.promotionActivityEdit.typeCommercialActivityDomainId,
			this.promotionActivityEdit.typePromotionalActivityDomainId,
			promotion.statusDomainId,
			this.promotionActivityEdit.description, // this.description.value,
			promotion.startTime,
			promotion.endTime,
			promotion.orderLimit,
			promotion.periodLimit,
			promotion.numberDays,
			this.promotionActivityEdit.filterClientJson,
			this.promotionActivityEdit.id,
			this.prepareProductGroupFilterListToEdit(this.productGroupFilterList, this.promotionActivityEdit.id),
			this.prepareProductGroupFilterListToEdit(this.productGroupFilterListPromotion, this.promotionActivityEdit.id),
			promotion.dataRanges,
			this.prepareChannelsToSave(),
			this.promotionActivityEdit.commercialActivityCustomers,
			null,
			null,
			null,
			null,
			null,
			this.showManejantes,
			this.commercialActivityHandler2,
			this.commercialActivityHandlerCurrentMonth2,
			this.commercialActivityHandlerPeriod2
		);
		return promotionalActivityModel;
	}

	/**
	 * @description This method is to prepare data to edit group list filters added id commercial activity
	 *
	 * @param groupList
	 * @param promotionalActivityId
	 * @author Diego Mauricio Cortés
	 * @sprint 9
	 */
	prepareProductGroupFilterListToEdit(groupList: IProductGroupList[], promotionalActivityId: number): IProductGroupList[] {
		const aux = [...groupList];
		const retData = aux.map((group) => {
			group.commercialActivityId = promotionalActivityId;
			return group;
		});
		return retData;
	}

	eventClickBackClients(): void {
		this.observationEdit = this.documentInfo;
		if (this.isProcess === this.commercialActivityEnum.update) {
			this.flagHanlder = true;
			this.flagDocumentObservation = false;
			this.prepareDataHandler();
			// this.getPromotionalActivityToEdit();
			this.prepareDataBackClients();
		} else if (this.isProcess === this.commercialActivityEnum.view) {
			this.nextAssignClients = false;
		} else {
			this.commercialActivityId = this.managePromotionActService.getCommercialActivityId();
			this.prepareDataHandler();
			this.prepareDataBackClients();
		}

	}

	private prepareDataHandler(){
		this.showManejantes = this.showManejantes;
		this.editFiltersObjectHandlerList = [...this.editFiltersObjectHandlerList2];
		this.commercialActivityHandlerDetails = [...this.commercialActivityHandlerDetails2]
		this.commercialActivityHandlerPeriod = this.commercialActivityHandlerPeriod2;
		this.commercialActivityHandlerisGroup = this.commercialActivityHandlerisGroup2;
		this.commercialActivityHandlerisClientWithoutSales = this.commercialActivityHandlerisClientWithoutSales2;

		//HandlerCurrentMonthList
		this.isCurrentMonth = this.isCurrentMonth;
		this.editFiltersObjectHandlerCurrentMonthList = [...this.editFiltersObjectHandlerCurrentMonthList2]
		this.commercialActivityHandlerCurrentMonthDetails = [...this.commercialActivityHandlerCurrentMonthDetails2]
	}
	
	prepareDataBackClients(){
		this.nextAssignClients = false;
		this.promotionEdit = { ...this.promotionEditFooter };
		this.editFiltersObjectList = [...this.editFiltersObjectList];
		this.editFiltersPromotionObjectList = [...this.editFiltersPromotionObjectList];
	}

	public prepareFooterForEvent($event: ICommercialActivityFooter) {
		this.promotionEditFooter = new CommercialActivityFooterModel(
			$event.numberDays,
			$event.startTime,
			$event.endTime,
			$event.orderLimit,
			$event.periodLimit,
			$event.statusDomainId,
			$event.dataRanges
		);
	}

	eventSendToCalculate($event: any) {
		this.updateCalculatedData($event);
		this.promotionActivityEdit.isCalculate = true;
		this.promotionActivityEdit.statusDomainId = CommercialActivityStatus.Pending;
		this.promotionActivityEdit.commercialACtivitySector = this.prepareCommercialActivitiesSectorsToCreate();
		const updateModel : CommercialActivityStatusModel = new CommercialActivityStatusModel(this.promotionActivityEdit.id,CommercialActivityStatus.Pending,'');
		this.spinner.show();
		this.promotionalActivityService.putPromotionalActivity(this.promotionActivityEdit, this.promotionActivityEdit.id).subscribe(
			(response) => {
				const language: string = sessionStorage.getItem('language');
					SweetAlertMessageHelpers.success(ManagePromotionalActivitiesMessage.OK,
						language === 'es-ES' ? ManagePromotionalActivitiesMessage.CALCULATE_PROCESS_OK : ManagePromotionalActivitiesMessage.CALCULATE_PROCESS_OK_TRANSLATE);
				this.managePromotionActService.postCommercialActivityUpdateStatus(updateModel).toPromise();
				this.router.navigate(['/auth/manage-promotional-activities']);
				this.spinner.hide();
			}, (error: HttpErrorResponse) => {
				this.spinner.hide();
				SweetAlertMessageHelpers.exception(error);
			});

	}

	/**
	 * @description: This method is to update calculated data in skus
	 *
	 * @param responseAnal: Aanalitics calculate process response
	 * @sprint 20
	 */
	private updateCalculatedData(responseAnal: any) {
		this.promotionActivityEdit.purchaseBases.forEach((prch: any) => {
			prch.purchaseBaseDetails.forEach((mat: any) => {
				const elm = responseAnal.purchaseBaseCalculate.filter((elem: any) => elem.materialCode === mat.materialCode);
				if (elm.length > 0) {
					mat.perDescount = elm[0].perDescount;
					mat.ebitda = elm[0].ebitda;
					mat.ebitdaPercentage = elm[0].ebitdaPercentage;
					mat.costIncome = elm[0].costIncome;
					mat.promotionValue = elm[0].promotionValue;
					mat.weightedValue = elm[0].weightedValue;
					mat.kpiLiberationSku = elm[0].kpiLiberationSku;
				}
			});
		});

		this.promotionActivityEdit.purchaseBasePromotions.forEach((prm: any) => {
			prm.purchaseBasePromotionDetails.forEach((mat: any) => {
				const elm = responseAnal.purchasePromotionCalculate.filter((elem: any) => elem.materialCode === mat.materialCode);
				if (elm.length > 0) {
					mat.perDescount = elm[0].perDescount;
					mat.ebitda = elm[0].ebitda;
					mat.ebitdaPercentage = elm[0].ebitdaPercentage;
					mat.costIncome = elm[0].costIncome;
					mat.promotionValue = elm[0].promotionValue;
					mat.weightedValue = elm[0].weightedValue;
					mat.kpiLiberationSku = elm[0].kpiLiberationSku;
				}
			});
		});
	}

	/**
	 * @description This method is to validate the selected sector combinations
	 *
	 * @sprint 17
	 * @author Diego Mauricio Cortés
	 */
	selectSectorValidate(): void {

		if (this.sector.value.length > 1)
		{
			this.sector.value.forEach((x:string) => {
				if (this.objectSectorList.find((y) => y.value === x && !y.crossActive))
				{
					const inx = this.sector.value.findIndex((z:string) => z === x)
					this.sector.value.splice(inx,1);
				}
			});
		}
	}

	sumPurchasePromotionalTotal(sku: string, type: boolean): void {
		if (type) {
			this.productGroupFilterList.forEach((group) => {
				const found = group.purchaseBaseDetails.filter((dtl) => dtl.materialCode === sku);
				if (found) {
					group.purchaseUnit = 0;
					group.purchaseBaseDetails.forEach((prd) => {
						group.purchaseUnit += prd.purchaseUnit ? prd.purchaseUnit : 0;
					});
				}
			});
		} else {
			this.productGroupFilterListPromotion.forEach((group) => {
				const found = group.purchaseBasePromotionDetails.filter((dtl) => dtl.materialCode === sku);
				if (found) {
					group.purchaseUnit = 0;
					group.purchaseBasePromotionDetails.forEach((prd) => {
						group.purchaseUnit += prd.purchaseUnit ? prd.purchaseUnit : 0;
					});
				}
			});
		}
	}

	eventswitchValue(value : boolean){
		this.showManejantes = value;
	}

	private deletePromotionSelectedProduct(auxProducts: IProductList[]) {
		this.productGroupFilterListPromotion.forEach(async (group, indexGroup) => {
			auxProducts.forEach((product, indexProduct) => {
				const indexFound = group.purchaseBasePromotionDetails.findIndex((prd) => prd.materialCode === product.materialCode);
				if (indexFound > -1) {
					group.purchaseBasePromotionDetails.splice(indexFound, 1);
					this.selectProductListPromotion.splice(indexProduct, 1);
				}
				group.purchaseBasePromotionDetails.forEach((elm) => {
					this.sumPurchasePromotionalTotal(elm.materialCode, false);
				});
			});
			if (group.purchaseBasePromotionDetails.length === 0) {
				const responseDelete = await this.promotionalActivityService.deleteProductPromotionGroupsList(`${group.id}`).toPromise();
				this.productGroupFilterListPromotion.splice(indexGroup, 1);
			}
		});
	}

	private deleteSelectedProductBasePurchase(auxProducts: IProductList[]) {
		this.productGroupFilterList.forEach(async (group, indexGroup) => {
			auxProducts.forEach((product, indexProduct) => {
				const indexFound = group.purchaseBaseDetails.findIndex((prd) => prd.materialCode === product.materialCode);
				if (indexFound > -1) {
					group.purchaseBaseDetails.splice(indexFound, 1);
					this.selectProductList.splice(indexProduct, 1);
				}
				group.purchaseBaseDetails.forEach((elm) => {
					this.sumPurchasePromotionalTotal(elm.materialCode, true);
				});
			});
			if (group.purchaseBaseDetails.length === 0) {
				const responseDelete = await this.promotionalActivityService.deleteProductGroupsList(`${group.id}`).toPromise();
				this.productGroupFilterList.splice(indexGroup, 1);
			}
		});
	}

	/**
	 * @description This method is to initialize the form.
	 * @author Diego Mauricio Cortés
	 * @sprint 7
	 */
	initForm(): void {
		this.formPromotionalActivity = this.fb.group({
			process: [{ value: '', disabled: false }],
			description: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(100), Validators.pattern(RegexCommon.ExpRegOnlyCaracterNumber100)]],
			typeCommercialActivity: [
				{ value: '', disabled: true },
				[Validators.required]],
			promotionType: [{ value: '', disabled: this.isProcess === this.commercialActivityEnum.update || this.isProcess === this.commercialActivityEnum.view }, [Validators.required]],
			channel: [{ value: '', disabled: this.isProcess === this.commercialActivityEnum.view }, [Validators.required]],
			sector: [{ value: '', disabled: this.isProcess === this.commercialActivityEnum.view }, []]
		});
	}

	get process(): AbstractControl { return this.formPromotionalActivity.get('process'); }
	get description(): AbstractControl { return this.formPromotionalActivity.get('description'); }
	get typeCommercialActivity(): AbstractControl { return this.formPromotionalActivity.get('typeCommercialActivity'); }
	get promotionType(): AbstractControl { return this.formPromotionalActivity.get('promotionType'); }
	get channel(): AbstractControl { return this.formPromotionalActivity.get('channel'); }
	get sector(): AbstractControl { return this.formPromotionalActivity.get('sector'); }

	private dataNoZeroValidation(dataNoZero: number, dataZero: number) {
		this.productGroupFilterList.forEach((elm) => {
			let sum = 0;
			elm.purchaseBaseDetails.forEach((itm) => {
				if (itm.purchaseUnit === 0) {
					sum += 1;
				}
			});
			if (sum === 0) {
				dataNoZero = 2;
			}
			if (sum > 0 && sum !== elm.purchaseBaseDetails.length) {
				dataZero = 1;
			}
		});
		return { dataNoZero, dataZero };
	}

	validateDocuments():boolean {
		let validDocument:boolean = true;
		this.documentInfo = this.documentSupport.getDocumentInformation();
		const language: string = sessionStorage.getItem('language');
	
		let fileName = this.documentInfo[0].file ?? this.documentSupport.getNameFile();

		if((this.documentFieldsRequired?.document && !fileName) && 
		(this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim()))
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE_TRANSLATE);
				validDocument = false;
			
			return;
		}
		
		if (this.documentFieldsRequired?.document && !fileName)
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE_TRANSLATE);
				validDocument = false;
		}
	
		if (this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim())
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_TRANSLATE);
				validDocument = false;
		}	
		return validDocument;
	}		
}

