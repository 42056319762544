import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApprovalRankMessage {

  public static readonly TITLE = 'Rangos de aprobación';
  public static readonly NOT_APPROVAL_LEVEL = 'Debe seleccionar un nivel de aprobación';
  public static readonly APPROVAL_LEVEL_REPEAT_ERROR = 'Nivel de aprobación repetido ';
  public static readonly NOT_OPERATOR_START = 'Debe seleccionar un operador inicial';
  public static readonly NOT_OPERATOR_END = 'Debe seleccionar un operador final';
  public static readonly NOT_OPERATOR_START_TRANSLATE = 'You must select an initial operator';
  public static readonly NOT_OPERATOR_END_TRANSLATE = 'You must select a final operator';
  public static readonly NOT_ACCEPTED_VALUE = 'Valor no aceptado';
  public static readonly NOT_VALUE_START = 'Digite un valor inicial';
  public static readonly NOT_VALUE_END = 'Digite un valor final';
  public static readonly NOT_ACCEPTED_VALUE_TRANSLATE = 'Unaccepted value';
  public static readonly NOT_VALUE_START_TRANSLATE = 'Enter an initial value';
  public static readonly NOT_VALUE_END_TRANSLATE = 'Type a end value';
  public static readonly NOT_CONTRIBUTION = 'Digite el valor de aporte';
  public static readonly NOT_CONTRIBUTION_TRANSLATE = 'Enter the amount of contribution';
    public static readonly NOT_VALUE_ACCEPTED = 'El valor final debe ser mayor que el inicial';
  public static readonly NOT_VALUE_EQUAL = 'Los valores deben ser diferentes';
  constructor() { }
}
