
export enum SweetAlert {

  btnConfirmationColor = '#009CDD',
  btnCancelColor = '#6c757d',
  btnConfirmationText = 'Aceptar',
  btnConfirmationTextTranslate = 'To accept',
  btnCancelText = 'Cancelar',
  btnCancelTextTranslate = 'Cancel',
  btnDeleteText = 'Eliminar',
  btnDeleteTextTranslate = 'Delete',
  iconSuccess = 'success',
  iconInfo = 'info',
  iconError = 'error',
  iconWarning = 'warning',
  iconWarningToast = 'warn',
  btnConfirmationDownloadText = 'Descargar',
  btnConfirmationDownloadTextTranslate = 'Download',

  titleAlert = 'Hecho',
  titleAlertTranslate = 'Done',
  titleAlertError = 'Error',
  titleAlertErrorTranslate = 'Error',
  titleAlertResult = 'No se encontraron resultados',
  titleAlertResultTranslate = 'No results found',
  titleAlertWarning = 'Cuidado',
  titleAlertWarningTranslate = 'Care',
  titleWarning = 'Advertencia',
  titleWarningTranslate ='Caveat',
  titleAlertRetry = 'Reintento de conexión',
  titleAlertRetryTranslate = 'Connection retry',
  titleAlertValidationParameters = 'Valor Parámetro',
  titleAlertValidationParametersTranslate = 'Parameter Value',
  messageAlertCreate = '¡Registro creado satisfactoriamente!',
  messageAlertCreateTranslate = '¡Record successfully created!',
  messageAlertSuspended = '¡Registro suspendido satisfactoriamente!',
  messageAlertSuspendedTranslate = '¡Registration suspended Successfully!',
  messageAlertContributionValue= 'El valor aporte no puede ser mayor a 100%',
  messageAlertContributionValueTranslate = 'The contribution value cannot be greater than 100%',
  messageAlertAnalitic = '¡Registro(s) enviado(s) a analítica!',
  messageAlertAnaliticTranslate = '¡Record(s) sent to analytics!',
  messageAlertCopy = '¡Registro copiado satisfactoriamente!',
  messageAlertCopyTranslate = '¡Record copied successfully!',
  messageAlertUpdate = '¡Registro actualizado satisfactoriamente!',
  messageAlertUpdateTranslate = '¡Registry Updated Successfully!',
  messageAlertDelete = '¡Registro eliminado satisfactoriamente!',
  messageAlertDeleteTranslate = '¡Record deleted successfully!',
  messageAlertError = '¡No se pudo ejecutar el proceso!',
  messageAlertErrorTranslate = '¡The process could not be executed!',
  messageAlertSend = '¡Solicitud enviada satisfactoriamente!',
  messageAlertSendTranslate = '¡Request sent successfully!',
  messageAlertApprovalInProcessSolicitude = 'La solicitud esta siendo procesada',
  messageAlertApprovalInProcessSolicitudeTranslate = 'The request is being processed',
  messageAlertAcceptSolicitude = '¡Solicitud aceptada satisfactoriamente!',
  messageAlertAcceptSolicitudeTranslate = '¡Request successfully accepted!',
  messageAlertRejectSolicitude = '¡Solicitud rechazada satisfactoriamente!',
  messageAlertRejectSolicitudeTranslate = '¡Request successfully rejected!',
  messageAlertExist = '¡El registro ya ha sido creado!',
  messageAlertExistTranslate = '¡The record has already been created!',
  messageAlertValidationParameters = '¡El registro ya ha sido creado!',
  messageAlertValidationParametersTranslate = '¡The record has already been created!',
  messageAlertValidationClients = 'El total de clientes debe ser mayor a cero',
  messageAlertValidationClientsTranslate = 'Total customers must be greater than zero',
  messageUserExists = 'El usuario ya se encuentra creado en la aplicación',
  messageUserExistsTranslate = 'The user is already created in the application',
  messageAlertRetry = '',
  messageAlertRetryTranslate = '',
  messageAlertExclusionNegociationValidation = 'Actividad comercial no existe',
  messageAlertExclusionNegociationValidationTranslate = 'Commercial activity does not exist',
  messageAlertExclusionNegociationDuplicateValidation = 'El registro se encuentra duplicado',
  messageAlertExclusionNegociationDuplicateValidationTranslate = 'The record is duplicated',
  mesageRedemption = 'No hay productos para visualizar',
  messageErrorValidationActivity = 'El proceso de validación no pudo ser completado por el servicio de analítica. ¿Desea reenviar a validar la actividad?',
  messageErrorValidationActivityTranslate = 'The validation process couldnt be completed by analytics service. Do you want to forward to validate the activity?',
  mesageRedemptionTranslate = 'There are no products to view',
  mesageRedemptionTotal = 'Total Productos Redimibles: ',
  mesageRedemptionTotalTranslate = 'Total Redeemable Products: ',
  mesagePendingCreation = 'La solicitud se esta recalculando debido a un error presentado inicialmente, por favor espere un momento',
  mesagePendingCreationTranslate = 'The request is being recalculated due to an error submitted initially, please wait a moment',
  mesageDiscountfilter = 'Ya se han parametrizado productos, verifique y eliminelos si desea aplicar otro filtro',
  mesageDiscountfilterTranslate = 'Products have already been parameterized, check and delete them if you want to apply another filter',
  mesageFilterDiscount = 'Existen productos seleccionados sin parametrizar unidades compra, verifique y eliminelos si desea aplicar otro filtro',
  mesageFilterDiscountTranslate = 'There are selected products without parameterizing purchase units, check and delete them if you want to apply another filter',
  mesageRequiredDiscount = 'La descripción es requerida. Por favor intentelo de nuevo.',
  mesageRequiredDiscountTranslate = 'Description is required. Please try again..',
  mesageFilterRequiredDiscount = 'El filtro de clientes es obligatorio',
  mesageFilterRequiredDiscountTranslate = 'The customer filter is mandatory',
  messageFilterEvents = 'Existen productos seleccionados en base compra sin parametrizar unidades compra, verifique y eliminelos si desea aplicar otro filtro',
  messageFilterEventsTranslate = 'There are products selected on a purchase basis without parameterizing purchase units, check and delete them if you want to apply another filter',
  mesageBaseCompra = 'Ya se han parametrizado productos en base copra, verifique y eliminelos si desea aplicar otro filtro',
  mesageBaseCompraTranslate = 'Ya se han parametrizado productos en base copra, verifique y eliminelos si desea aplicar otro filtro',
  messageNoProductZero = 'La cantidad de unidades de productos en el grupo no pueden ser cero',
  messageNoProductZeroTranslate = 'The number of product units in the group cannot be zero',
  messageSelectSKU = 'Para continuar con el proceso debes seleccionar por lo menos un sku',
  messageSelectSKUTranslate = 'Para continuar con el proceso debes seleccionar por lo menos un sku',
  messageAddRank = 'Para continuar con el proceso debes añadir por lo menos un rango',
  messageAddRankTranslate = 'To continue with the process you must add at least one range',
  messageUserEmail = 'Por favor ingrese el correo completo o el usuario que desea crear es inválido',
  messageUserEmailTranslate = 'Please enter the full email or the user you want to create is invalid',
  messageRankDate = 'Debe seleccionar un rango de fechas',
  messageRankDateTranslate = 'You must select a date range',
  messageSectorandChannel = 'Son obligatorios los campos sector y canal',
  messageSectorandChannelTranslate = 'Son obligatorios los campos sector y canal',
  mesageProductSame = 'La cantidad de unidades de productos no son iguales en productos base compra',
  mesageProductSameTranslate = 'The number of units of products are not the same in purchase base products',
  mesageProductBasisPurchase = 'La cantidad de unidades de productos en el grupo no pueden ser cero en productos base compra',
  mesageProductBasisPurchaseTranslate = 'The quantity of units of products in the group cannot be zero in purchase base products',
  mesagemesageProductSamePromotion = 'La cantidad de unidades de productos en el grupo no son iguales en productos promoción',
  mesagemesageProductSamePromotionTranslate = 'The number of units of products in the group are not the same in promotion products',
  messageNoProductPromoZero = 'La cantidad de unidades de productos  no pueden ser ceros en productos promoción',
  messageNoProductPromoZeroTranslate = 'La cantidad de unidades de productos  no pueden ser ceros en productos promoción',
  messageFilterAdd = 'Tipo de filtro ya fue agregado, si requiere agregar más contenido a buscar puede editarlo en acciones',
  messageFilterAddTranslate = 'Filter type was already added, if you need to add more content to search you can edit it in actions',
  messageAlertCreateDate = '¡La fecha se ha registrado satisfactoriamente!',
  messageAlertCreateDateTranslate = '¡The date has been registered successfully!',
  messageTope = 'Agregue un valor en los campos tope por pedido y tope por periodo',
  mesageTopeTranslate = 'Add a value in the cap by order and cap by period fields',
  messageRankUpper = 'El rango de piezas superior es menor al rango de piezas inferior',
  messageRankUpperTranslate = 'Upper part range is less than lower part range',
  messageRecalculateImpactAnalysis = 'El análisis de impacto del material {materialCode} fue limpiado exitosamente y fue enviado a calcular.',
  messageRecalculateImpactAnalysisTranslate = 'Impact analysis to material {materialCode} was successfully cleared and it was sent to calculate.',
  messageRankLower = 'El rango de piezas inferior es mayor al rango de piezas superior',
  messageRankLowerTranslate = 'Lower part range is greater than upper part range',
  messageValidateRank = 'Hay campos vacios en alguno de los rangos',
  messageValidateRankTranslate = 'There are empty fields in any of the ranges',
  messageEmptyConfiguration = 'No se encontraron clientes para esta configuración',
  messageEmptyConfigurationTranslate = 'No clients found for this configuration',
  messageValidateConfiguration = 'Verificar la configuración de productos no manejantes',
  messageValidateConfigurationTranslate = 'Verify no handling product settings',
  messageValidateConfigurationHandlerCurrenMonth = 'Verificar la configuración de productos a evaluar - mes actual',
  messageValidateConfigurationHandlerCurrenMonthTranslate = 'Verify the configuration of products to be evaluated - current month',
  messageConfirmDeleteSku = '¿Estás seguro de que quieres eliminar este registro?',
  messageConfirmDeleteSkuTranslate = 'Are you sure than you want to delete this record?',
  messageConfirmDeleteSkuSucessful = 'Registro borrado satisfactoriamente',
  messageConfirmDeleteSkuSucessfulTranslate = 'Record deleted successfully',
  messageConfirmDeleteSkuError = 'No se pudo eliminar el registro',
  messageConfirmDeleteSkuErrorTranslate = 'Record could not be deleted',
  messageSkusDeletedByApproverError = 'No se pudieron cargar los Skus eliminados por el Aprobador',
  messageSkusDeletedByApproverErrorTranslate = 'Unable to upload Skus deleted by Approver',
  messageConfirmDeleteSkusRejected = "Se encuentran Sku's en estado rechazado, por favor aprobarlos o eliminarlos de la solicitud.",
  messageConfirmDeleteSkusRejectedTranslate = "Sku's are found in a rejected state, please approve or remove them from the solicitude.",
  messageConfirmDeleteRejectedSku = "¿Está seguro que desea eliminar todos los Sku's en estado rechazado de esta solicitud? Esta acción no puede ser reversada.",
  messageConfirmDeleteRejectedSkuTranslate = "Are you sure you want to remove all Sku's in a rejected status from this solicitude? This action cannot be reversed.",
  messageConfirmDeleteRejectedSkuSucessful = "Los Sku's fueron eliminados de la solicitud exitosamente",
  messageConfirmDeleteRejectedSkuSucessfulTranslate = "Sku's were successfully removed from the solicitude.",
  messageConfirmDeleteRejectedError = "No se eliminaron los Sku's.",
  messageConfirmDeleteRejectedErrorTranslate = "Sku's not removed.",
  messageInfoLastSku = "No se puede eliminar el producto, la solicitud no puede quedar sin producto asociado.",
  messageInfoLastSkuTranslate = "The product cannot be deleted, the solicitude cannot be left without an associated product.",
  messageSpreadsAffect = "No se encontraron diferenciales afectados",
  messageSpreadsAffectTranslate = "No affected spreads were found"
}
