import { Injectable } from '@angular/core';
import { PaginatorFilterModel } from '../model/paginator-filter.models';

@Injectable({
    providedIn: 'root'
  })

  export class PaginatorFilterService{
    private pageModel: PaginatorFilterModel;
    
    setPaginatorValues(pageModel: PaginatorFilterModel) {
      this.pageModel = pageModel;
    }

    getPaginatorValues()
    {
      return this.pageModel;
    }

    resetPaginatorValues()
    {
      this.pageModel = null;
    }

    public scrollUp(){
      window.scroll({top: 0, left: 0, behavior:'smooth'});
    }
  }
