import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CommercialActivityFooterMessage {

  public static readonly MAXIMUM_PERIOD_VALIDITY_ES = 'La actividad no se puede crear porque supera el máximo de días configurado. La configuración máxima actual es de:';
  public static readonly MAXIMUM_PERIOD_VALIDITY_EN = 'The activity cannot be created because it exceeds the configured maximum days. The current maximum setting is:';
  public static readonly NOT_DATA_PERIOD_VALIDITY_ES = 'No hay información en periodo máximo de vigencia.';
  public static readonly NOT_DATA_PERIOD_VALIDITY_EN = 'There is no information on the maximum period of validity.';
  public static readonly INVALID_TIME_ES = 'La hora de inicio debe ser menor a la hora de fin.';
  public static readonly INVALID_TIME_EN = 'The start time must be less than the end time.';
  public static readonly INVALID_DATE_ES = 'Ya se ha seleccionado el rango de fechas.';
  public static readonly INVALID_DATE_EN = 'The date range has already been selected.';
  constructor() { }
}
