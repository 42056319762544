export enum exclusionState {
  registered = 1,
  valid = 2,
  finalized = 3
}

export enum exclusionStateString {
  registered = 'Registrado',
  valid = 'Vigente',
  finalized = 'Finalizado'
}
