import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '@app/@core/layouts/siderbar/notification.service';
import { SolicitudeNotificationUserStatusModel } from '@app/@modules/prices/solicitude/models/solicitude-notification-user-status.model';
import { SolicitudeStatusModel } from '@app/@modules/prices/solicitude/models/solicitude-status.model';
import { SolicitudeService } from '@app/@modules/prices/solicitude/solicitude.service';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { ManagePromotionalActivitiesMessage } from '@app/@modules/promotionalactivity/manage-promotional-activities/messages/manage-promotional-activities-messages.message';
import { CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { Module } from '@app/@shared/enums/module.enums';
import { NotificationType } from '@app/@shared/enums/notification-type.enum';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ErrorMessage } from '@app/@shared/messages/error.message';
import { NotificationInformativeModel } from '@app/@shared/model/notification-informative.model';
import { PaginationNotificationModel } from '@app/@shared/model/paginationNotification.model';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { INotification } from './interfaces/notifiaction-panel.interface';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['../../.././@shared/scss/responsive.table.scss'],
  styles: [
    `
    #pills-tabContent{
       height: 350px;
      }

      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }

      @media screen and (max-width: 1025px){
        #pills-tabContent{
          height: 800px;
        }
      }
    `,

  ],
  providers: [MessageService],
})
export class NotificationPanelComponent implements OnInit {
  private readonly notificationService: NotificationService;
  private readonly solicitudeService : SolicitudeService;
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly router: Router;
  private readonly sanitizer: DomSanitizer;
  private readonly managePromotionalActivitiesService: ManagePromotionalActivitiesService;

  public showDialog = false;
  public id: number;
  public snooze = true;
  public reject = true;
  public accept = true;
  public moreInfo = false;
  public commentary = false;
  public notificationColor = 'color-notificacion';
  public title = 'Title';
  public notificationText: any = 'Notification Text';
  public moduleId : number;
  public type : number;
  public notificationTypeId : number;
  public solicitudeNotificationId : number;

  public objListNotification: INotification[] = [];

  constructor(
    notificationService: NotificationService,
    solicitudeService : SolicitudeService,
    spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router,
    sanitizer: DomSanitizer,
    managePromotionalActivitiesService: ManagePromotionalActivitiesService,
  ) {
    this.notificationService = notificationService;
    this.solicitudeService = solicitudeService;
    this.spinner = spinner;
    this.messageService = messageService;
    this.router = router;
    this.sanitizer = sanitizer;
    this.managePromotionalActivitiesService = managePromotionalActivitiesService;
  }

  /**
   * @description this method is private.
   * @author Priscy Antonio Reales
   * @param $event
   */
  ngOnInit(): void {
    this.onClickTabPrice();
  }

  /**
   * @description this method is to show modal.
   * @author Priscy Antonio Reales
   * @param $event
   */
  onClickMoreInfo($event: any, object: INotification): void {
    if(object.moduleId !== Module.Price){
      this.reject = false;
    }else{ this.reject = true;}

    this.id = object.id;
    this.snooze = object.snooze;
    this.accept = object.accept;
    this.moreInfo = object.notificationTypeId !== NotificationType.Deleted && object.notificationTypeId !== NotificationType.Informative;
    this.notificationTypeId = object.notificationTypeId;
    this.solicitudeNotificationId = object.solicitudeNotificationId;
    this.commentary = object.commentary;
    this.notificationColor = object.notificationColor;
    this.title = object.title;
    this.notificationText = this.sanitizer.bypassSecurityTrustHtml(object.notificationText);
    this.moduleId = object.moduleId;
    this.type = object.type;
    this.showDialog = true;
  }

  /**
   * @description this method is to hide model.
   * @author Priscy Antonio Reales
   * @param $event
   */
  onHidden($event :any):void{
    this.showDialog = false;
  }

  onClickSnooze($event: any): void {}

  /**
   * @description this method is to reject data.
   * @author Priscy Antonio Reales
   * @param $event
   */
  onClickReject($event: any, notifiactionType : number, solicitudeNotificationId : number): void {
    if(notifiactionType === NotificationType.ApprovalRejection){
      this.updateStatus(
        $event.moduleId,
        $event.id,
        SolicitudeStatus.Rejected,
        $event.commentary
      );
    }
    this.updateStatusSolictudeNotification(solicitudeNotificationId, $event.moduleId);
  }

  /**
   * @description this method is to accept data.
   * @author Priscy Antonio Reales
   * @param $event
   */
  onClickAccept($event: any, notifiactionType : number, solicitudeNotificationId : number): void {
    if(notifiactionType === NotificationType.ApprovalRejection){
      this.updateStatusSolictudeNotification(solicitudeNotificationId, $event.moduleId);
    }
  }

  /**
   * @description this method is to see document.
   * @author Priscy Antonio Reales
   * @param $event
   */
  onClickSeeDocument($event: any, notifiactionType : number, solicitudeNotificationId : number) : void {
    this.updateStatusSolictudeNotification(solicitudeNotificationId, $event.moduleId);
    if($event.moduleId === Module.Price){
      this.solicitudeService.setSolicitudeId($event.id);
      let route = 'auth/pending-solicitude';
      if(notifiactionType === NotificationType.System){
        route = 'auth/solicitude';
      }

      if($event.type === SolicitudeType.Creation){
        this.router.navigate([`${route}/creation/`]);
      }else if($event.type === SolicitudeType.Withdrawal){
        this.router.navigate([`${route}/withdrawal/`]);
      }else{
        return;
      }
    }else if($event.moduleId === Module.PromotionalActivity){

        this.managePromotionalActivitiesService.setCommercialActivityId($event.id);
        this.managePromotionalActivitiesService.setIsCreate(ComercialActivityEnum.view);

        if ($event.type === CommercialActivitySubprocess.Promotions) {
          this.router.navigate(['/auth/manage-promotional-activities/create']);
        }
        else if($event.type === CommercialActivitySubprocess.DiscountValue ||
                $event.type === CommercialActivitySubprocess.DiscountKind ||
                $event.type === CommercialActivitySubprocess.FinancialDiscount ||
                $event.type === CommercialActivitySubprocess.PriceDiscount)
        {
          this.router.navigate(['/auth/manage-promotional-activities/discount']);
        }
        else if($event.type === CommercialActivitySubprocess.Loyalty)
        {
          this.router.navigate(['/auth/manage-promotional-activities/loyalty']);
        }
        else if($event.type === CommercialActivitySubprocess.Events)
        {
          this.router.navigate(['/auth/manage-promotional-activities/events']);
        }
        else if($event.type === CommercialActivitySubprocess.ZeroValueProduct)
        {
          this.router.navigate(['/auth/manage-promotional-activities/zero-value-product']);
        }
        else if($event.type  === CommercialActivitySubprocess.Redemption)
        {
          this.router.navigate(['/auth/manage-promotional-activities/redemption']);
        }
        else if($event.type  === CommercialActivitySubprocess.Rappel) {
          this.router.navigate(['/auth/manage-promotional-activities/rappel']);
        }
        else{
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_EXISTS_COMMERCIAL_ACTIVITY
            : ManagePromotionalActivitiesMessage.NOT_EXISTS_COMMERCIAL_ACTIVITY_TRANSLATE);
        }


    }else if($event.moduleId === Module.Negotiations){
      // Code ... by Negotiations
    }else{
      const language: string = sessionStorage.getItem('language');
      this.showToast(
        SweetAlert.iconError,
        SweetAlert.titleAlertError,
        language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate
      );
    }
  }

  /**
   * @description this method is to get Notification for Price.
   * @author Priscy Antonio Reales
   */
  onClickTabPrice(): void {
    this.getNotification(Module.Price);
  }

  /**
   * @description this method is to get Notification for Promotional Activity.
   * @author Priscy Antonio Reales
   */
  onClickTabPromotion(): void {
    this.getNotification(Module.PromotionalActivity);
  }

  /**
   * @description this method is to get Notification for Negotiation.
   * @author Priscy Antonio Reales
   */
  onClickTabNegotiation(): void {
    this.getNotification(Module.Negotiations);
  }

  /**
   * @description This method is to get all notification.
   * @author Priscy Antonio Reales
   * @sprint 6
   * @param moduleId
   * @returns none
   */
  getNotification(moduleId : Module ): void{
    this.spinner.show();
    const paginationNotificationModel = new PaginationNotificationModel('id',true,1,100,moduleId);

    this.notificationService.getListNotification(paginationNotificationModel).subscribe(
      (response) => {
        if(response.status){
          this.objListNotification = response.data.map((item) =>{
            return {
              id : item.solicitudeId,
              title: item.subject,
              notificationText : item.bodyMail,
              accept : true,
              reject : item.notificationTypeId === NotificationType.ApprovalRejection,
              snooze : false,
              commentary : item.enableCommentary,
              moreInfo : true,
              notificationColor : item.color,
              moduleId : item.moduleId,
              type : +item.typeSolicitude.split('-')[0],
              notificationTypeId : item.notificationTypeId,
              solicitudeNotificationId : item.id,
              creationDate : item.creationDate
            };
          });

          this.spinner.hide();
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
   * @description This method is to update status.
   * @author Priscy Antonio Reales
   * @sprint 7
   * @param moduleId
   * @param id
   * @param status
   * @param commentary
   * @returns none
   */
   updateStatus(
    moduleId : number,
    id : number,
    status : number,
    commentary : string
  ): void {
  if(moduleId === Module.Price){
    this.spinner.show();
    const statusModel: SolicitudeStatusModel = new SolicitudeStatusModel(
      id,
      status,
      commentary
    );

    this.solicitudeService.putSolicitudeStatus(id, statusModel)
    .subscribe(
      (response) => {
        if (response.status) {
          const language: string = sessionStorage.getItem('language');
          if(language === 'es-ES'){
            this.showToast(
              SweetAlert.iconSuccess,
              SweetAlert.titleAlert,
              status === SolicitudeStatus.InRecord ? SweetAlert.messageAlertAcceptSolicitude : SweetAlert.messageAlertRejectSolicitude
            );
          }else{
            this.showToast(
              SweetAlert.iconSuccess,
              SweetAlert.titleAlertTranslate,
              status === SolicitudeStatus.InRecord ? SweetAlert.messageAlertAcceptSolicitudeTranslate : SweetAlert.messageAlertRejectSolicitudeTranslate
            );
          }

          const modelNotification : NotificationInformativeModel = new NotificationInformativeModel(
            Module.Price,
            status === SolicitudeStatus.Rejected  ? NotificationType.System : NotificationType.Informative,
            id
          );
          this.solicitudeService.putSolicitudeNotificationInformative(modelNotification)
          .subscribe(
            () => {
              this.onClickTabPrice();
            }
          );

        } else {
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error) => {
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }else if(moduleId === Module.PromotionalActivity){
    // Code ... for update status by PromotionalActivity
  }else if(moduleId === Module.Negotiations){
    // Code ... for update status by Negotiations
  }else{
    const language: string = sessionStorage.getItem('language');
    this.showToast(
      SweetAlert.iconError,
      SweetAlert.titleAlertError,
      language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate
    );
  }
}

findNotificationByModule(moduleId : number){
  if(moduleId === Module.Price)
    this.onClickTabPrice();
  else if(moduleId === Module.PromotionalActivity)
    this.onClickTabPromotion();
  else if(moduleId === Module.Negotiations)
    this.onClickTabNegotiation();
}

  /**
   * @description This method is to update status notification.
   * @author Priscy Antonio Reales
   * @sprint 11
   * @param id
   * @returns none
   */
  updateStatusSolictudeNotification (id : number, moduleId: number) : void
  {
    this.spinner.show();
      const statusModel: SolicitudeNotificationUserStatusModel = new SolicitudeNotificationUserStatusModel(
        id,
        true
      );
    this.solicitudeService.putStatusSolicitudeNotificationUser(id, statusModel)
      .subscribe(
        (response) => {
          if (response.status) {
            this.findNotificationByModule(moduleId);
          } else {
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? ErrorMessage.ERROR_UPDATE_NOTIFICATION : ErrorMessage.ERROR_UPDATE_NOTIFICATION_TRANSLATE);
          }
        },
        (error) => {
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
   * @description This method shows the toast control.
   * @author Priscy Antonio Reales
   * @sprint 6
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
   showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }
}