import { IDataRange } from '@components/commercial-activity-footer/interfaces/data-range.interface';
import { ICommercialActivitySector } from '../../interfaces/commercial-activity-sector.interface';
import { ICustomer } from '../../promotional-activities-create/interfaces/customer-list.interface';
import { IChannel } from '../interfaces/channel-interface';
import { ICommercialActivityDiscount } from '../interfaces/commercial-activity-discount.interface';
import { IProductGroupList } from '../interfaces/product-group-list.interface';
import { ICommercialActivityCustomerJson } from './../../interfaces/commercial-activity-custommer-json.interface';
import { ICommercialActivityHandler } from '@app/@components/handling-product-filter/interfaces/commercial-activity-handler.interface';
import { ICommercialActivityHandlerPeriod } from '@app/@components/elements/calendar-period/interfaces/handler-period.interface';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';


export class CommercialActivityDiscountModel implements ICommercialActivityDiscount {

    constructor(
			public typeCommercialActivityDomainId?: number,
			public typePromotionalActivityDomainId?: number,
			public statusDomainId?: number,
			public description?: string,
			public startTime?: string,
			public endTime?: string,
			public orderLimit?: number,
			public periodLimit?: number,
			public numberDays?: number,
			public id?: number,
			public purchaseBases?: IProductGroupList[],
			public purchaseBasePromotions?: IProductGroupList[],
			public commercialActivityDateRanges?: IDataRange[],
			public commercialActivityChannels?: IChannel[],
			public isCalculate?: boolean,
			public filterClientJson?: string,
			public commercialActivityCustomers?: ICustomer[],
			public commercialActivityCustomerJson?: ICommercialActivityCustomerJson[],
			public commercialActivitySector?: ICommercialActivitySector[],
			public totalClientsFilter?: number,
			public uidCalculateProcess?: string,
			public isHandler?: boolean,
			public commercialActivityHandler?: ICommercialActivityHandler,
			public commercialActivityHandlerCurrentMonth?: ICommercialActivityHandler,
			public commercialActivityHandlerPeriod?: ICommercialActivityHandlerPeriod[],
			public commercialActivityObservation?: ICommercialActivityDocument[]
    ) { }

}