import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';
export class CriptografiaHelper {

  /**
   * @author Elkin Vasquez Isenia
   * @sprint 1
   * @param value
   * @param secret
   * @returns
   */
  static encrypt(value: string, secret: string) {
    const keySize = 256;
    const salt = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.PBKDF2(secret, salt, {
      keySize: keySize / 32,
      iterations: 100,
    });

    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    const encrypted = CryptoJS.AES.encrypt(value, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    const result = CryptoJS.enc.Base64.stringify(
      salt.concat(iv).concat(encrypted.ciphertext)
    );

    return result;
  }

  /**
   * @author Elkin Vasquez Isenia
   * @sprint 1
   * @param ciphertextB64
   * @param secret
   * @returns
   */
  static decrypt(ciphertextB64: string, secret: string) {
    const key = CryptoJS.enc.Utf8.parse(secret);
    const iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);

    const decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

    /**
   * @author Elkin Vasquez Isenia
   * @sprint 2
   * @param value
   * @returns
   */
     static encryptAes(value: string) {
      const ciphertext = CryptoJS.AES.encrypt(value, environment.crypto.secret).toString();
      return ciphertext;
    }

    /**
     * @author Elkin Vasquez Isenia
     * @sprint 2
     * @param value
     * @returns
     */
    static decryptAes(value: string) {
      const bytes  = CryptoJS.AES.decrypt(value, environment.crypto.secret);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
}
