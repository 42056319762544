import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExlusionBySkuModel } from './models/exclusions-by-sku.model';


@Injectable({
  providedIn: 'root',
})
export class ExclusionsBySkuService {
  private readonly httpService: HttpService;
  private readonly HttpClient: HttpClient;

  public processExclusion = 0;
  public exclusionId = 0;

  constructor(
    httpService: HttpService,
    HttpClient: HttpClient
  ) { 
    this.httpService = httpService;
    this.HttpClient = HttpClient;
  }


  public getListExclusions(): Observable<IResponseService<ExlusionBySkuModel[]>> {
    const response$: Observable<IResponseService<ExlusionBySkuModel[]>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/get_all_exlusiones_by_sku/1/1000`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ExlusionBySkuModel[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public getExclusionWithDetailById(id: number): Observable<IResponseService<ExlusionBySkuModel>> {
    const response$: Observable<IResponseService<ExlusionBySkuModel>> =

     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/get-exclusion-by-sku/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ExlusionBySkuModel>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public postExclusion(
    body: ExlusionBySkuModel
  ): Observable<IResponseService<string[]>> {
    const response$: Observable<IResponseService<string[]>> =
    this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/create_exclusion_by_sku`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<string[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public putExclusion(
    body: ExlusionBySkuModel
  ): Observable<IResponseService<string[]>> {
    const response$: Observable<IResponseService<string[]>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/update_exclusion_by_sku`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<string[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public deleteExclusionSelected(body: Array<any>): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/logic-exclusion-by-sku/all`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public setProcessExclusion(process: number){
    this.processExclusion = process;
  }

  public getProcessExclusion(){
    return this.processExclusion;
  }

  public setExclusionId(exclusionId: number){
    this.exclusionId = exclusionId;
  }

  public getExclusionId(){
    return this.exclusionId;
  }

}
