import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { RegexCommon } from '@app/@shared/enums/regex.enum';
import { Util } from '@app/@shared/util';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommercialActivityNegociationExclusionService } from './commercial-activity-negociation-exclusion.service';
import { IComercialActivityNegociation } from './interfaces/comercial-activity-negociation.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { CommercialActivityNegociationSendModel } from './models/commercial-activity-negociation-send-model';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertResult } from 'sweetalert2';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { MessageService } from 'primeng/api';
import { ApplicationConstants } from '@app/app.constants';

@Component({
  selector: 'app-commercial-activity-negociation-exclusion',
  templateUrl: './commercial-activity-negociation-exclusion.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss','./commercial-activity-negociation-exclusion.component.css'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService]
})
export class CommercialActivityNegociationExclusionComponent implements OnInit {
  private readonly fb: FormBuilder;
  private readonly spinner: NgxSpinnerService;
  private readonly commercialActivityNegociationExclusionService: CommercialActivityNegociationExclusionService;
  private readonly messageService: MessageService;

  public rolePermission: any = RolePermissionEnum;
  public formComercialActivityNegociation: FormGroup;
  public objectComercialActivityNegociationList : IComercialActivityNegociation[];
  public SolicitudeId : number;
  public displayModal = false;

  constructor(
    fb: FormBuilder,
    spinner: NgxSpinnerService,
    commercialActivityNegociationExclusionService: CommercialActivityNegociationExclusionService,
    messageService: MessageService
  ) { 
      this.fb = fb;
      this.spinner = spinner;
      this.commercialActivityNegociationExclusionService = commercialActivityNegociationExclusionService;
      this.messageService = messageService;
  }

  ngOnInit(): void {
    this.initForm();
    this.getAll();
  }

  getAll(){
    this.spinner.show();
    this.commercialActivityNegociationExclusionService.getAllDapper().subscribe(
      (response) => {
        this.objectComercialActivityNegociationList = response.data;
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  delete(id :number  ){
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.commercialActivityNegociationExclusionService.delete(id)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.getAll();
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
      this.spinner.hide();
    });
  }

  getModelAdd() : CommercialActivityNegociationSendModel{
    const model : CommercialActivityNegociationSendModel =
    new CommercialActivityNegociationSendModel (
      this.idCommercialActivity.value
      );
    return model;
  }

  post(){
    this.spinner.show();
     const model = this.getModelAdd();
      this.commercialActivityNegociationExclusionService.post(model)
        .subscribe(
          (response) => {
            if (response.status) {
              this.getAll();
              this.clearContols();
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconInfo,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertSend : SweetAlert.messageAlertSendTranslate);
              this.spinner.hide();
            } else {
              let duplicate = false;
              this.objectComercialActivityNegociationList.forEach((x) => {
                if(x.commercialActivityId == this.idCommercialActivity.value){
                  duplicate = true;
                  return;
                }
              });
              
              const language: string = sessionStorage.getItem('language');
              if(duplicate) {
                this.showToast(SweetAlert.iconError,
                  language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertExclusionNegociationDuplicateValidation : SweetAlert.messageAlertExclusionNegociationDuplicateValidationTranslate);
              }else{
                this.showToast(SweetAlert.iconError,
                  language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertExclusionNegociationValidation : SweetAlert.messageAlertExclusionNegociationValidationTranslate);
              }

              this.spinner.hide();
            }
          },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
  }

  clearContols(): void {
    this.idCommercialActivity.setValue('');
    this.formComercialActivityNegociation.reset();
  }

  public showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  onFocusOut(){
    this.idCommercialActivity.setValue(this.idCommercialActivity.value.trim());
  }

  initForm(): void {
    this.formComercialActivityNegociation = this.fb.group({
      idCommercialActivity: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(RegexCommon.ExpRegOnlyNumber)]]
    });
  }

  get idCommercialActivity(): AbstractControl { return this.formComercialActivityNegociation.get('idCommercialActivity'); }

}
