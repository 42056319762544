export enum ManagementParameterEnum {
    weightedClient = 1,
    weightedVolume = 2,
    weightedIncome = 3,
    weightedEbitdad = 4,
    threshold = 5,
    intervalAutoSave = 6,
    retries = 8,
    timeRetries = 14,
    sectorCommercialActivity = 16,
    intervalYears = 18
}
