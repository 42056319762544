import { ISubGroupItem } from '../../interfaces/sub-group-item.interface';
import { ITableGroupItem } from '../../interfaces/table-group-item.interface';


export class Loyalty implements ITableGroupItem {
    constructor(
        public id?: string,
        public descGroup?: string,
        public unitMeasure?: string,
        public groupByJson?: string,
        public contentGroup?: string,
        public subOptions?: ISubGroupItem[],
        public purcheseUnit?: number,
        public check?: boolean
    ) { }
}
