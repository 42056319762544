import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ErrorMessage {

  public static readonly UNEXPECTED_ERROR_TITLE = 'Error inesperado!';
  public static readonly UNEXPECTED_ERROR_TITLE_TRANSLATE = 'Unexpected error!';
  public static readonly UNEXPECTED_ERROR_DETAIL = 'Hubo un inconveniente al tratar de ejecutar la operación solicitada.';
  public static readonly UNEXPECTED_ERROR_DETAIL_TRANSLATE = 'There was a problem trying to execute the requested operation.';
  public static readonly SERVICE_FAILED = 'Error Servicio';
  public static readonly SERVICE_FAILED_TRANSLATE = 'Service Error';
  public static readonly SERVICE_FAILED_DETAIL = 'Oops!..., ha ocurrido un error con el servicio remoto.';
  public static readonly SERVICE_FAILED_DETAIL_TRANSLATE = 'Oops!..., an error has occurred with the remote service.';
  public static readonly BAD_REQUEST_ERROR_FAILED = 'Error No autorizado (400)';
  public static readonly BAD_REQUEST_ERROR_FAILED_TRANSLATE = 'Unauthorized error (400)';
  public static readonly BAD_REQUEST_ERROR_FAILED_DETAIL = 'Oops!..., Ha ocurrido un error al enviar una petición errónea.';
  public static readonly BAD_REQUEST_ERROR_FAILED_DETAIL_TRANSLATE = 'Oops!..., An error occurred while sending an erroneous request.';
  public static readonly UNAUTHORIZED_ERROR_FAILED = 'Error No autorizado (401)';
  public static readonly UNAUTHORIZED_ERROR_FAILED_TRANSLATE = 'Unauthorized error (401)';
  public static readonly UNAUTHORIZED_ERROR_FAILED_DETAIL = 'Oops!..., Acceso denegado debido a credenciales no válidas.';
  public static readonly UNAUTHORIZED_ERROR_FAILED_DETAIL_TRANSLATE = 'Oops!..., Access denied due to invalid credentials.';
  public static readonly INTERNAL_SERVER_ERROR_FAILED = 'Error Interno Servidor (500)';
  public static readonly INTERNAL_SERVER_ERROR_FAILED_TRANSLATE = 'Internal Server Error (500)';
  public static readonly INTERNAL_SERVER_ERROR_FAILEDDETAIL = 'Oops!..., Ha ocurrido un error interno en el servidor remoto. Si sigue presentando inconvenientes lo invitamos a escalar este caso a la mesa de servicio.';
  public static readonly INTERNAL_SERVER_ERROR_FAILEDDETAIL_TRANSLATE = 'Oops!..., An internal error has occurred on the remote server. If you continue to have problems, we invite you to escalate this case to the service desk.';
  public static readonly ERROR_UPDATE_NOTIFICATION = 'Error al actualizar la notifiación';
  public static readonly ERROR_UPDATE_NOTIFICATION_TRANSLATE = 'Failed to update notification';

  constructor() { }
}
