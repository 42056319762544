import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { PaginationByIdModel } from '@app/@shared/model/pagination-by-id.model';
import { IHierarchyReleaseJson } from './interface/hierarchy-release-json.interface';
import { HierarchyReleaseStatusModel } from './models/hierarchy-release-status.model';

@Injectable({
  providedIn: 'root'
})
export class HierarchyReleaseService {
    private readonly httpService: HttpService;
    private hierarchyReleaseId: number;
    private isJustLook :  boolean;

  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  /**
   * @description This method is to storage id.
   * @author Priscy Antonio Reales
   * @param number
   * @returns none
   * @sprint 7
   */
   setHierarchyReleaseId(id: number) : void {
    this.hierarchyReleaseId = id;
  }

  /**
   * @description This method is to return id storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 7
   */
  getHierarchyReleaseId(): number {
    return this.hierarchyReleaseId;
  }

  /**
   * @description This method is to storage isJustLook.
   * @author Priscy Antonio Reales
   * @param number
   * @returns none
   * @sprint 7
   */
   setIsJustLook(isJustLook: boolean) : void {
    this.isJustLook = isJustLook;
  }

  /**
   * @description This method is to return isJustLook storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 7
   */
  getIsJustLook(): boolean {
    return this.isJustLook;
  }

  /**
   * @description This method is to get all the HierarchyReleaseJson.
   * @author Priscy Antonio Reales
   * @param paginationById : PaginationByIdModel
   * @returns Observable<IResponseService<IHierarchyReleaseJson[]>>
   * @sprint 7
   */
   public getListHierarchyRelease(
    paginationById: PaginationByIdModel
  ): Observable<IResponseService<IHierarchyReleaseJson[]>> {
    const response$: Observable<IResponseService<IHierarchyReleaseJson[]>> =
     this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_hierarchy_release`,
        paginationById
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IHierarchyReleaseJson[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to delete logic.
   * @author Priscy Antonio Reales
   * @param id : string
   * @returns Observable<ResponseService>
   * @sprint 7
   */
   public deleteLogic(id: string): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_release/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to copy.
  * @author Priscy Antonio Reales
  * @param params
  * @returns Observable<ResponseService>
  * @sprint 7
  */
  public copy(body: HierarchyReleaseStatusModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_release/copy`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }
}