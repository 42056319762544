<section>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="pstn breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth/solicitude/'">
              {{ "SolicitudComponent.Title" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ 'PendingSolicitudeComponent.TitleDetail' | translate
            }}</li>
        </ol>
      </nav>
    </div>
  </section>
  
  <section class="pstngestion">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.TitleDetail' | translate }}</h1>
          <br>
        </div>
      </div>
      <div class="col-md-12">
        <h6 class="pstntitle">{{'Request' | translate}} : {{ solicitudeId }}</h6>
        <h6>{{ "SolicitudComponent.Reason" | translate }} : {{reason}}</h6>
      </div>

  
      <!-- <app-assing-clients 
            [showFields]="showFieldClientFilter"
            [validateFilter]="true"
            [requiredFields]="false"
            [filtersEdit]="filtersEdit"
            [isProcess]="isProcess"
            [clientsEdit]="clientsEdit"
            [isCreate]="false"
            >
          </app-assing-clients> -->
    
          <div class="mt-5"></div> 
    
          <div class="container">
            <app-price-certificate #priceCertificate
            [isCreate]="false"
            >
            </app-price-certificate>
          </div>
  
      <div class="row mt-5">
        <div class="pstn_container container-fluid">
  
          <p-table #dt [value]="objectListSolicitude"
                dataKey="id"
                styleClass="p-datatable-customers"
                [paginator]="true"
                [rows]="10"
                [scrollable]="true"
                paginatorPosition="bottom"
                currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
                [rowsPerPageOptions]="[10,25,50,100]"
                scrollHeight="800px"
                [globalFilterFields]="['materialCode', 
                                      'product', 
                                      'zofeDescription',
                                      'currentListPrice', 
                                      'newPriceWithOutTax', 
                                      'differential', 
                                      'currentPsp', 
                                      'newPsp']"
                                      >
              <ng-template pTemplate="caption">
                <div class="p-d-flex">
                  <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search" aria-hidden="true" ></i>
                    <input
                      pInputText
                      type="text"
                      (input)="dt.filterGlobal($event.target.value, 'contains')"
                      placeholder="{{ 'SearchBy' | translate }}" />
                  </span>
                </div>
              </ng-template>
  
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:180px">{{ 'Actions' | translate }}</th>
                  <th style="width:180px" pSortableColumn="materialCode">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.Sku" | translate }}
                    <p-sortIcon field="materialCode"></p-sortIcon>
                    </div>
                  </th>
  
                  <th style="width:230px" pSortableColumn="product">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.MaterialDescription"| translate  }}
                    <p-sortIcon field="product"></p-sortIcon>
                    </div>
                  </th>
  
                  <th style="width:180px" pSortableColumn="zofeDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.Create.ProductType" | translate }}
                    </div>
                  </th>
  
                  <th style="width:180px" pSortableColumn="currentListPrice">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{"SolicitudComponent.CurrentListPrice" | translate }}
                    <p-sortIcon field="currentListPrice"></p-sortIcon>
                  </div>
                  </th>
  
                  <th style="width:180px" pSortableColumn="newPriceWithOutTax">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.PriceProposed" | translate }}
                    <p-sortIcon field="newPriceWithOutTax"></p-sortIcon>
                   </div>
                  </th>

                  <th style="width:180px" pSortableColumn="differential">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'EliminateDifferentialComponent.Differential$' | translate }}
                      <p-sortIcon field="differential"></p-sortIcon>
                    </div>
                  </th>
  
                  <th style="width:180px" pSortableColumn="currentPsp">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.CurrentPsp" | translate }}
                    <p-sortIcon field="currentPsp"></p-sortIcon>
                   </div>
                  </th>
  
                  <th style="width:180px" pSortableColumn="newPsp">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.ProposedPsp" | translate }}
                    <p-sortIcon field="newPsp"></p-sortIcon>
                  </div>
                  </th>
  
                  <th style="width:180px" pSortableColumn="validity">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.Validity" | translate }}
                    <p-sortIcon field="validity"></p-sortIcon>
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex"let-object >
                <tr>
                  <td style="width:180px">
                    <i id="btnUsers"
                        class="uil uil-users-alt enviar m-1"
                        pTooltip="{{object.tooltipClient}}"
                        (click)="users(object)"
                        aria-hidden="true"
                        *ngIf="(rolePermission.create | rolePermission)">
                      </i>
                  </td>
                  <td style="width:180px" >{{materialCodeZeroLess(object.materialCode) }}</td>
                  <td style="width:230px">{{object.product }}</td>
                  <td style="width:180px"> {{ object.zofeDescription }} </td>
                  <td style="width:180px">
                    <div *ngIf="object.currentListPrice >= 1">
                      {{object.currentListPrice | currency: 'USD'}}
                    </div>
                    <div *ngIf="object.currentListPrice === 0">
                      {{ "SolicitudComponent.Priceless" | translate }}
                    </div>
                  </td>
                  <td style="width:180px">{{object.newPriceWithOutTax | currency: "USD" || ""}}</td>
                  <td style="width:180px">{{object.differential}}</td>
                  <td style="width:180px">{{object.currentPsp  | currency: "USD" || ""}}</td>
                  <td style="width:180px">{{object.newPsp | currency: "USD" || ""}}</td>
                  <td style="width:180px">
                    {{object.effectiveDateStart | date: "dd/MM/yyyy"}}- {{object.effectiveDateEnd | date: "dd/MM/yyyy"}}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td *ngIf="validity" colspan="6">{{ "ElementsNotFound" | translate }}</td>
                </tr>
              </ng-template>
            </p-table>
          <!-- Buttons -->
            <div class="row justify-content-between mt-4">
              <div class="mr-3">
                <button id="btnBack" pButton pRipple type="button"
                  label="{{ 'PendingSolicitudeComponent.Back' | translate }}"
                  class="p-button-rounded pstn_secundario w-100"
                  (click)="back()"></button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  <p-dialog [(visible)]="modalUser" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}">

    <ng-template pTemplate="header">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: left;">
        <h5><strong>{{ 'EliminateDifferentialComponent.AssignedClients' | translate}}</strong></h5>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div *ngIf="objectListClient.length > 0">
        <p-table #dt 
        name="gridAdvancedClientFilters" 
        [value]="objectListClient" 
        styleClass="p-datatable-customers">
        <ng-template pTemplate="body" let-object>
          <tr>
            <td>{{object.typeDescription }}</td>
            <td>{{object.contentDescription}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="3">{{ 'ElementsNotFound' | translate }}</td></tr>
        </ng-template>
      </p-table>
      </div>

      <div *ngIf="CSVFile.length > 0" class="row">         
        <div class="col-md-6">
          {{ "AssingClient.TotalRecords" | translate }} &nbsp;
          <strong style="font-size: 22px">{{ totalRecordsDownload }}</strong>
        </div>

        <div class="col-md-4">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            label="{{ 'Download' | translate }}"
            class="p-button-outlined p-button-rounded"
            (click)="fileDownload()"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-8 mt-1"></div>
        <div class="col-md-4 mt-1"> 
          <button pButton 
                  pRipple
                  type="button"
                  label="{{ 'CloseBtn' | translate}}"
                  class="pstn_primario w-100"
                  (click)="cancelUser()">
          </button>
        </div> 
      </div>
    </ng-template>
  </p-dialog>
  
  