<div class="row justify-content-between">

	<!--Encabezado de la tabla-->
	<!-- <div class=" align-self-center" *ngIf="isProcess !== comercialActivityEnum.view">
		<h2 class="pstn_subtitle">{{ 'AdvancedUpCustomerFilter.SelectedFilters' | translate }}</h2>

		{{ 'AdvancedUpCustomerFilter.TitleClearFilter' | translate }}
		<button id="btnDelete" type="button" class="delete btn btn-secundary-delete" (click)="deleteFilters()">
			<i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
		</button>

	</div>

	<div class="float-right">

		<div class="container" *ngIf="isProcess !== comercialActivityEnum.view">
			<button class="pstn_secundario mb-3" (click)="openAdvancedFilters()"
				[disabled]="!isMultiFilter && advancedFiltersObjectList.length > 0">
				<i class="uil uil-filter" aria-hidden="true"></i>
				{{ 'AdvancedRedeemableFilterComponent.AdvancedFilters' | translate }}
			</button>
		</div>


	</div> -->

</div>