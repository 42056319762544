<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ 'ManagePromotionalActivitiesComponent.Title' | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion" >
  <div class="container">
    <div class="row">
      <h1 class="pstntitle">{{ 'ManagePromotionalActivitiesComponent.Title' | translate }}</h1>
    </div>
    <form id="formBusinessActivityManagement" [formGroup]="formBusinessActivityManagement">
      <div class="row mt-5">
        <div class="col-md-4">
          <label>{{ 'ManagePromotionalActivitiesComponent.Description' | translate }}</label>
          <input id="description" formControlName="description" autocomplete="off" class="form-control"
            [ngClass]="{'ng-invalid ng-dirty':description.invalid && description.touched}" 
            required (focusout)="onFocusOut()" pInputText  
            onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) 
                                        || (event.charCode >= 97 && event.charCode <= 122) 
                                        || (event.charCode >= 48 && event.charCode <= 57)
                                        || event.charCode == 32 || event.charCode == 225
                                        || event.charCode == 233 || event.charCode == 237
                                        || event.charCode == 243 || event.charCode == 250)"/>
          <small id="DescriptionDetail"
            *ngIf="formBusinessActivityManagement.controls['description'].invalid &&
            (formBusinessActivityManagement.controls['description'].dirty ||
            formBusinessActivityManagement.controls['description'].touched)"
            class="p-error">{{ 'ManagePromotionalActivitiesComponent.DescriptionMsgValidate' | translate }}</small>
        </div>

        <div class="col-md-4">
          <label>{{ 'ManagePromotionalActivitiesComponent.TypeActivity' | translate }}</label>
          <div class="form-group">
            <p-dropdown id="typeCommercialActivity" [filter]="true" [showClear]="true"
              [ngClass]="{'ng-invalid ng-dirty':typeCommercialActivity.invalid && typeCommercialActivity.touched}"
              formControlName="typeCommercialActivity" optionLabel="label" optionValue="value"
              placeholder="{{ 'ManagePromotionalActivitiesComponent.TypeActivityPlaceHolderCombo' | translate }}"
              [options]="objectPromotionalActivity" (click)="getPromotionalActivity()" [style]="{ width: '100%' }">
            </p-dropdown>
            <small id="TypeCommercialActivityValidator"
              *ngIf="formBusinessActivityManagement.controls['typeCommercialActivity'].invalid &&
              (formBusinessActivityManagement.controls['typeCommercialActivity'].dirty ||
              formBusinessActivityManagement.controls['typeCommercialActivity'].touched)"
              class="p-error">{{ 'ManagePromotionalActivitiesComponent.TypeActivityMsgValidate' | translate }}</small>
          </div>
        </div>

        <div class="col-md-4 mt-4 align-items-end">
          <button id="btnCreate" pButton pRipple type="button" label="{{ 'CreateBtn' | translate }}"
            class="p-button-rounded pstn_primario w-100" (click)="add()"
            [disabled]="!formBusinessActivityManagement.valid" *ngIf="this.isCreate && (rolePermission.create | rolePermission)"></button>
        </div>
      </div>
    </form>

    <div class="mt-3"></div>

    <app-shared-advanced-filter (activitiesListAdvancedFilter)="onFilterAdvancedApplied($event)"
    (advancedFilter)="getAdvancedFilter($event)" (countListAdvancedFilter)="onCountListAdvancedFilter($event)"
    [showFieldsGeneral]="showFieldsGeneral"
    [showFieldsClient]="showFieldsClient"
    [rolePermissionBtn]="true" [totalRow]="rows">

    </app-shared-advanced-filter>

    <form id="formCommercialActivity" [formGroup]="formCommercialActivity" >
      <div class="row mt-5">
        <div class="pstn_container container-fluid">
          
          <!-- PrimeNg Table -->
          <p-table #dt name="gridCommercialActivity" [value]="objectList" dataKey="id" 
            [rowsPerPageOptions]="[10,25,50,100]"
            [paginator]="true" [rows]="10" 
            [lazy]="true"
            (onLazyLoad)="loadCustomers($event)"
            [totalRecords]="pageLinkSize"            
            [(selection)]="selectObject" paginatorPosition="bottom" styleClass="p-datatable-customers"
            currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
            [globalFilterFields]="['id', 'description', 'typeCommercialActivityDomain', 'statusDomain']">
            <ng-template pTemplate="caption"></ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th role="columnheader" style="width: 30px">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <!-- <th style="width: 4rem"></th> -->
                <th pSortableColumn="skuBase" style="width: 0px;visibility:hidden;padding:0;display:none;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                     <!--SKUBase--> 
                  </div>
                </th>
                <th pSortableColumn="skuBasePromotion" style="width: 0px;visibility:hidden;padding:0;display:none;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                      <!--SKUBasePromotion-->
                  </div>
                </th>
                <th pSortableColumn="id" style="width: 70px">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "ManagePromotionalActivitiesComponent.Id" | translate }}
                    <p-sortIcon field="id"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="description" style="width: 120px">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "ManagePromotionalActivitiesComponent.DescriptionTypeActivity" | translate }}
                    <p-sortIcon field="description"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="typeCommercialActivityDomain" style="width: 120px">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "ManagePromotionalActivitiesComponent.TypeActivity" | translate }}
                    <p-sortIcon field="typeCommercialActivityDomain"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="statusDomain" style="width: 120px">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "ManagePromotionalActivitiesComponent.Status" | translate }}
                    <p-sortIcon field="statusDomain"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="statusDomain" style="width: 200px">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "ManagePromotionalActivitiesComponent.DataRange" | translate }}
                    <p-sortIcon field="statusDomain"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 120px">{{ "Actions" | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-object let-expanded="expanded">
              <tr [pSelectableRow]="object" style="height: 40px !important;">
                <td style="width: 30px">
                  <p-tableCheckbox [value]="object"></p-tableCheckbox>
                </td>
                <!-- <td>
                  <button type="button" pButton pRipple [pRowToggler]="object"
                    class="p-button-text p-button-rounded p-button-plain" [icon]="
                  expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                "></button>
                </td> -->
                <td style="width: 0px;visibility:hidden;padding:0;display:none;">{{ object.skuBase }}</td>
                <td style="width: 0px;visibility:hidden;padding:0;display:none;">{{ object.skuBasePromotion }}</td>
                <td style="width: 70px">{{ object.id }}</td>
                <td style="width: 120px">{{ object.description }}</td>
                <td style="width: 120px">
                  {{ 'Domain.Domain-'+object.typeCommercialActivityDomainId  | translate}}
                  <!-- {{ object.typeCommercialActivityDomain }} -->
                </td>
                <td style="width: 120px">
									<i class="pi pi-ban" 
										style="font-size: 1rem; color: red;" 
										pTooltip="No se puede crear la actividad comercial"
                    aria-hidden="true" 
										*ngIf="commercialActivityStatusEnum.Invalid === object.statusDomainId"></i> 
										<i class="pi pi-spin pi-spinner" 
											style="font-size: 1rem; color: #009cdd;"
											pTooltip="La actividad está en proceso de validación"
                      aria-hidden="true" 
											*ngIf="commercialActivityStatusEnum.InValidation === object.statusDomainId"></i>
                      {{ 'Domain.Domain-'+object.statusDomainId  | translate}}
									<!-- {{ object.statusDomain }} -->
								</td>
                <td style="width: 200px;" >
                  <div *ngFor="let dateRange  of object.dataRange " style="display: flex; flex-direction: row; justify-content: center;">
                    <div>
                      {{dateRange.dateStart | date: "dd/MM/yyyy"}}
                    </div>
                    -
                    <div>
                      {{dateRange.dateEnd | date: "dd/MM/yyyy"}}
                    </div>
                  </div>
                </td>              
                <td>
                  <span >
										<i id="btnEdit" 
											class="uil uil-edit Editar" 
                      pTooltip="{{'Edit' | translate}}"
											aria-hidden="true" 
											(click)="edit(object)"
											*ngIf=" commercialActivityStatusEnum.Registered === object.statusDomainId && (rolePermission.update | rolePermission)"></i>                  
                    <i id="btnDelete" 
											class="uil uil-trash-alt eliminar" 
                      pTooltip="{{'Delete' | translate}}"                 
											aria-hidden="true"
											(click)="delete(object.id)"
											*ngIf="  (commercialActivityStatusEnum.Registered === object.statusDomainId) || (commercialActivityStatusEnum.Pending === object.statusDomainId) && (rolePermission.delete | rolePermission)"></i>
                  </span>
                  <button id="btnCopy"
                          type="button"
                          pTooltip="{{'Copy' | translate}}"
                          (click)="copyActivity(object)"      
                          style=" border: none; background: transparent;"                        
                        *ngIf="(rolePermission.update | rolePermission)">
                    <i class="uil uil-copy Editar copiar" aria-hidden="true"></i>
                  </button>
                  <i id="btnEye" 
										aria-hidden="true" 
                    class="uil uil-search-alt Editar"
                    pTooltip="{{'Detail' | translate}}"
										(click)="view(object)"
										*ngIf="(rolePermission.read | rolePermission)"></i>
                  <i id="btnHistory" 
                    class="uil uil-history historico" 
                    aria-hidden="true" 
                    pTooltip="{{'Historical' | translate}}"
                    (click)="history(object.id)"
                    *ngIf="(rolePermission.read | rolePermission)"></i>
                    <span *ngIf="!object.interruptDate && commercialActivityStatusEnum.Valid === object.statusDomainId">
                      <i id="btnEdit" 
                        class="uil uil-pause Editar" 
                        pTooltip="{{'Interrupt' | translate}}"
                        aria-hidden="true" 
                        (click)="interrupt(object)"
                        *ngIf="(rolePermission.update | rolePermission)"></i>
                    </span>
                  <i
                    aria-hidden="true"
                    id="btnMessage"
                    class="uil uil-message enviar"
                    pTooltip="{{ 'ManagePromotionalActivitiesComponent.BtnStatusPlanned' | translate }}"
                    (click)="updateStatus(object, commercialActivityStatusEnum.Planned)"
                      *ngIf="(commercialActivityStatusEnum.Registered === object.statusDomainId && object.typeCommercialActivityDomainId == commercialActivityTypeEnum.Redemption) && (rolePermission.update | rolePermission)"
                  ></i>
                  <i
                    aria-hidden="true"
                    id="btnMessage"
                    class="uil uil-redo enviar"
                    pTooltip="{{ 'ManagePromotionalActivitiesComponent.BtnStatusRegistered' | translate }}"
                    (click)="updateStatus(object, commercialActivityStatusEnum.Registered)"
                      *ngIf="(commercialActivityStatusEnum.Rejected === object.statusDomainId) && (rolePermission.update | rolePermission)"
                  ></i>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-object>
              <tr>
                <td colspan="7">
                  <div class="p-p-3">
                    <!-- <p-table [value]="object.dataRange" [scrollable]="true" scrollHeight="300px" dataKey="id">
                      <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="validity">
                  {{ "ManagePromotionalActivitiesComponent.DataRange" | translate }}
                  <p-sortIcon field="validity"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dataRange>
              <tr>
                <td>
                  {{
                  dataRange.dateStart
                  | date: "dd/MM/yyyy"
                  }}
                  -
                  {{
                  dataRange.dateEnd
                  | date: "dd/MM/yyyy"
                  }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="1">
                  {{ "ElementsNotFound" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table> -->
        </div>
        </td>
        </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
          </tr>
        </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
      </div>
  </div>
  </form>
  </div>
</section>

<p-dialog header="{{ 'ManagePromotionalActivitiesComponent.HeaderModalPromotionalActivitiesHistory' | translate}}"
  [(visible)]="displayModal" [modal]="true" [style]="{ width: '60vw' }" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="content">


  </ng-template>


  <!-- PrimeNg Table -->
  <p-table #dt name="gridCommercialActivityHistory" [value]="objectListCommercialActivityHistory" dataKey="commercialActivityId"
    [paginator]="false" [rows]="10" currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
    [rowsPerPageOptions]="[10, 25, 50, 100]" paginatorPosition="bottom" styleClass="p-datatable-customers"
    [globalFilterFields]="['statusDescription', 'creationUser', 'creationDate','commentary']">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{
            "SolicitudComponent.History.StatusDescription" | translate
            }}
            <p-sortIcon field="statusDescription"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{
            "SolicitudComponent.History.CreationUser"
            | translate
            }}<p-sortIcon field="creationUser"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="creationDate" style="width: 25%;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{
            "SolicitudComponent.History.CreationDate"
            | translate
            }}<p-sortIcon field="creationDate"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="commentary" style="width: 40%;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.History.Commentary"| translate}}<p-sortIcon field="commentary"></p-sortIcon>
          </div>
        </th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body" let-object>
      <tr>
        <td>{{'Domain.Domain-'+object.statusDomainId | translate}}</td>
        <td>{{ object.creationUser }}</td>
        <td>{{ object.creationDate | date: 'short' }}</td>
        <td>{{ object.commentary }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- PrimeNg Table -->


  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'CloseBtn' | translate}}" icon="pi pi-times" class="p-button-text"
      (click)="hide()"></button>
  </ng-template>
</p-dialog>

<p-dialog 
  header="{{ 'ManagePromotionalActivitiesComponent.HeaderModalPromotionalActivitiesInterrupt' | translate}}"
  [(visible)]="displayModalInterrupt" 
  [modal]="true" 
  [style]="{ width: '60vw' }" 
  [baseZIndex]="10000" 
  [draggable]="false"
  [resizable]="false"
  (onHide)="resetModalInterrupt()"
  [formGroup]="formCommercialActivityInterrupt">
  <ng-template pTemplate="content">
    <div class="col-md-auto">
      <label class="p-d-flex p-jc-between p-ai-center">
        <strong>{{ 'ManagePromotionalActivitiesComponent.InterruptionDate' | translate }}</strong>
      </label>
      <p-calendar
        #cal2
        formControlName="dateInterruption"
        dateFormat="dd/mm/yy" 
        [showIcon]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        [showButtonBar]="true"
        [showOtherMonths]="false"
        [yearRange]="getYearRangeInterruption()"
        [required]="true"
        [minDate]="tomorrow"
        [touchUI]="true"
        [style]="{ width: '100%' }"
        [readonlyInput]="true">
        <p-header class="p-2">
          <i
          id="btnClose"
          style="border:none"
          class="uil uil-times enviar"
          (click)="cal2.hideOverlay()"
          aria-hidden="true"></i>
        </p-header>
      </p-calendar>

      <small 
        id="dateInterruption"
        *ngIf="
          formCommercialActivityInterrupt.controls['dateInterruption'].invalid &&
          (formCommercialActivityInterrupt.controls['dateInterruption'].dirty ||
            formCommercialActivityInterrupt.controls['dateInterruption'].touched)
        "
        class="p-error">{{"ManagePromotionalActivitiesComponent.InterruptionDateMsg" | translate}}</small>
    </div>
    <div class="col-md-auto">
      <label class="p-d-flex p-jc-between p-ai-center">
        <strong>{{ 'ManagePromotionalActivitiesComponent.InterruptionReason' | translate }}</strong>
      </label>
      <textarea [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                id="descriptionInterruption"
                formControlName="descriptionInterruption"
                autocomplete="off"
                class="form-control p-d-flex p-jc-between p-ai-center"
                required>
              </textarea>
          <small 
            id="descriptionInterruption"
            *ngIf="
              formCommercialActivityInterrupt.controls['descriptionInterruption'].invalid &&
              (formCommercialActivityInterrupt.controls['descriptionInterruption'].dirty ||
                formCommercialActivityInterrupt.controls['descriptionInterruption'].touched)
            "
            class="p-error">{{"ManagePromotionalActivitiesComponent.InterruptionReasonMsg" | translate}}
          </small>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button 
      pButton 
      pRipple 
      type="button"
      label="{{ 'CloseBtn' | translate}}" 
      icon="pi pi-times" 
      class="p-button-text"
      (click)="hideModalInterrupt()">
    </button>

    <button id="btnSaveInterruption"
            pButton
            pRipple
            type="button"
            label="{{ 'SaveBtn' | translate }}"
            class="pstn_primario"
            (click)="saveInterruption()">
    </button>

  </ng-template>
</p-dialog>


<p-dialog
  [(visible)]="showModalCopy" [modal]="true" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="header">
    <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
      <h5><strong>{{ 'ManagePromotionalActivitiesComponent.TitleCopy' | translate}}: {{idCopy}}</strong></h5>
    </div>
  </ng-template>

  <ng-template pTemplate="content">

		<div class="row">
			<div class="col-md-12">
        <label>{{ 'ManagePromotionalActivitiesComponent.Description' | translate }}:</label>
        <input id="description"
                pInputText
                style="width: 50%;"
                [(ngModel)]="descriptionActivity"
                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) 
                                        || (event.charCode >= 97 && event.charCode <= 122) 
                                        || (event.charCode >= 48 && event.charCode <= 57)
                                        || event.charCode == 32 || event.charCode == 225
                                        || event.charCode == 233 || event.charCode == 237
                                        || event.charCode == 243 || event.charCode == 250)"
                (ngModelChange)="validityDescripcion()"
                required /><br>
                <small id="DescriptionActivity"
                  *ngIf="messageCopy == true"
                  class="p-error">{{ 'ManagePromotionalActivitiesComponent.DescriptionActivityMsgValidate' | translate }}</small>
                  <br><br>
        <label>{{ 'ManagePromotionalActivitiesComponent.Sku' | translate }}: {{sku}}</label>
			</div>
		</div>
    
  </ng-template>
	<ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-6 mt-1">
        <button pButton 
        pRipple
        type="button"
        class="pstn_primario w-100"
        label="{{'CancelBtn' | translate}}"
        (click)="cancelCopy()"
        style="background-color:#e4002b; border-color: #e4002b;">
      </button>
      </div>
      
      <div class="col-md-6 mt-1">
        <button pButton 
        pRipple
        type="button"
        label="{{ 'Copy' | translate}}"
        class="pstn_primario w-100"
        (click)="createCopy()">
      </button>
      </div>
    </div>
     
  </ng-template>
</p-dialog>



<p-toast></p-toast>
