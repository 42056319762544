import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Input, SimpleChange, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { FileBaseHelper } from '@app/@shared/helpers/file-base.helper';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { IAdvancedFiltersClientsResult } from '@app/@shared/interfaces/advanced-filters-client-result.interface';
import { IAdvancedFiltersClient } from '@app/@shared/interfaces/advanced-filters-client.interface';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { AdvancedFiltersClientModel } from '@app/@shared/model/advanced-filters-client.model';
import { DomainService } from '@app/@shared/services/domain.service';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Util } from '@app/@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { AssingClientService } from './assing-clients.service';
import { SolicitudeMessage } from '@app/@modules/prices/solicitude/messages/solicitude.message';
import { SummaryClientModel } from './models/summary-client.model';
import { SummaryClientJsonModel } from './models/summary-client-json.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { IDetailFilterClientsResult } from './interfaces/detail-filter-client-result.interface';
import { DetailFilterClientResultModel } from './models/detail-filter-client-result.model';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { CustomerService } from './services/customer.service';
import { ClientsCsvComponent } from './clients-csv/clients-csv.component';
import { IFieldsClientFilter } from './interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from './models/show-fields-client-filter.model';
import { IFilterClientInfo } from './interfaces/filter-client-info.interface';
import { FilterClientInfoModel } from './models/filter-client-info.model';
import { AdvancedClientFilterComponent } from '../advanced-client-filter/advanced-client-filter.component';
import { ICommercialActivityHandler } from '../handling-product-filter/interfaces/commercial-activity-handler.interface';
import { ICommercialActivityHandlerPeriod } from '../elements/calendar-period/interfaces/handler-period.interface';
import { ActivityHandlerModel } from '../handling-product-filter/models/activity-handler.model';

@Component({
  selector: 'app-assing-clients',
  templateUrl: './assing-clients.component.html',
  styleUrls: ['../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
  providers: [MessageService],
})
export class AssingClientsComponent implements OnInit, AfterViewInit {

  @Input('validateFilter') validateFilter: boolean;
  @Input('filtersEdit') filtersEdit: string;
  @Input('clientsEdit') clientsEdit: string;
  @Input('isProcess') isProcess: number;
  @Input('isCountryRequired') isCountryRequired = false;
  @Input('showFields') showFields : IFieldsClientFilter = new FieldsClientFilterModel(true, true, true);
  @Input('requiredFields') requiredFields: boolean = true;
  @Input('rolePermissionBtn') rolePermissionBtn: boolean;
  @Input('isCreate') isCreate: boolean = true;
  @Input('visibleHideFilterClient') visibleHideFilterClient: boolean = true;
  @Input('commercialActivityId') commercialActivityId: number;
  @Input('isHanlder') isHanlder: boolean = false;
  @Input('commercialActivityHandler') commercialActivityHandler: any;
  @Input('commercialActivityHandlerCurrentMonth') commercialActivityHandlerCurrentMonth: any;
  @Input('commercialActivityHandlerPeriod') commercialActivityHandlerPeriod: any;
  @Input('btnShowEdit') btnShowEdit = false;

  @Output('eventClickSendData') eventButton: EventEmitter<any> = new EventEmitter();
  @Output('eventClickSendDataEdit') eventButtonEdit: EventEmitter<any> = new EventEmitter();
  @Output('eventClickBack') eventButtonBack: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileUploadComponent') fileUpload: ClientsCsvComponent;
  @ViewChild(AdvancedClientFilterComponent) advancedClientFilter: AdvancedClientFilterComponent;



  public filterResult: IAdvancedFiltersClientsResult;
  public detailsResult: IDetailFilterClientsResult[];
  public detailsClientsResult: DetailFilterClientResultModel[];
  public typeFiltersObjectList: ICombo[];
  public filterTypeName = 'ClientListCreation';
  public uploadedFiles: any[] = [];
  public attachedFilePdf: string = undefined;
  public activeAccordionFilter = true;
  public activeAccordionSummary = false;
  public activeAccordionUploadFile = true;
  public filterCLientJson: string;
  public summaryClients: SummaryClientModel[];
  public totalRecords = 0;
  public jsonFilterClient: string;
  public summaryClientsJson: SummaryClientJsonModel[];
  public listClientCsv: string[] = null;
  public isEditFile = false;
  public processEnum: any;
  public isVisibleTotal = false;
  public displayModal = false;
  public listClientCsvDownload: string[] = null;
  public totalRecordsDownload = 0;
  public totalClients = 0;
  public language: string;
  public customerNotMatchProfileMessage: string;
  public fileUploadClear = false;
  public downloadFileClients = true;
  // public btnShowEdit = false;
  public clearCsv = false;

  private readonly spinner: NgxSpinnerService;
  private readonly fileBaseHelper: FileBaseHelper;
  private readonly domainService: DomainService;
  private readonly assingClientService: AssingClientService;
  private readonly router: Router;
  private readonly messageService: MessageService;
  private readonly helper: FileBaseHelper;
  private readonly customerService: CustomerService;
  public rolePermission: any = RolePermissionEnum;
  private readonly changeDetector: ChangeDetectorRef;

  constructor(
    spinner: NgxSpinnerService,
    fileBaseHelper: FileBaseHelper,
    domainService: DomainService,
    assingClientService: AssingClientService,
    router: Router,
    messageService: MessageService,
    helper: FileBaseHelper,
    customerService: CustomerService,
     changeDetector: ChangeDetectorRef
  ) {
    this.spinner = spinner;
      this.fileBaseHelper = fileBaseHelper;
      this.domainService = domainService;
      this.assingClientService = assingClientService;
      this.router = router;
      this.messageService = messageService;
      this.helper = helper;
      this.customerService = customerService;
      this.changeDetector =changeDetector;
  }

  ngOnInit(): void {
    this.processEnum = this.getCommercialActivityEnum();
    this.downloadFileClients = true;
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * @description This method is to wait the event changes
   * in father promotion, and apply the changes in this view
   *
   * @param changes
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const changeFiltersEdit: SimpleChange = changes['filtersEdit'];
    if (changeFiltersEdit && changeFiltersEdit.currentValue) {
      this.activeAccordionSummary = true;
      this.activeAccordionUploadFile = false;
      this.isEditFile = false;
    }
  }


  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  /**
* @description this method handles the event of the child component of delete filters client
* @author Daniel Londoño
* @sprint 11
*/
  eventClickDeleteFiltersClient() {
    this.activeAccordionUploadFile = true;
    if (this.summaryClients !== null && this.summaryClients !== undefined) {
      this.summaryClients = [];
      this.jsonFilterClient = '';
      this.totalRecords = 0;
      this.totalClients = 0;
      this.fileUpload.onRemoveFileImage();
      this.activeAccordionSummary = this.activeAccordionSummary === true ? false : true;
    }
  }

  /**
* @description this method handles the event of the child component of filters client
* @author Daniel Londoño
* @sprint 9
*/
  eventClickFiltersClient($event: any) {
    this.summaryClients = [];
    this.summaryClientsJson = [];
    if ($event.advancedFiltersObjectList.length > 0) {
      this.spinner.show();
      this.activeAccordionSummary = true;
      this.activeAccordionUploadFile = false;
      //TODO
      if(this.isProcess == 2 && $event.advancedFiltersObjectList.length > 0) this.activeAccordionFilter = true;
      if(this.isHanlder) {
        this.advancedFiltersClientsHanlder(JSON.stringify($event.advancedFiltersObjectList));
      }else{
        this.advancedFiltersClients(JSON.stringify($event.advancedFiltersObjectList));
      }
      this.summaryClientsJson = $event.advancedFiltersObjectList;
      this.summaryClientsJson = this.summaryClientsJson.map((item) => {
        return {
          id: item.id,
          type: item.type,
          typeDescription: item.typeDescription,
          content: item.content,
          contentDescription: item.contentDescription,
          operator: item.operator
        };
      });
      this.jsonFilterClient = JSON.stringify(this.summaryClientsJson);
    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(
        language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
        language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS : SolicitudeMessage.NOT_FILTERS_TRANSLATE);
    }
  }
  /**
  * @description This method for get the filters.
  * @author Elkin Vasquez Isenia
  * @sprint 7
  * @param filters
  */
  public advancedFiltersClients(filters: string): void {
    this.totalClients = 0;
    const objFilters: IAdvancedFiltersClient =
      new AdvancedFiltersClientModel(
        filters,
        true,
        1,
        100
      );

    this.assingClientService.postAdvancedFiltersClients(objFilters)
      .subscribe(
        (respose) => {

          this.filterResult = respose.data;

          if (this.filterResult.totalRecords > 0) {
            this.totalClients = this.filterResult.totalRecords;
            const listFilters = this.filterResult.results.map((item) => {
              return {
                typeFilter: item.typeFilter,
                groupClients: item.groupClients,
                codeType: item.codeType,
                labelType: item.labelType,
                total: item.total
              };
            });
            this.fileUpload.uploadClear();
            this.summaryClients = [...listFilters];
            this.totalRecords = this.summaryClients.length;
            this.summaryClients.sort((b, a) => (a.groupClients < b.groupClients) ? 1: - 1);

          } else {
            const language: string = sessionStorage.getItem('language');
             SweetAlertMessageHelpers.error(
              language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
              language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS_RESULT_CLIENTS : SolicitudeMessage.NOT_FILTERS_RESULT_CLIENTS_TRANSLATE);
          }

        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  public advancedFiltersClientsHanlder(filters: string): void {
    this.spinner.show();
    this.totalClients = 0;
    const objFilters: IAdvancedFiltersClient =
      new AdvancedFiltersClientModel(
        filters,
        true,
        1,
        100
      );
      
    const handlerModel = {
      commercialActivityHandler: this.commercialActivityHandler,
      commercialActivityHandlerCurrentMonth : this.commercialActivityHandlerCurrentMonth,
      commercialActivityHandlerPeriod: this.commercialActivityHandlerPeriod
    }

    const handlerRequest = {
      handlerRequest : handlerModel,
      advacedFiltersClient: objFilters
    }

    this.assingClientService.postAdvancedFiltersClientsHandler(handlerRequest)
      .subscribe(
        (respose) => {

          this.filterResult = respose.data;

          if (this.filterResult.totalRecords > 0) {
            this.totalClients = this.filterResult.totalRecords;
            const listFilters = this.filterResult.results.map((item) => {
              return {
                typeFilter: item.typeFilter,
                groupClients: item.groupClients,
                codeType: item.codeType,
                labelType: item.labelType,
                total: item.total
              };
            });
            if(!this.isHanlder) this.fileUpload.uploadClear();
            this.summaryClients = [...listFilters];
            this.totalRecords = this.summaryClients.length;
            this.summaryClients.sort((b, a) => (a.groupClients < b.groupClients) ? 1: - 1);

          } else {
            const language: string = sessionStorage.getItem('language');
             SweetAlertMessageHelpers.error(
              language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
              language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS_RESULT_CLIENTS : SolicitudeMessage.NOT_FILTERS_RESULT_CLIENTS_TRANSLATE);
              this.showFields.enabledExecuteFilters = true;
              this.showFields.enabledFilterAdvanced = true;
          }

        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
* @description This method for back solicitude and materials.
* @author Daniel Londoño
* @sprint 11
*/
  public back(): void {
    this.btnShowEdit = false;
    this.eventButtonBack.emit();
  }

  /**
* @description This method for send data JSon.
* @author Daniel Londoño
* @sprint 11
*/
  public sendData(): void {
    Util.confirmSave().then((result: SweetAlertResult) => {
      if(this.totalClients <= 0 && this.totalRecords <= 0){
        const language: string = sessionStorage.getItem('language');
        this.showToast(
          SweetAlert.iconError,
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? SweetAlert.messageAlertValidationClients:SweetAlert.messageAlertValidationClientsTranslate);

      } else {
        if (result.value) {
          const filterClientInfo = this.getFilterClientInfo();
          this.eventButton.emit(filterClientInfo);
        }
      }
    });
  }

  public sendDataEdit(): void {
    Util.confirmEdit().then((result: SweetAlertResult) => {
      if (this.summaryClients === null && this.totalRecords === 0) {
        this.totalClients = 0;
        this.totalRecords = 0;
        this.totalRecordsDownload = 0;
      }

      if(this.totalClients <= 0 && this.totalRecords <= 0 && this.totalRecordsDownload <= 0){
        const language: string = sessionStorage.getItem('language');
        this.showToast(SweetAlert.iconError,
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? SweetAlert.messageAlertValidationClients: SweetAlert.messageAlertValidationClientsTranslate);
      } else {
        if (result.value) {
          const filterClientInfo = this.getFilterClientInfo();
          this.eventButtonEdit.emit(filterClientInfo);
        }
      }
    });
  }

  /**
  * @description This method shows the toast control.
  * @author Daniel Londono Ortega
  * @sprint 8
  * @param severity
  * @param message
  * @param detail
  * @returns none
  */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  /**
   * @description This method is to download csv file
   *
   * @author Diego Mauricio Cortés
   * @sprint 11
   */

  getFilterClientInfo(isSearchFilterClient = false) : IFilterClientInfo {
    if(isSearchFilterClient){
      this.jsonFilterClient = this.advancedClientFilter.advancedFiltersObjectList.length === 0 ?
       '' : JSON.stringify(this.advancedClientFilter.advancedFiltersObjectList);
    }

    const filterClientInfo = new FilterClientInfoModel (
      this.jsonFilterClient,
      this.listClientCsv === null ? this.listClientCsvDownload : this.listClientCsv,
      this.totalClients === 0 ? this.totalRecords : this.totalClients
    );

    return filterClientInfo;
  }

  getActiveAccordionFilter(activeAccordionFilter : boolean){
    this.summaryClients = [];
    this.jsonFilterClient = '';
    if(activeAccordionFilter) this.listClientCsv = [];
    if(this.advancedClientFilter) this.advancedClientFilter.advancedFiltersObjectList = [];
    this.activeAccordionFilter = activeAccordionFilter;
  }

  getListClientCsvData(listClientCsvData : string[]){
    this.listClientCsv = listClientCsvData;
    this.totalRecords = this.listClientCsv.length;
  }

  getTotalRecordsDownload(totalRecordsDownload : number){
    this.totalRecordsDownload = totalRecordsDownload;
  }

  evenBtnShowEdit(event : any){
    this.btnShowEdit = event;
  }

  evenclearCsv(event : any){
    this.clearCsv = event;
  }
}
