import { CommonConfiguration } from '../enums/common.enums';
import { CriptografiaHelper } from './criptografia.helper';

export class SessionStorageHelper {

  static save(key: string, value: string): void {
    if(key !== CommonConfiguration.msalToken){
      sessionStorage.setItem(key, CriptografiaHelper.encryptAes(value));
    }
    else{
      sessionStorage.setItem(key, value);
    }
  }

  static get(key: string): string {
    let result = '';
    const value = sessionStorage.getItem(key);
    if(value !== null && value !== undefined){
        if(key !== CommonConfiguration.msalToken){
            result = CriptografiaHelper.decryptAes(value);
        }
        else{
          result = value;
        }

    }
    return result;
  }

  static remove(key: string): void {
    sessionStorage.removeItem(key);
  }

  static removeAll(): void {
    sessionStorage.removeItem(CommonConfiguration.Authenticated);
    sessionStorage.removeItem(CommonConfiguration.UserSession);
    sessionStorage.removeItem(CommonConfiguration.Photo);
    sessionStorage.removeItem(CommonConfiguration.Profile);
    sessionStorage.removeItem(CommonConfiguration.Email);
    sessionStorage.removeItem(CommonConfiguration.FirstLoad);
  }
}
