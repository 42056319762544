import { IUpdateStatus } from '@app/@shared/interfaces/update-status.interface';

export class HierarchyReleaseStatusModel implements IUpdateStatus<number,string> {
    /**
   * @author Priscy Antonio Reales
   * @sprint 7
   * @description Model for update status
   */
  constructor(
    public id : number,
    public status: string,
    public commentary : string,
  ) {}
}