import { IAccessManagementRequest } from '../interfaces/access-management-request.interface';

export class AccessManagementRequestModel implements IAccessManagementRequest {
  /**
   * @author Priscy Antonio Reales
   * @sprint 1
   */
  constructor(
    public id: string,
    public name: string,
    public lastName: string,
    public email: string,
    public domainId: number,
    public profileId:number,
    public identificationNumber: string,
    public jobTitle: string,
    public roleId: number[],
    public country: string[],
    public society: string[],
    public sector: string[],
    public region: string[],
    public cedi: string[],
    public channel: string[],
    public salesForce: string[],
    public salesBoss: string[],
    public supervisor: string[],
    public representative: string[],
    public macrosegmento: string[],
    public subTypology: string[],
    public purchaseMission: string[],
    public typology: string[],
    public hierarchyOne: string[],
    public hierarchyTwo: string[],
    public hierarchyApprovalLevelId: number,
    public jobTitleList?: string[]
  ) {}

}
