import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegexCommon } from '@app/@shared/enums/regex.enum';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { MessageService } from 'primeng/api';

import { SweetAlertResult } from 'sweetalert2';
import { SweetAlert } from '../../@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '../../@shared/helpers/sweet-alert-message.helper';
import { Util } from '../../@shared/util';
import { IFilters } from './interfaces/filters.interface';
import { FiltersModel } from './models/filters.model';

@Component({
  selector: 'app-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedFiltersComponent implements OnInit {

  @Input('typeFiltersObjectList') typeFiltersObjectList: ICombo[];
  @Input('filterTypeName') filterTypeName: string;
  @Output('eventClickFilters') eventButton: EventEmitter<any> = new EventEmitter();

  public advancedFiltersObjectList: IFilters[] = [];
  public formAdvancedFilters: FormGroup;
  public isAdd = true;
  public id: string;
  public index: number;

  constructor(
    private readonly fb: FormBuilder,
  ) { }


  ngOnInit(): void {
    this.initForm();
  }

  /**
   * @description this method is to add filter.
   * @author Elkin Vasquez Isenia
   * @sprint 4
   */
  add(): void {
    if (this.formAdvancedFilters.valid) {
      const resultType = this.advancedFiltersObjectList.filter((t) => t.type === this.type.value);
      if (resultType.length > 0) {
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? SweetAlert.messageFilterAdd : SweetAlert.messageFilterAddTranslate);
        return;
      }

      const typeDescription = this.typeFiltersObjectList.filter((t) => t.value === this.type.value).map((m) => m.label);
      const objFilter = new FiltersModel(
        Util.newGuid(),
        this.type.value,
        typeDescription[0],
        this.content.value
      );

      this.advancedFiltersObjectList.push(objFilter);
      this.formAdvancedFilters.reset();

    } else {
      this.formAdvancedFilters.markAllAsTouched();
    }
  }


  /**
   * @description this method is to edit.
   * @author Elkin Vasquez Isenia
   * @sprint 4
   * @param rowIndex
   * @param objectValue
   */
  edit(rowIndex: number, objectValue: IFilters): void {
    Util.confirmEdit().then((result: SweetAlertResult) => {
      if (result.value) {
        this.id = objectValue.id;
        this.index = rowIndex;
        this.isAdd = false;
        const resultType = this.advancedFiltersObjectList
          .filter((t) => t.id === objectValue.id)
          .map((m) => {
            return {
              label: m.type,
              value: m.content
            };
          });
        this.type.disable();
        this.type.setValue(resultType[0].label);
        this.content.setValue(resultType[0].value);
      }
    });
  }

  /**
  * @description this method is to update role.
  * @author Elkin Vasquez Isenia
  * @sprint 4
  */
  update(): void {
    if (this.formAdvancedFilters.valid) {
      this.isAdd = true;
      const resultType = this.advancedFiltersObjectList.filter((t) => t.id === this.id);
      if (resultType.length > 0) {
        this.advancedFiltersObjectList[this.index] = new FiltersModel(
          this.id,
          resultType[0].type,
          resultType[0].typeDescription,
          this.content.value
        );
        this.id = null;
        this.index = null;
        this.type.enable();
      }
      this.formAdvancedFilters.reset();
    } else {
      this.formAdvancedFilters.markAllAsTouched();
    }
  }

  /**
   * @description this method is to delete by id.
   * @author Elkin Vasquez Isenia
   * @param objectValue
   */
  delete(objectValue: IFilters) {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.isAdd = true;
        const resultType = this.advancedFiltersObjectList.filter((t) => t.id !== objectValue.id);
        this.advancedFiltersObjectList = [...resultType];
        this.id = null;
        this.index = null;
        this.type.enable();
        this.formAdvancedFilters.reset();
      }
    });
  }

  /**
   * @description this method is to delete all.
   * @author Elkin Vasquez Isenia
   */
  deleteFilters() {
    this.advancedFiltersObjectList = [];
  }

  /**
   * @description this method is to emit event the father.
   * @author Elkin Vasquez Isenia
   */
  eventClickFilters($event: any): void {
    const filterInfo = {
      filterTypeName: this.filterTypeName,
      advancedFiltersObjectList: this.advancedFiltersObjectList
    };
    this.eventButton.emit(filterInfo);
  }

  /**
  * @description this method is to initialize the form.
  * @author Elkin Vasquez Isenia
  * @sprint 1
  */
  initForm(): void {
    const maxLength = 50;
    this.formAdvancedFilters = this.fb.group({
      type: [{ value: [], disabled: false }, Validators.required],
      content: [{ value: '', disabled: false }, [
        Validators.required,
        Validators.maxLength(maxLength),
        Validators.pattern(RegexCommon.ExpRegOnlyCaracterFilters)
      ]],
    });
  }

  get type(): AbstractControl { return this.formAdvancedFilters.get('type'); }
  get content(): AbstractControl { return this.formAdvancedFilters.get('content'); }
}
