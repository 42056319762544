import { ISolicitude } from '../interfaces/solicitude.interface';
export class SolicitudeModel implements ISolicitude {
  /**
   * @author djgil
   * @sprint 4
   *
   * @authorUpdate Priscy Antonio Reales
   * @sprint 5
   */
  constructor(
    public id: number,
    public typeDomainId: number,
    public statusDomainId: number,
    public code: number,
    public codeCreation : string,
    public filterClientJson?: string,
    public message?: string
  ) {}
}
