<header class="pstn_principal">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="'/auth/select-profile-module'">
      <img src="assets/images/logo.svg" alt=""/>
    </a>
    <div id="user">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <div class="figure">
              <figure>
                <img [src]="photo" alt="avatar" class="img-fluid"/>
              </figure>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item">
              <h5>{{ this.userName }}</h5>
              <h6>{{ this.profile }}</h6>
            </a>
            <a class="dropdown-item" (click)="onProfileChange()">
              {{'ChangeProfile' | translate}}
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="icons">
      <i
        class="uil uil-bell"
        (click)="isVisibleNotification = true"
        aria-hidden="true"
        id="notification">
      </i>
    </div>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse " id="navbarSupportedContent" >
      <ul class="navbar-nav mr-auto dropmenu" >
        <li class="nav-item dropdown" *ngIf="isMenu">
          <a
            class="nav-link dropdown-toggle menuprincipal"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            {{'MenuMain' | translate}} - {{'Menu.MenuMain.menu-'+moduleId | translate}}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <div *ngFor="let item of (listOperation || [])">
                <a class="dropdown-item" (click)="sendRoute(item.route)">
                  {{'Menu.MenuOperation.operation-'+item.operationId | translate}}</a>
              </div>
          </div>
        </li>
      </ul>

      <div class="user">
        <figure>
          <img [src]="photo" alt="avatar" class="img-fluid"/>
        </figure>
        <div>
          <h4>{{ this.userName }}</h4>
          <h5>{{ this.profile }}</h5>
        </div>
        <div class="collapse navbar-collapse m-0" id="navbarSupportedContent">
          <ul class="navbar-nav m-0">
            <li class="nav-item dropdown m-2">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
              </a>
              <div class="dropdown-menu " aria-labelledby="navbarDropdown">
                <a class="dropdown-item" (click)="onProfileChange()">
                  {{'ChangeProfile' | translate}}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
       <br>

      <div class="language">
        <p-tag
            styleClass="p-mr-2"
            severity="primary"
            value="{{ environmentName }}">
          </p-tag>

          <div class="d-flex align-items-center">
            <app-language-selector
              inNavbar="true"
              menuClass="dropdown-menu dropdown-menu-right">
            </app-language-selector>
          </div>
      </div>

      <div class="icons">
        <i class="uil uil-bell"
          (click)="isVisibleNotification = true"
          aria-hidden="true"></i>
        <i class="uil uil-signout"
           aria-hidden="true"
           (click)="logout()"></i>
      </div>

      
    </div>
  </nav>
  <app-notification [isVisible]="isVisibleNotification"
                    (eventHidden)="eventHiddenNotificacion($event)"></app-notification>
</header>
