import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IImpactAnalysis, IImpactAnalysisChartObject } from '@app/@modules/prices/solicitude/interfaces/impact-analysis.interfaces';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html'
})
export class DoughnutChartComponent implements IImpactAnalysisChartObject, OnInit {
  @Input() chartData: IImpactAnalysis;
  @Output() eventEmitter = new EventEmitter();
  @Input() filterValue: any;
  @Input() toSendCalculatedValue: any;
  public subscription: any;
  public subscriptionSendCalculatedValue: any;
  private readonly changeDetector: ChangeDetectorRef;
  data: any;
  options: any;
  public percentageValue: any = 0;


  constructor(changeDetector: ChangeDetectorRef)
  {
    this.changeDetector = changeDetector;
  }

  ngOnInit(): void {
    this.toCreateDoughnutData(this.chartData.description, '', this.chartData.chartProps.percentage);
    this.options = {
      cutout: '100',
      legend: { display: false },
      tooltips: {
        filter: function (tooltipItem: any, data: any) {
          const label = data.labels[tooltipItem.index];
          if (label === '') {
            return false;
          } else {
            return true;
          }
        },
        callbacks: {
          label: function (t: any, d: any) {
            const xLabel = d.labels[t.datasetIndex];
            const dataLabel = d.datasets[t.datasetIndex].data[t.datasetIndex];
            return `${xLabel}:${dataLabel >= 100 ? 100 : new Number(dataLabel).toPrecision(2)}%`;
          }
        }
      }
    };
    if (this.chartData.dependent) {
      this.subscription = this.filterValue.subscribe((dataFilter: any) => {
        const { amount, percentage } = dataFilter || {};
        this.toCreateDoughnutData(this.chartData.description, '', percentage);
        this.eventEmitter.emit({ amount, percentage });
      });
    }
    if (this.chartData.toUpdate) {
      this.subscriptionSendCalculatedValue = this.toSendCalculatedValue.subscribe((valueCalculate: any) => {
        this.toCreateDoughnutData(this.chartData.description, '', valueCalculate);
      });
    }
  }

  ngAfterViewChecked() {
    // your code to update the model
    this.changeDetector.detectChanges();
  }

  toCreateDoughnutData(labelA: string, labelB: string, percentage: number) {
    if (percentage) {
      let percentageToConvert = (percentage * 100);
      percentageToConvert = percentageToConvert >= 100 ? 100 : percentageToConvert;
      const procentageLeftover = 100 - percentageToConvert;
      this.percentageValue = this.getFirstTwoDecimal(new Number(percentageToConvert).toPrecision(2), 0);
      this.data = {
        labels: [labelA, labelB],
        datasets: [
          {
            data: [this.percentageValue, procentageLeftover],
            backgroundColor: this.chartData?.chartStyle && this.chartData?.chartStyle?.backgroundColor ? this.chartData?.chartStyle.backgroundColor : [
              '#FF6384',
              '#f7f7f7'
            ],
            hoverBackgroundColor: this.chartData?.chartStyle && this.chartData?.chartStyle?.backgroundColor ? this.chartData?.chartStyle.backgroundColor : [
              '#FF6384',
              '#f7f7f7'
            ],
          }]
      };
    }

  }

  public getFirstTwoDecimal(num: any, decimal: any = 2) {
    if (num) {
      return (Math.round(num * 100) / 100).toFixed(decimal);
    }
    return;
  }

  ngOnDestroy() {
    this.subscription !== undefined && this.subscription.unsubscribe();
    this.subscriptionSendCalculatedValue !== undefined && this.subscriptionSendCalculatedValue.unsubscribe();
  }

  parsingNumber(number: number) {
    return number >= 100 ? 100 : new Number(number).toPrecision(2);
  }

}
