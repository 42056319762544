import { IAssociateProfile } from '../../associate/interfaces/associate-profile.interface';
import { AssociateUserUpdateModel } from '../../associate/models/associate-user-update.model';

export class HierarchyReleaseAssociateModel implements IAssociateProfile {

  /**
   * @author Jhon Steven Pavón Bedoya
   * @param id
   * @param description
   * @param ApprovalRankLevelId
   * @param OperatorStart
   * @param RankStart
   * @param OperatorEnd
   * @param RankEnd
   * @param UnitMeasurementId
   * @param UnitMeasurementDescription
   * @param associateUsers
   * @sprint 7
   */
    constructor(
      public id: number,
      public description: string,
      public ApprovalRankLevelId: number,
      public OperatorStart: string,
      public RankStart: number,
      public OperatorEnd: string,
      public RankEnd: number,
      public UnitMeasurementId: number,
      public UnitMeasurementDescription: string,
      public associateUsers: AssociateUserUpdateModel[]
  ) {}
}
