import { ISocietyRegion } from '../interfaces/society-region.interface';
import { CoreMasterModel } from './core-master.model';

export class SocietyRegionModel implements ISocietyRegion {
  constructor(
    public societyCode: any,
    public societyDescription: string,
    public regions : CoreMasterModel[]
  ){}
}
