import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { IUserName } from '@app/@shared/interfaces/user-name.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly httpService: HttpService;

  constructor(
    httpService: HttpService
  ) { 
    this.httpService = httpService;
  }

  public getPing(): Observable<IResponseService<boolean>> {
    let response$: Observable<IResponseService<boolean>>;
    response$ = this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/utilities/ping`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to get photo user of Graph Azure Directory.
   * @author Priscy Antonio Reales
   * @param params
   * @returns Observable<IResponseService<string>>
   * @sprint 33
   */
   public getUserPhoto(userName: IUserName): Observable<IResponseService<string>> {
    const response$: Observable<IResponseService<string>> = this.httpService
    .post(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/graph_active_directory/user_photo`, userName)
      .pipe(
        map((response: HttpResponse<IResponseService<string>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
