<div class="row justify-content-between">

  <div class=" align-self-center" *ngIf="isProcess !== comercialActivityEnum.view">
    <h2 class="pstn_subtitle">{{ 'HierarchyAdvancedFiltersComponent.SelectedFilters' | translate }}</h2>
    {{ 'HierarchyAdvancedFiltersComponent.TitleClearFilter' | translate }}
    <button id="btnDelete" type="button" pTooltip="{{'Deletefilter' | translate}}"
     class="delete btn btn-secundary-delete" 
     (click)="deleteFilters()"
    *ngIf="rolePermission.delete | rolePermission">
      <i class="uil uil-trash-alt eliminar"  aria-hidden="true"></i>
    </button>
  </div>

  <div class="float-right">

    <div class="container" *ngIf="isProcess !== comercialActivityEnum.view && (rolePermissionAux | rolePermission)">
      <button class="pstn_secundario mb-3" (click)="openAdvancedFilters()"
        [disabled]="!isMultiFilter && advancedFiltersObjectList.length > 0">
        <i class="uil uil-filter" aria-hidden="true"></i>
        {{ 'HierarchyAdvancedFiltersComponent.AdvancedFilters' | translate }}
      </button>
    </div><!-- container -->

    <!-- Filter Menu -->
    <p-sidebar [(visible)]="isVisible" position="right" [baseZIndex]="10000" [dismissible]="false"
      [closeOnEscape]="false" styleClass="p-sidebar-md" (onHide)="closeAdvancedFilters()"
      [style]="{'overflow-x': 'scroll'}">

      <div class="modal-header" *ngIf="isProcess !== comercialActivityEnum.view">
        <div class="row justify-content-between">
          <h5 class="modal-title float-left" id="myModalLabel2">
            <strong>{{ 'HierarchyAdvancedFiltersComponent.AdvancedFilters' | translate }}</strong>
          </h5>
        </div>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <button pButton pRipple type="button"
            pTooltip="{{'Deletefilter' | translate}}" icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
              (click)="clearFilters(false)"></button>
          </div>

          <div class="col-md-8" style="text-align: right;">
            <p-button label="{{ 'HierarchyAdvancedFiltersComponent.AddFilterBtn' | translate }}"
              icon="pi pi-plus-circle" styleClass="p-button" (click)="addFilter()" *ngIf="!toEditFilter"></p-button>
            <p-button label="{{ 'HierarchyAdvancedFiltersComponent.EditFilterBtn' | translate }}"
              icon="pi pi-plus-circle" styleClass="p-button" (click)="addFilter()" *ngIf="toEditFilter"></p-button>
          </div>
        </div>

        <div *ngIf="enableType && materialTypeFilterList.length > 1">
          <p-divider align="center" type="dashed">
            <span>{{ 'HierarchyAdvancedFiltersComponent.MaterialType' | translate }}</span>
          </p-divider> 
  
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="materialType">{{ 'HierarchyAdvancedFiltersComponent.MaterialType' | translate }}</label><br />
              <p-dropdown 
                id="materialType" 
                [options]="materialTypeFilterList"
                [(ngModel)]="materialTypeSelected"
                placeholder="{{ 'HierarchyAdvancedFiltersComponent.MaterialTypeSelect' | translate }}" 
                [virtualScroll]="true"
                optionLabel="label" 
                optionValue="value" 
                [style]="{'width': '100%'}"
                (onChange)="changeMaterialType()">
              </p-dropdown>
            </div>
          </div>
        </div>

        <p-divider align="center" type="dashed">
          <span>{{ 'HierarchyAdvancedFiltersComponent.HierarchyFilter' | translate }}</span>
        </p-divider>

        <div class="row" *ngIf="!enableType || materialTypeSelected">
          <div class="col-md-12 form-group">
            <label for="category">{{ 'HierarchyAdvancedFiltersComponent.Category' | translate }}</label><br />
            <p-dropdown *ngIf="!isMultiFilter || toEditFilter" id="category" [options]="categoriesFilterList"
              [(ngModel)]="categorySelected"
              placeholder="{{ 'HierarchyAdvancedFiltersComponent.CategorySelect' | translate }}" [virtualScroll]="true"
              [filter]="true" optionLabel="label" optionValue="value" [style]="{'width': '100%'}"
              (onChange)="getFilters_HierarchyMaterials(2)"></p-dropdown>

            <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" id="category"
              class="multiselect-custom-virtual-scroll" [virtualScroll]="true" [options]="categoriesFilterList"
              [(ngModel)]="categoryMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.CategorySelect' | translate }}" optionLabel="label"
              optionValue="value" display="chip" [style]="{'width': '100%'}" [resetFilterOnHide]="true"
              (onChange)="getFilters_HierarchyMaterials(2)"></p-multiSelect>

          </div>
        </div>
        <div class="row" *ngIf="(categorySelected !== undefined && 
                categorySelected !== null && categorySelected !== '') || 
                (categoryMultiSelected.length > 0)">
          <!-- #Packing -->
          <div class="col-md-12 form-group">
            <label for="packing">{{ 'HierarchyAdvancedFiltersComponent.Packing' | translate }}</label><br />
            <p-dropdown *ngIf="!isMultiFilter || toEditFilter" id="packing" [options]="packagingFilterList"
              [(ngModel)]="packingSelected"
              placeholder="{{ 'HierarchyAdvancedFiltersComponent.PackingSelect' | translate }}" [virtualScroll]="true"
              [filter]="true" [showClear]="true" optionLabel="label" optionValue="value" [style]="{'width': '100%'}"
              (onChange)="getFilters_HierarchyMaterials(3)"></p-dropdown>

            <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" id="packing" class="multiselect-custom-virtual-scroll"
              [virtualScroll]="true" [options]="packagingFilterList" [(ngModel)]="packingMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.PackingSelect' | translate }}" optionLabel="label"
              optionValue="value" display="chip" [style]="{'width': '100%'}"  [resetFilterOnHide]="true"
              (onChange)="getFilters_HierarchyMaterials(3)"></p-multiSelect>
          </div>
          <!-- #EndPacking -->

          <!-- #Content -->
          <div class="col-md-12 form-group"
            *ngIf="(packingSelected !== undefined && 
            packingSelected !== null && packingSelected !== '') || 
            (packingMultiSelected.length > 0)">
            <label for="content">{{ 'HierarchyAdvancedFiltersComponent.Content' | translate }}</label><br />
            <p-dropdown *ngIf="!isMultiFilter || toEditFilter" id="content" [options]="contentFilterList"
              [(ngModel)]="contentSelected"
              placeholder="{{ 'HierarchyAdvancedFiltersComponent.ContentSelect' | translate }}" [virtualScroll]="true"
              [itemSize]="10" [filter]="true" [showClear]="true" optionLabel="label" optionValue="value"
              [style]="{'width': '100%'}" (onChange)="getFilters_HierarchyMaterials(4)"></p-dropdown>

            <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" id="content" class="multiselect-custom-virtual-scroll"
              [virtualScroll]="true" [options]="contentFilterList" [(ngModel)]="contentMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.ContentSelect' | translate }}" optionLabel="label"
              optionValue="value" display="chip" [style]="{'width': '100%'}" [resetFilterOnHide]="true"
              (onChange)="getFilters_HierarchyMaterials(4)"></p-multiSelect>
          </div>
          <!-- #EndContent -->

          <!-- #Returnability -->
          <div class="col-md-12 form-group"
            *ngIf="(contentSelected !== undefined && 
            contentSelected !== null && contentSelected !== '') ||
            (contentMultiSelected.length > 0)">
            <label for="returnability">{{ 'HierarchyAdvancedFiltersComponent.Returnability' | translate }}</label><br />
            <p-dropdown *ngIf="!isMultiFilter || toEditFilter" id="returnability" 
              [options]="returnabilityFilterList"
              [(ngModel)]="returnabilitySelected"
              placeholder="{{ 'HierarchyAdvancedFiltersComponent.ReturnabilitySelect' | translate }}"
              [virtualScroll]="true" 
              [itemSize]="10" 
              [filter]="true" 
              [showClear]="true" 
              optionLabel="label"
              optionValue="value" 
              [style]="{'width': '100%'}" 
              (onChange)="getFilters_HierarchyMaterials(5)"></p-dropdown>

            <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" 
              id="returnability"
              class="multiselect-custom-virtual-scroll" 
              [virtualScroll]="true" 
              [options]="returnabilityFilterList"
              [(ngModel)]="returnabilityMultiSelected" 
              [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.ReturnabilitySelect' | translate }}"
              optionLabel="label" 
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true" 
              (onChange)="getFilters_HierarchyMaterials(5)"></p-multiSelect>
          </div>
          <!-- #EndReturnability -->

          <!-- #Denomination  -->
          <div class="col-md-12 form-group" 
          *ngIf="(returnabilitySelected !== undefined && 
          returnabilitySelected !== null && returnabilitySelected !== '') || 
            (returnabilityMultiSelected.length > 0)">
            <label for="denomination ">
              {{ 'HierarchyAdvancedFiltersComponent.Denomination' | translate }}
            </label>
            <br />
            <p-dropdown *ngIf="!isMultiFilter || toEditFilter" id="denomination " 
              [options]="denominationFilterList"
              [(ngModel)]="denominationSelected"
              placeholder="{{ 'HierarchyAdvancedFiltersComponent.DenominationSelect' | translate }}"
              [virtualScroll]="true" 
              [itemSize]="10" 
              [filter]="true" 
              [showClear]="true" 
              optionLabel="label"
              optionValue="value" 
              [style]="{'width': '100%'}" 
              (onChange)="getFilters_HierarchyMaterials(6)"></p-dropdown>

            <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" id="denomination"
              class="multiselect-custom-virtual-scroll" [virtualScroll]="true" [options]="denominationFilterList"
              [(ngModel)]="denominationMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.DenominationSelect' | translate }}"
              optionLabel="label" optionValue="value" display="chip" [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true" (onChange)="getFilters_HierarchyMaterials(6)"></p-multiSelect>
          </div> 
          <!-- #EndDenomination -->
        </div>
				
				<div class="alert alert-warning" 
						role="alert"
						*ngIf="!existsOthersFilters && ((categorySelected !== undefined && 
									categorySelected !== null && categorySelected !== '') || 
									(categoryMultiSelected.length > 0))">
					🚧 <b>¡Ten cuidado!</b>, el filtro que estas aplicando no contiene resultados.
				</div>

        <p-divider align="center" type="dashed" *ngIf="existsOthersFilters">
          <span>{{ 'HierarchyAdvancedFiltersComponent.OthersFilters' | translate }}</span>
        </p-divider>

        <div class="row" *ngIf="existsOthersFilters">

          <!-- #ZOFE -->
          <div class="col-md-12 form-group" *ngIf="uenDescriptionFilterList.length > 0">
            <label for="zofeDescription">{{ 'HierarchyAdvancedFiltersComponent.PrimaryOffer' | translate }}</label><br />
            <p-multiSelect 
              id="uenDescription" 
              class="multiselect-custom-virtual-scroll" 
              [virtualScroll]="true"
              [options]="zofeDescriptionFilterList" 
              [(ngModel)]="zofeDescriptionMultiSelected" 
              [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.PrimaryOfferSelect' | translate }}" 
              optionLabel="label"
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              (onChange)="prepareSelectedSubFilter()" [resetFilterOnHide]="true"> 
              (onChange)="prepareSelectedSubFilter()" [resetFilterOnHide]="true"> 
            </p-multiSelect>
          </div>
          <!-- #EndZOFE -->

          <!-- #Code  -->
          <div class="col-md-12 form-group" *ngIf="codeFilterList.length > 0">
            <label for="code ">{{ 'HierarchyAdvancedFiltersComponent.Code' | translate }}</label><br />
            <p-multiSelect id="code" class="multiselect-custom-virtual-scroll" 
              [virtualScroll]="true"
              [options]="codeFilterList" 
              [(ngModel)]="codeMultiSelected" 
              [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.CodeSelect' | translate }}" 
              optionLabel="label"
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              (onChange)="prepareSelectedSubFilter()" 
              [resetFilterOnHide]="true"> 
            </p-multiSelect>
          </div>
          <!-- #EndCode -->

          <!-- #Brand  -->
          <div class="col-md-12 form-group" *ngIf="brandFilterList.length > 0">
            <label for="brand ">{{ 'HierarchyAdvancedFiltersComponent.Brand' | translate }}</label><br />
            <p-multiSelect id="brand" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="brandFilterList" [(ngModel)]="brandMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.BrandSelect' | translate }}" optionLabel="label"
              optionValue="value" display="chip" 
              [style]="{'width': '100%'}" 
              (onChange)="prepareSelectedSubFilter()" 
              [resetFilterOnHide]="true"> 
            </p-multiSelect>
          </div>
          <!-- #EndBrand -->

          <!-- #SubBrand  -->
          <div class="col-md-12 form-group" *ngIf="subBrandDescriptionFilterList.length > 0">
            <label for="subbrand ">{{ 'HierarchyAdvancedFiltersComponent.SubBrand' | translate }}</label><br />
            <p-multiSelect id="subbrand" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="subBrandDescriptionFilterList" [(ngModel)]="subBrandDescriptionMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.SubBrandSelect' | translate }}" optionLabel="label"
              optionValue="value" display="chip" 
              [style]="{'width': '100%'}" 
              (onChange)="prepareSelectedSubFilter()" 
              [resetFilterOnHide]="true"> 
            </p-multiSelect>
          </div>
          <!-- #EndsubBrand -->

          <!-- #CategoryGroup  -->
          <div class="col-md-12 form-group" *ngIf="categoryGroupDescriptionFilterList.length > 0">
            <label for="categoryGroup">{{ 'HierarchyAdvancedFiltersComponent.CategoryGroup' | translate }}</label><br />
            <p-multiSelect id="categoryGroup" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="categoryGroupDescriptionFilterList" [(ngModel)]="categoryGroupDescriptionMultiSelected"
              [filter]="true" defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.CategoryGroupSelect' | translate }}"
              optionLabel="label" 
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndCategoryGroup -->

          <!-- #Composition  -->
          <div class="col-md-12 form-group" *ngIf="compositionDescriptionFilterList.length > 0">
            <label for="composition">{{ 'HierarchyAdvancedFiltersComponent.Composition' | translate }}</label><br />
            <p-multiSelect id="composition" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="compositionDescriptionFilterList" [(ngModel)]="compositionDescriptionMultiSelected"
              [filter]="true" defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.CompositionSelect' | translate }}"
              optionLabel="label" 
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndComposition -->

          <!-- #FranchiseOrOwnBrand  -->
          <div class="col-md-12 form-group" *ngIf="franchiseOrOwnBrandDescriptionFilterList.length > 0">
            <label for="franchiseOrOwnBrand">{{ 'HierarchyAdvancedFiltersComponent.FranchiseOrOwnBrand' | translate
              }}</label><br />
            <p-multiSelect id="franchiseOrOwnBrand" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="franchiseOrOwnBrandDescriptionFilterList"
              [(ngModel)]="franchiseOrOwnBrandDescriptionMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.FranchiseOrOwnBrandSelect' | translate }}"
              optionLabel="label" 
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndFranchiseOrOwnBrand -->

          <!-- #MeasurementUnitCodeBase  -->
          <div class="col-md-12 form-group" *ngIf="measurementUnitCodeBaseFilterList.length > 0">
            <label for="measurementUnitCodeBase">{{ 'HierarchyAdvancedFiltersComponent.MeasurementUnitCodeBase' |
              translate }}</label><br />
            <p-multiSelect id="measurementUnitCodeBase" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="measurementUnitCodeBaseFilterList" [(ngModel)]="measurementUnitCodeBaseMultiSelected"
              [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.MeasurementUnitCodeBaseSelect' | translate }}"
              optionLabel="label" 
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndMeasurementUnitCodeBase -->

          <!-- #IndustrySegment  -->
          <div class="col-md-12 form-group" *ngIf="industrySegmentDescriptionFilterList.length > 0">
            <label for="industrySegmentDescription">{{ 'HierarchyAdvancedFiltersComponent.IndustrySegment' | translate
              }}</label><br />
            <p-multiSelect id="industrySegmentDescription" class="multiselect-custom-virtual-scroll"
              [virtualScroll]="true" [options]="industrySegmentDescriptionFilterList"
              [(ngModel)]="industrySegmentDescriptionMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.IndustrySegmentSelect' | translate }}"
              optionLabel="label" 
              optionValue="value" display="chip" 
              [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndIndustrySegment -->

          <!-- #NameSegment  -->
          <div class="col-md-12 form-group" *ngIf="nameSegmentDescriptionFilterList.length > 0">
            <label for="nameSegmentDescription">{{ 'HierarchyAdvancedFiltersComponent.NameSegment' | translate
              }}</label><br />
            <p-multiSelect id="nameSegmentDescription" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="nameSegmentDescriptionFilterList" [(ngModel)]="nameSegmentDescriptionMultiSelected"
              [filter]="true" defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.NameSegmentSelect' | translate }}"
              optionLabel="label" optionValue="value" 
              display="chip" [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndNameSegment -->

          <!-- #ProductFlavor -->
          <div class="col-md-12 form-group" *ngIf="productFlavorDescriptionFilterList.length > 0">
            <label for="productFlavorDescription">{{ 'HierarchyAdvancedFiltersComponent.ProductFlavor' | translate
              }}</label><br />
            <p-multiSelect id="productFlavorDescription" class="multiselect-custom-virtual-scroll"
              [virtualScroll]="true" [options]="productFlavorDescriptionFilterList"
              [(ngModel)]="productFlavorDescriptionMultiSelected" [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.ProductFlavorSelect' | translate }}"
              optionLabel="label" optionValue="value" 
              display="chip" [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndProductFlavor -->

          <!-- #SegmentSiza -->
          <div class="col-md-12 form-group" *ngIf="segmentSizaDescriptionFilterList.length > 0">
            <label for="segmentSizaDescription">{{ 'HierarchyAdvancedFiltersComponent.SegmentSiza' | translate
              }}</label><br />
            <p-multiSelect id="segmentSizaDescription" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="segmentSizaDescriptionFilterList" [(ngModel)]="segmentSizaDescriptionMultiSelected"
              [filter]="true" defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.SegmentSizaSelect' | translate }}"
              optionLabel="label" optionValue="value" 
              display="chip" [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndSegmentSiza -->

          <!-- #TypeOfPrice -->
          <div class="col-md-12 form-group" *ngIf="typeOfPriceDescriptionFilterList.length > 0">
            <label for="typeOfPriceDescription">{{ 'HierarchyAdvancedFiltersComponent.TypeOfPrice' | translate
              }}</label><br />
            <p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
              [options]="typeOfPriceDescriptionFilterList" [(ngModel)]="typeOfPriceDescriptionMultiSelected"
              [filter]="true" defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.TypeOfPriceSelect' | translate }}"
              optionLabel="label" optionValue="value" 
              display="chip" [style]="{'width': '100%'}" 
              [resetFilterOnHide]="true"
              (onChange)="prepareSelectedSubFilter()"></p-multiSelect>
          </div>
          <!-- #EndTypeOfPrice -->

          <!-- #UEN -->
          <div class="col-md-12 form-group" *ngIf="uenDescriptionFilterList.length > 0">
            <label for="uenDescription">{{ 'HierarchyAdvancedFiltersComponent.UEN' | translate }}</label><br />
            <p-multiSelect 
              id="uenDescription" 
              class="multiselect-custom-virtual-scroll" 
              [virtualScroll]="true"
              [options]="uenDescriptionFilterList" 
              [(ngModel)]="uenDescriptionMultiSelected" 
              [filter]="true"
              defaultLabel="{{ 'HierarchyAdvancedFiltersComponent.UENSelect' | translate }}" 
              optionLabel="label"
              optionValue="value" 
              display="chip" 
              [style]="{'width': '100%'}" 
              (onChange)="prepareSelectedSubFilter()" [resetFilterOnHide]="true"> 
              (onChange)="prepareSelectedSubFilter()" [resetFilterOnHide]="true"> 
            </p-multiSelect>
          </div>
          <!-- #EndUEN -->
          
        </div>
      </div>
    </p-sidebar>
  </div>
</div>

<div class="row">
  <div class="table-responsive">
    <!-- PrimeNg Table -->
    <p-table #dt name="gridAdvancedFilters" [value]="advancedFiltersObjectList" dataKey="hierarchyFilter"
      [paginator]="true" [rows]="10" paginatorPosition="bottom" styleClass="p-datatable-customers"
      [globalFilterFields]="['hierarchyFilter']">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="type">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'HierarchyAdvancedFiltersComponent.Group' | translate }}
              <p-sortIcon field="type"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="content">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'HierarchyAdvancedFiltersComponent.ContentSearch' | translate }}
              <p-sortIcon field="content"></p-sortIcon>
            </div>
          </th>
          <th>{{ 'Actions' | translate }}</th>
        </tr>
        <tr>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
        <tr>
          <td>
            {{object.group}}
          </td>
          <td pTooltip="{{prepareSubFiltersToShowInfoTable(object)}}" tooltipPosition="top">
            {{object.hierarchyFilter}}
          </td>
          <td>
            <button id="btnEdit" *ngIf="isProcess !== comercialActivityEnum.view 
            && (rolePermission.update | rolePermission)" type="button" pTooltip="{{'Edit' | translate}}"
              class="edit btn btn-secundary-editar" pTooltip="{{'Editfilter' | translate}}"
               (click)="edit(rowIndex, object)">
              <i class="uil uil-edit Editar" aria-hidden="true"></i>
            </button>
            <button id="btnDelete" *ngIf="isProcess !== comercialActivityEnum.view 
            && (rolePermission.delete | rolePermission)" pTooltip="{{'Delete' | translate}}" type="button"
              class="delete btn btn-secundary-delete" pTooltip="{{'Deletefilter' | translate}}"
               (click)="delete(rowIndex, object)">
              <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
    <!-- PrimeNg Table -->
  </div>
</div>

<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4"></div>
  <div class="col-md-4 mt-2 align-items-end" *ngIf="isProcess !== comercialActivityEnum.view" >
    <button id="btnRunFilters" pButton pRipple type="button"
      label="{{ 'HierarchyAdvancedFiltersComponent.ExecuteFilter' | translate }}" 
      class="p-button-rounded pstn_primario w-100"
      [disabled]="advancedFiltersObjectList.length === 0" style="width: 100%;"
      (click)="eventClickFilters($event)"></button>
  </div>


</div>