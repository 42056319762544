import { IUserName } from '../interfaces/user-name.interface';

export class UserNameModel implements IUserName {
  /**
   * @author Elkin Vasquez Isenia
   * @sprint 2
   */
  constructor(
    public email: string
    ) {}
}
