<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'">
            <i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/manage-promotional-activities'">
            {{ "ManagePromotionalActivitiesComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "ManagePromotionalActivitiesComponent.Rappel.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container" *ngIf="!nextAssignClients">
    <section id="GeneralData">

      <div class="column">
        <div class="col-md-6">
          <h1 class="pstntitle">
            {{ "ManagePromotionalActivitiesComponent.Rappel.Title" | translate }}
          </h1>
        </div>
        <div class="col-md-6" *ngIf="isProcess == commercialActivityEnum.view || isProcess == commercialActivityEnum.update">
          <h6 class="pstntitle">{{'Comercialactivity' | translate}} : {{ commercialActivityId }}</h6>
        </div>
      </div>

      <form id="formCommercialRappel" [formGroup]="formCommercialRappel">

        <!-- GENERAL DATA -->
        <div class="row mt-5">

          <!-- description -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.Description" | translate }}</label>
              <input #user id="description" formControlName="description" autocomplete="off"
                placeholder="{{'ManagePromotionalActivitiesComponent.Create.DescriptionPlaceHolderInput' | translate}}"
                class="form-control" pInputText requerid />
            </div>
          </div>

          <!-- type commercial activity -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.TypeCommercialActivity" | translate }}</label>
              <input #user id="typeCommercialActivity" formControlName="typeCommercialActivity" autocomplete="off"
                class="form-control" pInputText />
            </div>
          </div>

          <!-- Channel -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.Channel" | translate }}</label>
              <div class="form-group">
                <p-multiSelect id="channelRappel" 
                  class="multiselect-custom-virtual-scroll multitooltip" 
                  [virtualScroll]="true"
                  [options]="objectChannelList" 
                  [filter]="true" 
                  formControlName="channelRappel"
                  placeholder="{{'ManagePromotionalActivitiesComponent.Create.ChannelPlaceHolderCombo' | translate}}"
                  optionLabel="label" 
                  optionValue="value" 
                  display="chip" 
                  [style]="{width: '100%'}">
                </p-multiSelect>
                  <small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
                    <ul>
                    <li *ngFor="let index of channelTooltip">{{index.channelDescription}}</li>
                    </ul>
                    </small>
                  <small id="channelValidator" *ngIf="
                formCommercialRappel.controls['channelRappel'].invalid &&
                (formCommercialRappel.controls['channelRappel'].dirty ||
                formCommercialRappel.controls['channelRappel'].touched)"
                  class="p-error">{{"ManagePromotionalActivitiesComponent.Create.ChannelMsgValidate"|
                  translate}}</small>
              </div>
            </div>
          </div>

          <!-- Sector -->
          <div class="form-check col-md-4" *ngIf="sectorCommercialActivityParam">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.Sector" | translate }}</label>
              <div class="form-group">
                <p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll multitooltip"
                  [virtualScroll]="true" [options]="objectSectorList" formControlName="sector" [filter]="true"
                  placeholder="{{'ManagePromotionalActivitiesComponent.Create.SectorPlaceHolderCombo' | translate}}"
                  optionLabel="label" optionValue="value" display="chip" [style]="{width: '100%'}"
                  (onChange)="selectSectorValidate()"></p-multiSelect>

                  <small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
                    <ul>
                    <li *ngFor="let index of sectorTooltip">{{index.sectorDescription}}</li>
                    </ul>
                    </small>

                  <small id="sectorValidator" *ngIf="
										formCommercialRappel.controls['sector'].invalid &&
                    (formCommercialRappel.controls['sector'].dirty ||
                    formCommercialRappel.controls['sector'].touched)"
                  class="p-error">{{"ManagePromotionalActivitiesComponent.Create.SectorMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>

          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Rappel.SettlementPeriod" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="settlementPeriod" class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
                  [options]="objectSettlementPeriodList" [filter]="true" formControlName="settlementPeriod"
                  placeholder="{{ 'ManagePromotionalActivitiesComponent.Rappel.SettlementPeriodPlaceHolderCombo' | translate }}" optionLabel="label" optionValue="value" display="chip"
                  (click)="forkJoinInitialData()" [style]="{width: '100%'}"></p-dropdown>
                <small id="settlementPeriodValidator" *ngIf="
                formCommercialRappel.controls['settlementPeriod'].invalid &&
                (formCommercialRappel.controls['settlementPeriod'].dirty ||
                formCommercialRappel.controls['settlementPeriod'].touched)" 
                class="p-error">{{"ManagePromotionalActivitiesComponent.Rappel.SettlementPeriodMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>

          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Rappel.TypeOfMeasure" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="measuredType" class="multiselect-custom-virtual-scroll" 
                  [virtualScroll]="true"
                  [options]="objectMeasuredTypeList" [filter]="true" formControlName="measuredType"
                  placeholder="{{ 'ManagePromotionalActivitiesComponent.Rappel.TypeOfMeasurePlaceHolderCombo' | translate }}"
                  optionLabel="label" optionValue="value" display="chip"
                  (click)="forkJoinInitialData()" 
                  [style]="{width: '100%'}" 
                  (onChange)="changeMeasureType()">
                </p-dropdown>
                <small id="measuredTypeValidator" *ngIf="
                formCommercialRappel.controls['measuredType'].invalid &&
                (formCommercialRappel.controls['measuredType'].dirty ||
                formCommercialRappel.controls['measuredType'].touched)" 
                class="p-error">{{ "ManagePromotionalActivitiesComponent.Rappel.TypeOfMeasureMsgValidate" | translate }}</small>
              </div>
            </div>
          </div>

          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Rappel.Consideration" | translate }}</label>
              <div class="form-group">
                <p-dropdown id="consideration" class="multiselect-custom-virtual-scroll" 
                  [virtualScroll]="true"
                  [options]="objectConsiderationList" 
                  [filter]="true" 
                  formControlName="consideration"
                  placeholder="{{ 'ManagePromotionalActivitiesComponent.Rappel.ConsiderationPlaceHolderCombo' | translate }}"
                  (onChange)="changeConsideration()" 
                  optionLabel="label"
                  (click)="forkJoinInitialData()" 
                  optionValue="value" 
                  display="chip" 
                  [style]="{width: '100%'}">
                </p-dropdown>
                <small id="considerationValidator" *ngIf="
                formCommercialRappel.controls['consideration'].invalid &&
                (formCommercialRappel.controls['consideration'].dirty ||
                formCommercialRappel.controls['consideration'].touched)"
                class="p-error">{{ "ManagePromotionalActivitiesComponent.Rappel.ConsiderationMsgValidate" | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="form-check col-md-4 mt-3" style="display: flex; align-items: center;">						
						<app-switch style="display: contents;"
							[nameSwitch]="'HandlingProductFilterComponent.NotHandlers'"
							[isValueSwitch]="showManejantes"
              [isDisabledInput]="isProcess == commercialActivityEnum.view"
							(switchValue)="eventswitchValue($event)">
						</app-switch>
					</div>
        </div>
      </form>
    </section>

    <section id="Products">
      <div class="row mt-5">
        <div class="pstn_container container-fluid">

          <div class="container-table header">
            <span>{{ 'ManagePromotionalActivitiesComponent.Discount.productfilters' | translate }}</span>
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
              (click)="toggleTableFilter()"
              [icon]="showTableFilter ? 'uil uil-angle-up arrow-up' : 'uil uil-angle-down arrow-down'"></button>
          </div>

          <div class="container-table body" *ngIf="showTableFilter">
            <app-hierarchy-advanced-filters [isProcess]="isProcess" [isMultiFilter]="isMultiFilter"
              [sector]="sector.value" [requireSector]="true" [editFiltersObjectList]="editFiltersObjectList"
              (eventClickFilters)="eventClickFilters($event)"></app-hierarchy-advanced-filters>
            <div class="row mt-5"></div>
          </div>

        </div>
      </div>
    </section>

    <section id="TableWhitGroup">
      <div class="row mt-5">
        <div class="pstn_container container-fluid ">

          <div class="container-table header">
            <span>Productos rappel</span>
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
              (click)="toggleTable()"
              [icon]="showTable ? 'uil uil-angle-up arrow-up' : 'uil uil-angle-down arrow-down'"></button>
          </div>

          <div class="container-table body" *ngIf="showTable">

            <div class="container-table options" *ngIf="!(isProcess === commercialActivityEnum.view)">
              <span>
                Borrar seleccionados
                <i id="btnDeleteBatch" 
                  aria-hidden="true" 
                  pTooltip="{{'Delete' | translate}}" 
                  class="uil uil-trash-alt eliminar" 
                  (click)="deleteAllAction()"
                  *ngIf="(rolePermission.delete | rolePermission)">
                </i>
              </span>
            </div>

            <app-tables-with-groups 
              [isProcess]="isProcess" 
              [headersTable]="headersTable"
              [subHeaderTable]="subHeaderTable" 
              [valueTable]="valueTable" 
              (deleteGroupEvent)="deleteGroupAction($event)"
              (deleteSelectedEvent)="getSelectList($event)"
              (deleteSelectedGroupEvent)="getSelectGroup($event)" >
            </app-tables-with-groups>

          </div>

        </div>
      </div>
    </section>

    <section id="Ranges">
      <div class="row mt-5">
        <div class="pstn_container container-fluid">

          <div class="container-table header">
            <span>Rangos rappel</span>
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
              (click)="toggleTableRanges()"
              [icon]="showTableRanges ? 'uil uil-angle-up arrow-up' : 'uil uil-angle-down arrow-down'"></button>
          </div>
          <div class="container-table body" *ngIf="showTableRanges">

            <div class="col-md-3 mb-3">
              <button type="button" 
                pButton 
                pRipple 
                label="Añadir rango" 
                (click)="addRange()"
                class="p-button-rounded pstn_primario w-100" 
                *ngIf="isProcess !== comercialActivityEnum.view && (rolePermission.create | rolePermission)" >
              </button>
            </div>

            <p-table #dt editMode="row" name="gridApprovalRank" [paginator]="true" [rows]="10"
              paginatorPosition="bottom" [value]="objectRangeRappel" dataKey="rangeRappelId"
              currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
              [rowsPerPageOptions]="[10,25,50,100]" styleClass="p-datatable-customers">
              <ng-template pTemplate="header" style="width: 100%;">
                <tr>
                  <th style="width: 25%;">
                    <div class="p-d-flex p-jc-between p-ai-center">Rango mínimo</div>
                  </th>
                  <th style="width: 25%;">
                    <div class="p-d-flex p-jc-between p-ai-center">Rango máximo </div>
                  </th>
                  <th>
                  </th>
                  <th style="width: 20%;">
                    <div class="p-d-flex p-jc-between p-ai-center">Valor aporte </div>
                  </th>
                  <th>
                    {{ "Actions" | translate }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rangeRappel let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="rangeRappel ">
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div>
                          <p-dropdown 
                            optionLabel="label" 
                            optionValue="value" 
                            [options]="objectOperatorListMinimun"
                            [(ngModel)]="rangeRappel.minimumRangeOperator" 
                            [style]="{ width: '30%' }"
                            placeholder="{{'ApprovalRankComponent.Create.Select'| translate}}">
                        </p-dropdown>
                        <p-inputNumber 
                          *ngIf="inputFormatMeasured" 
                          [style]="{ width: '40%' }"
                          [inputStyle]="{'width':'40%'}" 
                          [min]="0" [max]="999999999999999"
                          [(ngModel)]="rangeRappel.minimumRange">
                        </p-inputNumber>
                        <p-inputNumber 
                          *ngIf="!inputFormatMeasured" 
                          [style]="{ width: '40%' }"
                          [inputStyle]="{'width':'40%'}" 
                          mode="decimal" 
                          [minFractionDigits]="2" [maxFractionDigits]="2"
                          [(ngModel)]="rangeRappel.minimumRange">
                        </p-inputNumber>
                          {{symbolMeasuredTypeValue}}
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div [ngClass]="rangeRappel.inputMin ? 'color-borde-input' : 'Ninguno'">
                          {{rangeRappel.minimumRangeOperator}} &nbsp;&nbsp; {{rangeRappel.minimumRange}} &nbsp;&nbsp;
                          {{symbolMeasuredTypeValue}}
                        </div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div>
                          <p-dropdown 
                            optionLabel="label" 
                            optionValue="value" 
                            [options]="objectOperatorListMax"
                            [(ngModel)]="rangeRappel.maxRangeOperator" 
                            [style]="{ width: '30%'}"
                            placeholder="{{'ApprovalRankComponent.Create.Select'| translate}}" 
                            [disabled]="rangeRappel.disabledInput === true">
                          </p-dropdown>
                          <p-inputNumber 
                            *ngIf="inputFormatMeasured" 
                            [style]="{ width: '40%' }"
                            [inputStyle]="{'width':'40%'}" 
                            [min]="0" [max]="999999999999999"
                            [(ngModel)]="rangeRappel.maxRange" 
                            [disabled]="rangeRappel.disabledInput === true">
                          </p-inputNumber>
                          <p-inputNumber 
                            *ngIf="!inputFormatMeasured" 
                            [style]="{ width: '40%' }"
                            [inputStyle]="{'width':'40%'}" 
                            [minFractionDigits]="2" [maxFractionDigits]="2" 
                            mode="decimal"
                            [(ngModel)]="rangeRappel.maxRange" 
                            [disabled]="rangeRappel.disabledInput === true" >
                          </p-inputNumber>
                          {{symbolMeasuredTypeValue}}
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div [ngClass]="rangeRappel.inputMax ? 'color-borde-input' : 'Ninguno'">
                          {{rangeRappel.maxRangeOperator}} &nbsp;&nbsp; {{rangeRappel.maxRange}} &nbsp;&nbsp;
                          {{symbolMeasuredTypeValue}}
                        </div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-checkbox 
                          *ngIf="rangeRappel.showApply === true" 
                          name="groupname" 
                          label="No Aplica" 
                          [binary]="true"
                          [(ngModel)]="rangeRappel.notApply" 
                          (onChange)="onChangeDisabled($event)">
                        </p-checkbox>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-inputNumber 
                          *ngIf="inputFormatConsideration" 
                          [style]="{ width: '80%' }"
                          [inputStyle]="{'width':'80%'}" 
                          [min]="0" [max]="999999999999999"
                          [(ngModel)]="rangeRappel.contributionValue">
                        </p-inputNumber>
                        <p-inputNumber 
                          *ngIf="!inputFormatConsideration" 
                          [style]="{ width: '80%' }"
                          [inputStyle]="{'width':'80%'}" 
                          [minFractionDigits]="2" [maxFractionDigits]="2" 
                          mode="decimal"
                          [(ngModel)]="rangeRappel.contributionValue" 
                          (onBlur)="changeValue(rangeRappel)">
                        </p-inputNumber>
                        {{symbolConsiderationValue}}
                      </ng-template>
                      <ng-template pTemplate="output">{{rangeRappel.contributionValue}} &nbsp;&nbsp;
                        {{symbolConsiderationValue}} </ng-template>
                    </p-cellEditor>
                  </td>

                  <td style="text-align:center">
                      <i 
                        *ngIf="isProcess !== comercialActivityEnum.view 
                        && (rolePermission.update | rolePermission) && (!editing)" 
                        class="uil uil-edit Editar" 
                        aria-hidden="true" 
                        pInitEditableRow
                        (click)="onRowEditInit(rangeRappel)">
                      </i>
                    <i 
                      *ngIf="isProcess !== comercialActivityEnum.view 
                      && (rolePermission.delete | rolePermission) && (!editing)" 
                      aria-hidden="true" 
                      class="uil uil-trash-alt eliminar"
                      (click)="deleteRowRange(rangeRappel)">
                    </i>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                      class="p-button-rounded p-button-text p-button-success p-mr-2"
                      (click)="onRowEditSave(rangeRappel)"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                      class="p-button-rounded p-button-text p-button-danger"
                      (click)="onRowEditCancel(rangeRappel, ri)"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="4">{{ 'ElementsNotFound' | translate }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </section>

    <div class="row mt-5"></div>
    
    <div class="col-md-12">
			<app-handling-product-filter
				#activityHandler
        [nameComponent]="'HandlingProductFilterComponent.noHandlingCustomerProducts'"
				[isProcess]="isProcess"
				[editFiltersObjectList]="editFiltersObjectHandlerList"
				[commercialActivityHandlerisGroup]="commercialActivityHandlerisGroup"
        [commercialActivityHandlerisClientWithoutSales]="commercialActivityHandlerisClientWithoutSales"
				[commercialActivityHandlerDetails]="commercialActivityHandlerDetails"
				[commercialActivityHandlerPeriod]="commercialActivityHandlerPeriod"
				[showManejantes]="showManejantes"
        [sector]="sector.value"
        [typeCoreMaster]="typeCoreMaster.Handler">

			</app-handling-product-filter>
		</div>

    <div class="row mt-5"></div>

		<div class="col-md-12" *ngIf="isCurrentMonth">
			<app-handling-product-filter
				#ActivityHandlerCurrentMonth
        [nameComponent]="'HandlingProductFilterComponent.ProductsCurrentMonth'"
				[isProcess]="isProcess"
				[editFiltersObjectList]="editFiltersObjectHandlerCurrentMonthList"
				[commercialActivityHandlerDetails]="commercialActivityHandlerCurrentMonthDetails"
				[showManejantes]="showManejantes"
				[sector]="sector.value"
				[isCurrentMonth]="isCurrentMonth"
        [typeCoreMaster]="typeCoreMaster.HandlerCurrentMonth">

			</app-handling-product-filter>
		</div>

    <!-- Calculate -->
    <div class="col-md-12" *ngIf="rappelActivityEdit &&
																rappelActivityEdit.statusDomainId !== commercialActivityStateEnum.Registered &&
																rappelActivityEdit.statusDomainId !== commercialActivityStateEnum.InValidation &&
																rappelActivityEdit.statusDomainId !== commercialActivityStateEnum.Invalid">
      <div *ngIf="rappelActivityEdit !== undefined && purchaseBaseToCalculate.length > 0">
        <div class="row mt-5"></div>
        <app-commercial-activity-calculate [commercialACtivityId]="rappelActivityEdit.id"
          [statusDomainId]="rappelActivityEdit.statusDomainId"
          [commercialActivityType]="rappelActivityEdit.typeCommercialActivityDomainId"
          [filterClientJson]="filterClientJsonToCalculate" [purchaseBaseToCalculate]="purchaseBaseToCalculate"
          [deltaDays]="rappelActivityEdit.numberDays" [maxPromotion]="rappelActivityEdit.orderLimit"
          [maxPeriod]="rappelActivityEdit.periodLimit" [nClients]="rappelActivityEdit.totalClientsFilter"
          [isCalculate]="rappelActivityEdit.isCalculate" [nConsideration]="rappelActivityEdit.consideration"
          (eventSendToCalculate)="eventSendToCalculate($event)"></app-commercial-activity-calculate>
      </div>
    </div>

    <!-- Liberation -->
    <div *ngIf="rappelActivityEdit !== undefined &&
              rappelActivityEdit.statusDomainId !== commercialActivityStateEnum.Registered &&
              rappelActivityEdit.statusDomainId !== commercialActivityStateEnum.InValidation &&
              rappelActivityEdit.statusDomainId !== commercialActivityStateEnum.Invalid &&
							rappelActivityEdit.statusDomainId !== commercialActivityStateEnum.Validated &&
							isCalculate">
      <div class="row mt-5"></div>

      <div class="col-md-12">
        <div class="accordion" id="accordionCreatePromotion">
          <app-commercial-activity-approval-selected
            [idActivity]="commercialActivityId"
						[commercialActStatus]="rappelActivityEdit.statusDomainId"
            [nConsideration]="rappelActivityEdit.consideration"
            [typeCommercialActivity]="rappelActivityEdit.typeCommercialActivityDomainId"
            [userCreation]="userCreation">
          </app-commercial-activity-approval-selected>
        </div>
      </div>

      <div class="row mt-5"></div>
			<div class="col-md-12">
				<div class="accordion" id="accordionCreatePromotion">
					<app-commercial-activity-historical [idActivity]="commercialActivityId"
					[commercialActStatus]="rappelActivityEdit.statusDomainId"></app-commercial-activity-historical>
				</div>
			</div>

    </div>

    <div class="row mt-5"></div>

    	  <!-- CreateDocumentPromotion -->
        <div class="col-md-12">
          <div id="accordionCreateDocumentPromotion">
            <app-commercial-activity-document [title]="'ManagePromotionalActivitiesComponent.Create.DocumentCreate' | translate"
                                                                                    #documentSupport
                                                                            [routeCancel]="routeCancelCreatePromotion"
                                            [showFields]="showFieldsCreatePromotion"
                                            [promotionEdit]="promotionEdit"
                                            [isProcess]="isProcess"
                                            [commercialActivityTypeId]="typeCommercialActivity.value"
                                            [commercialActivityId]="commercialActivityId"
                                            [observationEdit]="observationEdit"
                                            (eventClickSaveBtn)="eventClickSaveBtn($event)"
                                            (eventClickEditBtn)="eventClickEditBtn($event)"
                                            (eventClickSendValidateBtn)="eventClickSendValidateBtn()">
              </app-commercial-activity-document>
          </div>
        </div>
	
			<div class="row mt-5"></div>
    <!-- CreatePromotion -->
    <div class="accordion" id="accordionCreatePromotion">
      <app-commercial-activity-footer
        [title]="'ManagePromotionalActivitiesComponent.Create.PromotionCreate' | translate"
        [routeCancel]="routeCancelCreatePromotion" [showFields]="showFieldsCreatePromotion"
        [showButtons]="showButtonsCreatePromotion" [promotionEdit]="promotionEdit" [isProcess]="isProcess"
        [isNext]="true" [commercialActStatVal]="commercialActStatVal" (eventClickSaveBtn)="eventClickSaveBtn($event)"
        (eventClickEditBtn)="eventClickEditBtn($event)" (eventClickSendValidateBtn)="eventClickSendValidateBtn()"
        (eventChangeData)="prepareFooterForEvent($event)"></app-commercial-activity-footer>
    </div>

  </div>

  <div class="container" *ngIf="nextAssignClients">
    <app-assing-clients 
      [isProcess]="isProcess" 
      [filtersEdit]="filterClientJson" 
      [validateFilter]="true"
      [clientsEdit]="commercialActivityCustomers"
      [commercialActivityId]="commercialActivityId"
      [commercialActivityHandler]="commercialActivityHandler2" 
			[commercialActivityHandlerPeriod]="commercialActivityHandlerPeriod2"
      [isHanlder]="showManejantes"
      [showFields]="showFields"
      (eventClickBack)="eventClickBackClients()"
      (eventClickSendData)="eventClickSendDataClients($event)"
      (eventClickSendDataEdit)="eventClickSendDataEditClients($event)"></app-assing-clients>
  </div>

</section>


<div *ngIf="displayValidationsDetails">
  <app-commercial-activity-invalid
    [isVisible]="displayValidationsDetails"
    [invalidationDataDetail]="invalidationDataDetail"
    [dateInterruptionActivity]="initialDate"
    [isProcess]="isProcess"
    [filterClientJson]="filterClientJson"
    [activityId]="commercialActivityId"
    (eventClickInvalidate)="registryInvalidateCommercialACtivity($event)"
  ></app-commercial-activity-invalid>
</div>
<div *ngIf="rappelActivityEdit">
  <app-commercial-activity-planned
    [isVisible]="rappelActivityEdit.statusDomainId == commercialActivityStateEnum.Planned"
    [commercialActivityId]="commercialActivityId"
  ></app-commercial-activity-planned>
</div>
<p-toast></p-toast>
