import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApprovalRank } from './interfaces/approval-rank.interface';
import { IApprovalView } from './interfaces/approval-view.interfaces';
import { ApprovalRankConfigurationModel } from './models/approval-rank.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalRanksService {
  private readonly httpService: HttpService;
  private objLevelApproval: ICombo[];

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

public getAllOperationApprovalLevel(idHierarchy: number): Observable<IResponseService<IApprovalView[]>> {
    const response$: Observable<IResponseService<IApprovalView[]>> =
     this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/approval_rank/${idHierarchy}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IApprovalView[]>>) => {
          return response.body;
        })
      );
    return response$;
  }


  public getAllApprovalLevel(): Observable<IResponseService<IApprovalRank[]>> {
    const response$: Observable<IResponseService<IApprovalRank[]>> =
     this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_approval_level/${1}/${100}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IApprovalRank[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public postApprovalRank(body: ApprovalRankConfigurationModel): Observable<IResponseService<any>>{
    const response$: Observable<IResponseService<any>> =
    this.httpService
   .post(
       `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/approval_rank`, body)
   .pipe(
       map((response: HttpResponse<IResponseService<any>>) => {
       return response.body;
       })
   );
   return response$;
  }

  getLevelApproval(): ICombo[] {
    return this.objLevelApproval;
  }

  setLevelApproval(object: ICombo[]){
    this.objLevelApproval = object;
  }
}