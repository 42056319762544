import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AssociateMessage {

  public static readonly TITLE = 'Asociar';
  public static readonly TEST = 'Aca los mensajes que deseas colocar en este componente';
  public static readonly NO_MANDATORY = 'Solo puede tener un usuario requerido como aprobador por nivel.';
  public static readonly NO_MANDATORY_TRANSLATE = 'You can only have one required user as an approver per level.';
  public static readonly NO_AUTHORIZER = 'Solo puede tener un usuario requerido como autorizador por nivel.';
  public static readonly NO_AUTHORIZER_TRANSLATE = 'You can only have one required user as an authorizer per level.';
  public static readonly NO_ASSOCIATE = 'Debe seleccionar un nivel de asociacion.';
  public static readonly NO_ASSOCIATE_TRANSLATE = 'You must select an association level.';

  constructor() { }
}
