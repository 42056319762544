<section>
    <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="pstn breadcrumb">
              <li class="breadcrumb-item">
              <a [routerLink]="'/auth'">
                  <i class="uil uil-estate" aria-hidden="true" ></i>{{ 'Home' | translate }}</a>
              </li>
              <li class="breadcrumb-item">
                <a [routerLink]="'/auth/liberation-hierarchy'">
                  {{ "LiberationHierarchyComponent.Title" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ "LiberationHierarchyComponent.HierarchyMap" | translate }}
              </li>
          </ol>
        </nav>
    </div>
  </section>

<section class="pstngestion">
    <div class="container">
        <div class="row">
            <h1 class="pstntitle">{{ 'LiberationHierarchyComponent.HierarchyMap' | translate }}</h1>
        </div>
        <div class="row">
          <h5 class="pstntitle">{{ 'LiberationHierarchyComponent.IdHierarchy' | translate }}: {{idHierarchy}}</h5>
      </div>
      <div class="mt-2"></div>
    </div>

    <app-organization-chart></app-organization-chart>

    <div class="row mt-3">
        <div class="col-md-2"></div>
        <div class="col-md-2">
            <button
              id="btnApprovalRanks"
              pButton
              pRipple
              type="button"
              label="{{ 'LiberationHierarchyComponent.BtnApprovalRanks' | translate }}"
              class="p-button-rounded pstn_primario w-100"
              (click)="openDialog()"></button>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-2">
            <button
              id="btnApprovalRanks"
              pButton
              pRipple
              type="button"
              label="{{ 'ReturnBtn' | translate }}"
              class="p-button-rounded pstn_secundario w-100"
              (click)="returnLiberation()"></button>
        </div>
        <!-- <div class="col-md-2">
            <button
              id="btnApprovalRanks"
              pButton
              pRipple
              type="button"
              label="{{ 'SaveBtn' | translate }}"
              class="p-button-rounded pstn_primario w-100"
              (click)="saveOrganization()"></button> 
        </div> -->
    </div>
    <div class="row mt-3"></div>
    

</section>
<p-toast></p-toast>
<p-dialog [(visible)]="isDialog"
          header="{{ 'LiberationHierarchyComponent.TitleDialog' | translate }}"
          [modal]="true"
          [draggable]="false" 
          [resizable]="false"
          styleClass="p-fluid"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '60vw', heigth: 'auto'}">

  <ng-template pTemplate="content">
     <div class="col-md-auto">
      
      <div class="row">
        <div class="col-8"></div>
        <div class="col-md-4">
          <button id="btnAddNew" 
          pButton 
          pRipple 
          type="button"
          label="{{ 'ApprovalRankComponent.Create.AddRecord' | translate }}"
          class="p-button-rounded pstn_secundario w-100"
          *ngIf="(rolePermission.create | rolePermission)"
          (click)="addApprovalRankLevel()"></button>
        </div>
      </div>

      <div class="mt-3"></div>

      <p-table #dt
      editMode="row"
      name="gridApprovalRank"
      [paginator]="true"
      [rows]="10"
      paginatorPosition="bottom"
      [value]="objectApprovalRankList"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
      [rowsPerPageOptions]="[10,25,50,100]"
      styleClass="p-datatable-customers">
      <ng-template pTemplate="header" style="width: 100%;">
        <tr>
          <th style="width: 220px;">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'ApprovalRankComponent.ApprovalLevel' | translate }}
            </div>
          </th>

          <th style="width: 100px">
            <div class="p-d-flex p-jc-between p-ai-center">{{ 'ApprovalRankComponent.OperatorStart' | translate }}
            </div>
          </th>

          <th style="width: 150px">
            <div class="p-d-flex p-jc-between p-ai-center">{{ 'ApprovalRankComponent.RankStart' | translate }}
            </div>
          </th>

          <th style="width: 100px">
            <div class="p-d-flex p-jc-between p-ai-center">{{ 'ApprovalRankComponent.OperatorEnd' | translate }}
            </div>
          </th>

          <th style="width: 150px">
            <div class="p-d-flex p-jc-between p-ai-center">{{ 'ApprovalRankComponent.RankEnd' | translate }}
            </div>
          </th>

          <th style="width: 50px">
            {{ "Actions" | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rank>
        <tr>
          <td>
            <p-dropdown id="profile"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="value"
                  [(ngModel)]="rank.hierarchyApprovalLevelId"
                  (onChange)="updateApprovallevel(rank)"
                  [filter]="true"
                  [showClear]="true"
                  [options]="objectApprovalLevelOption"
                  [style]="{ width: '220px' }"
                  placeholder="{{'ApprovalRankComponent.Create.ApprovalLevelPlaceHolderCombo'| translate}}"
                  [disabled]="!(rolePermission.create | rolePermission)">
          </p-dropdown>
          </td>
          <td>
            <p-dropdown id="operatorStart"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="value"
                  [showClear]="true"
                  [(ngModel)]="rank.operatorEnd"
                  [options]="objectOperatorListStart"
                  [style]="{ width: '100px' }"
                  placeholder="{{'ApprovalRankComponent.Create.Select'| translate}}"
                  [disabled]="!(rolePermission.create | rolePermission)">
            </p-dropdown>
          </td>
          <td>
            <input
                #rankStart
                [style]="{ width: '150px' }"
                [ngModel]="rank.rankStart | number: '1.2'"
                (change)="validationRankStart($event, rank)"
                pInputText
                ng-pattern="/^[0-9]+\.([0-9]{1,2})$/"
                placeholder="0.00"
                onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46)"
                [textMask]="{ mask: priceMask }"
                [disabled]="!(rolePermission.create | rolePermission)"/>
          </td>
          <td>
            <p-dropdown id="operatorEnd"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="value"
                  [showClear]="true"
                  [(ngModel)]="rank.operatorStart"
                  [options]="objectOperatorListEnd"
                  [style]="{ width: '100px'}"
                  placeholder="{{'ApprovalRankComponent.Create.Select'| translate}}"
                  [disabled]="!(rolePermission.create | rolePermission)">
            </p-dropdown>
          </td>
          <td>
              <input
              #rankEnd
              [style]="{ width: '150px' }"
              [ngModel]="rank.rankEnd | number: '1.2'"
              (change)="validationRankEnd($event, rank)"
              pInputText
              ng-pattern="/^[0-9]+\.([0-9]{1,2})$/"
              placeholder="0.00"
              onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46)"
              [textMask]="{ mask: priceMask }"
              [disabled]="!(rolePermission.create | rolePermission)"/>
          </td>
          <td>
            <i
            [style]="{ width: '50px' }"
            aria-hidden="true"
            id="btnDelete"
            class="uil uil-trash-alt eliminar"
            *ngIf="(rolePermission.delete | rolePermission)"
            (click)="deleteApprovalRankLevel(rank)"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">{{ 'ElementsNotFound' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>


    <div class="row">
      <div class="col-6"></div>
      <div class="col-md-3 mt-2">
        <button id="btnSave"
        pButton
        pRipple
        type="button"
        label="{{ 'SaveBtn' | translate }}"
        class="pstn_primario"
        *ngIf="(rolePermission.create | rolePermission)"
        (click)="saveApprovalRank()"></button>
      </div>

      <div class="col-md-3 mt-2">
        <button id="btnCancel"
          pButton
          pRipple
          type="button"
          label="{{ 'CancelBtn' | translate }}"
          style="background-color: #7C7D81; border:none"
          class="pstn_primario"
          (click)="cancelApprovalRank()"></button>
      </div>
    </div>  
    </div> 
  </ng-template>
</p-dialog>
