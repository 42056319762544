import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdvancedFiltersClientsResult } from '@app/@shared/interfaces/advanced-filters-client-result.interface';
import { IAdvancedFiltersClient } from '@app/@shared/interfaces/advanced-filters-client.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDetailFilterClientsResult } from './interfaces/detail-filter-client-result.interface';
import { ActivityHandlerModel } from '../handling-product-filter/models/activity-handler.model';


@Injectable({
  providedIn: 'root',
})
export class AssingClientService {
  private solicitudeId: number;

  private readonly httpService: HttpService;
  private readonly HttpClient: HttpClient;

  constructor(
    httpService: HttpService,
    HttpClient: HttpClient
  ) {
    this.httpService = httpService;
    this.HttpClient = HttpClient;
   }



  /**
 * @description This method is to filters to clients.
 * @author Daniel Londono Ortega
 * @param filters
 * @returns Observable<IResponseService<IAdvancedFiltersClientsResult>>
 * @sprint 7
 */
  public postAdvancedFiltersClients(filters: IAdvancedFiltersClient): Observable<IResponseService<IAdvancedFiltersClientsResult>> {
    const response$: Observable<IResponseService<IAdvancedFiltersClientsResult>> = this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/advanced_filters_clients`,
        filters
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IAdvancedFiltersClientsResult>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
* @description This method is to filters to clients details.
* @author Daniel Londono Ortega
* @param filters
* @returns Observable<IResponseService<IAdvancedFiltersClientsDetailsResult>>
* @sprint 12
*/
  public postAdvancedFiltersClientsDetails(filters: IAdvancedFiltersClient): Observable<IResponseService<IDetailFilterClientsResult[]>> {
    const response$: Observable<IResponseService<IDetailFilterClientsResult[]>> = this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/advanced_filters_clients_details`,
        filters
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IDetailFilterClientsResult[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public postAdvancedFiltersClientsHandler(filters: ActivityHandlerModel): Observable<IResponseService<IAdvancedFiltersClientsResult>> {
    const response$: Observable<IResponseService<IAdvancedFiltersClientsResult>> = this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity_handler/customer/filter`,
        filters
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IAdvancedFiltersClientsResult>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
