<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active"
            aria-current="page">
          {{ "Negotiations.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
      <section class="generalData">

          <div class="row title">
              <div class="col-md-6">
                  <h1 class="pstntitle">
                      {{ 'Negotiations.Title' | translate }}
                  </h1>
              </div>
          </div>

          <div class="options">
              <!-- button -->
              <div class="options--button">
                  <button id="btnCreate" pButton pRipple type="button"
                      label="{{ 'Negotiations.CreationBtn' | translate }}"
                      class="p-button-rounded pstn_primario w-100"
                      (click)="create()"></button>
              </div>
          </div>

      </section>
  </div>
</section>
