import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';
import { HttpService } from '@app/@shared/services/http.service';
import { map } from 'rxjs/operators';
import { IAdvancedFilter } from './interfaces/general-advanced-filter.interface';
import { ICommercialActivity } from '@app/@modules/promotionalactivity/manage-promotional-activities/interfaces/commercial-activity.interface';
import { environment } from '@env/environment';
import { IConfigurationUserAccessManagement } from '../advanced-client-filter/interfaces/configuration-user-access-management.interface';

@Injectable({
  providedIn: 'root'
})
export class SharedAdvancedFilterService {

  constructor(private httpService: HttpService) { }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  public getCommercialActivity(filters: IAdvancedFilter): Observable<IResponseService<ICommercialActivity[]>> {
    var url = `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/list_commercial_activity_async`;
    const response$: Observable<IResponseService<ICommercialActivity[]>> =
      this.httpService
        .post(url, filters)
        .pipe(
          map((response: HttpResponse<IResponseService<ICommercialActivity[]>>) => {
            return response.body;
          })
        );
    return response$;
  }

  public getConfigurationUserAcessManagement(accessManagementId: string): Observable<IResponseService<IConfigurationUserAccessManagement>>{
    const response$: Observable<IResponseService<IConfigurationUserAccessManagement>> = this.httpService
    .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/configuration-user-acess-management/${accessManagementId}`)
    .pipe(
      map((response: HttpResponse<IResponseService<IConfigurationUserAccessManagement>>) => {
        return response.body;
      })
    );
    return response$;
  }

  /*setUserFilters(channelList: ICombo[], sectorList: ICombo[]): ICommercialActivityUserConfig {

    const channel: string[] = JSON.parse(SessionStorageHelper.get(CommonConfiguration.Channel));
    const sector: string[]  = JSON.parse(SessionStorageHelper.get(CommonConfiguration.Sector));

    Filtrado de Canal y Sector
    const activityUserConfig = {} as ICommercialActivityUserConfig;
    activityUserConfig.channels = channel.length > 0 ? channelList.filter((z) => channel.includes(z.value)) : channelList;
    activityUserConfig.sectors = sector.length > 0 ? sectorList.filter((z) => sector.includes(z.value)) : sectorList;
    return activityUserConfig;
  }*/


}