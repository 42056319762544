import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { PaginationNotificationModel } from '@app/@shared/model/paginationNotification.model';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { INotification } from './Interfaces/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly httpService: HttpService;

  constructor(
    httpService: HttpService,
  ) { 
    this.httpService = httpService;
  }

  /**
   * @description This method is to get all the INotificationJson.
   * @author dcardona
   * @param none
   * @returns Observable<IResponseService<INotification[]>>
   * @sprint 4
   */
  public getListNotification(
    paginationNotificationModel: PaginationNotificationModel
  ): Observable<IResponseService<INotification[]>> {
    let response$: Observable<IResponseService<INotification[]>>;
    response$ = this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_solicitude_notification`,
        paginationNotificationModel
      )
      .pipe(
        map((response: HttpResponse<IResponseService<INotification[]>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
