<section>
  <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="pstn breadcrumb">
            <li class="breadcrumb-item">
            <a [routerLink]="'/auth'">
                <i class="uil uil-estate" aria-hidden="true" ></i>{{ 'Home' | translate }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "HierarchyReleaseComponent.Title" | translate }}
            </li>
        </ol>
      </nav>
  </div>
</section>

<section class="pstngestion" >
  <div class="container">
    <div class="row justify-content-between mb-3">
      <div class="col-md-6">
        <h1 class="pstntitle">
          {{ "HierarchyReleaseComponent.Title" | translate }}
        </h1>
      </div>
      <div class="col-md-6 mt-4 " >
        <button
          id="btnCreateApprovalRank"
          class="pstn_primario"
          pButton
          pRipple
          type="button"
          label="{{ 'HierarchyReleaseComponent.CreateBtn' | translate }}"
          class="p-button-rounded pstn_primario w-100"
          (click)="onCreate()"
          *ngIf="(rolePermission.create | rolePermission)"></button>
      </div>
    </div>
    <div class="row mt-5">
        <div class="pstn_container container-fluid">
          <!-- PrimeNg Table -->
          <p-table #dt
                   [value]="objectList"
                   [(selection)]="selectObject"
                   dataKey="id"
                   styleClass="p-datatable-customers"
                   [paginator]="true"
                   [rows]="10"
                   paginatorPosition="bottom"
                   currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
                   [rowsPerPageOptions]="[10,25,50,100]"
                   [globalFilterFields]="['id','description','countryDescription','societyDescription',
                                            'channelDescription','regionDescription','cediDescription',
                                            'processDescription','subprocessDescription','unitMeasurementDescription']">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-md-6 p-0 row">
                  <h6>
                    {{ "HierarchyReleaseComponent.DeleteBtn" | translate }}
                    <i id="btnDeleteBatch" 
                      class="uil uil-trash-alt eliminar" 
                      (click)="onDeleteBatch()" 
                      aria-hidden="true"
                      *ngIf="(rolePermission.delete | rolePermission)"></i>
                  </h6>
                </div>
                <div class="col-md-6 p-0">
                  <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search" aria-hidden="true" ></i>
                    <input pInputText
                          type="text"
                          (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="{{ 'SearchBy' | translate }}" />
                  </span>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th role="columnheader"
                    style="width: 3rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="description">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Description' | translate }}
                      <p-sortIcon field="description"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="countryDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Create.Country' | translate }}
                      <p-sortIcon field="countryDescription"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="societyDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Create.Society' | translate }}
                      <p-sortIcon field="societyDescription"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="channelDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Create.Channel' | translate }}
                      <p-sortIcon field="channelDescription"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="regionDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Create.Region' | translate }}
                      <p-sortIcon field="regionDescription"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="cediDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Create.Cedi' | translate }}
                      <p-sortIcon field="cediDescription"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="processDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Create.Process' | translate }}
                      <p-sortIcon field="processDescription"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="subprocessDescription">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'HierarchyReleaseComponent.Create.Subprocess' | translate }}
                      <p-sortIcon field="subprocessDescription"></p-sortIcon>
                    </div>
                </th>
                <th>{{ 'Actions' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body"
                         let-object>
              <tr>
                <td><p-tableCheckbox [value]="object"></p-tableCheckbox></td>
                <td>{{object.description}}</td>
                <td>{{object.countryDescription}}</td>
                <td>{{object.societyDescription}}</td>
                <td>{{object.channelDescription}}</td>
                <td>{{object.regionDescription}}</td>
                <td>{{object.cediDescription}}</td>
                <td>{{object.processDescription}}</td>
                <td>{{object.subprocessDescription}}</td>
                <td>
                  <i id="btnEye" 
                    class="uil uil-eye ver" 
                    (click)="onSeeRecord(object)" 
                    aria-hidden="true" 
                    *ngIf="(rolePermission.read | rolePermission)">
                  </i>
                  <i id="btnEdit" 
                    class="uil uil-edit Editar" 
                    (click)="onEdit(object)" 
                    aria-hidden="true" 
                    *ngIf="(rolePermission.update | rolePermission)">
                  </i>
                  <i id="btnCopy" 
                    class="uil uil-copy copiar" 
                    (click)="onCopy(object)" 
                    aria-hidden="true" 
                    *ngIf="(rolePermission.create | rolePermission)">
                  </i>
                  <i id="btnDelete" 
                    class="uil uil-trash-alt eliminar" 
                    (click)="onDelete(object)" 
                    aria-hidden="true"
                    *ngIf="(rolePermission.delete | rolePermission)">
                  </i>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">{{ 'ElementsNotFound' | translate }}</td>
              </tr>
            </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
      </div>
    </div>
  </div>
</section>
<p-toast></p-toast>
