import { IDataRange } from '../interfaces/data-range.interface';

export class DataRangeModel implements IDataRange {

  constructor(
    public dateStart?: Date,
    public dateEnd?: Date,
    public cantDays?: number,
    public id?: number,
    public commercialActivityId?: number
  ) {}

}