export enum CommercialActivitySubprocess {
    Promotions = 12,
    DiscountKind = 13,
    DiscountValue = 14,
    FinancialDiscount = 15,
    Events = 16,
    ZeroValueProduct = 17,
    Loyalty = 18,
    Redemption = 19,
    Rappel = 20,
    PriceDiscount = 21,
}

export enum CommercialActivityCalculateSlugEnum {
    Promotions = 'CostumerPromotion',
    DiscountKind = 'CostumerDiscount',
    DiscountValue = 'CostumerDiscount',
    FinancialDiscount = 'CostumerDiscount',
    Events = 'CostumerEvents',
    ZeroValueProduct = 'CostumerZeroValueProduct',
    Loyalty = 'CostumerLoyalty',
    Redemption = 'CostumerRedemption',
    Rappel = 'CostumerRappel',
    PriceDiscount = 'CostumerDiscount',
}
