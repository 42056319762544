import { Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { IProductGroupList } from '@app/@modules/promotionalactivity/exclusions/interfaces/products-groups-list.interface';
import { IHierarchyFiltersMaterials } from '../hierarchy-advanced-filters/interfaces/hierarchy-filters-materials.interface';
import { FilterMaterialDetailComponent } from '../hierarchy-advanced-filters/filter-material-detail/filter-material-detail.component';
import { CalendarPeriodComponent } from '../elements/calendar-period/calendar-period.component';
import { FilterMaterialResultModel } from '../hierarchy-advanced-filters/models/filter-material-result.model';
import { CommercialActivityHandlerPeriodModel } from '../elements/calendar-period/models/handler-period.model';
import { Subject } from 'rxjs';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';

@Component({
  selector: 'app-handling-product-filter',
  templateUrl: './handling-product-filter.component.html',
  styleUrls: ['./handling-product-filter.component.css']
})
export class HandlingProductFilterComponent implements OnInit {

  @Input('showManejantes') showManejantes = false;
  @Input('editFiltersObjectList') editFiltersObjectList: IHierarchyFiltersMaterials[] = [];
  @Input('commercialActivityHandlerisGroup') commercialActivityHandlerisGroup = false;
  @Input('commercialActivityHandlerisClientWithoutSales') commercialActivityHandlerisClientWithoutSales = false;
  @Input('commercialActivityHandlerDetails') commercialActivityHandlerDetails: any[] = [];
  @Input('commercialActivityHandlerPeriod') commercialActivityHandlerPeriod : CommercialActivityHandlerPeriodModel;
  @Input() isProcess : number;
  @Input('sector') sector: string[];
  @Input('isCurrentMonth') IsCurrentMonth: boolean;
  @Input('nameComponent') nameComponent: string;
  @Input('typeCoreMaster') typeCoreMaster: TypeCoreMaster;
  
  @ViewChild('detailBase') filterDetailMaterial : FilterMaterialDetailComponent;
  @ViewChild('detailPeriod') detailPeriod : CalendarPeriodComponent;

  public rolePermissionBtn = true;
  public advancedFiltersObjectList: IHierarchyFiltersMaterials[] = [];
  public advancedFiltersObjectListAux: any[] = [] ;
  public advancedFiltersObjectListJson: IHierarchyFiltersMaterials[] = [];
  // public valueCalendar = false;
  valueCalendar: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  eventClickFilterMaterial(event : any){
    this.advancedFiltersObjectList = event;
  }

  getCommercialActivityHandlerDetails() : FilterMaterialResultModel[]{
    return this.filterDetailMaterial.getMaterialListHandler();
  }

  getCommercialActivityHandlerPeriodModel() : CommercialActivityHandlerPeriodModel[]{
    return this.detailPeriod.getValuePeriod();
   
  }

  getGroupByJsonHandler() : any{
    if(this.editFiltersObjectList.length > 0){
      this.editFiltersObjectList = this.advancedFiltersObjectList.length == 0 ? this.editFiltersObjectList : this.advancedFiltersObjectList['advancedFiltersObjectList'];
      return JSON.stringify(this.editFiltersObjectList);
    }
    return JSON.stringify(this.advancedFiltersObjectList['advancedFiltersObjectList']);
  }

  getIsGroup(): boolean{
    return this.filterDetailMaterial.getValueSwitch();
  }

  getIsSwitchCustomer(): boolean{
    return this.filterDetailMaterial.getValueSwitchCustomer();
  }

  calendarValue(event : any) {
    this.valueCalendar.next(event);
  }


}
