<p-accordion >
  <p-accordionTab header="Documento soporte">

    <div class="card-body">
      <!-- CreateDocumentPromotion -->
      <form id="formPromotion" [formGroup]="formPromotion">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <!-- Observations -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for="observations">Observaciones</label><br/>
                  <textarea 
                    [rows]="2"
                    [cols]="50"
                    pInputTextarea
                    id="observations"
                    formControlName="observations"
                    autocomplete="off"
                    class="form-control p-d-flex p-jc-between p-ai-center">
                  </textarea>
                  <small id="observationsMsgValidate" *ngIf="
                              formPromotion.controls['observations'].invalid &&
                              (formPromotion.controls['observations'].dirty || formPromotion.controls['observations'].touched)"
                    class="p-error">{{ 'ManagePromotionalActivitiesComponent.Create.ObservationsMsgValidate' |
                    translate }}</small>
                </div>
              </div>

              <!-- Support Document -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for="document">Documento soporte</label><br/>
                      <p-message
                        severity="info"
                        text="{{
                          'ManagePromotionalActivitiesComponent.Create.FileUploadMsgValidate' | translate
                        }}"
                        styleClass="p-mr-5">
                      </p-message> 

                      <p-fileUpload
                      #fileUpload
                      customUpload="true"
                      class="col-md-12"
                      (uploadHandler)="onUploadFile($event)"
                      (onRemove)="onRemoveFileImage()"
                      [auto]="true"
                      maxFileSize="4194304"
                      [disabled]="isProcess == 3"
                      chooseLabel="{{'ManagePromotionalActivitiesComponent.Create.UploadFile' | translate}}"
                      invalidFileSizeMessageSummary="{0}: {{'SolicitudComponent.Create.InvalidSize' | translate}}"
                      invalidFileSizeMessageDetail="{{'SolicitudComponent.Create.FileUpload' | translate}} {0}."
                      invalidFileTypeMessageDetail=""
                      invalidFileTypeMessageSummary="{{'SolicitudComponent.Create.FileUploadMsgValidate' | translate}}"
                      accept=".pdf">
                    <ng-template pTemplate="content">
                      <div *ngIf="!uploadedFiles.length" class="col-md-12">
                        {{ "SolicitudComponent.Create.NoFile" | translate }}
                      </div>
                    </ng-template>
                  </p-fileUpload>
                </div>

                <div class="row mt-5">
                  <div *ngIf="downloadFile" class="row">
                    <div class="row mt-3 pb-3 d-flex justify-content-end">
                      <strong>{{ nameFile }}</strong>
                    </div>
                    
                    <div class="col-lg-4 md-3 mt-3" *ngIf="nameFile != '' && isProcess == commercialActivityEnum.view">
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-download"
                        label="{{ 'Download' | translate }}"
                        class="p-button-outlined p-button-rounded"
                        (click)="fileDownload()"
                      ></button>
                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

<div class="row mt-3"></div>

</p-accordionTab> 
</p-accordion>