<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/select-profile-module'">
            <i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "NotificationPanelComponent.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">
          {{ "NotificationPanelComponent.Title" | translate }}
        </h1>
      </div>
    </div>
    <!-- tabs notificaciones -->
    <ul class="nav nav-pills ccv-tabs mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="pills-price-tab"
          data-toggle="pill"
          role="tab"
          aria-selected="true"
          (click)="onClickTabPrice()"
          >{{ "NotificationPanelComponent.Price" | translate }}
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="pills-promotion-tab"
          data-toggle="pill"
          role="tab"
          (click)="onClickTabPromotion()"
          >{{ "NotificationPanelComponent.PromotionalActivity" | translate }}
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="pills-negotiation-tab"
          data-toggle="pill"
          role="tab"
          (click)="onClickTabNegotiation()"
          >{{ "NotificationPanelComponent.Negotiations" | translate }}
        </a>
      </li>
    </ul>
    <div class="tab-content row p-datatable-scrollable-body" id="pills-tabContent" style="overflow: auto; ">
      <div
        class="col-lg-6"
        *ngFor="let item of objListNotification">
        <div class="mb-2">
          <app-notification-card
            [id]="item.id"
            [snooze]="item.snooze"
            [reject]="item.reject"
            [accept]="item.accept"
            [moreInfo]="item.moreInfo"
            [notificationColor]="item.notificationColor"
            [title]="item.title"
            [notificationText]="item.notificationText"
            [moduleId]="item.moduleId"
            [type]="item.type"
            [creationDate]="item.creationDate"
            (eventClickMoreInfo)="onClickMoreInfo($event, item)"
            (eventClickSeeDocument)="onClickSeeDocument($event, item.notificationTypeId, item.solicitudeNotificationId)"
            (eventClickSnooze)="onClickSnooze($event)"
            (eventClickReject)="onClickReject($event, item.notificationTypeId, item.solicitudeNotificationId)"
            (eventClickAccept)="onClickAccept($event, item.notificationTypeId, item.solicitudeNotificationId)"
          ></app-notification-card>
        </div>
      </div>
    </div>
  </div>
</section>
<app-notification-modal
            [showDialog]="showDialog"
            (eventHidden)="onHidden($event)"
            [title]="title"
            [id]="id"
            [notificationText]="notificationText"
            [notificationColor]="notificationColor"
            [moreInfo]="moreInfo"
            [commentary]="commentary"
            [snooze]="snooze"
            [reject]="reject"
            [accept]="accept"
            [moduleId]="moduleId"
            [type]="type"
            (eventClickSnooze)="onClickSnooze($event)"
            (eventClickReject)="onClickReject($event, notificationTypeId, solicitudeNotificationId)"
            (eventClickAccept)="onClickAccept($event, notificationTypeId, solicitudeNotificationId)"
            (eventClickSeeDocument)="onClickSeeDocument($event, notificationTypeId,solicitudeNotificationId)"
  >

</app-notification-modal>
<p-toast></p-toast>
