import { environment } from '../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { HierarchyAdvancedFiltersService } from './hierarchy-advanced-filters.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomainService } from '@app/@shared/services/domain.service';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeSecurity } from '@app/@shared/enums/domain-type-security.enums';
import { Util } from '../../@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { MessageService } from 'primeng/api';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { IHierarchyFiltersMaterials } from './interfaces/hierarchy-filters-materials.interface';
import { ISubFilters } from './interfaces/sub-filters.interface';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { FilterDataModel } from './models/filters-data.model';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { DTOFilterMaterialsModel } from './models/DTOFilterMaterialsModel';
import { IDTOFilterMaterialsHierarchy } from './interfaces/IDTOFilterMaterialsHierarchy';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';

@Component({
  selector: 'app-hierarchy-advanced-filters',
  templateUrl: './hierarchy-advanced-filters.component.html',
  styleUrls: ['./../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService],
})

export class HierarchyAdvancedFiltersComponent implements OnInit {
  @Input('isMultiFilter') isMultiFilter: boolean;
  @Input('isProcess') isProcess: number;
  @Input('isVisible') isVisible: boolean;
  @Input('sector') sector: string[];
  @Input('requireSector') requireSector = false;
  @Input('priceless') priceless = false;
  @Input('editFiltersObjectList') editFiltersObjectList: IHierarchyFiltersMaterials[] = [];
  @Input('setDefaultMaterialType') setDefaultMaterialType: string[] = [];
  @Input('rolePermissionBtn') rolePermissionBtn: boolean;
  @Input('typeCoreMaster') typeCoreMaster: TypeCoreMaster;

  @Output('eventClickFilters') eventButton: EventEmitter<any> = new EventEmitter();

  private readonly filterService: HierarchyAdvancedFiltersService;
  private readonly spinner: NgxSpinnerService;
  private readonly domainService: DomainService;
  private readonly parameterService: ManageParametersService;

  public get comercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  public DTOFilterMaterialsObj: DTOFilterMaterialsModel;
  public DTOFilterMaterialsHierarchiObj: IDTOFilterMaterialsHierarchy[] = [];

  public advancedFiltersObjectList: IHierarchyFiltersMaterials[] = [];
  public subFiltersObjectList: ISubFilters[] = [];
  public isAdd = true;
  public id: string;
  public index: number;
  public levelContentSelected = '';
  public levelSelected = 0;
  public existsOthersFilters = false;
  public selectedSubFilters: ISubFilters[] = [];
  public toEditFilter = false;
  public filterToEdit: IHierarchyFiltersMaterials;
  public rowFilterToEdit: number = null;

  public categoriesFilterList: ICombo[] = [];
  public categoryMultiSelected: string[] = [];
  public categorySelected: string;

  public packagingFilterList: ICombo[] = [];
  public packingMultiSelected: string[] = [];
  public packingSelected: string;

  public contentFilterList: ICombo[] = [];
  public contentMultiSelected: string[] = [];
  public contentSelected: string;

  public returnabilityFilterList: ICombo[] = [];
  public returnabilityMultiSelected: string[] = [];
  public returnabilitySelected: string;

  public denominationFilterList: ICombo[] = [];
  public denominationMultiSelected: string[] = [];
  public denominationSelected: string;

  // SCode
  public codeFilterList: ICombo[] = [];
  public codeFilterListTmp: ICombo[] = [];
  public codeMultiSelected: string[] = [];

  // SProductBrandDescription - marca
  public brandFilterList: ICombo[] = [];
  public brandMultiSelected: string[] = [];

  // SCategoryGroupDescription - gupo de categoria
  public categoryGroupDescriptionFilterList: ICombo[] = [];
  public categoryGroupDescriptionMultiSelected: string[] = [];

  // SCompositionDescription
  public compositionDescriptionFilterList: ICombo[] = [];
  public compositionDescriptionMultiSelected: string[] = [];

  // SFranchiseOrOwnBrandDescription
  public franchiseOrOwnBrandDescriptionFilterList: ICombo[] = [];
  public franchiseOrOwnBrandDescriptionMultiSelected: string[] = [];

  // SMeasurementUnitCodeBase - unidad de medida
  public measurementUnitCodeBaseFilterList: ICombo[] = [];
  public measurementUnitCodeBaseMultiSelected: string[] = [];

  // SIndustrySegmentDescription - segmento industria
  public industrySegmentDescriptionFilterList: ICombo[] = [];
  public industrySegmentDescriptionMultiSelected: string[] = [];

  // SNameSegmentDescription - nombre segmento
  public nameSegmentDescriptionFilterList: ICombo[] = [];
  public nameSegmentDescriptionMultiSelected: string[] = [];

  // SProductFlavorDescription - sabor
  public productFlavorDescriptionFilterList: ICombo[] = [];
  public productFlavorDescriptionMultiSelected: string[] = [];

  // SSegmentSizaDescription - segmento tamaño
  public segmentSizaDescriptionFilterList: ICombo[] = [];
  public segmentSizaDescriptionMultiSelected: string[] = [];

  // SSubBrandDescription - submarca
  public subBrandDescriptionFilterList: ICombo[] = [];
  public subBrandDescriptionMultiSelected: string[] = [];

  // STypeOfPriceDescription - tipo precio
  public typeOfPriceDescriptionFilterList: ICombo[] = [];
  public typeOfPriceDescriptionMultiSelected: string[] = [];

  // SUenDescription - uen
  public uenDescriptionFilterList: ICombo[] = [];
  public uenDescriptionMultiSelected: string[] = [];

  // SZofeDescription - Zofe
  public zofeDescriptionFilterList: ICombo[] = [];
  public zofeDescriptionMultiSelected: string[] = [];

  //Material Type
  public materialTypeFilterList: ICombo[] = [];
  public materialTypeSelected: string;
  public enableType = false;

  public rolePermission: any = RolePermissionEnum;
  public rolePermissionAux: any = RolePermissionEnum;
  public sectorCommercialActivityParam = false;
  public typeEdition = false;

  constructor(filterService: HierarchyAdvancedFiltersService,
    spinner: NgxSpinnerService,
    domainService: DomainService,
    parameterService: ManageParametersService) {
    this.filterService = filterService;
    this.spinner = spinner;
    this.domainService = domainService;
    this.parameterService = parameterService;
  }

  async ngOnInit() {
    this.clearSubFilters();
    this.getSubFiltersList();
    this.getParametersValidateSector();
    await this.getListMaterialType();
    this.rolePermissionAux = this.rolePermissionBtn ? RolePermissionEnum.read : RolePermissionEnum.create;
  }

  /**
   * @description This method is to get parameter sector commercial activity
   * @author Diego Mauricio Cortés
   * @sprint 22
   */
  async getParametersValidateSector(): Promise<void> {
    this.spinner.show();
    await this.parameterService.getAll().toPromise().then((dt) => {
      const param = dt.data.filter((prm) => prm.id === ManagementParameterEnum.sectorCommercialActivity);
      if (param) {
        this.sectorCommercialActivityParam = param[0].status;
      }
      this.spinner.hide();
    });
  }

  async getListMaterialType() {
    this.spinner.show();
    await this.filterService.getMaterialType().toPromise().then(
      (resp) => {
        if (this.setDefaultMaterialType.length >= 1) {
          this.enableType = true;
          resp.data.forEach((item: ICombo) => {
            if (this.setDefaultMaterialType.some((x) => x === item.label)) {
              this.materialTypeFilterList.push(item);
            }
          });
        } else {
          this.materialTypeFilterList = [...resp.data];
          this.enableType = false;
        }

      }).finally(() => {
        this.spinner.hide();
      });
  }

  /**
   * @description This method is to verify changes in parent data
   * @param changes
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const changeInEditFiltersObjectList: SimpleChange = changes['editFiltersObjectList'];
    if (changeInEditFiltersObjectList !== undefined && changeInEditFiltersObjectList.currentValue.length > 0) {
      this.advancedFiltersObjectList = changeInEditFiltersObjectList.currentValue
        .map((resp: IHierarchyFiltersMaterials, index: number) => {
          return {
            group: `Grupo ${index + 1}`,
            id: '',
            hierarchyFilter: resp.hierarchyFilter,
            subFilters: resp.subFilters,
            type: resp.type
          };
        });
    }
    const changeInIsVisible: SimpleChange = changes['isVisible'];
    if (changeInIsVisible !== undefined && changeInIsVisible.currentValue !== null) {
      this.isVisible = changeInIsVisible.currentValue;
    }
    const changeSector: SimpleChange = changes['sector'];
    if (changeSector !== undefined && changeSector.currentValue !== null) {
      this.sector = changeSector.currentValue;
    }
  }

  /**
    * @description This method is to be visible the advanced filter modal
    * @author Diego Mauricio Cortés
    * @sprint 9
    */
  openAdvancedFilters() {
    if (this.sectorCommercialActivityParam) {
      if (this.requireSector) {
        if (!this.sector || this.sector.length === 0) {
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.warning(
            language === 'es-ES' ? 'Dato Requerido' : 'Data Required',
            language === 'es-ES' ? 'El sector es requerido para realizar el filtro' : 'The sector is required to perform the filter');
          return;
        }
      }
    }
    this.toEditFilter = false;
    this.filterToEdit = null;
    this.clearFilters();
    this.materialTypeSelected = '';
    if (this.materialTypeFilterList.length === 1) {
      this.materialTypeSelected = this.materialTypeFilterList[0].label;
    }
    this.changeMaterialType();
    this.isVisible = true;
  }

  changeMaterialType() {
    this.getFilters_HierarchyMaterials(1);
  }

  /**
   * @description This method is to be invisible the advanced filter modal
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  closeAdvancedFilters() {
    this.isVisible = false;
    this.typeEdition = false;
  }

  /**
   * @description This method is to clear selected list to show for select hierarchy.
   *
   * @author Diego Cortes
   * @sprint 16
   */
  private clearFilterList(selectedLevelLocal: number): void {
    switch (selectedLevelLocal) {
      case 1:
        this.categoriesFilterList = [];
        break;
      case 2:
        this.packagingFilterList = [];
        break;
      case 3:
        this.contentFilterList = [];
        break;
      case 4:
        this.returnabilityFilterList = [];
        break;
      case 5:
        this.denominationFilterList = [];
        break;
      default:
        break;
    }
  }

  /**
   * @description This method is to clear hierarchy filters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  clearFilters(isInitial = true): void {
    this.materialTypeSelected = '';
    if (isInitial) { this.categoriesFilterList = []; }
    this.categoryMultiSelected = [];
    this.categorySelected = null;
    this.packagingFilterList = [];
    this.packingMultiSelected = [];
    this.packingSelected = null;
    this.contentFilterList = [];
    this.contentMultiSelected = [];
    this.contentSelected = null;
    this.returnabilityFilterList = [];
    this.returnabilityMultiSelected = [];
    this.returnabilitySelected = null;
    this.denominationFilterList = [];
    this.denominationMultiSelected = [];
    this.denominationSelected = null;
    this.clearSubFilters();
  }


  /**
   * @description This method is to clear subFilters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  clearSubFilters(): void {
    this.codeFilterList = [];
    this.codeFilterListTmp = [];
    this.selectedSubFilters = [];
    this.codeMultiSelected = [];
    this.brandFilterList = [];
    this.brandMultiSelected = [];
    this.categoryGroupDescriptionFilterList = [];
    this.categoryGroupDescriptionMultiSelected = [];
    this.compositionDescriptionFilterList = [];
    this.compositionDescriptionMultiSelected = [];
    this.franchiseOrOwnBrandDescriptionFilterList = [];
    this.franchiseOrOwnBrandDescriptionMultiSelected = [];
    this.measurementUnitCodeBaseFilterList = [];
    this.measurementUnitCodeBaseMultiSelected = [];
    this.industrySegmentDescriptionFilterList = [];
    this.industrySegmentDescriptionMultiSelected = [];
    this.nameSegmentDescriptionFilterList = [];
    this.nameSegmentDescriptionMultiSelected = [];
    this.productFlavorDescriptionFilterList = [];
    this.productFlavorDescriptionMultiSelected = [];
    this.segmentSizaDescriptionFilterList = [];
    this.segmentSizaDescriptionMultiSelected = [];
    this.subBrandDescriptionFilterList = [];
    this.subBrandDescriptionMultiSelected = [];
    this.typeOfPriceDescriptionFilterList = [];
    this.typeOfPriceDescriptionMultiSelected = [];
    this.uenDescriptionFilterList = [];
    this.uenDescriptionMultiSelected = [];
    this.zofeDescriptionFilterList = [];
    this.zofeDescriptionMultiSelected = [];
  }

  getFilters_HierarchyMaterials(selectedLevel: number) {
    if (this.packingMultiSelected.length === 0 && selectedLevel === 3 && (this.packingSelected === '' || this.packingSelected == null)) {
      selectedLevel = selectedLevel - 1;
    } else if (this.contentMultiSelected.length === 0 && selectedLevel === 4 && (this.contentSelected === '' || this.contentSelected == null)) {
      selectedLevel = selectedLevel - 1;
    } else if (this.returnabilityMultiSelected.length === 0 && selectedLevel === 5 && (this.returnabilitySelected === '' || this.returnabilitySelected == null)) {
      selectedLevel = selectedLevel - 1;
    } else if (this.denominationMultiSelected.length === 0 && selectedLevel === 6 && (this.denominationSelected === '' || this.denominationSelected == null)) {
      selectedLevel = selectedLevel - 1;
    }
    this.levelSelected = selectedLevel;

    this.spinner.show();
    if (this.typeEdition === true) {
      for (let i = selectedLevel; i <= 6; i++) {
        this.clearControlsWhenEmpty(i);
      }
    }

    for (let i = selectedLevel; i <= 6; i++) {
      const data: FilterDataModel = this.prepareDataToFilters(i);
      if (data) {
        this.getHierarchyFiltersMaterials(i, data);
        if (this.typeEdition === true) {
          this.levelSelected = i;
        }
      }
      this.getHierarchySubFiltersMaterials(i);
    }
  }

  private prepareDataToFilters(selectedLevelLocal: number): FilterDataModel {
    this.getContentFilterByLevel(selectedLevelLocal);
    if (this.DTOFilterMaterialsObj && this.DTOFilterMaterialsObj.hierarchy && this.DTOFilterMaterialsObj.hierarchy.length >= selectedLevelLocal - 1) {
      if (this.DTOFilterMaterialsObj.hierarchy[0].content === '') {
        this.clearControlsWhenEmpty(selectedLevelLocal);
      }
      const filtersObj = JSON.stringify([{
        level: selectedLevelLocal,
        hierarchy: this.DTOFilterMaterialsObj.hierarchy,
        sector: this.sector ? this.sector.join(',') : "",
        type: this.materialTypeSelected
      }]);
      const data: FilterDataModel = new FilterDataModel(
        filtersObj,
        environment.conditionTable,
        environment.conditionClassCode,
        this.priceless
      );
      return data;
    }
    else {
      this.clearControlsWhenEmpty(selectedLevelLocal);
    }
  }

  clearControlsWhenEmpty(selectedLevelLocal: number) {
    switch (selectedLevelLocal) {
      case 2:
        this.packingSelected = '';
        this.packagingFilterList = [];
        this.packingMultiSelected = [];
        this.contentSelected = '';
        this.contentFilterList = [];
        this.contentMultiSelected = [];
        this.returnabilitySelected = '';
        this.returnabilityFilterList = [];
        this.returnabilityMultiSelected = [];
        this.denominationSelected = '';
        this.denominationFilterList = [];
        this.denominationMultiSelected = [];
        break;
      case 3:
        this.contentSelected = '';
        this.contentFilterList = [];
        this.contentMultiSelected = [];
        this.returnabilitySelected = '';
        this.returnabilityFilterList = [];
        this.returnabilityMultiSelected = [];
        this.denominationSelected = '';
        this.denominationFilterList = [];
        this.denominationMultiSelected = [];
        break;
      case 4:
        this.returnabilitySelected = '';
        this.returnabilityFilterList = [];
        this.returnabilityMultiSelected = [];
        this.denominationSelected = '';
        this.denominationFilterList = [];
        this.denominationMultiSelected = [];
        break;
      case 5:
        this.denominationSelected = '';
        this.denominationFilterList = [];
        this.denominationMultiSelected = [];
        break;
      default:
        break;
    }
  }

  /**
   * @description This method is to prepare value to send content for endpoint to search filters
   * @author Diego Mauricio Cortes
   * @sprint 9
   */
  private getContentFilterByLevel(selectedLevelLocal: number) {
    this.DTOFilterMaterialsHierarchiObj = [];
    switch (selectedLevelLocal) {
      case 1:
      case 2:
        this.packagingFilterList = [];
        this.getMultiselectToFilter('category', this.categoryMultiSelected, this.categorySelected);
        break;
      case 3:
        this.contentFilterList = [];
        this.getMultiselectToFilter('category', this.categoryMultiSelected, this.categorySelected);
        this.getMultiselectToFilter('package', this.packingMultiSelected, this.packingSelected);
        break;
      case 4:
        this.returnabilityFilterList = [];
        this.getMultiselectToFilter('category', this.categoryMultiSelected, this.categorySelected);
        this.getMultiselectToFilter('package', this.packingMultiSelected, this.packingSelected);
        this.getMultiselectToFilter('content', this.contentMultiSelected, this.contentSelected);
        break;
      case 5:
        this.denominationFilterList = [];
        this.getMultiselectToFilter('category', this.categoryMultiSelected, this.categorySelected);
        this.getMultiselectToFilter('package', this.packingMultiSelected, this.packingSelected);
        this.getMultiselectToFilter('content', this.contentMultiSelected, this.contentSelected);
        this.getMultiselectToFilter('returnability', this.returnabilityMultiSelected, this.returnabilitySelected);
        break;
      case 6:
        this.getMultiselectToFilter('category', this.categoryMultiSelected, this.categorySelected);
        this.getMultiselectToFilter('package', this.packingMultiSelected, this.packingSelected);
        this.getMultiselectToFilter('content', this.contentMultiSelected, this.contentSelected);
        this.getMultiselectToFilter('returnability', this.returnabilityMultiSelected, this.returnabilitySelected);
        this.getMultiselectToFilter('Denomination', this.denominationMultiSelected, this.denominationSelected);
        break;
      default:
        break;
    }
  }

  private getMultiselectToFilter(type: string, multiSelected: string[], selected: string) {
    if (this.isMultiFilter && !this.toEditFilter) {
      this.insertMultiSelectToFilter(multiSelected, type);
    } else {
      this.insertEditSelectToFilter(selected, type);
    }
  }

  public insertMultiSelectToFilter(multiSelect: string[], type: string) {
    if (multiSelect.length > 0 || type === 'category') {
      this.DTOFilterMaterialsHierarchiObj.push({
        content: multiSelect.join(';'),
        type: type
      });
      this.DTOFilterMaterialsObj = new DTOFilterMaterialsModel(
        this.DTOFilterMaterialsHierarchiObj
      );
    }
  }

  public insertEditSelectToFilter(EditSelect: string, type: string) {
    if (EditSelect !== null && EditSelect !== '' || type === 'category') {
      this.DTOFilterMaterialsHierarchiObj.push({
        content: EditSelect ? EditSelect : '',
        type: type
      });
      this.DTOFilterMaterialsObj = new DTOFilterMaterialsModel(
        this.DTOFilterMaterialsHierarchiObj
      );
    }
  }

  /**
   * @description This method is to prepare data for send to search filters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private prepareDataToGetHierarchyFilters(selectedLevelLocal: number): FilterDataModel {
    this.getContentFilterByLevel(selectedLevelLocal);
    const filtersObj = JSON.stringify([{
      level: selectedLevelLocal,
      hierarchy: this.DTOFilterMaterialsObj.hierarchy,
      type: this.materialTypeSelected
    }]);
    const data: FilterDataModel = new FilterDataModel(
      filtersObj,
      environment.conditionTable,
      environment.conditionClassCode,
      this.priceless
    );
    return data;
  }

  /**
   * @description This method is to prepare data for send to search filters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private prepareDataToGetHierarchySubFilters(selectedLevelLocal: number): FilterDataModel {
    const filtersObj = JSON.stringify([{
      level: selectedLevelLocal,
      hierarchy: this.DTOFilterMaterialsObj.hierarchy,
      type: this.materialTypeSelected
    }]);
    const data: FilterDataModel = new FilterDataModel(
      filtersObj,
      environment.conditionTable,
      environment.conditionClassCode,
      this.priceless
    );
    return data;
  }

  /**
   * @description This method is to get the materials hierarchy filters
   * @param data Data to get Hierarchy Filters Materials
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private getHierarchyFiltersMaterials(selectedLevelLocal: number, data: any): void {
    this.spinner.show();
    this.filterService.hierarchyFiltersMaterials(JSON.stringify(data)).subscribe(
      (response) => {
        if (response.status) {
          this.prepareFilterDataShow(selectedLevelLocal, response.data);
        }
      }
    );
  }

  /**
   * @description This method is to prepare filters to show by hierarchy
   * @param data Data to prepare filters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private prepareFilterDataShow(selectedLevelLocal: number, data: any) {
    this.clearFilterList(selectedLevelLocal);
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const filter = {
        value: element.description,
        label: element.description
      };
      switch (selectedLevelLocal) {
        case 1:
          this.categoriesFilterList.push(filter);
          break;
        case 2:
          this.packagingFilterList.push(filter);
          break;
        case 3:
          this.contentFilterList.push(filter);
          break;
        case 4:
          this.returnabilityFilterList.push(filter);
          break;
        case 5:
          this.denominationFilterList.push(filter);
          break;
        default:
          break;
      }
    }
    this.orderLists();
    this.maintainSelectedValues(selectedLevelLocal);
  }

  orderLists() {
    if (this.contentFilterList)
    {
      const regex = /[a-zA-Z ]/g;
      this.contentFilterList.sort(function(x,y)
      {
        var xp = `${x.value?.substring(x.value.search(regex))}`;
        var yp = `${y.value?.substring(y.value.search(regex))}`;
        return xp == yp ? 0 : xp < yp ? -1 : 1;
      });
    }

    if (this.denominationFilterList)
    {
      this.denominationFilterList.sort(function(a, b) {
        return Number(a.value?.substring(a.value?.toLowerCase().indexOf('x')+1)) - 
        Number(b.value?.substring(b.value?.toLowerCase().indexOf('x')+1)); 
      });
    }
  }

  maintainSelectedValues(selectedLevelLocal: number) {
    switch (selectedLevelLocal) {
      case 2:
        var findItemToAddArray = this.FindItemsSelectedToArray(this.packingMultiSelected, this.packagingFilterList);
        if (findItemToAddArray.length > 0) {
          this.packingMultiSelected = []
          for (let i = 0; i < findItemToAddArray.length; i++) {
            this.packingMultiSelected.push(findItemToAddArray[i])
          }
        }
        break;
      case 3:
        var findItemToAddArray = this.FindItemsSelectedToArray(this.contentMultiSelected, this.contentFilterList);
        if (findItemToAddArray.length > 0) {
          this.contentMultiSelected = []
          for (let i = 0; i < findItemToAddArray.length; i++) {
            this.contentMultiSelected.push(findItemToAddArray[i])
          }
        }
        break;
      case 4:
        var findItemToAddArray = this.FindItemsSelectedToArray(this.returnabilityMultiSelected, this.returnabilityFilterList);
        if (findItemToAddArray.length > 0) {
          this.returnabilityMultiSelected = []
          for (let i = 0; i < findItemToAddArray.length; i++) {
            this.returnabilityMultiSelected.push(findItemToAddArray[i])
          }
        }
        break;
      case 5:
        var findItemToAddArray = this.FindItemsSelectedToArray(this.denominationMultiSelected, this.denominationFilterList);
        if (findItemToAddArray.length > 0) {
          this.denominationMultiSelected = []
          for (let i = 0; i < findItemToAddArray.length; i++) {
            this.denominationMultiSelected.push(findItemToAddArray[i])
          }
        }
        break;
      default:
        break;
    }
  }

  FindItemsSelectedToArray(ListItems_MultiSelected: string[], ListItems_FilterList: ICombo[]) {
    var findItemToAddArray: string[] = [];
    for (var i = 0; i < ListItems_MultiSelected.length; i++) {
      var findItemToDelete = ListItems_FilterList.findIndex(x => x.value === ListItems_MultiSelected[i]);
      if (findItemToDelete !== -1) {
        findItemToAddArray.push(ListItems_MultiSelected[i]);
      }
    }
    return findItemToAddArray;
  }

  /**
   * @description This method for get sub filters object list
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  public getSubFiltersList(): void {
    this.domainService
      .getAllByDomainType(Microservice.Security, DomainTypeSecurity.TypeFilterCreation)
      .subscribe(
        (response) => {
          this.subFiltersObjectList = response.data.map((item) => {
            return {
              content: item.description,
              type: item.code,
            };
          });
        },
        (error) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }

  public dataSubFilter: string;
  /**
   * @description This method is to get hierarchy materials subFilters
   * @param data Data to search hierarchy materials subFilters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  getHierarchySubFiltersMaterials(selectedLevelLocal: number): void {
    this.clearSubFilters();
    this.spinner.show();
    let data: FilterDataModel = this.prepareDataToSubFilters();
    if (this.dataSubFilter !== data.filters) {
      this.dataSubFilter = data.filters;
      this.filterService.hierarchySubFiltersMaterials(JSON.stringify(data)).subscribe(
        (response) => {
          if (response.message === 'OK') {
            this.prepareDataSubFilters(response.data);
            this.existsOthersFilters = true;
          } else {
            this.existsOthersFilters = false;
          }
          if (selectedLevelLocal === 6) {
            this.spinner.hide();
          }
        }, (error) => {
          this.existsOthersFilters = false;
          this.clearSubFilters();
          this.spinner.hide();
        }
      );
    }
    else {
      this.spinner.hide();
    }
  }

  private prepareDataToSubFilters(): FilterDataModel {
    let actualLevel = this.obtActualLevelByFilter();
    const filtersObj = JSON.stringify([{
      level: actualLevel,
      hierarchy: this.DTOFilterMaterialsObj.hierarchy,
      type: this.materialTypeSelected
    }]);
    const data: FilterDataModel = new FilterDataModel(
      filtersObj,
      environment.conditionTable,
      environment.conditionClassCode,
      this.priceless
    );
    return data;
  }

  private obtActualLevelByFilter() {
    let actualLevel = this.DTOFilterMaterialsObj.hierarchy.length;
    if (this.DTOFilterMaterialsObj.hierarchy.length === 1) {
      if (this.DTOFilterMaterialsObj.hierarchy[0].content === '') {
        actualLevel = 1;
      }
      else {

        actualLevel = !this.toEditFilter ? 2 : actualLevel;
      }
    }
    return actualLevel;
  }

  /**
   * @description This method prepare data subFilter data
   * @param data Data to search subFilters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private prepareDataSubFilters(data: any[]) {
    this.clearSubFilters();

    data.forEach((element: any) => {
      let filter;
      if (element.description === 'SCodeDescription') {
        filter = {
          label: [this.materialCodeZeroLess(element.value.split('-')[0]), element.value.split('-')[1]].join('-'),
          value: JSON.stringify({
            content: element.value.split('-')[0],
            type: 'SCode',
          }),
          order: element.value?.split('-')[2] ?? ''
        }
      } else {
        filter = {
          label: element.value,
          value: JSON.stringify({
            content: element.value,
            type: element.description
          }),
          order: element.value?.split('-')[2] ?? ''
        }
      }
      this.validityDataSubFilters(element, filter);
    });
  }

  private validityDataSubFilters(element: any, filter: { label: any; value: string; order?: string }) {
    switch (element.description) {
      case 'SCodeDescription':
        this.codeFilterList.push(filter);
        this.codeFilterListTmp.push(filter);
        break;
      case 'SProductBrandDescription':
        this.brandFilterList.push(filter);
        break;
      case 'SCategoryGroupDescription':
        this.categoryGroupDescriptionFilterList.push(filter);
        break;
      case 'SCompositionDescription':
        this.compositionDescriptionFilterList.push(filter);
        break;
      case 'SFranchiseOrOwnBrandDescription':
        this.franchiseOrOwnBrandDescriptionFilterList.push(filter);
        break;
      case 'SMeasurementUnitCodeBase':
        this.measurementUnitCodeBaseFilterList.push(filter);
        break;
      case 'SIndustrySegmentDescription':
        this.industrySegmentDescriptionFilterList.push(filter);
        break;
      case 'SNameSegmentDescription':
        this.nameSegmentDescriptionFilterList.push(filter);
        break;
      case 'SProductFlavorDescription':
        this.productFlavorDescriptionFilterList.push(filter);
        break;
      case 'SSegmentSizaDescription':
        this.segmentSizaDescriptionFilterList.push(filter);
        break;
      case 'SSubbrandDescription':
        this.subBrandDescriptionFilterList.push(filter);
        break;
      case 'STypeOfPriceDescription':
        this.typeOfPriceDescriptionFilterList.push(filter);
        break;
      case 'SUenDescription':
        this.uenDescriptionFilterList.push(filter);
        break;
      case 'SZofeDescription':
        this.zofeDescriptionFilterList.push(filter);
        break;
      default:
        break;
    }
  }

  /**
   * @description This method is to prepare selected sub filter
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  prepareSelectedSubFilter() {
    this.selectedSubFilters = [];
    if (this.codeMultiSelected.length > 0) {
      let code = '';
      const type = JSON.parse(this.codeMultiSelected[0]).type;
      this.codeMultiSelected.forEach((codeStr: string) => {
        const codeJson = JSON.parse(codeStr);
        code = code !== '' ? `${code};${codeJson.content}` : codeJson.content;
      });

      this.selectedSubFilters.push({
        content: code,
        type: type
      });
    }

    if (this.brandMultiSelected.length > 0) {
      let brandMulti = '';
      const type = JSON.parse(this.brandMultiSelected[0]).type;
      this.brandMultiSelected.map((brand: string) => {
        const brandJson = JSON.parse(brand);
        brandMulti = brandMulti !== '' ? `${brandMulti};${brandJson.content}` : brandJson.content;
      });
      this.selectedSubFilters.push({
        content: brandMulti,
        type: type
      });
    }

    if (this.categoryGroupDescriptionMultiSelected.length > 0) {
      let categoryGroup = '';
      const type = JSON.parse(this.categoryGroupDescriptionMultiSelected[0]).type;
      this.categoryGroupDescriptionMultiSelected.forEach((category: string) => {
        const categoryJson = JSON.parse(category);
        categoryGroup = categoryGroup !== '' ? `${categoryGroup};${categoryJson.content}` : categoryJson.content;
      });
      this.selectedSubFilters.push({
        content: categoryGroup,
        type: type
      });
    }

    if (this.compositionDescriptionMultiSelected.length > 0) {
      let compositionDesc = '';
      const type = JSON.parse(this.compositionDescriptionMultiSelected[0]).type;
      this.compositionDescriptionMultiSelected.forEach((composition: string) => {
        const compositionJson = JSON.parse(composition);
        compositionDesc = compositionDesc !== '' ? `${compositionDesc};${compositionJson.content}` : compositionJson.content;
      });
      this.selectedSubFilters.push({
        content: compositionDesc,
        type: type
      });
    }

    if (this.franchiseOrOwnBrandDescriptionMultiSelected.length > 0) {
      let franchiseDesc = '';
      const type = JSON.parse(this.franchiseOrOwnBrandDescriptionMultiSelected[0]).type;
      this.franchiseOrOwnBrandDescriptionMultiSelected.forEach((franchise: string) => {
        const franchiseJson = JSON.parse(franchise);
        franchiseDesc = franchiseDesc !== '' ? `${franchiseDesc};${franchiseJson.content}` : franchiseJson.content;
      });
      this.selectedSubFilters.push({
        content: franchiseDesc,
        type: type
      });
    }

    if (this.measurementUnitCodeBaseMultiSelected.length > 0) {
      let measurement = '';
      const type = JSON.parse(this.measurementUnitCodeBaseMultiSelected[0]).type;
      this.measurementUnitCodeBaseMultiSelected.forEach((measurementStr: string) => {
        const measurementJson = JSON.parse(measurementStr);
        measurement = measurement !== '' ? `${measurement};${measurementJson.content}` : measurementJson.content;
      });
      this.selectedSubFilters.push({
        content: measurement,
        type: type
      });
    }
    this.checkDescriptionMultiSelect();
  }

  private checkDescriptionMultiSelect() {
    if (this.industrySegmentDescriptionMultiSelected.length > 0) {
      let industrySeg = '';
      const type = JSON.parse(this.industrySegmentDescriptionMultiSelected[0]).type;
      this.industrySegmentDescriptionMultiSelected.forEach((industry: string) => {
        const industryJson = JSON.parse(industry);
        industrySeg = industrySeg !== '' ? `${industrySeg};${industryJson.content}` : industryJson.content;
      });
      this.selectedSubFilters.push({
        content: industrySeg,
        type: type
      });
    }

    if (this.nameSegmentDescriptionMultiSelected.length > 0) {
      let nameSegment = '';
      const type = JSON.parse(this.nameSegmentDescriptionMultiSelected[0]).type;
      this.nameSegmentDescriptionMultiSelected.forEach((nameSeg) => {
        const nameSegJson = JSON.parse(nameSeg);
        nameSegment = nameSegment !== '' ? `${nameSegment};${nameSegJson.content}` : nameSegJson.content;
      });
      this.selectedSubFilters.push({
        content: nameSegment,
        type: type
      });
    }

    if (this.productFlavorDescriptionMultiSelected.length > 0) {
      let productFlavor = '';
      const type = JSON.parse(this.productFlavorDescriptionMultiSelected[0]).type;
      this.productFlavorDescriptionMultiSelected.forEach((productFlav: string) => {
        const productFlavJson = JSON.parse(productFlav);
        productFlavor = productFlavor !== '' ? `${productFlavor};${productFlavJson.content}` : productFlavJson.content;
      });
      this.selectedSubFilters.push({
        content: productFlavor,
        type: type
      });
    }

    if (this.segmentSizaDescriptionMultiSelected.length > 0) {
      let segmentSize = '';
      const type = JSON.parse(this.segmentSizaDescriptionMultiSelected[0]).type;
      this.segmentSizaDescriptionMultiSelected.forEach((segmentSizeStr: string) => {
        const segmentSizeJson = JSON.parse(segmentSizeStr);
        segmentSize = segmentSize !== '' ? `${segmentSize};${segmentSizeJson.content}` : segmentSizeJson.content;
      });
      this.selectedSubFilters.push({
        content: segmentSize,
        type: type
      });
    }

    if (this.typeOfPriceDescriptionMultiSelected.length > 0) {
      let typeOfPrice = '';
      const type = JSON.parse(this.typeOfPriceDescriptionMultiSelected[0]).type;
      this.typeOfPriceDescriptionMultiSelected.forEach((typeOfPriceStr: string) => {
        const typeOfPriceJson = JSON.parse(typeOfPriceStr);
        typeOfPrice = typeOfPrice !== '' ? `${typeOfPrice};${typeOfPriceJson.content}` : typeOfPriceJson.content;
      });
      this.selectedSubFilters.push({
        content: typeOfPrice,
        type: type
      });
    }

    if (this.subBrandDescriptionMultiSelected.length > 0) {
      let subBrand = '';
      const type = JSON.parse(this.subBrandDescriptionMultiSelected[0]).type;
      this.subBrandDescriptionMultiSelected.forEach((subBrandStr: string) => {
        const subBrandJson = JSON.parse(subBrandStr);
        subBrand = subBrand !== '' ? `${subBrand};${subBrandJson.content}` : subBrandJson.content;
      });
      this.selectedSubFilters.push({
        content: subBrand,
        type: type
      });
    }

    if (this.typeOfPriceDescriptionMultiSelected.length > 8) {
      let typePrice = '';
      const type = JSON.parse(this.typeOfPriceDescriptionMultiSelected[0]).type;
      this.typeOfPriceDescriptionMultiSelected.forEach((typePriceStr: string) => {
        const typePriceJson = JSON.parse(typePriceStr);
        typePrice = typePrice !== '' ? `${typePrice};${typePriceJson.content}` : typePriceJson.content;
      });
      this.selectedSubFilters.push({
        content: typePrice,
        type: type
      });
    }

    if (this.uenDescriptionMultiSelected.length > 0) {
      let uen = '';
      const type = JSON.parse(this.uenDescriptionMultiSelected[0]).type;
      this.uenDescriptionMultiSelected.forEach((uenStr: string) => {
        const uenJson = JSON.parse(uenStr);
        uen = uen !== '' ? `${uen};${uenJson.content}` : uenJson.content;
      });
      this.selectedSubFilters.push({
        content: uen,
        type: type
      });
    }

    if (this.zofeDescriptionMultiSelected.length > 0) {
      let zofe = '';
      this.codeFilterList = [];

      const type = JSON.parse(this.zofeDescriptionMultiSelected[0]).type;
      this.zofeDescriptionMultiSelected.forEach((zofeStr: string) => {
        const zofeJson = JSON.parse(zofeStr);
        zofe = zofe !== '' ? `${zofe};${zofeJson.content}` : zofeJson.content;

        /*Filtrar lista de SKUS*/
        let codeFilterList = this.codeFilterListTmp.filter(x => x.order == zofeJson.content);
        this.codeFilterList = [...this.codeFilterList, ...codeFilterList];
      });

      this.selectedSubFilters.push({
        content: zofe,
        type: type
      });


      /*Filtra lista de skus seleccionados*/
      let codeMultiSelected: string[] = [];
      this.codeFilterList.forEach((item: ICombo) => {
        codeMultiSelected = [...codeMultiSelected, ...this.codeMultiSelected.filter(cm => JSON.parse(cm).content.includes(JSON.parse(item.value).content))];
      });
      this.codeMultiSelected = codeMultiSelected ?? [];
    }
    else {
      this.codeFilterList = this.codeFilterListTmp;
    }
  }

  /**
   * @description This method is to prepare dubFilters data to show in tool tip
   * @param filter filter to prepare data subFilters to show
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  prepareSubFiltersToShowInfoTable(filter: IHierarchyFiltersMaterials) {
    let message = '';
    filter.subFilters.forEach((subFilter: ISubFilters) => {
      const sFilterFound = this.subFiltersObjectList.find((sFil) => sFil.type === subFilter.type);
      if (sFilterFound) {
        if (sFilterFound.content === 'SKU') {
          message += `${sFilterFound.content}: \n`;
          const aux = subFilter.content.split(';');
          for (let i = 0; i < aux.length; i++) {
            message += `${aux[i]} `;
          }
          message += `\n\n`;
        } else {
          message += `${sFilterFound.content}: ${subFilter.content} \n\n`;
        }
      }
    });
    return message;
  }

  /**
   * @description This method is to validate filters selected
   * @param vFilter
   * @author Diego Mauricio Cortés
   * @sprint 9
   */

  validateFilter(data: any): void {
    this.filterService.hierarchyFiltersCheck(JSON.stringify(data)).subscribe((response) => {
      if (!this.isMultiFilter && this.advancedFiltersObjectList.length > 0 && !this.toEditFilter) {
        this.isVisible = false;
        this.onlyOneSearchFilterAllowed();
      } else {
        if (response.message === 'OK' && response.data.length > 0) {
          let validate = 0;
          this.advancedFiltersObjectList.forEach((filterResponse: any) => {
            const indxFound = response.data.filter((dataFound: any) => dataFound.dataHierarchy === filterResponse.hierarchyFilter && dataFound.type === filterResponse.type);
            if (indxFound.length > 0) {
              validate += 1;
            }
          });

          let indexFound = -1;
          let indicador = 0;
          let indexSubFilter = -1;
          let auxData = false;

          if (this.toEditFilter) {
            let isFindGroup = false;
            const elementEdit = this.advancedFiltersObjectList[this.rowFilterToEdit];
            this.advancedFiltersObjectList.forEach((x) => {
              if (!isFindGroup) {
                let auxSubFilter = '';
                let dataSubFilter = '';
                let valSubFiltros = -1;

                x.subFilters.forEach((x) => {
                  if (x.content != null)
                    auxSubFilter += x.content + '/';
                });
                response.data[0].subFilters.forEach((a: any) => {
                  if (a.content != null)
                    dataSubFilter += a.content + '/';
                });

                valSubFiltros = this.assignmentValSubFiltros(x, valSubFiltros);

                if (valSubFiltros === 0) {
                  ({ indexFound, indicador } = this.subFiltrosEqualTo0(x, response, indexFound, indicador, auxSubFilter, dataSubFilter));
                } else if (valSubFiltros > 0) {
                  ({ indexSubFilter, indicador } = this.subFiltrosLessThan(x, response, auxSubFilter, dataSubFilter, indexSubFilter, indicador));
                } else if (x.hierarchyFilter === response.data[0].dataHierarchy && auxSubFilter === dataSubFilter) {
                  auxData = true;
                }

                if (indicador === 1) {
                  this.validityIndicator1(indexFound, auxData, elementEdit, response);
                } else if (indicador === 2) {
                  this.validityIndicator2(indexSubFilter, auxData, elementEdit, response);
                } else if ((indexFound > 0 || indexSubFilter > 0) && indicador === 0) {
                  this.isVisible = false;
                  this.matchesAppliedFilters();
                  this.spinner.hide();
                  isFindGroup = true;
                  return;
                }
              }
            });
          } else {
            if (validate > 0) {
              this.isVisible = false;
              this.matchesAppliedFilters();
              this.spinner.hide();
              return;
            }
            if (this.advancedFiltersObjectList.length === 0) {
              this.advancedFiltersObjectList = response.data
                .map((resp: IHierarchyFiltersMaterials, index: number) => {
                  return {
                    group: `Grupo ${index + 1}`,
                    id: '',
                    hierarchyFilter: resp.dataHierarchy,
                    subFilters: resp.subFilters,
                    type: resp.type ? resp.type : this.materialTypeSelected
                  };
                });
            } else {
              this.validateFilterI(response);
            }
          }
          this.materialTypeSelected = '';
          this.clearSubFilters();
          this.isVisible = false;
          this.toEditFilter = false;
          this.rowFilterToEdit = null;
        } else {
          this.isVisible = false;
          this.thereAreNoFiltersInTheCombination();
        }
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.isVisible = false;
      this.messageError(error);
      return;
    });
  }

  /**
   * @description This method is to delete all.
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  deleteFilters() {
    //TODO
    // Falta borrado logico TypeCoreMaster
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.advancedFiltersObjectList = [];
      }
    });
  }

  /**
   * @description This method is to validate and add filters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  addFilter(): void {
    this.spinner.show();
    let actualLevel = this.obtActualLevelByFilter();
    this.levelSelected = this.toEditFilter ? actualLevel + 1 : this.levelSelected;
    const filtersObj = JSON.stringify([{
      level: (this.levelSelected === 0 ? actualLevel : this.levelSelected) - 1,
      hierarchy: this.DTOFilterMaterialsObj.hierarchy,
      subFilters: this.selectedSubFilters,
      type: this.materialTypeSelected
    }]);
    const data: FilterDataModel = new FilterDataModel(
      filtersObj,
      environment.conditionTable,
      environment.conditionClassCode,
      this.priceless
    );
    if (this.DTOFilterMaterialsObj.hierarchy.length > 0) {
      this.validateFilter(data);
    } else {
      this.isVisible = false;
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'Filtro no válido.' : 'Invalid filter');
    }
  }

  /**
   * @description This method is to prepare edit filter
   * @param rowIndex Index row selected
   * @param objectValue Object selected
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  async edit(rowIndex: number, objectValue: IHierarchyFiltersMaterials) {
    Util.confirmEdit().then(async (result: SweetAlertResult) => {
      if (result.value) {
        this.typeEdition = true;
        this.clearFilters();
        this.clearSubFilters();
        this.toEditFilter = true;
        this.rowFilterToEdit = rowIndex;
        this.filterToEdit = objectValue;
        const hierarchyFilters: string[] = objectValue.hierarchyFilter.split('/');
        this.materialTypeSelected = objectValue.type;
        await this.prepareEditFilter(hierarchyFilters, objectValue.subFilters);
        this.isVisible = true;
      }
    });
  }

  /**
   * @description This method is to prepare filters for edit this
   * @param hierarchyFilters
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  async prepareEditFilter(hierarchyFilters: string[], subFilters: ISubFilters[]) {
    this.spinner.show();
    const lengthSearch = !this.toEditFilter ? hierarchyFilters.length : hierarchyFilters.length + 1;
    let dataSub: FilterDataModel;
    for (let index = 0; index < lengthSearch; index++) {
      const element: string = hierarchyFilters[index < lengthSearch ? index : index - 1];
      const data = this.prepareDataToGetHierarchyFilters(index + 1);
      const dataSubFilter = this.prepareDataToGetHierarchySubFilters(index < 2 ? index + 2 : index);
      dataSub = dataSubFilter;
      const responseHierarchyFilter = await this.filterService.hierarchyFiltersMaterials(JSON.stringify(data)).toPromise();
      this.prepareFilterDataShow(index + 1, responseHierarchyFilter.data);
      if (element) {
        this.prepareEditI(index, lengthSearch, element);
      }
    }
    const responseSubFilter = await this.filterService.hierarchySubFiltersMaterials(JSON.stringify(dataSub)).toPromise();
    this.prepareDataSubFilters(responseSubFilter.data);
    if (responseSubFilter.data.length > 0) this.existsOthersFilters = true;
    if (subFilters.length > 0) {
      subFilters.forEach((sFilter: ISubFilters) => {
        if (sFilter.content) {
          const theSubFilters = sFilter.content.split(';');
          const newSubFilters = theSubFilters.map((sFil) => {
            return JSON.stringify({
              content: sFil,
              type: sFilter.type
            });
          });
          this.switchPrepareEdit(sFilter, newSubFilters);
          this.prepareSelectedSubFilter();
        }
      });
    }
    this.spinner.hide();
  }

  private switchPrepareEdit(sFilter: ISubFilters, newSubFilters: string[]) {
    switch (sFilter.type) {
      case 'SCode':
        this.codeMultiSelected = this.codeMultiSelected.concat(newSubFilters);
        break;
      case 'SProductBrandDescription':
        this.brandMultiSelected = this.brandMultiSelected.concat(newSubFilters);
        break;
      case 'SCategoryGroupDescription':
        this.categoryGroupDescriptionMultiSelected = this.categoryGroupDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SCompositionDescription':
        this.compositionDescriptionMultiSelected = this.compositionDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SFranchiseOrOwnBrandDescription':
        this.franchiseOrOwnBrandDescriptionMultiSelected = this.franchiseOrOwnBrandDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SMeasurementUnitCodeBase':
        this.measurementUnitCodeBaseMultiSelected = this.measurementUnitCodeBaseMultiSelected.concat(newSubFilters);
        break;
      case 'SIndustrySegmentDescription':
        this.industrySegmentDescriptionMultiSelected = this.industrySegmentDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SNameSegmentDescription':
        this.nameSegmentDescriptionMultiSelected = this.nameSegmentDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SProductFlavorDescription':
        this.productFlavorDescriptionMultiSelected = this.productFlavorDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SSegmentSizaDescription':
        this.segmentSizaDescriptionMultiSelected = this.segmentSizaDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SSubbrandDescription':
        this.subBrandDescriptionMultiSelected = this.subBrandDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'STypeOfPriceDescription':
        this.typeOfPriceDescriptionMultiSelected = this.typeOfPriceDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SUenDescription':
        this.uenDescriptionMultiSelected = this.uenDescriptionMultiSelected.concat(newSubFilters);
        break;
      case 'SZofeDescription':
        this.zofeDescriptionMultiSelected = this.zofeDescriptionMultiSelected.concat(newSubFilters);
        break;
      default:
        break;
    }
  }

  private prepareEditI(index: number, lengthSearch: number, element: string) {
    if (index < lengthSearch) {
      if (element) {
        switch (index) {
          case 0:
            this.categorySelected = element;
            break;
          case 1:
            this.packingSelected = element;
            break;
          case 2:
            this.contentSelected = element;
            break;
          case 3:
            this.returnabilitySelected = element;
            break;
          case 4:
            this.denominationSelected = element;
            break;
          default:
            break;
        }
      }
    }
  }

  /**
   * @description This method is to delete a element from filter
   * @param objectValue Object to delete form filter
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  delete(rowIndex: number, objectValue: IHierarchyFiltersMaterials) {
    //TODO
    // Falta borrado logico TypeCoreMaster
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        if (this.isMultiFilter) {
          this.advancedFiltersObjectList.splice(rowIndex, 1);
          this.advancedFiltersObjectList.forEach((adv, index) => {
            adv.group = `Grupo ${index + 1}`;
          });
        } else {
          this.deleteFilters();
        }
      }
    });
  }

  /**
   * @description This method is to emit event to father component.
   * @param $event Event click data
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  eventClickFilters($event: any): void {
    const advancedFiltersObjectList = this.advancedFiltersObjectList.map((filter) => {
      const sFilter = filter.subFilters[0];
      if (sFilter && sFilter.content) {
        return {
          hierarchyFilter: filter.hierarchyFilter,
          subFilters: filter.subFilters,
          type: filter.type
        };
      } else {
        return {
          hierarchyFilter: filter.hierarchyFilter,
          subFilters: [],
          type: filter.type
        };
      }
    });
    const filterInfo = {
      advancedFiltersObjectList: advancedFiltersObjectList
    };
    this.eventButton.emit(filterInfo);
  }

  private assignmentValSubFiltros(x: IHierarchyFiltersMaterials, valSubFiltros: number) {
    if (x.subFilters.length === 0) {
      valSubFiltros = 0;
    } else {
      x.subFilters.forEach((x) => {
        if (x.content == null) {
          valSubFiltros = 0;
        } else {
          valSubFiltros = 1;
        }
      });
    }
    return valSubFiltros;
  }

  private subFiltrosLessThan(
    x: IHierarchyFiltersMaterials,
    response: IResponseService<any>,
    auxSubFilter: string,
    dataSubFilter: string,
    indexSubFilter: number,
    indicador: number
  ) {
    if (x.hierarchyFilter !== response.data[0].dataHierarchy || auxSubFilter !== dataSubFilter) {
      if (!this.advancedFiltersObjectList.some((x) => x.hierarchyFilter === response.data[0].dataHierarchy && x.type === response.data[0].type)) {
        indexSubFilter = this.rowFilterToEdit;
        indicador = 2;
      }
    }

    if (x.hierarchyFilter === response.data[0].dataHierarchy && auxSubFilter !== dataSubFilter) {
      indexSubFilter = this.rowFilterToEdit;
      indicador = 2;
    }
    return { indexSubFilter, indicador };
  }

  private subFiltrosEqualTo0(x: IHierarchyFiltersMaterials, response: IResponseService<any>, indexFound: number, indicador: number, auxSubFilter: string, dataSubFilter: string) {
    if (x.hierarchyFilter !== response.data[0].dataHierarchy) {
      if (!this.advancedFiltersObjectList.some((x) => x.hierarchyFilter === response.data[0].dataHierarchy && x.type === response.data[0].type)) {
        indexFound = this.rowFilterToEdit;
        indicador = 1;
      }
    }
    if (x.hierarchyFilter === response.data[0].dataHierarchy && auxSubFilter !== dataSubFilter) {
      indexFound = this.rowFilterToEdit;
      indicador = 1;
    }
    return { indexFound, indicador };
  }

  private validityIndicator2(indexSubFilter: number, auxData: boolean, elementEdit: IHierarchyFiltersMaterials, response: IResponseService<any>) {
    if (indexSubFilter === this.rowFilterToEdit && auxData === false) {
      this.advancedFiltersObjectList[this.rowFilterToEdit] = {
        group: elementEdit.group,
        id: '',
        hierarchyFilter: response.data[0].dataHierarchy,
        subFilters: response.data[0].subFilters,
        type: response.data[0].type
      };
    }
  }

  private validityIndicator1(indexFound: number, auxData: boolean, elementEdit: IHierarchyFiltersMaterials, response: IResponseService<any>) {
    if (indexFound === this.rowFilterToEdit && auxData === false) {
      this.advancedFiltersObjectList[this.rowFilterToEdit] = {
        group: elementEdit.group,
        id: '',
        hierarchyFilter: response.data[0].dataHierarchy,
        subFilters: response.data[0].subFilters,
        type: response.data[0].type
      };
    }
  }

  private messageError(error: any) {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? `Error al verificar los filtros válidos. \n Error => ${error.message}`
        : `Failed to check valid filters. \n Error => ${error.message}`);
  }

  private thereAreNoFiltersInTheCombination() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? 'No se han encontrado filtros validos en la combinación que ha realizado.'
        : 'No valid filters have been found in the combination you have made.');
  }

  private matchesAppliedFilters() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? 'Los filtros seleccionados coinciden con filtros ya aplicados.'
        : 'The selected filters match already applied filters.');
  }

  private onlyOneSearchFilterAllowed() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
      language === 'es-ES' ? 'Para tipo tradicional sólo se permite un filtro de búsqueda.'
        : 'For traditional type, only one search filter is allowed.');
  }

  private validateFilterI(response: IResponseService<any>) {
    let i = this.advancedFiltersObjectList.length;
    if (response.data.length > 1) {
      const data = response.data
        .map((resp: IHierarchyFiltersMaterials, index: number) => {
          i++;
          return {
            group: `Grupo ${i}`,
            id: '',
            hierarchyFilter: resp.dataHierarchy,
            subFilters: resp.subFilters,
            type: resp.type ? resp.type : this.materialTypeSelected
          };
        });
      this.advancedFiltersObjectList = this.advancedFiltersObjectList.concat(data);
    } else {
      this.advancedFiltersObjectList.push({
        group: `Grupo ${i + 1}`,
        id: '',
        hierarchyFilter: response.data[0].dataHierarchy,
        subFilters: response.data[0].subFilters,
        type: response.data[0].type ? response.data[0].type : this.materialTypeSelected
      });
    }
  }

  initializeForm() {
    this.advancedFiltersObjectList = [];
  }

  materialCodeZeroLess(value: string) {
    let materialCodeNumber = Number(value);
    return materialCodeNumber.toString();
  }

}