import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import OrgChart from 'src/assets/balkanapp/orgchart';
import { OrganizationChartService } from './organization-chart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { AccessManagementService } from '@app/@modules/administration/accessmanagement/access-management.service';
import { OrganizationChartModel } from './models/organization-chart.model';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ApplicationConstants } from '@app/app.constants';
import { OrganizationNodeJsonModel } from './models/organization-node-json.model';
import { LiberationHierarchyService } from '../liberation.hierarchy.service';
import { Router } from '@angular/router';
import { IOrganizationChart } from './interfaces/organization-chart.interface';
import { Util } from '@app/@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { IAccessManagementJson } from '@app/@modules/administration/accessmanagement/interfaces/access-management-json.interface';
import { PaginationModel } from '@app/@shared/model/pagination.model';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ApprovalRanksService } from '../hierarchy-map/approval-ranks/approval-ranks.service';
import { IApprovalRank } from '../hierarchy-map/approval-ranks/interfaces/approval-rank.interface';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccessManagementConfigurationService } from '@app/@modules/administration/accessmanagement/access-management-configuration.service';
import { IInfoAccessManagementConfiguration } from '@app/@modules/administration/accessmanagement/interfaces/info-access-management-configuration.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { forkJoin } from 'rxjs';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { RolePermissionPipe } from '@app/@shared/pipeline/role/role-permission.pipe';

@Component({
  selector: 'app-organization-chart',
  templateUrl: './organization-chart.component.html',
  styleUrls: ['./organization-chart.component.css'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService, RolePermissionPipe]
})
export class OrganizationChartComponent implements OnInit {
  private messageService: MessageService;
  private router: Router;
  private organizationService : OrganizationChartService;
  private approvalRankService: ApprovalRanksService;
  private hierarchyService: LiberationHierarchyService;
  private permissionPipe : RolePermissionPipe;

  public formNodeAddEdit :FormGroup;
  public isTypeDialog = false;
  public sender: OrgChart;
  public senderNode: any;
  public objectApprovalLevel :IApprovalRank []= [];
  public objectOrgChart: any[];
  public titleTypeDialog = 'Organigrama Postobon';
  public objectList: IAccessManagementJson[];
  public objectUsers: ICombo[];
  public objectJob: ICombo[];
  public objectJobCopy: ICombo[];
  public objectSectors: ICombo[];
  public objectCedis: ICombo[];
  public commentary = '';
  public user: IInfoAccessManagementConfiguration;
  public idHierarchy:number;
  public descriptionLevel:string;
  public addNode = false;
  public isAddNode = false;
  public isEdit = false;
  public nodeDataId: number;
  public idNode: number;
  public descriptionCedi: string = '';
  public descriptionSector: string;
  public levelApprovalOptions: ICombo[];
  public isNewOrg = true;
  public objectColorLevel: ICombo[];
  public rolePermission: any = RolePermissionEnum;
  public showNodeMenu = false;

  constructor(
    public fb: FormBuilder,
    public spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router,
    public accessManagementConfigService: AccessManagementConfigurationService,
    public accessManagementService : AccessManagementService,
    organizationService : OrganizationChartService,
    approvalRankService: ApprovalRanksService,
    hierarchyService: LiberationHierarchyService,
    permissionPipe : RolePermissionPipe
  ) { 
      this.messageService = messageService;
      this.router = router;
      this.organizationService = organizationService;
      this.approvalRankService = approvalRankService;
      this.hierarchyService = hierarchyService;
      this.permissionPipe = permissionPipe;
  }

  ngOnInit(): void {
    this.idHierarchy = this.hierarchyService.getHierarchyId();
    this.objectJob = this.approvalRankService.getLevelApproval();
    this.initForm();
    this.getAllColorLevel();
    this.getAll();
    this.getAllUser();
    this.forkJoinData();
    this.showNodeMenu = this.permissionPipe.transform(this.rolePermission.delete);
  }

  saveAddNode(){
    if(this.formNodeAddEdit.valid){
      this.getUsuarioByEmail(this.emailNode.value, this.nodeDataId);
    }else{
      this.formNodeAddEdit.markAllAsTouched();
    }
  }

  closeModalNode() {
    this.isAddNode = false;
  }

  clearControls(){
    this.emailNode.setValue('');
    this.jobNode.setValue('');
    this.formNodeAddEdit.reset();
  }

  postUserOrganization (body: OrganizationChartModel) {
    this.organizationService.postOrganization(body).subscribe(
      (response: any) => {
        if(response.status){
            this.getAll();
            this.clearControls();
            this.spinner.hide();
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconSuccess,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate );
        }else{
          this.spinner.hide();
          SweetAlertMessageHelpers.error(
            SweetAlert.titleAlertError,
            response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  putUserOrganization (id: number, body: OrganizationChartModel) {
    this.organizationService.putHierarchy(id, body).subscribe(
      (response: any) => {
        if(response.status){
            this.getAll();
            this.clearControls();
            this.spinner.hide();
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconWarningToast,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate);
        }else{
          this.spinner.hide();
          SweetAlertMessageHelpers.error(
            SweetAlert.titleAlertError,
            response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  getUsuarioByEmail (email: string, nodeId: number){
    this.accessManagementConfigService.getInfoAccessManagementOrgChart(email)
    .subscribe(
      (response) => {
        if (response.status){
          this.spinner.show();
          this.getDescriptionLevel();
          this.user = response.data[0];
          this.getSectorDescription();
          this.getCediDescription();
          this.isAddNode = false;
          this.idHierarchy = this.hierarchyService.getHierarchyId();
          if(this.objectOrgChart === [] || this.objectOrgChart.length === 0){
            nodeId=0;
          }

          const colorNode = this.colorLevel(this.jobNode.value);
          // Se crea modelo para nodo
          const nodoInfo : OrganizationNodeJsonModel = new OrganizationNodeJsonModel(
            nodeId,
            `${this.user.name} ${this.user.lastName}`,
            this.user.accessManagementId,
            this.jobNode.value,
            this.descriptionLevel,
            this.descriptionCedi,
            this.descriptionSector,
            null,
            [colorNode]
            );

          const nodoAdd: OrganizationChartModel = new OrganizationChartModel(
            this.idHierarchy,
            nodoInfo.email,
            JSON.stringify(nodoInfo),
            nodeId,
            Number(this.jobNode.value)
          );

          const nodoEdit: OrganizationChartModel = new OrganizationChartModel(
            this.idHierarchy,
            email,
            JSON.stringify(nodoInfo),
            nodeId,
            Number(this.jobNode.value),
            this.idNode
          );

          if(this.isEdit === false){
            this.postUserOrganization(nodoAdd);
          }else{
            this.putUserOrganization(this.idNode, nodoEdit);
          }
        }else{
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(
            SweetAlert.titleAlertError,
            language === 'es-ES' ? 'El usuario no existe.' : 'Username does not exist.');
          }
      },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        }
    );

  }

  getSectorDescription(){
    if(this.user.sectorCode == null){
      this.descriptionSector = '';
    }else{
      const auxCode = this.user.sectorCode[0].code;
      this.objectSectors.forEach((x) => {
        if(auxCode === x.value){
          this.descriptionSector = x.label;
        }
      });
    }

  }

  getCediDescription(){
    this.descriptionCedi = '';
    this.objectCedis.forEach((x) => {
    this.user.cediCode.forEach((cedi) => {
      if(cedi.code === x.value){
        this.descriptionCedi += '-' + x.label + ' ';
      }
      if(cedi.code == 'ALL'){
        this.descriptionCedi = 'Todos';
        return;
      }
    });
    });
  }

  getDescriptionLevel(){
    this.objectJob.forEach((x) => {
      if(this.jobNode.value === x.value){
          this.descriptionLevel = x.label;
      }
    });
  }

  getAllColorLevel(){
    this.spinner.show();
    this.approvalRankService.getAllApprovalLevel().subscribe(
    (response) => {
      if (response.data) {
        this.objectApprovalLevel = response.data;
        this.objectColorLevel = this.objectApprovalLevel.map((s) => {
          return{
            label: s.color,
            value: s.id
          };
        });
        this.spinner.hide();
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  forkJoinData(): void {
    this.spinner.show();
    const SectorResponse = this.hierarchyService.getAll(TypeCoreMaster.Sector);
    const CediResponse = this.hierarchyService.getAll(TypeCoreMaster.Cedi);

    forkJoin([
      SectorResponse,
      CediResponse,
      ]).subscribe(
        (result) => {
        const objSectorResponse: IResponseService<ICoreMaster[]> = result[0];
        const objCediResponse: IResponseService<ICoreMaster[]> = result[1];

        this.objectSectors = objSectorResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });

        this.objectCedis = objCediResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
      }
    );
  }

  getAll() {
    if(this.idHierarchy !== undefined){
      this.organizationService.getAllOrganization(this.idHierarchy).subscribe(
        (response) => {
          this.spinner.show();
           if(response.data.length !== 0){
            this.objectOrgChart = response.data
            .map((res: IOrganizationChart) => {
              const aux = JSON.parse(String(res.infoNodeJson));
              return{
                id: res.id,
                pid: res.parentNodeId,
                name: aux.name,
                Correo: aux.email,
                idCargo: aux.idTitle,
                Cargo: aux.title,
                Cedi: aux.cedi,
                Sector: aux.sector,
                tags : aux.tags,
                img: aux.img ? `${CommonConfiguration.TransformImage}${aux.img}` : 'https://static.malaga.es/malaga/subidas/imagenes/0/9/arc_330890_g.png'
              };
            });
            this.data();
            this.spinner.hide();
          }else if(response.data.length === 0){
            this.objectOrgChart = [];
            this.data();
            this.spinner.hide();
         }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
      );
    }else{
      this.router.navigate(['auth/liberation-hierarchy']);
    }
  }

  getAllUser() {
    this.spinner.show();
    const paginationModel = new PaginationModel('id', true, 1, 100);
    this.accessManagementService.getListAccessManagement(paginationModel).subscribe(
      (response) => {
        this.spinner.hide();
        this.objectList = response.data;
        this.objectList.forEach((x) => {
          x.stateDomain = (x.nameDomain === 'Activo' ? true : false);
        });

        this.objectUsers = this.objectList.map((item) => {
          return {
            label: item.fullName,
            value: item.id,
          };
        });
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      });
  }

  colorLevel(level: number): string{
    let color : string;
    this.objectColorLevel.forEach((x) => {
      if(level === x.value){
        color = x.label;
      }

    });
    return color;
  }

  data(){
  OrgChart.SEARCH_PLACEHOLDER = 'Buscar';
  this.template();
   const tree = document.getElementById('tree');
    var chart = this.nodeTree(tree, callHandler);

      // Cargar Datos
      if(this.objectOrgChart === undefined){
        this.getAll();
       }
       chart.load(this.objectOrgChart);

      //Agregar Nodo
      chart.on('add', function (sender, nodeData) {
        if(nodeData.Cargo === 'Vicepresidente Ventas'){
          nodeData.tags = ['2'];
        }
        this.isEdit = false;
        this.sender = sender;
        this.senderNode = nodeData;
          getValidityOrgChart(nodeData, true);

        return false;
      });

      //Editar nodo
      function callHandler(nodeId: any) {
        var nodeData = chart.get(nodeId);
        this.isEdit = true;
        getValidityOrgChart(nodeData, false);
      }

      //Borrar Nodo
      chart.on('remove', function (sender, nodeData) {
        Util.confirmDeleteNode().then((result: SweetAlertResult) => {
          if (result.value) {
            deleteUserOrganization(nodeData);
          }else{
            return false;
          }
        });
        return false;
      });

      const openModalNode = async (nodeData: any, indicator: boolean) => {
        this.isAddNode = true;

        this.validityIndicator(indicator, nodeData, getCodeLevel);
        this.nodeDataId = Number(nodeData.pid);
      };

      const validityInitial = () => {
       if(this.objectJobCopy.length === 0 && this.objectOrgChart.length === 0){
        this.ranksApprovalBeforeNodes();
        return false;
      }else if(this.objectOrgChart.length > 0 && this.objectJobCopy.length === 0){
        this.lastLevelOfApproval();
        return false;
      }else{
        return true;
      }
      };

      const getCodeLevel = (level: string) => {
        const auxLevel = this.objectJob.find((x) => level === x.label);
        if(auxLevel){
          this.jobNode.setValue(auxLevel.value);
          return true;
        }
        if(auxLevel === undefined){
          this.addApprovalRank();
        return false;
        }
      };

      //Eliminar nodo BD
      const deleteUserOrganization = async (id: number) => {
        this.organizationService.deleteHierarchyLogic(id).subscribe(
          (response: any) => {
            if(response){
              this.successfullyRemoved(showToast);
              this.getAll();
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      };

      const getValidityOrgChart = (nodeData: any, indicator: boolean) => {
        this.objectJob = this.approvalRankService.getLevelApproval();
        if(nodeData.pid){
          const padre = this.objectOrgChart.find((x: any) => x.id === nodeData.pid);
          const level = this.objectJob.find((x : ICombo) => x.label === padre.Cargo);
          if(level !== undefined){
            this.objectJobCopy = [...this.objectJob.filter((x: ICombo) => Number(x.order) > Number(level.order))];
          }else{
            this.NoAddLastApprovalRank();
          }
        }else{
          this.objectJobCopy = this.objectJob;
        }
        const aux = validityInitial();
        if(aux){
          this.openModal(indicator, openModalNode, nodeData);
        }

      };

      const showToast = (severity: string, message: string, detail: string) => {
        this.messageService.clear();
        this.messageService.add({
          severity: severity,
          summary: message,
          detail: detail,
          life: ApplicationConstants.CONSTANTS.lifeTimeToast as number
        });
      };

    }

  private validityIndicator(indicator: boolean, nodeData: any, getCodeLevel: (level: string) => boolean) {
    if (indicator) {
      this.isEdit = false;
      this.clearControls();
    }

    if (!indicator) {
      this.clearControls();
      if (typeof (nodeData.id) == 'string') {
        const nodo = this.objectOrgChart.find((x) => nodeData.pid === x.id);
        this.emailNode.setValue(nodo.Correo);
        this.jobNode.setValue(nodo.idCargo);
        this.nameNode.setValue(nodo.Correo);
      } else {
        this.dataUserEdit(getCodeLevel, nodeData);
      }
    }
  }

  showToast(severity: string, message: string, detail: string){
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number
    });
  }

  initForm():void{
    this.formNodeAddEdit = this.fb.group({
      emailNode: [{ value: '', disabled: false }, Validators.required],
      jobNode: [{ value: '', disabled: false }, Validators.required],
      nameNode: [{ value: '', disabled: false }],
    });
  }

  get emailNode(): AbstractControl { return this.formNodeAddEdit.get('emailNode'); }
  get jobNode(): AbstractControl { return this.formNodeAddEdit.get('jobNode'); }
  get nameNode(): AbstractControl { return this.formNodeAddEdit.get('nameNode');}

  template(){
    //"#b9ad02": {template: "nodecolorblue"}
    OrgChart.templates.nodecolorblue = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorblue.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#b9ad02"></line>`;

    //"#6610f2": {template: "nodecolorindigo"}
    OrgChart.templates.nodecolorindigo = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorindigo.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#6610f2"></line>`;

    //"#9e89c6": {template: "nodecolorpurple"}
    OrgChart.templates.nodecolorpurple = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorpurple.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#9e89c6"></line>`;

    //"#e83e8c": {template: "nodecolorpink"}
    OrgChart.templates.nodecolorpink = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorpink.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#e83e8c"></line>`;

   //"#dc3545": {template: "nodecolorred"}
    OrgChart.templates.nodecolorred = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorred.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#dc3545"></line>`;

   //"#fd7e14": {template: "nodecolororange"}
    OrgChart.templates.nodecolororange = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolororange.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#fd7e14"></line>`;

   // "#ffc107": {template: "nodecoloryellow"}
    OrgChart.templates.nodecoloryellow = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecoloryellow.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#ffc107"></line>`;

   // "#28a745": {template: "nodecolorgreen"}
    OrgChart.templates.nodecolorgreen = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorgreen.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#28a745"></line>`;

   // "#20c997": {template: "nodecolorteal"}
    OrgChart.templates.nodecolorteal = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorteal.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#20c997"></line>`;

   // "#17a2b8": {template: "nodecolorcyan"}
    OrgChart.templates.nodecolorcyan = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorcyan.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#17a2b8"></line>`;

   // "#6c757d": {template: "nodecolorgray"}
    OrgChart.templates.nodecolorgray = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorgray.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#6c757d"></line>`;

   // "#343a40": {template: "nodecolorgraydark"}
    OrgChart.templates.nodecolorgraydark = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorgraydark.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#343a40"></line>`;

   // "#c8ad8d": {template: "nodecolorbeige"}
    OrgChart.templates.nodecolorbeige = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorbeige.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#c8ad8d"></line>`;

   // "#5679c5": {template: "nodecolormediumpink"}
    OrgChart.templates.nodecolormediumpink = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolormediumpink.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#5679c5"></line>`;

   // "#31470b": {template: "nodecolormediumgreen"}
    OrgChart.templates.nodecolormediumgreen = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolormediumgreen.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#31470b"></line>`;

   // "#6aff07": {template: "nodecolorneongreen"}
    OrgChart.templates.nodecolorneongreen = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorneongreen.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#6aff07"></line>`;

   // "#8e001d": {template: "nodecolorreddark"}
    OrgChart.templates.nodecolorreddark = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorreddark.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#8e001d"></line>`;

   // "#1b0251": {template: "nodecolorpurpledark"}
    OrgChart.templates.nodecolorpurpledark = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorpurpledark.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#1b0251"></line>`;

   // "#431e0b": {template: "nodecolorbrown"}
    OrgChart.templates.nodecolorbrown = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolorbrown.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#431e0b"></line>`;

   // "#080808": {template: "nodecolordark"}
    OrgChart.templates.nodecolordark = Object.assign({}, OrgChart.templates.ula);
    OrgChart.templates.nodecolordark.node =`
    <rect x="0" y="0" height="120" width="250" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect>
    <line x1="0" y1="0" x2="250" y2="0" stroke-width="2" stroke="#080808"></line>`;

  }

  private nodeTree(tree: HTMLElement, callHandler: (nodeId: any) => void) {
    if (tree) {
      const nodeMenuConfig = this.showNodeMenu
      ? {
          add: { text: 'Agregar', icon: '' },
          call: {
            icon: '',
            text: 'Editar',
            onClick: callHandler,
          },
          remove: { text: 'Eliminar', icon: '' },
        }
      : {
        add: { text: 'Agregar', icon: '' },
          call: {
            icon: '',
            text: 'Editar',
            onClick: callHandler,
          }
      };
      var chart = new OrgChart(tree, {
        searchFields: ['name'],
        template: 'ula',
        nodeBinding: {
          field_0: 'name',
          field_1: 'Cargo',
          field_2: 'Correo',
          field_3: 'Cedi',
          field_4: 'Sector',
          img_0: 'img'
        },
        tags: {
          '#b9ad02': { template: 'nodecolorblue' },
          '#6610f2': { template: 'nodecolorindigo' },
          '#9e89c6': { template: 'nodecolorpurple' },
          '#e83e8c': { template: 'nodecolorpink' },
          '#dc3545': { template: 'nodecolorred' },
          '#fd7e14': { template: 'nodecolororange' },
          '#ffc107': { template: 'nodecoloryellow' },
          '#28a745': { template: 'nodecolorgreen' },
          '#20c997': { template: 'nodecolorteal' },
          '#17a2b8': { template: 'nodecolorcyan' },
          '#6c757d': { template: 'nodecolorgray' },
          '#343a40': { template: 'nodecolorgraydark' },
          '#c8ad8d': { template: 'nodecolorbeige' },
          '#5679c5': { template: 'nodecolormediumpink' },
          '#31470b': { template: 'nodecolormediumgreen' },
          '#6aff07': { template: 'nodecolorneongreen' },
          '#8e001d': { template: 'nodecolorreddark' },
          '#1b0251': { template: 'nodecolorpurpledark' },
          '#431e0b': { template: 'nodecolorbrown' },
          '#080808': { template: 'nodecolordark' },
        },
        nodeMenu: nodeMenuConfig,
        editForm: {
          generateElementsFromFields: false,
          elements: [
            { type: 'textbox', label: 'Nombre', binding: 'name' },
            { type: 'textbox', label: 'Cargo', binding: 'Cargo' },
            { type: 'textbox', label: 'Correo', binding: 'Correo' },
            { type: 'textbox', label: 'Cedi', binding: 'Cedi' },
            { type: 'textbox', label: 'Sector', binding: 'Sector' }
          ]
        }
      });
    }
    return chart;
  }

  completeEmail(user: any ){
    this.nameNode.setValue(user.value);
  }

  private openModal(indicator: boolean, openModalNode: (nodeData: any, indicator: boolean) => Promise<void>, nodeData: any) {
    if (indicator) {
      openModalNode(nodeData, true);
    }
    if (!indicator) {
      openModalNode(nodeData, false);
    }
  }

  private dataUserEdit(getCodeLevel: (level: string) => boolean, nodeData: any) {
    const aux = getCodeLevel(nodeData.Cargo);
    if (aux) {
      this.isEdit = true;
      this.emailNode.setValue(nodeData.Correo);
      this.idNode = Number(nodeData.id);
      this.nameNode.setValue(nodeData.Correo);
    } else {
      this.isAddNode = false;
    }
  }

  private NoAddLastApprovalRank() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.warning(
      language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
      language === 'es-ES' ? 'No se puede agregar nodo porque es el ultimo nivel o no existe en los rangos de aprobación'
        : 'Node cannot be added because it is the last level or it does not exist in the approval ranges');
  }

  private successfullyRemoved(showToast: (severity: string, message: string, detail: string) => void) {
    const language: string = sessionStorage.getItem('language');
    showToast(SweetAlert.iconInfo,
      language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
      language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
  }

  private addApprovalRank() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.warning(
      language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
      language === 'es-ES' ? 'El nivel de aprobación debe agregarse nuevamente en los rangos de aprobación'
        : 'Approval Level needs to be added back in Approval Ranks');
  }

  private lastLevelOfApproval() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.warning(
      language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
      language === 'es-ES' ? 'No se puede agregar el nodo porque es el ultimo nivel de aprobación'
        : 'The node cannot be added because it is the last approval level');
  }

  private ranksApprovalBeforeNodes() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.warning(
      language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
      language === 'es-ES' ? 'Debe agregar los rangos de aprobación antes de insertar el nodo'
        : 'You must add the approval ranges before inserting the node');
  }
}