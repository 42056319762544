import { IAdvancedFiltersClient } from '../interfaces/advanced-filters-client.interface';

export class AdvancedFiltersClientModel implements IAdvancedFiltersClient {

  /**
   * @author Daniel Londono Ortega
   * @sprint 8
   * @param filters
   * @param ascendent
   * @param pageNumber
   * @param resultsPerPage
   */
  constructor(
    public filters: string,
    public ascendent: boolean,
    public pageNumber: number,
    public resultsPerPage: number,
    public ids?: string
  ) { }
}