import { IFilters } from '../interfaces/filters.interface';

export class FiltersModel implements IFilters {
  constructor(
    public id: string,
    public type: string,
    public typeDescription: string,
    public label: string,
    public content: string,
    public contentDescription: string,
    public operator: string,
  ) {}
}
