import { IUpdateStatus } from '@app/@shared/interfaces/update-status.interface';

export class SolicitudeNotificationUserStatusModel implements IUpdateStatus<number,boolean> {
    /**
   * @author Priscy Antonio Reales Mozo
   * @sprint 11
   * @description Model for update status
   */
  constructor(
    public id : number,
    public status: boolean,
  ) {}
}