import { Injectable } from '@angular/core';
import { PaginationModel } from '../../../@shared/model/pagination.model';
import { IManagePromotionalActivitiesJson } from './interfaces/manage-promotional-activities-Json.interface';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { PaginationHistoryModel } from '@app/@shared/model/paginationHistory.model';
import { ICommercialActivityHistory } from './interfaces/commercial-activity-history.interface';
import { IAdvancedFiltersMaterialsResult } from '../../../@shared/interfaces/advanced-filters-material-result.interface';
import { ICommercialActivity } from './interfaces/commercial-activity.interface';
import { PaginationDateModel } from '@app/@shared/model/paginationDate.model';
import { CommercialActivityCalculateImpact } from './models/commercial-activity-calculate-impact.model';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { CommercialACtivityQueueValidationModel } from './models/commercial-activity-queue-validation.model';
import { IProductList } from './commercial-activity-events/interfaces/product-list.interface';
import { IProductGroupList } from './commercial-activity-events/interfaces/product-group-list.interface';
import { CommercialActivityStatusModel } from './models/commercial-activity-status.model';
import { CommercialActivityInterruptBatchModel, CommercialActivityInterruptModel } from './models/commercial-activity-interrupt.model';
import { ActivityCopyModel } from './models/activity-copy.model';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ICommercialActivityUserConfig } from './interfaces/ICommercialActivityUserConfig';
import { IConfigurationUserAccessManagement } from '@app/@components/advanced-client-filter/interfaces/configuration-user-access-management.interface';
import { CommercialActivityCopyModel } from './models/commercial-activity-Copy.model';
import { CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';
import { IFileResponse } from '@app/@components/upload-excel/interfaces/solicitude-file-response.interface';
import { IPurchaseBaseJson } from './interfaces/purchase-base-json.interface';

@Injectable({
  providedIn: 'root'
})
export class ManagePromotionalActivitiesService {
  private readonly httpService: HttpService;
  public description: string;
  public TypeCommercialActivity: number;
  public CommercialActivityId: number;
  public isProcess: number;
  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  /**
   * @description This method is to return union diferent IProductGroupList[].
   * @param oldProduc
   * @param newProduc
   * @returns IProductGroupList[]
   */
  public getUnionProductGroupList(oldProduc: IProductGroupList[], newProduc: IProductGroupList[]): IProductGroupList[] {
    if (oldProduc.length === 0) return newProduc;
    const newArray: IProductList[] = [];
    // Recorres ambos arreglos y aplicas la condición que deseas
    oldProduc.filter((d) => {
      newProduc.filter((s) => {
        if (d.groupByJson !== s.groupByJson) {
          newArray.push(s);
        }
      });
    });

    // Una vez obtenidos los registros correctos, puedes filtrar nuevamente el arreglo omitiendo los mismos
    const union = oldProduc.filter((d) => !newArray.includes(d));
    union.push(...newArray);
    return union;
  }

  /**
   * @description This method is to return union diferent IProductList[].
   * @param oldProduc
   * @param newProduc
   * @returns IProductList[]
   */
  public getUnionProducts(oldProduc: IProductList[], newProduc: IProductList[]): IProductList[] {
    const newArray: IProductList[] = [];
    // Recorres ambos arreglos y aplicas la condición que deseas
    oldProduc.filter((d) => {
      newProduc.filter((s) => {
        if (d.materialCode !== s.materialCode) {
          newArray.push(s);
        }
      });
    });

    // Una vez obtenidos los registros correctos, puedes filtrar nuevamente el arreglo omitiendo los mismos
    const union = oldProduc.filter((d) => !newArray.includes(d));
    union.push(...newArray);
    return union;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  getDescription(): string {
    return this.description;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  setDescription(description: string) {
    this.description = description;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  getTypeCommercialActivity(): number {
    return this.TypeCommercialActivity;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  setTypeCommercialActivity(TypeCommercialActivity: number) {
    this.TypeCommercialActivity = TypeCommercialActivity;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  getCommercialActivityId(): number {
    return this.CommercialActivityId;
  }

  /**
   * @description This method is to storage id.
   * @author Priscy Antonio Reales
   * @param number
   * @returns none
   * @sprint 8
   */
  setCommercialActivityId(id: number) {
    this.CommercialActivityId = id;
  }

  /**
   * @description This method is to return id storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns boolean
   * @sprint 8
   */
  getIsCreate(): number {
    return this.isProcess;
  }

  /**
   * @description This method is to storage id.
   * @author Priscy Antonio Reales
   * @param number
   * @returns none
   * @sprint 8
   */
  setIsCreate(IdProcess: number) {
    this.isProcess = IdProcess;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  public getListCommercialActivity(paginationDateModel: PaginationDateModel): Observable<IResponseService<IManagePromotionalActivitiesJson[]>> {
    const response$: Observable<IResponseService<IManagePromotionalActivitiesJson[]>> =
      this.httpService
        .patch(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_commercial_activity`, paginationDateModel)
        .pipe(
          map((response: HttpResponse<IResponseService<IManagePromotionalActivitiesJson[]>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  public getCommercialActivity(paginationModel: PaginationModel): Observable<IResponseService<ICommercialActivity[]>> {
    const response$: Observable<IResponseService<ICommercialActivity[]>> =
      this.httpService
        .post(`${environment.apiUrlCore}​/api​/CommercialActivity​`, paginationModel)
        .pipe(
          map((response: HttpResponse<IResponseService<ICommercialActivity[]>>) => {
            return response.body;
          })
        );
    return response$;
  }


  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  public copy(body: CommercialActivityCopyModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
      this.httpService
        .post(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/copy`,
          body
        )
        .pipe(
          map((response: HttpResponse<IResponseService<number>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
     * @description This method is to return id storage.
     * @author Santiago Alberto Gonzalez
     * @param none
     * @returns number
     * @sprint 8
     */
  public getListCommercialActivityHistory(
    paginationHistoryModel: PaginationHistoryModel
  ): Observable<IResponseService<ICommercialActivityHistory[]>> {
    const response$: Observable<IResponseService<ICommercialActivityHistory[]>> =
      this.httpService
        .patch(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_commercial_activity_history`,
          paginationHistoryModel
        )
        .pipe(
          map((response: HttpResponse<IResponseService<ICommercialActivityHistory[]>>) => {
            return response.body;
          })
        );
    return response$;
  }


  /**
   * @description This method is to return id storage.
   * @author Santiago Alberto Gonzalez
   * @param none
   * @returns number
   * @sprint 8
   */
  public delete(id: string): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
      this.httpService
        .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/logic/${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<boolean>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
   * @description This method is to filters.
   *
   * @author Diego Mauricio Cortés
   * @param filters
   * @sprint 9
   */
  public postAdvancedFiltersMaterials(filters: FilterDataModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
      this.httpService
        .patch(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_hierarchy_filters_materials_advances`, filters)
        .pipe(
          map((response: HttpResponse<IResponseService<IAdvancedFiltersMaterialsResult>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
  * @description This method is to send commercial activity to calculate.
  *
  * @author Diego Mauricio Cortés
  * @param body
  * @sprint 15
  */
  public postPromotionalActivityCalculate(body: CommercialActivityCalculateImpact): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
      this.httpService
        .post(
          `${environment.apiAnalytics}/api/v1/ccvp_apwb_prom/impact/`,
          body)
        .pipe(
          map((response: HttpResponse<IResponseService<any>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
   * @description This method is to send commercial activity to validate.
   *
   * @author Diego Mauricio Cortés
   * @param activity
   * @sprint 15
   */
  public postCommercialActivityQueueValidated(activity: CommercialACtivityQueueValidationModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
      this.httpService
        .post(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/update_status/${activity.id}`,
          activity)
        .pipe(
          map((response: HttpResponse<IResponseService<any>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
   * @description This method is to update status commercial activity.
   *
   * @author Priscy Antonio Reales
   * @param activityUpdate
   * @sprint 25
   */
  public postCommercialActivityUpdateStatus(activity: CommercialActivityStatusModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
      this.httpService
        .post(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/update_status/${activity.id}`,
          activity)
        .pipe(
          map((response: HttpResponse<IResponseService<any>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
   * @description This method is to interrupt commercial activity.
   *
   * @author Priscy Antonio Reales
   * @param activity
   * @sprint 23
   */
  public postCommercialActivityInterrupt(activity: CommercialActivityInterruptModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
      this.httpService
        .post(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/interrupt/${activity.id}`,
          activity)
        .pipe(
          map((response: HttpResponse<IResponseService<any>>) => {
            return response.body;
          })
        );
    return response$;
  }

  public deleteProductList(id: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
      this.httpService
        .delete(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/purchase_base_details/logic/${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<any>>) => {
            return response.body;
          })
        );
    return response$;
  }

  public deleteProductGroupsList(id: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
      this.httpService
        .delete(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/purchase_bases/logic/${id}`)
        .pipe(
          map((response: HttpResponse<IResponseService<any>>) => {
            return response.body;
          })
        );
    return response$;
  }

  /**
   * @description This method is to interrupt for batch commercial activity.
   *
   * @author Priscy Antonio Reales
   * @param activities
   * @sprint 23
   */
   public postCommercialActivityInterruptBatch(activities: CommercialActivityInterruptBatchModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .post(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/interrupt_batch`, activities)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public copyCommercialActivity(activity: ActivityCopyModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
      this.httpService
        .post(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/copy`,
          activity)
        .pipe(
          map((response: HttpResponse<IResponseService<number>>) => {
            return response.body;
          })
        );
    return response$;
  }

  public getConfigurationUserAcessManagement(accessManagementId: string): Observable<IResponseService<IConfigurationUserAccessManagement>>{
    const response$: Observable<IResponseService<IConfigurationUserAccessManagement>> = this.httpService
    .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/configuration-user-acess-management/${accessManagementId}`)
    .pipe(
      map((response: HttpResponse<IResponseService<IConfigurationUserAccessManagement>>) => {
        return response.body;
      })
    );
    return response$;
  }

  setUserFilters(channelList: ICombo[], sectorList: ICombo[]): ICommercialActivityUserConfig {
    const channel: string[] | string = SessionStorageHelper.get(CommonConfiguration.Channel) ?? JSON.parse(SessionStorageHelper.get(CommonConfiguration.Channel));
    const sector: string[] | string = SessionStorageHelper.get(CommonConfiguration.Sector) ?? JSON.parse(SessionStorageHelper.get(CommonConfiguration.Sector));

    /*Filtrado de Canal y Sector*/
    const activityUserConfig = {} as ICommercialActivityUserConfig;
    activityUserConfig.channels = [];
    activityUserConfig.sectors = [];
    
    if (typeof(channel) === 'string')
    {
      activityUserConfig.channels = channel.length > 0 && !channel.includes('ALL') ? channelList.filter((z) => channel.includes(z.value)) : channelList;
    }
    else
    {
      activityUserConfig.channels = channel.length > 0 && !channel.some(x => x === 'ALL') ? channelList.filter((z) => channel.includes(z.value)) : channelList;
    }
    
    if (typeof(sector) === 'string')
    {
      activityUserConfig.sectors = sector.length > 0 && !sector.includes('ALL') ? sectorList.filter((z) => sector.includes(z.value)) : sectorList;
    }
    else
    {
      activityUserConfig.sectors = sector.length > 0 && !sector.some(x => x === 'ALL') ? sectorList.filter((z) => sector.includes(z.value)) : sectorList;
    }

    return activityUserConfig;
  }

  public postUploadFileCommercialActivityMaterial(
    formData: FormData,
    commercialActivityType : CommercialActivitySubprocess,
    sector : string
    ): Observable<IResponseService<IFileResponse<IPurchaseBaseJson[]>>> {      

    const response$: Observable<IResponseService<IFileResponse<IPurchaseBaseJson[]>>> =
     this.httpService
    .postFile(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/upload_file_commercial_activicty/${commercialActivityType}/${sector}`,formData)
    .pipe(
      map((response: HttpResponse<IResponseService<IFileResponse<IPurchaseBaseJson[]>>>) => {
          return response.body;
      })
    );
    return response$;
  }
}