import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssingClientsComponent } from '@app/@components/assing-clients/assing-clients.component';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { IFieldsComponentClientFilter } from '@app/@shared/interfaces/fields-component-client-filter.interface';
import { FieldsComponentClientFilter } from '@app/@shared/model/fields-component-client-filter.model';
@Component({
  selector: 'app-clients-advanced-filter',
  templateUrl: './clients-advanced-filter.component.html',
  styleUrls: ['./clients-advanced-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsAdvancedFilterComponent implements OnInit {

  @Input('rolePermissionBtn') rolePermissionBtn: boolean;
  @Input('showFieldsComponent') showFieldsComponent = true;
  @Input('validateFilter') validateFilter = true;
  @Input('showFieldsClient') showFieldsClient : IFieldsComponentClientFilter = new FieldsComponentClientFilter(false,false);
  @Input('filtersEdit') filtersEdit: string;
  @Input('clientsEdit') clientsEdit: string;
  @Input('isProcess') isProcess: number;
  @Input('showFields') showFields : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false, false, true);

  @ViewChild (AssingClientsComponent) clientFilter : AssingClientsComponent;

  // public showFieldClientFilter : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false, false, true);
  public formAccessManagement: FormGroup;

  private readonly fb: FormBuilder;
  private clientAdvancedFilter:string[] = [];

  constructor(fb: FormBuilder) {
    this.fb = fb;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formAccessManagement = this.fb.group({
      clientsCode: [{ value: '', disabled: false}, [Validators.minLength(3), Validators.maxLength(100),Validators.pattern(/^[0-9,$\\,]*$/)]]
    });
  }

  getAdvancedFilters() {
    let filterClientInfo = this.clientFilter.getFilterClientInfo(true);
    this.clientAdvancedFilter = (this.clientsCode.value === '' || this.clientsCode.value == null ? []: this.clientsCode.value.split(','));
    if(!filterClientInfo.listClient) filterClientInfo.listClient = [];
    filterClientInfo.listClient = [...filterClientInfo.listClient, ...this.clientAdvancedFilter];
    return filterClientInfo;
  }

  initializeForm(){
    this.formAccessManagement.reset();
    this.clientFilter.eventClickDeleteFiltersClient();
    this.clientFilter.fileUpload.onRemoveFileImage();
    this.clientFilter.fileUpload.uploadClear();
    this.clientFilter.advancedClientFilter.clearFilters();
  }

   get clientsCode(): AbstractControl { return this.formAccessManagement.get('clientsCode');}

}