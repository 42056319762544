<p-sidebar [(visible)]="isVisible"
           position="right"
           [baseZIndex]="9000"
           styleClass="p-sidebar-md"
           (onHide)="onHidden()"
           [style]="{'overflow-x': 'scroll'}">
  <div class="modal-header">
    <div class="row justify-content-between">
      <h5 class="modal-title float-left"
          id="myModalLabel2"> <strong>{{ 'PendingNotifications' | translate}}</strong> </h5>
    </div>
  </div>

  <div class="modal-body">

    <div class="row">
      <a [routerLink]="'/auth/notification-panel'"
         class="card-link"><em><span>{{'SeeAll'| translate}}</span></em></a>

    </div>
    <br/>
    <div *ngFor="let notification of objectListNotification; let i = index">
      <div class="mb-2">
        <app-notification-card [id]="notification.solicitudeId"
                               [snooze]="snooze"
                               [reject]="notification.notificationTypeId !== notificationTypeEnum.Deleted &&
                                          notification.notificationTypeId !== notificationTypeEnum.Informative &&
                                          notification.notificationTypeId !== notificationTypeEnum.System"
                               [accept]="accept"
                               [moreInfo]="moreInfo"
                               [notificationColor]="notification.color"
                               [title]="notification.subject"
                               [notificationText]="notification.bodyMail"
                               [moduleId]="notification.moduleId"
                               [type]="notification.typeSolicitude.split('-')[0]"
                               [creationDate]="notification.creationDate"
                               (eventClickSnooze)="onClickSnooze($event)"
                               (eventClickSeeDocument)="onClickSeeDocument(
                                  $event, notification.notificationTypeId, notification.id)"
                               (eventClickReject)="onClickReject(
                                  $event, notification.notificationTypeId, notification.id)"
                               (eventClickAccept)="onClickAccept(
                                  $event, notification.notificationTypeId, notification.id)">
                                </app-notification-card>

      </div>
    </div>

  </div>
</p-sidebar>

<p-toast></p-toast>
