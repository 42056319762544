import { ISubGroupItem } from '../../interfaces/sub-group-item.interface';

export class SubLoyalty implements ISubGroupItem {
    constructor(
        public descSku?: string,
        public id?: string,
        public sku?: string,
        public unitMeasure?: string,
        public purcheseUnit?: number,
        public check?: boolean
    ) { }
}
