import { IAssociateUser } from '../interfaces/associate-user.interface';

export class AssociateUserModel implements IAssociateUser {
    /**
    * @author Jhon Steven Pavón Bedoyaid
    * @param name
    * @param lastName
    * @param email
    * @param accessManagementId
    * @param profileId
    * @param mandatory
    * @param authorizer
    * @param approval
    * @sprint 6
    */
    constructor(
        public name: string,
        public lastName: string,
        public email: string,
        public accessManagementId: string,
        public profileId: number,
        public mandatory: boolean = false,
        public authorizer: boolean = false,
        public approval: boolean = false
    ) {}

  }