import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudeNotificationUserStatusModel } from '@app/@modules/prices/solicitude/models/solicitude-notification-user-status.model';
import { SolicitudeStatusModel } from '@app/@modules/prices/solicitude/models/solicitude-status.model';
import { SolicitudeService } from '@app/@modules/prices/solicitude/solicitude.service';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { Module } from '@app/@shared/enums/module.enums';
import { NotificationType } from '@app/@shared/enums/notification-type.enum';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ErrorMessage } from '@app/@shared/messages/error.message';
import { NotificationInformativeModel } from '@app/@shared/model/notification-informative.model';
import { PaginationNotificationModel } from '@app/@shared/model/paginationNotification.model';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { INotification } from '../Interfaces/notification.interface';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }

        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }


    `,
  ],
  providers: [MessageService],
})
export class NotificationComponent implements OnInit, OnChanges {
  @Input('isVisible') isVisible = false;
  @Output('eventHidden') eventHidden: EventEmitter<any> = new EventEmitter();

  public id: number;
  public objectListNotification: INotification[];

  public snooze = false;
  public accept = true;
  public moreInfo = false;

  public notificationColor = 'color-notificacion';
  public title = 'Title';
  public notificationText = 'Notification Text';

  public notificationTypeEnum = NotificationType;

  private readonly notificationService: NotificationService;
  private readonly solicitudeService : SolicitudeService;
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly router: Router;
  private readonly managePromotionalActivitiesService: ManagePromotionalActivitiesService;

  constructor(
    notificationService: NotificationService,
    solicitudeService : SolicitudeService,
    spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router,
    managePromotionalActivitiesService: ManagePromotionalActivitiesService)
  {
    this.notificationService = notificationService;
    this.solicitudeService = solicitudeService;
    this.spinner = spinner;
    this.messageService = messageService;
    this.router = router;
    this.managePromotionalActivitiesService = managePromotionalActivitiesService;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.isVisible)
      this.getAll();
  }

  /**
   * @description this method is to emit event the father.
   * @author Priscy Antonio Reales
   */
  onHidden(): void {
    this.eventHidden.emit(!this.isVisible);
  }

  onClickSnooze($event: any): void {}

  /**
   * @description this method reject solicitude.
   * @author David Sneider Cardona Cardenas
   * @sprint 6
   * @param $event
   */
  onClickReject($event: any, notifiactionType : number, solicitudeNotificationId : number): void {
    if(notifiactionType === NotificationType.ApprovalRejection){
      this.updateStatus($event.moduleId, $event.id, SolicitudeStatus.Rejected);
    }
    this.updateStatusSolictudeNotification(solicitudeNotificationId);
  }

  /**
   * @description this method is to see document.
   * @author Priscy Antonio Reales
   * @param $event
   */
   onClickSeeDocument($event: any, notifiactionType : number, solicitudeNotificationId : number) : void {
    this.updateStatusSolictudeNotification(solicitudeNotificationId);
    if($event.moduleId === Module.Price){
      this.solicitudeService.setSolicitudeId($event.id);
      let route = 'auth/pending-solicitude';
      if(notifiactionType === NotificationType.System){
        route = 'auth/solicitude';
      }

      if(Number($event.type) === SolicitudeType.Creation){
        this.router.navigate([`${route}/creation/`]);
      }else if($event.type === SolicitudeType.Withdrawal){
        this.router.navigate([`${route}/withdrawal/`]);
      }else{
        return;
      }
    }else if($event.moduleId === Module.PromotionalActivity){

        this.managePromotionalActivitiesService.setCommercialActivityId($event.id);
        this.managePromotionalActivitiesService.setIsCreate(ComercialActivityEnum.view);

        if (Number($event.type) === CommercialActivitySubprocess.Promotions) {
          this.router.navigate(['/auth/manage-promotional-activities/create']);
        }
        else if(Number($event.type) === CommercialActivitySubprocess.DiscountValue ||
                Number($event.type) === CommercialActivitySubprocess.DiscountKind ||
                Number($event.type) === CommercialActivitySubprocess.FinancialDiscount ||
                Number($event.type) === CommercialActivitySubprocess.PriceDiscount)
        {
          this.router.navigate(['/auth/manage-promotional-activities/discount']);
        }
        else if(Number($event.type) === CommercialActivitySubprocess.Loyalty)
        {
          this.router.navigate(['/auth/manage-promotional-activities/loyalty']);
        }
        else if(Number($event.type) === CommercialActivitySubprocess.Events)
        {
          this.router.navigate(['/auth/manage-promotional-activities/events']);
        }
        else if(Number($event.type) === CommercialActivitySubprocess.ZeroValueProduct)
        {
          this.router.navigate(['/auth/manage-promotional-activities/zero-value-product']);
        }
        else if(Number($event.type)  === CommercialActivitySubprocess.Redemption)
        {
          this.router.navigate(['/auth/manage-promotional-activities/redemption']);
        }
        else if(Number($event.type)  === CommercialActivitySubprocess.Rappel) {
          this.router.navigate(['/auth/manage-promotional-activities/rappel']);
        }
    }else if($event.moduleId === Module.Negotiations){
      // Code ... by Negotiations
    }else{
      const language: string = sessionStorage.getItem('language');
      this.showToast(
        SweetAlert.iconError,
        language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
        language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate
      );
    }
  }

  /**
   * @description this method accept solicitude.
   * @author David Sneider Cardona Cardenas
   * @sprint 6
   * @param object
   */
  onClickAccept($event: any, notifiactionType : number, solicitudeNotificationId : number): void {
    this.updateStatusSolictudeNotification(solicitudeNotificationId);
    this.getAll();
    // if(notifiactionType === NotificationType.ApprovalRejection){
    //   this.updateStatus($event.moduleId, $event.id, SolicitudeStatus.InRecord);
    // }
  }

  onClicViewAll() {
    this.router.navigate(['auth/notification-panel']);
  }

  /**
   * @description this method is to get all the notification.
   * @author dcardona
   * @sprint 4
   */
  getAll(): void {
    this.spinner.show();
    const paginationNotificationModel = new PaginationNotificationModel(
      'id',
      false,
      1,
      100
    );

    this.notificationService
      .getListNotification(paginationNotificationModel)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.objectListNotification = response.data;
        },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }

  /**
   * @description This method is to update status.
   * @author Priscy Antonio Reales
   * @sprint 7
   * @param moduleId
   * @param id
   * @param status
   * @returns none
   */
   updateStatus(moduleId : number, id : number, status : number): void {
    if(moduleId === Module.Price){
      this.checkUpdate(id, status);
    }else if(moduleId === Module.PromotionalActivity){
      // Code ... for update status by PromotionalActivity
    }else if(moduleId === Module.Negotiations){
      // Code ... for update status by Negotiations
    }else{
      const language: string = sessionStorage.getItem('language');
      this.showToast(
        SweetAlert.iconError,
        SweetAlert.titleAlertError,
        language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate
      );
    }
  }

  private checkUpdate(id: number, status: number) {
    this.spinner.show();
    const statusModel: SolicitudeStatusModel = new SolicitudeStatusModel(
      id,
      status
    );

    this.solicitudeService.putSolicitudeStatus(id, statusModel)
      .subscribe(
        (response) => {
          if (response.status) {
            const language: string = sessionStorage.getItem('language');
            if(language === 'es-ES'){
              this.showToast(
                SweetAlert.iconSuccess,
                SweetAlert.titleAlert,
                status === SolicitudeStatus.InRecord ? SweetAlert.messageAlertAcceptSolicitude : SweetAlert.messageAlertRejectSolicitude
              );
            }else{
              this.showToast(
                SweetAlert.iconSuccess,
                SweetAlert.titleAlertTranslate,
                status === SolicitudeStatus.InRecord ? SweetAlert.messageAlertAcceptSolicitudeTranslate : SweetAlert.messageAlertRejectSolicitudeTranslate
              );
            }

            const modelNotification: NotificationInformativeModel = new NotificationInformativeModel(
              Module.Price,
              status === SolicitudeStatus.Rejected ? NotificationType.System : NotificationType.Informative,
              id
            );
            this.solicitudeService.putSolicitudeNotificationInformative(modelNotification)
              .subscribe(
                () => {
                  this.getAll();
                }
              );
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        },
        (error) => {
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
   * @description This method is to update status notification.
   * @author Priscy Antonio Reales
   * @sprint 11
   * @param id
   * @returns none
   */
  updateStatusSolictudeNotification (id : number) : void
  {
    this.spinner.show();
      const statusModel: SolicitudeNotificationUserStatusModel = new SolicitudeNotificationUserStatusModel(
        id,
        true
      );
    this.solicitudeService.putStatusSolicitudeNotificationUser(id, statusModel)
      .subscribe(
        (response) => {
          if (response.status) {
            this.getAll();
          } else {
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(
              SweetAlert.titleAlertError,
              language === 'es-ES' ? ErrorMessage.ERROR_UPDATE_NOTIFICATION
              : ErrorMessage.ERROR_UPDATE_NOTIFICATION_TRANSLATE);
          }
        },
        (error) => {
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
   * @description This method shows the toast control.
   * @author David Sneider Cardona Cardenas
   * @sprint 6
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }
}
