import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-negotiacion-create',
  providers: [],
  templateUrl: './negotiation-create.component.html',
  styleUrls: [],
  styles: [ ],
})
export class NegotiationCreateComponent implements OnInit {
  private readonly fb: FormBuilder;
  private readonly spinner: NgxSpinnerService;
  private readonly router: Router;
  private readonly route: ActivatedRoute;

  public formNegotiation: FormGroup;
  public objectChannel: ICombo[] = [];

  constructor(
    fb: FormBuilder,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
  ) {
    this.fb = fb;
    this.spinner = spinner;
    this.router = router;
    this.route = route;
  }

  ngOnInit(): void {
    this.initForm();
  }



  /**
  * @description this method is to initialize the form.
  * @author Jhon Steven Pavón Bedoya
  * @sprint 14
  */
   initForm(): void {
    this.formNegotiation = this.fb.group({
      TypeNegotiation: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  get typeNegotiation(): AbstractControl { return this.formNegotiation.get('TypeNegotiation'); }

}
