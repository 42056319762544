<div *ngIf="!switchHiddenButtons.switchFormControl">
<form id="formPeriod" [formGroup]="formSwitch" style="display: flex; align-items: center;">
    <p-inputSwitch inputId="switch1" 
        formControlName="switchValueForm"
        [disabled]="isDisabledInput"
        (onChange)="onChangeSwitch($event)">
    </p-inputSwitch>
    <label for="switch1" class="ml-2">{{ nameSwitch | translate }}</label>
</form>
</div>
<div *ngIf="!switchHiddenButtons.switchNgModel" style="display: flex; align-items: center;">
    <p-inputSwitch inputId="switch2"
        [(ngModel)]="nameNgModel" 
        [disabled]="isDisabledInput"
        (onChange)="onChangeNgModel()">
    </p-inputSwitch>
    <label for="switch1" class="ml-2">{{ nameSwitch | translate }}</label>
</div>
