import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { CommercialActivityStatusModel } from '@app/@modules/promotionalactivity/manage-promotional-activities/models/commercial-activity-status.model';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationConstants } from '@app/app.constants';

@Component({
  selector: 'app-commercial-activity-planned',
  templateUrl: './commercial-activity-planned.component.html',
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }

        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService],
})
export class CommercialActivityPlannedComponent implements OnInit {
  @Input('isVisible') isVisible = false;
  @Input('commercialActivityId') commercialActivityId: number;

  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly router: Router;
  private readonly managePromotionalActivitiesService: ManagePromotionalActivitiesService;

  constructor(
    spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router,
    managePromotionalActivitiesService: ManagePromotionalActivitiesService
  ) {
    this.spinner = spinner;
    this.messageService = messageService;
    this.router = router;
    this.managePromotionalActivitiesService = managePromotionalActivitiesService;
  }

  ngOnInit(): void {}

  changeStatusActivity(edit: boolean) {
        if (edit) {
          const updateModel: CommercialActivityStatusModel =
            new CommercialActivityStatusModel(
              this.commercialActivityId,
              CommercialActivityStatus.Registered,
              ''
            );
          this.managePromotionalActivitiesService
            .postCommercialActivityUpdateStatus(updateModel)
            .subscribe(
              (response) => {
                this.spinner.hide();
                if (response.status) {
                  SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
                  this.router.navigate(['auth/manage-promotional-activities']);
                } else {
                  SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
                }
                this.spinner.hide();
              },
              (error: HttpErrorResponse) => {
                this.spinner.hide();
                SweetAlertMessageHelpers.exception(error);
              }
            );
        } else {
          this.spinner.hide();
          this.isVisible = false;
        }
  }

  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }
}
