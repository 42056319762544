import { IPaginationForId } from '../interfaces/pagination-for-id.interface';


export class PaginationForId implements IPaginationForId {
  /**
   * @author Jhon Steven Pavón Bedoya
   * @sprint 6
   */
  constructor(
    public id: number,
    public orderBy: string,
    public ascendent: boolean,
    public pageNumber: number,
    public resultsPerPage: number
    ) {}
}