// Components
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

// Service
import { HttpService } from '@app/@shared/services/http.service';


// Models
import { PaginationApprovalRankLevelModel } from './models/pagination-approval-rank-level.model';
import { ApprovalRankManagementModel } from './models/approval-rank-management.model';

@Injectable({
  providedIn: 'root'
})
export class AssociateRankService {
  private readonly httpService: HttpService;
  public objectApprovalRankList: ApprovalRankManagementModel[] = [];

  constructor(httpService: HttpService) {
    this.httpService = httpService;
   }

  /**
  * @description This method is to create Domain.
  * @author Jhon Steven Pavón Bedoya
  * @param params
  * @returns Observable<ResponseService>
  * @sprint 7
  */
   public postHierarchyReleaseWithAssociate(body: PaginationApprovalRankLevelModel): Observable<IResponseService<ApprovalRankManagementModel[]>> {
    let response$: Observable<IResponseService<ApprovalRankManagementModel[]>>;
    response$ = this.httpService
        .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_approval_rank_level/`, body)
        .pipe(
        map((response: HttpResponse<IResponseService<ApprovalRankManagementModel[]>>) => {
            return response.body;
        })
        );
    return response$;
  }

}
