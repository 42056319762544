<div class="BigContainer">
    
    <div class="container-filter-bot" *ngIf="sw===1">
        <button (click)="openBot(0)">X</button>
        <iframe 
        src='https://webchat.botframework.com/embed/CCVPWBOTSBOX?s=QolckR4H-3U.mnM-A1Eq01u9CY4tWzsgDFqAGLYc0KMs_VTBxtbHsSw' 
        style=' width: auto; min-height: 300px;' title="bot" ></iframe>
    </div> 
    <div class="bot-btn" (click)="openBot(1)">
        <img src="assets/images/pbot.svg" alt="">    
    </div>
</div>


