import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChannelService } from '@app/@modules/administration/channel/channel.service';
import { ICountrySocietyChannel } from '@app/@modules/administration/channel/interfaces/country-society-channel.interface';
import { IProfile } from '@app/@modules/administration/profile/interfaces/profile.interface';
import { ICountrySocietyRegion } from '@app/@modules/administration/region/interfaces/country-society-region.interface';
import { RegionService } from '@app/@modules/administration/region/region.service';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { RegexCommon } from '@app/@shared/enums/regex.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { DomainService } from '@app/@shared/services/domain.service';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { SweetAlertResult } from 'sweetalert2';
import { SweetAlertMessageHelpers } from '../../../../@shared/helpers/sweet-alert-message.helper';
import { ProfileService } from '../../../administration/profile/profile.service';
import { Operator } from '../../../../@shared/enums/operator.enum';
import { ApprovalRankService } from '../approval-rank.service';
import { ApprovalRankRequestModel } from '../models/approval-rank-request.model';
import { ApprovalRankLevelRequestModel } from '../models/approval-rank-level-request.model';
import { Router } from '@angular/router';
import { RankNumber } from '../../../../@shared/enums/rank-numbers.enum';
import { ApprovalRankModel } from '../models/approval-ranks.models';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { Util } from '@app/@shared/util';
import { ApprovalRankMessage } from '../messages/approval-rank.message';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'app-approval-rank-create-edit',
  templateUrl: './approval-rank-create-edit.component.html',
  styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
  providers: [MessageService],
})
export class ApprovalRankCreateEditComponent implements OnInit {
  private readonly fb: FormBuilder;
  private readonly messageService: MessageService;
  private readonly spinner: NgxSpinnerService;
  private readonly coreService: CoreMasterService;
  private readonly regionServive: RegionService;
  private readonly channelService: ChannelService;
  private readonly domainService: DomainService;
  private readonly profileService: ProfileService;
  private readonly approvalRankService: ApprovalRankService;
  private readonly router: Router;
  private readonly translate: TranslateService;
  private readonly i18nService: I18nService;

  public isCreate = true;
  public isJustLook  = false;
  public formApprovalRank: FormGroup;
  public objectCountryList: ICombo[];
  public objectSocietyList: ICombo[];
  public objectChannelList: ICombo[];
  public objectRegionList: ICombo[];
  public objectCediList: ICombo[];
  public objectProcessList: ICombo[];
  public objectSubprocess: IDomain[];
  public objectSubprocessList: ICombo[];
  public objectUnitMeasurementList: ICombo[];
  public objectOperatorListStart: ICombo[];
  public objectOperatorListEnd: ICombo[];
  public objectApprovalRankList: ApprovalRankModel[] = [];
  public objectProfileList: ICombo[];
  public rolePermission: any = RolePermissionEnum;
  public language: string = sessionStorage.getItem('language');

  constructor(
    fb: FormBuilder,
    messageService: MessageService,
    spinner: NgxSpinnerService,
    coreService: CoreMasterService,
    regionServive: RegionService,
    channelService: ChannelService,
    domainService: DomainService,
    profileService: ProfileService,
    approvalRankService: ApprovalRankService,
    router: Router,
    translate: TranslateService,
    i18nService: I18nService
  ) { 
      this.fb = fb;
      this.messageService = messageService;
      this.spinner = spinner;
      this.coreService = coreService;
      this.regionServive = regionServive;
      this.channelService = channelService;
      this.domainService = domainService;
      this.profileService = profileService;
      this.approvalRankService = approvalRankService;
      this.router = router;
      this.translate = translate;
      this.i18nService = i18nService;
  }

  /**
   * @description this method is private.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  ngOnInit(): void {
    this.initForm();
    this.forkJoinData(true);
  }

  /**
  * @description this method calculate new record approval rank level
  * @author Daniel Londoño
  * @sprint 6
  */
  calculateRanges(rankLevel: ApprovalRankModel): void {
    let checkRank = true;
    let minorNumber = 0;

    if (this.objectApprovalRankList && this.validateData()) {
      this.objectApprovalRankList.filter((y) => y.approvalRankLevelId !== rankLevel.approvalRankLevelId || y.approvalRankdIdMemory !== rankLevel.approvalRankdIdMemory)
      .forEach((x) => {
        minorNumber = Math.min(...this.objectApprovalRankList.filter((y) => y.approvalRankLevelId !== rankLevel.approvalRankLevelId 
        || y.approvalRankdIdMemory !== rankLevel.approvalRankdIdMemory).map((r) => r.rankStart));

        let searchRank: number[] | undefined;
        ({ searchRank, checkRank } = this.ValidateRanks(x, rankLevel, checkRank));

        ({ checkRank, minorNumber } = this.DefineRankLevel(searchRank, checkRank, minorNumber, rankLevel));
      });

      if (!checkRank) {
        const language: string = sessionStorage.getItem('language');
        this.showToast(SweetAlert.iconError,
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate);
      }
    }
  }

  /**
  * @description this method delete new record approval rank
  * @author Daniel Londoño
  * @sprint 6
  */
  deleteRecordApprovalRankLevel(rankLevel: ApprovalRankModel): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        if (rankLevel.approvalRankLevelId !== undefined && rankLevel.approvalRankLevelId !== null) {
          this.approvalRankService.deleteDetailLogic(rankLevel.approvalRankLevelId)
          .subscribe(
            (response) => {
              if (response.status){
                this.spinner.hide();
                this.objectApprovalRankList = this.objectApprovalRankList.filter((x) => x.approvalRankLevelId !== rankLevel.approvalRankLevelId);
              }else
                 SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
        } else {
          this.objectApprovalRankList = this.objectApprovalRankList.filter((x) => x.approvalRankdIdMemory !== rankLevel.approvalRankdIdMemory);
        }
      }
      this.spinner.hide();
    });
  }

  /**
  * @description this method is to validate records
  * @author Daniel Londoño
  * @sprint 6
  */
  validateData(): boolean {
    let result = true;
    let messages = '';
    this.objectApprovalRankList.forEach((x) => {
      const countNumbers = this.objectApprovalRankList.filter((z) => z.approvalLevel === x.approvalLevel).length;
      if(result && countNumbers > 1) {
        messages = ApprovalRankMessage.APPROVAL_LEVEL_REPEAT_ERROR;
        result = false;
      }
      if(result && !x.approvalLevel) {
        messages = ApprovalRankMessage.NOT_APPROVAL_LEVEL;
        result = false;
      }
      if(result && x.rankStart && !x.operatorStart){
        messages = ApprovalRankMessage.NOT_OPERATOR_START;
        result = false;
      }
      if(result && x.rankEnd && !x.operatorEnd){
        messages = ApprovalRankMessage.NOT_OPERATOR_END;
        result = false;
      }
      if(result && x.operatorStart && !x.rankStart){
        messages = ApprovalRankMessage.NOT_VALUE_START;
        result = false;
      }
      if(result && x.operatorEnd && !x.rankEnd){
        messages = ApprovalRankMessage.NOT_VALUE_END;
        result = false;
      }
      if(result && x.rankEnd && x.rankEnd <= x.rankStart){
        messages = ApprovalRankMessage.NOT_ACCEPTED_VALUE;
        result = false;
      }
    });
    if(!result)
      this.showToast(SweetAlert.iconError, SweetAlert.titleAlertError, messages);
    return result;
  }

  /**
  * @description this method validate new record approval rank level
  * @author Daniel Londoño
  * @sprint 6
  */
  addRecordApprovalRankLevel(): void {
    let indicatorError = 0;
    this.spinner.show();
    if (this.objectApprovalRankList.length > 0) {
      if (this.validateData()) {
        this.objectApprovalRankList.forEach((x) => {
          indicatorError = this.IndicatorErrorCount(x, indicatorError);
        });

        if (indicatorError === RankNumber.Zero) {
          this.newRecrod();
        }
        else {
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconWarningToast,
            language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
            language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate);
        }
      }
    }
    else {
      this.newRecrod();
    }
    this.spinner.hide();
  }

  /**
  * @description this method add new record approval rank
  * @author Daniel Londoño
  * @sprint 6
  */
  newRecrod(): void {
    let maxMemoryId = Math.max(...this.objectApprovalRankList.map((x) => x.approvalRankdIdMemory === undefined ? 0 : x.approvalRankdIdMemory), 0);
    maxMemoryId++;
    this.objectApprovalRankList.push({ approvalLevel: null, operatorStart: null, rankStart: null, operatorEnd: null, rankEnd: null, approvalRankdIdMemory: maxMemoryId });
  }

  /**
  * @description this method execute methods async.
  * @author Priscy Antonio Reales
  * @sprint 6
  */
  forkJoinData(isFirst: boolean = false): void {
    if(this.language === this.translate.currentLang && !isFirst){
      return;
    }else{
      this.translate.use(this.i18nService.language);
      this.language = this.i18nService.language;
    }

    this.spinner.show();

    const countryResponse = this.coreService.getAll(TypeCoreMaster.Country);
    const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Process);
    const subprocessResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);
    const unitResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.UnitMeasurement);
    const operatorResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Operator);
    const profileResponse = this.profileService.getAllProfileApprovalLevel();
    forkJoin([
      countryResponse,
      processResponse,
      subprocessResponse,
      unitResponse,
      operatorResponse,
      profileResponse
    ])
      .subscribe(
        (result) => {

          const objCountryResponse: IResponseService<ICoreMaster[]> = result[0];
          const objProcessResponse: IResponseService<IDomain[]> = result[1];
          const objSubprocessResponse: IResponseService<IDomain[]> = result[2];
          const objUnitResponse: IResponseService<IDomain[]> = result[3];
          const objOperatorResponse: IResponseService<IDomain[]> = result[4];
          const objProfileResponse: IResponseService<IProfile[]> = result[5];

          this.objectCountryList = objCountryResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });

          this.objectProcessList = objProcessResponse.data.map((item) => {
            return {
              label: this.translate.instant('Domain.Domain-'+item.id),
              value: `${item.id}-${item.code}`
            };
          });

          this.objectSubprocess = objSubprocessResponse.data;

          this.objectUnitMeasurementList = objUnitResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          if (objProfileResponse.data.length > 0) {
            this.objectOperatorListStart = objOperatorResponse.data.map((item) => {
              return {
                label: item.description,
                value: item.code
              };
            });
          }
          else {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            return;
          }
          this.objectProfileList = objProfileResponse.data.map((item) => {
            return {
              label: item.description,
              value: `${item.id}-${item.description}`
            };
          });

          this.objectOperatorListEnd = this.objectOperatorListStart.filter((x) => x.value === Operator.LessThan || x.value === Operator.LessThanEqual);
          this.objectOperatorListStart = this.objectOperatorListStart.filter((x) => x.value !== Operator.LessThan && x.value !== Operator.LessThanEqual);

          this.spinner.hide();
          this.getById();
        },
        () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
  }

  /**
   * @description this method is to get Approval Rank by Id.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  getById(): void {
    this.spinner.show();
    const id = this.approvalRankService.getApprovalRankId();
    if (!id) {
      this.spinner.hide();
      return;
    }
    this.approvalRankService.getApprovalRankById(id).subscribe(
      (response) => {
        if (response.status) {
          this.isCreate = false;
          this.description.setValue(response.data.description);
          this.country.setValue(response.data.countryCode);
          this.process.setValue(`${response.data.processId}-${response.data.processCode}`);
          this.unitMeasurement.setValue(response.data.unitMeasurementId);

          const societyResponse = this.coreService.getAllSocietiesByCountry(this.country.value);

          forkJoin([societyResponse])
            .subscribe(
              (result) => {
                const objSocietyResponse: IResponseService<ICoreMaster[]> = result[0];

                this.objectSocietyList = objSocietyResponse.data.map((item) => {
                  return {
                    label: item.description,
                    value: item.code.trim()
                  };
                });
                this.society.enable();
                this.society.setValue(response.data.societyCode);

                const regionResponse = this.regionServive.getAllRegionBySociety(this.society.value);
                const channelResponse = this.channelService.getAllChannelBySociety(this.society.value);
                const cediResponse = this.coreService.getAllCediBySociety(this.society.value);

                forkJoin([
                  regionResponse,
                  channelResponse,
                  cediResponse
                ])
                  .subscribe(
                    (result) => {
                      const objRegionResponse: IResponseService<ICountrySocietyRegion[]> = result[0];
                      const objChannelResponse: IResponseService<ICountrySocietyChannel[]> = result[1];
                      const objCediResposonse: IResponseService<ICoreMaster[]> = result[2];

                      this.objectRegionList = objRegionResponse.data.map((item) => {
                        return {
                          label: item.regionDescription,
                          value: item.regionCode.trim()
                        };
                      });
                      this.region.enable();
                      this.objectChannelList = objChannelResponse.data.map((item) => {
                        return {
                          label: item.channelDescription,
                          value: item.channelCode.trim()
                        };
                      });
                      this.channel.enable();
                      this.objectCediList = objCediResposonse.data.map((item) => {
                        return {
                          label: item.description,
                          value: item.code.trim()
                        };
                      });
                      this.cedi.enable();

                      this.region.setValue(response.data.regionCode);
                      this.channel.setValue(response.data.channelCode);
                      this.cedi.setValue(response.data.cediCode);
                      this.getSubprocessByCodeProcess();
                      this.subprocess.setValue(response.data.subprocessId);

                      if (response.data.approvalRankDetailJson) {
                        this.objectApprovalRankList = response.data.approvalRankDetailJson.map((item) => {
                          return {
                            approvalRankLevelId: item.approvalRankLevelId,
                            approvalLevel: item.approvalLevel,
                            operatorStart: item.operatorStart,
                            rankStart: item.rankStart,
                            operatorEnd: item.operatorEnd,
                            rankEnd: item.rankEnd,
                          };
                        });
                      }
                      this.disableFields();
                      this.spinner.hide();
                    },
                    () => {
                      this.spinner.hide();
                      SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
                    }
                  );
              },
              () => {
                this.spinner.hide();
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            );
        } else {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      () => {
        this.spinner.hide();
        SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
      }
    );
  }

  /**
  * @description this method is to get Societies By Country.
  * @author Priscy Antonio Reales
  * @sprint 6
  */
  getSocietiesByCountry(): void {
    const id = this.country.value;
    if (id !== '') {
      this.spinner.show();
      this.coreService.getAllSocietiesByCountry(id).subscribe(
        (response) => {
          this.spinner.hide();
          this.objectSocietyList = response.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });
          this.society.enable();
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }
  }

  /**
  * @description this method is to get Cedi, Channel By Society.
  * @author Priscy Antonio Reales
  * @sprint 6
  */
  getCediChannelRegionBySocietyId(): void {
    const id = this.society.value;
    if (id !== '') {
      this.spinner.show();

      const regionResponse = this.regionServive.getAllRegionBySociety(id);
      const channelResponse = this.channelService.getAllChannelBySociety(id);
      const cediResponse = this.coreService.getAllCediBySociety(id);

      forkJoin([
        regionResponse,
        channelResponse,
        cediResponse
      ])
        .subscribe(
          (result) => {
            const objRegionResponse: IResponseService<ICountrySocietyRegion[]> = result[0];
            const objChannelResponse: IResponseService<ICountrySocietyChannel[]> = result[1];
            const objCediResponse: IResponseService<ICoreMaster[]> = result[2];

            this.objectRegionList = objRegionResponse.data.map((item) => {
              return {
                label: item.regionDescription,
                value: item.regionCode.trim()
              };
            });
            this.region.enable();
            this.objectChannelList = objChannelResponse.data.map((item) => {
              return {
                label: item.channelDescription,
                value: item.channelCode.trim()
              };
            });
            this.channel.enable();
            this.objectCediList = objCediResponse.data.map((item) => {
              return {
                label: item.description,
                value: item.code.trim()
              };
            });
            this.cedi.enable();
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        );
    }
  }

  /**
  * @description this method is to filter Subrocess By ProcessCode.
  * @author Priscy Antonio Reales
  * @sprint 6
  */
  getSubprocessByCodeProcess(): void {
    const code = this.process.value.split('-')[1];
    if (code !== '') {
      this.subprocess.enable();
      this.objectSubprocessList = this.objectSubprocess.filter((x) => x.code === code).map((item) => {
        return {
          label: this.translate.instant('Domain.Domain-'+item.id),
          value: item.id
        };
      });
    } else {
      this.subprocess.disable();
    }
  }

  /**
   * @description This method return model.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  getModel(): ApprovalRankRequestModel {
    return new ApprovalRankRequestModel(
      this.approvalRankService.getApprovalRankId(),
      this.description.value,
      this.country.value,
      this.society.value,
      this.channel.value,
      this.region.value,
      this.cedi.value,
      parseInt(this.process.value.split('-')[0], 10),
      this.subprocess.value,
      this.unitMeasurement.value,
      true,
      this.getModelDetail()
    );
  }

  /**
   * @description This method return model Detail.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  getModelDetail(): ApprovalRankLevelRequestModel[] {
    const modelDetail: ApprovalRankLevelRequestModel[] = this.objectApprovalRankList.map(
      (item) => {
        return {
          id: item.approvalRankLevelId,
          approvalRankId: this.approvalRankService.getApprovalRankId(),
          approvalLevel: item.approvalLevel,
          operatorStart: item.operatorStart,
          rankStart: item.rankStart,
          operatorEnd: item.operatorEnd,
          rankEnd: item.rankEnd
        };
      }
    );
    return modelDetail;
  }

  /**
  * @description this method is to cancel and redirect.
  * @author Priscy Antonio Reales
  * @sprint 6
  */
  onCancel(): void {
    this.router.navigate(['auth/approval-rank']);
  }

  /**
  * @description this method is to add.
  * @author Priscy Antonio Reales
  * @sprint 6
  */
  onAdd(): void {
    if (this.formApprovalRank.valid) {
      this.spinner.show();
      if (this.validateData()) {
        const model = this.getModel();
        this.approvalRankService.post(model)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.clearControl();
                this.formApprovalRank.reset();
                this.clearControl();
                const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
                this.router.navigate(['auth/approval-rank']);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }else {
        this.spinner.hide();
      }
    } else {
      this.formApprovalRank.markAllAsTouched();
    }
  }

  /**
  * @description this method is to update.
  * @author Priscy Antonio Reales
  * @sprint 6
  */
  onUpdate(): void {
    if (this.formApprovalRank.valid) {
      this.spinner.show();
      if (this.validateData()) {
        const model = this.getModel();
        this.approvalRankService.put(this.approvalRankService.getApprovalRankId(), model)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.clearControl();
                this.formApprovalRank.reset();
                this.clearControl();
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconWarningToast,
                  language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate);
                this.router.navigate(['auth/approval-rank']);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
      else {
        this.spinner.hide();
      }
    } else {
      this.formApprovalRank.markAllAsTouched();
    }
  }

  /**
   * @description this method is to clear controls.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  clearControl(): void {
    this.isCreate = true;
    this.approvalRankService.setApprovalRankId(0);
    this.description.setValue('');
    this.country.setValue('');
    this.society.setValue('');
    this.channel.setValue('');
    this.region.setValue('');
    this.cedi.setValue('');
    this.process.setValue('');
    this.subprocess.setValue('');
    this.unitMeasurement.setValue('');
    this.objectApprovalRankList = [];
  }

  /**
   * @description this method is to desable controls.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  disableFields() : void {
    if(this.approvalRankService.getIsJustLook()){
      this.isJustLook = true;
      this.description.disable();
      this.country.disable();
      this.society.disable();
      this.channel.disable();
      this.region.disable();
      this.cedi.disable();
      this.process.disable();
      this.subprocess.disable();
      this.unitMeasurement.disable();
    }
  }

  /**
   * @description This method shows the toast control.
   * @author Priscy Antonio Reales
   * @sprint 6
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  /**
   * @description this method is to initialize the form.
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  initForm(): void {
    this.formApprovalRank = this.fb.group({
      description: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(100), Validators.pattern(RegexCommon.ExpRegOnlyCaracterNumber100)]],
      country: [{ value: '', disabled: false }, [Validators.required]],
      society: [{ value: '', disabled: true }, [Validators.required]],
      channel: [{ value: '', disabled: true }, [Validators.required]],
      region: [{ value: '', disabled: true }, [Validators.required]],
      cedi: [{ value: '', disabled: true }, [Validators.required]],
      process: [{ value: '', disabled: false }, [Validators.required]],
      subprocess: [{ value: '', disabled: true }, [Validators.required]],
      unitMeasurement: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  get description(): AbstractControl { return this.formApprovalRank.get('description'); }
  get country(): AbstractControl { return this.formApprovalRank.get('country'); }
  get society(): AbstractControl { return this.formApprovalRank.get('society'); }
  get channel(): AbstractControl { return this.formApprovalRank.get('channel'); }
  get region(): AbstractControl { return this.formApprovalRank.get('region'); }
  get cedi(): AbstractControl { return this.formApprovalRank.get('cedi'); }
  get process(): AbstractControl { return this.formApprovalRank.get('process'); }
  get subprocess(): AbstractControl { return this.formApprovalRank.get('subprocess'); }
  get unitMeasurement(): AbstractControl { return this.formApprovalRank.get('unitMeasurement'); }

  keyupApprovalRank(event: any) {
    const value = Util.keyupLetterWithNumbersValidation(event);
    this.description.setValue(value);
  }

  private ValidateRanks(x: ApprovalRankModel, rankLevel: ApprovalRankModel, checkRank: boolean) {
    let searchRank: number[] = [];
    if (x.rankEnd === null || x.rankEnd === RankNumber.Zero) {
      checkRank = this.CheckRankByRankLevel(x, rankLevel, checkRank);
    }
    else {
      let numberStar: number = x.rankStart;
      let numberEnd: number = x.rankEnd;
      let numberStarMemory: number = rankLevel.rankStart;
      let numberEndMemory: number = rankLevel.rankEnd;

      ({ numberStar, numberEnd, numberStarMemory, numberEndMemory, searchRank } = this.DefineSearchRankByRankLevel(x, numberStar, numberEnd, rankLevel, numberStarMemory, numberEndMemory, searchRank));
    }
    return { searchRank, checkRank };
  }

  private CheckRankByRankLevel(x: ApprovalRankModel, rankLevel: ApprovalRankModel, checkRank: boolean) {
    if (x.operatorStart === Operator.GreaterThan) {
      if (rankLevel.rankStart > x.rankStart || rankLevel.rankEnd > x.rankStart) {
        checkRank = false;
      }
    }
    if (x.operatorStart === Operator.GreaterThanEqual) {
      if (rankLevel.rankStart >= x.rankStart || rankLevel.rankEnd >= x.rankStart) {
        checkRank = false;
      }
    }
    if (x.operatorStart === Operator.LessThan) {
      if (rankLevel.rankStart < x.rankStart || rankLevel.rankEnd < x.rankStart) {
        checkRank = false;
      }
    }
    if (x.operatorStart === Operator.LessThanEqual) {
      if (rankLevel.rankStart <= x.rankStart || rankLevel.rankEnd <= x.rankStart) {
        checkRank = false;
      }
    }
    if (x.operatorStart === Operator.Equal) {
      if (rankLevel.rankStart === x.rankStart || rankLevel.rankEnd === x.rankStart) {
        checkRank = false;
      }
    }
    return checkRank;
  }

  private DefineSearchRankByRankLevel(x: ApprovalRankModel, numberStar: number, numberEnd: number, rankLevel: ApprovalRankModel, numberStarMemory: number, numberEndMemory: number, searchRank: number[]) {
    if (x.operatorStart === Operator.GreaterThan) {
      numberStar++;
    }
    if (x.operatorEnd === Operator.LessThanEqual) {
      numberEnd++;
    }
    if (rankLevel.operatorStart === Operator.GreaterThan) {
      numberStarMemory++;
    }
    if (rankLevel.operatorEnd === Operator.LessThanEqual) {
      numberEndMemory++;
    }

    let numbersRangeNewRecord: number[];
    let numbersRange: number[];
    numbersRange = this.generateRange(numberStar, numberEnd);
    if (rankLevel.rankEnd === null || rankLevel.rankEnd === RankNumber.Zero) {
      searchRank = numbersRange.filter((x) => x === rankLevel.rankStart);
    } else {
      numbersRangeNewRecord = this.generateRange(numberStarMemory, numberEndMemory);
      searchRank = numbersRange.filter((x) => numbersRangeNewRecord.includes(x));
    }
    return { numberStar, numberEnd, numberStarMemory, numberEndMemory, searchRank };
  }

  /**
  * @description this method generate sequence numbers in a range
  * @author Daniel Londoño
  * @sprint 6
  */
  generateRange(start: number, end: number): number[] {
    const sequenceNumbers = [];
    for (let i = start; i < end; i++) {
      sequenceNumbers.push(i);
    }
    return sequenceNumbers;
  }

  private DefineRankLevel(searchRank: number[], checkRank: boolean, minorNumber: number, rankLevel: ApprovalRankModel) {
    if (searchRank.length > RankNumber.Zero) {
      checkRank = false;
    }
    else {
      minorNumber--;
      if (minorNumber !== RankNumber.Zero) {
        rankLevel.rankEnd = minorNumber;
        rankLevel.operatorEnd = Operator.LessThanEqual;
      }
      else {
        if (rankLevel.rankStart === RankNumber.One && minorNumber === RankNumber.Zero) {
          rankLevel.operatorStart = Operator.Equal;
        }
      }
    }
    return { checkRank, minorNumber };
  }

  private IndicatorErrorCount(x: ApprovalRankModel, indicatorError: number) {
    if (!x.approvalLevel) {
      indicatorError++;
    }
    if (x.operatorStart === null || x.operatorStart === '') {
      indicatorError++;
    }
    if (x.rankStart === null || x.rankStart === RankNumber.Zero) {
      indicatorError++;
    }
    if ((x.operatorEnd !== null) && (x.rankEnd === null)) {
      indicatorError++;
    }
    if ((x.operatorEnd !== null) && (x.rankEnd !== null)) {
      if (x.rankStart > x.rankEnd) {
        indicatorError++;
      }
    }
    return indicatorError;
  }
}
