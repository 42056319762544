<p-dialog
  [(visible)]="isVisible"
  [style]="{ width: '50vw' }"
  header="{{ 'ManagePromotionalActivitiesComponent.Create.InvalidationModalVerify'| translate }}"
>
  <ng-template pTemplate="footer">
    <p-button
      (click)="changeStatusActivity(true)"
      label="{{ 'YesBtn' | translate}}" 
      styleClass="pstn_primario"
    ></p-button>
    <button
      type="button"
      (click)="changeStatusActivity(false)"
      class="pstn_primario"
      style="background-color: darkgrey; border-color: darkgrey"
    >
    {{ 'NoBtn' | translate}}
    </button>
  </ng-template>
</p-dialog>
<p-toast></p-toast>