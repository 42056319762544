<div class="row justify-content-between">
  <div class=" align-self-center">
    <h2 class="pstn_subtitle">{{ 'AdvancedClientFiltersComponent.SelectedFilters' | translate }}</h2>
    <div *ngIf="!isProcess === commercialActivityEnum.view">
      {{ 'AdvancedClientFiltersComponent.TitleClearFilter' | translate }}
      <button id="btnDelete" type="button" class="delete btn btn-secundary-delete" (click)="clearFilters()">
        <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="float-right">

    <div class="container" *ngIf="isCreate && showFields.enabledFilterAdvanced">
      <button class="pstn_secundario mb-3" (click)="openAdvancedFilters()"
        [disabled]="isProcess === commercialActivityEnum.view"
        *ngIf="rolePermissionAux | rolePermission">
        <i class="uil uil-filter " aria-hidden="true"></i> {{ 'AdvancedClientFiltersComponent.AdvancedFilters' |
        translate }}
      </button>
    </div>

    <div *ngIf="visibleHideFilterClient">
      <p-sidebar [(visible)]="isVisible" position="right" [dismissible]="false" [closeOnEscape]="false"
        [baseZIndex]="10000" styleClass="p-sidebar-md" (onHide)="onHidden()" [style]="{'overflow-x': 'scroll'}">
        <div class="modal-header">
          <div class="row justify-content-between">
            <h5 class="modal-title float-left" id="myModalLabel2"> <strong>{{
                'AdvancedClientFiltersComponent.AdvancedClientFilters' | translate }}</strong> </h5>
          </div>
        </div>
  
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <button pButton pRipple type="button" icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-outlined" (click)="clearFilters()"></button>
            </div>
            <div class="col-md-8" style="text-align: right;">
              <p-button label="{{ 'AdvancedClientFiltersComponent.AddFilterBtn' | translate }}" 
                icon="pi pi-plus-circle"
                styleClass="p-button" 
                (click)="addFilter()" 
                *ngIf="!isVisibleEdit">
              </p-button>
              <p-button 
                label="{{ 'AdvancedClientFiltersComponent.EditFilterBtn' | translate }}" 
                icon="pi pi-plus-circle"
                styleClass="p-button" 
                (click)="addFilter()" 
                *ngIf="isVisibleEdit">
              </p-button>
            </div>
          </div>
  
          <br />
          <!-- Country -->
          <div *ngIf="objectCountryList">
            <div class="form-group">
              <label>{{ 'AdvancedClientFiltersComponent.Country' | translate }}</label>
              <div>
                <p-multiSelect [(ngModel)]="objectCountrySelect" [filter]="true" display="chip" optionLabel="label"
                  placeholder="{{'AdvancedClientFiltersComponent.CountryPlaceHolderMulti'| translate}}"
                  [options]="objectCountryList" [style]="{ width: '100%' }" (onChange)="getSocietiesByCountry(1)">
                </p-multiSelect>
                <small id="countryValidator"
                *ngIf="!messageCountrySelect"
                class="p-error">{{"AccessManagementComponent.Create.CountryMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>
          <!-- Sector -->
          <div *ngIf="objectSectorList && !hiddenSectorandSocietyWithHierarchyLevelOneAux">
            <div class="form-group">
              <label>{{ 'AdvancedClientFiltersComponent.Sector' | translate }}</label>
              <div class="form-group">
                <p-multiSelect [(ngModel)]="objectSectorSelect" [filter]="true" display="chip" optionLabel="label"
                  placeholder="{{'AdvancedClientFiltersComponent.SectorPlaceHolderMulti'| translate}}"
                  [options]="objectSectorList" [style]="{ width: '100%' }" (onChange)="onChangeSectorSelect()">
                </p-multiSelect>
                <small id="sectorValidator"
                       *ngIf="!messageSectorSelect"
                       class="p-error">{{"AccessManagementComponent.Create.SectorMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>
          <!-- Society -->
          <div *ngIf="objectSocietyList && !hiddenSectorandSocietyWithHierarchyLevelOneAux">
            <div class="form-group" *ngIf="isVisibleCountrySelect">
              <label>{{ 'AdvancedClientFiltersComponent.Society' | translate }}</label>
              <div class="form-group">
                <p-multiSelect [(ngModel)]="objectSocietySelect" [filter]="true" display="chip" optionLabel="label"
                  placeholder="{{'AdvancedClientFiltersComponent.SocietyPlaceHolderMulti'| translate}}"
                  [options]="objectSocietyList" [style]="{ width: '100%' }" (onChange)="getRegionCediBySocieties()">
                </p-multiSelect>
                <small id="societyValidator"
                        *ngIf="!messageSocietySelect"
                        class="p-error">{{"AccessManagementComponent.Create.SocietyMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>
          <!-- Region -->
          <div *ngIf="objectRegionList && !hiddenSectorandSocietyWithHierarchyLevelOneAux">
            <div class="form-group" *ngIf="isVisibleSocietySelect">
              <label>{{ 'AdvancedClientFiltersComponent.Region' | translate }}</label>
              <div class="form-group">
                <p-multiSelect [(ngModel)]="objectRegionSelect" [filter]="true" display="chip" optionLabel="label"
                  placeholder="{{'AdvancedClientFiltersComponent.RegionPlaceHolderMulti'| translate}}"
                  [options]="objectRegionList" [style]="{ width: '100%' }" (onChange)="getCediBySocietyRegion(1)">
                </p-multiSelect>
                <small id="regionValidator"
                       *ngIf="!messageRegionSelect"
                       class="p-error">{{"AccessManagementComponent.Create.RegionMsgValidate"| translate}}</small>
              </div>
            </div>
          </div>
          <!-- Cedi -->
          <div *ngIf="objectRegionCedi && !hiddenSectorandSocietyWithHierarchyLevelOneAux">
            <div class="form-group" *ngIf="isVisibleCediSelect">
              <label>{{ 'AdvancedClientFiltersComponent.Cedi' | translate }}</label>
              <div class="form-group">
                <p-multiSelect [(ngModel)]="objectRegionCediSelect" [filter]="true" [group]="true" display="chip"
                  placeholder="{{'AdvancedClientFiltersComponent.CediPlaceHolderMulti'| translate}}" optionLabel="label"
                  [options]="objectRegionCedi" [style]="{ width: '100%' }" (onChange)="setCedi()">
  
                    <ng-template let-group pTemplate="group">
                      <div class="flex align-items-center">
                        <i class="pi pi-minus"
                          aria-hidden="true"></i>
                        <span>{{group.label}}</span>
                      </div>
                    </ng-template>
  
                </p-multiSelect>
                <small id="cediValidator"
                      *ngIf="!messageRegionCediSelect"
                      class="p-error">{{"AccessManagementComponent.Create.CediMsgValidate" | translate}}</small>
              </div>
            </div>
          </div>
  
          
          <div *ngIf="!isVisibleHierarchyTwoySelect && objectSalesForcesTypeList">
            <div *ngIf=" objectRegionCediSelect.length > 0">
              <!-- SalesForceType -->
              <div>
                <div class="form-group">
                  <label>{{ 'AdvancedClientFiltersComponent.SalesForceType' | translate }}</label>
                  <div class="form-group">
                    <p-multiSelect [(ngModel)]="isProcess === 2 ? salesForceNgModel : objectSalesForcesTypeSelect" 
                      [filter]="true" 
                      display="chip"
                      optionLabel="label"
                      placeholder="{{'AdvancedClientFiltersComponent.SalesForceTypePlaceHolderMulti'| translate}}"
                      [options]="objectSalesForcesTypeList" [style]="{ width: '100%' }"
                      (onChange)="getSalesBossBySalesForce($event)">
                    </p-multiSelect>
                    <small id="cediValidator"
                      *ngIf="messageObjectSalesForcesTypeSelect"
                      class="p-error">
                      {{"AdvancedClientFiltersComponent.SalesForceTypePlaceHolderMulti" | translate}}
                    </small>
                  </div>
                </div>
              </div>
  
              <!-- SalesBoss -->
              <div *ngIf="groupedSalesBossList">
                <div class="form-group" *ngIf="isVisibleSalesForceTypeSelect">
                  <label>{{ 'AdvancedClientFiltersComponent.SalesBoss' | translate }}</label>
                  <p-multiSelect [group]="true"
                    [options]="groupedSalesBossList"
                    optionLabel="label"
                    defaultLabel="{{ 'AdvancedClientFiltersComponent.SalesBossPlaceHolderMulti'| translate }}"
                    scrollHeight="250px" display="chip"
                    [style]="{ width: '100%' }"
                    [(ngModel)]="isProcess == 2 ? salesBossNgModel : salesBossSelected"
                    (onChange)="getSupervisorBySalesBoss($event)">
                      <ng-template pTemplate="group" let-groupSalesBoss>
                          <div class="flex align-items-center">
                              <span>{{groupSalesBoss.label}}</span>
                          </div>
                      </ng-template>
                  </p-multiSelect>
                  <small id="cediValidator"
                      *ngIf="messageSalesBossSelected"
                      class="p-error">{{"AdvancedClientFiltersComponent.SalesBossPlaceHolderMulti" | translate}}</small>
                </div>
              </div>
  
              <!-- Supervisor -->
              <div *ngIf="groupedSupervisor">
                <div class="form-group" *ngIf="isVisibleSalesForceTypeSelect">
                  <label>{{ 'AdvancedClientFiltersComponent.Supervisor' | translate }}</label>
                  <p-multiSelect [group]="true"
                    [options]="groupedSupervisor"
                    optionLabel="label"
                    defaultLabel="{{ 'AdvancedClientFiltersComponent.SupervisorPlaceHolderMulti'| translate }}"
                    scrollHeight="250px" display="chip"
                    [style]="{ width: '100%' }"
                    [(ngModel)]="isProcess == 2 ? supervisoNgModel : supervisorSelected"
                    (onChange)="getRepresentativeBySupervisor($event)">
                      <ng-template pTemplate="group" let-groupSupervisor>
                          <div class="flex align-items-center">
                              <span>{{ groupSupervisor.label }}</span>
                          </div>
                      </ng-template>
                  </p-multiSelect>
                  <small id="cediValidator"
                      *ngIf="messageSupervisorSelected"
                      class="p-error">
                      {{"AdvancedClientFiltersComponent.SupervisorPlaceHolderMulti" | translate}}
                    </small>
                </div>
              </div>
  
              <!-- Representative -->
              <div *ngIf="groupedRepresentative">
                <div class="form-group" *ngIf="isVisibleSalesForceTypeSelect">
                  <label>{{ 'AdvancedClientFiltersComponent.Representative' | translate }}</label>
                  <p-multiSelect [group]="true"
                    [options]="groupedRepresentative"
                    optionLabel="label"
                    defaultLabel="{{ 'AdvancedClientFiltersComponent.RepresentativePlaceHolderMulti'| translate }}"
                    scrollHeight="250px" display="chip"
                    [style]="{ width: '100%' }"
                    [(ngModel)]="isProcess == 2 ? representativeNgModel : representativeSelected"
                    (onChange)="setRepresentativeSelected($event)">
                      <ng-template pTemplate="group" let-groupRepresentative>
                          <div class="flex align-items-center">
                              <span>{{ groupRepresentative.label }}</span>
                          </div>
                      </ng-template>
                  </p-multiSelect>
                  <small id="cediValidator"
                      *ngIf="messageRepresentativeSelected"
                      class="p-error">
                      {{"AdvancedClientFiltersComponent.RepresentativePlaceHolderMulti" | translate}}
                    </small>
                </div>
              </div>
            </div>
            <!-- Macrosegment -->
            <div *ngIf="switchFilter === advanceClientFilter.viewAll || 
                  switchFilter === advanceClientFilter.Macrosegments 
                  && objectMacrosegmentList">
              <div class="form-group">
                <label>{{ 'AdvancedClientFiltersComponent.Macrosegment' | translate }}</label>
                <div class="form-group">
                  <p-multiSelect 
                    [(ngModel)]="isProcess == 2 ? macrosegmentoNgModel : objectMacrosegmentSelect" 
                    [filter]="true" display="chip" 
                    optionLabel="label"
                    placeholder="{{'AdvancedClientFiltersComponent.MacrosegmentPlaceHolderMulti'| translate}}"
                    [options]="objectMacrosegmentList" 
                    [style]="{ width: '100%'}"
                    (onChange)="setValueAdvanceFilterr(advanceClientFilter.Macrosegments, $event, true)">
                  </p-multiSelect>
                  <small id="cediValidator"
                      *ngIf="messageMacrosegmentSelect"
                      class="p-error">
                      {{"AdvancedClientFiltersComponent.MacrosegmentPlaceHolderMulti" | translate}}
                  </small>
                </div>
              </div>
            </div>
            <!-- Subtypology -->
            <div *ngIf="switchFilter === advanceClientFilter.viewAll || 
                  switchFilter === advanceClientFilter.Subtypology 
                  && objectSubtypologyList">
              <div class="form-group">
                <label>{{ 'AdvancedClientFiltersComponent.Subtypology' | translate }}</label>
                <div class="form-group">
                  <p-multiSelect [(ngModel)]="objectSubtypologySelect" 
                    [filter]="true" 
                    display="chip" 
                    optionLabel="label"
                    placeholder="{{'AdvancedClientFiltersComponent.SubtypologyPlaceHolderMulti'| translate}}"
                    [options]="objectSubtypologyList" 
                    [style]="{ width: '100%' }"
                    (onChange)="setValueAdvanceFilterr(advanceClientFilter.Subtypology)">
                  </p-multiSelect>
                  <small id="cediValidator"
                      *ngIf="messageSubtypologySelect"
                      class="p-error">
                      {{"AdvancedClientFiltersComponent.SubtypologyPlaceHolderMulti" | translate}}
                  </small>
                </div>
              </div>
            </div>
            <!-- PurchaseMission -->
            <div *ngIf="switchFilter === advanceClientFilter.viewAll || switchFilter === advanceClientFilter.PurchaseMissions 
                && objectPurchaseMissionList">
              <div class="form-group">
                <label>{{ 'AdvancedClientFiltersComponent.PurchaseMission' | translate }}</label>
                <div class="form-group">
                  <p-multiSelect [(ngModel)]="objectPurchaseMissionSelect" [filter]="true" display="chip"
                    optionLabel="label"
                    placeholder="{{'AdvancedClientFiltersComponent.PurchaseMissionPlaceHolderMulti'| translate}}"
                    [options]="objectPurchaseMissionList" [style]="{ width: '100%' }"
                    (onChange)="setValueAdvanceFilterr(advanceClientFilter.PurchaseMissions)">
                  </p-multiSelect>
                  <small id="cediValidator"
                      *ngIf="messagePurchaseMissionSelect"
                      class="p-error">
                      {{"AdvancedClientFiltersComponent.PurchaseMissionPlaceHolderMulti" | translate}}
                  </small>
                </div>
              </div>
            </div>
            <!-- Typology -->
            <div *ngIf="switchFilter === advanceClientFilter.viewAll || switchFilter === advanceClientFilter.Typology 
            && objectTypologyList">
              <div class="form-group">
                <label>{{ 'AdvancedClientFiltersComponent.Typology' | translate }}</label>
                <div class="form-group">
                  <p-multiSelect [(ngModel)]="objectTypologySelect" [filter]="true" display="chip" optionLabel="label"
                    placeholder="{{'AdvancedClientFiltersComponent.TypologyPlaceHolderMulti'| translate}}"
                    [options]="objectTypologyList" [style]="{ width: '100%' }"
                    (onChange)="setValueAdvanceFilterr(advanceClientFilter.Typology)">
                  </p-multiSelect>
                  <small id="cediValidator"
                      *ngIf="messageTypologySelect"
                      class="p-error">
                      {{"AdvancedClientFiltersComponent.TypologyPlaceHolderMulti" | translate}}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <!-- Hierarchy Customer -->
          <div *ngIf="switchFilter === advanceClientFilter.viewAll || switchFilter === advanceClientFilter.HierarchyOne 
          && objectCustomerHierarchyLvlOneList">
            <div class="form-group">
              <label>{{ 'AdvancedClientFiltersComponent.CustomerHierarchy' | translate }}</label>
              <div class="form-group">
                <p-multiSelect [(ngModel)]="objectCustomerHierarchyLvlOneSelect" [filter]="true" display="chip"
                  optionLabel="label"
                  placeholder="{{'AdvancedClientFiltersComponent.CustomerHierarchyPlaceHolderMulti'| translate}}"
                  [options]="objectCustomerHierarchyLvlOneList" [style]="{ width: '100%' }"
                  (onChange)="getCustomerHierarchyLvlTwo(); setValueAdvanceFilterr(advanceClientFilter.HierarchyOne)">
                </p-multiSelect>
                <small id="cediValidator"
                  *ngIf="this.messageHierarchyLvlOneSelect"
                  class="p-error">
                  {{"AdvancedClientFiltersComponent.CustomerHierarchyPlaceHolderMulti" | translate}}
                </small>
              </div>
            </div>
          </div>
          <!-- Hierarchy Customer 2-->
          <div *ngIf="objectCustomerHierarchyLvlTwoList">
            <div class="form-group" *ngIf="isVisibleHierarchyTwoySelect">
              <label>{{ 'AdvancedClientFiltersComponent.CustomerHierarchy2' | translate }}</label>
              <div class="form-group">
                
                <p-multiSelect [(ngModel)]="objectCustomerHierarchyLvlTwoSelect" [filter]="true" display="chip"
                  optionLabel="label"
                  placeholder="{{'AdvancedClientFiltersComponent.CustomerHierarchy2PlaceHolderMulti'| translate}}"
                  [options]="objectCustomerHierarchyLvlTwoList" [style]="{ width: '100%' }"
                  (onChange)="validateMessageHierrarchyTwo()">
                </p-multiSelect>
                <small id="cediValidator"
                *ngIf="this.messageHierarchyLvlTwoSelect"
                class="p-error">
                  {{"AdvancedClientFiltersComponent.CustomerHierarchy2PlaceHolderMulti" | translate}}
                </small>
              </div>
            </div>
          </div>
        </div>
      </p-sidebar>
    </div>
  </div>
</div>


<div class="row">
  <div class="table-responsive">
    <!-- PrimeNg Table -->
    <p-table #dt name="gridAdvancedClientFilters" [value]="advancedFiltersObjectList" [paginator]="true" [rows]="10"
      paginatorPosition="bottom" styleClass="p-datatable-customers">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="type">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'AdvancedClientFiltersComponent.FilterType' | translate }}
              <p-sortIcon field="type"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="content">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'AdvancedClientFiltersComponent.ContentSearch' | translate }}
              <p-sortIcon field="content"></p-sortIcon>
            </div>
          </th>
          <th></th>
        </tr>
        <tr>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
        <tr>
          <td>
            {{object.typeDescription}}
          </td>
          <td>
            {{object.contentDescription}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
    <!-- PrimeNg Table -->
  </div>
</div>

<div class="row" *ngIf="enabledExecuteFilters && showFields.enabledExecuteFiltersShared">
  <div class="col-md-5"></div>
  <div class="col-md-4"></div>
  <div class="col-md-3 mt-2 align-items-end">
    <button id="btnRunFilters" pButton pRipple type="button"
      label="{{ 'AdvancedClientFiltersComponent.ExecuteFilter' | translate }}" class="p-button-rounded"
      [disabled]="advancedFiltersObjectList.length === 0 || isProcess === commercialActivityEnum.view"
      style="width: 100%;" (click)="eventClickFilters()"></button>
  </div>


</div>
