import { IProductList } from '../interfaces/product-list.interface';
import { IProductGroupList } from '../interfaces/product-group-list.interface';

export class ProductGroupListModel implements IProductGroupList{
  constructor(
    public groupName?: string,
    public commercialActivityId?: number,
    public purchaseUnit?: number,
    public measurementUnitCode?: string,
    public isGroup?: boolean,
    public groupByJson?: string,
    public id?: number,
    public purchaseBaseDetails?: IProductList[],
    public purchaseBasePromotionDetails?: IProductList[]
  ) {}
}