<p-accordion>
    <p-accordionTab header="{{ 'AssingClient.Summary' | translate }}" [selected]="activeAccordionSummary"
          [disabled]="!activeAccordionSummary || isProcess === processEnum.view">
      <div class="row">
      </div>
      <div class="table-responsive">
        <!-- PrimeNg Table -->
        <p-table #dt name="gridSolicitudeClients" [value]="summaryClients" [paginator]="true" [rows]="10"
          paginatorPosition="bottom" styleClass="p-datatable-customers">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="groupClients">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'AssingClient.Group' | translate }}
                  <p-sortIcon field="groupClients"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="labelType">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'AssingClient.Type' | translate }}
                  <p-sortIcon field="labelType"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="codeType">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'AssingClient.Code' | translate }}
                  <p-sortIcon field="codeType"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="total">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'AssingClient.Total' | translate }}
                  <p-sortIcon field="total"></p-sortIcon>
                </div>
              </th>
              <th>{{ 'Actions' | translate }}</th>
            </tr>
            <tr>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
            <tr>
              <td>
                {{object.groupClients}}
              </td>
              <td>
                {{object.labelType}}
              </td>
              <td>
                {{object.codeType}}
              </td>
              <td>
                {{object.total}}
              </td>
              <td>
                <i class="uil uil-search-alt Editar"
                pTooltip="{{'Detail' | translate}}"
                (click)="showModal(object)" aria-hidden="true"
                *ngIf="rolePermission.read | rolePermission"></i>
              </td>
            </tr>
          </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="3">TOTAL</td>
                                <td>{{totalClients}}</td>
                                <td>
                                  <p-button *ngIf="downloadFileClients && !isHanlder" label="Descargar listado" styleClass="p-button-text" (click)="downloadListClients()"></p-button>
                                  <p-button *ngIf="isProcess == 3 && isHanlder && totalClients > 0" label="Descargar listado" styleClass="p-button-text" (click)="downloadFileCsv()"></p-button>  
                                </td>
                            </tr>
                        </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
      </div>
    </p-accordionTab>
  </p-accordion>

<p-dialog header="{{ 'AssingClient.GroupDetail' | translate }}" [(visible)]="displayModal" [modal]="true"
  [style]="{ width: '60vw' }" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="content">


  </ng-template>


  <!-- PrimeNg Table -->
  <p-table #dt name="gridSolicitudeDetailGroup" [paginator]="false" [rows]="10" [value]="detailsClientsResult"
    currentPageReportTemplate="{{ 'CurrentPage' | translate }}" [rowsPerPageOptions]="[10, 25, 50, 100]"
    paginatorPosition="bottom" styleClass="p-datatable-customers">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ 'AssingClient.ClientCode' | translate }}
            <p-sortIcon field="statusDescription"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ 'AssingClient.CustomerName' | translate }}
            <p-sortIcon field="creationUser"></p-sortIcon>
          </div>
        </th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body" let-object>
      <tr>
        <td>{{ object.sClientCode }}</td>
        <td>{{ object.sCustomerName }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- PrimeNg Table -->
  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'CloseBtn' | translate}}" icon="pi pi-times" class="p-button-text"
      (click)="hideModal()"></button>
  </ng-template>

</p-dialog>