import { IAdvancedFilter } from "@app/@components/shared-advanced-filter/interfaces/general-advanced-filter.interface";
import { IPaginatorFilter } from "../interfaces/paginator-filter.interfaces";

export class PaginatorFilterModel implements IPaginatorFilter{
 constructor(
    public pageNumber?: number,
    public limitRecords?: number,
    public searchFilter?: string,
    public searchFilterDate?: string,
    public flagBack?: boolean,
    public advanceFilter?: IAdvancedFilter
 ){}
}