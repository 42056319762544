import { ISalesForceForRegionCedi } from './interfaces/sales-force-for-region-cedi.interface';
import { CountrySocietyRegionModel } from './../../@modules/administration/region/models/country-society-region.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { RegionService } from '@app/@modules/administration/region/region.service';
import { FilterClient } from '@app/@shared/enums/filter-client.enums';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { Util } from '@app/@shared/util';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { IFilters } from './interfaces/filters.interface';
import { AdvancedClientFilterModel } from './models/advanced-client-filter.model';
import { FiltersModel } from './models/filters.models';
import { AdvancedClientFilterService } from './services/advanced-client-filter-service';
import { Operator } from '../../@shared/enums/operator.enum';
import { ComercialActivityEnum } from '../../@shared/enums/comercial-activity.enums';
import { AdvanceClientFilterEnum } from '../../@shared/enums/advanced-client-filter';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { SocietyRegionModel } from '@app/@shared/model/society-region.model';
import { CoreMasterModel } from '@app/@shared/model/core-master.model';
import { SocietyRegionCedisModel } from '@app/@shared/model/society-region-cedi.model';
import { ComboGroupModel } from '@app/@shared/model/combo-group.model';
import { AdvancedClientFilterConfigurationUserService } from './services/advance-client-filter-configuration-user.service';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { ComboModel } from '@app/@shared/model/combo.model';
import { IFieldsClientFilter } from '../assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '../assing-clients/models/show-fields-client-filter.model';

@Component({
  selector: 'app-advanced-client-filter',
  templateUrl: './advanced-client-filter.component.html',
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }

        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService],
})
export class AdvancedClientFilterComponent implements OnInit {

  @Input('validateFilter') validateFilter: boolean;
  @Input('filtersEdit') filtersEdit: string;
  @Input('isProcess') isProcess: number;
  @Input('isCountryRequired') isCountryRequired: boolean = false;
  @Input('enabledExecuteFilters') enabledExecuteFilters: boolean = true;
  @Input('requiredFields') requiredFields: boolean = true;
  @Input('rolePermissionBtn') rolePermissionBtn: boolean;
  @Input('isCreate') isCreate: boolean = true;
  @Input('visibleHideFilterClient') visibleHideFilterClient: boolean = true;
  @Input('showFields') showFields: IFieldsClientFilter = new FieldsClientFilterModel(true, true, true, false, true, true);
  @Input('btnShowEdit') btnShowEdit = false;
  @Input('clearCsv') clearCsv = false;

  @Output('eventClickFiltersClient') eventButton: EventEmitter<any> =
    new EventEmitter();
  @Output('eventClickDeleteFiltersClient')
  eventButtonDelete: EventEmitter<any> = new EventEmitter();

  public commercialActivityEnum: any;
  public advanceClientFilter: any;
  public isVisible = false;
  public isVisibleEdit = false;
  public switchFilter = 0;

  public isGeneralSalesForce = true;

  public objectCountryList: ICombo[];
  public objectCountrySelect: ICombo[] = [];

  public objectSectorList: ICombo[];
  public objectSectorSelect: ICombo[] = [];

  public objectSocietyList: ICombo[];
  public objectSocietySelect: ICombo[] = [];

  public objectRegionListInternal: CountrySocietyRegionModel[] = [];
  public objectRegionList: ICombo[];
  public objectRegionSelect: ICombo[] = [];

  public objectSocietyRegionCedi: SocietyRegionCedisModel[] = [];
  public objectRegionCedi: any[] = [];
  public objectRegionCediSelect: ICombo[] = [];

  public objectCediList: ICombo[];
  public objectCediSelect: ICombo[] = [];


  public groupedSalesBossList: ComboGroupModel[] = [];
  public groupedSupervisor: ComboGroupModel[] = [];
  public groupedRepresentative: ComboGroupModel[] = [];

  public salesBossSelected: ICombo[] = [];
  public supervisorSelected: ICombo[] = [];
  public representativeSelected: ICombo[] = [];

  public filterListSalesForce : any[] = [];
  public filterListSalesBoss : any[] = [];
  public filterListSupervisor : any[] = [];
  public filterListRepresentative : any[] = [];

  public objectSalesForceForRegionCedi: ISalesForceForRegionCedi[] = [];
  public objectSalesForcesTypeListRegionCedi: ICombo[];

  public objectSalesForcesTypeListInternal: ICombo[];
  public objectSalesForcesTypeList: ICombo[];
  public objectSalesForcesTypeSelect: ICombo[] = [];

  public objectSalesBossList: ICombo[];
  public objectSalesBossSelect: ICombo[] = [];

  public objectSupervisorList: ICombo[];
  public objectSupervisorSelect: ICombo[] = [];

  public objectRepresentativeList: ICombo[];
  public objectRepresentativeSelect: ICombo[] = [];

  public objectMacrosegmentList: ICombo[];
  public objectMacrosegmentSelect: ICombo[] = [];

  public objectTypologyList: ICombo[];
  public objectTypologySelect: ICombo[] = [];

  public objectSubtypologyList: ICombo[];
  public objectSubtypologySelect: ICombo[] = [];

  public objectPurchaseMissionList: ICombo[];
  public objectPurchaseMissionSelect: ICombo[] = [];

  public objectPersonListInternal: AdvancedClientFilterModel[];
  public objectPersonList: AdvancedClientFilterModel[];

  public objectCustomerHierarchyLvlOneList: ICombo[];
  public objectCustomerHierarchyLvlOneSelect: ICombo[] = [];

  public objectCustomerHierarchyLvlTwoList: ICombo[];
  public objectCustomerHierarchyLvlTwoSelect: ICombo[] = [];

  public isVisibleCountrySelect = false;
  public isVisibleSocietySelect = false;
  public isVisibleCediSelect  = false;
  public isVisibleSalesForceTypeSelect = false;
  public isVisibleHierarchyTwoySelect = false;

  public messageCountrySelect  = true;
  public messageSectorSelect  = true;
  public messageSocietySelect  = true;
  public messageRegionSelect  = true;
  public messageRegionCediSelect  = true;

  public messageMacrosegmentSelect  = false;
  public messageSubtypologySelect  = false;
  public messagePurchaseMissionSelect  = false;
  public messageTypologySelect  = false;

  public messageObjectSalesForcesTypeSelect  = false;
  public messageSalesBossSelected  = false;
  public messageSupervisorSelected  = false;
  public messageRepresentativeSelected  = false;

  public messageHierarchyLvlOneSelect  = false;
  public messageHierarchyLvlTwoSelect  = false;

  public advancedFiltersObjectList: IFilters[] = [];

  public rolePermission: any = RolePermissionEnum;
  public rolePermissionAux: any = RolePermissionEnum;

	public preChargeEdit = 0;

  public salesForceNgModel : ICombo[] = [];
  public salesBossNgModel : ICombo[] = [];
  public supervisoNgModel : ICombo[] = [];
  public representativeNgModel : ICombo[] = [];
  public macrosegmentoNgModel : ICombo[] = [];

  public indicador = 0;
  public auxValidateFilter: boolean;
  public auxRequiredFields: boolean;

  public hiddenSectorandSocietyWithHierarchyLevelOneAux = false;

  private readonly spinner: NgxSpinnerService;
  private readonly coreService: CoreMasterService;
  private readonly regionServive: RegionService;
  private readonly advancedClientFilterServive: AdvancedClientFilterService;
  private readonly advancedClientFilterConfigurationUser: AdvancedClientFilterConfigurationUserService;

  constructor(
    spinner: NgxSpinnerService,
    coreService: CoreMasterService,
    regionServive: RegionService,
    advancedClientFilterServive: AdvancedClientFilterService,
    advancedClientFilterConfigurationUser: AdvancedClientFilterConfigurationUserService)
    {
    this.spinner = spinner;
    this.coreService = coreService;
    this.regionServive = regionServive;
    this.advancedClientFilterServive = advancedClientFilterServive;
    this.advancedClientFilterConfigurationUser = advancedClientFilterConfigurationUser;
  }

  ngOnInit(): void {
    this.commercialActivityEnum = this.getCommercialActivityEnum();
    this.advanceClientFilter = this.getAdvancedClientFilterEnum();
    this.rolePermissionAux = this.rolePermissionBtn ? RolePermissionEnum.read : RolePermissionEnum.create;
    if(!this.isProcess)this.isProcess = ComercialActivityEnum.create;
    this.getConfiguracionUserSession();
  }


  async getConfiguracionUserSession(){
    const userAccount = SessionStorageHelper.get(CommonConfiguration.Email);
    if(this.validateFilter) await this.advancedClientFilterConfigurationUser.getConfigurationUserAcessManagement(userAccount);
    this.forkJoinData();
  }

  /**
   * @description This method is validate show hierarchy lvl two
   *
   * @param changes
   * @author Leonardo Andres Herrera
   * @sprint 18
   */
  showHierarchyLvlTwo() {
    if (this.objectCustomerHierarchyLvlOneSelect.length > 0) {
      this.getCustomerHierarchyLvlTwo();
    }
  }
  /**
   * @description This method is validate show society
   *
   * @param changes
   * @author Leonardo Andres Herrera
   * @sprint 18
   */
  showSociety() {
		if (!this.objectCountryList) {
			setTimeout(() => {
				this.showSociety();
			}, 2000);
		}
    //TODO
    if (this.objectCountryList && this.objectCountryList.length > 0) {
      this.getSocietiesByCountry();
    }
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  public getAdvancedClientFilterEnum(): typeof AdvanceClientFilterEnum {
    return AdvanceClientFilterEnum;
  }

  /**
   * @description This method is to wait the event changes
   * in father promotion, and apply the changes in this view
   *
   * @param changes
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const changeFiltersEdit: SimpleChange = changes['filtersEdit'];
    const changeFiltersBtnEdit: SimpleChange = changes['btnShowEdit'];
    const changeFiltersClearCsv: SimpleChange = changes['clearCsv'];
    if (changeFiltersEdit && changeFiltersEdit.currentValue) {
			this.preChargeEdit += 1;
      setTimeout(() => {
        const currentValue = typeof changeFiltersEdit.currentValue === 'string' ? changeFiltersEdit.currentValue :  JSON.stringify(changeFiltersEdit.currentValue);
        this.prepareFiltersEdit(currentValue);
      }, 2000);
    }else if(changeFiltersEdit && changeFiltersEdit.currentValue == ""){
      this.advancedFiltersObjectList = [];
    }

    if(!this.filtersEdit){
      this.showFields.enabledExecuteFilters = true;
      this.showFields.enabledFilterAdvanced = true;
    }else if (changeFiltersBtnEdit && changeFiltersBtnEdit.currentValue && this.isProcess == 2) {
        this.showFields.enabledExecuteFilters = true;
        this.showFields.enabledFilterAdvanced = true;
    }else if(changeFiltersBtnEdit && changeFiltersBtnEdit.currentValue == false && this.isProcess == 2) {
      this.showFields.enabledExecuteFilters = false;
      this.showFields.enabledFilterAdvanced = false;
    }

    if(changeFiltersClearCsv && changeFiltersClearCsv.currentValue){
      this.showFields.enabledExecuteFilters = true;
      this.showFields.enabledFilterAdvanced = true;
    }
  }

  /**
   *
   * @param filtersEdit
   */
  async prepareFiltersEdit(filtersEdit: string){
    const filtersParsed: IFilters[] = JSON.parse(filtersEdit);
    if (filtersParsed) {

      this.objectCountrySelect = [];
      this.objectSectorSelect = [];
      this.objectSocietySelect = [];
      this.objectRegionSelect = [];
      this.objectCediSelect = [];
      this.objectSalesForcesTypeSelect = [];
      this.objectRepresentativeSelect = [];
      this.objectSupervisorSelect = [];
      this.objectSalesBossSelect = [];
      this.objectSubtypologySelect = [];
      this.objectMacrosegmentSelect = [];
      this.objectTypologySelect = [];
      this.objectPurchaseMissionSelect = [];
      this.objectCustomerHierarchyLvlOneSelect = [];
      this.objectCustomerHierarchyLvlTwoSelect = [];
      
      for(const fill of filtersParsed){
        this.checkSwitch(fill);
				switch (fill.type) {
					case FilterClient.CountryCode:
						// this.getSocietiesByCountry();
						break;
					case FilterClient.SocietyCode:
						// this.getRegionCediBySocieties();
						break;
					case FilterClient.RegionCode:
						 // this.GetCediBySocietyRegion();
						break;
					case FilterClient.CediCode:
						// this.setCedi();
						break;
					case FilterClient.SalesForceCode:
            this.getSalesForceEdit();
            await this.setListMultiselect();

						break;
					case FilterClient.SalesBossCode:
            this.getBossSalesEdit();

						break;
					case FilterClient.SalesSupervisorCode:
						this.getSupervisorEdit();
						break;
					case FilterClient.SalesManagerCode:
            this.getRepresentativeEdit();
						// this.getSupervisorByRepresentativeId(fill);
						break;
					case FilterClient.MacrosegmentCode:
						this.setValueAdvanceFilterr(this.advanceClientFilter.Macrosegments);
						break;
					case FilterClient.TypologyCode:
						this.setValueAdvanceFilterr(this.advanceClientFilter.Typology);
						break;
					case FilterClient.SubtypologyCode:
						this.setValueAdvanceFilterr(this.advanceClientFilter.Subtypology);
						break;
					case FilterClient.PurchaseMissionCode:
						this.setValueAdvanceFilterr(this.advanceClientFilter.PurchaseMissions);
						break;
          case FilterClient.CustomerCode:
            this.setValueAdvanceFilterr(this.advanceClientFilter.HierarchyOne);
            await this.getCustomerHierarchyLvlTwo();
            break;
					case FilterClient.SSupplyChainCode:
						break;
					default:
						break;
				}
			}
      this.addFilter();
      this.eventClickFilters();
      // this.showHierarchyLvlTwo();
      this.switchEditValidate();
      this.showSociety();
      return;
    }
  }

  private switchEditValidate() {
    if (this.isProcess === ComercialActivityEnum.update || this.isProcess === ComercialActivityEnum.view) {
      this.validateFilter = this.auxValidateFilter;
      this.requiredFields = this.auxRequiredFields;
    }
  }

  async setListMultiselect(salesForceCodeEdit : ICombo[] = [], indicador = 0){
    try {
      let salesForceCode: string[] = indicador === 0 ? this.objectSalesForcesTypeSelect.map((x : any) => x.value) : salesForceCodeEdit.map((x : any) => x.value);
      const response = await this.advancedClientFilterServive.getBySalesForceAsync(salesForceCode).toPromise();
      if(response.status){
        this.objectPersonListInternal = response.data;
          this.setValuesCombosBySalesForce();
      }
    } catch (error) {
      
    }
   
    }

  getSalesForceEdit(){
    if(this.isProcess === 2 || this.isProcess === 3){

      this.objectSalesForcesTypeSelect.forEach((elm) => {
        this.salesForceNgModel.push(elm);
      });
    }
  }

  getBossSalesEdit(){

    //OPCIONES DE SUPERVISOR A PARTIR DE JEFE
    this.salesBossNgModel.forEach((x) => {
      let supervisorList: ICombo [] = [];
      this.filterListSupervisor.forEach((y) => {
        if(Number(y.salesBoss) === Number(x.value)){
          let supervisor: ICombo = {label : y.label, value : y.value };
          supervisorList.push(supervisor);
        }
      });


      if(supervisorList.length > 0){
        let bossSelected = this.filterListSupervisor.find((z) => Number(z.salesBoss) === Number(x.value));
        this.objectSalesBossSelect.push({label : bossSelected.salesBossName , value : bossSelected.salesBoss});
        const objSupervisor = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSupervisorByUser(supervisorList) : supervisorList;
        let supervisorGroup : ComboGroupModel = { label : bossSelected.salesBossName , value : bossSelected.salesBoss, items: objSupervisor};
        this.groupedSupervisor.push(supervisorGroup);
      }
    });

  }

  getSupervisorEdit(){
    if(this.isProcess === 2 || this.isProcess === 3){

      this.objectSupervisorSelect.forEach((elm : any) => {
        this.supervisoNgModel.push(elm);
      });

      //OPCIONES DE REPRESENTANTE A PARTIR DE SUPERVISOR
      this.supervisoNgModel.forEach((x) => {
        let representativeList : ICombo[] = [];
        this.filterListRepresentative.forEach((y) => {
          if(y.salesSupervisor === x.value){
            let representative : ICombo = { label : y.label, value : y.value };
            representativeList.push(representative);
          }
        });

        let supervisorSelected = this.filterListRepresentative.find((z) => z.salesSupervisor === x.value);
        const objRepresentative = this.validateFilter ? this.advancedClientFilterConfigurationUser.setRepresentativeByUser(representativeList) : representativeList;
        if(objRepresentative.length > 0){
          let representativeGroup : ComboGroupModel = { label : supervisorSelected.supervisorName , value : supervisorSelected.salesSupervisor, items: objRepresentative};
          if(representativeGroup)
          this.groupedRepresentative.push(representativeGroup);
        }
      });
      
    }
  }

  getRepresentativeEdit(){
    if(this.isProcess === 2 || this.isProcess === 3){

      this.objectRepresentativeSelect.forEach((elm : any) => {
        this.representativeNgModel.push(elm);
      });
      
    }
  }

  private checkSwitch(fill: IFilters) {
    switch (fill.type) {
      case FilterClient.CountryCode:
        const codesCountry: string[] = fill.content.split(';');
        const descriptionsCountry: string[] = fill.contentDescription.split(';');
        codesCountry.forEach((cod, index) => {
          this.objectCountrySelect.push({ label: descriptionsCountry[index], value: cod });
          this.objectCountrySelect = [...this.objectCountrySelect];
        });
        break;
      case FilterClient.SectorCode:
        const codesSector: string[] = fill.content.split(';');
        const descriptionSector: string[] = fill.contentDescription.split(';');
        codesSector.forEach((cod, index) => {
          this.objectSectorSelect.push({ label: descriptionSector[index], value: cod });
          this.objectSectorSelect = [...this.objectSectorSelect];
        });
        break;
      case FilterClient.SocietyCode:
        const codesSociety: string[] = fill.content.split(';');
        const descriptionSociety: string[] = fill.contentDescription.split(';');
        codesSociety.forEach((cod, index) => {
          this.objectSocietySelect.push({ label: descriptionSociety[index], value: cod });
          this.objectSocietySelect = [...this.objectSocietySelect];
        });
        break;
      case FilterClient.RegionCode:
        const codesRegion: string[] = fill.content.split(';');
        const descriptionRegion: string[] = fill.contentDescription.split(';');
        codesRegion.forEach((cod, index) => {
          this.objectRegionSelect.push({ label: descriptionRegion[index], value: cod });
          this.objectRegionSelect = [...this.objectRegionSelect];
        });
        break;
      case FilterClient.CediCode:
        const codesCedi: string[] = fill.content.split(';');
        const descriptionCedi: string[] = fill.contentDescription.split(';');
        codesCedi.forEach((cod, index) => {
          this.objectCediSelect.push({ label: descriptionCedi[index], value: cod });
          this.objectRegionCediSelect = [...this.objectCediSelect];
          this.objectCediSelect = [...this.objectCediSelect];
        });
        break;
      case FilterClient.SalesForceCode:
        const codesSales: string[] = fill.content.split(';');
        const descriptionSales: string[] = fill.contentDescription.split(';');
        codesSales.forEach((cod, index) => {
          this.objectSalesForcesTypeSelect.push({ label: descriptionSales[index], value: cod });
          this.objectSalesForcesTypeSelect = [...this.objectSalesForcesTypeSelect];
        });
        break;
      case FilterClient.SalesManagerCode:
        const codesSalesManager: string[] = fill.content.split(';');
        const descriptionSalesManager: string[] = fill.contentDescription.split(';');
        codesSalesManager.forEach((cod, index) => {
          this.objectRepresentativeSelect.push({ label: descriptionSalesManager[index], value: cod });
          this.objectRepresentativeSelect = [...this.objectRepresentativeSelect];
        });
        break;
      case FilterClient.SalesSupervisorCode:
        const codesSalesSupervisor: string[] = fill.content.split(';');
        const descriptionSalesSupervisor: string[] = fill.contentDescription.split(';');
        codesSalesSupervisor.forEach((cod, index) => {
          this.objectSupervisorSelect.push({ label: descriptionSalesSupervisor[index], value: cod });
          this.objectSupervisorSelect = [...this.objectSupervisorSelect];
        });
        break;
      case FilterClient.SalesBossCode:
        const codesSalesBoss: string[] = fill.content.split(';');
        const descriptionSalesBoss: string[] = fill.contentDescription.split(';');
        codesSalesBoss.forEach((cod, index) => {
          this.objectSalesBossSelect.push({ label: descriptionSalesBoss[index], value: cod });
          this.objectSalesBossSelect = [...this.objectSalesBossSelect];
        });
        this.objectSalesBossSelect.forEach((elm : any) => {
          this.salesBossNgModel.push(elm);
        });
        break;
      case FilterClient.MacrosegmentCode:        
        const codesMacrosegment: string[] = fill.content.split(';');
        const descriptionMacrosegment: string[] = fill.contentDescription.split(';');
        codesMacrosegment.forEach((cod, index) => {
          this.objectMacrosegmentSelect.push({ label: descriptionMacrosegment[index], value: cod });
          this.objectMacrosegmentSelect = [...this.objectMacrosegmentSelect];
        });
        this.macrosegmentoNgModel = this.objectMacrosegmentSelect;
        break;
      case FilterClient.TypologyCode:
        const codesTypology: string[] = fill.content.split(';');
        const descriptionTypology: string[] = fill.contentDescription.split(';');
        codesTypology.forEach((cod, index) => {
          this.objectTypologySelect.push({ label: descriptionTypology[index], value: cod });
          this.objectTypologySelect = [...this.objectTypologySelect];
        });
        break;
      case FilterClient.SubtypologyCode:
        const codesSubtypology: string[] = fill.content.split(';');
        const descriptionSubtypology: string[] = fill.contentDescription.split(';');
        codesSubtypology.forEach((cod, index) => {
          this.objectSubtypologySelect.push({ label: descriptionSubtypology[index], value: cod });
          this.objectSubtypologySelect = [...this.objectSubtypologySelect];
        });
        break;
      case FilterClient.PurchaseMissionCode:
        const codesPurchase: string[] = fill.content.split(';');
        const descriptionPurchase: string[] = fill.contentDescription.split(';');
        codesPurchase.forEach((cod, index) => {
          this.objectPurchaseMissionSelect.push({ label: descriptionPurchase[index], value: cod });
          this.objectPurchaseMissionSelect = [...this.objectPurchaseMissionSelect];
        });
        break;
      case FilterClient.CustomerCode:
        const codesCustomerCode: string[] = fill.content.split(';');
        const descriptionCustomerCode: string[] = fill.contentDescription.split(';');
        codesCustomerCode.forEach((cod, index) => {
          this.objectCustomerHierarchyLvlOneSelect.push({ label: descriptionCustomerCode[index], value: cod });
          this.objectCustomerHierarchyLvlOneSelect = [...this.objectCustomerHierarchyLvlOneSelect];
        });
        break;
      case FilterClient.SSupplyChainCode:
        const codesSSupplyChainCode: string[] = fill.content.split(';');
        const descriptionSSupplyChainCode: string[] = fill.contentDescription.split(';');
        codesSSupplyChainCode.forEach((cod, index) => {
          this.objectCustomerHierarchyLvlTwoSelect.push({ label: descriptionSSupplyChainCode[index], value: cod });
          this.objectCustomerHierarchyLvlTwoSelect = [...this.objectCustomerHierarchyLvlTwoSelect];
        });
        break;
      default:
        break;
    }
  }

  /**
   * @description this method is to get Societies By Country.clearFilterSales
   * @author Daniel Londono
   * @sprint 9
   */
  getSocietiesByCountry(indicador = 0): void {
    this.isVisibleCountrySelect = true;
    if (this.objectCountrySelect.length !== 0) {
      this.spinner.show();

      this.messageCountrySelect = true;

      if(indicador === 1){
        this.clearFilterSalesNgModel();
      }

      const countries : string = this.objectCountrySelect.map((x) => x.value).join(',');
      this.coreService.getAllSocietiesByCountry(countries).subscribe(
        (response) => {
          this.spinner.hide();
          const objtSocietyList = response.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim(),
            };
          });

            this.objectSocietyList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSocietyByUser(objtSocietyList) : objtSocietyList;

            if(this.isProcess === ComercialActivityEnum.update ||  this.isProcess === ComercialActivityEnum.view){
              this.getRegionCediBySocieties();
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );

      this.objectCountrySelect.forEach((x) => {
        const temporalObjectSlaesForcesType : ICombo[] = [];

        this.objectSalesForceForRegionCedi.forEach((y) => {
          if(y.country === x.value){
            const salesForce = {'label' : y.salesForceDescription, 'value' : y.salesForce };
            temporalObjectSlaesForcesType.push(salesForce);
          }
        });

        this.objectSalesForcesTypeList = temporalObjectSlaesForcesType.filter((x1, index, array) =>
          array.findIndex((z) => z.label === x1.label && z.value === x1.value) === index
        );

        this.isGeneralSalesForce = false;
      });

    } else {
      this.objectSocietySelect = [];
      this.objectSocietyList = [];
      this.objectCediList = [];
      this.objectCediSelect = [];
      this.objectRegionList = [];
      this.objectRegionSelect = [];
      this.objectRegionCedi = [];
      this.objectRegionCediSelect = [];
      this.clearFilterSales();
      this.objectMacrosegmentSelect = [];
      this.isVisibleCountrySelect = true;
      this.isVisibleSalesForceTypeSelect = false;
      this.isGeneralSalesForce = true;
      this.objectSalesForcesTypeList = this.objectSalesForcesTypeListInternal;
    }
  }

  /**
   * @description this method is to control By Societies.
   * @author Daniel Londono
   * @sprint 9
   */
  getRegionCediBySocieties(): void {
    
    if(this.objectSocietySelect.length === 0){
      this.objectRegionListInternal = [];
      this.objectRegionList = [];
      this.objectRegionSelect = [];
      this.objectCediList = [];
      this.objectCediSelect =  [];
      this.objectRegionCedi =  [];
      this.objectRegionCediSelect = [];
      this.objectSalesForcesTypeList = [];
      this.clearFilterSales();
      this.objectMacrosegmentSelect = [];
      this.isVisibleSalesForceTypeSelect = false;
      this.isVisibleSocietySelect = false;
      this.isVisibleCediSelect = false;
      return;
    }else {
      this.messageSocietySelect = true;
    }
    this.getRegionBySocieties();
  }

  /**
   * @description this method is to get Region By Societies.
   * @author Daniel Londono
   * @sprint 9
   */
  getRegionBySocieties(): void {
    this.isVisibleSocietySelect = true;
    if (this.objectSocietySelect.length !== 0) {
      const societies : string = this.objectSocietySelect.map((x) => x.value).join(',');
      this.spinner.show();
        this.regionServive.getAllRegionBySociety(societies).subscribe(
          (response) => {
            this.spinner.hide();

            const objRegionCedi = response.data.map((item) => {
              return {
                label: item.regionDescription,
                value: item.regionCode.trim(),
              };
            });

            this.objectRegionList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setRegionByUser(objRegionCedi) : objRegionCedi;
            this.objectRegionList = [...new Map(this.objectRegionList.map((item) => [item['value'], item])).values(),];
            this.objectRegionListInternal = response.data ;

            this.setSalesForceBySociety();

            if(this.isProcess === ComercialActivityEnum.update || this.isProcess === ComercialActivityEnum.view){
              this.getCediBySocietyRegion();
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
    } else {
      this.objectRegionList = null;
      this.isVisibleSocietySelect = false;
    }
  }

  onChangeSectorSelect(){
    if(this.objectSectorSelect.length > 0){
      this.messageSectorSelect = true;
    }
  }

  setSalesForceBySociety(){
    let temporalObjectSlaesForcesType : ICombo[] = [];
    this.objectSalesForcesTypeList = [];
    this.objectSalesForcesTypeSelect = [];

    this.objectCountrySelect.forEach((x) => {
      this.objectSocietySelect.forEach((y) => {
        const listSalesForce = this.objectSalesForceForRegionCedi.filter((z) => z.country === x.value && z.society === y.value);

        if(listSalesForce !== undefined && listSalesForce.length > 0) {
          temporalObjectSlaesForcesType = listSalesForce.map((u) => {
            return { label: u.salesForceDescription, value : u.salesForce };
          });
        }
      });
    });

    const objSalesForce = temporalObjectSlaesForcesType.filter((x1, index, array) =>
      array.findIndex((z) => z.label === x1.label && z.value === x1.value) === index
    );

    this.objectSalesForcesTypeList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSalesForceByUser(objSalesForce) : objSalesForce;
  }

  getSocietyRegionModel() : SocietyRegionModel[] {
    let listSocietyRegion: SocietyRegionModel[] = [];

    this.objectSocietySelect.forEach((x) => {
      let listRegion: CoreMasterModel[] = [];

        this.objectRegionSelect.forEach((y) => {
          let searchRegion = this.objectRegionListInternal.find((z) => z.societyCode === x.value && z.regionCode === y.value);
          if(searchRegion){
            let region: CoreMasterModel = new CoreMasterModel(searchRegion.regionCode, searchRegion.regionDescription);
            listRegion.push(region);
          }
        });

      let societyRegion: SocietyRegionModel = new SocietyRegionModel(x.value, x.label, listRegion);
      listSocietyRegion.push(societyRegion);
    });

    return listSocietyRegion;
  }

  /**
   * @description this method is to get Cedi By Societies.
   * @author Daniel Londono
   * @sprint 9
   */
   getCediBySocietyRegion(indicador = 0): void {
     if (this.objectSocietySelect.length !== 0 && this.objectRegionSelect.length !== 0) {
       this.spinner.show();
       this.messageRegionSelect = true;

      if(this.isProcess === ComercialActivityEnum.create) this.objectRegionCediSelect = [];

       this.objectRegionCedi = [];
       let getSocietyRegionModel = this.getSocietyRegionModel();
       this.coreService.getAllCedisBySocietyRegion(getSocietyRegionModel)
       .subscribe((response) => {
         this.spinner.hide();
         this.objectSocietyRegionCedi = response.data;
         let groupRegionCedi: any[] = this.getDataSocietyRegion(this.objectSocietyRegionCedi);
         let auxGroupRegionCedi: any[] = [];
         let GroupCedi : any = [];
         let objectRegionCediUser: any = [];
         let items : any[] = [];

         objectRegionCediUser = this.validateFilter ? this.advancedClientFilterConfigurationUser.setCediByUser(groupRegionCedi) : groupRegionCedi;

         groupRegionCedi.forEach((x : any) => {
          x.items.forEach((y : any) => {
            objectRegionCediUser.forEach((z : any) => {
              if(y.value === z.value){
                items.push(z);
                GroupCedi = {'label': x.label, 'value': x.value, 'items': items};
              }
            })
          })
          if(JSON.stringify(GroupCedi) !=='[]' ) auxGroupRegionCedi.push(GroupCedi);
          GroupCedi = [];
          items = [];
         });

        this.objectRegionCedi = this.validateFilter ? auxGroupRegionCedi : groupRegionCedi;
         this.setCedi();
         this.isVisibleCediSelect = true;

         let objectSalesForcesTypeSelectInterno : ICombo[] = [];
         let temporalObjectSlaesForcesType : ICombo[] = [];

        if(this.objectSalesForcesTypeSelect.length > 0) objectSalesForcesTypeSelectInterno = this.objectSalesForcesTypeSelect;

        if(indicador === 1){
          this.objectSalesForcesTypeList = [];
          this.objectSalesForcesTypeSelect = [];
          this.salesForceNgModel = [];
        }

        if(this.objectRegionCediSelect.length > 0) {
          this.setSalesForceByRegionCedi();
          this.setValuesCombosBySalesForce();
          this.isVisibleSalesForceTypeSelect = true;
        }else{

          //Create Temporal Model with Sales Force Type.
          this.objectCountrySelect.forEach((x) => {
            this.objectSocietySelect.forEach((y) => {
              this.objectRegionSelect.forEach((z) => {
                  const listSalesForce = this.objectSalesForceForRegionCedi.filter((u) => u.country === x.value && u.society === y.value && u.region === z.value);

                  if(listSalesForce !== undefined && listSalesForce.length > 0) {
                    temporalObjectSlaesForcesType = listSalesForce.map((u) => {
                      return { label: u.salesForceDescription, value : u.salesForce };
                    });
                  }
              });
            });
          });

          //Create Distinct of Sales Force Type
          const objSalesForce = temporalObjectSlaesForcesType.filter((x1, index, array) =>
            array.findIndex((z) => z.label === x1.label && z.value === x1.value) === index
          );

          this.objectSalesForcesTypeList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSalesForceByUser(objSalesForce) : objSalesForce;
        }

        //Add SalesForce Selected.
        if(objectSalesForcesTypeSelectInterno.length > 0){
          objectSalesForcesTypeSelectInterno.forEach((x) => {
            const searchSalesForce = this.objectSalesForcesTypeList.find((y) => y.value === x.value);
            if(searchSalesForce !== undefined) this.objectSalesForcesTypeSelect.push(searchSalesForce);
          });
        }

      });
    } else {
      this.objectRegionCedi = [];
      this.objectRegionCediSelect = [];
      this.objectCediList = [];
      this.objectCediSelect = [];
      this.objectSalesForcesTypeList = [];
      this.clearFilterSales();
      this.objectMacrosegmentSelect = [];
      this.objectSalesForcesTypeList = this.objectSalesForcesTypeListInternal;
      this.isVisibleCediSelect = false;
      this.isVisibleSalesForceTypeSelect = false;
    }
  }

  getDataSocietyRegion(data: SocietyRegionCedisModel[]): any[]{
      const groupRegionCediTemporal: any[] = [];

      data.forEach((x) => {
        x.listRegion.forEach((y) => {
          if(y.listCedis.length > 0){
            let regionCedi: any = {'label' : y.regionDescription, 'value' : y.regionCode, 'items' : [] };
            let listCedis : any[] = [];
            y.listCedis.forEach((item) => {
                let cedi = {'label' : item.cediDescription, 'value' : item.cediCode };
                listCedis.push(cedi);
            });
            regionCedi.items = listCedis;
            groupRegionCediTemporal.push(regionCedi);
          }
        });
      });

      return groupRegionCediTemporal;
  }

  setCedi() {
    if(this.objectRegionCediSelect === null || this.objectRegionCediSelect.length === 0){
      this.objectCediList = [];
      this.objectCediSelect = [];
      this.objectSalesForcesTypeList = [];
      this.objectMacrosegmentSelect = [];
      this.clearFilterSales();
      this.isVisibleSalesForceTypeSelect = false;
      return;
    }else{
      this.messageRegionCediSelect = true;
    }

    if(this.objectSocietyRegionCedi.length > 0){

      if(!this.isGeneralSalesForce) {
        this.setSalesForceByRegionCedi();
        this.setValuesCombosBySalesForce();

        const objectSalesForcesTypeSelectTemporal = this.objectSalesForcesTypeSelect;
        this.objectSalesForcesTypeSelect = [];

        objectSalesForcesTypeSelectTemporal.forEach((x) => {
          const salesForce = this.objectSalesForcesTypeList.find((y) => y.value === x.value);

          if(salesForce !== undefined) this.objectSalesForcesTypeSelect.push(salesForce);
        });
      }

      let data = this.objectSocietyRegionCedi;
      this.objectCediSelect  = [];

      this.objectRegionCediSelect.forEach((v : any) => {
        data.forEach((x) => {
          x.listRegion.forEach((y) => {
            let existCedi = y.listCedis.find((x) => x.cediCode === v.value);
            if(existCedi !== undefined && existCedi != null){
              let objectSelected = {'label' : existCedi.cediDescription, 'value' : existCedi.cediCode };
              this.objectCediSelect.push(objectSelected);
            }
          });
        });
      });

      this.objectRegionCediSelect = [...this.objectCediSelect];

    }
  }

  setSalesForceByRegionCedi(){
    const temporalObjectSlaesForcesType : ICombo[] = [];

    if(this.objectRegionCediSelect.length === 0) {
      this.clearSalesForceRegionCedi();
      this.isVisibleSalesForceTypeSelect = false;
      return;
    }

    //Create Model of Sales Force by country, society, region and cedi.
     this.objectCountrySelect.forEach((x) => {
       this.objectSocietySelect.forEach((y) => {
         this.objectRegionSelect.forEach((z) => {
           this.objectRegionCediSelect.forEach((v) => {
             const listSalesForce = this.objectSalesForceForRegionCedi.filter((u) => u.country === x.value && u.society === y.value && u.region === z.value && v.value === u.cedi);

             if(listSalesForce !== undefined && listSalesForce.length > 0) {
               const filterListSalesForce = listSalesForce.map((u) => {
                 return { label: u.salesForceDescription, value : u.salesForce };
               });
               filterListSalesForce.forEach((x) => {
                 temporalObjectSlaesForcesType.push(x);
               });

             }
           });
         });
       });
     });

    //Distinct Model of Sales Force by countr, society, region and cedi.
    this.objectSalesForcesTypeList = [];
     const objTemporalSalesForce = temporalObjectSlaesForcesType.filter((x1, index, array) =>
       array.findIndex((z) => z.label === x1.label && z.value === x1.value) === index
     );

     this.objectSalesForcesTypeList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSalesForceByUser(objTemporalSalesForce) : objTemporalSalesForce;
   }


  getSalesBossBySalesForce(event : any){

    if(this.isProcess === ComercialActivityEnum.update ||  this.isProcess === ComercialActivityEnum.view){
      this.salesForceNgModel = event.value; 

      let dataSelect : ComboModel[] = [];
      if(event.value.length > 0){
        this.messageObjectSalesForcesTypeSelect = false;

        this.setListMultiselect(this.salesForceNgModel, 1);

        let listComboModel: ComboGroupModel[] = [];
        event.value.forEach((x : any) => {
          let salesForce = this.objectSalesForcesTypeList.find((y) => y.value === x.value);
    
          if(salesForce !== undefined){
            let comboGroupModel: ComboGroupModel = {
              label : salesForce.label,
              value : x.value,
              items : this.getItemsBySalesBoss(this.filterListSalesBoss, x.value)
            };
            listComboModel.push(comboGroupModel);
          }
    
        });

        this.groupedSalesBossList = listComboModel;

        if(this.groupedSalesBossList.length === 0) return;

        event.value.forEach((x:any) =>{
          let findListSelect = this.groupedSalesBossList.find((y) => y.value === x.value);
          if(findListSelect === undefined ) return;
          let items = findListSelect.items;
          this.salesBossNgModel.forEach((x)=> {
            let findSelectSalesBoss = items.filter((y) => y.value === x.value);
            if(findSelectSalesBoss.length === 0) return;
            dataSelect = findSelectSalesBoss;
          });
        });

        this.salesBossNgModel = [];
        this.salesBossNgModel = dataSelect;

        this.getSupervisorBySalesBoss(this.salesBossNgModel, 2);
        this.isVisibleSalesForceTypeSelect = true;

      }else {
        // SE LIMPIAN TODOS 
        this.clearFilterSales();
      }
    } else if(this.isProcess === ComercialActivityEnum.create) {
      this.messageObjectSalesForcesTypeSelect = false;

      const salesForceCode: string[] = this.objectSalesForcesTypeSelect.map((x) => x.value);

      if(salesForceCode !== undefined && salesForceCode.length > 0){
        this.advancedClientFilterServive.getBySalesForceAsync(salesForceCode)
        .subscribe((response) => {
          this.objectPersonListInternal = response.data;
          this.setValuesCombosBySalesForce();
        });
      }else{
        this.clearFilterSales();
        this.isVisibleSalesForceTypeSelect = false;
      }
    }
  }

  private setValuesCombosBySalesForce(){ 
    if(!this.isGeneralSalesForce){
      this.objectPersonList = this.getSalesForceValue();
    }else{
      this.objectPersonList = this.objectPersonListInternal;
    }

    const listSalesForce: ICombo[] = [];
    const listSalesBoss: any[] = [];
    const listSupervisor: any[] = [];
    const listRepresentative: any[] = [];

    this.objectPersonList.map((x: AdvancedClientFilterModel)  => {
      const salesForce: ICombo = { label : x.salesForceCode, value : x.salesForceCode };
      const salesBoss = {'salesForceCode': x.salesForceCode,'label' : x.salesBossName, 'value' : x.salesBoss};
      const supervisor = {'salesBoss' : x.salesBoss, 'salesBossName' : x.salesBossName, 'label' : x.supervisorName, 'value' : x.salesSupervisor};
      const representative = {'salesSupervisor' : x.salesSupervisor, 'supervisorName' : x.supervisorName, 'label' : x.representativeName, 'value' : x.representativeCode};

      if(salesForce.label != null) listSalesForce.push(salesForce);
      if(salesBoss.label != null) listSalesBoss.push(salesBoss);
      if(supervisor.label != null) listSupervisor.push(supervisor);
      if(representative.supervisorName && representative.label != null) listRepresentative.push(representative);
    });


    //#region  Distinct values
    this.filterListSalesForce = listSalesForce.filter((x1, index, array) =>
      array.findIndex((z) => z.label === x1.label && z.value === x1.value) === index
    );

    this.filterListSalesBoss =  listSalesBoss.filter((x1, index, array) =>
      array.findIndex((z) => z.salesForceCode === x1.salesForceCode && z.label === x1.label && z.value === x1.value) === index
    );

    this.filterListSupervisor = listSupervisor.filter((x1, index, array) =>
      array.findIndex((z) => z.salesBoss === x1.salesBoss && z.saleBossName === x1.saleBossName && z.label === x1.label && z.value === x1.value) === index
    );

    this.filterListRepresentative = listRepresentative.filter((x1, index, array) =>
      array.findIndex((z) => z.salesSupervisor === x1.salesSupervisor && z.supervisorName === x1.supervisorName && z.label === x1.label && z.value === x1.value) === index
    );
    //#endregion

    const listComboModel: ComboGroupModel[] = [];

    this.filterListSalesForce.forEach((x) => {
      const salesForce = this.objectSalesForcesTypeList.find((y) => y.value === x.value);

      if(salesForce !== undefined){
        const comboGroupModel: ComboGroupModel = {
          label : salesForce.label,
          value : x.value,
          items : this.getItemsBySalesBoss(this.filterListSalesBoss, x.value)
        };
        listComboModel.push(comboGroupModel);
      }

    });

    this.groupedSalesBossList = listComboModel;
    this.validateInfoSaleBossSelected();

    this.isVisibleSalesForceTypeSelect = true;

  }

  private getSalesForceValue(): AdvancedClientFilterModel[] {
    const personlistInternal : AdvancedClientFilterModel[] = [];

    if(this.objectPersonListInternal !== undefined && this.objectPersonListInternal != null && this.objectPersonListInternal.length > 0){
      this.objectCountrySelect.forEach((u) => {
        this.objectSocietySelect.forEach((v) => {
          this.objectRegionSelect.forEach((x) => {
            this.objectRegionCediSelect.forEach((y) => {
              const filterListPerson = this.objectPersonListInternal.filter((z) => z.country === u.value && z.society === v.value 
              && z.region === x.value && z.centerOfDistribution === y.value);
              filterListPerson.forEach((a) => {
                personlistInternal.push(a);
              });
            });
          });
        });
      });
    }

    return personlistInternal;
  }

  private getItemsBySalesBoss(filterListSalesBoss : any[], salesForceCode: string) : ICombo[] {

    const infoCombo : ICombo[] = [];
    filterListSalesBoss.forEach((z) => {
        if(salesForceCode === z.salesForceCode){
          const salesBoss: ICombo = { label: z.label, value: z.value.trim() };
          infoCombo.push(salesBoss);
        }
    });
    
    const objSalesBoss = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSalesBossByUser(infoCombo) : infoCombo;
    
    return objSalesBoss;
  }

  validateInfoSaleBossSelected(){

    const temporalBossSelected: any[] = [];
    this.salesBossSelected.forEach((x) => {
      const searchBossSelected = this.filterListSalesBoss.find((y) => y.value === x.value);
      if(searchBossSelected !== undefined){
        let findBossSelected = new ComboModel(searchBossSelected.label,searchBossSelected.value);
        temporalBossSelected.push(findBossSelected);
      }
    });

    if(temporalBossSelected.length > 0) {
      this.salesBossSelected = temporalBossSelected;
      this.getSupervisorBySalesBoss();
    }else{
      if(this.isProcess === ComercialActivityEnum.create){
        this.salesBossSelected = [];
        this.getSupervisorBySalesBoss();
      }
    }

  }

  getSupervisorBySalesBoss(event : any = '', indicador = 1){
    if(this.isProcess === ComercialActivityEnum.update ||  this.isProcess === ComercialActivityEnum.view){
      this.salesBossNgModel = indicador === 1 ? event.value : event;
      this.messageSalesBossSelected = false;

      let dataSelect : ComboModel[] = [];

      if(this.salesBossNgModel.length > 0){
        let listComboModel: ComboGroupModel[] = this.addGroupedSupervisorEdit();

        this.groupedSupervisor = listComboModel;


        if(this.groupedSupervisor.length === 0) return;

        this.dataSelectedSupervisor(dataSelect);

        this.supervisoNgModel = [];
        this.supervisoNgModel = dataSelect;
        this.getRepresentativeBySupervisor(this.supervisoNgModel, 2);
      }else {
        this.supervisoNgModel = [];
        this.groupedSupervisor = [];

        this.representativeNgModel = [];
        this.groupedRepresentative = [];
      }
    } else if(this.isProcess === ComercialActivityEnum.create) {
      if(this.salesBossSelected != null && this.salesBossSelected.length > 0){

        this.messageSalesBossSelected = false;
        this.objectSalesBossSelect = [];
        this.addGroupedSupervisorCreate();
  
        this.validateInformacionSupervisorSelected();
        this.getRepresentativeBySupervisor();
      }else{
        this.supervisorSelected = [];
        this.representativeSelected = [];
  
        this.groupedSupervisor  = [];
        this.groupedRepresentative = [];
  
        this.objectSupervisorSelect = [];
        this.objectSalesBossSelect = [];
        this.objectRepresentativeSelect = [];
      }
    }

  }

  validateInformacionSupervisorSelected(){
    if(this.salesBossSelected.length > 0 && this.supervisorSelected.length > 0){
      const temportalListSupervisor : any[] = [];

      this.salesBossSelected.forEach((x) => {
        const supervisorSelected = this.filterListSupervisor.filter((y) => y.salesBoss === x.value);
        if(supervisorSelected !== undefined && supervisorSelected.length > 0){
          supervisorSelected.forEach((z) => {
            temportalListSupervisor.push(z);
          });
        }
      });

      const listSupervisorSelected : any[] = [];
      this.supervisorSelected.forEach((x) => {
        const supervisorSelected = temportalListSupervisor.find((y) => y.value === x.value);
        if (supervisorSelected !== undefined) {
          listSupervisorSelected.push(supervisorSelected);
        }
      });

      if(listSupervisorSelected.length > 0){
        this.supervisorSelected = listSupervisorSelected.map((x : any) => {
          return {
            label : x.label,
            value : x.value
          };
        });
      }else{
        this.supervisorSelected = [];
      }
    }
  }

  getRepresentativeBySupervisor(event : any = '', indicador = 1){

    if(this.isProcess === ComercialActivityEnum.update ||  this.isProcess === ComercialActivityEnum.view){
      this.supervisoNgModel = indicador === 1 ? event.value : event;
      this.messageSupervisorSelected = false;

      let dataSelect : ComboModel[] = [];

      if(this.supervisoNgModel.length > 0){
        let listComboModel: ComboGroupModel[] = this.addGroupedRepresentativeEdit();

        this.groupedRepresentative = listComboModel;


        if(this.groupedRepresentative.length === 0) return;

        this.dataSelectedRepresentative(dataSelect);

        this.representativeNgModel = [];
        this.representativeNgModel = dataSelect;

      }else {
        this.representativeNgModel = [];
        this.groupedRepresentative = [];
      }


    } else if(this.isProcess === ComercialActivityEnum.create){

      if(this.supervisorSelected !== undefined && this.supervisorSelected.length > 0){

        this.messageSupervisorSelected = false;

        this.groupedRepresentative = [];
        this.objectSupervisorSelect = [];
  
        this.addGroupedRepresentativeCreate();
  
        this.validateRepresentativeBySupervisor();
      }else{
        this.objectSupervisorSelect = [];
        this.representativeSelected = [];
        this.groupedRepresentative = [];
        this.objectRepresentativeSelect = [];
      }
    }

  }

  validateRepresentativeBySupervisor(){
    if(this.supervisorSelected.length > 0 && this.representativeSelected.length > 0){
      const temporalRepresentativeSelected: any[] = [];
      this.supervisorSelected.forEach((x) =>  {
        const representativeSelected = this.filterListRepresentative.filter((y) => y.salesSupervisor === x.value);
        if(representativeSelected !== undefined && representativeSelected.length > 0){
          representativeSelected.forEach((z) => {
            temporalRepresentativeSelected.push(z);
          });
        }
      });

      const listTemporalRepresentativeSelected : any[] =[];
      this.representativeSelected.forEach((x) => {
        const searchSupervisor = temporalRepresentativeSelected.find((y) => y.value === x.value);
        if(searchSupervisor !== undefined) {
          let findRepresentativeSelected = new ComboModel(searchSupervisor.label,searchSupervisor.value);
          listTemporalRepresentativeSelected.push(findRepresentativeSelected);
        }
      });

      if(listTemporalRepresentativeSelected.length > 0) {
        this.representativeSelected = listTemporalRepresentativeSelected;
        this.setRepresentativeSelected();
      }
    }
  }

  setRepresentativeSelected(event : any = ''){
    if(this.isProcess === ComercialActivityEnum.update ||  this.isProcess === ComercialActivityEnum.view){
      this.messageRepresentativeSelected = false;
      this.representativeNgModel = event.value;

    }else if(this.isProcess === ComercialActivityEnum.create){

      if(this.representativeSelected != null && this.representativeSelected.length > 0){
        this.messageRepresentativeSelected = false;
        this.objectRepresentativeSelect = [];
        this.representativeSelected.forEach((x) => {
          const representative = this.filterListRepresentative.find((y) => y.value === x.value);
          if(representative !== undefined){
            this.objectRepresentativeSelect.push({label : representative.label, value : representative.value});
          }
        });
      }else{
        this.representativeSelected = [];
        this.objectRepresentativeSelect = [];
      }

    }

  }



  /**
   * @description this method get information of Customer
   * @author Daniel Londono
   * @sprint 15
   */
  getCustomerHierachyLvlOne(): void {
    this.advancedClientFilterServive
      .getAllCustomerHierachyLvlOne()
      .subscribe((response) => {

        const objHierarchyOne = response.data.map((item) => {
          return {
            label: item.customerName,
            value: item.clientCode,
          };
        });

        this.objectCustomerHierarchyLvlOneList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setHierarchyOneByUser(objHierarchyOne) : objHierarchyOne;
      });
  }

  validateMessageHierrarchyTwo(){
    if(this.objectCustomerHierarchyLvlTwoSelect.length > 0){
      this.messageHierarchyLvlTwoSelect = false;
    }
  }

  isNotVisibleSaleForce(): void
  {
    this.objectRepresentativeSelect = [];
    this.objectSupervisorSelect = [];
    this.objectSalesBossSelect = [];
  }

  selectHierarchyLvlHiddenFields():void{

    this.clearFilterSales();
    
    this.objectMacrosegmentSelect = [];
    this.objectSubtypologySelect = [];
    this.objectPurchaseMissionSelect = [];
    this.objectTypologySelect = [];

    this.isVisibleSalesForceTypeSelect = false;
  }

    async getCustomerHierarchyLvlTwo() {
    this.isVisibleHierarchyTwoySelect = true;
    this.objectCustomerHierarchyLvlTwoList = [];
    if (this.objectCustomerHierarchyLvlOneSelect.length !== 0) {
      this.spinner.show();
      let auxSelectHierarchyLvlTwo  = this.objectCustomerHierarchyLvlTwoSelect.length > 0 ? this.objectCustomerHierarchyLvlTwoSelect : [];
      this.objectCustomerHierarchyLvlTwoSelect = [];
      this.selectHierarchyLvlHiddenFields();

      for(const x of this.objectCustomerHierarchyLvlOneSelect){
        let response = await this.advancedClientFilterServive.getAllCustomerHierachyLvlTwo(x.value).toPromise();
        if(response.status){
          this.spinner.hide();
            const objHierarchyTwo = response.data.map(
              (item) => {
                return {
                  label: item.clientCode + ' - ' + item.customerName,
                  value: item.clientCode,
                };
              });
              let HierarchyTwo: ICombo[] = this.validateFilter ? this.advancedClientFilterConfigurationUser.setHierarchyTwoByUser(objHierarchyTwo) : objHierarchyTwo;
              let findDuplicate = HierarchyTwo.filter((item) => !this.objectCustomerHierarchyLvlTwoList.some((x) => item.value === x.value));
              if(findDuplicate) this.objectCustomerHierarchyLvlTwoList.push(...findDuplicate);
        } (error: HttpErrorResponse) => {
                this.spinner.hide();
                SweetAlertMessageHelpers.exception(error);
          }
      }
      let findSelect = auxSelectHierarchyLvlTwo.filter((item) => this.objectCustomerHierarchyLvlTwoList.some((x) => item.value === x.value));
      this.objectCustomerHierarchyLvlTwoSelect = findSelect;

      // this.objectCustomerHierarchyLvlOneSelect.forEach(async (x) => {
      //   let response = await this.advancedClientFilterServive.getAllCustomerHierachyLvlTwo(x.value).toPromise();
      //     if(response.status){
      //       this.spinner.hide();
      //         const objHierarchyTwo = response.data.map(
      //           (item) => {
      //             return {
      //               label: item.clientCode + ' - ' + item.customerName,
      //               value: item.clientCode,
      //             };
      //           });
      //           let HierarchyTwo: ICombo[] = this.validateFilter ? this.advancedClientFilterConfigurationUser.setHierarchyTwoByUser(objHierarchyTwo) : objHierarchyTwo;
      //           let findDuplicate = HierarchyTwo.filter((item) => !this.objectCustomerHierarchyLvlTwoList.some((x) => item.value === x.value));
      //           if(findDuplicate) this.objectCustomerHierarchyLvlTwoList.push(...findDuplicate);
      //     } (error: HttpErrorResponse) => {
      //             this.spinner.hide();
      //             SweetAlertMessageHelpers.exception(error);
      //       }
      //   this.advancedClientFilterServive
      //     .getAllCustomerHierachyLvlTwo(x.value)
      //     .subscribe(
      //       (response) => {
      //         this.spinner.hide();
      //         const objHierarchyTwo = response.data.map(
      //           (item) => {
      //             return {
      //               label: item.clientCode + ' - ' + item.customerName,
      //               value: item.clientCode,
      //             };
      //           });

      //           let HierarchyTwo: ICombo[] = this.validateFilter ? this.advancedClientFilterConfigurationUser.setHierarchyTwoByUser(objHierarchyTwo) : objHierarchyTwo;
      //           let findDuplicate = HierarchyTwo.filter((item) => !this.objectCustomerHierarchyLvlTwoList.some((x) => item.value === x.value));
      //           if(findDuplicate) this.objectCustomerHierarchyLvlTwoList.push(...findDuplicate);
      //       },
      //       (error: HttpErrorResponse) => {
      //         this.spinner.hide();
      //         SweetAlertMessageHelpers.exception(error);
      //       }
      //     );
      // });
    } else {
      this.objectCustomerHierarchyLvlTwoList = [];
      this.objectCustomerHierarchyLvlTwoSelect = [];

      this.isVisibleHierarchyTwoySelect = false;
      this.switchFilter = AdvanceClientFilterEnum.viewAll;
    }
  }

  /**
   * @description this method execute methods async.
   * @author Daniel Londono
   * @sprint 9
   */
  forkJoinData(): void {
    this.spinner.show();

    const countryResponse = this.coreService.getAll(TypeCoreMaster.Country);
    const sectorResponse = this.coreService.getAll(TypeCoreMaster.Sector);
    const macrosegmentResponse = this.coreService.getAll(
      TypeCoreMaster.Macrosegments
    );
    const typologyResponse = this.coreService.getAll(
      TypeCoreMaster.BusinessTypologies
    );
    const subtypologyResponse = this.coreService.getAll(
      TypeCoreMaster.Subtypology
    );
    const purchaseMissionsResponse = this.coreService.getAll(
      TypeCoreMaster.PurchaseMissions
    );
    const salesForcesTypeResponse = this.coreService.getAll(
      TypeCoreMaster.SalesForcesType
    );
    this.getCustomerHierachyLvlOne();

    forkJoin([
      countryResponse,
      sectorResponse,
      macrosegmentResponse,
      typologyResponse,
      subtypologyResponse,
      purchaseMissionsResponse,
      salesForcesTypeResponse,
    ]).subscribe(
      (result) => {
        const objCountryResponse: IResponseService<ICoreMaster[]> = result[0];
        const objSectorResponse: IResponseService<ICoreMaster[]> = result[1];
        const objMacrosegmentResponse: IResponseService<ICoreMaster[]> =
          result[2];
        const objTypologyResponse: IResponseService<ICoreMaster[]> = result[3];
        const objSubtypologyResponse: IResponseService<ICoreMaster[]> = result[4];
        const objPurchaseMissionsResponse: IResponseService<ICoreMaster[]> =
          result[5];
        const objSalesForcesTypeResponse: IResponseService<ICoreMaster[]> =
          result[6];

        const objCountry = objCountryResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });

        this.objectCountryList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setCountryByUser(objCountry) : objCountry;

        const objSector = objSectorResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });

        this.objectSectorList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSectorByUser(objSector) : objSector;

        const objMacrosegmento = objMacrosegmentResponse.data.map(
          (item) => {
            return {
              label: item.description,
              value: item.code.trim(),
            };
          }
        );

        this.objectMacrosegmentList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setMacrosegmentoByUser(objMacrosegmento) : objMacrosegmento;

        const objTypology = objTypologyResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });

        this.objectTypologyList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setTypologyByUser(objTypology) : objTypology;

        const objSubTypology = objSubtypologyResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.clientsGroup.trim(),
          };
        });

        this.objectSubtypologyList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSubTypologyByUser(objSubTypology) : objSubTypology;

        const objPurchaseMission = objPurchaseMissionsResponse.data.map(
          (item) => {
            return {
              label: item.description,
              value: item.code.trim(),
            };
          }
        );

        this.objectPurchaseMissionList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setPurchaseMissionByUser(objPurchaseMission) : objPurchaseMission;

        const objSalesForce = objSalesForcesTypeResponse.data.map(
          (item) => {
            return {
              label: item.description,
              value: item.code.trim(),
            };
          }
        );

        this.objectSalesForcesTypeList = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSalesForceByUser(objSalesForce) : objSalesForce;
        this.objectSalesForcesTypeListInternal = this.objectSalesForcesTypeList;

        this.getSalesForceForRegionCedi();

        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
      }
    );
  }

  getSalesForceForRegionCedi(){
    this.advancedClientFilterServive.getListSalesForceForRegionCedi()
    .subscribe((response) => {
      if(response.status){
        this.objectSalesForceForRegionCedi = response.data;
      }
    });
  }

  /**
   * @description this method is to create the json filter client.
   * @author Daniel Londoño
   * @sprint 9
   */
  addFilter(): void {
    const auxValidateFilter = this.valideFilter();
    if(auxValidateFilter){
    this.fillObjectAdvancedClientFilter(
      FilterClient.CountryCode,
      FilterClient.Country,
      this.objectCountrySelect,
      FilterClient.CountryDescription,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.SectorCode,
      FilterClient.Sector,
      this.objectSectorSelect,
      FilterClient.SecortDescription,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.SocietyCode,
      FilterClient.Society,
      this.objectSocietySelect,
      FilterClient.SocietyDescrioption,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.RegionCode,
      FilterClient.Region,
      this.objectRegionSelect,
      FilterClient.RegionDescription,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.CediCode,
      FilterClient.Cedi,
      this.objectCediSelect,
      FilterClient.CediDescription,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.SalesForceCode,
      FilterClient.SalesForce,
      this.isProcess === 1 ? this.objectSalesForcesTypeSelect : this.salesForceNgModel,
      FilterClient.SalesForceDescription,
      Operator.Equal,
      null
    );

    const salesBossSelectedTemporal = this.objectSalesBossSelect;
    this.objectSalesBossSelect = [];

    this.salesBossSelected.forEach((x) => {
      const searchSalesBoss = this.filterListSalesBoss.find((y) => y.value === x.value);
      if(searchSalesBoss !== undefined){
        const salesBossSelected: ICombo = { label:  searchSalesBoss.label, value: searchSalesBoss.value };
        this.objectSalesBossSelect.push(salesBossSelected);
      }
    });

    this.fillObjectAdvancedClientFilter(
      FilterClient.SalesBossCode,
      FilterClient.SalesBoss,
      this.isProcess === 1 ? this.salesBossSelected : this.salesBossNgModel,
      FilterClient.SalesBossDescription,
      Operator.Equal,
      null
    );

    this.fillObjectAdvancedClientFilter(
      FilterClient.SalesSupervisorCode,
      FilterClient.SalesSupervisor,
      this.isProcess === 1 ? this.supervisorSelected : this.supervisoNgModel,
      FilterClient.SalesSupervisorDescription,
      Operator.Equal,
      null
    );

    this.fillObjectAdvancedClientFilter(
      FilterClient.SalesManagerCode,
      FilterClient.SalesManager,
      this.isProcess === 1 ? this.representativeSelected : this.representativeNgModel,
      FilterClient.SalesManagerDescription,
      Operator.Equal,
      null
    );


    this.fillObjectAdvancedClientFilter(
      FilterClient.MacrosegmentCode,
      FilterClient.Macrosegment,
      this.isProcess === 1 ? this.objectMacrosegmentSelect : this.macrosegmentoNgModel,
      FilterClient.MacrosegmentDescription,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.SubtypologyCode,
      FilterClient.Subtypology,
      this.objectSubtypologySelect,
      FilterClient.SubtypologyDescription,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.PurchaseMissionCode,
      FilterClient.PurchaseMission,
      this.objectPurchaseMissionSelect,
      FilterClient.PurchaseMissionDescription,
      Operator.Equal,
      null
    );
    this.fillObjectAdvancedClientFilter(
      FilterClient.TypologyCode,
      FilterClient.Typology,
      this.objectTypologySelect,
      FilterClient.TypologyDescription,
      Operator.Equal,
      null
    );
    //if (this.objectCustomerHierarchyLvlTwoSelect) {
    this.fillObjectAdvancedClientFilter(
      FilterClient.CustomerCode,
      FilterClient.CustomerHierarchyLvl1,
      this.objectCustomerHierarchyLvlOneSelect,
      FilterClient.CustomerCode,
      Operator.Equal,
      null
    );
    //} else {
    this.fillObjectAdvancedClientFilter(
      FilterClient.SSupplyChainCode,
      FilterClient.CustomerHierarchyLvl2,
      this.objectCustomerHierarchyLvlTwoSelect,
      FilterClient.SSupplyChainCode,
      Operator.Equal,
      null
    );
    //}
    this.isVisible = false;
    }
  }

  countItems(objetc: any) : number{
    let count = 0;
    objetc.forEach((x : any) => {
      count += x.items.length;
    });
    return count;
    
  }

  validateFuerzaARepre() : boolean{
    let auxValidationFuerzaARepre = true;
    this.messageObjectSalesForcesTypeSelect = this.objectSalesForcesTypeList.length > 0 ? true : false;
    this.messageSalesBossSelected = this.messageObjectSalesForcesTypeSelect && this.countItems(this.groupedSalesBossList) > 0 ? true : false;
    this.messageSupervisorSelected = this.messageSalesBossSelected && this.countItems(this.groupedSupervisor) > 0 ? true : false;
    this.messageRepresentativeSelected = this.messageSupervisorSelected && this.countItems(this.groupedRepresentative) > 0 ? true : false;

    if(this.objectCustomerHierarchyLvlOneSelect.length > 0){
      return true;
    }

    if((this.messageObjectSalesForcesTypeSelect && (this.objectSalesForcesTypeSelect.length > 0 || this.salesForceNgModel.length > 0)) || !this.messageObjectSalesForcesTypeSelect){
      auxValidationFuerzaARepre = true;
      this.messageObjectSalesForcesTypeSelect = false;
    }else{
      return false;
    }

    if ((this.messageSalesBossSelected && (this.salesBossSelected.length > 0 || this.salesBossNgModel.length > 0)) || !this.messageSalesBossSelected){
      auxValidationFuerzaARepre = true;
      this.messageSalesBossSelected = false;
    }else{
      return false;
    }

    if((this.messageSupervisorSelected && (this.supervisorSelected.length > 0 || this.supervisoNgModel.length > 0)) || !this.messageSupervisorSelected ){
      auxValidationFuerzaARepre = true;
      this.messageSupervisorSelected = false;
    }else {
      return false;
    }

    if( (this.messageRepresentativeSelected && (this.representativeSelected.length > 0 || this.representativeNgModel.length > 0)) || !this.messageRepresentativeSelected){
      auxValidationFuerzaARepre = true;
      this.messageRepresentativeSelected = false;
    }else{
      return false;
    }



    return auxValidationFuerzaARepre;
  }


  valideFilter() : boolean {
    let auxValidationMacroASubtipologia = true;
    let auxValidationFuerzaARepre = true;
    let auxValidationHierrarchy = true;

    this.auxValidateFilter = this.validateFilter;
    this.auxRequiredFields = this.requiredFields;

    if((this.isProcess === ComercialActivityEnum.update || this.isProcess === ComercialActivityEnum.view) && this.indicador === 0){
      this.validateFilter = false;
      this.requiredFields = false;
      this.indicador += 1;
    }

    if(this.validateFilter && this.requiredFields){
      this.messageCountrySelect =  this.objectCountrySelect.length > 0 ? true : false;
      this.messageSectorSelect  =  this.objectSectorSelect.length > 0 ? true : false;
      this.messageSocietySelect =  this.objectSocietySelect.length > 0 ? true : false;
      this.messageRegionSelect  =  this.objectRegionSelect.length > 0 ? true : false;
      this.messageRegionCediSelect  = this.objectRegionCediSelect.length > 0 ? true : false;

      auxValidationMacroASubtipologia = this.validateMacroASubtipologia(auxValidationMacroASubtipologia);
      auxValidationFuerzaARepre =  this.validateFuerzaARepre();

      auxValidationHierrarchy = this.validateHierrarchyTwo(auxValidationHierrarchy);

    }

    if(this.isCountryRequired === true) this.messageCountrySelect =  this.objectCountrySelect.length > 0 ? true : false;

    if(this.messageCountrySelect && this.messageSectorSelect && this.messageSocietySelect && this.messageRegionSelect && this.messageRegionCediSelect
      && auxValidationMacroASubtipologia && auxValidationFuerzaARepre && auxValidationHierrarchy){
      return true;
    }else{
      return false;
    }
  }

  private validateHierrarchyTwo(auxValidationHierrarchy: boolean) {
    if(this.objectCustomerHierarchyLvlTwoList === undefined){
      return true;
    }
    if (this.objectCustomerHierarchyLvlOneSelect.length > 0 && this.objectCustomerHierarchyLvlTwoList.length > 0) {
      this.messageHierarchyLvlTwoSelect = true;
      if (this.objectCustomerHierarchyLvlTwoSelect.length > 0) {
        this.messageHierarchyLvlTwoSelect = false;
        auxValidationHierrarchy = true;
      } else {
        this.messageHierarchyLvlTwoSelect = true;
        auxValidationHierrarchy = false;
      }
    }
    return auxValidationHierrarchy;
  }

  private validateMacroASubtipologia(auxValidation: boolean) {
    this.messageMacrosegmentSelect = this.objectMacrosegmentList.length > 0 ? true : false;
    this.messageSubtypologySelect = this.objectSubtypologyList.length > 0 ? true : false;
    this.messagePurchaseMissionSelect = this.objectPurchaseMissionList.length > 0 ? true : false;
    this.messageTypologySelect = this.objectTypologyList.length > 0 ? true : false;

    this.messageHierarchyLvlOneSelect = this.objectCustomerHierarchyLvlOneList.length > 0 ? true : false;


    if(!this.messageMacrosegmentSelect && !this.messageSubtypologySelect
      && !this.messagePurchaseMissionSelect && !this.messageTypologySelect
      && !this.messageHierarchyLvlOneSelect){
      auxValidation = true;
    }else if ((this.messageMacrosegmentSelect && this.objectMacrosegmentSelect.length > 0) || (this.messageSubtypologySelect && this.objectSubtypologySelect.length > 0)
      || (this.messagePurchaseMissionSelect && this.objectPurchaseMissionSelect.length > 0) || (this.messageTypologySelect && this.objectTypologySelect.length > 0)
      || (this.messageHierarchyLvlOneSelect && this.objectCustomerHierarchyLvlOneSelect.length > 0)) {
      auxValidation = true;
      this.showMessagesOpenFilter();
    } else {
      auxValidation = false;
    }

    return auxValidation;
  }

  /**
   * @description this method is to fill data json filter client.
   * @author Daniel Londoño
   * @sprint 9
   */
  fillObjectAdvancedClientFilter(
    type: string,
    typeDescription: string,
    objectSelect: ICombo[],
    label: string,
    operator: string,
    id: string
  ): void {
    let concatValue = '';
    let concatLabel = '';

    if (objectSelect != null) {
      if (objectSelect.length > 0) {
        objectSelect.forEach((x) => {
          concatValue = concatValue + x.value + ';';
          concatLabel = concatLabel + x.label + ';';
        });
        const updateItem = this.advancedFiltersObjectList.find(
          (x) => x.type === type
        );

        if (updateItem) {
          const index = this.advancedFiltersObjectList.indexOf(updateItem);
          updateItem.typeDescription = typeDescription;
          updateItem.contentDescription = concatLabel.substring(
            0,
            concatLabel.length - 1
          );
          updateItem.content = concatValue.substring(0, concatValue.length - 1);
          this.advancedFiltersObjectList[index] = updateItem;
        } else {
          this.advancedFiltersObjectList.push(
            new FiltersModel(
              id !== null ? id : Util.newGuid(),
              type,
              typeDescription,
              label,
              concatValue.substring(0, concatValue.length - 1),
              concatLabel.substring(0, concatLabel.length - 1),
              operator
            )
          );
        }
      } else {
        this.advancedFiltersObjectList = this.advancedFiltersObjectList.filter(
          (x) => x.type !== type
        );
      }
    }
  }

  /**
   * @description This method is to be visible the advanced filter modal
   * @author Daniel Londoño
   * @sprint 9
   */
  openAdvancedFilters(): void {
    this.isVisibleEdit = false;
    this.isVisible = true;
    this.showMessagesOpenFilter();
  }

  /**
   * @description This method is to clear all filters
   *
   * @author Daniel Londoño
   * @sprint 9
   */
  clearFilters(): void {
    this.switchFilter = AdvanceClientFilterEnum.viewAll;
    this.advancedFiltersObjectList = [];
    this.objectCountrySelect = [];
    this.objectSectorSelect = [];
    this.objectSocietySelect = [];
    this.objectRegionSelect = [];
    this.objectCediSelect = [];
    this.clearFilterSales();
    this.objectMacrosegmentSelect = [];
    this.objectTypologySelect = [];
    this.objectSubtypologySelect = [];
    this.objectPurchaseMissionSelect = [];
    this.objectCustomerHierarchyLvlOneSelect = [];
    this.objectCustomerHierarchyLvlTwoSelect = [];
    this.objectRegionCediSelect = [];
    this.objectSalesForcesTypeList = [];
    this.macrosegmentoNgModel = [];
    
    this.isVisibleCountrySelect = false;
    this.isVisibleSocietySelect = false;
    this.isVisibleSalesForceTypeSelect = false;
    this.isVisibleHierarchyTwoySelect = false;
    this.isVisibleCediSelect = false;
    this.isGeneralSalesForce = true;

    this.hiddenSectorandSocietyWithHierarchyLevelOneAux = false;

    if(this.validateFilter){
      this.messageCountrySelect = false;
      this.messageSectorSelect = false;
      this.messageSocietySelect = false;
      this.messageRegionSelect = false;
      this.messageRegionCediSelect = false;
    }
    if(this.isCountryRequired) this.messageCountrySelect = false;

    this.eventButtonDelete.emit();

    if(this.isCountryRequired) this.messageCountrySelect = false;
  }

  private clearSalesForceRegionCedi(){
    this.objectSalesForcesTypeSelect = [];
    this.salesBossSelected = [];
    this.supervisorSelected = [];
    this.representativeSelected = [];
  }

  private clearFilterSales(){
    this.salesBossSelected = [];
    this.supervisorSelected = [];
    this.representativeSelected = [];
    this.objectSalesBossSelect = [];
    this.objectSupervisorSelect = [];
    this.objectRepresentativeSelect = [];
    this.objectSalesForcesTypeSelect = [];

    this.groupedSalesBossList = [];
    this.groupedSupervisor = [];
    this.groupedRepresentative = [];
    this.clearFilterSalesNgModel();
  }

  private clearFilterSalesNgModel(){
    this.salesForceNgModel = [];
    this.salesBossNgModel = [];
    this.supervisoNgModel = [];
    this.representativeNgModel = [];
  }

  /**
   * @description this method is to emit event the father.
   * @author Daniel Londono
   */
  onHidden(): void {
    this.isVisible = false;
  }

  /**
   * @description This method is to emit event to father component.
   * @param $event Event click data
   * @author Daniel Londoño
   * @sprint 9
   */
  eventClickFilters(): void {
    const filterInfo = {
      advancedFiltersObjectList: this.advancedFiltersObjectList,
    };
    this.eventButton.emit(filterInfo);
  }

  showMessagesOpenFilter(){
    this.messageMacrosegmentSelect = false;
    this.messageSubtypologySelect = false;
    this.messagePurchaseMissionSelect = false;
    this.messageTypologySelect = false;

    this.messageObjectSalesForcesTypeSelect = false;
    this.messageSalesBossSelected = false;
    this.messageSupervisorSelected = false;
    this.messageRepresentativeSelected = false;

    this.messageHierarchyLvlOneSelect = false;
    this.messageHierarchyLvlTwoSelect = false;
  }

  setValueAdvanceFilterr(value: number, event : any = '', indicador = false) {

    if(this.isProcess === ComercialActivityEnum.update && indicador){
      this.macrosegmentoNgModel = event.value;
      this.objectMacrosegmentSelect = this.macrosegmentoNgModel;
    }


    if(this.objectMacrosegmentSelect.length > 0 || this.objectSubtypologySelect.length > 0 || this.objectPurchaseMissionSelect.length > 0
        || this.objectTypologySelect.length > 0 || this.objectCustomerHierarchyLvlOneSelect.length > 0){

      this.messageMacrosegmentSelect = false;
      this.messageSubtypologySelect = false;
      this.messagePurchaseMissionSelect = false;
      this.messageTypologySelect = false;
      this.messageHierarchyLvlOneSelect = false;
    }else{
      this.valideFilter();
    }

    switch (value) {
      case this.objectMacrosegmentSelect.length === 0
        ? AdvanceClientFilterEnum.viewAll
        : AdvanceClientFilterEnum.Macrosegments:
        this.switchFilter = value;
        this.resetSelect(AdvanceClientFilterEnum.Macrosegments);
        break;
      case this.objectSubtypologySelect.length === 0
        ? AdvanceClientFilterEnum.viewAll
        : AdvanceClientFilterEnum.Subtypology:
        this.switchFilter = value;
        this.resetSelect(AdvanceClientFilterEnum.Subtypology);
        break;
      case this.objectTypologySelect.length === 0
        ? AdvanceClientFilterEnum.viewAll
        : AdvanceClientFilterEnum.Typology:
        this.switchFilter = value;
        this.resetSelect(AdvanceClientFilterEnum.Typology);
        break;
      case this.objectPurchaseMissionSelect.length === 0
        ? AdvanceClientFilterEnum.viewAll
        : AdvanceClientFilterEnum.PurchaseMissions:
        this.switchFilter = value;
        this.resetSelect(AdvanceClientFilterEnum.PurchaseMissions);
        break;
      case this.objectCustomerHierarchyLvlOneSelect.length === 0
        ? AdvanceClientFilterEnum.viewAll
        : AdvanceClientFilterEnum.HierarchyOne:
        this.switchFilter = value;
        this.validateWithHierarchyLvlOne();
        this.resetSelect(AdvanceClientFilterEnum.HierarchyOne);
        break;
      default:
        this.validateWithHierarchyLvlOne();
        this.switchFilter = AdvanceClientFilterEnum.viewAll;
        break;
    }
  }

  private validateWithHierarchyLvlOne() {
    this.hiddenSectorandSocietyWithHierarchyLevelOneAux = this.objectCustomerHierarchyLvlOneSelect.length > 0 && this.showFields.hiddenSectorandSocietyWithHierarchyLevelOne ? true : false;
    if(this.hiddenSectorandSocietyWithHierarchyLevelOneAux){
      this.objectSectorSelect = [];
      this.objectSocietySelect = [];
      this.objectRegionSelect = [];
      this.objectRegionCediSelect = [];
      this.objectCediSelect = [];
    }
  }

  resetSelect(type : number){
    if(type !== AdvanceClientFilterEnum.Macrosegments){
      this.objectMacrosegmentSelect = [];
    }
    if(type !== AdvanceClientFilterEnum.Subtypology){
      this.objectSubtypologySelect = [];
    }
    if(type !== AdvanceClientFilterEnum.Typology){
      this.objectTypologySelect = [];
    }
    if(type !== AdvanceClientFilterEnum.PurchaseMissions){
      this.objectPurchaseMissionSelect = [];
    }
  }

  private addGroupedSupervisorCreate() {
    this.groupedSupervisor = [];

    this.salesBossSelected.forEach((x) => {
      const supervisorList: ICombo[] = [];
      this.filterListSupervisor.forEach((y) => {
        if (y.salesBoss === x.value) {
          const supervisor: ICombo = { label: y.label, value: y.value };
          supervisorList.push(supervisor);
        }
      });

      if (supervisorList.length > 0) {
        const bossSelected = this.filterListSupervisor.find((z) => z.salesBoss === x.value);
        this.objectSalesBossSelect.push({ label: bossSelected.salesBossName, value: bossSelected.salesBoss });
        const objSupervisor = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSupervisorByUser(supervisorList) : supervisorList;
        const supervisorGroup: ComboGroupModel = { label: bossSelected.salesBossName, value: bossSelected.salesBoss, items: objSupervisor };
        this.groupedSupervisor.push(supervisorGroup);
      }
    });
  }

  private dataSelectedSupervisor(dataSelect: ComboModel[]) {
    this.salesBossNgModel.forEach((elm: any) => {
      let findListSelect = this.groupedSupervisor.find((y) => y.value === elm.value);

      if (findListSelect === undefined)
        return;

      let items = findListSelect.items;

      this.supervisoNgModel.forEach((x: any) => {
        let findSelectSupervisor = items.filter((y) => y.value === x.value);
        if (findSelectSupervisor.length === 0)
          return;
        findSelectSupervisor.forEach((x) => {
          dataSelect.push(x);
        });
      });
    });
  }

  private addGroupedSupervisorEdit() {
    let listComboModel: ComboGroupModel[] = [];
    this.salesBossNgModel.forEach((elm: any) => {

      let supervisorList: ICombo[] = [];
      this.filterListSupervisor.forEach((y) => {
        if (Number(y.salesBoss) === Number(elm.value)) {
          let supervisor: ICombo = { label: y.label, value: y.value };
          supervisorList.push(supervisor);
        }
      });

      let findSupervisor = this.filterListSupervisor.find((y) => y.salesBoss === elm.value);
      const objSupervisor = this.validateFilter ? this.advancedClientFilterConfigurationUser.setSupervisorByUser(supervisorList) : supervisorList;
      if (findSupervisor !== undefined) {
        let comboGroupModel: ComboGroupModel = {
          label: findSupervisor.salesBossName,
          value: findSupervisor.salesBoss,
          items: objSupervisor
        };
        listComboModel.push(comboGroupModel);
      }
    });
    return listComboModel;
  }

  private addGroupedRepresentativeCreate() {
    this.supervisorSelected.forEach((x) => {
      const representativeList: ICombo[] = [];
      this.filterListRepresentative.forEach((y) => {
        if (y.salesSupervisor === x.value) {
          const representative: ICombo = { label: y.label, value: y.value };
          representativeList.push(representative);
        }
      });

      const supervisorSelected = this.filterListRepresentative.find((z) => z.salesSupervisor === x.value);
      this.objectSupervisorSelect.push({ label: supervisorSelected.supervisorName, value: supervisorSelected.salesSupervisor });
      const objRepresentative = this.validateFilter ? this.advancedClientFilterConfigurationUser.setRepresentativeByUser(representativeList) : representativeList;
      const representativeGroup: ComboGroupModel = { label: supervisorSelected.supervisorName, value: supervisorSelected.salesSupervisor, items: objRepresentative };
      this.groupedRepresentative.push(representativeGroup);
    });
  }

  private dataSelectedRepresentative(dataSelect: ComboModel[]) {
    this.supervisoNgModel.forEach((elm: any) => {
      let findListSelect = this.groupedRepresentative.find((y) => y.value === elm.value);

      if (findListSelect === undefined)
        return;

      let items = findListSelect.items;

      this.representativeNgModel.forEach((x: any) => {
        let findSelectSupervisor = items.filter((y) => y.value === x.value);
        if (findSelectSupervisor.length === 0)
          return;
        findSelectSupervisor.forEach((x) => {
          dataSelect.push(x);
        });
      });
    });
  }

  private addGroupedRepresentativeEdit() {
    let listComboModel: ComboGroupModel[] = [];
    this.supervisoNgModel.forEach((elm: any) => {

      let representativeList: ICombo[] = [];
      this.filterListRepresentative.forEach((y) => {
        if (Number(y.salesSupervisor) === Number(elm.value)) {
          let representative: ICombo = { label: y.label, value: y.value };
          representativeList.push(representative);
        }
      });

      let representative = this.filterListRepresentative.find((y) => y.salesSupervisor === elm.value);
      const objRepresentative = this.validateFilter ? this.advancedClientFilterConfigurationUser.setRepresentativeByUser(representativeList) : representativeList;
      if (representative !== undefined) {
        let comboGroupModel: ComboGroupModel = {
          label: representative.supervisorName,
          value: representative.salesSupervisor,
          items: objRepresentative
        };
        listComboModel.push(comboGroupModel);
      }
    });
    return listComboModel;
  }
}
