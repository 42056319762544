import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { PriceCertificateComponent } from '@app/@components/price-certificate/price-certificate.component';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ISolicitudeDetailJson } from '../../interfaces/solicitude-detail-json.interface';
import { ISolicitudeJson } from '../../interfaces/solicitude-json.interface';
import { SolicitudeCreationModel } from '../../models/solicitude-creation.model';
import { SolicitudeFileModel } from '../../models/solicitude-file.model';
import { SolicitudeService } from '../../solicitude.service';
import { Util } from '@app/@shared/util';
import { ISolicitudeDetailDifferential } from '../../interfaces/solicitude-differential.interface';
import { SolicitudeSkusDeletedByApprover } from '../../models/solicitude-skus-deleted-by-approver.model';
import { PaginatorFilterService } from '@app/@shared/services/paginator-filter.service';
import { PaginatorFilterModel } from '@app/@shared/model/paginator-filter.models';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SolicitudeMessage } from '../../messages/solicitude.message';
import { HelperService } from '@app/@shared/services/helper.service';

@Component({
  selector: 'app-detail-creation',
  templateUrl: './detail-creation.component.html',
  styleUrls: ['../../../../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService, DatePipe],
})
export class DetailCreationComponent implements OnInit {
  @ViewChild('priceCertificate') priceCertificate: PriceCertificateComponent;
  
  private readonly spinner: NgxSpinnerService;
  private readonly solicitudeService: SolicitudeService;
  private readonly router: Router;

  public idSolicitude = 0;
  public objectList: ISolicitudeJson[];
  public actionsDetail: ISolicitudeDetailJson;
  public solicitudeStatus: SolicitudeStatus;
  public objectListSolicitude: SolicitudeCreationModel[];
  public objectListSolicitudeDifferential: ISolicitudeDetailDifferential[];
  public retry: number;
  public validity: boolean;
  public showFieldClientFilter : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false);
  public isProcess : number ;
  public filtersEdit : string = '';
  public clientsEdit : any;
  public solicitudeId = 0;

  public objectListSolicitudesDeletedByApprover: SolicitudeSkusDeletedByApprover[];
  public showAccordionSolicitudesDeletedByApprover : boolean = false;
  public page : number = 1;
  public rows : number  = 10;
  public showSkusDeletedByApprover : boolean = false;

  private readonly paginatorService: PaginatorFilterService;
  private readonly helperService: HelperService;
  public paginator: PaginatorFilterModel;

  constructor(
    spinner: NgxSpinnerService,
    solicitudeService: SolicitudeService,
    router: Router,
    public datepipe: DatePipe,
    paginatorService: PaginatorFilterService,
    helperService: HelperService
  ) { 
    this.spinner = spinner;
    this.solicitudeService = solicitudeService;
    this.router = router;
    this.paginatorService = paginatorService;
    this.helperService = helperService;
  }

  ngOnInit(): void {
    this.spinner.show();

    this.paginator = this.paginatorService.getPaginatorValues();
    this.page = this.paginator?.pageNumber ?? 0;
    this.rows = this.paginator?.limitRecords  ?? 10;

    this.solicitudeId = this.solicitudeService.getSolicitudeId();
    this.getSolicitudeById();
    this.isProcess = this.solicitudeService.getIsCreate();
  }

  validateElementsNotFound(){
    if(this.objectListSolicitude.length <= 0){
      this.validity = true;
    }else{
      this.validity = false;
    }
  }

    /**
   * @description this method is to get Solicitude with SolicitudeMaterial By Id.
   * @author Daniel Londoño
   * @sprint 5
   */
     getSolicitudeById(): void {
      this.spinner.show();
      const id = this.solicitudeId;
      if (!id) {
        this.spinner.hide();
        this.router.navigate(['auth/solicitude']);
        return;
      }

      this.solicitudeService.getSolicitudeWithDetailById(id).subscribe(
        (response) => {
          if (response.status) {

              if(response.data.status == SolicitudeStatus.Managed){
                this.showSkusDeletedByApprover = true;
                this.getSolicitudeMaterialDeletedByApprover(id);
              }
              this.retry = response.data.retry;

              if (response.data.filterClientJson) {
                this.filtersEdit = response.data.filterClientJson;
              } else if (response.data.solicitudeCustomerJson) {
                this.clientsEdit = response.data.solicitudeCustomerJson;
              } 

              this.objectListSolicitude = response.data.solicitudeDetailJson.map(
                (item) => {
                  return {
                    id: item.detailId,
                    solicitudeId: response.data.id,
                    materialCode: item.sku,
                    product: item.materialDescription,
                    currentListPrice: item.currentListPrice,
                    currentPsp: item.currentPsp,
                    newPriceWithOutTax: item.newPriceWithOutTax,
                    priceAdjustment: item.priceAdjustment,
                    newPsp: item.newPsp,
                    effectiveDateStart: new Date(item.effectiveDateStart),
                    effectiveDateEnd: new Date(item.effectiveDateEnd),
                    zofeCode: item.zofeCode,
                    zofeDescription: item.zofeDescription,
                    reason: item.reason,
                    isAutogenerated: item.isAutogenerated,
                    isPriceList : item.isPriceList === true ? SolicitudeMessage.PRICE_LIST_LABEL : item.isPriceList === false ? SolicitudeMessage.PRICE_ADJUSMENT : '',
                  };
                }
              );

              if(response.data.withdrawalDifferentialDetailJson) this.objectListSolicitudeDifferential = response.data.withdrawalDifferentialDetailJson;

              if (response.data.solicitudeFileJson) {
                this.priceCertificate.downloadFile = new SolicitudeFileModel(
                  response.data.solicitudeFileJson[0].id,
                  response.data.solicitudeFileJson[0].solicitudeId,
                  response.data.solicitudeFileJson[0].route,
                  '',
                  response.data.solicitudeFileJson[0].hash,
                );
                this.priceCertificate.nameFile = this.priceCertificate.downloadFile.route;
                this.priceCertificate.solicitudeId = this.solicitudeId;
              }

              this.validateElementsNotFound();
          } else {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        },
        (error) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
    }

    detailAutogenerated(object: SolicitudeCreationModel){
      Util.showWarning('Autogenerado', object.reason);
    }

  back(): void {
    setTimeout(() => {
      this.router.navigate(['auth/solicitude']);
    }, 1000);
  }
  

    loadPaginators(event: any) {
      let pageAux = event.first/event.rows;
      this.page = pageAux + 1;
      this.rows = event.rows;
    }
  
      /**
     * @description Get Solicitude Material Deleted By Approver
     * @author WArboleda
     * @sprint 57
     */
      getSolicitudeMaterialDeletedByApprover(solicitudeId : number) : void {
        this.spinner.show();
        this.solicitudeService.getSolicitudeMaterialDeletedByApprover(solicitudeId).subscribe(
          (response) => {
            if (response.status) {
              this.objectListSolicitudesDeletedByApprover = response.data.map(
                (item: { currentListPrice: number; currentPsp: number; dateApproverDeleted: Date; deleted: boolean; detailId: number; effectiveDateStart: Date; 
                  effectiveDateEnd: Date; guid: string; isApproval: boolean; isApproverDeleted: boolean; isAutogenerated: boolean;
                  materialCode: string; materialDescription: string; newPriceWithOutTax: number; newPsp: number; priceAdjustment: number;
                  reason: string; solicitudeId: number; solicitudeIdOrigin: number; statusDomainId: number; userApproverDeleted: string; 
                  zofeCode: string; zofeDescription: string; isPriceList?: boolean }) => {
                    
                  return {
                    currentListPrice: item.currentListPrice,
                    currentPsp: item.currentPsp,
                    dateApproverDeleted: new Date(item.dateApproverDeleted),
                    deleted: item.deleted,
                    detailId : item.detailId,
                    effectiveDateStart: new Date(item.effectiveDateStart),
                    effectiveDateEnd: new Date(item.effectiveDateEnd),
                    guid: item.guid,
                    isApproval: item.isApproval,
                    isApproverDeleted: item.isApproverDeleted,
                    isAutogenerated : item.isAutogenerated,
                    isPriceList:  item.isPriceList === true ? SolicitudeMessage.PRICE_LIST_LABEL : item.isPriceList === false ? SolicitudeMessage.PRICE_ADJUSMENT : '',
                    materialCode: item.materialCode,
                    materialDescription: item.materialDescription,
                    newPriceWithOutTax: item.newPriceWithOutTax,
                    newPsp: item.newPsp,
                    priceAdjustment: item.priceAdjustment,
                    reason : item.reason,
                    solicitudeId: item.solicitudeId,
                    solicitudeIdOrigin : item.solicitudeIdOrigin,
                    statusDomainId: item.statusDomainId,
                    userApproverDeleted: item.userApproverDeleted,
                    zofeCode: item.zofeCode,
                    zofeDescription: item.zofeDescription
                  }
                }
              );
              if(this.objectListSolicitudesDeletedByApprover.length > 0){
                this.showAccordionSolicitudesDeletedByApprover = true;
              }
            }
            this.spinner.hide();
          },
          (error: HttpErrorResponse) => {
            if (error.status === 422) {
              SweetAlertMessageHelpers.exception(error);
            }else{
              Util.showWarning(
                '',
                sessionStorage.getItem('language')  === 'es-ES' ? SweetAlert.messageSkusDeletedByApproverError : SweetAlert.messageSkusDeletedByApproverErrorTranslate
                );
            }
            this.spinner.hide();
          }
        );
      }

      materialCodeZeroLess(value: string): string{
       return this.helperService.materialCodeZeroLess(value);
      }
      
}
