import { ICommercialActivityDocument } from "../interfaces/commercial-activity-document.interface";

export class CommercialActivityDocumentModel implements ICommercialActivityDocument{
  constructor(
    public id: number,
    public commercialActivityId: number,
    public route?:string,
    public file?: string,
    public message?: string
    ) {  }
}