import { IImpactAnalysisJsonDetail } from '../interfaces/impact-analysis-json-detail.interface';


export class ImpactAnalysisJsonDetail implements IImpactAnalysisJsonDetail {
  constructor(
    public id: string,
    public type: string,
    public typeDescription: string,
    public content:string,
    public contentDescription:string,
    public operator:string,
  ){}

}
