import { IUpdateStatus } from '@app/@shared/interfaces/update-status.interface';

export class CommercialActivityStatusModel implements IUpdateStatus<number,number> {
    /**
   * @author Priscy Antonio Reales
   * @sprint 25
   * @description Model for update status
   */
  constructor(
    public id : number,
    public status: number,
    public commentary : string,
  ) {}
}