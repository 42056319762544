import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAccessManagementJson } from '@app/@modules/administration/accessmanagement/interfaces/access-management-json.interface';
import { AccessManagementRequestModel } from '@app/@modules/administration/accessmanagement/models/access-management-request.model';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdvancedUserFilterService {
  private readonly httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
   }

   getCargoAll() : Observable<IResponseService<String>> {
    const response$ : Observable<IResponseService<String>> = this.httpService
    .get(
      `${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/positions`
    )
    .pipe(
      map((response: HttpResponse<IResponseService<String>>) => response.body)
    );
    return response$;
  }

  public getAllUserByFilter(model : AccessManagementRequestModel): Observable<IResponseService<IAccessManagementJson[]>> {
    const response$: Observable<IResponseService<IAccessManagementJson[]>> =
     this.httpService
      .post(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/get_all_by_filter`, model)
      .pipe(
        map((response: HttpResponse<IResponseService<IAccessManagementJson[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

}
