import { DataRangeModel } from '@app/@components/commercial-activity-footer/models/data-range.model';
import { IProductList } from '../../exclusions/interfaces/product-list.interface';
import { IExclusionBySku } from '../interfaces/exclusions-by-sku.interface';

export class ExlusionBySkuModel implements IExclusionBySku {
  constructor(
    public id: number,
    public reasonExclusion : string,
    public typeBusinessActivity : string[],
    public canal : string[],
    public validityPeriod : DataRangeModel,
    public limitOrderPromotion : number,
    public limitOrderEvent : number,
    public limitOrderZeroValue : number,
    public limitOrderLoyalty : number,
    public limitOrderDiscountKind : number,
    public limitOrderDiscountValue : number,
    public limitOrderDiscountFinancial : number,
    public limitOrderDiscountRappel : number,
    public limitPeriodOrderPromotion : number,
    public limitPeriodOrderEvent : number,
    public limitPeriodOrderZeroValue : number,
    public limitPeriodOrderLoyalty : number,
    public limitPeriodOrderDiscountKind : number,
    public limitPeriodOrderDiscountValue : number,
    public limitPeriodOrderDiscountFinancial : number,
    public limitPeriodOrderDiscountRappel : number,
    public purchanseBase : boolean,
    public basePromotion : boolean,
    public promotionBiggerPurchanseBase : boolean,
    public limitKindDiscount : number,
    public limitFinancialDiscount : number,
    public limitValueDiscount : number,
    public materialFilter : string,
    public clientFilter : string,
    public state: number,
    public listProducts: string[],
    public idActivity?: number[],
    public materialsList?: IProductList[]
  ){}
}
