
<p-accordion>
  <p-accordionTab
    header="{{ 'SolicitudComponent.Create.PriceCertificate' | translate }}"
    [selected]="true"
  >
  <div *ngIf="isCreate"> 
    <div class="col-md-10">
      <p-message
        severity="info"
        text="{{
          'SolicitudComponent.Create.FileUploadMsgValidate' | translate
        }}"
        styleClass="p-mr-5"
      ></p-message>
    </div>

    <p-fileUpload
      #fileUpload
      customUpload="true"
      class="col-md-12"
      (uploadHandler)="onUploadFile($event)"
      (onRemove)="onRemoveFileImage()"
      [auto]="true"
      maxFileSize="4194304"
      chooseLabel="{{ 'SolicitudComponent.Create.UploadFile' | translate }}"
      invalidFileSizeMessageSummary="{0}: {{
        'SolicitudComponent.Create.InvalidSize' | translate
      }}"
      invalidFileSizeMessageDetail="{{
        'SolicitudComponent.Create.FileUpload' | translate
      }} {0}."
      accept=".pdf"
    >
      <ng-template pTemplate="content">
        <div *ngIf="!uploadedFiles.length" class="col-md-12">
          {{ "SolicitudComponent.Create.NoFile" | translate }}
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
    <div class="row mt-5">
      <div *ngIf="downloadFile" class="row">
        <div class="row mt-3 pb-3 d-flex justify-content-end">
          {{ "SolicitudComponent.Create.FilePriceCertificate" | translate }}
          &nbsp;&nbsp; <strong>{{ nameFile }}</strong
          >&nbsp;&nbsp;
        </div>

        <div class="col-lg-4 md-3 mt-3">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            label="{{ 'Download' | translate }}"
            class="p-button-outlined p-button-rounded"
            (click)="fileDownload()"
          ></button>
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
