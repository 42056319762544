import { IPagination } from '../interfaces/pagination.interface';

export class PaginationNotificationUserModel implements IPagination {
  /**
   * @author David Sneider Cardona Cardenas
   * @sprint 6
   */
  constructor(
    public orderBy: string,
    public ascendent: boolean,
    public pageNumber: number,
    public resultsPerPage: number,
    public typeSolicitude: string,
    ) {}
}
