import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { PriceCertificateComponent } from '@app/@components/price-certificate/price-certificate.component';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SolicitudeCreationModel } from '../../models/solicitude-creation.model';
import { SolicitudeFileModel } from '../../models/solicitude-file.model';
import { SolicitudeService } from '../../solicitude.service';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { HelperService } from '@app/@shared/services/helper.service';

@Component({
  selector: 'app-detail-differential',
  templateUrl: './detail-differential.component.html',
  styleUrls: ['../../../../../@shared/scss/responsive.table.scss'],
  styles: [`
  @media screen and (max-width: 960px) {
      :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
          display: flex;
      }
  }
`],
providers: [MessageService],
})
export class DetailDifferentialComponent implements OnInit {
  @ViewChild('priceCertificate') priceCertificate: PriceCertificateComponent;

  private readonly spinner: NgxSpinnerService;
  private readonly solicitudeService: SolicitudeService;
  private readonly router: Router;
  private readonly helperService: HelperService;

  public objectListSolicitude: SolicitudeCreationModel[];
  public retry: number;
  public validity: boolean;
  public showFieldClientFilter : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false);
  public isProcess : number ;
  public filtersEdit : string = '';
  public clientsEdit : any;
  public solicitudeId : number;
  public reason: string = '';

  public rolePermission: any = RolePermissionEnum;
  public modalUser: boolean = false;
  public objectListClient : any = [];
  public CSVFile : string [] = [];
  public totalRecordsDownload = 0;


  constructor(
    spinner: NgxSpinnerService,
    solicitudeService: SolicitudeService,
    router: Router,
    helperService: HelperService
  ) {
    this.spinner = spinner;
    this.solicitudeService = solicitudeService;
    this.router = router;
    this.helperService = helperService;
   }

  ngOnInit(): void {
    this.spinner.show();
    this.solicitudeId = this.solicitudeService.getSolicitudeId();
    this.getSolicitudeById();
    this.isProcess = this.solicitudeService.getIsCreate();
  }

  getSolicitudeById(): void {
    this.spinner.show();
    const id = this.solicitudeId;
    if (!id) {
      this.spinner.hide();
      this.router.navigate(['auth/solicitude']);
      return;
    }

    this.solicitudeService.getSolicitudeWithDetailById(id).subscribe(
      (response) => {
        if (response.status) {
          this.retry = response.data.retry;
          this.reason = response.data.message;
          if(response.data.filterClientJson) {
            this.filtersEdit = response.data.filterClientJson;
          } else if (response.data.solicitudeCustomerJson) {
            this.clientsEdit = response.data.solicitudeCustomerJson;
          }

          this.objectListSolicitude = response.data.solicitudeDetailJson.map(
            (item) => {
              return {
                id: item.detailId,
                solicitudeId: response.data.id,
                materialCode: item.sku,
                product: item.materialDescription,
                currentListPrice: item.currentListPrice,
                currentPsp: item.currentPsp,
                newPriceWithOutTax: item.newPriceWithOutTax,
                priceAdjustment: item.priceAdjustment,
                newPsp: item.newPsp,
                effectiveDateStart: new Date(item.effectiveDateStart),
                effectiveDateEnd: new Date(item.effectiveDateEnd),
                zofeCode: item.zofeCode,
                zofeDescription: item.zofeDescription,
                differential: item.differential,
                filterClient: item.filterClient,
                csvClients: item.csvClients
              };
            }
          );

          this.objectListSolicitude.forEach((x) => {
            if(x.filterClient){
              let description = JSON.parse(x.filterClient);
              if(description.length > 1){
                x.tooltipClient = description[0].contentDescription + '/' + description[1].contentDescription;
              }else if(description.length === 1){
                x.tooltipClient = description[0].contentDescription;
              }
            }
            if(x.csvClients){
              x.tooltipClient = 'Archivo CSV';
            }
          });

          if (response.data.solicitudeFileJson) {
            this.priceCertificate.downloadFile = new SolicitudeFileModel(
              response.data.solicitudeFileJson[0].id,
              response.data.solicitudeFileJson[0].solicitudeId,
              response.data.solicitudeFileJson[0].route,
              '',
              response.data.solicitudeFileJson[0].hash,
            );
            this.priceCertificate.nameFile = this.priceCertificate.downloadFile.route;
            this.priceCertificate.solicitudeId = this.solicitudeId;
          }

        } else {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  users(object: SolicitudeCreationModel){
    this.modalUser = true;
    this.objectListClient = [];
    this.CSVFile = [];
    if(object.filterClient){
      this.objectListClient = JSON.parse(object.filterClient);
    }
    if(object.csvClients){
      this.CSVFile = object.csvClients;
      this.totalRecordsDownload = object.csvClients.length;
    }
  }

  fileDownload() {
    const csv = typeof this.CSVFile[0] === 'string' ? this.CSVFile : this.CSVFile.map((row: any) => JSON.stringify(row.clientCode));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  cancelUser(){
    this.modalUser = false;
    this.objectListClient = [];
    this.CSVFile = [];
  }

back(): void {
  setTimeout(() => {
    this.router.navigate(['auth/solicitude']);
  }, 1000);
}

materialCodeZeroLess(value: string): string{
  return this.helperService.materialCodeZeroLess(value);
 }

}
