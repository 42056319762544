<form id="formAccessManagement" [formGroup]="formAccessManagement">
  <div class="pstn_container">
    <div class="row">
     <!-- Activity Code -->
      <div class="form-check col-md-3" *ngIf="showFieldsGeneral.activityCode">
        <div class="form-group">
          <label>{{ nameIdInput | translate }}</label><br />

          <input pInputText type="number" id="activityCode" formControlName="activityCode" autocomplete="off"
            buttonLayout="horizontal" spinnerMode="horizontal" inputId="activityCode"
            decrementButtonClass="p-button-primary" incrementButtonClass="p-button-primary"
            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
            onkeypress="return ((event.charCode >= 48 && event.charCode <= 57))" min=0/>
          <br />
          <small id="activityCodeMsgValidate" *ngIf="formAccessManagement.controls['activityCode'].invalid && 
                                              (formAccessManagement.controls['activityCode'].dirty || 
                                               formAccessManagement.controls['activityCode'].touched)"
            class="p-error">{{ 'AdvancedFiltersComponent.GeneralComponent.ActivityCodeMsgValidate' | translate
            }}</small>

        </div>
      </div>

      <!-- Activity Description -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.activityDescription">
        <div class="form-group">
          <label>{{
            "AdvancedFiltersComponent.GeneralComponent.ActivityDescription"
            | translate
            }}</label>
          <input id="activityDescription" formControlName="activityDescription" autocomplete="off"
            placeholder="Descripción actividad" class="form-control" pInputText (focusout)="onFocusOut()" /><br />
          <small id="activityDescMsgValidate" *ngIf="formAccessManagement.controls['activityDescription'].invalid && 
                                              (formAccessManagement.controls['activityDescription'].dirty || 
                                               formAccessManagement.controls['activityDescription'].touched)"
            class="p-error">
            {{ 'AdvancedFiltersComponent.GeneralComponent.ActivityDescMsgValidate' | translate }}
          </small>
        </div>
      </div>

      <!-- Activity Dates -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.rangeDateControl">
        <div class="form-group">
          <label>{{
            nameDateInput | translate
            }}</label>

          <p-calendar #cal2 id="rangeDateControl" formControlName="rangeDateControl" [showIcon]="true"
            selectionMode="range" [monthNavigator]="true" [showOtherMonths]="false" [yearNavigator]="true"
            [yearRange]="getYearRange()" inputId="icon" placeholder="{{ 'RangeDate' | translate }}"
            dateFormat="dd/mm/yy" [required]="true" [showButtonBar]="true" (ngModelChange)="onChangeDate()"
            class="multitooltip">
            <p-header class="p-2">
              <i id="btnClose" style="border: none" class="uil uil-times enviar" (click)="cal2.hideOverlay()"
                aria-hidden="true"></i>
            </p-header>
          </p-calendar>
          <small class="Tooltip" *ngIf="dateRangeTooltip != ''">
            <ul>
              {{ dateRangeTooltip }}
            </ul>
          </small>
        </div>
      </div>

      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.statusDifferential">
        <div class="form-group">
          <label>{{ 'EliminateDifferentialComponent.State' | translate }}</label>
          <p-dropdown id="statusDifferential" [filter]="true" [showClear]="true" formControlName="statusDifferential"
            optionLabel="label" optionValue="value"
            placeholder="{{ 'EliminateDifferentialComponent.State' | translate }}"
            [options]="objectStatusDifferentialCombo" [style]="{ width: '100%' }">
          </p-dropdown>
        </div>
      </div>

      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.typeSolicitude">
        <div class="form-group">
          <label>{{ 'PendingSolicitudeComponent.Type' | translate }}</label>
          <div class="form-group">
              <p-multiSelect id="typeSolicitude" class="multitooltip" [filter]="true" [ngClass]="{
                  'ng-invalid ng-dirty':
                    activityType.invalid && activityType.touched
                }" formControlName="typeSolicitude" optionLabel="label" placeholder="{{
                  'PendingSolicitudeComponent.Type'
                    | translate
                }}" [options]="objectSolicitudeTypeCombo" defaultLabel="{{
                  'AccessManagementComponent.GeneralComponent.ActivityType' | translate   
                }}" selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}" [style]="{ width: '100%' }"
                (onChange)="onChangeSolicitudeType()" display="chip">
              </p-multiSelect>
              <small id="typeSolicitude" *ngIf="
                  formAccessManagement.controls['typeSolicitude'].invalid &&
                  (formAccessManagement.controls['typeSolicitude'].dirty ||
                    formAccessManagement.controls['typeSolicitude'].touched)
                " class="p-error">{{
                "AccessManagementComponent.Create.ChannelMsgValidate"
                | translate
                }}</small>
  
              <small class="Tooltip" *ngIf="solicitudeTypeToolTip.length > 0">
                <ul>
                  <li *ngFor="let index of solicitudeTypeToolTip">
                    {{ index }}
                  </li>
                </ul>
              </small>
            </div>            
          </div>
      </div>

      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.statusSolicitude">
        <div class="form-group">
          <label>{{ 'PendingSolicitudeComponent.Status' | translate }}</label>
          <p-multiSelect id="statusSolicitude" class="multitooltip" [filter]="true" [ngClass]="{
            'ng-invalid ng-dirty':
              activityType.invalid && activityType.touched
          }" formControlName="statusSolicitude" optionLabel="label" placeholder="{{
            'PendingSolicitudeComponent.Status'
              | translate
          }}" [options]="objectSolicitudeStatusCombo" defaultLabel="{{
            'AccessManagementComponent.GeneralComponent.ActivityType'
              | translate  
          }}" selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}" [style]="{ width: '100%' }"
          (onChange)="onChangeSolicitudeStatus()" display="chip">
        </p-multiSelect>
        <small id="statusSolicitude" *ngIf="
            formAccessManagement.controls['statusSolicitude'].invalid &&
            (formAccessManagement.controls['statusSolicitude'].dirty ||
              formAccessManagement.controls['statusSolicitude'].touched)
          " class="p-error">{{
          "AccessManagementComponent.Create.ChannelMsgValidate"
          | translate
          }}</small>

        <small class="Tooltip" *ngIf="solicitudeStatusToolTip.length > 0">
          <ul>
            <li *ngFor="let index of solicitudeStatusToolTip">
              {{ index }}
            </li>
          </ul>
        </small>        
        </div>
      </div>

    </div>
    <div class="row">
      <!-- Sector -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.sector">
        <div class="form-group">
          <label>{{
            "AdvancedFiltersComponent.GeneralComponent.Sector" | translate
            }}</label>
          <div class="form-group">
            <p-multiSelect id="sector" class="multitooltip" [filter]="true" [ngClass]="{
                'ng-invalid ng-dirty': sector.invalid && sector.touched
              }" formControlName="sector" optionLabel="label" placeholder="{{
                'AdvancedFiltersComponent.GeneralComponent.Sector' | translate
              }}" [options]="objectSectorList" defaultLabel="{{
                'AccessManagementComponent.GeneralComponent.Sector' | translate
              }}" selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}" [style]="{ width: '100%' }"
              (onChange)="onChangeSector()" display="chip">
            </p-multiSelect>
            <small id="sectorValidator" *ngIf="
                formAccessManagement.controls['sector'].invalid &&
                (formAccessManagement.controls['sector'].dirty ||
                  formAccessManagement.controls['sector'].touched)
              " class="p-error">{{
              "AccessManagementComponent.Create.SectorMsgValidate" | translate
              }}</small>

            <small class="Tooltip" *ngIf="sectorToolTip.length > 0">
              <ul>
                <li *ngFor="let index of sectorToolTip">{{ index }}</li>
              </ul>
            </small>
          </div>
        </div>
      </div>

      <!-- Channel -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.channel">
        <div class="form-group">
          <label>{{
            "AdvancedFiltersComponent.GeneralComponent.Channel" | translate
            }}</label>
          <div class="form-group">
            <p-multiSelect id="channel" class="multitooltip" [filter]="true" [ngClass]="{
                'ng-invalid ng-dirty': channel.invalid && channel.touched
              }" formControlName="channel" optionLabel="label" placeholder="{{
                'AdvancedFiltersComponent.GeneralComponent.Channel' | translate
              }}" [options]="objectChannelList" defaultLabel="{{
                'AccessManagementComponent.GeneralComponent.Channel' | translate
              }}" selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}" [style]="{ width: '100%' }"
              (onChange)="onChangeChannel()" display="chip">
            </p-multiSelect>
            <small id="channel" *ngIf="
                formAccessManagement.controls['channel'].invalid &&
                (formAccessManagement.controls['channel'].dirty ||
                  formAccessManagement.controls['channel'].touched)
              " class="p-error">{{
              "AccessManagementComponent.Create.ChannelMsgValidate"
              | translate
              }}</small>

            <small class="Tooltip" *ngIf="channelToolTip.length > 0">
              <ul>
                <li *ngFor="let index of channelToolTip">{{ index }}</li>
              </ul>
            </small>
          </div>
        </div>
      </div>

      <!-- Tipo de Actividad -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.activityType">
        <div class="form-group">
          <label>{{
            "AdvancedFiltersComponent.GeneralComponent.ActivityType" | translate
            }}</label>

          <div class="form-group">
            <p-multiSelect id="activityType" class="multitooltip" [filter]="true" [ngClass]="{
                'ng-invalid ng-dirty':
                  activityType.invalid && activityType.touched
              }" formControlName="activityType" optionLabel="label" placeholder="{{
                'AdvancedFiltersComponent.GeneralComponent.ActivityType'
                  | translate
              }}" [options]="objectPromotionalActivity" defaultLabel="{{
                'AccessManagementComponent.GeneralComponent.ActivityType'
                  | translate
              }}" selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}" [style]="{ width: '100%' }"
              (onChange)="onChangeActivityType()" display="chip">
            </p-multiSelect>
            <small id="activityType" *ngIf="
                formAccessManagement.controls['activityType'].invalid &&
                (formAccessManagement.controls['activityType'].dirty ||
                  formAccessManagement.controls['activityType'].touched)
              " class="p-error">{{
              "AccessManagementComponent.Create.ChannelMsgValidate"
              | translate
              }}</small>

            <small class="Tooltip" *ngIf="activityTypeToolTip.length > 0">
              <ul>
                <li *ngFor="let index of activityTypeToolTip">
                  {{ index }}
                </li>
              </ul>
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Estado de Actividad -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.activityStatus">
        <div class="form-group">
          <label>{{
            "AdvancedFiltersComponent.GeneralComponent.ActivityStatus"
            | translate
            }}</label>

          <div class="form-group">
            <p-multiSelect id="activityStatus" class="multitooltip" [filter]="true" [ngClass]="{
                'ng-invalid ng-dirty':
                  activityStatus.invalid && activityStatus.touched
              }" formControlName="activityStatus" optionLabel="label" placeholder="{{
                'AdvancedFiltersComponent.GeneralComponent.ActivityStatus'
                  | translate
              }}" [options]="objectStatusList" defaultLabel="{{
                'AccessManagementComponent.GeneralComponent.ActivityStatus'
                  | translate
              }}" selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}" [style]="{ width: '100%' }"
              (onChange)="onChangeActivityStatus()" display="chip">
            </p-multiSelect>
            <small id="activityStatus" *ngIf="
                formAccessManagement.controls['activityStatus'].invalid &&
                (formAccessManagement.controls['activityStatus'].dirty ||
                  formAccessManagement.controls['activityStatus'].touched)
              " class="p-error">{{
              "AccessManagementComponent.Create.ChannelMsgValidate"
              | translate
              }}</small>

            <small class="Tooltip" *ngIf="activityStatusToolTip.length > 0">
              <ul>
                <li *ngFor="let index of activityStatusToolTip">
                  {{ index }}
                </li>
              </ul>
            </small>
          </div>
        </div>
      </div>

      <!-- Tipo de Promoción de Actividad -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.activityProm">
        <div class="form-group">
          <label>{{
            "AdvancedFiltersComponent.GeneralComponent.ActivityProm" | translate
            }}</label>

          <div class="form-group">
            <p-multiSelect id="activityProm" class="multitooltip" [filter]="true" [ngClass]="{
                'ng-invalid ng-dirty':
                  activityProm.invalid && activityProm.touched
              }" formControlName="activityProm" optionLabel="label" placeholder="{{
                'AdvancedFiltersComponent.GeneralComponent.ActivityProm'
                  | translate
              }}" [options]="objectPromList" defaultLabel="{{
                'AccessManagementComponent.GeneralComponent.ActivityProm'
                  | translate
              }}" selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}" [style]="{ width: '100%' }"
              (onChange)="onChangeActivityProm()" display="chip">
            </p-multiSelect>

            <small id="activityProm" *ngIf="
                formAccessManagement.controls['activityProm'].invalid &&
                (formAccessManagement.controls['activityProm'].dirty ||
                  formAccessManagement.controls['activityProm'].touched)
              " class="p-error">{{
              "AccessManagementComponent.Create.ChannelMsgValidate"
              | translate
              }}</small>

            <small class="Tooltip" *ngIf="activityPromToolTip.length > 0">
              <ul>
                <li *ngFor="let index of activityPromToolTip">
                  {{ index }}
                </li>
              </ul>
            </small>
          </div>
        </div>
      </div>


      <!-- Activity Current Dates -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.rangeCurrentDateControl">
        <div class="form-group">
          <label>{{
            nameCurrentDateInput | translate
            }}</label>

          <p-calendar #cal3 id="rangeCurrentDateControl" formControlName="rangeCurrentDateControl" [showIcon]="true"
            selectionMode="range" [monthNavigator]="true" [showOtherMonths]="false" [yearNavigator]="true"
            [yearRange]="getYearRange()" inputId="icon" placeholder="{{ 'RangeDate' | translate }}"
            dateFormat="dd/mm/yy" [required]="true" [showButtonBar]="true" (ngModelChange)="onChangeCurrentDate()"
            class="multitooltip">
            <p-header class="p-2">
              <i id="btnClose" style="border: none" class="uil uil-times enviar" (click)="cal3.hideOverlay()"
                aria-hidden="true"></i>
            </p-header>
          </p-calendar>
          <small class="Tooltip" *ngIf="dateCurrentRangeTooltip != ''">
            <ul>
              {{ dateCurrentRangeTooltip }}
            </ul>
          </small>
        </div>
      </div>

      <!-- User Creator -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.userCreator">
        <div class="form-group">
          <label>{{"AdvancedFiltersComponent.GeneralComponent.UserCreator" | translate }}</label>
          <input id="userCreator" formControlName="userCreator" autocomplete="off" placeholder="Usuario creador"
            class="form-control" pInputText (focusout)="onFocusOut()" /><br />
          <small id="userCreatorMsgValidate" *ngIf="formAccessManagement.controls['userCreator'].invalid && 
                                                   (formAccessManagement.controls['userCreator'].dirty || 
                                                    formAccessManagement.controls['userCreator'].touched)"
            class="p-error">
            {{ 'AdvancedFiltersComponent.GeneralComponent.UserCreatorMsgValidate' | translate }}
          </small>
        </div>
      </div>

    </div>

    <div class="row">
      <!-- Activity Creation Date -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.rangeCreationDateControl">
        <div class="form-group">
          <label>{{
            nameCreationDateInput | translate
            }}</label>

          <p-calendar #cal3 id="rangeCreationDateControl" formControlName="rangeCreationDateControl" [showIcon]="true"
            selectionMode="range" [monthNavigator]="true" [showOtherMonths]="false" [yearNavigator]="true"
            [yearRange]="getYearRange()" inputId="icon" placeholder="{{ 'RangeDate' | translate }}"
            dateFormat="dd/mm/yy" [required]="true" [showButtonBar]="true" (ngModelChange)="onChangeCreationDate()"
            class="multitooltip"
            (onClearClick)="initCreationDate()">
            <p-header class="p-2">
              <i id="btnClose" style="border: none" class="uil uil-times enviar" (click)="cal3.hideOverlay()"
                aria-hidden="true"></i>
            </p-header>
          </p-calendar>
          <small class="Tooltip" *ngIf="dateCreationRangeTooltip != ''">
            <ul>
              {{ dateCreationRangeTooltip }}
            </ul>
          </small>
        </div>
      </div>

      <!-- Activity Validity Dates -->
      <div class="form-check col-md-4" *ngIf="showFieldsGeneral.rangeValidityDateControl">
        <div class="form-group">
          <label>{{
            nameValidityDateInput | translate
            }}</label>

          <p-calendar #cal3 id="rangeValidityDateControl" formControlName="rangeValidityDateControl" [showIcon]="true"
            selectionMode="range" [monthNavigator]="true" [showOtherMonths]="false" [yearNavigator]="true"
            [yearRange]="getYearRange()" inputId="icon" placeholder="{{ 'RangeDate' | translate }}"
            dateFormat="dd/mm/yy" [required]="true" [showButtonBar]="true" (ngModelChange)="onChangeValidityDate()"
            class="multitooltip"
            (onClearClick)="initValidityDate()">
            <p-header class="p-2">
              <i id="btnClose" style="border: none" class="uil uil-times enviar" (click)="cal3.hideOverlay()"
                aria-hidden="true"></i>
            </p-header>
          </p-calendar>
          <small class="Tooltip" *ngIf="dateValidityRangeTooltip != ''">
            <ul>
              {{ dateValidityRangeTooltip }}
            </ul>
          </small>
        </div>
      </div>

    </div>

  </div>
</form>