export class UserSession {
  public userId: string;
  public email: string;
  public fullName: string;
  public roles: string[];
  public sessionAvailable: Date;

  constructor(
    id: string,
    name: string,
    email: string,
    roles: string[],
    sessionExpiration: Date
  ) {
    this.userId = id;
    this.email = email;
    this.fullName = name;
    this.roles = roles;
    this.sessionAvailable = sessionExpiration;
  }

  public getUserId(): string {
    return this.userId;
  }

  public getEmail(): string {
    return this.email;
  }

  public getFullName(): string {
    return this.fullName;
  }

  public getRoles(): string[] {
    return this.roles;
  }

  public getSessionAvailable(): Date {
    return this.sessionAvailable;
  }
}
