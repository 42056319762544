import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

import { environment } from '@env/environment';

import { IResponseService } from '@app/@shared/interfaces/response-service.interface';

import { HttpService } from '@app/@shared/services/http.service';
import { ICombo } from '@app/@shared/interfaces/combo.interface';

@Injectable({
  providedIn: 'root'
})
export class HierarchyAdvancedFiltersService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService)
  {
    this.httpService = httpService;
  }

  /**
   *
   * @param filters
   * @returns
   */
  hierarchyFiltersMaterials(filters: any): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> = this.httpService
    .patch(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/hierarchy_filters_materials`,filters)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => response.body)
    );
    return response$;
  } 

  /**
   *
   * @param filters
   * @returns
   */
  hierarchySubFiltersMaterials(filters: any): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> = this.httpService
    .patch(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/hierarchy_sub_filters_materials`, filters)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => response.body)
    );
    return response$;
  }

  /**
   *
   * @param filters
   * @returns
   */
  hierarchyFiltersCheck(filters: any): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> = this.httpService
    .patch(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_hierarchy_filters_materials_check`, filters)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => response.body)
    );
    return response$;
  }

  getMaterialType() : Observable<IResponseService<ICombo[]>> {
    const response$ : Observable<IResponseService<ICombo[]>> = this.httpService
    .get(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/materials/get_list_material_type`
    )
    .pipe(
      map((response: HttpResponse<IResponseService<ICombo[]>>) => response.body)
    );
    return response$;
  }
}