import { Injectable } from '@angular/core';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IAdvancedClientFilter } from '../interfaces/advanced-client-filter.interface';
import { ICustomerInformation } from '../interfaces/customer-information-interface';
import { ISalesForceForRegionCedi } from '../interfaces/sales-force-for-region-cedi.interface';
import { IConfigurationUserAccessManagement } from '../interfaces/configuration-user-access-management.interface';




@Injectable({
  providedIn: 'root'
})
export class AdvancedClientFilterService {
  private readonly httpService: HttpService;

  constructor(httpService: HttpService) 
  { 
    this.httpService = httpService;
  }

  /**
* @description This method is to get all the person by sales force code.
* @author Daniel Londono
* @param salesForcesTypeCode
* @returns Observable<IResponseService<IAdvancedClientFilter[]>>
* @sprint 9
*/
  public getBySalesForceCodeAsync(salesForcesTypeCode: string): Observable<IResponseService<IAdvancedClientFilter[]>> {
    const response$: Observable<IResponseService<IAdvancedClientFilter[]>> = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/person/get_by_sales_forces_code_async/${salesForcesTypeCode}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IAdvancedClientFilter[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public getBySalesForceAsync(salesForcesTypeCode: string[]): Observable<IResponseService<IAdvancedClientFilter[]>> {
    const response$: Observable<IResponseService<IAdvancedClientFilter[]>> = this.httpService
      .patch(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/person/get_by_sales_forces_code_async/`, salesForcesTypeCode)
      .pipe(
        map((response: HttpResponse<IResponseService<IAdvancedClientFilter[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
* @description This method is to get all the customer information.
* @author Daniel Londono
* @param none
* @sprint 15
*/
  public getAllCustomerHierachyLvlOne(): Observable<IResponseService<ICustomerInformation[]>> {
    const  response$: Observable<IResponseService<ICustomerInformation[]>> = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/advanced_filters_hierarchy_clients_lvl_one`)
      .pipe(
        map((response: HttpResponse<IResponseService<ICustomerInformation[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
* @description This method is to get all the customer information.
* @author Daniel Londono
* @param none
* @sprint 15
*/
  public getAllCustomerHierachyLvlTwo(clientCode: string): Observable<IResponseService<ICustomerInformation[]>> {
    const response$: Observable<IResponseService<ICustomerInformation[]>> = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/advanced_filters_hierarchy_clients_lvl_two/${clientCode}`)
      .pipe(
        map((response: HttpResponse<IResponseService<ICustomerInformation[]>>) => {
          return response.body;
        })
      );
    return response$;
  }


    /**
    * @description This method is to get all the customer information.
    * @author Daniel Londono
    * @param none
    * @sprint 15
    */
    public getAllCustomerHierachyLvlTwoAlternative(listClientCode: string[]): Observable<IResponseService<ICustomerInformation[]>> {
      const response$: Observable<IResponseService<ICustomerInformation[]>> = this.httpService
        .patch(
                `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/advanced_filters_hierarchy_clients_lvl_two/`,
                listClientCode
              )
        .pipe(
          map((response: HttpResponse<IResponseService<ICustomerInformation[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

  public getListSalesForceForRegionCedi(): Observable<IResponseService<ISalesForceForRegionCedi[]>>{
    const response$: Observable<IResponseService<ISalesForceForRegionCedi[]>> = this.httpService
    .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list-sales-force-for-region-cedi`)
    .pipe(
      map((response: HttpResponse<IResponseService<ISalesForceForRegionCedi[]>>) => {
        return response.body;
      })
    );

    return response$;
  }

  public getConfigurationUserAcessManagement(accessManagementId: string): Observable<IResponseService<IConfigurationUserAccessManagement>>{
    const response$: Observable<IResponseService<IConfigurationUserAccessManagement>> = this.httpService
    .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/configuration-user-acess-management/${accessManagementId}`)
    .pipe(
      map((response: HttpResponse<IResponseService<IConfigurationUserAccessManagement>>) => {
        return response.body;
      })
    );

    return response$;
  }
}
