import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { ApplicationConstants } from '@app/app.constants';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';

import { CommonConfiguration } from '@app/@shared/enums/common.enums';

export const retryCount = Number(SessionStorageHelper.get(CommonConfiguration.ConnectionRetries));
export const retryWaitMilliSeconds = Number(SessionStorageHelper.get(CommonConfiguration.TimeConnectionRetries));

@Injectable({
    providedIn: 'root'
  })

export class RetryInterceptor implements HttpInterceptor {
  private readonly messageService: MessageService;

  private readonly whiteList: string[] = ['api/v1/ccvp_apwb_core/solicitude/associate_solicitude_with_solicitude_material','api/v1/ccvp_apwb_core/solicitude_material/approval_material','api/v1/ccvp_apwb_core/solicitude/update_solicitude_status'];

  constructor(messageService: MessageService)
  {
    this.messageService = messageService;
  }

  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  private verifyURLIsWhiteList(url : string)
  {
    this.whiteList.forEach((element) => {
      if(url.includes(element))
      {
        return true;
      }
    });
    return false;
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!this.verifyURLIsWhiteList(request.url))
    {
      return next.handle(request).pipe(
        retryWhen((error) =>
          error.pipe(
            concatMap((error1, count) => {

              if (count <= retryCount && error1.status === 500) {
                const language: string = sessionStorage.getItem('language');
                  if( count > 0)
                      this.showToast(SweetAlert.iconWarningToast,
                        language === 'es-ES' ? SweetAlert.titleAlertRetry + ' ' + count.toString() : SweetAlert.titleAlertRetryTranslate + ' ' + count.toString(),
                        language === 'es-ES' ? '¡Fallo de conexión al servicio. Reintentando conexión en ' +  (retryWaitMilliSeconds / 1000).toString() + ' segundos!  '
                        : '¡Service connection failure. Retrying connection on ' +  (retryWaitMilliSeconds / 1000).toString() + ' segundos!  ');
                return of(error1);
              }
              return throwError(error1);
            }),
            delay(retryWaitMilliSeconds)
          )
        )
      );
    }
    else {
      
    }
  }
}
