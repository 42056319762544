import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SolicitudeFileModel } from '@app/@modules/prices/solicitude/models/solicitude-file.model';
import { FileBaseHelper } from '@app/@shared/helpers/file-base.helper';
import { Util } from '@app/@shared/util';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertResult } from 'sweetalert2';
import { PriceCertificateService } from './price-certificate.service';


@Component({
  selector: 'app-price-certificate',
  templateUrl: './price-certificate.component.html',
  styleUrls: ['./price-certificate.component.css']
})
export class PriceCertificateComponent implements OnInit {

  @Input('isCreate') isCreate: boolean = true;
  //@Input('solicitudeId') solicitudeId: string = "";

  @ViewChild('fileUpload') fileUpload: any;

  private readonly fileBaseHelper: FileBaseHelper;
  private readonly priceCertificateService: PriceCertificateService;
  private readonly spinner: NgxSpinnerService;

  public downloadFile: SolicitudeFileModel;
  public uploadedFiles: any[] = [];
  public attachedFilePdf: string = undefined;
  public nameFile = '';
  public solicitudeId: number;

  constructor(
    spinner: NgxSpinnerService,
    priceCertificateService: PriceCertificateService,
    fileBaseHelper: FileBaseHelper,
  ) {
    this.spinner = spinner;
    this.priceCertificateService = priceCertificateService,
    this.fileBaseHelper = fileBaseHelper;
   }

  ngOnInit(): void {
  }

  async onUploadFile(event: any) {
    if (this.downloadFile) {
      Util.confirmEditFile().then(async (result: SweetAlertResult) => {
        if (result.value) {
          this.downloadFile = null;
          this.uploadFile(event);
        } else {
          this.fileUpload.clear();
        }
      });
    } else {
      this.uploadFile(event);
    }
  }

  async uploadFile(event: any) {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.attachedFilePdf = await this.fileBaseHelper.getFileBase(this.uploadedFiles[0]);
  }

  onRemoveFileImage(): void {
    this.uploadedFiles = [];
  }

  fileDownload() {
    this.spinner.show();
    this.priceCertificateService.getFile(Number(this.solicitudeId), this.nameFile)
      .subscribe();
    this.spinner.hide();
  }

  getSolicitudeFileModel(): SolicitudeFileModel {
    if (this.uploadedFiles[0]) {
      return new SolicitudeFileModel(
        null,
        0,
        this.uploadedFiles[0].name,
        this.attachedFilePdf,
        ''
      );
    } else {
      if (this.downloadFile) {
        return new SolicitudeFileModel(
          this.downloadFile.id,
          this.downloadFile.solicitudeId,
          this.downloadFile.route,
          this.downloadFile.file,
          ''
        );
      }
    }
  }
}
