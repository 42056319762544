import { IHierarchyReleaseCreate } from '../interfaces/hierarchy-release-create.interface';
import { HierarchyReleaseAssociateCreateModel } from './hierarchy-release-associate-create.model';

export class HierarchyReleaseCreateModel implements IHierarchyReleaseCreate{

  constructor(
    public description: string,
    public countryCode: string,
    public societyCode: string,
    public channelCode: string,
    public regionCode: string,
    public cediCode: string,
    public macrosegmentCode: string,
    public businessTypologyCode: string,
    public processDomainId: number,
    public subprocessDomainId: number,
    public hierarchyReleaseAssociateCreateModel: HierarchyReleaseAssociateCreateModel[]
  ) {}

}
