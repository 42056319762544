import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApprovalRanksService } from './approval-ranks.service';
import { ApplicationConstants } from '@app/app.constants';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { LiberationHierarchyService } from '../../liberation.hierarchy.service';
import { IApprovalRank } from './interfaces/approval-rank.interface';
import { IApprovalRankOperation } from './interfaces/operation-rank.interface';
import { ApprovalViewModel } from './models/approval-view.model';
import { IApprovalView } from './interfaces/approval-view.interfaces';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ProfileService } from '@app/@modules/administration/profile/profile.service';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { IProfile } from '@app/@modules/administration/profile/interfaces/profile.interface';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { forkJoin } from 'rxjs';
import { DomainService } from '@app/@shared/services/domain.service';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { Operator } from '@app/@shared/enums/operator.enum';
import { OperationRankModel } from './models/operation-rank.model';
import { ApprovalRankConfigurationModel } from './models/approval-rank.model';
import { ApprovalRankMessage } from '@app/@modules/promotionalactivity/approval-ranks/messages/approval-rank.message';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';

@Component({
  selector: 'app-approval-ranks',
  templateUrl: './approval-ranks.component.html',
  styleUrls: ['./approval-ranks.component.css']
})
export class ApprovalRanksComponent implements OnInit {
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly router: Router;
  private readonly domainService: DomainService;
  private readonly profileService: ProfileService;
  private readonly approvalRankService: ApprovalRanksService;
  private readonly hierarchyService: LiberationHierarchyService;

  public isDialog = false;
  public objectApprovalRankList : IApprovalView[]=[];
  public objectApprovalLevel :IApprovalRank[]=[];
  public objectApprovalOperation: IApprovalRankOperation[] = [];
  public objectProfileList: ICombo[];
  public objectApprovalLevelOption :ICombo[];
  public objectColorLevel: ICombo[];
  public objectApprovalLevelOptionCopy :ICombo[];
  public rolePermission: any = RolePermissionEnum;

  public objectOperatorListStart: ICombo[];
  public objectOperatorListEnd: ICombo[];

  public idHierarchy:number;
  public result = true;
  public isStartCero = true;
  public levelAproval: ICombo[];
  public levelValue: number[]=[];


  readonly priceMask = createNumberMask({
    allowDecimal: true,
    decimalSymbol: '.',
    integerLimit: 12,
    prefix: '',
    thousandsSeparatorSymbol: ',',
    allowNegative  : true
  });

  constructor(
    spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router,
    domainService: DomainService,
    profileService: ProfileService,
    approvalRankService: ApprovalRanksService,
    hierarchyService: LiberationHierarchyService,
  ) {
      this.spinner = spinner;
      this.messageService = messageService;
      this.router = router;
      this.domainService = domainService;
      this.profileService = profileService;
      this.approvalRankService = approvalRankService;
      this.hierarchyService = hierarchyService;
   }

  ngOnInit(): void {
    this.idHierarchy = this.hierarchyService.getHierarchyId();
    this.forkJoinData();
  }

  forkJoinData(): void {
    this.spinner.show();
    if(this.idHierarchy !== undefined){
    const operatorResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Operator);
    const profileResponse = this.profileService.getAllProfileApprovalLevel();

    forkJoin([
      operatorResponse,
      profileResponse
    ])
      .subscribe(
        (result) => {
          const objOperatorResponse: IResponseService<IDomain[]> = result[0];
          const objProfileResponse: IResponseService<IProfile[]> = result[1];


          if (objProfileResponse.data.length > 0) {
            this.objectOperatorListStart = objOperatorResponse.data.map((item) => {
              return {
                label: item.description,
                value: item.code
              };
            });
          }
          else {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            return;
          }

          this.objectOperatorListEnd = this.objectOperatorListStart.filter((x) => x.value === Operator.LessThan || x.value === Operator.LessThanEqual);
          this.objectOperatorListStart = this.objectOperatorListStart.filter((x) => x.value !== Operator.LessThan && x.value !== Operator.LessThanEqual);
          this.getAllApprovalRank();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
    }else{
      this.router.navigate(['auth/liberation-hierarchy']);
    }
  }

  getAllApprovalRank(){
    this.spinner.show();
    this.approvalRankService.getAllApprovalLevel().subscribe(
    (response) => {
      if (response.data) {

        this.objectApprovalLevel = response.data.filter((x) => x.level > 0);
        this.objectApprovalLevelOption = this.objectApprovalLevel.map((x) => {
            return{
              label: x.description,
              value: x.id,
              order: x.level.toString()
            };
        });
        this.getAllApprovalLevel();
        this.spinner.hide();
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  getAllApprovalLevel(){
    this.spinner.show();
    this.approvalRankService.getAllOperationApprovalLevel(this.idHierarchy).subscribe(
      (response) => {
        if (response.data) {
          this.objectApprovalRankList = response.data;
          this.addApprovalLevelCombo();
          this.approvalRankService.setLevelApproval(this.levelAproval);
          this.getValueLevel();
          this.getLevelOrder();
          this.spinner.hide();
          }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
     );
    }


    getLevelOrder(){
      this.objectApprovalLevel.forEach((x) => {
        this.objectApprovalRankList.forEach((i) => {
          if(x.id === i.hierarchyApprovalLevelId){
            i.orderlevel = x.level;
          }
        });
      });
    }

   getValueLevel(){
      this.levelValue = [];
      if(this.objectApprovalRankList.length > 0){
        this.objectApprovalRankList.forEach((x) => {
          this.levelValue.push(x.hierarchyApprovalLevelId);
        });
      }
    }

  openDialog(){
    this.isDialog = true;
  }


  saveApprovalRank(){
    const isCompletField = this.validateData();
    const isCompletValue = this.validationValue();
    const isCompletLevel = this.validityDescriptionLevelApproval();
    let approvalRank : OperationRankModel;
    const auxApproval: OperationRankModel[] = [];
    this.addApprovalLevelCombo();

    if(isCompletField && isCompletValue && isCompletLevel){
      this.objectApprovalRankList.forEach((x) => {
        approvalRank = new OperationRankModel(
          x.edit === true ? 0 : x.idOperation,
          x.hierarchyConfigurationId,
          x.hierarchyApprovalLevelId,
          x.operatorStart,
          x.rankStart,
          x.operatorEnd,
          x.rankEnd
        );
        auxApproval.push(approvalRank);
    });

    const rank: ApprovalRankConfigurationModel = new ApprovalRankConfigurationModel(
      this.idHierarchy,
      auxApproval
      );


    this.approvalRankService.postApprovalRank(rank).subscribe(
      (response) => {
        this.spinner.hide();
        if(response.status){
          this.approvalRankService.setLevelApproval(this.levelAproval);
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
          this.isDialog = false;
          this.getValueLevel();
        }else{
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
   }

  }

  addApprovalLevelCombo(){
    this.levelAproval = this.objectApprovalRankList.map((obj) => {
      const description = this.objectApprovalLevelOption.find((x) => x.value === obj.hierarchyApprovalLevelId);
     if(description !== undefined){
      return{
        label: description.label,
        value: obj.hierarchyApprovalLevelId,
        order : description.order
      };
     }

    });
  }

  validityDescriptionLevelApproval(): boolean{
    let result = true;
    const ultimo = this.objectApprovalRankList.length;
    for (let i = 0; i < this.objectApprovalRankList.length; i++) {
      const auxSart = this.objectApprovalRankList[i].orderlevel;
      const auxEnd = this.objectApprovalRankList[ultimo - 1].orderlevel;

      if(ultimo > 0 && auxSart > auxEnd){
        result = false;
        const language: string = sessionStorage.getItem('language');
        this.showToast(SweetAlert.iconError,
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? 'Nivel de aprobación inferior al anterior registrado' : 'Approval level lower than the previous one recorded');
      }else{

      }
    }

    return result;
  }


  validationValue(): boolean{
    let result = true;
    const aux = this.objectApprovalRankList.length - 1;

    for (let i = aux; i >= 0; i--) {
      for (let j = i-1; j >= 0; j--) {
        const auxSartI = this.objectApprovalRankList[i].rankStart;
        const auxEndI = this.objectApprovalRankList[i].rankEnd;
        const auxSartJ = this.objectApprovalRankList[j].rankStart;

        //Validacion del 0
        if(this.objectApprovalRankList[aux].rankStart !== 0){
          result = false;
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconError, SweetAlert.titleAlertError,
            language === 'es-ES' ? 'El valor inicial del ultimo nivel de aprobación debe ser 0'
            : 'The initial value of the last approval level must be 0');
        }

        //Validacion solapar
        if(auxSartI >= auxSartJ || auxEndI > auxSartJ){
          result = false;
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconError, SweetAlert.titleAlertError,
            language === 'es-ES' ? 'Los rangos se estan solapando' : 'The ranges are overlapping');
        }
      }
    }
    return result;
  }

  validationRankStart($event: any, rank: IApprovalView){
    rank.rankStart = Number($event.target.value.replace(/,/g,''));
    // let result = this.validateData();
    // return result;
  }

  validationRankEnd($event: any, rank: IApprovalView){
    rank.rankEnd = Number($event.target.value.replace(/,/g,''));
    // let result = this.validateData();
    // return result;
  }

  validateData(): boolean {
    let result = true;
    let messages = '';
    const position = this.objectApprovalRankList.length -1;
    let cont = 0;
    this.objectApprovalRankList.forEach((x) => {
      cont += 1;
      const countNumbers = this.objectApprovalRankList.filter((z) => z.hierarchyApprovalLevelId === x.hierarchyApprovalLevelId).length;
      if(result && countNumbers > 1) {
        messages = ApprovalRankMessage.APPROVAL_LEVEL_REPEAT_ERROR;
        result = false;
      }
      if(result && x.description === '') {
        messages = ApprovalRankMessage.NOT_APPROVAL_LEVEL;
        result = false;
      }
      if(result && x.rankStart == null){
        messages = ApprovalRankMessage.NOT_VALUE_START;
        result = false;
      }
      if(result && x.rankEnd == null){
        messages = ApprovalRankMessage.NOT_VALUE_END;
        result = false;
      }
      if(result && x.operatorEnd === '' || x.operatorEnd == null){
        messages = ApprovalRankMessage.NOT_OPERATOR_START;
        result = false;
      }
      if(result && x.operatorStart === '' || x.operatorStart == null){
        messages = ApprovalRankMessage.NOT_OPERATOR_END;
        result = false;
      }
      if(result && x.rankEnd <= x.rankStart){
        messages = ApprovalRankMessage.NOT_ACCEPTED_VALUE;
        result = false;
      }
    });
    if(!result)
      this.showToast(SweetAlert.iconError, SweetAlert.titleAlertError, messages);
    return result;
  }

  validityNewLevel(): boolean {
    const cantlevel = this.objectApprovalLevelOption.length;
    const cantAddLevel = this.objectApprovalRankList.length;
    let level;
    this.objectApprovalRankList.forEach((x) => {
      if(x.hierarchyApprovalLevelId === 0 || x.operatorEnd === '' || x.operatorStart === '' || x.rankEnd == null || x.rankStart == null){
        level = true;
      }else if(cantlevel === cantAddLevel){
        level = true;
      }else{
        level = false;
      }
    });
    return level;
  }

  updateApprovallevel(rank: IApprovalView){
    const description = this.objectApprovalLevelOption.find((x) => x.value === rank.hierarchyApprovalLevelId);
    if(description !== undefined){
      rank.description = description.label;
    }

    this.objectApprovalLevel.forEach((x) => {
      if(rank.hierarchyApprovalLevelId === x.id){
        rank.orderlevel = x.level;
      }
    });
  }

  //Agregar nuevo nivel
  addApprovalRankLevel(): void {
    const isNew = this.validityNewLevel();
    if(!isNew){
      const auxModel: ApprovalViewModel = new ApprovalViewModel(0,'',0,0,this.idHierarchy,0,'',0,'',0,0,true);
      this.objectApprovalRankList.push(auxModel);

    }else{
      const language: string = sessionStorage.getItem('language');
      this.showToast(SweetAlert.iconWarningToast, SweetAlert.titleAlertError,
        language === 'es-ES' ?  SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate);
    }
  }

  deleteApprovalRankLevel(rank: IApprovalView){
    const index = this.objectApprovalRankList.findIndex((x) => x.hierarchyApprovalLevelId === rank.hierarchyApprovalLevelId);
    this.objectApprovalRankList.splice(index, 1);

    const indexLevel = this.levelValue.findIndex((l) => l === rank.hierarchyApprovalLevelId);
    this.levelValue.splice(indexLevel,1);
  }

  returnLiberation(){
    this.router.navigate(['auth/liberation-hierarchy']);
  }

  saveOrganization(){
    const language: string = sessionStorage.getItem('language');
    this.showToast(SweetAlert.iconSuccess,
      language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
      language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
    this.router.navigate(['auth/liberation-hierarchy']);
  }

  cancelApprovalRank(){
    this.isDialog = false;
    this.getAllApprovalRank();
  }

  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number
    });
  }

}
