<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"
            ><i class="uil uil-estate" aria-hidden="true"></i
            >{{ "Home" | translate }}</a
          >
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/solicitude'">
            {{ "SolicitudComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "SolicitudComponent.Create.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div *ngIf="!isVisibleAssingClient" class="container">
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">
          {{ "SolicitudComponent.Create.SolicitudeCreation" | translate }}
        </h1>
      </div>
      <div class="col-md-6 text-right" style="padding: 0">
        <div class="form-group">
          <label>{{ "SolicitudComponent.TypeSolicitude" | translate }}</label>
          <div class="row estadosol">
            <p class="creacion" style="opacity: 99">
              <i class="uil uil-okta" aria-hidden="true"></i>
              {{ "SolicitudComponent.Create.SolicitudeCreation" | translate }}
            </p>
            <p class="retiro" style="opacity: 0.5">
              <i class="uil uil-okta" aria-hidden="true"></i>
              {{ "SolicitudComponent.Withdrawal.TypeSolicitude" | translate }}
            </p>
            <p class="modificacion" style="opacity: 99" *ngIf="isEdit">
              <i class="uil uil-okta" aria-hidden="true"></i>
              {{ "SolicitudComponent.TypeSolicitudeModification" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <p-accordion>
      <p-accordionTab
        header="{{ 'SolicitudComponent.Create.ProductFilter' | translate }}"
        [selected]="activeAccordion"
      >
        <app-hierarchy-advanced-filters
          [isMultiFilter]="true"
          [priceless]="true"
          (eventClickFilters)="eventClickFilters($event)"
        >
        </app-hierarchy-advanced-filters>
      </p-accordionTab>
    </p-accordion>
    <div class="row mt-5"></div>
    <app-upload-excel
      [codeCreation]="codeCreationSession"
      [idSolicitude]="solicitudeService.getSolicitudeId()"
      [processType]="solicitudeType"
      [typeOfLoad]="typeOfLoadFileExcel.Solicitude"
      (eventEmitterId)="refreshDetailByFile($event)"
    ></app-upload-excel>

    <div class="row mt-5"></div>
    <div>
      <app-price-certificate #priceCertificate></app-price-certificate>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <h2 class="pstn_subtitle">
          {{ "SolicitudComponent.Create.PriceUpdate" | translate }}
        </h2>
      </div>
      <div class="col-md-12">
        <h6 class="pstn_subtitle">
          {{ "SolicitudComponent.Create.CurrentPriceNewPrice" | translate }}
        </h6>
      </div>
      <div class="pstn_container container-fluid">
        <div class="row justify-content-between">
          <div class="align-self-center">
            <h6>
              {{ "SolicitudComponent.Withdrawal.DeleteBtn" | translate }}
              <button
                id="btnDelete"
                type="button"
                class="delete btn btn-secundary-delete"
                (click)="delete()"
                *ngIf="rolePermission.delete | rolePermission"
              > 
                <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
              </button>
            </h6>
          </div>
        </div>

        <p-table
          #dt
          dataKey="id"
          editMode="row"
          name="gridCreationSolicitude"
          [value]="solicitude"
          [paginator]="true"
          [rows]="10"
          [scrollable]="true"
          paginatorPosition="bottom"
          [(selection)]="selectSolicitude"
          currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
          [rowsPerPageOptions]="[10, 25, 50, 100]"
          [globalFilterFields]="['materialCode', 'product']"
          styleClass="p-datatable-customers"
          scrollHeight="800px"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'SearchBy' | translate }}"
                />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 50px" role="columnheader">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th style="width: 80px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.Status" | translate }}
                </div>
              </th>
              <th pSortableColumn="materialCode" style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.Code" | translate }}
                </div>
              </th>
              <th pSortableColumn="product" style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.Product" | translate }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.ProductType" | translate }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{
                    "SolicitudComponent.Create.CurrentWithoutTax" | translate
                  }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.CurrentPSP" | translate }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.NoNewTax" | translate }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.AdjustmentMoney" | translate }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{
                    "SolicitudComponent.Create.PercentageAdjustment" | translate
                  }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.NewPSP" | translate }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.InitialDate" | translate }}
                </div>
              </th>
              <th style="width: 180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.FinalDate" | translate }}
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-solicitud
            let-editing="editing"
            let-rowIndex="rowIndex"
          >
            <tr>
              <td style="width: 50px">
                <p-tableCheckbox [value]="solicitud"></p-tableCheckbox>
              </td>
              <td style="width: 80px">
                <!-- <span [ngClass]="solicitud.isApproval === null ? solicitud.isPending === false ? 'color-notificacion-5' : solicitud.isPending ? 'color-notificacion-1' : 'Ninguno' : solicitud.isPending === false ? 'color-notificacion-5' : solicitud.isApproval ? 'color-notificacion-1' : 'Ninguno'"></span>  -->
                <span
                  [ngClass]="
                    solicitud.isPending === false
                      ? 'color-notificacion-5'
                      : solicitud.isPending === true
                      ? 'color-notificacion-1'
                      : 'Ninguno'
                  "
                ></span>
              </td>
              <td style="width: 180px;">
                {{ materialCodeZeroLess(solicitud.materialCode) }}
              </td>
              <td style="width: 180px">
                {{ solicitud.product }}
              </td>
              <td style="width: 180px">
                {{ solicitud.zofeDescription }}
              </td>
              <td style="width: 180px">
                <div *ngIf="solicitud.currentListPrice >= 1">
                  {{ solicitud.currentListPrice | currency: "USD" }}
                </div>
                <div *ngIf="solicitud.currentListPrice === 0">
                  {{ "SolicitudComponent.Priceless" | translate }}
                </div>
              </td>
              <td style="width: 180px">
                {{ solicitud.currentPsp | currency: "USD" }}
              </td>
              <!-- $Sin impuesto nuevo -->
              <td style="width: 180px">
                <!-- <p-inputNumber #e [inputStyle]="{'width':'150px'}"
                  [(ngModel)]="solicitud.newPriceWithOutTax"
                  (onBlur)="onChangeNewPriceWithOutTax(solicitud)">
                </p-inputNumber> -->

                <input
                  #newPriceWithOutTax
                  id="newPriceWithOutTax{{ rowIndex }}"
                  name="newPriceWithOutTax{{ rowIndex }}"
                  [style]="{ width: '150px' }"
                  [ngModel]="solicitud.newPriceWithOutTax | number: '1.2'"
                  (change)="onChangeNewPriceWithOutTax(solicitud, $event)"
                  pInputText
                  tabindex="{{ rowIndex + 1 }}"
                  ng-pattern="/^[0-9]+\.([0-9]{1,2})$/"
                  placeholder="0.00"
                  onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46)"
                  (keyup)="validatePriceWithOutTax($event, solicitud)"
                  [textMask]="{ mask: priceMask }"
                  (focus)="selectAllContent($event)"
                />
              </td>
              <!-- $Ajuste -->
              <td style="width: 180px">
                <!-- <p-inputNumber [inputStyle]="{'width':'150px'}"
                  [(ngModel)]="solicitud.priceAdjustment" (ngModelChange)="onChangePriceAdjustment(solicitud)">
                </p-inputNumber> -->

                <input
                  #priceAdjustment
                  id="priceAdjustment{{ rowIndex }}"
                  name="priceAdjustment{{ rowIndex }}"
                  [style]="{ width: '150px' }"
                  [ngModel]="solicitud.priceAdjustment | number: '1.2'"
                  (change)="onChangePriceAdjustment(solicitud, $event)"
                  pInputText
                  tabindex="{{ rowIndex + 1 }}"
                  ng-pattern="/^[0-9]+\.([0-9]{1,2})$/"
                  placeholder="0.00"
                  onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) 
                                      || event.charCode == 45 || event.charCode == 46)"
                  (keyup)="validatePriceAdjustment($event, solicitud)"
                  (focus)="selectAllContent($event)"
                  [textMask]="{ mask: priceMask }"
                />
              </td>
              <!-- %Ajuste -->
              <td style="width: 180px">
                <input
                  #percentage
                  id="percentage{{ rowIndex }}"
                  name="percentage{{ rowIndex }}"
                  [style]="{ width: '150px' }"
                  (change)="onChangePercentage(solicitud, $event)"
                  [ngModel]="solicitud.percentage | number: '1.2'"
                  ng-pattern="/^[0-9]+\.([0-9]{1,2})$/"
                  step="0.01"
                  pInputText
                  (keyup)="validityValue($event)"
                  tabindex="{{ rowIndex + 1 }}"
                  placeholder="0.00"
                  onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) 
                                      || event.charCode == 45 || event.charCode == 46 )"
                  (focus)="selectAllContent($event)"
                  [textMask]="{ mask: priceMask }"
                />
              </td>
              <!-- $ PSP nuevo -->
              <td style="width: 180px">
                <!-- <p-inputNumber [inputStyle]="{'width':'150px'}"
                  [(ngModel)]="solicitud.newPsp" (ngModelChange)="onChangeNewPsp(solicitud)">
                </p-inputNumber> -->

                <input
                  [style]="{ width: '150px' }"
                  [(ngModel)]="solicitud.newPsp"
                  (change)="onChangeNewPsp(solicitud, $event)"
                  pInputText
                  [textMask]="{ mask: priceMask }"
                  tabindex="{{ rowIndex + 1 }}"
                  onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46)"
                  (focus)="selectAllContent($event)"
                />
              </td>

              <td style="width: 180px">
                <p-calendar
                  #cal2
                  [(ngModel)]="solicitud.effectiveDateStart"
                  (ngModelChange)="onChangeDateStart(solicitud)"
                  dateFormat="dd/mm/yy"
                  [style]="{ width: '150px' }"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [showButtonBar]="true"
                  [showOtherMonths]="false"
                  [yearRange]="getYearRange()"
                  [required]="true"
                  [minDate]="tomorrow"
                  [touchUI]="true"
                  [readonlyInput]="true"
                  tabindex="{{ rowIndex + 1 }}"
                >
                  <p-header class="p-2">
                    <i
                      id="btnClose"
                      style="border: none"
                      class="uil uil-times enviar"
                      (click)="cal2.hideOverlay()"
                      aria-hidden="true"
                    ></i>
                  </p-header>
                </p-calendar>
              </td>
              <td style="width: 180px">
                <p-calendar
                  #cal3
                  [(ngModel)]="solicitud.effectiveDateEnd"
                  (ngModelChange)="onChangeDateEnd(solicitud)"
                  dateFormat="dd/mm/yy"
                  [style]="{ width: '150px' }"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [showButtonBar]="true"
                  [showOtherMonths]="false"
                  [yearRange]="getYearRange()"
                  [required]="true"
                  [minDate]="tomorrow"
                  [touchUI]="true"
                  (onShow)="onShowDate(solicitud)"
                  (onTodayClick)="onTodayClick(solicitud)"
                  (onClearClick)="onClearClick(solicitud)"
                  [readonlyInput]="true"
                  tabindex="{{ rowIndex + 1 }}"
                >
                  <p-header class="p-2">
                    <i
                      id="btnClose"
                      style="border: none"
                      class="uil uil-times enviar"
                      (click)="cal3.hideOverlay()"
                      aria-hidden="true"
                    ></i>
                  </p-header>
                </p-calendar>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Buttons -->
        <div class="row mt-3 pb-3 d-flex justify-content-end">
          <div class="col-lg-4 md-3 mt-3">
            <button
              id="btnCreate"
              pButton
              pRipple
              type="button"
              label="{{ 'SolicitudComponent.Create.Next' | translate }}"
              class="p-button-rounded pstn_primario w-100"
              (click)="next()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isVisibleAssingClient">
    <app-assing-clients
      (eventClickBack)="back()"
      (eventClickSendData)="eventClickGetDataSave($event)"
      (eventClickSendDataEdit)="eventClickGetDataSave($event)"
      [isProcess]="isProcess"
      [isCountryRequired]="true"
      [filtersEdit]="filterClientJsonEdit"
      [clientsEdit]="solicitudeCustomer"
      [showFields]="showFields"
      [validateFilter]="false">
    </app-assing-clients>
  </div>
</section>

<p-toast></p-toast>
