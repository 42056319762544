import { IDataRange } from '@components/commercial-activity-footer/interfaces/data-range.interface';
import { ICommercialActivityCustomerJson } from '../../interfaces/commercial-activity-custommer-json.interface';
import { ICommercialActivitySector } from '../../interfaces/commercial-activity-sector.interface';
import { ICustomer } from '../../promotional-activities-create/interfaces/customer-list.interface';
import { IChannel } from '../interfaces/channel-interface';
import { ICommercialActivityRappel } from '../interfaces/commercial-activity-rappel-interface';

import { IProductGroupList } from '../interfaces/product-group-list.interface';
import { IRangesRappel } from '../interfaces/ranges-rappel.interfaces';
import { ICommercialActivityHandler } from '@app/@components/handling-product-filter/interfaces/commercial-activity-handler.interface';
import { ICommercialActivityHandlerPeriod } from '@app/@components/elements/calendar-period/interfaces/handler-period.interface';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';


export class CommercialActivityRappelModel implements ICommercialActivityRappel {

    constructor(
        public typeCommercialActivityDomainId?: number,
        public typePromotionalActivityDomainId?: number,
        public statusDomainId?: number,
        public description?: string,
        public startTime?: string,
        public endTime?: string,
        public orderLimit?: number,
        public periodLimit?: number,
        public numberDays?: number,
        public id?: number,
        public consideration?: number,
        public periodSettlement?: number,
        public typeMeasure?: number,
        public purchaseBases?: IProductGroupList[],
        public purchaseBasePromotions?: IProductGroupList[],
        public commercialActivityDateRanges?: IDataRange[],
        public commercialActivityChannels?: IChannel[],
        public commercialActivityRange?: IRangesRappel[],
        public isCalculate?: boolean,
				public commercialActivitySector?: ICommercialActivitySector[],
        public totalClientsFilter?: number,
				public uidCalculateProcess?: string,
				public filterClientJson?: string,
				public commercialActivityCustomers?: ICustomer[],
				public commercialActivityCustomerJson?: ICommercialActivityCustomerJson[],
        public isHandler?: boolean,
        public commercialActivityHandler?: ICommercialActivityHandler,
        public commercialActivityHandlerCurrentMonth?: ICommercialActivityHandler,
        public commercialActivityHandlerPeriod?: ICommercialActivityHandlerPeriod[],
        public commercialActivityObservation?: ICommercialActivityDocument[]
    ) { }

}
