import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICommercialActivityHandler } from './interfaces/commercial-activity-handler.interface';
import { CommercialActivityHandlerModel } from './models/commercial-activity-handler.model';
import { ActivityHandlerModel } from './models/activity-handler.model';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';

@Injectable({
  providedIn: 'root'
})
export class HandlingProductFilterService {

  private readonly httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
   }

  public postActivityHandler(body: ActivityHandlerModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
    this.httpService
    .post(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity_handler`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public deleteProductMaterialHandler(id: string, typeController : TypeCoreMaster): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .delete(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/${typeController}_detail/logic/${id}`)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

}
