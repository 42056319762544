import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RouteGuard } from '../../@core/authentication/route.guard';
import { ApprovalRankCreateEditComponent } from './approval-ranks/approval-rank-create-edit/approval-rank-create-edit.component';
import { ApprovalRankComponent } from './approval-ranks/approval-rank.component';
import { CommercialActivityNegociationExclusionComponent } from './commercial-activity-negociation-exclusion/commercial-activity-negociation-exclusion.component';
import { ExclusionsBySkuCreateEditComponent } from './exclusions-by-sku/exclusions-by-sku-create-edit/exclusions-by-sku-create-edit.component';
import { ExclusionsBySkuComponent } from './exclusions-by-sku/exclusions-by-sku.componente';
import { ExclusionsCreateComponent } from './exclusions/exclusions-create/exclusions-create.component';
import { ExclusionsManageComponent } from './exclusions/exclusions-manage/exclusions-manage.component';
import { ExclusionsComponent } from './exclusions/exclusions.component';
import { AssociateRankComponent } from './hierarchy-release/associate-rank/associate-rank.component';
import { AssociateComponent } from './hierarchy-release/associate/associate.component';
import { CreateComponent } from './hierarchy-release/create/create.component';
import { HierarchyReleaseComponent } from './hierarchy-release/hierarchy-release.component';
import { ApprovalRanksComponent } from './liberation-hierarchy/hierarchy-map/approval-ranks/approval-ranks.component';
import { LiberationHierarchyComponent } from './liberation-hierarchy/liberation-hierarchy.component';
import { CommercialActivityDiscountComponent } from './manage-promotional-activities/commercial-activity-discount/commercial-activity-discount.component';
import { CommercialActivityEventsComponent } from './manage-promotional-activities/commercial-activity-events/commercial-activity-events.component';
import { CommercialActivityLoyaltyComponent } from './manage-promotional-activities/commercial-activity-loyalty/commercial-activity-loyalty.component';
import { CommercialActivityRappelComponent } from './manage-promotional-activities/commercial-activity-rappel/commercial-activity-rappel.component';
import { CommercialActivityRedemptionComponent } from './manage-promotional-activities/commercial-activity-redemption/commercial-activity-redemption.component';
import { CommercialActivityZeroValueProductComponent } from './manage-promotional-activities/commercial-activity-zero-value-product/commercial-activity-zero-value-product.component';
import { ManagePromotionalActivitiesComponent } from './manage-promotional-activities/manage-promotional-activities.component';
import { PromotionalActivitiesCreateComponent } from './manage-promotional-activities/promotional-activities-create/promotional-activities-create.component';
import { ValidityPeriodComponent } from './validity-period/validity-period.component';

const routes: Routes = [
  { path: 'approval-rank', component: ApprovalRankComponent, canActivate: [MsalGuard, RouteGuard] },
  { path: 'approval-rank/create-edit', component: ApprovalRankCreateEditComponent, canActivate: [MsalGuard, RouteGuard] },
  { path: 'hierarchy-release', component: HierarchyReleaseComponent, canActivate: [MsalGuard, RouteGuard] },
  { path: 'hierarchy-release/create', component: CreateComponent, canActivate: [MsalGuard] },
  { path: 'hierarchy-release/associate-level', component: AssociateComponent, canActivate: [MsalGuard] },
  { path: 'hierarchy-release/associate-rank', component: AssociateRankComponent, canActivate: [MsalGuard] },
  { path: 'liberation-hierarchy', component: LiberationHierarchyComponent, canActivate: [MsalGuard] },
  { path: 'liberation-hierarchy/approval-ranks', component: ApprovalRanksComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities', component: ManagePromotionalActivitiesComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities/create', component: PromotionalActivitiesCreateComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities/discount', component: CommercialActivityDiscountComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities/rappel', component: CommercialActivityRappelComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities/redemption', component: CommercialActivityRedemptionComponent, canActivate: [MsalGuard] },
  { path: 'validity-period', component: ValidityPeriodComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities/loyalty', component: CommercialActivityLoyaltyComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities/events', component: CommercialActivityEventsComponent, canActivate: [MsalGuard] },
  { path: 'manage-promotional-activities/zero-value-product', component: CommercialActivityZeroValueProductComponent, canActivate: [MsalGuard] },
  { path: 'exclusion/manage', component: ExclusionsManageComponent },
  { path: 'exclusion/create', component: ExclusionsCreateComponent, canActivate: [MsalGuard] },
  { path: 'exclusion', component: ExclusionsComponent },
  { path: 'exclusions-by-negociation', component: CommercialActivityNegociationExclusionComponent, canActivate: [MsalGuard] },
  { path: 'exclusion-by-sku', component: ExclusionsBySkuComponent },
  { path: 'exclusion-by-sku/create-edit', component: ExclusionsBySkuCreateEditComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromotionalactivityRoutingModule { }
