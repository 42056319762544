import { DomainTypeCore } from './../../../@shared/enums/domain-type-core.enums';
import { Component, OnInit, OnDestroy, Input, EventEmitter, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManagePromotionalActivitiesService } from './manage-promotional-activities.service';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertResult } from 'sweetalert2';
import { Router } from '@angular/router';
import { IManagePromotionalActivitiesJson } from './interfaces/manage-promotional-activities-Json.interface';
import { PaginationModel } from '../../../@shared/model/pagination.model';
import { ICommercialActivityHistory } from './interfaces/commercial-activity-history.interface';
import { PaginationHistoryModel } from '@app/@shared/model/paginationHistory.model';
import { Util } from '@app/@shared/util';
import { SweetAlert } from '../../../@shared/enums/sweetalert.enum';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { ApplicationConstants } from '@app/app.constants';
import { ManagePromotionalActivitiesMessage } from './messages/manage-promotional-activities-messages.message';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainService } from '@app/@shared/services/domain.service';
import { CommercialActivityCopyModel } from './models/commercial-activity-Copy.model';
import { CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';
import { ICommercialActivity } from './interfaces/commercial-activity.interface';
import { PaginationDateModel } from '@app/@shared/model/paginationDate.model';
import { RegexCommon } from '@app/@shared/enums/regex.enum';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { CommercialActivityInterruptModel } from './models/commercial-activity-interrupt.model';
import { CommercialActivityStatusModel } from './models/commercial-activity-status.model';
import { ActivityCopyModel } from './models/activity-copy.model';
import { CommercialActivityCalculateService } from '@app/@components/commercial-activity-calculate/commercial-activity-calculate.service';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { IConfigurationUserAccessManagement } from '@app/@components/advanced-client-filter/interfaces/configuration-user-access-management.interface';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';
import { SharedAdvancedFilterComponent } from '@app/@components/shared-advanced-filter/shared-advanced-filter.component';
import { IAdvancedFilter, IGeneralAdvancedFilter } from '@app/@components/shared-advanced-filter/interfaces/general-advanced-filter.interface';
import { filter } from 'rxjs/operators';
import { SharedAdvancedFilterService } from '@app/@components/shared-advanced-filter/shared-advanced-filter.service';
import { IFieldsComponentGeneralFilter } from '@app/@shared/interfaces/fields-component-general-filter.interface';
import { FieldsComponentGeneralFilter } from '@app/@shared/model/fields-component-general-filter.model';
import { IFieldsComponentClientFilter } from '@app/@shared/interfaces/fields-component-client-filter.interface';
import { FieldsComponentClientFilter } from '@app/@shared/model/fields-component-client-filter.model';

@Component({
  selector: 'app-manage-promotional-activities',
  templateUrl: './manage-promotional-activities.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
  styles: [`
      @media screen and (max-width: 960px) {
          :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
              display: flex;
          }

          :host ::ng-deep .p-dialog {
            min-width: 6rem;
      }
      }
  `],
  providers: [MessageService]
})
export class ManagePromotionalActivitiesComponent implements OnInit, OnDestroy {
  @ViewChild(SharedAdvancedFilterComponent) sharedAdvancedFilter : SharedAdvancedFilterComponent;

  private readonly fb: FormBuilder;
  private readonly fbInterruption: FormBuilder;
  private readonly spinner: NgxSpinnerService;
  private readonly managePromotionalActivitiesService: ManagePromotionalActivitiesService;
  private readonly commActivityCalculateService: CommercialActivityCalculateService;
  private readonly router: Router;
  private readonly messageService: MessageService;
  private readonly primengConfig: PrimeNGConfig;
  private readonly domainService: DomainService;
  private readonly translate: TranslateService;
  private readonly i18nService: I18nService;

  public pageTitle = ManagePromotionalActivitiesMessage.TITLE;
  @Input() activitiesListAdvancedFilter: EventEmitter<any> = new EventEmitter();

  public formBusinessActivityManagement: FormGroup;
  public formCommercialActivity: FormGroup;
  public formCommercialActivityInterrupt: FormGroup;
  public isCreate = true;
  public objectPromotionalActivity: ICombo[];
  public type = '';
  public objectList: IManagePromotionalActivitiesJson[] = [];
  public objectListCommercialActivities: ICommercialActivity[];
  public objectListCommercialActivitiesAdvanced: IManagePromotionalActivitiesJson[];
  public objectListCommercialActivityHistory: ICommercialActivityHistory[];
  public displayModal: boolean;
  public displayModalInterrupt: boolean;
  public selectObject: IManagePromotionalActivitiesJson[] = [];
  public commercialActivityStatusEnum: any;
  public rolePermission: any = RolePermissionEnum;
  public commercialActivityTypeEnum: any = CommercialActivitySubprocess;
	public timer: any;
  public tomorrow: Date = new Date();
  public configurationUserAccessManagement : IConfigurationUserAccessManagement;
  public showModalCopy = false;
  public idCopy: number;
  public descriptionActivity: string;
  public objectCopy: IManagePromotionalActivitiesJson;
  public sku = 0;
  public messageCopy: boolean ;
  public page = 1;
  public rows = 10;
  public pageLinkSize = 5;
  public auxSave: IManagePromotionalActivitiesJson[] = [];
  public advanceFilter = {} as IAdvancedFilter;
  public language: string = sessionStorage.getItem('language');
  private readonly sharedAdvancedFilterService: SharedAdvancedFilterService;
  public readonly showFieldsGeneral : IFieldsComponentGeneralFilter = new FieldsComponentGeneralFilter(true,  true, true, false, false, 
                                                                                                       false, true, true, true, true, 
                                                                                                       true, true, true, false, false);
  public readonly showFieldsClient : IFieldsComponentClientFilter = new FieldsComponentClientFilter(true, true);

  constructor(
    fb: FormBuilder,
    fbInterruption: FormBuilder,
    spinner: NgxSpinnerService,
    managePromotionalActivitiesService: ManagePromotionalActivitiesService,
    commActivityCalculateService: CommercialActivityCalculateService,
    router: Router,
    messageService: MessageService,
    primengConfig: PrimeNGConfig,
    domainService: DomainService,
    translate: TranslateService,
    i18nService: I18nService,
    sharedAdvancedFilterService: SharedAdvancedFilterService,
  ) { 
    this.fb = fb;
    this.fbInterruption = fbInterruption;
    this.spinner = spinner;
    this.managePromotionalActivitiesService = managePromotionalActivitiesService;
    this.commActivityCalculateService = commActivityCalculateService;
    this.router = router;
    this.messageService = messageService;
    this.primengConfig = primengConfig;
    this.domainService = domainService;
    this.translate = translate;
    this.i18nService = i18nService;
    this.sharedAdvancedFilterService = sharedAdvancedFilterService;
  }

  async ngOnInit(): Promise<void> {
    this.advanceFilter.search = {ascendent:true, id:0, orderBy:'Id', pageNumber:1, resultsPerPage:this.rows}
    this.advanceFilter.general = {} as IGeneralAdvancedFilter;
    this.advanceFilter.general.activityCode = 0;
    this.advanceFilter.general.activityDescription = "";
    this.advanceFilter.general.activityChannels = [];
    this.advanceFilter.general.activityStatus = [];
    this.advanceFilter.general.activityTypes = [];
    this.advanceFilter.general.activitySectors = [];
    this.advanceFilter.general.activityProms = [];
    this.advanceFilter.client = [];
    this.advanceFilter.clientFilter = "";
    this.advanceFilter.materialBase = [];
    this.advanceFilter.materialBasePromotion = [];

    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.commercialActivityStatusEnum = this.getCommercialActivityStatus();
    this.initForm();
    await this.getPromotionalActivity(true);
    this.primengConfig.ripple = true;
    this.intervalToVerifyStatus();
    this.getUserConfig();
    
    await this.getAll(this.advanceFilter);
    sessionStorage.removeItem('LocalPromotionEdit');
    sessionStorage.removeItem('LocalPromotionNew');
  }

	ngOnDestroy(): void {
		clearTimeout(this.timer);
	}

  onFilterAdvancedApplied($event: any) {    
    this.objectListCommercialActivitiesAdvanced = [];
    this.objectListCommercialActivitiesAdvanced = $event;

    if (this.objectListCommercialActivitiesAdvanced)
    {
      this.objectList = [];
      this.objectList = this.objectListCommercialActivitiesAdvanced;
      this.auxSave = [];
    }
  }

  onCountListAdvancedFilter($event: any){
    this.pageLinkSize = $event;
  }

  getAdvancedFilter($event: any){
    this.advanceFilter = $event;
  }

  getCommercialActivityStatus(): typeof CommercialActivityStatus {
    return CommercialActivityStatus;
  }

  /**
   * @description this method is to get all the society.
   * @author sgonzalezj
   * @sprint 8
   */
  async getPromotionalActivity(isFirst: boolean = false): Promise<void> {
    if(this.language === this.translate.currentLang && !isFirst){
      return;
    }else{
      this.translate.use(this.i18nService.language);
      this.language = this.i18nService.language;
    }

    this.spinner.show();
    await  this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess).subscribe(
      (response) => {
        this.spinner.hide();
        this.objectPromotionalActivity = response.data.filter((f) => f.code === 'AP').map((item) => {
          return {
            label: this.translate.instant('Domain.Domain-'+item.id),
            value: item.id
          };
        });
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
   * @description this method is to users the distribution lists information.
   * @author Santiago Alberto Gonzalez
   * @sprint 6
   * @param objectValue
   */
  add() {
    this.managePromotionalActivitiesService.setDescription(this.description.value);
    this.managePromotionalActivitiesService.setTypeCommercialActivity(this.typeCommercialActivity.value);
    this.managePromotionalActivitiesService.setIsCreate(ComercialActivityEnum.create);
    if (this.typeCommercialActivity.value === CommercialActivitySubprocess.Promotions) {
      this.router.navigate(['/auth/manage-promotional-activities/create']);
    }
    if(this.typeCommercialActivity.value === CommercialActivitySubprocess.DiscountValue ||
      this.typeCommercialActivity.value === CommercialActivitySubprocess.DiscountKind ||
      this.typeCommercialActivity.value === CommercialActivitySubprocess.FinancialDiscount ||
      this.typeCommercialActivity.value === CommercialActivitySubprocess.PriceDiscount) {
      this.router.navigate(['/auth/manage-promotional-activities/discount']);
    }
    if(this.typeCommercialActivity.value === CommercialActivitySubprocess.Loyalty)
    {
      this.router.navigate(['/auth/manage-promotional-activities/loyalty']);
    }
    if(this.typeCommercialActivity.value === CommercialActivitySubprocess.Events)
    {
      this.router.navigate(['/auth/manage-promotional-activities/events']);
    }
    if(this.typeCommercialActivity.value === CommercialActivitySubprocess.ZeroValueProduct)
    {
      this.router.navigate(['/auth/manage-promotional-activities/zero-value-product']);
    }
    if(this.typeCommercialActivity.value === CommercialActivitySubprocess.Rappel)
    {
      this.router.navigate(['/auth/manage-promotional-activities/rappel']);
    }
    if(this.typeCommercialActivity.value === CommercialActivitySubprocess.Redemption)
    {
      this.router.navigate(['/auth/manage-promotional-activities/redemption']);
    }
  }

  copyActivity(ObjectDetail: IManagePromotionalActivitiesJson): void {
    this.spinner.show();
    this.idCopy = ObjectDetail.id;
    this.numberSku(this.idCopy);
    this.objectCopy = ObjectDetail;
    this.showModalCopy = true;
  }

  numberSku(id: number){
    this.sku = 0;
    this.commActivityCalculateService.getPromotionalActivityById(id)
    .subscribe(
      (response: any) => {
        if (response.status) {
          if(response.data.purchaseBasePromotions != null){
            response.data.purchaseBasePromotions.forEach((x: any) => {
              x.purchaseBasePromotionDetails.forEach((d: any) => {
                if(d.materialCode){
                  this.sku += 1;
                }
              });
            });
          }

          if(response.data.purchaseBases != null){
						response.data.purchaseBases.forEach((x: any) => {
							x.purchaseBaseDetails.forEach((d:any) => {
                if(d.materialCode){
                  this.sku += 1;
                }
              });
            });
          }

        }
      }
    );
    this.spinner.hide();
  }

  clearCopy(){
    this.descriptionActivity = '';
    this.messageCopy = false;
    this.idCopy = 0;
    this.sku = 0;
  }

  createCopy(){
    if(this.descriptionActivity !== undefined && this.descriptionActivity.trim() !== ''){
      this.messageCopy = false;
      const copy: ActivityCopyModel = new ActivityCopyModel(this.idCopy, this.descriptionActivity.trim());
      //Servicio
      this.managePromotionalActivitiesService.copyCommercialActivity(copy)
      .subscribe(
        (response) => {
          this.spinner.hide();
          if (response.status) {
            this.showModalCopy = false;
            this.cancelCopy();
            this.getAll(this.advanceFilter);
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconSuccess,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
            this.objectCopy.id = response.data;
            this.edit(this.objectCopy);
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }

        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }else{
      this.descriptionActivity = '';
      this.messageCopy = true;
    }
  }

  cancelCopy(){
    this.showModalCopy = false;
    this.clearCopy();
  }


  /**
    * @description this method is to edit AccessManagementConfiguration.
    * @author Santiago Alberto Gonzalez
    * @sprint 8
    */
  edit(ObjectDetail: IManagePromotionalActivitiesJson): void {
    this.managePromotionalActivitiesService.setCommercialActivityId(ObjectDetail.id);
    this.managePromotionalActivitiesService.setIsCreate(ComercialActivityEnum.update);
    this.managePromotionalActivitiesService.setTypeCommercialActivity(ObjectDetail.typeCommercialActivityDomainId);
    if (ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Promotions) {
      this.router.navigate(['/auth/manage-promotional-activities/create']);
    }
    else if (ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.DiscountValue ||
            ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.DiscountKind ||
            ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.FinancialDiscount ||
            ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.PriceDiscount) {
      this.router.navigate(['/auth/manage-promotional-activities/discount']);
    }
    else if (ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Loyalty)
    {
      this.router.navigate(['/auth/manage-promotional-activities/loyalty']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Events)
    {
      this.router.navigate(['/auth/manage-promotional-activities/events']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.ZeroValueProduct)
    {
      this.router.navigate(['/auth/manage-promotional-activities/zero-value-product']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Rappel)
    {
      this.router.navigate(['/auth/manage-promotional-activities/rappel']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Redemption)
    {
      this.router.navigate(['/auth/manage-promotional-activities/redemption']);
    }
    else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_EXISTS_COMMERCIAL_ACTIVITY : ManagePromotionalActivitiesMessage.NOT_EXISTS_COMMERCIAL_ACTIVITY_TRANSLATE);
    }
  }

  /**
    * @description this method is to edit Commercial Activity.
    * @author Santiago Alberto Gonzalez
    * @sprint 8
    */
  view(ObjectDetail: IManagePromotionalActivitiesJson): void {
    this.managePromotionalActivitiesService.setCommercialActivityId(ObjectDetail.id);
    this.managePromotionalActivitiesService.setIsCreate(ComercialActivityEnum.view);

    if (ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Promotions) {
      this.router.navigate(['/auth/manage-promotional-activities/create']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.DiscountValue ||
            ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.DiscountKind ||
            ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.FinancialDiscount ||
            ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.PriceDiscount)
    {
      this.router.navigate(['/auth/manage-promotional-activities/discount']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Loyalty)
    {
      this.router.navigate(['/auth/manage-promotional-activities/loyalty']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Events)
    {
      this.router.navigate(['/auth/manage-promotional-activities/events']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.ZeroValueProduct)
    {
      this.router.navigate(['/auth/manage-promotional-activities/zero-value-product']);
    }
    else if(ObjectDetail.typeCommercialActivityDomainId === CommercialActivitySubprocess.Redemption)
    {
      this.router.navigate(['/auth/manage-promotional-activities/redemption']);
    }
		else if(ObjectDetail.typeCommercialActivityDomainId  === CommercialActivitySubprocess.Rappel) {
			this.router.navigate(['/auth/manage-promotional-activities/rappel']);
		}
    else{
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ?  ManagePromotionalActivitiesMessage.NOT_EXISTS_COMMERCIAL_ACTIVITY : ManagePromotionalActivitiesMessage.NOT_EXISTS_COMMERCIAL_ACTIVITY_TRANSLATE);
    }
  }

  /**
    * @description this method is to interrupt Commercial Activity.
    * @author Priscy Antonio Reales
    * @sprint 23
    */
  interrupt(object : IManagePromotionalActivitiesJson) : void{
    this.managePromotionalActivitiesService.setCommercialActivityId(object.id);
    this.displayModalInterrupt = true;
  }

  saveInterruption() : void {
    if(this.formCommercialActivityInterrupt.valid){
      this.spinner.show();
      const interruptModel = new CommercialActivityInterruptModel
      (
        this.managePromotionalActivitiesService.getCommercialActivityId(),
        this.commercialActivityStatusEnum.Interrupt,
        this.descriptionInterruption.value,
        this.dateInterruption.value
      );
      this.managePromotionalActivitiesService.postCommercialActivityInterrupt(interruptModel)
      .subscribe(
        (response) =>{
          this.hideModalInterrupt();
          this.spinner.hide();
          if(response.status){
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.success(SweetAlert.titleAlert,
              language === 'es-ES' ? ManagePromotionalActivitiesMessage.INTERRUPT_OK : ManagePromotionalActivitiesMessage.INTERRUPT_OK_TRANSLATE);
            this.getAll(this.advanceFilter);
          }else{
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message);
          }
        },
        ()=>{
          this.spinner.hide();
        }
      );
    }else{
      this.formCommercialActivityInterrupt.markAllAsTouched();
    }
  }

  updateStatus(object : IManagePromotionalActivitiesJson, status : number){
    if(object.dataRange == null){
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.warning(SweetAlert.titleAlertWarning,
        language === 'es-ES' ? ManagePromotionalActivitiesMessage.DATARANGEMESSAGE : ManagePromotionalActivitiesMessage.DATARANGEMESSAGE_TRANSLATE);
        return;
      }else{
        Util.confirmEditStatus().then((result: SweetAlertResult) => {
          if (result.value) {
            this.spinner.show();
            const updateModel : CommercialActivityStatusModel = new CommercialActivityStatusModel(object.id, status,'');
            this.managePromotionalActivitiesService.postCommercialActivityUpdateStatus(updateModel).subscribe(
              (response) => {
                this.spinner.hide();
                if (response.status) {
                  this.getAll(this.advanceFilter);
                  const language: string = sessionStorage.getItem('language');
                  this.showToast(SweetAlert.iconWarningToast,
                    language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                    language === 'es-ES' ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate);
                } else {
                  SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
                }
              },
              (error: HttpErrorResponse) => {
                SweetAlertMessageHelpers.exception(error);
              }
            );
          }
        });
      }
  }
  /**
   * @description this method is to get all.
   * @author Santiago Alberto Gonzalez
    * @sprint 8
   */
  getAllCommercialActivities(): void {
    this.spinner.show();

    const paginationModel = new PaginationModel('Id', true, 1, 100);

    this.managePromotionalActivitiesService.getCommercialActivity(paginationModel).subscribe(
      (response) => {
        this.spinner.hide();
        this.objectListCommercialActivities = response.data;
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
   * @description this method is to get all the profile.
   * @author Santiago Alberto Gonzalez
    * @sprint 8
   */
  async getAll(filter: IAdvancedFilter): Promise<void> {
    this.spinner.show();

    await this.sharedAdvancedFilterService.getCommercialActivity(this.advanceFilter).subscribe(
      (response:any) => {
        this.spinner.hide();
        if (response.status) {
          this.objectList = response.data;
          this.pageLinkSize = response.resultCount;
          this.auxSave = [];
        } else {
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    ); 
  }

  async paginate(event: any){
    let pageAux = event.first/event.rows;
    this.page = pageAux + 1;
    this.rows = event.rows;
    this.advanceFilter.search = {ascendent:true, id:0, orderBy:'Id', pageNumber:this.page, resultsPerPage:this.rows}
    await this.getAll(this.advanceFilter);
  }

  async loadCustomers($event: any){

    this.auxSave = this.auxSave.length > 0 ? this.auxSave : this.objectList;

    if($event.globalFilter){
      let auxList = this.auxSave.find((x: any) => x.description == $event.globalFilter);
      this.objectList = [];
      this.objectList.push(auxList);
    }else{
      await this.paginate($event);
    }

    // let pageAux = $event.first/$event.rows;
    // if(this.page != pageAux + 1){
    //   await this.paginate($event);
    // }

  }

    /**
     * @description This method is to get all commercial activities for
     */
  intervalToVerifyStatus(): void {
    this.timer = setInterval(() => {
      //this.getAll();
      this.sharedAdvancedFilter.eventClickFilters(this.page, this.rows);
    }, 300000);
  }

  /**
   * @description this method is for validate date control.
   * @author Santiago Alberto Gonzalez
    * @sprint 8
   */
  getYearRange() {
    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();
    return `${thisYear - 2}:${thisYear}`;
  }

  getYearRangeInterruption() {
    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();
    return `${thisYear}:${thisYear + 10}`;
  }

  /**
   * @description this method is for validate date control.
   * @author Santiago Alberto Gonzalez
    * @sprint 8
   */
  validateDate(): void {
    if (!this.formCommercialActivity.valid) {
      this.formCommercialActivity.markAllAsTouched();
    }
  }

  /**
    * @description this method is to history CommercialActivity.
    * @author Santiago Alberto Gonzalez
    * @sprint 8
    * @param ObjectDetail.id
  */
  history(commercialActivityId: number): void {
    this.displayModal = true;
    const paginationHistoryModel = new PaginationHistoryModel('id', true, 1, 100, commercialActivityId);
    this.managePromotionalActivitiesService.getListCommercialActivityHistory(paginationHistoryModel).subscribe(
      (response) => {
        this.spinner.hide();
        this.objectListCommercialActivityHistory = response.data;
        this.objectListCommercialActivityHistory.sort((a, b) => 
        (a.creationDate > b.creationDate) ? 1 : -1);
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
   * @description this method is for show modal dialog.
   * @author Santiago Alberto Gonzalez
    * @sprint 8
   */
  showModalDialog() {
    this.displayModal = true;
  }

  /**
   * @description this method is for hide modal dialog.
   * @author Santiago Alberto Gonzalez
    * @sprint 8
   */
  hide() {
    this.displayModal = false;
  }

  /**
   * @description this method is for hide modal dialog.
   * @author Priscy Antonio Reales
   * @sprint 23
   */
  hideModalInterrupt() : void {
    this.displayModalInterrupt = false;
    this.resetModalInterrupt();
  }

  resetModalInterrupt() : void {
    this.formCommercialActivityInterrupt.reset();
  }

  /**
   * @description this method is to delete CommercialActivity.
   * @author Santiago Alberto Gonzalez
    * @sprint 8
   */
  delete(id: string): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.managePromotionalActivitiesService.delete(id)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.getAll(this.advanceFilter);
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
    });
  }

  /**
 * @description This method for delete in batch.
 * @author Santiago Alberto Gonzalez
 * @sprint 8
 */
  public onDeleteBatch(): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        const Ids = this.selectObject
          .map((item: IManagePromotionalActivitiesJson) => {
            return item.id;
          })
          .join();
        if (!Ids) {
          this.spinner.hide();
          return;
        }
        this.managePromotionalActivitiesService.delete(Ids).subscribe(
          (response) => {
            if (response.status) {
              this.getAll(this.advanceFilter);
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconInfo,
                language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              this.spinner.hide();
            } else {
              this.spinner.hide();
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });
  }

  copy(id: number) {
    Util.confirmCopy().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        const profile: CommercialActivityCopyModel =
          new CommercialActivityCopyModel(
            id
          );
        this.managePromotionalActivitiesService.copy(profile)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.getAll(this.advanceFilter);
                this.formBusinessActivityManagement.reset();
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCopy :SweetAlert.messageAlertCopyTranslate);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
    });
  }

  validityDescripcion(){
    if(this.descriptionActivity === undefined || this.descriptionActivity.trim() === ''){
      this.messageCopy = true;
    }else{
      this.messageCopy = false;
    }
  }


  /**
  * @description This method shows the toast control.
  * @author Santiago Alberto Gonzalez
    * @sprint 8
  * @param severity
  * @param message
  * @param detail
  * @returns none
  */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  /**
 * @description this method is to initialize the form.
 * @author Santiago Albero Gonzalez Jaramillo
 * @sprint 8
 */
  initForm(): void {
    this.formBusinessActivityManagement = this.fb.group({
      description: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(100), Validators.pattern(RegexCommon.ExpRegOnlyCaracterNumber100)]],
      typeCommercialActivity: [{ value: '', disabled: false }, [Validators.required]],
    });
    this.formCommercialActivity = this.fb.group({
      rangeDateControl: [{ value: '', disabled: false }, Validators.required],
    });

    this.formCommercialActivityInterrupt = this.fbInterruption.group({
      descriptionInterruption: [{ value: '', disabled: false }, [Validators.required]],
      dateInterruption: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  get description(): AbstractControl { return this.formBusinessActivityManagement.get('description'); }
  get typeCommercialActivity(): AbstractControl { return this.formBusinessActivityManagement.get('typeCommercialActivity'); }
  get rangeDateControl(): AbstractControl { return this.formCommercialActivity.get('rangeDateControl'); }

  get descriptionInterruption(): AbstractControl { return this.formCommercialActivityInterrupt.get('descriptionInterruption'); }
  get dateInterruption(): AbstractControl { return this.formCommercialActivityInterrupt.get('dateInterruption'); }

  keyupManagePromotional(event: any) {
    const value = Util.keyupLetterWithNumbersValidation(event);
    this.description.setValue(value);
  }


  onFocusOut(){
    this.description.setValue(this.description.value.trim());
  }


  async getUserConfig()
  {
    const userAccount = SessionStorageHelper.get(CommonConfiguration.Email);
    this.managePromotionalActivitiesService.getConfigurationUserAcessManagement(userAccount)
    .subscribe((response) =>{
      if(response.status){
        this.configurationUserAccessManagement = response.data;
        SessionStorageHelper.save(CommonConfiguration.Sector,  JSON.stringify(this.configurationUserAccessManagement.sector));
        SessionStorageHelper.save(CommonConfiguration.Channel, JSON.stringify(this.configurationUserAccessManagement.channel));
      }
    },
    (error: HttpErrorResponse) => {
      SweetAlertMessageHelpers.exception(error);
    });
  }
}
