import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { Util } from '@app/@shared/util';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { AdvancedRedeemableFilterService } from './advanced-redeemable-filter.service';
import { IRedeemableFilterMaterials } from './interfaces/redeemable-filter-materials.interface';
import { IRedeemableFilterElements } from './interfaces/redeemable-filter-elements.interface';
import { FilterDataModel } from './models/filters-data.model';
import { RedeemableFilterMaterialsModel } from './models/redeemable-filter-materials.model';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';

@Component({
  selector: 'app-advanced-redeemable-filter',
  templateUrl: './advanced-redeemable-filter.component.html',
  styleUrls: ['./../../@shared/scss/responsive.table.scss'],
  styles: [`
  @media screen and (max-width: 960px) {
      :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
          display: flex;
      }
  }
`],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedRedeemableFilterComponent implements OnInit {

  @Input('isMultiFilter') isMultiFilter: boolean;
  @Input('isProcess') isProcess: number;
  @Input('isVisible') isVisible: boolean;
  @Input('sector') sector: string[];
  @Input('requireSector') requireSector = false;
  @Input('priceless') priceless = false;
  @Input('editFiltersObjectList') editFiltersObjectList: IRedeemableFilterMaterials[] = [];
  @Input('editFiltersObjectDataList') editFiltersObjectDataList: IRedeemableFilterMaterials[] = [];
  @Output('eventClickFilters') eventButton: EventEmitter<any> = new EventEmitter();
  @Output('eventFilters') eventButtonData: EventEmitter<any> = new EventEmitter();
  @Output('eventClickDeleteFilter') eventDeleteFilter: EventEmitter<any> = new EventEmitter();
  @Output('eventClickDeleteFilters') eventDeleteFilters: EventEmitter<any> = new EventEmitter();

  public get comercialActivityEnum(): typeof ComercialActivityEnum {
    //Enum create - update - view
    return ComercialActivityEnum;
  }

  private readonly filterService: AdvancedRedeemableFilterService;
  private readonly spinner: NgxSpinnerService;
  private readonly parameterService: ManageParametersService;

  public advancedFiltersObjectList: IRedeemableFilterMaterials[] = [];
  public advancedFilterElement: IRedeemableFilterElements[] = [];
  public selectObject:IRedeemableFilterMaterials[] = [];
  public toEditFilter = false;
  public filterToEdit: IRedeemableFilterMaterials;
  public filterLevelSelected = 0;
  public levelContentSelected = '';
  public rowFilterToEdit: number = null;
  public selectedValue = 2;
  public numberGroup = 0;

  //Poduct
  public categoriesFilterList: ICombo[] = [];
  public categoryMultiSelected: string[] = [];
  public categorySelected: string;

  public packingSelected: string;
  public packagingFilterList: ICombo[] = [];
  public packingMultiSelected: string[] = [];

  public contentFilterList: ICombo[] = [];
  public contentMultiSelected: string[] = [];
  public contentSelected: string;

  public returnabilityFilterList: ICombo[] = [];
  public returnabilityMultiSelected: string[] = [];
  public returnabilitySelected: string;

  public denominationFilterList: ICombo[] = [];
  public denominationMultiSelected: string[] = [];
  public denominationSelected: string;

  //Element
  public codesFilterList: ICombo[] = [];
  public codesMultiSelected: string[] = [];
  public codeSelected = '';
  public codeBeforeSelected = '';

  public rolePermission = RolePermissionEnum;
	public sectorCommercialActivityParam = false;
  public checkDelete = false;

  constructor(
    filterService: AdvancedRedeemableFilterService,
    spinner: NgxSpinnerService,
		parameterService: ManageParametersService)
  {
    this.filterService = filterService;
    this.spinner = spinner;
    this.parameterService = parameterService;
  }

  ngOnInit(): void {
		this.getParametersValidateSector();
	}

	/**
	 * @description This method is to get parameter sector commercial activity
	 * @author Diego Mauricio Cortés
	 * @sprint 22
	 */
	async getParametersValidateSector(): Promise<void> {
		this.spinner.show();
		await this.parameterService.getAll().toPromise().then((dt) => {
			let param = dt.data.filter((prm) => prm.id === ManagementParameterEnum.sectorCommercialActivity);
			if (param) {
				this.sectorCommercialActivityParam = param[0].status;
			}
			this.spinner.hide();
		});
	}

  /**
  * @description This method is to verify changes in parent data
  * @param changes
  * @author Diego Mauricio Cortés
  * @sprint 16
  */
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const changeInEditFiltersObjectList: SimpleChange = changes['editFiltersObjectList'];
    const changeInEditFiltersObjectDataList: SimpleChange = changes['editFiltersObjectDataList'];
    if (changeInEditFiltersObjectList !== undefined && changeInEditFiltersObjectList.currentValue.length > 0) {
      this.advancedFiltersObjectList = changeInEditFiltersObjectList.currentValue
        .map((resp: IRedeemableFilterMaterials, index: number) => {
          return {
            group: `Grupo ${index + 1}`,
            materialCode : resp.materialCode,
            id: '',
            hierarchyFilter: resp.hierarchyFilter !== undefined ? resp.hierarchyFilter : resp,
            type : resp.type
          };
        });
    } else if (changeInEditFiltersObjectDataList !== undefined && changeInEditFiltersObjectList !== undefined &&
               changeInEditFiltersObjectDataList.currentValue.length > 0 && changeInEditFiltersObjectList.currentValue.length > 0){
      this.advancedFiltersObjectList = changeInEditFiltersObjectDataList.currentValue;
    }
    const changeInIsVisible: SimpleChange = changes['isVisible'];
    if (changeInIsVisible !== undefined && changeInIsVisible.currentValue !== null) {
      this.isVisible = changeInIsVisible.currentValue;
    }
    const changeSector: SimpleChange = changes['sector'];
    if (changeSector !== undefined && changeSector.currentValue !== null) {
      this.sector = changeSector.currentValue;
    }
  }

  /**
    * @description This method is to be visible the advanced filter modal
    *
    * @author Diego Mauricio Cortés
    * @sprint 16
  */
  openAdvancedFilters() {
    if (this.sectorCommercialActivityParam) {
			if (this.requireSector) {
				if (!this.sector || this.sector.length === 0) {
          const language: string = sessionStorage.getItem('language');
					SweetAlertMessageHelpers.warning(
            language === 'es-ES' ? 'Dato Requerido' : 'Required data',
            language === 'es-ES' ? 'El sector es requerido para realizar el filtro' : 'The sector is required to perform the filter');
					return;
				}
			}
		}
    this.toEditFilter = false;
    this.filterToEdit = null;
    this.clearFilters();
    this.getFiltersByLevelHierarchy(0);
    this.getFiltersElementsCode();
    this.isVisible = true;
  }

    /**
   * @description This method is to be load Filter List Redeemeable Materials
   *
   * @author Karol Mora
   * @sprint 16
  */
  getFiltersElementsCode(): void {
    this.spinner.show();
		this.filterService.redeemableFiltersElements().subscribe(
      (response) => {
        this.spinner.hide();
        this.codesFilterList = response.data.map((item: any) => {
          return {
            label: item.label,
            value: item.value
          };
        });
      });
  }

  /**
   * @description This method is to be invisible the advanced filter modal
   *
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  closeAdvancedFilters() {
    this.isVisible = false;
  }

  /**
   * @description This method is to clear hierarchy filters
   *
   * @author Karol Mora
   * @sprint 16
  */
  clearFilters(): void {
    this.categoriesFilterList = [];
    this.categoryMultiSelected = [];
    this.categorySelected = null;
    this.packagingFilterList = [];
    this.packingMultiSelected = [];
    this.packingSelected = null;
    this.contentFilterList = [];
    this.contentMultiSelected = [];
    this.contentSelected = null;
    this.returnabilityFilterList = [];
    this.returnabilityMultiSelected = [];
    this.returnabilitySelected = null;
    this.denominationFilterList = [];
    this.denominationMultiSelected = [];
    this.denominationSelected = null;
    this.codesFilterList = [];
    this.codesMultiSelected = [];
    this.codeSelected = '';
  }

  /**
   * @description This method is to verify hierarchy's level has
   * been selected, to call endpoint
   *
   * @param selectedLevel
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  getFiltersByLevelHierarchy(selectedLevel: number) {
    this.filterLevelSelected = selectedLevel === 0 ? 1 : selectedLevel;
    const data = this.prepareDataToGetHierarchyFilters();
    if (data) {
      this.getHierarchyFiltersMaterials(data);
    }
  }

  /**
   * @description This method is to validate filters selected
   *
   * @param vFilter
   * @author Diego Mauricio Cortés
   * @sprint 16
   */
  validateFilter(vFilter: IRedeemableFilterMaterials): void {
    this.filterService.redeemableFiltersCheck(JSON.stringify(vFilter)).subscribe((response) => {
      if (!this.isMultiFilter && this.advancedFiltersObjectList.length > 0 && !this.toEditFilter) {
        this.isVisible = false;
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError: SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? 'Para tipo tradicional sólo se permite un filtro de búsqueda.'
          : 'For traditional type only one search filter is allowed.');
      } else {
        if (response.message === 'OK' && response.data.length > 0) {
          let validate = 0;
          validate = this.duplicateData(response, validate);
          if (validate > 0) {
						return this.messageDuplicateData();
					}
          if (this.toEditFilter) {
            this.dataEditFilter(response);
          } else {
            if (this.advancedFiltersObjectList.length === 0) {
              this.dataGroup(response);
            } else {
              const i = this.advancedFiltersObjectList.length;
              this.validateFilterI(response);
            }
          }
          this.clearFilters();
          this.isVisible = false;
          this.toEditFilter = false;
          this.rowFilterToEdit = null;
        } else {
          this.invalidFiltersMesagge();
        }
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.isVisible = false;
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(
        language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
        language === 'es-ES' ? `Error al verificar los filtros válidos. \n Error => ${error.message}`
        : `Failed to check valid filters. \n Error => ${error.message}`);
      return;
    });
  }

  /**
   * @description This method is to delete all.
   *
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  deleteFilters(): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        let RedeemableDelete = this.advancedFiltersObjectList.filter((x) => x.check);
        RedeemableDelete.forEach((elm) => {
          const index = this.advancedFiltersObjectList.indexOf(elm);
          this.advancedFiltersObjectList.splice(index, 1);
        });
        this.checkDelete = false;

        this.eventDeleteFilters.emit(RedeemableDelete);
        this.spinner.hide();
      }
    });
  }

  /**
   * @description This method is to validate and add filters
   *
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  addFilter(): void {
    if (Number(this.selectedValue) === 1) {
      if (this.levelContentSelected !== undefined && this.levelContentSelected !== null && this.levelContentSelected !== '') {
        this.spinner.show();
        const vFilter: RedeemableFilterMaterialsModel = new RedeemableFilterMaterialsModel(
          this.levelContentSelected, ''
        );
        this.validateFilter(vFilter);
      } else {
        this.isVisible = false;
        this.selectionHierarchy();
      }
    } else {
      if (Number(this.selectedValue) === 2) {
        let validate = this.duplicateElement();
        if(validate){
          if (this.toEditFilter) {
            this.validationCodeSelect();
          } else {
            this.validationFullObject();
          }
        }
      }
    }
    if(this.advancedFiltersObjectList.length > 0){
      this.advancedFiltersObjectList.forEach((x) => {
        x.check = false;
      })
      this.eventButtonData.emit(this.advancedFiltersObjectList);
    }
  }

  addRedeemeableElementToTable(elements: ICombo[]) {
    if (!this.toEditFilter) {
      elements.forEach((item) =>{
        this.numberGroup = this.advancedFiltersObjectList.length;
        this.numberGroup++;

        this.advancedFiltersObjectList.push({
          group: `Grupo ${this.numberGroup}`,
          materialCode : item.value,
          id: '',//item.value,
          hierarchyFilter: item.label.substring(0,item.label.indexOf(' -')),
          type: true
        });
      });
    } else {
      const elementFound = this.advancedFiltersObjectList[this.rowFilterToEdit];
      elementFound.hierarchyFilter = elements[0].label.substring(0,elements[0].label.indexOf(' -'));
      elementFound.materialCode = elements[0].value;
      this.advancedFiltersObjectList[this.rowFilterToEdit] = {...elementFound};
    }

    this.clearFilters();
    this.isVisible = false;
    this.toEditFilter = true;
    this.rowFilterToEdit = null;
    this.spinner.hide();
  }

  /**
   * @description This method is to prepare edit filter
   *
   * @param rowIndex Index row selected
   * @param objectValue Object selected
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
    async edit(rowIndex: number, objectValue: IRedeemableFilterMaterials) {
    Util.confirmEdit().then(async (result: SweetAlertResult) => {
      if (result.value) {
        this.clearFilters();
        this.toEditFilter = true;

        this.filterToEdit = objectValue;
        this.rowFilterToEdit = rowIndex;
        if(objectValue.type){
          this.selectedValue = 2;
          this.prepareEditElement(objectValue);
        } else {
          const hierarchyFilters: string[] = objectValue.hierarchyFilter.split('/');
          this.selectedValue = 1;
          await this.prepareEditFilter(hierarchyFilters);
        }
        this.isVisible = true;
      }
    });
  }

  /**
     * @description This method is to prepare edit filter
     *
     * @param element Object selected
     * @author Karol Mora
     * @sprint 16
    */
    prepareEditElement(element: IRedeemableFilterMaterials){
      this.spinner.show();
      if (this.codesFilterList.length === 0) {
        this.getFiltersElementsCode();
      }
      setTimeout(() => {
        this.codeSelected = element.materialCode;
        this.spinner.hide();
      }, 1000);
    }

    /**
     * @description This method is to prepare filters for edit this
     *
     * @param hierarchyFilters
     * @author Diego Mauricio Cortés
     * @sprint 9
     */
    async prepareEditFilter(hierarchyFilters: string[]) {
      this.spinner.show();
      this.filterLevelSelected = 1;

      const lengthSearch = !this.toEditFilter ? hierarchyFilters.length : hierarchyFilters.length + 1;
      for (let index = 0; index <= lengthSearch; index++) {
        const element = hierarchyFilters[index < lengthSearch ? index : index - 1];
        const data = this.prepareDataToGetHierarchyFilters();
        const responseHierarchyFilter = await this.filterService.redeemableFiltersMaterials(JSON.stringify(data)).toPromise();
        this.prepareFilterDataShow(responseHierarchyFilter.data);
        if (index < lengthSearch) {
          if (element) {
            this.checkSwitch(index, element);
          }
          this.filterLevelSelected += 1;
        }
      }
      this.spinner.hide();
    }

  /**
   * @description This method is to delete a element from filter
   *
   * @param objectValue Object to delete form filter
   * @author Diego Mauricio Cortés
   * @sprint 16
   */
   delete(rowIndex: number, objectValue: IRedeemableFilterMaterials) {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        if (this.isMultiFilter) {
          this.advancedFiltersObjectList.splice(rowIndex, 1);
          this.eventDeleteFilter.emit(objectValue);
          this.advancedFiltersObjectList.forEach((adv, index) => {
            adv.group = `Grupo ${index + 1}`;
          });
        } else {
          this.deleteFilters();
        }
      }
    });
  }

  /**
 * @description This method is to emit event to father component.
 *
 * @param $event Event click data
 * @author Diego Mauricio Cortés
 * @sprint 16
 */
  eventClickFilters(): void {
    const filterInfo = {
      advancedFiltersObjectList: this.advancedFiltersObjectList
    };

    this.eventButton.emit(filterInfo);
  }

  /**
   * @description This method is to prepare data for
   * send to search filters
   *
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  private prepareDataToGetHierarchyFilters(): FilterDataModel {
    this.levelContentSelected = this.getContentFilterByLevel();
    const filtersAux = JSON.stringify({
      type: 'SCode',
      level: this.filterLevelSelected,
      content: this.levelContentSelected !== undefined ? this.levelContentSelected : '',
      sector: this.sector ? this.sector.join(',') : ''
    });
    const data: FilterDataModel = new FilterDataModel(
      filtersAux,
      environment.conditionTable,
      environment.conditionClassCode,
      this.priceless
    );
    return data;
  }

  /**
   * @description This method is to get the materials hierarchy filters
   *
   * @param data Data to get Hierarchy Filters Materials
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  private getHierarchyFiltersMaterials(data: any): void {
    this.spinner.show();
    this.filterService.redeemableFiltersMaterials(JSON.stringify(data)).subscribe(
      (response) => {
        if (response.status) {
          this.prepareFilterDataShow(response.data);
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * @description This method is to prepare filters
   * to show by hierarchy
   *
   * @param data Data to prepare filters
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  private prepareFilterDataShow(data: any) {

    const arrangeDataFilter: ICombo[] = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const filter = {
          value: element.description,
          label: element.description
      };

      arrangeDataFilter.push(filter);
    }

    switch (this.filterLevelSelected) {
      case 1:
        this.prepareFilterCategory(arrangeDataFilter);
        break;
      case 2:
        this.prepareFilterPacking(arrangeDataFilter);
        break;
      case 3:
        this.prepareFilterContent(arrangeDataFilter);
        break;
      case 4:
        this.prepareFilterReturnability(arrangeDataFilter);
        break;
      case 5:
        this.prepareFilterDenomination(arrangeDataFilter);
        break;
      default:
        break;
    }

  }

  private prepareFilterCategory(data: ICombo[]){
    this.categoriesFilterList = [];
    data.forEach((d) => {
      this.categoriesFilterList.push(d);
    });

    this.validateFilterCategory(data);

    if(this.packingMultiSelected && this.packingMultiSelected.length > 0){
      this.getFiltersByLevelHierarchy(2);
    }

  }

  private validateFilterCategory(data: ICombo[]){
    if(this.categoryMultiSelected && this.categoryMultiSelected.length > 0){
      const temporalCategory: string[] = [];

      data.forEach((x) => {
        const searchCategory = this.categoryMultiSelected.find((y) => y === x.value);
        if(temporalCategory !== undefined) temporalCategory.push(searchCategory);
      });

      this.categoryMultiSelected = [];
      this.categoryMultiSelected = temporalCategory;

    }else{
      this.packagingFilterList = [];
      this.packingMultiSelected = [];

      this.contentFilterList = [];
      this.contentMultiSelected = [];

      this.returnabilityFilterList = [];
      this.returnabilityMultiSelected = [];

      this.denominationFilterList = [];
      this.denominationMultiSelected = [];
    }
  }

  private prepareFilterPacking(data: ICombo[]){

    if(this.categoryMultiSelected && this.categoryMultiSelected.length === 0){
      this.packagingFilterList = [];
      this.packingMultiSelected = [];
    }

      this.packagingFilterList = [];
      data.forEach((d) => {
        this.packagingFilterList.push(d);
      });

      this.validateFilterPacking(data);
  }

  private validateFilterPacking(data: ICombo[]){
    if(this.packingMultiSelected && this.packingMultiSelected.length > 0){
      const temportalPacking : string[] = [];

      data.forEach((x) => {
        const searchPacking = this.packingMultiSelected.find((y) => y === x.value);
        if(searchPacking !== undefined) temportalPacking.push(searchPacking);
      });

      this.packingMultiSelected = [];
      this.packingMultiSelected = temportalPacking;

      this.getFiltersByLevelHierarchy(3);
    }else{
      this.contentFilterList = [];
      this.contentMultiSelected = [];

      this.returnabilityFilterList = [];
      this.returnabilityMultiSelected = [];

      this.denominationFilterList = [];
      this.denominationMultiSelected = [];
    }
  }

  private prepareFilterContent(data: ICombo[]){

    if(this.packingMultiSelected && this.packingMultiSelected.length === 0){
      this.contentFilterList = [];
      this.contentMultiSelected = [];
    }

    this.contentFilterList = [];

    data.forEach((d) => {
      this.contentFilterList.push(d);
    });

    this.validateFilterContent(data);
  }

  private validateFilterContent(data: ICombo[]){

    if(this.contentMultiSelected && this.contentMultiSelected.length > 0){
      const temportalContent : string[] = [];

      data.forEach((x) => {
        const searchContent = this.contentMultiSelected.find((y) => y === x.value);
        if(searchContent !== undefined) temportalContent.push(searchContent);
      });

      this.contentMultiSelected = [];
      this.contentMultiSelected = temportalContent;

      this.getFiltersByLevelHierarchy(4);
    }else{
      this.returnabilityFilterList = [];
      this.returnabilityMultiSelected = [];

      this.denominationFilterList = [];
      this.denominationMultiSelected = [];
    }

  }

  private prepareFilterReturnability(data: ICombo[]){

    if(this.contentMultiSelected && this.contentMultiSelected.length === 0){
      this.returnabilityFilterList = [];
      this.returnabilityMultiSelected = [];
      return;
    }

      this.returnabilityFilterList = [];

      data.forEach((d) => {
        this.returnabilityFilterList.push(d);
      });

      this.validateFilterReturnability(data);
  }

  private validateFilterReturnability(data: ICombo[]){
    if(this.returnabilityMultiSelected && this.returnabilityMultiSelected.length > 0){

      const temportalReturnability : string[] = [];

      data.forEach((x) => {
        const searchReturnability = this.returnabilityMultiSelected.find((y) => y === x.value);
        if(searchReturnability !== undefined) temportalReturnability.push(searchReturnability);
      });

      this.returnabilityMultiSelected = [];
      this.returnabilityMultiSelected = temportalReturnability;

      this.getFiltersByLevelHierarchy(5);
    }else{
      this.denominationFilterList = [];
      this.denominationMultiSelected = [];
    }
  }

  private prepareFilterDenomination(data: ICombo[]){

    if(this.returnabilityMultiSelected && this.returnabilityMultiSelected.length === 0){
      this.denominationFilterList = [];
      this.denominationMultiSelected = [];
      return;
    }

      this.denominationFilterList = [];

      data.forEach((d) => {
        this.denominationFilterList.push(d);
      });

      this.validateFilterDenomination(data);
  }

  private validateFilterDenomination(data: ICombo[]){
    if(this.denominationMultiSelected && this.denominationMultiSelected.length > 0){

      const temporalDenomination : string[] = [];

      data.forEach((x) => {
        const searchDenomination = this.denominationMultiSelected.find((y) => y === x.value);
        if(searchDenomination !== undefined) temporalDenomination.push(searchDenomination);
      });

      this.denominationMultiSelected = [];
      this.denominationMultiSelected = temporalDenomination;

      this.getFiltersByLevelHierarchy(6);
    }
  }

  /**
   * @description This method is to prepare value to send content for endpoint to search filters
   *
   * @author Diego Mauricio Cortes
   * @sprint 16
  */
  private getContentFilterByLevel(): string {
    const levelToPrepareData = (this.filterLevelSelected);
    switch (levelToPrepareData) {
      case 1:
        this.packagingFilterList = [];
        return this.getContentToFilterLevelI();
      case 2:
        this.contentFilterList = [];
        return this.getContentToFilterLevelII();
      case 3:
        this.returnabilityFilterList = [];
        return this.getContentToFilterLevelIII();
      case 4:
        this.denominationFilterList = [];
        return this.getContentToFilterLevelIV();
      case 5:
        return this.getContentToFilterLevelV();
      case 6:
        return this.getContentFilterLevelVI();
      default:
        break;
    }
    return;
  }

  /**
   * @description This method is to get content value for send to get
   * filter of levelII
   *
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  private getContentToFilterLevelI(): string {
    if (this.isMultiFilter && !this.toEditFilter) {
      return this.categoriesFilterList.length === this.categoryMultiSelected.length ? '' : this.categoryMultiSelected.join(';');
    } else {
      return this.categorySelected !== null ? this.categorySelected : '';
    }
  }


  /**
    * @description This method is to prepare content value for send in
    * endpoint to get filters levelIII
    *
    * @author Diego Mauricio Cortés
    * @sprint 16
   */
  private getContentToFilterLevelII(): string {
    if (this.isMultiFilter && !this.toEditFilter) {
      if (this.packingMultiSelected.length > 0) {
        const packings: string[] = [];
        this.categoryMultiSelected.forEach((category: string) => {
          this.packingMultiSelected.forEach((element: string) => {
            packings.push(`${category}`);
          });
        });
        return packings.join(';');
      } else {
        return this.categoryMultiSelected.join(';');
      }
    } else {
      return `${this.categorySelected}${this.packingSelected !== null ? '/' + this.packingSelected : ''}`;
    }
  }

  /**
     * @description This method is to prepare content value for send in
     * endpoint to get filters level IV
     *
     * @author Diego Mauricio Cortés
     * @sprint 16
    */
  private getContentToFilterLevelIII(): string {
    if (this.isMultiFilter && !this.toEditFilter) {
      if (this.packingMultiSelected.length > 0) {
        const packings: string[] = [];
        this.categoryMultiSelected.forEach((category: string) => {
          this.packingMultiSelected.forEach((element: string) => {
            packings.push(`${category}/${element}`);
          });
        });
        return packings.join(';');
      } else {
        return this.categoryMultiSelected.join(';');
      }
    }else {
      const contentLevelII = this.getContentToFilterLevelII();
      return `${contentLevelII}${this.contentSelected !== null ? '/' + this.contentSelected : ''}`;
    }
  }

  /**
   * @description This method is to prepare content value for send in
   * endpoint to get filters level V
   *
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  private getContentToFilterLevelIV(): string {
    if (this.isMultiFilter && !this.toEditFilter) {
      if (this.contentMultiSelected.length > 0) {
        const contents: string[] = [];
        this.categoryMultiSelected.forEach((category: string) => {
          this.packingMultiSelected.forEach((element: string) => {
            this.contentMultiSelected.forEach((content: string) => {
              contents.push(`${category}/${element}/${content}`);
            });
          });
        });

        return contents.join(';');
      }else{
        const contentLevelIII = this.getContentToFilterLevelIII();
        return contentLevelIII;
      }

    }else{
      const contentLevelIII = this.getContentToFilterLevelIII();
      return `${contentLevelIII}${this.returnabilitySelected !== null ? '/' + this.returnabilitySelected : ''}`;
    }

  }

  /**
   * @description This method is to prepare content value for add filter level V
   *
   * @author Diego Mauricio Cortés
   * @sprint 16
  */
  private getContentToFilterLevelV(): string {

    if (this.isMultiFilter && !this.toEditFilter) {
      if (this.returnabilityMultiSelected.length > 0) {

        const returnabilities: string[] = [];

        this.categoryMultiSelected.forEach((category: string) => {
          this.packingMultiSelected.forEach((element: string) => {
            this.contentMultiSelected.forEach((content: string) => {
              this.returnabilityMultiSelected.forEach((returnability : string) => {
                returnabilities.push(`${category}/${element}/${content}/${returnability}`);
              });
            });
          });
        });

        return returnabilities.join(';');
      }else{
        const contentLevelIV = this.getContentToFilterLevelIV();
        return contentLevelIV;
      }
    }else{
      const contentLevelIV = this.getContentToFilterLevelIV();
      return `${contentLevelIV}${this.denominationSelected !== null ? '/' + this.denominationSelected : ''}`;
    }

  }

  private getContentFilterLevelVI(): string {
    if (this.returnabilityMultiSelected.length > 0) {
      const returnabilities: string[] = [];
      this.categoryMultiSelected.forEach((category: string) => {
        this.packingMultiSelected.forEach((element: string) => {
          this.contentMultiSelected.forEach((content: string) => {
            this.returnabilityMultiSelected.forEach((returnability : string) => {
              this.denominationMultiSelected.forEach((denomination: string) => {
                returnabilities.push(`${category}/${element}/${content}/${returnability}/${denomination}`);
              });
            });
          });
        });
      });

      return returnabilities.join(';');
    }

  }

  private invalidFiltersMesagge() {
    this.isVisible = false;
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(
      language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
      language === 'es-ES' ? 'No se han encontrado filtros validos en la combinación que ha realizado.'
        : 'No valid filters have been found in the combination you have made.');
  }

  private dataGroup(response: IResponseService<any>) {
    this.advancedFiltersObjectList = response.data
      .map((res: IRedeemableFilterMaterials) => {
        this.numberGroup++;
        return {
          group: `Grupo ${this.numberGroup}`,
          id: '',
          hierarchyFilter: res.dataHierarchy,
          type: false
        };
      });
    this.eventButtonData.emit(this.advancedFiltersObjectList);
  }

  private dataEditFilter(response: IResponseService<any>) {
    const elementEdit = this.advancedFiltersObjectList[this.rowFilterToEdit];
    this.advancedFiltersObjectList[this.rowFilterToEdit] = {
      group: elementEdit.group,
      materialCode: '',
      id: '',
      hierarchyFilter: response.data[0].dataHierarchy,
      type: false
    };
  }

  private messageDuplicateData() {
    this.isVisible = false;
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(
      language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
      language === 'es-ES' ? 'Los filtros seleccionados coinciden con filtros ya aplicados.'
        : 'Selected filters match filters already applied.');
    this.spinner.hide();
    return;
  }

  private duplicateData(response: IResponseService<any>, validate: number) {
    this.advancedFiltersObjectList.forEach((filterResponse: any) => {
      const indxFound = response.data.filter((dataFound: any) => dataFound.dataHierarchy === filterResponse.hierarchyFilter);
      if (indxFound.length > 0) {
        validate += 1;
      }
    });
    return validate;
  }

  duplicateElement(): boolean{
    let duplicate = 0;
    this.advancedFiltersObjectList.forEach((filterResponse: any) => {
      const indxFound = this.codesMultiSelected.filter((dataFound: any) => dataFound === filterResponse.materialCode);
      if (indxFound.length > 0) {
        duplicate +=1;
      }
    });

    if(duplicate > 0){
      this.messageDuplicateData();
      return false;
    }
    return true;
  }

  private validateFilterI(response: IResponseService<any>) {
    this.numberGroup = this.advancedFiltersObjectList.length;
    if (response.data.length > 1) {
      const data = response.data
        .map((resp: IRedeemableFilterMaterials) => {
          this.numberGroup++;
          return {
            group: `Grupo ${this.numberGroup}`,
            id: '',
            hierarchyFilter: resp.dataHierarchy,
            type: false
          };
        });
      this.advancedFiltersObjectList = this.advancedFiltersObjectList.concat(data);
    } else {
      this.advancedFiltersObjectList.push({
        group: `Grupo ${this.numberGroup + 1}`,
        materialCode : '',
        id: '',
        hierarchyFilter: response.data[0].dataHierarchy,
        type: false
      });
    }
  }
  public selectAll(value: any) {
    this.advancedFiltersObjectList.forEach((advanced) => {
      advanced.check = value;
    });
    this.checkDelete = value;
  }

  public selectRow(value: any, object: any) {
    object.check = value;
    this.checkDelete = false;
  }

  private validationCodeSelect() {
    if (this.codeSelected) {
      this.spinner.show();
      const result = this.codesFilterList.filter((x1) => x1.value === this.codeSelected);
      this.addRedeemeableElementToTable(result);
    } else {
      this.isVisible = false;
      this.messageSelectionItem();
    }
  }

  private selectionHierarchy() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(
      language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
      language === 'es-ES' ? 'Debe seleccionar mínimo una jerarquía.' : 'You must select at least one hierarchy.');
  }

  private validationFullObject() {
    if (this.codesMultiSelected.length > 0) {
      this.spinner.show();
      const result = this.codesFilterList.filter((x1) => this.codesMultiSelected.some((x2) => x1.value === x2));
      this.addRedeemeableElementToTable(result);
    }
    else {
      this.isVisible = false;
      this.messageSelectionItem();
    }
  }

  private messageSelectionItem() {
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.error(
      language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
      language === 'es-ES' ? 'Debe seleccionar mínimo un elemento.' : 'You must select at least one element.');
  }

  private checkSwitch(index: number, element: string) {
    switch (index) {
      case 0:
        this.categorySelected = element;
        break;
      case 1:
        this.packingSelected = element;
        break;
      case 2:
        this.contentSelected = element;
        break;
      case 3:
        this.returnabilitySelected = element;
        break;
      case 4:
        this.denominationSelected = element;
        break;
      default:
        break;
    }
  }
}
