import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Microservice } from '../enums/microservice.enum';
import { IDomain } from '../interfaces/domain.interface';
import { IResponseService } from '../interfaces/response-service.interface';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class DomainService {
  public urlEnviroment : string;
  private readonly httpService: HttpService;
  
    constructor(httpService: HttpService) {
      this.httpService = httpService;
     }

  /**
   * @description This method is to get all the Domain By DomainType.
   * @author Priscy Antonio Reales
   * @param none
   * @returns Observable<IResponseService<IDomain[]>>
   * @sprint 1
   */
   public getAllByDomainType(typeEnviroment: Microservice, id : number): Observable<IResponseService<IDomain[]>> {
    const response$: Observable<IResponseService<IDomain[]>> = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/domains/domainTypes/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IDomain[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  private getUrlEnviroment(typeEnviroment: Microservice){
    if(typeEnviroment === Microservice.Security){
      this.urlEnviroment = environment.apiUrlSecurity;
    }else if(typeEnviroment === Microservice.Core){
      this.urlEnviroment = environment.apiUrlCore;
    }else{
      this.urlEnviroment = environment.apiUrlIntegration;
    }
  }

}
