<section>
  <div class="container">
      <nav aria-label="breadcrumb">
          <ol class="pstn breadcrumb">
              <li class="breadcrumb-item">
                  <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate
                      }}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                  {{ 'ExclusionComponent.ExclusionManagement' | translate }}
              </li>
          </ol>
      </nav>
  </div>
</section>


<section class="pstngestion">
  <div class="container">
      <section class="generalData">

          <div class="row title">
              <div class="col-md-6">
                  <h1 class="pstntitle">
                      {{ 'ExclusionComponent.ExclusionManagement' | translate }}
                  </h1>
              </div>
          </div>

          <div class="options">
              <!-- button -->
              <div class="options--button col-md-4 mt-4 align-items-end">
                  <button id="btnCreate" 
                        *ngIf="rolePermission.create | rolePermission"
                        pButton 
                        pRipple 
                        type="button"
                        label="{{ 'ExclusionComponent.CreateExclusions' | translate }}"
                        class="p-button-rounded pstn_primario w-100" (click)="createAction()"></button>
              </div>
          </div>

      </section>
  </div>
</section>


<section class="pstngestion">
  <div class="container">
      <section id="TableWhitGroup">
          <div class="row mt-5">
              <div class="pstn_container container-fluid ">
                  <div class="container-table body">

                      <p-table #dt 
                        name="gridSolicitude" 
                        dataKey="descGroup" 
                        [paginator]="true" 
                        [rows]="10"
                        [rowsPerPageOptions]="[10,25,50]" 
                        paginatorPosition="bottom"
                        styleClass="p-datatable-customers" 
                        [value]="exclusionsList" 
                        [(selection)]="selectedRows"
                        [globalFilterFields]="['id', 'reasonExclusion', 'typeBusinessActivity', 'canal']">
                        <ng-template pTemplate="caption">
                            <div style="display: flex; justify-content: space-between;">
                                <div class="container-table">
                                    <span class="row" *ngIf="rolePermission.delete | rolePermission">
                                        <h6 style="color:black">{{ 'ExclusionComponent.DeleteSelected' | translate }}
                                        <i id="btnDeleteBatch"
                                            class="uil uil-trash-alt eliminar"
                                            (click)="deleteAllObjectAction()"
                                            aria-hidden="true"></i></h6>
                                    </span>
                                </div>
    
                                <div class="p-d-flex">
                                  <span class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search" aria-hidden="true"></i>
                                    <input pInputText
                                           type="text"
                                           (input)="dt.filterGlobal($event.target.value, 'contains')"
                                           placeholder="{{ 'SearchBy' | translate }}" />
                                  </span>
                                </div>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="header">
                              <tr>
                                  <!-- <th style="width: 3rem"></th>
                                  <th role="columnheader" style="width: 3rem"></th> -->
                                  <th style="width: 3rem">
                                      <p-checkbox (click)="selectAll(e.checked)" binary="true" #e></p-checkbox>
                                  </th>
                                  <th style="width: 10rem">{{ 'ExclusionComponent.IdExclusion' | translate }}</th>
                                  <th>{{ 'ExclusionComponent.ReasonExclusion' | translate }}</th>
                                  <th style="width: 12rem">{{ 'ExclusionComponent.TypeActivity' | translate }}</th>
                                  <th>{{ 'ExclusionComponent.Channels' | translate }}</th>
                                  <th>{{ 'ExclusionComponent.State' | translate }}</th>
                                  <th>{{ 'ExclusionComponent.Actions' | translate }}</th>
                                  <!-- <th>{{ "Actions" | translate }}</th> -->
                                  <!-- <th role="columnheader" style="width: 4rem"></th>
                                  <th style="width: 4rem"></th> -->
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-object>
                              <tr>
                                  <td style="width: 3rem">
                                      <p-checkbox (click)="selectRow(e.checked,object)"
                                                  [(ngModel)]="object.check"
                                                  binary="true" #e>
                                      </p-checkbox>
                                  </td>
                                  <td>{{object.id}}</td>
                                  <td>{{object.reasonExclusion}}</td>

                                  <td >
                                        <ul style="list-style: none;" *ngFor="let typeActivity of object.typeBusinessActivity">
                                            <li>{{'Domain.Domain-'+typeActivity | translate}}</li>
                                        </ul>

                                    </td>
                                
                                  <td>{{object.canal}}</td>
                                  <td>{{'Domain.Domain-'+getState(object.state) | translate}}</td>
                                  <td>
                                    <span>
                                      <i id="btnEye"
                                      *ngIf="rolePermission.read | rolePermission"
                                         aria-hidden="true"
                                         class="uil uil-search-alt Editar"
                                         pTooltip="{{'Detail' | translate}}"
                                         (click)="viewDetailsObjectAction(object)"
                                         ></i>
                                      <i id="btnEdit"
                                        *ngIf="rolePermission.update | rolePermission"
                                        class="uil uil-edit Editar"
                                        pTooltip="{{'Edit' | translate}}"
                                        aria-hidden="true"
                                        (click)="editObjectAction(object)"></i>
                                      <i id="btnDelete"
                                        *ngIf="rolePermission.delete | rolePermission"
                                        class="uil uil-trash-alt eliminar"
                                        pTooltip="{{'Delete' | translate}}"
                                        aria-hidden="true"
                                        (click)="deleteObjectAction(object)"></i>
                                    </span>
                                  </td>
                              </tr>
                          </ng-template>
                      </p-table>

                  </div>

              </div>
          </div>
      </section>
  </div>
</section>

<p-toast></p-toast>