import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { PaginationDateModel } from '@app/@shared/model/paginationDate.model';
import { PaginationHistoryModel } from '@app/@shared/model/paginationHistory.model';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ISolicitudeHistory } from '../interfaces/solicitude-history.interface';
import { ISolicitudeJson } from '../interfaces/solicitude-json.interface';
import { SolicitudeService } from '../solicitude.service';


@Component({
  selector: 'app-pending-solicitude',
  templateUrl: './pending-solicitude.component.html',
  styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
})
export class PendingSolicitudeComponent implements OnInit {
  private readonly solicitudeService: SolicitudeService;
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly router: Router;

  public objectList: ISolicitudeJson[];
  public objectListSolicitudeHistory: ISolicitudeHistory[];
  public displayModal: boolean;
  public rolePermission: any = RolePermissionEnum;
  public validity: boolean;
  public solicitudeStatus: any =  SolicitudeStatus;
  
  constructor(
    solicitudeService: SolicitudeService,
    spinner: NgxSpinnerService,
    messageService: MessageService,
    router: Router,
  ) { 
    this.solicitudeService = solicitudeService;
    this.spinner = spinner;
    this.messageService = messageService;
    this.router = router;
  }

  ngOnInit(): void {
    this.getAllSolicitude();
  }

  validateElementsNotFound(){
    if(this.objectList.length <= 0){
      this.validity = true;
    }else{
      this.validity = false;
    }
  }

  /**
 * @description Method is to get all the solicitude.
 * @author Daniel Londoño
 * @sprint
 */
  getAllSolicitude() {
    this.spinner.show();
    const paginationDateModel = new PaginationDateModel('id', false, 1, 10000, '', '', false, SolicitudeStatus.InProgress);
    this.solicitudeService.getListSolicitude(paginationDateModel).subscribe(
      (response) => {
        if (response.status) {
          this.objectList = response.data;
          this.validateElementsNotFound();
        }
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
  * @description this method is to edit Solicitude.
  * @author Daniel Londoño
  * @sprint 5
  */
  analyzeSolicitude(ObjecDetail: ISolicitudeJson): void {
    this.spinner.show();
    this.solicitudeService.setSolicitudeId(ObjecDetail.id);
    this.solicitudeService.setIsCreate(ComercialActivityEnum.view);
    if (ObjecDetail.type === SolicitudeType.Creation) {
      setTimeout(() => {
        this.solicitudeService.setSolicitudeId(ObjecDetail.id);
        this.router.navigate(['auth/pending-solicitude/creation/']);

      }, 1000);
    } else {
      if (ObjecDetail.type === SolicitudeType.Withdrawal) {
        setTimeout(() => {
          this.router.navigate(['auth/pending-solicitude/withdrawal/']);
        }, 1000);
      }else if(ObjecDetail.type === SolicitudeType.WithdrawalDiferential){
        setTimeout(() => {
          this.router.navigate(['auth/pending-solicitude/differential/']);
        }, 1000);
      }

    }
    this.spinner.hide();
  }

  /**
  * @description this method is to history Solicitude.
  * @author Daniel Londoño
  * @sprint 5
  * @param ObjectDetail.id
*/
  historySolicitude(solicitudeId: number): void {
    this.displayModal = true;
    const paginationHistoryModel = new PaginationHistoryModel('id', true, 1, 100, solicitudeId);
    this.solicitudeService.getListSolicitudeHistory(paginationHistoryModel).subscribe(
      (response) => {
        this.spinner.hide();
        this.objectListSolicitudeHistory = response.data;
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }
  /**
* @description This method shows the toast control.
* @author Daniel Londoño
* @sprint 2
* @param severity
* @param message
* @param detail
* @returns none
*/
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }
}
