import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DoughnutChartComponent } from '@app/@components/charts/doughnut-chart/doughnut-chart.component';
import { PieChartComponent } from '@app/@components/charts/pie-chart/pie-chart.component';
import { ImpactAnalysisChartsDirective } from '@app/@modules/prices/solicitude/directives/impact-analysis-charts/impact-analysis-charts.directive';
import { IImpactAnalysis, IImpactAnalysisChartObject } from '@app/@modules/prices/solicitude/interfaces/impact-analysis.interfaces';
import { BarChartComponent } from '@components/charts/bar-chart/bar-chart.component';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-impact-analyses-card',
  templateUrl: './impact-analysis-card.component.html',
  styleUrls: ['./impact-analysis-card.component.css']
})
export class ImpactAnalysisCardComponent implements OnInit, AfterViewInit {
  @ViewChild(ImpactAnalysisChartsDirective, { static: true }) impactAnalysisCharts: ImpactAnalysisChartsDirective;

  @Output() eventEmitterCard = new EventEmitter();
  
  @Input() item: IImpactAnalysis;
  @Input() toSendCalculatedValue$: Observable<any>;

  private readonly componentFactoryResolver: ComponentFactoryResolver;
  private readonly changeDetector: ChangeDetectorRef;
  public filterValue = new Subject<string>();
  public filterValue$ = this.filterValue.asObservable();

  public selectedFilter = {
    label: ''
  };

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetector: ChangeDetectorRef
  ) { 
    this.componentFactoryResolver = componentFactoryResolver;
    this.changeDetector = changeDetector;
  }

  ngOnInit(): void {
    this.loadChartComponent(this.item);

  }

  ngAfterViewInit() {
    if (this.item.chartProps.filters) {
      setTimeout(() => {
        this.onRowSelect({
          data: this.item.chartProps.filters[3]
        });
      }, 1000);
    }
  }

  ngAfterViewChecked() {
    // your code to update the model
    this.changeDetector.detectChanges();
  }

  loadChartComponent(itemToLoaded: IImpactAnalysis) {
    const ChartComponent = this.getChartComponent(itemToLoaded.type);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(<any>ChartComponent);

    const viewContainerRef = this.impactAnalysisCharts.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<IImpactAnalysisChartObject>(componentFactory);
    componentRef.instance.chartData = this.item;

    componentRef.instance.filterValue = this.filterValue$;
    componentRef.instance.toSendCalculatedValue = this.toSendCalculatedValue$;
    // this.changeDetector.detectChanges();
  }

  getChartComponent(type: string) {
    switch (type) {
      case 'barChart':
        return BarChartComponent;
      case 'doughnutChart':
        return DoughnutChartComponent;
      case 'pieChart':
        return PieChartComponent;
      default:
        break;
    }
  }

  public updateStringSubject(newStringVar: any) {
    this.filterValue.next(newStringVar);
  }

  public onRowSelect(event: any, op?: any) {
    this.selectedFilter = event.data;
    const dataFilter = {
      selecetedFilter: event.data,
      props: this.item.chartProps
    };
    const { props, selecetedFilter: { attr } } = dataFilter;
    const { amount, percentage } = props.values[attr] || {};
    this.item.amount = amount;
    this.updateStringSubject({ amount, percentage });
    this.eventEmitterCard.emit({ amount, percentage });
    op !== undefined && op.hide();
  }

  public formatNumberPercentage(num: any) {
    if (num) {
      return num * 100;
    }
      return '';
  }


  public getValueByType(item: any) {
    if (item.idType === '') {
      return item.chartProps.percentage;
    }
    return item.amount;
  }

  public getFirstTwoDecimal(num: any, decimal: any = 2) {
    if (num) {
      return (Math.round(num * 100) / 100).toFixed(decimal);
    }
  }

  public numberWithCommas(x: string, item: any) {
    if (x !== undefined) {
      let stringPart = [];
      const newValue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      stringPart = newValue.split('');
      stringPart[newValue.lastIndexOf('.')] = ',';
      return stringPart.join('');
    }
    return x;
  }

  public parsingNumberPercentage(percentage: any) {
    let percentageToConvert = (percentage * 100);
    percentageToConvert = percentageToConvert >= 100 ? 100 : percentageToConvert;
    return percentageToConvert >= 100 ? 100 : this.getFirstTwoDecimal(new Number(percentageToConvert).toPrecision(2), 0);
  }

  public showValueCard(item: any) {
    if (item.idType === '') {
      const value = this.getValueByType(item);
      return `${this.parsingNumberPercentage(value)} %`;
    } else {
      return this.numberWithCommas(this.getFirstTwoDecimal(this.getValueByType(item)), item);
    }

  }

}
