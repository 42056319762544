import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from '@app/@shared/enums/module.enums';
import { NotificationType } from '@app/@shared/enums/notification-type.enum';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NotificationInformativeModel } from '@app/@shared/model/notification-informative.model';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { SolicitudeMessage } from '../../messages/solicitude.message';
import { SolicitudeCreationModel } from '../../models/solicitude-creation.model';
import { SolicitudeStatusModel } from '../../models/solicitude-status.model';
import { SolicitudeService } from '../../solicitude.service';
import { DataImpactAnalysisJson } from '../../models/data-impact-analysis-json.model';
import { ImpactAnalysisJsonDetail } from '../../models/impact-analysis-json-detail.model';
import { AnalysisImpactMessage } from '../../messages/analysis-Impact.message';
import { AnalyticModule } from '../../../../../@shared/enums/analytic-module.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { RankNumber } from '../../../../../@shared/enums/rank-numbers.enum';
import { ISolicitudeCustomer } from '../../interfaces/solicitude-customer.interface';
import { FilterClient } from '@app/@shared/enums/filter-client.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { PriceCertificateComponent } from '@app/@components/price-certificate/price-certificate.component';
import { SolicitudeFileModel } from '../../models/solicitude-file.model';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { PaginatorFilterService } from '@app/@shared/services/paginator-filter.service';
import { PaginatorFilterModel } from '@app/@shared/model/paginator-filter.models';
import { SolicitudeSkusDeletedByApprover } from '../../models/solicitude-skus-deleted-by-approver.model';
import { HelperService } from '@app/@shared/services/helper.service';

@Component({
  selector: 'app-pending-creation',
  templateUrl: './pending-creation.component.html',
  styleUrls: ['../../../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
  ],
  providers: [MessageService, DatePipe],
})
export class PendingCreationComponent implements OnInit {
  @ViewChild('priceCertificate') priceCertificate: PriceCertificateComponent;

  private readonly spinner: NgxSpinnerService;
  private readonly solicitudeService: SolicitudeService;
  private readonly router: Router;
  private readonly messageService: MessageService;
  private readonly paginatorService: PaginatorFilterService;
  private readonly helperService: HelperService;

  public rolePermission: any = RolePermissionEnum;
  public objectListSolicitude: SolicitudeCreationModel[];
  public solicitudeStatus: SolicitudeStatus;
  public isVisibleReject = false;
  public isVisibleApproval = false;
  public jsonFilterClient: ImpactAnalysisJsonDetail[] = [];
  public dateOutOfRange = false;
  public solicitudId: number;
  public isVisibleButton = true;
  public showFieldClientFilter : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false);
  public filtersEdit : string = '';
  public isProcess : number ;
  public clientsEdit : any;
  public page = 1;
  public rows  = 10;
  public orderBy  = "";
  public pageLinkSize = 5;
  public paginator: PaginatorFilterModel;
  public objectListSolicitudesDeletedByApprover: SolicitudeSkusDeletedByApprover[];
  public showAccordionSolicitudesDeletedByApprover : boolean = false;

  public pageApproverDeleted = 1;
  public rowsApproverDeleted = 10;
  public paginatorApproverDeleted: PaginatorFilterModel;
  private hasRejected : boolean = false;

  constructor(
    spinner: NgxSpinnerService,
    solicitudeService: SolicitudeService,
    router: Router,
    public datepipe: DatePipe,
    messageService: MessageService,
    paginatorService: PaginatorFilterService,
    helperService: HelperService
  ) {
    this.spinner = spinner;
    this.solicitudeService = solicitudeService;
    this.router = router;
    this.messageService = messageService;
    this.paginatorService = paginatorService;
    this.helperService = helperService;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.paginator = this.paginatorService.getPaginatorValues();
    this.page = this.paginator?.pageNumber ?? 0;
    this.rows = this.paginator?.limitRecords  ?? 10;

    this.paginatorApproverDeleted = this.paginatorService.getPaginatorValues();
    this.pageApproverDeleted = this.paginatorApproverDeleted?.pageNumber ?? 0;
    this.rowsApproverDeleted = this.paginatorApproverDeleted?.limitRecords  ?? 10;


    this.paginatorService.resetPaginatorValues();
    this.solicitudId = this.solicitudeService.getSolicitudeId();
    this.isProcess = this.solicitudeService.getIsCreate();
    this.getSolicitudeById();
    this.getSolicitudeMaterialDeletedByApprover(this.solicitudId);
  }

  /**
   * @description this method is to control visibility the buttons of approval and reject
   * @author Daniel Londoño
   * @sprint 11
   */
  visibilityButtonsApprovalReject(): void {
    this.isVisibleApproval = false;
    this.isVisibleReject = false;
    this.hasRejected = false;
    const totalSku: number = this.objectListSolicitude.length;
    let contApproval = 0;
    let contReject = 0;
    this.objectListSolicitude.forEach((x) => {
      if (x.isApproval === false) {
        contReject++;
      } else {
        if (x.isApproval) {
          contApproval++;
        }
      }
      if (!this.dateOutOfRange && x.effectiveDateStart <= new Date()) {
        this.dateOutOfRange = true;
      }
    });

    if (contApproval + contReject === totalSku) {
      this.isVisibleApproval = true;
    } 
    if (contApproval === totalSku || contReject > 0) {
      this.isVisibleReject = true;
    }
    if(contReject > 0){
      this.hasRejected = true;
    }
  }

  /**
   * @description event to navigate back
   * @author Daniel Londoño
   * @sprint 5
   */
  back(): void {
    setTimeout(() => {
      this.router.navigate(['auth/pending-solicitude']);
    }, 1000);
  }

  /**
   * @description event to send parameters
   * @author Daniel Londoño
   * @sprint 9
   */
  impactAnalyze(object: SolicitudeCreationModel): void {
    if (this.jsonFilterClient) {
      const modelSetDataImpact: DataImpactAnalysisJson =
        new DataImpactAnalysisJson(
          object.guid,
          AnalyticModule.Price,
          object.materialCode,
          this.jsonFilterClient
        );
      this.solicitudeService
        .postSetDataImpactAnalysis(modelSetDataImpact)
        .subscribe(
          (response) => {
            if (response.status) {
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);

              }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            if (error.status === 422) {
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.warning(
                SweetAlert.titleAlertError,
                language === 'es-ES' ? SolicitudeMessage.EXIST_ANALYSIS : SolicitudeMessage.EXIST_ANALYSIS_TRANSLATE
              );
            } else {
              SweetAlertMessageHelpers.exception(error);
            }
          },
          () => {
            this.spinner.hide();
          }
        );
    } else {
      Util.showWarning(
        '',
        `${AnalysisImpactMessage.MESSAGE_WARNING_NO_FOUND_CLIENTS}`
      );
    }
  }

  /**
   * @description event to send parameters
   * @author Daniel Londoño
   * @sprint 14
   */
  getImpactAnalyze(object: SolicitudeCreationModel): void {
    this.spinner.show();
    let auxSatus: number;
    this.paginatorService.setPaginatorValues(new PaginatorFilterModel(this.page, this.rows));
    if(!this.solicitudId){
      this.router.navigate(['auth/pending-solicitude/']);
      return;
    } 
    this.solicitudeService.getImpactAnalysis(object.guid,AnalyticModule.Price)
    .subscribe(
      (response) => {
        this.spinner.hide();
        auxSatus = object.isApproval === true ? 1 : object.isApproval === false ? 2 : 0;
        this.router.navigate(
          ['auth/pending-solicitude/creation/impact-analysis'],
          {
            queryParams: {
              id: object.id,
              sku: object.materialCode,
              newPriceWithOutTax: object.newPriceWithOutTax,
              codeId: object.guid,
              name: object.product,
              price: object.newPsp,
              dateStart: object.effectiveDateStart,
              dateEnd: object.effectiveDateEnd,
              status: auxSatus,
            },
          }
        );
      },
      (error: any) => {
        this.spinner.hide();
        let exist = 0;
        const text = String(error.error.errors);
        const aux = text.split(' ');
        for (let index = 0; index < aux.length; index++) {
          if(aux[index] === 'cod_err_app:10'){
            exist += 1;
          }
        }

        if(exist > 0 && object.statusPost === false){
            this.sendToCalculateMaterialImpactAnalysis(object, true);
        }else{
          Util.showWarning(
            '',
            `${AnalysisImpactMessage.MESSAGE_WARNING_NO_FOUND_ANALYSIS_INFORMATION}`
          );
        }
      }
    );
  }


  /**
   * @description Event of the new price without tax to perform calculations.
   * @author Daniel Londoño
   * @sprint 5
   */
  onCalculationsNewPriceWithOutTax(): void {
    if (this.objectListSolicitude) {
      this.objectListSolicitude.forEach((x) => {
        x.priceAdjustment =
          Number(x.newPriceWithOutTax) - Number(x.currentListPrice);
        if (Number(x.currentListPrice) !== RankNumber.Zero) {
          x.percentage = Number(x.priceAdjustment) / Number(x.currentListPrice);
        } else {
          x.percentage = 0;
        }
      });
    }
  }
  /**
   * @description this method is to get Solicitude with SolicitudeMaterial By Id.
   * @author Daniel Londoño
   * @sprint 5
   */
  getSolicitudeById(): void {
    this.spinner.show();
    const id = this.solicitudeService.getSolicitudeId();
    if (!id) {
      this.spinner.hide();
      this.router.navigate(['auth/pending-solicitude/creation']);
      return;
    }
    this.solicitudeService.getSolicitudeWithDetailById(id).subscribe(
      (response) => {
        if (response.status) {
          if (response.data.status === SolicitudeStatus.InProgress) {
            if (!response.data.solicitudeDetailJson) {
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.error(
                SweetAlert.titleAlertError,
                language === 'es-ES' ? SolicitudeMessage.NOT_SKU : SolicitudeMessage.NOT_SKU_TRANSLATE
              );
              this.router.navigate(['auth/pending-solicitude']);
            }
            if (response.data.filterClientJson) {
              this.jsonFilterClient = <ImpactAnalysisJsonDetail[]>(
                JSON.parse(response.data.filterClientJson)
              );
              this.filtersEdit = response.data.filterClientJson;
            } else if (response.data.solicitudeCustomerJson) {
              this.jsonFilterClient.push(
                this.mapFilterCustomerJson(response.data.solicitudeCustomerJson)
              );
              this.clientsEdit = response.data.solicitudeCustomerJson;
            } else {
              this.jsonFilterClient = null;
            }
            this.objectListSolicitude = response.data.solicitudeDetailJson.map(
              (item) => {
                return {
                  id: item.detailId,
                  solicitudeId: response.data.id,
                  materialCode: item.sku,
                  product: item.materialDescription,
                  currentListPrice: item.currentListPrice,
                  currentPsp: item.currentPsp,
                  newPriceWithOutTax: item.newPriceWithOutTax,
                  priceAdjustment: item.priceAdjustment,
                  newPsp: item.newPsp,
                  effectiveDateStart: new Date(item.effectiveDateStart),
                  effectiveDateEnd: new Date(item.effectiveDateEnd),
                  isApproval: item.isApproval,
                  guid: item.guid,
                  statusPost : false,
                  zofeCode: item.zofeCode,
                  zofeDescription: item.zofeDescription,
                  solicitudeIdOrigin: item.solicitudeIdOrigin,
                  isAutogenerated: item.isAutogenerated,
                  reason: item.reason,
                  isPriceList : item.isPriceList === true ? SolicitudeMessage.PRICE_LIST_LABEL : item.isPriceList === false ? SolicitudeMessage.PRICE_ADJUSMENT : '',
                  isApproverDeleted: item.isApproverDeleted
                };
              }
            );
            this.onCalculationsNewPriceWithOutTax();
            this.visibilityButtonsApprovalReject();
          }

          if (response.data.solicitudeFileJson) {
            this.priceCertificate.downloadFile = new SolicitudeFileModel(
              response.data.solicitudeFileJson[0].id,
              response.data.solicitudeFileJson[0].solicitudeId,
              response.data.solicitudeFileJson[0].route,
              '',
              response.data.solicitudeFileJson[0].hash,
            );
            this.priceCertificate.nameFile = this.priceCertificate.downloadFile.route;
            this.priceCertificate.solicitudeId = this.solicitudId;
          }

        } else {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  mapFilterCustomerJson(
    solicitudeCustomerJson: ISolicitudeCustomer[]
  ): ImpactAnalysisJsonDetail {
    return new ImpactAnalysisJsonDetail(
      Util.newGuid(),
      FilterClient.ClientCode,
      'Cliente',
      this.returnClientCode(solicitudeCustomerJson),
      '',
      '='
    );
  }

  returnClientCode(solicitudeCustomerJson: ISolicitudeCustomer[]): string {
    let concatClientCode = '';
    solicitudeCustomerJson.forEach((customer) => {
      concatClientCode += customer.clientCode + ';';
    });
    return concatClientCode.substring(0, concatClientCode.length - 1);
  }

  /**
   * @description This method reject status solicitude.
   * @author Daniel Londoño
   * @sprint 11
   */
  reject(): void {
    this.solicitudeStatus = SolicitudeStatus.Rejected;
    this.save(SolicitudeMessage.REJECT_SOLICITUDE);
  }
  /**
   * @description This method accept status solicitude.
   * @author Daniel Londoño
   * @sprint 11
   */
  accept(): void {
    this.spinner.show();
    if(!this.hasRejected){
      this.solicitudeStatus = SolicitudeStatus.InRecord;
      this.save(SolicitudeMessage.APPROVAL_SOLICITUDE);
      this.spinner.hide();
    }else{
      this.confirmDeleteSkusRejected();
    }
  }

  /**
   * @description This method for change status solicitude.
   * @author Daniel Londoño
   * @sprint 11
   */
  public save(message: string): void {
    if (
      this.dateOutOfRange &&
      this.solicitudeStatus === SolicitudeStatus.InRecord
    ) {
      message = SolicitudeMessage.DATE_OUT_OF_RANGE;
    }

    Util.optionalMessage(message).then((result: SweetAlertResult) => {
      if (result.value) {
        this.isVisibleButton = false;
        this.spinner.show();
        const statusModel: SolicitudeStatusModel = new SolicitudeStatusModel(
          this.solicitudeService.getSolicitudeId(),
          this.solicitudeStatus,
          ''
        );
        this.approvalSolicitudeOneStep(statusModel);
      }
    });
  }

  private approvalSolicitudeOneStep(statusModel: SolicitudeStatusModel) {
    this.solicitudeService.putApprovalSolicitude(statusModel.id, statusModel)
      .subscribe(
        (response) => {
          this.spinner.hide();
          if (response.status) {
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconSuccess, SweetAlert.titleAlert, language === 'es-ES'
                ? SweetAlert.messageAlertApprovalInProcessSolicitude : SweetAlert.messageAlertApprovalInProcessSolicitudeTranslate);
            
            this.back();
          } else {
            this.isVisibleButton = true;
            SweetAlertMessageHelpers.error(
              SweetAlert.titleAlertError,
              response.message
            );
          }
        },
        (error) => {
          this.isVisibleButton = true;
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }
  private approvalSolicitude(statusModel: SolicitudeStatusModel) {
    this.solicitudeService.putApprovalSolicitudeTemp(statusModel.id, statusModel.status)
      .subscribe(
        (response) => {
          if (response.status) {
            this.solicitudeService
              .putSolicitudeStatus(
                this.solicitudeService.getSolicitudeId(),
                statusModel
              )
              .subscribe(
                (response) => {
                  if (response.status) {
                    const modelNotification: NotificationInformativeModel = new NotificationInformativeModel(
                      Module.Price,
                      this.solicitudeStatus === SolicitudeStatus.Rejected
                        ? NotificationType.System
                        : NotificationType.Informative,
                      this.solicitudeService.getSolicitudeId()
                    );
                    this.solicitudeService
                      .putSolicitudeNotificationInformative(modelNotification)
                      .subscribe(() => {
                        this.spinner.hide();
                        this.back();
                      });
                    const language: string = sessionStorage.getItem('language');
                    if (language === 'es-ES') {
                      this.showToast(SweetAlert.iconSuccess, SweetAlert.titleAlert, this.solicitudeStatus === SolicitudeStatus.InRecord
                        ? SweetAlert.messageAlertAcceptSolicitude : SweetAlert.messageAlertRejectSolicitude);
                    } else {
                      this.showToast(SweetAlert.iconSuccess, SweetAlert.titleAlertTranslate, this.solicitudeStatus === SolicitudeStatus.InRecord
                        ? SweetAlert.messageAlertAcceptSolicitudeTranslate : SweetAlert.messageAlertRejectSolicitudeTranslate);
                    }
                  } else {
                    this.spinner.hide();
                    this.isVisibleButton = true;

                    SweetAlertMessageHelpers.error(
                      SweetAlert.titleAlertError,
                      response.message
                    );
                  }
                },
                (error) => {
                  this.isVisibleButton = true;
                  this.spinner.hide();
                  SweetAlertMessageHelpers.exception(error);
                }
              );
          } else {
            this.isVisibleButton = true;
            this.spinner.hide();
            SweetAlertMessageHelpers.error(
              SweetAlert.titleAlertError,
              response.message
            );
          }
        },
        (error) => {
          this.isVisibleButton = true;
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }

  /**
   * @description This method shows the toast control.
   * @author Daniel Londoño
   * @sprint 6
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  loadPaginators(event: any) {
      let pageAux = event.first/event.rows;
      this.page = pageAux + 1;
      this.rows = event.rows;
  }

  loadPaginatorsApproverDeleted(event: any) {
    let pageAux = event.first/event.rows;
    this.pageApproverDeleted = pageAux + 1;
    this.rowsApproverDeleted = event.rows;
}

  /**
   * @description Recalculate Material Impact Analisys
   * @author WArboleda
   * @sprint 57
   */
  recalculateImpactAnalysis(object: SolicitudeCreationModel): void {
    this.spinner.show();
    object.guid = String(Util.newGuid());
    object.isApproval = null;
    object.isPriceList = null;

    this.solicitudeService.putSolicitudeMaterial(object)
    .subscribe(
      (response) => {
        this.spinner.hide();
        if (response.status) {
          const language: string = sessionStorage.getItem('language');
          let message = language === 'es-ES' ? String(SweetAlert.messageRecalculateImpactAnalysis) : String(SweetAlert.messageRecalculateImpactAnalysisTranslate);
          message = message.replace("{materialCode}", this.materialCodeZeroLess(object.materialCode));
          Util.showSuccess(
            '',
            message
          );
          this.visibilityButtonsApprovalReject();
          this.sendToCalculateMaterialImpactAnalysis(object, false);
        }
      },
      (error: any) => {
        this.spinner.hide();
        if (error.status === 422) {
          SweetAlertMessageHelpers.exception(error);
        }else{
          Util.showWarning(
            '',
            `${AnalysisImpactMessage.MESSAGE_WARNING_NO_FOUND_ANALYSIS_INFORMATION}`
          );
        }
      }
      );
      this.spinner.hide();
  }

  /**
   * @description event to send parameters
   * @author WArboleda
   * @sprint 57
   */
  private sendToCalculateMaterialImpactAnalysis(object: SolicitudeCreationModel, showMessage: boolean) : void{
    this.spinner.show();
    object.statusPost = true;
    
    const modelSetDataImpact: DataImpactAnalysisJson =
    new DataImpactAnalysisJson(
      object.guid,
      AnalyticModule.Price,
      object.materialCode,
      this.jsonFilterClient
    );
    this.solicitudeService.postSetDataImpactAnalysis(modelSetDataImpact)
    .subscribe(
      (response) => {
        this.spinner.hide();
        if (response.status) {
          if(showMessage){
            const language: string = sessionStorage.getItem('language');
            Util.showWarning(
              language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
              language === 'es-ES' ? SweetAlert.mesagePendingCreation : SweetAlert.mesagePendingCreationTranslate
            );
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status === 422) {
          SweetAlertMessageHelpers.exception(error);
        }else{
          Util.showWarning(
            '',
            `${AnalysisImpactMessage.MESSAGE_WARNING_NO_FOUND_ANALYSIS_INFORMATION}`
          );
        }
      }
    );
  }

  /**
   * @description Delete Material from Solicitude
   * @author WArboleda
   * @sprint 57
   */
  deleteImpactAnalyze(object: SolicitudeCreationModel): void {
    let isSpanishLanguage : boolean = sessionStorage.getItem('language')  === 'es-ES';

    if(this.objectListSolicitude.length == 1){
      Util.showWarning(
        '',
        isSpanishLanguage ?  SweetAlert.messageInfoLastSku : SweetAlert.messageInfoLastSkuTranslate
        );

    }else{
      const message = isSpanishLanguage ? SweetAlert.messageConfirmDeleteSku: SweetAlert.messageConfirmDeleteSkuTranslate;

      SweetAlertMessageHelpers.confirmDelete("", message).then((result: SweetAlertResult) => {
        if(result.value){
          this.spinner.show();
          this.solicitudeService.deleteSolicitudeMaterialIdByApprover(String(object.id)).subscribe(
            (response) => {
              if (response.status) {
                this.showToast(
                  SweetAlert.iconSuccess,
                  '',
                  isSpanishLanguage  ? SweetAlert.messageConfirmDeleteSkuSucessful : SweetAlert.messageConfirmDeleteSkuSucessfulTranslate);
                  this.getSolicitudeById();
                  this.getSolicitudeMaterialDeletedByApprover(object.solicitudeId);
                }
                this.spinner.hide();
            },
            (error: HttpErrorResponse) => {
              if (error.status === 422) {
                SweetAlertMessageHelpers.exception(error);
              }else{
                Util.showWarning(
                  '',
                  isSpanishLanguage ? SweetAlert.messageConfirmDeleteSkuError : SweetAlert.messageConfirmDeleteSkuErrorTranslate
                  );
              }
              this.spinner.hide();
            }
          );
        }
      });

    }

  }

  /**
   * @description Get Solicitude Material Deleted By Approver
   * @author WArboleda
   * @sprint 57
   */
  getSolicitudeMaterialDeletedByApprover(solicitudeId : number) : void {
    if(solicitudeId === undefined || solicitudeId === null){
      return;
    }
    this.spinner.show();
    this.solicitudeService.getSolicitudeMaterialDeletedByApprover(solicitudeId).subscribe(
      (response) => {
        if (response.status) {
          this.objectListSolicitudesDeletedByApprover = response.data.map(
            (item: { currentListPrice: number; currentPsp: number; dateApproverDeleted: Date; deleted: boolean; detailId: number; effectiveDateStart: Date; 
              effectiveDateEnd: Date; guid: string; isApproval: boolean; isApproverDeleted: boolean; isAutogenerated: boolean;
              materialCode: string; materialDescription: string; newPriceWithOutTax: number; newPsp: number; priceAdjustment: number;
              reason: string; solicitudeId: number; solicitudeIdOrigin: number; statusDomainId: number; userApproverDeleted: string; 
              zofeCode: string; zofeDescription: string; isPriceList?: boolean;}) => {
                
              return {
                currentListPrice: item.currentListPrice,
                currentPsp: item.currentPsp,
                dateApproverDeleted: new Date(item.dateApproverDeleted),
                deleted: item.deleted,
                detailId : item.detailId,
                effectiveDateStart: new Date(item.effectiveDateStart),
                effectiveDateEnd: new Date(item.effectiveDateEnd),
                guid: item.guid,
                isApproval: item.isApproval,
                isApproverDeleted: item.isApproverDeleted,
                isAutogenerated : item.isAutogenerated,
                isPriceList:  item.isPriceList === true ? SolicitudeMessage.PRICE_LIST_LABEL : item.isPriceList === false ? SolicitudeMessage.PRICE_ADJUSMENT : '',
                materialCode: item.materialCode,
                materialDescription: item.materialDescription,
                newPriceWithOutTax: item.newPriceWithOutTax,
                newPsp: item.newPsp,
                priceAdjustment: item.priceAdjustment,
                reason : item.reason,
                solicitudeId: item.solicitudeId,
                solicitudeIdOrigin : item.solicitudeIdOrigin,
                statusDomainId: item.statusDomainId,
                userApproverDeleted: item.userApproverDeleted,
                zofeCode: item.zofeCode,
                zofeDescription: item.zofeDescription
              }
            }
          );
          if(this.objectListSolicitudesDeletedByApprover.length > 0){
            this.showAccordionSolicitudesDeletedByApprover = true;
          }
        }
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 422) {
          SweetAlertMessageHelpers.exception(error);
        }else{
          Util.showWarning(
            '',
            sessionStorage.getItem('language')  === 'es-ES' ? SweetAlert.messageSkusDeletedByApproverError : SweetAlert.messageSkusDeletedByApproverErrorTranslate
            );
        }
        this.spinner.hide();
      }
    );
  }

  /**
   * @description Confirm deleting for rejected Skus
   * @author WArboleda
   * @sprint 57
   */
  confirmDeleteSkusRejected() : void{
    let isSpanishLanguage : boolean = sessionStorage.getItem('language')  === 'es-ES';

    const message = isSpanishLanguage ? SweetAlert.messageConfirmDeleteSkusRejected : SweetAlert.messageConfirmDeleteSkusRejectedTranslate;

    SweetAlertMessageHelpers.confirmDelete("", message).then((result: SweetAlertResult) => {
      if(result.value){
        this.deleteSkusRejected(isSpanishLanguage);
      }
    });
    this.spinner.hide();
  }

  /**
   * @description Delete all Rejected Skus
   * @author WArboleda
   * @sprint 57
   */
  deleteSkusRejected(isSpanishLanguage : boolean): void{

    let skusRejected : string[] = [];

    this.objectListSolicitude.forEach((x) => {
      if (x.isApproval === false) {
        skusRejected.push(String(x.id));
      } 
    });

    let joinedSkusToDelete = skusRejected.join();


    const message = isSpanishLanguage ? SweetAlert.messageConfirmDeleteRejectedSku : SweetAlert.messageConfirmDeleteRejectedSkuTranslate;

    SweetAlertMessageHelpers.confirmDelete("", message).then((result: SweetAlertResult) => {
      if(result.value){
        this.spinner.show();
        this.solicitudeService.deleteSolicitudeMaterialIdByApprover(joinedSkusToDelete).subscribe(
          (response) => {
            if (response) {
              this.showToast(
                SweetAlert.iconSuccess,
                '',
                isSpanishLanguage  ? SweetAlert.messageConfirmDeleteRejectedSkuSucessful : SweetAlert.messageConfirmDeleteRejectedSkuSucessfulTranslate);
              }
              this.getSolicitudeById();
              this.getSolicitudeMaterialDeletedByApprover(this.solicitudId);
              this.spinner.hide();
          },
          (error: HttpErrorResponse) => {
            if (error.status === 422) {
              SweetAlertMessageHelpers.exception(error);
            }else{
              Util.showWarning(
                '',
                isSpanishLanguage ? SweetAlert.messageConfirmDeleteRejectedError : SweetAlert.messageConfirmDeleteRejectedErrorTranslate
                );
            }
            this.spinner.hide();
          }
        );
      }
    });
  }

  materialCodeZeroLess(value: string): string{
    return this.helperService.materialCodeZeroLess(value);
   }
}
