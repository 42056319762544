import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertResult } from 'sweetalert2';
import { IRequestWithNovelty } from './interfaces/request-with-novelty.interface';
import { IVarkeys } from './interfaces/varkeys-novelty.interface';
import { RequestsWithNoveltyService } from './requests-with-novelty.service';
import { Util } from '@app/@shared/util';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { ApplicationConstants } from '@app/app.constants';
import { MessageService } from 'primeng/api';
import { RequestNoveltySendModel } from './models/request-novelty-send-model';
@Component({
  selector: 'app-requests-with-novelty',
  templateUrl: './requests-with-novelty.component.html',
  styleUrls: ['./requests-with-novelty.component.css']
})
export class RequestsWithNoveltyComponent implements OnInit {
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly requestsWithNoveltyService : RequestsWithNoveltyService;

  public displayModal = false;
  public rolePermission: any = RolePermissionEnum;
  public objectVarkeysNovelty : IRequestWithNovelty[];
  public selectedVarkey : IRequestWithNovelty;
  public varkeysList : IVarkeys[];
  public SolicitudeId : number;

  constructor(
    spinner: NgxSpinnerService,
    messageService: MessageService,
    requestsWithNoveltyService : RequestsWithNoveltyService) {
      this.spinner = spinner;
      this.messageService = messageService;
      this.requestsWithNoveltyService = requestsWithNoveltyService;
     }

  ngOnInit(): void {
    this.getAllVarkeys();
  }

  public showModal(object : IRequestWithNovelty ){
    this.varkeysList = object.varkeys;
    this.SolicitudeId = object.id;
    this.selectedVarkey = object;
    this.displayModal = true;
  }

  public hideModal(): void {
    this.displayModal = false;
  }


  deleteItem(object :IVarkeys ){
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.requestsWithNoveltyService.deleteVarkeyUnmanaged(this.SolicitudeId, object.varkeyCode )
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                const index = this.varkeysList.findIndex((elm) => elm.varkeyCode === object.varkeyCode);
                if(index !== -1)
                this.varkeysList.splice(index,1);
                this.getAllVarkeys();
                this.displayModal = false;
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
      this.spinner.hide();
    });
  }

  getAllVarkeys(){
    this.spinner.show();
    this.requestsWithNoveltyService.getAll().subscribe(
      (response) => {
        this.objectVarkeysNovelty = response.data;
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  getistVarkeys(){
    if(this.varkeysList.length === 0 || this.varkeysList === undefined || this.varkeysList === null){
      const index = this.objectVarkeysNovelty.findIndex((elm) => elm.id === this.SolicitudeId);
      this.objectVarkeysNovelty.splice(index,1);
    }
  }

  getModelAdd( object : IVarkeys) : RequestNoveltySendModel{
    const model : RequestNoveltySendModel =
    new RequestNoveltySendModel (
      this.SolicitudeId,
      object.varkeyCode,
      object.materialCode,
      object.deleted
    );
    return model;
  }

  sendVarkey(object : IVarkeys){

    this.spinner.show();
   	const model = this.getModelAdd(object);
      this.requestsWithNoveltyService.postSendVarkeyUnmanaged(model)
        .subscribe(
          (response) => {
            this.spinner.hide();
            this.getAllVarkeys();
            this.displayModal = false;
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconInfo,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertSend : SweetAlert.messageAlertSendTranslate);
          },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );

  }


  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

}
