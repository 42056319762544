<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/exclusion'">
            {{ "ExclusionComponent.ExclusionManagement" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "ExclusionComponent.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <h1 class="pstntitle">{{ "ExclusionComponent.Title" | translate }}</h1>
    </div>
    <form id="formExclusions" [formGroup]="formExclusions">
      <div class="row">

        <div class="col-sm-3 mt-3 without-border">
          <div class="form-group">
            <label>{{ "ExclusionComponent.ReasonExclusion" | translate }}</label>
            <input #reasonExclusion id="reasonExclusion" formControlName="ReasonExclusion" autocomplete="off"
              class="form-control" pInputText required [readonly]="isProcess == 'detail'"
              (keyup)="keyupReasonExclusion($event)" />
            <small id="reasonExclusionValidator"
            *ngIf="formExclusions.controls['ReasonExclusion'].invalid &&
                  (formExclusions.controls['ReasonExclusion'].dirty ||
                  formExclusions.controls['ReasonExclusion'].touched)"
              class="p-error">{{"ExclusionComponent.ReasonMsgValidate"|
              translate}}</small>
          </div>
        </div>

        <div class="col-sm-3 mt-3">
          <div class=" form-group">
            <label>{{ "ExclusionComponent.TypeComercialActivity" | translate }}</label>
            <div class="form-group">
              <p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll"
                [virtualScroll]="true" [options]="objectPromotionalActivity" formControlName="TypeComercialActivity"
                [filter]="true" placeholder="{{'ExclusionComponent.TypeComercialActivityPlaceHolderCombo' | translate}}"
                optionLabel="label" required optionValue="value" display="chip" [style]="{width: '100%'}"
                [disabled]="this.isProcess == 'detail'" (onChange)="changeTypeOfPrice()"></p-multiSelect>
              <small id="typeComercialActivityValidator"
              *ngIf="formExclusions.controls['TypeComercialActivity'].invalid &&
                (formExclusions.controls['TypeComercialActivity'].dirty ||
                formExclusions.controls['TypeComercialActivity'].touched)"
                class="p-error">{{"ExclusionComponent.TypeComercialActivityMsgValidate"|
                translate}}</small>
            </div>
          </div>
        </div>

        <div class="col-sm-3 mt-3">
          <div class=" form-group">
            <label>{{ "ExclusionComponent.Channel" | translate }}</label>
            <div class="form-group">
              <p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll"
                [virtualScroll]="true" 
                [options]="objectChannel" 
                formControlName="Channel" 
                [filter]="true"
                placeholder="{{'ExclusionComponent.ChannelPlaceHolderCombo' | translate}}" optionLabel="label"
                optionValue="value" 
                required 
                display="chip" 
                [style]="{width: '100%'}" 
                [disabled]="this.isProcess == 'detail'">
              </p-multiSelect>
              <small id="channelValidator"
              *ngIf="formExclusions.controls['Channel'].invalid &&
                (formExclusions.controls['Channel'].dirty ||
                formExclusions.controls['Channel'].touched)" class="p-error">{{"ExclusionComponent.ChannelMsgValidate"|
                translate}}</small>
            </div>
          </div>
        </div>

        <div class="col-sm-3 mt-3">
          <div class=" form-group">
            <label>{{ "ExclusionComponent.Validity" | translate }}</label>
            <div class="form-group">
              <p-calendar #cal2 id="rangeDateControl" 
                          [minDate]="today" 
                          formControlName="rangeDateControl"
                          [showIcon]="true" 
                          selectionMode="range" 
                          [showOtherMonths]="false" 
                          [monthNavigator]="true" 
                          [yearNavigator]="true"
                          [yearRange]="getYearRange()" 
                          inputId="icon" 
                          placeholder="{{ 'RangeDate' | translate }}"
                          dateFormat="dd/mm/yy" 
                          [disabledDates]="invalidDates" 
                          [disabled]="getProcessEnum() == comercialActivityEnum.view ? true : false">
                          <p-header class="p-2">
                            <div class="form-group">
                                <i id="btnClose"
                                  style="margin-left: 350px; color: #009CDD; background-color: #ffffff"
                                  class="uil uil-times enviar"
                                  (click)="cal2.hideOverlay()"
                                  aria-hidden="true">
                                </i>
                            </div>
                          </p-header>
                          <p-footer class="p-2">
                            <div class=" form-group">
                              <div class="col-md-12">
                                <p-button label="Limpiar"
                                          *ngIf="getProcessEnum() == comercialActivityEnum.view ? false : true"
                                          (click)="clear()">
                                </p-button>
                                <p-button label="Agregar"
                                          *ngIf="getProcessEnum() == comercialActivityEnum.view ? false : true"
                                          (click)="addRangeDate(cal2.hideOverlay())">
                                </p-button>
                              </div>
                            </div>
                          </p-footer>
              </p-calendar>
            </div>
          </div>
        </div>



        <div class="col-md-12 without-border" *ngIf="isPromotion">
          <div class="row">
            <div class="col-md-2 without-border">
              <label for="Purchase">{{ 'ExclusionComponent.Purchase' | translate }}</label>
              <div class="form-group center">
                <p-inputSwitch formControlName="Purchase" [disabled]="isProcess == 'detail'"></p-inputSwitch>
              </div>
            </div>
            <div class="col-md-2">
              <label for="Promotion">{{ 'ExclusionComponent.Promotion' | translate }}</label>
              <div class="form-group">
                <p-inputSwitch formControlName="Promotion" [disabled]="isProcess == 'detail'"></p-inputSwitch>
              </div>
            </div>
            <div class="col-md-3" *ngIf="hiddenBiggestPromoBuy">
              <label for="BiggestPromoBuy">{{ 'ExclusionComponent.BiggestPromoBuy' | translate }}</label>
              <div class="form-group">
                <p-inputSwitch formControlName="BiggestPromoBuy" [disabled]="isProcess == 'detail'"></p-inputSwitch>
              </div>
            </div>
            <div class="col-md-3">
              <label for="PromoClient">{{ 'ExclusionComponent.PromoClient' | translate }}</label>
              <input #promoClientExclusion type="number" id="PromoClient" [readonly]="isProcess == 'detail'"
                formControlName="PromoClient" autocomplete="off" class="form-control" pInputNumber />
            </div>
          </div>
        </div>

        <div class="col-md-12 without-border" *ngIf="isDiscountKind">
          <div class="col-md-3 without-border">
            <div class="form-group">
              <label for="exampleInputEmail1">{{ 'ExclusionComponent.CapDiscount' | translate }}</label>
              <input #capDiscountExclusion type="text" id="CapDiscount" [readonly]="isProcess == 'detail'"
                (keyup)="keyUpCapDiscount($event)" formControlName="CapDiscount" autocomplete="off" class="form-control"
                pInputNumber />
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</section>

<!-- INICIO FILTROS -->
<section id="Products" *ngIf="!isExclusionClients">
  <div class="row mt-5">
    <div class=" container pstn_container container-fluid container_size">
      <div class="row">
        <app-hierarchy-advanced-filters [isMultiFilter]="isMultiFilter" (eventClickFilters)="eventClickFilters($event)"
          [isProcess]="this.isProcess == 'detail'? 3:''" [editFiltersObjectList]="editFiltersObjectList">
        </app-hierarchy-advanced-filters>
        <div class="row mt-5"></div>
      </div>
    </div>
  </div>
</section>
<!-- FIN FILTROS -->

<!-- INICIO TABLA -->
<section id="ResultProducts" *ngIf="!isExclusionClients">
  <div class="row mt-5">
    <div class=" container pstn_container container-fluid container_size">
      <div class="row">
        <app-exclusions-table-filter [productFilterListPromotion]="productFilterListPromotion"
          (deleteGroupEvent)="deleteGroupAction($event)" [isProcess]="this.isProcess == 'detail'? 3:''">
        </app-exclusions-table-filter>
        <div class="row mt-5"></div>
      </div>
    </div>
  </div>
</section>
<!-- FIN TABLA -->
<section id="Clients" *ngIf="isExclusionClients">
  <app-assing-clients 
    [isProcess]="getProcessEnum()" 
    [filtersEdit]="jsonFilterClients" 
    [validateFilter]="false" 
    (eventClickBack)="back()"
    (eventClickSendData)="eventClickGetDataSave($event)" 
    (eventClickSendDataEdit)="eventClickUpdateBtn($event)">
  </app-assing-clients>
</section>

<section id="ResultProducts" *ngIf="!isExclusionClients">
  <div class="row mt-5">
    <div class=" container">
      <div class="row justify-content-end">
        <div class="col-lg-4 md-3 mt-3">
          <button type="button"
            class="p-button-rounded pstn_primario w-100"
            (click)="viewDetails()"
            style="background-color: darkgrey; border-color: darkgrey;"
            *ngIf="(rolePermission.read | rolePermission)">
            {{'CancelBtn' | translate}}
          </button>
        </div>

        <!-- Botones de creacion -->
        <!-- div class="col-md-6" *ngIf="this.isProcess != 'detail'">
          <button pButton pRipple type="button" class="pstn_primario" *ngIf="this.isProcess == 'create'"
            label="{{'CreateBtn' | translate}}" style="float: right;" (click)="eventClickSaveBtn()"
            [disabled]="validResult()">
          </button>
          <button pButton pRipple type="button" *ngIf="this.isProcess == 'edit'" class="pstn_primario"
            label="{{'UpdateBtn' | translate}}" style="float: right;" (click)="eventClickUpdateBtn()"
            [disabled]="validResult()">
          </button>
        </!--div -->

        <div class="col-lg-4 md-3 mt-3">
          <button pButton 
            pRipple 
            type="button" 
            class="p-button-rounded pstn_primario w-100" 
            label="{{'NextBtn' | translate}}"
            style="float: right;" (click)="next()">
          </button>
        </div>

      </div>
      <div class="row mt-5"></div>
    </div>
  </div>
</section>

<p-toast></p-toast>
