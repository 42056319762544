import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRequestWithNovelty } from './interfaces/request-with-novelty.interface';
import { RequestNoveltySendModel } from './models/request-novelty-send-model';

@Injectable({
  providedIn: 'root'
})
export class RequestsWithNoveltyService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
   }

  public getAll(): Observable<IResponseService<IRequestWithNovelty[]>> {
    const response$: Observable<IResponseService<IRequestWithNovelty[]>> = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material_rule/varkey_unmanaged`)
      .pipe(
        map((response: HttpResponse<IResponseService<IRequestWithNovelty[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public deleteVarkeyUnmanaged(SolicitudeId : number, Varkey: string){
    const response$: Observable<IResponseService<boolean>> =
    this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material_rule/varkey_unmanaged/${SolicitudeId}/${Varkey}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public postSendVarkeyUnmanaged(body: RequestNoveltySendModel): Observable<IResponseService<string>> {
    const response$: Observable<IResponseService<string>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/send_varkey_unmanaged
        `,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<string>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
