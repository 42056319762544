import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './@core/login/login.component';
import { MainComponent } from './@core/main/main.component';
import { SelectProfileModuleComponent } from './@modules/administration/select-profile-module/select-profile-module.component';
import { SelectedActivityComponent } from './@modules/dashboard/selected-activity/selected-activity.component';
import { NegotiationComponent } from './@modules/negotiations/negotiation/negotiation.component';
import { NegotiationCreateComponent } from './@modules/negotiations/negotiation/create/negotiation-create.component';
import { NotificationPanelComponent } from './@modules/dashboard/notification-panel/notification-panel.component';

export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'auth', component: MainComponent, canActivate: [MsalGuard],
    children: [
      { path: 'select-profile-module', component: SelectProfileModuleComponent, canActivate: [MsalGuard] },
      { path: '', component: SelectedActivityComponent, canActivate: [MsalGuard] },

      /* Administration */
      { path: '', loadChildren: () => import('./@modules/administration/administration.module')
        .then(a => a.AdministrationModule)},

      /* Prices  */
      { path: '', loadChildren: () => import('./@modules/prices/prices.module')
        .then(p => p.PricesModule)},

        
      /* Promotional Activity */
      { path: '', loadChildren: () => import('./@modules/promotionalactivity/promotionalactivity.module')
        .then(pa => pa.PromotionalactivityModule)},


      /*Negotiations*/
      { path: '', loadChildren: () => import('./@modules/negotiations/negotiations.module')
        .then(pa => pa.NegotiationsModule)},

      /*Dashboard*/
      { path: 'notification-panel', component: NotificationPanelComponent, canActivate: [MsalGuard] },

      /*Dashboard*/
    ]
  },
  // { path: 'error', component: AppErrorComponent },
  // { path: 'accessdenied', component: AppAccessdeniedComponent },
  // { path: '404', component: AppNotfoundComponent },
  // { path: 'wizard', component: AppWizardComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
