<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active"
            aria-current="page">
          {{ "SolicitudComponent.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <h1 class="pstntitle">{{ "SolicitudComponent.Title" | translate }}</h1>
    </div>
    <div class="row">
      <div class="col-md-3 mt-3"></div>
      <div class="col-md-3 mt-3">
        <button id="addWithdrawal"
                pButton
                pRipple
                type="button"
                label="{{ 'SolicitudComponent.EliminatePriceDifferentialBtn' | translate }}"
                class="p-button-rounded pstn_secundario w-100"
                (click)="addDifferential()"
                *ngIf="(rolePermission.create | rolePermission)"></button>
      </div>
      <div class="col-md-3 mt-3">
        <button id="addWithdrawal"
                pButton
                pRipple
                type="button"
                label="{{ 'SolicitudComponent.WithdrawalSolicitudeBtn' | translate }}"
                class="p-button-rounded pstn_secundario w-100"
                (click)="addWithdrawal()"
                *ngIf="(rolePermission.create | rolePermission)"></button>
      </div>
      <div class="col-md-3 mt-3">
        <button id="addCreation"
                pButton
                pRipple
                type="button"
                label="{{ 'SolicitudComponent.CreationSolicitudeBtn' | translate }}"
                class="p-button-rounded pstn_secundario w-100"
                (click)="addCreation()"
                *ngIf="(rolePermission.create | rolePermission)"></button>
      </div>
    </div>

    <div class="mt-3">
      <app-shared-advanced-filter #sharedAdvancedFilter
                                  [showFieldsComponent]="false"
                                  [validateFilter]="false"
                                  [showFieldsGeneral]="showFieldsGeneral"
                                  [showFieldsClient]="showFieldsClient"
                                  [advancedSearchFilter]="advanceFilter"
                                  (removeListAdvancedFilter)="getDataListAll($event)"
                                  (clearListAdvancedFilter)="getDataListAll($event)">
      </app-shared-advanced-filter>
    </div>

      <form id="formSolicitude"
          [formGroup]="formSolicitude">
      <div class="row mt-4">
        <div class="pstn_container container-fluid">

          <div class="row col-md-12">

            <div class="col-md-3 mt-1 calendarSize">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input pInputText
                       type="text"
                       formControlName="searchFilter"
                       (keyup)="loadCustomers2()"
                       (keyup.enter)="getAll()"
                       placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
            <div class="col-md-1 mt-1">
              <button 
                      (click)="getAll(true)"
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-search"
                      class="
                  p-button-rounded p-button-primary p-button-outlined
                "></button>
            </div>
            <div class="col-md-5 mt-1"></div>
          </div>

          <!-- PrimeNg Table -->
          <p-table #dt
                   name="gridSolicitude"
                   [value]="objectList"
                   dataKey="id"
                   [paginator]="true"
                   [rows]="rows"
                   [(first)]="page"
                   (onPage)="loadPaginators($event)"
                   [lazy]="true"
                   [totalRecords]="pageLinkSize" 
                   [rowsPerPageOptions]="[10,25,50,100]"
                   paginatorPosition="bottom"
                   styleClass="p-datatable-customers"
                   currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
                   [globalFilterFields]="['id', 'typeDescription', 'statusDescription', 'sku']">
            <ng-template pTemplate="caption"></ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 3rem"></th>
                <th pSortableColumn="sku" style="display:none;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    <!--sku-->
                    <p-sortIcon field="sku"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="id">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.Id" | translate }}
                    <p-sortIcon field="id"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="typeDescription">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.SolicitudeType" | translate }}
                    <p-sortIcon field="typeDescription"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="statusDescription">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ "SolicitudComponent.Status" | translate }}
                    <p-sortIcon field="statusDescription"></p-sortIcon>
                  </div>
                </th>
                <th>{{ "Actions" | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body"
                         let-object
                         let-expanded="expanded">
              <tr [pSelectableRow]="object" style="height: 40px !important;" >

                <td>
                 <!-- <button type="button"
                          pButton
                          pRipple
                          [pRowToggler]="object"
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="
                      expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                    "></button>-->
                </td>
                <td style="display:none;">
                  {{ object.sku }}
                </td>
                <td>
                  {{ object.id }}
                </td>
                <td>
                  {{ 'DomainPrices.Domain-'+object.type | translate }}
                  <!-- {{ object.typeDescription }} -->
                </td>
                <td>
                  {{ 'DomainPrices.Domain-'+object.status | translate}}
                  <!-- {{ object.statusDescription }} -->
                </td>
                <td>
                  <i id="btnAnalyze"
                  (click)="detailSolicitude(object)"
                  class="uil uil-search-alt Editar"
                  pTooltip="{{'Detail' | translate}}"
                  aria-hidden="true"
                  *ngIf="rolePermission.read | rolePermission"></i>
                  <i
                  aria-hidden="true"
                  id="btnEdit"
                  class="uil uil-edit Editar"
                  pTooltip="{{'Edit' | translate}}"
                  (click)="edit(object)"
                    *ngIf="(object.status === solicitudeStatusEnum.Registered ||
                            object.status === solicitudeStatusEnum.Modification ||
                            object.status === solicitudeStatusEnum.Rejected ||
                            object.status === solicitudeStatusEnum.Temporary) && (rolePermission.update | rolePermission)"
                  ></i>
                <button id="btnCopy"
                    type="button"
                    pTooltip="{{'Copy' | translate}}"
                    (click)="copySolicitude(object)"      
                    style="border: none; background: transparent;"                        
                  *ngIf="(rolePermission.update | rolePermission) && 
                          object.type != solicitudeTypeEnum.WithdrawalDiferential">
                <i class="uil uil-copy Editar copiar" aria-hidden="true"></i>
              </button>
                  <i
                  aria-hidden="true"
                  id="btnHistory"
                  class="uil uil-history historico"
                  pTooltip="{{'Historical' | translate}}"
                  (click)="history(object.id)" 
                  *ngIf="rolePermission.read | rolePermission"></i>
                  <i
                  aria-hidden="true"
                  id="btnMessage"
                  class="uil uil-message enviar"
                  pTooltip="{{'SendRequest' | translate}}"
                  (click)="sendSolicitude(object)"
                    *ngIf="(object.status == solicitudeStatusEnum.Registered || object.status == solicitudeStatusEnum.Modification) && (rolePermission.create | rolePermission)"
                  ></i>
                  <i
                  aria-hidden="true"
                  id="btnDelete"
                  class="uil uil-trash-alt eliminar"
                  pTooltip="{{'Delete' | translate}}"
                  (click)="delete(object.id)"
                  *ngIf="object.status != solicitudeStatusEnum.InProgress &&
                         object.status != solicitudeStatusEnum.InRecord &&
                         object.status != solicitudeStatusEnum.Managed &&
                         object.status != solicitudeStatusEnum.ManagedWithNovelty &&
                         !object.isManagedWithdrawal &&
                         (rolePermission.delete | rolePermission)"
                  ></i>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion"
                         let-object>
              <tr>
                <td colspan="5">
                  <div class="p-p-3">
                    <p-table [value]="object.solicitudeDetailJson"
                             [scrollable]="true"
                             scrollHeight="300px"
                             dataKey="id">
                      <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="sku">
                  {{ "SolicitudComponent.Sku" | translate }}
                  <p-sortIcon field="sku"></p-sortIcon>
                </th>
                <th pSortableColumn="materialDescription">
                  {{
                  "SolicitudComponent.MaterialDescription"
                  | translate
                  }}
                  <p-sortIcon field="materialDescription"></p-sortIcon>
                </th>
                <th pSortableColumn="currentListPrice">
                  {{
                  "SolicitudComponent.CurrentListPrice" | translate
                  }}
                  <p-sortIcon field="currentListPrice"></p-sortIcon>
                </th>
                <th pSortableColumn="priceProposed">
                  {{ "SolicitudComponent.PriceProposed" | translate }}
                  <p-sortIcon field="priceProposed"></p-sortIcon>
                </th>
                <th pSortableColumn="currentPsp">
                  {{ "SolicitudComponent.CurrentPsp" | translate }}
                  <p-sortIcon field="currentPsp"></p-sortIcon>
                </th>
                <th pSortableColumn="proposedPsp">
                  {{ "SolicitudComponent.ProposedPsp" | translate }}
                  <p-sortIcon field="proposedPsp"></p-sortIcon>
                </th>
                <th pSortableColumn="validity">
                  {{ "SolicitudComponent.Validity" | translate }}
                  <p-sortIcon field="validity"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body"
                         let-solicitudeDetailJson>
              <tr>
                <td>{{ solicitudeDetailJson.sku }}</td>
                <td>
                  {{ solicitudeDetailJson.materialDescription }}
                </td>
                <td>
                  {{
                  solicitudeDetailJson.currentListPrice
                  | currency: "USD" || ""
                  }}
                </td>
                <td>
                  {{
                  solicitudeDetailJson.newPriceWithOutTax
                  | currency: "USD" || ""
                  }}
                </td>
                <td>
                  {{
                  solicitudeDetailJson.currentPsp
                  | currency: "USD" || ""
                  }}
                </td>
                <td>
                  {{
                  solicitudeDetailJson.newPsp
                  | currency: "USD" || ""
                  }}
                </td>
                <td>
                  {{
                  solicitudeDetailJson.effectiveDateStart
                  | date: "dd/MM/yyyy"
                  }}
                  -
                  {{
                  solicitudeDetailJson.effectiveDateEnd
                  | date: "dd/MM/yyyy"
                  }}
                </td>
              </tr>
            </ng-template>
            <!-- <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">
                  {{ "ElementsNotFound" | translate }}
                </td>
              </tr>
            </ng-template> -->
          </p-table>
        </div>
        </td>
        </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td *ngIf="validity" colspan="4">{{ "ElementsNotFound" | translate }}</td>
              </tr>
            </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
      </div>
  </div>
  </form>
  </div>
</section>


<p-dialog header="{{ 'SolicitudComponent.HeaderModalSolicitudeHistory' | translate}}"
          [(visible)]="displayModal"
          [modal]="true"
          [style]="{ width: '60vw' }"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false">
  <ng-template pTemplate="content">


  </ng-template>

  <!-- PrimeNg Table -->
  <p-table #dt
           name="gridSolicitudeHistory"
           [value]="objectListSolicitudeHistory"
           dataKey="solicitudeId"
           [paginator]="false"
           [rows]="10"
           currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
           [rowsPerPageOptions]="[10, 25, 50, 100]"
           paginatorPosition="bottom"
           styleClass="p-datatable-customers"
           [globalFilterFields]="['statusDescription', 'creationUser', 'creationDate','commentary']">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{
            "SolicitudComponent.History.StatusDescription" | translate
            }}
            <p-sortIcon field="statusDescription"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{
            "SolicitudComponent.History.CreationUser"
            | translate
            }}<p-sortIcon field="creationUser"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="creationDate" style="width: 25%;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{
            "SolicitudComponent.History.CreationDate"
            | translate
            }}<p-sortIcon field="creationDate"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="commentary" style="width: 40%;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.History.Commentary"| translate}}<p-sortIcon field="commentary"></p-sortIcon>
          </div>
        </th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-object>
      <tr>       
        <td>{{'DomainPrices.Domain-'+object.statusDomainId | translate}}</td>
        <td>{{ object.creationUser }}</td>
        <td>{{ object.creationDate | date: 'short' }}</td>
        <td>{{ object.commentary }}
          <p *ngIf="
          (
            object.statusDomainId == solicitudeStatusEnum.Managed 
            || object.statusDomainId == solicitudeStatusEnum.ManagedWithNovelty 
            || object.statusDomainId == solicitudeStatusEnum.Pending)
          && object.details" 
          style="color: blue; cursor: pointer;" 
          (click)="seeMore(object)">Ver detalles
        </p>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- PrimeNg Table -->


  <ng-template pTemplate="footer">
    <button pButton
            pRipple
            label="{{ 'CloseBtn' | translate}}"
            icon="pi pi-times"
            class="p-button-text"
            (click)="hide2()"></button>
  </ng-template>
</p-dialog>

<p-dialog header="{{ 'Detail' | translate}}"
          [(visible)]="displayModalDetails"
          [modal]="true"
          [style]="{ width: '70vw' }"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false">
  <ng-template pTemplate="content">


  </ng-template>


  <!-- PrimeNg Table -->
  <p-table #dt
           name="gridSolicitudeHistory"
           [value]="objectListSolicitudeDetails"
           dataKey="solicitudeId"
           [paginator]="false"
           [rows]="10"
           [scrollable]="true"
           currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
           [rowsPerPageOptions]="[10, 25, 50, 100]"
           paginatorPosition="bottom"
           styleClass="p-datatable-customers"
           [globalFilterFields]="['solicitudeId', 
                                  'codeMaterial',
                                  'description',
                                  'variablePass', 
                                  'idocNumber', 
                                  'statusIdoc', 
                                  'creationDate',
                                  'condition']">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="solicitudeId" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.RequestId"| translate}}
            <p-sortIcon field="solicitudeId"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="codeMaterial" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.MaterialCode"| translate}}
            <p-sortIcon field="codeMaterial"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="description" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.MaterialDescription"| translate}}
            <p-sortIcon field="description"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="variablePass" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.KeyVariable"| translate}}
            <p-sortIcon field="variablePass"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="idocNumber" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.IdocNumber"| translate}}
            <p-sortIcon field="idocNumber"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="statusIdoc" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.IdocStatus"| translate}}
            <p-sortIcon field="statusIdoc"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="creationDate" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{
            "SolicitudComponent.DateCreated"
            | translate
            }}<p-sortIcon field="creationDate"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="condition" style="width: 180px;">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"SolicitudComponent.ConditionClass"| translate}}
            <p-sortIcon field="condition"></p-sortIcon>
          </div>
        </th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-object>
      <tr>       
        <td style="width: 180px;">{{object.solicitudeId}}</td>
        <td style="width: 180px;">{{object.materialCode}}</td>
        <td style="width: 180px;">{{object.materialDescription}}</td>
        <td style="width: 180px;">{{object.variableKey}}</td>
        <td style="width: 180px;">{{object.sapIdocNumber}}</td>
        <td style="width: 180px;">{{object.statusIdoc}}</td>
        <td style="width: 180px;">{{object.creationDate | date: 'short'  }}</td>
        <td style="width: 180px;">{{object.conditionClass}}</td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- PrimeNg Table -->


  <ng-template pTemplate="footer">
    <button pButton
            pRipple
            label="{{ 'CloseBtn' | translate}}"
            icon="pi pi-times"
            class="p-button-text"
            (click)="hide()"></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showModalCopy" [modal]="true" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">

  <ng-template pTemplate="header">
    <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
      <h5><strong>{{ 'SolicitudComponent.TitleCopy' | translate}}:{{idCopy}}</strong></h5>
    </div>
  </ng-template>

  <ng-template pTemplate="content">

		<div class="row">
			<div class="col-md-12">
        <label>{{ 'SolicitudComponent.SkuCopy' | translate }}: {{sku}}</label>
			</div>
		</div>
    
  </ng-template>
	<ng-template pTemplate="footer">
    <div class="row">
    <div class="col-md-6 mt-1"> 
      <button pButton 
            pRipple
            type="button"
            class="pstn_primario w-100"
            label="{{'CancelBtn' | translate}}"
            (click)="cancelCopy()"
            style="background-color:#e4002b; border-color: #e4002b;">
      </button>
    </div>

    <div class="col-md-6 mt-1"> 
    <button pButton 
            pRipple
            type="button"
						label="{{ 'Copy' | translate}}"
						class="pstn_primario w-100"
      			(click)="createCopy()">
    </button>
  </div> 
  </div>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
