import { ICombo } from '../interfaces/combo.interface';
export class ComboModel implements ICombo {
  /**
   * @author Elkin Vasquez Isenia
   * @sprint 2
   */
  constructor(
    public label: string,
    public value: any
    ) {}
}
