<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'">
            <i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/manage-promotional-activities'">
            {{ "ManagePromotionalActivitiesComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "ManagePromotionalActivitiesComponent.Events.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container" *ngIf="!nextAssignClients">
    <section id="GeneralData">

      <div class="column">
        <div class="col-md-6">
          <h1 class="pstntitle">
            {{ "ManagePromotionalActivitiesComponent.Events.Title" | translate }}
          </h1>
        </div>
        <div class="col-md-6" *ngIf="isProcess == commercialActivityEnum.view || isProcess == commercialActivityEnum.update">
          <h6 class="pstntitle">{{'Comercialactivity' | translate}} : {{ commercialActivityId }}</h6>
        </div>
      </div>

      <!-- GENERAL DATA -->
      <div class="row mt-5">

        <!-- description -->
        <div class="form-check col-md-4">
          <div class="form-group">
            <label>{{ "ManagePromotionalActivitiesComponent.Create.Description" | translate }}</label>
            <input #user id="description" [(ngModel)]="description" autocomplete="off"
              placeholder="{{'ManagePromotionalActivitiesComponent.Create.DescriptionPlaceHolderInput' | translate}}"
              class="form-control" pInputText disabled="true" />
          </div>
        </div>

        <!-- type commercial activity -->
        <div class="form-check col-md-4">
          <div class="form-group">
            <label>{{ "ManagePromotionalActivitiesComponent.Create.TypeCommercialActivity" | translate }}</label>
            <input #user id="typeCommercialActivity" [(ngModel)]="typeCommercialActivity" autocomplete="off"
              class="form-control" pInputText disabled="true" />
          </div>
        </div>

        <!-- Channel -->
        <div class="form-check col-md-4">
          <div class="form-group">
            <label>{{ "ManagePromotionalActivitiesComponent.Create.Channel" | translate }}</label>
            <div class="form-group">
              <p-multiSelect id="channel" [filter]="true" optionLabel="label"
                placeholder="{{'ManagePromotionalActivitiesComponent.Create.ChannelPlaceHolderCombo' | translate}}"
                [options]="objectChannelList" 
                class="multiselect-custom-virtual-scroll multitooltip" 
                [virtualScroll]="true"
                [(ngModel)]="channel" 
                [disabled]="isProcess === commercialActivityEnum.view" optionValue="value" 
                display="chip" 
                [style]="{width: '100%'}">
              </p-multiSelect>
                <small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
                  <ul>
                    <li *ngFor="let index of channelTooltip">{{index.channelDescription}}</li>
                  </ul>
                </small>
            </div>
          </div>
        </div>

				<!-- Sector -->
        <div class="form-check col-md-4" *ngIf="sectorCommercialActivityParam">
          <div class="form-group">
            <label>{{ "ManagePromotionalActivitiesComponent.Create.Sector" | translate }}</label>
            <div class="form-group">
              <p-multiSelect id="typeOfPriceDescription"
                            class="multiselect-custom-virtual-scroll multitooltip"
                            [virtualScroll]="true"
                            [options]="objectSectorList"
                            [(ngModel)]="sector"
                            [disabled]="isProcess === commercialActivityEnum.view"
                            [filter]="true"
                            placeholder="{{'ManagePromotionalActivitiesComponent.Create.SectorPlaceHolderCombo' | translate}}"
                            optionLabel="label"
                            optionValue="value"
                            display="chip"
                            [style]="{width: '100%'}"
                            (onChange)="selectSectorValidate()"></p-multiSelect>
              <small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
                <ul>
                <li *ngFor="let index of sectorTooltip">{{index.sectorDescription}}</li>
                </ul>
                </small>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="Products">
      <div class="row mt-5">
        <div class="pstn_container container-fluid">

          <div class="container-table header">
            <span>{{ 'ManagePromotionalActivitiesComponent.Events.productfilters' | translate }}</span>
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
              (click)="toggleTableFilter()"
              [icon]=" showTableFilter ? 'uil uil-angle-up arrow-up' : 'uil uil-angle-down arrow-down'"></button>
          </div>

          <div class="container-table body" *ngIf="showTableFilter">
            <app-hierarchy-advanced-filters [isProcess]="isProcess"
																						[isMultiFilter]="isMultiFilter"
																						[sector]="sector"
																						[requireSector]="true"
																						[editFiltersObjectList]="editFiltersObjectList"
																						(eventClickFilters)="eventClickFilters($event)">
            </app-hierarchy-advanced-filters>
            <div class="row mt-5"></div>
          </div>

        </div>
      </div>
    </section>

    <section id="TableWhitGroup">
      <div class="row mt-5">
        <div class="pstn_container container-fluid ">

          <div class="container-table header">
            <span>{{ 'ManagePromotionalActivitiesComponent.Events.discountedproducts' | translate }}</span>
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
              (click)="toggleTable()"
              [icon]="showTable ? 'uil uil-angle-up arrow-up' : 'uil uil-angle-down arrow-down'"></button>
          </div>

          <div class="container-table body" *ngIf="showTable">

            <div class="container-table options" *ngIf="isProcess !== commercialActivityEnum.view">
              <span>
                {{ 'ManagePromotionalActivitiesComponent.Events.RemoveSelected' | translate }}
                <i id="btnDeleteBatch" 
                  aria-hidden="true" 
                  pTooltip="{{'Delete' | translate}}" 
                  class="uil uil-trash-alt eliminar"
                  (click)="deleteAllAction()"
                  *ngIf="(rolePermission.delete | rolePermission)">
                </i>
              </span>
            </div>

            <app-tables-with-groups [isProcess]="isProcess" [headersTable]="headersTable"
              [subHeaderTable]="subHeaderTable" [valueTable]="valueTable"
              (deleteGroupEvent)="deleteGroupAction($event)"
              (deleteSelectedEvent)="getSelectList($event)"
              (deleteSelectedGroupEvent)="getSelectGroup($event)"
              ></app-tables-with-groups>

          </div>

        </div>
      </div>
    </section>

    <!-- Calculate -->
		<div class="col-md-12" *ngIf="discountActivityEdit &&
																discountActivityEdit.statusDomainId !== commercialActivityStateEnum.Registered &&
																discountActivityEdit.statusDomainId !== commercialActivityStateEnum.InValidation &&
																discountActivityEdit.statusDomainId !== commercialActivityStateEnum.Invalid">
			<div *ngIf="discountActivityEdit !== undefined && purchaseBaseToCalculate.length > 0">
				<div class="row mt-5"></div>
				<app-commercial-activity-calculate [commercialACtivityId]="discountActivityEdit.id"
																					[statusDomainId]="discountActivityEdit.statusDomainId"
																					[commercialActivityType]="discountActivityEdit.typeCommercialActivityDomainId"
																					[filterClientJson]="filterClientJsonToCalculate"
																					[purchaseBaseToCalculate]="purchaseBaseToCalculate"
                                          [deltaDays]="discountActivityEdit.numberDays"
																					[maxPromotion]="discountActivityEdit.orderLimit"
																					[maxPeriod]="discountActivityEdit.periodLimit"
																					[nClients]="discountActivityEdit.totalClientsFilter"
																					[isCalculate]="discountActivityEdit.isCalculate"
																					(eventSendToCalculate)="eventSendToCalculate($event)"></app-commercial-activity-calculate>
			</div>
		</div>

		<!-- Liberation -->
		<div *ngIf="discountActivityEdit !== undefined &&
              discountActivityEdit.statusDomainId !== commercialActivityStateEnum.Registered &&
              discountActivityEdit.statusDomainId !== commercialActivityStateEnum.InValidation &&
              discountActivityEdit.statusDomainId !== commercialActivityStateEnum.Invalid &&
							discountActivityEdit.statusDomainId !== commercialActivityStateEnum.Validated &&
							isCalculate">
			<div class="row mt-5"></div>

			<div class="col-md-12">
				<div class="accordion" id="accordionCreatePromotion">
					<app-commercial-activity-approval-selected
            [idActivity]="commercialActivityId"
						[commercialActStatus]="discountActivityEdit.statusDomainId"
            [typeCommercialActivity]="discountActivityEdit.typeCommercialActivityDomainId"
            [userCreation]="userCreation">
          </app-commercial-activity-approval-selected>
				</div>
			</div>

      <div class="row mt-5"></div>
			<div class="col-md-12">
				<div class="accordion" id="accordionCreatePromotion">
					<app-commercial-activity-historical [idActivity]="commercialActivityId"
					[commercialActStatus]="discountActivityEdit.statusDomainId"></app-commercial-activity-historical>
				</div>
			</div>

		</div>

		<div class="row mt-5"></div>

    	  <!-- CreateDocumentPromotion -->
			<div id="accordionCreateDocumentPromotion">
				<app-commercial-activity-document [title]="'ManagePromotionalActivitiesComponent.Create.DocumentCreate' | translate"
                                                                                #documentSupport
				                                                                [routeCancel]="routeCancelCreatePromotion"
																				[showFields]="showFieldsCreatePromotion"
																				[promotionEdit]="promotionEdit"
																				[isProcess]="isProcess"
																				[commercialActivityTypeId]="typeCommercialActivity"
                                        [commercialActivityId]="commercialActivityId"
																				[observationEdit]="observationEdit"
																				(eventClickSaveBtn)="eventClickSaveBtn($event)"
																				(eventClickEditBtn)="eventClickEditBtn($event)"
																				(eventClickSendValidateBtn)="eventClickSendValidateBtn()"></app-commercial-activity-document>
			</div>
	
			<div class="row mt-5"></div>
    <!-- CreatePromotion -->
    <div class="accordion" id="accordionCreatePromotion">
			<app-commercial-activity-footer [title]="'ManagePromotionalActivitiesComponent.Create.PromotionCreate' | translate"
                                      [routeCancel]="routeCancelCreatePromotion"
                                      [showFields]="showFieldsCreatePromotion"
                                      [showButtons]="showButtonsCreatePromotion"
                                      [promotionEdit]="promotionEdit"
                                      [isProcess]="isProcess"
                                      [isNext]="true"
                                      [commercialActStatVal]="commercialActStatVal"
                                      (eventClickSaveBtn)="eventClickSaveBtn($event)"
                                      (eventClickEditBtn)="eventClickEditBtn($event)"
                                      (eventClickSendValidateBtn)="eventClickSendValidateBtn()"
                                      (eventChangeData)="prepareFooterForEvent($event)"></app-commercial-activity-footer>
    </div>

    <div class="row mt-5"></div>


  </div>

	<div class="container" *ngIf="nextAssignClients">
    <app-assing-clients [isProcess]="isProcess" [filtersEdit]="filterClientJson" [validateFilter]="true"
        [clientsEdit]="commercialActivityCustomers" [showFields]="showFields" (eventClickBack)="eventClickBackClients()"
        (eventClickSendData)="eventClickSendDataClients($event)"
        (eventClickSendDataEdit)="eventClickSendDataEditClients($event)"></app-assing-clients>
	</div>
</section>

<div *ngIf="displayValidationsDetails">
  <app-commercial-activity-invalid
    [isVisible]="displayValidationsDetails"
    [invalidationDataDetail]="invalidationDataDetail"
    [dateInterruptionActivity]="initialDate"
    [isProcess]="isProcess"
    [filterClientJson]="filterClientJson"
    [activityId]="commercialActivityId"
    (eventClickInvalidate)="registryInvalidateCommercialACtivity($event)"
  ></app-commercial-activity-invalid>
</div>

<div *ngIf="discountActivityEdit">
  <app-commercial-activity-planned
    [isVisible]="discountActivityEdit.statusDomainId == commercialActivityStateEnum.Planned"
    [commercialActivityId]="commercialActivityId"
  ></app-commercial-activity-planned>
</div>
<p-toast></p-toast>
