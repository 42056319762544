import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommercialActivityApprovalStatus, CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommercialActivityApprovalSelectedService } from '../commercial-activity-approval-selected/commercial-activity-approval-selected.service';
import { CommercialActivityApprovalSelectedModel } from '../commercial-activity-approval-selected/models/commercial-activity-approval-selected.model';

@Component({
  selector: 'app-commercial-activity-historical',
  templateUrl: './commercial-activity-historical.component.html',
  styleUrls: ['./commercial-activity-historical.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommercialActivityHistoricalComponent implements OnInit {

  @Input('idActivity') idActivity: number;
  @Input('commercialActStatus') commercialActStatus: number;

  private readonly spinner: NgxSpinnerService;
  private readonly commercialActivityHistoricalService: CommercialActivityApprovalSelectedService;

  public objectList: CommercialActivityApprovalSelectedModel[] = [];
  public selectObject: CommercialActivityApprovalSelectedModel;

  public approvalStatus: any;
	public domainStatusEnum: any;
  public emailSession = '';
  public showModalApprovalDetailText = false;

  constructor(
    spinner: NgxSpinnerService,
    commercialActivityHistoricalService: CommercialActivityApprovalSelectedService,
  ) {
    this.spinner = spinner;
    this.commercialActivityHistoricalService = commercialActivityHistoricalService;
   }

  ngOnInit(): void {
    this.emailSession = SessionStorageHelper.get(CommonConfiguration.Email);
		this.approvalStatus = this.getCommercialActivityApprovalStatus();
		this.domainStatusEnum = this.getCommercialActivityStatus();
    this.getAll();
  }

  getCommercialActivityApprovalStatus(): typeof CommercialActivityApprovalStatus {
		return CommercialActivityApprovalStatus;
	}

	getCommercialActivityStatus(): typeof CommercialActivityStatus {
		return CommercialActivityStatus;
	}

  getAll() {
		this.spinner.show();

		this.commercialActivityHistoricalService.getCommercialActivityHierarchyApproval(this.idActivity,true)
			.subscribe((response) => {
				if (response.status) {
				this.objectList = response.data.filter((i) => i.statusDomainId !== this.approvalStatus.WithoutChecking)
                                       .sort((a, b) => (a.modificationDate < b.modificationDate) ? 1 : -1);
			}
				this.spinner.hide();
		}, (error) => {
			this.spinner.hide();
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, `${error.message}`);
		});
	}

  showApprovalModalDetail(object: CommercialActivityApprovalSelectedModel){
    this.showModalApprovalDetailText = true;
		this.selectObject = object;
  }
}
