import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValidityPeriodMessage {

  public static readonly TITLE = 'Periodo Máximo de Vigencia';
  public static readonly IS_DESCRIPTION = 'El periodo máximo de vigencia que desea agregar ya se encuentra creado';
  public static readonly IS_DESCRIPTION_TRANSLATE = 'The maximum period of validity that you want to add is already created';
  public static readonly IS_DESCRIPTIONUPDATE = 'El periodo máximo de vigencia que desea actualizar ya se encuentra creada';
  public static readonly IS_DESCRIPTIONUPDATE_TRANSLATE = 'The maximum period of validity that you want to update is already created';

  constructor() { }
}
