import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProductList } from '../interfaces/product-list.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';

@Component({
  selector: 'app-exclusions-table-filter',
  templateUrl: './exclusions-table-filter.component.html',
  styleUrls: ['./exclusions-table-filter.component.css']
})
export class ExclusionsTableFilterComponent implements OnInit {
  public rolePermission: any = RolePermissionEnum;
  @Input() isProcess: any;
  @Input() productFilterListPromotion: Array<IProductList>;
  @Output() deleteGroupEvent = new EventEmitter<any>();
  checkDelete = false;
  constructor() { }

  ngOnInit(): void {

  }



  selectDeleteAll(checked: any) {
    this.productFilterListPromotion.forEach((element) => {
      element.check = checked;
    });
  }

  deleteAction(element: IProductList) {
    this.deleteGroupEvent.emit(element);
  }
}
