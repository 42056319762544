import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { FileBaseHelper } from '@app/@shared/helpers/file-base.helper';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { Util } from '@app/@shared/util';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertResult } from 'sweetalert2';
import { AssingClientsMessages } from '../messages/assing-clients-messages';
import { String } from 'typescript-string-operations';
import { CustomerService } from '../services/customer.service';

@Component({
  selector: 'app-clients-csv',
  templateUrl: './clients-csv.component.html',
  styleUrls: ['./clients-csv.component.css']
})
export class ClientsCsvComponent implements OnInit {

  @Input('isProcess') isProcess: number;
  @Input('activeAccordionUploadFile') activeAccordionUploadFile: boolean = true;
  @Input('clientsEdit') clientsEdit: string;
  @Input('fileUploadClear') fileUploadClear: boolean;
  @Input('isCreate') isCreate: boolean = true;

  @Output('activeAccordionFilter') activeAccordionFilter: EventEmitter<any> = new EventEmitter();
  @Output('listClientCsvData') listClientCsvData: EventEmitter<any> = new EventEmitter();
  @Output('totalRecordsDownloadEmit') totalRecordsDownloadEmit: EventEmitter<any> = new EventEmitter();
  @Output('evenclearCsv') evenclearCsv: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: any;

  public processEnum: any;

  public jsonFilterClient: string;

  public uploadedFiles: any[] = [];
  public listClientCsv: string[] = null;
  public totalRecords = 0;
  public isVisibleTotal = false;
  public activeAccordionSummary = false;
  public listClientCsvDownload: string[] = null;
  public totalRecordsDownload = 0;
  public customerNotMatchProfileMessage: string;
  public language: string;
  public isEditFile = false;
  public btnRemove = true;

  private readonly spinner: NgxSpinnerService;
  private readonly helper: FileBaseHelper;
  private readonly customerService: CustomerService;

  constructor(
    spinner: NgxSpinnerService,
    helper: FileBaseHelper,
    customerService: CustomerService
  ) {
    this.spinner = spinner;
    this.helper = helper;
    this.customerService = customerService;
  }

  ngOnInit(): void {
    this.processEnum = this.getCommercialActivityEnum();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const changeClientsEdit: SimpleChange = changes['clientsEdit'];
    if (changeClientsEdit && changeClientsEdit.currentValue) {
      this.activeAccordionSummary = false;
      if(this.isProcess === ComercialActivityEnum.create) this.activeAccordionUploadFile = true;
      this.isEditFile = true;
      if (changeClientsEdit.currentValue.length > 0) {
        this.totalRecordsDownload = changeClientsEdit.currentValue.length;
        this.listClientCsvDownload = changeClientsEdit.currentValue;
        this.totalRecordsDownloadEmit.emit(this.totalRecordsDownload);
        this.activeAccordionFilter.emit(false);
        this.getListClientCSV(changeClientsEdit);
      }
    }
  }

  getListClientCSV(changeClientsEdit:SimpleChange){
    this.listClientCsv = [];
    changeClientsEdit.currentValue.forEach((element:any) => {
      this.listClientCsv.push(element.clientCode);
    });
    this.listClientCsvData.emit(this.listClientCsv);
  }


  async onUploadFile(event: any) {
    if (this.listClientCsvDownload) {
      Util.confirmEditFileCsv().then(async (result: SweetAlertResult) => {
        if (result.value) {
          this.listClientCsvDownload = null;
          this.totalRecordsDownload = null;
          this.uploadFile(event);
        } else {
          this.fileUpload.clear();
        }
      });
    }
    else {
      this.uploadFile(event);
    }
  }

  async uploadFile(event: any) {
    this.spinner.show();
    this.jsonFilterClient = null;
    this.uploadedFiles = [];
    for (const fileIndex of event.files) {
      this.uploadedFiles.push(fileIndex);
    }
    const file: File = this.uploadedFiles[0];
    const allCodClient: any = await this.helper.parseCsvFile(file);
    if (allCodClient.length > 0) {
      const arrl = allCodClient.length;
      this.listClientCsv = [];

      for (let i = 1; i < arrl; i++) {
        let codClient: string = isNaN(parseInt(allCodClient[i], 10)) === false ? allCodClient[i] : '';
        if (codClient && codClient !== '') {
          this.listClientCsv.push(codClient);
        }
      }

      this.customerService.postValidationCustomers(this.listClientCsv)
        .subscribe(
          (response) => {
            if (!response.status) {
              this.spinner.hide();
              this.language = sessionStorage.getItem('language');
               this.customerNotMatchProfileMessage = (this.language === CommonConfiguration.LanguageKey) ?
                 AssingClientsMessages.CUSTOMER_NOT_MATCH_USERPROFILE_ES :
                 AssingClientsMessages.CUSTOMER_NOT_MATCH_USERPROFILE_EN;

              SweetAlertMessageHelpers.listWarningMessageShow(
                 String.Format(this.customerNotMatchProfileMessage, response.messages.length), response.messages, 3);
            }
            this.listClientCsv = response.data;
            this.totalRecords = this.listClientCsv.length;
            this.isVisibleTotal = true;
            this.activeAccordionSummary = false;
            this.activeAccordionUploadFile = true;
            this.totalRecordsDownload = this.listClientCsv.length;
            this.listClientCsvData.emit(this.listClientCsv);
            this.activeAccordionFilter.emit(false);
            this.totalRecordsDownloadEmit.emit(this.totalRecordsDownload);
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          },
          () => {
            this.spinner.hide();
          }
        );
    }
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  downloadFile() {
    const csv = typeof this.listClientCsvDownload[0] === 'string' ? this.listClientCsvDownload : this.listClientCsvDownload.map((row: any) => JSON.stringify(row.clientCode));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public onRemoveFileImage(): void {
    this.uploadedFiles = [];
    this.listClientCsv = [];
    this.totalRecords = 0;
    this.totalRecordsDownload = 0;
    this.isVisibleTotal = false;
    this.activeAccordionSummary = false;
    this.activeAccordionUploadFile = true;
    this.activeAccordionFilter.emit(true);
    this.listClientCsvData.emit(this.listClientCsv);
    this.totalRecordsDownloadEmit.emit(0);
    this.listClientCsvDownload = null;
    this.btnRemove = false;  
    this.evenclearCsv.emit(true); 
  }

  public uploadClear(){
    this.listClientCsvDownload = null;
    this.totalRecordsDownload = null;
    this.fileUpload?.clear();
  }

}
