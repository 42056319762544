import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { LiberationHierarchyService } from './liberation.hierarchy.service';
import { Util } from '@app/@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { ILiberationHierarchy } from './interfaces/liberation-hierarchy.interface';
import { LiberationHierarchyModel } from './models/liberation-hierarchy.model';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { HierarchyConfigurationModel } from './models/hierarchy-configuration.model';
import { ApplicationConstants } from '@app/app.constants';
import { IdCombo } from './interfaces/IdCombo.interfaces';
import { ICoreMasterId } from '@app/@shared/interfaces/core-master-id.interfaces';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'app-liberation-hierarchy',
  templateUrl: './liberation-hierarchy.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
  styles: [`
    @media screen and (max-width: 960px) {
        :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
            display: flex;
        }
    }
  `],
  providers: [MessageService],
})

export class LiberationHierarchyComponent implements OnInit, AfterViewInit{
  private readonly fb: FormBuilder;
  private readonly router: Router;
  private readonly spinner: NgxSpinnerService;
  private readonly messageService: MessageService;
  private readonly cd : ChangeDetectorRef;
  private readonly miDatePipe: DatePipe;
  private readonly hierarchyService: LiberationHierarchyService;
  private readonly translate: TranslateService;
  private readonly i18nService: I18nService;

  public formLiberationHerarchy: FormGroup;
  public objectChannels: ICombo[];
  public objectSectors: ICombo[];
  public objectCedis: ICombo[];
  public objectType: IdCombo[];
  public objectTypeCopy: ICombo[];
  public objectMacro: ICombo[];
  public objectipology: ICombo[];
  public objectLiberationHierarchy: ILiberationHierarchy[] = [];
  public objLiberationHierarchy: ILiberationHierarchy;
  public rolePermission: any = RolePermissionEnum;
  public isCreate = true;
  public editId: number;
  public auxMacro:boolean;
  public auxTypology:boolean;
  public auxChannel: ILiberationHierarchy;
  public isCopyHerarchy = false;
  public idCopy : number;

  public nameHierarchyCopy: string;
  public sectorCopy:ICombo[];
  public tipologyCopy: ICombo[];
  public macroCopy: ICombo[];
  public cediCopy: ICombo[];
  public typeCopy: IdCombo[];
  public channelCopy: ICombo[];
  public isSelectCopy = 1;
  public selectCopy = true;

  public isName = false;
  public isSector = false;
  public isChannel = false;
  public isType = false;
  public isCedi = false;
  public isMacro = false;
  public isTipology = false;

  public typeToolTip : string[] = [];
  public cediToolTip : string[] = [];
  public sectorToolTip : string[] = [];
  public channelToolTip : string[] = [];
  public tipologyToolTip : string[] = [];
  public macroToolTip : string[] = [];

  public typeCopyToolTip : string[] = [];
  public cediCopyToolTip : string[] = [];
  public sectorCopyToolTip : string[] = [];
  public channelCopyToolTip : string[] = [];
  public tipologyCopyToolTip : string[] = [];
  public macroCopyToolTip : string[] = [];

  public macroRequired: boolean;
  public tipologyRequired: boolean;
  public language: string = sessionStorage.getItem('language');
  pattern = /[%&/#!"*{};:+?¡|°|'@()=$]/;

  constructor(
    fb: FormBuilder,
    router: Router,
    spinner: NgxSpinnerService,
    messageService: MessageService,
    cd : ChangeDetectorRef,
    miDatePipe: DatePipe,
    hierarchyService: LiberationHierarchyService,
    translate: TranslateService,
    i18nService: I18nService
  ) { 
      this.fb = fb;
      this.router = router;
      this.spinner = spinner;
      this.messageService = messageService;
      this.cd = cd;
      this.miDatePipe = miDatePipe;
      this.hierarchyService = hierarchyService;
      this.translate = translate;
      this.i18nService = i18nService;
  }

  ngOnInit(): void {
    this.initForm();
    this.forkJoinData(true);
    //this.getAllHierarchy();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  forkJoinData(isFirst: boolean = false): void {
    if(this.language === this.translate.currentLang && !isFirst){
      return;
    }else{
      this.translate.use(this.i18nService.language);
      this.language = this.i18nService.language;
    }

    this.spinner.show();
    const SectorResponse = this.hierarchyService.getAll(TypeCoreMaster.Sector);
    const CanalResponse = this.hierarchyService.getAll(TypeCoreMaster.Channel);
    const MacroResponse = this.hierarchyService.getAll(TypeCoreMaster.Macrosegments);
    const TypeActivityResponse = this.hierarchyService.getAllType(Microservice.Core, DomainTypeCore.Subprocess);
    const TypologyResponse = this.hierarchyService.getAll(TypeCoreMaster.BusinessTypologies);
    const CediResponse = this.hierarchyService.getAll(TypeCoreMaster.Cedi);
    forkJoin([
      SectorResponse,
      CanalResponse,
      MacroResponse,
      TypeActivityResponse,
      TypologyResponse,
      CediResponse,
      ]).subscribe(
        (result) => {
        const objSectorResponse: IResponseService<ICoreMaster[]> = result[0];
        const objChannelResponse: IResponseService<ICoreMaster[]> = result[1];
        const objMacrosegmentResponse: IResponseService<ICoreMaster[]> = result[2];
        const objTypeActivityResponse: IResponseService<ICoreMasterId[]> = result[3];
        const objTypologyResponse: IResponseService<ICoreMaster[]> = result[4];
        const objCediResponse: IResponseService<ICoreMaster[]> = result[5];

        this.objectSectors = objSectorResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });

        this.objectChannels = objChannelResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });

        this.objectMacro = objMacrosegmentResponse.data.map(
          (item) => {
            return {
              label: item.description,
              value: item.code.trim(),
            };
          }
        );

        this.objectType = objTypeActivityResponse.data.filter((item) => item.code === 'AP' && item.description !== 'Descuento en precio').map((item) => {
          if (item.code === 'AP') {
            return {
              label: this.translate.instant('Domain.Domain-'+item.id),
              value: item.code.trim(),
              id: item.id,
            };
          }
        });

        this.objectTypeCopy = objTypeActivityResponse.data.filter((item) => item.code === 'AP' && item.description !== 'Descuento en precio').map((item) => {
          if (item.code === 'AP') {
            return {
              label: item.description,
              value: item.id,
            };
          }
        });


        this.objectipology = objTypologyResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });

        this.objectCedis = objCediResponse.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim(),
          };
        });
        this.getAllHierarchy();
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
      }
    );
  }

  getAllHierarchy(){
    this.spinner.show();
    this.hierarchyService.getAllHierarchy().subscribe(
      (response) => {
        this.objectLiberationHierarchy = response.data;
        this.objectLiberationHierarchy.sort((b, a) => a.hierarchyConfiguration.id - b.hierarchyConfiguration.id);
        this.changeCodetoDescription();

        this.objectLiberationHierarchy.forEach((x) => {
          const aux = this.miDatePipe.transform(x.hierarchyConfiguration.creationDate,'dd/MM/yyyy');
          x.hierarchyConfiguration.creationDateString = aux;
        });
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  changeCodetoDescription(){
    this.objectLiberationHierarchy.forEach((x) => {
      x.channel = [];
      x.macrosegment = [];
      x.sector = [];
      x.typology = [];
      x.type = [];
      x.cedi = [];

      this.objectChannels.forEach((c) => {
        x.channelCode.forEach((co) => {
          if(co === c.value){
            x.channel.push(c.label);
          }
        });
      });

      this.objectMacro.forEach((m) => {
        x.macrosegmentCode.forEach((mo) => {
          if(mo === m.value){
            x.macrosegment.push(m.label);
          }
        });
      });

      this.objectSectors.forEach((s) => {
        x.sectorCode.forEach((so) => {
          if(so === s.value){
            x.sector.push(s.label);
          }
        });
      });

      this.objectipology.forEach((t) => {
        x.typologyCode.forEach((to) => {
          if(to === t.value){
             x.macrosegment.push(t.label);
            //  x.typology.push(t.label);
          }
        });
      });

      this.objectCedis.forEach((t) => {
        x.cediCode.forEach((to) => {
          if(to === t.value){
            x.cedi.push(t.label);
          }
        });
      });

      this.objectType.forEach((t) => {
        x.typeCommercialActivityDomainId.forEach((to) => {
          if(Number(to) === t.id){
             x.type.push(t.label);
          }
        });
      });

    });
  }

  valueRequired(){
    if(Number(this.isSelect.value) === 1){
        this.requiredField(false);
    }else if(Number(this.isSelect.value) === 2){
      this.requiredField(true);
    }
  }

  selectCombo(){
    if(this.isSelectCopy === 1){
      this.auxMacro = true;
  }else if(this.isSelectCopy === 2){
    this.auxTypology = true;
  }
  }

  clearTooltips(){
    this.typeToolTip = [];
    this.cediToolTip = [];
    this.sectorToolTip = [];
    this.channelToolTip  = [];
    this.tipologyToolTip = [];
    this.macroToolTip = [];
  }

  clearTooltipsCopy(){
    this.typeCopyToolTip = [];
    this.cediCopyToolTip = [];
    this.sectorCopyToolTip = [];
    this.channelCopyToolTip  = [];
    this.tipologyCopyToolTip = [];
    this.macroCopyToolTip = [];
  }

  addHierarchy(): void{
    this.valueRequired();
    if(this.formLiberationHerarchy.valid){
      this.spinner.show();
      let radioSelect: boolean;
      const objChannel: string[] = [];
      const objSector: string[] = [];
      const objType: string[] = [];
      const objMacro: string[] = [];
      const objTipo: string[] = [];
      const objCedi: string[] = [];
      let auxSelect: number;

      //Arrays Strings
      this.channel.value.map((x:any) => {
        objChannel.push(x.value);
      });
      this.sector.value.map((x:any) => {
        objSector.push(x.value);
      });
      this.type.value.map((x:any) => {
        objType.push(String(x.id));
      });
      this.cedi.value.map((x: any) => {
        objCedi.push(x.value);
      });
      if(this.isSelect.value === 1){
        //Macrosegmento
        radioSelect = false;
        auxSelect = 1;
        this.macrosegment.value.map((x:any) => {
          objMacro.push(x.value);
        });
      }else{
        //Tipologia
        radioSelect = true;
        auxSelect = 2;
        this.tipology.value.map((x:any) => {
          objTipo.push(x.value);
        });
      }

      const hierarchyConfig: HierarchyConfigurationModel = new HierarchyConfigurationModel(
        this.nameHierarchy.value,
        radioSelect
      );

     // Se agrega la jerarquia
      const hierarchy: LiberationHierarchyModel = new LiberationHierarchyModel(
        hierarchyConfig,
        objSector,
        objType,
        objChannel,
        objMacro,
        objTipo,
        objCedi
      );

      this.hierarchyService.postHierarchy(hierarchy).subscribe(
        (response) => {
          this.spinner.hide();
          if(response.status){
              this.clearControls(); 
              this.formLiberationHerarchy.reset();
              this.isSelect.setValue(1);
              this.clearTooltips();
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
              this.getAllHierarchy();
            }else{
              // SweetAlertMessageHelpers.Warning(SweetAlert.titleAlertWarning, SweetAlert.messageAlertExist);
              SweetAlertMessageHelpers.listWarningMessageShow(`Encontramos ${response.messages.length} registro(s) en el sistema.`, response.messages);
            }
          // }else{
          //   SweetAlertMessageHelpers.ShowMessage(TypeMessage.Error);
          // }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }else{
      this.formLiberationHerarchy.markAllAsTouched();
    }
  }

  validityNameCopy(){
    //Name
    if(this.nameHierarchyCopy === '' || this.nameHierarchyCopy === undefined){
      this.isName = true;
    }else{
      this.isName = false;
    }

  }

  validitySectorCopy(){
    //Sector
    if(this.sectorCopy === [] || this.sectorCopy === undefined || this.sectorCopy.length === 0){
      this.isSector = true;
    }else{
      this.isSector = false;
    }
    this.tooltipHideSectorCopy();
  }

  validityChannelCopy(){
    //Chanel
    if(this.channelCopy === [] || this.channelCopy === undefined || this.channelCopy.length === 0){
      this.isChannel = true;
    }else{
      this.isChannel = false;
    }
    this.tooltipHideChannelCopy();
  }

  validityTypeCopy(){
    //Type
    if(this.typeCopy === [] || this.typeCopy === undefined || this.typeCopy.length === 0){
      this.isType = true;
    }else{
      this.isType = false;
    }
    this.tooltipHideTypeCopy();
  }

  validityCediCopy(){
    //Cedi
    if(this.cediCopy === [] || this.cediCopy === undefined || this.cediCopy.length === 0){
      this.isCedi = true;
    }else{
      this.isCedi = false;
    }
    this.tooltipHideCediCopy();
  }

  validitySelectCopy(){
    this.selectCopy = true;
    if(Number(this.isSelectCopy) === 1){
      this.tipologyCopy = [];
      if(this.macroCopy === [] || this.macroCopy === undefined || this.macroCopy.length === 0){
        this.isMacro = true;
      }else{
        this.isMacro = false;
        this.selectCopy = false;
      }
      this.tooltipHideMacroCopy();
    }else if(Number(this.isSelectCopy) === 2){
      this.macroCopy = [];
      if(this.tipologyCopy === [] || this.tipologyCopy === undefined || this.tipologyCopy.length === 0){
        this.isTipology = true;
      }else{
        this.isTipology = false;
        this.selectCopy = false;
      }
      this.tooltipHideTipologyCopy();
    }
  }

  addHierarchyCopy(){
    this.selectCombo();
    this.validityNameCopy();
    this.validitySectorCopy();
    this.validityChannelCopy();
    this.validityTypeCopy();
    this.validityCediCopy();
    this.validitySelectCopy();

    if(!this.isName && !this.isSector && !this.isChannel && !this.isType && !this.isCedi && !this.selectCopy){
      this.spinner.show();
      let radioSelect: boolean;
      const objChannel: string[] = [];
      const objSector: string[] = [];
      const objType: string[] = [];
      const objMacro: string[] = [];
      const objTipo: string[] = [];
      const objCedi: string[] = [];
      let auxSelect: number;

      //Arrays Strings
      this.channelCopy.map((x:any) => {
        objChannel.push(x.value);
      });
      this.sectorCopy.map((x:any) => {
        objSector.push(x.value);
      });

      this.typeCopy.map((x:any) => {
        objType.push(String(x));
      });
      this.cediCopy.map((x: any) => {
        objCedi.push(x.value);
      });
      if(this.isSelectCopy === 1){
        //Macrosegmento
        radioSelect = false;
        auxSelect = 1;
        this.macroCopy.map((x:any) => {
          objMacro.push(x.value);
        });
      }else{
        //Tipologia
        radioSelect = true;
        auxSelect = 2;
        this.tipologyCopy.map((x:any) => {
          objTipo.push(x.value);
        });
      }

      const hierarchyConfig: HierarchyConfigurationModel = new HierarchyConfigurationModel(
        this.nameHierarchyCopy,
        radioSelect
      );

     // Se agrega la jerarquia
      const hierarchy: LiberationHierarchyModel = new LiberationHierarchyModel(
        hierarchyConfig,
        objSector,
        objType,
        objChannel,
        objMacro,
        objTipo,
        objCedi
      );

      //POST
      this.spinner.hide();

      this.hierarchyService.postHierarchyCopy(hierarchy, this.idCopy).subscribe(
        (response) => {
          this.spinner.hide();
          if(response.status){
             this.cancelBtn();
             this.clearTooltipsCopy();
             const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
              this.getAllHierarchy();
            }else{
              // SweetAlertMessageHelpers.Warning(SweetAlert.titleAlertWarning, SweetAlert.messageAlertExist);
              SweetAlertMessageHelpers.listWarningMessageShow(`Encontramos ${response.messages.length} registro(s) en el sistema.`, response.messages);
            }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }
  }


  updateHierarchy() {
    this.valueRequired();
    if (this.formLiberationHerarchy.valid) {
      Util.confirmEdit().then((result: SweetAlertResult) => {
        if (result.value) {

          this.spinner.show();
          const date = new Date();
          let radioSelect: boolean;
          const objChannel: string[] = [];
          const objSector: string[] = [];
          const objType: string[] = [];
          const objMacro: string[] = [];
          const objTipo: string[] = [];
          const objCedi: string[] = [];

          //Arrays Strings
          this.channel.value.map((x: any) => {
            objChannel.push(x.value);
          });
          this.sector.value.map((x: any) => {
            objSector.push(x.value);
          });
          this.type.value.map((x: any) => {
            objType.push(String(x.id));
          });
          this.cedi.value.map((x: any) => {
            objCedi.push(x.value);
          });

          if (Number(this.isSelect.value) === 1) {
            //Macrosegmento
            radioSelect = false;
            this.macrosegment.value.map((x: any) => {
              objMacro.push(x.value);
            });
          } else {
            //Tipologia
            radioSelect = true;
            this.tipology.value.map((x: any) => {
              objTipo.push(x.value);
            });
          }

          const hierarchyConfig: HierarchyConfigurationModel = new HierarchyConfigurationModel(
            // this.editId,
            this.nameHierarchy.value,
            radioSelect,
            this.editId,
          );

          // Se agrega la jerarquia
          const hierarchy: LiberationHierarchyModel = new LiberationHierarchyModel(
            hierarchyConfig,
            objSector,
            objType,
            objChannel,
            objMacro,
            objTipo,
            objCedi
          );
          this.hierarchyService.putHierarchy(this.editId, hierarchy).subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.isCreate = true;
                this.clearControls();
                this.clearTooltips();
                this.formLiberationHerarchy.reset();
                this.isSelect.setValue(1);
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconWarningToast,
                  language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate);
                this.getAllHierarchy();
              } else {
                SweetAlertMessageHelpers.listWarningMessageShow(`Encontramos ${response.messages.length} registro(s) en el sistema.`, response.messages);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
        }
        else
        {
          this.isCreate = true;
          this.clearControls();
          this.clearTooltips();
          this.formLiberationHerarchy.reset();
          this.isSelect.setValue(1);
          this.getAllHierarchy();
        }
      });
    }
    else {
      this.formLiberationHerarchy.markAllAsTouched();
    }
  }

  

  copyHierarchy(objHierarchy: ILiberationHierarchy){
    this.isCopyHerarchy = true;
    this.isSelect.setValue(1);
    this.idCopy = objHierarchy.hierarchyConfiguration.id;
  }

  requiredField(isTipology: boolean){
    this.cd.detectChanges();
    if(isTipology){
      this.tipology.setValidators(Validators.required);
      this.macrosegment.clearValidators();
      this.macrosegment.setValue([]);
      this.macroRequired = false;
      this.tipologyRequired = true;
    }else {
      this.macrosegment.setValidators(Validators.required);
      this.tipology.clearValidators();
      this.tipology.setValue([]);
      this.macroRequired = true;
      this.tipologyRequired = false;
    }
    this.cd.detectChanges();
  }

  cancelBtn(){
    this.isCopyHerarchy = false;

    this.nameHierarchyCopy = '';
    this.sectorCopy = [];
    this.channelCopy = [];
    this.typeCopy = [];
    this.cediCopy = [];
    this.isSelectCopy = 1;
    this.tipologyCopy = [];
    this.macroCopy = [];

    this.isName = false;
    this.isChannel = false;
    this.isSector = false;
    this.isType = false;
    this.isCedi = false;
    this.isTipology = false;
    this.isMacro = false;
  }

  editOrganizationChart(objHierarchy: ILiberationHierarchy){
    this.hierarchyService.setHierarchyId(objHierarchy.hierarchyConfiguration.id);
    this.router.navigate(['auth/liberation-hierarchy/approval-ranks']);
  }

  editHierarchy(objHierarchy: ILiberationHierarchy){
    this.clearTooltips();
    Util.confirmEdit().then((result: SweetAlertResult) => {
      if (result.value) {
        this.isCreate = false;
        this.editId = objHierarchy.hierarchyConfiguration.id;
        this.nameHierarchy.setValue(objHierarchy.hierarchyConfiguration.name);
        const selectSector: any[] = [];
        const selectChannel: any[] = [];
        const selectType: any[] = [];
        const selectMacro: any[] = [];
        const selectTipology: any[] = [];
        const selectCedi: any [] = [];


        this.objectSectors.forEach((x) =>{
          objHierarchy.sector.forEach((s) => {
            if(x.label === s){
              selectSector.push(x);
              this.sectorToolTip.push(x.label);
            }
          });
        });

        this.objectChannels.forEach((x) =>{
          objHierarchy.channel.forEach((s) => {
            if(x.label === s){
              selectChannel.push(x);
              this.channelToolTip.push(x.label);
            }
          });
        });

        this.objectType.forEach((x) =>{
          objHierarchy.type.forEach((s) => {
            if(x.label === s){
              selectType.push(x);
              this.typeToolTip.push(x.label);
            }
          });
        });


        this.objectMacro.forEach((x) =>{
          objHierarchy.macrosegment.forEach((s) => {
            if(x.label === s){
              selectMacro.push(x);
              this.macroToolTip.push(x.label);
            }
          });
        });


        this.objectipology.forEach((x) =>{
          objHierarchy.macrosegment.forEach((s) => {
            if(x.label === s){
              selectTipology.push(x);
              this.tipologyToolTip.push(x.label);
            }
          });
        });

        this.objectCedis.forEach((x) => {
          objHierarchy.cedi.forEach((c) => {
            if(x.label === c){
              selectCedi.push(x);
              this.cediToolTip.push(x.label);
            }
          });
        });

        this.sector.setValue(selectSector);
        this.channel.setValue(selectChannel);
        this.type.setValue(selectType);
        this.cedi.setValue(selectCedi);

        if(objHierarchy.macrosegmentCode.length > 0){
            this.isSelect.setValue(1);
            this.macrosegment.setValue(selectMacro);
        }else if(objHierarchy.typologyCode.length > 0){
            this.isSelect.setValue(2);
            this.tipology.setValue(selectTipology);
        }
      }
    });
  }

  deleteHierarchy(objHierarchy: number){
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.hierarchyService.deleteHierarchyLogic(objHierarchy).subscribe(
          (response) => {
            this.spinner.hide();
            if(response.status){
              this.isCreate = true;
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconInfo,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              this.getAllHierarchy();
            }else {
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });
  }

  onPaste(event: any, aux :boolean) {
    const textCopy = event.clipboardData.getData('text');
    if (this.pattern.test(textCopy)) {
      const text = textCopy.replace(/[%&/#!"*{};:+?¡|°|'@()=$]/gi, '');
      if(aux){
        this.formLiberationHerarchy.get('nameHierarchy').setValue(text);
      } else if(!aux){
        this.nameHierarchyCopy = text;
      }
      event.preventDefault();
    }
  }

  tooltipHideMacro(){
    this.macroToolTip = [];
      this.macrosegment.value.forEach((x: any) => {
          this.macroToolTip.push(x.label);
      });
  }

  tooltipHideMacroCopy(){
    this.macroCopyToolTip = [];
    if(this.macroCopy !== undefined){
        this.macroCopy.forEach((x: any) => {
            this.macroCopyToolTip.push(x.label);
      });
    }
  }

  tooltipHideTipology(){
    this.tipologyToolTip = [];
      this.tipology.value.forEach((x: any) => {
          this.tipologyToolTip.push(x.label);
      });
  }

  tooltipHideTipologyCopy(){
    this.tipologyCopyToolTip = [];
    if(this.tipologyCopy !== undefined){
      this.tipologyCopy.forEach((x: any) => {
        this.tipologyCopyToolTip.push(x.label);
    });
    }
  }

  tooltipHideCedi(){
    this.cediToolTip = [];
      this.cedi.value.forEach((x: any) => {
          this.cediToolTip.push(x.label);
      });
  }

  tooltipHideCediCopy(){
    this.cediCopyToolTip = [];
    if(this.cediCopy !== undefined){
      this.cediCopy.forEach((x: any) => {
          this.cediCopyToolTip.push(x.label);
      });
    }
  }

  tooltipHideType(){
    this.typeToolTip = [];
      this.type.value.forEach((x: any) => {
          this.typeToolTip.push(x.label);
      });
  }

  tooltipHideTypeCopy(){
    this.typeCopyToolTip = [];
    if(this.typeCopy !== undefined){
      this.objectTypeCopy.forEach((x) => {
        this.typeCopy.forEach((t: any) => {
          if(x.value === t){
            this.typeCopyToolTip.push(x.label);
          }
      });
      });

    }
  }

  tooltipHideChannel(){
    this.channelToolTip = [];
      this.channel.value.forEach((x: any) => {
          this.channelToolTip.push(x.label);
      });
  }

  tooltipHideChannelCopy(){
    this.channelCopyToolTip = [];
    if(this.channelCopy !== undefined){
      this.channelCopy.forEach((x: any) => {
        this.channelCopyToolTip.push(x.label);
    });
    }
  }

  tooltipHideSector(){
    this.sectorToolTip = [];
      this.sector.value.forEach((x: any) => {
          this.sectorToolTip.push(x.label);
      });
  }

  tooltipHideSectorCopy(){
    this.sectorCopyToolTip = [];
    if(this.sectorCopy !== undefined){
      this.sectorCopy.forEach((x: any) => {
          this.sectorCopyToolTip.push(x.label);
      });
    }
  }

  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number
    });
  }

  clearControls(): void {
    this.nameHierarchy.setValue('');
    this.channel.setValue([]);
    this.sector.setValue([]);
    this.type.setValue([]);
    this.macrosegment.setValue([]);
    this.tipology.setValue([]);
  }

  initForm(): void{
    this.formLiberationHerarchy = this.fb.group({
      nameHierarchy: [{ value: '', disabled: false }, Validators.required],
      channel: [{ value: [], disabled: false }, Validators.required],
      sector: [{ value: [], disabled: false }, Validators.required],
      type: [{ value: [], disabled: false }, Validators.required],
      isSelect: [{value: 1, disabled: false }, Validators.required],
      macrosegment: [{ value: [], disabled: false }, Validators.required],
      tipology: [{ value: [], disabled: false }, Validators.required],
      cedi: [{ value: [], disabled: false }, Validators.required]
    });
  }

  get nameHierarchy(): AbstractControl { return this.formLiberationHerarchy.get('nameHierarchy'); }
  get channel(): AbstractControl { return this.formLiberationHerarchy.get('channel'); }
  get sector(): AbstractControl { return this.formLiberationHerarchy.get('sector'); }
  get type(): AbstractControl { return this.formLiberationHerarchy.get('type'); }
  get isSelect(): AbstractControl{ return this.formLiberationHerarchy.get('isSelect'); }
  get macrosegment(): AbstractControl { return this.formLiberationHerarchy.get('macrosegment');}
  get tipology(): AbstractControl { return this.formLiberationHerarchy.get('tipology');}
  get cedi(): AbstractControl { return this.formLiberationHerarchy.get('cedi');}
}
