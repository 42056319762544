import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { HierarchyReleaseModel } from './models/hierarchy-release.model';
import { HierarchyReleaseCreateModel } from './models/hierarchy-release-create.model';
import { HierarchyRealeseUpdateModel } from './models/hierarchy-realese-update.model';
import { HierarchyReleaseAssociateModel } from './models/hierarchy-release-associate.model';

@Injectable({
  providedIn: 'root'
})
export class CreateService {
  private readonly httpService: HttpService;

  public hierarchyIdEdit: number;
  public profileDescriptionEdit: string;
  public hierarchyReleaseModel: HierarchyReleaseModel;
  public hierarchyReleaseAssociateModel: HierarchyReleaseAssociateModel;
  public processHierarchyRealese: number;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
   }


  /**
  * @description This method is to create Domain.
  * @author Jhon Steven Pavón Bedoya
  * @param params
  * @returns Observable<ResponseService>
  * @sprint 7
  */
   public postHierarchyReleaseWithAssociate(body: HierarchyReleaseCreateModel): Observable<IResponseService<number>> {
      let response$: Observable<IResponseService<number>>;
      response$ = this.httpService
          .post(
          `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_release/hierarchy_release_with_associate/`, body)
          .pipe(
          map((response: HttpResponse<IResponseService<number>>) => {
              return response.body;
          })
          );
      return response$;
    }

  /**
   * @description This method is to update Domain.
   * @author Jhon Steven Pavón Bedoya
   * @param id
   * @param body
   * @returns
   * @sprint 7
   */
  public putHierarchyReleaseWithAssociate(id: number, body: HierarchyRealeseUpdateModel): Observable<IResponseService<number>> {
    let response$: Observable<IResponseService<number>>;
    response$ = this.httpService
      .put(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_release/hierarchy_release_with_associate/${id}`, body)
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }


    /**
    * @description This method set type process for herarchy realese.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 7
    */
    setProcessHierarchyRealese(idProcess: number){
      this.processHierarchyRealese = idProcess;
    }

    /**
    * @description This method get type process for herarchy realese.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 7
    */
    getProcessHierarchyRealese(){
      return this.processHierarchyRealese;
    }


    /**
    * @description This method set herarchy realese model.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 8
    */
    setInfoHierarchyModel(hierarchyCreateModel: HierarchyReleaseModel){
      this.hierarchyReleaseModel = hierarchyCreateModel;
    }

    /**
    * @description This method get herarchy realese model.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 8
    */
    getInfoHierarchyModel(){
      return this.hierarchyReleaseModel;
    }

    /**
    * @description This method set Profile description for edit.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 8
    */
    setProfileDescriptionEdit(profileId: string){
      this.profileDescriptionEdit = profileId;
    }

    /**
    * @description This method get Profile description for edit.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 8
    */
    getProfileDescriptionEdit(){
      return this.profileDescriptionEdit;
    }

    /**
    * @description This method set Hierarchy Id for edit.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 8
    */
    setHierarchyIdEdit(hierarchyId: number){
      this.hierarchyIdEdit = hierarchyId;
    }


        /**
    * @description This method get Hierarchy Id for edit.
    * @author Jhon Steven Pavón Bedoya
    * @returns
    * @sprint 8
    */
    getHierarchyIdEdit(){
      return this.hierarchyIdEdit;
    }

}
