<p-accordion>
  <p-accordionTab
    header="{{ 'AssingClient.ClientList' | translate }}"
    [selected]="activeAccordionUploadFile"
    [disabled]="!activeAccordionUploadFile"
  >
  <div *ngIf="isCreate">
    <div class="table-responsive">
      <a
        download="{{ 'AssingClient.TemplateCsv' | translate }}"
        target="_blank"
        href="/assets/files/Plantilla.csv"
      >
        {{ "AssingClient.DownloadTemplate" | translate }}
      </a>
    </div>
    <div class="col-md-12">
      <p-message
        severity="info"
        text="{{ 'AssingClient.FileUploadMsgValidateCsv' | translate }}"
        styleClass="p-mr-5"
      ></p-message>
    </div>
    <p-fileUpload
      #fileUpload
      id="fileKey"
      customUpload="true"
      class="col-md-12"
      (uploadHandler)="onUploadFile($event)"
      (onRemove)="onRemoveFileImage()"
      [auto]="true"
      maxFileSize="4194304"
      chooseLabel="{{ 'SolicitudComponent.Create.UploadFile' | translate }}"
      invalidFileSizeMessageSummary="{0}: {{
        'SolicitudComponent.Create.InvalidSize' | translate
      }}"
      invalidFileSizeMessageDetail="{{
        'SolicitudComponent.Create.FileUpload' | translate
      }} {0}."
      invalidFileTypeMessageDetail=""
      invalidFileTypeMessageSummary="{{'SolicitudComponent.Create.FileUploadMsgValidateExcel' | translate}}"
      accept=".csv"
      [disabled]="isProcess === processEnum.view"
    >
      <ng-template pTemplate="content">
        <div *ngIf="!uploadedFiles.length" class="col-md-12 pruebacsv">
          <button *ngIf="isProcess === processEnum.update && btnRemove && listClientCsvDownload" (click)="onRemoveFileImage()" class="btnRemove">X</button>
          {{ "AssingClient.NoFileCsv" | translate }}
        </div>
        <div *ngIf="isVisibleTotal" class="row col-md-12">
          <div>
            {{ "AssingClient.TotalRecords" | translate }}
          </div>
          <div class="col-md-10">
            <h4>{{ totalRecords }}</h4>
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
    <div *ngIf="listClientCsvDownload" class="row">
      <div class="col-md-2">
        {{ "AssingClient.TotalRecords" | translate }} &nbsp;
        <strong style="font-size: 22px">{{ totalRecordsDownload }}</strong>
      </div>
      <div class="col-md-10">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-download"
          label="{{ 'Download' | translate }}"
          class="p-button-outlined p-button-rounded"
          (click)="downloadFile()"
        ></button>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
