<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/exclusion-by-sku'">
            {{ "ExclusionBySku.Title" | translate }}
          </a>
        </li>
        <li *ngIf="this.process == processEnum.create" class="breadcrumb-item active" aria-current="page">
          {{ "ExclusionBySku.Create.Title" | translate }}
        </li>
        <li *ngIf="this.process == processEnum.update" class="breadcrumb-item active" aria-current="page">
          {{ "ExclusionBySku.Edit.Title" | translate }}
        </li>
        <li *ngIf="this.process == processEnum.view" class="breadcrumb-item active" aria-current="page">
          {{ "ExclusionBySku.View.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>


<!-- INICIO FORM -->
<section class="pstngestion">
  <div class="container"> 
    <div class="row">
      <h1 *ngIf="process == processEnum.create" class="pstntitle">{{ "ExclusionBySku.Create.Title" | translate }}</h1>
      <h1 *ngIf="process == processEnum.update" class="pstntitle">{{ "ExclusionBySku.Edit.Title" | translate }}</h1>
      <h1 *ngIf="process == processEnum.view" class="pstntitle">{{ "ExclusionBySku.View.Title" | translate }}</h1>
    </div>
    <div>
      <h6 class="pstntitle" *ngIf="process != create">Exclusion : {{idExlusion}}</h6>
    </div>
    <form id="formExclusions" [formGroup]="formExclusions">
      <div class="row">

        <div class="col-sm-3 mt-3 without-border">
          <div class="form-group">
            <label>{{ "ExclusionBySku.ReasonExclusion" | translate }}</label>
            <input #reasonExclusion id="reasonExclusion" formControlName="Description" autocomplete="off"
              class="form-control" pInputText required [readonly]="this.process == processEnum.view"
              (keyup)="keyupDescription($event)" />
            <small id="reasonExclusionValidator"
            *ngIf="formExclusions.controls['Description'].invalid &&
                  (formExclusions.controls['Description'].dirty ||
                   formExclusions.controls['Description'].touched)"
              class="p-error">{{"ExclusionBySku.ReasonMsgValidate"|
              translate}}</small>
          </div>
        </div>

        <div class="col-sm-3 mt-3">
          <div class=" form-group">
            <label>{{ "ExclusionBySku.TypeComercialActivity" | translate }}</label>
            <div class="form-group">
              <p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll multitooltip"
                [virtualScroll]="true" [options]="objectPromotionalActivity" formControlName="TypeComercialActivity"
                [filter]="true" placeholder="{{'ExclusionBySku.TypeComercialActivityPlaceHolderCombo' | translate}}"
                optionLabel="label" required optionValue="value" display="chip" 
                (click)="loadInfomartion()" [style]="{width: '100%'}"
                [disabled]="this.process == processEnum.view" (onChange)="changeTypeOfPrice()"></p-multiSelect>
              <small class="Tooltip" *ngIf="this.process == processEnum.view">
                <ul>
                  <li *ngFor="let index of tooltipCommercialActivity">{{index}}</li>
                </ul>
              </small>
              <small id="typeComercialActivityValidator"
              *ngIf="formExclusions.controls['TypeComercialActivity'].invalid &&
                (formExclusions.controls['TypeComercialActivity'].dirty ||
                formExclusions.controls['TypeComercialActivity'].touched)"
                class="p-error">{{"ExclusionBySku.TypeComercialActivityMsgValidate"|
                translate}}</small>
            </div>
          </div>
        </div>

        <div class="col-sm-3 mt-3">
          <div class=" form-group">
            <label>{{ "ExclusionBySku.Channel" | translate }}</label>
            <div class="form-group">
              <p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll multitooltip"
                [virtualScroll]="true" 
                [options]="objectChannel" 
                formControlName="Channel" 
                [filter]="true"
                placeholder="{{'ExclusionBySku.ChannelPlaceHolderCombo' | translate}}" optionLabel="label"
                optionValue="value" 
                required 
                display="chip" 
                [style]="{width: '100%'}"
                [disabled]="this.process == processEnum.view">
              </p-multiSelect>
              <small class="Tooltip" *ngIf="this.process == processEnum.view">
                <ul>
                  <li *ngFor="let index of tooltipChannel">{{index}}</li>
                </ul>
              </small>
              <small id="channelValidator"
              *ngIf="formExclusions.controls['Channel'].invalid &&
                (formExclusions.controls['Channel'].dirty ||
                formExclusions.controls['Channel'].touched)" class="p-error">{{"ExclusionBySku.ChannelMsgValidate"|
                translate}}</small>
            </div>
          </div>
        </div>


        <div class="col-sm-3 mt-3">
          <div class=" form-group">
            <label>{{ "ExclusionBySku.Validity" | translate }}</label>
            <div class="form-group">
              <p-calendar #cal2 id="rangeDateControl" [minDate]="today" formControlName="RangeDateControl"
                          [showIcon]="true" selectionMode="range" 
                          [showOtherMonths]="false" [monthNavigator]="true" [yearNavigator]="true"
                          [yearRange]="getYearRange()" inputId="icon" placeholder="{{ 'RangeDate' | translate }}"
                          dateFormat="dd/mm/yy" 
                          [disabledDates]="invalidDates" 
                          [disabled]="this.process == processEnum.view" 
                          (onClickOutside)="addRangeDate(cal2.hideOverlay())">
                          <!-- <p-header class="p-2">
                            <div class="form-group">
                                <i id="btnClose"
                                  style=" color: #009CDD; background-color: #ffffff"
                                  class="uil uil-times enviar"
                                  (click)="cal2.hideOverlay()"
                                  aria-hidden="true">
                                </i>
                            </div>
                          </p-header> -->
                          <p-header class="p-2">
                            <i id="btnClose"
                            style="border:none"
                            class="uil uil-times enviar"
                            (click)="cal2.hideOverlay()" aria-hidden="true" ></i>
                          </p-header>
                          <p-footer class="p-2">
                            <div class=" form-group">
                              <div class="col-md-12">
                                <p-button label="Limpiar"
                                          *ngIf="this.process != processEnum.view"
                                          (click)="clearDatePicker()">
                                </p-button>
                                <p-button label="Agregar"
                                          *ngIf="this.process != processEnum.view"
                                          (click)="addRangeDate(cal2.hideOverlay())">
                                </p-button>
                              </div>
                            </div>
                          </p-footer>
              </p-calendar>
              <br>
              <small id="channelValidator"
                     *ngIf="formExclusions.controls['RangeDateControl'].invalid &&
                           (formExclusions.controls['RangeDateControl'].dirty ||
                            formExclusions.controls['RangeDateControl'].touched)"
                     class="p-error">{{"ExclusionBySku.ValidityPlaceHolder"| translate}}
              </small>
            </div>
          </div>
        </div>

        <div class="col-md-12">
        <!--INICIO: Campos de promocion, eventos, fidelizacion y producto valor cero-->
          
        <!-- Exlusion SKU -->
          <div class="col-md-12 card shadow" *ngIf="showLimitOrderOne && showLimitOrderPromotion">
              <div class="row card-body">
                <div class="col-md-12" *ngIf="isBasePurchase">
                  <div class="row">
                    <div class="col-md-12 center">
                      <h4>
                        <label class="center"> {{ 'ExclusionBySku.TypeExclusion' | translate }} </label>
                      </h4>
                    </div>
                  </div>
                  <div class="row" >
                    <div class="col-md-3 without-border">
                      <label>{{ 'ExclusionBySku.PurchaseBase' | translate }}</label>
                      <div class="form-group center">
                        <p-inputSwitch formControlName="PurchaseBase" 
                          [disabled]="this.process == processEnum.view">
                        </p-inputSwitch>
                      </div>
                    </div>

                    <div *ngIf="isBasePromotion" class="col-md-3 without-border">
                      <label>{{ 'ExclusionBySku.PromotionBase' | translate }}</label>
                      <div class="form-group center">
                        <p-inputSwitch formControlName="PromotionBase" 
                          [disabled]="this.process == processEnum.view">
                        </p-inputSwitch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

          <div class="row mt-5" *ngIf="showLimitOrderOne && showLimitOrderPromotion"></div>

          <!-- mayor a base compra -->
          <div class="col-md-12 card shadow" *ngIf="showLimitOrderOne && showLimitOrderPromotion">
              <div class="row card-body">
                <div class="col-md-12" *ngIf="isBasePurchase">
                  <h4>
                    <label for="PurchaseBase">{{'ExclusionBySku.PromotionBasePurchase' | translate}}</label>
                  </h4>
                  <div class="form-group center">
                    <p-inputSwitch formControlName="PromotionPurchanse"
                      [disabled]="this.process == processEnum.view">
                    </p-inputSwitch>
                  </div>
                </div>
              </div>
          </div>

          <div class="row mt-5" *ngIf="showLimitOrderOne && showLimitOrderPromotion"></div>

          <!-- limite por pedido -->
          <div class="col-md-12 card shadow " *ngIf="showLimitOrderOne">
            <div class="row card-body">
                <div class="col-md-12">
                  <h4>
                    <label> {{"ExclusionBySku.StopOrder"| translate}}</label>
                  </h4>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderPromotion">
                  <label>{{"ExclusionBySku.StopOrderPromotion"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderPromotion" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderDiscountKind">
                  <label>{{"ExclusionBySku.StopOrderDiscountKind"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderDiscountKind" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderDiscountValue">
                  <label>{{"ExclusionBySku.StopOrderDiscountValue"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderDiscountValue" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderDiscountFinancial">
                  <label>{{"ExclusionBySku.StopOrderDiscountFinancial"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderDiscountFinancial" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderEvents">
                  <label>{{"ExclusionBySku.StopOrderEvents"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderEvents" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderZeroValue">
                  <label>{{"ExclusionBySku.StopOrderZeroValue"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderZeroValue" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderLoyalty">
                  <label>{{"ExclusionBySku.StopOrderLoyalty"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderLoyalty" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showLimitOrderRappel">
                  <label>{{"ExclusionBySku.StopOrderRappel"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="StopOrderRappel" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
            </div>
          </div>

          <div class="row mt-5" *ngIf="showLimitOrderOne"></div>

           <!-- limite por periodo -->
           <div class="col-md-12 card shadow " *ngIf="showLimitOrderOne">
            <div class="row card-body">
                <div class="col-md-12">
                  <h4>
                    <label> {{"ExclusionBySku.CapPeriod"| translate}}</label>
                  </h4>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodPromotion">
                  <label>{{"ExclusionBySku.StopOrderPromotion"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodPromotion" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodDiscountKind">
                  <label>{{"ExclusionBySku.StopOrderDiscountKind"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodDiscountKind" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodDiscountValue">
                  <label>{{"ExclusionBySku.StopOrderDiscountValue"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodDiscountValue" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodDiscountFinancial">
                  <label>{{"ExclusionBySku.StopOrderDiscountFinancial"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodDiscountFinancial" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodEvents">
                  <label>{{"ExclusionBySku.StopOrderEvents"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodEvents" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodZeroValue">
                  <label>{{"ExclusionBySku.StopOrderZeroValue"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodZeroValue" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodLoyalty">
                  <label>{{"ExclusionBySku.StopOrderLoyalty"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodLoyalty" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
                <div class="col-md-3" *ngIf="showCapPeriodRappel">
                  <label>{{"ExclusionBySku.StopOrderRappel"| translate}}</label>
                  <input #StopOrder pInputText
                        id="StopOrder"
                        formControlName="CapPeriodRappel" autocomplete="off"
                        class="form-control"
                        min="0"
                        type="number"
                        [readonly]="this.process == processEnum.view"/>
                </div>
            </div>
          </div>

        <!--FIN: Campos de promocion, eventos, fidelizacion y producto valor cero-->

        <!--INICIO: Campos descuentos-->
        <div class="row mt-5" *ngIf="isKindDiscount || isFinancialDiscount || isValueDiscount"></div>

          <div class="col-md-12 card shadow" *ngIf="isKindDiscount || isFinancialDiscount || isValueDiscount">
            <div class="row card-body">
              <div class="col-md-12">
                <h4>
                  <label>{{"ExclusionBySku.ExclusionDiscount"| translate}}</label>
                </h4>
              </div>

              <div class=" col-md-4" *ngIf="isKindDiscount">
                <div class="row">
                  <div class="col-md-12 btn-group">
                    <p-inputSwitch formControlName="SwitchKindDiscount" 
                      [disabled]="this.process == processEnum.view" 
                      (onChange)="changeKindDiscount()">
                    </p-inputSwitch>
                    <label class="text-center">{{"ExclusionBySku.KindDiscount"| translate}}</label>
                  </div>
                </div>
              </div>

              <div class=" col-md-4" *ngIf="isFinancialDiscount">
                <div class="row">
                  <div class="col-md-12 btn-group" >
                    <p-inputSwitch formControlName="SwitchFinancialDiscount" 
                      [disabled]="this.process == processEnum.view" 
                      (onChange)="changeFinancialDiscount()">
                    </p-inputSwitch>
                    <label class="text-center">{{"ExclusionBySku.FinancialDiscount"| translate}} </label>
                  </div>
                </div>
              </div>
              <div class=" col-md-4" *ngIf="isValueDiscount">
                <div class="row">
                  <div class="col-md-12 btn-group">
                    <p-inputSwitch formControlName="SwitchValueDiscount" 
                      [disabled]="this.process == processEnum.view" 
                      (onChange)="changeValueDiscount()">
                    </p-inputSwitch>
                    <label class="text-center">{{"ExclusionBySku.ValueDiscount"| translate}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5"></div>

          <div class="col-md-12 card shadow" *ngIf="isKindDiscount || isFinancialDiscount || isValueDiscount">
            <div class="row card-body">
              <div class="col-md-12">
                <h4>
                  <label>{{"ExclusionBySku.LimitDiscount"| translate}}</label>
                </h4>
              </div>
              <div class="col-md-4" *ngIf="isKindDiscount">
                <div class="col-md-12">
                  <label>{{"ExclusionBySku.KindDiscount"| translate}}</label>
                </div>
                <div class="col-md-12">

                  <div class="p-inputgroup">
                    <input #KindDiscount id="KindDiscount"
                           formControlName="KindDiscount"
                           autocomplete="off"
                           [readonly]="this.process == processEnum.view"
                           pInputText
                           (keyup)="keyUpOnlyNumberWithPoint($event, 5)">
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <small id="KindDiscountValidator"
                      *ngIf="isKindDiscountValidator"
                      class="p-error">{{"ExclusionBySku.KindDiscountValidate"| translate}}
                  </small>
              </div>

              </div>
              <div class="col-md-4" *ngIf="isFinancialDiscount">
                <div class="col-md-12">
                  <label>{{"ExclusionBySku.FinancialDiscount"| translate}} </label>
                </div>
                <div class="col-md-12">
                  <div class="p-inputgroup">
                    <input #FinancialDiscount id="FinancialDiscount"
                          formControlName="FinancialDiscount"
                          autocomplete="off"
                          [readonly]="this.process == processEnum.view"
                          pInputText
                          (keyup)="keyUpOnlyNumberWithPoint($event, 6)"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <small id="FinancialDiscountValidator"
                        *ngIf="isFinancialDiscountValidator"
                        class="p-error">{{"ExclusionBySku.FinancialDiscountValidate"| translate}}
                  </small>
                </div>
              </div>
              <div class="col-md-4" *ngIf="isValueDiscount">
                <div class="col-md-12">
                  <label>{{"ExclusionBySku.ValueDiscount"| translate}}</label>
                </div>
                <div class="col-md-12">
                  <div class="p-inputgroup">
                    <input #ValueDiscount id="ValueDiscount"
                          formControlName="ValueDiscount"
                          autocomplete="off"
                          [readonly]="this.process == processEnum.view"
                          pInputText
                          (keyup)="keyUpOnlyNumberWithPoint($event, 7)"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <small id="ValueDiscountValidator"
                        *ngIf="isValueDiscountValidator"
                        class="p-error">{{"ExclusionBySku.ValueDiscountValidate"| translate}}
                  </small>
                </div>
              </div>
            </div>
          </div>

        <!--FIN: Campos descuentos-->
      </div>
      </div>
    </form>

  </div>
  <!-- FIN FORM -->

  <!-- INICIO FILTROS MATERIALES -->
  <div class="container" *ngIf="showMaterialFilter">
    <section id="Products" >
      <div class="row mt-5">
        <div class=" container pstn_container container-fluid container_size">
          <div class="row">
            <app-hierarchy-advanced-filters 
              [isMultiFilter]="isMultiFilter" 
              (eventClickFilters)="eventClickFilters($event)"
              [isProcess]="process == processEnum.view ? 3 : 1" [editFiltersObjectList]="editFiltersObjectList">
            </app-hierarchy-advanced-filters>
            <div class="row mt-5"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- FIN FILTROS MATERIALES -->

  <!-- INICIO TABLA FILTROS MATERIALES -->
  <div class="container" *ngIf="showMaterialFilter">
    <section id="Products" >
      <div class="row mt-5">
        <div class=" container pstn_container container-fluid container_size">
          <div class="row">
            <app-exclusions-table-filter [productFilterListPromotion]="productFilterListPromotion"
              (deleteGroupEvent)="deleteGroupAction($event)" [isProcess]="process == processEnum.view ? 3 : 1" >
            </app-exclusions-table-filter>
            <div class="row mt-5"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
<!-- FIN TABLA FILTROS MATERIALES -->

<div class="row mt-5" *ngIf="showMaterialFilter"></div>

<!-- INICIO FILTRO CLIENTES -->
<div class="container" *ngIf="!showMaterialFilter">
  <section id="Products" >
    <div class="row mt-5">
      <div class=" container pstn_container container-fluid container_size">
        <div class="row">


          <app-advanced-client-filter 
            [isProcess]="process" 
            [filtersEdit]="filtersEdit"
            [isCountryRequired]="true"
            (eventClickFiltersClient)="eventClickFiltersClient($event)"
            (eventClickDeleteFiltersClient)="eventClickDeleteFiltersClient()">
          </app-advanced-client-filter>


          <div class="row mt-5"></div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- FIN FILTRO CLIENTES -->

<!-- INICIO TABLA FILTRO CLIENTES -->
<div class="container" *ngIf="!showMaterialFilter">
  <section id="Products" >
    <div class="row mt-5">
      <div class=" container pstn_container container-fluid container_size">
        <div class="row">

            <div class="table-responsive">
              <!-- PrimeNg Table -->
              <p-table #dt name="gridSolicitudeClients" [value]="summaryClients" [paginator]="true" [rows]="10"
                paginatorPosition="bottom" styleClass="p-datatable-customers">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="groupClients">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'AssingClient.Group' | translate }}
                        <p-sortIcon field="groupClients"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="labelType">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'AssingClient.Type' | translate }}
                        <p-sortIcon field="labelType"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="codeType">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'AssingClient.Code' | translate }}
                        <p-sortIcon field="codeType"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="total">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'AssingClient.Total' | translate }}
                        <p-sortIcon field="total"></p-sortIcon>
                      </div>
                    </th>
                    <th>{{ 'Actions' | translate }}</th>
                  </tr>
                  <tr>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
                  <tr>
                    <td>
                      {{object.groupClients}}
                    </td>
                    <td>
                      {{object.labelType}}
                    </td>
                    <td>
                      {{object.codeType}}
                    </td>
                    <td>
                      {{object.total}}
                    </td>
                    <td>
                      <i class="uil uil-search-alt Editar"
                        pTooltip="{{'Detail' | translate}}"
                       (click)="showModal(object)" aria-hidden="true"></i>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td colspan="3">TOTAL</td>
                    <td>{{totalClients}}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- PrimeNg Table -->
            </div>

          <div class="row mt-5"></div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- FIN TABLA FILTRO CLIENTES -->

<div class="row mt-5" *ngIf="!showMaterialFilter"></div>

<!-- INICIO BOTONES FORMULARIO -->
<div class="container" >
  <div class="col-md-12">
    <div class="row" *ngIf="showMaterialFilter">
      <div class="col-lg-6 md-6 mt-6">
        <div class="col-lg-10 md-10 mt-10">
          <button type="button"
            class="p-button-rounded pstn_primario w-100"
            (click)="cancel()"
            style="background-color: darkgrey; border-color: darkgrey;">
            {{'CancelBtn' | translate}}
          </button>
        </div>
      </div>
      <div class="col-lg-6 md-6 mt-6">
        <div class="col-lg-10 md-10 mt-10">
          <button pButton pRipple
              type="button"
              class="p-button-rounded pstn_primario w-100"
              label="{{'NextBtn' | translate}}"
              style="float: right;" (click)="next()">
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!showMaterialFilter">
      <div class="col-lg-6 md-6 mt-6">
        <div class="col-lg-10 md-10 mt-10">
          <button type="button"
            class="p-button-rounded pstn_primario w-100"
            (click)="return()"
            style="background-color: darkgrey; border-color: darkgrey;">
            {{'ReturnBtn' | translate}}
          </button>
        </div>
      </div>
      <div class="col-lg-6 md-6 mt-6">
        <div class="col-lg-10 md-10 mt-10">
          <button pButton pRipple
              *ngIf="process == processEnum.create"
              type="button"
              class="p-button-rounded pstn_primario w-100"
              label="{{'SaveBtn' | translate}}"
              style="float: right;" (click)="save()">
          </button>
          <button pButton pRipple
              *ngIf="process == processEnum.update"
              type="button"
              class="p-button-rounded pstn_primario w-100"
              label="{{'UpdateBtn' | translate}}"
              style="float: right;" (click)="update()">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN BOTONES FORMULARIO -->

<div class="row mt-5"></div>

<!--INICIO MODAL-->

<p-dialog header="{{ 'AssingClient.GroupDetail' | translate }}" 
  [(visible)]="displayModal" 
  [modal]="true" 
  [style]="{ width: '60vw' }"
  [baseZIndex]="10000" 
  [draggable]="false" 
  [resizable]="false">
  <ng-template pTemplate="content">


  </ng-template>


  <!-- PrimeNg Table -->
  <p-table #dt name="gridSolicitudeDetailGroup" [paginator]="false" [rows]="10" [value]="detailsClientsResult"
    currentPageReportTemplate="{{ 'CurrentPage' | translate }}" [rowsPerPageOptions]="[10, 25, 50, 100]"
    paginatorPosition="bottom" styleClass="p-datatable-customers">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ 'AssingClient.ClientCode' | translate }}
            <p-sortIcon field="statusDescription"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{ 'AssingClient.CustomerName' | translate }}
            <p-sortIcon field="creationUser"></p-sortIcon>
          </div>
        </th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body" let-object>
      <tr>
        <td>{{ object.sClientCode }}</td>
        <td>{{ object.sCustomerName }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- PrimeNg Table -->


  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'CloseBtn' | translate}}" icon="pi pi-times" class="p-button-text"
      (click)="hideModal()"></button>
  </ng-template>
</p-dialog>

<!--FIN MODAL-->

<!-- MODAL LISTA TRASLAPADAS -->
<p-dialog
  header="{{ 'ExclusionBySku.TitleOverlap' | translate }}"
  [(visible)]="displayModalList"
  [modal]="true"
  [style]="{ width: '60vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template pTemplate="content"> </ng-template>
  <p-table
    #dt
    [paginator]="false"
    [value]="listError"
    [rows]="10"
    currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    paginatorPosition="bottom"
    styleClass="p-datatable-customers"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          <div class="p-d-flex p-jc-between p-ai-center">
             {{"ExclusionBySku.IdExclusion"| translate}}
            <!-- <p-sortIcon field="statusDescription"></p-sortIcon> -->
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
              {{"Detail"| translate}}
            <!-- <p-sortIcon field="creationUser"></p-sortIcon> -->
          </div>
        </th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body" let-object>
      <tr>
        <td>{{ object }}</td>
        <td>
          <i id="btnEye"
                                         aria-hidden="true"
                                         class="uil uil-search-alt Editar"
                                         pTooltip="{{'Detail' | translate}}"
                                         (click)="viewDetailsOverlap(object)"
                                         ></i>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="{{ 'CloseBtn' | translate }}"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideModal2(true)"
    ></button>
  </ng-template>
</p-dialog>


<!-- MODAL DETALLE LISTA TRASLAPAD -->
<p-dialog
  [(visible)]="displayModalList2"
  [modal]="true"
  [style]="{ width: '60vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template pTemplate="content"> </ng-template>
  <p-table
    #dt
    [paginator]="false"
    [value]="listOverlap"
    [rows]="10"
    currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    paginatorPosition="bottom"
    styleClass="p-datatable-customers"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"ExclusionBySku.Clients"| translate}}
            <!-- <p-sortIcon field="statusDescription"></p-sortIcon> -->
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"ExclusionBySku.NameSku"| translate}}
            <!-- <p-sortIcon field="creationUser"></p-sortIcon> -->
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
             {{"ExclusionBySku.CodigoSku"| translate}}
            <!-- <p-sortIcon field="creationUser"></p-sortIcon> -->
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{"ExclusionBySku.TypeComercialActivity"| translate}}
            <!-- <p-sortIcon field="creationUser"></p-sortIcon> -->
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
             {{"ExclusionBySku.Channel"| translate}}
            <!-- <p-sortIcon field="creationUser"></p-sortIcon> -->
          </div>
        </th>
        <th pSortableColumn="creationUser">
          <div class="p-d-flex p-jc-between p-ai-center">
             {{"ExclusionBySku.Validity"| translate}}
            <!-- <p-sortIcon field="creationUser"></p-sortIcon> -->
          </div>
        </th>
      </tr>
      <tr></tr>
    </ng-template>
    <ng-template pTemplate="body" let-object>
      <tr>
        <td>{{ object.clientFilter }}</td>
        <td>{{ object.groupNameSku }}</td>
        <td>{{ object.codeSku }}</td>
        <td>{{ object.typeBusinessActivity }}</td>
        <td>{{ object.channel }}</td>
        <td>{{ object.validityPeriod }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">
          {{ "ElementsNotFound" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="{{ 'CloseBtn' | translate }}"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideModal2(false)"
    ></button>
  </ng-template>
</p-dialog>


</section>


<p-toast></p-toast>
