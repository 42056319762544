import { IAssociateProfile } from '../interfaces/associate-profile.interface';
import { IAssociateUser } from '../interfaces/associate-user.interface';

export class AssociateProfileModel implements IAssociateProfile {
    /**
    * @author Jhon Steven Pavón Bedoya
    * @param id
    * @param description
    * @param associateUsers
    * @sprint 6
    */
    constructor(
        public id: number,
        public description: string,
        public associateUsers: IAssociateUser[]
    ) {}

  }
