<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'">
            <i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/manage-promotional-activities'">
            {{ "ManagePromotionalActivitiesComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" >
          {{ "ManagePromotionalActivitiesComponent.Redemption.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion" *ngIf="viewDetailComponent && !viewDetailSelectedFilter">
  <div class="container">
    <section id="GeneralData">

      <div class="column">
        <div class="col-md-6">
          <h1 class="pstntitle">
            {{ "ManagePromotionalActivitiesComponent.Redemption.Title" | translate }}
          </h1>
        </div>
        <div class="col-md-6" 
          *ngIf="isProcess == commercialActivityEnum.view || isProcess == commercialActivityEnum.update">
          <h6 class="pstntitle">{{'Comercialactivity' | translate}} : {{ commercialActivityId }}</h6>
        </div>
      </div>

      <form id="formCommercialRedemption" [formGroup]="formCommercialRedemption">

        <!-- GENERAL DATA -->
        <div class="row mt-5">

          <!-- description -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.Description" | translate }}</label>
              <input #user id="description" formControlName="description" autocomplete="off"
                placeholder="{{'ManagePromotionalActivitiesComponent.Create.DescriptionPlaceHolderInput' | translate}}"
                class="form-control" pInputText requerid />
            </div>
          </div>

          <!-- type commercial activity -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.TypeCommercialActivity" | translate }}</label>
              <input #user id="typeCommercialActivity" formControlName="typeCommercialActivity" autocomplete="off"
                class="form-control" pInputText />
            </div>
          </div>

          <!-- Channel -->
          <div class="form-check col-md-4">
            <div class="form-group">
              <label>{{ "ManagePromotionalActivitiesComponent.Create.Channel" | translate }}</label>
              <div class="form-group">
                <p-multiSelect id="channel" 
                  class="multiselect-custom-virtual-scroll multitooltip" 
                  [virtualScroll]="true"
                  [options]="objectChannelList" 
                  [filter]="true" 
                  formControlName="channel"
                  placeholder="{{'ManagePromotionalActivitiesComponent.Create.ChannelPlaceHolderCombo' | translate}}"
                  optionLabel="label" 
                  optionValue="value" 
                  display="chip" 
                  [style]="{width: '100%'}">
                </p-multiSelect>

                  <small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
                    <ul>
                    <li *ngFor="let index of channelTooltip">{{index.channelDescription}}</li>
                    </ul>
                    </small>

                  <small id="channel" *ngIf="
                formCommercialRedemption.controls['channel'].invalid &&
                (formCommercialRedemption.controls['channel'].dirty ||
                formCommercialRedemption.controls['channel'].touched)"
                  class="p-error">{{"ManagePromotionalActivitiesComponent.Create.ChannelMsgValidate"|
                  translate}}</small>
              </div>
            </div>
          </div>

					<!-- Sector -->
					<div class="form-check col-md-4" *ngIf="sectorCommercialActivityParam">
						<div class="form-group">
							<label>{{ "ManagePromotionalActivitiesComponent.Create.Sector" | translate }}</label>
							<div class="form-group">
								<p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll multitooltip"
									[virtualScroll]="true" [options]="objectSectorList" formControlName="sector" [filter]="true"
									placeholder="{{'ManagePromotionalActivitiesComponent.Create.SectorPlaceHolderCombo' | translate}}"
									optionLabel="label" optionValue="value" display="chip" [style]="{width: '100%'}"
									(onChange)="selectSectorValidate()"></p-multiSelect>
                  <small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
                    <ul>
                    <li *ngFor="let index of sectorTooltip">{{index.sectorDescription}}</li>
                    </ul>
                    </small>
								<small id="sectorValidator" *ngIf="
                                    formCommercialRedemption.controls['sector'].invalid &&
                                    (formCommercialRedemption.controls['sector'].dirty ||
                                    formCommercialRedemption.controls['sector'].touched)"
									class="p-error">{{"ManagePromotionalActivitiesComponent.Create.SectorMsgValidate"|
									translate}}</small>
							</div>
						</div>
					</div>

        </div>
      </form>
    </section>

    <section id="Ranges" *ngIf="!viewDetailSelectedFilter">
      <div class="row mt-5">
        <div class="pstn_container container-fluid">
          <p-tabView (onChange)="viewPanel()">
            <!--PANEL CANJEABLES-->
            <p-tabPanel #Canjeables 
              header ="{{ 'ManagePromotionalActivitiesComponent.Redemption.PanelRedemptionI' | translate }}"  
              [selected]="viewCanjeables">
              <div class="container-table header">
                <div class="p-fluid p-grid p-formgrid col-md-6 col-sm-12 row align-items-center flex-wrap justify-content-between">


                      <span>{{ 'ManagePromotionalActivitiesComponent.Redemption.ExchangeableProducts' | translate }}</span>

                </div>
                <div class="p-fluid p-grid p-formgrid col-md-6 col-sm-12 row align-items-center flex-wrap justify-content-between"
                  *ngIf="isProcess !== comercialActivityEnum.view">


                      <!--COMPONENTE FILTRO CANJEABLE-->
                      <app-redeemable-filter [isProcess]="isProcess"
                                             [listProductSelectedEdit]="objectRedeemableCommercialActivitySelected"
                                            (eventClickFilterRedemtion)="newRecordRedeemable($event)"
                                            (eventClickClearFilter)="clearFilter($event)">
                      </app-redeemable-filter>
                      <!--COMPONENTE FILTRO CANJEABLE FIN-->

                </div>
              </div>
              <div class="container-table body">
                <p-table #dt editMode="row" name="gridApprovalRank" [paginator]="true" [rows]="10"
                  paginatorPosition="bottom" dataKey="materialCode" [value]="objectRedeemableCommercialActivity"
                  currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
                  [rowsPerPageOptions]="[10,25,50,100]" styleClass="p-datatable-customers">
                  <ng-template pTemplate="header" style="width: 100%;">
                    <tr>
                      <th style="width: 20%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'ManagePromotionalActivitiesComponent.Redemption.Code' | translate}}
                        </div>
                      </th>
                      <th style="width: 30%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'ManagePromotionalActivitiesComponent.Redemption.Description' | translate}}
                        </div>
                      </th>
                      <th style="width: 10%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'ManagePromotionalActivitiesComponent.Redemption.Quantity' | translate}}
                        </div>
                      </th>
                      <th style="width: 20%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'ManagePromotionalActivitiesComponent.Redemption.IndividualWeightValue' | translate}}
                        </div>
                      </th>
                      <th style="width: 10%;" *ngIf="isProcess !== comercialActivityEnum.view">
                        {{ "Actions" | translate }}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-redeemableCommercialActivity let-editing="editing"
                    let-ri="rowIndex">
                    <tr [pEditableRow]="redeemableCommercialActivity">
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input 
                              type="text" 
                              pInputText 
                              [(ngModel)]="redeemableCommercialActivity.materialCode" 
                              [disabled]="true" 
                              [style]="{ width: '100%' }"/>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <input 
                              type="text" 
                              pInputText 
                              [(ngModel)]="redeemableCommercialActivity.materialCode" 
                              [disabled]="true" 
                              [style]="{ width: '100%' }"/>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input 
                              type="text" 
                              pInputText 
                              [(ngModel)]="redeemableCommercialActivity.descriptionRedeemable" 
                              [disabled]="true" 
                              [style]="{ width: '100%' }"/>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <input 
                              type="text" 
                              pInputText 
                              [(ngModel)]="redeemableCommercialActivity.descriptionRedeemable" 
                              [disabled]="true" 
                              [style]="{ width: '100%' }"/>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">

                            <p-inputNumber 
                              [(ngModel)]="redeemableCommercialActivity.amount" 
                              mode="decimal" 
                              [min]="0" [max]="999"
                              [disabled]="false" 
                              [style]="{ width: '100%' }" 
                              [inputStyle]="{ width: '100%' }"
                              [minFractionDigits]="0" [maxFractionDigits]="0">
                            </p-inputNumber>
                          </ng-template>
                          <ng-template pTemplate="output">

                                   <p-inputNumber 
                                    [(ngModel)]="redeemableCommercialActivity.amount" 
                                    mode="decimal" 
                                    [min]="0" [max]="999"
                                    [disabled]="true" 
                                    [style]="{ width: '100%' }" 
                                    [inputStyle]="{ width: '100%' }"
                                    [minFractionDigits]="0" [maxFractionDigits]="0">
                                  </p-inputNumber>

                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-inputNumber [disabled]="false" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"
                                           currency="USD" locale="en-US" mode="currency"
                                           [(ngModel)]="redeemableCommercialActivity.individualPesoValue">
                            </p-inputNumber>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-inputNumber [disabled]="true" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"
                                           currency="USD" locale="en-US" mode="currency"
                                           [(ngModel)]="redeemableCommercialActivity.individualPesoValue">
                            </p-inputNumber>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align:center" *ngIf="isProcess !== comercialActivityEnum.view">
                        <i *ngIf="!editing" class="uil uil-edit Editar" pTooltip="{{'Edit' | translate}}" aria-hidden="true" pInitEditableRow (click)="onRowEditInit(redeemableCommercialActivity)"></i>
                        <i *ngIf="!editing" aria-hidden="true" pTooltip="{{'Delete' | translate}}" class="uil uil-trash-alt eliminar" (click)="deleteRowRange(redeemableCommercialActivity)"></i>
                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                          class="p-button-rounded p-button-text p-button-success p-mr-2" (click)="saveRedeemable(redeemableCommercialActivity)"></button>
                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                          class="p-button-rounded p-button-text p-button-danger" (click)="onRowEditCancel(redeemableCommercialActivity, ri)"></button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="4">{{ 'ElementsNotFound' | translate }}</td>
                    </tr>
                  </ng-template>
                </p-table>
                <div class="row mt-3 pb-3 d-flex justify-content-center">
                  <div *ngIf="showAccumulatedWeightValue" class="col-md-3 md-3 mt-3">
                    <label>Valor pesos acumulado </label>
                    <p-inputNumber
                      [min]="0"
                      currency="USD"
                      [(ngModel)]="accumulatedValue"
                      locale="en-US"
                      mode="currency"
                      (onChange)="validateAccumulatedValue()"
                      [disabled]="isProcess === comercialActivityEnum.view"></p-inputNumber>
                  </div>
                </div>
              </div>
            </p-tabPanel>
            <!--PANEL CANJEABLES FIN-->
            <!--PANEL REDIMIBLES-->
            <p-tabPanel #Redimibles header ="{{ 'ManagePromotionalActivitiesComponent.Redemption.PanelRedemptionII' | translate }}" [selected]="viewRedimibles">
              <div class="container-table header">
                <span>{{ "ManagePromotionalActivitiesComponent.Redemption.ProductsRedeemable" | translate }}</span>
              </div>
              <div class="container-table body" *ngIf="showTable">
                <p-table #dttea
                    name="gridAdvancedFilters"
                    [value]="objectRedeemableCommercialActivity"
                    dataKey="hierarchyFilter"
                    [paginator]="true"
                    [rows]="10"
                    paginatorPosition="bottom"
                    styleClass="p-datatable-customers"
                    [rowsPerPageOptions]="[10,25,50,100]"
                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
                    [globalFilterFields]="['hierarchyFilter']">

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 20%;">
                          <div class="p-d-flex p-jc-between p-ai-center">{{ 'ManagePromotionalActivitiesComponent.Redemption.Code' | translate}}</div>
                        </th>
                        <th style="width: 20%;">
                          <div class="p-d-flex p-jc-between p-ai-center">{{ 'ManagePromotionalActivitiesComponent.Redemption.Description' | translate}}</div>
                        </th>
                        <th style="width: 10%;">
                          <div class="p-d-flex p-jc-between p-ai-center">{{ 'ManagePromotionalActivitiesComponent.Redemption.Quantity' | translate}}</div>
                        </th>
                        <th style="width: 20%;">
                          <div class="p-d-flex p-jc-between p-ai-center">{{ 'ManagePromotionalActivitiesComponent.Redemption.IndividualWeightValue' | translate}}</div>
                        </th>
                      </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
                      <tr>
                        <td>{{object.materialCode}}</td>
                        <td>{{object.descriptionRedeemable}}</td>
                        <td>{{object.amount}}</td>
                        <td>{{object.individualPesoValue | currency}}</td>
                      </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-tabPanel>
            <!--PANEL REDIMIBLES FIN-->
          </p-tabView>
        </div>
      </div>
    </section>

    <!--COMPONENTE FILTRO-->
    <section id="Filter" *ngIf="viewRedimibles && !viewDetailSelectedFilter">
      <div class="row mt-5">
        <div class="pstn_container container-fluid ">
          <app-advanced-redeemable-filter [isProcess]="isProcess"
                                          [isMultiFilter]="isMultiFilter"
                                          [sector]="sector.value"
                                          [requireSector]="true"
                                          [editFiltersObjectList]="filtersObjectList"
                                          [editFiltersObjectDataList]="filtersObjectDataList"
                                          (eventClickFilters)="eventFilters($event)"
                                          (eventFilters)="eventDataFilters($event)"
                                          (eventClickDeleteFilter)="eventDeleteFilter($event)"
                                          (eventClickDeleteFilters)="eventDeleteFilters($event)">
          </app-advanced-redeemable-filter>
        </div>
      </div>
    </section>
    <!--COMPONENTE FILTRO FIN-->

    <!--TABLA CON FILTRO AGRUPADO-->
    <section id="TableWhitGroup" *ngIf="viewRedimibles">
      <div class="row mt-5">
        <div class="pstn_container container-fluid ">

          <div class="container-table header">
            <span>{{ "ManagePromotionalActivitiesComponent.Redemption.SelectedProductRedeemable" | translate }}</span>
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
              (click)="toggleTable()"
              [icon]="showTable ? 'uil uil-angle-up arrow-up' : 'uil uil-angle-down arrow-down'"></button>
          </div>

          <div class="container-table body" *ngIf="showTable">
          <!--
            <div class="container-table options" *ngIf="isProcess !== comercialActivityEnum.view">
              <span>
                Borrar seleccionados
                <i id="btnDeleteBatch" aria-hidden="true" class="uil uil-trash-alt eliminar"
                  (click)="deleteAllAction()"
                  *ngIf="(rolePermission.delete | rolePermission)"></i>
              </span>
            </div>
          -->
            <p-table #dttea
                name="gridAdvancedFilters"
                [value]="filtersObjectList"
                dataKey="hierarchyFilter"
                [paginator]="true"
                [rows]="10"
                paginatorPosition="bottom"
                styleClass="p-datatable-customers"
                [rowsPerPageOptions]="[10,25,50,100]"
                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
                [globalFilterFields]="['hierarchyFilter']">

                <ng-template pTemplate="header">
                  <tr>
                  <!--
                    <th role="columnheader" style="width: 3rem">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                  -->
                    <th pSortableColumn="type">
                      <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'ManagePromotionalActivitiesComponent.Create.Product' | translate }}
                          <p-sortIcon field="type"></p-sortIcon>
                      </div>
                    </th>
                    <th>{{ 'Actions' | translate }}</th>
                  </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
                  <tr>
                    <!--
                      <td>
                          <p-tableCheckbox [value]="object"></p-tableCheckbox>
                      </td>
                    -->
                      <td>{{object.group}}</td>
                      <td>
                        <button id="btnDelete"
                                type="button"
                                class="edit btn btn-secundary-editar"
                                pTooltip="{{'Detail' | translate}}"
                                (click)="infoDetail(object, comercialActivityEnum.view)">
                            <i class="uil uil-search-alt Editar" aria-hidden="true"></i>
                        </button>
                        <button id="btnDelete" *ngIf="isProcess !== comercialActivityEnum.view"
                                type="button"
                                pTooltip="{{'Delete' | translate}}"
                                class="delete btn btn-secundary-delete"
                                (click)="deleteGroupFilter(rowIndex, object)">
                              <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
                        </button>
                      </td>
                  </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </section>
    <!--TABLA CON FILTRO AGRUPADO-->

		<div class="row mt-5"></div>

    	  <!-- CreateDocumentPromotion -->
			<div id="accordionCreateDocumentPromotion">
				<app-commercial-activity-document [title]="'ManagePromotionalActivitiesComponent.Create.DocumentCreate' | translate"
                                                                                #documentSupport
				                                                                [routeCancel]="routeCancelCreatePromotion"
																				[showFields]="showFieldsCreatePromotion"
																				[promotionEdit]="promotionEdit"
																				[isProcess]="isProcess"
																				[commercialActivityTypeId]="typeCommercialActivity.value"
                                        [commercialActivityId]="commercialActivityId"
																				[observationEdit]="observationEdit"
																				(eventClickSaveBtn)="eventClickSaveBtn($event)"
																				(eventClickEditBtn)="eventClickEditBtn($event)"
																				(eventClickSendValidateBtn)="eventClickSendValidateBtn()"></app-commercial-activity-document>
			</div>
	
			<div class="row mt-5"></div>


    <!-- CreatePromotion -->
    <div class="accordion" id="accordionCreatePromotion">
      <app-commercial-activity-footer
        [commercialActStatVal]="commercialActStatVal"
        title="{{'ManagePromotionalActivitiesComponent.Redemption.TitleDate' | translate }}"
        [routeCancel]="routeCancelCreatePromotion" [showFields]="showFieldsCreatePromotion"
        [showButtons]="showButtonsCreatePromotion" [promotionEdit]="promotionEdit" 
        [isNext]="false" [isProcess]="isProcess" [contFecha]="contFecha"
        (eventClickSaveBtn)="eventClickSaveBtn($event)" (eventClickEditBtn)="eventClickEditBtn($event)"
        (eventChangeData)="prepareFooterForEvent($event)">
      </app-commercial-activity-footer>
    </div>

  </div>
</section>

<!--DETAIL COMPONENT-->
<section class="pstngestion" *ngIf="viewDetailSelectedFilter">
  <div class="container">
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <h1 class="pstntitle">
            {{ "ManagePromotionalActivitiesComponent.Redemption.ProductDetailRedention" | translate }}
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-5">
      <div class="pstn_container container-fluid">
        <div class="row">
          <div style="color: #009CDD;">
            <h4>{{ "ManagePromotionalActivitiesComponent.Redemption.SelectedProduct" | translate}} : </h4>
          </div>
          <div style="margin-left: 10px;">
            <h4><strong> {{groupSelected}} </strong></h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-5">
      <div class="pstn_container container-fluid">
        <div class="container-table options">
          <span>
              Borrar seleccionados
              <i 
              id="btnDeleteBatch" 
              pTooltip="{{'Delete' | translate}}"
              class="uil uil-trash-alt eliminar"
               aria-hidden="true" 
               (click)="deleteChecked()"
               *ngIf="(rolePermission.delete | rolePermission)"></i>
            </span>
        </div>

          <p-table #dttea
            name="gridAdvancedFilters"
            [value]="productsSelected"
            dataKey="hierarchyFilter"
            [paginator]="true"
            [rows]="10"
            paginatorPosition="bottom"
            styleClass="p-datatable-customers"
            [rowsPerPageOptions]="[10,25,50,100]"
            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
            [globalFilterFields]="['hierarchyFilter']">

            <ng-template pTemplate="header">
              <tr>
                  <th style="width: 30px;">
                    <p-checkbox (click)="selectDeleteAll(e.checked)" [(ngModel)]="checkDelete" binary="true" #e>
                    </p-checkbox>
                  </th>
                  <th pSortableColumn="materialCode">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'ManagePromotionalActivitiesComponent.Redemption.CodSku' | translate }}
                      <p-sortIcon field=""></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="product">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'ManagePromotionalActivitiesComponent.Redemption.DescriptionSku' | translate }}
                      <p-sortIcon field=""></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="product">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'ManagePromotionalActivitiesComponent.Redemption.ProductType' | translate }}
                      <p-sortIcon field=""></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="purchaseUnit">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'ManagePromotionalActivitiesComponent.Redemption.Amount' | translate }}
                      <p-sortIcon field=""></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="additionalValueCheck">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'ManagePromotionalActivitiesComponent.Redemption.AdtionalValueCheck' | translate }}
                      <p-sortIcon field=""></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="additionalValue">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'ManagePromotionalActivitiesComponent.Redemption.AdtionalValue' | translate }}
                      <p-sortIcon field=""></p-sortIcon>
                    </div>
                  </th>
                <th pSortableColumn="">{{ 'Actions' | translate }} </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
                <tr>
                    <td style="width: 30px;">
                      <p-checkbox (click)="selectRemoveAll()" [(ngModel)]="object.check" binary="true">
                      </p-checkbox>
                    </td>
                    <td> {{object.materialCode}} </td>
                    <td> {{object.product}} </td>
                    <td> {{object.zofeDescription}} </td>
                    <td>
                      <input
                            #in
                           id="input-detail-product-{{object.id}}"
                            name="input-detail-product-{{object.id}}"
                            [(ngModel)]="object.purchaseUnit"
                            class="form-controll" pInputText
                            [style]="{ width: '8rem' }"
                            ng-pattern="/^[0-9]+\.([0-9]{1,2})$/"
                            (onChange)="assingValue(groupSelected, object)"
                            onkeypress="return ((event.charCode >= 48 && event.charCode <= 57))"
                            [disabled]="isProcess === comercialActivityEnum.view" />
                    </td>
                    <td style="width: 6rem">
                      <p-inputSwitch id="switch-{{object.id}}"
                                    name="switch-{{object.id}}"
                                    [(ngModel)]="object.additionalValueCheck"
                                    (onChange)="getValueProduct($event, object)"
                                    [disabled]="isProcess === comercialActivityEnum.view">
                      </p-inputSwitch>
                    </td>
                    <td>
                       {{object.additionalValue | currency}}
                    </td>
                    <td>
                      <button id="btnDelete" *ngIf="isProcess !== comercialActivityEnum.view && (rolePermission.delete | rolePermission)" pTooltip="{{'Delete' | translate}}" type="button"
                          class="delete btn btn-secundary-delete" (click)="deleteDetail(rowIndex, object)">
                          <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
                      </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8">{{ 'ElementsNotFound' | translate }}</td>
              </tr>
            </ng-template>
        </p-table>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="row mt-5">
      <button id="btnReturn"
            pButton
            pRipple
            type="button"
            label="{{ 'ReturnBtn' | translate }}"
            class="pstn_primario"
            style="background-color: darkgrey; border-color: darkgray;"
            (click)="closeSelectedProduct()">
      </button>
    </div>
  </div>

</section>
<!--DETAIL COMPONENT END-->

<div class="row mt-5"></div>
<div *ngIf="redemptionActivityEdit">
  <app-commercial-activity-planned
	  [isVisible]="redemptionActivityEdit.statusDomainId == commercialActivityStatusEnum.Planned"
	  [commercialActivityId]="commercialActivityId"
  ></app-commercial-activity-planned>
</div>

<p-toast></p-toast>
