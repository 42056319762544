<section id="ResultProducts">
  <div class="container-table body">

    <div class="container-table options">
      <span *ngIf="isProcess!=3">
        {{ 'ExclusionComponent.DeleteSelected' | translate }}
        <i id="btnDeleteBatch"
        class="uil uil-trash-alt eliminar"
        (click)="deleteAction(null)"
        aria-hidden="true"
        *ngIf="(rolePermission.delete | rolePermission)"></i>
      </span>
    </div>

    <p-table [value]="productFilterListPromotion" dataKey="id" styleClass="p-datatable-customers" [paginator]="true"
      [rows]="10" paginatorPosition="bottom" currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
      [rowsPerPageOptions]="[10,25,50,100]">
      >
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="isProcess!=3">
            <p-checkbox (click)="selectDeleteAll(e.checked)" [(ngModel)]="checkDelete" binary="true" #e>
            </p-checkbox>
          </th>
          <th>{{ "ExclusionComponent.CodigoSku" | translate }}</th>
          <th>{{ "ExclusionComponent.NameSku" | translate }}</th>
          <th>{{ "SolicitudComponent.Create.ProductType" | translate }}</th>
          <th *ngIf="isProcess!=3">{{'Actions' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-element>
        <tr>
          <td *ngIf="isProcess!=3">
            <p-checkbox [(ngModel)]="element.check" binary="true">
            </p-checkbox>
          </td>
          <td>
            {{ element.materialCode }}
          </td>
          <td>
            {{ element.product }}
          </td>
          <td>{{ element.zofeDescription }}</td>
          <td *ngIf="isProcess!=3">
            <button id="btnDelete"
              ngbTooltip="Eliminar"
              type="button"
              class="delete btn btn-secundary-delete"
              (click)="deleteAction(element)"
              *ngIf="(rolePermission.delete | rolePermission)">
              <i class="uil uil-trash-alt eliminar" aria-hidden="true" ></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</section>
