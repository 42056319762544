import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ICommercialActivityFooter } from '@app/@components/commercial-activity-footer/interfaces/commercial-activity-footer.interface';
import { CommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/commercial-activity-footer.model';
import { FieldsCommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/fields-commercial-activity-footer.model';
import { IHierarchyFiltersMaterials } from '@app/@components/hierarchy-advanced-filters/interfaces/hierarchy-filters-materials.interface';
import { IHeaderTableGroupItem } from '@app/@components/tables-with-groups/interfaces/header-table-group-item.interface';
import { ISubGroupItem } from '@app/@components/tables-with-groups/interfaces/sub-group-item.interface';
import { ISubHeaderTableGroupItem } from '@app/@components/tables-with-groups/interfaces/subheader-table-group-item.interface';
import { ITableGroupItem } from '@app/@components/tables-with-groups/interfaces/table-group-item.interface';
import { Rappel } from '@app/@components/tables-with-groups/models/group/rappel.model';
import { SubLoyalty } from '@app/@components/tables-with-groups/models/subGroup/loyalty.model';
import { SubDiscountPriceAdjustment } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-price-adjustment.model';
import { SubRappel } from '@app/@components/tables-with-groups/models/subGroup/sub-rappel.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { Operator } from '@app/@shared/enums/operator.enum';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { DomainService } from '@app/@shared/services/domain.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { SweetAlertResult } from 'sweetalert2';
import { ManagePromotionalActivitiesService } from '../manage-promotional-activities.service';
import { ManagePromotionalActivitiesMessage } from '../messages/manage-promotional-activities-messages.message';
import { IProductGroupList } from './interfaces/product-group-list.interface';
import { IProductList } from './interfaces/product-list.interface';
import { ProductGroupListModel } from './models/product-group-list.model';
import { RangeRappel } from './models/ranges-rappel.model';
import { MeasuredType } from '../../../../@shared/enums/measured-type.enum';
import { MeasuredTypeSymbol } from '../../../../@shared/enums/measured-type-symbol.enum';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Consideration } from '../../../../@shared/enums/consideration-type.enum';
import { ConsiderationSymbol } from '@app/@shared/enums/consideration-type-symbol.enum';
import { environment } from '@env/environment';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { CommercialActivityRappelService } from './commercial-activity-rappel.service';
import { CommercialActivityRappelModel } from './models/commercial-activity-rappel.model';
import { MeasurementUnit } from '@app/@shared/enums/measurement-unit.enum';
import { IChannel } from './interfaces/channel-interface';
import { HttpErrorResponse } from '@angular/common/http';
import { CommercialActivityEventsMessage } from '../commercial-activity-events/messages/commercial-activity-events.message';
import { SubDiscountFinancial } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-financial.model';
import { SubDiscountKind } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-kind.model';
import { SubDiscountValue } from '@app/@components/tables-with-groups/models/subGroup/sub-discount-value.model';
import { ICommercialActivityRappel } from './interfaces/commercial-activity-rappel-interface';
import { IDataRange } from '@app/@components/commercial-activity-footer/interfaces/data-range.interface';
import { ApprovalRankMessage } from '../../approval-ranks/messages/approval-rank.message';
import { RankNumber } from '@app/@shared/enums/rank-numbers.enum';
import { ICommercialActivitySector } from '../interfaces/commercial-activity-sector.interface';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { CommonConfiguration } from '@shared/enums/common.enums';
import { PromotionalActivitiesCreateService } from '../promotional-activities-create/promotional-activities-create.service';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { ICustomer } from '../promotional-activities-create/interfaces/customer-list.interface';
import { CommercialActivityDiscountModel } from '../commercial-activity-discount/models/commercial-activity-discount.model';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { CommercialActivityDiscountService } from '../commercial-activity-discount/commercial-activity-discount.service';
import { IRangesRappel } from './interfaces/ranges-rappel.interfaces';
import { CommercialACtivityQueueValidationModel } from '../models/commercial-activity-queue-validation.model';
import { Module } from '@app/@shared/enums/module.enums';
import { CommercialActivityInvalidationDataDetail, CommercialActivityInvalidationDataDetailShow } from './../interfaces/commercial-activity-invalidation-data-detail';
import { CommercialActivityStatusModel } from '../models/commercial-activity-status.model';
import { IUserCreation } from '@app/@components/commercial-activity-approval-selected/interface/user-creation.interface';
import { UserCreationModel } from '@app/@components/commercial-activity-approval-selected/models/user-creation.model';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';
import { ICommercialActivityHandlerPeriod } from '@app/@components/elements/calendar-period/interfaces/handler-period.interface';
import { ICommercialActivityHandler } from '@app/@components/handling-product-filter/interfaces/commercial-activity-handler.interface';
import { ActivityHandlerModel } from '@app/@components/handling-product-filter/models/activity-handler.model';
import { HandlingProductFilterComponent } from '@app/@components/handling-product-filter/handling-product-filter.component';
import { HandlingProductFilterService } from '@app/@components/handling-product-filter/handling-product-filter.service';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';
import { CommercialActivityDocumentComponent } from '@app/@components/commercial-activity-document/commercial-activity-document.component';
import { CommercialActivityDocumentRequiredModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document-required.model';
import { CommercialActivityDocumentModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document.model';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { SwitchService } from '@app/@components/elements/switch/switch.service';

@Component({
	selector: 'app-commercial-activity-rappel',
	templateUrl: './commercial-activity-rappel.component.html',
	styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
	styles: [
		`
    .container-table.header{
      padding-left: 29px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #009CDD;
      font-weight: 700;
      font-size: 19px;
      margin-bottom:50px;
    }
    .container-table.options{
      padding-left: 29px;
      font-weight: 400;
      font-size: 14px;
    }
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
	],
	providers: [MessageService, DatePipe],
})
export class CommercialActivityRappelComponent implements OnInit {

	@ViewChild('activityHandler') activityHandler : HandlingProductFilterComponent;
	@ViewChild('ActivityHandlerCurrentMonth') ActivityHandlerCurrentMonth : HandlingProductFilterComponent;
	@ViewChild('documentSupport') documentSupport: CommercialActivityDocumentComponent;

	private readonly managePromotionActService: ManagePromotionalActivitiesService;
	private readonly spinner: NgxSpinnerService;
	private readonly coreService: CoreMasterService;
	private readonly domainService: DomainService;
	private readonly router: Router;
	private readonly messageService: MessageService;
	private readonly fb: FormBuilder;
	private readonly rappelService: CommercialActivityRappelService;
	private readonly parameterService: ManageParametersService;
	private readonly promotionalActivityService: PromotionalActivitiesCreateService;
	private readonly discountService: CommercialActivityDiscountService;
	private readonly translate: TranslateService;
	private readonly i18nService: I18nService;
	private readonly promotionalActivityHandlerService: HandlingProductFilterService;

	public routeCancelCreatePromotion = '/auth/manage-promotional-activities';
	public showFieldsCreatePromotion: FieldsCommercialActivityFooterModel;
	public showButtonsCreatePromotion = true;
	public promotionEdit: CommercialActivityFooterModel;
	public promotionEditFooter: CommercialActivityFooterModel;
	public typeCommercialActivityId = 0;
	public typeCommercialActivityN = '';
	public commercialActivityId = 0;
	public objectChannelList: ICombo[];
	public objectProcessList: ICombo[];
	public symbolMeasuredTypeValue: string;
	public symbolConsiderationValue: string;
	public objectMeasuredTypeList: ICombo[];
	public objectSettlementPeriodList: ICombo[];
	public objectConsiderationList: ICombo[] = [];
	public objectTypeCommercialActivity: any[] = [];
	public isProcess: number;
	public showTable = true;
	public showTableFilter = true;
	public showTableRanges = true;
	public isMultiFilter = true;
	public editFiltersObjectList: IHierarchyFiltersMaterials[] = [];
	public advancedFiltersObjectList: IHierarchyFiltersMaterials[] = [];
	public productGroupFilterListPromotion: IProductGroupList[] = [];
	public valueTable: ITableGroupItem[] = [];
	public subHeaderTable: ISubHeaderTableGroupItem[] = [];
	public headersTable: IHeaderTableGroupItem[] = [];
	public objectRangeRappel: RangeRappel[] = [];
	public objectOperatorListMinimun: ICombo[];
	public objectOperatorListMax: ICombo[];
	public inputFormatMeasured = true;
	public inputFormatConsideration = true;
	public formCommercialRappel: FormGroup;
	public clonedRangeRappel: { [s: number]: RangeRappel; } = {};
	public isPurchaseBase = false;
	public productGroupFilterList: IProductGroupList[] = [];
	public rappelActivityEdit: CommercialActivityRappelModel;
	public nextAssignClients = false;
	public commercialActivityCustomers: ICustomer[];
	public commercialActStatVal: number;
	public objectSectorList: ICombo[];
	public objectSectorSelect: ICombo[] = [];
	public sectorCommercialActivityParam = false;
	public commercialActivityEnum = ComercialActivityEnum;
	public discountActivityEdit: CommercialActivityDiscountModel;
	public purchaseBaseToCalculate: IProductList[] = [];
	public commActivityStatusRegisteredId: number =
		CommercialActivityStatus.Registered;
	public filterClientJson = '';
	public filterClientJsonToCalculate = '';
	public commercialActivityStateEnum: any;
	public arrayDeleteSelect: any[] = [];
	public arrayDeleteSelectGroup: any[] = [];
	public displayValidationsDetails = false;
    public isInitialError: boolean = true;
	public invalidationDataDetail: CommercialActivityInvalidationDataDetailShow[] = [];
	public subHeaderTableConst: ISubHeaderTableGroupItem[] = [
		{
			name: 'ManagePromotionalActivitiesComponent.Discount.subHeaderTable.codSku',
			attr: 'sku'
		},
		{
			name: 'ManagePromotionalActivitiesComponent.Discount.subHeaderTable.desSku',
			attr: 'descSku'
		},{
      name: 'ManagePromotionalActivitiesComponent.Discount.subHeaderTable.productType',
			attr: 'prodType',
    }
	];

	public headersTableConst: IHeaderTableGroupItem[] = [
		{
			name: 'Productos',
			attr: 'descGroup'
		}
	];
	public get comercialActivityEnum(): typeof ComercialActivityEnum {
		return ComercialActivityEnum;
	}
	public rolePermission: any = RolePermissionEnum;
	public channelTooltip: string[];
	public sectorTooltip: string[];

	public userCreation : IUserCreation;
	public initialDate : Date;
	public language: string = sessionStorage.getItem('language');

		// manejantes
	public showManejantes = false;
	public editFiltersObjectHandlerList: IHierarchyFiltersMaterials[] = [];
	public editFiltersObjectHandlerList2: IHierarchyFiltersMaterials[] = [];
	public commercialActivityHandlerDetails: any[] = [];
	public commercialActivityHandlerDetails2: any[] = [];
	public commercialActivityHandlerDetailsAux: any[] = [];
	public commercialActivityHandlerDetailsAux2: any[] = [];
	public commercialActivityHandlerPeriod : ICommercialActivityHandlerPeriod[] = [];
	public commercialActivityHandlerPeriod2 : ICommercialActivityHandlerPeriod[] = [];
	public commercialActivityHandler?: ICommercialActivityHandler;
	public commercialActivityHandler2?: ICommercialActivityHandler;
	public commercialActivityHandlerisGroup?: boolean;
	public commercialActivityHandlerisGroup2?: boolean;
	public commercialActivityHandlerisClientWithoutSales?: boolean;
	public commercialActivityHandlerisClientWithoutSales2?: boolean;

	//HandlerCurrentMonth
	public commercialActivityHandlerCurrentMonth?: ICommercialActivityHandler;
	public commercialActivityHandlerCurrentMonth2?: ICommercialActivityHandler;
	public editFiltersObjectHandlerCurrentMonthList: IHierarchyFiltersMaterials[] = [];
	public editFiltersObjectHandlerCurrentMonthList2: IHierarchyFiltersMaterials[] = [];
	public commercialActivityHandlerCurrentMonthDetails: any[] = []; // faltan 2 
	public commercialActivityHandlerCurrentMonthDetails2: any[] = [];
	public commercialActivityCurrentMonthDetailsAux2: any[] = []; //Faltan 2 por si validaciones

	//Observable
	public isCurrentMonth = false;

	public flagHanlder? = false;
	public documentInfo: CommercialActivityDocumentModel[];
	public flagDocumentObservation: boolean = true;
	public documentFieldsRequired: CommercialActivityDocumentRequiredModel;
	public observationEdit: CommercialActivityDocumentModel[];
	public documentValid: boolean = true;
	public showFields : IFieldsClientFilter = new FieldsClientFilterModel(true, true, true, false, true, true);

	public typeCoreMaster : any = TypeCoreMaster;
	public isCalculate : boolean = false;

	constructor(
		managePromotionActService: ManagePromotionalActivitiesService,
		spinner: NgxSpinnerService,
		coreService: CoreMasterService,
		domainService: DomainService,
		router: Router,
		messageService: MessageService,
		fb: FormBuilder,
		rappelService: CommercialActivityRappelService,
		parameterService: ManageParametersService,
		promotionalActivityService: PromotionalActivitiesCreateService,
		discountService: CommercialActivityDiscountService,
		translate: TranslateService,
    	i18nService: I18nService,
		promotionalActivityHandlerService: HandlingProductFilterService,
		private switchService : SwitchService
	) {
		this.managePromotionActService = managePromotionActService;
		this.spinner = spinner;
		this.coreService = coreService;
		this.domainService = domainService;
		this.router = router;
		this.messageService = messageService;
		this.fb = fb;
		this.rappelService = rappelService;
		this.parameterService = parameterService;
		this.promotionalActivityService = promotionalActivityService;
		this.discountService = discountService;
		this.translate = translate;
    	this.i18nService = i18nService;
		this.promotionalActivityHandlerService = promotionalActivityHandlerService;
		this.switchService.data$.subscribe((data) => {
			this.isCurrentMonth = data;
		  });
	 }

	ngOnInit(): void {
		this.commercialActivityStateEnum = this.getCommercialActivityStatusEnum();
		this.showFieldsCreatePromotion = new FieldsCommercialActivityFooterModel(true, true, true, true, true, true, true, true);
		this.isProcess = this.managePromotionActService.getIsCreate();
		this.initForm();
		this.isInitialError = true;
		this.forkJoinInitialData(true);
	}

	public getCommercialActivityStatusEnum(): typeof CommercialActivityStatus {
		return CommercialActivityStatus;
	}

	/**
	* @description this method execute async requests.
	* @author Daniel londono
	* @sprint 14
	*/
	forkJoinInitialData(isFirst: boolean = false): void {
		if(this.language === this.translate.currentLang && !isFirst){
			return;
		  }else{
			this.translate.use(this.i18nService.language);
			this.language = this.i18nService.language;
		  }

		this.spinner.show();

		const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
		const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);
		const periodResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.SettlementPeriod);
		const measuredTyperesponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.TypeMeasure);
		const considerationResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Consideration);
		const operatorResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Operator);

		forkJoin([
			channelResponse,
			processResponse,
			periodResponse,
			measuredTyperesponse,
			considerationResponse,
			operatorResponse
		])
			.subscribe(
				(result) => {
					this.coreService.getAll(TypeCoreMaster.Sector).subscribe((response) =>
					{
						this.objectSectorList = response.data.map((item) => {
							return {
								label: item.description,
								value: item.code.trim(),
								crossActive: item.crossActive
							};
						});

                        if (this.objectSectorList && this.objectChannelList)
						{
 						  const userFilters = this.managePromotionActService.setUserFilters(this.objectChannelList, this.objectSectorList);
						  this.objectChannelList =  userFilters.channels;
						  this.objectSectorList =  userFilters.sectors;
						}
					});

					this.parameterService.getAll().subscribe((response) => {
						const param = response.data.filter((prm) => prm.id === ManagementParameterEnum.sectorCommercialActivity);
						if (param) {
							this.sectorCommercialActivityParam = param[0].status;
							if (this.sectorCommercialActivityParam) {
								this.sector.setValidators([Validators.required]);
							}
						}
					});

					const objChannelResponse: IResponseService<ICoreMaster[]> = result[0];
					const objProcessResponse: IResponseService<IDomain[]> = result[1];
					const objPeriodResponse: IResponseService<IDomain[]> = result[2];
					const objMeasuredTyperesponse: IResponseService<IDomain[]> = result[3];
					const objConsiderationResponse: IResponseService<IDomain[]> = result[4];
					const objOperatorResponse: IResponseService<IDomain[]> = result[5];

					this.objectChannelList = objChannelResponse.data.map((item) => {
						return {
							label: item.description,
							value: item.code.trim()
						};
					});

					this.objectProcessList = objProcessResponse.data.map((item) => {
						return {
							label: item.description,
							value: item.id
						};
					});

					this.objectSettlementPeriodList = objPeriodResponse.data.map((item) => {
						return {
							label: this.translate.instant('Domain.Domain-'+item.id),
							value: item.id
						};
					});

					this.objectMeasuredTypeList = objMeasuredTyperesponse.data.map((item) => {
						return {
							label: this.translate.instant('Domain.Domain-'+item.id),
							value: item.id
						};
					});

					if (objConsiderationResponse) {
						this.objectConsiderationList = [];
						objConsiderationResponse.data.forEach((item) => {
							if (item && item.code !== CommonConfiguration.ConsiderationCode) {
								this.objectConsiderationList.push({
									label: this.translate.instant('Domain.Domain-'+item.id),
									value: item.id
								});
							}
						});
					}

					this.objectOperatorListMinimun = objOperatorResponse.data.map((item) => {
						return {
							label: item.description,
							value: item.code
						};
					});

					this.objectOperatorListMax = this.objectOperatorListMinimun.filter((x) => x.value === Operator.LessThan || x.value === Operator.LessThanEqual);
					this.objectOperatorListMinimun = this.objectOperatorListMinimun.filter((x) => x.value !== Operator.LessThan && x.value !== Operator.LessThanEqual);

					this.objectTypeCommercialActivity = [...objProcessResponse.data];

					if (this.isProcess === this.commercialActivityEnum.update || this.isProcess === this.commercialActivityEnum.view) {
						this.initEditRappel();
					} else {
						this.initCreateRappel();
					}
				}, () => {
					this.spinner.hide();
					SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
				}
			);
		this.spinner.hide();
	}

		/**
	 * @description This method is to validate if the commercial activity is invalid
	 *
	 * @param statusDomainId nunmber of status domaind id from the commercial activity
	 * @param validationResult Json string with the validation result
	 * @sprint 21
	 * @author Diego Mauricio Cortés
	 */
	verifyInvalidateData(statusDomainId: number, validationResult: string): void {
		if (CommercialActivityStatus.Invalid === statusDomainId && validationResult) {
         try{
			const validation: any = JSON.parse(validationResult);
			const invalidation: CommercialActivityInvalidationDataDetail = {...validation};
			this.invalidationDataDetail= [];
			invalidation.result.forEach((rsp) => {
				let skus = '';
				let clients = 0;
				if (!rsp.clients.status) {
					clients = rsp.clients.repeated;
				}
				if (!rsp.material.status) {
					skus = rsp.material.materialCodesrepeated.join();
				}
				if (clients > 0 || skus !== '') {
					this.invalidationDataDetail.push({
						id: rsp.id,
						clients: clients,
						skus: skus
					});
				}
			  });
			  this.displayValidationsDetails = true;
		    }
			catch(error)
			{
				if (this.isInitialError)
				{
					const language: string = sessionStorage.getItem('language');
					const msg = language === 'es-ES' ? SweetAlert.messageErrorValidationActivity
												: SweetAlert.messageErrorValidationActivityTranslate;

					Util.optionalMessage(msg).then((result : SweetAlertResult) =>{
						if(result.value){
							this.eventClickSendValidateBtn();
						}else{
							this.displayValidationsDetails = false;
							this.isInitialError = false;
						}
					})
				}
			}
		}
	}

	/**
	 * @description This method is to verify if the user wants update the commercial activity to status registered or deleted
	 * @param opc boolean with the user select
	 * @sprint 21
	 * @author Diego Mauricio Cortes
	 */
	registryInvalidateCommercialACtivity(opc: boolean): void {
		this.displayValidationsDetails = false;
		this.spinner.show();
		if (opc === true) {
			this.discountActivityEdit.commercialActivityHandler = this.commercialActivityHandler;
			this.rappelActivityEdit.statusDomainId = CommercialActivityStatus.Registered;
			this.discountService.putPromotionalActivity(
				this.rappelActivityEdit,
				this.commercialActivityId
			).subscribe(
				(response) => {
					if (response.status) {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
						this.router.navigate(['auth/manage-promotional-activities']);
					} else {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
					}
					this.spinner.hide();
				}, (error: HttpErrorResponse) => {
					this.spinner.hide();
					SweetAlertMessageHelpers.exception(error);
				});
		} else {
			this.managePromotionActService.delete(`${this.commercialActivityId}`)
			.subscribe(
				(response) => {
					this.spinner.hide();
					if (response.status) {
						const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
						this.router.navigate(['auth/manage-promotional-activities']);
					} else {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
					}
				},
				(error: HttpErrorResponse) => {
					this.spinner.hide();
					SweetAlertMessageHelpers.exception(error);
				}
			);
		}

	}

	prepareRangeToEditRappelActivity(rappel: CommercialActivityRappelModel): void {
		if (rappel.commercialActivityRange) {
			this.objectRangeRappel = rappel.commercialActivityRange.map((item: any) => {
				return {
					rangeRappelId: item.id,
					commercialActivityId: item.commercialActivityId,
					minimumRangeOperator: item.minimumRangeOperator,
					minimumRange: item.minimumRange,
					maxRangeOperator: item.maxRangeOperator,
					maxRange: item.maxRange,
					notApply: item.notApply,
					contributionValue: item.contributionValue
				};
			});
			this.consideration.disable();
			this.measuredType.disable();
		}
	}

	public toggleTableFilter() {
		this.showTableFilter = !this.showTableFilter;
	}

	/**
* @description This method for event for filter.
*
* @author Diego Mauricio Cortés
* @sprint 8
*/
	eventClickFilters($event: any): void {
		
		if (!this.isPurchaseBase && this.valueTable.length > 0) {
			let productArray = [];
			let productCero = [];
			this.valueTable.forEach((elm) => {
				elm.subOptions.forEach((itm) => {
					this.valueTable.forEach((x) => {
						productArray = x.subOptions.filter((y) => y.sku === itm.sku);
					});
				});
			});

			this.valueTable.forEach((elm) => {
				elm.subOptions.forEach((itm) => {
					if (itm.percentDiscountValue  === '0') {
						productCero.push(itm);
					}

				});
			});

			if(productArray.length > 0){
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
					language === 'es-ES' ? SweetAlert.mesageDiscountfilter: SweetAlert.mesageDiscountfilterTranslate);
				return;
			}

			if(productCero.length > 0){
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
					language === 'es-ES' ? SweetAlert.mesageFilterDiscount : SweetAlert.mesageFilterDiscountTranslate);
				return;
			}

		}


		if ($event.advancedFiltersObjectList.length > 0) {
			this.spinner.show();
			this.advancedFiltersObjectList = $event.advancedFiltersObjectList;
			const newFilterModel = new FilterDataModel(
				JSON.stringify($event.advancedFiltersObjectList),
				environment.conditionTable,
				environment.conditionClassCode,
				false
			);
			this.getMaterialsByFilters(newFilterModel);
		} else {
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(
				SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS : ManagePromotionalActivitiesMessage.NOT_FILTERS_TRANSLATE
			);
		}
	}

	public schematicResultsToTable(groups: IProductGroupList[]) {

		const valueTableConvert: ITableGroupItem[] = [];
		let valueHeadersTable: IHeaderTableGroupItem[] = [];
		let valueSubHeaderTable: ISubHeaderTableGroupItem[] = [];


		const extraOptions = this.factoryExtraOptions(this.typeCommercialActivity.value);
		const InterfaceOption = extraOptions.Interface;
		const SubInterfaceOption = extraOptions.SubInterface;
		valueHeadersTable = [...this.headersTableConst, ...extraOptions.headersTable];
		valueSubHeaderTable = [...this.subHeaderTableConst, ...extraOptions.subHeaderTable];
		groups.forEach((group, index) => {
			let newGroup: ITableGroupItem = new InterfaceOption();

			newGroup = new InterfaceOption(
				group.id.toString(),
				`Grupo ${index + 1}`,
				group.measurementUnitCode ? group.measurementUnitCode : MeasurementUnit.PIECE,
				group.groupByJson,
				group.groupName,
				this.schematicSubGroupToTable(group.products, SubInterfaceOption),
				false
			);


			valueTableConvert.push(newGroup);
		});

		return {
			valueTableConvert,
			valueHeadersTable,
			valueSubHeaderTable
		};
	}

	public factoryExtraOptions(type: any) {
		switch (type) {
			case 'Rappel':
				return {
					headersTable: [] as any[],
					subHeaderTable: [] as any[],
					Interface: Rappel,
					SubInterface: SubRappel
				};
			default:
				break;
		}
	}

	public sumPurchaseUnit(childs: ISubGroupItem[]): number {
		let result = 0;

		childs.forEach((x) => {
			result += Number(x.purcheseUnit);
		});

		return result;
	}

	public schematicSubGroupToTable(subGroups: IProductList[], SubInterfaceOption: any) {
		return subGroups.map((subGroup) => {
			const newSubGroup: ISubGroupItem = new SubInterfaceOption();
			newSubGroup.descSku = subGroup.product;
			newSubGroup.id = subGroup.id.toString();
			newSubGroup.sku = subGroup.materialCode;
			newSubGroup.unitMeasure = '';
			newSubGroup.check = false;
			newSubGroup.currentPsp = subGroup.currentPsp;
      newSubGroup.prodType = subGroup.zofeDescription;

			if (newSubGroup instanceof SubRappel) {
				newSubGroup.purcheseUnit = subGroup.purchaseUnit;
			}

			return newSubGroup;
		});
	}

	public toggleTable() {
		this.showTable = !this.showTable;
	}

	public toggleTableRanges() {
		this.showTableRanges = !this.showTableRanges;
	}

	public getSelectList(object: any) {
		if (object.check === true || object.check.checked === true) {
			this.arrayDeleteSelect.push(object);
		} else {
			const index = this.arrayDeleteSelect.findIndex((elm) => elm.sku === object.sku);
			if (index !== -1) {
				this.arrayDeleteSelect.splice(index, 1);
			}
		}
	}
	public getSelectGroup(object : any){
		if(object.check === true){
		  this.arrayDeleteSelectGroup.push(object);
		}else{
		  const index = this.arrayDeleteSelectGroup.findIndex((elm) => elm.descGroup === object.descGroup);
		  if(index !==-1){
			this.arrayDeleteSelectGroup.splice(index, 1);
		  }
		}
	  }

	public deleteAllAction() {
		Util.confirmDelete().then(async (result: SweetAlertResult) => {
			if (result.value) {
				const skuIds : number[] = [];
				this.valueTable.forEach((object) => {
					this.arrayDeleteSelect.forEach((objectselect) => {
						const index = object.subOptions.findIndex((elm) => elm.sku === objectselect.sku);
						if (index !== -1) {
							const idSKU = object.subOptions.find((f) => f.sku === objectselect.sku).id;
							if(parseInt(idSKU) > 0){
								skuIds.push(parseInt(idSKU, 10));
								this.productGroupFilterListPromotion.map( (item) =>
									{
										const products = item.products.filter((i) => i.id !== parseInt(idSKU));
										item.products = [...products];
										return {...item};
									}
								);
							}
							object.subOptions.splice(index, 1);
						}
					});
				});
				this.deleteMaterialDB(skuIds);

				if(this.arrayDeleteSelectGroup.length > 0){
					this.arrayDeleteSelectGroup.forEach((objectselect) => {
					const indexGroup = this.valueTable.findIndex((elm) => elm.descGroup === objectselect.descGroup);
					if(indexGroup !== -1){
					  this.valueTable.splice(indexGroup, 1);
					}
					});
				}

				const groupIdToDelete = this.productGroupFilterListPromotion.filter((item) => item.products.length !== 0).map((i) => i.id);
				if(groupIdToDelete.length > 0){
					this.managePromotionActService.deleteProductGroupsList(groupIdToDelete.join(',')).toPromise();
						this.arrayDeleteSelectGroup.forEach((x) =>{
							this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion.filter((item) => item.id !== Number(x.id) )];
						});
				}

				  this.valueTable.forEach((elm) =>{
					if(elm.subOptions.length === 0){
					  let index = this.valueTable.indexOf(elm);
					  this.valueTable.splice(index, 1);
					}
				  });
			}
			this.arrayDeleteSelectGroup= [];
			this.arrayDeleteSelect = [];
		});
	}

	public deleteMaterialDB(skuIds: number[]) {
		if(skuIds.length > 0){
			this.managePromotionActService.deleteProductList(skuIds.join(',')).toPromise();
			const groupIdToDelete = this.productGroupFilterListPromotion.filter((item) => item.products.length === 0).map((i) => i.id);
			if(groupIdToDelete.length > 0){
				this.managePromotionActService.deleteProductGroupsList(groupIdToDelete.join(',')).toPromise();
				this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion.filter((item) => item.products.length !== 0)];
			}
		}
	}

	public deleteGroupAction(groupToDelete: any) {
		Util.confirmDelete().then(async (result: SweetAlertResult) => {
			if (result.value) {
				const groupIndexFount = this.valueTable.findIndex((gr: any) => gr.contentGroup === groupToDelete.contentGroup);
				this.valueTable.splice(groupIndexFount, 1);
				await this.managePromotionActService
					.deleteProductGroupsList(`${groupToDelete.id}`)
					.toPromise();
				if(groupToDelete.id > 0){
					this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion.filter((item) => item.id !== groupToDelete.id)];
				}
			}
			this.arrayDeleteSelect= [];
		});

	}

	async eventClickSaveBtn($event: ICommercialActivityFooter) {

		this.documentValid = this.validateDocuments();
		let validateManejantes = true;

		if(this.showManejantes){ 
			this.prepareActivityHandler();
			this.isCurrentMonth ? this.prepareActivityHandlerCurrentMonth() : this.commercialActivityHandlerCurrentMonth2 = null;
			validateManejantes = this.isProcess === this.commercialActivityEnum.view ? true : await this.validateHandler();
		}else{
			this.commercialActivityHandlerPeriod2 = [];
			
			this.commercialActivityHandler2 = {
				commercialActivityId : 0,
				id : 0,
				groupByJson : '',
				isGroup: false,
				commercialActivityHandlerDetails : [],
				isClientWithoutSales : false,
				isCurrentMonth : false
			};
		} 

		if (this.validateForm() && this.validationSkus() && this.validationRanges() && this.formCommercialRappel.valid && validateManejantes && this.documentValid) {
			this.spinner.show();
			if (this.validateData()) {
				if (this.formCommercialRappel.valid) {
					this.rappelActivityEdit = this.prepareCommercialActivityRappelToCreate($event);
					this.nextAssignClients = true;
					this.prepareFiltersToEditProductsRappelActivity(this.rappelActivityEdit);
				}
				else {
					Object.values(this.formCommercialRappel.controls).forEach((control) => {
						if (control.invalid) {
							control.markAsTouched();
						}
					});
				}
			}
			this.spinner.hide();
		}
	}

	validateForm() {
		if (this.sectorCommercialActivityParam) {
			if (this.sector.value[0] === undefined || this.channelRappel.value[0] === undefined) {
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.warning(
					language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
					language === 'es-ES' ? `${CommercialActivityEventsMessage.NO_CHANNEL_SECTOR}` : `${CommercialActivityEventsMessage.NO_CHANNEL_SECTOR_TRANSLATE}`
				);
				this.formCommercialRappel.markAllAsTouched();
				return false;
			}
		}
		if(this.settlementPeriod.value === ''){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
				language === 'es-ES' ? `${CommercialActivityEventsMessage.NO_SETTLEMENTPERIOD}` : `${CommercialActivityEventsMessage.NO_SETTLEMENTPERIOD_TRANSLATE}`
			);
			return false;
		}
		return true;
	}

	validationSkus() : boolean{
		if(this.valueTable.length === 0){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate,
				language === 'es-ES' ? SweetAlert.messageSelectSKU: SweetAlert.messageSelectSKUTranslate);
			return false;
		}
		return true;
	}

	validationRanges() : boolean{
		if(this.objectRangeRappel.length === 0){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate,
				language === 'es-ES' ? SweetAlert.messageAddRank : SweetAlert.messageAddRankTranslate);
			return false;
		}
		return true;
	}

	eventClickSendDataClients($event: any) {
		if ($event.jsonFilterClient || $event.listClient) {
			this.rappelActivityEdit.filterClientJson = $event.jsonFilterClient;
			this.rappelActivityEdit.commercialActivityCustomers = this.prepareCommercialActivityCustomerCreate($event.listClient);
			this.rappelActivityEdit.isCalculate = false;
			this.rappelActivityEdit.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate(this.rappelActivityEdit);
			this.rappelActivityEdit.totalClientsFilter = $event.totalClients;
			this.rappelActivityEdit.commercialActivityObservation= this.documentInfo;
			this.prepareCommercialActivitiesDocumentsToCreate();

			const isNotValid: boolean = this.validateTableInformation(this.valueTable, this.rappelActivityEdit);
			this.eventClickSaveBtnI(isNotValid, this.rappelActivityEdit);
		} else {
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'El filtro de clientes es obligatorio'
				: 'The customer filter is mandatory');
		}
	}

	/**
	 * @description This method is to validate the selected sector combinations
	 *
	 * @sprint 17
	 * @author Diego Mauricio Cortés
	 */
	selectSectorValidate(): void {
		if (this.sector.value.length > 1)
		{
			this.sector.value.forEach((x:string) => {
				if (this.objectSectorList.find((y) => y.value === x && !y.crossActive))
				{
					const inx = this.sector.value.findIndex((z:string) => z === x)
					this.sector.value.splice(inx,1);
				}
			});
		}
	}

	validateTableInformation(valuTable: ITableGroupItem[], valueTableModel: CommercialActivityRappelModel): boolean {
		let isNotValid = false;
		valuTable.forEach((value) => {
			const { subOptions, descGroup } = value;

			if (!isNotValid) {
				subOptions.forEach((subOption) => {
					if (
						subOption instanceof SubDiscountPriceAdjustment &&
						(subOption.percentDiscountPriceAdjustment === '' &&
							subOption.valueDiscountPriceAdjustment === '')
					) {
						isNotValid = true;
						const language: string = sessionStorage.getItem('language');
						SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
							language === 'es-ES' ? `${CommercialActivityEventsMessage.NO_DISCOUNT_PRICE_ADJUSTMENT} por favor revisar el ${descGroup}`
							: `${CommercialActivityEventsMessage.NO_DISCOUNT_PRICE_ADJUSTMENT_TRANSLATE} please check the ${descGroup}`);
						this.spinner.hide();
						return;

					} else if (
						subOption instanceof SubDiscountFinancial &&
						subOption.percentageDiscountFinancial === ''
					) {
						isNotValid = true;
						const language: string = sessionStorage.getItem('language');
						SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
							language === 'es-ES' ? `${CommercialActivityEventsMessage.NO_DISCOUNT_FINANCIAL} por favor revisar el ${descGroup}`
							: `${CommercialActivityEventsMessage.NO_DISCOUNT_FINANCIAL_TRANSLATE} please check the ${descGroup}`);
						this.spinner.hide();
						return;

					} else if (
						subOption instanceof SubDiscountKind &&
						subOption.percentDiscountKind === ''
					) {
						isNotValid = true;
						const language: string = sessionStorage.getItem('language');
						SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
							language === 'es-ES' ? `${CommercialActivityEventsMessage.NO_DISCOUNT_KIND} por favor revisar el ${descGroup}`
							: `${CommercialActivityEventsMessage.NO_DISCOUNT_KIND_TRANSLATE} please check the ${descGroup}`);
						this.spinner.hide();
						return;

					} else {
						if (
							subOption instanceof SubDiscountValue &&
							subOption.percentDiscountValue === ''
						) {
							isNotValid = true;
							const language: string = sessionStorage.getItem('language');
							SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
								language === 'es-ES' ? `${CommercialActivityEventsMessage.NO_DISCOUNT_VALUE} por favor revisar el ${descGroup}`
								: `${CommercialActivityEventsMessage.NO_DISCOUNT_VALUE_TRANSLATE} please check the ${descGroup}`);
							this.spinner.hide();
							return;
						}
					}
				});
			}
		});
		return isNotValid;
	}

	/**
	 * @description This method is to prepare commercial activity rappel data to create
	 *
	 * @author Daniel Londono
	 * @sprint 14
	 */
	prepareCommercialActivityRappelToCreate(footerData: ICommercialActivityFooter) {
		const promotionalActivityModel: CommercialActivityRappelModel = new CommercialActivityRappelModel(
			this.typeCommercialActivityId,
			null,
			footerData.statusDomainId,
			this.description.value,
			footerData.startTime,
			footerData.endTime,
			footerData.orderLimit,
			footerData.periodLimit,
			footerData.numberDays,
			0,
			this.consideration.value,
			this.settlementPeriod.value,
			this.measuredType.value,
			this.transformDataCommercialProductRappel(this.valueTable),
			[],
			footerData.dataRanges,
			this.prepareChannelsToSave(),
			this.objectRangeRappel,
			null,
			[],
			null,
			null,
			null,
			null,
			null,
			this.showManejantes,
			this.commercialActivityHandler2,
			this.commercialActivityHandlerCurrentMonth2,
			this.commercialActivityHandlerPeriod2
		);
		return promotionalActivityModel;
	}


	async eventClickEditBtn($event: ICommercialActivityFooter){

		this.documentValid = this.validateDocuments();
		let validateManejantes = true;

		if(this.showManejantes){ 
			this.prepareActivityHandler();
			this.isCurrentMonth ? this.prepareActivityHandlerCurrentMonth() : this.commercialActivityHandlerCurrentMonth2 = null;
			validateManejantes = this.isProcess === this.commercialActivityEnum.view ? true : await this.validateHandler();
		}else {
			this.commercialActivityHandlerPeriod2 = [];
			
			this.commercialActivityHandler2 = {
				commercialActivityId : 0,
				id: 0,
				groupByJson : '',
				isGroup: false,
				commercialActivityHandlerDetails : [],
				isClientWithoutSales : false,
				isCurrentMonth : false
			};
		}

		if (this.validateForm() && this.validationSkus() && this.validFormRappel() && this.validationTope($event.orderLimit, $event.periodLimit) && validateManejantes && this.documentValid) {
			this.nextAssignClients = true;
			this.rappelActivityEdit = this.prepareCommercialActivityRappelToEdit($event);
			this.rappelActivityEdit.commercialActivityChannels = this.prepareChannelsToSave();
			this.rappelActivityEdit.commercialActivitySector = this.prepareSectorsToSave();
			this.discountActivityEdit.commercialActivityObservation = this.documentInfo;
		}
	}

	validFormRappel(): boolean{
		if(this.isProcess === this.commercialActivityEnum.view){
			return true;
		}
		return this.formCommercialRappel.valid;
	}

	validationTope(order: number, period: number): boolean{
		if(order == 0 || period == 0){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate ,
				language === 'es-ES' ? SweetAlert.messageTope : SweetAlert.mesageTopeTranslate);
			return false;
		}
		return true;
	}

	eventClickSendDataEditClients($event: any) {
		this.spinner.show();
		this.rappelActivityEdit.commercialActivityCustomers = this.prepareCommercialActivityCustomerCreate($event.listClient);
		this.rappelActivityEdit.totalClientsFilter = $event.listClient == null ? $event.totalClients : $event.listClient.length;
		this.rappelActivityEdit.filterClientJson = $event.jsonFilterClient;
		this.prepareCommercialActivitiesDocumentsToCreate();
		this.rappelActivityEdit.commercialActivityObservation = this.documentInfo;

		this.discountService.putPromotionalActivity(this.rappelActivityEdit, this.rappelActivityEdit.id).subscribe(
			(response) => {
				if (response.status) {
					SweetAlertMessageHelpers.showMessage(TypeMessage.Success);
					this.router.navigate(['auth/manage-promotional-activities']);
				} else {
					SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
				}
				this.spinner.hide();
			}, (error: HttpErrorResponse) => {
				this.spinner.hide();
				SweetAlertMessageHelpers.exception(error);
			});
	}

	
	prepareRangeRappelToSendEdit(): IRangesRappel[] {
		let rsp: IRangesRappel[] = [];
		rsp = this.objectRangeRappel.map((range) => {
			return {
				commercialActivityId: this.rappelActivityEdit.id,
				rangeRappelId: range.rangeRappelId,
				minimumRangeOperator: range.minimumRangeOperator,
				minimumRange: range.minimumRange,
				maxRangeOperator: range.maxRangeOperator,
				maxRange: range.maxRange,
				notApply: range.notApply,
				contributionValue: range.contributionValue
			};
		});
		return rsp;



	}

	changeMeasureType(): void {
		if (this.measuredType.value === MeasuredType.Piezas) {
			this.symbolMeasuredTypeValue = MeasuredTypeSymbol.Piezas;
			this.inputFormatMeasured = true;
		}
		else {
			if (this.measuredType.value === MeasuredType.Valor) {
				this.symbolMeasuredTypeValue = MeasuredTypeSymbol.Valor;
				this.inputFormatMeasured = true;
			}
		}
	}

	changeConsideration(): void {
		if (this.consideration.value === Consideration.Product) {
			this.symbolConsiderationValue = ConsiderationSymbol.Product;
			this.inputFormatConsideration = true;
		}
		else {
			this.symbolConsiderationValue = ConsiderationSymbol.Discount;
			this.inputFormatConsideration = false;
		}
	}

	onRowEditInit(rangeRappel: RangeRappel) {
		this.clonedRangeRappel[rangeRappel.rangeRappelId] = { ...rangeRappel };
		this.validateCheckShow();
		this.validateDisabledCheck();

	}

	changeValue(rangeRappel : RangeRappel){
		if(rangeRappel.contributionValue > 100){
			rangeRappel.contributionValue = 100;
			const language: string = sessionStorage.getItem('language');
			this.showToast(SweetAlert.iconError,
				language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
				language === 'es-ES' ? SweetAlert.messageAlertContributionValue : SweetAlert.messageAlertContributionValueTranslate);
		}
	}

	validateDisabledCheck(){
		this.objectRangeRappel.forEach((elm) => {
			elm.disabledInput = false;
			if(elm.notApply === true){
				elm.disabledInput = true;
			}else if (elm.notApply === false) {
				elm.disabledInput = false;
			}
		});
	}

	onRowEditSave(rangeRappel: RangeRappel) {
		this.calculateRanges(rangeRappel);
	}

	onRowEditCancel(rangeRappel: RangeRappel, index: number) {
		this.objectRangeRappel[index] = this.clonedRangeRappel[rangeRappel.rangeRappelId];
		delete this.clonedRangeRappel[rangeRappel.rangeRappelId];
	}

	deleteRowRange(rowRangeRappel: RangeRappel): void {
		Util.confirmDelete().then((result: SweetAlertResult) => {
			if (result.value) {
				this.spinner.show();
				this.objectRangeRappel = this.objectRangeRappel.filter((x)=> x.rangeRappelId !== rowRangeRappel.rangeRappelId);

				if (this.objectRangeRappel.length === 0) {
					this.measuredType.enable();
					this.consideration.enable();
				}
			}
			this.validateCheckShow();
			this.spinner.hide();
		});
	}

	addRange(): void {
		let indicatorError = 0;
		if (this.measuredType.value) {
			if (this.consideration.value) {
				indicatorError = this.addRangeII(indicatorError);
			}
			else {
				const language: string = sessionStorage.getItem('language');
				SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
					language === 'es-ES' ? 'Debe configurar contraprestación.'
					: 'You must set up consideration.');
				return;
			}
		} else {
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'Debe configurar tipo de medida.'
				: 'You must configure measurement type.');
			return;
		}
	}

	newRecordrange() {
		let maxMemoryId = Math.max(...this.objectRangeRappel.map((x) => x.rangeRappelId === undefined ? 0 : x.rangeRappelId), 0);
		maxMemoryId++;
		this.objectRangeRappel.push({ minimumRangeOperator: '>=', minimumRange: null, maxRangeOperator: '<', maxRange: null, notApply: false, contributionValue: 0, rangeRappelId: maxMemoryId });
		this.validateCheckShow();
	}

	validateCheckShow(){
		this.objectRangeRappel.forEach((elm) => {
			const lasItem = this.objectRangeRappel.length;
			const item = this.objectRangeRappel.indexOf(elm) + 1;
			if(lasItem === item){
				elm.showApply = true;
				if(elm.eventCheck === true ){
					elm.disabledInput = true;
				}
				elm.disabledInput = false;
			}else {
				elm.showApply = false;
				elm.disabledInput = false;
			}
		});
	}

	validateData(): boolean {
		let result = true;
		let messages = '';
		this.objectRangeRappel.forEach((x) => {
			x.inputMax = false;
			({ result, messages } = this.validateDataI(result, x, messages));
			const language: string = sessionStorage.getItem('language');
			if (result && x.minimumRangeOperator && !x.minimumRange) {
				messages = language === 'es-ES' ? ApprovalRankMessage.NOT_VALUE_START : ApprovalRankMessage.NOT_VALUE_START_TRANSLATE;
				result = false;
			}
			if (result && language === 'es-ES' && x.maxRangeOperator && !x.maxRange) {
				messages = language === 'es-ES' ? ApprovalRankMessage.NOT_VALUE_END : ApprovalRankMessage.NOT_VALUE_END_TRANSLATE;
				result = false;
			}
			if (result && language === 'es-ES' && x.maxRange && x.maxRange <= x.minimumRange) {
				messages = language === 'es-ES' ? ApprovalRankMessage.NOT_ACCEPTED_VALUE : ApprovalRankMessage.NOT_ACCEPTED_VALUE_TRANSLATE;
				result = false;
				x.inputMax = true;
			}
		});
		if (!result)
			this.showToast(SweetAlert.iconError, SweetAlert.titleAlertError, messages);
		return result;
	}


	calculateRanges(rangeRappel: RangeRappel): void {
		let numbersRange: number[];
		let numbersRangeNewRecord: number[];
		let searchRank: number[] = [];
		let checkRank = true;
		let minorNumber = 0;
		let result  = false;

		if (this.objectRangeRappel && this.validateData()) {
			this.objectRangeRappel.filter((y) => y.rangeRappelId !== rangeRappel.rangeRappelId)
				.forEach((x) => {
					minorNumber = Math.min(...this.objectRangeRappel.filter((y) => y.rangeRappelId !== rangeRappel.rangeRappelId).map((r) => r.minimumRange));

					if (x.maxRange === null || x.maxRange === RankNumber.Zero) {
						checkRank = this.calculateRangesI(x, rangeRappel, checkRank);
					}
					else {
						let numberStar: number = x.minimumRange;
						let numberEnd: number = x.maxRange;
						let numberStarMemory: number = rangeRappel.minimumRange;
						let numberEndMemory: number = rangeRappel.maxRange;

						({ numberStar, numberEnd, numberStarMemory, numberEndMemory } = this.calculateRangesII(x, numberStar, numberEnd, rangeRappel, numberStarMemory, numberEndMemory));

						// numbersRange = this.generateRange(numberStar, numberEnd);
						if(numberStarMemory !== null && numberEndMemory !== RankNumber.Zero && numberEndMemory !== null)
						{
							result = this.validateRangeMinMax(rangeRappel, x);
						}

						if(!result){

						}else{
							searchRank.push(1);
						}

						// if (rangeRappel.maxRange === null || rangeRappel.maxRange === RankNumber.Zero) {
						// 	searchRank = numbersRange.filter(x => x === rangeRappel.minimumRange);
						// } else {
						// 	// this.ValidateRangeMinMax(,numberStarMemory,numberEndMemory)
						// 	// numbersRangeNewRecord = this.generateRange(numberStarMemory, numberEndMemory);
						// 	// searchRank = numbersRange.filter(x => numbersRangeNewRecord.includes(x));

						// }
					}
					if (searchRank.length > RankNumber.Zero) {
						checkRank = false;
					}
					else {
						minorNumber--;
						this.calculateRangesIII(minorNumber, rangeRappel);
					}
				});
			if (!checkRank) {
				this.objectRangeRappel = this.objectRangeRappel.filter((x) => x.rangeRappelId !== rangeRappel.rangeRappelId);
				const language: string = sessionStorage.getItem('language');
				this.showToast(SweetAlert.iconError,
					language === 'es-ES' ? SweetAlert.titleAlertError: SweetAlert.titleAlertErrorTranslate,
					language === 'es-ES' ? SweetAlert.messageAlertError: SweetAlert.messageAlertErrorTranslate);
			}
			else {
				delete this.clonedRangeRappel[rangeRappel.rangeRappelId];
				const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
			}
		}
	}

	generateRange(start: number, end: number): number[] {
		const sequenceNumbers = [];
		for (let i = start; i < end; i++) {
			sequenceNumbers.push(i);
		}
		return sequenceNumbers;
	}

	eventSendToCalculate($event: string) {
		this.updateCalculatedData($event);
		this.spinner.show();
		this.rappelActivityEdit.isCalculate = true;
		this.rappelActivityEdit.statusDomainId = CommercialActivityStatus.Pending;
		this.rappelActivityEdit.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate(this.rappelActivityEdit);
		const updateModel : CommercialActivityStatusModel = new CommercialActivityStatusModel(this.commercialActivityId,CommercialActivityStatus.Pending,'');
		this.discountService.putPromotionalActivity(
			this.rappelActivityEdit,
			this.commercialActivityId
		).subscribe(
			(response) => {
				const language: string = sessionStorage.getItem('language');
					SweetAlertMessageHelpers.success(ManagePromotionalActivitiesMessage.OK,
						language === 'es-ES' ? ManagePromotionalActivitiesMessage.CALCULATE_PROCESS_OK : ManagePromotionalActivitiesMessage.CALCULATE_PROCESS_OK_TRANSLATE);
				this.managePromotionActService.postCommercialActivityUpdateStatus(updateModel).toPromise();
				this.router.navigate(['/auth/manage-promotional-activities']);
				this.spinner.hide();
			}, (error: HttpErrorResponse) => {
				this.spinner.hide();
				SweetAlertMessageHelpers.exception(error);
			});
	}

	/**
 * @description This method is to prepare edit data
 * @sprint 14
 */
		eventClickBackClients(): void {
		this.promotionEdit = { ...this.promotionEditFooter };
		this.editFiltersObjectList = [...this.editFiltersObjectList];
		this.flagDocumentObservation = false;
		this.observationEdit = this.documentInfo;
		this.nextAssignClients = false;
		this.flagHanlder = true;
		this.getRappelToEdit();
		this.prepareDataHandler();
	}

	public prepareFooterForEvent($event: ICommercialActivityFooter) {
		this.promotionEditFooter = new CommercialActivityFooterModel(
			$event.numberDays,
			$event.startTime,
			$event.endTime,
			$event.orderLimit,
			$event.periodLimit,
			$event.statusDomainId,
			$event.dataRanges
		);
	}

	public showToast(severity: string, message: string, detail: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: severity,
			summary: message,
			detail: detail,
			life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
		});
	}
	
	/**
	* @description This method is to catch the event to send promotional activity to validate.
	*
	* @param $event
	* @author Diego Mauricio Cortés
	* @sprint 9
	*/
	eventClickSendValidateBtn(): void {
		if(!this.validateForm()){
			return;
		}
		this.spinner.show();
		const sendQueue = new CommercialACtivityQueueValidationModel(
			this.commercialActivityId,
			CommercialActivityStatus.InValidation,
			null,
			Module.PromotionalActivity,
			this.commercialActivityId,
			this.typeCommercialActivityN,
			moment(new Date()).format('YYYY-MM-DD')
		);
		this.managePromotionActService.postCommercialActivityQueueValidated(sendQueue).subscribe(
			(response) => {
				if (response.status) {
					this.router.navigate(['auth/manage-promotional-activities']);
				}else{
					const listError = [];
					listError.push(response.message);
					SweetAlertMessageHelpers.listWarningMessageShow(SweetAlert.titleAlertError, listError);
					}
				this.spinner.hide();
			}, (error) => {
				console.error('ERROR', error);
				this.spinner.hide();
			}
		);
	}
	
	onChangeDisabled(event : any){
		if(event.checked === true){
			this.objectRangeRappel.forEach((elm) => {
				elm.eventCheck = event.checked;
				if(elm.showApply === true){
					elm.maxRangeOperator = '<';
					elm.maxRange = 9999999999999,99;
					elm.disabledInput = event.checked;
				}
			});
		}else if (event.checked === false){
			this.objectRangeRappel.forEach((elm) => {
				elm.disabledInput = event.checked;
				elm.eventCheck = event.checked;
			});
		}
	}

	private prepareCommercialActivityRappelToEdit(footerData: ICommercialActivityFooter): CommercialActivityRappelModel {
		const promotionalActivityModel: CommercialActivityRappelModel = new CommercialActivityRappelModel(
			this.typeCommercialActivityId,
			null,
			footerData.statusDomainId,
			this.description.value,
			footerData.startTime,
			footerData.endTime,
			footerData.orderLimit,
			footerData.periodLimit,
			footerData.numberDays,
			this.rappelActivityEdit.id,
			this.consideration.value,
			this.settlementPeriod.value,
			this.measuredType.value,
			this.transformDataCommercialProductRappel(this.valueTable),
			[],
			footerData.dataRanges,
			this.prepareChannelsToSave(),
			this.prepareRangeRappelToSendEdit(),
			this.rappelActivityEdit.isCalculate,
			this.prepareCommercialActivitiesSectorsToCreate(this.rappelActivityEdit),
			this.rappelActivityEdit.totalClientsFilter,
			null,
			this.rappelActivityEdit.filterClientJson,
			this.rappelActivityEdit.commercialActivityCustomers,
			null,
			this.showManejantes,
			this.commercialActivityHandler2,
			this.commercialActivityHandlerCurrentMonth2,
			this.commercialActivityHandlerPeriod2
		);
		return promotionalActivityModel;
	}

	eventswitchValue(value : boolean){
		this.showManejantes = value;
	}

	async validateHandler() : Promise<boolean>{
		const language: string = sessionStorage.getItem('language');
		if(this.commercialActivityHandlerDetailsAux2.length > 0 &&  (this.commercialActivityHandlerPeriod2 && this.commercialActivityHandlerPeriod2.length) > 0){
			
			let findRangeEmpty = this.commercialActivityHandlerDetailsAux2.find((item : any) => item.rankStart === '' || item.rankStart === undefined);

			if(findRangeEmpty != undefined){
				const language: string = sessionStorage.getItem('language');
					this.showToast(SweetAlert.iconError,
					language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
					language === 'es-ES' ? SweetAlert.messageValidateRank : SweetAlert.messageValidateRankTranslate);
				return false;
			}

			if(this.isCurrentMonth && this.commercialActivityHandlerCurrentMonthDetails2.length == 0){
				const language: string = sessionStorage.getItem('language');
					this.showToast(SweetAlert.iconError,
					language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
					language === 'es-ES' ? SweetAlert.messageValidateConfigurationHandlerCurrenMonth : SweetAlert.messageValidateConfigurationHandlerCurrenMonthTranslate);
				return false;
			}

			const activityHandlerModel : ActivityHandlerModel = new ActivityHandlerModel (
				this.commercialActivityHandler2,
				this.isCurrentMonth ? this.commercialActivityHandlerCurrentMonth2 : null,
				this.commercialActivityHandlerPeriod2
			)
			try {
				this.spinner.show();
				const response = await this.promotionalActivityHandlerService.postActivityHandler(activityHandlerModel).toPromise();
					if(response){
						if (response.status) {
							this.spinner.hide();
							return true;
						} else {
							// Mensaje de error de no hay clientes
							this.spinner.hide();
							SweetAlertMessageHelpers.error(
								language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
								language === 'es-ES' ? SweetAlert.messageEmptyConfiguration : SweetAlert.messageEmptyConfigurationTranslate
							);
							return false;
						}
					}
			} catch (error) {
				this.spinner.hide();
				SweetAlertMessageHelpers.exception(error);
			}
			
		}else{
			// Mensaje de Error de validacion 
			SweetAlertMessageHelpers.error(
				language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
				language === 'es-ES' ? SweetAlert.messageValidateConfiguration : SweetAlert.messageValidateConfigurationTranslate
			);
			return false;
		}
	}

	private validateDataI(result: boolean, x: RangeRappel, messages: string) {
		const language: string = sessionStorage.getItem('language');
		if (result && !x.contributionValue) {
			messages = language === 'es-ES' ? ApprovalRankMessage.NOT_CONTRIBUTION : ApprovalRankMessage.NOT_CONTRIBUTION_TRANSLATE;
			result = false;
		}
		if (result && x.minimumRange && !x.minimumRangeOperator) {
			messages = language === 'es-ES' ? ApprovalRankMessage.NOT_OPERATOR_START : ApprovalRankMessage.NOT_OPERATOR_START_TRANSLATE;
			result = false;
		}
		if (result && x.maxRange && !x.maxRangeOperator) {
			messages = language === 'es-ES' ? ApprovalRankMessage.NOT_OPERATOR_END : ApprovalRankMessage.NOT_OPERATOR_END_TRANSLATE;
			result = false;
		}
		return { result, messages };
	}

	private calculateRangesIII(minorNumber: number, rangeRappel: RangeRappel) {
		if (minorNumber !== RankNumber.Zero) {
			// rangeRappel.maxRange = minorNumber;
			// rangeRappel.maxRangeOperator = Operator.LessThanEqual;
		}
		else {
			if (rangeRappel.minimumRange === RankNumber.One && minorNumber === RankNumber.Zero) {
				rangeRappel.minimumRangeOperator = Operator.Equal;
			}
		}
	}

	private calculateRangesII(x: RangeRappel, numberStar: number, numberEnd: number, rangeRappel: RangeRappel, numberStarMemory: number, numberEndMemory: number) {
		if (x.minimumRangeOperator === Operator.GreaterThan) {
			numberStar++;
		}
		if (x.maxRangeOperator === Operator.LessThanEqual) {
			numberEnd++;
		}
		if (rangeRappel.minimumRangeOperator === Operator.GreaterThan) {
			numberStarMemory++;
		}
		if (rangeRappel.maxRangeOperator === Operator.LessThanEqual) {
			numberEndMemory++;
		}
		return { numberStar, numberEnd, numberStarMemory, numberEndMemory };
	}

	private calculateRangesI(x: RangeRappel, rangeRappel: RangeRappel, checkRank: boolean) {
		if (x.minimumRangeOperator === Operator.GreaterThan) {
			if (rangeRappel.minimumRange > x.minimumRange || rangeRappel.maxRange > x.minimumRange) {
				checkRank = false;
			}
		}
		if (x.minimumRangeOperator === Operator.GreaterThanEqual) {
			if (rangeRappel.minimumRange >= x.minimumRange || rangeRappel.maxRange >= x.minimumRange) {
				checkRank = false;
			}
		}
		if (x.minimumRangeOperator === Operator.LessThan) {
			if (rangeRappel.minimumRange < x.minimumRange || rangeRappel.maxRange < x.minimumRange) {
				checkRank = false;
			}
		}
		if (x.minimumRangeOperator === Operator.LessThanEqual) {
			if (rangeRappel.minimumRange <= x.minimumRange || rangeRappel.maxRange <= x.minimumRange) {
				checkRank = false;
			}
		}
		if (x.minimumRangeOperator === Operator.Equal) {
			if (rangeRappel.minimumRange === x.minimumRange || rangeRappel.maxRange === x.minimumRange) {
				checkRank = false;
			}
		}
		return checkRank;
	}

	private addRangeII(indicatorError: number) {
		if (this.validateData()) {
			this.objectRangeRappel.forEach((x) => {
				indicatorError = this.addRangeI(x, indicatorError);
			});

			if (indicatorError === RankNumber.Zero) {
				this.measuredType.disable();
				this.consideration.disable();
				this.newRecordrange();
			}
			else {
				const language: string = sessionStorage.getItem('language');
				this.showToast(SweetAlert.iconWarningToast,
					language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
					language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate);
			}
		}
		return indicatorError;
	}

	private addRangeI(x: RangeRappel, indicatorError: number) {
		if (!x.contributionValue || x.contributionValue === RankNumber.Zero) {
			indicatorError++;
		}
		if (x.minimumRangeOperator === null || x.minimumRangeOperator === '') {
			indicatorError++;
		}
		if (x.minimumRange === null || x.minimumRange === RankNumber.Zero) {
			indicatorError++;
		}
		if ((x.maxRangeOperator !== null) && (x.maxRange === null)) {
			indicatorError++;
		}
		if ((x.maxRangeOperator !== null) && (x.maxRange !== null)) {
			if (x.minimumRange > x.maxRange) {
				indicatorError++;
			}
		}
		return indicatorError;
	}

	private validateRangeMinMax (rangeRappel : RangeRappel, objectRangeRappel : RangeRappel){
		let auxMaxrange = 0;
		let auxMinrange = 0;
		auxMaxrange = (objectRangeRappel.maxRangeOperator === Operator.LessThan) ? (objectRangeRappel.maxRange -1) : objectRangeRappel.maxRange;
		auxMinrange = (rangeRappel.minimumRangeOperator === Operator.GreaterThan) ? (rangeRappel.minimumRange +1) : rangeRappel.minimumRange;
		if(objectRangeRappel.minimumRange >=auxMinrange || auxMaxrange >= auxMinrange ||
			objectRangeRappel.minimumRange >= rangeRappel.maxRange || auxMaxrange >= rangeRappel.maxRange){
			return true;
		}

		return false;
	}

	/**
	 * @description: This method is to update calculated data in skus
	 *
	 * @param responseAnal: Aanalitics calculate process response
	 * @sprint 20
	 */
	private updateCalculatedData(responseAnal: any) {
		this.rappelActivityEdit.purchaseBases.forEach((prch: any) => {
			prch.purchaseBaseDetails.forEach((mat: any) => {
				const elm = responseAnal.purchaseBaseCalculate.filter((elem: any) => elem.materialCode === mat.materialCode);
				if (elm.length > 0) {
					mat.perDescount = elm[0].perDescount;
					mat.ebitda = elm[0].ebitda;
					mat.ebitdaPercentage = elm[0].ebitdaPercentage;
					mat.costIncome = elm[0].costIncome;
					mat.promotionValue = elm[0].promotionValue;
					mat.weightedValue = elm[0].weightedValue;
					mat.kpiLiberationSku = elm[0].kpiLiberationSku;
				}
			});
		});
	}

	private preparePurchaseBaseProductsToEditRappelActivity(rappel: CommercialActivityRappelModel): void {
		if (rappel.purchaseBases) {
			this.productGroupFilterListPromotion = rappel.purchaseBases.map((group: any, index: number) => {
				return {
					groupName: `Grupo ${(index + 1)}`,
					cgroupName: `Grupo ${(index + 1)}`,
					commercialActivityId: group.commercialActivityId,
					purchaseUnit: group.purchaseUnit,
					measurementUnitCode: group.purchaseBaseDetails ? group.purchaseBaseDetails[0].measurementUnitCode : '',
					isGroup: true,
					groupByJson: group.groupByJson,
					id: group.id,
					products: this.prepareProductsToEditPromotionalActivity(group)
				};
			});

			const valueConfigurationTable = this.schematicResultsToTable(this.productGroupFilterListPromotion);
			this.valueTable = valueConfigurationTable.valueTableConvert;
			this.subHeaderTable = valueConfigurationTable.valueSubHeaderTable;
			this.headersTable = valueConfigurationTable.valueHeadersTable;

		}
	}

	private transformDataCommercialProductRappel(products: Array<ITableGroupItem>) {

		let newProduct: IProductGroupList[] = [];
		newProduct = products.map((product) => {
			return {
				'commercialActivityId': 0,
				'purchaseUnit': product.purcheseUnit,
				'isGroup': true,
				'groupByJson': product.groupByJson,
				'id': parseInt(product.id, 10),
				'purchaseBaseDetails': this.transformDataPurchaseBase(product.subOptions, product)
			};
		});

		return newProduct;
	}

	private transformDataPurchaseBase(subOptions: Array<ISubGroupItem>, product: ITableGroupItem): Array<IProductList> {
		let newSubOptions: IProductList[] = [];

		newSubOptions = subOptions.map((subOption) => {
			const extraOptionValue = this.getExtraOptionsValue(subOption);
			return {
				'purchaseBaseId': parseInt(product.id, 10),
				'materialCode': subOption.sku,
				'measurementUnitCode': MeasurementUnit.PIECE,
				'purchaseUnit': extraOptionValue.purcheseUnit,
				'porcent': 0,
				'discountValue': extraOptionValue.discountValue,
				'id': parseInt(subOption.id, 10)
			};
		});

		return newSubOptions;
	}

	private getExtraOptionsValue(subOption: ISubGroupItem): { purcheseUnit: number, discountValue: number } {
		const extraOptionValue = {
			purcheseUnit: 0,
			discountValue: 0
		};

		if (subOption instanceof SubLoyalty) {
			extraOptionValue.purcheseUnit = subOption.purcheseUnit != null ? subOption.purcheseUnit : 0;
		}
		return extraOptionValue;
	}

	private prepareChannelsToSave(): IChannel[] {
		if (this.channelRappel.value) {
			const channels = this.channelRappel.value.map((chl: number) => {
				return {
					commercialActivityId: 0,
					channelCode: chl
				};
			});
			return channels;
		}
	}

	private prepareSectorsToSave(): ICommercialActivitySector[] {
		if (this.sector.value) {
			const sectors: ICommercialActivitySector[] = this.sector.value.map((sct: string) => {
				return {
					id: 0,
					commercialActivityId: this.commercialActivityId || 0,
					sectorCode: sct
				};
			});
			return sectors;
		}
	}

		/**
	 * @description This method is to prepare customers data to create promotional activity
	 *
	 * @param listClient
	 * @author Diego Mauricio Cortés
	 * @sprint 11
	 */
		private prepareCommercialActivityCustomerCreate(listClient: any[]): ICustomer[] {
		let customers: ICustomer[] = [];

		if (listClient !== null) {
			customers = listClient.map((client) => {

				return {
					commercialActivityId: client.commercialActivityId === undefined ? 0 : client.commercialActivityId,
					clientCode: client.clientCode === undefined ? client : client.clientCode,
					id: 0,
				};
			});
		}
		return customers;
	}
	
	private eventClickSaveBtnI(isNotValid: boolean, toCreate: CommercialActivityRappelModel) {
		if (!isNotValid) {
			this.spinner.show();
			this.rappelService.postPromotionalActivity(toCreate).subscribe(
				(response) => {
					if (response.status) {
						SweetAlertMessageHelpers.showMessage(this.isProcess === this.commercialActivityEnum.update ? TypeMessage.Update : TypeMessage.Success);
						this.router.navigate(['auth/manage-promotional-activities']);
					} else {
						SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
					}
					this.spinner.hide();
				}, (error: HttpErrorResponse) => {
					this.spinner.hide();
					SweetAlertMessageHelpers.exception(error);
				}
			);
		}
	}
	
	/**
	 * @description This method is to prepare commercial activity sectors to create
	 * @sprint 15
	 * @author Diego Mauricio Cortes
	 * @returns ICommercialActivitySector object list
	 */
	private prepareCommercialActivitiesSectorsToCreate(toCreate: CommercialActivityRappelModel): ICommercialActivitySector[] {
		let rsp: ICommercialActivitySector[] = [];
		if (this.sectorCommercialActivityParam && this.sector.value) {
			rsp = this.sector.value.map((sct: string) => {
				return {
					id: 0,
					commercialActivityId: this.commercialActivityId || 0,
					sectorCode: sct
				};
			});
		}
		return rsp;
	}

	private prepareProductsToEditPromotionalActivity(group: any): IProductList[] {
		let products: IProductList[] = [];
		if (group.purchaseBaseDetails) {
			products = group.purchaseBaseDetails.map((product: any) => {
				const productRet = { ...product };
				productRet.product = product.materialDescription;
				productRet.hierarchy = JSON.parse(group.groupByJson).hierarchyFilter;
				return productRet;
			});
		}
		return products;
	}

	private prepareFiltersToEditProductsRappelActivity(rappel: CommercialActivityRappelModel): void {
		if (rappel.purchaseBases) {
			const filters: IHierarchyFiltersMaterials[] = rappel.purchaseBases.map((element) => {
				return JSON.parse(element.groupByJson);
			});
			this.editFiltersObjectList = [...filters];
		}
	}

	private prepareChannelsToShowEditPromotionalActivity(channels: IChannel[]): string[] {
		if (channels) {
			return channels.map((ch) => {
				return `${ch.channelCode}`;
			});
		}
		return [];
	}

	private prepareDataRangeCommercialActivityToEdit(dataRange: ICommercialActivityRappel[]): IDataRange[] {
		let dataRangeRet: IDataRange[] = [];
		if (dataRange) {
			if(dataRange.length > 0){
				dataRangeRet = dataRange.map((data: any) => {
					const start = new Date(data.dateStart);
					const end = new Date(data.dateEnd);
					return {
						dateStart: data.dateStart,
						dateEnd: data.dateEnd,
						cantDays: Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)),
						id: data.id,
						commercialActivityId: data.commercialActivityId
					};
				});
				this.initialDate = dataRangeRet[0].dateStart;
			}
		}
		return dataRangeRet;
	}

	/**
 * @description This method is to initialize the create a new rappel
 * @author Daniel Londono
 * @sprint 14
 */
	private async initCreateRappel() {
		const descriptionIn = this.managePromotionActService.getDescription();
		if (descriptionIn !== undefined && descriptionIn !== null && descriptionIn !== '') {
			this.description.setValue(descriptionIn);
			this.typeCommercialActivityId = this.managePromotionActService.getTypeCommercialActivity();
			this.typeCommercialActivity.setValue(this.findTypeCommercialActivityName(this.typeCommercialActivityId));

			if (this.typeCommercialActivityId)
			{
				await this.documentSupport.setRequiredDocumentInformation(this.typeCommercialActivityId);
				this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
			}

		} else {
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? 'La descripción es requerida. Porfavor intentelo de nuevo.'
				: 'Description is required. Please try again.');
			this.router.navigate(['auth/manage-promotional-activities']);
		}
	}

		/**
	 * @description This method is to find the name of commercial activity selected
	 * @param id
	 * @author Daniel Londono
	 * @sprint 14
	 */
		private findTypeCommercialActivityName(id: number): string {
			const typeCommercialActivity = this.objectTypeCommercialActivity.find((type) => type.id === id);
			return typeCommercialActivity.description;
		}

	/**
	* @description This method is to init the edit process
	* @author Daniel Londono
	* @sprint 14
	*/
	private initEditRappel() {
		this.commercialActivityId = this.managePromotionActService.getCommercialActivityId();
		this.getRappelToEdit();
	}

	/**
	* @description This method is to get the Rappel data for edit
	*
	* @author Daniel Londono
	* @sprint 15
	*/
	private async getRappelToEdit(): Promise<void> {
	this.spinner.show();
	this.rappelService.getPromotionalActivityById(this.commercialActivityId).subscribe(
		async (response: any) => {
			if (response.status) {
				this.commercialActStatVal = response.data.statusDomainId;
				let commercialActivityChannelsLocal: IChannel[] = response.data.commercialActivityChannels;
				let CommercialActivitySectorLocal: ICommercialActivitySector[] = response.data.commercialActivitySector;

				if(this.rappelActivityEdit && this.rappelActivityEdit.commercialActivityChannels && this.rappelActivityEdit.commercialActivitySector){
					commercialActivityChannelsLocal = this.rappelActivityEdit.commercialActivityChannels;
					CommercialActivitySectorLocal = this.rappelActivityEdit.commercialActivitySector;
				}

				this.rappelActivityEdit = new CommercialActivityRappelModel(
					response.data.typeCommercialActivityDomainId,
					response.data.typePromotionalActivityDomainId,
					response.data.statusDomainId,
					response.data.description,
					response.data.startTime,
					response.data.endTime,
					response.data.orderLimit,
					response.data.periodLimit,
					response.data.numberDays,
					response.data.id,
					response.data.consideration,
					response.data.periodSettlement,
					response.data.typeMeasure,
					response.data.purchaseBases,
					[],
					this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges),
					commercialActivityChannelsLocal,
					response.data.commercialActivityRange,
					response.data.isCalculate,
					CommercialActivitySectorLocal,
					response.data.totalClientsFilter,
					response.data.uidCalculateProcess,
					response.data.filterClientJson,
					response.data.commercialActivityCustomers,
					response.data.commercialActivityCustomerJson,
					response.data.isHandler,
					response.data.commercialActivityHandler,
					response.data.commercialActivityHandlerCurrentMonth,
					response.data.commercialActivityHandlerPeriod,
					response.data.commercialActivityObservation
				);

				this.userCreation = new UserCreationModel(
					response.data.nameUser,
					response.data.user,
					response.data.emailUser,
					response.data.creationDate
				 );

				this.channelTooltip = response.data.commercialActivityChannels;

				this.typeCommercialActivityId = response.data.typeCommercialActivityDomainId;
				this.typeCommercialActivityN = this.findTypeCommercialActivityName(this.typeCommercialActivityId);
				this.promotionEdit = new CommercialActivityFooterModel(
					this.rappelActivityEdit.numberDays,
					this.rappelActivityEdit.startTime,
					this.rappelActivityEdit.endTime,
					this.rappelActivityEdit.orderLimit,
					this.rappelActivityEdit.periodLimit,
					this.rappelActivityEdit.statusDomainId,
					this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges)
				);

				if(this.rappelActivityEdit.isHandler && !this.flagHanlder){
						
					this.showManejantes = this.rappelActivityEdit.isHandler;
					this.commercialActivityHandler = this.rappelActivityEdit.commercialActivityHandler;
					this.commercialActivityHandlerisGroup = this.commercialActivityHandler.isGroup;
					this.commercialActivityHandlerisClientWithoutSales = this.commercialActivityHandler.isClientWithoutSales;
					this.commercialActivityHandlerDetails = this.commercialActivityHandler.commercialActivityHandlerDetails;
					this.editFiltersObjectHandlerList = JSON.parse(this.commercialActivityHandler.groupByJson);
					this.commercialActivityHandlerPeriod = this.rappelActivityEdit.commercialActivityHandlerPeriod;

					// CurrentMonthDetails
					this.isCurrentMonth = this.rappelActivityEdit.commercialActivityHandler.isCurrentMonth;
					if(this.isCurrentMonth){
						this.commercialActivityHandlerCurrentMonth = this.rappelActivityEdit.commercialActivityHandlerCurrentMonth;
						this.editFiltersObjectHandlerCurrentMonthList = JSON.parse(this.rappelActivityEdit.commercialActivityHandlerCurrentMonth.groupByJson);
						this.commercialActivityHandlerCurrentMonthDetails = this.rappelActivityEdit. commercialActivityHandlerCurrentMonth.commercialActivityHandlerCurrentMonthDetails;
						this.switchService.sendData(this.isCurrentMonth);
					}
				}

				this.filterClientJsonToCalculate = response.data.commercialActivityCustomers !== null 
					? JSON.stringify(response.data.commercialActivityCustomerJson) : response.data.filterClientJson;
				this.filterClientJson = response.data.filterClientJson;
				this.preparePurchaseToCalculate();
				if (CommercialActivitySectorLocal) {
					this.sectorTooltip = response.data.commercialActivitySector;
					const sct = CommercialActivitySectorLocal.map((sctr: ICommercialActivitySector) => {
						return sctr.sectorCode;
					});
					this.sector.setValue(sct);
				}
				this.prepareDataEditActivityRappel(this.rappelActivityEdit);
				this.verifyInvalidateData(response.data.statusDomainId, response.data.validationResult);
				this.commercialActivityCustomers = response.data.commercialActivityCustomers;


				if (response.data.commercialActivityObservation && this.flagDocumentObservation)
				{
					this.observationEdit = response.data.commercialActivityObservation;
				}

				let typeCommercialActivityId = this.rappelActivityEdit.typeCommercialActivityDomainId;
				if (typeCommercialActivityId)
				{
					await this.documentSupport.setRequiredDocumentInformation(typeCommercialActivityId);
					this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
				}

				if(this.isProcess == this.comercialActivityEnum.view 
					&& (!this.filterClientJson || this.filterClientJson == '') 
					&& (!this.rappelActivityEdit.commercialActivityCustomerJson || this.rappelActivityEdit.commercialActivityCustomerJson[0].content == null))
					{
						const language: string = sessionStorage.getItem('language');
								SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
									language === 'es-ES' ? 'Por favor asignar clientes.'
									: 'Please assign clients.');
						this.showFieldsCreatePromotion.commercialActivitySendValidate = false;
				}
				this.isCalculate = this.rappelActivityEdit.isCalculate;				
				this.spinner.hide();
			}
		}, (error: any) => {
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, `${error}`);
		});
	}

	private countSumZeroPromotion(dataNoZero: number, dataZero: number) {
		this.productGroupFilterListPromotion.forEach((elm) => {
			let sum = 0;
			elm.products.forEach((itm) => {
				if (itm.purchaseUnit === 0) {
					sum += 1;
				}
			});
			if (sum === 0) {
				dataNoZero = 2;
			}
			if (sum > 0 && sum !== elm.products.length) {
				dataZero = 1;
			}
		});
		return { dataNoZero, dataZero };
	}

	private countSumZero(dataNoZero: number) {
		this.productGroupFilterList.forEach((elm) => {
			let sum = 0;

			if (sum === 0) {
				dataNoZero = 2;
			}

		});
		return dataNoZero;
	}

	private parameterizedProducts() {
		const language: string = sessionStorage.getItem('language');
		SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
			language === 'es-ES' ? 'Ya se han parametrizado productos, verifique y eliminelos si desea aplicar otro filtro.'
				: 'Products have already been parameterized, check and delete them if you want to apply another filter.');
	}

	private productsUnParameterized() {
		const language: string = sessionStorage.getItem('language');
		SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
			language === 'es-ES' ? 'Existen productos seleccionados sin parametrizar unidades compra, verifique y eliminelos si desea aplicar otro filtro.'
				: 'There are selected products without parameterizing purchase units, check and delete them if you want to apply another filter.');
	}

	private parameterizedProductsPurchaseBase() {
		const language: string = sessionStorage.getItem('language');
		SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
			language === 'es-ES' ? 'Ya se han parametrizado productos en base copra, verifique y eliminelos si desea aplicar otro filtro.'
				: 'Copra-based products have already been parameterized, check and delete them if you want to apply another filter.');
	}

	private unparameterizedProductsPurchaseBase() {
		const language: string = sessionStorage.getItem('language');
		SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
			language === 'es-ES' ? 'Existen productos seleccionados en base compra sin parametrizar unidades compra, verifique y eliminelos si desea aplicar otro filtro.'
				: 'There are products selected on a purchase basis without parameterizing purchase units, check and delete them if you want to apply another filter.');
	}


	/**
* @description This method for get the filters.
*
* @author Diego Mauricio Cortés
* @sprint 8
* @param filters
*/
	private getMaterialsByFilters(filters: FilterDataModel): void {
		this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
			async (response) => {
				if (response.data.length > 0) {
					const language: string = sessionStorage.getItem('language');
					this.showToast(SweetAlert.iconSuccess,
						language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
						language === 'es-ES' 
							? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`: `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`);
					const listProducts: IProductList[] = response.data.map((item: any) => {
						return {
							product: item.product,
							hierarchy: item.hierarchy,
							purchaseBaseId: 0,
							materialCode: item.materialCode,
							measurementUnitCode: 'ST',
							purchaseUnit: 0,
							porcent: 0,
							discountValue: 0,
							id: 0,
							currentPsp: item.currentPsp,
              zofeCode: item.zofeCode,
              zofeDescription : item.zofeDescription
						};
					});


          const temporalProducts = listProducts;
          const finalListProducts: IProductList[] = [];

          this.advancedFiltersObjectList.forEach((x) => {
            const product = temporalProducts.filter((y) => y.hierarchy === x.hierarchyFilter);

            if(product !== undefined){
              product.forEach((z) =>  { finalListProducts.push(z); } );
            }

          });

          const newGroups = this.groupProductsToShow(finalListProducts);
					let newProductGroupList: IProductGroupList[] = [];

					this.productGroupFilterListPromotion.forEach(async (x) => {
						newGroups.filter(async (g) => {
							if (x.groupByJson === g.groupByJson) {
								x.products = [...await this.managePromotionActService.getUnionProducts(x.products, g.products)];
							}
						});
					});

					newProductGroupList = await this.managePromotionActService.getUnionProductGroupList(this.productGroupFilterListPromotion, newGroups);

					const valueConfigurationTable = this.schematicResultsToTable(newProductGroupList);
					this.valueTable = valueConfigurationTable.valueTableConvert;
					this.subHeaderTable = valueConfigurationTable.valueSubHeaderTable;
					this.headersTable = valueConfigurationTable.valueHeadersTable;

				} else {
					const language: string = sessionStorage.getItem('language');
					SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
						language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE);
				}
				this.spinner.hide();
			}, (error) => {
				this.spinner.hide();
				console.error(error);
			}
		);
	}


	/**
* @description This method is to group by hierarchy the purchase base
* and promotional products list
*
* @param listProducts
* @author Diego Mauricio Cortés
* @sprint 9
*/
	private groupProductsToShow(listProducts: IProductList[]): IProductGroupList[] {
		const groups: IProductGroupList[] = [];
		if (listProducts.length > 0) {
			listProducts.forEach((product: IProductList) => {
				const groupFound = groups.find((fGroup) => fGroup.groupName === product.hierarchy);
				if (groupFound) {
					groupFound.products.push(product);
				} else {
					const filterFound = this.advancedFiltersObjectList.find((fFilter) => fFilter.hierarchyFilter === product.hierarchy);
					let newGroup: ProductGroupListModel = null;
					newGroup = new ProductGroupListModel(
						product.hierarchy, // groupName
						0, // commercialActivityId
						0, // purchaseUnit
						'ST', // measurementUnitCode
						true, // isGroup,
						JSON.stringify(filterFound), // groupByJson
						0, // id
						[product] // producst
					);
					groups.push(newGroup);
				}
			});
		}

		return groups;
	}

	/**
	 * @description This method is to prepare sku's to send to calculate
	 * @sprint 18
	 * @author Diego Cortes
	 */
	 private preparePurchaseToCalculate() {
		if (this.rappelActivityEdit.isCalculate) {
			this.isProcess = ComercialActivityEnum.view;
		}
		if (this.rappelActivityEdit.purchaseBases) {
			const rangeRappel: IRangesRappel = this.maxValRappelRange(this.rappelActivityEdit.commercialActivityRange);
			this.rappelActivityEdit.purchaseBases.forEach((object: any) => {
				if (object.purchaseBaseDetails) {
					const dt = object.purchaseBaseDetails.map((obj: any) => {
						return {
							id: obj.id,
							purchaseBaseId: obj.purchaseBaseId,
							materialCode: obj.materialCode,
							materialDescription: obj.materialDescription,
							measurementUnitCode: obj.measurementUnitCode,
							measurementUnitDescription: obj.measurementUnitDescription,
							purchaseUnit: rangeRappel.contributionValue,
							porcent: rangeRappel.contributionValue,
							discountValue: obj.discountValue,
							product: obj.product,
							hierarchy: obj.hierarchy,
							currentPsp: obj.currentPsp,
							depositValue: obj.depositValue,
							ebitdaPercentage: obj.ebitdaPercentage,
							ebitda: obj.ebitda,
							weightedDiscountPercentage: obj.weightedDiscountPercentage,
							costSale: obj.costSale,
							costIncome: obj.costIncome,
							factor: object.factor || 1.235,
							perDescount: obj.perDescount || 0,
							promotionValue: obj.promotionValue || 0,
							weightedValue: obj.weightedValue || 0,
							kpiLiberationSku: obj.kpiLiberationSku || 0,
						};
					});
					this.purchaseBaseToCalculate = this.purchaseBaseToCalculate.concat(dt);
				}
			});
		}
	}

	private maxValRappelRange(rangeRappel: IRangesRappel[]): IRangesRappel {
		let ret: IRangesRappel;
		rangeRappel.forEach((range) => {
			if (ret) {
				if (ret.contributionValue < range.contributionValue) {
					ret = range;
				}
			} else {
				ret = range;
			}
		});
		return ret;
	}

	private prepareDataEditActivityRappel(rappel: CommercialActivityRappelModel): void {
		this.description.setValue(rappel.description);
		this.typeCommercialActivity.setValue(this.findTypeCommercialActivityName(rappel.typeCommercialActivityDomainId));
		this.prepareFiltersToEditProductsRappelActivity(rappel);
		this.channelRappel.setValue(this.prepareChannelsToShowEditPromotionalActivity(rappel.commercialActivityChannels));
		this.settlementPeriod.setValue(rappel.periodSettlement);
		this.consideration.setValue(rappel.consideration);
		this.changeConsideration();
		this.measuredType.setValue(rappel.typeMeasure);
		this.changeMeasureType();
		this.prepareRangeToEditRappelActivity(rappel);
		this.preparePurchaseBaseProductsToEditRappelActivity(rappel);
		this.rappelActivityEdit.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate(rappel);
	}

	private prepareActivityHandler() {
		let FiltersObjectHandlerListAux = this.activityHandler.getGroupByJsonHandler();
		let HandlerDetailsAux = this.activityHandler.getCommercialActivityHandlerDetails();
		let handlerPeriodAux = this.activityHandler.getCommercialActivityHandlerPeriodModel();
		let isGroup = this.activityHandler.getIsGroup();
		let isSwitchCustomer = this.activityHandler.getIsSwitchCustomer();

		this.editFiltersObjectHandlerList2 =  FiltersObjectHandlerListAux ? JSON.parse(FiltersObjectHandlerListAux) : [];
		this.commercialActivityHandlerisGroup2 = isGroup;
		this.commercialActivityHandlerisClientWithoutSales2 = isSwitchCustomer;

		this.commercialActivityHandlerDetails2 = HandlerDetailsAux;
		

		this.commercialActivityHandlerDetailsAux2 = HandlerDetailsAux.map(item => {
			return {
				commercialActivityHandlerId : item.commercialActivityHandlerId,
				materialCode : item.materialCode,
				rankStart : item.rankStart,
				rankEnd : item.rankEnd,
				id: item.id,
			}
		});
		

		this.commercialActivityHandlerPeriod2 = handlerPeriodAux;

		this.commercialActivityHandler2 = {
			commercialActivityId: this.commercialActivityId != undefined ? this.commercialActivityId : 0,
			id: this.isProcess == 1 || !this.commercialActivityHandler ? 0 : this.commercialActivityHandler.id,
			isGroup: this.commercialActivityHandlerisGroup2,
			groupByJson : FiltersObjectHandlerListAux,
			commercialActivityHandlerDetails: this.commercialActivityHandlerDetailsAux2,
			isClientWithoutSales : this.commercialActivityHandlerisClientWithoutSales2,
			isCurrentMonth : this.isCurrentMonth
		};

	}

	private prepareActivityHandlerCurrentMonth() {
		let date = new Date();
        let currentDate = date.getFullYear().toString() + ((date.getMonth() + 1).toString().padStart(2,'0'));
		let FiltersObjectHandlerListAux = this.ActivityHandlerCurrentMonth.getGroupByJsonHandler();
		
		this.editFiltersObjectHandlerCurrentMonthList2 =  FiltersObjectHandlerListAux ? JSON.parse(FiltersObjectHandlerListAux) : [];
		this.commercialActivityHandlerCurrentMonthDetails2 = this.ActivityHandlerCurrentMonth.getCommercialActivityHandlerDetails();

		
		this.commercialActivityCurrentMonthDetailsAux2 = this.commercialActivityHandlerCurrentMonthDetails2.map(item => {
			return {
				id: item.id,
				commercialActivityHandlerCurrentMonthId : item.commercialActivityHandlerCurrentMonthId,
				materialCode : item.materialCode,
				materialDescription : item.materialDescription,
				zofeCode : item.zofeCode,
				zofeDescription : item.zofeDescription
			}
		});
		

		this.commercialActivityHandlerCurrentMonth2 = {
			commercialActivityId: this.commercialActivityId != undefined ? this.commercialActivityId : 0,
			id: this.isProcess == 1 || !this.commercialActivityHandlerCurrentMonth ? 0 : this.commercialActivityHandlerCurrentMonth.id,
			groupByJson : FiltersObjectHandlerListAux,
			commercialActivityHandlerCurrentMonthDetails: this.commercialActivityCurrentMonthDetailsAux2,
			isClientWithoutSales : undefined,
			isCurrentMonth : undefined,
			isGroup : undefined,
			period: currentDate,
		};

	}

	private prepareDataHandler(){
		this.showManejantes = this.showManejantes;
		this.editFiltersObjectHandlerList = [...this.editFiltersObjectHandlerList2];
		this.commercialActivityHandlerDetails = [...this.commercialActivityHandlerDetails2]
		this.commercialActivityHandlerPeriod = this.commercialActivityHandlerPeriod2;
		this.commercialActivityHandlerisGroup = this.commercialActivityHandlerisGroup2;
		this.commercialActivityHandlerisClientWithoutSales = this.commercialActivityHandlerisClientWithoutSales2;

		//HandlerCurrentMonthList
		this.isCurrentMonth = this.isCurrentMonth;
		this.editFiltersObjectHandlerCurrentMonthList = [...this.editFiltersObjectHandlerCurrentMonthList2]
		this.commercialActivityHandlerCurrentMonthDetails = [...this.commercialActivityHandlerCurrentMonthDetails2]
	}

	prepareCommercialActivitiesDocumentsToCreate() {
		this.documentInfo[0].commercialActivityId = this.commercialActivityId;
	}

	/**
	* @description This method is to initialize the form.
	* @author Daniel London0
	* @sprint 14
	*/
	initForm(): void {
		this.formCommercialRappel = this.fb.group({
			description: [{ value: '', disabled: true }, [Validators.required]],
			typeCommercialActivity: [{ value: '', disabled: true }, [Validators.required]],
			channelRappel: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.view }, [Validators.required]],
			settlementPeriod: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.view }, [Validators.required]],
			measuredType: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.view }, [Validators.required]],
			consideration: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.view }, [Validators.required]],
			sector: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.view }, []]
		});
	}

	
	validateDocuments():boolean {
		let validDocument:boolean = true;
		this.documentInfo = this.documentSupport.getDocumentInformation();
		const language: string = sessionStorage.getItem('language');
	
		let fileName = this.documentInfo[0].file ?? this.documentSupport.getNameFile();

		if((this.documentFieldsRequired?.document && !fileName) && 
		(this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim()))
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE_TRANSLATE);
				validDocument = false;
			
			return;
		}
		
		if (this.documentFieldsRequired?.document && !fileName)
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE_TRANSLATE);
				validDocument = false;
		}
	
		if (this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim())
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_TRANSLATE);
				validDocument = false;
		}	
		return validDocument;
	}	

	get description(): AbstractControl { return this.formCommercialRappel.get('description'); }
	get typeCommercialActivity(): AbstractControl { return this.formCommercialRappel.get('typeCommercialActivity'); }
	get channelRappel(): AbstractControl { return this.formCommercialRappel.get('channelRappel'); }
	get settlementPeriod(): AbstractControl { return this.formCommercialRappel.get('settlementPeriod'); }
	get measuredType(): AbstractControl { return this.formCommercialRappel.get('measuredType'); }
	get consideration(): AbstractControl { return this.formCommercialRappel.get('consideration'); }
	get sector(): AbstractControl { return this.formCommercialRappel.get('sector'); }
}
