<section>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="pstn breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ 'HierarchyReleaseComponent.Associate.Title' | translate }}</li>

        </ol>
      </nav>
    </div>
  </section>

<section class="pstngestion">
    <div class="container">
        <div class="row">
          <h1 class="pstntitle">{{ 'HierarchyReleaseComponent.Associate.Title' | translate }}</h1>
        </div>

        <div class="row mt-5"></div>

        <div class="row" *ngIf="processHierarchy == processHierarchyEnum.create">

            <div class="col-md-6">
              <p-panel header="{{ 'HierarchyReleaseComponent.Associate.SubTitleOne' | translate }}">
                <div *ngFor="let profile of profileList">
                  <div 
                  pDraggable="dd" 
                  dragEffect="move" 
                  (onDragStart)="dragStart($event, profile)" 
                  (onDragEnd)="dragEnd($event)">
                    <button id="btnProfile-{{profile.id}}"
                            type="button"
                            class="pstn_posponer_drag_and_drop">
                            {{profile.description}}
                    </button>
                  </div>
                </div>
              </p-panel>
            </div>

            <div class="col-md-6">
              <p-panel 
              header="{{ 'HierarchyReleaseComponent.Associate.SubTitleTwo' | translate }}" 
              pDroppable="dd" 
              (onDrop)="drop($event)">
                <div *ngFor="let profileSelected of profileListSelected">
                  <div class="col-md-12">
                    <button id="btnProfileSelected-{{profileSelected.id}}"
                            type="button"
                            class="pstn_secundario_drag_and_drop"
                            (click)="changeStyleProfileSelected($event, profileSelected)">
                            {{profileSelected.description}}
                    </button>
                    <button id="btnProfileSelectedDelete-{{profileSelected.id}}"
                            type="button"
                            class="btn btn-secundary-editar">
                        <i class="uil uil-trash-alt eliminar" 
                           aria-hidden="true"
                           (click)="deleteProfileSelected(profileSelected)">
                        </i>
                    </button>
                  </div>
                </div>
              </p-panel>
            </div>

        </div>

        <div class="row mt-5"></div>
        <label> <h3 class="pstntitle">{{ titleTable }}</h3></label>
        <div class="row">
            <p-table #dt
                [value]="usersInfo"
                dataKey="id"
                styleClass="p-datatable-customers"
                [paginator]="true"
                [rows]="10"
                paginatorPosition="bottom"
                currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
                [rowsPerPageOptions]="[10,25,50,100]"
                [globalFilterFields]="['name', 'accessManagementId']">
                <ng-template pTemplate="caption">
                  <div class="p-d-flex">
                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search" aria-hidden="true"></i>
                        <input pInputText
                                type="text"
                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="{{ 'SearchBy' | translate }}" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 15rem">
                        <div class="p-d-flex p-jc-between col-md-12">
                          <p-checkbox id="check-associate-all"
                                      inputId="check-associate-all"
                                      value="checkAssociateAll"
                                      binary="true"
                                      [(ngModel)]="checkAssociateAll"
                                      (onChange)="approvalAll($event)">
                          </p-checkbox>
                          <div>
                            {{ 'HierarchyReleaseComponent.Associate.Associate' | translate }}
                          </div>
                        </div>
                      </th>
                        <th pSortableColumn="name">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'HierarchyReleaseComponent.Associate.Names' | translate }}
                            <p-sortIcon field="name"></p-sortIcon>
                          </div>
                        </th>
                        <th pSortableColumn="accessManagementId">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'HierarchyReleaseComponent.Associate.Users' | translate }}
                            <p-sortIcon field="accessManagementId"></p-sortIcon>
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'HierarchyReleaseComponent.Associate.Mandatory' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ 'HierarchyReleaseComponent.Associate.Authorizer' | translate }}
                          </div>
                        </th>

                      </tr>
                </ng-template>
                <ng-template pTemplate="body"
                             let-user>
                    <tr>
                      <td>
                        <p-checkbox id="check-{{user.id}}"
                                    inputId="check-{{user.id}}"
                                    value="user.approval"
                                    binary="true"
                                    [(ngModel)]="user.approval"
                                    (onChange)="callApproval($event, user)">
                        </p-checkbox>
                      </td>
                      <td>
                        {{user.name}} {{user.lastName}}
                      </td>
                      <td>
                        {{user.accessManagementId}}
                      </td>
                      <td>
                        <p-inputSwitch id="switch-one-{{user.id}}"
                                       name="switch-{{user.id}}"
                                       [(ngModel)]="user.mandatory"
                                       (onChange)="callMandatory($event, user)">
                        </p-inputSwitch>
                      </td>
                      <td>
                        <p-inputSwitch id="switch-two-{{user.id}}"
                                       name="switch-{{user.id}}"
                                       [(ngModel)]="user.authorizer"
                                       (onChange)="callAuthorizer($event, user)">
                        </p-inputSwitch>
                      </td>
                    </tr>

                </ng-template>
            </p-table>
        </div>
        <div class="row mt-5"></div>
        <div class="row">
          <div class="col-md-6">
            <button type="button"
                  class="pstn_primario"
                  style="background-color: darkgrey; border-color: darkgrey;"
                  (click)="cancel()">
              {{'CancelBtn' | translate}}
            </button>
          </div>

          <div class="col-md-6">
            <button type="button"
                    class="pstn_primario"
                    style="float: right;"
                    (click)="save()">
              {{'SaveBtn' | translate}}
            </button>
          </div>
        </div>
        <div class="row mt-5"></div>
    </div>
</section>

<p-toast></p-toast>
