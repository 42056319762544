import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

  export class AdvancedRedeemableFilterService {
    private readonly httpService: HttpService;
    constructor(httpService: HttpService)
    {
      this.httpService = httpService;
    }

    /**
   *
   * @param filters
   * @returns
   */
  redeemableFiltersMaterials(filters: any): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> = this.httpService
    .patch(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/hierarchy_filters_materials_old`, filters)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => response.body)
    );
    return response$;
  }

  /**
 *
 * @param filters
 * @returns
 */
    redeemableFiltersCheck(filters: any): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> = this.httpService
    .patch(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_hierarchy_filters_materials_check_old`, {'filters': filters})
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => response.body)
    );
    return response$;
  }

  /**
 *
 * @param filters
 * @returns
 */
  redeemableFiltersElements(): Observable<IResponseService<any>>{
    const response$: Observable<IResponseService<boolean>> = this.httpService
    .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/redemeeable_materials`)
    .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
        return response.body;
        })
    );
    return response$;
  }
}
