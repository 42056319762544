import { IPagination } from '../interfaces/pagination.interface';

export class PaginationByIdModel implements IPagination {
  /**
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  constructor(
    public orderBy: string,
    public ascendent: boolean,
    public pageNumber: number,
    public resultsPerPage: number,
    public id: number,
    ) {}
}