import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProfile } from './interfaces/profile.interface';
import { ProfileModel } from './models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  /**
   * @description This method is to get all the profiles.
   * @author Elkin Vasquez Isenia
   * @param none
   * @returns Observable<IResponseService<IProfile[]>>
   * @sprint 1
   */
  public getAllProfile(): Observable<IResponseService<IProfile[]>> {
    const response$: Observable<IResponseService<IProfile[]>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/profiles/1/100`)
      .pipe(
        map((response: HttpResponse<IResponseService<IProfile[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to get all the profiles whit approval level.
   * @author Priscy Antonio Reales
   * @param none
   * @returns Observable<IResponseService<IProfile[]>>
   * @sprint 6
   */
   public getAllProfileApprovalLevel(): Observable<IResponseService<IProfile[]>> {
    const response$: Observable<IResponseService<IProfile[]>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/profiles/is_approval_level/1/100`)
      .pipe(
        map((response: HttpResponse<IResponseService<IProfile[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to create profile.
 * @author Elkin Vasquez Isenia
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 1
 */
  public postProfile(body: ProfileModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .post(
        `${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/profiles`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to update profile.
 * @author Elkin Vasquez Isenia
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 1
 */
  public putProfile(id: number, body: ProfileModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .put(
        `${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/profiles/${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to delete a profile.
 * @author Elkin Vasquez Isenia
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 1
 */
  public deleteProfile(id: number): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService.delete(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/profiles/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
