export enum FilterClient {

  Country = 'País',
  CountryCode = 'SCountryCode',
  CountryDescription = 'SCountryDescription',
  Sector = 'Sector',
  SectorCode = 'SSector',
  SecortDescription = 'SSectorDescription',
  Society = 'Sociedad',
  SocietyCode = 'SSociety',
  SocietyDescrioption = 'SSocietyDescription',
  Region = 'Region',
  RegionCode = 'SRegion',
  RegionDescription = 'SRegionDescription',
  Cedi = 'Centro de distribución',
  CediCode = 'SCenterDistribution',
  CediDescription = 'SCediDescription',
  SalesForce = 'Fuerza de venta',
  SalesForceCode = 'SSalesForceCode',
  SalesForceDescription = 'SSalesForceDescription',
  SalesManager = 'Representante',
  SalesManagerCode = 'SSRepresentativeCode',
  SalesManagerDescription = 'SSRepresentativeName',
  SalesSupervisor = 'Supervisor',
  SalesSupervisorCode = 'SSalesSupervisor',
  SalesSupervisorDescription = 'SSalesSupervisorName',
  SalesBoss = 'Jefe de venta',
  SalesBossCode = 'SSalesBoss',
  SalesBossDescription = 'SSalesBossName',
  Macrosegment = 'Macrosegmento',
  MacrosegmentCode = 'SMacrosegment',
  MacrosegmentDescription = 'SMacrosegmentDescription',
  Subtypology = 'Subtipología',
  SubtypologyCode = 'SSubtypology',
  SubtypologyDescription = 'SSubTypologyDescription',
  PurchaseMission = 'Misión de compra',
  PurchaseMissionCode = 'SPurchaseMission',
  PurchaseMissionDescription = 'SPurchaseMissionDescription',
  Typology = 'Tipología',
  TypologyCode = 'STypology',
  TypologyDescription = 'STypologyDescription',
  CustomerHierarchyLvl1 = 'Cliente Jerarquía 1',
  CustomerHierarchyLvl2 = 'Cliente Jerarquía 2',
  CustomerCode = 'SGroupCode',
  SSupplyChainCode = 'SSupplyChainCode',
  CustomerDescription = 'SCustomerName',
  ClientCode = 'SClientCode'
}
