import { IApprovalRankRequest } from '../interfaces/approval-rank-request.interface';
import { ApprovalRankLevelRequestModel } from './approval-rank-level-request.model';

export class ApprovalRankRequestModel implements IApprovalRankRequest {
    /**
   * @author Priscy Antonio Reales
   * @sprint 6
   */
  constructor(
    public id : number,
    public description: string,
    public countryCode: string,
    public societyCode: string,
    public channelCode: string,
    public regionCode: string,
    public cediCode: string,
    public processDomainId: number,
    public subprocessDomainId: number,
    public unitMeasurementDomainId: number,
    public status : boolean,
    public approvalRankLevel?: ApprovalRankLevelRequestModel[],
  ) {}
}