<p-accordion>
    <p-accordionTab [disabled]="isDisabled"
      header="{{ 'MaterialList' | translate }}"
    >
    <div>
      <div class="table-responsive">
        <a *ngIf="typeOfLoad == typeOfLoadFileExcel.Solicitude && processType == solicitudeType.Creation"
        download="{{ 'SolicitudComponent.Create.TemplateExcel' | translate }}"
        target="_blank"
          href="/assets/files/Plantilla - Carga masiva lista de precio.xlsx"
        >
         {{ "AssingClient.DownloadTemplate" | translate }}
        </a>
        <a *ngIf="typeOfLoad == typeOfLoadFileExcel.Solicitude && processType == solicitudeType.Withdrawal"
          download="{{ 'SolicitudComponent.Create.TemplateExcel' | translate }}"
          target="_blank"
          href="/assets/files/Plantilla - Carga masiva retiro de precio.xlsx"
      >
        {{ "AssingClient.DownloadTemplate" | translate }}
      </a>
        <a *ngIf="typeOfLoad == typeOfLoadFileExcel.CommercialActivity"
          download="{{ 'SolicitudComponent.Create.TemplateExcel' | translate }}"
          target="_blank"
          href="/assets/files/Plantilla - Carga masiva actividad comercial.xlsx"
      >
        {{ "AssingClient.DownloadTemplate" | translate }}
      </a>
    </div>
      <div class="col-md-12">
        <p-message
          severity="info"
          text="{{ 'SolicitudComponent.Create.FileUploadMsgValidateExcel' | translate }}"
          styleClass="p-mr-5"
        ></p-message>
      </div>
      <p-fileUpload
        #fileUpload
        id="fileKey"
        customUpload="true"
        class="col-md-12"
        (uploadHandler)="onUploadFile($event)"        
        [auto]="true"
        maxFileSize="4194304"
        chooseLabel="{{ 'SolicitudComponent.Create.UploadFile' | translate }}"
        invalidFileSizeMessageSummary="{0}: {{
          'SolicitudComponent.Create.InvalidSize' | translate
        }}"
        invalidFileSizeMessageDetail="{{
          'SolicitudComponent.Create.FileUpload' | translate
        }} {0}."
        accept=".xlsx,.xls"
        invalidFileTypeMessageSummary="{0}. {{'SolicitudComponent.Create.InvalidType' | translate}}"
        invalidFileTypeMessageDetail= "{{'SolicitudComponent.Create.ValidType' | translate}} : {0}"
      >
        <ng-template pTemplate="content">
          <div *ngIf="!uploadedFiles.length" class="col-md-12">
            {{ "SolicitudComponent.Create.NoFileExcel" | translate }}
          </div>
        </ng-template>
      </p-fileUpload>
    </div>     
    </p-accordionTab>
  </p-accordion>
  