import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidityPeriodService } from './validity-period.service';
import { ApplicationConstants } from '@app/app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { ValidityPeriodMessage } from './messages/validity-period.message';
import { SweetAlertResult } from 'sweetalert2';
import { Util } from '@app/@shared/util';
import { MaximumPeriodValidityModel } from './models/maximum-period-validity.model';
import { MaximumPeriodValidityCreateModel } from './models/maximum-period-validity-create.model';
import { DomainService } from '@app/@shared/services/domain.service';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { PaginationModel } from '@app/@shared/model/pagination.model';
import { IValidityPeriod } from './interfaces/validity-period.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'app-validity-period',
  templateUrl: './validity-period.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
    providers: [MessageService]
})
export class ValidityPeriodComponent implements OnInit {
  private readonly fb: FormBuilder;
  private readonly messageService: MessageService;
  private readonly spinner: NgxSpinnerService;
  private readonly validityPeriodService: ValidityPeriodService;
  private readonly domainService: DomainService;
  private readonly translate: TranslateService;
  private readonly i18nService: I18nService;

  public formValidityPeriod: FormGroup;
  public isCreate = true;
  public objectPromotionalActivity: ICombo[];
  public maximunPeriodValidityUpdate: MaximumPeriodValidityModel;
  public listValidityPeriod : IValidityPeriod[] = [];
  public validityPeriodUpdate : IValidityPeriod;
  public objectlist: any[];
  public disabledDropdown = false;
  public numberDays = 0;
  public rolePermission: any = RolePermissionEnum;
  public language: string = sessionStorage.getItem('language');

  constructor(
    fb: FormBuilder,
    messageService: MessageService,
    spinner: NgxSpinnerService,
    validityPeriodService: ValidityPeriodService,
    domainService: DomainService,
    translate: TranslateService,
    i18nService: I18nService
  ) { 
    this.fb = fb;
    this.messageService = messageService;
    this.spinner = spinner;
    this.validityPeriodService = validityPeriodService;
    this.domainService = domainService;
    this.translate = translate;
    this.i18nService = i18nService;
  }

  ngOnInit(): void {
    this.getAllComercialActivity(true);
    this.initForm();  
    this.formValidityPeriod.reset();
  }

  /**
   * @description this method is to get all the validity period.
   * @author kmora
   * @sprint 9
   */
  getAll(): void {
   this.spinner.show();
   const pagination = new PaginationModel('Id', true, 1, 100);
    this.validityPeriodService
    .getAll(pagination)
    .subscribe(
      (response) => {
        this.spinner.hide();
        this.objectlist = response.data;
        this.listValidityPeriod = response.data;
        response.data.map((detail) => {
          this.objectPromotionalActivity = this.objectPromotionalActivity.filter(item => item.value != detail.subProcessDomainId);
        }
      )
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  /**
   * @description this method is to create validity period.
   * @author kmora
   * @sprint 9
   */
  add(): void{
    Object.keys(this.formValidityPeriod.controls).forEach((key) => { this.formValidityPeriod.get(key).markAsDirty(); });
    if(this.formValidityPeriod){
      this.disabledDropdown = false;
      const maxPeriod: MaximumPeriodValidityCreateModel =
      new MaximumPeriodValidityCreateModel(
        this.comercialActivity.value,
        this.days.value
      );
      if(maxPeriod.subProcessDomainId != null && maxPeriod.numberDay != null){
        const isDescripcionExist = this.listValidityPeriod.filter((d) => d.subProcessDomainId === Number(this.comercialActivity.value));

        if(isDescripcionExist.length > 0){
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? ValidityPeriodMessage.IS_DESCRIPTION : ValidityPeriodMessage.IS_DESCRIPTION_TRANSLATE);
          this.spinner.hide();
          return;
        }
      this.spinner.show();
        this.validityPeriodService.post(maxPeriod).subscribe(
          (response) => {
            this.spinner.hide();
            if(response.status){
              this.getAll();
              this.clearControls();
              this.formValidityPeriod.reset();
              const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
            }else{
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    }else{
      this.formValidityPeriod.markAllAsTouched();
    }
  }

 /**
   * @description this method is to updateData.
   * @author kmora
   * @sprint 9
   */
  updateData(): void {
    if(this.formValidityPeriod.valid){
      this.spinner.show();
      const updateModel = new MaximumPeriodValidityModel(this.validityPeriodUpdate.id, this.comercialActivity.value, this.days.value);
      this.validityPeriodService.put(updateModel.id, updateModel).subscribe(
        (response) => {
          this.spinner.hide();
          this.disabledDropdown = false;
          if(response.status){
            this.isCreate = true;
            this.getAll();
            this.clearControls();
            this.formValidityPeriod.reset();
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconWarningToast,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate);
          }else{
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
    }else {
      this.formValidityPeriod.markAllAsTouched();
    }
  }

    /**
   * @description this method is to get all the Comercial Activity.
   * @author kmora
   * @sprint 9
   */
  getAllComercialActivity(isFirst: boolean = false): void {
    if(this.language === this.translate.currentLang && !isFirst){
      return;
    }else{
      this.translate.use(this.i18nService.language);
      this.language = this.i18nService.language;
    }

    this.spinner.show();
    this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess).subscribe(
      (response) => {
        this.spinner.hide();
        this.objectPromotionalActivity = response.data.filter((f) => f.code === 'AP').map((item) => {
          return {
            label: this.translate.instant('Domain.Domain-'+item.id),
            value: `${item.id}`
          };
        });
        this.getAll();
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

 /**
   * @description this method is to edit validity period.
   * @author kmora
   * @sprint 9
   */
  public edit(validityPeriod: IValidityPeriod): void  {
    this.disabledDropdown = true;
    Util.confirmEdit().then((result: SweetAlertResult) => {
      if(result.value){
        this.isCreate = false;
        this.objectPromotionalActivity.push({label: this.translate.instant('Domain.Domain-'+validityPeriod.subProcessDomainId), value: validityPeriod.subProcessDomainId});
        this.comercialActivity.setValue(validityPeriod.subProcessDomainId);
        this.days.setValue(validityPeriod.numberDay);
        this.validityPeriodUpdate = validityPeriod;
      }
    });
  }

   /**
   * @description this method is to delete validity period.
   * @author kmora
   * @sprint 9
   */
  public delete(maximoPeriodValidity: MaximumPeriodValidityModel) {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.validityPeriodService.deleteLogic(maximoPeriodValidity.id)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.isCreate = true;
                this.getAll();
                this.clearControls();
                this.formValidityPeriod.reset();
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
                this.objectPromotionalActivity.push({label: this.translate.instant('Domain.Domain-'+maximoPeriodValidity.subProcessDomainId), value: maximoPeriodValidity.subProcessDomainId})
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
  });
  }

  /**
   * @description this method is to clear controls.
   * @author kmora
   * @sprint 9
   */
   clearControls(): void {
    this.formValidityPeriod.controls['comercialActivity'].setValue([]);
    this.formValidityPeriod.controls['days'].setValue([]);
  }

  public checkTNumber = (event: KeyboardEvent) => {
    const keyCod = event.keyCode;
      const key =  event.key;
    if (![45].includes(keyCod) && isNaN(Number(key))) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  /**
  * @description This method shows the toast control.
  * @author kmora
  * @sprint 9
  * @param severity
  * @param message
  * @param detail
  * @returns none
  */
   showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  initForm(): void {
    this.formValidityPeriod = this.fb.group({
      comercialActivity: [{ value: [], disabled: false }, Validators.required],
      days: [{ value: null, disabled: false }, [Validators.required, Validators.min(1), Validators.max(999)]]
    });
  }

  get comercialActivity(): AbstractControl { return this.formValidityPeriod.get('comercialActivity'); }
  get days(): AbstractControl { return this.formValidityPeriod.get('days'); }
}
