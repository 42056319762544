export class OrganizationNodeJsonModel{
    constructor(
        public pid: number,
        public name: string,
        public email: string,
        public idTitle: number,
        public title: string,
        public cedi: string,
        public sector: string,
        public img: string,
        public tags?: any[]

    ) {}
}