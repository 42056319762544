import { IProductGroupList } from '../interfaces/product-group-list.interface';
import { IProductList } from '../interfaces/product-list.interface';


export class ProductGroupListModel implements IProductGroupList {
  constructor(
    public groupName?: string,
    public commercialActivityId?: number,
    public purchaseUnit?: number,
    public measurementUnitCode?: string,
    public isGroup?: boolean,
    public groupByJson?: string,
    public id?: number,
    public products?: IProductList[],

  ) { }
}
