import { ISolicitudeFile } from '../interfaces/solicitude-file.interface';

export class SolicitudeFileModel implements ISolicitudeFile {

  constructor(
    public id: string,
    public solicitudeId: number,
    public route: string,
    public file: string,
    public hash: string,
  ){}
}
