<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"
            ><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "PendingSolicitudeComponent.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">
          {{ "PendingSolicitudeComponent.Title" | translate }}
        </h1>
      </div>
    </div>

    <div class="row mt-5">
      <div class="pstn_container container-fluid">
        <!-- PrimeNg Table -->
        <p-table
          #dt
          dataKey="id"
          [value]="objectList"
          [paginator]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10,25,50,100]"
          paginatorPosition="bottom"
          styleClass="p-datatable-customers"
          [globalFilterFields]="['id', 'typeDescription', 'statusDescription', 'sku']"
        >
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="p-fluid p-grid p-formgrid col-md-6 row">
                <div class="col-md-8 align-items-start"></div>
                <div class="col-md-2"></div>
              </div>
              <div class="col-md-6 p-0">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search" aria-hidden="true"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{ 'SearchBy' | translate }}"
                  />
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem"></th>
              <th pSortableColumn="sku" style="display:none;">
                <div class="p-d-flex p-jc-between p-ai-center">
                 <!--sku-->
                  <p-sortIcon field="sku"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="id">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Id" | translate }}
                  <p-sortIcon field="id"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="typeDescription">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.SolicitudeType" | translate }}
                  <p-sortIcon field="typeDescription"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="statusDescription">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Status" | translate }}
                  <p-sortIcon field="statusDescription"></p-sortIcon>
                </div>
              </th>
              <th>{{ "Actions" | translate }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-object let-expanded="expanded">
            <tr [pSelectableRow]="object">
              <td></td>
             <!-- <td>
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="object"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                ></button>
              </td>-->
              <td style="display:none;">
                {{ object.sku }}
              </td>
              <td>
                {{ object.id }}
              </td>
              <td>
                {{ 'DomainPrices.Domain-'+object.type | translate}}
                <!-- {{ object.typeDescription }} -->
              </td>
              <td>
                {{ 'DomainPrices.Domain-'+object.status | translate}} 
                <!-- {{ object.statusDescription }} -->

                <i class="pi pi-spin pi-spinner" 
											style="font-size: 1rem; color: #009cdd;"
											pTooltip="La solicitud está en proceso de aprobación"
                      aria-hidden="true" 
											*ngIf="solicitudeStatus.InProcessApproval === object.status"></i>

                <i class="pi pi-info-circle" 
											style="font-size: 1rem; color: red;"
											pTooltip="Error al aprobar la solicitud"
                      aria-hidden="true" 
											*ngIf="object.isError"></i>
              </td>
              <td>
                  <i id="btnAnalyze"
                  pTooltip="{{'Analysis' | translate}}"
                  (click)="analyzeSolicitude(object)"
                  class="uil uil-search-alt Editar"
                  aria-hidden="true" 
                  *ngIf="(solicitudeStatus.InProcessApproval !== object.status && (rolePermission.read | rolePermission))"></i>

                  <i id="btnHistory"
                  pTooltip="{{'Historical' | translate}}"
                  class="uil uil-history historico"
                  (click)="historySolicitude(object.id)"
                  aria-hidden="true"
                  *ngIf="rolePermission.read | rolePermission"></i>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-object>
            <tr>
              <td colspan="5">
                <div class="p-p-3">
                  <p-table
                    [value]="object.solicitudeDetailJson"
                    [scrollable]="true"
                    scrollHeight="300px"
                    dataKey="id"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th pSortableColumn="sku">
                          {{ "SolicitudComponent.Sku" | translate }}
                          <p-sortIcon field="sku"></p-sortIcon>
                        </th>
                        <th pSortableColumn="materialDescription">
                          {{
                            "SolicitudComponent.MaterialDescription" | translate
                          }}
                          <p-sortIcon field="materialDescription"></p-sortIcon>
                        </th>
                        <th pSortableColumn="currentListPrice">
                          {{
                            "SolicitudComponent.CurrentListPrice" | translate
                          }}
                          <p-sortIcon field="currentListPrice"></p-sortIcon>
                        </th>
                        <th pSortableColumn="priceProposed">
                          {{ "SolicitudComponent.PriceProposed" | translate }}
                          <p-sortIcon field="priceProposed"></p-sortIcon>
                        </th>
                        <th pSortableColumn="currentPsp">
                          {{ "SolicitudComponent.CurrentPsp" | translate }}
                          <p-sortIcon field="currentPsp"></p-sortIcon>
                        </th>
                        <th pSortableColumn="proposedPsp">
                          {{ "SolicitudComponent.ProposedPsp" | translate }}
                          <p-sortIcon field="proposedPsp"></p-sortIcon>
                        </th>
                        <th pSortableColumn="validity">
                          {{ "SolicitudComponent.Validity" | translate }}
                          <p-sortIcon field="validity"></p-sortIcon>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-solicitudeDetailJson>
                      <tr>
                        <td>{{ solicitudeDetailJson.sku }}</td>
                        <td>
                          {{ solicitudeDetailJson.materialDescription }}
                        </td>
                        <td>
                          {{
                            solicitudeDetailJson.currentListPrice
                              | currency: "USD" || ""
                          }}
                        </td>
                        <td>
                          {{
                            solicitudeDetailJson.newPriceWithOutTax
                              | currency: "USD" || ""
                          }}
                        </td>
                        <td>
                          {{
                            solicitudeDetailJson.currentPsp
                              | currency: "USD" || ""
                          }}
                        </td>
                        <td>
                          {{
                            solicitudeDetailJson.newPsp | currency: "USD" || ""
                          }}
                        </td>
                        <td>
                          {{
                            solicitudeDetailJson.effectiveDateStart
                              | date: "dd/MM/yyyy"
                          }}
                          -
                          {{
                            solicitudeDetailJson.effectiveDateEnd
                              | date: "dd/MM/yyyy"
                          }}
                        </td>
                      </tr>
                    </ng-template>
                    <!-- <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">
                          {{ "ElementsNotFound" | translate }}
                        </td>
                      </tr>
                    </ng-template> -->
                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td *ngIf="validity" colspan="4">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
      </div>
    </div>
  </div>
</section>
<p-dialog
header="{{
  'SolicitudComponent.HeaderModalSolicitudeHistory'
    | translate
}}"
[(visible)]="displayModal"
[modal]="true"
[style]="{ width: '50vw' }"
[baseZIndex]="10000"
[draggable]="false"
[resizable]="false">
<ng-template pTemplate="content">


</ng-template>
<!-- PrimeNg Table -->
<p-table
  #dt
  name="gridSolicitudeHistory"
  [value]="objectListSolicitudeHistory"
  dataKey="solicitudeId"
  [paginator]="false"
  [rows]="10"
  currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  paginatorPosition="bottom"
  styleClass="p-datatable-customers"
  [globalFilterFields]="[
    'statusDescription',
    'creationUser',
    'creationDate'
  ]"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="status">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{
            "SolicitudComponent.History.StatusDescription"
              | translate
          }}
          <p-sortIcon field="statusDescription"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="creationUser">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{
            "SolicitudComponent.History.CreationUser"
              | translate
          }}<p-sortIcon field="creationUser"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="creationDate">
        <div class="p-d-flex p-jc-between p-ai-center">
          {{
            "SolicitudComponent.History.CreationDate"
              | translate
          }}<p-sortIcon field="creationDate"></p-sortIcon>
        </div>
      </th>
    </tr>
    <tr></tr>
  </ng-template>
  <ng-template pTemplate="body" let-object>
    <tr>
      <td>
        {{'Domain.Domain-'+object.statusDomainId | translate}}
        <!-- {{ object.statusDescription }} -->
      </td>
      <td>{{ object.creationUser }}</td>
      <td>
        {{ object.creationDate | date: "dd/MM/yyyy" }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">
        {{ "ElementsNotFound" | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>
<!-- PrimeNg Table -->
</p-dialog>
<p-toast></p-toast>
