import { IDetailFilterClientJson } from '../interfaces/details-filter-client-json.interface';


export class DetailFilterClientModel implements IDetailFilterClientJson {
  constructor(
  public type: string,
  public typeDescription: string,
  public content: string,
  ){}
}
