import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AnalysisImpactMessage {

    public static readonly CLIENTS_DESCRIPTION = 'Número y porcentaje de clientes ';
    public static readonly CLIENTS_DESCRIPTION_TRANSLATE = 'Number and percentage of clients';
    public static readonly VOLUME_DESCRIPTION = 'Número y porcentaje de volumen';
    public static readonly VOLUME_DESCRIPTION_TRANSALTE = 'Number and volume percentage';
    public static readonly INCOME_DESCRIPTION = 'Número y porcentaje de ingresos';
    public static readonly INCOME_DESCRIPTION_TRANSLATE = 'Number and percentage of income';
    public static readonly EBITDA_DESCRIPTION = 'Número y porcentaje de';
    public static readonly EBITDA_DESCRIPTION_TRANSLATE = 'Number and percentage of';
    public static readonly IMPACTPORCENTAGE_DESCRIPTION = 'Porcentaje de impacto';
    public static readonly IMPACTPORCENTAGE_DESCRIPTION_TRANSLATE = 'Impact percentage';

    public static readonly MESSAGE_WARNING_LACK_INFORMATION = `esta en ${(0)}% por favor configurela`;
    public static readonly MESSAGE_WARNING_LACK_ANALYSIS_INFORMATION = `Análisis no encontrado`;
    public static readonly MESSAGE_WARNING_NO_FOUND_CLIENTS = `Asignación de clientes no encontrado`;
    public static readonly MESSAGE_WARNING_NO_FOUND_ANALYSIS_INFORMATION = `Solicitud de análisis en proceso`;



    constructor() { }
}
