export class ApplicationConstants {
  static CONSTANTS = {
    languageKey: 'language',
    lifeTimeToast: 6000,
    paginationSize: 10,
    domainClient: '@postobon.com.co',
    initialPage: 1,
    loadingConfig: {
      imageUrl: 'assets/img/loading.gif',
      width: 250,
      imageWidth: 50,
      imageHeight: 50,
      text: 'Procesando la transacción...',
      animation: false,
      showConfirmButton: false,
    },
    listOperation: 'listOperation'
  };
}
