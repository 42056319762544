import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, untilDestroyed } from '@core';
import { I18nService } from '@app/i18n';
import { MonitoringService } from '@core/monitoring.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';

const log = new Logger('App');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit, OnDestroy {

  private readonly router: Router;
  private readonly activatedRoute: ActivatedRoute;
  private readonly titleService: Title;
  private readonly translateService: TranslateService;
  private readonly i18nService: I18nService;
  private readonly monitoringService: MonitoringService;
  private readonly msalService: MsalService;
  private readonly broadcastService: BroadcastService;

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    titleService: Title,
    translateService: TranslateService,
    i18nService: I18nService,
    monitoringService: MonitoringService,
    msalService: MsalService,
    broadcastService: BroadcastService)
  {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.titleService = titleService;
    this.translateService = translateService;
    this.i18nService = i18nService;
    this.monitoringService = monitoringService;
    this.msalService = msalService;
    this.broadcastService = broadcastService;
  }


  ngOnInit() {
    // Setup logger
    const sub = this.broadcastService.subscribe('msal:acquireTokenFailure', () => {
      sub.unsubscribe();
      this.msalService.loginRedirect();
    });
    if (environment.production) {
      Logger.enableProductionMode();
    }
    log.debug('init');
    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );

    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        tap((route) => this.logPageEvent(route)),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  private logPageEvent(route: ActivatedRoute) {
    const title = route.snapshot.data.title;
    this.monitoringService.logPageView(title, this.router.url);
  }
}
