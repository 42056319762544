import { CommercialActivityHandlerPeriodModel } from "@app/@components/elements/calendar-period/models/handler-period.model";
import { CommercialActivityHandlerModel } from "./commercial-activity-handler.model";
import { IAdvancedFiltersClient } from "@app/@shared/interfaces/advanced-filters-client.interface";

export class ActivityHandlerModel {
    constructor(
      public commercialActivityHandler?: CommercialActivityHandlerModel,
      public commercialActivityHandlerCurrentMonth?: CommercialActivityHandlerModel,
      public commercialActivityHandlerPeriod?: CommercialActivityHandlerPeriodModel[],
      public advacedFiltersClient?: IAdvancedFiltersClient,
    ) {}
  }