import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '@app/@shared/services/http.service';
import { IEliminateDifferential } from './interfaces/table-eliminate-differential.interfaces';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { environment } from '@env/environment';
import { IAdvancedFilter } from '@app/@components/shared-advanced-filter/interfaces/general-advanced-filter.interface';
import { StatusEliminateDifferentialModel } from './models/satus-eliminate-differential.models';
import { SolicitudeMaterialRequestModel } from '../models/solicitude-material-request.model';


@Injectable({
  providedIn: 'root'
})
export class RemoveDifferentialService {
  private readonly httpService: HttpService;
  private readonly HttpClient: HttpClient;

  constructor(
    httpService: HttpService,
    HttpClient: HttpClient
  ) { 
      this.httpService = httpService;
      this.HttpClient = HttpClient;
  }

  public getRemoveDifferential(body: IAdvancedFilter): Observable<IResponseService<IEliminateDifferential[]>> {
    const response$: Observable<IResponseService<IEliminateDifferential[]>> =
       this.httpService
       .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/get_differential_solicitude`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<IEliminateDifferential[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public putStatusChange(body: StatusEliminateDifferentialModel[]): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude_material/update_solicitude_material_differential_status`, 
          body)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public postSolicitudeExtDetail(
    body: SolicitudeMaterialRequestModel
  ): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
    this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/create_solicitude_differential_detail`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

}

