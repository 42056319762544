import { DataRangeModel } from './data-range.model';
import { ICommercialActivityFooter } from '../interfaces/commercial-activity-footer.interface';

export class CommercialActivityFooterModel implements ICommercialActivityFooter{
  constructor(
    public numberDays: number,
    public startTime: string,
    public endTime: string,
    public orderLimit: number,
    public periodLimit: number,
    public statusDomainId: number,
    public dataRanges: DataRangeModel[]
  ) {

  }
}