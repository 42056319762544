import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccessManagementConfigurationInfo } from './interfaces/access-management-configuration-info.interface';
import { IInfoAccessManagementConfiguration } from './interfaces/info-access-management-configuration.interface';
import { InfoAccessManagementConfigurationModel } from './models/info-access-management-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class AccessManagementConfigurationService {
  private readonly httpService: HttpService;

    constructor(httpService: HttpService) { 
      this.httpService = httpService;
    }

     /**
 * @description This method is to delete a AccessManagementConfigurations.
 * @author Priscy Antonio Reales
 * @param params
 * @returns Observable<ResponseService<boolean>>
 * @sprint 1
 */
   public deleteAccessManagementConfiguration(id: number): Observable<IResponseService<boolean>> {
    let response$: Observable<IResponseService<boolean>>;
    response$ = this.httpService.delete(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_management_configurations/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

    /**
     * @description This method is to get a AccessManagementConfigurations.
     * @author Priscy Antonio Reales
     * @param params
     * @returns Observable<ResponseService<IAccessManagementConfigurationInfo>>
     * @sprint 1
     */
      public getAccessManagementConfigurationInfo(id:  number): Observable<IResponseService<IAccessManagementConfigurationInfo>> {
        let response$: Observable<IResponseService<IAccessManagementConfigurationInfo>>;
        response$ = this.httpService
            .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/access_management_configuration_info/${id}`)
            .pipe(
                map((response: HttpResponse<IResponseService<IAccessManagementConfigurationInfo>>) => {
                return response.body;
                })
            );
        return response$;
      }

      /**
      * @description This method is to get a InfoAccessManagementConfiguration.
      * @author Jhon Steven Pavon Bedoya
      * @param params
      * @returns Observable<ResponseService<InfoAccessManagementConfigurationModel>>
      * @sprint 22
      */
      public getInfoAccessManagementConfiguration(accessManegementId: string) : Observable<IResponseService<InfoAccessManagementConfigurationModel[]>>{
        let response$: Observable<IResponseService<InfoAccessManagementConfigurationModel[]>>;

        response$ = this.httpService
                .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/info-access-management-configuration/${accessManegementId}`)
                .pipe(
                  map((response: HttpResponse<IResponseService<InfoAccessManagementConfigurationModel[]>>) => {
                    return response.body;
                  })
                );

          return response$;
      }


      public getInfoAccessManagementOrgChart(accessManegementId: string) : Observable<IResponseService<IInfoAccessManagementConfiguration>>{
        let response$: Observable<IResponseService<IInfoAccessManagementConfiguration>>;
        response$ = this.httpService
                .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/info-access-management-configuration/${accessManegementId}`)
                .pipe(
                  map((response: HttpResponse<IResponseService<IInfoAccessManagementConfiguration>>) => {
                    return response.body;
                  })
                );
          return response$;
      }



      /**
     * @description This method is to delete a AccessManagementConfigurations.
     * @author Jhon Steven Pavon Bedoya
     * @param params
     * @returns Observable<ResponseService<boolean>>
     * @sprint 1
     */
      public removePermissionAccessManagement(accessManegementId: string): Observable<IResponseService<boolean>> {
        let response$: Observable<IResponseService<boolean>>;
        response$ = this.httpService.get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/remove-permission-access-management/${accessManegementId}`)
          .pipe(
            map((response: HttpResponse<IResponseService<boolean>>) => {
              return response.body;
            })
          );
        return response$;
      }
}
