import { MeasurementUnit } from './../../../../@shared/enums/measurement-unit.enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FieldsCommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/fields-commercial-activity-footer.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { DomainService } from '@app/@shared/services/domain.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ManagePromotionalActivitiesService } from '../manage-promotional-activities.service';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { CommercialActivityFooterModel } from '@app/@components/commercial-activity-footer/models/commercial-activity-footer.model';
import { ICommercialActivityFooter } from '@app/@components/commercial-activity-footer/interfaces/commercial-activity-footer.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { RedeeableCommercialActivityModel } from './models/redeemable-commercial-activity.model';
import { RedeemableModel } from './models/redeemable.model';
import { CommercialActivityRedemptionService } from './commercial-activity-redemption.service';
import { IRedeemable } from './interfaces/redeemable.interfaces';
import { Util } from '@app/@shared/util';
import { IRedeemableFilterMaterials } from '@app/@components/advanced-redeemable-filter/interfaces/redeemable-filter-materials.interface';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { ManagePromotionalActivitiesMessage } from '../messages/manage-promotional-activities-messages.message';
import { IProductList } from '../commercial-activity-loyalty/interfaces/product-list.interface';
import { ApplicationConstants } from '@app/app.constants';
import { environment } from '@env/environment';
import { SweetAlertResult } from 'sweetalert2';
import { ITableGroupItem } from '@app/@components/tables-with-groups/interfaces/table-group-item.interface';
import { IHeaderTableGroupItem } from '@app/@components/tables-with-groups/interfaces/header-table-group-item.interface';
import { IProductGroupList } from '../commercial-activity-loyalty/interfaces/product-group-list.interface';
import { ProductGroupListModel } from '../commercial-activity-loyalty/models/product-group-list.model';
import { ISubGroupItem } from '@app/@components/tables-with-groups/interfaces/sub-group-item.interface';
import { SubLoyalty } from '@app/@components/tables-with-groups/models/subGroup/loyalty.model';
import { IChannel } from '../commercial-activity-loyalty/interfaces/channel-interface';
import { CommercialActivityRedemptionModel } from './models/commercial-activity-redemption.model';
import { ICommercialActivityRedemption } from './interfaces/commercial-activity-redemption.interface';
import { IDataRange } from '@app/@components/commercial-activity-footer/interfaces/data-range.interface';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { ICommercialActivitySector } from '../interfaces/commercial-activity-sector.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { PromotionalActivitiesCreateService } from '../promotional-activities-create/promotional-activities-create.service';
import { AccountingTypeEnum } from '@app/@shared/enums/accounting-type-enum';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { ICommercialActivityDocument } from '@app/@components/commercial-activity-document/interfaces/commercial-activity-document.interface';
import { CommercialActivityDocumentComponent } from '@app/@components/commercial-activity-document/commercial-activity-document.component';
import { CommercialActivityDocumentRequiredModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document-required.model';
import { CommercialActivityDocumentModel } from '@app/@components/commercial-activity-document/models/commercial-activity-document.model';

@Component({
  selector: 'app-commercial-activity-redemption',
  templateUrl: './commercial-activity-redemption.component.html',
  styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
    .container-table.header{
      padding-left: 29px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #009CDD;
      font-weight: 700;
      font-size: 19px;
      margin-bottom:50px;
    }
    .container-table.options{
      padding-left: 29px;
      font-weight: 400;
      font-size: 14px;
    }
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
  ],
})
export class CommercialActivityRedemptionComponent implements OnInit {

  @ViewChild('Canjeables') Canjeables: any;
  @ViewChild('Redimibles') Redimibles: any;
  @ViewChild('documentSupport') documentSupport: CommercialActivityDocumentComponent;

  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly spinner: NgxSpinnerService;
  private readonly coreService: CoreMasterService;
  private readonly domainService: DomainService;
  private readonly fb: FormBuilder;
  private readonly router: Router;
  private readonly messageService: MessageService;
  private readonly redemptionService: CommercialActivityRedemptionService;
	private readonly parameterService: ManageParametersService;
  private readonly promotionalActivityService: PromotionalActivitiesCreateService;

  //#region filter redemtion component
  public objectRedeemableCommercialActivitySelected: RedeeableCommercialActivityModel[] = [];
  //#endregion

  //#region Params Component

  public routeCancelCreatePromotion = '/auth/manage-promotional-activities';
  public showFieldsCreatePromotion: FieldsCommercialActivityFooterModel;
  public showButtonsCreatePromotion = true;
  public promotionEdit: CommercialActivityFooterModel;
  public promotionEditFooter: CommercialActivityFooterModel;
  public objectChannelList: ICombo[];
  public objectProcessList: ICombo[];
  public objectTypeRedeemableList: ICombo[];
  public isProcess: number;
  public contFecha = 0;
  public editFiltersObjectList: IRedeemableFilterMaterials[] = [];
  public isMultiFilter = true;
  public formCommercialRedemption: FormGroup;
  public typeCommercialActivityId: number;
  public objectTypeCommercialActivity: any[] = [];
  public showTableRedemption = true;
  public objectRedeemableCommercialActivity: RedeeableCommercialActivityModel[] = [];
  public objectRedeemableSelect: ICombo[] = [];
  public objectRedeemable: RedeemableModel = new RedeemableModel;
  public objectRedeemableList: ICombo[];
  public clonedRedeemableCommercityActivity: { [s: number]: RedeeableCommercialActivityModel; } = {};
  public showAccumulatedWeightValue = false;
  public showTable = true;
  public valueTable: ITableGroupItem[] = [];
  public productGroupFilterListPromotion: IProductGroupList[];
  public filtersObjectList: IRedeemableFilterMaterials[] = [];
  public filtersObjectDataList: IRedeemableFilterMaterials[] = [];
  public viewCanjeables = true;
  public viewRedimibles = false;
  public filterRedeemable = '';
  public accumulatedValue = 0;
  public commercialActivityId = 0;
  public redemptionActivityEdit: CommercialActivityRedemptionModel;
  public commercialActStatVal: number;
  public cantidadElementos: number;
	public objectSectorList: ICombo[];
	public objectSectorSelect: ICombo[] = [];
	public sectorCommercialActivityParam = false;
  public tittleCreatePromotion = 'Crear Redención';
  public commercialActivityStatusEnum: any  = CommercialActivityStatus;
	public documentInfo: CommercialActivityDocumentModel[];
	public flagDocumentObservation: boolean = true;
	public documentFieldsRequired: CommercialActivityDocumentRequiredModel;
	public observationEdit: CommercialActivityDocumentModel[];
  public documentValid: boolean = true;
  //#endregion

  //#region Params Detail Component
  public viewDetailComponent = true;
  public viewDetailSelectedFilter = false;
  public groupSelected = '';
  public productsSelected: IProductList[] = [];
  public auxProductsSelected: string[] = [];
  public validateProductsRedimeeable = 0;
  //#endregion
  public commercialActivityEnum = ComercialActivityEnum;
  public channelTooltip: string[];

  public headersTable: IHeaderTableGroupItem[] = [
    {
      name: 'Grupo',
      attr: 'descGroup'
    },
    {
      name: 'Contenido',
      attr: 'descGroup'
    }
  ];

  public get comercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }
  public rolePermission: any = RolePermissionEnum;
  public sectorTooltip: string[];
  public checkDelete = false;

  constructor(
    managePromotionActService: ManagePromotionalActivitiesService,
    spinner: NgxSpinnerService,
    coreService: CoreMasterService,
    domainService: DomainService,
    fb: FormBuilder,
    router: Router,
    messageService: MessageService,
    redemptionService: CommercialActivityRedemptionService,
		parameterService: ManageParametersService,
    promotionalActivityService: PromotionalActivitiesCreateService,
  ) { 
      this.managePromotionActService = managePromotionActService;
      this.spinner = spinner;
      this.coreService = coreService;
      this.domainService = domainService;
      this.fb = fb;
      this.router = router;
      this.messageService = messageService;
      this.redemptionService = redemptionService;
		  this.parameterService = parameterService;
      this.promotionalActivityService = promotionalActivityService;
  }

  ngOnInit(): void {
    this.showFieldsCreatePromotion = new FieldsCommercialActivityFooterModel(true, true, true, true, true, true, true, true);
    this.isProcess = this.managePromotionActService.getIsCreate();
    this.initForm();
    this.forkJoinInitialData();
  }

  /**
* @description this method execute async requests.
* @author Daniel londono
* @sprint 16
*/
  forkJoinInitialData(): void {
    this.spinner.show();

    const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
    const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);
    const typeRedeemableResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.TypeRedeemable);
    const redeemableResponse = this.redemptionService.getAllRedeemables();
    const sectorResponse = this.coreService.getAll(TypeCoreMaster.Sector);
    const parametersResponse = this.parameterService.getAll();

    forkJoin([
      channelResponse,
      processResponse,
      typeRedeemableResponse,
      redeemableResponse,
      sectorResponse,
      parametersResponse,
    ])
      .subscribe(
        (result) => {

          const objChannelResponse: IResponseService<ICoreMaster[]> = result[0];
          const objProcessResponse: IResponseService<IDomain[]> = result[1];
          const objTypeRedeemableResponse: IResponseService<IDomain[]> = result[2];
          const objRedeemableResponse: IResponseService<IRedeemable[]> = result[3];
          const objSectorResponse: IResponseService<ICoreMaster[]> = result[4];
          const objParametersResponse: IResponseService<any[]> = result[5];

          this.objectChannelList = objChannelResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });

          this.objectProcessList = objProcessResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          this.objectTypeRedeemableList = objTypeRedeemableResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          this.objectRedeemableList = objRedeemableResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.id
            };
          });

          this.objectSectorList = objSectorResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim(),
              crossActive: item.crossActive
            };
          });

          const param = objParametersResponse.data.filter((prm) => prm.id === ManagementParameterEnum.sectorCommercialActivity);
          if (param) {
						this.sectorCommercialActivityParam = param[0].status;
						if (this.sectorCommercialActivityParam) {
							this.sector.setValidators([Validators.required]);
						}
          }

          this.objectTypeCommercialActivity = [...objProcessResponse.data];


          if (this.isProcess === this.comercialActivityEnum.update || this.isProcess === this.comercialActivityEnum.view) {
            this.initEditRedemption();
          } else {
            this.initCreateRedemption();
          }

          if (this.objectSectorList && this.objectChannelList) {
            const userFilters = this.managePromotionActService.setUserFilters(this.objectChannelList, this.objectSectorList);
            this.objectChannelList = userFilters.channels;
            this.objectSectorList = userFilters.sectors;
          }

        }, () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
    this.spinner.hide();
  }

  initEditRedemption() {
    this.typeCommercialActivityId = this.managePromotionActService.getTypeCommercialActivity();
    this.commercialActivityId = this.managePromotionActService.getCommercialActivityId();
    this.getRedemptionToEdit();
  }

  private async getRedemptionToEdit(): Promise<void> {
    this.spinner.show();
    this.redemptionService.getPromotionalActivityById(this.commercialActivityId).subscribe(
      async (response: any) => {
        if (response.status) {
          this.commercialActStatVal = response.data.statusDomainId;
          let commercialActivityChannelsLocal: IChannel[] = response.data.commercialActivityChannels;
					let CommercialActivitySectorLocal: ICommercialActivitySector[] = response.data.commercialActivitySector;

					if(this.redemptionActivityEdit && this.redemptionActivityEdit.commercialActivityChannels && this.redemptionActivityEdit.commercialActivitySector){
						commercialActivityChannelsLocal = this.redemptionActivityEdit.commercialActivityChannels;
						CommercialActivitySectorLocal = this.redemptionActivityEdit.commercialActivitySector;
					}

          this.redemptionActivityEdit = new CommercialActivityRedemptionModel(
            response.data.typeCommercialActivityDomainId,
            response.data.typePromotionalActivityDomainId,
            response.data.statusDomainId,
            response.data.description,
            response.data.startTime,
            response.data.endTime,
            response.data.orderLimit,
            response.data.periodLimit,
            response.data.numberDays,
            response.data.id,
            response.data.consideration,
            response.data.periodSettlement,
            response.data.typeMeasure,
            response.data.accumulatedValue,
            response.data.purchaseBases,
            [],
            this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges),
            commercialActivityChannelsLocal,
            response.data.commercialActivityRedeemable,
            response.data.CommercialActivitySector,
            response.data.commercialActivityObservation
          );
          this.channelTooltip = response.data.commercialActivityChannels;

          this.accumulatedValue = response.data.accumulatedValue;
          this.promotionEdit = new CommercialActivityFooterModel(
            this.redemptionActivityEdit.numberDays,
            this.redemptionActivityEdit.startTime,
            this.redemptionActivityEdit.endTime,
            this.redemptionActivityEdit.orderLimit,
            this.redemptionActivityEdit.periodLimit,
            this.redemptionActivityEdit.statusDomainId,
            this.prepareDataRangeCommercialActivityToEdit(response.data.commercialActivityDateRanges)
          );
          if (CommercialActivitySectorLocal) {
            this.sectorTooltip = response.data.commercialActivitySector;
            const sct = CommercialActivitySectorLocal.map((sctr: ICommercialActivitySector) => {
              return sctr.sectorCode;
            });
            this.sector.setValue(sct);
          }
          this.prepareDataEditActivityRedemption(this.redemptionActivityEdit);

          if (response.data.commercialActivityObservation && this.flagDocumentObservation)
          {
            this.observationEdit = response.data.commercialActivityObservation;
          }

          let typeCommercialActivityId = this.redemptionActivityEdit.typeCommercialActivityDomainId;
          if (typeCommercialActivityId)
          {
            await this.documentSupport.setRequiredDocumentInformation(typeCommercialActivityId);
            this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
          }
 
          this.spinner.hide();
        }
      }, (error: any) => {
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, `${error}`);
      });
  }

  private prepareDataEditActivityRedemption(rappel: CommercialActivityRedemptionModel): void {
    this.description.setValue(rappel.description);
    this.typeCommercialActivity.setValue(this.findTypeCommercialActivityName(rappel.typeCommercialActivityDomainId));
    this.prepareFiltersToEditProductsRappelActivity(rappel);
    this.channel.setValue(this.prepareChannelsToShowEditPromotionalActivity(rappel.commercialActivityChannels));
    this.prepareRedeemableToEditRappelActivity(rappel);
    this.preparePurchaseBaseProductsToEditRedemptionActivity(rappel);
  }
  prepareRedeemableToEditRappelActivity(rappel: CommercialActivityRedemptionModel) {
    if (rappel.commercialActivityRedeemable) {
      this.objectRedeemableCommercialActivity = rappel.commercialActivityRedeemable.map((item: any) => {
        return {
          id: item.id,
          idTypeRedeemable: item.idTypeRedeemable,
          materialCode: item.materialCode,
          descriptionRedeemable: item.descriptionRedeemable,
          amount: item.amount,
          individualPesoValue: item.individualPesoValue
        };
      });
      this.objectRedeemableCommercialActivitySelected = [...this.objectRedeemableCommercialActivity];
      this.validateAccumulatedValue();
    }
  }

  private preparePurchaseBaseProductsToEditRedemptionActivity(rappel: CommercialActivityRedemptionModel): void {
    if (rappel.purchaseBases) {
      const rex = /Grupo [0-9]+/g;
      this.productGroupFilterListPromotion = rappel.purchaseBases.map((group: any, index: number) => {
        return {
          groupName: `Grupo ${(index + 1)}`,//JSON.parse(group.groupByJson).group,//`Grupo ${(index + 1)}`,
          cgroupName: `Grupo ${(index + 1)}`,//JSON.parse(group.groupByJson).group,//`Grupo ${(index + 1)}`,
          commercialActivityId: group.commercialActivityId,
          purchaseUnit: group.purchaseUnit,
          measurementUnitCode: group.purchaseBaseDetails ? group.purchaseBaseDetails[0].measurementUnitCode : '',
          isGroup: true,
          groupByJson: group.groupByJson.replace(rex,`Grupo ${(index + 1)}`),
          id: group.id,
          products: this.prepareProductsToEditPromotionalActivity(group)
        };
      });
    }
  }

  private prepareProductsToEditPromotionalActivity(group: any): IProductList[] {
    let products: IProductList[] = [];
    if (group.purchaseBaseDetails) {
      products = group.purchaseBaseDetails.map((product: any) => {
        const productRet = { ...product };
        productRet.product = product.materialDescription;
        productRet.hierarchy = JSON.parse(group.groupByJson).hierarchyFilter;
        productRet.additionalValueCheck = product.additionalValue > 0;
        productRet.additionalValue = product.additionalValue;
        return productRet;
      });
    }
    this.productsSelected = [...products];
    return products;
  }

  private prepareChannelsToShowEditPromotionalActivity(channels: IChannel[]): string[] {
    if (channels) {
      return channels.map((ch) => {
        return `${ch.channelCode}`;
      });
    }
    return [];
  }

  public prepareFooterForEvent($event : ICommercialActivityFooter){
    this.promotionEditFooter = new CommercialActivityFooterModel(
      $event.numberDays,
      $event.startTime,
      $event.endTime,
      $event.orderLimit,
      $event.periodLimit,
      $event.statusDomainId,
      $event.dataRanges
    );
  }

  private prepareFiltersToEditProductsRappelActivity(rappel: CommercialActivityRedemptionModel): void {
    if (rappel.purchaseBases) {
      const filters: IRedeemableFilterMaterials[] = rappel.purchaseBases.map((element, index: number) => {
        const group = JSON.parse(element.groupByJson);
        return {
          hierarchyFilter : group.hierarchyFilter,
          materialCode : group.materialCode,
          id : `${element.id}`,
          group: `Grupo ${index + 1}`,//group : group.group,
          dataHierarchy : '',
          type : group.type
        };
      });
      this.editFiltersObjectList = [...filters];
      this.filtersObjectList = [...filters];
    }
  }


  private prepareDataRangeCommercialActivityToEdit(dataRange: ICommercialActivityRedemption[]): IDataRange[] {
    let dataRangeRet: IDataRange[] = [];
    if (dataRange) {
      if(dataRange.length > 0){
        dataRangeRet = dataRange.map((data: any) => {
          const start = new Date(data.dateStart);
          const end = new Date(data.dateEnd);
          return {
            dateStart: data.dateStart,
            dateEnd: data.dateEnd,
            cantDays: Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)),
            id: data.id,
            commercialActivityId: data.commercialActivityId
          };
        });
			}
    }
    return dataRangeRet;
  }

  async initCreateRedemption() {
    const descriptionIn = this.managePromotionActService.getDescription();
    if (descriptionIn !== undefined && descriptionIn !== null && descriptionIn !== '') {
      this.description.setValue(descriptionIn);
      this.typeCommercialActivityId = this.managePromotionActService.getTypeCommercialActivity();
      this.typeCommercialActivity.setValue(this.findTypeCommercialActivityName(this.typeCommercialActivityId));

			if (this.typeCommercialActivityId)
			{
				await this.documentSupport.setRequiredDocumentInformation(this.typeCommercialActivityId);
				this.documentFieldsRequired = this.documentSupport.getRequiredDocumentInformation();
			}

    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'La descripción es requerida. Por favor intentelo de nuevo.'
        : 'Description is required. Please try again.');
      this.router.navigate(['auth/manage-promotional-activities']);
    }
  }
  /**
  * @description This method is to find the name of commercial activity selected
  * @param id
  * @author Daniel Londono
  * @sprint 16
  */
  private findTypeCommercialActivityName(id: number): string {
    const typeCommercialActivity = this.objectTypeCommercialActivity.find((type) => type.id === id);
    return typeCommercialActivity.description;
  }

  validateInformationModel(model: CommercialActivityRedemptionModel): boolean {
    if (model === undefined || model.purchaseBases === undefined ||
      (model.commercialActivityChannels === undefined && model.commercialActivityChannels.length === 0) ||
      model.commercialActivityDateRanges.length === 0 || model.commercialActivityRedeemable.length === 0) {
      return false;
    }
    return true;
  }

  eventClickSaveBtn($event: ICommercialActivityFooter): void {

		this.documentValid = this.validateDocuments();

    this.spinner.show();
    if (this.validateForm() && this.formCommercialRedemption.valid &&
      this.formCommercialRedemption.valid && this.validateAccumulatedValue()
        && this.validateProducts(this.productGroupFilterListPromotion) && this.validateGroupandProduct() && this.documentValid) {
      const toCreate = this.prepareCommercialActivityRedemptionToCreate($event);
			toCreate.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate(toCreate);
      const approvalCreate = this.validateInformationModel(toCreate);
      if (!approvalCreate) {
        this.spinner.hide();
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
          language === 'es-ES' ? 'Debe completar todos los datos del formulario.'
          : 'You must complete all the information on the form.');
        return;
      }
      this.redemptionService.postPromotionalActivity(toCreate).subscribe(
        (response) => {
          if (response.status) {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Success);
            this.router.navigate(['auth/manage-promotional-activities']);
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
          this.spinner.hide();
        }, (error: HttpErrorResponse) => {
          this.spinner.hide();
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? 'Debe completar todos los datos del formulario.'
            : 'You must complete all the information on the form.');
          return;
        });
    }
    else {
      Object.values(this.formCommercialRedemption.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsTouched();
        }
      });
    }
    this.spinner.hide();
  }

  validateForm() {
    if (this.formCommercialRedemption.valid) {
      return true;
    }
    const language: string = sessionStorage.getItem('language');
    SweetAlertMessageHelpers.warning(
      language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
      language === 'es-ES' ? SweetAlert.messageSectorandChannel : SweetAlert.messageSectorandChannelTranslate
    );
    this.formCommercialRedemption.markAllAsTouched();
    return false;
  }

  /**
   * @description This method is to prepare commercial activity sectors to create
   * @sprint 15
   * @author Diego Mauricio Cortes
   * @returns ICommercialActivitySector object list
   */
  private prepareCommercialActivitiesSectorsToCreate(toCreate: CommercialActivityRedemptionModel): ICommercialActivitySector[] {
    let rsp: ICommercialActivitySector[] = [];
		if (this.sectorCommercialActivityParam && this.sector.value) {
			rsp = this.sector.value.map((sct: string) => {
				return {
					id: 0,
					commercialActivityId: toCreate.id || 0,
					sectorCode: sct
				};
			});
		}
    return rsp;
  }

  /**
   * @description This method is to validate the selected sector combinations
   *
   * @sprint 17
   * @author Diego Mauricio Cortés
   */
  selectSectorValidate(): void {
		if (this.sector.value.length > 1)
		{
			this.sector.value.forEach((x:string) => {
				if (this.objectSectorList.find((y) => y.value === x && !y.crossActive))
				{
					const inx = this.sector.value.findIndex((z:string) => z === x)
					this.sector.value.splice(inx,1);
				}
			});
		}
  }

  prepareCommercialActivityRedemptionToCreate(footerData: ICommercialActivityFooter): CommercialActivityRedemptionModel {
    const promotionalActivityModel: CommercialActivityRedemptionModel = new CommercialActivityRedemptionModel(
      this.typeCommercialActivityId,
      null,
      footerData.statusDomainId,
      this.description.value,
      footerData.startTime,
      footerData.endTime,
      footerData.orderLimit,
      footerData.periodLimit,
      footerData.numberDays,
      0,
      null,
      null,
      null,
      this.accumulatedValue,
      this.transformDataCommercialProductRedemption(this.productGroupFilterListPromotion),
      [],
      footerData.dataRanges,
      this.prepareChannelsToSave(),
      this.objectRedeemableCommercialActivity
    );
    return promotionalActivityModel;
  }

  private transformDataCommercialProductRedemption(products: IProductGroupList[]) {

    if (products === undefined || products.length === 0) {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'Debe agregar productos redimibles.'
        : 'You must add redeemable products.');
      return;
    }

    let newProduct: IProductGroupList[] = [];
    newProduct = products.map((product) => {
      return {
        'groupName': product.groupName,
        'commercialActivityId': 0,
        'purchaseUnit': product.purchaseUnit,
        'measurementUnitCode': product.measurementUnitCode,
        'isGroup': true,
        'groupByJson': product.groupByJson,
        'id': product.id,
        'purchaseBaseDetails': this.transformDataPurchaseBase(product.products, product)
      };
    });

    return newProduct;
  }

  private transformDataPurchaseBase(products: IProductList[], product: IProductGroupList): Array<IProductList> {
    let newSubOptions: IProductList[] = [];

    newSubOptions = products.map((subOption) => {
      return {
        'purchaseBaseId': product.id,
        'materialCode': subOption.materialCode,
        'measurementUnitCode': MeasurementUnit.PIECE,
        'purchaseUnit': subOption.purchaseUnit,
        'porcent': 0,
        'discountValue': subOption.discountValue,
        'additionalValue': subOption.additionalValue,
        'id': subOption.id,
        'accountingTypeDomainId' : subOption.accountingTypeDomainId
      };
    });

    return newSubOptions;
  }

  private getExtraOptionsValue(subOption: ISubGroupItem): { purcheseUnit: number, discountValue: number } {
    const extraOptionValue = {
      purcheseUnit: 0,
      discountValue: 0
    };

    if (subOption instanceof SubLoyalty) {
      extraOptionValue.purcheseUnit = subOption.purcheseUnit != null ? subOption.purcheseUnit : 0;
    }
    return extraOptionValue;
  }

  private prepareChannelsToSave(): IChannel[] {
    if (this.channel.value) {
      const channels = this.channel.value.map((chl: number) => {
        return {
          commercialActivityId: 0,
          channelCode: chl
        };
      });
      return channels;
    }
  }

	private prepareSectorsToSave(): ICommercialActivitySector[] {
		if (this.sector.value) {
			const sectors: ICommercialActivitySector[] = this.sector.value.map((sct: string) => {
				return {
					id: 0,
					commercialActivityId: this.commercialActivityId || 0,
					sectorCode: sct
				};
			});
			return sectors;
		}
	}

  private prepareCommercialActivityRedemptionToEdit(footerData: ICommercialActivityFooter): CommercialActivityRedemptionModel {
		const promotionalActivityModel: CommercialActivityRedemptionModel = new CommercialActivityRedemptionModel(
			this.typeCommercialActivityId,
			null,
			footerData.statusDomainId,
			this.description.value,
			footerData.startTime,
			footerData.endTime,
			footerData.orderLimit,
			footerData.periodLimit,
			footerData.numberDays,
			this.commercialActivityId,
      null,
      null,
      null,
      this.accumulatedValue,
      this.transformDataCommercialProductRedemption(this.productGroupFilterListPromotion),
			[],
			footerData.dataRanges,
      this.prepareChannelsToSave(),
      this.objectRedeemableCommercialActivity
		);
		return promotionalActivityModel;
	}

  eventDataFilters($event : any){
    this.filtersObjectDataList = $event;
  }

  eventFilters($event: any) {
    this.groupSelected = '';
    this.cantidadElementos = 0;
    const productlistLocal: IProductList[] = [];
    this.productsSelected = productlistLocal;

    const groups: IProductGroupList[] = [];
    this.filtersObjectList = $event.advancedFiltersObjectList;
    const groupProductOld = this.productGroupFilterListPromotion;

    this.filtersObjectList.forEach((x) => {
      let productlist: IProductList[] = [];
      let hierarchyFilterOld : ProductGroupListModel;
      let productOld : IProductList;
      if(groupProductOld){
        hierarchyFilterOld = groupProductOld.find((h) => JSON.parse(h.groupByJson).hierarchyFilter === x.hierarchyFilter);
      }

      if (x.type) {
        if(hierarchyFilterOld){
          productOld = hierarchyFilterOld.products.find((p) => p.materialCode === x.materialCode);
        }
        if(productOld){
          productlist.push(productOld);
        }else{
          productlist = [{
            'product': x.hierarchyFilter,
            'hierarchy': x.hierarchyFilter,
            'purchaseBaseId': 0,
            'materialCode': x.materialCode,
            'measurementUnitCode': MeasurementUnit.PERCENT,
            'purchaseUnit': 0,
            'porcent': 0,
            'discountValue': 0,
            'id': 0,
            'currentPsp': 0,
            'additionalValueCheck': false,
            'additionalValue': 0,
            'accountingTypeDomainId' : AccountingTypeEnum.Spending
          }];
        }
        this.cantidadElementos++;
      }
      if(hierarchyFilterOld){
        groups.push(hierarchyFilterOld);
      }else{
        let newGroup: ProductGroupListModel = null;
        newGroup = new ProductGroupListModel(
          x.group, // groupName
          0, // comercialActivityId
          0, // purchaseUnit
          MeasurementUnit.PERCENT, // measurementUnitCode
          true, // isGroup,
          JSON.stringify(x), // groupByJson
          0, // id
          productlist, // producst
          x.type
        );
        groups.push(newGroup);
      }
    });

		let advancedFiltersObjectListSelected: IRedeemableFilterMaterials[] = [];
		if (this.filtersObjectList) {
			advancedFiltersObjectListSelected = this.filtersObjectList.filter((filter: IRedeemableFilterMaterials) => {
				if (!filter.type) {
					return {
						hierarchyFilter: filter.hierarchyFilter
					};
				}
			});
		}

    if (advancedFiltersObjectListSelected[0] !== undefined && advancedFiltersObjectListSelected.length > 0) {
      const newFilterModel = new FilterDataModel(
        JSON.stringify(advancedFiltersObjectListSelected),
        environment.conditionTable,
        environment.conditionClassCode,
        false
      );

      if(newFilterModel){
        this.getMaterialsByFilters(newFilterModel, groups);
      }else{
        const language: string = sessionStorage.getItem('language');
        this.showToast(SweetAlert.iconSuccess,
          language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
          language === 'es-ES' ? SweetAlert.mesageRedemptionTotal +`${this.cantidadElementos}`: SweetAlert.mesageRedemptionTotalTranslate +`${this.cantidadElementos}`);
      }
    } else {
      const language: string = sessionStorage.getItem('language');
      this.showToast(SweetAlert.iconSuccess,
        language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
        language === 'es-ES' ? SweetAlert.mesageRedemptionTotal +`${this.cantidadElementos}`: SweetAlert.mesageRedemptionTotalTranslate +`${this.cantidadElementos}`);
        this.productGroupFilterListPromotion = groups;
    }
  }


  /**
  * @description This method for get the filters.
  *
  * @author Diego Mauricio Cortés
  * @sprint 8
  * @param filters
  */
  private getMaterialsByFilters(filters: FilterDataModel, group: ProductGroupListModel[]): void {
    this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
      (response) => {
        if (response.data.length > 0) {
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' 
              ? SweetAlert.mesageRedemptionTotal +`${response.data.length + this.cantidadElementos}`: SweetAlert.mesageRedemptionTotalTranslate +`${response.data.length + this.cantidadElementos}`);
          const listProducts: IProductList[] = response.data.map((item: any) => {
            return {
              product: item.product,
              hierarchy: item.hierarchy,
              purchaseBaseId: 0,
              materialCode: item.materialCode,
              measurementUnitCode: MeasurementUnit.PERCENT,
              purchaseUnit: 0,
              porcent: 0,
              discountValue: 0,
              id: 0,//this.comercialActivityEnum.create ? item.id : 0,
              currentPsp: item.currentPsp,
              additionalValueCheck: false /*item.additionalValue > 0 ? true : false*/,
              additionalValue: 0,
              accountingTypeDomainId : AccountingTypeEnum.Replacement,
              zofeCode : item.zofeCode,
              zofeDescription : item.zofeDescription
            };
          });

          this.productGroupFilterListPromotion = this.groupProductsToShow(listProducts, group);

        } else {
          const filter = JSON.parse(filters.filters);
          const filtered = filter.reduce((a : any, o : any) => (o.hierarchyFilter && a.push(o.hierarchyFilter), a), []);
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' 
              ? `${ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT} ${filtered}` :  `${ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE} ${filtered}`);
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  /**
 * @description This method is to group by hierarchy the purchase base
 * and promotional products list
 *
 * @param listProducts
 * @author Diego Mauricio Cortés
 * @sprint 9
 */
  private groupProductsToShow(listProducts: IProductList[], group: ProductGroupListModel[]): IProductGroupList[] {
    let groups: IProductGroupList[] = [];
    const groupProductOld = this.productGroupFilterListPromotion;

    listProducts.forEach((product: IProductList) => {
      const hierarchyFilter = group.find((h) => JSON.parse(h.groupByJson).hierarchyFilter === product.hierarchy);
      if(hierarchyFilter){
        let hierarchyFilterOld : ProductGroupListModel;
        let productOld : IProductList;

        if(groupProductOld){
          hierarchyFilterOld = groupProductOld.find((h) => JSON.parse(h.groupByJson).hierarchyFilter === product.hierarchy);
          if(hierarchyFilterOld){
            productOld = hierarchyFilterOld.products.find((p) => p.materialCode === product.materialCode);
          }
        }

        const localProduct = listProducts.find((p) => p.hierarchy === JSON.parse(hierarchyFilter.groupByJson).hierarchyFilter
          && p.materialCode === product.materialCode);
        group.forEach((r) => {
          if (JSON.parse(r.groupByJson).hierarchyFilter === product.hierarchy) {
            if(!productOld){
              r.products.push(localProduct);
            }
          }
        });
      }
    });

    groups = group;
    return groups;
  }

  public infoDetail(object: IProductGroupList, process: number) {
    if(this.productGroupFilterListPromotion === undefined ||
        this.productGroupFilterListPromotion.length === 0){
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconError,
            language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
            language === 'es-ES' ? SweetAlert.mesageRedemption : SweetAlert.mesageRedemptionTranslate);
      return;
    }
    const infoGroup = this.productGroupFilterListPromotion.find((f) => f.groupName === object['group'] &&
      JSON.parse(f.groupByJson).hierarchyFilter === object['hierarchyFilter']);

    if(!infoGroup || infoGroup === undefined){
      this.viewDetailSelectedFilter = false;
      return;
    }

    if (infoGroup.products !== undefined && infoGroup.products.length > 0) {
      this.groupSelected = infoGroup.groupName;

      infoGroup.products.forEach((x) => x.typeElement = JSON.parse(infoGroup.groupByJson).type);

      this.productsSelected = infoGroup.products;
      this.checkDelete = false;
      this.productsSelected.forEach((x) => {x.check = false;});
      this.viewDetailSelectedFilter = true;
    } else {
      this.viewDetailSelectedFilter = false;
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.warning(
        language === 'es-ES' ? 'Ten cuidado!' : 'Be careful!',
        language === 'es-ES' ? 'No hay productos, elimime el grupo o vuelva a ejecutar los filtros'
        : 'There are no products, please delete the group or run the filters again');
    }


  }

  public async eventDeleteFilters($event : IRedeemableFilterMaterials []) {
    $event.forEach(async (i) => await this.eventDeleteFilter(i));
  }

  public async eventDeleteFilter($event : IRedeemableFilterMaterials ) {
    const filter = this.filtersObjectList.find((x) => x.hierarchyFilter === $event.hierarchyFilter);
		if (filter) {
			if(parseInt(filter.id, 10) !== 0 && this.isProcess === ComercialActivityEnum.update){
				await this.promotionalActivityService.deleteProductGroupsList(filter.id).toPromise();
			}
		}
    this.filtersObjectList = this.filtersObjectList.filter((x) => x.hierarchyFilter !== $event.hierarchyFilter);
		if (this.productGroupFilterListPromotion) {
			this.productGroupFilterListPromotion = this.productGroupFilterListPromotion.filter((x) => JSON.parse(x.groupByJson).hierarchyFilter !== $event.hierarchyFilter);
			this.orderNumberGroup();
		}
  }

  private orderNumberGroup(){
    this.filtersObjectList.forEach((item : IRedeemableFilterMaterials, index : number) =>{
        item.group = `Grupo ${(index + 1)}`;
    });

    const rex = /Grupo [0-9]+/g;
    this.productGroupFilterListPromotion.forEach((item: IProductGroupList, index: number) => {
        item.groupName = `Grupo ${(index + 1)}`;
        item.groupByJson = item.groupByJson.replace(rex,`Grupo ${(index + 1)}`);
    });
  }

  public async deleteGroupFilter(rowIndex: number, objectValue: IRedeemableFilterMaterials) {
    this.filtersObjectList = this.filtersObjectList.filter((x) => x.group !== objectValue.group);
    this.productGroupFilterListPromotion = this.productGroupFilterListPromotion.filter((x) => x.groupName !== objectValue.group);

    this.groupSelected = this.groupSelected === objectValue.group ? '' : this.groupSelected;
    this.productsSelected = this.productsSelected.filter((x) => x.hierarchy !== objectValue.hierarchyFilter);

    if(parseInt(objectValue.id, 10) !== 0 && this.isProcess === ComercialActivityEnum.update){
      await this.promotionalActivityService.deleteProductGroupsList(objectValue.id).toPromise();
    }

  }

  public async deleteDetail(rowIndex: number, objectValue: IProductList) {
    this.productsSelected = this.productsSelected.filter((x) => x.materialCode !== objectValue.materialCode);
    if(objectValue.id !== 0){
      await this.promotionalActivityService.deleteProductList(`${objectValue.id}`).toPromise();
    }
    this.productGroupFilterListPromotion.filter((x) => {
      if (JSON.parse(x.groupByJson).hierarchyFilter === objectValue.hierarchy) {
        x.products = [...x.products.filter((y) => y.materialCode !== objectValue.materialCode)];
      }
    });
    if (this.productsSelected === undefined || this.productsSelected.length === 0) {
      this.viewDetailSelectedFilter = false;
      this.editFiltersObjectList = [...this.filtersObjectList];
      if(objectValue.purchaseBaseId !== 0){
        await this.promotionalActivityService.deleteProductGroupsList(`${objectValue.purchaseBaseId}`).toPromise();
      }
      this.promotionEdit = {...this.promotionEdit}; //componente de fechas
      this.filtersObjectList = [...this.filtersObjectList.filter((x) => x.hierarchyFilter !== objectValue.hierarchy )];
      this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion.filter((x) => JSON.parse(x.groupByJson).hierarchyFilter !== objectValue.hierarchy)];

    }
  }

  public closeSelectedProduct() {
    this.contFecha += 1;

    if(this.isProcess !== this.comercialActivityEnum.view){

      const listErrorMessage : string [] = [];
      const language: string = sessionStorage.getItem('language');

      this.productsSelected.forEach((x) => {
        let errorMessage: string;
        if(x.purchaseUnit === 0){
          errorMessage = language === 'es-ES' ? `Debe ingresar la cantidad para ${x.product}.` :`You must enter the amount to ${x.product}.`;
          listErrorMessage.push(errorMessage);
        }

        if(x.additionalValueCheck && (!x.additionalValue || x.additionalValue === 0)){
          errorMessage = language === 'es-ES' ? `El Redimible ${x.product} no tiene precio de lista creado` : `The Redeemable ${x.product} has no list price created`;
          listErrorMessage.push(errorMessage);
        }

        if(x.typeElement && x.additionalValueCheck){
          x.accountingTypeDomainId = AccountingTypeEnum.Sale;
        }else if(x.typeElement && !x.additionalValueCheck){
          x.accountingTypeDomainId = AccountingTypeEnum.Spending;
        }else if(!x.typeElement && !x.additionalValueCheck){
          x.accountingTypeDomainId = AccountingTypeEnum.Replacement;
        }else{
          x.accountingTypeDomainId = null;
        }

      });

      if(listErrorMessage.length > 0){
        SweetAlertMessageHelpers.listWarningMessageShow(SweetAlert.titleAlertError, listErrorMessage);
        return;
      }
    }

    this.editFiltersObjectList = [...this.filtersObjectList];
    this.viewDetailSelectedFilter = false;

    if(this.isProcess === this.comercialActivityEnum.create){
      if(this.promotionEditFooter){
        this.promotionEdit = {...this.promotionEditFooter };
        }
    }else if(this.isProcess === this.comercialActivityEnum.update || this.isProcess === this.comercialActivityEnum.view){
      this.promotionEdit = {...this.promotionEdit };
    }

    this.productGroupFilterListPromotion = [...this.productGroupFilterListPromotion];
  }


  getValueProduct($event: any, object: IProductList) {
    if ($event.checked) {
      this.redemptionService.getImporteAmount('ZPLP', object.materialCode)
      .subscribe(
        (response) => {
          if (response.status && response.data > 0) {
            this.productsSelected.forEach((x) => {
              if(x.materialCode === object.materialCode){
                x.additionalValue = response.data;

                if(x.accountingTypeDomainId === AccountingTypeEnum.Spending){
                  x.accountingTypeDomainId = AccountingTypeEnum.Sale;
                }else{
                  x.accountingTypeDomainId = null;
                }
              }
            });
          } else {
            this.validateProductsRedimeeable += 1;
            object.additionalValueCheck = false;
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? `El Redimible ${object.product} no tiene precio de lista creado`
              : `The Redeemable ${object.product} has no list price created`);
          }
        });
    }else{
      this.validateProductsRedimeeable -= 0;
      this.productsSelected.forEach((x) => {
        if (x.materialCode === object.materialCode) {
          x.additionalValue = 0;

          if(x.accountingTypeDomainId === AccountingTypeEnum.Sale){
            x.accountingTypeDomainId = AccountingTypeEnum.Spending;
          }else{
            x.accountingTypeDomainId = AccountingTypeEnum.Replacement;
          }
        }
      });
    }
  }

  public assingValue(groupSelected: string, object: IProductList) {
    this.productGroupFilterListPromotion.forEach((x) => {
      if (JSON.parse(x.groupByJson) !== groupSelected) {
        x.products.forEach((y) => {
          if (y.id === object.id && y.hierarchy === object.hierarchy) {
            y = object;
          }
        });
      }
    });

  }

  eventClickEditBtn($event: ICommercialActivityFooter): void {
    this.documentValid = this.validateDocuments();

    if (this.validateForm() && this.formCommercialRedemption.valid  &&
      this.validateAccumulatedValue() && this.validateProducts(this.productGroupFilterListPromotion) && this.validateGroupandProduct()
      && this.validationTope($event.orderLimit, $event.periodLimit)  && this.documentValid) {
      const toEdit = this.prepareCommercialActivityRedemptionToEdit($event);
      this.redemptionActivityEdit.commercialActivityChannels = this.prepareChannelsToSave();
      this.redemptionActivityEdit.commercialActivitySector = this.prepareSectorsToSave();
      this.redemptionActivityEdit.commercialActivityObservation = this.documentInfo;
      toEdit.commercialActivitySector = this.prepareCommercialActivitiesSectorsToCreate(toEdit);
      this.spinner.show();
      this.redemptionService.putPromotionalActivity(toEdit, toEdit.id).subscribe(
        (response) => {
          if (response.status) {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
            this.router.navigate(['auth/manage-promotional-activities']);
          } else {
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
          this.spinner.hide();
        }, (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        });
    }
  }

  validationTope(order: number, period: number): boolean{
		if(order == 0 || period == 0){
			const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(
				language === 'es-ES' ? SweetAlert.titleWarning : SweetAlert.titleWarningTranslate ,
				language === 'es-ES' ? SweetAlert.messageTope : SweetAlert.mesageTopeTranslate);
			return false;
		}
		return true;
	}

  validateGroupandProduct(): boolean {
    let response = true;
    let aux;
    let errorMessage = '';
    const listMessageGroup: string[] = [];
    this.productGroupFilterListPromotion.forEach((x) => {
      aux = x.products.length;
      if(aux === 0){
        errorMessage = `El ${x.groupName} no tiene productos.`;
        listMessageGroup.push(errorMessage);
      }
    });

    if(listMessageGroup.length > 0){
      SweetAlertMessageHelpers.listWarningMessageShow('Ten cuidado!',listMessageGroup);
      response = false;
    }

    return response;
  }

  getIdRedeemable() {
    const maxMemoryId = Math.max(...this.objectRedeemableList.map((x) => x.value === undefined ? 0 : x.value), 0);
  }

  search(event: any) {
    const gg = this.objectRedeemableList.find((x) => x.label.toLocaleLowerCase().includes(event.query));
  }

  onFilterRedeemable($event: any) {
    const resultFilter = this.objectRedeemableList.filter((x) => x.label.includes($event.filter));
    if (resultFilter.length === 0) {
      this.filterRedeemable = $event.filter;
      $event.filter = null;
    }
  }

  onPanelHideRedeemable(redeemableCommercityActivity: RedeeableCommercialActivityModel) {
    if (this.filterRedeemable) {
      this.objectRedeemableList = null;
      //this.objectRedeemable.domainTypeId = redeemableCommercityActivity.idTypeRedeemable === undefined ? 0 : redeemableCommercityActivity.idTypeRedeemable;
      this.objectRedeemable.description = this.filterRedeemable;
      this.redemptionService.postRedeemable(this.objectRedeemable).subscribe(
        (response: any) => {
          if (response.status) {
            this.redemptionService.getAllRedeemables().subscribe(
              (response: any) => {
                if (response.data.length > 0) {
                  this.objectRedeemableList = response.data.map((item: any) => {
                    return {
                      label: item.description,
                      value: item.id
                    };
                  });
                }
                redeemableCommercityActivity.materialCode = this.objectRedeemableList.find((x) => x.label === this.filterRedeemable).value;
                redeemableCommercityActivity.descriptionRedeemable = this.objectRedeemableList.find((x) => x.label === this.filterRedeemable).label;
                this.filterRedeemable = null;
              }
            );
            this.showAccumulatedWeightValue = true;
            const language: string = sessionStorage.getItem('language');
              this.showToast(SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
          }
        }, (error: any) => {
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, `${error}`);
        });
    }
  }
  onChangeRedeemable(redeemableCommercityActivity: RedeeableCommercialActivityModel, index:number) {
    redeemableCommercityActivity.descriptionRedeemable= this.objectRedeemableList.find((x) => x.value === this.objectRedeemableSelect[index]).label;
    redeemableCommercityActivity.materialCode= this.objectRedeemableList.find((x) => x.value === this.objectRedeemableSelect[index]).value;
    this.filterRedeemable = null;
  }

  clearFilter($event: boolean) {
    if ($event) {
      this.objectRedeemableCommercialActivity = [];
      this.objectRedeemableCommercialActivitySelected = [];
    }
  }

  saveRedeemable(redeemableCommercityActivity: RedeeableCommercialActivityModel) {
    this.spinner.show();
    let countRedeemable = 0;

    if (!redeemableCommercityActivity.materialCode || redeemableCommercityActivity.materialCode === '') {
      const language: string = sessionStorage.getItem('language');
      this.showToast(SweetAlert.iconError,
        language === 'es-ES' ? SweetAlert.titleAlertError: SweetAlert.titleAlertErrorTranslate,
        language === 'es-ES' ? SweetAlert.messageAlertError: SweetAlert.messageAlertErrorTranslate);
      this.objectRedeemableCommercialActivity = this.objectRedeemableCommercialActivity.filter(x => x.id !== redeemableCommercityActivity.id);
      this.spinner.hide();
      return;
    }

    this.objectRedeemableCommercialActivity.forEach((x) => {
      if( x.individualPesoValue > 0){
        countRedeemable += 1;
      }
    });

    this.objectRedeemableCommercialActivitySelected = this.objectRedeemableCommercialActivity;
    this.showAccumulatedWeightValue = countRedeemable > 0 ? true : false;
    this.spinner.hide();
  }

  validateRedeemable() {
    const listMsnError: string[] = [];
    let countErrorAmount = 0;
    let countErrorIndividualValue = 0;

    this.objectRedeemableCommercialActivity.forEach((x) => {
      if (x.amount === 0) {
        countErrorAmount += 1;
        const msnError = `Debe ingresar la cantidad para ${x.descriptionRedeemable}.`;
        listMsnError.push(msnError);
      }

      if (x.individualPesoValue === 0) {
        countErrorIndividualValue += 1;
        const msnError = `Debe ingresar la valor pesos individual para ${x.descriptionRedeemable}.`;
        listMsnError.push(msnError);
      }
    });

    this.objectRedeemableCommercialActivitySelected = this.objectRedeemableCommercialActivity;

    if (countErrorIndividualValue > 0 || countErrorAmount > 0) {
      listMsnError.forEach((v) => {
        this.showToast(SweetAlert.iconError, SweetAlert.titleAlertError, v);
      });

      this.showAccumulatedWeightValue = false;
      return;
    } else {
      this.showAccumulatedWeightValue = true;
    }
  }

  private validateProducts(products: IProductGroupList[]): boolean {
    if(products === undefined || products.length === 0){
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'Debe agregar productos redimibles.'
        : 'You must add redeemable products.');
      return;
    }
    const listMsnError: string[] = [];
    const language: string = sessionStorage.getItem('language');
    products.forEach((x) => {
      x.products.forEach((i) => {
        if(i.purchaseUnit <= 0 || !i.purchaseUnit){
          const msnError =  language === 'es-ES' 
            ? `Debe ingresar la cantidad para ${i.product} en el ${x.groupName}.` : `You must enter the amount to ${i.product} in the ${x.groupName}.`;
          listMsnError.push(msnError);
        }
      });
    });
    if(listMsnError.length > 0){
      SweetAlertMessageHelpers.listWarningMessageShow(SweetAlert.titleAlertError, listMsnError);
      return false;
    }else{
      return true;
    }
  }

  validateAccumulatedValue(): boolean {
    const listMsnError: string[] = [];
    let countErrorIndividualValue = 0;
    let countErrorAmount = 0;
    let minIndividualValue = 99999999999;
    const language: string = sessionStorage.getItem('language');

    this.objectRedeemableCommercialActivity.forEach((x) => {
      if (x.amount === 0) {
        countErrorAmount += 1;
        const msnError = language === 'es-ES' ?  `Debe ingresar la cantidad para ${x.descriptionRedeemable}.` : `You must enter the amount to ${x.descriptionRedeemable}.`;
        listMsnError.push(msnError);
      }

      if(x.individualPesoValue > 0 ){
        this.showAccumulatedWeightValue = true;
        if(x.individualPesoValue < minIndividualValue){
          minIndividualValue = x.individualPesoValue;
        }
      }
    });

    if(this.accumulatedValue < minIndividualValue && minIndividualValue !== 99999999999){
      countErrorIndividualValue++;
      const msnError = language === 'es-ES' 
        ?  `El valor acumulado no debe ser menor a $${minIndividualValue}.` : `The accumulated value must not be less than $${minIndividualValue}.`;
      listMsnError.push(msnError);
    }

    if(countErrorIndividualValue > 0 || countErrorAmount > 0){
      SweetAlertMessageHelpers.listWarningMessageShow(SweetAlert.titleAlertError, listMsnError);
      return false;
    } else {
      return true;
    }
  }

  /**
   * @method this method add new product redemtion for list redemtions
   * @param $event
   * @author Jhon Steven Pavón Bedoya
   */
  newRecordRedeemable($event : RedeeableCommercialActivityModel[]) {
      this.objectRedeemableCommercialActivity = $event;
      this.objectRedeemableCommercialActivitySelected = this.objectRedeemableCommercialActivity;
  }

  onRowEditInit(redeemableCommercityActivity: RedeeableCommercialActivityModel) {
    this.clonedRedeemableCommercityActivity[redeemableCommercityActivity.materialCode] = { ...redeemableCommercityActivity };
  }

  onRowEditCancel(redeemableCommercityActivity: RedeeableCommercialActivityModel, index: number) {
    this.objectRedeemableCommercialActivity[index] = this.clonedRedeemableCommercityActivity[redeemableCommercityActivity.materialCode];
    delete this.clonedRedeemableCommercityActivity[redeemableCommercityActivity.materialCode];
    if (redeemableCommercityActivity.descriptionRedeemable === '') {
      this.objectRedeemableCommercialActivity = this.objectRedeemableCommercialActivity.filter((x) => x.materialCode !== redeemableCommercityActivity.materialCode);
      return;
    }
  }

  deleteRowRange(rowRedeemableCommercityActivity: RedeeableCommercialActivityModel): void {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        if(rowRedeemableCommercityActivity.id !== 0){
          this.redemptionService.deleteProductRedeemable(rowRedeemableCommercityActivity.id).toPromise();
        }
        this.objectRedeemableCommercialActivity = this.objectRedeemableCommercialActivity.filter((x) => x.materialCode !== rowRedeemableCommercityActivity.materialCode);
        if (this.objectRedeemableCommercialActivity.length === 0) {
          this.showAccumulatedWeightValue = false;
        }
      }
      //this.validateRedeemable();
      this.spinner.hide();
    });
  }

  public deleteAllAction() {
    Util.confirmDelete().then(async (result: SweetAlertResult) => {
      if (result.value) {
        const groupsToDelete = [...this.valueTable];
        groupsToDelete.forEach((group: any, index) => {
          const subOptions = [...group.subOptions];
          subOptions.forEach((detail) => {
            if (detail.check) {
              const indexFound = group.subOptions.findIndex((dt: any) => dt.id === detail.id);
              group.subOptions.splice(indexFound, 1);
            }
          });
          if (group.subOptions.length === 0) {
            this.deleteGroupAction(group);
          }
        });
      }
    });
  }

  public deleteGroupAction(groupToDelete: any) {
    Util.confirmDelete().then(async (result: SweetAlertResult) => {
      if (result.value) {
        const groupIndexFount = this.valueTable.findIndex((gr: any) => gr.contentGroup === groupToDelete.contentGroup);
        this.valueTable.splice(groupIndexFount, 1);
      }
    });

  }

  public toggleTable() {
    this.showTable = !this.showTable;
  }

  public viewPanel() {
    if (this.Redimibles._selected) {
      this.viewCanjeables = false;
      this.viewRedimibles = true;
    } else {
      this.viewCanjeables = true;
      this.viewRedimibles = false;
    }
		if (this.filtersObjectList.length > 0) {
			this.editFiltersObjectList = [...this.filtersObjectList];
			this.filtersObjectList = [...this.filtersObjectList];
		}
  }

  selectDeleteAll(checked: any) {
    this.productsSelected.forEach((element) => {
      element.check = checked;
    });
  }

  selectAux(){
    this.auxProductsSelected = [];
    this.productsSelected.forEach((elm) => {
      if(elm.check === true){
        this.auxProductsSelected.push(elm.materialCode);
      }
    });
  }

  selectRemoveAll(){
    const aux = this.productsSelected.length;
    let sum = 0;
    this.productsSelected.forEach((x) => {
      if(x.check === true){
        sum += 1;
      }
    });

    if(sum === aux){
      this.checkDelete = true;
    }else{
      this.checkDelete = false;
    }
  }

  deleteChecked(){
    this.selectAux();
    Util.confirmDelete().then(async (result: SweetAlertResult) => {
			if (result.value) {
        this.auxProductsSelected.forEach((elm) => {
            const indexProduct = this.productsSelected.findIndex((x) => x.materialCode === elm);
            this.productsSelected.splice(indexProduct, 1);
        });

        if(this.productsSelected.length === 0){
          this.productsSelected = [];
        }
      }
    });
  }

  /**
 * @description This method is to initialize the form.
 * @author Daniel Londono
 * @sprint 16
 */
  initForm(): void {
    this.formCommercialRedemption = this.fb.group({
      description: [{ value: '', disabled: true }, [Validators.required]],
      typeCommercialActivity: [{ value: '', disabled: true }, [Validators.required]],
      channel: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.view }, [Validators.required]],
      sector: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.view }, []]
    });
  }

  public showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  public eventClickSendValidateBtn(){}

  prepareCommercialActivitiesDocumentsToCreate() {
		this.documentInfo[0].commercialActivityId = this.commercialActivityId;
	}

	validateDocuments():boolean {
		let validDocument:boolean = true;
		this.documentInfo = this.documentSupport.getDocumentInformation();
		const language: string = sessionStorage.getItem('language');
	
    let fileName = this.documentInfo[0].file ?? this.documentSupport.getNameFile();

    if((this.documentFieldsRequired?.document && !fileName) && 
		(this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim()))
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_AND_FILE_TRANSLATE);
				validDocument = false;
			
			return;
		}
    
		if (this.documentFieldsRequired?.document && !fileName)
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_FILE_TRANSLATE);
				validDocument = false;
		}
	
		if (this.documentFieldsRequired?.observation && !this.documentInfo[0].message.trim())
		{
			SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
				language === 'es-ES' ? ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION
				: ManagePromotionalActivitiesMessage.NO_DOCUMENT_OBSERVATION_TRANSLATE);
				validDocument = false;
		}	
		return validDocument;
	}	


  get description(): AbstractControl { return this.formCommercialRedemption.get('description'); }
  get typeCommercialActivity(): AbstractControl { return this.formCommercialRedemption.get('typeCommercialActivity'); }
  get channel(): AbstractControl { return this.formCommercialRedemption.get('channel'); }
  get sector(): AbstractControl { return this.formCommercialRedemption.get('sector'); }
}
