import { IUpdateStatus } from '@app/@shared/interfaces/update-status.interface';
import { RulesImpactedDeleteModel } from '../pending-solicitude/pending-creation/impact-analysis/models/rules-impacted-delete.models';
import { RulesImpactedEditModel } from '../pending-solicitude/pending-creation/impact-analysis/models/rules-impacted-edit.models';

export class SolicitudeMaterialApprovalModel implements IUpdateStatus<number,boolean> {
    /**
   * @author Daniel Londono
   * @sprint 11
   * @description Model for update status
   */
  constructor(
    public id : number,
    public status: boolean,
    public commentary?: string,
    public materialcode? : string,
    public isPriceList?: boolean,
    public editRules?: RulesImpactedEditModel[],
    public deleteRules?: RulesImpactedDeleteModel[],
  ) {}
}
