<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"
            ><i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}</a
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "ApprovalRankComponent.Title" | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h1 class="pstntitle">
          {{ "ApprovalRankComponent.Title" | translate }}
        </h1>
      </div>
      <div class="col-md-4 row justify-content-end">
        <button
          id="btnCreateApprovalRank"
          class="pstn_primario"
          [routerLink]="'create-edit'"
          pButton
          pRipple
          type="button"
          label="{{ 'ApprovalRankComponent.CreateBtn' | translate }}"
          class="p-button-rounded"
          *ngIf="(rolePermission.create | rolePermission)">
        </button>
      </div>
    </div>
    <div class="mt-5"></div>
    <div class="pstn_container container-fluid">
      <p-table #dt
        [value]="objectList"
        dataKey="id"
        styleClass="p-datatable-customers"
        [paginator]="true"
        [rows]="10"
        [scrollable]="true"  
        paginatorPosition="bottom"
        currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
        [rowsPerPageOptions]="[10,25,50,100]"
        [globalFilterFields]="['id','description','countryDescription','societyDescription',
        'channelDescription','regionDescription','cediDescription',
        'processDescription','subprocessDescription','unitMeasurementDescription']">

        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search" aria-hidden="true"></i>
              <input pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="{{ 'SearchBy' | translate }}"/>
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th style="width:50px"></th>
            <th style="width:180px" pSortableColumn="description">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Description' | translate }}<p-sortIcon field="description"></p-sortIcon>
              </div>
            </th>
            <th style="width:150px" pSortableColumn="countryDescription">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Country' | translate }}<p-sortIcon field="countryDescription"></p-sortIcon>
              </div>
            </th>
            <th style="width:180px" pSortableColumn="societyDescription">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Society' | translate }}<p-sortIcon field="societyDescription"></p-sortIcon>
              </div>
            </th>
            <th style="width:150px" pSortableColumn="channelDescription">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Channel' | translate }}<p-sortIcon field="channelDescription"></p-sortIcon>
              </div>
            </th>
            <th style="width:150px" pSortableColumn="regionDescription">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Region' | translate }}<p-sortIcon field="regionDescription"></p-sortIcon>
              </div>
            </th>
            <th style="width:200px" pSortableColumn="cediDescription">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Cedi' | translate }}<p-sortIcon field="cediDescription"></p-sortIcon>
              </div>
            </th>
            <th style="width:150px" pSortableColumn="processDescription">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Process' | translate }}<p-sortIcon field="processDescription"></p-sortIcon>
              </div>
            </th>
            <th style="width:180px" pSortableColumn="subprocessDescription">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Subprocess' | translate }}<p-sortIcon field="subprocessDescription"></p-sortIcon>
              </div>
            </th>
            <th style="width:120px" pSortableColumn="status">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ 'ApprovalRankComponent.Status' | translate }}<p-sortIcon field="status"></p-sortIcon>
              </div>
            </th>
            <th style="width:150px" >{{ 'Actions' | translate }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body"
          let-object
          let-expanded="expanded">
          <tr [pSelectableRow]="object">
            <td style="width:50px">
              <button type="button"
                pButton
                pRipple
                [pRowToggler]="object"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
              </button>
            </td>
            <td style="width:180px">{{object.description}}</td>
            <td style="width:150px">{{object.countryDescription}}</td>
            <td style="width:180px">{{object.societyDescription}}</td>
            <td style="width:150px">{{object.channelDescription}}</td>
            <td style="width:150px">{{object.regionDescription}}</td>
            <td style="width:200px">{{object.cediDescription}}</td>
            <td style="width:150px">
              {{'Domain.Domain-'+object.processId | translate}}
              <!-- {{object.processDescription}} -->
            </td>
            <td style="width:180px">
              {{'Domain.Domain-'+object.subprocessId | translate}}
              <!-- {{object.subprocessDescription}} -->
            </td>
            <td style="width:120px">
              <p-inputSwitch id="switch-{{object.id}}"
                name="switch-{{object.id}}"
                [(ngModel)]="object.status"
                (onChange)="changeState($event,object)">
              </p-inputSwitch>
            </td>
            <td style="width:150px">
              <!-- <i id="btnEye" class="uil uil-eye ver" (click)="onSeeRecord(object)"></i> -->
              <i id="btnEdit" 
                class="uil uil-edit Editar" 
                aria-hidden="true" 
                (click)="onEdit(object)"
                *ngIf="(rolePermission.update | rolePermission)">
              </i>
              <i id="btnDelete" 
                class="uil uil-trash-alt eliminar" 
                aria-hidden="true" 
                (click)="onDelete(object)"
                *ngIf="(rolePermission.delete | rolePermission)"></i>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">{{ 'ElementsNotFound' | translate }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion"
         let-object>
          <tr>
            <td colspan="11">
              <div class="p-p-3">
                <p-table [value]="object.approvalRankDetailJson"
                  [scrollable]="true"
                  scrollHeight="300px"
                  dataKey="id">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="approvalLevel">
                        {{ 'ApprovalRankComponent.ApprovalLevel' | translate }} 
                        <p-sortIcon field="approvalLevel"></p-sortIcon>
                      </th>
                      <th></th>
                      <th pSortableColumn="rankStart">
                        {{ 'ApprovalRankComponent.RankStart' | translate }} 
                        <p-sortIcon field="rankStart"></p-sortIcon>
                      </th>
                      <th></th>
                      <th pSortableColumn="rankEnd">
                        {{ 'ApprovalRankComponent.RankEnd' | translate }} 
                        <p-sortIcon field="rankEnd"></p-sortIcon>
                      </th>
                      <th pSortableColumn="unitMeasurementDescription">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          {{ 'ApprovalRankComponent.UnitMeasurement' | translate }}
                          <p-sortIcon field="unitMeasurementDescription"></p-sortIcon>
                        </div>
                      </th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body"
                    let-approvalRankDetailJson>
                    <tr>
                      <td>{{approvalRankDetailJson.approvalLevel.split('-')[1] || ''}}</td>
                      <td>{{approvalRankDetailJson.operatorStart || ''}}</td>
                      <td>{{approvalRankDetailJson.rankStart || ''}}</td>
                      <td>{{approvalRankDetailJson.operatorEnd || ''}}</td>
                      <td>{{approvalRankDetailJson.rankEnd || ''}}</td>
                      <td>{{object.unitMeasurementDescription}}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6">{{ 'ElementsNotFound' | translate }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</section>
<p-toast></p-toast>
