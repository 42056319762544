import { IFilterClientInfo } from "../interfaces/filter-client-info.interface";

export class FilterClientInfoModel implements IFilterClientInfo {
    constructor(
    public jsonFilterClient: string,
    public listClient: string[],
    public totalClients: number,
    ){
        
    }
}