import 'moment/locale/es';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './@core/login/login.component';
import { PrimeNgModule } from './@shared/modules/prime-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalModule, MsalInterceptor, MsalGuard } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerComponent } from './@shared/ngx-spinner/ngx-spinner.component';
import { CoreModule, ErrorHandlerInterceptor } from './@core';
import { MainComponent } from './@core/main/main.component';
import { HeaderComponent } from './@core/layouts/header/header.component';
import { I18nModule } from './i18n';
import { SharedModule } from './@shared';
import { environment } from '@env/environment';
import { PricesModule } from './@modules/prices/prices.module';
import { NotificationPanelComponent } from './@modules/dashboard/notification-panel/notification-panel.component';
import { PromotionalactivityModule } from './@modules/promotionalactivity/promotionalactivity.module';
import { MomentModule } from 'ngx-moment';
import { NegotiationsModule } from './@modules/negotiations/negotiations.module';
import { BotDirective, BotHelperDirective, BotService, ComService, StyleSetDirective, NgxMicrosoftBotFrameworkModule } from 'ngx-microsoft-bot-framework';
import { SelectedActivityComponent } from './@modules/dashboard/selected-activity/selected-activity.component';
import { TextMaskModule } from 'angular2-text-mask';
import { DatePipe } from '@angular/common';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    HeaderComponent,
    NgxSpinnerComponent,
    NotificationPanelComponent,
    SelectedActivityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PrimeNgModule,
    CoreModule,
    PricesModule,
    PromotionalactivityModule,
    NegotiationsModule,
    FormsModule,
    TextMaskModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    I18nModule,
    SharedModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MomentModule,
    NgxMicrosoftBotFrameworkModule,
    MsalModule.forRoot(
      {
        auth: {
          clientId: environment.msalAuth.clientId,
          authority: environment.msalAuth.authority,
          redirectUri: environment.msalAuth.redirectUri,
          postLogoutRedirectUri: environment.msalAuth.postLogoutRedirectUri,
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
      },
      {
        popUp: !isIE,
        consentScopes: ['openid','profile'],
        protectedResourceMap: [
          [environment.apiUrlCore,[environment.msalAuth.ApiScope]], // api you host
          [environment.apiUrlSecurity,[environment.msalAuth.ApiScope]], // api you host
          [environment.apiUrlIntegration,[environment.msalAuth.ApiScope]], // api you host
          [environment.apiAnalytics,[environment.msalAuth.ApiScope]], // api you host
          [environment.msalAuth.protectedResourceMap, ['user.read']],
        ],
        extraQueryParameters: {},
      }
    ),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    NgxSpinnerModule,
    NgxSpinnerComponent,
  ],
  providers: [
    MsalGuard,BotService, ComService, BotDirective, BotHelperDirective, StyleSetDirective, DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
