<p-accordion>
	<p-accordionTab header="{{ 'ManagePromotionalActivitiesComponent.Calculate.Header' | translate }}" [selected]="false">
		<div class="col-md-12" *ngIf="statusDomainId === commercialActivityStateEnum.Validated">
			<button class="pstn_primario" (click)="sendToCalculate()" *ngIf="!isCalculate && activityUid === ''">{{'CalculateBtn' | translate}}</button>
			<button class="pstn_primario" (click)="validateState()" *ngIf="!isCalculate && activityUid != ''">{{'ValidateStateBtn' | translate}}</button>
			</div>

		<div class="mt-5"></div>

		<div class="col-md-12">
			<p-tabView>
				<p-tabPanel header="{{ 'ManagePromotionalActivitiesComponent.Calculate.Consolidated' | translate }}">
					<div class="row col-md-12">
						<p-card class="col-md-3 mt-3">
							<div class="d-flex justify-content-between">
								<div> 
									<h4 style="color:#009CDD; font-size:large;"><strong>{{totalClient}}</strong></h4>
									<p style="color:#6c757d; font-size:small;">
										{{ 'ManagePromotionalActivitiesComponent.Calculate.NumberOfAssignedClients' | translate }}</p>
								</div>
								<div><i class="uil uil-users-alt Indicators" 
										style="font-size: 70px !important; align-self:center;"
										aria-hidden="true"></i></div>
							</div>
						</p-card>
						<p-card class="col-md-3 mt-3">
							<div class="d-flex justify-content-between">
								<div> 
									<h4 style="color:#009CDD; font-size:large;">
										<strong>{{ totalPromotionValue.toFixed(2) | currency: " ":"symbol" }}</strong></h4>
									<p style="color:#6c757d; font-size:small;">
										{{ 'ManagePromotionalActivitiesComponent.Calculate.TotalPromotionValue' | translate }}</p>
								</div>
								<div><i class="uil uil-tag-alt Indicators" 
									style="font-size: 70px !important; align-self:center;"
									aria-hidden="true" ></i></div>
								<!-- <div style="align-self:center;"><img src="assets/images/tag-percentage.ico" style="width:10vh;"></div> -->
							</div>
						</p-card>
						<p-card class="col-md-3 mt-3">
							<div class="d-flex justify-content-between">
								<div> 
									<h4 style="color:#009CDD; font-size:large;">
										<strong>{{ totalInvestmentEbitda.toFixed(2) | currency: " ":"symbol"}}</strong></h4>
									<p style="color:#6c757d; font-size:small;">
										{{ 'ManagePromotionalActivitiesComponent.Calculate.TotalValueofInvestmentEbitda' | translate }}</p>
								</div>
								<div><i class="uil uil-analytics Indicators" 
									style="font-size: 70px !important; align-self:center;"
									aria-hidden="true" ></i></div>
							</div>
						</p-card>
						<p-card class="col-md-3 mt-3">
							<div class="d-flex justify-content-between">
								<div> 
									<h4 style="color:#009CDD; font-size:large;">
										<strong>{{ totalEbitdaConsolidated.toFixed(2) }}%</strong></h4>
									<p style="color:#6c757d; font-size:small;">
										{{ 'ManagePromotionalActivitiesComponent.Calculate.TotalEBITDA' | translate}}</p>
								</div>
								<div><i class="uil uil-analytics Indicators" 
									style="font-size: 70px !important; align-self:center;"
									aria-hidden="true" ></i></div>
							</div>
						</p-card>
					</div>

					<div *ngIf="objectNoAlcoholic.length > 0" class="row col-md-12">
						<div class="col-md-12 mt-3">
							<h5>
								<strong>{{ 'ManagePromotionalActivitiesComponent.Calculate.NoAlcoholic' | translate }}</strong>
							</h5>
						</div>
						<div class="col-md-4 mt-3" *ngFor="let item of objectNoAlcoholic">
							<p-card>
								<div class="d-flex justify-content-between">
									<div> 
										<h4 *ngIf="item.description != '% ebitda'" style="color:#009CDD;">
											<strong>{{item.value.toFixed(2) | currency: " ":"symbol"}}</strong>
										</h4>
										<h4 *ngIf="item.description == '% ebitda'"style="color:#009CDD;">
											<strong>{{item.value.toFixed(2)}}%</strong>
										</h4>
										<p style="color:#6c757d;">{{'Domain.Domain-'+item.description | translate}}</p>
									</div>
									<div><img src="assets/images/bottles.ico" style="width:10vh;"></div>
								</div>
							</p-card>
						</div>
					</div>

					<div *ngIf="objectAlcoholic.length > 0" class="row col-md-12">
						<div class="col-md-12 mt-3">
							<h5>
								<strong>{{ 'ManagePromotionalActivitiesComponent.Calculate.Alcoholic' | translate }}</strong>
							</h5>
						</div>
						<div class="col-md-4 mt-3" *ngFor="let item of objectAlcoholic">
							<p-card>
								<div class="d-flex justify-content-between">
									<div> 
										<h4 *ngIf="item.description != '% ebitda'" style="color:#009CDD;">
											<strong>{{item.value.toFixed(2) | currency: " ":"symbol"}}</strong>
										</h4>
										<h4 *ngIf="item.description == '% ebitda'"style="color:#009CDD;">
											<strong>{{item.value.toFixed(2)}}%</strong>
										</h4>
										<p style="color:#6c757d;">{{'Domain.Domain-'+item.description | translate}}</p>
									</div>
									<div><img src="assets/images/beer.ico" style="width:10vh;"></div>
								</div>
							</p-card>
						</div>
					</div>

					<div *ngIf="objectFood.length > 0" class="row col-md-12">
						<div class="col-md-12 mt-3">
							<h5>
								<strong>{{ 'ManagePromotionalActivitiesComponent.Calculate.Food' | translate }}</strong>
							</h5>
						</div>
						<div class="col-md-4 mt-3" *ngFor="let item of objectFood">
							<p-card>
								<div class="d-flex justify-content-between">
									<div> 
										<h4 *ngIf="item.description != '% ebitda'" style="color:#009CDD;">
											<strong>{{item.value.toFixed(2) | currency: " ":"symbol"}}</strong>
										</h4>
										<h4 *ngIf="item.description == '% ebitda'"style="color:#009CDD;">
											<strong>{{item.value.toFixed(2)}}%</strong>
										</h4>
										<p style="color:#6c757d;">{{'Domain.Domain-'+item.description | translate}}</p>
									</div>
									<div><img src="assets/images/bell-cook.ico" style="width:10vh;"></div>
								</div>
							</p-card>
						</div>
					</div>

				</p-tabPanel>

				<p-tabPanel header="{{ 'ManagePromotionalActivitiesComponent.Calculate.BaseSKU' | translate }}">
	
					<p-table [value]="purchaseBaseToCalculate" responsiveLayout="scroll" [scrollable]="true">
						<ng-template pTemplate="header">
							<tr>
								<th style="width:220px;">SKU</th>
								<th style="width: 220px;">
									{{ "ManagePromotionalActivitiesComponent.Description" | translate }} SKU</th>
								<th style="width: 220px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.Income" | translate }}</th>
								<th style="width: 220px;">
									% {{ "ManagePromotionalActivitiesComponent.Calculate.EbidtaPercentage" | translate }}</th>
								<th style="width: 180px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.Ebidta" | translate }}</th>
								<th style="width: 220px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.PromotionValue" | translate }}</th>
								<th style="width: 150px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.WeightedInvestment" | translate }}</th>
								<th style="width: 150px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.IndicatorSector" | translate }}</th>
							</tr>

						</ng-template>
						<ng-template pTemplate="body" let-product>
							<tr>
								<td style="width:220px;">{{ product.materialCode }}</td>
								<td style="width: 220px;">{{ product.materialDescription }}</td>
								<td style="width: 220px;">{{ (product.costIncome ? product.costIncome.toFixed(2) : 0) | currency }}</td>
								<td style="width: 220px;">{{ product.ebitdaPercentage ? product.ebitdaPercentage.toFixed(2) : 0 }}%</td>
								<td style="width: 180px;">{{ (product.ebitda ? product.ebitda.toFixed(2) : 0)  | currency: " "}}</td>
								<td style="width: 220px;">{{ (product.promotionValue ? product.promotionValue.toFixed(2) : 0) | currency }}</td>
								<td style="width: 150px;">{{ product.weightedValue ? product.weightedValue.toFixed(2) : 0 }}%</td>
								<td style="width: 150px;">{{ product.weightedValue ? product.weightedValue.toFixed(2) : 0 }}%</td>
								<!-- <td style="width: 150px;">
									{{ product.kpiLiberationSku ? product.kpiLiberationSku.toFixed(2) : 0 }}%</td> -->
							</tr>
						</ng-template>
						<ng-template pTemplate="footer" let-product>
							<tr>
								<td style="width:440px;">{{ "ManagePromotionalActivitiesComponent.Calculate.Total" | translate }}</td>
								<td style="width: 220px;">{{ (totalSumCostIncome ? totalSumCostIncome.toFixed(2) : 0) | currency}}</td>
								<td style="width: 220px;">{{ totalSumEbitdaPercentage ? totalSumEbitdaPercentage.toFixed(2) : 0 }}%</td>
								<td style="width: 180px;">{{ (totalSumEbitda ? totalSumEbitda.toFixed(2) : 0) | currency: " ":"symbol"}}</td>
								<td style="width: 220px;">{{ (totalSumPromotionValue ? totalSumPromotionValue.toFixed(2) : 0) | currency }}</td>
								<td style="width: 150px;">{{ totalSumWeightedValue ? totalSumWeightedValue.toFixed(2) : 0 }}%</td>
								<td style="width: 150px;">{{ totalSumWeightedValue ? totalSumWeightedValue.toFixed(2) : 0 }}%</td>
								<!-- <td style="width: 150px;">
									{{ totalSUmKpiLiberationSku ? totalSUmKpiLiberationSku.toFixed(2) : 0 }}%</td> -->
							</tr>
						</ng-template>
					</p-table>
	
				</p-tabPanel>
	
				<p-tabPanel header="SKU Promocion" *ngIf="commercialActivityType === commercialActivityTypeEnum.Promotions">

					<p-table [value]="purchasePromotionToCalculate" responsiveLayout="scroll" [scrollable]="true">
						<ng-template pTemplate="header">
							<tr>
								<th style="width:220px;">SKU</th>
								<th style="width: 220px;">
									{{ "ManagePromotionalActivitiesComponent.Description" | translate }} SKU</th>
								<th style="width: 220px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.Income" | translate }}</th>
								<th style="width: 220px;">
									% {{ "ManagePromotionalActivitiesComponent.Calculate.EbidtaPercentage" | translate }}</th>
								<th style="width: 180px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.Ebidta" | translate }}</th>
								<th style="width: 220px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.PromotionValue" | translate }}</th>
								<th style="width: 150px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.WeightedInvestment" | translate }}</th>
								<th style="width: 150px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.IndicatorSector" | translate }}</th>
							</tr>

						</ng-template>
						<ng-template pTemplate="body" let-product>
							<tr>
								<td style="width: 220px;">
									{{ product.materialCode }}</td>
								<td style="width: 220px;">
									{{ product.materialDescription }}</td>
								<td style="width: 220px;">
									{{ (product.costIncome ? product.costIncome.toFixed(2) : 0) | currency}}</td>
								<td style="width: 220px;">
									{{ product.ebitdaPercentage ? product.ebitdaPercentage.toFixed(2) : 0 }}%</td>
								<td style="width: 180px;">
									{{ (product.ebitda ? product.ebitda.toFixed(2) : 0) | currency: " ": "symbol"  }}</td>
								<td style="width: 220px;">
									{{ (product.promotionValue ? product.promotionValue.toFixed(2) : 0) | currency  }}</td>
								<td style="width: 150px;">
									{{ product.weightedValue ? product.weightedValue.toFixed(2) : 0 }}%</td>
								<td style="width: 150px;">
									{{ product.weightedValue ? product.weightedValue.toFixed(2) : 0 }}%</td>
								<!-- <td style="width: 150px;">
									{{ product.kpiLiberationSku ? product.kpiLiberationSku.toFixed(2) : 0 }}%</td> -->
							</tr>
						</ng-template>
						<ng-template pTemplate="footer" let-product>
							<tr>
								<td style="width:220px;">
									{{ "ManagePromotionalActivitiesComponent.Calculate.Total" | translate }}</td>
								<td style="width:220px;"></td>
								<td style="width:220px;">
									{{ (totalSumCostIncomeProm ? totalSumCostIncomeProm.toFixed(2) : 0) | currency }}</td>
								<td style="width:220px;">
									{{ totalSumEbitdaPercentageProm ? totalSumEbitdaPercentageProm.toFixed(2) : 0 }}%</td>
								<td style="width:180px;">
									{{ (totalSumEbitdaProm ? totalSumEbitdaProm.toFixed(2) : 0) | currency : " ": "symbol"  }}</td>
								<td style="width:220px;">
									{{ (totalSumPromotionValueProm ? totalSumPromotionValueProm.toFixed(2) : 0) | currency }}</td>
								<td style="width:150px;">
									{{ totalSumWeightedValueProm ? totalSumWeightedValueProm.toFixed(2) : 0 }}%</td>
								<td style="width:150px;">
									{{ totalSumWeightedValueProm ? totalSumWeightedValueProm.toFixed(2) : 0 }}%</td>
								<!-- <td style="width:150px;">
									{{ totalSUmKpiLiberationSkuProm ? totalSUmKpiLiberationSkuProm.toFixed(2) : 0 }}%</td> -->
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>

			</p-tabView>
		</div>
		
	</p-accordionTab>
</p-accordion>