<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/solicitude/'">
            {{ "SolicitudComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ 'PendingSolicitudeComponent.TitleDetail' | translate
          }}</li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container" >
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.TitleDetail' | translate }}</h1>
      </div>
    </div>
    
  </div>
  
  <p-dialog [(visible)]="modalUser" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}">

    <ng-template pTemplate="header">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: left;">
        <h5><strong>{{ 'EliminateDifferentialComponent.AssignedClients' | translate}}</strong></h5>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div *ngIf="objectListClient.length > 0">
        <p-table #dt 
        name="gridAdvancedClientFilters" 
        [value]="objectListClient" 
        styleClass="p-datatable-customers">
        <ng-template pTemplate="body" let-object>
          <tr>
            <td>{{object.typeDescription }}</td>
            <td>{{object.contentDescription}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="3">{{ 'ElementsNotFound' | translate }}</td></tr>
        </ng-template>
      </p-table>
      </div>

      <div *ngIf="CSVFile.length > 0" class="row">         
        <div class="col-md-6">
          {{ "AssingClient.TotalRecords" | translate }} &nbsp;
          <strong style="font-size: 22px">{{ totalRecordsDownload }}</strong>
        </div>

        <div class="col-md-4">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            label="{{ 'Download' | translate }}"
            class="p-button-outlined p-button-rounded"
            (click)="fileDownloadCSV()"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-md-8 mt-1"></div>
        <div class="col-md-4 mt-1"> 
          <button pButton 
                  pRipple
                  type="button"
                  label="{{ 'CloseBtn' | translate}}"
                  class="pstn_primario w-100"
                  (click)="cancelUser()">
          </button>
        </div> 
      </div>
    </ng-template>
  </p-dialog>

  <div class="container">
    

    <div class="col-md-12">
      <h6 class="pstntitle">{{'Request' | translate}} : {{ solicitudeId }}</h6>
    </div>

    <div class="row mt-5">
      <div class="pstn_container container-fluid">

        <p-table #dt [value]="objectListSolicitude"
              dataKey="id"
              styleClass="p-datatable-customers"
              [paginator]="true"
              [rows]="10"
              paginatorPosition="bottom"
              currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
              [rowsPerPageOptions]="[10,25,50,100]"
              [globalFilterFields]="['sku', 'materialDescription', 'currentListPrice','priceProposed', 'currentPsp', 'proposedPsp', 'validity']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search" aria-hidden="true" ></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{ 'SearchBy' | translate }}" />
                </span>
              </div>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="sku">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Sku" | translate }}
                  <p-sortIcon field="sku"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="materialDescription">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.MaterialDescription" | translate }}
                  <p-sortIcon field="materialDescription"></p-sortIcon>
                  </div>
                </th>

                <th style="width:180px">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.ProductType" | translate }}
                  </div>
                </th>

                <th pSortableColumn="currentListPrice">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.CurrentListPrice" | translate }}
                  <p-sortIcon field="currentListPrice"></p-sortIcon>
                </div>
                </th>

                <th pSortableColumn="priceProposed">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.PriceProposed" | translate }}
                  <p-sortIcon field="priceProposed"></p-sortIcon>
                 </div>
                </th>

                <th pSortableColumn="currentPsp">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.CurrentPsp" | translate }}
                  <p-sortIcon field="currentPsp"></p-sortIcon>
                 </div>
                </th>

                <th pSortableColumn="proposedPsp">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.ProposedPsp" | translate }}
                  <p-sortIcon field="proposedPsp"></p-sortIcon>
                </div>
                </th>

                <th pSortableColumn="validity">
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Validity" | translate }}
                  <p-sortIcon field="validity"></p-sortIcon>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex"let-object >
              <tr>
                <td>{{materialCodeZeroLess(object.materialCode) }}</td>
                <td>{{object.product }}</td>
                <td style="width:180px"> {{ object.zofeDescription }} </td>
                <td>
                  <div *ngIf="object.currentListPrice >= 1">
                    {{object.currentListPrice | currency: 'USD'}}
                  </div>
                  <div *ngIf="object.currentListPrice === 0">
                    {{ "SolicitudComponent.Priceless" | translate }}
                  </div>
                </td>

                <td>{{object.newPriceWithOutTax | currency: "USD" || ""}}</td>
                <td>{{object.currentPsp  | currency: "USD" || ""}}</td>
                <td>{{object.newPsp | currency: "USD" || ""}}</td>
                <td>
                  {{object.effectiveDateStart | date: "dd/MM/yyyy"}}- {{object.effectiveDateEnd | date: "dd/MM/yyyy"}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td *ngIf="validity" colspan="6">{{ "ElementsNotFound" | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
      </div>
    </div>

      <div class="row mt-5" >
      <div class="pstn_container container-fluid" *ngIf=" objectListSolicitudeDifferential && objectListSolicitudeDifferential.length > 0">
        <p-accordion>
          <p-accordionTab
          header="{{ 'SolicitudComponent.Withdrawal.SpreadsAffectedWithdrawal' | translate }}"
          [selected]="true">
            <p-table #dt [value]="objectListSolicitudeDifferential"
            dataKey="id"
            styleClass="p-datatable-customers"
            [paginator]="true"
            [rows]="10"
            [scrollable]="true"
            paginatorPosition="bottom"
            currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
            [rowsPerPageOptions]="[10,25,50,100]"
            [globalFilterFields]="['id', 'sku', 'materialDescription', 'valueDifferential', 'accessSequence', 'dateStart', 'dateEnd']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true" ></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
          </ng-template>
    
          <ng-template pTemplate="header">
            <tr>
              <th style="width:150px">
                {{ "Actions" | translate }}
              </th>
              <th style="width:180px" pSortableColumn="id">
                <div class="p-d-flex p-jc-between p-ai-center">
                {{ "SolicitudComponent.idDifferential" | translate }}
                <p-sortIcon field="id"></p-sortIcon>
                </div>
              </th>
              
              <th style="width:180px" pSortableColumn="sku">
                <div class="p-d-flex p-jc-between p-ai-center">
                {{ "SolicitudComponent.CodigoSku" | translate }}
                <p-sortIcon field="sku"></p-sortIcon>
                </div>
              </th>
    
              <th style="width:200px" pSortableColumn="materialDescription">
                <div class="p-d-flex p-jc-between p-ai-center">
                {{"SolicitudComponent.SkuDescription" | translate }}
                <p-sortIcon field="materialDescription"></p-sortIcon>
              </div>
              </th>
    
              <th style="width:180px" pSortableColumn="valueDifferential">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.Value" | translate }}
                <p-sortIcon field="valueDifferential"></p-sortIcon>
                </div>
              </th>
    
              <th style="width:210px" pSortableColumn="accessSequence">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.AccessSequence" | translate }}
                <p-sortIcon field="accessSequence"></p-sortIcon>
              </div>
              </th>
    
              <th style="width:220px" pSortableColumn="dateStart">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.InitialDate" | translate }}
                <p-sortIcon field="dateStart"></p-sortIcon>
              </div>
              </th>
    
              <th style="width:200px" pSortableColumn="dateEnd">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{"SolicitudComponent.Withdrawal.FinalDate" | translate }}
                <p-sortIcon field="dateEnd"></p-sortIcon>
              </div>
              </th>
    
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex"let-object >
            <tr>
              <td style="width:150px">
                <i id="btnUsers"
                      class="uil uil-users-alt enviar m-1"
                      pTooltip="{{object.tooltipClient}}"
                      (click)="users(object)"
                      aria-hidden="true"
                      *ngIf="(rolePermission.create | rolePermission)">
                    </i>
              </td>
              <td style="width:180px">{{object.id }}</td>
              <td style="width:180px">{{ materialCodeZeroLess(object.sku) }} </td>
              <td style="width:200px">{{ object.materialDescription }} </td>
              <td style="width:180px">{{ object.valueDifferential}}</td>
              <td style="width:210px">{{object.accessSequence }}</td>
              <td style="width:220px">{{object.dateStart | date: 'dd/MM/yyyy'}}</td>
              <td style="width:200px">{{object.dateEnd | date: 'dd/MM/yyyy'}}</td>
    
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td *ngIf="validity" colspan="6">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>

      <!-- Buttons -->
      <div class="row justify-content-between mt-4">
        <div class="mr-3">
          <button id="btnBack" pButton pRipple type="button"
          label="{{ 'PendingSolicitudeComponent.Back' | translate }}"
          class="p-button-rounded pstn_secundario w-100"
          (click)="back()"></button>
        </div>
      </div>
      
    </div>

</section>


