import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ISolicitudeJson } from '../../interfaces/solicitude-json.interface';
import { ISolicitudeDetailJson } from '../../interfaces/solicitude-detail-json.interface';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { SolicitudeService } from '../../solicitude.service';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SolicitudeCreationModel } from '../../models/solicitude-creation.model';
import { ISolicitudeDetailDifferentialWithDrawal } from '../../interfaces/solicitude-differential-withdrawal.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { HelperService } from '@app/@shared/services/helper.service';
import { ManagePromotionalActivitiesMessage } from '@app/@modules/promotionalactivity/manage-promotional-activities/messages/manage-promotional-activities-messages.message';

@Component({
  selector: 'app-detail-withdrawal',
  templateUrl: './detail-withdrawal.component.html',
  styleUrls: ['../../../../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService, DatePipe],
})
export class DetailWithdrawalComponent implements OnInit {
  private readonly spinner: NgxSpinnerService;
  private readonly solicitudeService: SolicitudeService;
  private readonly router: Router;
  private readonly helperService: HelperService;

  public solicitudeId = 0;
  public objectList: ISolicitudeJson[];
  public actionsDetail: ISolicitudeDetailJson;
  public solicitudeStatus: SolicitudeStatus;
  public objectListSolicitude: SolicitudeCreationModel[];
  public retry: number;
  public validity: boolean;
  public objectListSolicitudeDifferential: ISolicitudeDetailDifferentialWithDrawal[];
  public rolePermission : any = RolePermissionEnum;
  public modalUser: boolean = false;
  public objectListClient : any = [];
  public CSVFile : string[] = [];
  public totalRecordsDownload = 0;

  constructor(
    spinner: NgxSpinnerService,
    solicitudeService: SolicitudeService,
    router: Router,
    public datepipe: DatePipe,
    helperService: HelperService
  ) {
    this.spinner = spinner;
    this.solicitudeService = solicitudeService;
    this.router = router;
    this.helperService = helperService;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.solicitudeId = this.solicitudeService.getSolicitudeId();
    this.getSolicitudeById();
  }

  validateElementsNotFound(){
    if(this.objectListSolicitude.length <= 0){
      this.validity = true;
    }else{
      this.validity = false;
    }
  }

    /**
   * @description this method is to get Solicitude with SolicitudeMaterial By Id.
   * @author Daniel Londoño
   * @sprint 5
   */
     getSolicitudeById(): void {
      this.spinner.show();
      const id = this.solicitudeService.getSolicitudeId();
      if (!id) {
        this.spinner.hide();
        this.router.navigate(['auth/solicitude']);
        return;
      }

      this.solicitudeService.getSolicitudeWithDetailById(id).subscribe(
        (response) => {
          if (response.status) {
              this.retry = response.data.retry;

              this.objectListSolicitude = response.data.solicitudeDetailJson.map(
                (item) => {
                  return {
                    id: item.detailId,
                    solicitudeId: response.data.id,
                    materialCode: item.sku,
                    product: item.materialDescription,
                    currentListPrice: item.currentListPrice,
                    currentPsp: item.currentPsp,
                    newPriceWithOutTax: item.newPriceWithOutTax,
                    priceAdjustment: item.priceAdjustment,
                    newPsp: item.newPsp,
                    effectiveDateStart: new Date(item.effectiveDateStart),
                    effectiveDateEnd: new Date(item.effectiveDateEnd),
                    zofeCode: item.zofeCode,
                    zofeDescription: item.zofeDescription
                  };
                }
              );

              if(response.data.solicitudeAffectedByWithdrawalJson){
                this.objectListSolicitudeDifferential = response.data.solicitudeAffectedByWithdrawalJson;
                
                this.objectListSolicitudeDifferential.forEach((x) => {
                  if(x.filterClient){
                    let description = JSON.parse(x.filterClient);
                    if(description.length > 1){
                      x.tooltipClient = description[0].contentDescription + '/' + description[1].contentDescription;
                    }else if(description.length === 1){
                      x.tooltipClient = description[0].contentDescription;
                    }
                  }
                  if(x.csvClients){
                    x.tooltipClient = 'Archivo CSV';
                  }
                });
              } 

              
              this.validateElementsNotFound();
          } else {
            this.spinner.hide();
            SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
          }
        },
        (error) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
    }

  back(): void {
    setTimeout(() => {
      this.router.navigate(['auth/solicitude']);
    }, 1000);
  }

  users(solicitude : ISolicitudeDetailDifferentialWithDrawal){
		this.modalUser = true;
		this.objectListClient = [];
		this.CSVFile = [];

		if(solicitude.filterClient){
			this.objectListClient = JSON.parse(solicitude.filterClient);
		}

		if(solicitude.csvClients){
		this.CSVFile = solicitude.csvClients;
		this.totalRecordsDownload = this.CSVFile.length;
		}
	}

  cancelUser(){
		this.modalUser = false;
		this.objectListClient = [];
		this.CSVFile = [];
	}

  fileDownloadCSV() {
		const csv = typeof this.CSVFile[0] === 'string' ? this.CSVFile : this.CSVFile.map((row: any) => JSON.stringify(row.clientCode));
		const csvArray = csv.join('\r\n');
    const language: string = sessionStorage.getItem('language');

	
		const a = document.createElement('a');
		const blob = new Blob([csvArray], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
	
		a.href = url;
		a.download = language === 'es-ES' 
                                    ? ManagePromotionalActivitiesMessage.CSV_CLIENTS_SOLICITUDE_ASSIGNED 
                                    : ManagePromotionalActivitiesMessage.CSV_CLIENTS_SOLICITUDE_ASSIGNED_TRANSLATE;
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	  }

    materialCodeZeroLess(value: string): string{
      return this.helperService.materialCodeZeroLess(value);
    }
    
}
