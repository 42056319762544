import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {

  public appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
  }

  logPageView(name?: string, uri?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name,
      uri,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(error: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: error, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      let item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties['ApplicationPlatform'] = 'WEB';
      item.properties['ApplicationName'] = 'CCVP-Frontend';
    });
  }

}
