import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdvancedFiltersClientsResult } from '@app/@shared/interfaces/advanced-filters-client-result.interface';
import { IAdvancedFiltersClient } from '@app/@shared/interfaces/advanced-filters-client.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import * as saveAs from 'file-saver';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CustomerSummaryService {
  private solicitudeId: number;
  private readonly httpService: HttpService;
  private readonly HttpClient: HttpClient;

  constructor(
    httpService: HttpService,
    HttpClient: HttpClient
  ) {
    this.httpService = httpService;
    this.HttpClient = HttpClient;
   }


  public getFile(filters: IAdvancedFiltersClient) {
    let type : string = 'application/csv';
    
    return this.httpService.post(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/download_file_customers_activity`, filters, true)
    .pipe(
      tap(content => {
        const blob = new Blob([content.body], {type});
        saveAs(blob,"Clientes.csv");
      }),
      map(() => true)
    );
  }

  public getFileCustomers(commercialActivityId : number){
    // let type : string = 'application/text';
    return this.httpService.get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity_customers/download_file/${commercialActivityId}`, undefined, true )
    .pipe(
      tap(content => {
        const blob = new Blob([content.body]);
        saveAs(blob, `plantilla_${commercialActivityId}.csv`);
      }),
      map(() => true)
    ); 
  }
  
}
