import { IUpdateStatus } from '@app/@shared/interfaces/update-status.interface';

export class SolicitudeStatusModel implements IUpdateStatus<number,number> {
    /**
   * @author David Sneider Cardona Cardenas
   * @sprint 6
   * @description Model for update status
   */
  constructor(
    public id : number,
    public status: number,
    public commentary? : string,
  ) {}
}