
<section>
  <div class="mt-5"></div>
  <div class="container">
    <div class="selectedProfile">
      <div class="imgProfile">
        <img src="assets/images/admi.png" alt="" style="object-fit: cover" />
      </div>
      <div class="selectedName">
        <h6>{{ profile }}</h6>
      </div>
    </div>

    <div class="mt-4"></div>
    <p>{{'ModuleDescription' | translate}}</p>

    <div class="d-flex flex-row justify-content-start flex-wrap">
      <div
        *ngFor="let item of listOperation"
        class="containerCard"
        (click)="sendRoute(item.route)"
      >
        <header>
          <i class="uil uil-clipboard clipboard"
          aria-hidden="true" ></i>
        </header>
        <div class="descriptionOperation">
          <h6>{{'Menu.MenuOperation.operation-'+item.operationId | translate}}</h6>
        </div>
      </div>
    </div>
		<div class="col-md-3 mt-4 align-items-end">
			<button
			id="btnCreate"
			pButton
			pRipple
			type="button"
			label="{{ 'ReturnBtn' | translate }}"
			class="p-button-rounded pstn_primario w-100"
			(click)="returnShow();"
			></button>
		</div>
  </div>
</section>
