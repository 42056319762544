import { IAssociateUserUpdate } from '../interfaces/associate-user-update.interface';

export class AssociateUserUpdateModel implements IAssociateUserUpdate {

    /**
    * @author Jhon Steven Pavón Bedoyaid
    * @param id
    * @param name
    * @param lastName
    * @param email
    * @param accessManagementId
    * @param profileId
    * @param mandatory
    * @param authorizer
    * @param approval
    * @sprint 6
    */
       constructor(
        public id: number,
        public name: string,
        public lastName: string,
        public email: string,
        public accessManagementId: string,
        public profileId: number,
        public mandatory: boolean,
        public authorizer: boolean,
        public approval: boolean
    ) {}

}
