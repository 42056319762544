import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { RemoveDifferentialService } from './remove-differential.service';
import { IEliminateDifferential } from './interfaces/table-eliminate-differential.interfaces';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { SolicitudeFileModel } from '../models/solicitude-file.model';
import { Util } from '@app/@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileBaseHelper } from '@app/@shared/helpers/file-base.helper';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeleteEliminateDifferentialModel } from './models/delete-eliminate-differential.model';
import { parse } from 'date-fns';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusEliminateDifferentialModel } from './models/satus-eliminate-differential.models';
import { SolicitudeService } from '../solicitude.service';
import { SolicitudeMaterialRequestModel } from '../models/solicitude-material-request.model';
import { SolicitudeModel } from '../models/solicitude.model';
import { SolicitudeMaterialModel } from '../models/solicitude-material.model';
import { SolicitudeCustomerModel } from '../models/solicitude-customer.model';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ApplicationConstants } from '@app/app.constants';
import { SolicitudeStatusDescription } from '@app/@shared/enums/solicitude-status-description.enums';
import { IAdvancedFilter } from '@app/@components/shared-advanced-filter/interfaces/general-advanced-filter.interface';
import { AuxMaterialIdModel } from './models/aux-material-id.model';
import { IFieldsComponentGeneralFilter } from '@app/@shared/interfaces/fields-component-general-filter.interface';
import { FieldsComponentGeneralFilter } from '@app/@shared/model/fields-component-general-filter.model';
import { IFieldsComponentClientFilter } from '@app/@shared/interfaces/fields-component-client-filter.interface';
import { FieldsComponentClientFilter } from '@app/@shared/model/fields-component-client-filter.model';
import { HelperService } from '@app/@shared/services/helper.service';

@Component({
  selector: 'app-remove-differential',
  templateUrl: './remove-differential.component.html',
  styleUrls: ['../../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
  ],
  providers: [MessageService, DatePipe],
})
export class RemoveDifferentialComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: any;
  private readonly removeDifferentialService : RemoveDifferentialService;
  private readonly spinner: NgxSpinnerService;
  private readonly fileBaseHelper: FileBaseHelper;
  private readonly fb: FormBuilder;
  private readonly miDatePipe: DatePipe;
  private readonly solicitudeService : SolicitudeService;
  private readonly messageService: MessageService;
  private readonly helperService: HelperService;
  
  public solicitudeDescriptionEnum = SolicitudeStatusDescription;
  public objEliminateDifferential : IEliminateDifferential[];
  public checkDelete: boolean = false;
  public modalDelete: boolean = false;
  public modalUser: boolean = false;
  public today: Date = new Date();
  public uploadedFiles: any[] = [];
  public PDFFile: SolicitudeFileModel;
  public CSVFile : string [] = [];
  public totalRecordsDownload = 0;
  public nameFile = '';
  public objectListClient : any = [];
  public rangeDateModel: Date;
  public motivoModel: string = '';
  public attachedFilePdf: string = undefined;
  public formDifferential: FormGroup;
  public rolePermission: any = RolePermissionEnum;
  public auxId : AuxMaterialIdModel[] = [];
  public dataTemporal : any;
  public actaMsgValidity : boolean = false;
  public statusDifferential: boolean = false;
  public first: number;
  public readonly showFieldsGeneral : IFieldsComponentGeneralFilter = new FieldsComponentGeneralFilter(true,  false, true, true, false, 
                                                                                                       false, false, false, false, false, 
                                                                                                       false, false, false, false, false);
  public readonly showFieldsClient : IFieldsComponentClientFilter = new FieldsComponentClientFilter(false, true);

  constructor(
    removeDifferentialService : RemoveDifferentialService,
    fileBaseHelper: FileBaseHelper,
    fb: FormBuilder,
    miDatePipe: DatePipe,
    spinner: NgxSpinnerService,
    solicitudeService : SolicitudeService,
    messageService: MessageService,
    helperService: HelperService
  ) { 
    this.removeDifferentialService = removeDifferentialService,
    this.fileBaseHelper = fileBaseHelper;
    this.fb = fb;
    this.miDatePipe = miDatePipe;
    this.spinner = spinner;
    this.solicitudeService = solicitudeService;
    this.messageService = messageService;
    this.helperService = helperService;
  }

  ngOnInit(): void {
    this.initForm();
    let filterInitial = this.prepareDataInitial();
    this.getDataListAll(filterInitial);
    this.first = 0;
  }
  
  getDataListAll(event : any){
    this.spinner.show();
    this.dataTemporal = event;
    this.objEliminateDifferential = [];
    if(event.length === 0) event = this.prepareDataInitial();
    this.removeDifferentialService.getRemoveDifferential(event)
    .subscribe(
      (response) => {
        if (response.status) {
          this.objEliminateDifferential = response.data;
          this.objEliminateDifferential.forEach((x) => {
            if(x.filterClient){
              let description = JSON.parse(x.filterClient);
              if(description.length > 1){
                x.tooltipClient = description[0].contentDescription + '/' + description[1].contentDescription;
              }else if(description.length === 1){
                x.tooltipClient = description[0].contentDescription;
              }
            }
            if(x.csvClients){
              x.tooltipClient = 'Archivo CSV';
            }
          });
          this.first = 0;
          this.objEliminateDifferential.sort((a, b) => b.solicitudeId - a.solicitudeId);
          this.spinner.hide();
        }else{
          this.spinner.hide();
		      const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.info(
            language === 'es-ES' ? 'Información' : 'Information',
            language === 'es-ES' ? 'No se encontraron registros para el filtro aplicado' : 
			      'No records found for the applied filter');
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  users(object: IEliminateDifferential){
    this.modalUser = true;
    this.objectListClient = [];
    this.CSVFile = [];
    if(object.filterClient){
      this.objectListClient = JSON.parse(object.filterClient);
    }
    if(object.csvClients){
      this.CSVFile = object.csvClients;
      this.totalRecordsDownload = object.csvClients.length;
    }
  }

  deleteSku(object: IEliminateDifferential){
    this.clearAuxDelete();
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.modalDelete = true;
        this.auxId.push({id: object.solicitudeId, solicitudeMaterialId : object.solicitudeMaterialId, material :object.materialCode.toString()})
      }
    });
  }
  
  deleteAllSku(){
    this.clearAuxDelete();
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.modalDelete = true;
        this.objEliminateDifferential.forEach((x) => {
          if(x.check){
            this.auxId.push({id: x.solicitudeId, solicitudeMaterialId : x.solicitudeMaterialId, material: x.materialCode.toString()});
          }
        });
      }
    });
  }

  clearAuxDelete() {
    this.auxId = [];
    this.formDifferential.reset();
    this.actaMsgValidity = false;
  }

  selectAll(value : any){
    this.objEliminateDifferential.forEach((diferential) => {
      if(diferential.statusDesc !== this.solicitudeDescriptionEnum.RemovalProcess){
        diferential.check = value;
      }
    });
    this.checkDelete = value;
  }

  selectRow(value : any, object: IEliminateDifferential){
    this.checkDelete = false;

    this.objEliminateDifferential.forEach((x) => {
      if(
        x.materialCode == object.materialCode 
        && x.solicitudeId == object.solicitudeId 
        && x.statusDesc !== this.solicitudeDescriptionEnum.RemovalProcess
      ){
       x.check = value; 
      }
    });
  }
  
  cancelUser(){
    this.modalUser = false;
    this.objectListClient = [];
    this.CSVFile = [];
  }

  cancelDelete(){
    this.modalDelete = false;
    this.clearFormDelete();
  }

  validateDates() : boolean {
    const dateSelected = new Date(this.rangeDateControl.value)
    let solicitudeErrorDate : string[] = []
    this.objEliminateDifferential.forEach((diferential) => {
      if(diferential.check ){
        const diferentialDateEnd = new Date(diferential.endDate);
        diferentialDateEnd.setUTCHours(0, 0, 0, 0);
        dateSelected.setUTCHours(0, 0, 0, 0);
        if(diferentialDateEnd < dateSelected)
          solicitudeErrorDate.push(`${diferential.solicitudeId} - ${diferential.materialCode} `)
      }
    });
    if(solicitudeErrorDate.length === 0)
      return true

    solicitudeErrorDate = [...new Map(solicitudeErrorDate.map((item) => [item, item])).values()]
    
    const language: string = sessionStorage.getItem('language');
    
    SweetAlertMessageHelpers.listWarningMessageShow(      
      language === 'es-ES' ? `La fecha seleccionada supera la(s) fecha(s) fin de la(s) solicitud(es) de diferencial(es) que está intentando retirar.` : 
      `The date selected exceeds the end date(s) of the spread withdrawal request(s) you are attempting to withdraw.`, solicitudeErrorDate, null, null, '   Id    -       Sku');   
    return false;
  }

  async saveDelete(){
    if(this.formDifferential.valid && this.uploadedFiles[0] !== undefined){
      if(!this.validateDates()) return;
      this.actaMsgValidity = false;
      this.modalDelete = false;
      this.spinner.show();
      try {
        let modelStatus: StatusEliminateDifferentialModel[] = this.prepareDataStatus();
        await this.statusChange(modelStatus);
        if(this.statusDifferential){
          let modelDelete : SolicitudeMaterialRequestModel = this.prepareDataDelete();
          const response = await this.removeDifferentialService.postSolicitudeExtDetail(modelDelete).toPromise();
          if (response.status) {
            this.spinner.show();
            const language: string = sessionStorage.getItem('language');
            Util.showSuccess(
              '',
              language === 'es-ES' ? 'Se ha generado la solicitud '+ response.data +' de tipo retiro diferencial' 
              : 'The request has been generated '+ response.data +' of the differential withdrawal type'
            );
            this.getDataListAll(this.dataTemporal);
            this.dataTemporal = [];
          }
        }
      } catch (error) {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      }
      this.clearFormDelete();
      this.spinner.hide();
      
    }else{
      this.actaMsgValidity = true;
      this.formDifferential.markAllAsTouched();
    }
      
  }

  prepareDataStatus() : StatusEliminateDifferentialModel[]{
    let modelStatus: StatusEliminateDifferentialModel[] = [];    
    let status : AuxMaterialIdModel[] = [];
    let data : any[] = [];
    let description : string = '';
    let solicitudMaterialId : number[] = [];
    this.auxId.forEach((x) => {
      status = this.auxId.filter((s) => s.id === x.id);
      if(status.length > 0){
        let first : boolean = true;
        status.forEach((x : AuxMaterialIdModel) => {
          if(!first){
            description += ','
          }
          description += x.solicitudeMaterialId;
          first = false;
          solicitudMaterialId.push(x.solicitudeMaterialId);
        });

        let duplicated = data.filter((d) => d.id === x.id);
        if(duplicated.length === 0){
          data.push({id: x.id, solicitudeMaterialId : solicitudMaterialId, materialCode : description});
        }  
        description = '';
        solicitudMaterialId = [];
      }
    });

    data.forEach((x) => {
      let auxStatus : StatusEliminateDifferentialModel 
      = new StatusEliminateDifferentialModel(
        x.id,
        x.solicitudeMaterialId 
      );
      modelStatus.push(auxStatus);
    });
    return modelStatus;
  }

  prepareDataDelete(): SolicitudeMaterialRequestModel{
    let modelSolicitud = this.dataSolicitud();
    let modelMaterial = this.dataMaterial(this.auxId);
    let modelFile = this.getSolicitudeFileModel();
    let dataModel : SolicitudeMaterialRequestModel =
      new SolicitudeMaterialRequestModel(
        modelSolicitud,
        modelMaterial,
        modelFile,
      );
    return dataModel;
  }


  dataSolicitud(): SolicitudeModel{
    let codeSolicitude = Util.newGuid().value;
    let modelSolicitud : SolicitudeModel = 
    new SolicitudeModel(
      0,
      SolicitudeType.WithdrawalDiferential,
      SolicitudeStatus.Temporary,
      0,
      codeSolicitude,
      '',
      this.reason.value,
    );
    return modelSolicitud;
  }

  dataMaterial(data: AuxMaterialIdModel[]) : SolicitudeMaterialModel[]{
    let modelMaterial : SolicitudeMaterialModel[] = [];
    data.forEach((x) => {
      let material = this.objEliminateDifferential.find((d) => d.solicitudeId === x.id && d.materialCode === x.material && d.solicitudeMaterialId === x.solicitudeMaterialId);
      let auxMaterial : SolicitudeMaterialModel =
      new SolicitudeMaterialModel(
        0,
        0,
        String(material.materialCode),
        material.currentListPrice,
        material.currentPsp,
        material.newPriceWithOutTax,
        material.priceAdjustment,
        material.newPsp,
        this.getDateSelected(material.startDate),
        material.endDate,
        String(Util.newGuid()),
        '',
        '',
        x.id,
        x.solicitudeMaterialId
      );
        modelMaterial.push(auxMaterial);

    });
    return modelMaterial;
  }

  getDateSelected(startDate : Date) : Date {
    const dateSelected = new Date(this.rangeDateControl.value)
    dateSelected.setUTCHours(0, 0, 0, 0);
    const diferentialDateStart = new Date(startDate);
    diferentialDateStart.setUTCHours(0, 0, 0, 0);
    if(diferentialDateStart < dateSelected)
      return dateSelected;
    return diferentialDateStart;
  }
  
  prepareDataInitial() : IAdvancedFilter{
    let filters = {} as IAdvancedFilter;
    filters.search = {ascendent:true, id:0, orderBy:'Id', pageNumber:1, resultsPerPage:10000};
    filters.general = { 
      activityCode: 0,
      activityDescription: "",
      activitySectors: [],
      activityChannels: [],
      activityTypes: [],
      activityStatus: [],
      activityProms: [],
      activityStartDate: "",
      activityEndDate: ""
    }
    filters.client = [];
    filters.clientFilter = "";
    filters.materialBase = [];
    filters.materialBasePromotion = [];

    return filters;
  }

  async statusChange(object: StatusEliminateDifferentialModel[]){
    const response = await this.removeDifferentialService.putStatusChange(object).toPromise();
    if (response.status) {
      const language: string = sessionStorage.getItem('language');
      this.showToast(SweetAlert.iconSuccess,
        language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
        language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
        this.statusDifferential = true;
    }
  }

  clearFormDelete(){
    this.rangeDateControl.setValue('');
    this.reason.setValue('');
  }

  //Metodos archivo
  async uploadFile(event: any) {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.attachedFilePdf = await this.fileBaseHelper.getFileBase(this.uploadedFiles[0]);
  }

  async onUploadFile(event: any) {
    if (this.PDFFile) {
      Util.confirmEditFile().then(async (result: SweetAlertResult) => {
        if (result.value) {
          this.PDFFile = null;
          this.uploadFile(event);
        }
        else {
          this.fileUpload.clear();
        }
      });
    }
    else {
      this.uploadFile(event);
      this.actaMsgValidity = false;
    }
  }

  getSolicitudeFileModel(): SolicitudeFileModel {
    if (this.uploadedFiles[0]) {
      return new SolicitudeFileModel(
        null,
        0,
        this.uploadedFiles[0].name,
        this.attachedFilePdf,
        ''
      );
    }
    else {
      if (this.PDFFile) {
        return new SolicitudeFileModel(
          this.PDFFile.id,
          this.PDFFile.solicitudeId,
          this.PDFFile.route,
          this.PDFFile.file,
          ''
        );
      }
    }
  }

  onRemoveFileImage(): void {
    this.uploadedFiles = [];
  }

  fileDownload() {
    const csv = typeof this.CSVFile[0] === 'string' ? this.CSVFile : this.CSVFile.map((row: any) => JSON.stringify(row.clientCode));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  getYearRange() {
    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();
    return `${thisYear-2}:${thisYear}`;
  }

  materialCodeZeroLess(value: string): string{
    return this.helperService.materialCodeZeroLess(value);
  }

  initForm(): void{
    this.formDifferential = this.fb.group({
      rangeDateControl: [{ value: '', disabled: false }, [Validators.required]],
      reason: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  get rangeDateControl(): AbstractControl { return this.formDifferential.get('rangeDateControl'); }
  get reason(): AbstractControl { return this.formDifferential.get('reason'); }

}
