import { IFieldsCommercialActivityFooter } from '../interfaces/fields-commercial-activity-footer.interface';

export class FieldsCommercialActivityFooterModel implements IFieldsCommercialActivityFooter{
  constructor(
    public rangeDate: boolean,
    public numberDays: boolean,
    public startTime: boolean,
    public endTime: boolean,
    public orderLimit: boolean,
    public periodLimit: boolean,
    public commercialActivityState: boolean,
    public commercialActivitySendValidate: boolean
  ) {}

}