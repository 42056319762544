<div class="card"  [style]="{'height':'auto'}" style="flex-wrap: wrap; width: auto;">
  <div class="card-body">
    <div class="row" style="display:flex;">
      <span class="{{ notificationColor }}"></span>
      <h5 class="card-title"><b>{{ title }}</b></h5>
    </div>

    <div class="card-text notifi-text" [innerHTML]="notificationText"  
    [style]="{'height':'135px', 'overflow': 'hidden' }"></div>

    <button
      *ngIf="moreInfo"
      type="button"
      class="btn btn-link pl-0"
      (click)="onClickMoreInfo($event)"
    >
    {{ 'MoreInformation' | translate }}
    </button>

    <div class="row">
      <button 
        *ngIf="seeDocument" 
        type="button" 
        class="btn btn-link pl-0"
        (click)="onClickSeeDocument($event)">
        {{ 'SeeActivity' | translate }}
      </button>
    </div>

    <div class="row justify-content-between">
    
      <div class="col-md-4 text-left">
        <p><time>{{creationDate | amCalendar  }}</time></p>
        <button
          *ngIf="snooze"
          (click)="onClickSnooze($event)"
          class="pstn_posponer"
        >
          <i class="uil uil-history" aria-hidden="true"></i>
          {{ 'PutOff' | translate }}
        </button>
      </div>
      <div class="col-md-8 float-right">
        <!-- <button
          *ngIf="reject"
          (click)="onClickReject($event)"
          class="pstn_rechazar float-right"
        >
          <i class="uil uil-times-circle" aria-hidden="true"></i>Rechazar
        </button> -->
        <button
          *ngIf="accept"
          (click)="onClickAccept($event)"
          class="pstn_aceptar float-right"
        >
          <i class="uil uil-check-circle" aria-hidden="true"></i>{{'Panel.Panel-'+approvalName | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
