import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advanced-up-customer-filter',
  templateUrl: './advanced-up-customer-filter.component.html',
  styleUrls: ['./advanced-up-customer-filter.component.css']
})
export class AdvancedUpCustomerFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
