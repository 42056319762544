<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/pending-solicitude/'">
            {{ "PendingSolicitudeComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ 'PendingSolicitudeComponent.TitleAnalyze' | translate
          }}</li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.TitleAnalyze' | translate }}</h1>
      </div>
    </div>

      <div class="col-md-12">
        <h6 class="pstntitle">{{'Request' | translate}} : {{ solicitudId }}</h6>
      </div>

      <app-assing-clients 
        [showFields]="showFieldClientFilter"
        [validateFilter]="true"
        [requiredFields]="false"
        [filtersEdit]="filtersEdit"
        [isProcess]="isProcess"
        [clientsEdit]="clientsEdit"
        [isCreate]="false"
        >
      </app-assing-clients>

      <div class="mt-5"></div>

      <div class="container">
        <app-price-certificate #priceCertificate
        [isCreate]="false"
        >
        </app-price-certificate>
      </div>

    <!-- Table Impact Analysis -->
    <div class="row mt-5">
      <div class="col-md-12">
        <h6 class="pstn_subtitle">{{ 'PendingSolicitudeComponent.Subtitle' | translate }}</h6>
      </div>
      <div class="pstn_container container-fluid">
        <p-table #dt 
          dataKey="solicitudeId" 
          name="gridCreationSolicitude" 
          [value]="objectListSolicitude"
          [paginator]="true" 
          [rows]="rows" 
          [(first)]="page"
          (onPage)="loadPaginators($event)"
          [scrollable]="true"  
          paginatorPosition="bottom"
          currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
          [rowsPerPageOptions]="[10,25,50,100]" styleClass="p-datatable-customers"
          [globalFilterFields]="['materialCode', 'product', 'zofeDescription','currentListPrice', 'newPriceWithOutTax', 'priceAdjustment', 'percentage', 'newPsp', 'effectiveDateEnd', 'effectiveDateStart', 'isPriceList']"
          scrollHeight="800px">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input pInputText type="text" 
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 20px;"></th>
              <th style="width:180px">{{ "Actions" | translate }}</th>
              <th style="width:180px" pSortableColumn="materialCode">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ 'SolicitudComponent.Create.Code' | translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="product">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.Product' | translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="isPriceList">
                <div class="p-d-flex p-jc-between p-ai-center">{{ "SolicitudComponent.Create.LabelApprovedBy" | translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="zofeDescription">
                <div class="p-d-flex p-jc-between p-ai-center">{{ "SolicitudComponent.Create.ProductType" | translate }}
                </div>
              </th>
              <th style="width:180px">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.CurrentWithoutTax' |
                  translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="currentListPrice">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.CurrentPSP' | translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="newPriceWithOutTax">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.NoNewTax' |
                  translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="priceAdjustment">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.AdjustmentMoney' |
                  translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="percentage">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.PercentageAdjustment' |
                  translate
                  }}</div>
              </th>
              <th style="width:180px" pSortableColumn="newPsp">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.NewPSP' | translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="effectiveDateStart">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.InitialDate' | translate }}
                </div>
              </th>
              <th style="width:180px" pSortableColumn="effectiveDateEnd">
                <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.FinalDate' | translate }}
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-solicitud let-editing="editing" let-ri="rowIndex">
            <tr>
              <td style="width:20px">
                <span [ngClass]="solicitud.isApproval === false ? 'color-notificacion-5' : solicitud.isApproval ? 'color-notificacion-1' : 'Ninguno'"></span>
              </td>
              <td style="width:180px">

                <i id="btnAnalisysImpact"
                  pTooltip="{{ 'SolicitudComponent.Create.BtnImpactAnalysis' | translate }}"
                  class="uil uil-search-alt Editar"
                  aria-hidden="true"
                  (click)="getImpactAnalyze(solicitud)"></i>
                  
                <i id="btnDelete"
                  pTooltip="{{ 'SolicitudComponent.Create.BtnDelete' | translate }}"
                  class="uil uil-trash-alt eliminar"
                  aria-hidden="true"
                  (click)="deleteImpactAnalyze(solicitud)"></i>

                <i id="btnRecalculate"
                  pTooltip="{{ 'SolicitudComponent.Create.BtnRecalculate' | translate }}"
                  class="uil uil-refresh historico"
                  aria-hidden="true"
                  (click)="recalculateImpactAnalysis(solicitud)"></i>

              </td>
              <td style="width:180px">
                {{materialCodeZeroLess(solicitud.materialCode) }}
              </td>
              <td style="width:180px">
                {{solicitud.product}}
              </td>
              <td style="width:180px">
                {{ solicitud.isPriceList}}
              </td>
              <td style="width:180px">
                {{ solicitud.zofeDescription }}
              </td>
              <td style="width:180px">
                <div *ngIf="solicitud.currentListPrice >= 1">
                  {{solicitud.currentListPrice | currency: 'USD'}}
                </div>
                <div *ngIf="solicitud.currentListPrice === 0">
                  {{ "SolicitudComponent.Priceless" | translate }}
                </div>
              </td>
              <td style="width:180px">
                {{solicitud.currentPsp | currency: 'USD'}}
              </td>
              <td style="width:180px">
                {{solicitud.newPriceWithOutTax | currency: 'USD'}}
              </td>
              <td style="width:180px">
                {{solicitud.priceAdjustment | currency: 'USD'}}
              </td>
              <td style="width:180px">
                {{solicitud.percentage | percent:'2.2'}}
              </td>
              <td style="width:180px">
                {{solicitud.newPsp | currency: 'USD'}}
              </td>
              <td style="width:180px">
                {{solicitud.effectiveDateStart | date:'dd/MM/yyyy'}}
              </td>
              <td style="width:180px">
                {{solicitud.effectiveDateEnd | date:'dd/MM/yyyy'}}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">{{ 'ElementsNotFound' | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Buttons -->
        <div class="row justify-content-between mt-4">
          <div class="mr-3">
            <button id="btnBack" pButton pRipple type="button"
              label="{{ 'PendingSolicitudeComponent.Back' | translate }}"
              class="p-button-rounded pstn_secundario w-100"
              (click)="back()"></button>
          </div>          
          <div class="col-md-8 float-right" *ngIf="isVisibleButton">
            <button *ngIf="isVisibleReject && rolePermission.create | rolePermission" class="pstn_rechazar float-right" (click)="reject()">
            <i class="uil uil-times-circle" aria-hidden="true"></i>{{ 'PendingSolicitudeComponent.Reject' | translate }}
            </button>
            <button *ngIf="isVisibleApproval && rolePermission.create | rolePermission" class="pstn_aceptar float-right" (click)="accept()">
              <i class="uil uil-check-circle" aria-hidden="true"></i>{{ 'PendingSolicitudeComponent.Approve' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

     <!-- SKUs Deleted By Approver-->
     <div  class="pstn_container container-fluid mt-5">
      <p-accordion>
       <p-accordionTab header="{{ 'PendingSolicitudeComponent.DeletedByApprover.TitleTableSkusDeleted' | translate }}" 
         [disabled]="!showAccordionSolicitudesDeletedByApprover">
             <p-table #dtc 
               dataKey="solicitudeId" 
               name="gridSkusDeletedByApprover" 
               [value]="objectListSolicitudesDeletedByApprover"
               [paginator]="true" 
               [rows]="rowsApproverDeleted" 
               [(first)]="pageApproverDeleted"
               (onPage)="loadPaginatorsApproverDeleted($event)"
               [scrollable]="true"  
               paginatorPosition="bottom"
               currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
               [rowsPerPageOptions]="[10,25,50,100]" styleClass="p-datatable-customers"
               [globalFilterFields]="['materialCode', 'materialDescription', 'zofeDescription','currentListPrice', 'newPriceWithOutTax', 'priceAdjustment', 'newPsp', 'userApproverDeleted', 'effectiveDateEnd', 'effectiveDateStart', 'isPriceList']">
               <ng-template pTemplate="caption">
                 <div class="p-d-flex">
                   <span class="p-input-icon-left p-ml-auto">
                     <i class="pi pi-search" aria-hidden="true"></i>
                     <input pInputText type="text" 
                     (input)="dtc.filterGlobal($event.target.value, 'contains')"
                     placeholder="{{ 'SearchBy' | translate }}" />
                   </span>
                 </div>
               </ng-template>
               <ng-template pTemplate="header">
                 <tr>
                   <th style="width: 20px;"></th>
                   <th style="width:180px" pSortableColumn="materialCode">
                     <div class="p-d-flex p-jc-between p-ai-center">
                       {{ 'SolicitudComponent.Create.Code' | translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="materialDescription">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.Product' | translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="isPriceList">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ "SolicitudComponent.Create.LabelApprovedBy" | translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="zofeDescription">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ "SolicitudComponent.Create.ProductType" | translate }}
                     </div>
                   </th>
                   <th style="width:180px">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.CurrentWithoutTax' |
                       translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="currentListPrice">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.CurrentPSP' | translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="newPriceWithOutTax">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.NoNewTax' |
                       translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="priceAdjustment">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.AdjustmentMoney' |
                       translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="newPsp">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.NewPSP' | translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="effectiveDateStart">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.InitialDate' | translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="effectiveDateEnd">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'SolicitudComponent.Create.FinalDate' | translate }}
                     </div>
                   </th>
                   <th style="width:180px" pSortableColumn="userApproverDeleted">
                     <div class="p-d-flex p-jc-between p-ai-center">{{ 'PendingSolicitudeComponent.DeletedByApprover.ApproverUserDeleted' | translate }}</div>
                   </th>
                   <th style="width:180px" pSortableColumn="dateApproverDeleted">
                    <div class="p-d-flex p-jc-between p-ai-center">{{ 'PendingSolicitudeComponent.DeletedByApprover.DeletionDate' | translate }}</div>
                  </th>
                 </tr>
               </ng-template>
               <ng-template pTemplate="body" let-solicitud let-editing="editing" let-ri="rowIndex">
                 <tr>
                   <td style="width:20px"></td>
                   <td style="width:180px">
                     {{materialCodeZeroLess(solicitud.materialCode)}}
                   </td>
                   <td style="width:180px">
                     {{solicitud.materialDescription}}
                   </td>
                   <td style="width:180px">
                     {{ solicitud.isPriceList}}
                   </td>
                   <td style="width:180px">
                     {{ solicitud.zofeDescription }}
                   </td>
                   <td style="width:180px">
                     <div *ngIf="solicitud.currentListPrice >= 1">
                       {{solicitud.currentListPrice | currency: 'USD'}}
                     </div>
                     <div *ngIf="solicitud.currentListPrice === 0">
                       {{ "SolicitudComponent.Priceless" | translate }}
                     </div>
                   </td>
                   <td style="width:180px">
                     {{solicitud.currentPsp | currency: 'USD'}}
                   </td>
                   <td style="width:180px">
                     {{solicitud.newPriceWithOutTax | currency: 'USD'}}
                   </td>
                   <td style="width:180px">
                     {{solicitud.priceAdjustment | currency: 'USD'}}
                   </td>
                   <td style="width:180px">
                     {{solicitud.newPsp | currency: 'USD'}}
                   </td>
                   <td style="width:180px">
                     {{solicitud.effectiveDateStart | date:'dd/MM/yyyy'}}
                   </td>
                   <td style="width:180px">
                     {{solicitud.effectiveDateEnd | date:'dd/MM/yyyy'}}
                   </td>
                   <td style="width:180px">
                     {{solicitud.userApproverDeleted}}
                   </td>
                   <td style="width:180px">
                    {{solicitud.dateApproverDeleted | date:'dd/MM/yyyy HH:mm:ss'}}
                  </td>
                 </tr>
               </ng-template>
               <ng-template pTemplate="emptymessage">
                 <tr>
                   <td colspan="5">{{ 'ElementsNotFound' | translate }}</td>
                 </tr>
               </ng-template>
             </p-table>
       </p-accordionTab>
     </p-accordion>
    </div>

  </div>
</section>
<p-toast></p-toast>
