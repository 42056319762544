import { IUpdateStatus } from '@app/@shared/interfaces/update-status.interface';

export class CommercialActivityInterruptModel  implements IUpdateStatus<number,number> {

  constructor(
    public id: number,
    public status: number,
    public commentary: string,
    public interruptDate: Date
  ) { }
}

export class CommercialActivityInterruptBatchModel  implements IUpdateStatus<number[],number> {

  constructor(
    public id: number[],
    public status: number,
    public commentary: string,
    public interruptDate: Date
  ) { }
}