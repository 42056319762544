import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileBaseHelper {

  /**
   * @description Generar a base64 los archivos que se desea enviar serializados.
   * @author Elkin Vasquez Isenia
   * @sprint 4
   * @param inputFile any
   */
  convertFileBase64(inputFile: any) {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve) => {
      // Leer el archivo
      temporaryFileReader.readAsDataURL(inputFile);
      // Capturar error
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        resolve('Problemas al intentar convertir el archivo a Base64.');
      };
      // Procesarlo
      temporaryFileReader.onload = () => {
        resolve(this.base64ToString(temporaryFileReader.result));
      };
    });
  }

  /**
   * @description Convertir de File a Byte[] (string)
   * @author Elkin Vasquez Isenia
   * @sprint 4
   * @param file any
   */
  private base64ToString(file: any): string {
    return file !== undefined ? file.split(',')[1] : '';
  }


  /**
   * @description Método asincrono para generar el base64
   * @author Elkin Vasquez
   * @sprint 1
   * @param event any
   */
  async getFileBase(file: any) {
    const fileContents: any = await this.convertFileBase64(file);
    return fileContents;
  }


    /**
   * @description Método asincrono para convertir csv
   * @author Elkin Vasquez
   * @sprint 7
   * @param file File
   */
  async parseCsvFile(file: File) {
    // Always return a Promise
    return new Promise((resolve, reject) => {
        let content = '';
        const reader = new FileReader();
        // Wait till complete
        reader.onloadend = function (e: any) {
            content = e.target.result;
            const result = content.split(/\r\n|\n/);
            resolve(result);
        };
        // Make sure to handle error states
        reader.onerror = function (e: any) {
            reject(e);
        };
        reader.readAsText(file);
    });
}


}
