import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '@app/@shared/modules/prime-ng.module';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { SharedModule } from '@app/@shared';
import { AdvancedFiltersComponent } from './advanced-filters/advanced-filters.component';
import { HierarchyAdvancedFiltersComponent } from './hierarchy-advanced-filters/hierarchy-advanced-filters.component';
import { CreatePromotionComponent } from './create-promotion/create-promotion.component';
import { DoughnutChartComponent } from './charts/doughnut-chart/doughnut-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { ChartModule } from 'primeng/chart';
import { KnobModule } from 'primeng/knob';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { AdvancedClientFilterComponent } from './advanced-client-filter/advanced-client-filter.component';
import { TablesWithGroupsComponent } from './tables-with-groups/tables-with-groups.component';
import { CommercialActivityFooterComponent } from './commercial-activity-footer/commercial-activity-footer.component';
import { AssingClientsComponent } from './assing-clients/assing-clients.component';
import { AdvancedRedeemableFilterComponent } from './advanced-redeemable-filter/advanced-redeemable-filter.component';
import { CommercialActivityCalculateComponent } from './commercial-activity-calculate/commercial-activity-calculate.component';
import { FilterBotClientsComponent } from './filter-bot-clients/filter-bot-clients.component';
import { AdvancedUpCustomerFilterComponent } from './advanced-up-customer-filter/advanced-up-customer-filter.component';
import { CommercialActivityApprovalSelectedComponent } from './commercial-activity-approval-selected/commercial-activity-approval-selected.component';
import { TextMaskModule } from 'angular2-text-mask';
import { CommercialActivityPlannedComponent } from './commercial-activity-planned/commercial-activity-planned.component';
import { CommercialActivityHistoricalComponent } from './commercial-activity-historical/commercial-activity-historical.component';
import { CommercialActivityInvalidComponent } from './commercial-activity-invalid/commercial-activity-invalid.component';
import { SharedAdvancedFilterComponent } from './shared-advanced-filter/shared-advanced-filter.component';
import { GeneralAdvancedFilterComponent } from './shared-advanced-filter/general-advanced-filter/general-advanced-filter.component';
import { ClientsAdvancedFilterComponent } from './shared-advanced-filter/clients-advanced-filter/clients-advanced-filter.component';
import { FilterMaterialDetailComponent } from './hierarchy-advanced-filters/filter-material-detail/filter-material-detail.component';
import { ClientsCsvComponent } from './assing-clients/clients-csv/clients-csv.component';
import { CustomerSummaryComponent } from './assing-clients/customer-summary/customer-summary.component';
import { PriceCertificateComponent } from './price-certificate/price-certificate.component';
import { AdvancedUserFilterComponent } from './advanced-user-filter/advanced-user-filter.component';
import { HandlingProductFilterComponent } from './handling-product-filter/handling-product-filter.component';
import { CalendarPeriodComponent } from './elements/calendar-period/calendar-period.component';
import { SwitchComponent } from './elements/switch/switch.component';
import { CommercialActivityDocumentComponent } from './commercial-activity-document/commercial-activity-document.component';
import { UploadExcelComponent } from './upload-excel/upload-excel.component';

@NgModule({
  declarations: [
    AdvancedFiltersComponent,
    HierarchyAdvancedFiltersComponent,
    CreatePromotionComponent,
    AdvancedClientFilterComponent,
    DoughnutChartComponent,
    BarChartComponent,
    PieChartComponent,
    TablesWithGroupsComponent,
    CommercialActivityFooterComponent,
    CommercialActivityDocumentComponent,
    AssingClientsComponent,
    AdvancedRedeemableFilterComponent,
    CommercialActivityCalculateComponent,
    FilterBotClientsComponent,
    AdvancedUpCustomerFilterComponent,
    CommercialActivityApprovalSelectedComponent,
    CommercialActivityPlannedComponent,
    CommercialActivityHistoricalComponent,
    CommercialActivityInvalidComponent,
    SharedAdvancedFilterComponent,
    GeneralAdvancedFilterComponent,
    ClientsAdvancedFilterComponent,
    FilterMaterialDetailComponent,
    ClientsCsvComponent,
    CustomerSummaryComponent,
    PriceCertificateComponent,
    AdvancedUserFilterComponent,
    HandlingProductFilterComponent,
    CalendarPeriodComponent,
    SwitchComponent,
    UploadExcelComponent,
  ],
  exports: [
    AdvancedFiltersComponent,
    TablesWithGroupsComponent,
    HierarchyAdvancedFiltersComponent,
    CreatePromotionComponent,
    AdvancedClientFilterComponent,
    CommercialActivityFooterComponent,
    CommercialActivityDocumentComponent,
    AssingClientsComponent,
    AdvancedRedeemableFilterComponent,
    CommercialActivityCalculateComponent,
    FilterBotClientsComponent,
    CommercialActivityApprovalSelectedComponent,
    CommercialActivityPlannedComponent,
    CommercialActivityHistoricalComponent,
    CommercialActivityInvalidComponent,
    SharedAdvancedFilterComponent,
    FilterMaterialDetailComponent,
    PriceCertificateComponent,
    AdvancedUserFilterComponent,
    HandlingProductFilterComponent,
    CalendarPeriodComponent,
    SwitchComponent,
    UploadExcelComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PrimeNgModule,
    DividerModule,
    SidebarModule,
    ChartModule,
    KnobModule,
    TextMaskModule
  ]
})
export class ComponentModule { }
