<div [ngClass]="{ 'nav-item': inNavbar }"
     ngbDropdown>
  <ul class="navbar-nav m-0">
    <li class="nav-item dropdown m-2">
      <a *ngIf="inNavbar; else button"
         id="language-dropdown"
         class="nav-link  dropdown-toggle"
         ngbDropdownToggle>
        {{ currentLanguage | translate }}
      </a>
    </li>
  </ul>
  <ng-template #button>
    <button id="language-dropdown"
            class="btn btn-sm btn-secondary"
            ngbDropdownToggle>
      {{ currentLanguage }}
    </button>
  </ng-template>
  <div ngbDropdownMenu
       aria-labelledby="language-dropdown"
       [ngClass]="menuClass">
    <button class="dropdown-item"
            [ngClass]="{ active: language === currentLanguage }"
            *ngFor="let language of languages"
            (click)="setLanguage(language)">
      {{ language | translate }}
    </button>
  </div>
</div>
