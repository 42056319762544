import { IListModuleByUser } from './interfaces/list-module-by-user.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ICombo } from '../../../@shared/interfaces/combo.interface';
import { SelectProfileModuleService } from './select-profile-module.service';
import { ManageParametersService } from '@modules/administration/manage-parameters/manage-parameters.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SweetAlert } from '../../../@shared/enums/sweetalert.enum';
import { SelectProfileModuleMessage } from './messages/select-profile-module.message';
import { IResponseService } from '../../../@shared/interfaces/response-service.interface';
import { MsalService } from '@azure/msal-angular';

import { ManagementParameterEnum } from '@app/@shared/enums/management-parameter.enum';
import { IManageParameters } from '@modules/administration/manage-parameters/interfaces/manage-parameters.interface';

@Component({
  selector: 'app-select-profile-module',
  templateUrl: './select-profile-module.component.html',
  styleUrls: ['./select-profile-module.component.css'],
  providers: [MessageService]
})
export class SelectProfileModuleComponent implements OnInit {
  private readonly selectProfileModuleService: SelectProfileModuleService;
  private readonly spinner: NgxSpinnerService;
  private readonly authService: MsalService;
  private readonly fb: FormBuilder;
  private readonly router: Router;
  private readonly manageParametersService: ManageParametersService;

  public userId: string;
  public userName: string;
  public selectedProfile: ICombo;
  public listProfile: ICombo[] = [];
  public listIsAdminProfile: ICombo[] = [];
  public listModule: IListModuleByUser[];
  public formSelectProfileModule: FormGroup;
  public isProfile: boolean;
  public selectedProfileShow= false;
  public isModule= true;
  public profile: string;
  public language = '';
  public parametersRetriesConnectionIds: Array<number> = [
    ManagementParameterEnum.timeRetries,
    ManagementParameterEnum.retries];

  public objectList : IManageParameters[] = [];

  constructor(
    selectProfileModuleService: SelectProfileModuleService,
    spinner: NgxSpinnerService,
    authService: MsalService,
    fb: FormBuilder,
    router: Router,
    manageParametersService: ManageParametersService,
  ) {
    this.selectProfileModuleService = selectProfileModuleService;
    this.spinner = spinner;
    this.authService = authService;
    this.fb = fb;
    this.router = router;
    this.manageParametersService = manageParametersService;
    
    this.isProfile = false;
    this.isModule = false;
    SessionStorageHelper.remove(CommonConfiguration.ProfileId);
    SessionStorageHelper.remove(CommonConfiguration.ModuleId);
  }

  ngOnInit(): void {
    const userInfo = JSON.parse(SessionStorageHelper.get(CommonConfiguration.UserSession));
    this.userId = userInfo.userId;
    this.userName = userInfo.fullName.split(' ')[0];
    this.getAll();
    this.initForm();
    SessionStorageHelper.remove(CommonConfiguration.ProfileId);
    SessionStorageHelper.remove(CommonConfiguration.ModuleId);
    this.forkJoinData();
  }

  ngChecked(){
    this.language = sessionStorage.getItem('language');
  }

  getAll(): void {

    this.spinner.show();

    for(let i = 0; i < this.parametersRetriesConnectionIds.length; i++)
    {

      this.manageParametersService.getById(this.parametersRetriesConnectionIds[i]).subscribe(
        (response) => {
          if(i === 0)
          {
            const timeConnectionRetries = (response == null )
            ? response.data.value
            : '5000';

            SessionStorageHelper.save(CommonConfiguration.TimeConnectionRetries, timeConnectionRetries);
          }
          else if(i === 1)
          {
            const connectionRetries = response.data.value == null
                  ? response.data.value
                  : '3';

             SessionStorageHelper.save(CommonConfiguration.ConnectionRetries, connectionRetries);
          }


          this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        });
    }
  }

  /**
   * @description this method is to get data to forkJoin.
   * @author Priscy Antonio Reales
   * @sprint 12
   */
  forkJoinData(){
      const profileResponse = this.selectProfileModuleService.getListProfileByUser(this.userId);
      this.spinner.show();

      forkJoin([profileResponse])
        .subscribe(
          (response) => {
            this.isProfile = false;
            const listProfile: IResponseService<ICombo[]> = response[0];
            if (listProfile.data.length === 0) {
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                language === 'es-ES' ? SelectProfileModuleMessage.ERROR_PROFILE_MODULE : SelectProfileModuleMessage.ERROR_PROFILE_MODULE_TRANSLATE);
              setTimeout(() => {
                this.spinner.show();
                this.logout();
                this.spinner.hide();
              }, 5000);
            }

            if (listProfile.data.length === 1) {
              const profileId = listProfile.data[0].value;
              const profileDescription = listProfile.data[0].label;
              this.profile = profileDescription;
              SessionStorageHelper.save(CommonConfiguration.ProfileId, `${profileId}`);
              SessionStorageHelper.save(CommonConfiguration.Profile, profileDescription);
              this.getAllModuleByUser();
              this.isModule = true;
              this.selectedProfileShow = true;
            }
          },
          (error) => {
            SweetAlertMessageHelpers.exception(error);
          },
          () => {
            this.spinner.hide();
          }
      );

  }

  /**
   * @description this method is to logout the user when not profile and module.
   * @author Elkin Vasquez Isenia
   * @sprint 2
   */
  logout() {
    SessionStorageHelper.removeAll();
    SessionStorageHelper.remove(CommonConfiguration.Token);
    SessionStorageHelper.remove(CommonConfiguration.ProfileId);
    SessionStorageHelper.remove(CommonConfiguration.ModuleId);
    sessionStorage.removeItem(CommonConfiguration.FirstLoad);
    this.authService.logout();
  }

  /**
     * @description this method is to get all the profile of users.
     * @author Elkin Vasquez Isenia
     * @sprint 2
     */
  async getAllModuleByUser(isRedirect: boolean = false) {
    this.spinner.show();
    await this.selectProfileModuleService.getListModuleUser()
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.listModule = response.data;

          if (isRedirect) {
            const moduleId = this.listModule[0].id;
            SessionStorageHelper.save(CommonConfiguration.ModuleId, `${moduleId}`);
            this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
            this.router.navigate(['auth']);
          }

        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }

  onModule(id: number) {

    SessionStorageHelper.remove(CommonConfiguration.ModuleId);
    sessionStorage.removeItem(CommonConfiguration.FirstLoad);

    SessionStorageHelper.save(CommonConfiguration.ModuleId, `${id}`);
    sessionStorage.setItem(CommonConfiguration.FirstLoad, 'false');

    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    this.router.navigate(['auth']);
  }

  /**
* @description this method is to initialize the form.
* @author Elkin Vasquez Isenia
* @sprint 2
*/
  initForm(): void {
    this.formSelectProfileModule = this.fb.group({
      selectProfile: [{ value: [], disabled: false }, Validators.required],
    });
  }

  get selectProfile(): AbstractControl { return this.formSelectProfileModule.get('selectProfile'); }
}