import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, ObservableInput } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { HttpService } from '@app/@shared/services/http.service';

import { IResponseService } from '@app/@shared/interfaces/response-service.interface';

import { CommercialActivityCalculateImpact } from './models/commercial-activity-calculate-impact.model';
import { ICommercialActivityStateImpact } from './interfaces/commercial-activity-state-impact.interface';


@Injectable({
  providedIn: 'root'
})
export class CommercialActivityCalculateService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService)
  {
    this.httpService = httpService;
  }

  /**
   * @description This method is to send commercial activity to calculate.
   *
   * @author Diego Mauricio Cortés
   * @param body
   * @sprint 15
   */
   public postPromotionalActivityCalculate(body: CommercialActivityCalculateImpact): Observable<IResponseService<any>> {
    const response$: ObservableInput<IResponseService<any>>  = this.httpService
    .post(
      `${environment.apiAnalytics}/api/v1/ccvp_apwb_comm/impact`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$ as Observable<IResponseService<any>>;
  }

  public getPromotionalActivityById(id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/commercial_activity_by_id/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public getPromotionalActivityCalcute(id: string, codactivity: number, slug: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
      .get(
        `${environment.apiAnalytics}/api/v1/ccvp_apwb_comm/impact/${id}/${codactivity}/${slug}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
        })
      );
    return response$;
  }
  
  
  public getPromotionalActivityStateById(id: string): Observable<ICommercialActivityStateImpact> {
    const response$: Observable<any> =
     this.httpService.get(`${environment.apiAnalytics}/api/v1/ccvp_apwb_stat/state/${id}/CostumerLoyalty`)
      .pipe(
        timeout(5000),
        map((response: HttpResponse<IResponseService<ICommercialActivityStateImpact>>) => {
          return response.body;
        })
      );
    return response$;
  }

}
