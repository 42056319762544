import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-negotiacion',
  providers: [],
  templateUrl: './negotiation.component.html',
  styleUrls: ['./../../../../app/@shared/scss/responsive.table.scss'],
  styles: [
    `
    .generalData .options{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    `,
  ],
})
export class NegotiationComponent implements OnInit {
  private readonly router: Router;
  constructor(
    router: Router,
  ){
    this.router = router;
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  create(){
    this.router.navigate(['/auth/negotiation/create']);
  }

}
