import { IPagination } from './../../../../../@shared/interfaces/pagination.interface';

export class PaginationApprovalRankLevelModel implements IPagination {


  public profile: string;
  public countryCode: string;
  public societyCode: string;
  public channelCode: string;
  public regionCode: string;
  public cediCode: string;
  public processDomainId: number;
  public subprocessDomainId: number;
  public orderBy: string;
  public ascendent: boolean;
  public pageNumber: number;
  public resultsPerPage: number;

}
