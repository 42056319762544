import { SweetAlertMessageHelpers } from './helpers/sweet-alert-message.helper';
import { Guid } from 'guid-typescript';
import { CommonConfiguration } from './enums/common.enums';
export class Util {
  static confirmDelete() {
    const language: string = sessionStorage.getItem('language');
    let title = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de eliminar el registro?' : 'Are you sure you want to delete the record?';
    let text = language === CommonConfiguration.LanguageKey ? 'Ten cuidado, esta opción no puede ser reversada' : 'Be careful, this option cannot be reversed';
    return SweetAlertMessageHelpers.confirmDelete(
      title,
      text
    );
  }

  static confirmDeleteNode() {
    const language: string = sessionStorage.getItem('language');
    let title = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de eliminar el registro?' : 'Are you sure you want to delete the record?';
    let text = language === CommonConfiguration.LanguageKey ? 'Ten cuidado, esta opción no puede ser reversada. Si seleccionó un nodo superior se eliminarán todos los usuarios que esten en la parte inferior.' : 'Be careful, this option cannot be reversed.If a superior node was selected, all the users that are in the lower part will be eliminated.';
    return SweetAlertMessageHelpers.confirmDelete(
      title,
      text
    );
  }

  static confirmEdit() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de actualizar el registro?' : 'Are you sure to update the registry?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmAction(msn: string, typeMsn: boolean){
    const msnEnglish = typeMsn ? 'do you  want to activate this user?' : 'do you  want to deactivate this user?';

    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? msn : msnEnglish;
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmSee() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás segura de  ver el registro?' : 'Are you sure you see the log?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmRule(){
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Desea confirmar la regla traslapada?' : 'Do you want to confirm the overlapping rule?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmCopy() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de copiar el registro?' : 'Are you sure to copy the registry?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmSave() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de guardar el registro?' : 'Are you sure to save the record?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmRejection() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de rechazar la solicitud?' : 'Are you sure to decline the request?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmInterrupt() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de suspender el usuario?' : 'Are you sure to suspend the user?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmApprove() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de aprobar la solicitud?' : 'Are you sure to approve the request?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmEditStatus() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de cambiar el estado al registro?' : 'Are you sure to change the status to registration?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmEditFile() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de cambiar el acta de precio?' : 'Are you sure to change the price act?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmEditFileCsv() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de cambiar el archivo Csv?' : 'Are you sure to change the Csv file?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmFileExcel() {
    const language: string = sessionStorage.getItem('language');
    let title = language === CommonConfiguration.LanguageKey ? '¿Estás seguro de cargar un archivo Excel?' : 'Are you sure to upload the Excel file?';
    let text = language === CommonConfiguration.LanguageKey ? 'Si hay algún material filtrado en el proceso, se eliminará' : 'If there is any leaked material in the process, it will be removed.';
    return SweetAlertMessageHelpers.confirm(
      title,
      text
    );
  }

  static confirmSendSolicitude() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro que quieres enviar la solicitud?' : 'Are you sure you want to submit the request?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmCancel() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro que quieres cancelar la solicitud?' : 'Are you sure you want to cancel the request?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static confirmSendApproval() {
    const language: string = sessionStorage.getItem('language');
    let text = language === CommonConfiguration.LanguageKey ? '¿Estás seguro que quieres aprobar las reglas?' : 'Are you sure you want to approve the rules?';
    return SweetAlertMessageHelpers.confirm(
      '',
      text
    );
  }

  static optionalMessage(message: string) {
    return SweetAlertMessageHelpers.confirm(
      '',
      message
    );
  }

  static showWarning(title: string, text: string) {
    return SweetAlertMessageHelpers.warning(title, text);
  }

  static showSuccess(title: string, text: string) {
    return SweetAlertMessageHelpers.success(title, text);
  }

  static showMessage(typeMessage: number) {
    const language: string = sessionStorage.getItem('language');
    let titleAlert: string;
    let messageAlert: string;
    switch (typeMessage) {
      case 0: {
        titleAlert = language === CommonConfiguration.LanguageKey ? 'Hecho' : 'Done';
        messageAlert = language === CommonConfiguration.LanguageKey ? '¡Registro creado satisfactoriamente!' : 'Record successfully created!';
        return SweetAlertMessageHelpers.success(titleAlert, messageAlert);
      }
      case 1: {
        titleAlert = language === CommonConfiguration.LanguageKey ? 'Hecho' : 'Done';
        messageAlert = language === CommonConfiguration.LanguageKey ? '¡Registro actualizado satisfactoriamente!' : 'Registry Updated Successfully!';
        return SweetAlertMessageHelpers.success(titleAlert, messageAlert);
      }
      default: {
        titleAlert = 'Error';
        messageAlert = language === CommonConfiguration.LanguageKey ? '¡No se pudo ejecutar el proceso!' : 'The process could not be executed!';
        return SweetAlertMessageHelpers.error(titleAlert, messageAlert);
      }
    }
  }

  static newGuid(): any {
    return Guid.create();
  }

  static keyupLetterValidation(event: any): any {
    const regex = /^[a-zA-ZáéíóúAÉÍÓÚÑñ\s]+$/;
    if (regex.exec(event.target.value)) {
      return event.target.value;
    } else {
      return event.target.value.substr(0, event.target.value.length - 1);
    }
  }

  static keyupLetterWithNumbersValidation(event: any): any {
    const regex = /^[0-9a-zA-ZáéíóúAÉÍÓÚÑñ\s]+$/;
    if (regex.exec(event.target.value)) {
      return event.target.value;
    } else {
      return event.target.value.substr(0, event.target.value.length - 1);
    }
  }

  static keyupLetterWithNumbersValidation2(event: any): Boolean {
    const regex = /^[0-9a-zA-ZáéíóúAÉÍÓÚÑñ\s]+$/;
    if (regex.exec(event.target.value)) {
      return false;
    } else {
      return true;
    }
  }

  static keyUpOnlyNumber(event: any){
    const regex = /^[0-9,$]*$/;

    if(regex.exec(event.target.value)){
      return event.target.value;
    }else{
      return event.target.value.substr(0, event.target.value.length - 1);
    }
  }

  static keyUpOnlyNumberWithPoint(event: any){
    const regex = /^[0-9,$,.]*$/;
    if(regex.exec(event.target.value)){
      return event.target.value;
    }else{
      return event.target.value.substr(0, event.target.value.length - 1);
    }
  }

}
