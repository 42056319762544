import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MessageService } from 'primeng/api';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';

import { DomainService } from '@app/@shared/services/domain.service';

import { IFieldsPromotion } from './interface/fields-promotion.interface';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { IDataRange } from './interface/data-range.interface';

import { DataRangeModel } from './models/data-range.model';
import { PromotionModel } from './models/promotion.model';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';

import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';

@Component({
  selector: 'app-create-promotion',
  templateUrl: './create-promotion.component.html',
  styleUrls: ['./../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePromotionComponent implements OnInit {

  @Input('title') title: string;
  @Input('routeCancel') routeCancel: string;
  @Input('showFields') showFields: IFieldsPromotion;
  @Input('showButtons') showButtons: boolean;
  @Input('isProcess') isProcess: number;
  @Input('promotionEdit') promotionEdit: PromotionModel;
  @Output('eventClickSaveBtn') eventButton: EventEmitter<any> = new EventEmitter();
  @Output('eventClickEditBtn') eventButtonEdit: EventEmitter<any> = new EventEmitter();

  private readonly fb: FormBuilder;
  private readonly domainService: DomainService;

  public get comercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }
  public formPromotion: FormGroup;
  public rangeDateSelectList: IDataRange[] = [];
  public cantDays = 0;
  public objectCommercialActivityStatusList: ICombo[];
  public objectCommercialActivityStatus: any[] = [];
  public today: Date = new Date();
  public currentYear = 0;
  public endYear = 0;
  public invalidDates: Date[] = [];

  constructor(
    fb: FormBuilder,
    domainService: DomainService)
  {
    this.fb = fb;
    this.domainService = domainService;
  }

  ngOnInit(): void {
    this.getCommercialActivityStatus();
    this.initForm();
    this.prepareDefaultTimes();
  }

  /**
   * @description This method is to prepare default times to select
   * @author Diego Mauricio Cortés
   * @sprint 9
   */

  private prepareDefaultTimes() {
    const start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    this.startTime.setValue(start);
    const end = new Date();
    end.setHours(23);
    end.setMinutes(59);
    this.endTime.setValue(end);
  }

  /**
   * @description This method is to wait the event changes
   * in father promotion, and apply the changes in this view
   * @param changes
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const change: SimpleChange = changes['promotionEdit'];
    if (change && this.promotionEdit) {
      this.cantDays = this.promotionEdit.numberDays;
        this.days.setValue(this.cantDays);
      this.orderLimit.setValue(this.promotionEdit.orderLimit);
        this.periodLimit.setValue(this.promotionEdit.periodLimit);
      this.commercialActivityState.setValue(this.getCommercialActivityName(this.promotionEdit.statusDomainId));
      this.promotionEdit.dataRanges.forEach((range) => {
        const diffDays = this.getDateDiff(range.dateStart, range.dateEnd);
        this.addInvalidDates(diffDays, range.dateStart, range.dateEnd);
      });
      this.rangeDateSelectList = this.promotionEdit.dataRanges;

      const start = new Date();
      const startTimeEd = this.promotionEdit.startTime.split(':');
      start.setHours(parseInt(startTimeEd[0], 10));
      start.setMinutes(parseInt(startTimeEd[1], 10));
      this.startTime.setValue(start);
      const end = new Date();
      const endTimeEd = this.promotionEdit.endTime.split(':');
      end.setHours(parseInt(endTimeEd[0], 10));
      end.setMinutes(parseInt(endTimeEd[1], 10));
      this.endTime.setValue(end);
    }
  }

  /**
   * @description This method is to get commercial activity name
   * @param id
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private getCommercialActivityName(id: number): string {
    const x = this.objectCommercialActivityStatus.map((c) => {
      if (c.id === this.promotionEdit.statusDomainId) {
        return c.description;
      }
      return;
    });
    return x.length > 0 && x[0] !== undefined ? x[0] : '';
  }

  /**
   * @description This method is to get commercial activities status
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  getCommercialActivityStatus() {
    this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.CommercialActivityStatus).subscribe(
      (response) => {
        this.objectCommercialActivityStatusList = response.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim()
          };
        });
        this.objectCommercialActivityStatus = [...response.data];
        this.commercialActivityState.setValue('RE');
      });
  }

  /**
   * @description this method is to emit event the father.
   * @param $event
   * @author Diego Mauricio Cortés
   * @sprint 8
   */

  eventClickSaveBtn($event: any): void {
    const minuteStart = this.startTime.value.getMinutes() < 10 ? `0${this.startTime.value.getMinutes()}` : this.startTime.value.getMinutes();
    const minuteEnd = this.endTime.value.getMinutes() < 10 ? `0${this.endTime.value.getMinutes()}` : this.endTime.value.getMinutes();
    if (this.startTime.value < this.endTime.value) {
      const status: any = this.objectCommercialActivityStatus.find((z: any) => z.code === this.commercialActivityState.value);
      const promotion = new PromotionModel(
        this.cantDays,
        `${this.startTime.value.getHours()}:${minuteStart}:00`,
        `${this.endTime.value.getHours()}:${minuteEnd}:00`,
        this.orderLimit.value,
        this.periodLimit.value,
        status.id,
        this.rangeDateSelectList
      );
      this.eventButton.emit(promotion);
    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(
        SweetAlert.titleAlertError,
        language === 'es-ES' ? 'La hora de inicio debe ser menor a la hora de fin.': 'The start time must be less than the end time.');
    }
  }

  /**
   * @description This method is to prepare data to edit promotion and emit info
   * to edit promotional Activity
   * @param $event
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  eventClickEditBtn($event: any): void {
    const minuteStart = this.startTime.value.getMinutes() < 10 ? `0${this.startTime.value.getMinutes()}` : this.startTime.value.getMinutes();
    const minuteEnd = this.endTime.value.getMinutes() < 10 ? `0${this.endTime.value.getMinutes()}` : this.endTime.value.getMinutes();
    if (this.startTime.value < this.endTime.value) {
      const status = this.objectCommercialActivityStatus.find((z: any) => z.code === this.commercialActivityState.value);
      const promotion = new PromotionModel(
        this.cantDays,
        `${this.startTime.value.getHours()}:${minuteStart}:00`,
        `${this.endTime.value.getHours()}:${minuteEnd}:00`,
        this.orderLimit.value,
        this.periodLimit.value,
        this.promotionEdit.statusDomainId,
        this.rangeDateSelectList
      );
      this.eventButtonEdit.emit(promotion);
    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'La hora de inicio debe ser menor a la hora de fin.'
        : 'The start time must be less than the end time.');
      return;
    }
  }

  /**
   * @description This method is for validate date control.
   * @author Diego Mauricio Cortés
   * @sprint 8
   */

  getYearRange() {
    this.currentYear = this.today.getFullYear();
    this.endYear = this.currentYear + 10;
    return `${this.currentYear}:${this.endYear}`;
  }

  /**
   * @description This method is to add range data to selectRangeData
   * @author Diego Mauricio Cortés
   * @sprint 8
   */

  addRangeDate(): void {
    if (this.rangeDateControl.value) {
      let lstRangeDate = this.rangeDateControl.value.toString().split(',');
      let startDate = new Date(lstRangeDate[0]);
      let endDate = new Date(lstRangeDate[1]);

      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {

        if (!this.validateDatesInDataRange(startDate, endDate)) {
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? 'Ya se ha seleccionado el rango de fechas.' : 'The date range has already been selected.');
          this.rangeDateControl.setValue(null);
          return;
        }

        const days = parseInt(`${(this.getDateDiff(startDate, endDate) + 1)}`, 10);
        this.cantDays += days;

        this.addInvalidDates(days, startDate, endDate);

        const rangeDateData = new DataRangeModel(
          startDate,
          endDate,
          days
        );
        this.rangeDateSelectList.push(rangeDateData);
        this.days.setValue(this.cantDays);
      }

      this.rangeDateControl.setValue(null);
    }
  }

  /**
   * @description This method is to add invalid dates to disabled in calendar
   * @param days
   * @param startDate
   * @param endDate
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private addInvalidDates(days: number, startDate: Date, endDate: Date) {
    for (let i = 0; i <= days; i++) {
      if (i < days) {
        const start = new Date(startDate);
        start.setDate(start.getDate() + i);
        this.invalidDates.push(start);
      } else {
        this.invalidDates.push(new Date(endDate));
      }
    }
  }

  /**
   * @description This method is to validate if the dates is into dateRange
   * @param startDate
   * @param endDate
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private validateDatesInDataRange(startDate: Date, endDate: Date): boolean {
    for (let i = 0; i < this.invalidDates.length; i++) {
      if (this.invalidDates[i] >= startDate && this.invalidDates[i] <= endDate) {
        return false;
      }
    }
    return true;
  }

  /**
   * @description This method is to get days between two dates
   * @param start
   * @param end
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  getDateDiff(start: Date, end: Date) {
    const operation = 1000 * 60 * 60 * 24;
    return Math.abs((end.getTime() - start.getTime()) / (operation));
  }

  /**
   * @description This method is to delete a selected range date
   * @param indexRangeDate
   * @author Diego Mauricio Cortés
   * @sprint 8
   */

  deleteRangeDateSelected(indexRangeDate: number) {
    const dataRange = this.rangeDateSelectList[indexRangeDate];

    const invalidDates = [...this.invalidDates];
    invalidDates.forEach((inv, index) => {
      if (inv >= dataRange.dateStart && inv <= dataRange.dateEnd) {
        const ix = this.invalidDates.findIndex((fn) => fn === inv);
        this.invalidDates.splice(ix, 1);
      }
    });

    this.cantDays = this.cantDays - dataRange.cantDays;
    this.days.setValue(this.cantDays);
    this.rangeDateSelectList.splice(indexRangeDate, 1);
  }

  /**
   * @description This method is to init the form controls
   * @author Diego Mauricio Cortés
   * @sprint 9
   */

  initForm(): void {
    this.formPromotion = this.fb.group({
      rangeDateControl: [{ value: '', disabled: false }, []],
      days: [{ value: '', disabled: true }, [Validators.required]],
      startTime: [{ value: '', disabled: false }, [Validators.required]],
      endTime: [{ value: '', disabled: false }, [Validators.required]],
      periodLimit: [{ value: '', disabled: false }, [Validators.required]],
      orderLimit: [{ value: '', disabled: false }, [Validators.required]],
      commercialActivityState: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.update }, [Validators.required]]
    });
  }

  get rangeDateControl(): AbstractControl { return this.formPromotion.get('rangeDateControl'); }
  get days(): AbstractControl { return this.formPromotion.get('days'); }
  get startTime(): AbstractControl { return this.formPromotion.get('startTime'); }
  get endTime(): AbstractControl { return this.formPromotion.get('endTime'); }
  get orderLimit(): AbstractControl { return this.formPromotion.get('orderLimit'); }
  get periodLimit(): AbstractControl { return this.formPromotion.get('periodLimit'); }
  get commercialActivityState(): AbstractControl { return this.formPromotion.get('commercialActivityState'); }
}
