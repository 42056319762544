import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { PaginationModel } from '@app/@shared/model/pagination.model';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IValidityPeriod } from './interfaces/validity-period.interface';
import { MaximumPeriodValidityCreateModel } from './models/maximum-period-validity-create.model';
import { MaximumPeriodValidityModel } from './models/maximum-period-validity.model';

const endpointController = '/api/v1/ccvp_apwb_core/maximum_period_validity/';

@Injectable({
  providedIn: 'root'
})

export class ValidityPeriodService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService
  ) {
    this.httpService = httpService;
  }

  /**
   * @description This method is to get all the Validity Period.
   * @author kmora
   * @param params
   * @returns Observable<IResponseService<IValidityPeriod[]>>
   * @sprint 9
  */
  public getAll(pagination:PaginationModel): Observable<IResponseService<IValidityPeriod[]>> {
    const response$: Observable<IResponseService<IValidityPeriod[]>> =
     this.httpService
      .patch(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_maximum_period_validity`, pagination)
      .pipe(
        map((response: HttpResponse<IResponseService<IValidityPeriod[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to create Validity Period.
   * @author kmora
   * @param params
   * @returns Observable<IResponseService<number>>
   * @sprint 9
  */
  public post(body: MaximumPeriodValidityCreateModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}${endpointController}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to update Validity Period.
   * @author kmora
   * @param params
   * @returns Observable<IResponseService<number>>
   * @sprint 9
  */
  public put(id: number, body: MaximumPeriodValidityModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}${endpointController}${id}`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to delete a Validity Period.
   * @author kmora
   * @param params
   * @returns Observable<IResponseService<boolean>>
   * @sprint 9
  */
  public deleteLogic(id: number): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService.delete(`${environment.apiUrlCore}${endpointController}logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

}
