export enum TypeCoreMaster {
    Country = 'countries',
    Society = 'societies',
    Sector = 'sectors',
    Region = 'regions',
    Cedi = 'cedi',
    Channel = 'channels',
    Role = 'role',
    Macrosegments = 'macrosegments',
    Subtypology = 'subtypology',
    Typology = 'typology',
    CustomerLevelI = 'customerHierarchyOne',
    CustomerLevelII = 'customerHierarchyTwo',
    BusinessTypologies = 'business_typologies',
    MeasurementUnit = 'measurement_units',
    PurchaseMissions = 'purchase_missions',
    SalesForcesType = 'sales_forces_type',
    SalesBoss = 'sales_boss',
    Supervisor = 'supervisor',
    Representative = 'representative',
    Customer= 'customer',
    WorkPosition= 'post',
    Handler= 'commercial_activity_handler',
    HandlerCurrentMonth = 'commercial_activity_handler_current_month'

  }
