<p-dialog 
  header="{{'ManagePromotionalActivitiesComponent.Create.InvalidationModalTitle' | translate}}"					
  [(visible)]="isVisible" 
  [style]="{width: '50vw'}"
  (onHide)="resetModalInterrupt()"  >

	<div class="row">
		<div class="col-md-12">
			<p>
				<strong>{{ 'ManagePromotionalActivitiesComponent.Create.InvalidationModalContent' | translate }}</strong>
			</p>
			<br/>
			<p-table #dt
							name="gridCommercialActivity"
							[value]="invalidationDataDetail"
							dataKey="id"
							[paginator]="false"
							[rows]="10"
							paginatorPosition="bottom"
							styleClass="p-datatable-customers"
							currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
							responsiveLayout="scroll"
							[globalFilterFields]="['id', 'clients', 'skus']"
							[scrollable]="true" scrollHeight="400px">
				<ng-template pTemplate="header">
						<tr>
								<th pSortableColumn="id">
									<div class="p-d-flex p-jc-between p-ai-center">
                    ID <p-sortIcon field="id"></p-sortIcon>
                  </div>
								</th>
								<th pSortableColumn="clients">
									<div class="p-d-flex p-jc-between p-ai-center">
                    {{ 'ManagePromotionalActivitiesComponent.Create.RepeatedClients' | translate }}
										<p-sortIcon field="clients"></p-sortIcon>
                  </div>
								</th>
								<th>{{ 'ManagePromotionalActivitiesComponent.Create.SKUCodes' | translate }}</th>
						</tr>
				</ng-template>
				<ng-template pTemplate="body" let-act>
						<tr>
								<td>{{act.id}}</td>
								<td class="td-center">
                  {{act.clients}}  <p-button class="btn-padding-10" label="Descargar" styleClass="p-button-text" (click)="downloadListClients(act.id)"></p-button>
                </td>
								<td style="font-size: 15px;">{{act.skus}}</td>
						</tr>
				</ng-template>

			</p-table>
			<br/>
			<p>
				<strong>{{ 'ManagePromotionalActivitiesComponent.Create.InvalidationModalVerify' | translate }}</strong>
			</p>
		</div>
		<br/>
		<br/>
      <div class="row p-2">
        <div class="col-12">
          <p-radioButton name="typeFilter"
            value="1" 
            label="{{ 'ManagePromotionalActivitiesComponent.Create.InterruptAllVerify' | translate }}"
            [(ngModel)]="selectedRadioButton">
          </p-radioButton>
        </div>
        <div class="col-12">
          <p-radioButton name="typeFilter"
            value="2"
            label="{{ 'ManagePromotionalActivitiesComponent.Create.InvalidationModalVerify' | translate }}"
            [(ngModel)]="selectedRadioButton">
          </p-radioButton>
        </div>
      </div>
      <div class="row p-2" [formGroup]="formCommercialActivityInterrupt" *ngIf="selectedRadioButton == 1">
        <div class="col-md-10">
          <label class="col-12 p-d-flex p-jc-between p-ai-center">
            <strong>{{ 'ManagePromotionalActivitiesComponent.InterruptionDate' | translate }}</strong>
          </label>
          {{dateInterruptionActivity | date:'dd/MM/yyyy'}}
    
          <small 
            id="dateInterruption"
            *ngIf="
              formCommercialActivityInterrupt.controls['dateInterruption'].invalid &&
              (formCommercialActivityInterrupt.controls['dateInterruption'].dirty ||
                formCommercialActivityInterrupt.controls['dateInterruption'].touched)
            "
            class="p-error">{{"ManagePromotionalActivitiesComponent.InterruptionDateMsg" | translate}}</small>
        </div>
        <div class="col-md-10">
          <label class="p-d-flex p-jc-between p-ai-center">
            <strong>{{ 'ManagePromotionalActivitiesComponent.InterruptionReason' | translate }}</strong>
          </label>
          <textarea [rows]="5"
                    [cols]="30"
                    pInputTextarea
                    autoResize="autoResize"
                    id="descriptionInterruption"
                    formControlName="descriptionInterruption"
                    autocomplete="off"
                    class="form-control p-d-flex p-jc-between p-ai-center"
                    required>
                  </textarea>
              <small 
                id="descriptionInterruption"
                *ngIf="
                  formCommercialActivityInterrupt.controls['descriptionInterruption'].invalid &&
                  (formCommercialActivityInterrupt.controls['descriptionInterruption'].dirty ||
                    formCommercialActivityInterrupt.controls['descriptionInterruption'].touched)
                "
                class="p-error">{{"ManagePromotionalActivitiesComponent.InterruptionReasonMsg" | translate}}
              </small>
        </div>
      </div>
	</div>
  <br>
  
	<ng-template pTemplate="footer">
    <div *ngIf="selectedRadioButton == 1">
			<p-button 
				(click)="saveInterruption()"
				label="{{ 'ManagePromotionalActivitiesComponent.Create.InterruptAll' | translate }}" 
				styleClass="pstn_primario"></p-button>
			<button
        type="button"
				class="pstn_primario"
				style="background-color: darkgrey; border-color: darkgrey;"       
        (click)="hideModalInterrupt()">
        {{ 'CancelBtn' | translate }}
      </button>
		</div>
		<div *ngIf="selectedRadioButton == 2">
			<p-button 
				(click)="eventClickInvalidateCommercialActivity(true)" 
				label="Si" 
				styleClass="pstn_primario"></p-button>
			<button 
				type="button"
				(click)="eventClickInvalidateCommercialActivity(false)" 
				class="pstn_primario"
				style="background-color: darkgrey; border-color: darkgrey;">No</button>
		</div>
		
	</ng-template>

</p-dialog>