import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { MessageService } from 'primeng/api';

import { DomainService } from '@app/@shared/services/domain.service';

import { IFieldsCommercialActivityFooter } from './interfaces/fields-commercial-activity-footer.interface';
import { IDataRange } from './interfaces/data-range.interface';
import { ICombo } from '@app/@shared/interfaces/combo.interface';

import { CommercialActivityFooterModel } from './models/commercial-activity-footer.model';
import { DataRangeModel } from './models/data-range.model';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';
import { ValidityPeriodService } from '@app/@modules/promotionalactivity/validity-period/validity-period.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PaginationModel } from '@app/@shared/model/pagination.model';
import { IValidityPeriod } from '@app/@modules/promotionalactivity/validity-period/interfaces/validity-period.interface';
import { CommercialActivityFooterMessage } from './messages/commercial-activity-footer.message';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { parse } from 'date-fns';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';

@Component({
  selector: 'app-commercial-activity-footer',
  templateUrl: './commercial-activity-footer.component.html',
  styleUrls: ['./../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommercialActivityFooterComponent implements OnInit, OnChanges {

  @Input('title') title: string;
  @Input('routeCancel') routeCancel: string;
  @Input('showFields') showFields: IFieldsCommercialActivityFooter;
  @Input('showButtons') showButtons: boolean;
  @Input('promotionEdit') promotionEdit: CommercialActivityFooterModel;
  @Input('promotionNew') promotionNew: CommercialActivityFooterModel;
  @Input('commercialActStatVal') commercialActStatVal: number;
  @Input('isProcess') isProcess: number;
  @Input('contFecha') contFecha: number;
  @Input('isCreate') isCreate: boolean;
  @Input('isNext') isNext: boolean;
  @Input('showEditBtn') showEditBtn = true;
  @Output('eventClickSaveBtn') eventButton: EventEmitter<any> = new EventEmitter();
  @Output('eventClickEditBtn') eventButtonEdit: EventEmitter<any> = new EventEmitter();
  @Output('eventClickSendValidateBtn') eventButtonSendValidate: EventEmitter<any> = new EventEmitter();
  @Output('eventChangeData') eventChangeData: EventEmitter<any> = new EventEmitter();
  @Output('prepareFooterForEvent')prepareFooterForEvent : EventEmitter<any> = new EventEmitter();

  private readonly fb: FormBuilder;
  private readonly domainService: DomainService;
  private readonly validityPeriodService : ValidityPeriodService;
  private readonly datePipe: DatePipe;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;

  public formPromotion: FormGroup;
  public rangeDateSelectList: IDataRange[] = [];
  public cantDays = 0;
  public objectCommercialActivityStatusList: ICombo[];
  public objectCommercialActivityStatus: any[] = [];
  public today: Date = new Date();
  public currentYear = 0;
  public endYear = 0;
  public invalidDates: Date[] = [];
  public commercialActivityEnum: any;
  public typeComercialActivityId = 0;
  public commercialActivityStatusEnum: any;
  public showText = false;
  public listValidityPeriod : IValidityPeriod[] = [];
  public language: string;
  public auxCont = 0;
  public addFecha = 0;
  public rolePermission: any = RolePermissionEnum;
  public auxBtn = true;


  constructor(fb: FormBuilder,
    domainService: DomainService,
    managePromotionActService: ManagePromotionalActivitiesService,
		validityPeriodService : ValidityPeriodService,
    datePipe: DatePipe
    )
    {
      this.fb = fb;
      this.domainService = domainService;
      this.managePromotionActService = managePromotionActService;
      this.validityPeriodService = validityPeriodService;
      this.datePipe = datePipe;
    }

  ngOnInit(): void {
    this.commercialActivityStatusEnum = this.getCommercialActivityEnumStatus();
    this.typeComercialActivityId = this.managePromotionActService.getTypeCommercialActivity();
    this.getValidityPeriod();
    this.getCommercialActivityStatus();
    this.initForm();
    this.prepareDefaultTimes();
    this.commercialActivityEnum = this.getCommercialActivityEnum();
  }

  disableBtn(): boolean{
    if(this.isProcess === ComercialActivityEnum.view && this.promotionEdit.dataRanges.length === 0){
      return true;
    }else{
      return false;
    }
  }

  getCommercialActivityEnumStatus(): typeof CommercialActivityStatus {
    return CommercialActivityStatus;
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  getValidityPeriod(){
    const pagination = new PaginationModel('Id', true, 1, 1000);
    this.validityPeriodService
      .getAll(pagination)
      .subscribe(
        (response) => {
          if(response.data.length > 0){
            this.listValidityPeriod = response.data;
          }else{
            this.listValidityPeriod = [];
          }
          if(!response.status && this.listValidityPeriod.length > 0){
            SweetAlertMessageHelpers.error(
              SweetAlert.titleAlertError,
              (this.language === CommonConfiguration.LanguageKey)
                  ? CommercialActivityFooterMessage.NOT_DATA_PERIOD_VALIDITY_ES
                  : CommercialActivityFooterMessage.NOT_DATA_PERIOD_VALIDITY_EN);
          }
        },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }
  /**
   * @description This method is to prepare default times to select
   *
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private prepareDefaultTimes() {
    const start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    this.startTime.setValue(start);
    const end = new Date();
    end.setHours(23);
    end.setMinutes(59);
    this.endTime.setValue(end);
  }

  /**
   * @description This method is to wait the event changes
   * in father promotion, and apply the changes in this view
   *
   * @param changes
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    this.validateActivityId(false, false, null);

    const change: SimpleChange = changes['promotionEdit'];
    if (change && change.currentValue !== null && this.promotionEdit) {
      this.initForm();
      this.cantDays = this.promotionEdit.numberDays;
      setTimeout(() => {
        let auxDays = 0;

        this.orderLimit.setValue(this.promotionEdit.orderLimit);
        this.periodLimit.setValue(this.promotionEdit.periodLimit);
        this.promotionEdit.dataRanges.forEach((range) => {
          const diffDays = parseInt(`${(this.getDateDiff(this.convert(new Date(range.dateStart).toString()), this.convert(new Date(range.dateEnd).toString())) + 1)}`, 10);
          auxDays = auxDays + diffDays;
          this.days.setValue(auxDays);
          if(this.typeComercialActivityId === CommercialActivitySubprocess.Redemption){
            if(this.isProcess === ComercialActivityEnum.update){
              if(this.contFecha === 0){
                this.addInvalidDates(diffDays, String(range.dateStart), String(range.dateEnd), 0);
              }else{
                if(range.dateStart instanceof Date){
                const auxFechaStart = this.convertDate(range.dateStart);
                const auxFechaEnd = this.convertDate(range.dateEnd);
                this.addInvalidDates(diffDays, String(auxFechaStart), String(auxFechaEnd), 0);
                }else{
                  this.addInvalidDates(diffDays, String(range.dateStart), String(range.dateEnd), 0);
                }

              }
            }else{
              if(range.dateStart instanceof Date){
                const auxFechaStart = this.convertDate(range.dateStart);
                const auxFechaEnd = this.convertDate(range.dateEnd);
                this.addInvalidDates(diffDays, String(auxFechaStart), String(auxFechaEnd), 0);
              }else{
                this.addInvalidDates(diffDays, String(range.dateStart), String(range.dateEnd), 0);
              }
            }
          }else{
            this.addInvalidDates(diffDays, String(range.dateStart), String(range.dateEnd), 0);
          }

        });

        this.rangeDateSelectList = this.promotionEdit.dataRanges;
        let startTimeEd: string[] = [];
        let endTimeEd: string[] = [];
        this.auxBtn = this.disableBtn();

        if(this.promotionEdit && this.promotionEdit.dataRanges && this.promotionEdit.dataRanges.length > 0 && this.promotionEdit.dataRanges[0].id){
          startTimeEd = this.promotionEdit.startTime.split(':');
          endTimeEd = this.promotionEdit.endTime.split(':');
        }
        else if(this.promotionNew && this.promotionNew.dataRanges && this.promotionNew.dataRanges.length > 0){
            startTimeEd = this.promotionNew.startTime.split(':');
            endTimeEd = this.promotionNew.endTime.split(':');
        }
        else if(this.promotionEdit && this.promotionEdit.dataRanges 
                && this.promotionEdit.dataRanges.length > 0 && !this.promotionEdit.dataRanges[0].id 
                && this.promotionEdit.statusDomainId === this.commercialActivityStatusEnum.Registered){
          startTimeEd = this.promotionEdit.startTime.split(':');
          endTimeEd = this.promotionEdit.endTime.split(':');
        }

        if(startTimeEd.length > 1){
          const start = new Date();
          start.setHours(parseInt(startTimeEd[0], 10));
          start.setMinutes(parseInt(startTimeEd[1], 10));
          this.startTime.setValue(start);
          const end = new Date();
          end.setHours(parseInt(endTimeEd[0], 10));
          end.setMinutes(parseInt(endTimeEd[1], 10));
          this.endTime.setValue(end);
        }
        else{
          this.days.setValue('0');
          this.periodLimit.setValue('0');
          this.orderLimit.setValue('0');
          this.prepareDefaultTimes();
        }

        this.commercialActStatVal = this.promotionEdit.statusDomainId;
        this.commercialActivityState.setValue(this.getCommercialActivityName(this.promotionEdit.statusDomainId));
      }, 2000);
    }
    this.language = sessionStorage.getItem('language');
  }

  private validateActivityId(saveEdit: boolean, saveNew: boolean, promotion: CommercialActivityFooterModel) {
    if(saveEdit) {
      SessionStorageHelper.save('LocalPromotionEdit',  JSON.stringify(this.promotionEdit));
    }
    else {
      if(sessionStorage.getItem('LocalPromotionEdit')) {
        this.promotionEdit = JSON.parse(SessionStorageHelper.get('LocalPromotionEdit')!);
      }
    }

    if(saveNew && promotion) {
      SessionStorageHelper.save('LocalPromotionNew',  JSON.stringify(promotion));
    }
    else {
      if(sessionStorage.getItem('LocalPromotionNew')) {
        this.promotionNew = JSON.parse(SessionStorageHelper.get('LocalPromotionNew')!);
      }
    }
  }

  /**
   * @description This method is to get commercial activity name
   *
   * @param id
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private getCommercialActivityName(id: number): string {
    const x = this.objectCommercialActivityStatus.map((c) => {
      if (c.id === this.promotionEdit.statusDomainId) {
        return c.description;
      }
      return;
    });
    return x.length > 0 && x[0] !== undefined ? x[0] : '';
  }

  /**
   * @description This method is to get commercial activities status
   *
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  getCommercialActivityStatus() {
    this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.CommercialActivityStatus).subscribe(
      (response) => {
        this.objectCommercialActivityStatusList = response.data.map((item) => {
          return {
            label: item.description,
            value: item.code.trim()
          };
        });
        this.objectCommercialActivityStatus = [...response.data];
        if (ComercialActivityEnum.create === this.isProcess) {
          this.commercialActivityState.setValue('RE');
        } else {
          const x = response.data.filter(
            (dt) => {
            dt.id === this.commercialActStatVal;
          });
          if (x.length > 0) {
            this.commercialActivityState.setValue(x[0].code);
          }
        }
      });
  }

  /**
   * @description this method is to emit event the father.
   *
   * @param $event
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  eventClickSaveBtn($event: any): void {
    if (this.startTime.value < this.endTime.value) {
      if (this.validateBufferStop() && this.validateDataToSaveEdit()) {
        const promotion = this.prepareFooterModel();
        this.validateActivityId(false, true, promotion);
        this.eventButton.emit(promotion);
      }
    } else {
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        (this.language === CommonConfiguration.LanguageKey)
                  ? CommercialActivityFooterMessage.INVALID_TIME_ES
                  : CommercialActivityFooterMessage.INVALID_TIME_EN);
      return;
    }
  }

  prepareFooter($event: any): void{
    const promotion = this.prepareFooterModel();
    this.prepareFooterForEvent.emit(promotion);
  }

  /**
   * @description This method is to prepare data to edit promotion and emit info
   * to edit promotional Activity
   *
   * @param $event
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  eventClickEditBtn($event: any): void {
    if (this.validateBufferStop() && this.validateDataToSaveEdit()) {
      const minuteStart = this.startTime.value.getMinutes() < 10 ? `0${this.startTime.value.getMinutes()}` : this.startTime.value.getMinutes();
      const minuteEnd = this.endTime.value.getMinutes() < 10 ? `0${this.endTime.value.getMinutes()}` : this.endTime.value.getMinutes();
      this.promotionEdit.startTime = `${this.startTime.value.getHours()}:${minuteStart}:00`;
      this.promotionEdit.endTime = `${this.endTime.value.getHours()}:${minuteEnd}:00`;
      this.promotionEdit.numberDays = this.cantDays;
      this.promotionEdit.orderLimit = this.orderLimit.value;
      this.promotionEdit.periodLimit = this.periodLimit.value;

      this.validateActivityId(true, false, null);

      const promotion = new CommercialActivityFooterModel(
        this.cantDays,
        `${this.startTime.value.getHours()}:${minuteStart}:00`,
        `${this.endTime.value.getHours()}:${minuteEnd}:00`,
        this.orderLimit.value,
        this.periodLimit.value,
        this.promotionEdit.statusDomainId,
        this.rangeDateSelectList
      );
      this.eventButtonEdit.emit(promotion);
    }
  }

  /**
   * @description This method is to prepare footer model
   * @author Priscy Antonio Reales
   * @sprint 20
   * @returns
   */
  private prepareFooterModel() :  CommercialActivityFooterModel{
    const minuteStart = this.startTime.value.getMinutes() < 10 ? `0${this.startTime.value.getMinutes()}` : this.startTime.value.getMinutes();
    const minuteEnd = this.endTime.value.getMinutes() < 10 ? `0${this.endTime.value.getMinutes()}` : this.endTime.value.getMinutes();
    const status = this.objectCommercialActivityStatus.find((z: any) => z.code === this.commercialActivityState.value || z.description === this.commercialActivityState.value);
    const promotion = new CommercialActivityFooterModel(
      this.cantDays,
      `${this.startTime.value.getHours()}:${minuteStart}:00`,
      `${this.endTime.value.getHours()}:${minuteEnd}:00`,
      this.orderLimit.value,
      this.periodLimit.value,
      !this.promotionEdit || this.promotionEdit.statusDomainId === null || this.promotionEdit.statusDomainId === undefined ? status.id : this.promotionEdit.statusDomainId,
      this.rangeDateSelectList
    );

    return promotion;
  }

  public onChangeData(){
    this.eventChangeData.emit(this.prepareFooterModel());
  }

  /**
  * @description This method is to validate data to create or update
  * @author Jhon Steven Pavón Bedoya
  * @sprint 9
  */
  validateBufferStop() : boolean {
    let result = true;
    result = (this.orderLimit.value <= this.periodLimit.value) ? true : false;

      if(!result){
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? `El campo de tope por pedido no debe superar el tope por periodo.`
          : 'The cap per order field must not exceed the cap per period.');
      }

    return result;
  }

  /**
   * @description This method is to validate data to create
   * or update
   *
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private validateDataToSaveEdit(): boolean {
    const language: string = sessionStorage.getItem('language');
    if (this.showFields.rangeDate && (this.rangeDateSelectList.length === 0)) {
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? 'Debe seleccionar al menos un rango de fechas.' : 'You must select at least one date range');
        return false;
    }
    if (this.showFields.orderLimit && (this.orderLimit.value === undefined || this.orderLimit.value === null || this.orderLimit.value === '' || this.orderLimit.value === 0)) {
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? 'El tope por pedido es requerido.' : 'The cap per order is required');
        return false;
    }
    if (this.showFields.periodLimit && (this.periodLimit.value === undefined || this.periodLimit.value === null || this.periodLimit.value === '' || this.periodLimit.value === 0)) {
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? 'El tope por periodo es requerido.' : 'The cap per period is required.');
        return false;
    }
    if (this.showFields.startTime && (this.startTime.value === undefined || this.startTime.value === null || this.startTime.value === '')) {
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? 'La hora de inicio es requerida.': 'The start time is required.');
        return false;
    }
    if (this.showFields.endTime && (this.endTime.value === undefined || this.endTime.value === null || this.endTime.value === '')) {
        SweetAlertMessageHelpers.error(
          language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
          language === 'es-ES' ? 'La hora de fin es requerida.' : 'End time is required.');
        return false;
    }

    if (this.startTime.value > this.endTime.value) {
      SweetAlertMessageHelpers.error(
        language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
        language === 'es-ES' ? 'La hora de inicio no debe ser mayor a la hora de fin.': 'The start time should not be greater than the end time.');
      return false;
    }
    return true;
  }

  /**
   * @description This method is for validate date control.
   *
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  getYearRange() {
    this.currentYear = this.today.getFullYear();
    this.endYear = this.currentYear + 10;
    return `${this.currentYear}:${this.endYear}`;
  }

  /**
   * @description This method is to add range data to selectRangeData
   *
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  addRangeDate(): void {
    this.addFecha += 1;
    if (this.rangeDateControl.value) {
      let lstRangeDate = this.rangeDateControl.value.toString().split(',');
      let startDate = new Date(lstRangeDate[0]);
      let endDate = new Date(lstRangeDate[1]);

      if(endDate > startDate){
        this.showText = false;
        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
          if (!this.validateDatesInDataRange(startDate, endDate)) {
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              (this.language === CommonConfiguration.LanguageKey)
                  ? CommercialActivityFooterMessage.INVALID_DATE_ES
                  : CommercialActivityFooterMessage.INVALID_DATE_EN);
            this.rangeDateControl.setValue(null);
            return;
          }

          const days = parseInt(`${(this.getDateDiff(this.convert(startDate.toString()), this.convert(endDate.toString())) + 1)}`, 10);
          this.cantDays += days;

          // if(this.invalidDates.length == 0){
          //   this.cantDays = this.cantDays + 1;
          // }

          const validity = this.listValidityPeriod.find((x) => x.subProcessDomainId === this.typeComercialActivityId);
          if(validity){
            if(this.cantDays > validity.numberDay){
              SweetAlertMessageHelpers.error(
                SweetAlert.titleAlertError,
                (this.language === CommonConfiguration.LanguageKey)
                  ? CommercialActivityFooterMessage.MAXIMUM_PERIOD_VALIDITY_ES +' '+ validity.numberDay + ' Dias.'
                  : CommercialActivityFooterMessage.MAXIMUM_PERIOD_VALIDITY_EN +' '+ validity.numberDay + ' Days.');
                  this.cantDays -= days;
              return;
            }
          }
          const auxFechaStart = this.convertDate(startDate);
          const auxFechaEnd = this.convertDate(endDate);
          this.addInvalidDates(days, auxFechaStart, auxFechaEnd, 1);

          const rangeDateData = new DataRangeModel(
            startDate,
            endDate,
            days
          );
          this.rangeDateSelectList.push(rangeDateData);
          this.days.setValue(this.cantDays);
        }

        this.rangeDateControl.setValue(null);
        this.onChangeData();
      }else if (endDate <= startDate){
        this.formPromotion.get('rangeDateControl').setValue('');
        this.showText = true;
      }
    }
  }

  convertDate(fecha: Date): string {
    const auxYear = fecha.getFullYear();
    const auxDay = fecha.getDate();
    const auxMonth = fecha.getMonth() + 1;
    return ''+ auxYear +'-'+auxMonth+'-'+auxDay;
  }

  /**
   * @description This method is to add invalid dates to disabled in calendar
   *
   * @param days
   * @param startDate
   * @param endDate
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private addInvalidDates(days: number, startDate: string, endDate: string, index: number) {
    const auxFecha = new Date();
    let dayValidity = 0;

    const auxStartDateShortFormat = this.datePipe.transform(startDate, 'yyyy-M-dd');
    const auxEndDateShortFormat = this.datePipe.transform(endDate, 'yyyy-M-dd');
    const auxStartDate = parse(auxStartDateShortFormat, 'yyyy-MM-dd', new Date());
    const auxEndDate = parse(auxEndDateShortFormat, 'yyyy-MM-dd', new Date());

    if(auxFecha.getDate() === auxStartDate.getDate() && auxFecha.getMonth() === auxStartDate.getMonth() && auxFecha.getFullYear() === auxStartDate.getFullYear()){
      dayValidity = parseInt(`${(this.getDateDiff(auxStartDate, auxEndDate) + 1)}`, 10);
      if(days !== dayValidity){
        days = dayValidity;
      }
    }

    for (let i = 0; i < Math.round(days); i++) {
        const start = new Date(auxStartDate);
        start.setDate(start.getDate() + i);
        this.invalidDates.push(start);
    }
  }

  /**
   * @description This method is to validate if the dates is into dateRange
   *
   * @param startDate
   * @param endDate
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  private validateDatesInDataRange(startDate: Date, endDate: Date): boolean {
    for (let i = 0; i < this.invalidDates.length; i++) {
      if (this.invalidDates[i] >= startDate && this.invalidDates[i] <= endDate) {
        return false;
      }
    }
    return true;
  }

  /**
   * @description This method is to get days between two dates
   *
   * @param start
   * @param end
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  getDateDiff(start: Date, end: Date) {
    return Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  }

  convert(str : string) : Date {
    let date = new Date(str),
      mnth = ('0' + (date.getUTCMonth() + 1)).slice(-2),
      day = ('0' + date.getUTCDate()).slice(-2);
    return new Date([date.getUTCFullYear(), mnth, day].join('-'));
  }

  /**
   * @description This method is to delete a selected range date
   *
   * @param indexRangeDate
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  deleteRangeDateSelected(indexRangeDate: number) {
    const dataRange = this.rangeDateSelectList[indexRangeDate];
    const invalidDates = [...this.invalidDates];
    const startDate = this.convert(dataRange.dateStart.toString());
    const endDate = this.convert(dataRange.dateEnd.toString());

    const days = parseInt(`${(this.getDateDiff(startDate, endDate) + 1)}`, 10);
    dataRange.cantDays = days;

    endDate.setDate(endDate.getDate() + 1);

    invalidDates.forEach((inv, index) => {
      if (this.convert(inv.toString()) >= startDate && this.convert(inv.toString()) <= endDate) {
        const ix = this.invalidDates.findIndex((fn) => fn === inv);
        this.invalidDates.splice(ix, 1);
      }
    });

    if(this.invalidDates.length === 0){
      this.cantDays = 0;
      this.days.setValue(this.cantDays);
      this.rangeDateSelectList.splice(indexRangeDate, 1);
    }else{
      this.cantDays = invalidDates.length - dataRange.cantDays;
      this.days.setValue(this.cantDays);
      this.rangeDateSelectList.splice(indexRangeDate, 1);
    }
  }

  eventClickSendValidate($event: any): void {
    if(this.validateDataToSaveEdit()){
      this.eventButtonSendValidate.emit();
      this.auxBtn = true;
    }
  }

  /**
   * @description This method is to init the form controls
   *
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  initForm(): void {
    this.formPromotion = this.fb.group({
      rangeDateControl: [{ value: '', disabled: false }, []],
      days: [{ value: '', disabled: true }, []],
      startTime: [{ value: '', disabled: false }, []],
      endTime: [{ value: '', disabled: false }, []],
      periodLimit: [{ value: '', disabled: false }, []],
      orderLimit: [{ value: '', disabled: false }, []],
      commercialActivityState: [{ value: '', disabled: this.isProcess === ComercialActivityEnum.update }, []]
    });
  }

  get rangeDateControl(): AbstractControl { return this.formPromotion.get('rangeDateControl'); }
  get days(): AbstractControl { return this.formPromotion.get('days'); }
  get startTime(): AbstractControl { return this.formPromotion.get('startTime'); }
  get endTime(): AbstractControl { return this.formPromotion.get('endTime'); }
  get orderLimit(): AbstractControl { return this.formPromotion.get('orderLimit'); }
  get periodLimit(): AbstractControl { return this.formPromotion.get('periodLimit'); }
  get commercialActivityState(): AbstractControl { return this.formPromotion.get('commercialActivityState'); }
}
