import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { DomainService } from '@app/@shared/services/domain.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { DataRangeModel } from '@app/@components/commercial-activity-footer/models/data-range.model';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { forkJoin } from 'rxjs';
import { CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';
import { environment } from '@env/environment';
import { IHierarchyFiltersMaterials } from '@app/@components/hierarchy-advanced-filters/interfaces/hierarchy-filters-materials.interface';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { ManagePromotionalActivitiesMessage } from '../../manage-promotional-activities/messages/manage-promotional-activities-messages.message';
import { ManagePromotionalActivitiesService } from '../../manage-promotional-activities/manage-promotional-activities.service';
import { IProductList } from '../../exclusions/interfaces/product-list.interface';
import { SummaryClientModel } from '@app/@components/assing-clients/models/summary-client.model';
import { SummaryClientJsonModel } from '@app/@components/assing-clients/models/summary-client-json.model';
import { AdvancedFiltersClientModel } from '@app/@shared/model/advanced-filters-client.model';
import { IAdvancedFiltersClient } from '@app/@shared/interfaces/advanced-filters-client.interface';
import { SolicitudeMessage } from '@app/@modules/prices/solicitude/messages/solicitude.message';
import { AssingClientService } from '@app/@components/assing-clients/assing-clients.service';
import { IAdvancedFiltersClientsResult } from '@app/@shared/interfaces/advanced-filters-client-result.interface';
import { ExlusionBySkuModel } from '../models/exclusions-by-sku.model';
import { ExclusionsBySkuService } from '../exclusions-by-sku.service';
import { IDetailFilterClientJson } from '@app/@components/assing-clients/interfaces/details-filter-client-json.interface';
import { DetailFilterClientModel } from '@app/@components/assing-clients/models/details-filter-client-json.model';
import { IDetailFilterClientsResult } from '@app/@components/assing-clients/interfaces/detail-filter-client-result.interface';
import { DetailFilterClientResultModel } from '@app/@components/assing-clients/models/detail-filter-client-result.model';
import { ExclusionsBySkuComponent } from '../exclusions-by-sku.componente';
import { ExlusionOverlapModel } from '../models/exclusions-overlap.model';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';



@Component({
  selector: 'app-exclusions-manage',
  templateUrl: './exclusions-by-sku-create-edit.component.html',
  styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
    .generalData .options{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService, ExclusionsBySkuComponent]
})
export class ExclusionsBySkuCreateEditComponent implements OnInit {
  private readonly fb: FormBuilder;
  private readonly spinner: NgxSpinnerService;
  private readonly router: Router;
  private readonly domainService: DomainService;
  private readonly coreService: CoreMasterService;
  private readonly messageService: MessageService;
  private readonly assingClientService: AssingClientService;
  private readonly exclusionsBySkuService: ExclusionsBySkuService;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly miDatePipe: DatePipe;
  private readonly translate: TranslateService;
  private readonly i18nService: I18nService;

  public process = 0;
  public processEnum: any = ComercialActivityEnum;
  public isBasePromotion = false;
  public isBasePurchase = false;
  public commercialActivitySubprocess = CommercialActivitySubprocess;

  public showLimitOrderOne = false;
  //public showLimitOrderTwo: boolean = false;

  public showLimitOrderPromotion = false;
  public showLimitOrderDiscountKind = false;
  public showLimitOrderDiscountValue = false;
  public showLimitOrderDiscountFinancial = false;
  public showLimitOrderEvents = false;
  public showLimitOrderZeroValue = false;
  public showLimitOrderLoyalty = false;
  public showLimitOrderRappel = false;

  public showCapPeriodPromotion = false;
  public showCapPeriodDiscountKind = false;
  public showCapPeriodDiscountValue = false;
  public showCapPeriodDiscountFinancial = false;
  public showCapPeriodEvents = false;
  public showCapPeriodZeroValue = false;
  public showCapPeriodLoyalty = false;
  public showCapPeriodRappel = false;

  public isKindDiscount: any = null;
  public isFinancialDiscount: any = null;
  public isValueDiscount : any = null;

  public isStopOrderValidatorPromotion = false;
  public isStopOrderValidatorEvents = false;
  public isStopOrderValidatorZeroValue = false;
  public isStopOrderValidatorLoyality = false;
  public isKindDiscountValidator = false;
  public isFinancialDiscountValidator = false;
  public isValueDiscountValidator = false;

  //#region Params Load Page
    public objectPromotionalActivity: ICombo[] = [];
    public objectChannel: ICombo[] = [];

  //#endregion

  //#region Params Form
    public formExclusions: FormGroup;
    public rangeDateData: DataRangeModel = new DataRangeModel;
    public invalidDates: Date[] = [];
    public today: Date = new Date();
    public currentYear = 0;
    public endYear = 0;
  //#endregion

  //#region Params Filter Material
    //public editFiltersObjectList: IHierarchyFiltersMaterials[] = [];
    public showMaterialFilter = true;
    public isMultiFilter = true;
    public editFiltersObjectList: any[] = [];
    public productFilterListPromotion: IProductList[] = [];
    public advancedFiltersObjectList: IHierarchyFiltersMaterials[] = [];
    public jsonMaterialFilter = '';
    public isDeleteAll = false;
    public listProductsDetail: string[] = [];
  //#endregion

  //#region Params Filter Clients
    public filtersEdit: string;
    public summaryClients: SummaryClientModel[];
    public summaryClientsJson: SummaryClientJsonModel[];
    public filterResult: IAdvancedFiltersClientsResult;
    public detailsResult: IDetailFilterClientsResult[];
    public detailsClientsResult: DetailFilterClientResultModel[];
    public jsonFilterClient: string;
    public totalClients = 0;
    public displayModal = false;
    public displayModalList = false;
    public displayModalList2 = false;
  //#endregion

  //#region Paramas Create Exclusion
    public exclusionModel : ExlusionBySkuModel;
  //#endregion

  //#region Params Validations
    public listError: string[] = [];
    //#endregion

    //#region Tooltips
    public tooltipCommercialActivity: string[] = [];
    public tooltipChannel: string[] = [];
    //#endregion

    //region Overlap
    public listOverlap: any = [];
    //
    public language: string = sessionStorage.getItem('language');
    public copiActivity: number[] = [13, 14, 15];
    public idExlusion: number;
    public create =  ComercialActivityEnum.create;
    public auxKind = 0;
    public auxValue = 0;
    public auxFinancie = 0;
    public validateTopes = false;
    public isTopesZero = false;

  constructor(
    fb: FormBuilder,
    spinner: NgxSpinnerService,
    router: Router,
    domainService: DomainService,
    coreService: CoreMasterService,
    messageService: MessageService,
    assingClientService: AssingClientService,
    exclusionsBySkuService: ExclusionsBySkuService,
    managePromotionActService: ManagePromotionalActivitiesService,
    miDatePipe: DatePipe,
    translate: TranslateService,
    i18nService: I18nService
  ){ 
      this.fb = fb;
      this.spinner = spinner;
      this.router = router;
      this.domainService = domainService;
      this.coreService = coreService;
      this.messageService = messageService;
      this.assingClientService = assingClientService;
      this.exclusionsBySkuService = exclusionsBySkuService;
      this.managePromotionActService = managePromotionActService;
      this.miDatePipe = miDatePipe;
      this.translate = translate;
      this.i18nService = i18nService;
  }

  ngOnInit(): void {
    this.process = this.exclusionsBySkuService.getProcessExclusion();
    this.idExlusion = this.exclusionsBySkuService.getExclusionId();
    if(this.process < 1) this.router.navigate(['auth/exclusion-by-sku']);

    this.initForm();
    this.loadInfomartion(true);
  }

  //#region Load Information.
  loadInfomartion(isFirst: boolean = false) {
    if(this.language === this.translate.currentLang && !isFirst){
      return;
    }else{
      this.translate.use(this.i18nService.language);
      this.language = this.i18nService.language;
    }

    this.spinner.show();

    const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
    const processResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess);

    forkJoin([
      channelResponse,
      processResponse
    ])
      .subscribe(
        (result) => {

          const objChannelResponse: IResponseService<ICoreMaster[]> = result[0];
          const objProcessResponse: IResponseService<IDomain[]> = result[1];

          this.objectChannel = objChannelResponse.data.map((item) => {
            return {
              label: item.description,
              value: item.code.trim()
            };
          });

          this.objectPromotionalActivity = objProcessResponse.data.filter((y) => y.code === 'AP' && y.id != 19).map((item) => {
            return {
              label: this.translate.instant('Domain.Domain-'+item.id),
              value: item.id
            };
          });

          if (this.process === ComercialActivityEnum.update || this.process === ComercialActivityEnum.view) {
            const exclusionId = this.exclusionsBySkuService.getExclusionId();
            this.loaderFormDetails(exclusionId);
          }

          this.spinner.hide();

        }, () => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
  }

  public get comercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }

  public loaderFormDetails(exclusionId: number){
    this.exclusionsBySkuService.getExclusionWithDetailById(exclusionId)
    .subscribe(
      (response) => {
        if(response.status){
          this.exclusionModel = response.data;
          this.showInformationExclusion(response.data);
        }
      },() => {
        SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
      });
  }

  public showInformationExclusion(exclusion: ExlusionBySkuModel){
    this.Description.setValue(exclusion.reasonExclusion);
    exclusion.materialsList.forEach((elm) => {elm.check = false});
    this.TypeComercialActivity.setValue(exclusion.typeBusinessActivity.map((x) => {return x;}));
    this.Channel.setValue(exclusion.canal.map((x) => { return `${x}`;  }));

    this.rangeDateData = exclusion.validityPeriod;
    this.setValueDataRange();
    //pintar el rango de fecha

    this.PurchaseBase.setValue(exclusion.purchanseBase);
    this.PromotionBase.setValue(exclusion.basePromotion);
    this.PromotionPurchanse.setValue(exclusion.promotionBiggerPurchanseBase);

    this.StopOrderPromotion.setValue(exclusion.limitOrderPromotion == 0 ? null : exclusion.limitOrderPromotion);
    this.StopOrderEvents.setValue(exclusion.limitOrderEvent == 0 ? null : exclusion.limitOrderEvent);
    this.StopOrderZeroValue.setValue(exclusion.limitOrderZeroValue == 0 ? null : exclusion.limitOrderZeroValue);
    this.StopOrderLoyalty.setValue(exclusion.limitOrderLoyalty == 0 ? null : exclusion.limitOrderLoyalty);
    this.StopOrderDiscountFinancial.setValue(exclusion.limitOrderDiscountFinancial == 0 ? null : exclusion.limitOrderDiscountFinancial);
    this.StopOrderDiscountKind.setValue(exclusion.limitOrderDiscountKind == 0 ? null : exclusion.limitOrderDiscountKind);
    this.StopOrderDiscountValue.setValue(exclusion.limitOrderDiscountValue == 0 ? null : exclusion.limitOrderDiscountValue);
    this.StopOrderRappel.setValue(exclusion.limitOrderDiscountRappel == 0 ? null : exclusion.limitOrderDiscountRappel);

    this.CapPeriodDiscountFinancial.setValue(exclusion.limitPeriodOrderDiscountFinancial == 0 ? null : exclusion.limitPeriodOrderDiscountFinancial);
    this.CapPeriodDiscountKind.setValue(exclusion.limitPeriodOrderDiscountKind == 0 ? null : exclusion.limitPeriodOrderDiscountKind );
    this.CapPeriodDiscountValue.setValue(exclusion.limitPeriodOrderDiscountValue == 0 ? null : exclusion.limitPeriodOrderDiscountValue);
    this.CapPeriodEvents.setValue(exclusion.limitPeriodOrderEvent == 0 ? null : exclusion.limitPeriodOrderEvent);
    this.CapPeriodLoyalty.setValue(exclusion.limitPeriodOrderLoyalty == 0 ? null : exclusion.limitPeriodOrderLoyalty);
    this.CapPeriodPromotion.setValue(exclusion.limitPeriodOrderPromotion == 0 ? null : exclusion.limitPeriodOrderPromotion);
    this.CapPeriodRappel.setValue(exclusion.limitPeriodOrderDiscountRappel == 0 ? null : exclusion.limitPeriodOrderDiscountRappel);
    this.CapPeriodZeroValue.setValue(exclusion.limitPeriodOrderZeroValue == 0 ? null : exclusion.limitPeriodOrderZeroValue);

    this.changeTypeOfPrice();

    this.KindDiscount.setValue(exclusion.limitKindDiscount);
    this.FinancialDiscount.setValue(exclusion.limitFinancialDiscount);
    this.ValueDiscount.setValue(exclusion.limitValueDiscount);

     exclusion.limitKindDiscount > 0 ? this.SwitchKindDiscount.setValue(false) : this.SwitchKindDiscount.setValue(true);
     exclusion.limitFinancialDiscount > 0 ? this.SwitchFinancialDiscount.setValue(false) : this.SwitchFinancialDiscount.setValue(true);
     exclusion.limitValueDiscount > 0 ? this.SwitchValueDiscount.setValue(false) : this.SwitchValueDiscount.setValue(true);

     
    this.editFiltersObjectList = JSON.parse(exclusion.materialFilter);
    this.filtersEdit = JSON.parse(exclusion.clientFilter);
    this.jsonFilterClient = exclusion.clientFilter;
    this.jsonMaterialFilter = exclusion.materialFilter;

    if(exclusion.listProducts.length > 0) this.listProductsDetail = exclusion.listProducts;

    const newFilterModel = new FilterDataModel(
      exclusion.materialFilter,
      environment.conditionTable,
      environment.conditionClassCode,
      false
      );
    this.changeTooltipChannel();
    this.tooltipTypeComercialActivity();
    // this.getMaterialsByFilters(newFilterModel);
    this.productFilterListPromotion = [...exclusion.materialsList];
    this.changeKindDiscount();
    this.changeFinancialDiscount();
    this.changeValueDiscount();
  }

  //#endregion

  //#region Functions Interactive Form
  keyupDescription($event: any){
    const value = Util.keyupLetterValidation(event);
    this.Description.setValue(value);
  }

  keyUpOnlyNumberWithPoint($event: any, field: number){

    const value = Util.keyUpOnlyNumberWithPoint($event);
    const validateValue = value === '' ? 0 : value.replace(/,/g, '.');
    const validationNumber = this.validateNumber(validateValue, field);
    const validateDigits = validationNumber !== 0 ? this.validateDigits(validationNumber) : '';

    switch (field) {
      case 5:
        this.KindDiscount.setValue(validateDigits);
        break;
      case 6:
        this.FinancialDiscount.setValue(validateDigits);
        break;
      case 7:
        this.ValueDiscount.setValue(validateDigits);
        break;
      default: break;
    }

  }

  validateDigits(value: any){
    const validateValue = value === '' ? 0 : value.replace(/,/g, '.');
    const split = validateValue.split('.');
    let result;

    if(split.length > 1){
      const valueDiscount = (split[0] === '100') ? split[0] : `${split[0]}.${split[1].substring(2,0)}`;
      result = valueDiscount;
    }else{
      result = validateValue;
    }

    return result;
  }

  keyUpOnlyNumber($event: any, field: number){
    const value = Util.keyUpOnlyNumber($event);
    const validateValue = value === '' ? 0 : value;
    const validationNumber = this.validateNumber(validateValue, field);

    switch (field) {
      case 1:
        this.StopOrderPromotion.setValue(validationNumber);
        break;
      case 2:
        this.StopOrderEvents.setValue(validationNumber);
        break;
      case 3:
        this.StopOrderZeroValue.setValue(validationNumber);
        break;
      case 4:
        this.StopOrderLoyalty.setValue(validationNumber);
        break;
      default: break;
    }
  }

  validateNumber(value: any, field: number): any {
    let result: any = 0;
    let validateErrorValue = true;

    const arrayField = [1,2,3,4];

    if(arrayField.includes(field) && parseInt(value, 10) > 0){
      validateErrorValue = false;
      result = parseInt(value, 10);
    }else if(field !== 1 && parseInt(value, 10) >= 1 && parseInt(value, 10) <= 100){
      validateErrorValue = false;
      result = value;
    }

    this.showMessageErrorOnlyNumber(field, validateErrorValue);

    return result;
  }

  showMessageErrorOnlyNumber(field: number, isError: boolean){
    if(isError){
      switch (field) {
        case 1:
          this.isStopOrderValidatorPromotion = true;
          break;
        case 2:
          this.isStopOrderValidatorEvents = true;
          break;
        case 3:
          this.isStopOrderValidatorZeroValue = true;
          break;
        case 4:
          this.isStopOrderValidatorLoyality = true;
          break;
        case 5:
          this.isKindDiscountValidator = true;
          break;
        case 6:
          this.isFinancialDiscountValidator = true;
          break;
        case 7:
          this.isValueDiscountValidator = true;
          break;
        default: break;
      }
    }else{
      switch (field) {
        case 1:
          this.isStopOrderValidatorPromotion = false;
          break;
        case 2:
          this.isStopOrderValidatorEvents = false;
          break;
        case 3:
          this.isStopOrderValidatorZeroValue = false;
          break;
        case 4:
          this.isStopOrderValidatorLoyality = false;
          break;
        case 5:
          this.isKindDiscountValidator = false;
          this.KindDiscount.reset();
          break;
        case 6:
          this.isFinancialDiscountValidator = false;
          this.FinancialDiscount.reset();
          break;
        case 7:
          this.isValueDiscountValidator = false;
          this.ValueDiscount.reset();
          break;
        default: break;
      }
    }

  }
  valueInit(){
    this.SwitchFinancialDiscount.setValue(false);
    this.SwitchKindDiscount.setValue(false);
    this.SwitchValueDiscount.setValue(false);
  }

  inputDisabled(){
    this.KindDiscount.enable();
    this.FinancialDiscount.enable();
    this.ValueDiscount.enable();
  }

  changeTypeOfPrice(){
    this.viewPanelLimitOrder();
    this.viewBasePromotion();
    this.viewTopesDiscountValue();
    this.viewTopesDiscountKind();
    this.viewTopesDiscountFinancial();
    this.viewTopesEvents();
    this.viewTopesZeroValueProduct();
    this.viewTopesLoyalty();
    this.viewTopesRappel();

    if(this.TypeComercialActivity.value.length == 0){
      this.valueInit();
      this.inputDisabled()
    }else{
      let aux : any = [];
      aux = this.copiActivity.filter((x) => 
        !this.TypeComercialActivity.value.some((y: any) => x == y)
      );

      aux.forEach((a: any) => {
        if(a == CommercialActivitySubprocess.DiscountKind){
          this.SwitchKindDiscount.setValue(false);
          this.KindDiscount.enable();
        }else if(a == CommercialActivitySubprocess.DiscountValue){
          this.SwitchValueDiscount.setValue(false);
          this.ValueDiscount.enable();
        }else if(a == CommercialActivitySubprocess.FinancialDiscount){
          this.SwitchFinancialDiscount.setValue(false);
          this.FinancialDiscount.enable();
        }
      });
        
        aux = [];
    }
    this.viewKindDiscount();
    this.viewValueDiscount();
    this.viewFinancialDiscount();
  }

  changeTooltipChannel(){
    if(this.Channel.value.length > 0){
      this.tooltipChannel = [];
      this.Channel.value.forEach((x : any) => {
        this.objectChannel.forEach((y) => {
          if(x === y.value){
            this.tooltipChannel.push(y.label);
          }
        });
      });
    }else{
      this.tooltipChannel = [];
    }
  }

  public viewPanelLimitOrder(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Promotions) ||
       this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Events) ||
       this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.ZeroValueProduct) ||
       this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Loyalty) ||
        this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountKind) ||
          this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountValue) ||
            this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.FinancialDiscount) ||
            this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Rappel)){
        this.showLimitOrderOne = true;
       }else{
        this.showLimitOrderOne = false;
       }
  }

  public viewBasePromotion(){
    if (this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Promotions)) {
      this.showLimitOrderPromotion = true;
      this.showCapPeriodPromotion =  true;
      this.isBasePromotion = true;
      this.isBasePurchase = true;

    } else {
      this.showLimitOrderPromotion = false;
      this.showCapPeriodPromotion = false;
      this.isBasePromotion = false;
      this.isBasePurchase = false;
      this.StopOrderPromotion.reset();
      this.StopOrderPromotion.setValue(null);
      this.CapPeriodPromotion.reset();
      this.CapPeriodPromotion.setValue(null);
      this.PurchaseBase.setValue(false);
      this.PromotionBase.setValue(false);
      this.PromotionPurchanse.setValue(false);
    }

  }

    public viewTopesDiscountValue(){
      if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountValue)){
        this.showLimitOrderDiscountValue = true;
        this.showCapPeriodDiscountValue = true;
      }else{
        this.showLimitOrderDiscountValue = false;
        this.showCapPeriodDiscountValue = false;
        this.StopOrderDiscountValue.reset();
        this.StopOrderDiscountValue.setValue(null);
        this.CapPeriodDiscountValue.reset();
        this.CapPeriodDiscountValue.setValue(null);
      }
    }

    public viewTopesDiscountKind(){
      if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountKind)){
        this.showLimitOrderDiscountKind = true;
        this.showCapPeriodDiscountKind = true;
      }else{
        this.showLimitOrderDiscountKind = false;
        this.showCapPeriodDiscountKind = false;
        this.StopOrderDiscountKind.reset();
        this.StopOrderDiscountKind.setValue(null);
        this.CapPeriodDiscountKind.reset();
        this.CapPeriodDiscountKind.setValue(null);
      }
    }

    public viewTopesDiscountFinancial(){
      if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.FinancialDiscount)){
        this.showLimitOrderDiscountFinancial = true;
        this.showCapPeriodDiscountFinancial = true;
      }else{
        this.showLimitOrderDiscountFinancial = false;
        this.showCapPeriodDiscountFinancial = false;
        this.StopOrderDiscountFinancial.reset();
        this.StopOrderDiscountFinancial.setValue(null);
        this.CapPeriodDiscountFinancial.reset();
        this.CapPeriodDiscountFinancial.setValue(null);
      }
    }

    public viewTopesRappel(){
      if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Rappel)){
        this.showLimitOrderRappel = true;
        this.showCapPeriodRappel = true;
      }else{
        this.showLimitOrderRappel = false;
        this.showCapPeriodRappel = false;
        this.StopOrderRappel.reset();
        this.StopOrderRappel.setValue(null);
        this.CapPeriodRappel.reset();
        this.CapPeriodRappel.setValue(null);
      }
    }
    

  public viewTopesEvents(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Events)){
      this.showLimitOrderEvents = true;
      this.showCapPeriodEvents = true;
    }else{
      this.showLimitOrderEvents = false;
      this.showCapPeriodEvents = false;
      this.StopOrderEvents.reset();
      this.StopOrderEvents.setValue(null);
      this.CapPeriodEvents.reset();
      this.CapPeriodEvents.setValue(null);
    }
  }

  public viewTopesZeroValueProduct(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.ZeroValueProduct)){
         this.showLimitOrderZeroValue = true;
         this.showCapPeriodZeroValue = true;
      }else{
        this.showLimitOrderZeroValue = false;
        this.showCapPeriodZeroValue = false;
        this.StopOrderZeroValue.reset();
        this.StopOrderZeroValue.setValue(null);
        this.CapPeriodZeroValue.reset();
        this.CapPeriodZeroValue.setValue(null);
      }
  }

  public viewTopesLoyalty(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.Loyalty)){
         this.showLimitOrderLoyalty = true;
         this.showCapPeriodLoyalty = true;
      }else{
         this.showLimitOrderLoyalty = false;
         this.showCapPeriodLoyalty =  false;
         this.StopOrderLoyalty.reset();
         this.StopOrderLoyalty.setValue(null);
         this.CapPeriodLoyalty.reset();
         this.CapPeriodLoyalty.setValue(null);
      }
  }

  public viewKindDiscount(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountKind) ) {
      this.isKindDiscount = true;
      this.auxKind += 1;
      if(this.auxKind == 1){
        this.resetKindDiscount();
      }
    }else{
      this.isKindDiscount = false;
      this.auxKind = 0;
      this.resetKindDiscount();

    }
  }

  private resetKindDiscount() {
    this.KindDiscount.reset();
    this.KindDiscount.setValue(0);
    this.KindDiscount.enable();
    this.SwitchKindDiscount.setValue(false);
  }

  public viewValueDiscount(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountValue)) {
      this.isValueDiscount = true;
      this.auxValue += 1;
      if(this.auxValue == 1){
        this.resetValueDiscount();
      }
    }else{
      this.isValueDiscount = false;
      this.auxValue = 0;
      this.resetValueDiscount();
    }
  }

  private resetValueDiscount() {
    this.ValueDiscount.reset();
    this.ValueDiscount.setValue(0);
    this.ValueDiscount.enable();
    this.SwitchValueDiscount.setValue(false);
  }

  public viewFinancialDiscount(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.FinancialDiscount)) {
      this.isFinancialDiscount = true;
      this.auxFinancie += 1;
      if(this.auxFinancie == 1){
        this.resetFinanciaDiscount();
      }
    }else{
      this.isFinancialDiscount = false;
      this.auxFinancie = 0;
      this.resetFinanciaDiscount();
    }
  }

  private resetFinanciaDiscount() {
    this.FinancialDiscount.reset();
    this.FinancialDiscount.setValue(0);
    this.FinancialDiscount.enable();
    this.SwitchFinancialDiscount.setValue(false);
  }

  public changeKindDiscount(){
    if(this.SwitchKindDiscount.value === true){
      this.KindDiscount.disable();
      this.KindDiscount.setValue(0);
      this.isKindDiscountValidator = false;
    }else{
      this.KindDiscount.enable();
    }
  }

  public changeValueDiscount(){
    if(this.SwitchValueDiscount.value === true){
      this.ValueDiscount.disable();
      this.ValueDiscount.setValue(0);
      this.isValueDiscountValidator = false;
    }else{
      this.ValueDiscount.enable();
    }
  }

  public changeFinancialDiscount(){
    if(this.SwitchFinancialDiscount.value === true){
      this.FinancialDiscount.disable();
      this.FinancialDiscount.setValue(0);
      this.isFinancialDiscountValidator = false;
    }else{
      this.FinancialDiscount.enable();
    }
  }

  public tooltipTypeComercialActivity(){
    if(this.TypeComercialActivity.value.length > 0){
      this.tooltipCommercialActivity = [];
      this.objectPromotionalActivity.forEach((x) => {
        this.TypeComercialActivity.value.forEach((elm : any) => {
          if(x.value === elm){
            this.tooltipCommercialActivity.push(x.label);
          }
        });
      });
    }else this.tooltipCommercialActivity = [];

  }

  public getYearRange() {
    this.currentYear = this.today.getFullYear();
    this.endYear = this.currentYear + 10;
    return `${this.currentYear}:${this.endYear}`;
  }

  clearDatePicker() {
    this.RangeDateControl.reset();
    this.rangeDateData = new DataRangeModel();
  }

  public addRangeDate(event: any) {
    if (this.RangeDateControl.value == null || this.RangeDateControl.value.length === 0) {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.info(
        language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
        language === 'es-ES' ? 'Debe seleccionar un rago de fechas.' : 'You must select a date range.');
    } else {
      const startDate = new Date(this.RangeDateControl.value[0]);
      const endDate = new Date(this.RangeDateControl.value[1]);

      if(startDate.getDate() === endDate.getDate()){
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.info(
          language === 'es-ES' ? SweetAlert.titleAlertWarning : SweetAlert.titleAlertWarningTranslate,
          language === 'es-ES' ? 'Fecha inicial no puede ser igual a fecha final.'
          : 'Start date cannot be the same as end date');
        this.RangeDateControl.reset();
        return;
      }

      const days = parseInt(`${(this.getDateDiff(startDate, endDate) + 1)}`, 10);

      this.rangeDateData = new DataRangeModel(
        startDate,
        endDate,
        days,
        0, //revisar que se debe enviar this.oldRangeDateData.id
      );
      const language: string = sessionStorage.getItem('language');
      this.showToast(SweetAlert.iconSuccess,
        SweetAlert.iconSuccess,
        language === 'es-ES' ? SweetAlert.messageAlertCreateDate : SweetAlert.messageAlertCreateDateTranslate);
    }
  }


  getDateDiff(start: Date, end: Date) {
    return Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  }


  public next(){
    this.getValidators();
    if(this.listError.length > 0) return;
    this.showMaterialFilter = false;
  }

  public cancel() {
    this.router.navigate(['/auth/exclusion-by-sku']);
  }

  public getModelCreateExclusion(isUpdate: boolean = false){
    const id = isUpdate ? this.exclusionModel.id : 0;
    this.exclusionModel = new ExlusionBySkuModel(
      id,
      this.Description.value,
      this.TypeComercialActivity.value,
      this.Channel.value,
      this.rangeDateData,
      this.StopOrderPromotion.value ?? 0,
      this.StopOrderEvents.value ?? 0,
      this.StopOrderZeroValue.value ?? 0,
      this.StopOrderLoyalty.value ?? 0,
      this.StopOrderDiscountKind.value?? 0,
      this.StopOrderDiscountValue.value ?? 0,
      this.StopOrderDiscountFinancial.value ?? 0,
      this.StopOrderRappel.value ?? 0,
      this.CapPeriodPromotion.value ?? 0,
      this.CapPeriodEvents.value ?? 0,
      this.CapPeriodZeroValue.value ?? 0,
      this.CapPeriodLoyalty.value ?? 0,
      this.CapPeriodDiscountKind.value ?? 0,
      this.CapPeriodDiscountValue.value ?? 0,
      this.CapPeriodDiscountFinancial.value ?? 0,
      this.CapPeriodRappel.value ?? 0,
      this.PurchaseBase.value,
      this.PromotionBase.value,
      this.PromotionPurchanse.value,
      this.KindDiscount.value,
      this.FinancialDiscount.value,
      this.ValueDiscount.value,
      this.jsonMaterialFilter,
      this.jsonFilterClient,
      1,
      this.productFilterListPromotion.map((p) => p.materialCode)
    );
  }

  public showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  public setValueDataRange() {
    const dateStart = new Date(this.rangeDateData.dateStart);
    const dateEnd = new Date(this.rangeDateData.dateEnd);
    const arrDates = [dateStart, dateEnd];
    this.RangeDateControl.setValue(arrDates);
  }


  detailRedirect(exclusionId: any){
    this.exclusionsBySkuService.setExclusionId(exclusionId);
    this.exclusionsBySkuService.setProcessExclusion(this.comercialActivityEnum.view);
    this.router.navigate(['auth/exclusion-by-sku/create-edit']);
  }

  //#endregion

  //#region Filter Material Seccion
    public eventClickFilters($event : any){
      if ($event.advancedFiltersObjectList.length > 0) {
        this.spinner.show();
        this.advancedFiltersObjectList = $event.advancedFiltersObjectList;
        this.jsonMaterialFilter = JSON.stringify($event.advancedFiltersObjectList);
        const newFilterModel = new FilterDataModel(
          JSON.stringify($event.advancedFiltersObjectList),
          environment.conditionTable,
          environment.conditionClassCode,
          false
        );

        this.getMaterialsByFilters(newFilterModel);
        this.spinner.hide();
      } else {
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
          language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS
          : ManagePromotionalActivitiesMessage.NOT_FILTERS_TRANSLATE);
      }

    }

    private getMaterialsByFilters(filters: FilterDataModel): void {
      this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
        (response) => {
          if (response.data.length > 0) {
            const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconSuccess,
              language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
              language === 'es-ES' ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`
              : `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`);

            const listProducts: IProductList[] = response.data.map((item: any) => {
              return {
                check: false,
                product: item.product,
                hierarchy: item.hierarchy,
                purchaseBaseId: 0,
                materialCode: item.materialCode,
                measurementUnitCode: '',
                purchaseUnit: 0,
                porcent: 0,
                discountValue: 0,
                id: this.comercialActivityEnum.create ? item.id : 0,
                currentPsp: item.currentPsp,
                zofeCode: item.zofeCode,
                zofeDescription : item.zofeDescription
              };
            });

            // if (this.process === ComercialActivityEnum.update || this.process === ComercialActivityEnum.view) {
            //   const productsDetail = listProducts.filter((x) => this.listProductsDetail.includes(x.materialCode));
            //   this.productFilterListPromotion = productsDetail;
            //   return;
            // }

            this.productFilterListPromotion = listProducts;
          } else {
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT
              : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE);
          }
          this.spinner.hide();
        }, (error) => {
          this.spinner.hide();
          console.error(error);
        }
      );
    }

    public deleteGroupAction(element: IProductList) {

      Util.confirmDelete().then((result: SweetAlertResult) => {
        if (result.value) {
          this.spinner.show();
          if (element) {
            this.productFilterListPromotion = this.productFilterListPromotion.filter((x) => x.id !== element.id);
          } else {
            this.productFilterListPromotion = this.productFilterListPromotion.filter((x) => x.check === false);
            this.isDeleteAll = true;
          }
          this.spinner.hide();
        }
      });
    }

  //#endregion

  //#region  Filter Clientes
    eventClickFiltersClient($event: any) {
      this.summaryClients = null;
      this.summaryClientsJson = [];
      if ($event.advancedFiltersObjectList.length > 0) {

        this.advancedFiltersClients(JSON.stringify($event.advancedFiltersObjectList));
        this.summaryClientsJson = $event.advancedFiltersObjectList;

        this.summaryClientsJson = this.summaryClientsJson.map((item) => {
          return {
            id: item.id,
            type: item.type,
            typeDescription: item.typeDescription,
            content: item.content,
            contentDescription: item.contentDescription,
            operator: item.operator
          };
        });

        this.jsonFilterClient = JSON.stringify(this.summaryClientsJson);
      } else {
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
          language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS
          : SolicitudeMessage.NOT_FILTERS_TRANSLATE);
      }
    }

    public advancedFiltersClients(filters: string): void {
      this.spinner.show();
      this.totalClients = 0;
      const objFilters: IAdvancedFiltersClient = new AdvancedFiltersClientModel(
        filters,
        true,
        1,
        100
      );

      this.spinner.show();

      this.assingClientService.postAdvancedFiltersClients(objFilters)
        .subscribe(
          (respose) => {
          this.filterResult = respose.data;
            if (this.filterResult.totalRecords > 0) {
            this.totalClients = this.filterResult.totalRecords;
            const listFilters = this.filterResult.results.map((item) => {
              return {
                typeFilter: item.typeFilter,
                groupClients: item.groupClients,
                codeType: item.codeType,
                labelType: item.labelType,
                total: item.total
              };
            });

            this.spinner.hide();
            this.summaryClients = [...listFilters];
          }
          else {
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS_RESULT_CLIENTS
              : SolicitudeMessage.NOT_FILTERS_RESULT_CLIENTS_TRANSLATE);
          }

        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
            this.spinner.hide();
      });
    }


    public eventClickDeleteFiltersClient(){

    }

    public showModal(object: SummaryClientModel): void {
      const filterClientDetails: IDetailFilterClientJson = new DetailFilterClientModel(
        object.typeFilter,
        object.groupClients,
        object.codeType
      );

      const objFilters: IAdvancedFiltersClient = new AdvancedFiltersClientModel(
        JSON.stringify(filterClientDetails),
        true,
        1,
        100
      );

      this.assingClientService.postAdvancedFiltersClientsDetails(objFilters)
        .subscribe(
          (response) => {
            if (response.status) {

              this.detailsResult = response.data;
              const listDetails = this.detailsResult.map((item) => {
                return {
                  type: item.type,
                  sClientCode: item.sClientCode,
                  sCustomerName: item.sCustomerName
                };
              });
              this.detailsClientsResult = [...listDetails];
            }
            else {
              this.spinner.hide();
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }

          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          },
          () => {
            this.spinner.hide();
          }
        );

      this.summaryClientsJson = [];
      this.displayModal = true;
    }

    public hideModal(): void {
      this.displayModal = false;
    }

    return() {
      this.editFiltersObjectList = this.process == ComercialActivityEnum.create ? [...this.advancedFiltersObjectList] : [...this.editFiltersObjectList];
      this.showMaterialFilter = true;
    }

    save(){
      Util.confirmSave().then((result: SweetAlertResult) => {
        if(result.isConfirmed){

          this.getValidators();
          if(this.listError.length > 0) return;

          this.spinner.show();
          this.getModelCreateExclusion(false);
          this.exclusionsBySkuService.postExclusion(this.exclusionModel).subscribe(
            (response) => {
              if(response.status){
                this.spinner.hide();
                const language: string = sessionStorage.getItem('language');
                this.showToast(
                        SweetAlert.iconSuccess,
                        language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                        language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate
                      );

                      setTimeout(() => {
                        this.router.navigate(['auth/exclusion-by-sku/']);
                      }, 3000);
                    }else {
                this.spinner.hide();
                this.listError = [];
                response.data.forEach((x) => {
                  this.listError.push(x);
                });
                this.displayModalList = true;
              }
            },
            (error : HttpErrorResponse) => {
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate);
            }
          );
        }
       });
    }

    update(){
      Util.confirmEdit().then((result: SweetAlertResult) => {
        if(result.isConfirmed){

          this.getValidators();
          if(this.listError.length > 0) return;

          this.spinner.show();
          this.addRangeDate('');
          this.getModelCreateExclusion(true);

          this.exclusionsBySkuService.putExclusion(this.exclusionModel)
          .subscribe((response) => {
            this.spinner.hide();
            if(response.status){
              const language: string = sessionStorage.getItem('language');
              this.showToast(
                SweetAlert.iconSuccess,
                language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                language === 'es-ES' ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate
              );

              setTimeout(() => {
                this.router.navigate(['auth/exclusion-by-sku/']);
              }, 3000);

            }else{
              this.listError = [];
              this.listError = response.data;
              this.displayModalList = true;
              // return;
            }
          },
          (error) => {
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate);
          });
        }
      });
    }
  //#endregion


  viewDetailsOverlap(object : any){
    this.exclusionsBySkuService.getExclusionWithDetailById(object)
    .subscribe(
      (response) => {
        if(response.status){
          this.setDetailsOverlap(response.data);
          this.displayModalList2 = true;
        }
      },() => {
        SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
      });

  }

  setDetailsOverlap(exclusion: ExlusionBySkuModel){
    let listOverlapModel : ExlusionOverlapModel;
    const exclusionEdit = this.convertExclusion(exclusion);
    const exclusionCreate = this.convertExclusion(this.exclusionModel);

    if(exclusionEdit != null && exclusionCreate != null){
      listOverlapModel = this.compareExclusion(exclusionEdit, exclusionCreate);
      }
      this.listOverlap.push(listOverlapModel);

  }

  convertExclusion(exclusion: ExlusionBySkuModel) : ExlusionOverlapModel{
    this.listOverlap = [];
    let allValueTypeComercialActivity: string[] = [];
    let allNameTypeComercialActivity = '';

    let allValueTypeChannel: string[] = [];
    let allNameTypeChannel = '';

    const clientFilter =  JSON.parse(exclusion.clientFilter);
    let objClientFilter = '';

    let productFilterListPromotion : any[] = [];
    let objNameSku  = '';
    let objCodeSku  = '';

    let validityPeriod  = '';
    let dateStart  = '';
    let dateEnd  = '';


    // Tipo Actividad Comercial
    allValueTypeComercialActivity = exclusion.typeBusinessActivity.map((x) => {return x;});
    if(allValueTypeComercialActivity.length !== 0){
      allValueTypeComercialActivity.forEach(x =>{
        const findTypeComercialActivity = this.objectPromotionalActivity.filter((y) => y.value === x).map((w) => w.label);
        if(findTypeComercialActivity != null){
          findTypeComercialActivity.forEach((elm) => {
            allNameTypeComercialActivity += elm+', ';
          });
        }
      });
    }

    // Canales
    allValueTypeChannel = exclusion.canal.map((x) => {return x;});
    if(allValueTypeChannel.length !== 0){
      allValueTypeChannel.forEach((x) => {
        const findTypeChannel = this.objectChannel.filter((y) => y.value === x).map((w) => w.label);
        if(findTypeChannel != null){
          findTypeChannel.forEach((elm)=> {
            allNameTypeChannel += elm.trim()+', ';
          });
        }
      });
    }

    // Clientes
    const allNameClientFilter = clientFilter.map((x: any) => {
      return {
        'typeDescription' : x.typeDescription,
        'contentDescription' : x.contentDescription
      };
    });
    if(allNameClientFilter.length !== 0){
      objClientFilter ='';
      allNameClientFilter.forEach((x : any) => {
        objClientFilter += x.contentDescription+', ';
      });
    }


    // Nombres y codigos skus
    const materials : IProductList[] = !exclusion.materialsList ? this.productFilterListPromotion : exclusion.materialsList;
    productFilterListPromotion = materials.map((x) => {
      return {
        'product' : x.product,
        'materialCode' : x.materialCode
      };
    });
    if(productFilterListPromotion.length !== 0){
      productFilterListPromotion.forEach((x) => {
        objNameSku += x.product+', ';
        objCodeSku += x.materialCode+', ';
      });
    }

    // Fechas
    dateStart = this.miDatePipe.transform(exclusion.validityPeriod.dateStart,'dd/MM/yyyy');
    dateEnd = this.miDatePipe.transform(exclusion.validityPeriod.dateEnd,'dd/MM/yyyy');
    if(dateStart !== '' && dateEnd !=='')
      validityPeriod = dateStart +'-'+dateEnd;

    const listOverlapModel = new ExlusionOverlapModel (
      allNameTypeComercialActivity,
      allNameTypeChannel,
      objNameSku,
      objCodeSku,
      objClientFilter,
      validityPeriod
    );

    return listOverlapModel;
  }

  compareExclusion(exclusionEdit : ExlusionOverlapModel, exclusionCreate : ExlusionOverlapModel) : ExlusionOverlapModel{
   let clientFilter = '';
   let groupNameSku = '';
   let codeSku = '';
   let typeActivity = '';
   let channel = '';
   const date = '';

   const auxClientFilterEdit = exclusionEdit.clientFilter.split(',');
   const auxClientFilterCreate = exclusionCreate.clientFilter.split(',');

   const auxGroupNameSkuEdit = exclusionEdit.groupNameSku.split(',');
   const auxGroupNameSkuCreate = exclusionCreate.groupNameSku.split(',');

   const auxCodeSkuEdit = exclusionEdit.codeSku.split(',');
   const auxCodeSkuCreate = exclusionCreate.codeSku.split(',');

   const auxTypeActivityEdit = exclusionEdit.typeBusinessActivity.split(',');
   const auxTypeActivityCreate = exclusionCreate.typeBusinessActivity.split(',');

   const auxChannelEdit = exclusionEdit.channel.split(',');
   const auxChannelCreate = exclusionCreate.channel.split(',');

    // Clientes
    auxClientFilterCreate.forEach((x) => {
      const findClientFilter = auxClientFilterEdit.filter((y) => y === x);
      if(findClientFilter.length > 0)
        findClientFilter.forEach((elm) => {
          if(elm !== ' ') clientFilter += elm.trim()+', ';
        });
    });

    // Nombre Sku
    auxGroupNameSkuCreate.forEach((x) => {
      const findGroupNameSku = auxGroupNameSkuEdit.filter((y) => y.trim().replace('  ', ' ') === x.trim().replace('  ', ' '));
      if(findGroupNameSku.length > 0){
        findGroupNameSku.forEach((elm) => {
          if(elm !== ' ') groupNameSku += elm.trim()+', ';
        });
      }
    });

    // Codigo Sku
    auxCodeSkuCreate.forEach((x) => {
      const findCodeSku = auxCodeSkuEdit.filter((y) => y.trim() === x.trim());
      if(findCodeSku.length > 0){
        findCodeSku.forEach((elm) => {
          if(elm !== ' ') codeSku += elm.trim()+', ';
        });
      }
    });

    // Tipo Actividad
    auxTypeActivityCreate.forEach((x) => {
      const findTypeActivity = auxTypeActivityEdit.filter((y) => y.trim() === x);
      if(findTypeActivity.length > 0){
        findTypeActivity.forEach((elm) => {
          if(elm !== '') typeActivity += elm.trim()+', ';
        });
      }
    });

    // Canal
    auxChannelCreate.forEach(x => {
      const findChannel = auxChannelEdit.filter((y) => y === x);
      if(findChannel.length > 0){
        findChannel.forEach((elm) => {
          if(elm !== ' ') channel += elm.trim()+', ';
        });
      }
    });

    const listOverlapModel = new ExlusionOverlapModel (
      typeActivity,
      channel,
      groupNameSku,
      codeSku,
      clientFilter,
      exclusionEdit.validityPeriod
    );

    return listOverlapModel;
  }

  public hideModal2( condition : boolean): void {
    if(condition === true) this.displayModalList = false;
    else this.displayModalList2 = false;
  }

  //#region Validations

  public getValidators(){

    this.listError = [];

    const vDescription = this.validateDescription();
    const vTypeActivity = this.validateTypeActivity();

    const vPeriodPromotion = this.validationTopePeriod(this.CapPeriodPromotion.value, this.StopOrderPromotion.value, CommercialActivitySubprocess.Promotions, 'promoción');
    const vPeriodEvents = this.validationTopePeriod(this.CapPeriodEvents.value, this.StopOrderEvents.value, CommercialActivitySubprocess.Events, 'eventos');
    const vPeriodZeroValue = this.validationTopePeriod(this.CapPeriodZeroValue.value, this.StopOrderZeroValue.value, CommercialActivitySubprocess.ZeroValueProduct, 'producto valor cero');
    const vPeriodLoyalty = this.validationTopePeriod(this.CapPeriodLoyalty.value, this.StopOrderLoyalty.value, CommercialActivitySubprocess.Loyalty, 'fidelización');
    const vPeriodDiscountKind = this.validationTopePeriod(this.CapPeriodDiscountKind.value, this.StopOrderDiscountKind.value, CommercialActivitySubprocess.DiscountKind, 'descuento en especie');
    const vPeriodDiscountValue = this.validationTopePeriod(this.CapPeriodDiscountValue.value, this.StopOrderDiscountValue.value, CommercialActivitySubprocess.DiscountValue, 'descuento en valor');
    const vPeriodDiscountFinancial = this.validationTopePeriod(this.CapPeriodDiscountFinancial.value, this.StopOrderDiscountFinancial.value, CommercialActivitySubprocess.FinancialDiscount, 'descuento financiero');
    const vPeriodRappel = this.validationTopePeriod(this.CapPeriodRappel.value, this.StopOrderRappel.value, CommercialActivitySubprocess.Rappel, 'rappel');

    const vOrderPromotion = this.validationTopeOrder(this.CapPeriodPromotion.value, this.StopOrderPromotion.value, CommercialActivitySubprocess.Promotions, 'promoción');
    const vOrderEvents = this.validationTopeOrder(this.CapPeriodEvents.value, this.StopOrderEvents.value, CommercialActivitySubprocess.Events, 'eventos');
    const vOrderZeroValue = this.validationTopeOrder(this.CapPeriodZeroValue.value, this.StopOrderZeroValue.value, CommercialActivitySubprocess.ZeroValueProduct, 'producto valor cero');
    const vOrderLoyalty = this.validationTopeOrder(this.CapPeriodLoyalty.value, this.StopOrderLoyalty.value, CommercialActivitySubprocess.Loyalty, 'fidelización');
    const vOrderDiscountKind = this.validationTopeOrder(this.CapPeriodDiscountKind.value, this.StopOrderDiscountKind.value, CommercialActivitySubprocess.DiscountKind, 'descuento en especie');
    const vOrderDiscountValue = this.validationTopeOrder(this.CapPeriodDiscountValue.value, this.StopOrderDiscountValue.value, CommercialActivitySubprocess.DiscountValue, 'descuento en valor');
    const vOrderDiscountFinancial = this.validationTopeOrder(this.CapPeriodDiscountFinancial.value, this.StopOrderDiscountFinancial.value, CommercialActivitySubprocess.FinancialDiscount, 'descuento financiero');
    const vOrderRappel = this.validationTopeOrder(this.CapPeriodRappel.value, this.StopOrderRappel.value, CommercialActivitySubprocess.Rappel, 'rappel');

    const Promotion = this.validationZeroMoreTopes(this.CapPeriodPromotion.value, this.StopOrderPromotion.value, 'promoción');
    const Events = this.validationZeroMoreTopes(this.CapPeriodEvents.value, this.StopOrderEvents.value, 'eventos');
    const ZeroValue = this.validationZeroMoreTopes(this.CapPeriodZeroValue.value, this.StopOrderZeroValue.value, 'producto valor cero');
    const Loyalty = this.validationZeroMoreTopes(this.CapPeriodLoyalty.value, this.StopOrderLoyalty.value, 'fidelización');
    const DiscountKind = this.validationZeroMoreTopes(this.CapPeriodDiscountKind.value, this.StopOrderDiscountKind.value, 'descuento en especie');
    const DiscountValue = this.validationZeroMoreTopes(this.CapPeriodDiscountValue.value, this.StopOrderDiscountValue.value, 'descuento en valor');
    const DiscountFinancial = this.validationZeroMoreTopes(this.CapPeriodDiscountFinancial.value, this.StopOrderDiscountFinancial.value, 'descuento financiero');
    const Rappel = this.validationZeroMoreTopes(this.CapPeriodRappel.value, this.StopOrderRappel.value, 'rappel');

    const vChannel = this.validateChannel();
    const vRangeDate = this.validateRangeDate();
    const vFilterMaterial = this.validateFilterMaterial();
    const vKingDiscount = this.validateKindDiscount();
    const vFinancialDiscount = this.validateFinancialDiscount();
    const vValueDiscount = this.validateValueDiscount();
    const vFilterClient = this.validateFilterClient();
    const vValuesTableFilterClient = this.validateValuesTableFilterClient();
    
    if(vDescription !== undefined) this.listError.push(vDescription);
    if(vTypeActivity !== undefined) this.listError.push(vTypeActivity);
    if(vPeriodPromotion !== undefined) this.listError.push(vPeriodPromotion);
    if(vOrderPromotion !== undefined) this.listError.push(vOrderPromotion);
    if(vPeriodEvents !== undefined) this.listError.push(vPeriodEvents);
    if(vOrderEvents !== undefined) this.listError.push(vOrderEvents);
    if(vPeriodZeroValue !== undefined) this.listError.push(vPeriodZeroValue);
    if(vOrderZeroValue !== undefined) this.listError.push(vOrderZeroValue);
    if(vPeriodLoyalty !== undefined) this.listError.push(vPeriodLoyalty);
    if(vOrderLoyalty !== undefined) this.listError.push(vOrderLoyalty);
    if(vPeriodDiscountKind !== undefined) this.listError.push(vPeriodDiscountKind);
    if(vOrderDiscountKind !== undefined) this.listError.push(vOrderDiscountKind);
    if(vPeriodDiscountValue !== undefined) this.listError.push(vPeriodDiscountValue);
    if(vOrderDiscountValue !== undefined) this.listError.push(vOrderDiscountValue);
    if(vPeriodDiscountFinancial !== undefined) this.listError.push(vPeriodDiscountFinancial);
    if(vOrderDiscountFinancial !== undefined) this.listError.push(vOrderDiscountFinancial);
    if(vPeriodRappel !== undefined) this.listError.push(vPeriodRappel);
    if(vOrderRappel !== undefined) this.listError.push(vOrderRappel);
    if(vChannel !== undefined) this.listError.push(vChannel);
    if(vRangeDate !== undefined) this.listError.push(vRangeDate);
    if(vFilterMaterial !== undefined) this.listError.push(vFilterMaterial);
    if(vKingDiscount !== undefined) this.listError.push(vKingDiscount);
    if(vFinancialDiscount !== undefined) this.listError.push(vFinancialDiscount);
    if(vValueDiscount !== undefined) this.listError.push(vValueDiscount);
    if(vFilterClient !== undefined) this.listError.push(vFilterClient);
    if(vValuesTableFilterClient !== undefined) this.listError.push(vValuesTableFilterClient);


    if(Promotion !== undefined) this.listError.push(Promotion);
    if(Events !== undefined) this.listError.push(Events);
    if(ZeroValue !== undefined) this.listError.push(ZeroValue);
    if(Loyalty !== undefined) this.listError.push(Loyalty);
    if(DiscountKind !== undefined) this.listError.push(DiscountKind);
    if(DiscountValue !== undefined) this.listError.push(DiscountValue);
    if(DiscountFinancial !== undefined) this.listError.push(DiscountFinancial);
    if(Rappel !== undefined) this.listError.push(Rappel);

    // if(this.validateTopes) this.listError.push('El campo de tope por pedido no debe superar el tope por periodo');
    // if(this.isTopesZero) this.listError.push('Los campos de tope por pedido y tope por periodo deben ser mayor a cero');
    if(this.listError.length > 0) SweetAlertMessageHelpers.listWarningMessageShow(SweetAlert.titleAlertError, this.listError);
    return;
  }

  validationZeroMoreTopes(period: number, order: number, messageES: string): string{   
    if((period != null && period <= 0) && (order != null && order <= 0)){
        return 'Los campos de tope por pedido y tope por periodo deben ser mayor a cero para la actividad '+messageES;
    }else if((period < order) && (period != null && order != null)){
        return 'El campo de tope por pedido no debe superar el tope por periodo para la actividad '+messageES;
    }
  }

  validationTopePeriod(period: number, order: number, activity: number, messageES: string): string{
    if(period == null && order != null) return 'El campo de tope por periodo no puede ser vacio para la actividad ' + messageES;
    if(this.TypeComercialActivity.value.includes(activity) && 
    (period > 0 && order == 0)) return 'Debe agregar un limite por pedido para la actividad ' + messageES;
  }

  validationTopeOrder(period: number, order: number, activity: number, messageES: string): string{
    if(order == null && period != null) return 'El campo de tope por pedido no puede ser vacio para la actividad ' + messageES;
    if(this.TypeComercialActivity.value.includes(activity) && 
    (order > 0 && period == 0)) return 'Debe agregar un limite por periodo para la actividad ' + messageES;
  }

  public validateDescription(): string{
    if(this.Description.value === '') return 'Debe agregar una descripcion a la exclusion.';
  }

  public validateTypeActivity(): string{
    if(this.TypeComercialActivity.value === 0) return 'Debe seleccionar una actividad comercial';
  }

  public validateChannel(){
    if(this.Channel.value == null || this.Channel.value.length === 0) return 'Debe agregar un canal.';
  }

  public validateRangeDate(){
    if (this.RangeDateControl.value == null || this.RangeDateControl.value.length === 0) 
        return 'Debe Añadir un rango de fechas para la exclusión.';
  }

  public validateFilterMaterial(){
    if(this.jsonMaterialFilter == null || this.jsonMaterialFilter === '' || 
      this.productFilterListPromotion.length === 0) return 'Debe Seleccionar los materiales de la exclusión.';
  }

  public validateKindDiscount(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountKind) &&
      (this.KindDiscount.value === 0 || this.KindDiscount.value === '') && !this.SwitchKindDiscount.value) 
        return 'Debe agregar un limite de descuento para la actividad descuento en especie.';
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountKind) && 
      !this.SwitchKindDiscount.value && this.KindDiscount.value > 100) 
        return 'Descuento en especie solo permite valores entre 1 y 100.';
  }

  public validateFinancialDiscount(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.FinancialDiscount) && 
    (this.FinancialDiscount.value === 0 || this.FinancialDiscount.value === '') && !this.SwitchFinancialDiscount.value) 
        return 'Debe agregar un limite de descuento para la actividad descuento financiero.';
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.FinancialDiscount) && 
      !this.SwitchFinancialDiscount.value && this.FinancialDiscount.value > 100) 
        return 'Descuento Financiero solo permite valores entre 1 y 100.';
  }

  public validateValueDiscount(){
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountValue) && 
    (this.ValueDiscount.value === 0 || this.ValueDiscount.value === '') && !this.SwitchValueDiscount.value) 
        return 'Debe agregar un limite de descuento para la actividad descuento en valor.';
    if(this.TypeComercialActivity.value.includes(CommercialActivitySubprocess.DiscountValue) && 
      !this.SwitchValueDiscount.value && this.ValueDiscount.value > 100) 
        return 'Descuento en valor solo permite valores entre 1 y 100..';
  }


  public validateFilterClient(){
    if(!this.showMaterialFilter && this.jsonFilterClient == null || this.jsonFilterClient === '') 
        return 'Debe seleccionar los clientes de la exclusión.';
  }

  public validateValuesTableFilterClient(){
    if(!this.showMaterialFilter && this.summaryClients === undefined) return 'Debe agregar clientes a la exclusión';
  }

  //#endregion


  initForm(): void {
    this.formExclusions = this.fb.group({
      Description: [{ value: '', disabled: false }, [Validators.required]],
      TypeComercialActivity: [{ value: '', disabled: false }, [Validators.required]],
      Channel: [{ value: '', disabled: false }, [Validators.required]],
      RangeDateControl: [{ value: '', disabled: false }, [Validators.required]],
      PurchaseBase: [{ value: false, disabled: false }],
      PromotionBase: [{ value: false, disabled: false }],
      StopOrderPromotion: [{ value: null, disabled: false }],
      StopOrderDiscountKind: [{ value: null, disabled: false }],
      StopOrderDiscountValue: [{ value: null, disabled: false }],
      StopOrderDiscountFinancial: [{ value: null, disabled: false }],
      StopOrderEvents: [{ value: null, disabled: false }],
      StopOrderZeroValue: [{ value: null, disabled: false }],
      StopOrderLoyalty: [{ value: null, disabled: false }],
      StopOrderRappel: [{ value: null, disabled: false }],
      CapPeriodPromotion: [{ value: null, disabled: false }],
      CapPeriodDiscountKind: [{ value: null, disabled: false }],
      CapPeriodDiscountValue: [{ value: null, disabled: false }],
      CapPeriodDiscountFinancial: [{ value: null, disabled: false }],
      CapPeriodEvents: [{ value: null, disabled: false }],
      CapPeriodZeroValue: [{ value: null, disabled: false }],
      CapPeriodLoyalty: [{ value: null, disabled: false }],
      CapPeriodRappel: [{ value: null, disabled: false }],
      PromotionPurchanse: [{ value: false, disabled: false }],
      SwitchKindDiscount: [{value: false, disabled: false }],
      SwitchFinancialDiscount: [{value: false, disabled: false }],
      SwitchValueDiscount: [{value: false, disabled: false }],
      KindDiscount: [{value: '', disabled: false }],
      FinancialDiscount: [{value: '', disabled: false }],
      ValueDiscount: [{value: '', disabled: false }],
    });
  }

  get Description(): AbstractControl { return this.formExclusions.get('Description'); }
  get TypeComercialActivity(): AbstractControl { return this.formExclusions.get('TypeComercialActivity'); }
  get Channel(): AbstractControl { return this.formExclusions.get('Channel'); }
  get RangeDateControl(): AbstractControl { return this.formExclusions.get('RangeDateControl'); }
  get PurchaseBase(): AbstractControl { return this.formExclusions.get('PurchaseBase'); }
  get PromotionBase(): AbstractControl { return this.formExclusions.get('PromotionBase'); }

  get StopOrderPromotion(): AbstractControl { return this.formExclusions.get('StopOrderPromotion'); }
  get StopOrderDiscountKind(): AbstractControl { return this.formExclusions.get('StopOrderDiscountKind'); }
  get StopOrderDiscountValue(): AbstractControl { return this.formExclusions.get('StopOrderDiscountValue'); }
  get StopOrderDiscountFinancial(): AbstractControl { return this.formExclusions.get('StopOrderDiscountFinancial'); }
  get StopOrderEvents(): AbstractControl { return this.formExclusions.get('StopOrderEvents'); }
  get StopOrderZeroValue(): AbstractControl { return this.formExclusions.get('StopOrderZeroValue'); }
  get StopOrderLoyalty(): AbstractControl { return this.formExclusions.get('StopOrderLoyalty'); }
  get StopOrderRappel(): AbstractControl { return this.formExclusions.get('StopOrderRappel'); }
      
  get PromotionPurchanse(): AbstractControl { return this.formExclusions.get('PromotionPurchanse'); }
  get SwitchKindDiscount() : AbstractControl { return this.formExclusions.get('SwitchKindDiscount'); }
  get SwitchFinancialDiscount() : AbstractControl { return this.formExclusions.get('SwitchFinancialDiscount'); }
  get SwitchValueDiscount() : AbstractControl { return this.formExclusions.get('SwitchValueDiscount'); }
  get KindDiscount() : AbstractControl { return this.formExclusions.get('KindDiscount'); }
  get FinancialDiscount() : AbstractControl { return this.formExclusions.get('FinancialDiscount'); }
  get ValueDiscount() : AbstractControl { return this.formExclusions.get('ValueDiscount'); }

  get CapPeriodPromotion(): AbstractControl { return this.formExclusions.get('CapPeriodPromotion'); }
  get CapPeriodDiscountKind(): AbstractControl { return this.formExclusions.get('CapPeriodDiscountKind'); }
  get CapPeriodDiscountValue(): AbstractControl { return this.formExclusions.get('CapPeriodDiscountValue'); }
  get CapPeriodDiscountFinancial(): AbstractControl { return this.formExclusions.get('CapPeriodDiscountFinancial'); }
  get CapPeriodEvents(): AbstractControl { return this.formExclusions.get('CapPeriodEvents'); }
  get CapPeriodZeroValue(): AbstractControl { return this.formExclusions.get('CapPeriodZeroValue'); }
  get CapPeriodLoyalty(): AbstractControl { return this.formExclusions.get('CapPeriodLoyalty'); }
  get CapPeriodRappel(): AbstractControl { return this.formExclusions.get('CapPeriodRappel'); }

}
