import { DataRangeModel } from '@app/@components/commercial-activity-footer/models/data-range.model';
import { IExclusion, IExclusionChannel, IExclusionDetailActivity, IExclusionMaterial, IExclusionCustomer } from '../interfaces/exclusions.interface';

export class ExclusionInformationModel implements IExclusion {
  /**
 * @author Priscy Antonio Reales
 * @sprint 7
 * @description Model for update status
 */
  constructor(
    public id?: number,
    public ExclusionReason?: string,
    public JsonFilter?: string,
    public exclusionDetailActivity?: IExclusionDetailActivity[],
    public exclusionChannel?: IExclusionChannel[],
    public exclusionMaterial?: IExclusionMaterial[],
    public jsonFilterClients?: string,
    public exclusionCustomer?: IExclusionCustomer[],
    public rangeDateData?: DataRangeModel
  ) { }
}
