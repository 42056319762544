export enum SolicitudeStatus {
    Registered = 3,
    InProgress = 4,
    Modification = 5,
    InRecord = 6,
    Rejected = 7,
    Managed = 8,
    Temporary = 9,
    Pending = 95,
    ManagedWithNovelty = 130,
    InProcessDelete = 138,
    InProcessApproval = 148
}
