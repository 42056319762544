<section>
    <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="pstn breadcrumb">
              <li class="breadcrumb-item">
              <a [routerLink]="'/auth'">
                  <i class="uil uil-estate" aria-hidden="true" ></i>{{ 'Home' | translate }}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ "LiberationHierarchyComponent.Title" | translate }}
              </li>
          </ol>
        </nav>
    </div>
  </section>

  <section class="pstngestion">
    <div class="container">
      <div class="row">
          <h1 class="pstntitle">{{ 'LiberationHierarchyComponent.Title' | translate }}</h1>
      </div>
    <div class="mt-2"></div>
  </div>

    <div class="container">
        <form id="formLiberationHerarchy" [formGroup]="formLiberationHerarchy">
            <div class="row mt-3">
            <div class="col-md-6">
                      <label>{{ 'LiberationHierarchyComponent.IdHierarchy' | translate }}: {{ editId }}</label>
                      <div class="form-group">
                      <label for="exampleFormControlInput1">{{ 'LiberationHierarchyComponent.NameHierarchy' | translate }}</label>
                        <input id="nameHierarchy"
                                    formControlName="nameHierarchy"
                                    class="form-control"
                                    [ngClass]="{'ng-invalid ng-dirty':nameHierarchy.invalid && nameHierarchy.touched}"
                                    pInputText pattern="^[-a-zA-Z_&`´#. áéíóúÁÉÍÓÚÑñÇç]*$"
                                    placeholder="{{ 'LiberationHierarchyComponent.Description' | translate }}"
                                    (paste)="onPaste($event, true)"
                                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) 
                                        || (event.charCode >= 97 && event.charCode <= 122) 
                                        || event.charCode == 209 || event.charCode == 241
                                        || event.charCode == 32
                                        || event.charCode == 193 || event.charCode == 250
                                        || event.charCode == 225 || event.charCode == 201
                                        || event.charCode == 233 || event.charCode == 205
                                        || event.charCode == 237 || event.charCode == 211
                                        || event.charCode == 243 || event.charCode == 218)"
                         required/>
                    <small id="nameHierarchyCodeValidator"
                        *ngIf="formLiberationHerarchy.controls['nameHierarchy'].invalid 
                        && (formLiberationHerarchy.controls['nameHierarchy'].dirty ||
                        formLiberationHerarchy.controls['nameHierarchy'].touched)"
                        class="p-error">{{ 'LiberationHierarchyComponent.NameHierarchyMsgValidate' | translate }}
                    </small>
                </div>
              </div>
              <div class="col-md-6"></div>

                <div class="col-md-4">
                    <label>{{ 'LiberationHierarchyComponent.Sector' | translate }}</label>
                    <div class="form-group">
                        <p-multiSelect id="sector" class="multitooltip"
                            [filter]="true" (onChange)="tooltipHideSector()"
                            [ngClass]="{'ng-invalid ng-dirty': sector.invalid && sector.touched}"
                            formControlName="sector"
                            optionLabel="label"
                            placeholder="{{'LiberationHierarchyComponent.SectorPlaceHolderMulti'| translate}}"
                            [options]="objectSectors"
                            defaultLabel="{{'LiberationHierarchyComponent.SectorPlaceHolderMulti'| translate}}"
                            selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                            [style]="{ width: '100%' }"
                            display="chip"
                            required>
                        </p-multiSelect>
                        <small id="sectorCodeValidator"
                            *ngIf="formLiberationHerarchy.controls['sector'].invalid 
                            && (formLiberationHerarchy.controls['sector'].dirty ||
                            formLiberationHerarchy.controls['sector'].touched)"
                            class="p-error">{{ 'LiberationHierarchyComponent.SectorMsgValidate' | translate }}
                        </small>
                        <small class="Tooltip" *ngIf="sectorToolTip.length > 0">
                          <ul>
                            <li *ngFor="let index of sectorToolTip">{{index}}</li>
                          </ul>
                        </small>
                    </div>
                </div>

                <div class="col-md-4">
                <label>{{ 'LiberationHierarchyComponent.Channel' | translate }}</label>
                <div class="form-group">
                    <p-multiSelect id="channel" class="multitooltip"
                                     [filter]="true" (onChange)="tooltipHideChannel()"
                                     [ngClass]="{'ng-invalid ng-dirty': channel.invalid && channel.touched}"
                                     formControlName="channel"
                                     optionLabel="label"
                                     placeholder="{{'LiberationHierarchyComponent.ChannelPlaceHolderMulti'| translate}}"
                                     [options]="objectChannels"
                                     defaultLabel="{{'LiberationHierarchyComponent.ChannelPlaceHolderMulti'| translate}}"
                                     selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                     [style]="{ width: '100%' }"
                                     display="chip"
                                     required>
                      </p-multiSelect>
                    <small id="channelCodeValidator"
                            *ngIf="formLiberationHerarchy.controls['channel'].invalid 
                            && (formLiberationHerarchy.controls['channel'].dirty ||
                            formLiberationHerarchy.controls['channel'].touched)"
                            class="p-error">{{ 'LiberationHierarchyComponent.ChannelMsgValidate' | translate }}</small>

                            <small class="Tooltip" *ngIf="channelToolTip.length > 0">
                              <ul>
                                <li *ngFor="let index of channelToolTip">{{index}}</li>
                              </ul>
                            </small>
                  </div>
          </div>
          <div class="col-md-4">
            <label>{{ 'LiberationHierarchyComponent.Type' | translate }}</label>
            <div class="form-group">
                <p-multiSelect id="type" class="multitooltip"
                                 [filter]="true" (onChange)="tooltipHideType()"
                                 [ngClass]="{'ng-invalid ng-dirty': type.invalid && type.touched}"
                                 formControlName="type"
                                 optionLabel="label"
                                 placeholder="{{'LiberationHierarchyComponent.TypePlaceHolderMulti'| translate}}"
                                 [options]="objectType"
                                 (click)="forkJoinData()"
                                 defaultLabel="{{'LiberationHierarchyComponent.TypePlaceHolderMulti'| translate}}"
                                 selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                 [style]="{ width: '100%' }"
                                 display="chip"
                                 required>
                  </p-multiSelect>
                <small id="typeCodeValidator"
                        *ngIf="formLiberationHerarchy.controls['type'].invalid 
                        && (formLiberationHerarchy.controls['type'].dirty ||
                        formLiberationHerarchy.controls['type'].touched)"
                        class="p-error">{{ 'LiberationHierarchyComponent.TypeMsgValidate' | translate }}</small>

                      <small class="Tooltip" *ngIf="typeToolTip.length > 0">
                        <ul>
                          <li *ngFor="let index of typeToolTip">{{index}}</li>
                        </ul>
                      </small>
              </div>
      </div>

          <div class="col-md-4">
            <label>{{ 'LiberationHierarchyComponent.Cedi' | translate }}</label>
            <div class="form-group">
                <p-multiSelect id="cedi"class="multitooltip"
                                [filter]="true" (onChange)="tooltipHideCedi()"
                                [ngClass]="{'ng-invalid ng-dirty': cedi.invalid && cedi.touched}"
                                formControlName="cedi"
                                optionLabel="label"
                                placeholder="{{'LiberationHierarchyComponent.CediPlaceHolderMulti'| translate}}"
                                [options]="objectCedis"
                                defaultLabel="{{'LiberationHierarchyComponent.CediPlaceHolderMulti'| translate}}"
                                selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
                                [style]="{ width: '100%' }"
                                display="chip"
                                required>
                          </p-multiSelect>
                        <small id="cediCodeValidator"
                                *ngIf="formLiberationHerarchy.controls['cedi'].invalid 
                                && (formLiberationHerarchy.controls['cedi'].dirty ||
                                formLiberationHerarchy.controls['cedi'].touched)"
                                class="p-error">{{ 'LiberationHierarchyComponent.CediMsgValidate' | translate }}</small>

                                <small class="Tooltip" *ngIf="cediToolTip.length > 0">
                                  <ul>
                                    <li *ngFor="let index of cediToolTip">{{index}}</li>
                                  </ul>
                                </small>
                      </div>
      </div>


      <div class="col-md-4">
        <div class="form-group">
          <div class="row justify-content-around" style="width: 90%;">
            <div>
              <p-radioButton formControlName="isSelect" 
                (onClick)="requiredField(false)" 
                value="1" 
                label="{{ 'LiberationHierarchyComponent.Macrosegment' | translate }}" >
              </p-radioButton>
            </div>
            <div>
              <p-radioButton formControlName="isSelect" 
                (onClick)="requiredField(true)" 
                value="2" 
                label="{{ 'LiberationHierarchyComponent.Typology' | translate }}" >
              </p-radioButton>
            </div>
          </div>
          <!-- MacroSegmento -->
          <div class="mt-2"  *ngIf="isSelect.value == 1">
            <p-multiSelect id="macro" class="multitooltip"
            [filter]="isSelect.value == 1"
            (onChange)="tooltipHideMacro()"
            [ngClass]="{'ng-invalid ng-dirty': macrosegment.invalid && macrosegment.touched}"
            formControlName="macrosegment"
            optionLabel="label"
            placeholder="{{'LiberationHierarchyComponent.MacrosegmentPlaceHolderMulti'| translate}}"
            [options]="objectMacro"
            defaultLabel="{{'LiberationHierarchyComponent.MacrosegmentPlaceHolderMulti'| translate}}"
            selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
            [style]="{ width: '100%' }"
            [required]="macroRequired"
            display="chip">
            </p-multiSelect>
            <small id="macrosegmentCodeValidator"
              *ngIf="formLiberationHerarchy.controls['macrosegment'].invalid
              && (formLiberationHerarchy.controls['macrosegment'].dirty ||
              formLiberationHerarchy.controls['macrosegment'].touched)"
              class="p-error">{{ 'LiberationHierarchyComponent.MacrosegmentMsgValidate' | translate }}</small>
          
              <small class="Tooltip" *ngIf="macroToolTip.length > 0">
                <ul>
                  <li *ngFor="let index of macroToolTip">{{index}}</li>
                </ul>
              </small>
          
            </div>
          <!-- Tipologia -->
          <div class="mt-2" *ngIf="isSelect.value == 2">
            <p-multiSelect id="tipology" class="multitooltip"
            [filter]="isSelect.value == 2" (onChange)="tooltipHideTipology()"
            [ngClass]="{'ng-invalid ng-dirty': tipology.invalid && tipology.touched}"
            formControlName="tipology"
            optionLabel="label"
            placeholder="{{'LiberationHierarchyComponent.TypologyPlaceHolderMulti'| translate}}"
            [options]="objectipology"
            defaultLabel="{{'LiberationHierarchyComponent.TypologyPlaceHolderMulti'| translate}}"
            selectedItemsLabel="{0} {{ 'ItemsSelected' | translate }}"
            [style]="{ width: '100%' }"
            [required]="tipologyRequired"
            display="chip">
            </p-multiSelect>
            <small id="tipologyCodeValidator"
              *ngIf="formLiberationHerarchy.controls['tipology'].invalid
              && (formLiberationHerarchy.controls['tipology'].dirty ||
              formLiberationHerarchy.controls['tipology'].touched)"
              class="p-error">{{ 'LiberationHierarchyComponent.TypologyMsgValidate' | translate }}</small>

              <small class="Tooltip" *ngIf="tipologyToolTip.length > 0">
                <ul>
                  <li *ngFor="let index of tipologyToolTip">{{index}}</li>
                </ul>
              </small>
          </div>
          </div>
        </div>

        <div class="col-md-4 mt-4">
          <button
            id="btnCreate"
            pButton
            pRipple
            type="button"
            label="{{ 'LiberationHierarchyComponent.CreateBtn' | translate }}"
            class="p-button-rounded pstn_primario w-100"
            (click)="addHierarchy()"
            *ngIf="this.isCreate && (rolePermission.create | rolePermission)"></button>

            <button
            id="btnUpdate"
            pButton
            pRipple
            type="button"
            label="{{ 'UpdateBtn' | translate }}"
            class="p-button-rounded pstn_primario w-100"
            (click)="updateHierarchy()"
            *ngIf="!this.isCreate && (rolePermission.update | rolePermission)"></button>
        </div>
        
        </div>
        </form>
        <div class="pstn_container container-fluid mt-2">
        <p-table #dt
            name="gridLiberationHierarchy"
            [value]="objectLiberationHierarchy"
            dataKey="id"
            [paginator]="true"
            [rows]="10"
            [scrollable]="true"
            paginatorPosition="bottom"
            styleClass="p-datatable-customers"
            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
            [rowsPerPageOptions]="[10,25,50,100]"
            [globalFilterFields]="['hierarchyConfiguration.id','hierarchyConfiguration.name', 'sector', 'channel', 'cedi','macrosegment', 'type', 'hierarchyConfiguration.creationDateString']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                  <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search" aria-hidden="true"></i>
                    <input pInputText
                           type="text"
                           (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{ 'SearchBy' | translate }}" />
                  </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                  <th style="width:200px">{{ 'Actions' | translate }}</th>

                  <th style="width:80px">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'LiberationHierarchyComponent.Id' | translate }}
                    </div>
                  </th>

                  <th style="width:220px">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'LiberationHierarchyComponent.HierarchyName' | translate }}
                      </div>
                  </th>

                  <th style="width:200px">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'LiberationHierarchyComponent.Sector' | translate }}
                      </div>
                  </th>

                  <th style="width:200px">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'LiberationHierarchyComponent.Channel' | translate }}
                      </div>
                  </th>

                  <th style="width:200px">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      {{ 'LiberationHierarchyComponent.Cedi' | translate }}
                    </div>
                  </th>

                  <th style="width:200px">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'LiberationHierarchyComponent.Macrosegment' | translate }}/ {{'LiberationHierarchyComponent.Typology' | translate}}
                      </div>
                  </th>

                  <th style="width:200px">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'LiberationHierarchyComponent.Type' | translate }}
                      </div>
                  </th>

                  <th style="width:150px">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'LiberationHierarchyComponent.CreationDate' | translate }}
                      </div>
                  </th>
                </tr>
                
            </ng-template>
            <ng-template pTemplate="body" let-object let-hierarchyConfiguration>
                <tr>
                  <td style="width:200px">                       
                      <i class="uil uil-edit Editar" 
                        (click)="editHierarchy(object)"
                        pTooltip="{{'Edit' | translate}}"
                        *ngIf="(rolePermission.update | rolePermission)"
                        aria-hidden="true"></i>

                        <i class="uil uil-sitemap Editar Organization" 
                        (click)="editOrganizationChart(object)"
                        pTooltip="{{'OrganizationChart' | translate}}"
                        *ngIf="(rolePermission.read | rolePermission)"
                          aria-hidden="true"></i>  

                        <i class="uil uil-copy Editar copiar" 
                        pTooltip="{{'Copy' | translate}}"
                        (click)="copyHierarchy(object)"
                        *ngIf="(rolePermission.create | rolePermission)"
                        aria-hidden="true"></i>
                      
                        <i class="uil uil-trash-alt eliminar" 
                        pTooltip="{{'Delete' | translate}}"
                        (click)="deleteHierarchy(object.hierarchyConfiguration.id)"
                        *ngIf="(rolePermission.delete | rolePermission)"
                        aria-hidden="true"></i>

                  </td>
                    <td style="width:80px">{{object.hierarchyConfiguration.id}}</td>
                    <td style="width:220px">{{object.hierarchyConfiguration.name}}</td>
                    <td style="width:200px">{{object.sector}}</td>
                    <td style="width:200px">{{object.channel}}</td>
                    <td style="width:200px">{{object.cedi}}</td>
                    <td style="width:200px">{{object.macrosegment}}</td>
                    <td style="width:200px">
                      <div *ngFor="let item of object.type">
                        {{ 'Domain.Domain-'+item | translate}}
                      </div>
                      <!-- {{object.type}} -->
                    </td>
                    <td style="width:150px">{{object.hierarchyConfiguration.creationDateString }}</td>

                </tr>
            </ng-template>  
            <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5">{{ 'ElementsNotFound' | translate }}</td>
                </tr>
            </ng-template>
        </p-table>
        </div>
    </div>
  </section>
  <p-toast></p-toast>


  <p-dialog [(visible)]="isCopyHerarchy"
          header="{{ 'LiberationHierarchyComponent.TitleCopy' | translate }}"
          [modal]="true"
          styleClass="p-fluid"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '50vw'}">

  <ng-template pTemplate="content">
     <div class="col-md-auto">
      <h6>{{ 'LiberationHierarchyComponent.DescriptionCopy' | translate }}</h6>
    
        <div class="row mt-3">
          <div class="col-md-12">
            <div>
              <label for="exampleFormControlInput1">
                {{ 'LiberationHierarchyComponent.NameHierarchy' | translate }}
              </label>
              <input id="nameHierarchy"
                     pInputText
                     [(ngModel)]="nameHierarchyCopy"
                     placeholder="{{ 'LiberationHierarchyComponent.Description' | translate }}"
                     (blur)="validityNameCopy()"
                     (paste)="onPaste($event, false)"
                     onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) 
                      || (event.charCode >= 97 && event.charCode <= 122) 
                      || event.charCode == 209 || event.charCode == 241
                      || event.charCode == 32)"
                     required/>
                     <small id="nameHierarchyCodeValidator"
                     *ngIf="isName == true"
                     class="p-error">{{ 'LiberationHierarchyComponent.NameHierarchyMsgValidate' | translate }}
                 </small>
                     
            </div>
          </div>

            <div class="col-md-6">
                <label>{{ 'LiberationHierarchyComponent.Sector' | translate }}</label>
                <div>
                    <p-multiSelect  [(ngModel)]="sectorCopy" [filter]="true" display="chip"
                        optionLabel="label" appendTo="body" class="multitooltip"
                        placeholder="{{'LiberationHierarchyComponent.SectorPlaceHolderMulti'| translate}}"
                        [options]="objectSectors" [style]="{ width: '100%' }"
                        (onClick)="validitySectorCopy()"
                        (onChange)="validitySectorCopy()"
                        required>
                    </p-multiSelect>
                    <small id="sectorCodeValidator"
                    *ngIf="isSector"
                    class="p-error">{{ 'LiberationHierarchyComponent.SectorMsgValidate' | translate }}
                </small>

                <small class="Tooltip" *ngIf="sectorCopyToolTip.length > 0">
                  <ul>
                    <li *ngFor="let index of sectorCopyToolTip">{{index}}</li>
                  </ul>
                </small>
                </div>
            </div>

            <div class="col-md-6">
            <label>{{ 'LiberationHierarchyComponent.Channel' | translate }}</label>
            <div>
                <p-multiSelect [(ngModel)]="channelCopy" [filter]="true" display="chip"
                    appendTo="body" optionLabel="label" class="multitooltip"
                    placeholder="{{'LiberationHierarchyComponent.ChannelPlaceHolderMulti'| translate}}"
                    [options]="objectChannels" [style]="{ width: '100%' }"
                    (onClick)="validityChannelCopy()"
                    (onChange)="validityChannelCopy()"
                    required>
                  </p-multiSelect>

                  <small id="channelCodeValidator"
                    *ngIf="isChannel"
                      class="p-error">{{ 'LiberationHierarchyComponent.ChannelMsgValidate' | translate }}</small>
                    
                      <small class="Tooltip" *ngIf="channelCopyToolTip.length > 0">
                        <ul>
                          <li *ngFor="let index of channelCopyToolTip">{{index}}</li>
                        </ul>
                      </small>
              </div>
      </div>
      <div class="col-md-6">
        <label>{{ 'LiberationHierarchyComponent.Type' | translate }}</label>
        <div>
            <p-multiSelect [(ngModel)]="typeCopy" [filter]="true" display="chip"
                appendTo="body" optionLabel="label" optionValue="value" class="multitooltip"
                placeholder="{{'LiberationHierarchyComponent.TypePlaceHolderMulti'| translate}}"
                [options]="objectTypeCopy"  [style]="{ width: '100%' }"
                (onClick)="validityTypeCopy()"
                (onChange)="validityTypeCopy()"
                required>
              </p-multiSelect>

              <small id="typeCodeValidator"
              *ngIf="isType"
                        class="p-error">{{ 'LiberationHierarchyComponent.TypeMsgValidate' | translate }}</small>
                  <small class="Tooltip" *ngIf="typeCopyToolTip.length > 0">
                    <ul>
                      <li *ngFor="let index of typeCopyToolTip">{{index}}</li>
                    </ul>
                  </small>
          </div>
      </div>

      <div class="col-md-6">
        <label>{{ 'LiberationHierarchyComponent.Cedi' | translate }}</label>
        <div>
            <p-multiSelect [(ngModel)]="cediCopy" [filter]="true" display="chip"
                appendTo="body" optionLabel="label" class="multitooltip"
                placeholder="{{'LiberationHierarchyComponent.CediPlaceHolderMulti'| translate}}"
                [options]="objectCedis" [style]="{ width: '100%' }"
                (onClick)="validityCediCopy()"
                (onChange)="validityCediCopy()"
                required>
             </p-multiSelect>
             <small id="cediCodeValidator"
             *ngIf="isCedi"       
                  class="p-error">{{ 'LiberationHierarchyComponent.CediMsgValidate' | translate }}</small>
              
              <small class="Tooltip" *ngIf="cediCopyToolTip.length > 0">
                <ul>
                  <li *ngFor="let index of cediCopyToolTip">{{index}}</li>
                </ul>
              </small>
                  </div>
  </div>

  <div class="col-md-8 mt-4">
    <div>
      <div class="row justify-content-around" style="width: 90%;">
        <div>
          <!-- <p-radioButton formControlName="isSelect" value="1" label="{{ 'LiberationHierarchyComponent.Macrosegment' | translate }}" ></p-radioButton> -->
          <p-radioButton name="typeFilter"
									value="1" label="{{ 'LiberationHierarchyComponent.Macrosegment' | translate }}"
									[(ngModel)]="isSelectCopy" (ngModelChange)="selectCombo()"></p-radioButton>
        </div>
        <div>
          <!-- <p-radioButton formControlName="isSelect" value="2" label="{{ 'LiberationHierarchyComponent.Typology' | translate }}" ></p-radioButton> -->
          <p-radioButton name="typeFilter"
          value="2" label="{{ 'LiberationHierarchyComponent.Typology' | translate }}"
          [(ngModel)]="isSelectCopy" (ngModelChange)="selectCombo()"></p-radioButton>
        </div>
      </div>
      <!-- MacroSegmento -->
      <div class="mt-2"  *ngIf="isSelectCopy == 1">
        <p-multiSelect [(ngModel)]="macroCopy" [filter]="true" display="chip"
        appendTo="body" optionLabel="label" class="multitooltip"
        placeholder="{{'LiberationHierarchyComponent.MacrosegmentPlaceHolderMulti'| translate}}"
        [options]="objectMacro" [style]="{ width: '100%' }" [required]="auxMacro"
        (onClick)="validitySelectCopy()"
        (onChange)="validitySelectCopy()" >
        </p-multiSelect>
        <small id="macrosegmentCodeValidator"
              *ngIf="isMacro"
              class="p-error">{{ 'LiberationHierarchyComponent.MacrosegmentMsgValidate' | translate }}</small>
        
              <small class="Tooltip" *ngIf="macroCopyToolTip.length > 0">
                <ul>
                  <li *ngFor="let index of macroCopyToolTip">{{index}}</li>
                </ul>
              </small>

      </div>
      <!-- Tipologia -->
      <div class="mt-2" *ngIf="isSelectCopy == 2">
        <p-multiSelect [(ngModel)]="tipologyCopy" [filter]="true" display="chip"
        appendTo="body" optionLabel="label" class="multitooltip"
        placeholder="{{'LiberationHierarchyComponent.TypologyPlaceHolderMulti'| translate}}"
        [options]="objectipology" [style]="{ width: '100%' }" [required]="auxTypology"
        (onClick)="validitySelectCopy()"
        (onChange)="validitySelectCopy()" >
        </p-multiSelect>
        <small id="tipologyCodeValidator"
              *ngIf="isTipology"
              class="p-error">{{ 'LiberationHierarchyComponent.TypologyMsgValidate' | translate }}</small>
            
              <small class="Tooltip" *ngIf="tipologyCopyToolTip.length > 0">
                <ul>
                  <li *ngFor="let index of tipologyCopyToolTip">{{index}}</li>
                </ul>
              </small>
      </div>
      </div>
    </div>  
    <div class="col-md-4"></div>

    <div class="col-md-4"></div> 

    <div class="col-md-4 mt-4">
      <button
      id="btnCreate"
      pButton
      pRipple
      type="button"
      label="{{ 'AcceptBtn' | translate }}"
      class="p-button-rounded pstn_primario w-100"
      (click)="addHierarchyCopy()"
      *ngIf="this.isCreate"></button> 
    </div>

    <div class="col-md-4 mt-4">
      <button id="btnCancel"
      pButton
      pRipple
      type="button"
      label="{{ 'CancelBtn' | translate }}"
      class="pstn_primario"
      style="background-color: darkgrey; border-color: darkgrey;"
      (click)="cancelBtn()"></button>
    </div>

    </div>
    </div>
  </ng-template>
  <!-- <ng-template pTemplate="footer">
    

  </ng-template> -->

</p-dialog>