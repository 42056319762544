import { ISubGroupItem } from '../../interfaces/sub-group-item.interface';
import { ITableGroupItem } from '../../interfaces/table-group-item.interface';


export class DiscountValue implements ITableGroupItem {
    constructor(
        public id?: string,
        public descGroup?: string,
        public unitMeasure?: string,
        public groupByJson?: string,
        public contentGroup?: string,
        public subOptions?: ISubGroupItem[],
        public percentDiscountValue?: string,
        public check?: any
    ) { }
}
