<div class="row h-100 pstn_inicio position-relative">
  <div class="logohome"><img src="assets/images/logohome.png" alt="" /></div>
  <div class="content">
    <h1>{{ 'LogInComponent.Title' | translate }}</h1>
    <h3>{{ 'LogInComponent.SubTitle' | translate }}</h3>
    <h6>{{environmentName}} - {{ 'Version' | translate }}: {{version}}</h6>
    <button class="pstn_btn_primario  mt-3" (click)="login()">
      {{ 'LogInComponent.LogInBtn' | translate }}
    </button>
  </div>
</div>

<p-toast></p-toast>
