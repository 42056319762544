import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-exclusions',
  providers: [MessageService],
  templateUrl: './exclusions.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],

})
export class ExclusionsComponent implements OnInit {

  ngOnInit() {

  }

}
