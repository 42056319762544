import { Component, Input, OnInit } from '@angular/core';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommercialActivityApprovalSelectedService } from './commercial-activity-approval-selected.service';
import { CommercialActivityApprovalSelectedModel } from './models/commercial-activity-approval-selected.model';
import {
  CommercialActivityApprovalStatus,
  CommercialActivityStatus,
} from '@shared/enums/commercial-activity-status.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { Router } from '@angular/router';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { IUserCreation } from './interface/user-creation.interface';
import { CommercialActivityapprovalMessage } from './messages/commercial-activity-approval.message';
import { CommercialActivityCalculateSlugEnum, CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';
@Component({
  selector: 'app-commercial-activity-approval-selected',
  templateUrl: './commercial-activity-approval-selected.component.html',
  styleUrls: ['./commercial-activity-approval-selected.component.css'],
})
export class CommercialActivityApprovalSelectedComponent implements OnInit {
  @Input('idActivity') idActivity: number;
  @Input('userCreation') userCreation: IUserCreation;
  @Input('commercialActStatus') commercialActStatus: number;
  @Input('typeCommercialActivity') typeCommercialActivity : number;
  @Input('nConsideration') nConsideration = 0;

  private readonly commercialActivityApprovalSelectedService: CommercialActivityApprovalSelectedService;
  private readonly spinner: NgxSpinnerService;
  private readonly router: Router;

  public objectList: CommercialActivityApprovalSelectedModel[] = [];
  public selectObject: CommercialActivityApprovalSelectedModel;

  public approvalStatus: any;
  public domainStatusEnum: any;
  public showModalApproval = false;
  public showModalApprovalDetail = false;
  public showModalApprovalDetailText = false;
  public approvalRejectionDetail = '';
  public selectedStatus: number;
  public showStatus = false;
  public emailSession = '';
  public messageData = CommercialActivityapprovalMessage.LOAD_DATA;
  public loading = true;

  constructor(
    commercialActivityApprovalSelectedService: CommercialActivityApprovalSelectedService,
    spinner: NgxSpinnerService,
    router: Router
  ) {
    this.commercialActivityApprovalSelectedService = commercialActivityApprovalSelectedService;
    this.spinner = spinner;
    this.router = router;
  }

  ngOnInit(): void {
    this.emailSession = SessionStorageHelper.get(CommonConfiguration.Email);
    this.approvalStatus = this.getCommercialActivityApprovalStatus();
    this.domainStatusEnum = this.getCommercialActivityStatus();
    this.getAll();
  }

  getCommercialActivityApprovalStatus(): typeof CommercialActivityApprovalStatus {
    return CommercialActivityApprovalStatus;
  }

  getCommercialActivityStatus(): typeof CommercialActivityStatus {
    return CommercialActivityStatus;
  }

  getAll() {
    this.spinner.show();
    this.commercialActivityApprovalSelectedService
      .getCommercialActivityHierarchyApproval(this.idActivity, false)
      .subscribe(
        (response) => {
          this.loading = false;
          if (response.status) {
            this.objectList = response.data;
            this.validateUserStatus();
            this.messageData = '';
          }else{
            this.messageData = `🚨 ${response.message} 🚨`;
          }
          this.spinner.hide();
        },
        (error) => {
          this.loading = false;
          this.spinner.hide();
          this.messageData = `🚨 ${error.error.Message} 🚨`;
        }
      );
  }

  showApprovalModal(select: CommercialActivityApprovalSelectedModel): void {
    this.showModalApproval = true;
    this.showModalApprovalDetail = false;
    this.showModalApprovalDetailText = false;
    this.selectObject = select;
  }

  updateStatusApprovalSelected(status: number): void {
    this.selectedStatus = status;
    this.showModalApproval = false;
    this.showModalApprovalDetailText = false;
    this.showModalApprovalDetail = true;
  }

  deleteCalculations(){
    const slug = this.prepareSlugToCalculate(this.typeCommercialActivity);
    this.commercialActivityApprovalSelectedService
    .deleteMetricCalculationsReject(String(this.idActivity), slug)
    .subscribe(
      (response) => {
        if(response){
        }
      }
    );
  }

 prepareSlugToCalculate(commercialActivityType: number): string {
		switch (commercialActivityType) {
			case CommercialActivitySubprocess.Promotions:
				return CommercialActivityCalculateSlugEnum.Promotions;
			case CommercialActivitySubprocess.DiscountKind:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
			case CommercialActivitySubprocess.DiscountValue:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
			case CommercialActivitySubprocess.FinancialDiscount:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
			case CommercialActivitySubprocess.Events:
				return CommercialActivityCalculateSlugEnum.Loyalty;
			case CommercialActivitySubprocess.ZeroValueProduct:
				return CommercialActivityCalculateSlugEnum.Loyalty;
			case CommercialActivitySubprocess.Loyalty:
				return CommercialActivityCalculateSlugEnum.Loyalty;
			case CommercialActivitySubprocess.Redemption:
				return CommercialActivityCalculateSlugEnum.Rappel;
			case CommercialActivitySubprocess.PriceDiscount:
				return CommercialActivityCalculateSlugEnum.DiscountKind;
      case CommercialActivitySubprocess.Rappel:
        if (this.nConsideration === 104) {
          return CommercialActivityCalculateSlugEnum.Loyalty;
        }
        if (this.nConsideration === 102 || this.nConsideration === 103) {
          return CommercialActivityCalculateSlugEnum.DiscountKind;
        }
			default: break;
		}
		return;
	}

  updateStatusLiberation(): void {
    if (this.approvalRejectionDetail !== '') {
      this.spinner.show();
      const dataSend = { ...this.selectObject };
      dataSend.approvalDetail = this.approvalRejectionDetail;
      dataSend.statusDomainId = this.selectedStatus;
      if(this.selectedStatus === this.approvalStatus.Rejection){
        this.deleteCalculations();
      }
      this.commercialActivityApprovalSelectedService
        .putCommercialActivityApprovalSelected(dataSend)
        .subscribe(
          (response) => {
            if (response.status) {
              this.showModalApproval = false;
              this.showModalApprovalDetail = false;
              this.showModalApprovalDetailText = false;
              SweetAlertMessageHelpers.showMessage(TypeMessage.Update);
              this.router.navigate(['auth/manage-promotional-activities']);
              this.spinner.hide();
            } else {
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.error(
              SweetAlert.titleAlertError,
              `${error.message}`
            );
          }
        );
    }
  }

  showApprovalModalDetail(
    select: CommercialActivityApprovalSelectedModel
  ): void {
    this.showModalApproval = false;
    this.showModalApprovalDetail = false;
    this.showModalApprovalDetailText = true;
    this.selectObject = select;
  }

  validateUserStatus() {
    if (this.emailSession != null || this.emailSession !== '') {
      const reject = this.objectList.find(
        (x) => x.statusDomainId === this.approvalStatus.Rejection
      );
      if (!reject) {
        const max = Math.max(
          ...this.objectList
            .filter(
              (w) => w.statusDomainId === this.approvalStatus.WithoutChecking
            )
            .map((o) => o.hierarchyApprovalLevelId)
        );
        const dataList = [...this.objectList];
        const hierarchyAvailable = {
          ...dataList
            .sort((a, b) => (a.id < b.id ? 1 : -1))
            .find(
              (f) =>
                f.hierarchyApprovalLevelId === max &&
                f.statusDomainId !== this.approvalStatus.Ok
            ),
        };

        this.objectList.forEach((element) => {
          if (
            element.accessManagementId === this.emailSession &&
            element.id === hierarchyAvailable.id
          ) {
            element.showButton = true;
          } else {
            element.showButton = false;
          }
        });
      }
    }
  }
}