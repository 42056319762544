import { ComponentModule } from './../../@components/component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitudeComponent } from './solicitude/solicitude.component';
import { SharedModule } from '@app/@shared';
import { PrimeNgModule } from '@app/@shared/modules/prime-ng.module';
import { WithdrawalSolicitudeComponent } from './solicitude/withdrawal-solicitude/withdrawal-solicitude.component';
import { CreationSolicitudeComponent } from './solicitude/creation-solicitude/creation-solicitude.component';
import { PendingSolicitudeComponent } from './solicitude/pending-solicitude/pending-solicitude.component';
import { PendingWithdrawalComponent } from './solicitude/pending-solicitude/pending-withdrawal/pending-withdrawal.component';
import { PendingCreationComponent } from './solicitude/pending-solicitude/pending-creation/pending-creation.component';
import { ImpactAnalysisComponent } from './solicitude/pending-solicitude/pending-creation/impact-analysis/impact-analysis.component';
import { ImpactAnalysisCardComponent } from './solicitude/pending-solicitude/pending-creation/impact-analysis/components/impact-analysis-card/impact-analysis-card.component';
import { ImpactAnalysisChartsDirective } from './solicitude/directives/impact-analysis-charts/impact-analysis-charts.directive';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RouterModule } from '@angular/router';
import { DetailCreationComponent } from './solicitude/detail-solicitude/detail-creation/detail-creation.component';
import { DetailWithdrawalComponent } from './solicitude/detail-solicitude/detail-withdrawal/detail-withdrawal.component';
import { TextMaskModule } from 'angular2-text-mask';
import { RequestsWithNoveltyComponent } from './solicitude/requests-with-novelty/requests-with-novelty.component';
import { PricesRoutingModule } from './prices-routing.module';
import { RemoveDifferentialComponent } from './solicitude/remove-differential/remove-differential.component';
import { PendingDifferentialComponent } from './solicitude/pending-solicitude/pending-differential/pending-differential.component';
import { DetailDifferentialComponent } from './solicitude/detail-solicitude/detail-differential/detail-differential.component';
@NgModule({
  declarations: [
    SolicitudeComponent,
    CreationSolicitudeComponent,
    WithdrawalSolicitudeComponent,
    PendingSolicitudeComponent,
    PendingCreationComponent,
    PendingWithdrawalComponent,
    ImpactAnalysisComponent,
    ImpactAnalysisCardComponent,
    ImpactAnalysisChartsDirective,
    DetailCreationComponent,
    DetailWithdrawalComponent,
    RequestsWithNoveltyComponent,
    RemoveDifferentialComponent,
    PendingDifferentialComponent,
    DetailDifferentialComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
    PrimeNgModule,
    ComponentModule,
    CardModule,
    OverlayPanelModule,
    RouterModule,
    TextMaskModule,
    PricesRoutingModule
  ]
})
export class PricesModule { }
