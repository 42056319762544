import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MonitoringService } from '@core/monitoring.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly loadingService: NgxSpinnerService,
    private readonly monitorService: MonitoringService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error, request)));
  }

  // Customize the default error handler here if needed
  private errorHandler(error: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    this.loadingService.hide();
    this.monitorService.logException(error);
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', error);
    }
    return throwError(error);
  }
}
