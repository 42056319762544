import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { AdvancedClientFilterConfigurationUserService } from '../advanced-client-filter/services/advance-client-filter-configuration-user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { AccessManagementService } from '@app/@modules/administration/accessmanagement/access-management.service';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { IRoleMaster } from '@app/@shared/interfaces/role-master.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { ICustomerInformation } from '../advanced-client-filter/interfaces/customer-information-interface';
import { AdvancedClientFilterService } from '../advanced-client-filter/services/advanced-client-filter-service';
import { ISelectItemGroup } from '@app/@modules/administration/accessmanagement/interfaces/access-management-select-group.interface';
import { RegionService } from '@app/@modules/administration/region/region.service';
import { SocietyRegionModel } from '@app/@shared/model/society-region.model';
import { CoreMasterModel } from '@app/@shared/model/core-master.model';
import { IAdvancedClientFilter } from '../advanced-client-filter/interfaces/advanced-client-filter.interface';
import { AdvancedClientFilterModel } from '../advanced-client-filter/models/advanced-client-filter.model';
import { AccessManagementRequestModel } from '@app/@modules/administration/accessmanagement/models/access-management-request.model';
import { AdvancedUserFilterService } from './advanced-user-filter.service';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-advanced-user-filter',
  templateUrl: './advanced-user-filter.component.html',
  styleUrls: ['./../../@shared/scss/responsive.table.scss'],
  styles: [`
  @media screen and (max-width: 960px) {
      :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
          display: flex;
      }
  }
`],
  providers: [MessageService],
})

export class AdvancedUserFilterComponent implements OnInit {

  @Output('eventClickFilters') eventButtonFilter: EventEmitter<any> = new EventEmitter();
  @Output('eventClickClear') eventButtonClear: EventEmitter<any> = new EventEmitter();

  public formUserFilter: FormGroup;
  public objectPost : ICombo[] = [];
  public objectRole : ICombo[] = [];
  public objectCountry : ICombo[] = [];
  public objectSector : ICombo[] = [];
  public objectSociety : ISelectItemGroup[] = [];
  public objectRegion : ICombo[] = [];
  public objectCedi : ISelectItemGroup[] = [];
  public objectChannel : ICombo[] = [];
  public objectSalesForceType : ICombo[] = [];
  public objectSalesBoss : ISelectItemGroup[] = [];
  public objectSupervisor : ISelectItemGroup[] = [];
  public objectRepresentative : ISelectItemGroup[] = [];
  public objectMacrosegment : ICombo[] = [];
  public objectSubtypology : ICombo[] = [];
  public objectTypology : ICombo[] = [];
  public objectPurchaseMission : ICombo[] = [];
  public objectCustomerHierarchyI : ICombo[] = [];
  public objectCustomerHierarchyII : ISelectItemGroup[] = [];
  public rolePermission: any = RolePermissionEnum;

  public filterListSalesForce : any[] = [];
  public filterListSalesBoss : any[] = [];
  public filterListSupervisor : any[] = [];
  public filterListRepresentative : any[] = [];

  private readonly fb: FormBuilder;
  private readonly coreService: CoreMasterService;
  private readonly spinner: NgxSpinnerService;
  private readonly regionServive: RegionService;
  private readonly accessManagementService: AccessManagementService;
  private readonly advancedUserFilterService : AdvancedUserFilterService;
  private readonly advancedClientFilterServive: AdvancedClientFilterService;
  private readonly advancedClientFilterConfigurationUser: AdvancedClientFilterConfigurationUserService;

  constructor(
    fb: FormBuilder,
    spinner: NgxSpinnerService,
    coreService: CoreMasterService,
    regionServive: RegionService,
    accessManagementService: AccessManagementService,
    advancedUserFilterService : AdvancedUserFilterService,
    advancedClientFilterServive: AdvancedClientFilterService,
    advancedClientFilterConfigurationUser: AdvancedClientFilterConfigurationUserService
  ) {
    this.fb = fb;
    this.spinner = spinner;
    this.coreService = coreService;
    this.regionServive = regionServive;
    this.accessManagementService = accessManagementService;
    this.advancedUserFilterService = advancedUserFilterService;
    this.advancedClientFilterServive = advancedClientFilterServive;
    this.advancedClientFilterConfigurationUser = advancedClientFilterConfigurationUser;
  }

  async ngOnInit(){
    this.initForm();
    this.forkJoinData();
  }

  forkJoinData() : void{
    this.spinner.show();

    const cargoResponse = this.advancedUserFilterService.getCargoAll();
    const roleResponse = this.accessManagementService.getRoleAll();
    const countryResponse = this.coreService.getAll(TypeCoreMaster.Country);
    const sectorResponse = this.coreService.getAll(TypeCoreMaster.Sector);
    const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
    const salesForcesResponse = this.coreService.getAll(TypeCoreMaster.SalesForcesType);

    forkJoin([
      cargoResponse,
      roleResponse,
      countryResponse,
      sectorResponse,
      channelResponse,
      salesForcesResponse
    ]).subscribe(
      (result) => {
        const objCargoResponse: IResponseService<any> = result[0];
        const objRoleResponse: IResponseService<IRoleMaster[]> = result[1];
        const objCountryResponse: IResponseService<ICoreMaster[]> = result[2];
        const objSectorResponse: IResponseService<ICoreMaster[]> = result[3];
        const objChannelResponse: IResponseService<ICoreMaster[]> = result[4];
        const objSalesForcesResponse: IResponseService<ICoreMaster[]> = result[5];

        this.addObjectCombo(objCargoResponse.data, TypeCoreMaster.WorkPosition);
        this.addObjectCombo(objRoleResponse.data, TypeCoreMaster.Role);
        this.addObjectCombo(objCountryResponse.data, TypeCoreMaster.Country);
        this.addObjectCombo(objSectorResponse.data, TypeCoreMaster.Sector);
        this.addObjectCombo(objChannelResponse.data, TypeCoreMaster.Channel);
        this.addObjectCombo(objSalesForcesResponse.data, TypeCoreMaster.SalesForcesType);

        const macrosegmentResponse = this.coreService.getAll(TypeCoreMaster.Macrosegments);
        const subtypologyResponse = this.coreService.getAll(TypeCoreMaster.Subtypology);
        const typologyResponse = this.coreService.getAll(TypeCoreMaster.BusinessTypologies);
        const purchaseMissionsResponse = this.coreService.getAll(TypeCoreMaster.PurchaseMissions);
        const customerLevelIResponse = this.advancedClientFilterServive.getAllCustomerHierachyLvlOne();

        forkJoin([
          macrosegmentResponse,
          subtypologyResponse,
          typologyResponse,
          purchaseMissionsResponse,
          customerLevelIResponse
        ]).subscribe(
          (result) => {
            const objMacrosegmentResponse: IResponseService<ICoreMaster[]> = result[0];
            const objSubtypologyResponse: IResponseService<ICoreMaster[]> = result[1];
            const objTypologyResponse: IResponseService<ICoreMaster[]> = result[2];
            const objPurchaseMissionsResponse: IResponseService<ICoreMaster[]> = result[3];
            const objCustomerLevelIResponse: IResponseService<ICustomerInformation[]> = result[4];

            this.addObjectCombo(objMacrosegmentResponse.data, TypeCoreMaster.Macrosegments);
            this.addObjectCombo(objSubtypologyResponse.data, TypeCoreMaster.Subtypology);
            this.addObjectCombo(objTypologyResponse.data, TypeCoreMaster.Typology);
            this.addObjectCombo(objPurchaseMissionsResponse.data, TypeCoreMaster.PurchaseMissions);
            this.addObjectCombo(objCustomerLevelIResponse.data, TypeCoreMaster.CustomerLevelI);
            this.spinner.hide();
          })
      })
  }

  changeSelectCountry(){
    this.addSociety()
    this.addRegion();
    this.addCedi();
  }

  async changeSelectSociety(){
    await this.addRegion();
    await this.addCedi();
  }

  changeSelectRegion(){
    this.addCedi();
  }

  changeSelectHerarchyOne(){
    this.addHerarchyTwo();
  }

  changeSelectSalesForce(){
    this.spinner.show();
    this.addSalesBossToSupervisor(TypeCoreMaster.SalesBoss);
    this.addSalesBossToSupervisor(TypeCoreMaster.Supervisor);
    this.addSalesBossToSupervisor(TypeCoreMaster.Representative);
  }

  changeSelectSalesBoss(){
    this.spinner.show();
    this.addSalesBossToSupervisor(TypeCoreMaster.Supervisor);
    this.addSalesBossToSupervisor(TypeCoreMaster.Representative);
  }

  changeSelectSupervisor(){
    this.spinner.show();
    this.addSalesBossToSupervisor(TypeCoreMaster.Representative);
  }

  addSalesBossToSupervisor(type: string){
    this.advancedClientFilterServive.getBySalesForceAsync(this.saleForce.value)
      .subscribe((response) => {
        this.setValuesComboSalesForceToRepresentative(response.data, type);
      });
  }

  addSociety(){
    this.objectSociety = [];
    if(this.country.value.length > 0){
      this.spinner.show();
      this.coreService.getAll(TypeCoreMaster.Society).subscribe(
        (response) => {
          if(response.status){
            this.addObjectCombo(response.data, TypeCoreMaster.Society);
          }
        }
      );
    }else{
      this.objectSociety = [];
      this.objectRegion = [];
      this.objectCedi = [];
      this.society.setValue([]);
      this.region.setValue([]);
      this.cedi.setValue([]);
    }
  }

  async addRegion(){
    this.objectRegion = [];
    if(this.society.value.length > 0){
      this.spinner.show();
      const societies : string = this.society.value.map((x: any) => x).join(',');
      const response = await this.regionServive.getAllRegionBySociety(societies).toPromise();
      if(response.status){
        this.addObjectCombo(response.data, TypeCoreMaster.Region);
      }
    }else{
      this.objectCedi = [];
      this.objectRegion = [];
      this.region.setValue([]);
      this.cedi.setValue([]);
    }
  }

  async addCedi(){
    this.objectCedi = [];
    let modelCedis : SocietyRegionModel[] = [];
    let region : CoreMasterModel[] = [];
    if(this.society.value.length > 0 && this.region.value.length > 0){
      this.spinner.show();
      this.objectRegion.forEach((x) => {
        this.region.value.forEach((reg : any) => {
            if(reg === x.value){
              region.push({code : x.value, description: x.label});
            }

        });
        if(region.length > 0){
          this.objectSociety.forEach((soc) => {
            soc.items.forEach((i) => {
              modelCedis.push({societyCode: i.value, societyDescription: i.label, regions: region});
              region = [];
            });
          });

        }
      });

      const response = await this.coreService.getAllCedisBySocietyRegion(modelCedis).toPromise();
      if(response.status){
        this.addObjectCombo(response.data, TypeCoreMaster.Cedi);
      }
    }else{
      this.objectCedi = [];
      this.cedi.setValue([]);
    }
  }

  addHerarchyTwo(){
    this.objectCustomerHierarchyII = [];
    if(this.hierarchyCustomerI.value.length > 0){
      this.spinner.show();
      const codeHierarchyOne = this.hierarchyCustomerI.value.map((x: any) => x);
      this.advancedClientFilterServive.getAllCustomerHierachyLvlTwoAlternative(codeHierarchyOne).subscribe(
        (response) => {
          if(response.status){
            this.addObjectCombo(response.data, TypeCoreMaster.CustomerLevelII);

          }
        }
      );
    }else{
      this.objectCustomerHierarchyII = [];
      this.hierarchyCustomerII.setValue([]);
    }
  }

  validitySelectHerarchy(){
    let auxHerarchy = this.hierarchyCustomerII.value;
    let data: string[] = [];
    this.hierarchyCustomerII.setValue([]);
    this.objectCustomerHierarchyII.forEach((x) => {
      x.items.forEach((item) => {
        auxHerarchy.forEach((aux: any) => {
          if(aux === item.value){
            data.push(aux);
          }
        });
      });
    });
    this.hierarchyCustomerII.setValue(data);
  }

  validitySelectSociety(){
    let auxSociety = this.society.value;
    let data: string[] = [];
    this.society.setValue([]);
    this.objectSociety.forEach((x) => {
        auxSociety.forEach((aux: any) => {
          if(aux === x.value){
            data.push(aux);
          }
        });
    });
    if(data.length > 0){
      this.society.setValue(data);
    }else{
      this.society.setValue([]);
    }

  }

  validitySelectRegion(){
    let auxRegion = this.region.value;
    let data: string[] = [];
    this.region.setValue([]);
    this.objectRegion.forEach((x) => {
        auxRegion.forEach((aux: any) => {
          if(aux === x.value){
            data.push(aux);
          }
        });
    });
    if(data.length > 0){
      this.region.setValue(data);
    }else{
      this.region.setValue([]);
    }
  }

  validitySelectCedi(){
    let auxCedi = this.cedi.value;
    let data: string[] = [];
    this.cedi.setValue([]);
    this.objectCedi.forEach((x) => {
      x.items.forEach((item) => {
        auxCedi.forEach((aux: any) => {
          if(aux === item.value){
            data.push(aux);
          }
        });
      });
    });
    if(data.length > 0){
      this.cedi.setValue(data);
    }else{
      this.cedi.setValue([]);
    }
  }

  validitySelectSalesBoss(){
    let auxSalesBoss = this.salesBoss.value;
    let data: string[] = [];
    this.salesBoss.setValue([]);
    this.objectSalesBoss.forEach((x) => {
      x.items.forEach((item) => {
        auxSalesBoss.forEach((aux: any) => {
          if(aux === item.value){
            data.push(aux);
          }
        });
      });
    });
    if(data.length > 0){
      this.salesBoss.setValue(data);
    }else{
      this.salesBoss.setValue([]);
    }
  }

  validitySelectSupervisor(){
    let auxSupervisor = this.supervisor.value;
    let data: string[] = [];
    this.supervisor.setValue([]);
    this.objectSupervisor.forEach((x) => {
      x.items.forEach((item) => {
        auxSupervisor.forEach((aux: any) => {
          if(aux === item.value){
            data.push(aux);
          }
        });
      });
    });
    if(data.length > 0){
      this.supervisor.setValue(data);
    }else{
      this.supervisor.setValue([]);
    }
  }

  validitySelectRepresentative(){
    let auxRepresentative = this.representative.value;
    let data: string[] = [];
    this.representative.setValue([]);
    this.objectRepresentative.forEach((x) => {
      x.items.forEach((item) => {
        auxRepresentative.forEach((aux: any) => {
          if(aux === item.value){
            data.push(aux);
          }
        });
      });
    });
    if(data.length > 0){
      this.representative.setValue(data);
    }else{
      this.representative.setValue([]);
    }
  }

  addSalesBoss(){
    if(this.objectSalesForceType.length <= 0){
      return;
    }
    let itemsObject : ICombo[] = [];
    let items : string[] = [];
    this.objectSalesForceType.forEach((salesForce) => {
      this.filterListSalesBoss.forEach((x) => {
        if(salesForce.value === x.salesForceCode && !items.includes(String(x.value))){
          items.push(String(x.value));
          itemsObject.push({label: x.label ,value: x.value});
        }
      });
      items = [];
      if(itemsObject.length > 0){
        this.objectSalesBoss.push({label: salesForce.label, value: salesForce.value, items: itemsObject});
        itemsObject = [];
      }
    });
    this.validitySelectSalesBoss();
  }

  addSupervisor(){
    if(this.objectSalesBoss.length <= 0){
      return;
    }
    let itemsObject : ICombo[] = [];
    let items : string[] = [];
    this.objectSalesBoss.forEach((salesBoss) => {
      salesBoss.items.forEach((item) => {
        this.salesBoss.value.forEach((boss: any) => {
          if(boss === item.value && !this.objectSupervisor.find((x) => x.value === item.value)){

            this.filterListSupervisor.forEach((x) => {
              if(item.value === x.salesBoss && !items.includes(String(x.value))){
                items.push(String(x.value));
                itemsObject.push({label: x.label ,value: x.value});
              }
            });

            if(itemsObject.length > 0){
              this.objectSupervisor.push({label: item.label, value: item.value, items: itemsObject});
            }
            items = [];
            itemsObject = [];
          }
        });
      });
    });
    this.validitySelectSupervisor();
  }

  addRepresentative(){
    if(this.objectSupervisor.length <= 0){
      return;
    }
    let itemsObject : ICombo[] = [];
    let items : string[] = [];
    
    this.objectSupervisor.forEach((sup) => {
      sup.items.forEach((item) => {
        this.supervisor.value.forEach((data : any) => {
          if(data === item.value && !this.objectRepresentative.find((x) => x.value === item.value)){
            this.filterListRepresentative.forEach((x) => {
              if(item.value === x.salesSupervisor && !items.includes(String(x.value))){
                items.push(String(x.value));
                itemsObject.push({label: x.label ,value: x.value});
              }
            });
            if(itemsObject.length > 0){
              this.objectRepresentative.push({label: item.label, value: item.value, items: itemsObject});
            }
            itemsObject = [];
            items = [];
          }
        });
      });
    });
    this.validitySelectRepresentative();
  }

  addObjectCombo(object: any[], field: string){
    let itemsObject : ICombo[] = [];
    switch(field){

      case TypeCoreMaster.WorkPosition:
        this.objectPost = [];
        this.objectPost = object.map((item) => {
          return {
            label : item,
            value : item
          };
        });
      break;

      case TypeCoreMaster.Role:
        this.objectRole = [];
        this.objectRole = object.map((item) => {
          return {
            label : item.description,
            value : item.id
          };
        });
      break;

      case TypeCoreMaster.Country:
        this.objectCountry = [];
        this.objectCountry = object.map((item) => {
          return{
            label : item.description,
            value : item.code
          };
        });
      break;

      case TypeCoreMaster.Sector:
        this.objectSector = [];
        this.objectSector = object.map((item) => {
          return{
            label : item.description,
            value : item.code
          };
        });
      break;

      case TypeCoreMaster.Society:
        this.objectSociety = [];
        this.objectCountry.forEach((country) => {
          object.forEach((data) => {
            if(data.countyCode === country.value){
              itemsObject.push({label: data.description, value: data.code});
            }
          });
          this.objectSociety.push({label: country.label, value: country.value, items: itemsObject});
          itemsObject = [];
        });
        this.validitySelectSociety();
        this.spinner.hide();
      break;

      case TypeCoreMaster.Region:
        this.objectRegion = [];
        this.objectRegion = object.map((item) => {
          return{
            label : item.regionDescription,
            value : item.regionCode
          };
        });
        this.objectRegion = this.objectRegion.filter((item, i, arr) => arr.findIndex((t) => t.value === item.value) === i);
        this.validitySelectRegion();
        this.spinner.hide();
      break;

      case TypeCoreMaster.Cedi:
        this.objectCedi = [];
        this.objectRegion.forEach((region) => {
            object.forEach((data) => {
              data.listRegion.forEach((itemList: any) => {
                if(itemList.regionCode === region.value){
                  itemList.listCedis.forEach((cedis : any) => {
                  itemsObject.push({label: cedis.cediDescription, value: cedis.cediCode });
                  });
                }

                if(itemsObject.length > 0){
                  let duplicated = this.objectCedi.find((x) => x.value === itemList.regionCode);
                  if(!duplicated){
                    this.objectCedi.push({label: itemList.regionDescription, value: itemList.regionCode, items: itemsObject});
                    itemsObject = [];
                  }
                }
              });
            });
        });
        this.validitySelectCedi();
        this.spinner.hide();
      break;

      case TypeCoreMaster.Channel:
        this.objectChannel = [];
        this.objectChannel = object.map((item) => {
          return {
            label : item.description,
            value : item.code
          };
        });
      break;

      case TypeCoreMaster.SalesForcesType:
        this.objectSalesForceType = [];
        this.objectSalesForceType = object.map((item) => {
          return {
            label : item.description,
            value : item.code
          };
        });
      break;

      case TypeCoreMaster.Macrosegments:
        this.objectMacrosegment = [];
        this.objectMacrosegment = object.map((item) => {
          return {
            label : item.description,
            value : item.code
          };
        });
      break;

      case TypeCoreMaster.Subtypology:
        this.objectSubtypology = [];
        this.objectSubtypology = object.map((item) => {
          return {
            label : item.description,
            value : item.clientsGroup
          };
        });
      break;

      case TypeCoreMaster.Typology:
        this.objectTypology = [];
        this.objectTypology = object.map((item) => {
          return {
            label : item.description,
            value : item.code
          };
        });
      break;

      case TypeCoreMaster.PurchaseMissions:
        this.objectPurchaseMission = [];
        this.objectPurchaseMission = object.map((item) => {
          return {
            label : item.description,
            value : item.code
          };
        });
      break;

      case TypeCoreMaster.CustomerLevelI:
        this.objectCustomerHierarchyI = [];
        this.objectCustomerHierarchyI = object.map((item) => {
          return {
            label : item.customerName,
            value : item.clientCode
          };
        });
      break;

      case TypeCoreMaster.CustomerLevelII:
        if(object.length > 0){
          this.objectCustomerHierarchyI.forEach((hierarchy) => {
          object.forEach((herarchyItem) => {
            if(herarchyItem.customerCodeLevelOne === hierarchy.value){
              itemsObject.push({label: herarchyItem.customerName, value: herarchyItem.clientCode });
            }

            if(itemsObject.length > 0){
              this.objectCustomerHierarchyII.push({label: hierarchy.label, value: hierarchy.value, items: itemsObject});
              itemsObject = [];
            }
            });
          });
          this.validitySelectHerarchy();
        }else{
          this.objectCustomerHierarchyII = [];
        }

        this.spinner.hide();
      break;
      default: break;
    }
  }

  private setValuesComboSalesForceToRepresentative(data: IAdvancedClientFilter[], type: string){
    const listSalesBoss: any[] = [];
    const listSupervisor: any[] = [];
    const listRepresentative: any[] = [];

    data.map((x: AdvancedClientFilterModel)  => {
      const salesBoss = {'salesForceCode': x.salesForceCode,'label' : x.salesBossName, 'value' : x.salesBoss};
      const supervisor = {'salesBoss' : x.salesBoss, 'salesBossName' : x.salesBossName, 'label' : x.supervisorName, 'value' : x.salesSupervisor};
      const representative = {'salesSupervisor' : x.salesSupervisor, 'supervisorName' : x.supervisorName, 'label' : x.representativeName, 'value' : x.representativeCode};

      if(salesBoss.label != null) listSalesBoss.push(salesBoss);
      if(supervisor.label != null) listSupervisor.push(supervisor);
      if(representative.supervisorName && representative.label != null) listRepresentative.push(representative);
    });

    if(type === TypeCoreMaster.SalesBoss && this.saleForce.value){
      this.objectSalesBoss = [];
      this.filterListSalesBoss =  listSalesBoss.filter((x1, index, array) =>
        array.findIndex((z) => z.salesForceCode === x1.salesForceCode && z.label === x1.label && z.value === x1.value) === index);
      this.addSalesBoss();
    }

    if(type === TypeCoreMaster.Supervisor && this.salesBoss.value){
      this.objectSupervisor = [];
      this.filterListSupervisor = listSupervisor.filter((x1, index, array) =>
      array.findIndex((z) => z.salesBoss === x1.salesBoss && z.saleBossName === x1.saleBossName && z.label === x1.label && z.value === x1.value) === index);
      this.addSupervisor()
    }

    if(type === TypeCoreMaster.Representative && this.supervisor.value){
      this.objectRepresentative = [];
      this.filterListRepresentative = listRepresentative.filter((x1, index, array) =>
      array.findIndex((z) => z.salesSupervisor === x1.salesSupervisor && z.supervisorName === x1.supervisorName && z.label === x1.label && z.value === x1.value) === index);
      this.addRepresentative();
    }

    this.spinner.hide();
  }

  objectFilter(): AccessManagementRequestModel{
    let model: AccessManagementRequestModel =
    new AccessManagementRequestModel(
      this.user.value,
      this.name.value,
      this.lastName.value,
      this.email.value,
      0,
      0,
      '',
      '',
      this.role.value,
      this.country.value,
      this.society.value,
      this.sector.value,
      this.region.value,
      this.cedi.value,
      this.channel.value,
      this.saleForce.value,
      this.salesBoss.value,
      this.supervisor.value,
      this.representative.value,
      this.macrosegment.value,
      this.subtypology.value,
      this.purchaseMission.value,
      this.typology.value,
      this.hierarchyCustomerI.value,
      this.hierarchyCustomerII.value,
      0,
      this.cargo.value
    );
    return model;
  }

  // Botones
  eventClickFilters(){
    let model = this.objectFilter();
    this.advancedUserFilterService.getAllUserByFilter(model)
      .subscribe(
        (response) => {
          if(response.status){
            this.eventButtonFilter.emit(response.data);
          }else{
            this.eventButtonFilter.emit([]);
          }
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      });
  }

  eventClearFilters(){
    this.clearFilter();
    this.eventButtonClear.emit(true);
  }

  // Se limpian los filtros
  clearFilter(){
    this.user.setValue('');
    this.name.setValue('');
    this.lastName.setValue('');
    this.email.setValue('');
    this.role.setValue([]);
    this.country.setValue([]);
    this.society.setValue([]);
    this.sector.setValue([]);
    this.region.setValue([]);
    this.cedi.setValue([]);
    this.channel.setValue([]);
    this.saleForce.setValue([]);
    this.salesBoss.setValue([]);
    this.supervisor.setValue([]);
    this.representative.setValue([]);
    this.macrosegment.setValue([]);
    this.subtypology.setValue([]);
    this.purchaseMission.setValue([]);
    this.typology.setValue([]);
    this.hierarchyCustomerI.setValue([]);
    this.hierarchyCustomerII.setValue([]);
    this.cargo.setValue([]);
    this.cleanDependenciesInpunt();
  }

  cleanDependenciesInpunt(){
    this.objectCustomerHierarchyII = [];
    this.objectRepresentative = [];
    this.objectSupervisor = [];
    this.objectSalesBoss = [];
    this.objectSociety = [];
    this.objectRegion = [];
    this.objectCedi = [];
  }

  initForm(): void{
    this.formUserFilter = this.fb.group({
      user: [{value: '', disabled: false}],
      email: [{ value: '', disabled: false }],
      name: [{ value: '', disabled: false }],
      lastName: [{ value: '', disabled: false }],
      cargo: [{ value: [], disabled: false }],
      role: [{ value: [], disabled: false }],
      country: [{ value: [], disabled: false }],
      sector: [{ value: [], disabled: false }],
      society: [{ value: [], disabled: false }],
      region: [{ value: [], disabled: false }],
      cedi: [{ value: [], disabled: false }],
      channel: [{ value: [], disabled: false }],
      saleForce: [{ value: [], disabled: false }],
      salesBoss: [{ value: [], disabled: false }],
      supervisor: [{ value: [], disabled: false }],
      representative: [{ value: [], disabled: false }],
      macrosegment: [{ value: [], disabled: false }],
      subtypology: [{ value: [], disabled: false }],
      typology: [{ value: [], disabled: false }],
      purchaseMission: [{ value: [], disabled: false }],
      hierarchyCustomerI: [{ value: [], disabled: false }],
      hierarchyCustomerII: [{ value: [], disabled: false }],
    });
  }

  get user(): AbstractControl { return this.formUserFilter.get('user'); }
  get email(): AbstractControl { return this.formUserFilter.get('email'); }
  get name(): AbstractControl { return this.formUserFilter.get('name'); }
  get lastName(): AbstractControl { return this.formUserFilter.get('lastName'); }
  get cargo(): AbstractControl { return this.formUserFilter.get('cargo'); }
  get role(): AbstractControl { return this.formUserFilter.get('role'); }
  get country(): AbstractControl { return this.formUserFilter.get('country'); }
  get sector(): AbstractControl { return this.formUserFilter.get('sector'); }
  get society(): AbstractControl { return this.formUserFilter.get('society'); }
  get region(): AbstractControl { return this.formUserFilter.get('region'); }
  get cedi(): AbstractControl { return this.formUserFilter.get('cedi'); }
  get channel(): AbstractControl { return this.formUserFilter.get('channel'); }
  get saleForce(): AbstractControl { return this.formUserFilter.get('saleForce'); }
  get salesBoss(): AbstractControl { return this.formUserFilter.get('salesBoss'); }
  get supervisor(): AbstractControl { return this.formUserFilter.get('supervisor'); }
  get representative(): AbstractControl { return this.formUserFilter.get('representative'); }
  get macrosegment(): AbstractControl { return this.formUserFilter.get('macrosegment'); }
  get subtypology(): AbstractControl { return this.formUserFilter.get('subtypology'); }
  get typology(): AbstractControl { return this.formUserFilter.get('typology'); }
  get purchaseMission(): AbstractControl { return this.formUserFilter.get('purchaseMission'); }
  get hierarchyCustomerI(): AbstractControl { return this.formUserFilter.get('hierarchyCustomerI'); }
  get hierarchyCustomerII(): AbstractControl { return this.formUserFilter.get('hierarchyCustomerII'); }
}
