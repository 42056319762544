import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListOperationByRoleModel } from '@app/@modules/administration/select-profile-module/models/list-operation-by-role.model';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class SelectedActivityModuleService{
    private readonly httpService: HttpService;
    constructor(
        httpService: HttpService
      ) { 
        this.httpService = httpService;
      }

      /**
      * @description This method is to get all the operations of user.
      * @author Jhon Steven Pavón Bedoya
      * @returns Observable<IResponseService<ListOperationByUserModel[]>>
      * @sprint 23
      */
      public getListOperationUser(moduleId: number): Observable<IResponseService<ListOperationByRoleModel[]>> {
        let response$: Observable<IResponseService<ListOperationByRoleModel[]>>;
        response$ = this.httpService
          .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/list-operation-user/${moduleId}`)
          .pipe(
            map((response: HttpResponse<IResponseService<ListOperationByRoleModel[]>>) => {
              return response.body;
            })
          );
        return response$;
      }
  }
