import { ISubGroupItem } from '../../interfaces/sub-group-item.interface';

export class SubDiscountPriceAdjustment implements ISubGroupItem {
    constructor(
        public descSku?: string,
        public id?: string,
        public sku?: string,
        public unitMeasure?: string,
        public percentDiscountPriceAdjustment?: string,
        public valueDiscountPriceAdjustment?: string,
        public check?: boolean
    ) { }
}
