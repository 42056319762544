<section>
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="pstn breadcrumb">
                <li class="breadcrumb-item">
                    <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    {{ 'ExclusionComponent.ExclusionManagement' | translate }}
                </li>
            </ol>
        </nav>
    </div>
</section>


<section class="pstngestion">
    <div class="container">
        <section class="generalData">

            <div class="row title">
                <div class="col-md-6">
                    <h1 class="pstntitle">
                        {{ 'ExclusionComponent.ExclusionManagement' | translate }}
                    </h1>
                </div>
            </div>

            <div class="options">
                <!-- button -->
                <div class="options--button col-md-4 mt-4 align-items-end">
                    <button id="btnCreate" pButton pRipple type="button"
                        label="{{ 'ExclusionComponent.CreateExclusions' | translate }}"
                        class="p-button-rounded pstn_primario w-100" (click)="createAction()"
                        *ngIf="(rolePermission.create | rolePermission)"></button>
                </div>
            </div>

        </section>
    </div>
</section>

<section class="pstngestion">
    <div class="container">
        <section id="TableWhitGroup">
            <div class="row mt-5">
                <div class="pstn_container container-fluid ">
                    <div class="container-table body">

                        <div class="container-table options">
                            <span>
                                {{ 'ExclusionComponent.DeleteSelected' | translate }}
                                <i id="btnDeleteBatch" 
                                    class="uil uil-trash-alt eliminar"
                                    (click)="deleteAllObjectAction()" 
                                    aria-hidden="true"
                                    *ngIf="(rolePermission.delete | rolePermission)"></i>
                            </span>
                        </div>

                        <p-table #dt name="gridSolicitude" dataKey="descGroup" [paginator]="true" [rows]="10"
                            [rowsPerPageOptions]="[10,25,50]" paginatorPosition="bottom"
                            styleClass="p-datatable-customers" [value]="exclusionsList" [(selection)]="selectedRows">
                            <ng-template pTemplate="header">
                                <tr>
                                    <!-- <th style="width: 3rem"></th>                        
                                    <th role="columnheader" style="width: 3rem"></th> -->
                                    <th style="width: 3rem">
                                        <p-checkbox (click)="selectAll(e.checked)" binary="true" #e></p-checkbox>
                                    </th>
                                    <th>{{ 'ExclusionComponent.IdExclusion' | translate }}</th>
                                    <th>{{ 'ExclusionComponent.ReasonExclusion' | translate }}</th>
                                    <th>{{ 'ExclusionComponent.Actions' | translate }}</th>
                                    <!-- <th>{{ "Actions" | translate }}</th> -->
                                    <!-- <th role="columnheader" style="width: 4rem"></th>
                                    <th style="width: 4rem"></th> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-object>
                                <tr>
                                    <td style="width: 3rem">
                                        <p-checkbox (click)="selectRow(e.checked,object)" [(ngModel)]="object.check"
                                            binary="true" #e></p-checkbox>
                                    </td>
                                    <td>{{object.id}}</td>
                                    <td>{{object.exclusionReason}}</td>
                                    <td>
                                        <i id="btnDelete" 
                                            class="uil uil-trash-alt eliminar"
                                            (click)="deleteObjectAction(object)"
                                            pTooltip="{{'Delete' | translate}}"
                                            aria-hidden="true"
                                            *ngIf="(rolePermission.delete | rolePermission)">
                                        </i>
                                        <i id="btnEye" 
                                            class="uil uil-search-alt Editar"
                                            pTooltip="{{'Detail' | translate}}"
                                           (click)="viewDetailsObjectAction(object)"
                                            aria-hidden="true"
                                            *ngIf="(rolePermission.read | rolePermission)">
                                        </i>
                                        <i id="btnEdit" 
                                            class="uil uil-edit Editar" 
                                            (click)="editObjectAction(object)"
                                            pTooltip="{{'Edit' | translate}}"
                                            aria-hidden="true"
                                            *ngIf="(rolePermission.update | rolePermission)">
                                        </i>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>

                </div>
            </div>
        </section>
    </div>
</section>