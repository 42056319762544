import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwitchService {

  constructor() { }

  private dataSubject = new BehaviorSubject<boolean>(false); // Inicializar el Subject con un valor por defecto
  public data$: Observable<boolean> = this.dataSubject.asObservable();

  sendData(data: boolean) {
    this.dataSubject.next(data);
  }
}
