import { IRulesImpactedEdit } from '../interfaces/rules-impacted-edit.interfaces';


export class RulesImpactedEditModel implements IRulesImpactedEdit{

    constructor(
        public solicitudeId:  number,
        public priceAdjustment: string,
        public validityStart: Date,
        public validityEnd: Date,
        
    ){}

}