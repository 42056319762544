import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommercialActivityApprovalSelectedModel } from './models/commercial-activity-approval-selected.model';

@Injectable({
    providedIn: 'root'
  })

export class CommercialActivityApprovalSelectedService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService)
  {
    this.httpService = httpService;
  }

  getCommercialActivityHierarchyApproval(idActivity: number, history : boolean): Observable<IResponseService<CommercialActivityApprovalSelectedModel[]>>{
    const response$: Observable<IResponseService<CommercialActivityApprovalSelectedModel[]>> = this.httpService
    .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/hierarchy_approval/${idActivity}/${history}`)
    .pipe(
        map((response: HttpResponse<IResponseService<CommercialActivityApprovalSelectedModel[]>>) => {
        return response.body;
        })
    );
    return response$;
  }

  putCommercialActivityApprovalSelected(approvalSelected: CommercialActivityApprovalSelectedModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> = this.httpService
    .put(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity_approval`,
      approvalSelected)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  deleteMetricCalculationsReject(idActivity: string, slug: string) : Observable<any> {
    const response$: Observable<any> = this.httpService
    .delete(
      `${environment.apiAnalytics}/api/v1/ccvp_apwb_comm/commercialactivity/impact/${idActivity}/${slug}`)
    .pipe(
      map((response: HttpResponse<any>) => {
          return response.body;
      })
    );
    return response$;
  }
  
}