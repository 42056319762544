<section>
	<div class="container">
		<nav aria-label="breadcrumb">
			<ol class="pstn breadcrumb">
				<li class="breadcrumb-item">
					<a [routerLink]="'/auth'">
						<i class="uil uil-estate" aria-hidden="true"></i>{{ "Home" | translate }}
					</a>
				</li>
				<li class="breadcrumb-item">
					<a [routerLink]="'/auth/manage-promotional-activities'">
						{{ "ManagePromotionalActivitiesComponent.Title" | translate }}
					</a>
				</li>
				<li class="breadcrumb-item active" aria-current="page">
					{{ "ManagePromotionalActivitiesComponent.Create.Title" | translate }}
				</li>
			</ol>
		</nav>
	</div>
</section>

<section class="pstngestion">
	<div class="container" *ngIf="!nextAssignClients">

		<section id="InitFormPromotion">
			<div class="column">
				<div class="col-md-6">
					<h1 class="pstntitle">
						{{ "ManagePromotionalActivitiesComponent.Create.Title" | translate }}
					</h1>
				</div>
				<div class="col-md-6" *ngIf="isProcess == commercialActivityEnum.view || isProcess == commercialActivityEnum.update">
					<h6 class="pstntitle">{{'Comercialactivity' | translate}} : {{ commercialActivityId }}</h6>
				  </div>
			</div>

			<form id="formPromotionalActivity" [formGroup]="formPromotionalActivity">
				<!-- GENERAL DATA -->
				<div class="row ">

					<!-- description -->
					<div class="form-check col-md-4">
						<div class="form-group">
							<label>{{ "ManagePromotionalActivitiesComponent.Create.Description" | translate }}</label>
							<input #user id="description" formControlName="description" autocomplete="off"
								placeholder="{{'ManagePromotionalActivitiesComponent.Create.DescriptionPlaceHolderInput' | translate}}"
								class="form-control" pInputText required />
						</div>
					</div>

					<!-- type commercial activity -->
					<div class="form-check col-md-4">
						<div class="form-group">
							<label>{{ "ManagePromotionalActivitiesComponent.Create.TypeCommercialActivity" | translate }}</label>
							<input #user id="typeCommercialActivity" formControlName="typeCommercialActivity" autocomplete="off"
								class="form-control" pInputText required />
						</div>
					</div>

					<!-- Promotion Type -->
					<div class="form-check col-md-4">
						<div class="form-group">
							<label>{{ "ManagePromotionalActivitiesComponent.Create.PromotionType" | translate }}</label>
							<div class="form-group" *ngIf="isProcess === commercialActivityEnum.create">
								<p-dropdown id="promotionType" [filter]="true" [showClear]="true"
									[ngClass]="{'ng-invalid ng-dirty': promotionType.invalid && promotionType.touched}"
									formControlName="promotionType" optionLabel="label" optionValue="value"
									placeholder="{{'ManagePromotionalActivitiesComponent.Create.PromotionTypePlaceHolderCombo' | translate}}"
									[options]="objectPromotionTypeList" (click)="forkJoinInitialData()" [style]="{ width: '100%' }" (onChange)="onSelectTypePromotion()">
								</p-dropdown>
								<small id="promotionTypeValidator" *ngIf="
                    formPromotionalActivity.controls['promotionType'].invalid &&
                    (formPromotionalActivity.controls['promotionType'].dirty ||
                    formPromotionalActivity.controls['promotionType'].touched)"
									class="p-error">{{"ManagePromotionalActivitiesComponent.Create.PromotionTypeMsgValidate"|
									translate}}</small>
							</div>
							<div class="form-group"
								*ngIf="isProcess === commercialActivityEnum.update || isProcess === commercialActivityEnum.view">
								<input #user type="text" pInputText id="promotionType" formControlName="promotionType"
									autocomplete="off" class="form-control" required />
							</div>
						</div>
					</div>

					<!-- Channel -->
					<div class="form-check col-md-4">
						<div class="form-group">
							<label>{{ "ManagePromotionalActivitiesComponent.Create.Channel" | translate }}</label>
							<div class="form-group">
								<p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll multitooltip"
									[virtualScroll]="true" [options]="objectChannelList" formControlName="channel" [filter]="true"
									placeholder="{{'ManagePromotionalActivitiesComponent.Create.ChannelPlaceHolderCombo' | translate}}"
									optionLabel="label" optionValue="value" display="chip" [style]="{width: '100%'}"></p-multiSelect>
									<small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
										<ul>
											<li *ngFor="let index of channelTooltip">{{index.channelDescription}}</li>
										</ul>
									</small>
									<small id="channelValidator" *ngIf="
										formPromotionalActivity.controls['channel'].invalid &&
										(formPromotionalActivity.controls['channel'].dirty ||
										formPromotionalActivity.controls['channel'].touched)"
									class="p-error">{{"ManagePromotionalActivitiesComponent.Create.ChannelMsgValidate"|
									translate}}</small>
							</div>
						</div>
					</div>

					<!-- Sector -->
					<div class="form-check col-md-4" *ngIf="sectorCommercialActivityParam">
						<div class="form-group">
							<label>{{ "ManagePromotionalActivitiesComponent.Create.Sector" | translate }}</label>
							<div class="form-group">
								<p-multiSelect id="typeOfPriceDescription" class="multiselect-custom-virtual-scroll multitooltip"
									[virtualScroll]="true" [options]="objectSectorList" formControlName="sector" [filter]="true"
									placeholder="{{'ManagePromotionalActivitiesComponent.Create.SectorPlaceHolderCombo' | translate}}"
									optionLabel="label" optionValue="value" display="chip" [style]="{width: '100%'}"
									(onChange)="selectSectorValidate()"></p-multiSelect>
									<small class="Tooltip" *ngIf="isProcess === commercialActivityEnum.view">
										<ul>
										<li *ngFor="let index of sectorTooltip">{{index.sectorDescription}}</li>
										</ul>
										</small>
								<small id="sectorValidator" *ngIf="
                    formPromotionalActivity.controls['sector'].invalid &&
                    (formPromotionalActivity.controls['sector'].dirty ||
                    formPromotionalActivity.controls['sector'].touched)"
									class="p-error">{{"ManagePromotionalActivitiesComponent.Create.SectorMsgValidate"|
									translate}}</small>
							</div>
						</div>
					</div>

					<div class="form-check col-md-4 mt-3" style="display: flex; align-items: center;">						
						<app-switch style="display: contents;"
							[nameSwitch]="'HandlingProductFilterComponent.NotHandlers'"
							[isValueSwitch]="showManejantes"
							[isDisabledInput]="isProcess == commercialActivityEnum.view"
							(switchValue)="eventswitchValue($event)">
	
						</app-switch>
					</div>
				</div>
			</form>
		</section>

		<div class="row mt-5"></div>

		<section id="Products">
			<div class="row">
				<div class="col-md-12">
					<p-tabView (onChange)="productsPurchasePromotionChange($event)">
						<p-tabPanel header="{{ 'ManagePromotionalActivitiesComponent.Create.ProductsBasePurchase' | translate }}"
							[selected]="isPurchaseBase">

							<app-hierarchy-advanced-filters [isProcess]="isProcess"
																							[isMultiFilter]="isMultiFilter"
																							[requireSector]="true"
																							[sector]="sector.value"
																							[editFiltersObjectList]="editFiltersObjectList"
																							(eventClickFilters)="eventClickFilters($event)"></app-hierarchy-advanced-filters>

							<div class="row mt-5"></div>

							<h2 class="pstn_subtitle">{{ 'ManagePromotionalActivitiesComponent.Create.ProductsBasePurchase' |
								translate }}</h2>
							{{ 'ManagePromotionalActivitiesComponent.Create.RemoveSelected' | translate }}
							<button id="btnDelete" pTooltip="{{'Deletefilter' | translate}}" type="button" class="delete btn btn-secundary-delete"
								*ngIf="isProcess !== commercialActivityEnum.view" (click)="deleteSelectedPurchaseBase()">
								<i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
							</button>
							<p-table #dt [value]="productGroupFilterList" dataKey="groupName" styleClass="p-datatable-customers"
								[paginator]="true" [rows]="10" paginatorPosition="bottom" [(selection)]="selectProductHeadGroupList"
								currentPageReportTemplate="{{ 'CurrentPage' | translate }}" [rowsPerPageOptions]="[10,25,50,100]"
								[globalFilterFields]="['groupName']" >

								<ng-template pTemplate="header">
									<th style="width: 4rem;"></th>
									<th style="width: 4rem;">
										<!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
									</th>
									<th pSortableColumn="groupName">
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'ManagePromotionalActivitiesComponent.Create.GroupName' | translate }}<p-sortIcon
												field="groupName"></p-sortIcon>
										</div>
									</th>
									<th>
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'ManagePromotionalActivitiesComponent.Create.PurchaseUnits' | translate }}
										</div>
									</th>
									<!-- <th>
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'ManagePromotionalActivitiesComponent.Create.MeasureUnits' | translate }}
										</div>
									</th> -->
									<th>
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'Actions' | translate }}
										</div>
									</th>
								</ng-template>

								<ng-template pTemplate="body" let-object let-expanded="expanded">
									<tr [pSelectableRow]="object">
										<td style="width: 4rem;">
											<button type="button" pButton pRipple [pRowToggler]="object"
												class="p-button-text p-button-rounded p-button-plain"
												[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
											</button>
										</td>
										<td style="width: 4rem;">
											<p-tableCheckbox [value]="object" [disabled]="isProcess === commercialActivityEnum.view">
											</p-tableCheckbox >
										</td>
										<td>{{object.groupName}}</td>
										<td pEditableColumn style="width: 7rem;">
											<input id="{{ object.id }}"
											type="number"
											[(ngModel)]="object.purchaseUnit"
														[(ngModel)]="object.purchaseUnit"
											[(ngModel)]="object.purchaseUnit"
											[disabled]="isProcess === commercialActivityEnum.view"
											onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46)"
											class="form-control"/>
										</td>
										<!-- <td pEditableColumn>
											<p-dropdown id="{{ object.id }}" [filter]="true" [showClear]="true"
												[(ngModel)]="object.measurementUnitCode" optionLabel="label" optionValue="value"
												placeholder="{{'ManagePromotionalActivitiesComponent.Create.ChannelPlaceHolderCombo' | translate}}"
												[options]="objectMeasurementUnitList" [style]="{ width: '100%' }"
												(onChange)="selectMeasurementUnit(object)"
												[disabled]="isProcess === commercialActivityEnum.view">
											</p-dropdown>
										</td> -->
										<td>
											<i class="uil uil-trash-alt eliminar" pTooltip="{{'Delete' | translate}}" *ngIf="isProcess !== commercialActivityEnum.view && (rolePermission.delete | rolePermission)"
												(click)="selectProductHeadGroupList.push(object); deleteSelectOnly(object,1)"
												aria-hidden="true"></i>
										</td>
									</tr>
								</ng-template>

								<ng-template pTemplate="rowexpansion" let-object>
									<tr>
										<td colspan="6">
											<div class="">
												<p-table [value]="object.purchaseBaseDetails" [scrollable]="true" scrollHeight="400px"
													[(selection)]="selectProductList" dataKey="materialCode">

													<ng-template pTemplate="header">
									<tr>
										<th style="width: 1rem;">
											<p-checkbox (onChange)="selectProductAll($event, object)"></p-checkbox>
										</th>
										<th pSortableColumn="materialCode">
											<div class="p-d-flex p-jc-between p-ai-center">
												{{ 'ManagePromotionalActivitiesComponent.Create.SKUCode' | translate }}<p-sortIcon
													field="names"></p-sortIcon>
											</div>
										</th>
										<th pSortableColumn="product" style="width: 20rem;">
											<div class="p-d-flex p-jc-between p-ai-center">
												{{ 'ManagePromotionalActivitiesComponent.Create.Product' | translate }} <p-sortIcon
													field="user"></p-sortIcon>
											</div>
										</th>
											<th pSortableColumn="productType" style="width: 180px">
											<div class="p-d-flex p-jc-between p-ai-center">
											{{ "ManagePromotionalActivitiesComponent.Create.ProductType" | translate }}
											</div>
											</th>
										<th pSortableColumn="purchaseUnits">
											<div class="p-d-flex p-jc-between p-ai-center">
												{{ 'ManagePromotionalActivitiesComponent.Create.PurchaseUnits' | translate }}
											</div>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-purchaseBaseDetails let-options>
									<tr *ngIf="purchaseBaseDetails !== undefined">
										<td style="width: 1rem;">

											<p-checkbox (onChange)="selectProductRow(e.checked,options)"
												[(ngModel)]="options.check"
												binary="true" #e
												[disabled]="isProcess === commercialActivityEnum.view" binary="true" ></p-checkbox>
										</td>
										<td>{{ purchaseBaseDetails.materialCode }}</td>
										<td style="width: 20rem;">{{ purchaseBaseDetails.product }}</td>
                    					<td style="width: 180px"> {{ purchaseBaseDetails.zofeDescription }} </td>
										<td>
											<p-inputNumber
												#in
												id="input-{{ purchaseBaseDetails.id }}"
												name="input-{{ purchaseBaseDetails.id }}"
												class="form-controll"
												[(ngModel)]="purchaseBaseDetails.purchaseUnit"
												[min]="0"
												[max]="9999"
												[inputStyle]="{width: '8rem'}"
												[disabled]="isProcess === commercialActivityEnum.view">
											</p-inputNumber>
										</td>
									</tr>
								</ng-template>

							</p-table>
				</div>
				</td>
				</tr>
				</ng-template>

				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
					</tr>
				</ng-template>

							</p-table>

						</p-tabPanel>
						<p-tabPanel header="{{ 'ManagePromotionalActivitiesComponent.Create.PromotionProducts' | translate }}"
							[selected]="!isPurchaseBase">
							<app-hierarchy-advanced-filters [isProcess]="isProcess"
																							[isMultiFilter]="isMultiFilter"
																							[requireSector]="true"
																							[sector]="sector.value"
																							[editFiltersObjectList]="editFiltersPromotionObjectList"
																							(eventClickFilters)="eventClickFilters($event)"></app-hierarchy-advanced-filters>

							<div class="row mt-5"></div>

							<h2 class="pstn_subtitle">{{ 'ManagePromotionalActivitiesComponent.Create.PromotionProducts' | translate }}
							</h2>
							{{ 'ManagePromotionalActivitiesComponent.Create.RemoveSelected' | translate }}
							<button id="btnDelete" type="button" class="delete btn btn-secundary-delete" pTooltip="{{'Deletefilter' | translate}}"
								*ngIf="isProcess !== commercialActivityEnum.view" (click)="deleteSelectedPurchaseBase()">
								<i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
							</button>
							<p-table #dt [value]="productGroupFilterListPromotion" dataKey="groupName" styleClass="p-datatable-customers"
								[paginator]="true" [rows]="10" paginatorPosition="bottom"
								[(selection)]="selectProductHeadGroupListPromotion"
								currentPageReportTemplate="{{ 'CurrentPage' | translate }}" [rowsPerPageOptions]="[10,25,50,100]"
								[globalFilterFields]="['groupName']">

								<ng-template pTemplate="header">
									<th style="width: 4rem;"></th>
									<th style="width: 4rem;">
										<!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
									</th>
									<th pSortableColumn="groupName">
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'ManagePromotionalActivitiesComponent.Create.GroupName' | translate }}<p-sortIcon
												field="groupName"></p-sortIcon>
										</div>
									</th>
									<th>
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'ManagePromotionalActivitiesComponent.Create.PromotionUnit' | translate }}
										</div>
									</th>
									<!-- <th>
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'ManagePromotionalActivitiesComponent.Create.MeasureUnits' | translate }}
										</div>
									</th> -->
									<th>
										<div class="p-d-flex p-jc-between p-ai-center">
											{{ 'Actions' | translate }}
										</div>
									</th>
								</ng-template>

								<ng-template pTemplate="body" let-object let-expanded="expanded">
									<tr [pSelectableRow]="object">
										<td style="width: 4rem;">
											<button type="button" pButton pRipple [pRowToggler]="object"
												class="p-button-text p-button-rounded p-button-plain"
												[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
											</button>
										</td>
										<td style="width: 4rem;">
											<p-tableCheckbox [value]="object" [disabled]="isProcess === commercialActivityEnum.view">
											</p-tableCheckbox>
										</td>
										<td>{{object.groupName}}</td>
										<td pEditableColumn style="width: 7rem;">
											<input id="{{ object.id }}" type="number"
												[(ngModel)]="object.purchaseUnit"
												[disabled]="isProcess === commercialActivityEnum.view"
												onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46)"
												class="form-control" />
										</td>
										<!-- <td pEditableColumn>
											<p-dropdown id="{{ object.id }}" [filter]="true" [showClear]="true"
												[(ngModel)]="object.measurementUnitCode" optionLabel="label" optionValue="value"
												placeholder="{{'ManagePromotionalActivitiesComponent.Create.ChannelPlaceHolderCombo' | translate}}"
												[options]="objectMeasurementUnitList" [style]="{ width: '100%' }"
												(onChange)="selectMeasurementUnit(object)" [disabled]="isProcess === commercialActivityEnum.view">
											</p-dropdown>
										</td> -->
										<td>
											<i 
												class="uil uil-trash-alt eliminar" 
												*ngIf="isProcess !== commercialActivityEnum.view"
												pTooltip="{{'Delete' | translate}}" 
												(click)="selectProductHeadGroupListPromotion.push(object); deleteSelectOnly(object,2)"
												aria-hidden="true">
											</i>
										</td>
									</tr>
								</ng-template>

								<ng-template pTemplate="rowexpansion" let-object>
									<tr>
										<td colspan="6">
											<div class="">
												<p-table [value]="object.purchaseBasePromotionDetails" [scrollable]="true" scrollHeight="400px"
													[(selection)]="selectProductListPromotion" dataKey="materialCode">

													<ng-template pTemplate="header">
														<tr>
															<th style="width: 1rem;">
																<p-checkbox (onChange)="selectProductPromotionAll($event, object)"></p-checkbox>
															</th>
															<th pSortableColumn="materialCode">
																<div class="p-d-flex p-jc-between p-ai-center">
																	{{ 'ManagePromotionalActivitiesComponent.Create.SKUCode' | translate }}<p-sortIcon field="names">
																	</p-sortIcon>
																</div>
															</th>
															<th pSortableColumn="product" style="width: 20rem;">
																<div class="p-d-flex p-jc-between p-ai-center">
																	{{ 'ManagePromotionalActivitiesComponent.Create.Product' | translate }} <p-sortIcon field="user">
																	</p-sortIcon>
																</div>
															</th>
                              <th pSortableColumn="productType" style="width: 180px">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                {{ "ManagePromotionalActivitiesComponent.Create.ProductType" | translate }}
                                </div>
                              </th>
															<th pSortableColumn="promotionUnit">
																<div class="p-d-flex p-jc-between p-ai-center">
																	{{ 'ManagePromotionalActivitiesComponent.Create.PromotionUnit' | translate }}
																</div>
															</th>
														</tr>
													</ng-template>
													<ng-template pTemplate="body" let-purchaseBaseDetails let-options>
														<tr *ngIf="purchaseBaseDetails !== undefined">
															<td style="width: 1rem;">
																<p-checkbox (onChange)="selectProductPromotionRow(e.checked,options)"
																	[(ngModel)]="options.check"
																	binary="true" #e
																	[disabled]="isProcess === commercialActivityEnum.view" binary="true" ></p-checkbox>
															</td>
															<td>{{ purchaseBaseDetails.materialCode }}</td>
															<td style="width: 20rem;">{{ purchaseBaseDetails.product }}</td>
                              <td style="width: 180px"> {{ purchaseBaseDetails.zofeDescription }} </td>
															<td>
																<p-inputNumber
																	#in
																	id="input-{{ purchaseBaseDetails.id }}"
																	name="input-{{ purchaseBaseDetails.id }}"
																	class="form-controll"
																	[(ngModel)]="purchaseBaseDetails.purchaseUnit"
																	[min]="0"
																	[max]="9999"
																	[inputStyle]="{width: '8rem'}"
																	[disabled]="isProcess === commercialActivityEnum.view">
																</p-inputNumber>
															</td>
														</tr>
													</ng-template>

												</p-table>
											</div>
										</td>
									</tr>
								</ng-template>

								<ng-template pTemplate="emptymessage">
									<tr>
										<td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
									</tr>
								</ng-template>
						</p-table>
						</p-tabPanel>
					</p-tabView>
				</div>
			</div>
		</section>

		<div class="row mt-5"></div>
		
		<div class="col-md-12">
			<app-handling-product-filter
				#activityHandler
				[nameComponent]="'HandlingProductFilterComponent.noHandlingCustomerProducts'"
				[isProcess]="isProcess"
				[editFiltersObjectList]="editFiltersObjectHandlerList"
				[commercialActivityHandlerisGroup]="commercialActivityHandlerisGroup"
				[commercialActivityHandlerisClientWithoutSales]="commercialActivityHandlerisClientWithoutSales"
				[commercialActivityHandlerDetails]="commercialActivityHandlerDetails"
				[commercialActivityHandlerPeriod]="commercialActivityHandlerPeriod"
				[showManejantes]="showManejantes"
				[sector]="sector.value"
				[typeCoreMaster]="typeCoreMaster.Handler">

			</app-handling-product-filter>
		</div>

		<div class="row mt-5"></div>

		<div class="col-md-12" *ngIf="isCurrentMonth">
			<app-handling-product-filter
				#ActivityHandlerCurrentMonth
				[nameComponent]="'HandlingProductFilterComponent.ProductsCurrentMonth'"
				[isProcess]="isProcess"
				[editFiltersObjectList]="editFiltersObjectHandlerCurrentMonthList"
				[commercialActivityHandlerDetails]="commercialActivityHandlerCurrentMonthDetails"
				[showManejantes]="showManejantes"
				[sector]="sector.value"
				[isCurrentMonth]="isCurrentMonth"
				[typeCoreMaster]="typeCoreMaster.HandlerCurrentMonth">

			</app-handling-product-filter>
		</div>

    <!-- Calculate -->
		<div class="col-md-12"
			*ngIf="promotionActivityEdit &&
			promotionActivityEdit.statusDomainId !== commercialActivityStateEnum.Registered &&
			promotionActivityEdit.statusDomainId !== commercialActivityStateEnum.InValidation &&
			promotionActivityEdit.statusDomainId !== commercialActivityStateEnum.Invalid">
			<div *ngIf="promotionActivityEdit !== undefined && purchaseBaseToCalculate.length > 0">
				<app-commercial-activity-calculate
					[commercialACtivityId]="promotionActivityEdit.id"
					[statusDomainId]="promotionActivityEdit.statusDomainId"
					[commercialActivityType]="promotionActivityEdit.typeCommercialActivityDomainId"
					[filterClientJson]="filterClientJsonToCalculate"
					[purchaseBaseToCalculate]="purchaseBaseToCalculate"
					[purchasePromotionToCalculate]="purchasePromotionToCalculate"
					[deltaDays]="promotionActivityEdit.numberDays"
					[maxPromotion]="promotionActivityEdit.orderLimit"
					[maxPeriod]="promotionActivityEdit.periodLimit"
					[nClients]="promotionActivityEdit.totalClientsFilter"
					[isCalculate]="promotionActivityEdit.isCalculate"
					(eventSendToCalculate)="eventSendToCalculate($event)"></app-commercial-activity-calculate>
			</div>
		</div>

		<!-- Liberation -->
		<div *ngIf="promotionActivityEdit !== undefined &&
					promotionActivityEdit.statusDomainId !== commercialActivityStateEnum.Registered &&
					promotionActivityEdit.statusDomainId !== commercialActivityStateEnum.InValidation &&
					promotionActivityEdit.statusDomainId !== commercialActivityStateEnum.Invalid &&
					promotionActivityEdit.statusDomainId !== commercialActivityStateEnum.Validated &&
					isCalculate">
			<div class="row mt-5"></div>

			<div class="col-md-12">
				<div class="accordion" id="accordionCreatePromotion">
					<app-commercial-activity-approval-selected
						[idActivity]="commercialActivityId"
						[commercialActStatus]="promotionActivityEdit.statusDomainId"
						[typeCommercialActivity]="promotionActivityEdit.typeCommercialActivityDomainId"
						[userCreation]="userCreation">
					</app-commercial-activity-approval-selected>
				</div>
			</div>

			<div class="row mt-5"></div>
			<div class="col-md-12">
				<div class="accordion" id="accordionCreatePromotion">
					<app-commercial-activity-historical [idActivity]="commercialActivityId"
					[commercialActStatus]="promotionActivityEdit.statusDomainId"></app-commercial-activity-historical>
				</div>
			</div>

		</div>

		<div class="row mt-5"></div>

    	  <!-- CreateDocumentPromotion -->
	     <div class="col-md-12">
		    <div id="accordionCreateDocumentPromotion">
				<app-commercial-activity-document [title]="'ManagePromotionalActivitiesComponent.Create.DocumentCreate' | translate"
                                                                                #documentSupport
				                                                                [routeCancel]="routeCancelCreatePromotion"
																				[showFields]="showFieldsCreatePromotion"
																				[promotionEdit]="promotionEdit"
																				[isProcess]="isProcess"
																				[commercialActivityTypeId]="typeCommercialActivity.value"
																				[commercialActivityId]="commercialActivityId"
																				[observationEdit]="observationEdit"
																				(eventClickSaveBtn)="eventClickSaveBtn($event)"
																				(eventClickEditBtn)="eventClickEditBtn($event)"
																				(eventClickSendValidateBtn)="eventClickSendValidateBtn()"></app-commercial-activity-document>
  		     </div>
		   </div>
	
	       <div class="row mt-5"></div>

		    <!-- CreatePromotion -->
		    <div class="col-md-12">
			 <div class="accordion" id="accordionCreatePromotion">
				<app-commercial-activity-footer [title]="'ManagePromotionalActivitiesComponent.Create.PromotionCreate' | translate"
																				[routeCancel]="routeCancelCreatePromotion"
																				[showFields]="showFieldsCreatePromotion"
																				[showButtons]="showButtonsCreatePromotion"
																				[promotionEdit]="promotionEdit"
																				[isProcess]="isProcess"
																				[isNext]="true"
																				[commercialActStatVal]="commercialActStatVal"
																				(eventClickSaveBtn)="eventClickSaveBtn($event)"
																				(eventClickEditBtn)="eventClickEditBtn($event)"
																				(eventClickSendValidateBtn)="eventClickSendValidateBtn()"
																				(eventChangeData)="prepareFooterForEvent($event)"></app-commercial-activity-footer>
			</div>
		</div>

		<div class="row mt-5"></div>

    </div>

	<div class="container" *ngIf="nextAssignClients">
		<app-assing-clients 
			[isProcess]="isProcess" 
			[isHanlder]="showManejantes"
			[filtersEdit]="filterClientJson" 
			[validateFilter]="true"
			[clientsEdit]="commercialActivityCustomers"
			[commercialActivityId]="commercialActivityId"
			[commercialActivityHandler]="commercialActivityHandler2"
			[commercialActivityHandlerCurrentMonth]="commercialActivityHandlerCurrentMonth2" 
			[commercialActivityHandlerPeriod]="commercialActivityHandlerPeriod2"
			[showFields]="showFields"
			(eventClickBack)="eventClickBackClients()"
			(eventClickSendData)="eventClickSendDataClients($event)"
			(eventClickSendDataEdit)="eventClickSendDataEditClients($event)"></app-assing-clients>
	</div>

</section>


<div *ngIf="displayValidationsDetails">
	<app-commercial-activity-invalid
	  [isVisible]="displayValidationsDetails"
	  [invalidationDataDetail]="invalidationDataDetail"
	  [dateInterruptionActivity]="initialDate"
	  [isProcess]="isProcess"
	  [filterClientJson]="filterClientJson"
	  [activityId]="commercialActivityId"
	  (eventClickInvalidate)="registryInvalidateCommercialACtivity($event)"
	></app-commercial-activity-invalid>
</div>
<div *ngIf="promotionActivityEdit">
	<app-commercial-activity-planned
		[isVisible]="promotionActivityEdit.statusDomainId == commercialActivityStateEnum.Planned"
		[commercialActivityId]="commercialActivityId"
	></app-commercial-activity-planned>
</div>

<!--
<app-filter-bot-clients></app-filter-bot-clients>
-->
<p-toast></p-toast>
