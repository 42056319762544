import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISolicitudeJson } from '@app/@modules/prices/solicitude/interfaces/solicitude-json.interface';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { PaginationByIdModel } from '@app/@shared/model/pagination-by-id.model';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExclusionInformationModel } from './models/exclusionInformation.model';


@Injectable({
  providedIn: 'root',
})
export class ExclusionsService {
  private readonly httpService: HttpService;
  private readonly HttpClient: HttpClient;

  constructor(
    httpService: HttpService,
    HttpClient: HttpClient
  ) {
    this.httpService = httpService;
    this.HttpClient = HttpClient;
   }


  public getListExclusions(): Observable<IResponseService<ISolicitudeJson[]>> {
    const response$: Observable<IResponseService<ISolicitudeJson[]>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/1/1000`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ISolicitudeJson[]>>) => {
          return response.body;
        })
      );
    return response$;
  }


  public getExclusionWithDetailById(id: number): Observable<IResponseService<ISolicitudeJson>> {
    const response$: Observable<IResponseService<ISolicitudeJson>> =

     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<ISolicitudeJson>>) => {
          return response.body;
        })
      );
    return response$;
  }



  public deleteExclusionsById(id: string): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }


  public postExclusion(
    body: ExclusionInformationModel
  ): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
    this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }


  public putExclusion(
    body: ExclusionInformationModel
  ): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }


  public deleteExclusionSelected(body: Array<any>): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion/logic/all`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public getExclusionMaterial(
    paginationById: PaginationByIdModel
  ): Observable<IResponseService<any[]>> {
    const response$: Observable<IResponseService<any[]>> =
     this.httpService
      .patch(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list_exclusion_material`,
        paginationById
      )
      .pipe(
        map((response: HttpResponse<IResponseService<any[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

}
