import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { MaterialModel } from '@app/@shared/model/material.model';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

  export class RedemptionFilterService {
    private readonly httpService: HttpService;
    constructor(httpService: HttpService) {
      this.httpService = httpService;
     }

      /**
       *
       * @param filters
       * @returns
       */
      redeemableFiltersElements( materialType: string, materialGroup: string): Observable<IResponseService<MaterialModel[]>>{
        let response$: Observable<IResponseService<MaterialModel[]>>;
        response$ = this.httpService
        .get(
            `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/materials/get_all_reedemeable_async/${materialType}/${materialGroup}`)
        .pipe(
            map((response: HttpResponse<IResponseService<MaterialModel[]>>) => {
            return response.body;
            })
        );
        return response$;
      }

  }
