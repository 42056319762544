import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NegotiationCreateComponent } from './negotiation/create/negotiation-create.component';
import { NegotiationComponent } from './negotiation/negotiation.component';

const routes: Routes = [
  { path: 'negotiation', component: NegotiationComponent },
  { path: 'negotiation/create', component: NegotiationCreateComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NegotiationsRoutingModule { }
