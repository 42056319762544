import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypeCoreMaster } from '../enums/type-core-master.enums';
import { ICoreMaster } from '../interfaces/core-master.interface';
import { IResponseService } from '../interfaces/response-service.interface';
import { SocietyRegionCedisModel } from '../model/society-region-cedi.model';
import { SocietyRegionModel } from '../model/society-region.model';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})

export class CoreMasterService {
  private readonly httpService: HttpService;
    constructor(httpService: HttpService) {
      this.httpService = httpService;
     }

    /**
     * @description This method is to get all the Countries.
     * @author Priscy Antonio Reales
     * @param none
     * @returns Observable<IResponseService<ICoreMaster[]>>
     * @sprint 1
     */
     public getAll(nameMaster : TypeCoreMaster): Observable<IResponseService<ICoreMaster[]>> {
      const response$: Observable<IResponseService<ICoreMaster[]>> = this.httpService
        .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/${nameMaster}/1/100`)
        .pipe(
          map((response: HttpResponse<IResponseService<ICoreMaster[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

    /**
     * @description This method is to get all the Societies by Country code.
     * @author Priscy Antonio Reales
     * @param none
     * @returns Observable<IResponseService<ICoreMaster[]>>
     * @sprint 1
     */
     public getAllSocietiesByCountry(code : string): Observable<IResponseService<ICoreMaster[]>> {
        const response$: Observable<IResponseService<ICoreMaster[]>>  = this.httpService
          .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/societies/countries/${code}`)
          .pipe(
            map((response: HttpResponse<IResponseService<ICoreMaster[]>>) => {
              return response.body;
            })
          );
        return response$;
      }


    /**
     * @description This method is to get all the Societies by Countries code.
     * @author Daniel Londoño
     * @param none
     * @returns Observable<IResponseService<ICoreMaster[]>>
     * @sprint 1
     */
     public getAllSocietiesByCountries(countries : string[]): Observable<IResponseService<ICoreMaster[]>> {
      const response$: Observable<IResponseService<ICoreMaster[]>> = this.httpService
        .post(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/societies/countries/${countries}`)
        .pipe(
          map((response: HttpResponse<IResponseService<ICoreMaster[]>>) => {
            return response.body;
          })
        );
      return response$;
    }

      /**
     * @description This method is to get all the Societies by Country code.
     * @author Priscy Antonio Reales
     * @param none
     * @returns Observable<IResponseService<ICoreMaster[]>>
     * @sprint 1
     */
     public getAllCediBySociety(code : string): Observable<IResponseService<ICoreMaster[]>> {
        const response$: Observable<IResponseService<ICoreMaster[]>> = this.httpService
          .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/cedi/societies/${code}`)
          .pipe(
            map((response: HttpResponse<IResponseService<ICoreMaster[]>>) => {
              return response.body;
            })
          );
        return response$;
      }

      public getAllCedisBySocietyRegion(sociedtyRegion: SocietyRegionModel[]): Observable<IResponseService<SocietyRegionCedisModel[]>>
      {
        const response$: Observable<IResponseService<SocietyRegionCedisModel[]>> = this.httpService
        .patch(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/cedi/get-cedis-by-society-region/`,
                sociedtyRegion)
        .pipe(
          map((response: HttpResponse<IResponseService<SocietyRegionCedisModel[]>>) => {
            return response.body;
          })
        );

        return response$;
      }

  }
