// Components
import { TypeMessage } from './../../../../@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from './../../../../@shared/helpers/sweet-alert-message.helper';
import { ApprovalRankService } from './../../approval-ranks/approval-rank.service';
import { Util } from '@app/@shared/util';
import { Router } from '@angular/router';
import { SweetAlertResult } from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { ProcessHierarchyReleaseEnum } from '@app/@shared/enums/hierarchy-release.enum';

// Services
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CreateService } from '../create/create.service';
import { AssociateRankService } from './associate-rank.service';

// Models
import { PaginationApprovalRankLevelModel } from './models/pagination-approval-rank-level.model';
import { HierarchyReleaseModel } from '../create/models/hierarchy-release.model';
import { ApprovalRankManagementModel } from './models/approval-rank-management.model';
import { ApprovalRankLevelManagementModel } from './models/approval-rank-level-management.model';

@Component({
  selector: 'app-associate-rank',
  templateUrl: './associate-rank.component.html',
  styleUrls: ['./associate-rank.component.css'],
  styles: [`
    @media screen and (max-width: 960px) {
        :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
            display: flex;
        }
    }

  `],
providers: [MessageService]
})
export class AssociateRankComponent implements OnInit {
  private readonly associateRankService : AssociateRankService;
  private readonly approvalRankLevel: ApprovalRankService;
  private readonly createHierarchyService: CreateService;
  private readonly messageService: MessageService;
  private readonly spinner: NgxSpinnerService;
  private readonly router: Router;

  public processHierarchy = 0;
  public hierarchyReleaseModel: HierarchyReleaseModel;
  public listApprovalRank: ApprovalRankManagementModel[] = [];
  public listApprovalRankSelected: ApprovalRankManagementModel[] = [];
  public listApprovalRankLevel: ApprovalRankLevelManagementModel[] = [];
  public listApprovalRankLevelSelected: ApprovalRankLevelManagementModel[] = [];
  public processHierarchyEnum: any = ProcessHierarchyReleaseEnum;
  public hierarchyIdUpdate = 0;


  constructor(
    associateRankService : AssociateRankService,
    approvalRankLevel: ApprovalRankService,
    createHierarchyService: CreateService,
    messageService: MessageService,
    spinner: NgxSpinnerService,
    router: Router
  ) {
      this.associateRankService = associateRankService;
      this.approvalRankLevel = approvalRankLevel;
      this.createHierarchyService = createHierarchyService;
      this.messageService = messageService;
      this.spinner = spinner;
      this.router = router;
   }

  ngOnInit(): void {
    this.spinner.show();
    this.processHierarchy = this.createHierarchyService.getProcessHierarchyRealese();
    if(this.processHierarchy === undefined){
      this.redirectTo('auth/hierarchy-release/');
    }
    if(this.processHierarchy === ProcessHierarchyReleaseEnum.update)
      this.hierarchyIdUpdate = this.createHierarchyService.getHierarchyIdEdit();

    this.listApprovalRank = [];
    this.listApprovalRankSelected = [];
    this.listApprovalRankLevel = [];
    this.listApprovalRankLevelSelected = [];
    this.getInfoAssociateRank();
  }

  /**
  * @description This method get info the associate rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  getInfoAssociateRank(){
   this.hierarchyReleaseModel = this.createHierarchyService.getInfoHierarchyModel();

    const pagination = new PaginationApprovalRankLevelModel();
    const descriptionProfile = this.createHierarchyService.getProfileDescriptionEdit();
    const profile = `${this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel[0]?.associateUsers[0]?.profileId}-${descriptionProfile}`;

    pagination.profile = profile;
    pagination.countryCode = this.hierarchyReleaseModel.countryCode;
    pagination.societyCode = this.hierarchyReleaseModel.societyCode;
    pagination.channelCode = this.hierarchyReleaseModel.channelCode;
    pagination.regionCode = this.hierarchyReleaseModel.regionCode;
    pagination.cediCode = this.hierarchyReleaseModel.cediCode;
    pagination.processDomainId = this.hierarchyReleaseModel.processDomainId;
    pagination.subprocessDomainId = this.hierarchyReleaseModel.subprocessDomainId;
    pagination.orderBy = 'Id';
    pagination.ascendent = false;
    pagination.pageNumber = 1;
    pagination.resultsPerPage = 100;


    this.associateRankService.postHierarchyReleaseWithAssociate(pagination)
    .subscribe( (response) => {
      if (response.status) {
        this.spinner.hide();
        if(response.data.length > 0){
          this.listApprovalRank = response.data;
          this.setInfoApprovalRank(response.data);
        }else{
          Util.optionalMessage('No existen registros asociados para los parametros de busqueda.')
          .then((result: SweetAlertResult) => {
            this.createHierarchyService.setInfoHierarchyModel(this.hierarchyReleaseModel);
            this.createHierarchyService.setHierarchyIdEdit(this.hierarchyIdUpdate);
            this.createHierarchyService.setProcessHierarchyRealese(this.processHierarchy);
            this.redirectTo('auth/hierarchy-release/create');
          });
        }

      } else {
        SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
      }
    });
  }

  /**
  * @description This method set info approval rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  setInfoApprovalRank(data: ApprovalRankManagementModel[]): void {
    if(this.hierarchyReleaseModel !== undefined && this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel?.length > 0){
        data.forEach((v) => {
          this.setInfoApprovalRankI(v);
        });
    }
  }

  private setInfoApprovalRankI(v: ApprovalRankManagementModel) {
    if (v.approvalRankLevel !== undefined && v.approvalRankLevel.length > 0) {
      v.approvalRankLevel.forEach((u) => {
        const existElement = this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel.find((x) => x.ApprovalRankLevelId === u.approvalRankId);
        u.approvalRankLevelId = +v.approvalRankLevelId;
        u.selected = existElement !== undefined ? true : false;

        this.listApprovalRankLevel.push(u);

        if (existElement !== undefined) {
          this.listApprovalRankLevelSelected.push(u);
        }

      });
    }
  }

  /**
  * @description This method select rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  selectRank($event: any, selectRank: ApprovalRankLevelManagementModel){
    selectRank.selected = $event.checked;
    if($event.checked){
      this.listApprovalRankLevelSelected.push(selectRank);
    }else{
      const listSelectedTemp = this.listApprovalRankLevelSelected.filter((x) => x.approvalRankId !== selectRank.approvalRankId);
      this.listApprovalRankLevelSelected = listSelectedTemp;
    }
  }

  /**
  * @description This method validate infor the approval rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  validateInfo(): boolean{
    return (this.listApprovalRankLevelSelected.length === 1) ? true : false;
  }


  /**
  * @description This method set infor in services create hierarchy
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  setVariablesInServices(){
    this.createHierarchyService.setHierarchyIdEdit(this.hierarchyIdUpdate);
    this.createHierarchyService.setProcessHierarchyRealese(this.processHierarchy);
    this.createHierarchyService.setInfoHierarchyModel(this.hierarchyReleaseModel);
  }

  /**
  * @description This method set info approval rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  setValueApprovalRank(){

    const approvalRankLevelSelected = this.listApprovalRankLevelSelected.find((x) => x.selected === true);
    const profileDescription = this.listApprovalRank[0].approvalLevel;

    this.hierarchyReleaseModel.hierarchyReleaseAssociateCreateModel.forEach((v) => {
      if(v.description === profileDescription.split('-')[1]){

        v.description = profileDescription.split('-')[1];
        v.ApprovalRankLevelId = approvalRankLevelSelected.approvalRankLevelId;
        v.OperatorStart = approvalRankLevelSelected.operatorStart;
        v.RankStart = approvalRankLevelSelected.rankStart;
        v.OperatorEnd = approvalRankLevelSelected.operatorEnd;
        v.RankEnd = approvalRankLevelSelected.rankEnd;
        v.UnitMeasurementId = approvalRankLevelSelected.unitMeasurementDomainId;
        v.UnitMeasurementDescription = approvalRankLevelSelected.unitMeasurementDomainDescription;

      }
    });
  }

  /**
  * @description This method save info aprroval rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  save(){
    if(this.validateInfo()){
      this.setValueApprovalRank();
      this.setVariablesInServices();
      this.redirectTo('auth/hierarchy-release/create');
    }else{
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'No puede seleccionar mas de un rango de aprobación.'
        : 'You cannot select more than one approval range.');
    }
  }

  /**
  * @description This method update infor the associate rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  update(){
    if(this.validateInfo()){
      this.setValueApprovalRank();
      this.setVariablesInServices();
      this.redirectTo('auth/hierarchy-release/create');
    }else{
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? 'No puede seleccionar mas de un rango de aprobación.'
        : 'You cannot select more than one approval range.');
    }
  }

  /**
  * @description This method cancel operation associate rank.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  cancel(){
    this.setVariablesInServices();
    this.redirectTo('auth/hierarchy-release/create');
  }

  /**
  * @description This method redirect to url.
  * @author Jhon Steven Pavón Bedoya
  * @returns
  * @sprint 8
  */
  redirectTo(to: string): void{
    this.router.navigate([to]);
  }


}
