<section class="pstngestion">
  <div class="container">
    <div class="row" *ngIf="showFields.enabledSummary">
      <div class="col-md-6">
        <h1 class="pstntitle">{{ 'AssingClient.Title' | translate }}</h1>
      </div>
    </div>
    <p-accordion>
      <p-accordionTab header="{{ 'AssingClient.ClientFilter' | translate }}" [selected]="activeAccordionFilter"
        [disabled]="!activeAccordionFilter">
        <app-advanced-client-filter
          [rolePermissionBtn]="rolePermissionBtn" 
          [isProcess]="isProcess" 
          [filtersEdit]="filtersEdit" 
          [validateFilter]="validateFilter" 
          [enabledExecuteFilters]="showFields.enabledExecuteFilters" 
          [isCountryRequired]="isCountryRequired"
          [requiredFields]="requiredFields"
          [isCreate]="isCreate"
          [visibleHideFilterClient]="visibleHideFilterClient"
          [showFields]="showFields"
          [btnShowEdit]="btnShowEdit"
          [clearCsv]="clearCsv"
          (eventClickFiltersClient)="eventClickFiltersClient($event)"
          (eventClickDeleteFiltersClient)="eventClickDeleteFiltersClient()"
          
          >
        </app-advanced-client-filter>
      </p-accordionTab>
    </p-accordion>

    <div class="row mt-5" *ngIf="showFields.enabledSummary">
      <app-customer-summary 
                            [isProcess]="isProcess"
                            [summaryClients] = "summaryClients"
                            [totalClients]="totalClients"
                            [activeAccordionSummary] = "activeAccordionSummary"
                            [commercialActivityId]="commercialActivityId"
                            [isHanlder]="isHanlder"
                            [clientsFilter]="filtersEdit"
                            [downloadFileClients]="downloadFileClients"
                            [jsonFilterClient]="jsonFilterClient"
                            (evenBtnShowEdit)="evenBtnShowEdit($event)">

      </app-customer-summary>
    </div>
    <div class="row mt-5"></div>
    <div *ngIf="!isHanlder">
      <app-clients-csv  #fileUploadComponent 
                        [isProcess]="isProcess"
                        [activeAccordionUploadFile]="activeAccordionUploadFile"
                        [clientsEdit]="clientsEdit"
                        [fileUploadClear]="fileUploadClear"
                        [isCreate]="isCreate"
                        (activeAccordionFilter)="getActiveAccordionFilter($event)"
                        (listClientCsvData)="getListClientCsvData($event)"
                        (totalRecordsDownloadEmit)="getTotalRecordsDownload($event)"
                        (evenclearCsv)="evenclearCsv($event)"
                        >
      </app-clients-csv>
    </div>

  </div>
</section>

<section id="bottoms" *ngIf="showFields.enabledBtn">
  <div class="row mt-5">
    <div class=" container">
      <div class="row justify-content-end">
        <div class="col-lg-4 md-3 mt-3">
          <button type="button" class="p-button-rounded pstn_primario w-100" (click)="back()"
            style="background-color: darkgrey; border-color: darkgrey;">
            {{ 'AssingClient.Back' | translate }}
          </button>
        </div>

        <div class="col-lg-4 md-3 mt-3" *ngIf="isProcess === processEnum.create">
          <button pButton pRipple type="button" 
            class=" p-button-rounded pstn_primario w-100" 
            label="{{ 'AssingClient.Save' | translate }}"
            style="float: right;" (click)="sendData()"
            *ngIf="rolePermission.create | rolePermission">
          </button>
        </div>
        <div class="col-lg-4 md-3 mt-3" *ngIf="isProcess === processEnum.update">
          <button pButton pRipple type="button" 
            class=" p-button-rounded pstn_primario w-100" 
            label="{{ 'AssingClient.Update' | translate }}"
            style="float: right;" (click)="sendDataEdit()"
            *ngIf="rolePermission.update | rolePermission">
          </button>
        </div>

      </div>
      <div class="row mt-5"></div>
    </div>
  </div>
</section>

<p-toast></p-toast>
