 export class CommercialACtivityQueueValidationModel {

  constructor(
    public id: number,
    public status: number, //Estado en validacion
    public commentary: string, //Puede ir NULL, no es obligatorio
    public moduleId: number, //En front ya existe enum para esto, se debe mandar el de actividades comerciales
    public processId: number, //Id de la actividad comercial, igual que "id"
    public processType: string, //Promociones, Descuento ... .. ...
    public startDate: string
  ) {}

}