
import { IHierarchyRelease } from '../interfaces/hierarchy-release.interface';
import { HierarchyReleaseAssociateModel } from './hierarchy-release-associate.model';

export class HierarchyReleaseModel implements IHierarchyRelease{

  constructor(
    public id: number,
    public description: string,
    public countryCode: string,
    public societyCode: string,
    public channelCode: string,
    public regionCode: string,
    public cediCode: string,
    public macrosegmentCode: string,
    public businessTypologyCode: string,
    public processDomainId: number,
    public processDomainIdView: string,
    public subprocessDomainId: number,
    public hierarchyReleaseAssociateCreateModel: HierarchyReleaseAssociateModel[]
  ) {}

}
