<section>
  <div class="container">
      <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth'">
              <i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
            </li>
          <li class="breadcrumb-item">
            <a [routerLink]="'/auth/hierarchy-release'">
              {{ "HierarchyReleaseComponent.Title" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "HierarchyReleaseComponent.AssociateRank.Title" | translate }}
          </li>
      </ol>
      </nav>
  </div>
</section>


<section class="pstngestion">
  <div class="container">
    <div class="row">
      <h1 class="pstntitle">
        {{ "HierarchyReleaseComponent.AssociateRank.Title" | translate }}
      </h1>
    </div>

    <div class="row" *ngIf="listApprovalRankLevel != undefined && listApprovalRankLevel.length >= 1">
      <h1 class="pstnsubtitle">
        {{ listApprovalRank[0].approvalLevel.split('-')[1] }}
      </h1>
    </div>

    <div class="row mt-5">

      <div class="pstn_container container-fluid">

        <p-table  #dt
                  [value]="listApprovalRankLevel"
                  dataKey="id"
                  styleClass="p-datatable-customers"
                  [paginator]="true"
                  [rows]="10"
                  paginatorPosition="bottom"
                  currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
                  [rowsPerPageOptions]="[10,25,50,100]">

      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search" aria-hidden="true"></i>
            <input pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{ 'SearchBy' | translate }}" />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>
          <th pSortableColumn="description">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'HierarchyReleaseComponent.AssociateRank.NameRankApproval' | translate }}
              <p-sortIcon field="description"></p-sortIcon>
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'HierarchyReleaseComponent.InitialRange' | translate }}
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'HierarchyReleaseComponent.FinalRange' | translate }}
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'HierarchyReleaseComponent.UnitMeasurement' | translate }}
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body"
                   let-approvalRanklevel>
        <tr>
          <td>
            <p-checkbox id="check-approval-rank-level-{{approvalRanklevel.id}}"
                        inputId="check-approval-rank-level-selected"
                        value="approvalRanklevel.selected"
                        binary="true"
                        [(ngModel)]="approvalRanklevel.selected"
                        (onChange)="selectRank($event, approvalRanklevel)">
            </p-checkbox>
          </td>
          <td>{{approvalRanklevel.description}}</td>
          <td>{{approvalRanklevel.rankStart}} {{approvalRanklevel.operatorStart }}</td>
          <td>{{approvalRanklevel.rankEnd}} {{approvalRanklevel.operatorEnd}}</td>
          <td>{{approvalRanklevel.unitMeasurementDomainDescription}}</td>

        </tr>
      </ng-template>
    </p-table>


      </div>
    </div>


    <div class="row mt-5"></div>
    <div class="row">
      <div class="col-md-6">
        <button type="button"
              class="pstn_primario"
              style="background-color: darkgrey; border-color: darkgrey;"
              (click)="cancel()">
          {{'CancelBtn' | translate}}
        </button>
      </div>

      <div class="col-md-6" *ngIf="processHierarchy == processHierarchyEnum.create">
        <button type="button"
                class="pstn_primario"
                style="float: right;"
                (click)="save()">
          {{'SaveBtn' | translate}}
        </button>
      </div>

      <div class="col-md-6" *ngIf="processHierarchy == processHierarchyEnum.update">
        <button type="button"
                class="pstn_primario"
                style="float: right;"
                (click)="update()">
          {{'UpdateBtn' | translate}}
        </button>
      </div>
    </div>
    <div class="row mt-5"></div>


  </div>
</section>
