import { Injectable } from '@angular/core';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { IRulesImpacted } from './interfaces/rules-impacted.interfaces';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

  export class ImpactAnalysisServices{
    private readonly httpService: HttpService;
    
    constructor(httpService: HttpService) { 
        this.httpService = httpService;
    }

    public getListPrice(sku:string, id: number): Observable<IResponseService<IRulesImpacted[]>>{
         const response$: Observable<IResponseService<IRulesImpacted[]>> =
        this.httpService
        .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/associate_solicitude_by_material_code/${id}/${sku}/${true}`)
        .pipe(
            map((response: HttpResponse<IResponseService<IRulesImpacted[]>>) => {
            return response.body;
            })
        );
        return response$;
    }


    public getAdjustmentPrice(sku:string, id: number): Observable<IResponseService<IRulesImpacted[]>>{
      const response$: Observable<IResponseService<IRulesImpacted[]>> =
     this.httpService
     .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/solicitude/associate_solicitude_by_material_code/${id}/${sku}/${false}`)
     .pipe(
         map((response: HttpResponse<IResponseService<IRulesImpacted[]>>) => {
         return response.body;
         })
     );
     return response$;
 }
  }