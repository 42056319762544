import { IResponseService } from './../../../@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICombo } from '../../../@shared/interfaces/combo.interface';
import { IListModuleByUser } from './interfaces/list-module-by-user.interface';
import { ListOperationByRoleModel } from './models/list-operation-by-role.model';

@Injectable({
  providedIn: 'root'
})
export class SelectProfileModuleService {
  private readonly httpService: HttpService;
  constructor(
    httpService: HttpService
  ) { 
    this.httpService = httpService;
  }


  /**
  * @description This method is to get all the profiles of user.
  * @author Elkin Vasquez Isenia
  * @param userName
  * @returns Observable<IResponseService<ICombo>>
  * @sprint 2
  */
  public getListProfileByUser(userName: string): Observable<IResponseService<ICombo[]>> {
    const response$: Observable<IResponseService<ICombo[]>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/list_profile_user/${userName}`)
      .pipe(
        map((response: HttpResponse<IResponseService<ICombo[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to get all the modules of user.
  * @author Elkin Vasquez Isenia
  * @param userName
  * @param profilId
  * @returns Observable<IResponseService<IListModuleByUser>>
  * @sprint 2
  */
  public getListModuleByRole(rolId: string): Observable<IResponseService<IListModuleByUser[]>> {
    let response$: Observable<IResponseService<IListModuleByUser[]>>;
    response$ = this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/list_module_role/${rolId}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IListModuleByUser[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to get all the modules of user.
  * @author Jhon Steven Pavón Bedoya
  * @returns Observable<IResponseService<IListModuleByUser[]>>
  * @sprint 23
  */
  public getListModuleUser(): Observable<IResponseService<IListModuleByUser[]>> {
    let response$: Observable<IResponseService<IListModuleByUser[]>>;
    response$ = this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/list-module-user/`)
      .pipe(
        map((response: HttpResponse<IResponseService<IListModuleByUser[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to get all the operations of user.
  * @author Jhon Steven Pavón Bedoya
  * @returns Observable<IResponseService<ListOperationByUserModel[]>>
  * @sprint 23
  */
  public getListOperationUser(moduleId: number): Observable<IResponseService<ListOperationByRoleModel[]>> {
    let response$: Observable<IResponseService<ListOperationByRoleModel[]>>;
    response$ = this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/list-operation-user/${moduleId}`)
      .pipe(
        map((response: HttpResponse<IResponseService<ListOperationByRoleModel[]>>) => {
          return response.body;
        })
      );
    return response$;
  }
}