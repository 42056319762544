export class OperationRankModel{

    constructor(
        public id: number,
        public hierarchyConfigurationId: number,
        public hierarchyApprovalLevelId: number,
        public operatorStart: string,
        public rankStart: number,
        public operatorEnd: string,
        public rankEnd: number
    ) {}
  }