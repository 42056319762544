import { Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { IConfigurationUserAccessManagement } from '../interfaces/configuration-user-access-management.interface';
import { AdvancedClientFilterService } from './advanced-client-filter-service';

@Injectable({
  providedIn: 'root'
})
export class AdvancedClientFilterConfigurationUserService {

  public configurationUserAccessManagement : IConfigurationUserAccessManagement;
  public  route : string;
  private readonly router: Router;
  private readonly advancedClientFilterService: AdvancedClientFilterService;
  private readonly allRecord = "ALL";

  constructor(
    router: Router,
    advancedClientFilterService: AdvancedClientFilterService
  ){
    this.router = router;
    this.advancedClientFilterService = advancedClientFilterService;
  }

   async getConfigurationUserAcessManagement(accessManagementId: string){
    try{
      const response = await this.advancedClientFilterService.getConfigurationUserAcessManagement(accessManagementId).toPromise();
      if(response.status){
        this.configurationUserAccessManagement = response.data;
      }
    }catch (error){
      SweetAlertMessageHelpers.exception(error);
    }
  }

  setDataUser() : IConfigurationUserAccessManagement {
    return this.configurationUserAccessManagement;
  }

  setCountryByUser(countries: ICombo[]) : ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.country.map((x) => x);
    const countrySelected = countries.filter((z)=> idsByUser.includes(z.value));
    return countrySelected;
  }


  setSocietyByUser(society: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.society.map((x) => x);
    const societySelected = society.filter((z) => idsByUser.includes(z.value) || idsByUser.includes(this.allRecord));
    return societySelected;
  }

  setSectorByUser(sector: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.sector.map((x) => x);
    const sectorSelected = sector.filter((z) => idsByUser.includes(z.value) || idsByUser.includes(this.allRecord));
    return sectorSelected;
  }

  setRegionByUser(region: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.region.map((x) => x);
    const regionSelected = region.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return regionSelected;
  }

  setCediByUser(cedi: ICombo[]): ICombo[]{
    let Items : ICombo[] = [];
    let cediItems = cedi.forEach((x : any) => {x.items.forEach((y : any) => {
      let ComboItems = {'label' : y.label, 'value' : y.value};
      Items.push(ComboItems);
    })});

    const idsByUser = this.configurationUserAccessManagement.cedi.map((x) => x);
    const cediSelected = Items.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return cediSelected;
  }

  setChannelByUser(channel: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.channel.map((x) => x);
    const channelSelected = channel.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return channelSelected;
  }

  setSalesForceByUser(salesForce: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.salesForce.map((x) => x);
    const salesForceSelected = salesForce.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return salesForceSelected;
  }

  setSalesBossByUser(salesBoss: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.salesBoss.map((x) => x);
    const salesBossSelected = salesBoss.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return salesBossSelected;
  }

  setSupervisorByUser(supervisor: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.supervisor.map((x) => x);
    const supervisorSelected = supervisor.filter((z) => idsByUser.includes(z.value) || idsByUser.includes(this.allRecord));
    return supervisorSelected;
  }

  setRepresentativeByUser(representative: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.representative.map((x) => x);
    const representativeSelected = representative.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return representativeSelected;
  }

  setMacrosegmentoByUser(macrosegmento: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.macrosegmento.map((x) => x);
    const macrosegmentoSelected = macrosegmento.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return macrosegmentoSelected;
  }

  setSubTypologyByUser(subTypology: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.subTypology.map((x) => x);
    const subTypologySelected = subTypology.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return subTypologySelected;
  }

  setPurchaseMissionByUser(purchaseMission: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.purchaseMission.map((x) => x);
    const purchaseMissionSelected = purchaseMission.filter((z) => idsByUser.includes(z.value)  || idsByUser.includes(this.allRecord));
    return purchaseMissionSelected;
  }

  setTypologyByUser(typology: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.typology.map((x) => x);
    const typologySelected = typology.filter((z) => idsByUser.includes(z.value) || idsByUser.includes(this.allRecord));
    return typologySelected;
  }

  setHierarchyOneByUser(hierarchyOne: ICombo[]): ICombo[]{
    let idsByUser = this.configurationUserAccessManagement.hierarchyOne.map((x) => x);
    const hierarchyOneSelected = hierarchyOne.filter((z) => idsByUser.includes(z.value) || idsByUser.includes(this.allRecord));
    return hierarchyOneSelected;
  }

  setHierarchyTwoByUser(hierarchyTwo: ICombo[]): ICombo[]{
    const idsByUser = this.configurationUserAccessManagement.hierarchyTwo.map((x) => x);
    const hierarchyTwoSelected = hierarchyTwo.filter((z) => idsByUser.includes(z.value) || idsByUser.includes(this.allRecord));
    return hierarchyTwoSelected;
  }



}
