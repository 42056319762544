<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth/pending-solicitude/'">
            {{ "PendingSolicitudeComponent.Title" | translate }}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ 'PendingSolicitudeComponent.TitleAnalyze' | translate
          }}</li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h1 class="pstntitle">{{ 'PendingSolicitudeComponent.TitleAnalyze' | translate }}</h1>
      </div>
    </div>
    <div class="row mt-5">
      <div class="pstn_container container-fluid">
        <p-table #dt [value]="objectListSolicitude"
          dataKey="id"
          styleClass="p-datatable-customers"
          [paginator]="true"
          [rows]="10"
          [scrollable]="true"
          paginatorPosition="bottom"
          currentPageReportTemplate="{{ 'CurrentPage' | translate }}"
          [rowsPerPageOptions]="[10, 25, 50, 100]"
          scrollHeight="800px"
          [globalFilterFields]="['materialCode', 'product', 'currentListPrice', 'currentPsp', 'effectiveDateStart', 'effectiveDateEnd']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search" aria-hidden="true" ></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'SearchBy' | translate }}" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="materialCode">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.Code" | translate }}
                  <p-sortIcon field="materialCode"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="product">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.Product" | translate }}
                  <p-sortIcon field="product"></p-sortIcon>
                </div>
              </th>
              <th style="width:180px">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.ProductType" | translate }}
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{
                  "SolicitudComponent.Create.CurrentWithoutTax" | translate
                  }}
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.CurrentPSP" | translate }}
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.InitialDate" | translate }}
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "SolicitudComponent.Create.FinalDate" | translate }}
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-object let-editing="editing" let-ri="rowIndex">
            <tr>
              <td>{{materialCodeZeroLess(object.materialCode) }}</td>
              <td>{{ object.product }}</td>
              <td style="width:180px">
                {{ object.zofeDescription }}
              </td>
              <td>{{ object.currentListPrice | currency: "USD" }}</td>
              <td>{{ object.currentPsp | currency: "USD" }}</td>
              <td>{{ object.effectiveDateStart | date:'dd/MM/yyyy'}}</td>
              <td>{{ object.effectiveDateEnd | date:'dd/MM/yyyy'}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Buttons -->
        <div class="row col-12 ">
          <div class="col-lg-3">
            <button id="btnBack" pButton pRipple type="button"
              label="{{ 'PendingSolicitudeComponent.Back' | translate }}"
              class="pstn_reintetar w-100"
              (click)="back()"></button>
          </div>
            <div class="col-lg-3">
              <button class="pstn_reintetar  w-100" (click)="sendRetry()" *ngIf="retry > 0">
                <i class="uil uil-redo" aria-hidden="true"></i>{{ 'PendingSolicitudeComponent.Retry' | translate }}
              </button>
            </div>
            <div class="col-lg-3">
              <button class="pstn_rechazar  w-100" (click)="reject()"
              *ngIf="rolePermission.create | rolePermission">
                <i class="uil uil-times-circle" aria-hidden="true"></i>{{ 'PendingSolicitudeComponent.Reject' | translate }}
              </button>
            </div>
            <div class="col-lg-3">
              <button class="pstn_aceptar  w-100" 
              (click)="accept()"
              *ngIf="rolePermission.create | rolePermission">
                <i class="uil uil-check-circle" aria-hidden="true"></i>{{ 'PendingSolicitudeComponent.Approve' | translate }}
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<p-toast></p-toast>
