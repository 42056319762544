import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterDataModel } from '@app/@components/hierarchy-advanced-filters/models/filters-data.model';
import { ManageParametersService } from '@app/@modules/administration/manage-parameters/manage-parameters.service';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { ManagePromotionalActivitiesMessage } from '@app/@modules/promotionalactivity/manage-promotional-activities/messages/manage-promotional-activities-messages.message';
import { DomainTypeSecurity } from '@app/@shared/enums/domain-type-security.enums';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { FileBaseHelper } from '@app/@shared/helpers/file-base.helper';
import { IAdvancedFiltersMaterialsResult } from '@app/@shared/interfaces/advanced-filters-material-result.interface';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { DomainService } from '@app/@shared/services/domain.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { environment } from '@env/environment';
import { parse } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SweetAlertResult } from 'sweetalert2';
import { SweetAlertMessageHelpers } from '../../../../@shared/helpers/sweet-alert-message.helper';
import { IAdvancedFiltersMaterial } from '../../../../@shared/interfaces/advanced-filters-material.interface';
import { AdvancedFiltersMaterialModel } from '../../../../@shared/model/advanced-filters-material.model';
import { SolicitudeMessage } from '../messages/solicitude.message';
import { SolicitudeCreationModel } from '../models/solicitude-creation.model';
import { SolicitudeMaterialRequestModel } from '../models/solicitude-material-request.model';
import { SolicitudeMaterialModel } from '../models/solicitude-material.model';
import { SolicitudeModel } from '../models/solicitude.model';
import { SolicitudeService } from '../solicitude.service';
import { SolicitudeFileModel } from '../models/solicitude-file.model';
import { ISolicitudeCustomer } from '../interfaces/solicitude-customer.interface';
import { SolicitudeCustomerModel } from '../models/solicitude-customer.model';
import { AssingClientEnums } from '@app/@shared/enums/assing-client.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { PriceCertificateComponent } from '@app/@components/price-certificate/price-certificate.component';
import { CommonConfiguration } from '@app/@shared/enums/common.enums';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { PaginatorFilterService } from '@app/@shared/services/paginator-filter.service';
import { TypeOfLoadExcelEnum } from '@app/@components/upload-excel/enums/type-of-load-excel.enum';
import { HelperService } from '@app/@shared/services/helper.service';

@Component({
  selector: 'app-creation-solicitude',
  templateUrl: './creation-solicitude.component.html',
  styleUrls: ['./../../../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
          }
          :host ::ng-deep .p-fileupload .p-fileupload-row  {
            display:flex;
            flex-direction : row;
            justify-content: space-between;

          }
          :host ::ng-deep .p-fileupload .p-fileupload-row   {
            display:flex;
            flex-direction : column;
            justify-content: space-between;
          }

          :host ::ng-deep .p-button.p-button-icon-only {
            width: 2.357rem;
            padding: 0.5rem 0;
            position: absolute;
            right: 5%;
            top: 35%;
          }

          :host ::ng-deep .p-fileupload .p-fileupload-row > div {
            padding: 0;
            width:80%;
        }

    `],
  providers: [MessageService, DatePipe],
})
export class CreationSolicitudeComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('priceCertificate') priceCertificate: PriceCertificateComponent;

  private readonly spinner: NgxSpinnerService;
  private readonly fb: FormBuilder;
  private readonly messageService: MessageService;
  private readonly fileBaseHelper: FileBaseHelper;
  private readonly domainService: DomainService;
  readonly solicitudeService: SolicitudeService;
  private readonly router: Router;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly manageParametersService: ManageParametersService;
  private readonly miDatePipe: DatePipe;
  private readonly paginatorService: PaginatorFilterService;
  private readonly helperService: HelperService;

  public filterResult: IAdvancedFiltersMaterialsResult;
  public solicitudeType: SolicitudeType;
  public solicitudeStatus: SolicitudeStatus;
  public uploadedFiles: any[] = [];
  public attachedFilePdf: string = undefined;
  public filterTypeName = 'PriceListCreation';
  public typeFiltersObjectList: ICombo[];
  public selectSolicitude: SolicitudeCreationModel[];
  public solicitude: SolicitudeCreationModel[];
  public solicitudeTemp: SolicitudeCreationModel[] = [];
  public formSolicitude: FormGroup;
  public datepipe: DatePipe;
  public isEdit = true;
  public isVisibleAssingClient = false;
  public activeAccordion = true;
  public stop$ = new Subject<void>();
  public filterClientJson: string;
  public isProcess: number;
  public downloadFile: SolicitudeFileModel;
  public nameFile = '';
  public solicitudeCustomer: SolicitudeCustomerModel[] = [];
  public listSolicitudeCustomer: string[] = [];
  public today: Date = new Date();
  public tomorrow: Date = new Date();
  public filterClientJsonEdit: string;
  public assingClientEnums = AssingClientEnums;
  public auxListMax : string[] = [];
  public priceCertificateAux : any;
  private listPendingMaterialsByManage: string[] = [];
  public showFields : IFieldsClientFilter = new FieldsClientFilterModel(true, true, true, true, true, true); 
  public typeOfLoadFileExcel : any = TypeOfLoadExcelEnum;

  public rolePermission: any = RolePermissionEnum;

  readonly priceMask = createNumberMask({
      allowDecimal: true,
      decimalSymbol: '.',
      integerLimit: 12,
      prefix: '',
      thousandsSeparatorSymbol: ',',
      allowNegative  : true
    });
  
    codeCreationSession: string;

  constructor(
    spinner: NgxSpinnerService,
    fb: FormBuilder,
    messageService: MessageService,
    fileBaseHelper: FileBaseHelper,
    domainService: DomainService,
    solicitudeService: SolicitudeService,
    router: Router,
    managePromotionActService: ManagePromotionalActivitiesService,
    manageParametersService: ManageParametersService,
    miDatePipe: DatePipe,
    paginatorService: PaginatorFilterService,
    helperService: HelperService
  ) {
    this.spinner = spinner;
    this.fb = fb;
    this.messageService = messageService;
    this.fileBaseHelper = fileBaseHelper;
    this.domainService = domainService;
    this.solicitudeService = solicitudeService;
    this.router = router;
    this.managePromotionActService = managePromotionActService;
    this.manageParametersService = manageParametersService;
    this.miDatePipe = miDatePipe;
    this.paginatorService = paginatorService;
    this.helperService = helperService;
  }

  ngOnInit(): void {
    this.manageParametersService.setIntervalAutosave();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.solicitudeType = SolicitudeType.Creation;
    this.solicitudeStatus = SolicitudeStatus.Temporary;
    this.spinner.show();
    this.isProcess = this.solicitudeService.getIsCreate();
    if(this.isProcess === AssingClientEnums.create){
      this.codeCreationSession = sessionStorage.getItem(CommonConfiguration.SolicitudeCodeCreation);
    }
    this.getSolicitudeById();
    this.getTypeFiltersCreation();
    this.spinner.hide();
  }

  // setToday(){
  //   let date : Date = new Date.now;
  // }

  selectAllContent($event : any) {
    $event.target.select();
  }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  stopInterval(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  zeroforNull(){
    this.solicitude.forEach((x) => {
      if(x.newPriceWithOutTax == null){
        x.newPriceWithOutTax = 0;
      }
      if(x.priceAdjustment == null){
        x.priceAdjustment = 0;
      }
      if(x.percentage == null){
        x.percentage = 0;
      }
      if(x.effectiveDateEnd == null){
        x.effectiveDateEnd = new Date(2199,11,31);
      }
    });
  }

  /**
 * @description this method is to get Solicitude with SolicitudeMaterial By Id.
 * @author Daniel Londoño
 * @sprint 5
 */
  getSolicitudeById() {
    this.spinner.show('PleaseWait2');
    const id = this.solicitudeService.getSolicitudeId();
    if (!id) {
      this.isEdit = false;
      this.spinner.hide('PleaseWait2');
      return;
    }
    this.solicitudeService.getSolicitudeWithDetailById(id)
    .subscribe(
      (response) => {
        if (response.status) {
          this.activeAccordion = false;
          this.solicitudeStatus = response.data.status;
          this.solicitudeType = response.data.type;
          this.filterClientJson = response.data.filterClientJson;
          this.filterClientJsonEdit = this.filterClientJson;
          this.solicitudeCustomer = response.data.solicitudeCustomerJson;
          if (this.solicitudeCustomer) {
            this.listSolicitudeCustomer = [];
            this.solicitudeCustomer.forEach((customer) => {
              this.listSolicitudeCustomer.push(customer.clientCode);
            });
          }
          this.codeCreationSession = response.data.codeCreation;

          this.solicitude = [];
          if (response.data.solicitudeDetailJson) {
            this.solicitude = response.data.solicitudeDetailJson.map((item) => {
              return {
                id: item.detailId,
                solicitudeId: response.data.id,
                materialCode: item.sku,
                product: item.materialDescription,
                currentListPrice: item.currentListPrice,
                currentPsp: item.currentPsp,
                newPriceWithOutTax: item.newPriceWithOutTax,
                priceAdjustment: item.priceAdjustment,
                newPsp: item.newPsp === 0 ? item.currentPsp : item.newPsp,
                effectiveDateStart: new Date(item.effectiveDateStart),
                effectiveDateEnd: new Date(item.effectiveDateEnd),
                isApproval: item.isApproval,
                guid: item.guid,
                isPending : true,
                zofeCode: item.zofeCode,
                zofeDescription: item.zofeDescription,
                temporalDateEnd :new Date(item.effectiveDateEnd)
              };
            });
          }

          if (response.data.solicitudeFileJson) {
            this.priceCertificate.downloadFile = new SolicitudeFileModel(
              response.data.solicitudeFileJson[0].id,
              response.data.solicitudeFileJson[0].solicitudeId,
              response.data.solicitudeFileJson[0].route,
              response.data.solicitudeFileJson[0].file,
              response.data.solicitudeFileJson[0].hash,
            );
            this.priceCertificate.nameFile = this.priceCertificate.downloadFile.route;
          }
          this.onCalculationsNewPriceWithOutTax();
          this.markPendingRequest();
          this.zeroforNull();
          this.valueTempInput();
        } else {
          this.spinner.hide('PleaseWait2');
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error) => {
        this.spinner.hide('PleaseWait2');
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide('PleaseWait2');
        this.autoSave();
      }

    );
  }

  validityValue($event: any){
    let valor = 9999;
    if(Number($event.target.value.replace(/,/g,'')) > valor){
      $event.target.value = valor.toFixed(2);
    }else 
    if(Number($event.target.value.replace(/,/g,'')) < -100){
      $event.target.value = (-100).toFixed(2);
    }   
  }

  validityMaxMin(percent: any, solicitud: SolicitudeCreationModel){
    if(Number(percent) > 9999){
      solicitud.percentage = 9999;
      this.changePercentagetoMinMaxValue(solicitud);
    }else if(Number(percent) < -100){
      solicitud.percentage = -100;
      this.changePercentagetoMinMaxValue(solicitud);
    }
  }

  markPendingRequest(){
    let messagesPending = 0;
    const array: string[] = [];
    this.listPendingMaterialsByManage.forEach((itemP) => {
      this.solicitude.forEach((itemS) => {
        if(itemP === itemS.materialCode){
          array.push(itemS.materialCode);
          itemS.isPending = false;
          messagesPending ++;
        }
      });
    });
    this.spinner.hide();
    if(messagesPending>0){
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? SolicitudeMessage.PENDING_STATUS_MATERIALS : SolicitudeMessage.PENDING_STATUS_MATERIALS_TRANSLATE);
    }
  }


  /**
 * @description This method for autosave solicitude and materials.
 * @author Priscy Antonio Reales
 * @sprint 10
 */
  autoSave() {
    if(this.solicitudeService.getSolicitudeId() === 0)
			return;
    const intervalSecond = this.manageParametersService.getIntervalAutosave();
    if (intervalSecond && intervalSecond > 0) {
      const subcribeInterval = interval(intervalSecond * 1000);
      subcribeInterval
        .pipe(takeUntil(this.stop$))
        .subscribe(() => {
          this.zeroforNull();
          const model: SolicitudeMaterialRequestModel =
            new SolicitudeMaterialRequestModel(
              this.getSolicitudeModel(),
              this.getListSolicitudeMaterialModel(),
              this.priceCertificate.getSolicitudeFileModel(),
              this.solicitudeCustomer
            );

          if (model.solicitudeMaterial.length > 0) {
            this.validationSolicitudeStatus(model);
          }
        });
    }
  }

  private validationSolicitudeStatus(model: SolicitudeMaterialRequestModel) {
    if (this.solicitudeStatus === SolicitudeStatus.Temporary) {
      this.solicitudeWhitSolicitudeMaterialCreate(model);
    } else {
      this.solicitudeWhitSolicitudeMaterialEdit(model);
    }
  }

  private solicitudeWhitSolicitudeMaterialCreate(model: SolicitudeMaterialRequestModel) {
    this.solicitudeService
      .postSolicitudeWhitSolicitudeMaterial(model)
      .subscribe(
        (response) => {
          if (response.status) {
            sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
            this.dataSave();
          }else{
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
          }
        },
        (error: HttpErrorResponse) => {
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }

  private solicitudeWhitSolicitudeMaterialEdit(model: SolicitudeMaterialRequestModel) {
    if (this.solicitudeStatus === SolicitudeStatus.Registered
      || this.solicitudeStatus === SolicitudeStatus.Modification
      || this.solicitudeStatus === SolicitudeStatus.Rejected) {
      this.solicitudeService.putSolicitudeWhitSolicitudeMaterial(this.solicitudeService.getSolicitudeId(), model)
        .subscribe(
          (response) => {
            if (response.status) {
              this.dataSave();
            }else{
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
            }
          },
          (error: HttpErrorResponse) => {
            SweetAlertMessageHelpers.exception(error);
          }
        );
    }
  }

  private dataSave() {
    const language: string = sessionStorage.getItem('language');
    this.showToast(SweetAlert.iconSuccess,
      language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
      language === 'es-ES' ? SolicitudeMessage.AUTO_SAVE : SolicitudeMessage.AUTO_SAVE_TRANSLATE);
  }

  /**
* @description Event of the new price without tax to perform calculations.
* @author Daniel Londoño
* @sprint 5
*/
  onCalculationsNewPriceWithOutTax() {
    this.solicitude.forEach((x) => {
      if(x.currentListPrice === 0){
        x.priceAdjustment = 0;
        if(x.newPriceWithOutTax === 0){
          x.percentage = 0;
        }else{
          x.percentage = 100;
        }
      }else if(x.currentListPrice >= 1){
      x.priceAdjustment = Number((Number(x.newPriceWithOutTax) - Number(x.currentListPrice)).toFixed(2));
      x.percentage = Number(((Number(x.priceAdjustment) / Number(x.currentListPrice === 0 ? 1 : x.currentListPrice)) * 100).toFixed(2));
      }
    });
  }



  /**
  * @description Event of the new price without tax to perform calculations.
  * @author Daniel Londoño
  * @sprint 5
  */
  onChangeNewPriceWithOutTax(solicitud: SolicitudeCreationModel, $event: any) {
   solicitud.newPriceWithOutTax = 0;
   const aux = String($event.target.value);
   const array = aux.split('-');
   if(array[1]){
    solicitud.newPriceWithOutTax = 0;
    $event.target.value = Number(0).toFixed(2);
   }else{
    solicitud.newPriceWithOutTax = Number($event.target.value.replace(/,/g,''));
   }

    if(solicitud.currentListPrice === 0){
      this.solicitude.forEach((x) => {
        if(x.id === solicitud.id){
          x.newPriceWithOutTax = solicitud.newPriceWithOutTax;
          x.priceAdjustment = solicitud.newPriceWithOutTax;
          if(x.newPriceWithOutTax === 0){
            x.percentage = 0;
          }else {
            x.percentage = 100;
          }
        }
      });
    }else if(solicitud.currentListPrice >= 1){
      const priceList = Number(solicitud.currentListPrice);
      solicitud.priceAdjustment = Number((Number(solicitud.newPriceWithOutTax) - priceList).toFixed(2));
      solicitud.percentage = Number(((Number(solicitud.priceAdjustment) / (priceList === 0 ? 1 : priceList)) * 100).toFixed(2));

      this.validityMaxMin(solicitud.percentage, solicitud);
      //this.ChangePriceAdjustment(solicitud);
      this.zeroforNull();
    }

    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === solicitud.id);
      const isSelectRow = this.selectSolicitude.findIndex((x) => x.id === solicitud.id);
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
          if (x.id !== solicitud.id) {
            x.newPriceWithOutTax = solicitud.newPriceWithOutTax;
            x.priceAdjustment = Number((Number(x.newPriceWithOutTax) - Number(x.currentListPrice)).toFixed(2));
            x.percentage = Number(((Number(x.priceAdjustment) / Number(x.currentListPrice === 0 ? 1 : x.currentListPrice)) * 100).toFixed(2));
          }
        });
      }
    }
}

  validatePriceWithOutTax($event:any,solicitud: SolicitudeCreationModel ){
   
     let topMax = Number(((99.99 * Number(solicitud.currentListPrice)) + Number(solicitud.currentListPrice)).toFixed(2));

    if(solicitud.currentListPrice >= 1){
      if($event.target.value.replace(/,/g,'') > topMax){
        $event.target.value = topMax.toFixed(2);
      }else if($event.target.value.replace(/,/g,'') < (solicitud.currentListPrice * -2)){
        $event.target.value = ((solicitud.currentListPrice * -2).toFixed(2));
      }
    }
  }

  validatePriceAdjustment($event : any, solicitud : SolicitudeCreationModel){
   let topMax = Number(((99.99 * Number(solicitud.currentListPrice))).toFixed(2));

    if(solicitud.currentListPrice >= 1){
      if($event.target.value.replace(/,/g,'') > (topMax)){
        $event.target.value = ((topMax).toFixed(2));
      }else if($event.target.value.replace(/,/g,'') < (solicitud.currentListPrice * -1)){
        $event.target.value = ((solicitud.currentListPrice * -1).toFixed(2));
      }
    }
  }

  /**
  * @description Event of the price adjustment to perform calculations.
  * @author Daniel Londoño
  * @sprint 5
  */
  onChangePriceAdjustment(solicitud: SolicitudeCreationModel, $event : any) {
    const priceAdjustment = Number($event.target.value.replace(/,/g,''));
    solicitud.priceAdjustment = Number(priceAdjustment.toFixed(2));
    solicitud.newPriceWithOutTax = Number((Number(priceAdjustment) + Number(solicitud.currentListPrice)).toFixed(2));
    solicitud.percentage = Number(((Number(priceAdjustment) / Number(solicitud.currentListPrice === 0 ? 1 : solicitud.currentListPrice)) * 100).toFixed(2));

    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === solicitud.id);
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
           if (x.id !== solicitud.id) {
              x.priceAdjustment = solicitud.priceAdjustment;
              x.newPriceWithOutTax = Number((Number(x.priceAdjustment) + Number(x.currentListPrice)).toFixed(2));
              x.percentage = Number(((Number(x.priceAdjustment) / Number(x.currentListPrice === 0 ? 1 : x.currentListPrice)) * 100).toFixed(2));
            }
        });
      }
    }
    this.auxNegativo();
    this.zeroforNull();
  }

  auxNegativo(){
    this.solicitude.forEach((x) => {
      const aux = String(x.newPriceWithOutTax);
      const array = aux.split('-');
      if(array[1]){
       x.newPriceWithOutTax = Number(array[1].replace(/,/g,''));
       //x.newPriceWithOutTax = 0;
      }
    });

  }

  changePriceAdjustment(solicitud: SolicitudeCreationModel){
    if(solicitud.percentage > 100){
      const skuid = solicitud.materialCode;
      this.auxListMax.push(skuid);
    }else if (solicitud.percentage <= 100){
      const auxid = this.auxListMax.findIndex((elm) => {
        elm === solicitud.materialCode;
      });
      this.auxListMax.splice(auxid, 1);
    }
    // solicitud = {...solicitud};
    if (this.solicitude) {
      const isSelect = this.solicitude.filter((x) => x.id === solicitud.id);
      const isSelectRow = this.solicitude.findIndex((x) => x.id === solicitud.id);
      if (isSelectRow !== -1) {
        const x = this.solicitude[isSelectRow];
        x.priceAdjustment = solicitud.priceAdjustment;
        x.newPriceWithOutTax = Number((Number(x.priceAdjustment) + Number(x.currentListPrice)).toFixed(2));
        x.percentage = Number(((Number(x.priceAdjustment) / Number(x.currentListPrice === 0 ? 1 : x.currentListPrice)) * 100).toFixed(2));
        this.solicitude[isSelectRow] = {...x};
      }
      this.solicitude = [...this.solicitude];

    }
  }

  /**
   *
   *
  * @description Event of the percentage to perform calculations.
  * @author Daniel Londoño
  * @sprint 5
  */
  onChangePercentage(solicitud: SolicitudeCreationModel, $event: any) {
    let auxPriceAdjustment = 0;
    if(solicitud.currentListPrice !== 0){
      solicitud.percentage = Number(Number($event.target.value.replace(/,/g,'')).toFixed(2));
      auxPriceAdjustment = Number((Number(solicitud.percentage) * Number(solicitud.currentListPrice) / 100).toFixed(2));
      if(auxPriceAdjustment !== solicitud.priceAdjustment){
        solicitud.priceAdjustment = Number((Number(solicitud.percentage) * Number(solicitud.currentListPrice) / 100).toFixed(2));
      }
      solicitud.newPriceWithOutTax = Number(Number(solicitud.priceAdjustment) + Number(Number((solicitud.currentListPrice).toFixed(2)) === 0 
      ? 1 : Number(Number(solicitud.currentListPrice).toFixed(2))));
    }


    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === solicitud.id); // array te solicitude
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
          if(x.currentListPrice !== 0){
            x.percentage = Number(Number($event.target.value).toFixed(2));
            x.priceAdjustment = Number(((Number(x.percentage) * Number(x.currentListPrice)) / 100).toFixed(2));
            x.newPriceWithOutTax = Number((Number(x.priceAdjustment) + Number(x.currentListPrice === 0 ? 1 : x.currentListPrice)).toFixed(2));
          }else if(x.currentListPrice === 0){
            x.percentage = Number(Number($event.target.value).toFixed(2));
            x.priceAdjustment = 0;
            x.newPriceWithOutTax = 0;
          }
        });
      }
    }

    this.zeroforNull();
  }


  changePercentagetoMinMaxValue(solicitud: SolicitudeCreationModel){
    solicitud.priceAdjustment = Number(((Number(solicitud.percentage) * Number(solicitud.currentListPrice)) / 100).toFixed(2));
    let topMax = Number(((99.99 * Number(solicitud.currentListPrice)) + Number(solicitud.currentListPrice)).toFixed(2));

    if(Number(solicitud.newPriceWithOutTax) > topMax){
       solicitud.newPriceWithOutTax = Number(topMax.toFixed(2));
    }else{
      solicitud.newPriceWithOutTax = Number(solicitud.newPriceWithOutTax);
    }
   // solicitud.newPriceWithOutTax = Math.trunc(Number(Number(solicitud.priceAdjustment) + Number(solicitud.currentListPrice === 0 ? 1 : solicitud.currentListPrice)));
    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === solicitud.id); // array te solicitude
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
          x.percentage = solicitud.percentage;
          x.priceAdjustment = Number(((Number(x.percentage) * Number(x.currentListPrice)) / 100).toFixed(2));
          x.newPriceWithOutTax = Number((Number(x.priceAdjustment) + Number(x.currentListPrice === 0 ? 1 : x.currentListPrice)).toFixed(2));
        });
      }
    }
  }
  /**
  * @description Event of the new psp to perform calculations.
  * @author Daniel Londoño
  * @sprint 5
  */
  onChangeNewPsp(solicitud: SolicitudeCreationModel, $event : any) {
    solicitud.newPsp = Number(Number($event.target.value.replace(/,/g,'')).toFixed(2));
    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === solicitud.id);
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
          x.newPsp = solicitud.newPsp;
        });
      }
    }
  }
  /**
  * @description Event of the date start to change.
  * @author Daniel Londoño
  * @sprint 5
  */
  onChangeDateStart(solicitud: SolicitudeCreationModel): void {
    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === solicitud.id);
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
          x.effectiveDateStart = solicitud.effectiveDateStart;
        });
      }
    }
  }

  /**
     * @description This method is for validate date control.
     *
     * @author Priscy Reales
     * @sprint 15
     */
  getYearRange() {
    return `${this.getActuallyYear(0)}:${this.getActuallyYear(10)}`;
  }

  getActuallyYear(years : number) : number{
    const thisDate = new Date();
    return thisDate.getFullYear() + years;
  }

  validateDates(dateStart: Date, dateEnd: Date) {
    if (dateStart > dateEnd) {
    }
  }

  /**
  * @description Event of the date end to change.
  * @author Daniel Londoño
  * @sprint 5
  */
  onChangeDateEnd(solicitud: SolicitudeCreationModel) {
    if(solicitud.effectiveDateEnd === null || solicitud.effectiveDateEnd === undefined){
      solicitud.effectiveDateEnd = new Date(2199,11,31);
    }else if(solicitud.effectiveDateEnd.getFullYear() > this.getActuallyYear(10)){
      solicitud.effectiveDateEnd.setFullYear(this.getActuallyYear(0));
      this.verifyEndDateChange(solicitud);
    }
    if (this.selectSolicitude) {
      const isSelect = this.selectSolicitude.filter((x) => x.id === solicitud.id);
      if (isSelect.length > 0) {
        this.selectSolicitude.forEach((x) => {
          x.effectiveDateEnd = solicitud.effectiveDateEnd;
        });
      }
    }
  }

  onShowDate(object: SolicitudeCreationModel){
    let effectiveDateEnd;
    let today = this.miDatePipe.transform(this.today,'yyyy-MM-dd');
    effectiveDateEnd = this.miDatePipe.transform(object.effectiveDateEnd,'yyyy-MM-dd');

    if(object.effectiveDateEnd.getFullYear() > this.getActuallyYear(10)){
      object.effectiveDateEnd = new Date();
    }

    if(effectiveDateEnd < today){
      object.effectiveDateEnd = this.today;
    }

  }

  onTodayClick(object: SolicitudeCreationModel){
    object.effectiveDateEnd = new Date();
  }

  onClearClick(object: SolicitudeCreationModel){
     object.effectiveDateEnd = new Date(9999, 11, 31); 
     return;
  }

  /**
  * @description This method is to validate and change year when date selected is 9999 in year
  * @author Diego Cortes Quiroga
  * @sprint 16
  */
  verifyEndDateChange(solicitud: SolicitudeCreationModel): void {
    if (solicitud.effectiveDateEnd && solicitud.effectiveDateEnd.getFullYear() === 9999) {
      solicitud.effectiveDateEnd.setFullYear(this.tomorrow.getFullYear());
      if (solicitud.effectiveDateEnd.getMonth() === this.tomorrow.getMonth() &&
        solicitud.effectiveDateEnd.getDay() < this.tomorrow.getDay()) {
        solicitud.effectiveDateEnd.setDate(this.tomorrow.getDate());
      }
    }
    if (!solicitud.effectiveDateEnd) {
      solicitud.effectiveDateEnd = new Date(9999, 11, 31);
    }
  }

  /**
   * @description this method handles the event of the child component of filters
   * @author Daniel Londoño
   * @sprint 4
   */
  eventClickFilters($event: any) {

    if ($event.advancedFiltersObjectList.length > 0) {
      this.spinner.show();
      const newFilterModel = new FilterDataModel(
        JSON.stringify($event.advancedFiltersObjectList),
        environment.conditionTable,
        environment.conditionClassCode,
        true
      );
      this.getMaterialsByFilters(newFilterModel);
    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS : SolicitudeMessage.NOT_FILTERS_TRANSLATE);
    }
  }


  getPendingSolicitudeByMaterialCode(solicitudeMaterialModel : SolicitudeMaterialModel[])
  {
    this.solicitudeService.getPendingSolicitudeByMaterialCode([]).subscribe(
      (response) => {
        if (response.status) {
          this.listPendingMaterialsByManage = response.data;
        }
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }



  /**
  * @description This method return model SolicitudeMaterial List.
  * @author Daniel Londoño
  * @sprint 5
  */
  getListSolicitudeMaterialModel(): SolicitudeMaterialModel[] {

    if (this.solicitude) {
      const modelDetail: SolicitudeMaterialModel[] = this.solicitude.map(
        (item) => {
          return {
            id: 0,
            solicitudeId: this.solicitudeService.getSolicitudeId(),
            materialCode: item.materialCode,
            currentListPrice: item.currentListPrice,
            currentPsp: item.currentPsp,
            newPriceWithOutTax: item.newPriceWithOutTax,
            priceAdjustment: item.priceAdjustment,
            newPsp: item.newPsp,
            effectiveDateStart: new Date(item.effectiveDateStart),
            effectiveDateEnd: new Date(item.effectiveDateEnd),
            isApproval: item.isApproval,
            guid: item.guid,
            zofeCode: item.zofeCode,
            zofeDescription: item.zofeDescription
          };
        }
      );
      return modelDetail;
    }
  }
  /**
   * @description This method return model Solicitude.
   * @author Daniel Londoño
   * @sprint 5
   */
  getSolicitudeModel(): SolicitudeModel {
    return new SolicitudeModel(
      this.solicitudeService.getSolicitudeId(),
      this.solicitudeType,
      this.solicitudeStatus,
      0,
      this.codeCreationSession,
      this.filterClientJson
    );
  }

  /**
   * @description This method for get type filters creation.
   * @author Elkin Vasquez Isenia
   * @sprint 4
   */
  public getTypeFiltersCreation(): void {
    this.domainService.getAllByDomainType(Microservice.Core, DomainTypeSecurity.TypeFilterCreation).subscribe(
      (response) => {
        this.typeFiltersObjectList = response.data.map((item) => {
          return {
            label: item.description,
            value: item.code
          };
        });
      },
      (error) => {
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  /**
    * @description This method for get the filters.
    * @author Priscy Antonio Reales
    * @sprint 10
    * @param filters
    */
  private getMaterialsByFilters(filters: FilterDataModel): void {
    this.spinner.show();
    this.managePromotionActService.postAdvancedFiltersMaterials(filters).subscribe(
      (response) => {
        if (response.data.length > 0) {
          const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' 
            ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`: 
            `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`);

          const listFilters = response.data.map((item: any) => {
            return {
              id: item.id,
              solicitudeId: 0,
              materialCode: item.materialCode,
              product: item.product,
              currentListPrice: parseFloat(item.currentPsp),
              currentPsp: 0,
              newPriceWithOutTax: 0,
              priceAdjustment: 0,
              newPsp: 0,
              effectiveDateStart: this.tomorrow,
              effectiveDateEnd: parse(item.effectiveDateEnd, 'dd/MM/yyyy', new Date()),
              guid: String(Util.newGuid()),
              zofeCode: item.zofeCode,
              zofeDescription: item.zofeDescription
            };
          });

					this.solicitude = [];
          this.solicitude = [...listFilters];
          this.onCalculationsNewPriceWithOutTax();
          const model: SolicitudeMaterialRequestModel =
            new SolicitudeMaterialRequestModel(
              this.getSolicitudeModel(),
              this.getListSolicitudeMaterialModel()
            );

          this.getPendingSolicitudeByMaterialCode(model.solicitudeMaterial);
          this.spinner.show('PleaseWait2');
          this.solicitudeService
            .postSolicitudeWhitSolicitudeMaterial(model)
            .subscribe(
              (response) => {
                if(response.status){
                  sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
                  this.solicitudeService.setSolicitudeId(response.data);
                  this.getSolicitudeById();
                }else{
                  SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
                }                  
              },
              (error: HttpErrorResponse) => {
                this.spinner.hide();
                SweetAlertMessageHelpers.exception(error);
              },
              () => {
                this.spinner.hide();
              }
            );

        } else {
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE);
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  /**
   * @description This method for get the filters.
   * @author Elkin Vasquez Isenia
   * @sprint 7
   * @param filters
   */
  public advancedFiltersMaterials(filters: string): void {
    const objFilters: IAdvancedFiltersMaterial =
      new AdvancedFiltersMaterialModel(
        filters,
        environment.conditionTable,
        environment.conditionClassCode,
        'Id',
        true,
        1,
        1000
      );

    this.solicitudeService.postAdvancedFiltersMaterials(objFilters)
      .subscribe(
        (response) => {
          this.filterResult = response.data;
          if (this.filterResult.totalRecords > 0) {

            const language: string = sessionStorage.getItem('language');
          this.showToast(SweetAlert.iconSuccess,
            language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
            language === 'es-ES' 
            ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${this.filterResult.totalRecords}`: 
            `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${this.filterResult.totalRecords}`);
            const listFilters = this.filterResult.results.map((item) => {
              return {
                id: item.id,
                solicitudeId: 1,
                materialCode: item.materialCode,
                product: item.product,
                currentListPrice: parseFloat(item.currentPsp),
                currentPsp: 0,
                newPriceWithOutTax: 0,
                priceAdjustment: 0,
                newPsp: 0,
                effectiveDateStart: parse(item.effectiveDateStart, 'dd/MM/yyyy', new Date()),
                effectiveDateEnd: parse(item.effectiveDateEnd, 'dd/MM/yyyy', new Date()),
                zofeCode: item.zofeCode,
                zofeDescription: item.zofeDescription
              };
            });

            this.solicitude = [...listFilters];
            this.onCalculationsNewPriceWithOutTax();
            const model: SolicitudeMaterialRequestModel =
              new SolicitudeMaterialRequestModel(
                this.getSolicitudeModel(),
                this.getListSolicitudeMaterialModel()
              );
            this.solicitudeService
              .postSolicitudeWhitSolicitudeMaterial(model)
              .subscribe(
                (response) => {
                  if(response.status){
                    sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
                    this.solicitudeService.setSolicitudeId(response.data);
                    this.getSolicitudeById();
                  }else{
                    SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
                  }
                  
                },
                (error: HttpErrorResponse) => {
                  this.spinner.hide();
                  SweetAlertMessageHelpers.exception(error);
                },
                () => {
                  this.spinner.hide();
                }
              );
          } else {
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS_RESULT : SolicitudeMessage.NOT_FILTERS_RESULT_TRANSLATE);
          }

        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        },
        () => {
          this.spinner.hide();
        }
      );
  }


  /**
 * @description This method for delete material.
 * @author Daniel Londoño
 * @sprint 5
 */
  public delete(): void {
    if(!this.selectSolicitude || this.selectSolicitude.length === 0 ){
      const language: string = sessionStorage.getItem('language');
			SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
        language === 'es-ES' ? `${SolicitudeMessage.NOT_SELECTED_ITEM}` : `${SolicitudeMessage.NOT_SELECTED_ITEM_TRANSLATE}`);
			return;
    }
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.solicitudeTemp = this.solicitude;
        const Ids = this.selectSolicitude.map((item: SolicitudeCreationModel) => { return item.id; }).join();
        if (!Ids) {
          this.spinner.hide();
          return;
        }
        const auxProductsSelect = [...this.selectSolicitude];
        auxProductsSelect.forEach((element, index) => {
          const indexSolicitude = this.solicitude.findIndex((prd) => prd.id === element.id);
          const indexSolicitudeSelect = this.selectSolicitude.findIndex((prd) => prd.id === element.id);
          if (indexSolicitude > -1) {
            this.solicitude.splice(indexSolicitude, 1);
            this.selectSolicitude.splice(indexSolicitudeSelect, 1);
          }
        });

        this.solicitudeService.deleteSolicitudeMaterialByIds(Ids).subscribe(
          (response) => {
            if (response.status) {
              this.getSolicitudeById();
              const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              this.spinner.hide();
            } else {
              this.spinner.hide();
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );

      }
    });
  }

  valueTempInput(){
    if(this.solicitudeTemp.length > 0){
      this.solicitudeTemp.forEach((itemTemp) => {
        this.solicitude.forEach((item) => {
          if(item.id === itemTemp.id){
  
            if(item.newPriceWithOutTax !== itemTemp.newPriceWithOutTax){
              item.newPriceWithOutTax = itemTemp.newPriceWithOutTax;
            }
  
            if(item.priceAdjustment !== itemTemp.priceAdjustment){
              item.priceAdjustment = itemTemp.priceAdjustment;
            }
  
            if(item.percentage !== itemTemp.percentage){
              item.percentage = itemTemp.percentage;
            }
  
            if(item.newPsp !== itemTemp.newPsp){
              item.newPsp = itemTemp.newPsp;
            }
  
            if(item.effectiveDateStart !== itemTemp.effectiveDateStart){
              item.effectiveDateStart = itemTemp.effectiveDateStart;
            }
  
            if(item.effectiveDateEnd !== itemTemp.effectiveDateEnd){
              item.effectiveDateEnd = itemTemp.effectiveDateEnd;
            }
          }
        });
      });
    }
  }

  /**
* @description This method return back
* @author Daniel Londoño
* @sprint 11
*/
  public back(): void {
    this.isVisibleAssingClient = false;
    this.getSolicitudeById();
  }

  /**
* @description This method save data
* @author Daniel Londoño
* @sprint 11
*/
  public eventClickGetDataSave($event: any): void {
    if ($event.jsonFilterClient || $event.listClient) {
      this.spinner.show();
      this.filterClientJson = $event.jsonFilterClient;
      this.solicitudeCustomer = this.getSolicitudeCustomerModel($event.listClient);
      this.solicitudeStatus = SolicitudeStatus.Registered;
      const model: SolicitudeMaterialRequestModel =
        new SolicitudeMaterialRequestModel(
          this.getSolicitudeModel(),
          this.getListSolicitudeMaterialModel(),
          // this.getSolicitudeFileModel(),
          this.priceCertificateAux,
          this.solicitudeCustomer
        );

      this.solicitudeService.putSolicitudeWhitSolicitudeMaterial(this.solicitudeService.getSolicitudeId(), model)
        .subscribe(
          (response) => {
            if(response.status){
              this.solicitudeService.setSolicitudeId(response.data);
              const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconSuccess,
                  language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertCreate :SweetAlert.messageAlertCreateTranslate);
              this.router.navigate(['auth/solicitude']);
            }else{
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message);
              this.router.navigate(['auth/solicitude']);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          },
          () => {
            this.spinner.hide();
          }
        );
    }
    else {
      this.spinner.hide();
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
        language === 'es-ES' ? SolicitudeMessage.NOT_FILTERS_CLIENT : SolicitudeMessage.NOT_FILTERS_CLIENT_TRANSLATE );
    }
  }

  /**
 * @description This method is to prepare customers data to create solicitude
 *
 * @param listClient
 * @author Daniel Londono
 * @sprint 16
 */
  private getSolicitudeCustomerModel(listClient: number[]): ISolicitudeCustomer[] {
    let customers: ISolicitudeCustomer[] = [];
    if (listClient !== null) {
      customers = listClient.map((id) => {
        return {
          solicitudeId: 0,
          clientCode: `${id}`,
          id: 0
        };
      });
    }
    return customers;
  }

  validationPSPNew(): boolean {
    let newPSPAux = 0;
    this.solicitude.forEach((x) => {
      if (x.newPsp === 0 || x.newPsp === null || !x.newPsp) {
        newPSPAux++;
      }
    });
    return newPSPAux > 0;
  }

  /**
 * @description This method for save solicitude and materials.
 * @author Daniel Londoño
 * @sprint 5
 */
  public next(): void {
    this.zeroforNull();
    const model: SolicitudeMaterialRequestModel =
    new SolicitudeMaterialRequestModel(
      this.getSolicitudeModel(),
      this.getListSolicitudeMaterialModel(),
      this.priceCertificateAux = this.priceCertificate.getSolicitudeFileModel(),
    );

    let flagContinue = true;
    for(let i = 0; i < model.solicitudeMaterial.length; i ++){
      flagContinue = this.listPendingMaterialsByManage.find((x) => x ===  model.solicitudeMaterial[i].materialCode) ? false : true;
      if(flagContinue){
        break;
      }
    }
    if(!flagContinue){
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
        language === 'es-ES' ? SolicitudeMessage.PENDING_STATUS_MATERIALS : SolicitudeMessage.PENDING_STATUS_MATERIALS_TRANSLATE);
      return;
    }

    Util.confirmSave().then((result: SweetAlertResult) => {
      if (result.value) {
        if(this.auxListMax.length > 0){
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
            language === 'es-ES' ? SolicitudeMessage.MAX_PERCENTAGE : SolicitudeMessage.MAX_PERCENTAGE_TRANSLATE);
          return;
        }
        else {
          this.PdfExportOption(model);
        }
      }
    });
  }

  /**
   * @description This method is to validate the date range on each sku.
   * @author Diego Cortes Quiroga
   * @sprint 15
   */
  validateDatesRanges(solicitude: SolicitudeMaterialModel[]): string {
    let indicatorValidateDatesRanges = '';
    solicitude.forEach((x) => {
      if (x.effectiveDateEnd.getFullYear() !== 9999) {
        if (x.effectiveDateStart > x.effectiveDateEnd) {
          indicatorValidateDatesRanges += '<br/>' + x.materialCode;
        }
      }
    });
    return indicatorValidateDatesRanges;
  }

  validateNewPriceWithOutTax(solicitude: SolicitudeMaterialModel[]): boolean {
    let newPriceWithOutTax = 0;
    solicitude.forEach((x) => {
      if (x.newPriceWithOutTax === 0 || x.newPriceWithOutTax === null || !x.newPriceWithOutTax) {
        newPriceWithOutTax++;
      }
    });
    return newPriceWithOutTax > 0;
  }

  refreshDetailByFile(id : number){
    this.solicitudeService.setSolicitudeId(id);
    this.getSolicitudeById();
  }
 
  /**
   * @description This method shows the toast control.
   * @author Elkin Vasquez Isenia
   * @sprint 4
   * @param severity
   * @param message
   * @param detail
   * @returns none
   */
  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }

  materialCodeZeroLess(value: string): string{
    return this.helperService.materialCodeZeroLess(value);
  }

  private PdfExportOption(model: SolicitudeMaterialRequestModel) {
    if(this.validationPSPNew()){
      this.spinner.hide();
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
      language === 'es-ES' ? SolicitudeMessage.PSPNEW : SolicitudeMessage.PSPNEW_TRANSLATE);
      return;
    }
    if (this.priceCertificate.attachedFilePdf || this.priceCertificate.downloadFile) {
      this.spinner.show();
      if (model.solicitudeMaterial && model.solicitudeMaterial.length > 0) {
        if (!this.validateNewPriceWithOutTax(model.solicitudeMaterial)) {
          this.validationDates(model);
        }
        else {
          this.spinner.hide();
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
            language === 'es-ES' ? SolicitudeMessage.NEW_TAXES_ZERO : SolicitudeMessage.NEW_TAXES_ZERO_TRANSLATE);
        }
      }
      else {
        this.spinner.hide();
        const language: string = sessionStorage.getItem('language');
        SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
          language === 'es-ES' ? SolicitudeMessage.NO_EXIST_MATERIALS : SolicitudeMessage.NO_EXIST_MATERIALS_TRANSLATE);
      }
    }
    else {
      this.spinner.hide();
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
        language === 'es-ES' ? SolicitudeMessage.NO_PDF : SolicitudeMessage.NO_PDF_TRANSLATE);
    }
  }

  private validationDates(model: SolicitudeMaterialRequestModel) {
    const validationDates = this.validateDatesRanges(model.solicitudeMaterial);
    if (validationDates.length === 0) {
      if (this.solicitudeStatus === SolicitudeStatus.Temporary) {
        this.solicitudeService
          .postSolicitudeWhitSolicitudeMaterial(model)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                sessionStorage.removeItem(CommonConfiguration.SolicitudeCodeCreation);
                this.stopInterval();
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconSuccess,
                  language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
                this.isVisibleAssingClient = true;
                this.paginatorService.scrollUp();
              }else{
                SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
              }
            },
            (error: HttpErrorResponse) => {
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
      else {
        if (this.solicitudeStatus === SolicitudeStatus.Registered
          || this.solicitudeStatus === SolicitudeStatus.Modification
          || this.solicitudeStatus === SolicitudeStatus.Rejected) {
          this.solicitudeService.putSolicitudeWhitSolicitudeMaterial(this.solicitudeService.getSolicitudeId(), model)
            .subscribe(
              (response) => {
                if (response.status) {
                  this.spinner.hide();
                  this.stopInterval();
                  const language: string = sessionStorage.getItem('language');
                  this.showToast(SweetAlert.iconSuccess,
                    language === 'es-ES' ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate,
                    language === 'es-ES' ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate);
                  this.isVisibleAssingClient = true;
                  this.paginatorService.scrollUp();
                }else{
									SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
								}
              },
              (error: HttpErrorResponse) => {
                this.spinner.hide();
                SweetAlertMessageHelpers.exception(error);
              }
            );
        }
      }
    }
    else {
      this.spinner.hide();
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.warning(SweetAlert.titleAlertError,
        language === 'es-ES' ? `${SolicitudeMessage.BAD_SKU_DATE_RANGE}: ${validationDates}`
          : `${SolicitudeMessage.BAD_SKU_DATE_RANGE_TRANSLATE}: ${validationDates}`);
    }
  }

}
