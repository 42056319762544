import { ErrorMessage } from '../messages/error.message';
import { StatusCode } from '../enums/statuscode.enum';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SweetAlert } from '../enums/sweetalert.enum';
import { TypeMessage } from '../enums/type-message.enum';
import { CommonConfiguration } from '../enums/common.enums';

export class SweetAlertMessageHelpers {

  /**
  * @description Success
  * @author Elkin Vasquez Isenia
  * @param title show title in message window
  * @param text display the message in the pop-up window
  * @returns none
  */
  static success(title: string, text: string) {
    const language: string = sessionStorage.getItem('language');
    const btnConfirmationText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnConfirmationText : SweetAlert.btnConfirmationTextTranslate;
    Swal.fire({
      title,
      text,
      icon: SweetAlert.iconSuccess,
      confirmButtonText: btnConfirmationText,
      confirmButtonColor: SweetAlert.btnConfirmationColor,
    });
  }

  /**
  * @description Info
  * @author Elkin Vasquez Isenia
  * @param title show title in message window
  * @param text display the message in the pop-up window
  * @returns none
  */
   static info(title: string, text: string) {
    const language: string = sessionStorage.getItem('language');
    const btnConfirmationText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnConfirmationText : SweetAlert.btnConfirmationTextTranslate;
    Swal.fire({
      title,
      text,
      icon: SweetAlert.iconInfo,
      confirmButtonText: btnConfirmationText,
      confirmButtonColor: SweetAlert.btnConfirmationColor,
    });
  }


  /**
 * @description Warning
 * @author Elkin Vasquez Isenia
 * @param title show title in message window
 * @param text display the message in the pop-up window
 * @returns none
 */
  static warning(title: string, text: string) {
    const language: string = sessionStorage.getItem('language');
    const btnConfirmationText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnConfirmationText : SweetAlert.btnConfirmationTextTranslate;
    Swal.fire({
      title,
      html : text,
      icon: SweetAlert.iconWarning,
      confirmButtonText: btnConfirmationText,
      confirmButtonColor: SweetAlert.btnConfirmationColor,
    });
  }

  /**
 * @description Error
 * @author Elkin Vasquez Isenia
 * @param title show title in message window
 * @param text display the message in the pop-up window
 * @returns none
 */
  static error(title: string, text: string) {
    const language: string = sessionStorage.getItem('language');
    const btnConfirmationText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnConfirmationText : SweetAlert.btnConfirmationTextTranslate;
    Swal.fire({
      title,
      text,
      icon: SweetAlert.iconError,
      confirmButtonText: btnConfirmationText,
      confirmButtonColor: SweetAlert.btnConfirmationColor,
    });
  }

  /**
 * @description Confirm
 * @author Elkin Vasquez Isenia
 * @param title show title in message window
 * @param text display the message in the pop-up window
 * @returns none
 */
  static confirm(title: string, text: string) {
    const language: string = sessionStorage.getItem('language');
    const btnCancelText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnCancelText : SweetAlert.btnCancelTextTranslate;
    const btnConfirmationText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnConfirmationText : SweetAlert.btnConfirmationTextTranslate;
    return Swal.fire({
      title,
      text,
      icon: SweetAlert.iconWarning,
      showCancelButton: true,
      confirmButtonColor: SweetAlert.btnConfirmationText,
      cancelButtonColor: SweetAlert.btnCancelColor,
      confirmButtonText: btnConfirmationText,
      cancelButtonText: btnCancelText,
    });
  }

   /**
 * @description Confirm download
 * @author Priscy Antonio Reales
 * @param title show title in message window
 * @param text display the message in the pop-up window
 * @returns none
 */
   static confirmDownload(title: string, text: string) {
    const language: string = sessionStorage.getItem('language');
    const btnCancelText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnCancelText : SweetAlert.btnCancelTextTranslate;
    const btnConfirmationText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnConfirmationDownloadText : SweetAlert.btnConfirmationDownloadTextTranslate;
    return Swal.fire({
      title,
      text,
      icon: SweetAlert.iconWarning,
      showCancelButton: true,
      confirmButtonColor: SweetAlert.btnConfirmationText,
      cancelButtonColor: SweetAlert.btnCancelColor,
      confirmButtonText: btnConfirmationText,
      cancelButtonText: btnCancelText,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
  }  

  /**
   * @description Confirm Delete
   * @author Elkin Vasquez Isenia
   * @param title show title in message window
   * @param text display the message in the pop-up window
   * @returns none
   */
  static confirmDelete(title: string, text: string) {
    const language: string = sessionStorage.getItem('language');
    const btnCancelText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnCancelText : SweetAlert.btnCancelTextTranslate;
    const confirmButtonText = (language === CommonConfiguration.LanguageKey) ? SweetAlert.btnDeleteText : SweetAlert.btnDeleteTextTranslate;
    return Swal.fire({
      title,
      text,
      icon: SweetAlert.iconWarning,
      showCancelButton: true,
      confirmButtonColor: SweetAlert.btnConfirmationText,
      cancelButtonColor: SweetAlert.btnCancelColor,
      confirmButtonText: confirmButtonText,
      cancelButtonText: btnCancelText,
    });
  }

  /**
   * @description Show Message.
   * @author Elkin Vasquez Isenia
   * @param typeMessage 1: Success, 2: Warning y 0: Error
   * @sprint 1
   * @returns none
   */
  static showMessage(typeMessage: TypeMessage, optionalParameters? : string[]) {
    const language: string = sessionStorage.getItem('language');
    let titleAlert: string;
    let messageAlert: string;
    switch (typeMessage) {
      case TypeMessage.Success: {
        titleAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate;
        messageAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.messageAlertCreate : SweetAlert.messageAlertCreateTranslate;
        return SweetAlertMessageHelpers.success(titleAlert, messageAlert);
      }
      case TypeMessage.Warning: {
        titleAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate;
        messageAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate;
        return SweetAlertMessageHelpers.warning(titleAlert, messageAlert);
      }
      case TypeMessage.Delete: {
        titleAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate;
        messageAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate;
        return SweetAlertMessageHelpers.info(titleAlert, messageAlert);
      }
      case TypeMessage.Error: {
        titleAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate;
        messageAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.messageAlertError : SweetAlert.messageAlertErrorTranslate;
        return SweetAlertMessageHelpers.error(titleAlert, messageAlert);
      }
      case TypeMessage.Retry: {
        titleAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.titleAlertRetry + optionalParameters[0] : SweetAlert.titleAlertRetryTranslate + optionalParameters[0];
        messageAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.messageAlertRetry : SweetAlert.messageAlertRetryTranslate;
        return SweetAlertMessageHelpers.warning(titleAlert, messageAlert + optionalParameters[1]);
      }
      case TypeMessage.Update: {
        titleAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.titleAlert : SweetAlert.titleAlertTranslate;
        messageAlert = (language === CommonConfiguration.LanguageKey) ? SweetAlert.messageAlertUpdate : SweetAlert.messageAlertUpdateTranslate;
        return SweetAlertMessageHelpers.success(titleAlert, messageAlert);
      }
      default: break;
    }
  }

  /**
   * @description Exception.
   * @author Elkin Vasquez Isenia
   * @param error: HttpErrorResponse
   * @sprint 1
   * @returns none
   */
  static exception(error: HttpErrorResponse): void {
    switch (error.status) {
      case StatusCode.BadRequest:
        Swal.fire({
          title: ErrorMessage.UNEXPECTED_ERROR_DETAIL,
          text: error.error.Message ? error.error.Message : ErrorMessage.BAD_REQUEST_ERROR_FAILED_DETAIL,
          icon: SweetAlert.iconError,
          confirmButtonText: SweetAlert.btnConfirmationText,
          confirmButtonColor: SweetAlert.btnConfirmationColor,
        });
        break;
      case StatusCode.Unauthorized:
        Swal.fire({
          title: ErrorMessage.UNAUTHORIZED_ERROR_FAILED,
          text: ErrorMessage.UNAUTHORIZED_ERROR_FAILED_DETAIL,
          icon: SweetAlert.iconError,
          confirmButtonText: SweetAlert.btnConfirmationText,
          confirmButtonColor: SweetAlert.btnConfirmationColor,
        });
        break;
      case StatusCode.InternalServerError:
        Swal.fire({
          title: ErrorMessage.INTERNAL_SERVER_ERROR_FAILED,
          text: ErrorMessage.INTERNAL_SERVER_ERROR_FAILEDDETAIL,
          icon: SweetAlert.iconError,
          confirmButtonText: SweetAlert.btnConfirmationText,
          confirmButtonColor: SweetAlert.btnConfirmationColor,
        });
        break;
      default:
        Swal.fire({
          title:
            error.status === StatusCode.None
              ? ErrorMessage.SERVICE_FAILED
              : `${ErrorMessage.SERVICE_FAILED} (${error.status})`,
          text: ErrorMessage.SERVICE_FAILED_DETAIL,
          icon: SweetAlert.iconError,
          confirmButtonText: SweetAlert.btnConfirmationText,
          confirmButtonColor: SweetAlert.btnConfirmationColor,
        });
        break;
    }
  }


     /**
     * @description List Messa
     * @author Elkin Vasquez Isenia
     * @param title show title in message window
     * @param text display the message in the pop-up window
     * @returns none
     */
     static listWarningMessageShow(tittleMessage: string, listMessage: string[], column? :number, width? : string, subtittleOne?: string) {
      if(!column) column = 1;
      var divStar = `<div style = "overflow-x: auto; height: auto;">`
      var divEnd = `<div>`
      var ulStart = `<ul style=" column-count: ${column}">`;
      var ulEnd = '</ul>';
      let htmlMessage : string = '';
      let firstSubtittle : string = '';
      if(subtittleOne) firstSubtittle = `<ul class="text-justify"><h5><strong>${subtittleOne}</strong></h6>${ulEnd}`;
      htmlMessage += firstSubtittle;
      let msnError = `${divStar} ${ulStart} ${listMessage.map((v) => {return `<li class="text-justify">${v}</li>`;})} ${ulEnd} ${divEnd}`;
      msnError = msnError.replace(/li>,/g,'li>');
      htmlMessage += msnError;

      Swal.fire({
        title: tittleMessage,
        html: htmlMessage,
        icon: SweetAlert.iconError,
        confirmButtonText: SweetAlert.btnConfirmationText,
        confirmButtonColor: SweetAlert.btnConfirmationColor,
        width : width
      });
    }

    static listMessageShow(tittleMessage: string, listMessageOne: string[], subtittleOne?: string, subtittleTwo?: string, listMessageTwo?: string[], column?: number, width? : string){
      if(!column) column = 1;
      var ulStartSimple = `<ul>`;
      var ulEnd = '</ul>';
      var htmlMessage = [];
      let firstSubtittle : string = '';
      if(subtittleOne) firstSubtittle = `${ulStartSimple}<h4><strong>${subtittleOne}</strong></h4>${ulEnd}`;
      let msnErrorOne = `${ulStartSimple} ${listMessageOne.map((v) => {return `<li style="list-style: none" class="text-justify"><strong>${v}</strong></li>`;})} ${ulEnd}`;
      msnErrorOne = msnErrorOne.replace(/li>,/g,'li>');

      let secondSubtittle : string = '';
      if(subtittleTwo) secondSubtittle = `${ulStartSimple}<h4><strong>${subtittleTwo}</strong></h4>${ulEnd}`;
      var ulStart = `<ul style="column-count: ${column}">`;
      let msnErrorTwo : string = '';
      if(listMessageTwo){
        msnErrorTwo = `${ulStart} ${listMessageTwo.map((v) => {return `<li style="list-style: none" class="text-justify"><strong>${v}</strong></li>`;})} ${ulEnd}`;
        msnErrorTwo = msnErrorTwo.replace(/li>,/g,'li>');
      }
        
      const html = `${firstSubtittle}${msnErrorOne}${secondSubtittle}${msnErrorTwo}`;

      Swal.fire({
        title: tittleMessage,
        html: html,
        icon: SweetAlert.iconError,
        confirmButtonText: SweetAlert.btnConfirmationText,
        confirmButtonColor: SweetAlert.btnConfirmationColor,
        width : width
      });
    }

}
