import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IComercialActivityNegociation } from './interfaces/comercial-activity-negociation.interface';
import { CommercialActivityNegociationSendModel } from './models/commercial-activity-negociation-send-model';
//import { RequestNoveltySendModel } from './models/request-novelty-send-model';

@Injectable({
  providedIn: 'root'
})
export class CommercialActivityNegociationExclusionService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  public getAll(): Observable<IResponseService<IComercialActivityNegociation[]>> {
    const response$: Observable<IResponseService<IComercialActivityNegociation[]>> = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion_commercial_activity_negociation/1/10000`)
      .pipe(
        map((response: HttpResponse<IResponseService<IComercialActivityNegociation[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public getAllDapper(): Observable<IResponseService<IComercialActivityNegociation[]>> {
    const response$: Observable<IResponseService<IComercialActivityNegociation[]>> = this.httpService
      .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/list-commercial-activity-negociation`)
      .pipe(
        map((response: HttpResponse<IResponseService<IComercialActivityNegociation[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public delete(id : number){
    const response$: Observable<IResponseService<boolean>> =
    this.httpService
      .delete(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion_commercial_activity_negociation/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public post(body: CommercialActivityNegociationSendModel): Observable<IResponseService<string>> {
    const response$: Observable<IResponseService<string>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/exclusion_commercial_activity_negociation`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<string>>) => {
          return response.body;
        })
      );
    return response$;
  }
}
