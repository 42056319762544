import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOrganizationChart } from './interfaces/organization-chart.interface';
import { OrganizationChartModel } from './models/organization-chart.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationChartService {

  constructor(private httpService: HttpService) { }

  public getAllOrganization(IdHierarchy: number): Observable<IResponseService<IOrganizationChart[]>> {
    const response$: Observable<IResponseService<IOrganizationChart[]>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/node/${IdHierarchy}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IOrganizationChart[]>>) => {
          return response.body;
        })
      );
    return response$;
  }



  public postOrganization(body: OrganizationChartModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
      .post(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/node`, body)
      .pipe(
        map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public putHierarchy(id: number, body: OrganizationChartModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
      .put(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/node/${id}`, body)
      .pipe(
        map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public deleteHierarchyLogic(id: number): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
     .delete(
       `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/hierarchy_configuration/node/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }


}
