import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { IDataRange } from './interfaces/data-range.interface';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { CommercialActivityDocumentModel } from './models/commercial-activity-document.model';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { IValidityPeriod } from '@app/@modules/promotionalactivity/validity-period/interfaces/validity-period.interface';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { ICommercialActivityDocument } from './interfaces/commercial-activity-document.interface';
import { SolicitudeFileModel } from '@app/@modules/prices/solicitude/models/solicitude-file.model';
import { Util } from '@app/@shared/util';
import { SweetAlertResult } from 'sweetalert2';
import { FileBaseHelper } from '@app/@shared/helpers/file-base.helper';
import { CommercialActivityDocumentService } from './commercial-activity-document.service';
import { CommercialActivityDocumentRequiredModel } from './models/commercial-activity-document-required.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { ICommercialActivityDocumentRequired } from './interfaces/commercial-activity-document-required.interface';
import { CommercialActivityStatus } from '@app/@shared/enums/commercial-activity-status.enum';

@Component({
  selector: 'app-commercial-activity-document',
  templateUrl: './commercial-activity-document.component.html',
  styleUrls: ['./../../@shared/scss/responsive.table.scss'],
  styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }
    `],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommercialActivityDocumentComponent implements OnInit, OnChanges {

  @Input('title') title: string;
  @Input('routeCancel') routeCancel: string;
  @Input('showFields') showFields: ICommercialActivityDocument;
  @Input('promotionEdit') promotionEdit: CommercialActivityDocumentModel;
  @Input('promotionNew') promotionNew: CommercialActivityDocumentModel;
  @Input('commercialActivityTypeId') commercialActivityTypeId: number;
  @Input('commercialActivityId') commercialActivityId: number;
  @Input('isProcess') isProcess: number;
  @Input('isCreate') isCreate: boolean;
  @Input('showEditBtn') showEditBtn = true;
  @Input('observationEdit') observationEdit: CommercialActivityDocumentModel;
  @Output('eventClickSaveBtn') eventButton: EventEmitter<any> = new EventEmitter();
  @Output('eventClickEditBtn') eventButtonEdit: EventEmitter<any> = new EventEmitter();
  @Output('eventClickSendValidateBtn') eventButtonSendValidate: EventEmitter<any> = new EventEmitter();
  @Output('eventChangeData') eventChangeData: EventEmitter<any> = new EventEmitter();
  @Output('prepareDocumentForEvent')prepareFooterForEvent : EventEmitter<any> = new EventEmitter();
  @ViewChild('fileUpload') fileUpload: any;

  private readonly fb: FormBuilder;
  private readonly fileBaseHelper: FileBaseHelper;
  public formPromotion: FormGroup;
  public rangeDateSelectList: IDataRange[] = [];
  public objectCommercialActivityStatusList: ICombo[];
  public objectCommercialActivityStatus: any[] = [];
  public today: Date = new Date();
  public currentYear = 0;
  public endYear = 0;
  public invalidDates: Date[] = [];
  public commercialActivityEnum: any;
  public typeComercialActivityId = 0;
  public commercialActivityStatusEnum: any;
  public showText = false;
  public listValidityPeriod : IValidityPeriod[] = [];
  public language: string;
  public auxCont = 0;
  public addFecha = 0;
  public rolePermission: any = RolePermissionEnum;
  public auxBtn = true;
  public uploadedFiles: any[] = [];
  public downloadFile: SolicitudeFileModel;
  public nameFile = '';
  public isChangeActa:boolean = true;
  public searchMode:boolean = false;
  public attachedFilePdf: string = '';
  public PdfFileName: string = '';
  public documentService: CommercialActivityDocumentService;
  public activityDocumentRequired: CommercialActivityDocumentRequiredModel;
  public onInitExecuted: boolean = false;

  constructor(fb: FormBuilder, fileBaseHelper: FileBaseHelper, documentService: CommercialActivityDocumentService)
    {
      this.fb = fb;
      this.fileBaseHelper = fileBaseHelper;
      this.documentService = documentService;
    }

  ngOnInit(): void {
    this.commercialActivityEnum = this.getCommercialActivityEnum();
    if (this.isProcess == this.commercialActivityEnum.view)
    {
      this.searchMode = true;
      this.observations.disable();
    }
    else
    {
      this.searchMode = false;
    }

    if (!this.onInitExecuted)
    {
      this.initForm();
    }
  }

  getCommercialActivityEnumStatus(): typeof CommercialActivityStatus {
    return CommercialActivityStatus;
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
    return ComercialActivityEnum;
  }


  disableBtn(): boolean{
    if(this.isProcess === ComercialActivityEnum.view){
      return true;
    }else{
      return false;
    }
  }

  /**
   * @description This method is to prepare document model
   * @author Priscy Antonio Reales
   * @sprint 20
   * @returns
   */
  private prepareDocumentModel() :  CommercialActivityDocumentModel[]{
    let message = this.observations.value; 
    let file = this.downloadFile ? this.downloadFile.file : this.attachedFilePdf;
    let promotionDocuments: CommercialActivityDocumentModel[] = [];
    let id = this.downloadFile ? Number(this.downloadFile.id) : 0;
    let solicitudeId = this.downloadFile ? this.downloadFile.solicitudeId : 0;
    this.PdfFileName = this.downloadFile ? this.downloadFile.route : this.PdfFileName;

    promotionDocuments.push(
      new CommercialActivityDocumentModel(
        id,
        solicitudeId,
        this.PdfFileName ?? this.nameFile,
        file,
        message
      )      
    );
    return promotionDocuments;
  }


  getDocumentInformation() : CommercialActivityDocumentModel[] {
    return this.prepareDocumentModel();
  } 
  

  async setRequiredDocumentInformation(commercialActivityTypeId:number) {
    this.activityDocumentRequired = null;
    await this.documentService.getRequiredFields(commercialActivityTypeId).toPromise().then(
      (response) => {
        this.activityDocumentRequired = response.data;
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }
  
  
  getRequiredDocumentInformation(): CommercialActivityDocumentRequiredModel
  {
    return this.activityDocumentRequired;
  }
  

  /**
   * @description This method is to wait the event changes
   * in father promotion, and apply the changes in this view
   *
   * @param changes
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  ngOnChanges(changes: { [property: string]: SimpleChange }) {

    if (!this.onInitExecuted)
    {
      this.initForm();
      this.onInitExecuted = true;
    }
    
    const change: SimpleChange = changes['observationEdit'];

    if (change && change.currentValue !== null && change.currentValue != undefined) {
      this.observations.setValue(change.currentValue[0].message);

      if(change.currentValue[0].file == '' && this.isProcess == ComercialActivityEnum.update) return;
      this.downloadFile = new SolicitudeFileModel(
        change.currentValue[0].id,
        change.currentValue[0].commercialActivityId,
        change.currentValue[0].route,
        change.currentValue[0].file,
        "");
        this.nameFile = change.currentValue[0].route;
    }
  }

  /**
   * @description this method is to emit event the father.
   *
   * @param $event
   * @author Diego Mauricio Cortés
   * @sprint 8
   */
  eventClickSaveBtn($event: any): void {
  }

  /**
   * @description This method is to prepare data to edit promotion and emit info
   * to edit promotional Activity
   *
   * @param $event
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  eventClickEditBtn($event: any): void {
  }

  /**
   * @description This method is to init the form controls
   *
   * @author Diego Mauricio Cortés
   * @sprint 9
   */
  initForm(): void {
    this.formPromotion = this.fb.group({
      observations: [{ value: '', disabled: false }, []]
    });
  }


	async onUploadFile(event: any) {
		if (this.downloadFile) {
		  Util.confirmEditFile().then(async (result: SweetAlertResult) => {
			if (result.value) {
				this.isChangeActa = true;
			  this.downloadFile = null;
			  this.uploadFile(event);
			}
			else {
			  this.fileUpload.clear();
			  this.isChangeActa = false;
			}
		  });
		}
		else {
		  this.uploadFile(event);
		}
	}

	async uploadFile(event: any) {
		this.uploadedFiles = [];
		for (const file of event.files) {
		  this.uploadedFiles.push(file);
      this.PdfFileName = file.name;
		}
		this.attachedFilePdf = await this.fileBaseHelper.getFileBase(this.uploadedFiles[0]);
	  }

	onRemoveFileImage(): void {
	  this.uploadedFiles = [];
    this.PdfFileName = '';
    this.nameFile = '';
    this.attachedFilePdf = '';		
  }  

  fileDownload() {
    this.documentService.getFile(Number(this.commercialActivityId), this.nameFile).subscribe();
  }

  getNameFile():string
  {
    return this.nameFile;
  }

  get observations(): AbstractControl { return this.formPromotion.get('observations'); }
}
