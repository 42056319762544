import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudeService } from '@app/@modules/prices/solicitude/solicitude.service';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { Util } from '@app/@shared/util';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertResult } from 'sweetalert2';
import { TypeOfLoadExcelEnum } from './enums/type-of-load-excel.enum';
import { SolicitudeType } from '@app/@shared/enums/solicitude-type.enums';
import { ISolicitudeFileResponse } from './interfaces/solicitude-file-response.interface';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { CommercialActivitySubprocess } from '@app/@shared/enums/comercial-activity-subprocess.enums';

@Component({
  selector: 'app-upload-excel',
  templateUrl: './upload-excel.component.html',
  styles: [],
})
export class UploadExcelComponent implements OnInit {

  @Input() idSolicitude : number;
  @Input() codeCreation : string;
  @Input() typeOfLoad : TypeOfLoadExcelEnum;
  @Input() processType : number;
  @Input() sector : string[];
  @Input() isDisabled : boolean = false;

  @Output() eventEmitterId : EventEmitter<any> = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: FileUpload;

  public uploadedFiles: any[] = [];
  public typeOfLoadFileExcel : any = TypeOfLoadExcelEnum;
  public solicitudeType : any = SolicitudeType;

  private readonly solicitudeService: SolicitudeService;
  private readonly managePromotionalActivitiesService:ManagePromotionalActivitiesService;
  private readonly spinner: NgxSpinnerService;  
  private readonly router: Router;

  constructor(
    solicitudeService: SolicitudeService,
    managePromotionalActivitiesService: ManagePromotionalActivitiesService,
    spinner: NgxSpinnerService,
    router: Router
  ) {
    this.solicitudeService = solicitudeService;
    this.managePromotionalActivitiesService = managePromotionalActivitiesService;
    this.spinner = spinner;
    this.router = router;
  }

  ngOnInit() {
    if(this.typeOfLoad){
      if( this.typeOfLoad === TypeOfLoadExcelEnum.Solicitude )
      {
        if(!this.idSolicitude && (!this.codeCreation || this.codeCreation == '')){                  
          this.router.navigate(['auth/solicitude']);
          return;
        }
      }else if(this.typeOfLoad === TypeOfLoadExcelEnum.CommercialActivity){
        if(!this.typeOfLoad && !this.processType ){                  
          this.router.navigate(['auth/manage-promotional-activities']);
          return;
        }
      }      
    }
  }

  async onUploadFile(event: any) {
    this.uploadedFiles = [];
    for (const fileIndex of event.files) {
      this.uploadedFiles.push(fileIndex);
    }
    Util.confirmFileExcel().then(async (result: SweetAlertResult) => {
      if (result.value) {
        if (event.files.length === 0) {
          return;
        }
        let file : File = event.files[0];
        const formData = new FormData();
        formData.append('file', file, file.name)
        if(this.typeOfLoad){
          if(this.typeOfLoad === TypeOfLoadExcelEnum.Solicitude) await this.loadFileSolicitude(formData, this.processType);
          if(this.typeOfLoad === TypeOfLoadExcelEnum.CommercialActivity) await this.loadFilecommercialActivity(formData, this.processType, this.sector.join(','));

        }
      } else {
        this.uploadedFiles = [];
        this.fileUpload.clear();
      }
    });
  }

  async loadFileSolicitude(formData : FormData, solicitudeType : SolicitudeType){
    this.spinner.show();
    await this.solicitudeService
      .postUploadFileSolicutdeMaterial(formData, this.idSolicitude, this.codeCreation, solicitudeType)
      .toPromise()
      .then(
        (response) => {
          this.spinner.hide();
          var dataResponse = response.data;                
          if (response.status) {
            this.eventEmitterId.emit(dataResponse.id);
            this.dataDetailFail(dataResponse)           
          }
          else{
            if(dataResponse?.detailErrors!.length > 0)
              this.dataDetailFail(dataResponse)
            else
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
          }
        },
        (error) => {
          this.spinner.hide();
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, 
            language === 'es-ES' ? 'Error al cargar el archivo' : 'Error upload file');
        }
      );
  }

  async loadFilecommercialActivity(formData : FormData, processType : CommercialActivitySubprocess, sector : string){
    this.spinner.show();
    if(sector.length === 0){
      const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, 
            language === 'es-ES' ? 'El sector es requerido' : 'The sector is required');

            this.uploadedFiles = [];
            this.fileUpload.clear();
            this.spinner.hide();
            return;
    }
    await this.managePromotionalActivitiesService
      .postUploadFileCommercialActivityMaterial(formData, processType, sector)
      .toPromise()
      .then(
        (response) => {
          this.spinner.hide();
          var dataResponse = response.data;                
          if (response.status) {
            this.eventEmitterId.emit(dataResponse.detailValids); 
            this.dataDetailFail(dataResponse)         
          }
          else{
            if(dataResponse?.detailErrors!.length > 0)
              this.dataDetailFail(dataResponse)
            else
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, response.message)
          }
        },
        (error) => {
          this.spinner.hide();
          const language: string = sessionStorage.getItem('language');
          SweetAlertMessageHelpers.error(SweetAlert.titleAlertError, 
            language === 'es-ES' ? 'Error al cargar el archivo' : 'Error upload file');
        }
      );
  }

  dataDetailFail (dataResponse : ISolicitudeFileResponse) : void{
    if(dataResponse.detailErrors!.length > 0){
      const language: string = sessionStorage.getItem('language');
      let title = SweetAlert.titleWarningTranslate;                  
      let text = "The following Sku's were not loaded because they had an error during the loading process: \n\r ";
      if(language === 'es-ES'){
        title = SweetAlert.titleWarning;
        text = "Los siguientes Sku's no se cargaron porque tuvieron error durante el proceso de carga: \n\r";
      }
      text += dataResponse.detailErrors.slice(0,10).map(x => x.materialCode).join(',');
      if(dataResponse.detailErrors.length > 10) text += ' ...';
      
      SweetAlertMessageHelpers.confirmDownload(title,text)
      .then(async (result: SweetAlertResult) => {
        if (result.value) {
          let dataArrayString =  dataResponse.detailErrors.map( i => `${i.materialCode};${i.message}`);
          this.downloadFile(dataArrayString);
        }
      });
    }
  }

  downloadFile(data : string[]) {
    const csvArray = data.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}