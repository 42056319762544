import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { ManagePromotionalActivitiesMessage } from '@app/@modules/promotionalactivity/manage-promotional-activities/messages/manage-promotional-activities-messages.message';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { FilterMaterialResultModel } from '../models/filter-material-result.model';
import { FilterDataModel } from '../models/filters-data.model';
import { SwitchComponent } from '@app/@components/elements/switch/switch.component';
import { HandlingProductFilterService } from '@app/@components/handling-product-filter/handling-product-filter.service';
import { ComercialActivityEnum } from '@app/@shared/enums/comercial-activity.enums';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { Observable, Subject } from 'rxjs';
import { ISwitchHiddenButtons } from '@app/@components/elements/switch/interfaces/switch.interface';
import { SwitchHiddenButtonsModel } from '@app/@components/elements/switch/models/switch.model';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';

@Component({
  selector: 'app-filter-material-detail',
  templateUrl: './filter-material-detail.component.html',
  styleUrls: ['./../../../@shared/scss/responsive.table.scss'],
  styles: [
    `
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
      }
    `,
  ],
  providers: [MessageService],
})
export class FilterMaterialDetailComponent implements OnChanges, OnInit {

  @Input() filterObjectList: any;
  @Input() filterObjectHandlerList: any;
  @Input() isHandlerShow = false;
  @Input() commercialActivityHandlerisGroup = false;
  @Input() commercialActivityHandlerisClientWithoutSales = false;
  @Input() valueCalendar? : Observable<boolean>;
  @Input() isProcess : number;
  @Input('isCurrentMonth') isCurrentMonth: boolean;
  @Input('nameComponent') nameComponent: string;
  @Input('typeCoreMaster') typeCoreMaster: TypeCoreMaster;
  
  @ViewChild('appSwitch') appSwitch : SwitchComponent ;
  
  
  public rolePermission: any = RolePermissionEnum;
  public switchHiddenButtons : ISwitchHiddenButtons = new SwitchHiddenButtonsModel(true, false);
  public objectListMaterial: FilterMaterialResultModel[] = [];
  public selectMaterials: FilterMaterialResultModel[] = [];
  public commercialActivityHandlerDetails: FilterMaterialResultModel[] = [];
  public commercialActivityHandlerEditId : number;
  public isDisabledInput = false;
  public isDisabledInputSwitch = false;
  isValueSwitchSubjet: Subject<boolean> = new Subject<boolean>();
  
  private readonly spinner: NgxSpinnerService;
  private readonly managePromotionActService: ManagePromotionalActivitiesService;
  private readonly handlingProductFilterService: HandlingProductFilterService;
  private readonly messageService: MessageService;
  commercialActivityEnum: any;

  constructor(
    spinner: NgxSpinnerService,
    managePromotionActService: ManagePromotionalActivitiesService,
    handlingProductFilterService: HandlingProductFilterService,
    messageService: MessageService,
  ) {
    this.spinner = spinner;
    this.managePromotionActService = managePromotionActService;
    this.handlingProductFilterService = handlingProductFilterService;
    this.messageService = messageService;
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }): void {
    const changeFilterObjectList: SimpleChange = changes['filterObjectList'];
    const changeFilterObjectHanlderList: SimpleChange = changes['filterObjectHandlerList'];
    const commercialActivityHandlerisClientWithoutSales: SimpleChange = changes['commercialActivityHandlerisClientWithoutSales'];

    if(commercialActivityHandlerisClientWithoutSales){
      this.switchValue(commercialActivityHandlerisClientWithoutSales.currentValue)
    }

    if (changeFilterObjectList && changeFilterObjectList.currentValue != undefined) {
      this.eventClickFilters(changeFilterObjectList.currentValue);
    }
    if ( changeFilterObjectHanlderList && changeFilterObjectHanlderList.currentValue != undefined) {
      // this.eventClickFilters(changeFilterObjectList.currentValue);
      this.objectListMaterial = changeFilterObjectHanlderList.currentValue;
      
      if(this.isProcess == 2 &&  this.objectListMaterial.length > 0){
        if(this.objectListMaterial[0].commercialActivityHandlerId)
          this.commercialActivityHandlerEditId = this.objectListMaterial.find(x => {return x.commercialActivityHandlerId}).commercialActivityHandlerId;
        else if(this.objectListMaterial[0].commercialActivityHandlerCurrentMonthId)
          this.commercialActivityHandlerEditId = this.objectListMaterial.find(x => {return x.commercialActivityHandlerCurrentMonthId}).commercialActivityHandlerCurrentMonthId;
      }
    }
  }

  ngOnInit(): void {
    this.setObservableCalendar();
  }

  public getCommercialActivityEnum(): typeof ComercialActivityEnum {
		return ComercialActivityEnum;
	}

  eventClickFilters($event: any): void {
    if($event.length === 0) return;
    if ($event.advancedFiltersObjectList.length > 0) {
      this.spinner.show();
      const newFilterModel = new FilterDataModel(
        JSON.stringify($event.advancedFiltersObjectList),
        environment.conditionTable,
        environment.conditionClassCode,
        false
      );
      this.getMaterialsByFilters(newFilterModel);
    } else {
      const language: string = sessionStorage.getItem('language');
      SweetAlertMessageHelpers.error(
        SweetAlert.titleAlertError,
        language === 'es-ES'
          ? ManagePromotionalActivitiesMessage.NOT_FILTERS
          : ManagePromotionalActivitiesMessage.NOT_FILTERS_TRANSLATE
      );
    }
    this.spinner.hide();
  }

  private getMaterialsByFilters(filters: FilterDataModel): void {
    this.selectMaterials = [];
    this.spinner.show();
    this.managePromotionActService
      .postAdvancedFiltersMaterials(filters)
      .subscribe(
        (response) => {
          if (response.data.length > 0) {
            const language: string = sessionStorage.getItem('language');
            this.showToast(
              SweetAlert.iconSuccess,
              language === 'es-ES'
                ? SweetAlert.titleAlert
                : SweetAlert.titleAlertTranslate,
              language === 'es-ES'
                ? `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS} ${response.data.length}`
                : `${ManagePromotionalActivitiesMessage.TOTAL_FILTERS_TRANSLATE} ${response.data.length}`
            );

            let objectListMaterialCopy = [...this.objectListMaterial];

            if(this.objectListMaterial.length > 0) {
              let responseDataListMaterial = response.data.map((item: any) => {
                return {
                  id: 0,
                  commercialActivityHandlerId: this.commercialActivityHandlerEditId ? this.commercialActivityHandlerEditId : 0,
                  materialCode: item.materialCode,
                  materialDescription: item.product,
                  zofeCode: item.zofeCode,
                  zofeDescription: item.zofeDescription,
                };
              });

         
              
              // Si encuentra el mismo material no lo coloca
              let resultListMaterial = objectListMaterialCopy.filter(x => !responseDataListMaterial.some((y : any) => y.materialCode === x.materialCode))
                .concat(responseDataListMaterial.filter((x : any) => !objectListMaterialCopy.some(y => y.materialCode === x.materialCode)));

              
                // resultListMaterial = resultListMaterial.map(x => ({...x, id: 0}));

                this.objectListMaterial = [];
                this.objectListMaterial =  objectListMaterialCopy.concat(resultListMaterial);

            }else {
              this.objectListMaterial = response.data.map((item: any) => {
                return {
                  id: 0,
                  commercialActivityHandlerId: this.commercialActivityHandlerEditId ? this.commercialActivityHandlerEditId : 0,
                  materialCode: item.materialCode,
                  materialDescription: item.product,
                  zofeCode: item.zofeCode,
                  zofeDescription: item.zofeDescription,
                };
              });
              this.switchValue(this.commercialActivityHandlerisClientWithoutSales)
            }
          } else {
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(
              SweetAlert.titleAlertError,
              language === 'es-ES'
                ? ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT
                : ManagePromotionalActivitiesMessage.NOT_FILTERS_RESULT_TRANSLATE
            );
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.error(error);
        }
      );
  }

  async deleteSelected(){
    Util.confirmDelete().then(async (result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        const auxProducts = [...this.selectMaterials];

        // Ensayar
        if (this.isProcess === this.commercialActivityEnum.update) {
          const ids = auxProducts.map((itm) => {
            return itm.id;
          });
          const responseDelete = await this.handlingProductFilterService.deleteProductMaterialHandler(ids.join(','), this.typeCoreMaster).toPromise();
        }

        auxProducts.forEach((element) => {
          const indexFound = this.objectListMaterial.findIndex(
            (elm) => elm.materialCode === element.materialCode
          );
          const indexFoundSelect = this.selectMaterials.findIndex(
            (elm) => elm.materialCode === element.materialCode
          );
          if (indexFound !== -1) {
            this.objectListMaterial.splice(indexFound, 1);
            this.selectMaterials.splice(indexFoundSelect, 1);
          }
        });
        this.selectMaterials = [...this.selectMaterials];
        this.spinner.hide();
      }
    });
  }

  deleteSelectOnly(object: FilterMaterialResultModel): void {
    Util.confirmDelete().then(async (result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        
        if (this.isProcess === this.commercialActivityEnum.update) {
          const responseDelete = await this.handlingProductFilterService.deleteProductMaterialHandler(String(object.id), this.typeCoreMaster).toPromise();
        }
        
        const index = this.objectListMaterial.findIndex(
          (elm) => elm.materialCode === object.materialCode
        );
        if (index !== -1) {
          this.objectListMaterial.splice(index, 1);
        }
        this.spinner.hide();
      }
    });
  }

  getValueSwitch() : boolean {
    return this.appSwitch.getValue();
  }

  getValueSwitchCustomer() : boolean {
    return this.commercialActivityHandlerisClientWithoutSales;
  }

  changeRankStart(event : any, object : FilterMaterialResultModel){
    if(Number(event.target.value) && object.rankEnd == null){
      object.rankStart = Number(event.target.value);
    }
    else if(Number(event.target.value) > object.rankEnd){
      const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconError,
              language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
              language === 'es-ES' ? SweetAlert.messageRankLower : SweetAlert.messageRankLowerTranslate);
      event.target.value = '';
      object.rankStart = event.target.value;
      return;
    } 
    object.rankStart = Number(event.target.value);
  }

  changeRankEnd(event : any, object : FilterMaterialResultModel){
    if(event.target.value == ''){
      return;
    } else if(Number(event.target.value) < object.rankStart){
      const language: string = sessionStorage.getItem('language');
            this.showToast(SweetAlert.iconError,
              language === 'es-ES' ? SweetAlert.titleAlertError : SweetAlert.titleAlertErrorTranslate,
              language === 'es-ES' ? SweetAlert.messageRankUpper : SweetAlert.messageRankUpperTranslate);
      object.rankEnd = null;
      return;
    } 

    object.rankEnd = event.target.value == '' ? null : Number(event.target.value);
  }


  getMaterialList() : string []{
    return this.objectListMaterial.map((item) => { return item.materialCode} );
  }

  getMaterialListHandler() : FilterMaterialResultModel []{
     
        return this.objectListMaterial.map((item) => 
        { 
          return {
          commercialActivityHandlerId : item.commercialActivityHandlerId != 0 ? item.commercialActivityHandlerId : 0,
          materialCode : item.materialCode,
          rankStart : item.rankStart,
          rankEnd : item.rankEnd,
          id: item.id != 0 ? item.id : 0,
          materialDescription: item.materialDescription,
          zofeCode: item.zofeCode,
          zofeDescription: item.zofeDescription,
          }
        });
  }
  

  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  initializeForm() {
    this.objectListMaterial = [];
    this.selectMaterials = [];
  }

  switchValue(event : any){
    this.commercialActivityHandlerisClientWithoutSales = event;
    this.isDisabledInput = event ? true : false;
    if(this.isDisabledInput && this.objectListMaterial.length > 0){
      this.objectListMaterial = this.objectListMaterial.map(item => ({
        ...item,
        rankStart: 0,
        rankEnd: null
      }));
    }else{
      this.objectListMaterial = this.objectListMaterial.map(({rankStart, ...restoDelObjeto}) => restoDelObjeto);
    }
  }

  setObservableCalendar(){
    this.commercialActivityEnum = this.getCommercialActivityEnum();
    this.valueCalendar?.subscribe((apiRequest) => {
      if(apiRequest){
        this.commercialActivityHandlerisGroup = !apiRequest;
        this.isValueSwitchSubjet.next(!apiRequest);
        this.isDisabledInputSwitch = apiRequest;
      }else {
        this.isDisabledInputSwitch = apiRequest;
      }
    });

  }

}
