import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { Observable } from 'rxjs';
import { CommercialActivityDiscountModel } from './models/commercial-activity-discount.model';
import { environment } from '@env/environment';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommercialActivityDiscountService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
   }


  public postPromotionalActivity(body: CommercialActivityDiscountModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
    this.httpService
    .post(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/associate_commercial_activity_with_detail/`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public getPromotionalActivityById(id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/commercial_activity_by_id/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public putPromotionalActivity(body: CommercialActivityDiscountModel, id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .put(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/associate_commercial_activity_with_detail/${id}`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public deleteProductGroupsList(id: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .delete(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/purchase_bases/logic/${id}`)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

}
