import { IAdvancedFiltersMaterial } from '../interfaces/advanced-filters-material.interface';

export class AdvancedFiltersMaterialModel implements IAdvancedFiltersMaterial {

  /**
   * @author Elkin Vasquez Isenia
   * @sprint 1
   * @param filters
   * @param conditionTable
   * @param conditionClassCode
   * @param orderBy
   * @param ascendent
   * @param pageNumber
   * @param resultsPerPage
   */
  constructor(
    public filters: string,
    public conditionTable: string,
    public conditionClassCode: string,
    public orderBy: string,
    public ascendent: boolean,
    public pageNumber: number,
    public resultsPerPage: number,
  ) { }
}
