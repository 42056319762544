import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ManagePromotionalActivitiesMessage {

  public static readonly TITLE = 'Gestión de actividad comercial';
  public static readonly TOTAL_FILTERS = 'Total de materiales: ';
  public static readonly TOTAL_FILTERS_TRANSLATE = '-Total Materials: ';
  public static readonly NOT_FILTERS = 'Debe agregar un tipo de filtro a consultar es requerido';
  public static readonly NOT_FILTERS_TRANSLATE = 'You must add a type of filter to query is required';
  public static readonly NOT_FILTERS_RESULT = 'No existe materiales para este tipo de filtro';
  public static readonly NOT_FILTERS_RESULT_TRANSLATE = 'There are no materials for this type of filter';
  public static readonly NOT_MATERIAL_REDEEMABLE = 'No existen productos canjeables';
  public static readonly NOT_MATERIAL_REDEEMABLE_TRANSLATE = 'There are no redeemable products';
  public static readonly NOT_EXISTS_COMMERCIAL_ACTIVITY = 'No se puede ingresar a esta actividad comercial';
  public static readonly NOT_EXISTS_COMMERCIAL_ACTIVITY_TRANSLATE = 'You cannot enter this commercial activity';
  public static readonly OK = 'OK';
  public static readonly CALCULATE_PROCESS_STARTED = 'Se ha iniciado el proceso de cálculos correctamente';
  public static readonly CALCULATE_PROCESS_STARTED_TRANSLATE = 'The calculation process has been started successfully';
  public static readonly CALCULATE_PROCESS_OK = 'Se ha realizado el proceso de calculos correctamente';
  public static readonly CALCULATE_PROCESS_OK_TRANSLATE = 'The calculation process has been carried out correctly';
  public static readonly INTERRUPT_OK = 'Se ha realizado la programación para interrumpir la actividad comercial en la fecha seleccionada';
  public static readonly INTERRUPT_OK_TRANSLATE = 'The schedule has been made to interrupt the commercial activity on the selected date';
  public static readonly DATARANGEMESSAGE = 'No se puede cambiar de estado porque no hay rango de fechas para la actividad';
  public static readonly DATARANGEMESSAGE_TRANSLATE = 'Cannot change status because there is no date range for the activity';
  public static readonly NO_DOCUMENT_OBSERVATION = 'El campo observaciones es requerido para este tipo de actividad comercial';
  public static readonly NO_DOCUMENT_OBSERVATION_TRANSLATE = 'Observation field is required for activity type';
  public static readonly NO_DOCUMENT_FILE = 'El documento soporte es requerido para este tipo de actividad comercial';
  public static readonly NO_DOCUMENT_FILE_TRANSLATE = 'Support Document is required for this activity type';
  public static readonly NO_DOCUMENT_OBSERVATION_AND_FILE_TRANSLATE = 'The observations fields and the supporting document are not required, when both are required';
  public static readonly NO_DOCUMENT_OBSERVATION_AND_FILE = 'Los campos observaciones y documento soporte son obligatorios';
  public static readonly CSV_CLIENTS_SOLICITUDE_ASSIGNED = 'Clientes asignados a la solicitud.csv';
  public static readonly CSV_CLIENTS_SOLICITUDE_ASSIGNED_TRANSLATE = 'Clients assigned to solicitude.csv';
  constructor() { }
}