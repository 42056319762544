import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SolicitudeService } from '../../solicitude.service';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { SolicitudeStatus } from '@app/@shared/enums/solicitude-status.enums';
import { SolicitudeCreationModel } from '../../models/solicitude-creation.model';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SolicitudeMessage } from '../../messages/solicitude.message';
import { IFieldsClientFilter } from '@app/@components/assing-clients/interfaces/show-fields-client-filter.interface';
import { FieldsClientFilterModel } from '@app/@components/assing-clients/models/show-fields-client-filter.model';
import { Util } from '@app/@shared/util';
import { PriceCertificateComponent } from '@app/@components/price-certificate/price-certificate.component';
import { SolicitudeFileModel } from '../../models/solicitude-file.model';
import { SweetAlertResult } from 'sweetalert2';
import { SolicitudeStatusModel } from '../../models/solicitude-status.model';
import { NotificationInformativeModel } from '@app/@shared/model/notification-informative.model';
import { NotificationType } from '@app/@shared/enums/notification-type.enum';
import { ApplicationConstants } from '@app/app.constants';
import { MessageService } from 'primeng/api';
import { Module } from '@app/@shared/enums/module.enums';
import { HelperService } from '@app/@shared/services/helper.service';

@Component({
  selector: 'app-pending-differential',
  templateUrl: './pending-differential.component.html',
  styleUrls: ['./pending-differential.component.css'],
  providers: [MessageService],
})
export class PendingDifferentialComponent implements OnInit {
  
  @ViewChild('priceCertificate') priceCertificate: PriceCertificateComponent;

  private readonly spinner: NgxSpinnerService;
  private readonly solicitudeService: SolicitudeService;
  private readonly router: Router;
  private readonly messageService: MessageService;
  private readonly helperService: HelperService;

  public showFieldClientFilter : IFieldsClientFilter = new FieldsClientFilterModel(false, false, false);
  public solicitudId: number;
  public isProcess : number ;
  public objectListSolicitude: SolicitudeCreationModel[];
  public rolePermission: any = RolePermissionEnum;
  public retry: number;
  public filtersEdit : string = '';
  public clientsEdit : any;
  public solicitudeStatus: SolicitudeStatus;

  public modalUser: boolean = false;
  public objectListClient : any = [];
  public CSVFile : string [] = [];
  public totalRecordsDownload = 0;
  

  constructor(
    solicitudeService: SolicitudeService,
    spinner: NgxSpinnerService,
    router: Router,
    helperService: HelperService
  ) { 
    this.solicitudeService = solicitudeService;
    this.spinner = spinner;
    this.router = router;
    this.helperService = helperService;
  }

  ngOnInit(): void {
    this.solicitudId = this.solicitudeService.getSolicitudeId();
    this.isProcess = this.solicitudeService.getIsCreate();
    this.getSolicitudeById();
  }

  getSolicitudeById(): void {
    this.spinner.show();

    if (!this.solicitudId) {
      this.spinner.hide();
      this.back();
      return;
    }

    this.solicitudeService.getSolicitudeWithDetailById(this.solicitudId).subscribe(
      (response) => {
        if (response.status) {
          if(response.data.status === SolicitudeStatus.InProgress){
            if(!response.data.solicitudeDetailJson){
              const language: string = sessionStorage.getItem('language');
              SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
                language === 'es-ES' ? SolicitudeMessage.NOT_SKU : SolicitudeMessage.NOT_SKU_TRANSLATE);
              this.router.navigate(['auth/pending-solicitude']);
            }
            this.retry = response.data.retry;
            if (response.data.filterClientJson) {
              this.filtersEdit = response.data.filterClientJson;
            } else if (response.data.solicitudeCustomerJson) {
              this.clientsEdit = response.data.solicitudeCustomerJson;
            } 
            
            this.objectListSolicitude = response.data.solicitudeDetailJson.map(
              (item) => {
                return {
                  id: item.detailId,
                  solicitudeId: response.data.id,
                  materialCode: item.sku,
                  product: item.materialDescription,
                  currentListPrice: item.currentListPrice,
                  currentPsp: item.currentPsp,
                  newPriceWithOutTax: item.newPriceWithOutTax,
                  priceAdjustment: item.priceAdjustment,
                  newPsp: item.newPsp,
                  effectiveDateStart: new Date(item.effectiveDateStart),
                  effectiveDateEnd: new Date(item.effectiveDateEnd),
                  zofeCode: item.zofeCode,
                  zofeDescription: item.zofeDescription,
                  filterClient: item.filterClient,
                  csvClients: item.csvClients
                };
              }
            );

            this.objectListSolicitude.forEach((x) => {
              if(x.filterClient){
                let description = JSON.parse(x.filterClient);
                if(description.length > 1){
                  x.tooltipClient = description[0].contentDescription + '/' + description[1].contentDescription;
                }else if(description.length === 1){
                  x.tooltipClient = description[0].contentDescription;
                }
              }
              if(x.csvClients){
                x.tooltipClient = 'Archivo CSV';
              }
            });

            if (response.data.solicitudeFileJson) {
              this.priceCertificate.downloadFile = new SolicitudeFileModel(
                response.data.solicitudeFileJson[0].id,
                response.data.solicitudeFileJson[0].solicitudeId,
                response.data.solicitudeFileJson[0].route,
                '',
                response.data.solicitudeFileJson[0].hash,
              );
              this.priceCertificate.nameFile = this.priceCertificate.downloadFile.route;
              this.priceCertificate.solicitudeId = this.solicitudId;
            }
            
          }else{
            const language: string = sessionStorage.getItem('language');
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,
              language === 'es-ES' ? SolicitudeMessage.NOT_STATUS_PENDING
              : SolicitudeMessage.NOT_STATUS_PENDING_TRANSLATE);
            this.router.navigate(['auth/pending-solicitude/']);
          }
        } else {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      },
      (error) => {
        this.spinner.hide();
        SweetAlertMessageHelpers.exception(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  accept(): void {
    this.solicitudeStatus = SolicitudeStatus.InRecord;
    Util.confirmApprove().then((result: SweetAlertResult) => {
      if (result.value) {
        this.save();
      }
    });
  }

  reject(): void {
    this.solicitudeStatus = SolicitudeStatus.Rejected;
    Util.confirmRejection().then((result: SweetAlertResult) => {
      if (result.value) {
        this.save();
      }
    });
  }


  back(): void {
    this.router.navigate(['auth/pending-solicitude']);
  }

  public save(): void {
    this.spinner.show();
      const statusModel: SolicitudeStatusModel = new SolicitudeStatusModel(
        this.solicitudeService.getSolicitudeId(),
        this.solicitudeStatus,
        ''
      );
      this.solicitudeService.putSolicitudeStatus(this.solicitudeService.getSolicitudeId(), statusModel)
      .subscribe(
        (response) => {
          if (response.status) {

            const modelNotification : NotificationInformativeModel = new NotificationInformativeModel(
              Module.Price,
              this.solicitudeStatus === SolicitudeStatus.Rejected  ? NotificationType.System : NotificationType.Informative,
              this.solicitudeService.getSolicitudeId()
            );
            this.solicitudeService.putSolicitudeNotificationInformative(modelNotification)
            .subscribe(
              (response)=>{
                this.spinner.hide();
                this.back();
              },
              (error)=>{
                this.spinner.hide();
                this.back();
              }
            );
            const language: string = sessionStorage.getItem('language');
            if(language === 'es-ES'){
              this.showToast(SweetAlert.iconSuccess,SweetAlert.titleAlert,this.solicitudeStatus === SolicitudeStatus.InRecord 
                ? SweetAlert.messageAlertAcceptSolicitude : SweetAlert.messageAlertRejectSolicitude);
            }else{
              this.showToast(SweetAlert.iconSuccess,SweetAlert.titleAlertTranslate,this.solicitudeStatus === SolicitudeStatus.InRecord 
                ? SweetAlert.messageAlertAcceptSolicitudeTranslate : SweetAlert.messageAlertRejectSolicitudeTranslate);
            }
          } else {
            this.spinner.hide();
            SweetAlertMessageHelpers.error(SweetAlert.titleAlertError,response.message);
          }
        },
        (error) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.exception(error);
        }
      );
  }

  users(object: any){
    this.modalUser = true;
    this.objectListClient = [];
    this.CSVFile = [];
    if(object.filterClient){
      this.objectListClient = JSON.parse(object.filterClient);
    }
    if(object.csvClients){
      this.CSVFile = object.csvClients;
      this.totalRecordsDownload = object.csvClients.length;
    }
  }

  fileDownload() {
    const csv = typeof this.CSVFile[0] === 'string' ? this.CSVFile : this.CSVFile.map((row: any) => JSON.stringify(row.clientCode));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  cancelUser(){
    this.modalUser = false;
    this.objectListClient = [];
    this.CSVFile = [];
  }

  showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: severity,
      summary: message,
      detail: detail,
      life: ApplicationConstants.CONSTANTS.lifeTimeToast as number,
    });
  }

  materialCodeZeroLess(value: string): string{
    return this.helperService.materialCodeZeroLess(value);
  }

}
