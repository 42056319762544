import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RouteGuard } from '../../@core/authentication/route.guard';
import { CreationSolicitudeComponent } from './solicitude/creation-solicitude/creation-solicitude.component';
import { DetailCreationComponent } from './solicitude/detail-solicitude/detail-creation/detail-creation.component';
import { DetailDifferentialComponent } from './solicitude/detail-solicitude/detail-differential/detail-differential.component';
import { DetailWithdrawalComponent } from './solicitude/detail-solicitude/detail-withdrawal/detail-withdrawal.component';
import { ImpactAnalysisComponent } from './solicitude/pending-solicitude/pending-creation/impact-analysis/impact-analysis.component';
import { PendingCreationComponent } from './solicitude/pending-solicitude/pending-creation/pending-creation.component';
import { PendingDifferentialComponent } from './solicitude/pending-solicitude/pending-differential/pending-differential.component';
import { PendingSolicitudeComponent } from './solicitude/pending-solicitude/pending-solicitude.component';
import { PendingWithdrawalComponent } from './solicitude/pending-solicitude/pending-withdrawal/pending-withdrawal.component';
import { RemoveDifferentialComponent } from './solicitude/remove-differential/remove-differential.component';
import { RequestsWithNoveltyComponent } from './solicitude/requests-with-novelty/requests-with-novelty.component';
import { SolicitudeComponent } from './solicitude/solicitude.component';
import { WithdrawalSolicitudeComponent } from './solicitude/withdrawal-solicitude/withdrawal-solicitude.component';

const routes: Routes = [
  { path: 'solicitude', component: SolicitudeComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'solicitude/creation', component: CreationSolicitudeComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'solicitude/withdrawal', component: WithdrawalSolicitudeComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'pending-solicitude', component: PendingSolicitudeComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'pending-solicitude/creation', component: PendingCreationComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'pending-solicitude/withdrawal', component: PendingWithdrawalComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'pending-solicitude/differential', component: PendingDifferentialComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'pending-solicitude/creation/impact-analysis', component: ImpactAnalysisComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'solicitude/detail-solicitude/detail-creation', component: DetailCreationComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'solicitude/detail-solicitude/detail-withdrawal', component: DetailWithdrawalComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'solicitude/detail-solicitude/detail-differential', component: DetailDifferentialComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'solicitude/request-with-novelty', component: RequestsWithNoveltyComponent, canActivate: [MsalGuard, RouteGuard] },
      { path: 'solicitude/remove-differential', component: RemoveDifferentialComponent, canActivate: [MsalGuard, RouteGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricesRoutingModule { }
