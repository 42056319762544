<p-table #dt
          name="gridSolicitude"
          dataKey="descGroup"
          [paginator]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10,25,50]"
          paginatorPosition="bottom"
          styleClass="p-datatable-customers"
          [value]="valueTable"
          [(selection)]="selectedRows">

          <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem"></th>

                <th role="columnheader" style="width: 3rem"></th>

                <th *ngFor="let hr of this.headersTable" pSortableColumn="{{hr.attr}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{hr.name | translate}}
                        <p-sortIcon field="{{hr.attr}}"></p-sortIcon>
                    </div>
                </th>

                <th>{{ "Actions" | translate }}</th>

                <th role="columnheader" style="width: 4rem"></th>
                <th style="width: 4rem"></th>
              </tr>
          </ng-template>

          <ng-template pTemplate="body"
                      let-object
                      let-expanded="expanded"
                      let-columns="columns">
            <tr  style="height: 40px !important;">

                <td>
                    <button type="button"
                      pButton
                      pRipple
                      [pRowToggler]="object"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>

                <td>
                    <p-checkbox (onChange)="selectGroup(e.checked,object)"
                                [(ngModel)]="object.check"
                                binary="true"
                                #e
                                [disabled]="isProcess === processEnum.view"></p-checkbox>
                </td>

                <td *ngFor="let hr of headersTable">
                    <div *ngIf="hr?.percentage">
                      <input
                          [(ngModel)]="object[hr.attr]"
                          [disabled]="(hr?.enable? hr?.enable(object,hr?.depend) : false) ||
                          (isProcess === processEnum.view)"
                          (keydown)="checkRange($event,object[hr.attr],hr?.props?.max)"
                          type="hr?.props?.type ? hr?.props?.type:'text'"
                          min="0"  max="hr?.props?.max ? hr?.props?.max:'100'" step="1"
                          [ngClass]="{'ng-invalid ng-dirty':myPercentage.touched &&
                          (object[hr.attr]=='' || object[hr.attr]==null)}"
                          (ngModelChange)="hr?.onChange ? hr?.onChange(object[hr.attr],object,hr.depend,hr.attr): null"
                          #myPercentage="ngModel"
                          [ngStyle]="{width:'80px'}"
                      />
                      <div *ngIf="myPercentage.touched && (object[hr.attr]=='' || object[hr.attr]==null)"
                          [ngStyle]="{color: 'red', 'font-size': '16px'}">Campo obligatorio</div>
                    </div>
                    <div *ngIf="hr?.input">
                        <input type="text"
                              [(ngModel)]="object[hr.attr]"
                              [disabled]="(hr?.enable ? hr?.enable(object,hr?.depend) : false) ||
                              (isProcess === processEnum.view)"
                              (keydown)="checkTNumber($event)"
                              [ngClass]="{'ng-invalid ng-dirty':myValue.touched &&
                              (object[hr.attr]=='' || object[hr.attr]==null)}"
                              (ngModelChange)="hr?.onChange ?
                              hr?.onChange(object[hr.attr],object,hr.depend,hr.attr):null"
                              #myValue="ngModel"
                        />
                        <div *ngIf="myValue.touched && (object[hr.attr]=='' || object[hr.attr]==null)"
                        [ngStyle]="{color: 'red', 'font-size': '16px'}">Campo obligatorio</div>
                    </div>
                    <div *ngIf="hr?.select">
                        <p-dropdown [disabled]="isProcess === processEnum.view"
                                    [filter]="true"
                                    [showClear]="true"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="{{ 'ManagePromotionalActivitiesComponent.TypeActivityPlaceHolderCombo'
                                    | translate }}"
                                    [ngClass]="{'ng-invalid ng-dirty':myText.touched &&
                                    (object[hr.attr]=='' || object[hr.attr]==null)}"
                                    [options]="hr?.options"
                                    [(ngModel)]="object[hr.attr]"
                                    [style]="{ width: '100%' }"
                                    #myText="ngModel"
                                    (onChange)="onSearchLead(object[hr.attr],hr?.options)">
                        </p-dropdown>
                        <div *ngIf="myText.touched && (object[hr.attr]=='' || object[hr.attr]==null)">
                          Campo obligatorio
                        </div>
                    </div>
                    <span *ngIf="!hr?.input && !hr?.select && !hr?.percentage">{{object[hr.attr]}}</span>
                </td>

                <td>
                    <i id="btnDelete" pTooltip="{{'Delete' | translate}}"
                    class="uil uil-trash-alt eliminar" (click)="deleteGroupAction(object)" 
                    aria-hidden="true" 
                    *ngIf="isProcess !== processEnum.view && (rolePermission.delete | rolePermission)"></i>
                </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="rowexpansion"
            let-object>
            <tr>
              <td [attr.colspan] = "subHeaderTable.length>3 ? '7' : '8'">
                <div class="p-p-1">
                  <p-table
                    [value]="object.subOptions"
                    [scrollable]="true"
                    scrollHeight="300px"
                    dataKey="descSku">
                    [(selection)]="selectedSubOptions"

                    <ng-template pTemplate="header">
                      <tr>
                        <th role="columnheader" style="width: 2rem">
                          <p-checkbox #a (onChange)="selectAll($event,object)"></p-checkbox>
                        </th>
                        <th *ngFor="let hr of this.subHeaderTable">
                          {{hr.name | translate}}
                        </th>

                      </tr>
                    </ng-template>

                    <ng-template
                      pTemplate="body"
                      let-dataRange>

                      <tr  style="height: 40px !important;">
                        <td role="columnheader" style="width: 2rem"></td>
                        <td style="width: 3rem">
                          <p-checkbox (onChange)="selectRow(e.checked,dataRange)"
                                      [(ngModel)]="dataRange.check"
                                      
                                      binary="true" #e
                                      [disabled]="isProcess === processEnum.view" binary="true" ></p-checkbox>
                                      <!-- <p-tableCheckbox #e [value]="dataRange" 
                                        (click)="selectRow(e.checked,dataRange)"></p-tableCheckbox>           -->
                        </td>
                        <td *ngFor="let hr of subHeaderTable">
                          <div *ngIf="hr?.percentage">
                            <input #e 
                              [ngModel]="dataRange[hr.attr] | number:'0.2': 'EN-US'" pInputText
                              [disabled]="(hr?.enable? hr?.enable(dataRange,hr?.depend) : false) 
                              || (isProcess === processEnum.view)"
                              onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) 
                              || event.charCode == 46)"
                              type="{{hr?.props?.type}}"
                              [ngClass]="{'ng-invalid ng-dirty':myPercentage.touched 
                                && (dataRange[hr.attr]=='' || dataRange[hr.attr]==null)}"
                              (ngModelChange)="hr?.onChange ? hr?.onChange(dataRange[hr.attr],dataRange,hr.depend,hr.attr): null"
                              #myPercentage="ngModel"
                              step="0.01"
                              [ngStyle]="{width:'110px'}"
                              ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/"
                              (focusout)="changeValue($event,dataRange)"
                              [textMask]="{ mask: priceMask }"                      
                            />
                          </div>
                            <div *ngIf="hr?.input">
                                <input
                                type="{{hr?.props?.type}}"
                                onkeypress="return ((event.charCode >= 48 && event.charCode <= 57))"
                                pInputText
                                [(ngModel)]="dataRange[hr.attr]"
                                [disabled]="(hr?.enable ?
                                hr?.enable(dataRange,hr?.depend) : false) || (isProcess === processEnum.view)"
                                (keydown)="checkTNumber($event)"
                                [ngClass]="{'ng-invalid ng-dirty':myValue.touched &&
                                (dataRange[hr.attr]=='' || dataRange[hr.attr]==null)}"
                                (ngModelChange)="hr?.onChange ?
                                hr?.onChange(dataRange[hr.attr],dataRange,hr.depend,hr.attr):null"
                                #myValue="ngModel"
                                (keyup)="keyupMaxNumber($event, object)"
                                [max]="9999"
                                />
                                <div
                                *ngIf="myValue.touched && (dataRange[hr.attr]=='' || dataRange[hr.attr]==null)"
                                [ngStyle]="{color: 'red', 'font-size': '16px'}">Campo obligatorio</div>
                            </div>
                            <div *ngIf="hr?.select">
                                <p-dropdown [disabled]="isProcess === processEnum.view"
                                            [filter]="true"
                                            [showClear]="true"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="{{ 'ManagePromotionalActivitiesComponent.TypeActivityPlaceHolderCombo'
                                            | translate }}"
                                            [ngClass]="{'ng-invalid ng-dirty':myText.touched &&
                                            (dataRange[hr.attr]=='' || dataRange[hr.attr]==null)}"
                                            [options]="hr?.options"
                                            [(ngModel)]="dataRange[hr.attr]"
                                            [style]="{ width: '100%' }"
                                            #myText="ngModel"
                                            (onChange)="onSearchLead(dataRange[hr.attr],hr?.options)"></p-dropdown>
                                <div
                                *ngIf="myText.touched && (dataRange[hr.attr]=='' || dataRange[hr.attr]==null)"
                                [ngStyle]="{color: 'red', 'font-size': '16px'}">Campo obligatorio</div>
                            </div>
                            <span *ngIf="!hr?.input && !hr?.select && !hr?.percentage">{{dataRange[hr.attr]}}</span>
                        </td>
                    </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="1">
                          {{ 'ElementsNotFound' | translate }}
                        </td>
                      </tr>
                    </ng-template>

                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">{{ 'ElementsNotFound' | translate }}</td>
            </tr>
        </ng-template>
        </p-table>
