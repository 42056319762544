export enum CommercialActivityStatus {
  Registered = 45,
  Pending = 46,
  Rejected = 47,
  ScheduledToInterrupt = 48,
  Valid = 49,
  Finalized = 50,
  Interrupt = 51,
  Removed = 52,
  InValidation = 105,
  Validated = 106,
  Invalid = 107,
  OnApprobation = 128,
  Planned = 129
}

export enum CommercialActivityApprovalStatus {
	WithoutChecking = 121,
	Rejection = 122,
	OK = 123
}
