import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlert } from '@app/@shared/enums/sweetalert.enum';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { DomainService } from '@app/@shared/services/domain.service';
import { Util } from '@app/@shared/util';
import { ApplicationConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { SweetAlertResult } from 'sweetalert2';
import { ExclusionsService } from '../exclusions.service';
import { RolePermissionEnum } from '@app/@shared/enums/role-permission.enum';

@Component({
  selector: 'app-exclusions-manage',
  templateUrl: './exclusions-manage.component.html',
  styleUrls: ['./../../../../../app/@shared/scss/responsive.table.scss'],
  styles: [
    `
    .generalData .options{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
      @media screen and (max-width: 960px) {
        :host
          ::ng-deep
          .p-datatable.p-datatable-customers.rowexpand-table
          .p-datatable-tbody
          > tr
          > td:nth-child(6) {
          display: flex;
        }
        :host ::ng-deep .p-dialog {
          min-width: 6rem;
        }
      }
    `,
  ],
  providers: [MessageService]
})
export class ExclusionsManageComponent implements OnInit {
  private readonly spinner: NgxSpinnerService;
  private readonly exclusionService: ExclusionsService;
  private readonly router: Router;
  private readonly messageService: MessageService;
  private readonly primengConfig: PrimeNGConfig;
  private readonly domainService: DomainService;

  public exclusionsList: Array<any> = [];
  public selectedRows: [];
  public rolePermission: any = RolePermissionEnum;

  constructor(
    spinner: NgxSpinnerService,
    exclusionService: ExclusionsService,
    router: Router,
    messageService: MessageService,
    primengConfig: PrimeNGConfig,
    domainService: DomainService,
  ) { 
      this.spinner = spinner;
      this.exclusionService = exclusionService;
      this.router = router;
      this.messageService = messageService;
      this.primengConfig = primengConfig;
      this.domainService = domainService;
  }


  ngOnInit(): void {
    this.getAll();
  }

  /**
  * @description this method obtains the information for the table.
  * @author Santiago Martinez
  */

  public getAll(): void {
    this.exclusionsList = [];
    this.spinner.show();
    this.exclusionService.getListExclusions().subscribe(
      (response) => {
        this.spinner.hide();
        if (response.status) {
          this.exclusionsList = response.data.map((exclusion: any) => {
            return exclusion;
          });
        }
      },
      (error: HttpErrorResponse) => {
        SweetAlertMessageHelpers.exception(error);
      }
    );
  }

  /**
  * @description this method deletes the information in the table as a group.
  * @author Santiago Martinez
  */
  public deleteAllObjectAction() {
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        const ids = this.exclusionsList
          .map((item: any) => {
            if (item.check) {
              return item.id;
            } else {
              return false;
            }
          }).filter((item) => item !== false);
        if (!ids.length) {
          this.spinner.hide();
          return;
        }
        this.exclusionService.deleteExclusionSelected(ids).subscribe(
          (response: any) => {
            if (response.status) {
              this.getAll();
              const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              this.spinner.hide();
            } else {
              this.spinner.hide();
              SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            SweetAlertMessageHelpers.exception(error);
          }
        );
      }
    });
  }

  /**
  * @description this method removes an element from the table.
  * @author Santiago Martinez
  */
  public deleteObjectAction(value: any) {
    const { id } = value;
    Util.confirmDelete().then((result: SweetAlertResult) => {
      if (result.value) {
        this.spinner.show();
        this.exclusionService.deleteExclusionsById(id)
          .subscribe(
            (response) => {
              this.spinner.hide();
              if (response.status) {
                this.getAll();
                const language: string = sessionStorage.getItem('language');
                this.showToast(SweetAlert.iconInfo,
                  language === 'es-ES' ? SweetAlert.titleAlert: SweetAlert.titleAlertTranslate,
                  language === 'es-ES' ? SweetAlert.messageAlertDelete : SweetAlert.messageAlertDeleteTranslate);
              } else {
                SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
              }
            },
            (error: HttpErrorResponse) => {
              this.spinner.hide();
              SweetAlertMessageHelpers.exception(error);
            }
          );
      }
    });

  }

  /**
 * @description this method redirects to see detail information.
 * @author Santiago Martinez
 */

  public viewDetailsObjectAction(value: any) {
    this.router.navigate(['/auth/exclusion/create', { isProcess: 'detail', objectDetail: value.id }]);
  }

  /**
 * @description this method redirects to edit information.
 * @author Santiago Martinez
 */

  public editObjectAction(value: any) {
    this.router.navigate(['/auth/exclusion/create', { isProcess: 'edit', objectDetail: value.id }]);
  }


  /**
 * @description this method redirects to see create.
 * @author Santiago Martinez
 */

  public createAction() {
    this.router.navigate(['/auth/exclusion/create', { isProcess: 'create' }]);
  }


  public selectRow(value: any, object: any) {
    object.check = value;
  }

  /**
  * @description this method selects and marks as selected table elements.
  * @author Santiago Martinez
  */

  public selectAll(value: any) {
    this.exclusionsList.forEach((exclusion) => {
      exclusion.check = value;
    });
  }

  public showToast(severity: string, message: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({ severity: severity, summary: message, detail: detail, life: ApplicationConstants.CONSTANTS.lifeTimeToast as number });
  }
}
