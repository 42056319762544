import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { HttpService } from '@app/@shared/services/http.service';

import { PromotionalActivityModel } from './models/promotional-activity.model';

import { IResponseService } from '@app/@shared/interfaces/response-service.interface';

@Injectable({
  providedIn: 'root'
})
export class PromotionalActivitiesCreateService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  public postPromotionalActivity(body: PromotionalActivityModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
    this.httpService
    .post(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/associate_commercial_activity_with_detail/`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public getPromotionalActivityById(id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/commercial_activity_by_id/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public putPromotionalActivity(body: PromotionalActivityModel, id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .put(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/associate_commercial_activity_with_detail/${id}`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public deleteProductGroupsList(id: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .delete(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/purchase_bases/logic/${id}`)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public deleteProductList(id: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .delete(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/purchase_base_details/logic/${id}`)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public deleteProductPromotionGroupsList(id: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .delete(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/purchase_base_promotions/logic/${id}`)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public deleteProductPromotionList(id: string): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .delete(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/purchase_base_promotion_details/logic/${id}`)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

}
