<div class="row justify-content-between">
  <div class="float-right">

    <div class="row mt-12 pb-12 d-flex justify-content-end" *ngIf="!isVisible">
      <div class="col-md-12 md-12 mt-12">
        <button type="button" pButton pRipple label="{{ 'ManagePromotionalActivitiesComponent.Redemption.AddExchangeable' | translate}}" (click)="newRecordRedeemable()"
          class="p-button-rounded pstn_primario w-60"></button>
      </div>
    </div>


    <!-- Filter Menu -->
    <p-sidebar [(visible)]="isVisible" position="right" [baseZIndex]="10000" [dismissible]="false"
      [closeOnEscape]="false" styleClass="p-sidebar-md" (onHide)="closeAdvancedFilters()"
      [style]="{'overflow-x': 'scroll'}">


      <!--div class=" align-self-center" *ngIf="isVisible">
        <h2 class="pstn_subtitle">{{ 'AdvancedRedeemableFilterComponent.SelectedFilters' | translate }}</h2>

        {{ 'AdvancedRedeemableFilterComponent.TitleClearFilter' | translate }}
        <button id="btnDelete" type="button" class="delete btn btn-secundary-delete" (click)="deleteFilters()">
          <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
        </button>
      </div-->


      <div class="modal-header">
        <div class="row justify-content-between">
          <h5 class="modal-title float-left" id="myModalLabel2">
            <strong>{{ 'AdvancedRedeemableFilterComponent.AdvancedFilters' | translate }}</strong>
          </h5>
        </div>
      </div>

      <div class="modal-body">

        <!--Elemento-->
        <div class="mt-3">
            <!--Code-->
            <div class="row">

                <div class="col-md-4">
                    <button pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-danger p-button-outlined"
                            (click)="clearFilterSelected()">
                    </button>
                </div>

                <div class="col-md-8" style="text-align: right;">
                  <p-button label="{{ 'AdvancedRedeemableFilterComponent.AddFilterBtn' | translate }}"
                            icon="pi pi-plus-circle"
                            styleClass="p-button"
                            (click)="addFilter()">
                  </p-button>
                </div>

                <div class="col-md-12 form-group">
                    <label for="code">{{ 'AdvancedRedeemableFilterComponent.ElementRedemeeable' | translate }}</label><br />

                    <p-multiSelect id="code"
                        class="multiselect-custom-virtual-scroll" [virtualScroll]="true"
                        [(ngModel)]="codesMultiSelected" [options]="codesFilterList" [filter]="true"
                        defaultLabel="{{ 'AdvancedRedeemableFilterComponent.ElementCodeSelect' | translate }}"
                        display="chip" [style]="{'width': '100%'}">
                    </p-multiSelect>

                </div>
            </div>
        </div>

      </div>

    </p-sidebar>

  </div>
</div>
