import { NgModule } from '@angular/core';
import { SharedModule } from '@app/@shared';
import { CommonModule } from '@angular/common';
import { ComponentModule } from '@app/@components/component.module';
import { PrimeNgModule } from '@app/@shared/modules/prime-ng.module';
import { NegotiationComponent } from './negotiation/negotiation.component';
import { NegotiationCreateComponent } from './negotiation/create/negotiation-create.component';
import { NegotiationsRoutingModule } from './negotiations-routing.module';

@NgModule({
  declarations: [
    NegotiationComponent,
    NegotiationCreateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PrimeNgModule,
    ComponentModule,
    NegotiationsRoutingModule
  ]
})
export class NegotiationsModule { }
