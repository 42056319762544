import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICombo } from '@app/@shared/interfaces/combo.interface';
import { IAdvancedFilter, IGeneralAdvancedFilter, IMasterValue } from '../interfaces/general-advanced-filter.interface';
import { forkJoin } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { ICoreMaster } from '@app/@shared/interfaces/core-master.interface';
import { CoreMasterService } from '@app/@shared/services/core-master.service';
import { TypeCoreMaster } from '@app/@shared/enums/type-core-master.enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertMessageHelpers } from '@app/@shared/helpers/sweet-alert-message.helper';
import { TypeMessage } from '@app/@shared/enums/type-message.enum';
import { ChannelService } from '@app/@modules/administration/channel/channel.service';
import { DomainService } from '@app/@shared/services/domain.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/i18n';
import { Microservice } from '@app/@shared/enums/microservice.enum';
import { DomainTypeCore } from '@app/@shared/enums/domain-type-core.enums';
import { HttpErrorResponse } from '@angular/common/http';
import { RegexCommon } from '@app/@shared/enums/regex.enum';
import { DatePipe } from '@angular/common';
import { ManagePromotionalActivitiesService } from '@app/@modules/promotionalactivity/manage-promotional-activities/manage-promotional-activities.service';
import { IDomain } from '@app/@shared/interfaces/domain.interface';
import { FieldsComponentGeneralFilter } from '@app/@shared/model/fields-component-general-filter.model';
import { IFieldsComponentGeneralFilter } from '@app/@shared/interfaces/fields-component-general-filter.interface';
import { SessionStorageHelper } from '@app/@shared/helpers/sessionstorage.helper';

@Component({
  selector: 'app-general-advanced-filter',
  templateUrl: './general-advanced-filter.component.html',
  styleUrls: ['./general-advanced-filter.component.css'],
})
export class GeneralAdvancedFilterComponent implements OnInit {

  @Input('showFieldsComponent') showFieldsComponent = true;
  @Input('showFieldsGeneral') showFieldsGeneral : IFieldsComponentGeneralFilter = new FieldsComponentGeneralFilter(false, false, false, false, false, 
                                                                                                                   false, false, false, false, false, 
                                                                                                                   false, false, false, false, false);
  @Input('advancedSearchFilter') advancedSearchFilter = {} as IAdvancedFilter;

  public formAccessManagement: FormGroup;
  private readonly fb: FormBuilder;
  public objectSectorList: ICombo[];
  public objectChannelList: ICombo[];
  public objectStatusList: ICombo[];
  public objectPromList: ICombo[];
  public objectPromotionalActivity: ICombo[];
  public objectStatusDifferentialCombo: ICombo[];
  public objectSolicitudeTypeCombo: ICombo[];
  public objectSolicitudeStatusCombo: ICombo[];

  private generalAdvancedFilter = {} as IGeneralAdvancedFilter;
  private readonly domainService: DomainService;
  private readonly translate: TranslateService;
  private readonly i18nService: I18nService;
  private readonly managePromotionalActivitiesService:ManagePromotionalActivitiesService;
  private readonly miDatePipe: DatePipe;
  private isInitialized = false;

  public language: string = sessionStorage.getItem('language');

  private readonly coreService: CoreMasterService;
  private readonly channelService: ChannelService;

  private readonly spinner: NgxSpinnerService;

  public channelToolTip: string[] = [];
  public sectorToolTip: string[] = [];
  public activityTypeToolTip: string[] = [];
  public solicitudeTypeToolTip: string[] = [];
  public activityStatusToolTip: string[] = [];
  public solicitudeStatusToolTip: string[] = [];
  public activityPromToolTip: string[] = [];
  public dateRangeTooltip = '';
  public dateCurrentRangeTooltip = '';
  public dateCreationRangeTooltip = '';
  public dateValidityRangeTooltip = '';

  public nameIdInput = '';
  public nameDateInput = '';
  public nameCurrentDateInput = '';
  public nameCreationDateInput = 'Fecha creación';
  public nameValidityDateInput = 'Fecha vigencia';

  public flagTypeCombo = false;
  public flagStatusCombo = false;

  constructor(fb: FormBuilder,
              coreService: CoreMasterService,
              channelService: ChannelService,
              domainService:DomainService,
              translate: TranslateService,
              i18nService: I18nService,
              spinner: NgxSpinnerService,
              managePromotionalActivitiesService: ManagePromotionalActivitiesService,
              miDatePipe: DatePipe) {
    this.fb = fb;
    this.coreService = coreService;
    this.channelService = channelService;
    this.domainService = domainService;
    this.managePromotionalActivitiesService = managePromotionalActivitiesService;
    this.i18nService = i18nService;
    this.translate = translate;
    this.spinner = spinner;
    this.miDatePipe = miDatePipe;
  }

  async ngOnInit(): Promise<void> {
    this.changeNameInput();
    this.initForm()
    this.forkJoinData();
    await this.getPromotionalActivity(true);
    await this.getActivityStatus(true);
    await this.getActivityPromType(true);
    await this.setGeneralFormValues();
  }

  private changeNameInput() {
    this.nameIdInput = this.showFieldsComponent ? 'AdvancedFiltersComponent.GeneralComponent.ActivityCode'
      : 'EliminateDifferentialComponent.RequestId';

    this.nameDateInput = this.showFieldsComponent ? 'AdvancedFiltersComponent.GeneralComponent.ActivityDate'
      : 'EliminateDifferentialComponent.DataRange';

      this.nameCurrentDateInput = this.showFieldsComponent ? 'AdvancedFiltersComponent.GeneralComponent.ActivityCurrentDate'
      : 'EliminateDifferentialComponent.DataRange';
  }

  initForm(): void {
    this.formAccessManagement = this.fb.group({
      activityCode: [{ value: '', disabled: false}, [Validators.pattern(/^[0-9,$]*$/)]],
      activityDescription: [{ value: '', disabled: false }, 
      [ Validators.required,
        Validators.maxLength(100),
        Validators.pattern(RegexCommon.ExpRegOnlyCaracterNumber100Email),
        Validators.minLength(3)]
      ],
      rangeDateControl: [{ value: '', disabled: false }],
      statusDifferential: [{ value: '', disabled: false }],
      typeSolicitude: [{ value: [], disabled: false }],
      statusSolicitude: [{ value: [], disabled: false }],
      sector: [{ value: [], disabled: false }],
      channel: [{ value: [], disabled: false }],
      activityType: [{ value: [], disabled: false }],
      activityStatus: [{ value: '', disabled: false }],
      activityProm: [{ value: [], disabled: false }],
      rangeCurrentDateControl: [{ value: '', disabled: false }],
      rangeCreationDateControl: [{ value: '', disabled: false }],
      rangeValidityDateControl: [{ value: '', disabled: false }],
      userCreator: [{ value: '', disabled: false }, 
      [ Validators.maxLength(100),
        Validators.pattern(RegexCommon.ExpRegOnlyCaracterNumber100Email),
        Validators.minLength(3)]
      ],
    });
  }

  async forkJoinData() {
    const sectorResponse = this.coreService.getAll(TypeCoreMaster.Sector);
    const channelResponse = this.coreService.getAll(TypeCoreMaster.Channel);
    const statusDifferentialResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.SolicitudeStatus);
    const solicitudeTypeResponse = this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.SolicitudeType);

    forkJoin([
      sectorResponse,
      channelResponse,
      statusDifferentialResponse,
      solicitudeTypeResponse
    ]).toPromise().then(
        (result) => {
          const objSectorResponse: IResponseService<ICoreMaster[]> = result[0];
          const objChannelResponse: IResponseService<ICoreMaster[]> = result[1];
          const objStatusDifferentialResponse: IResponseService<IDomain[]> = result[2];
          const objSolicitudeTypeResponse: IResponseService<IDomain[]> = result[3];

          forkJoin(
            [sectorResponse])
            .toPromise().then(
              (result) => {

                this.objectSectorList = objSectorResponse.data.map((item) => {
                   return {
                     label: item.description,
                     code: `${item.code.trim()}`, 
                     value: `${item.code.trim()}`
                  };
                 });

                 this.objectChannelList = objChannelResponse.data.map((item) => {
                   return {
                    label: item.description,
                    code: `${item.code.trim()}`,
                    value: `${item.code.trim()}`
                   };
                 });

                 this.objectStatusDifferentialCombo = objStatusDifferentialResponse.data.filter((f) => f.code === 'EL' || f.code === 'AC').map((item) => {
                  return {
                    label: item.description,
                    code:  item.code,
                    value: item.id
                  };
                });

                if(!this.flagTypeCombo){
                  this.objectSolicitudeTypeCombo = objSolicitudeTypeResponse.data.map((item) => {
                    return {
                      label: item.description == 'Retiro'? 'Retiro/Lista de Precios': 
                                                 item.description == 'retiro/diferencial de precios'?  'Retiro/Diferencial de Precios': 
                                                 `${item.description.charAt(0).toUpperCase()}${item.description.slice(1)}`,
                      code:  item.code,
                      value: item.id
                    };
                  });
                }

                if(!this.flagStatusCombo){
                  this.objectSolicitudeStatusCombo = objStatusDifferentialResponse.data.filter((f) => f.code != 'EL' && f.code != 'AC' && f.code != 'PE' && f.code != 'MD').map((item) => {
                    return {
                      label: item.description,
                      code:  item.code,
                      value: item.id
                    };
                  });
                }


                 if (this.objectSectorList && this.objectChannelList) {
                  const userFilters = this.managePromotionalActivitiesService.setUserFilters(this.objectChannelList, this.objectSectorList);
                  this.objectChannelList = userFilters.channels;
                  this.objectSectorList = userFilters.sectors;
                }

            },
        (error) => {
          this.spinner.hide();
          SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
        }
      );
    },
    (error) => {
      this.spinner.hide();
      SweetAlertMessageHelpers.showMessage(TypeMessage.Error);
  });
}

getAdvancedFilters() {
  let rangeDate = this.rangeDateControl.value? this.rangeDateControl.value : this.rangeCreationDateControl.value;
  this.getRangeDates(rangeDate);
  let currentDate = this.rangeCurrentDateControl.value ? this.rangeCurrentDateControl.value : this.rangeValidityDateControl.value;
  this.getCurrentRangeDates(currentDate);

  this.generalAdvancedFilter.activityCode = (this.activityCode.value === '' ||
                                             this.activityCode.value == null ||
                                             this.activityCode.value === undefined)   ? 0 : this.activityCode.value;
  this.generalAdvancedFilter.activityDescription = (this.activityDescription.value === '' ||
                                                    this.activityDescription.value == null ||
                                                    this.activityDescription.value === undefined)   ? '' : this.activityDescription.value;
  this.generalAdvancedFilter.activitySectors =  this.sector.value ?  this.sector.value?.map((item : IMasterValue) => { return item.code.toString() }) : [];
  this.generalAdvancedFilter.activityChannels = this.channel.value ? this.channel.value?.map((item : IMasterValue) => { return item.code.toString() }): [];

  this.generalAdvancedFilter.activityTypes = [];
  if (this.activityType.value != undefined && this.activityType.value != '' && this.activityType.value != null){
    this.generalAdvancedFilter.activityTypes = this.activityType.value ? this.activityType.value?.map((item : IMasterValue) => { return item.code.toString() }): [];
  }
  else if (this.typeSolicitude.value){
    this.generalAdvancedFilter.activityTypes = this.typeSolicitude.value ? this.typeSolicitude.value?.map((item : IMasterValue) => { return item.value.toString() }): [];
  }

  this.generalAdvancedFilter.activityStatus = [];
  if (this.activityStatus.value)
  {
    this.generalAdvancedFilter.activityStatus = this.generalAdvancedFilter.activityStatus = this.activityStatus.value ? this.activityStatus.value?.map((item : IMasterValue) => { return item.code.toString() }): [];
  }
  else if (this.statusSolicitude.value){
    this.generalAdvancedFilter.activityStatus = this.generalAdvancedFilter.activityStatus = this.statusSolicitude.value ? this.statusSolicitude.value?.map((item : IMasterValue) => { return item.value.toString() }): [];
  }

  this.generalAdvancedFilter.statusDifferential = ( this.statusDifferential.value === '' ||
                                                    this.statusDifferential.value == null ||
                                                    this.statusDifferential.value === undefined)   ? 0 : this.statusDifferential.value;
  this.generalAdvancedFilter.activityProms = this.activityProm.value ? this.activityProm.value?.map((item : IMasterValue) => { return item.code.toString() }): [];
  this.generalAdvancedFilter.userCreator = (this.userCreator.value === '' ||
                                                    this.userCreator.value == null ||
                                                    this.userCreator.value === undefined)   ? '' : this.userCreator.value;

  return this.generalAdvancedFilter;
}

  getRangeDates(rangeDate:any) {
    let lstRangeDate = rangeDate?.toString().split(',');
    if(lstRangeDate){
      const startDate = new Date(lstRangeDate[0]);
      const endDate = new Date(lstRangeDate[1]);
      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        const startMonth = startDate.getMonth() + 1;
        const endMonth = endDate.getMonth() + 1;
        this.generalAdvancedFilter.activityStartDate = startDate.getFullYear().toString() + '-' + startMonth.toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') + ' 00:00:00';
        this.generalAdvancedFilter.activityEndDate = endDate.getFullYear().toString() + '-' + endMonth.toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0') + ' 23:59:59.999';
      }
    }
    else{
      this.generalAdvancedFilter.activityStartDate = '';
      this.generalAdvancedFilter.activityEndDate = '';
    }
  }

  getCurrentRangeDates(currentDate: any) {
    let lstRangeDate = currentDate?.toString().split(',');
    if(lstRangeDate){
      const startDate = new Date(lstRangeDate[0]);
      const endDate = new Date(lstRangeDate[1]);
      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        const startMonth = startDate.getMonth() + 1;
        const endMonth = endDate.getMonth() + 1;
        this.generalAdvancedFilter.activityCurrentStartDate = startDate.getFullYear().toString() + '-' + startMonth.toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') + ' 00:00:00';
        this.generalAdvancedFilter.activityCurrentEndDate = endDate.getFullYear().toString() + '-' + endMonth.toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0') + ' 23:59:59.999';
      }
    }
    else{
      this.generalAdvancedFilter.activityCurrentStartDate = '';
      this.generalAdvancedFilter.activityCurrentEndDate = '';
    }
  }

  onChangeSector(){
    if(this.sector.value.length > 0){
      this.sectorToolTip = [];
      this.sector.value.forEach((elm : IMasterValue) => {
        this.sectorToolTip.push(elm.label);
      });
    }else this.sectorToolTip = [];
  }

  onChangeChannel(){
    if(this.channel.value.length > 0){
      this.channelToolTip = [];
      this.channel.value.forEach((elm : IMasterValue) => {
        this.channelToolTip.push(elm.label);
      });
    } else this.channelToolTip = [];
  }

  onChangeActivityType(){
    if(this.activityType.value.length > 0){
      this.activityTypeToolTip = [];
      this.activityType.value.forEach((elm : IMasterValue) => {
        this.activityTypeToolTip.push(elm.label);
      });
    }else this.activityTypeToolTip = [];
  }

  onChangeSolicitudeType(){
    if(this.typeSolicitude.value.length > 0){
      this.solicitudeTypeToolTip = [];
      this.typeSolicitude.value.forEach((elm : IMasterValue) => {
        this.solicitudeTypeToolTip.push(elm.label);
      });
    }else this.solicitudeTypeToolTip = [];
  }

  onChangeActivityStatus(){
    if(this.activityStatus.value.length > 0){
      this.activityStatusToolTip = [];
      this.activityStatus.value.forEach((elm : IMasterValue) => {
        this.activityStatusToolTip.push(elm.label);
      });
    }else this.activityStatusToolTip = [];
  }

  onChangeSolicitudeStatus(){
    if(this.statusSolicitude.value.length > 0){
      this.solicitudeStatusToolTip = [];
      this.statusSolicitude.value.forEach((elm : IMasterValue) => {
        this.solicitudeStatusToolTip.push(elm.label);
      });
    }else this.solicitudeStatusToolTip = [];
  }  

  onChangeActivityProm(){
    if(this.activityProm.value.length > 0){
      this.activityPromToolTip = [];
      this.activityProm.value.forEach((elm : IMasterValue) => {
        this.activityPromToolTip.push(elm.label);
      });
    } else this.activityPromToolTip = [];
  }
  onChangeDate(){
    if(this.rangeDateControl.value == null) return;
    if(this.rangeDateControl.value.length > 0){
      this.dateRangeTooltip = '';
      const dateStart = this.miDatePipe.transform(this.rangeDateControl.value[0], 'dd-MM-yyyy');
      const dateEnd = this.miDatePipe.transform(this.rangeDateControl.value[1], 'dd-MM-yyyy');
      if(dateStart && dateEnd){
        this.dateRangeTooltip = dateStart +'/'+ dateEnd;
      }
    } else {
      this.dateRangeTooltip = '';
    }
  }

  onChangeCurrentDate(){
    if(this.rangeCurrentDateControl.value == null) return;
    if(this.rangeCurrentDateControl.value.length > 0){
      this.dateCurrentRangeTooltip = '';
      const dateStart = this.miDatePipe.transform(this.rangeCurrentDateControl.value[0], 'dd-MM-yyyy');
      const dateEnd = this.miDatePipe.transform(this.rangeCurrentDateControl.value[1], 'dd-MM-yyyy');
      if(dateStart && dateEnd){
        this.dateCurrentRangeTooltip = dateStart +'/'+ dateEnd;
      }
    } else {
      this.dateCurrentRangeTooltip = '';
    }
  }

  onChangeCreationDate(){

    if(this.rangeCreationDateControl.value == null) 
    {
      this.initCreationDate();
      return;
    };

    if(this.rangeCreationDateControl.value.length > 0){
      this.dateCreationRangeTooltip = '';
      const dateStart = this.miDatePipe.transform(this.rangeCreationDateControl.value[0], 'dd-MM-yyyy');
      const dateEnd = this.miDatePipe.transform(this.rangeCreationDateControl.value[1], 'dd-MM-yyyy');
      if(dateStart && dateEnd){
        this.dateCreationRangeTooltip = dateStart +'/'+ dateEnd;
      }
    } else {
      this.dateCreationRangeTooltip = '';
    }
  }
  
  onChangeValidityDate(){
    if(this.rangeValidityDateControl.value == null) 
    {
      this.initValidityDate();
      return;
    };

    if(this.rangeValidityDateControl.value.length > 0){
      this.dateValidityRangeTooltip = '';
      const dateStart = this.miDatePipe.transform(this.rangeValidityDateControl.value[0], 'dd-MM-yyyy');
      const dateEnd = this.miDatePipe.transform(this.rangeValidityDateControl.value[1], 'dd-MM-yyyy');
      if(dateStart && dateEnd){
        this.dateValidityRangeTooltip = dateStart +'/'+ dateEnd;
      }
    } else {
      this.dateValidityRangeTooltip = '';
    }
  }

 
 initializeForm(){
    this.formAccessManagement.reset();
    this.sectorToolTip = [];
    this.channelToolTip = [];
    this.activityTypeToolTip = [];
    this.activityStatusToolTip = [];
    this.activityPromToolTip = [];
    this.dateRangeTooltip = '';
    this.solicitudeStatusToolTip = [];
    this.solicitudeTypeToolTip = [];
    this.dateCreationRangeTooltip = '';
    this.dateCurrentRangeTooltip = '';
    this.dateValidityRangeTooltip = '';
  }


/**
   * @description this method is to get all the society.
   * @author sgonzalezj
   * @sprint 8
   */
async getPromotionalActivity(isFirst = false): Promise<void> {
  if(this.language === this.translate.currentLang && !isFirst){
    return;
  }else{
    this.translate.use(this.i18nService.language);
    this.language = this.i18nService.language;
  }

  this.spinner.show();
  await  this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.Subprocess).subscribe(
    (response) => {
      this.objectPromotionalActivity = response.data.filter((f) => f.code === 'AP').map((item) => {
        return {
          label: this.translate.instant('Domain.Domain-'+item.id),
          code:  item.id,
          value: item.id
        };
      });
    },
    (error: HttpErrorResponse) => {
      SweetAlertMessageHelpers.exception(error);
    }
  );
}

/**
   * @description this method is to get all the society.
   * @author sgonzalezj
   * @sprint 8
   */
async getActivityStatus(isFirst = false): Promise<void> {

  if(this.language === this.translate.currentLang && !isFirst){
    return;
  } else {
    this.translate.use(this.i18nService.language);
    this.language = this.i18nService.language;
  }

  this.spinner.show();
  await this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.CommercialActivityStatus).subscribe(
    (response) => {
      this.objectStatusList = response.data.map((item) => {
        return {
          label: this.translate.instant('Domain.Domain-'+item.id),
          code:  item.id,
          value: item.id
        };
      });
    },
    (error: HttpErrorResponse) => {
      SweetAlertMessageHelpers.exception(error);
    });
  }


/**
   * @description this method is to get all the society.
   * @author sgonzalezj
   * @sprint 8
   */
async getActivityPromType(isFirst = false): Promise<void> {

  if(this.language === this.translate.currentLang && !isFirst){
    return;
  } else {
    this.translate.use(this.i18nService.language);
    this.language = this.i18nService.language;
  }

  this.spinner.show();
  await this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.TypePromotionalActivity).subscribe(
    (response) => {
      this.objectPromList = response.data.map((item) => {
        return {
          label: this.translate.instant('Domain.Domain-'+item.id),
          code:  item.id,
          value: item.id
        };
      });
    },
    (error: HttpErrorResponse) => {
      SweetAlertMessageHelpers.exception(error);
    });
  }

  getYearRange() {
    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();
    return `${thisYear - 2}:${thisYear + 10}`;
  }

  onFocusOut(){
    this.activityDescription.setValue(this.activityDescription.value.trim());
    this.userCreator.setValue(this.userCreator.value.trim());
  }

  initCreationDate()
  {
    this.rangeCreationDateControl.reset();
  }
  
  initValidityDate()
  {
    this.rangeValidityDateControl.reset();
  }  

   async setGeneralFormValues() {
    let selectedTypeFilter : ICombo[] = [];
    let selectedStatusFilter : ICombo[] = [];
    let filter : IAdvancedFilter = SessionStorageHelper.get('filters') ?  JSON.parse(SessionStorageHelper.get('filters')) : null;

    if(filter){

      if (filter.general.activityCode)  this.activityCode.setValue(filter.general.activityCode);
  
      if (filter.general.activityTypes.length > 0) {
        const response = await this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.SolicitudeType).toPromise();
        this.objectSolicitudeTypeCombo = response.data.map((item) => {
          return {
            label: item.description == 'Retiro'? 'Retiro/Lista de Precios': 
                                       item.description == 'retiro/diferencial de precios'?  'Retiro/Diferencial de Precios': 
                                       `${item.description.charAt(0).toUpperCase()}${item.description.slice(1)}`,
            code:  item.code,
            value: item.id
          };
        });
  
        filter.general.activityTypes.forEach(x =>{
          let findActitivyType = this.objectSolicitudeTypeCombo.find((itm) => itm.value == x );
          if(findActitivyType) selectedTypeFilter.push(findActitivyType);
        });
  
        this.typeSolicitude.setValue(selectedTypeFilter);
        this.flagTypeCombo = true;
      }
  
      if (filter.general.activityStatus.length > 0){
        const response = await this.domainService.getAllByDomainType(Microservice.Core, DomainTypeCore.SolicitudeStatus).toPromise();
  
        this.objectSolicitudeStatusCombo = response.data.filter((f) => f.code != 'EL' && f.code != 'AC' && f.code != 'PE' && f.code != 'MD').map((item) => {
          return {
            label: item.description,
            code:  item.code,
            value: item.id
          };
        });
  
        filter.general.activityStatus.forEach(x =>{
          let findActitivyStatus = this.objectSolicitudeStatusCombo.find((itm) => itm.value == x );
          if(findActitivyStatus) selectedStatusFilter.push(findActitivyStatus);
        });
  
        this.statusSolicitude.setValue(selectedStatusFilter);
        this.flagStatusCombo = true;
      } 
      
      if (filter.general.activityStartDate){
          let selectedDate : any = [];
          let activityStartDate = new Date(filter.general.activityStartDate);
          let activityEndDate = filter.general.activityEndDate != '' ? new Date(filter.general.activityEndDate) : null;
  
          selectedDate.push(activityStartDate);
          selectedDate.push(activityEndDate);
  
          this.rangeCreationDateControl.setValue(selectedDate);
      }
  
      if (filter.general.activityCurrentStartDate){
          let selectedDate : any = [];
          let activityCurrentStartDate = new Date(filter.general.activityCurrentStartDate);
          let activityCurrentEndDate = filter.general.activityCurrentEndDate != '' ? new Date(filter.general.activityCurrentEndDate) : null;
  
          selectedDate.push(activityCurrentStartDate);
          selectedDate.push(activityCurrentEndDate);
  
          this.rangeValidityDateControl.setValue(selectedDate);
      } 
    }
  
  }

  get activityCode(): AbstractControl { return this.formAccessManagement.get('activityCode');}
  get activityDescription(): AbstractControl { return this.formAccessManagement.get('activityDescription');}
  get activityStartDate(): AbstractControl { return this.formAccessManagement.get('activityStartDate');}
  get activityEndDate(): AbstractControl { return this.formAccessManagement.get('activityEndDate');}
  get sector(): AbstractControl { return this.formAccessManagement.get('sector');}
  get channel(): AbstractControl { return this.formAccessManagement.get('channel'); }
  get activityType(): AbstractControl { return this.formAccessManagement.get('activityType'); }
  get activityStatus(): AbstractControl { return this.formAccessManagement.get('activityStatus'); }
  get statusDifferential(): AbstractControl { return this.formAccessManagement.get('statusDifferential'); }
  get typeSolicitude(): AbstractControl { return this.formAccessManagement.get('typeSolicitude'); }
  get statusSolicitude(): AbstractControl { return this.formAccessManagement.get('statusSolicitude'); }
  get activityProm(): AbstractControl { return this.formAccessManagement.get('activityProm'); }
  get rangeDateControl(): AbstractControl { return this.formAccessManagement.get('rangeDateControl'); }
  get rangeCurrentDateControl(): AbstractControl { return this.formAccessManagement.get('rangeCurrentDateControl'); }
  get rangeCreationDateControl(): AbstractControl { return this.formAccessManagement.get('rangeCreationDateControl'); }
  get rangeValidityDateControl(): AbstractControl { return this.formAccessManagement.get('rangeValidityDateControl'); }
  get userCreator(): AbstractControl { return this.formAccessManagement.get('userCreator');}
}