import { ISolicitudeMaterial } from '../interfaces/solicitude-material.interface';

export class SolicitudeMaterialModel implements ISolicitudeMaterial {
  /**
   * @author Priscy Antonio Reales
   * @sprint 4
   */
  constructor(
    public id: number,
    public solicitudeId: number,
    public materialCode: string,
    public currentListPrice: number,
    public currentPsp: number,
    public newPriceWithOutTax: number,
    public priceAdjustment: number,
    public newPsp: number,
    public effectiveDateStart: Date,
    public effectiveDateEnd: Date,
    public guid?:string,
    public zofeCode?: string,
    public zofeDescription?: string,
    public solicitudeIdOrigin?: number,
    public solicitudeMaterialIdOrigin? : number,
    public statusDomainId?: number,
    public effectiveDateStartOrigin? : Date
  ) {}
}
