import { Injectable } from '@angular/core';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';
import { map, tap } from 'rxjs/operators';
import { ICommercialActivityDocumentRequired } from './interfaces/commercial-activity-document-required.interface';
import { Observable } from 'rxjs';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommercialActivityDocumentService {

  private readonly httpService: HttpService;

  constructor(
    httpService: HttpService,
  ) {
      this.httpService = httpService;
  }


  public getRequiredFields(activityTypeId: number): Observable<IResponseService<ICommercialActivityDocumentRequired>>{
    const response$: Observable<IResponseService<ICommercialActivityDocumentRequired>> = 
    this.httpService
    .get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity_observation_config/${activityTypeId}`)
    .pipe(
      map((response: HttpResponse<IResponseService<ICommercialActivityDocumentRequired>>) => {
        return response.body;
      })
    );
    return response$;
  }

  public getFile(activityId : number, fileName : string){
    let type : string = 'application/pdf';
    return this.httpService.get(`${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity_observation/download_file_by_activity/${activityId}`, undefined, true )
    .pipe(
      tap(content => {
        const blob = new Blob([content.body], {type});
        saveAs(blob,fileName);
      }),
      map(() => true)
    );
  }

}
