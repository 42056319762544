<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="pstn breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/auth'"><i class="uil uil-estate" aria-hidden="true"></i>{{ 'Home' | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ 'ComercialActivityNegociationExclusionComponent.Title' | translate }}
        </li>
      </ol>
    </nav>
  </div>
</section>

<section class="pstngestion">
  <div class="container">
  <div class="row">
      <h1 class="pstntitle">{{ 'ComercialActivityNegociationExclusionComponent.Title' | translate }}</h1>
  </div>
  <form id="formBusinessActivityManagement" [formGroup]="formComercialActivityNegociation">
      <div class="row mt-5">
      <div class="col-md-5">
          <label>{{ 'ComercialActivityNegociationExclusionComponent.Description' | translate }}</label>
          <input id="description" formControlName="idCommercialActivity" autocomplete="off" class="form-control"
          [ngClass]="{'ng-invalid ng-dirty':idCommercialActivity.invalid && idCommercialActivity.touched}" pInputText 
          required (focusout)="onFocusOut()" 
          onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )"  
          placeholder="{{ 'ComercialactivityIdentificator' | translate }}"
           />
          <small id="DescriptionDetail"
          *ngIf="formComercialActivityNegociation.controls['idCommercialActivity'].invalid &&
          (formComercialActivityNegociation.controls['idCommercialActivity'].dirty ||
          formComercialActivityNegociation.controls['idCommercialActivity'].touched)"
          class="p-error">{{ 'ManagePromotionalActivitiesComponent.DescriptionExclusionMsgValidate' | translate }}</small>
      </div>


      <div class="col-md-3"></div>
      <div class="col-md-4 mt-4 align-items-end">
          <button id="btnCreate" pButton pRipple type="button" label="{{ 'AggregateBtn' | translate }}"
          class="p-button-rounded pstn_primario w-100" (click)="post()"
          [disabled]="!formComercialActivityNegociation.valid"></button>
      </div>
      </div>
  </form>
  <div class="row mt-5">
      <div class="pstn_container container-fluid">
        <!-- PrimeNg Table -->
        <p-table
          #dt
          dataKey="id"
          [value]="objectComercialActivityNegociationList"
          [paginator]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10,25,50,100]"
          paginatorPosition="bottom"
          styleClass="p-datatable-customers"
          [globalFilterFields]="['commercialActivityId', 'creationUser', 'creationDate','commercialActivityDescription','typeDomainDescription']"
        >
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="p-fluid p-grid p-formgrid col-md-6 row">
                <div class="col-md-8 align-items-start"></div>
                <div class="col-md-2"></div>
              </div>
              <div class="col-md-6 p-0">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search" aria-hidden="true"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{ 'SearchBy' | translate }}"
                  />
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem"></th>
              <th pSortableColumn="commercialActivityId">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "ComercialActivityNegociationExclusionComponent.Id" | translate }}
                  <p-sortIcon field="commercialActivityId"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="commercialActivityDescription">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "ComercialActivityNegociationExclusionComponent.CommercialActivityDescription" | translate }}
                  <p-sortIcon field="commercialActivityDescription"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="typeDomainDescription">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "ComercialActivityNegociationExclusionComponent.TypeDomainDescription" | translate }}
                  <p-sortIcon field="typeDomainDescription"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="creationUser">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "ComercialActivityNegociationExclusionComponent.Creator" | translate }}
                  <p-sortIcon field="creationUser"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="creationDate">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ "ComercialActivityNegociationExclusionComponent.CreationDate" | translate }}
                  <p-sortIcon field="creationDate"></p-sortIcon>
                </div>
              </th>
              <th>{{ "Actions" | translate }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-object let-expanded="expanded">
            <tr [pSelectableRow]="object">
              <td></td>
              <td>
                {{object.commercialActivityId}}
              </td>
              <td>
                {{object.commercialActivityDescription}}
              </td>
              <td>
                {{ 'Domain.Domain-'+object.typeDomainDescription | translate}}
                <!-- {{object.typeDomainDescription}} -->
              </td>

              <td>
               {{object.creationUser}}
              </td>
              <td>
                {{object.creationDate | date:'dd/MM/yyyy'}}
              </td>
              <td>
                <i aria-hidden="true" id="btnDelete" 
                  class="uil uil-trash-alt eliminar" 
                  pTooltip="{{'Delete' | translate}}"
                  (click)="delete(object.id)"
                  *ngIf="rolePermission.delete | rolePermission"></i>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">{{ "ElementsNotFound" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
      </div>
    </div>
  </div>
</section>
<p-toast></p-toast>