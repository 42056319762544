import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccessManagementJson } from './interfaces/access-management-json.interface';
import { PaginationModel } from '../../../@shared/model/pagination.model';
import { IAccessManagement } from './interfaces/access-management.interface';
import { AccessManagementModel } from './models/access-management.model';
import { AccessManagementRequestModel } from './models/access-management-request.model';
import { IRoleMaster } from '@app/@shared/interfaces/role-master.interface';
import { IAccessManagementConfigurationJson } from './interfaces/access-management-configuration-json.interface';

@Injectable({
  providedIn: 'root'
})
export class AccessManagementService {

  private processAccessManagement = 0;
  private accessManagementId: number;
  private accessManagemStringentId = '';
  private accessManagementAll: IAccessManagementConfigurationJson[];
  private readonly httpService: HttpService;


  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  /**
  * @description This method is to get all the Accessmanagement.
  * @author Priscy Antonio Reales
  * @param none
  * @returns Observable<IResponseService<IAccessManagement[]>>
  * @sprint 1
  */
  public getByIdAccessManagement(id: string): Observable<IResponseService<IAccessManagement>> {
    const response$: Observable<IResponseService<IAccessManagement>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IAccessManagement>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to get all the Accessmanagement.
  * @author Priscy Antonio Reales
  * @param none
  * @returns Observable<IResponseService<IAccessManagement[]>>
  * @sprint 1
  */
   public getByIdAccessManagementEmail(solicitudEmail: string): Observable<IResponseService<IAccessManagement>> {
    const response$: Observable<IResponseService<IAccessManagement>> =
    this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/email/${solicitudEmail}`)
      .pipe(
        map((response: HttpResponse<IResponseService<IAccessManagement>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public getRoleAll(): Observable<IResponseService<IRoleMaster[]>> {
    const response$: Observable<IResponseService<IRoleMaster[]>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/roles/1/1000`)
      .pipe(
        map((response: HttpResponse<IResponseService<IRoleMaster[]>>) => {
          return response.body;
        })
      );
    return response$;
  }
  /**
   * @description This method is to get all the Accessmanagement.
   * @author Priscy Antonio Reales
   * @param none
   * @returns Observable<IResponseService<IAccessManagement[]>>
   * @sprint 1
   */
  public getAllAccessManagement(): Observable<IResponseService<IAccessManagement[]>> {
    const response$: Observable<IResponseService<IAccessManagement[]>> =
     this.httpService
      .get(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/1/1000`)
      .pipe(
        map((response: HttpResponse<IResponseService<IAccessManagement[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
   * @description This method is to get all the IAccessManagementJson.
   * @author Priscy Antonio Reales
   * @param none
   * @returns Observable<IResponseService<IAccessManagementJson[]>>
   * @sprint 1
   */
  public getListAccessManagement(paginationModel: PaginationModel): Observable<IResponseService<IAccessManagementJson[]>> {
    const response$: Observable<IResponseService<IAccessManagementJson[]>> =
     this.httpService
      .patch(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/dapper_utilities/list_access_management`, paginationModel)
      .pipe(
        map((response: HttpResponse<IResponseService<IAccessManagementJson[]>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to create AccessManagement.
 * @author Priscy Antonio Reales
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 1
 */
  public postAccessManagementWhitConfiguration(body: AccessManagementRequestModel): Observable<IResponseService<string>> {
    const response$: Observable<IResponseService<string>> =
     this.httpService
      .post(
        `${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/associate_with_configurations
        `,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<string>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to create AccessManagement.
 * @author Priscy Antonio Reales
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 1
 */
  public putAccessManagementWhitConfiguration(body: AccessManagementRequestModel): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService
      .put(
        `${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/associate_with_configurations`,
        body
      )
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
 * @description This method is to update AccessManagement.
 * @author Priscy Antonio Reales
 * @param params
 * @returns Observable<ResponseService>
 * @sprint 1
 */
  public putAccessManagement(id: string, body: AccessManagementModel): Observable<IResponseService<number>> {
    const response$: Observable<IResponseService<number>> =
    this.httpService
      .put(
        `${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/${id}`,
          body)
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );
    return response$;
  }

  /**
  * @description This method is to delete a AccessManagement.
  * @author Priscy Antonio Reales
  * @param params
  * @returns Observable<ResponseService>
  * @sprint 1
  */
  public deleteAccessManagement(id: string): Observable<IResponseService<boolean>> {
    const response$: Observable<IResponseService<boolean>> =
     this.httpService.delete(`${environment.apiUrlSecurity}/api/v1/ccvp_apwb_secu/access_managements/logic/${id}`)
      .pipe(
        map((response: HttpResponse<IResponseService<boolean>>) => {
          return response.body;
        })
      );
    return response$;
  }


  /**
   * @description This method is to return id storage.
   * @author Priscy Antonio Reales
   * @param none
   * @returns number
   * @sprint 3
   */
  getAccessManagementId(): number {
    return this.accessManagementId;
  }

  /**
   * @description This method is to storage id.
   * @author Priscy Antonio Reales
   * @param number
   * @returns none
   * @sprint 3
   */
  setAccessManagementId(id: number) {
    this.accessManagementId = id;
  }

  getAccessManagementAll(): IAccessManagementConfigurationJson []{
    return this.accessManagementAll;
  }
  setAccessManagementAll(Object : IAccessManagementConfigurationJson []) {
    this.accessManagementAll = Object;
  }

  setAccessManagementIdUser(id: string) {
    this.accessManagemStringentId = id;
  }

  getAccessManagementIdUser(){
    return this.accessManagemStringentId;
  }

  setProcessAccessManagement(process: number){
    this.processAccessManagement = process;
  }

  getProcessAccessManagement(){
    return this.processAccessManagement;
  }

}
