import { Injectable } from '@angular/core';
import { IResponseService } from '@app/@shared/interfaces/response-service.interface';
import { HttpService } from '@app/@shared/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RedeemableModel } from './models/redeemable.model';
import { IRedeemable } from './interfaces/redeemable.interfaces';
import { CommercialActivityRedemptionModel } from './models/commercial-activity-redemption.model';


@Injectable({
  providedIn: 'root'
})
export class CommercialActivityRedemptionService {
  private readonly httpService: HttpService;
  constructor(httpService: HttpService) { 
    this.httpService = httpService;
  }

  public postRedeemable(body: RedeemableModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .post(
      `${environment.apiUrlCore}/api/Redeemable/`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public getAllRedeemables(): Observable<IResponseService<IRedeemable[]>> {
    const response$: Observable<IResponseService<IRedeemable[]>> =
     this.httpService
    .get(
      `${environment.apiUrlCore}/api/Redeemable/1/100`,)
    .pipe(
      map((response: HttpResponse<IResponseService<IRedeemable[]>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public postPromotionalActivity(body: CommercialActivityRedemptionModel): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .post(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/associate_commercial_activity_with_detail/`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public getPromotionalActivityById(id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
      .get(
        `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/commercial_activity_by_id/${id}`
      )
      .pipe(
        map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
        })
      );
    return response$;
  }

  public putPromotionalActivity(body: CommercialActivityRedemptionModel, id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .put(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity/associate_commercial_activity_with_detail/${id}`,
      body)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }

  public getImporteAmount(conditionClassCode: string, code: string): Observable<IResponseService<number>>{
    let response$: Observable<IResponseService<number>>;

    response$ = this.httpService.get(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/dapper_utilities/importe_amount/${conditionClassCode}/${code}`
    )
      .pipe(
        map((response: HttpResponse<IResponseService<number>>) => {
          return response.body;
        })
      );

      return response$;
  }

  public deleteProductRedeemable(id: number): Observable<IResponseService<any>> {
    const response$: Observable<IResponseService<any>> =
     this.httpService
    .delete(
      `${environment.apiUrlCore}/api/v1/ccvp_apwb_core/commercial_activity_redeemable/logic/${id}`)
    .pipe(
      map((response: HttpResponse<IResponseService<any>>) => {
          return response.body;
      })
    );
    return response$;
  }
}
