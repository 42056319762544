<p-dialog
  [(visible)]="showDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [style]="{width: '80vw'}"
  (onHide)="onHidden()"
>
  <ng-template pTemplate="header" style="background: chartreuse;">
    <h5 class="modal-title" id="staticBackdropLabel">{{ title }}</h5>
  </ng-template>
  <div class="modal-body">
    <div class="card-text notifi-text" 
    [innerHTML]="notificationText"  [style]="{'height':'150px', 'overflow-x': 'hidden' }">
      
    </div>

    <div class="row">
      <button 
        *ngIf="moreInfo" 
        type="button" 
        class="btn btn-link pl-0"
        (click)="onClickSeeDocument($event)">
        {{ 'SeeActivity' | translate }}
      </button>
    </div>
    <div class="form-group">
      <label *ngIf="commentary"  for="message-text" class="col-form-label">{{ 'Commentary' | translate}}</label>      
      <textarea
        #commentary
        *ngIf="commentary"
        [(ngModel)]="commentaryText"
        pInputTextarea
        class="form-control"
        id="message-text"
        rows="2"
      ></textarea>
    </div>
  </div>

  <p-footer>
    <div class="row">
    <div class="col-md-6 text-left">
      <button *ngIf="snooze" class="pstn_posponer" (click)="onClickSnooze($event)">
        <i class="uil uil-history" aria-hidden="true"></i> {{ 'BtnDecline'| translate}}
      </button>
    </div>

    <div class="col-md-6 text-right">
      <button *ngIf="accept"  class="pstn_aceptar" (click)="onClickAccept($event)">
        <i class="uil uil-check-circle" aria-hidden="true" ></i> {{ 'BtnViewed'| translate}}
      </button>
      <button *ngIf="reject"  class="pstn_rechazar" (click)="onClickReject($event)">
        <i class="uil uil-times-circle" aria-hidden="true" ></i>{{ 'BtnPutOff'| translate}}
      </button>
    </div>
  </div>
  </p-footer>
</p-dialog>
