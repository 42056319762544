export const environment = {
  production: true,
  environmentName: 'PROD',
  version: '1.0.0.0',
  hmr: true,
  serverUrl: '/api',
  defaultLanguage: 'es-ES',
  supportedLanguages: ['en-US', 'es-ES'],
  conditionTable: '509', // CÃ³digo de la tabla de Materiales
  conditionClassCode: 'ZPLP', // CÃ³digo de la Clase de Condiciones para Materiales
  apiUrlSecurity: 'https://ccvpapwbsecbprod.azurewebsites.net',
  apiUrlCore: 'https://ccvpapwbcoreprod.azurewebsites.net',
  apiUrlTools: '',
  apiUrlIntegration: 'https://ccvpapwbinteprod.azurewebsites.net',
  apiAnalytics: 'https://ccvpapwbanalprod.azurewebsites.net',
  apimSubscriptionKey : 'faf57fd2ab854f1fb88055c5dfbc1243',
  clientStorageType: 'SESSION', // LOCAL - SESSION
  appInsights: {
    instrumentationKey: 'b2e34dad-1955-4219-becd-20d26f8789b8',
  },
  applicationId: '32D5FFE381D64B9FAB83F9DDD34EE4A0',
  crypto: {
    enabled: true,
    secret: '@jYS9P8=z58hC4-@i{59Yr^Qw%4gAd',
    encryptSecret: '52179298027054134849559501952416',
    decryptSecret: '@9g2249Wr2KC^+4i!Cb?P@Wf>/-qw6CF&6J9@xgYgqkxJ5-5W3',
  },
  msalAuth: {
    tenantId: '1be5ad33-84d3-42a0-a64a-1b673e4e9944',
    clientId: '667f937d-03ef-471f-a26a-4d84e8ba434d',
    authority: 'https://login.microsoftonline.com/postobon.com.co',
    redirectUri: 'https://www.ccv-grupolux.com',
    postLogoutRedirectUri: 'https://www.ccv-grupolux.com',
    protectedResourceMap: 'https://graph.microsoft.com/v1.0/me',
    ApiScope: 'api://ac37b140-686b-478f-b63c-cfaff8d485f5/user_impersonation',
  },
};
