import { ISubGroupItem } from '../../interfaces/sub-group-item.interface';
import { ITableGroupItem } from '../../interfaces/table-group-item.interface';


export class DiscountPriceAdjustment implements ITableGroupItem {
    constructor(
        public id?: string,
        public descGroup?: string,
        public unitMeasure?: string,
        public groupByJson?: string,
        public contentGroup?: string,
        public subOptions?: ISubGroupItem[],
        public percentDiscountPriceAdjustment?: string,
        public check?: boolean,
        public valueDiscountPriceAdjustment?: string
    ) { }
}
