<div class="row justify-content-between">
    <!--Encabezado de la tabla-->
    <div class=" align-self-center" >
      <h2 class="pstn_subtitle">{{ 'AdvancedRedeemableFilterComponent.SelectedFilters' | translate }}</h2>

      {{ 'AdvancedRedeemableFilterComponent.TitleClearFilter' | translate }}
      <button id="btnDelete" type="button" class="delete btn btn-secundary-delete"
      pTooltip="Eliminar Filtros" (click)="deleteFilters()"
      *ngIf="(rolePermission.delete | rolePermission)">
        <i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
      </button>
    </div>

    <div class="float-right">

    <div class="container" *ngIf="isProcess !== comercialActivityEnum.view && (rolePermission.create | rolePermission)">
      <button class="pstn_secundario mb-3" (click)="openAdvancedFilters()"
      [disabled]="!isMultiFilter && advancedFiltersObjectList.length > 0">
        <i class="uil uil-filter" aria-hidden="true"></i>
        {{ 'AdvancedRedeemableFilterComponent.AdvancedFilters' | translate }}
      </button>
    </div>

    <!-- Filter Menu -->
    <p-sidebar [(visible)]="isVisible" position="right" [baseZIndex]="10000" [dismissible]="false"
    [closeOnEscape]="false" styleClass="p-sidebar-md" (onHide)="closeAdvancedFilters()"
    [style]="{'overflow-x': 'scroll'}">

        <div class="modal-header" >
        <div class="row justify-content-between">
          <h5 class="modal-title float-left" id="myModalLabel2">
            <strong>{{ 'AdvancedRedeemableFilterComponent.AdvancedFilters' | translate }}</strong>
          </h5>
        </div>
      </div>

      <div class="modal-body" *ngIf="isProcess !== comercialActivityEnum.view">
            <div class="row">
                <div class="col-md-4">
                <button pButton pRipple type="button" icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-button-outlined" (click)="openAdvancedFilters()"></button>
                </div>

                <div class="col-md-8" style="text-align: right;">
                <p-button label="{{ 'AdvancedRedeemableFilterComponent.AddFilterBtn' | translate }}"
                    icon="pi pi-plus-circle"
                    styleClass="p-button"
                    (click)="addFilter()"
                    *ngIf="!toEditFilter"></p-button>
                <p-button label="{{ 'AdvancedRedeemableFilterComponent.EditFilterBtn' | translate }}"
                    icon="pi pi-plus-circle"
                    styleClass="p-button"
                    (click)="addFilter()"
                    *ngIf="toEditFilter"></p-button>
                </div>
            </div>

            <!-- Radio button -->
            <div class="col-md-8 mt-3">
							<label for="typeFilter">{{ 'AdvancedRedeemableFilterComponent.SelectFilter' | translate }}</label>
							<div class="mt-2 row justify-content-between">
								<p-radioButton name="typeFilter"
									value="2" label="Elementos"
									[(ngModel)]="selectedValue"
									[disabled]="toEditFilter"></p-radioButton>
								<p-radioButton name="typeFilter"
									value="1"
									label="Productos Redimibles"
									[(ngModel)]="selectedValue"
									[disabled]="toEditFilter"></p-radioButton>
							</div>
            </div>

            <!--Product-->
          <div class="mt-3" *ngIf="selectedValue == 1">
                <!--Category-->
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label for="category">
                            {{ 'AdvancedRedeemableFilterComponent.Category' | translate }}
                        </label><br/>
                        <p-dropdown
                        *ngIf="!isMultiFilter || toEditFilter"
                        id="category"
                        [options]="categoriesFilterList"
                        [(ngModel)]="categorySelected"
                        placeholder="{{ 'AdvancedRedeemableFilterComponent.CategorySelect' | translate }}"
                        [virtualScroll]="true"
                        [filter]="true" optionLabel="label" optionValue="value" [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(2)"></p-dropdown>

                        <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" id="category"
                        class="multiselect-custom-virtual-scroll"
                        [virtualScroll]="true"
                        [options]="categoriesFilterList"
                        [(ngModel)]="categoryMultiSelected" [filter]="true"
                        defaultLabel="{{ 'AdvancedRedeemableFilterComponent.CategorySelect' | translate }}"
                        optionLabel="label"
                        optionValue="value" display="chip" [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(2)"></p-multiSelect>
                    </div>
                </div>
                <!--#End Category-->
                <div class="row" *ngIf="(categorySelected !== undefined &&
                    categorySelected !== null && categorySelected !== '') ||
                    (categoryMultiSelected.length > 0)">

                    <!-- #Packing -->
                    <div class="col-md-12 form-group">
                        <label for="packing">{{ 'AdvancedRedeemableFilterComponent.Packing' | translate }}</label><br />
                        <p-dropdown *ngIf="!isMultiFilter || toEditFilter" id="packing" [options]="packagingFilterList"
                        [(ngModel)]="packingSelected"
                        placeholder="{{ 'AdvancedRedeemableFilterComponent.PackingSelect' | translate }}"
                        [virtualScroll]="true"
                        [filter]="true"
                        [showClear]="true"
                        optionLabel="label"
                        optionValue="value"
                        [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(3)"></p-dropdown>

                        <p-multiSelect
                        *ngIf="isMultiFilter && !toEditFilter"
                        id="packing"
                        class="multiselect-custom-virtual-scroll"
                        [virtualScroll]="true"
                        [options]="packagingFilterList"
                        [(ngModel)]="packingMultiSelected"
                        [filter]="true"
                        defaultLabel="{{ 'AdvancedRedeemableFilterComponent.PackingSelect' | translate }}"
                        optionLabel="label"
                        optionValue="value" display="chip" [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(3)"></p-multiSelect>
                    </div>
                    <!-- #EndPacking -->

                    <!-- #Content -->
                    <div class="col-md-12 form-group"
                        *ngIf="(packingSelected !== undefined &&
                        packingSelected !== null &&
                        packingSelected !== '') || (packingMultiSelected.length > 0)">
                        <label for="content">{{ 'AdvancedRedeemableFilterComponent.Content' | translate }}</label><br />
                        <p-dropdown *ngIf="!isMultiFilter || toEditFilter" id="content" [options]="contentFilterList"
                        [(ngModel)]="contentSelected"
                        placeholder="{{ 'AdvancedRedeemableFilterComponent.ContentSelect' | translate }}"
                        [virtualScroll]="true"
                        [itemSize]="10" [filter]="true" [showClear]="true" optionLabel="label" optionValue="value"
                        [style]="{'width': '100%'}" (onChange)="getFiltersByLevelHierarchy(4)"></p-dropdown>

                        <p-multiSelect *ngIf="isMultiFilter && !toEditFilter"
                        id="content"
                        class="multiselect-custom-virtual-scroll"
                        [virtualScroll]="true"
                        [options]="contentFilterList"
                        [(ngModel)]="contentMultiSelected"
                        [filter]="true"
                        defaultLabel="{{ 'AdvancedRedeemableFilterComponent.ContentSelect' | translate }}"
                        optionLabel="label"
                        optionValue="value" display="chip" [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(4)"></p-multiSelect>
                    </div>
                    <!-- #EndContent -->

                    <!-- #Returnability -->
                    <div class="col-md-12 form-group"
                        *ngIf="(contentSelected !== undefined &&
                        contentSelected !== null &&
                        contentSelected !== '') || (contentMultiSelected.length > 0)">
                        <label for="returnability">
                            {{ 'AdvancedRedeemableFilterComponent.Returnability' | translate }}
                        </label><br />
                        <p-dropdown *ngIf="!isMultiFilter || toEditFilter"
                        id="returnability"
                        [options]="returnabilityFilterList"
                        [(ngModel)]="returnabilitySelected"
                        placeholder="{{ 'AdvancedRedeemableFilterComponent.ReturnabilitySelect' | translate }}"
                        [virtualScroll]="true" [itemSize]="10" [filter]="true" [showClear]="true" optionLabel="label"
                        optionValue="value"
                        [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(5)"></p-dropdown>

                        <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" id="returnability"
                        class="multiselect-custom-virtual-scroll"
                        [virtualScroll]="true" [options]="returnabilityFilterList"
                        [(ngModel)]="returnabilityMultiSelected" [filter]="true"
                        defaultLabel="{{ 'AdvancedRedeemableFilterComponent.ReturnabilitySelect' | translate }}"
                        optionLabel="label" optionValue="value" display="chip" [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(5)"></p-multiSelect>
                    </div>
                    <!-- #EndReturnability -->

                    <!-- #Denomination  -->
                    <div class="col-md-12 form-group"
                        *ngIf="(returnabilitySelected !== undefined
                        && returnabilitySelected !== null && returnabilitySelected !== '') ||
                        (returnabilityMultiSelected.length > 0)">
                        <label for="denomination ">
                            {{ 'AdvancedRedeemableFilterComponent.Denomination' | translate }}
                        </label><br />
                        <p-dropdown
                        *ngIf="!isMultiFilter || toEditFilter" id="denomination "
                        [options]="denominationFilterList"
                        [(ngModel)]="denominationSelected"
                        placeholder="{{ 'AdvancedRedeemableFilterComponent.DenominationSelect' | translate }}"
                        [virtualScroll]="true" [itemSize]="10" [filter]="true" [showClear]="true" optionLabel="label"
                        optionValue="value"
                        [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(6)"></p-dropdown>

                        <p-multiSelect *ngIf="isMultiFilter && !toEditFilter" id="denomination"
                        class="multiselect-custom-virtual-scroll"
                        [virtualScroll]="true"
                        [options]="denominationFilterList"
                        [(ngModel)]="denominationMultiSelected" [filter]="true"
                        defaultLabel="{{ 'AdvancedRedeemableFilterComponent.DenominationSelect' | translate }}"
                        optionLabel="label" optionValue="value" display="chip" [style]="{'width': '100%'}"
                        (onChange)="getFiltersByLevelHierarchy(6)"></p-multiSelect>
                    </div>
                    <!-- #EndDenomination -->
                </div>
					</div>

					<!--Elemento-->
					<div class="mt-3" *ngIf="selectedValue == 2">
						<!--Code-->
						<div class="row">
							<div class="col-md-12 form-group">
								<label for="code">
									{{ 'AdvancedRedeemableFilterComponent.ElementRedemeeable' | translate }}
								</label><br />

								<p-multiSelect
									id="code"
									*ngIf="!toEditFilter"
									class="multiselect-custom-virtual-scroll"
									[virtualScroll]="true"
									[(ngModel)]="codesMultiSelected"
									[options]="codesFilterList"
									[filter]="true"
									defaultLabel="{{ 'AdvancedRedeemableFilterComponent.ElementCodeSelect' | translate }}"
									display="chip"
									[style]="{'width': '100%'}"></p-multiSelect>

								<p-dropdown
									id="code"
									*ngIf="toEditFilter"
									[options]="codesFilterList"
									[(ngModel)]="codeSelected"
									[virtualScroll]="true"
									[filter]="true"
									optionLabel="label"
									optionValue="value"
									[style]="{'width': '100%'}"></p-dropdown>
							</div>
						</div>
					</div>
        </div>
    </p-sidebar>
  </div>
</div>

<div class="row">
    <div class="table-responsive">
      <!-- PrimeNg Table -->
      <p-table #dttea
            name="gridAdvancedFilters"
            [value]="advancedFiltersObjectList"
            dataKey="hierarchyFilter"
            [paginator]="true"
            [rows]="10"
            paginatorPosition="bottom"
            [(selection)]="selectObject"
            styleClass="p-datatable-customers"
            [rowsPerPageOptions]="[10,25,50,100]"
            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
            [globalFilterFields]="['hierarchyFilter']">

            <ng-template pTemplate="header">
                <tr>
                    <th role="columnheader" style="width: 3rem" *ngIf="isProcess !== comercialActivityEnum.view">
                    <p-checkbox 
                        (click)="selectAll(e.checked)" 
                        [(ngModel)]="checkDelete" 
                        binary="true" #e>
                    </p-checkbox>
                    </th>

                    <th pSortableColumn="type">
											<div class="p-d-flex p-jc-between p-ai-center">
												{{ 'AdvancedRedeemableFilterComponent.Group' | translate }}
												<p-sortIcon field="type"></p-sortIcon>
											</div>
                    </th>

                    <th pSortableColumn="content">
											<div class="p-d-flex p-jc-between p-ai-center">
												{{ 'AdvancedRedeemableFilterComponent.ContentSearch' | translate }}
												<p-sortIcon field="content"></p-sortIcon>
											</div>
                    </th>

                    <th>{{ 'Actions' | translate }}</th>
                </tr>
                <tr></tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-object>
                <tr>
                    <td *ngIf="isProcess !== comercialActivityEnum.view">
                        <p-checkbox (click)="selectRow(e.checked,object)" [(ngModel)]="object.check"
                                    binary="true" #e></p-checkbox>
                    </td>
                    <td>{{object.group}}</td>
                    <td tooltipPosition="top">{{object.hierarchyFilter}} </td>
                    <td>
											<button id="btnEdit" 
                                                *ngIf="isProcess !== comercialActivityEnum.view && 
                                                (rolePermission.update | rolePermission)" 
                                                type="button" ngbTooltip="Editar"
												class="edit btn btn-secundary-editar" pTooltip="Editar" (click)="edit(rowIndex, object)">
												<i class="uil uil-edit Editar" aria-hidden="true"></i>
											</button>
											<button id="btnDelete" 
                                                *ngIf="isProcess !== comercialActivityEnum.view && 
                                                (rolePermission.delete | rolePermission)" 
                                                ngbTooltip="Eliminar" type="button"
												class="delete btn btn-secundary-delete" pTooltip="Eliminar" (click)="delete(rowIndex, object)">
												<i class="uil uil-trash-alt eliminar" aria-hidden="true"></i>
											</button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">{{ 'ElementsNotFound' | translate }}</td>
                </tr>
            </ng-template>
        </p-table>
        <!-- PrimeNg Table -->
    </div>
</div>

<div class="row">
    <div class="col-md-8"></div>
    <div class="col-md-4 mt-2 align-items-end" *ngIf="isProcess !== comercialActivityEnum.view" >
      <button id="btnRunFilters" pButton pRipple type="button"
        label="{{ 'AdvancedRedeemableFilterComponent.ExecuteFilter' | translate }}"
        class="p-button-rounded pstn_primario w-100"
        [disabled]="advancedFiltersObjectList.length === 0" style="width: 100%;"
        (click)="eventClickFilters()"></button>
    </div>
</div>
