<form id="formPeriod" [formGroup]="formPeriod">
    <div class="col-md-4">
        <label>
          Periodo
        </label>
        <div class="form-group">
            <p-multiSelect id="periodFilter" class="multitooltip"
                [filter]="true"
                [ngClass]="{'ng-invalid ng-dirty': periodFilter.invalid && periodFilter.touched}"
                formControlName="periodFilter"
                optionLabel="label"
                optionValue="value"
                placeholder="{{'ApplicationAuditComponent.SelectOption'| translate}}"
                [options]="objectPeriodList"
                [style]="{ width: '80%' }"
                display="chip"
                [disabled]="this.isProcess === commercialActivityEnum.view"
                (onChange)="onChangePeriod()"
                >
            </p-multiSelect>
            <br>
            <small id="periodFilter"
                *ngIf="formPeriod.controls['periodFilter'].invalid 
                && (formPeriod.controls['periodFilter'].dirty ||
                formPeriod.controls['periodFilter'].touched)"
                class="p-error">{{ 'HandlingProductFilterComponent.Period' | translate }}
            </small>
    
            <small class="Tooltip" *ngIf="periodToolTip">
              <ul>
                <li *ngFor="let index of periodToolTip">{{index}}</li>
              </ul>
            </small>
    
        </div>
    </div>
</form>