export class LanguageCalendarHelper {

    /**
   * @description this method is convert calendar spanish.
   * @author Elkin Vasquez Isenia
   * @Sprint 5
   */
    static spanish(): any {
        return {
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sa'],
            monthNames: [ 'Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre' ],
            monthNamesShort: [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun','Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic' ],
            today: 'Hoy',
            clear: 'Limpiar',
            dateFormat: 'mm/dd/yy',
            weekHeader: 'Wk'
        };
    }
}
