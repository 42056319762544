import { IPagination } from '../interfaces/pagination.interface';

export class PaginationDateModel implements IPagination {
  /**
   * @author Elkin Vasquez Isenia
   * @sprint 1
   */
  constructor(
    public orderBy: string,
    public ascendent: boolean,
    public pageNumber: number,
    public resultsPerPage: number,
    public startDate: string,
    public endDate: string,
    public findByUser? : boolean,
    public status?: number,
    public filter?: string,
    ) {}
}
