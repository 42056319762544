
  export class OrganizationChartModel{

    constructor(
        public hierarchyConfigurationId: number,
        public emailUser: string,
        public infoNodeJson: string,
        public parentNodeId: number,
        public hierarchyApprovalLevelId: number,
        public id?: number,
    ) {}
  }

